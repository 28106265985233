import React, { useEffect, useState } from "react";

import { GetEligibility_eligibilityRequest as EligibilityRequest } from "../../../generated/GetEligibility";
import {
  Response as RawEligibilityResponse,
  BenefitCode,
  BenefitsInformation,
  YesNoResponse,
  BenefitsRelatedEntity,
  ResponseMember,
  Contacts,
  CoverageLevel,
  TimePeriodQualifierCode,
  ServiceTypeCode,
  QuantityQualifierCode,
} from "./types";
import {
  classNames,
  cn,
  formatDateMMDDYYYY,
  formatPercentage,
  isDefined,
  mapNullable,
  usdFormatter,
} from "../../../utils";
import {
  HeartIcon,
  ExclamationIcon,
  PhoneIcon,
  CheckIcon,
  DuplicateIcon,
  PrinterIcon,
  MailIcon,
  LinkIcon,
} from "@heroicons/react/outline";
import { HashLink } from "react-router-hash-link";
import { Card, Badge, ProgressBar, Tooltip } from "../../../components";
import { useUser } from "../../../user-context";
import { useAnalytics } from "../../../analytics-context";
import { EligibilityError } from "./show";
import { Clearinghouse } from "../../../generated/globalTypes";

type ServiceTypeBenefits = {
  serviceTypeDisplay: string;
  benefits: {
    [benefitCode: string]: BenefitsInformation[];
  };
};

const COVERAGE_LEVEL_ORDER = [
  CoverageLevel.Individual,
  CoverageLevel.EmployeeOnly,
  CoverageLevel.EmployeeAndSpouse,
  CoverageLevel.Family,
] as string[];

// Hack to sort the benefits that have the note "HIGHEST BENEFIT" to the bottom
const sortHighestBenefitNoteLast = (
  a: BenefitsInformation,
  b: BenefitsInformation
) => {
  const aNote = a.additionalInformation?.some((n) =>
    n.description?.includes("HIGHEST BENEFIT")
  );
  const bNote = b.additionalInformation?.some((n) =>
    n.description?.includes("HIGHEST BENEFIT")
  );
  if (aNote && !bNote) return 1;
  if (!aNote && bNote) return -1;
  return 0;
};
// Hack to sort HRA deductibles to the bottom
const sortHraDeductibleNoteLast = (
  a: BenefitsInformation,
  b: BenefitsInformation
) => {
  const aNote = a.additionalInformation?.some((n) =>
    n.description?.includes("HRA UPFRONT DEDUCTIBLE")
  );
  const bNote = b.additionalInformation?.some((n) =>
    n.description?.includes("HRA UPFRONT DEDUCTIBLE")
  );
  if (aNote && !bNote) return 1;
  if (!aNote && bNote) return -1;
  return 0;
};
// TODO: Move this kind of sorting to backend
const sortBenefits = (benefits: BenefitsInformation[]) => {
  return benefits
    .sort(sortHighestBenefitNoteLast)
    .sort(sortHraDeductibleNoteLast);
};

const PlanBenefitDetails: React.FC<
  React.PropsWithChildren<{
    serviceType: string;
    benefits: ServiceTypeBenefits;
  }>
> = ({ serviceType, benefits: serviceTypeBenefits }) => {
  const { benefits, serviceTypeDisplay } = serviceTypeBenefits;
  const [activeCoverageBenefit] = benefits[BenefitCode.ActiveCoverage] ?? [];
  let insuranceType;
  let planCoverage;
  if (activeCoverageBenefit) {
    insuranceType = activeCoverageBenefit.insuranceType;
    planCoverage = activeCoverageBenefit.planCoverage;
  }

  const benefitObjects = Object.values(benefits).flat();

  const activeCoverageBenefits = benefitObjects.filter(
    (benefit) => benefit.code === BenefitCode.ActiveCoverage
  );

  const coverageLevelCodes = Array.from(
    new Set([
      ...benefitObjects
        .map((benefit) => benefit.coverageLevelCode)
        .filter(isDefined),
      null,
      undefined,
    ])
  );

  const groupedBenefits = benefitObjects.reduce(
    (accum: { [key: string]: BenefitsInformation[] }, benefit) => {
      // Don't add active coverage benefits to the group
      // const activeCoverageBenefit = benefit.code === BenefitCode.ActiveCoverage;
      // if (activeCoverageBenefit) return accum;

      const notes =
        benefit.additionalInformation
          ?.map((info) => info.description)
          ?.filter((note) => {
            if (!isDefined(note)) return false;
            // Filter out "deductible does not apply" notes from key
            const isDeductibleDoesNotApplyNote = note
              .toLocaleLowerCase()
              .includes("deductible does not apply");
            return !isDeductibleDoesNotApplyNote;
          }) ?? [];
      const planNetworkIdNumber =
        benefit.benefitsAdditionalInformation?.planNetworkIdNumber ?? "";

      const key = `${benefit.coverageLevel}-${
        benefit.eligibilityAdditionalInformation?.industryCode
      }-${notes.join("|")}-${planNetworkIdNumber}`;
      if (!accum[key]) accum[key] = [];
      accum[key].push(benefit);
      return accum;
    },
    {}
  );
  const normalizedBenefits = Object.values(groupedBenefits).map((benefits) =>
    getNormalizedBenefits(benefits)
  );
  const nonTableBenefits = normalizedBenefits.filter(
    (b) => !b.hasTableBenefits
  );

  const anyCopay = benefitObjects.some((b) => b.code === BenefitCode.CoPayment);
  const anyCoinsurance = benefitObjects.some(
    (b) => b.code === BenefitCode.CoInsurance
  );
  const anyLimitations = benefitObjects.some(
    (b) => b.code === BenefitCode.Limitations
  );

  let columns = 3;
  if (anyCopay) columns++;
  if (anyCoinsurance) columns++;
  if (anyLimitations) columns++;

  return (
    <Card
      id={serviceType}
      className="flex flex-col gap-2 bg-white overflow-x-auto"
    >
      <div className="flex items-center gap-1 pb-1">
        <h2 className="font-medium text-lg">{serviceTypeDisplay}</h2>
        {activeCoverageBenefit && (
          <Badge text="Active Coverage" variant="success" />
        )}
      </div>
      {(insuranceType || planCoverage) && (
        <>
          {insuranceType && <div>Insurance Type: {insuranceType}</div>}
          {planCoverage && <div>Plan Coverage: {planCoverage}</div>}
        </>
      )}
      {coverageLevelCodes.length > 0 && (
        <table className="divide-y divide-gray-200">
          <tr className="divide-x divide-gray-200 text-gray-500">
            <th>Coverage Level</th>
            <th>Deductible</th>
            <th>Out of Pocket</th>
            {anyCopay && <th>Copay</th>}
            {anyCoinsurance && <th>Coinsurance</th>}
            {anyLimitations && <th>Limitations</th>}
          </tr>
          {coverageLevelCodes
            .sort(
              (a, b) =>
                COVERAGE_LEVEL_ORDER.indexOf(a) -
                COVERAGE_LEVEL_ORDER.indexOf(b)
            )
            .map((coverageLevelCode) => {
              const coverageLevelBenefits = benefitObjects.filter(
                (benefit) => benefit.coverageLevelCode === coverageLevelCode
              );
              const [deductibleRemainingBenefit] = sortBenefits(
                coverageLevelBenefits.filter(
                  (benefit) =>
                    benefit.code === BenefitCode.Deductible &&
                    ["Remaining", "Year to Date"].includes(
                      benefit.timeQualifier as string
                    )
                )
              );
              const [deductibleMaxBenefit] = sortBenefits(
                coverageLevelBenefits.filter(
                  (benefit) =>
                    benefit.code === BenefitCode.Deductible &&
                    // Assume no timeQualifier means max benefit
                    (["Calendar Year", "Service Year"].includes(
                      benefit.timeQualifier as string
                    ) ||
                      !isDefined(benefit.timeQualifier))
                )
              );
              const [outOfPocketRemainingBenefit] = sortBenefits(
                coverageLevelBenefits.filter(
                  (benefit) =>
                    benefit.code === BenefitCode.OutofPocketStopLoss &&
                    ["Remaining", "Year to Date"].includes(
                      benefit.timeQualifier as string
                    )
                )
              );
              const [outOfPocketMaxBenefit] = sortBenefits(
                coverageLevelBenefits.filter(
                  (benefit) =>
                    benefit.code === BenefitCode.OutofPocketStopLoss &&
                    // Assume no timeQualifier means max benefit
                    (["Calendar Year", "Service Year"].includes(
                      benefit.timeQualifier as string
                    ) ||
                      !isDefined(benefit.timeQualifier))
                )
              );
              const deductibleMax = mapNullable(parseFloat)(
                deductibleMaxBenefit?.benefitAmount! ?? null
              );
              let deductibleRemaining = mapNullable(parseFloat)(
                deductibleRemainingBenefit?.benefitAmount! ?? null
              );
              // If the remaining deductible benefit is YTD, then normalize to remaining by subtracting from max
              if (
                deductibleMax &&
                isDefined(deductibleRemaining) &&
                deductibleRemainingBenefit.timeQualifier == "Year to Date"
              ) {
                deductibleRemaining = deductibleMax - deductibleRemaining;
              }
              const outOfPocketMax = mapNullable(parseFloat)(
                outOfPocketMaxBenefit?.benefitAmount! ?? null
              );
              let outOfPocketRemaining = mapNullable(parseFloat)(
                outOfPocketRemainingBenefit?.benefitAmount! ?? null
              );
              // If the remaining oop benefit is YTD, then normalize to remaining by subtracting from max
              if (
                outOfPocketMax &&
                isDefined(outOfPocketRemaining) &&
                outOfPocketRemainingBenefit.timeQualifier == "Year to Date"
              ) {
                outOfPocketRemaining = outOfPocketMax - outOfPocketRemaining;
              }

              const copayBenefits = coverageLevelBenefits.filter(
                (benefit) => benefit.code === BenefitCode.CoPayment
              );
              const coinsuranceBenefits = coverageLevelBenefits.filter(
                (benefit) => benefit.code === BenefitCode.CoInsurance
              );
              const maxLimitationBenefits = coverageLevelBenefits.filter(
                (benefit) =>
                  benefit.code === BenefitCode.Limitations &&
                  isMaxBenefit(benefit)
              );
              const remainingLimitationBenefits = coverageLevelBenefits.filter(
                (benefit) =>
                  benefit.code === BenefitCode.Limitations &&
                  isRemainingBenefit(benefit)
              );
              const limitationPairs = matchLimitations(
                remainingLimitationBenefits,
                maxLimitationBenefits
              );

              // If no benefits, skip the row
              if (
                !isDefined(deductibleRemaining) &&
                !deductibleMax &&
                !isDefined(outOfPocketRemaining) &&
                !outOfPocketMax &&
                copayBenefits.length === 0 &&
                coinsuranceBenefits.length === 0 &&
                limitationPairs.length === 0
              )
                return null;

              const coverageLevel = coverageLevelBenefits[0].coverageLevel;
              const limitationPlanCoverages = Array.from(
                new Set(
                  limitationPairs
                    .flatMap((pair) => pair.map((b) => b?.planCoverage))
                    .filter(isDefined)
                )
              );
              return (
                <tr
                  key={coverageLevelCode}
                  className="divide-x divide-gray-200"
                >
                  <td className="p-2 w-[20%]">
                    {coverageLevel
                      ? coverageLevel
                      : limitationPlanCoverages.map((pc) => (
                          <Badge text={pc} variant="info" />
                        ))}
                  </td>
                  <td
                    className={cn(
                      "px-2 py-4",
                      columns === 3 && "w-[40%]",
                      columns === 4 && "w-[35%]",
                      columns === 5 && "w-[30%]"
                    )}
                  >
                    {isDefined(deductibleRemaining) && deductibleMax ? (
                      <div>
                        <ProgressBar
                          percentage={
                            (deductibleMax - deductibleRemaining) /
                            deductibleMax
                          }
                        />
                        <div className="flex justify-between pt-1">
                          <div className="text-sm">
                            {usdFormatter.format(deductibleMax)}
                            {isDefined(deductibleMaxBenefit.timeQualifier) && (
                              <> / {deductibleMaxBenefit.timeQualifier}</>
                            )}
                          </div>
                          <div className="text-sm">
                            {usdFormatter.format(deductibleRemaining)} remaining
                          </div>
                        </div>
                      </div>
                    ) : deductibleMax ? (
                      <div>
                        {usdFormatter.format(deductibleMax)}
                        {isDefined(deductibleMaxBenefit.timeQualifier) && (
                          <> / {deductibleMaxBenefit.timeQualifier}</>
                        )}
                      </div>
                    ) : (
                      <div className="text-center">-</div>
                    )}
                  </td>
                  <td
                    className={cn(
                      "px-2 py-4",
                      columns === 3 && "w-[40%]",
                      columns === 4 && "w-[35%]",
                      columns === 5 && "w-[30%]",
                      columns === 6 && "w-[25%]"
                    )}
                  >
                    {isDefined(outOfPocketRemaining) && outOfPocketMax ? (
                      <div>
                        <ProgressBar
                          percentage={
                            (outOfPocketMax - outOfPocketRemaining) /
                            outOfPocketMax
                          }
                        />
                        <div className="flex justify-between pt-1">
                          <div className="text-sm">
                            {usdFormatter.format(outOfPocketMax)}
                            {isDefined(outOfPocketMaxBenefit.timeQualifier) && (
                              <> / {outOfPocketMaxBenefit.timeQualifier}</>
                            )}
                          </div>
                          <div className="text-sm">
                            {usdFormatter.format(outOfPocketRemaining)}{" "}
                            remaining
                          </div>
                        </div>
                      </div>
                    ) : outOfPocketMax ? (
                      <div>
                        {usdFormatter.format(outOfPocketMax)}
                        {isDefined(outOfPocketMaxBenefit.timeQualifier) && (
                          <> / {outOfPocketMaxBenefit.timeQualifier}</>
                        )}
                      </div>
                    ) : (
                      <div className="text-center">-</div>
                    )}
                  </td>
                  {anyCopay && (
                    <td className="px-2 py-4 w-[10%] text-center">
                      {copayBenefits
                        .filter((b) => isDefined(b.benefitAmount))
                        .map((b) => (
                          <div>
                            {mapNullable((amount: string) =>
                              usdFormatter.format(parseFloat(amount))
                            )(b.benefitAmount)}
                          </div>
                        ))}
                    </td>
                  )}
                  {anyCoinsurance && (
                    <td className="px-2 py-4 w-[10%] text-center">
                      {coinsuranceBenefits
                        .filter((b) => isDefined(b.benefitPercent))
                        .map((b) => (
                          <div>
                            {mapNullable((amount: string) =>
                              formatPercentage(parseFloat(amount))
                            )(b.benefitPercent)}
                          </div>
                        ))}
                    </td>
                  )}
                  {anyLimitations && (
                    <td className="px-2 py-4 w-[10%] text-center">
                      {limitationPairs.map(([remaining, max], i) => {
                        const remainingQuantity =
                          remaining?.benefitQuantity ??
                          remaining?.benefitsServiceDelivery?.at(0)?.quantity;
                        const maxQuantity =
                          max?.benefitQuantity ??
                          max?.benefitsServiceDelivery?.at(0)?.quantity;
                        const remainingQuantityQualifier =
                          remaining?.quantityQualifier ??
                          remaining?.benefitsServiceDelivery?.at(0)
                            ?.quantityQualifier;
                        const maxQuantityQualifier =
                          max?.quantityQualifier ??
                          max?.benefitsServiceDelivery?.at(0)
                            ?.quantityQualifier;
                        const remainingTimeQualifier =
                          remaining?.timeQualifier ??
                          remaining?.benefitsServiceDelivery?.at(0)
                            ?.timePeriodQualifier;
                        const maxTimeQualifier =
                          max?.timeQualifier ??
                          max?.benefitsServiceDelivery?.at(0)
                            ?.timePeriodQualifier;
                        const quantityLimitation =
                          isDefined(remainingQuantity) ||
                          isDefined(maxQuantity) ? (
                            <div>
                              {isDefined(remainingQuantity) &&
                                remainingQuantity}
                              {isDefined(remainingTimeQualifier) && (
                                <span className="text-gray-500">
                                  {" "}
                                  {remainingTimeQualifier}
                                </span>
                              )}
                              {isDefined(remainingQuantity) &&
                                isDefined(maxQuantity) &&
                                ", "}
                              {isDefined(maxQuantity) && maxQuantity}
                              {isDefined(maxQuantityQualifier) && (
                                <span className="text-gray-500">
                                  {" "}
                                  {maxQuantityQualifier}
                                </span>
                              )}
                              {isDefined(maxTimeQualifier) && (
                                <span className="text-gray-500">
                                  {" "}
                                  / {maxTimeQualifier}
                                </span>
                              )}
                            </div>
                          ) : null;
                        const amountLimitation =
                          isDefined(remaining?.benefitAmount) ||
                          isDefined(max?.benefitAmount) ? (
                            <div>
                              {isDefined(remaining?.benefitAmount) &&
                                usdFormatter.format(
                                  parseFloat(remaining!.benefitAmount)
                                )}
                              {isDefined(remaining?.timeQualifier) && (
                                <span className="text-gray-500">
                                  {" "}
                                  {remaining?.timeQualifier}
                                </span>
                              )}
                              {isDefined(remaining?.benefitAmount) &&
                                isDefined(max?.benefitAmount) &&
                                ", "}
                              {isDefined(max?.benefitAmount) &&
                                usdFormatter.format(
                                  parseFloat(max!.benefitAmount)
                                )}
                              {isDefined(max?.quantityQualifier) && (
                                <span className="text-gray-500">
                                  {" "}
                                  {max?.quantityQualifier}
                                </span>
                              )}
                              {isDefined(max?.timeQualifier) && (
                                <span className="text-gray-500">
                                  {" "}
                                  / {max?.timeQualifier}
                                </span>
                              )}
                            </div>
                          ) : null;
                        const percentageLimitation =
                          isDefined(remaining?.benefitPercent) ||
                          isDefined(max?.benefitPercent) ? (
                            <div>
                              {isDefined(remaining?.benefitPercent) &&
                                usdFormatter.format(
                                  parseFloat(remaining!.benefitPercent)
                                )}
                              {isDefined(remaining?.timeQualifier) && (
                                <span className="text-gray-500">
                                  {" "}
                                  {remaining?.timeQualifier}
                                </span>
                              )}
                              {isDefined(remaining?.benefitPercent) &&
                                isDefined(max?.benefitPercent) &&
                                ", "}
                              {isDefined(max?.benefitPercent) &&
                                usdFormatter.format(
                                  parseFloat(max!.benefitPercent)
                                )}
                              {isDefined(max?.quantityQualifier) && (
                                <span className="text-gray-500">
                                  {" "}
                                  {max?.quantityQualifier}
                                </span>
                              )}
                              {isDefined(max?.timeQualifier) && (
                                <span className="text-gray-500">
                                  {" "}
                                  / {max?.timeQualifier}
                                </span>
                              )}
                            </div>
                          ) : null;

                        return (
                          <div className="flex flex-col gap-1 divide-y">
                            {quantityLimitation}
                            {amountLimitation}
                            {percentageLimitation}
                          </div>
                        );
                      })}
                    </td>
                  )}
                </tr>
              );
            })}
        </table>
      )}
      {activeCoverageBenefits.map((benefit) => {
        const benefitAdditionalInfo = benefit?.additionalInformation ?? [];
        const generateAuthBadge = (authOrCertIndicator: string) => {
          if (authOrCertIndicator === "Y") {
            return <Badge text="Auth Required" variant="error" />;
          } else if (authOrCertIndicator === "N") {
            return <Badge text="No Auth Required" variant="success" />;
          } else if (authOrCertIndicator === "W") {
            return <Badge text="Auth Not Applicable" variant="info" />;
          } else if (authOrCertIndicator === "U") {
            return <Badge text="Auth Requirement Unknown" variant="info" />;
          }
        };
        return (
          (benefit.description || benefitAdditionalInfo.length > 0) && (
            <div>
              <div className="flex items-center gap-1 pb-1">
                <p className="text-sm">{benefit.description}</p>
                <Badge text="Active Coverage" variant="success" />
                {benefit.authOrCertIndicator
                  ? generateAuthBadge(benefit.authOrCertIndicator)
                  : null}
                {benefit.inPlanNetworkIndicator ? (
                  <NetworkStatusBadge
                    networkStatus={benefit.inPlanNetworkIndicator}
                  />
                ) : null}
                {benefit.planCoverage && (
                  <Badge text={benefit.planCoverage} variant="info" />
                )}
                {benefit.insuranceType && (
                  <Badge text={benefit.insuranceType} variant="info" />
                )}
              </div>
              {benefitAdditionalInfo.length > 0 && (
                <ul className="list-outside list-disc text-gray-700 text-sm">
                  {benefitAdditionalInfo.map((note, i) => (
                    <li key={i} className="ml-4 break-words">
                      {note.description}
                    </li>
                  ))}
                </ul>
              )}
              {benefit?.benefitsRelatedEntities?.map(
                (benefitsRelatedEntity) => (
                  <div className="pt-1">
                    <ServiceBenefitRelatedEntityDisplay
                      name={benefitsRelatedEntity.entityName}
                      entity={benefitsRelatedEntity}
                    />
                  </div>
                )
              )}
            </div>
          )
        );
      })}
      {nonTableBenefits.map((benefits, i) => {
        const {
          networkStatus,
          nonCoveredBenefit,
          inactiveBenefit,
          coverageLevel,
          placeOfService,
          notes,
          benefitRelatedEntities,
        } = benefits;
        return (
          <div
            key={i}
            className="p-4 rounded-md border bg-slate-100 grid grid-cols-2"
          >
            <div className="p-2">
              <div className="flex gap-1 flex-wrap">
                {nonCoveredBenefit && (
                  <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                    Non Covered
                  </span>
                )}
                {inactiveBenefit && (
                  <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10">
                    Inactive
                  </span>
                )}
                {networkStatus && (
                  <span className="truncate inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                    Network Status: {networkStatus}
                  </span>
                )}
                {coverageLevel && (
                  <span className="truncate inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                    Coverage Level: {coverageLevel}
                  </span>
                )}
                {placeOfService && (
                  <span className="truncate inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                    Place of Service: {placeOfService}
                  </span>
                )}
              </div>
            </div>
            <div className="p-2">
              {notes.length > 0 && (
                <ul className="list-outside list-disc text-gray-700 text-sm">
                  {notes.map((note, i) => (
                    <li key={i} className="ml-4 break-words">
                      {note}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              {benefitRelatedEntities.map(({ name, benefitsRelatedEntity }) => (
                <div className="pt-1">
                  <ServiceBenefitRelatedEntityDisplay
                    name={name}
                    entity={benefitsRelatedEntity}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </Card>
  );
};

// TODO: Move to a utils file
async function copyTextToClipboard(text: string) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export const CopyToClipboardButton: React.FC<
  React.PropsWithChildren<{ copyText: string }>
> = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className="hover:bg-gray-200 rounded-md p-0.5"
      onClick={() => handleCopyClick()}
    >
      <Tooltip
        content={isCopied ? <> Copied!</> : <>Copy to clipboard</>}
        trigger={
          isCopied ? (
            <CheckIcon className="inline-block w-4 h-4 text-green-600" />
          ) : (
            <DuplicateIcon className="inline-block w-4 h-4 text-gray-600" />
          )
        }
      />
    </div>
  );
};

const ContactDisplay: React.FC<
  React.PropsWithChildren<{
    contact: Contacts;
  }>
> = ({ contact }) => {
  if (!contact.communicationNumber) return null;

  // List from: https://www.stedi.com/edi/x12-005010/element/365
  switch (contact.communicationMode) {
    case "Uniform Resource Locator (URL)":
      return (
        <div className="flex gap-2 items-center">
          <div className="flex">
            <Tooltip
              content={contact.communicationMode}
              trigger={<LinkIcon className="inline-block w-4 h-4 mr-1" />}
            />
            <a
              href={contact.communicationNumber}
              className="text-sm text-indigo-600 hover:text-indigo-500"
            >
              {contact.communicationNumber.toLowerCase()}
            </a>
          </div>
          <div className="flex gap-1">
            <CopyToClipboardButton
              copyText={contact.communicationNumber.toLowerCase()}
            />
          </div>
        </div>
      );
    case "Electronic Mail":
      return (
        <div className="flex gap-2 items-center">
          <div className="flex">
            <Tooltip
              content={contact.communicationMode}
              trigger={<MailIcon className="inline-block w-4 h-4 mr-1" />}
            />
            <a
              href={`mailto:${contact.communicationNumber}`}
              className="text-sm text-indigo-600 hover:text-indigo-500"
            >
              {contact.communicationNumber.toLowerCase()}
            </a>
          </div>
          <div className="flex gap-1">
            <CopyToClipboardButton
              copyText={contact.communicationNumber.toLowerCase()}
            />
          </div>
        </div>
      );
    case "International Telephone Access Code":
    case "Joint Facsimile and Phone Number":
    case "Message Only Voice Number":
    case "Alternate Telephone":
    case "Cellular Phone":
    case "Home Phone Number":
    case "International Telephone":
    case "Night Telephone":
    case "Other Residential Telephone Number":
    case "Appointment Phone":
    case "Personal Cellular":
    case "Personal Phone":
    case "Showing Phone":
    case "Work Cellular":
    case "Work Phone Number":
    case "Telephone":
      return (
        <div className="flex gap-2 items-center">
          <div>
            <Tooltip
              content={contact.communicationMode}
              trigger={<PhoneIcon className="inline-block w-4 h-4 mr-1" />}
            />
            <a
              href={`tel:${contact.communicationNumber}`}
              className="text-sm text-indigo-600 hover:text-indigo-500"
            >
              {formatPhoneNumber(contact.communicationNumber)}
            </a>
          </div>
          <div className="flex gap-1">
            <CopyToClipboardButton
              copyText={
                formatPhoneNumber(contact.communicationNumber) ??
                contact.communicationNumber
              }
            />
          </div>
        </div>
      );
    case "Home Facsimile Number":
    case "Other Residential Facsimile Number":
    case "Work Facsimile Number":
    case "Facsimile":
      return (
        <div className="flex gap-2 items-center">
          <div>
            <Tooltip
              content="Fax"
              trigger={<PrinterIcon className="inline-block w-4 h-4 mr-1" />}
            />
            <a
              href={`tel:${contact.communicationNumber}`}
              className="text-sm text-indigo-600 hover:text-indigo-500"
            >
              {formatPhoneNumber(contact.communicationNumber)}
            </a>
          </div>
          <div className="flex gap-1">
            <CopyToClipboardButton
              copyText={
                formatPhoneNumber(contact.communicationNumber) ??
                contact.communicationNumber
              }
            />
          </div>
        </div>
      );
  }
  return (
    <>
      {contact.communicationMode}: {contact.communicationNumber}
    </>
  );
};

/**
 * Converts a YYYYMMDDD formatted date into a MM/DD/YYYY formatted date
 */
const formatDate = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  return `${month}/${day}/${year}`;
};

/**
 * e.g. 20230101-99991231 -> 01/01/2023 - 12/31/9999
 */
const formatDateOrRange = (date: string) => {
  if (date.includes("-")) {
    const [start, end] = date.split("-");
    return `${formatDate(start)} - ${formatDate(end)}`;
  }
  return formatDate(date);
};

const ServiceBenefitRelatedEntityDisplay: React.FC<
  React.PropsWithChildren<{
    name?: string;
    entity: BenefitsRelatedEntity;
  }>
> = ({ entity }) => {
  const contacts = entity.contactInformation?.contacts;
  return (
    <div className="flex flex-col">
      <div className="text-sm font-medium text-gray-500">
        Name: {entity.entityName}
      </div>
      <div className="text-sm font-medium text-gray-500">
        Type: {entity.entityIdentifier}
      </div>
      {contacts && contacts.length > 0 && (
        <div className="mt-1 text-sm text-gray-900 col-span-2">
          <div>Contact Information</div>
          <ul className="flex flex-col gap-1">
            {entity.contactInformation?.contacts?.map((c) => (
              <ContactDisplay contact={c} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const REMAINING_QUANTITY_QUALIFIER_CODES = [QuantityQualifierCode.QuantityUsed];

const MAX_TIME_PERIOD_CODES = [
  TimePeriodQualifierCode.CalendarYear,
  TimePeriodQualifierCode.Lifetime,
  TimePeriodQualifierCode.ServiceYear,
  TimePeriodQualifierCode.Contract,
];

const REMAINING_TIME_PERIOD_CODES = [
  TimePeriodQualifierCode.Remaining,
  TimePeriodQualifierCode.LifetimeRemaining,
];

const MAX_QUANTITY_QUALIFIER_CODES = [QuantityQualifierCode.QuantityApproved];

export const isMaxBenefit = (benefit: BenefitsInformation) => {
  const quantityQualifierCode = benefit.quantityQualifierCode;
  const isMaxQuantity =
    quantityQualifierCode &&
    MAX_QUANTITY_QUALIFIER_CODES.includes(
      quantityQualifierCode as QuantityQualifierCode
    );

  if (benefit.timeQualifierCode !== undefined) {
    return (
      MAX_TIME_PERIOD_CODES.includes(benefit.timeQualifierCode as any) ||
      isMaxQuantity
    );
  }
  if (
    benefit.benefitsServiceDelivery !== undefined &&
    benefit.benefitsServiceDelivery.length > 0
  ) {
    return (
      MAX_TIME_PERIOD_CODES.includes(
        benefit.benefitsServiceDelivery[0].timePeriodQualifierCode as any
      ) || isMaxQuantity
    );
  }
  // Explicitly consider benefit with a benefitAmoutn but missing
  // timeQualifierCode as a max benefit
  if (
    benefit.timeQualifierCode === undefined &&
    isDefined(benefit.benefitAmount)
  ) {
    return true;
  }
  return false;
};

export const isRemainingBenefit = (benefit: BenefitsInformation) => {
  const isMax = isMaxBenefit(benefit);
  if (isMax) return false;
  const quantityQualifierCode = benefit.quantityQualifierCode;
  const isRemainingQuantity =
    quantityQualifierCode &&
    REMAINING_QUANTITY_QUALIFIER_CODES.includes(
      quantityQualifierCode as QuantityQualifierCode
    );

  let isRemaining = false;
  if (benefit.timeQualifierCode !== undefined) {
    isRemaining = REMAINING_TIME_PERIOD_CODES.includes(
      benefit.timeQualifierCode as any
    );
  } else if (
    benefit.benefitsServiceDelivery !== undefined &&
    benefit.benefitsServiceDelivery.length > 0
  ) {
    isRemaining = REMAINING_TIME_PERIOD_CODES.includes(
      benefit.benefitsServiceDelivery[0].timePeriodQualifierCode as any
    );
  }
  return isRemaining || isRemainingQuantity;
};

const isLimitationMatch = (a: BenefitsInformation, b: BenefitsInformation) => {
  if (
    // If both have the same quantity qualifier AND
    (a.quantityQualifier === b.quantityQualifier &&
      // If both have a benefitQuantity present
      a.benefitQuantity !== null &&
      b.benefitQuantity !== null) ||
    // If both have a benefitAmount present
    (a.benefitAmount !== null && b.benefitAmount !== null) ||
    // If both have a benefitPercent present
    (a.benefitPercent !== null && b.benefitPercent !== null)
  ) {
    return true;
  }

  const aBenefitServiceDelivery = a.benefitsServiceDelivery?.at(0);
  const bBenefitServiceDelivery = b.benefitsServiceDelivery?.at(0);

  if (!aBenefitServiceDelivery || !bBenefitServiceDelivery) return false;

  if (
    // If both have the same quantity qualifier AND
    aBenefitServiceDelivery.quantityQualifier ===
      bBenefitServiceDelivery.quantityQualifier &&
    // If both have a quantity present
    aBenefitServiceDelivery.quantity !== null &&
    bBenefitServiceDelivery.quantity !== null
  ) {
    return true;
  }
};

function matchLimitations(
  _remainingLimitations: BenefitsInformation[],
  _maxLimitations: BenefitsInformation[]
): Array<[BenefitsInformation | null, BenefitsInformation | null]> {
  let remainingLimitations = _remainingLimitations.slice();
  let maxLimitations = _maxLimitations.slice();

  const matchedPairs: Array<
    [BenefitsInformation | null, BenefitsInformation | null]
  > = [];

  while (remainingLimitations.length > 0) {
    const remaining = remainingLimitations.pop()!;
    let anyMatched = false;
    for (const max of maxLimitations) {
      let matched = false;

      // if (
      //   // If both have the same quantity qualifier AND
      //   (remaining.quantityQualifier === max.quantityQualifier &&
      //     // If both have a benefitQuantity present
      //     remaining.benefitQuantity !== null &&
      //     max.benefitQuantity !== null) ||
      //   // If both have a benefitAmount present
      //   (remaining.benefitAmount !== null && max.benefitAmount !== null)
      // ) {
      if (isLimitationMatch(remaining, max)) {
        matchedPairs.push([remaining, max]);
        maxLimitations.splice(maxLimitations.indexOf(max), 1);
        anyMatched = true;
        matched = true;
        continue;
      }
    }
    // If no matches, add unmatched pair
    if (!anyMatched) {
      matchedPairs.push([remaining, null]);
    }
  }

  // Add leftover unmatched maxLimitations
  for (const max of maxLimitations) {
    matchedPairs.push([null, max]);
  }

  return matchedPairs;
}

const getNormalizedBenefits = (benefits: BenefitsInformation[]) => {
  const networkStatus = benefits.at(0)?.inPlanNetworkIndicator;
  const coverageLevel = benefits.at(0)?.coverageLevel;
  const placeOfService =
    benefits.at(0)?.eligibilityAdditionalInformation?.industryCode;
  const planCoverage = benefits.at(0)?.planCoverage;
  const [copayBenefit] = benefits.filter(
    (benefit) => benefit.code === BenefitCode.CoPayment
  );
  const [coinsuranceBenefit] = benefits.filter(
    (benefit) => benefit.code === BenefitCode.CoInsurance
  );
  const [maxLimitationBenefit] = benefits.filter(
    (benefit) =>
      benefit.code === BenefitCode.Limitations && isMaxBenefit(benefit)
  );
  const [remainingLimitationBenefit] = benefits.filter(
    (benefit) =>
      benefit.code === BenefitCode.Limitations && isRemainingBenefit(benefit)
  );

  const maxLimitationBenefits = benefits.filter(
    (benefit) =>
      benefit.code === BenefitCode.Limitations && isMaxBenefit(benefit)
  );
  const remainingLimitationBenefits = benefits.filter(
    (benefit) =>
      benefit.code === BenefitCode.Limitations && isRemainingBenefit(benefit)
  );
  const limitationPairs = matchLimitations(
    remainingLimitationBenefits,
    maxLimitationBenefits
  );
  const otherLimitations = benefits.filter(
    (benefit) =>
      benefit.code === BenefitCode.Limitations &&
      !isRemainingBenefit(benefit) &&
      !isMaxBenefit(benefit)
  );
  const [remainingDeductibleBenefit] = benefits.filter(
    (benefit) =>
      benefit.code === BenefitCode.Deductible && isRemainingBenefit(benefit)
  );
  const [maxDeductibleBenefit] = benefits.filter(
    (benefit) =>
      benefit.code === BenefitCode.Deductible && isMaxBenefit(benefit)
  );
  const [nonCoveredBenefit] = benefits.filter(
    (benefit) => benefit.code === BenefitCode.NonCovered
  );
  const [inactiveBenefit] = benefits.filter(
    (benefit) => benefit.code === BenefitCode.Inactive
  );
  // Get all deduplicated notes
  const notes = Array.from(
    new Set(
      benefits.flatMap(
        (benefit) =>
          benefit.additionalInformation
            ?.map((info) => info.description)
            ?.filter(isDefined) ?? []
      )
    )
  );
  // Get all unique planNetworkIdNumbers
  const planNetworkIdNumbers = Array.from(
    new Set(
      benefits
        .map(
          (benefit) =>
            benefit.benefitsAdditionalInformation?.planNetworkIdNumber
        )
        .filter(isDefined)
    )
  );
  const planNetworkNames = Array.from(
    new Set(
      benefits
        .map(
          (benefit) => benefit.benefitsAdditionalInformation?.planNetworkName
        )
        .filter(isDefined)
    )
  );

  const contactInformation = benefits.flatMap(
    (benefit) => benefit.contactInformation ?? []
  );

  const copay = mapNullable(parseFloat)(copayBenefit?.benefitAmount);
  const coinsurance = mapNullable(parseFloat)(
    coinsuranceBenefit?.benefitPercent
  );
  const remainingLimitation = mapNullable(parseInt)(
    remainingLimitationBenefit?.benefitQuantity ??
      remainingLimitationBenefit?.benefitsServiceDelivery?.at(0)?.quantity
  );
  const maxLimitation = mapNullable(parseInt)(
    maxLimitationBenefit?.benefitQuantity ??
      maxLimitationBenefit?.benefitsServiceDelivery?.at(0)?.quantity
  );
  const remainingDeductible = mapNullable(parseFloat)(
    remainingDeductibleBenefit?.benefitAmount
  );
  const maxDeductible = mapNullable(parseFloat)(
    maxDeductibleBenefit?.benefitAmount
  );
  const authOrCertIndicator = benefits.find(
    (b) => b.authOrCertIndicator
  )?.authOrCertIndicator;

  const benefitRelatedEntities = Array.from(
    new Set(
      benefits
        .filter((benefit) => benefit.benefitsRelatedEntities)
        .flatMap((filteredBenefit) =>
          filteredBenefit.benefitsRelatedEntities!.map(
            (benefitsRelatedEntity) => ({
              name: filteredBenefit.name,
              benefitsRelatedEntity,
            })
          )
        )
    )
  );

  // If the benefit has any info that would be displayed in the table
  const hasTableBenefits =
    isDefined(copay) ||
    isDefined(coinsurance) ||
    isDefined(remainingLimitation) ||
    isDefined(maxLimitation) ||
    isDefined(remainingDeductible) ||
    isDefined(maxDeductible) ||
    isDefined(authOrCertIndicator) ||
    limitationPairs.length > 0;

  return {
    hasTableBenefits,
    networkStatus,
    coverageLevel,
    placeOfService,
    copay,
    coinsurance,
    remainingLimitation,
    maxLimitation,
    otherLimitations,
    remainingDeductible,
    maxDeductible,
    nonCoveredBenefit,
    inactiveBenefit,
    notes,
    planNetworkIdNumbers,
    planNetworkNames,
    authOrCertIndicator,
    remainingLimitationBenefit,
    remainingDeductibleBenefit,
    maxDeductibleBenefit,
    maxLimitationBenefit,
    copayBenefit,
    coinsuranceBenefit,
    limitationPairs,
    benefitRelatedEntities,
    planCoverage,
    contactInformation,
  };
};

const NetworkStatusBadge: React.FC<
  React.PropsWithChildren<{ networkStatus: string }>
> = ({ networkStatus }) => {
  if (networkStatus === "Yes") {
    return <Badge variant="success" text="In Network" />;
  }
  if (networkStatus === "No") {
    return <Badge variant="error" text="Out of Network" />;
  }
  if (networkStatus === "Not Applicable") {
    return <Badge variant="info" text="Network Not Applicable" />;
  }
  return <Badge variant="info" text={`Network Status: ${networkStatus}`} />;
};

const BenefitDetails: React.FC<
  React.PropsWithChildren<{
    serviceType: string;
    benefits: ServiceTypeBenefits;
  }>
> = ({ serviceType, benefits: { benefits, serviceTypeDisplay } }) => {
  const [activeCoverageBenefit] = benefits[BenefitCode.ActiveCoverage] ?? [];
  const hasNonCoveredBenefit =
    (benefits[BenefitCode.NonCovered]?.length ?? 0) > 0;
  const benefitObjects = Object.values(benefits).flat();

  const groupedBenefits = benefitObjects.reduce(
    (accum: { [key: string]: BenefitsInformation[] }, benefit) => {
      // Don't add active coverage benefits to the group
      const activeCoverageBenefit = benefit.code === BenefitCode.ActiveCoverage;
      //   const nonCoveredBenefit = benefit.code === BenefitCode.NonCovered;
      //   if (activeCoverageBenefit || nonCoveredBenefit) return accum;
      if (activeCoverageBenefit) return accum;

      const notes =
        benefit.additionalInformation
          ?.map((info) => info.description)
          ?.filter((note) => {
            if (!isDefined(note)) return false;
            // Filter out "deductible does not apply" notes from key
            const isDeductibleDoesNotApplyNote = note
              .toLocaleLowerCase()
              .includes("deductible does not apply");
            return !isDeductibleDoesNotApplyNote;
          }) ?? [];
      const planNetworkIdNumber =
        benefit.benefitsAdditionalInformation?.planNetworkIdNumber ?? "";

      const key = `${benefit.coverageLevel}-${
        benefit.eligibilityAdditionalInformation?.industryCode
      }-${notes.join("|")}-${planNetworkIdNumber}`;
      if (!accum[key]) accum[key] = [];
      accum[key].push(benefit);
      return accum;
    },
    {}
  );

  const nonCoverageBenefits = benefitObjects.filter(
    (benefit) =>
      benefit.code !== BenefitCode.ActiveCoverage &&
      benefit.code !== BenefitCode.NonCovered
  );

  const normalizedBenefits = Object.values(groupedBenefits).map((benefits) =>
    getNormalizedBenefits(benefits)
  );

  const tableBenefits = normalizedBenefits.filter((b) => b.hasTableBenefits);
  const nonTableBenefits = normalizedBenefits.filter(
    (b) => !b.hasTableBenefits
  );

  const activeCoverageBenefits = benefits[BenefitCode.ActiveCoverage] ?? [];

  return (
    <Card
      id={serviceType}
      className="flex flex-col gap-2 bg-white overflow-x-auto"
    >
      <div className="flex items-center gap-1 pb-1">
        <h2 className="font-medium text-lg">{serviceTypeDisplay}</h2>
        {activeCoverageBenefit ? (
          <Badge text="Active Coverage" variant="success" />
        ) : hasNonCoveredBenefit ? (
          <Badge text="Non Covered" variant="warning" />
        ) : null}
      </div>
      {tableBenefits.length > 0 && (
        <table className="divide-y divide-gray-200">
          <tr className="divide-x divide-gray-200 text-gray-500">
            <th>Details</th>
            <th>Copay</th>
            <th>Coinsurance</th>
            <th>Deductible</th>
            <th>Limitation</th>
            <th>Authorization</th>
          </tr>
          {tableBenefits.map((benefit, i) => {
            const {
              networkStatus,
              coverageLevel,
              placeOfService,
              copay,
              coinsurance,
              remainingDeductible,
              maxDeductible,
              notes,
              planNetworkIdNumbers,
              planNetworkNames,
              authOrCertIndicator,
              limitationPairs,
              copayBenefit,
              planCoverage,
              contactInformation,
            } = benefit;
            let authDisplay;
            if (authOrCertIndicator === "Y") {
              authDisplay = (
                <span className="truncate inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                  Auth Required
                </span>
              );
            } else if (authOrCertIndicator === "N") {
              authDisplay = (
                <span className="truncate inline-flex items-center rounded-md bg-slate-100 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-600/10">
                  No Auth Required
                </span>
              );
            } else if (authOrCertIndicator === "W") {
              authDisplay = (
                <span className="truncate inline-flex items-center rounded-md bg-slate-100 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-600/10">
                  Auth Not Applicable
                </span>
              );
            } else if (authOrCertIndicator === "U") {
              authDisplay = (
                <span className="truncate inline-flex items-center rounded-md bg-slate-100 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-600/10">
                  Auth Requirement Unknown
                </span>
              );
            }

            return (
              <tr key={i} className="divide-x divide-gray-200">
                <td className="p-2 max-w-sm">
                  <div className="divide-y divide-gray-200 space-y-1">
                    <div className="flex gap-1 flex-wrap pt-1">
                      {networkStatus && (
                        <NetworkStatusBadge networkStatus={networkStatus} />
                      )}
                      {coverageLevel && (
                        <span className="truncate inline-flex items-center rounded-md bg-slate-100 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                          <span className="font-light mr-1">
                            Coverage Level:
                          </span>
                          {coverageLevel}
                        </span>
                      )}
                      {placeOfService && (
                        <span className="truncate inline-flex items-center rounded-md bg-slate-100 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                          <span className="font-light mr-1">
                            Place of Service:
                          </span>
                          {placeOfService}
                        </span>
                      )}
                      {planCoverage && (
                        <span className="truncate inline-flex items-center rounded-md bg-slate-100 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                          <span className="font-light mr-1">
                            Plan Coverage:
                          </span>
                          {planCoverage}
                        </span>
                      )}
                    </div>
                    {(notes.length > 0 ||
                      planNetworkIdNumbers.length > 0 ||
                      planNetworkNames.length > 0) && (
                      <ul className="list-outside list-disc text-gray-700 text-sm pt-1">
                        {notes.map((note, i) => (
                          <li key={i} className="ml-4 break-words">
                            {note}
                          </li>
                        ))}
                        {planNetworkIdNumbers.map((idNumber, i) => (
                          <li key={i} className="ml-4 break-words">
                            Plan Network Id: {idNumber}
                          </li>
                        ))}
                        {planNetworkNames.map((planNetworkName, i) => (
                          <li key={i} className="ml-4 break-words">
                            Plan Network Name: {planNetworkName}
                          </li>
                        ))}
                      </ul>
                    )}
                    {benefit.benefitRelatedEntities.map(
                      ({ name, benefitsRelatedEntity }) => (
                        <div className="pt-1">
                          <ServiceBenefitRelatedEntityDisplay
                            name={name}
                            entity={benefitsRelatedEntity}
                          />
                        </div>
                      )
                    )}
                  </div>
                </td>
                <td className="p-2 text-center">
                  {isDefined(copay) ? (
                    <div>
                      <div>
                        {usdFormatter.format(copay)}
                        {isDefined(copayBenefit.timeQualifier) ? (
                          <span className="text-gray-500">
                            {" "}
                            / {copayBenefit.timeQualifier}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {isDefined(copayBenefit.benefitQuantity) && (
                        <>
                          {copayBenefit.benefitQuantity}
                          {isDefined(copayBenefit.quantityQualifier) ? (
                            <span className="text-gray-500">
                              {" "}
                              {copayBenefit.quantityQualifier}
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="p-2 text-center">
                  {isDefined(coinsurance) ? formatPercentage(coinsurance) : "-"}
                </td>
                <td className="p-2 text-center">
                  {isDefined(remainingDeductible) &&
                  isDefined(maxDeductible) ? (
                    <>
                      {usdFormatter.format(remainingDeductible)}/
                      {usdFormatter.format(maxDeductible)}
                    </>
                  ) : isDefined(maxDeductible) ? (
                    usdFormatter.format(maxDeductible)
                  ) : (
                    <HashLink
                      smooth
                      to={`#30`}
                      className="text-sm text-indigo-600 hover:text-indigo-500"
                    >
                      See Health Benefit Plan Coverage
                    </HashLink>
                  )}
                </td>
                <td className="p-2 text-center">
                  {limitationPairs.length === 0 && <>-</>}
                  {limitationPairs.map(([remaining, max], i) => {
                    const remainingQuantity =
                      remaining?.benefitQuantity ??
                      remaining?.benefitsServiceDelivery?.at(0)?.quantity;
                    const maxQuantity =
                      max?.benefitQuantity ??
                      max?.benefitsServiceDelivery?.at(0)?.quantity;
                    const remainingQuantityQualifier =
                      remaining?.quantityQualifier ??
                      remaining?.benefitsServiceDelivery?.at(0)
                        ?.quantityQualifier;
                    const maxQuantityQualifier =
                      max?.quantityQualifier ??
                      max?.benefitsServiceDelivery?.at(0)?.quantityQualifier;
                    const remainingTimeQualifier =
                      remaining?.timeQualifier ??
                      remaining?.benefitsServiceDelivery?.at(0)
                        ?.timePeriodQualifier;
                    const maxTimeQualifier =
                      max?.timeQualifier ??
                      max?.benefitsServiceDelivery?.at(0)?.timePeriodQualifier;
                    const quantityLimitation =
                      isDefined(remainingQuantity) || isDefined(maxQuantity) ? (
                        <div>
                          {isDefined(remainingQuantity) && remainingQuantity}
                          {isDefined(remainingTimeQualifier) && (
                            <span className="text-gray-500">
                              {" "}
                              {remainingTimeQualifier}
                            </span>
                          )}
                          {isDefined(remainingQuantity) &&
                            isDefined(maxQuantity) &&
                            ", "}
                          {isDefined(maxQuantity) && maxQuantity}
                          {isDefined(maxQuantityQualifier) && (
                            <span className="text-gray-500">
                              {" "}
                              {maxQuantityQualifier}
                            </span>
                          )}
                          {isDefined(maxTimeQualifier) && (
                            <span className="text-gray-500">
                              {" "}
                              / {maxTimeQualifier}
                            </span>
                          )}
                        </div>
                      ) : null;
                    const amountLimitation =
                      isDefined(remaining?.benefitAmount) ||
                      isDefined(max?.benefitAmount) ? (
                        <div>
                          {isDefined(remaining?.benefitAmount) &&
                            usdFormatter.format(
                              parseFloat(remaining!.benefitAmount)
                            )}
                          {isDefined(remaining?.timeQualifier) && (
                            <span className="text-gray-500">
                              {" "}
                              {remaining?.timeQualifier}
                            </span>
                          )}
                          {isDefined(remaining?.benefitAmount) &&
                            isDefined(max?.benefitAmount) &&
                            ", "}
                          {isDefined(max?.benefitAmount) &&
                            usdFormatter.format(parseFloat(max!.benefitAmount))}
                          {isDefined(max?.quantityQualifier) && (
                            <span className="text-gray-500">
                              {" "}
                              {max?.quantityQualifier}
                            </span>
                          )}
                          {isDefined(max?.timeQualifier) && (
                            <span className="text-gray-500">
                              {" "}
                              / {max?.timeQualifier}
                            </span>
                          )}
                        </div>
                      ) : null;
                    const percentageLimitation =
                      isDefined(remaining?.benefitPercent) ||
                      isDefined(max?.benefitPercent) ? (
                        <div>
                          {isDefined(remaining?.benefitPercent) &&
                            usdFormatter.format(
                              parseFloat(remaining!.benefitPercent)
                            )}
                          {isDefined(remaining?.timeQualifier) && (
                            <span className="text-gray-500">
                              {" "}
                              {remaining?.timeQualifier}
                            </span>
                          )}
                          {isDefined(remaining?.benefitPercent) &&
                            isDefined(max?.benefitPercent) &&
                            ", "}
                          {isDefined(max?.benefitPercent) &&
                            usdFormatter.format(
                              parseFloat(max!.benefitPercent)
                            )}
                          {isDefined(max?.quantityQualifier) && (
                            <span className="text-gray-500">
                              {" "}
                              {max?.quantityQualifier}
                            </span>
                          )}
                          {isDefined(max?.timeQualifier) && (
                            <span className="text-gray-500">
                              {" "}
                              / {max?.timeQualifier}
                            </span>
                          )}
                        </div>
                      ) : null;

                    return (
                      <div className="flex flex-col gap-1 divide-y">
                        {quantityLimitation}
                        {amountLimitation}
                        {percentageLimitation}
                      </div>
                    );
                  })}
                </td>
                <td className="p-2 text-center">{authDisplay ?? "-"}</td>
              </tr>
            );
          })}
        </table>
      )}
      {activeCoverageBenefits.map((benefit) => {
        const benefitAdditionalInfo = benefit?.additionalInformation ?? [];
        const generateAuthBadge = (authOrCertIndicator: string) => {
          if (authOrCertIndicator === "Y") {
            return <Badge text="Auth Required" variant="error" />;
          } else if (authOrCertIndicator === "N") {
            return <Badge text="No Auth Required" variant="success" />;
          } else if (authOrCertIndicator === "W") {
            return <Badge text="Auth Not Applicable" variant="info" />;
          } else if (authOrCertIndicator === "U") {
            return <Badge text="Auth Requirement Unknown" variant="info" />;
          }
        };
        return (
          (benefit.description || benefitAdditionalInfo.length > 0) && (
            <div>
              <div className="flex items-center gap-1 pb-1">
                <p className="text-sm">{benefit.description}</p>
                <Badge text="Active Coverage" variant="success" />
                {benefit.authOrCertIndicator
                  ? generateAuthBadge(benefit.authOrCertIndicator)
                  : null}
                {benefit.inPlanNetworkIndicator ? (
                  <NetworkStatusBadge
                    networkStatus={benefit.inPlanNetworkIndicator}
                  />
                ) : null}
              </div>
              {benefitAdditionalInfo.length > 0 && (
                <ul className="list-outside list-disc text-gray-700 text-sm">
                  {benefitAdditionalInfo.map((note, i) => (
                    <li key={i} className="ml-4 break-words">
                      {note.description}
                    </li>
                  ))}
                </ul>
              )}
              {benefit?.benefitsRelatedEntities?.map(
                (benefitsRelatedEntity) => (
                  <div className="pt-1">
                    <ServiceBenefitRelatedEntityDisplay
                      name={benefitsRelatedEntity.entityName}
                      entity={benefitsRelatedEntity}
                    />
                  </div>
                )
              )}
            </div>
          )
        );
      })}
      {nonTableBenefits.map((benefits, i) => {
        const {
          networkStatus,
          nonCoveredBenefit,
          inactiveBenefit,
          coverageLevel,
          placeOfService,
          notes,
          benefitRelatedEntities,
        } = benefits;
        return (
          <div
            key={i}
            className="p-4 rounded-md border bg-slate-100 grid grid-cols-2"
          >
            <div className="p-2">
              <div className="flex gap-1 flex-wrap">
                {nonCoveredBenefit && (
                  <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                    Non Covered
                  </span>
                )}
                {inactiveBenefit && (
                  <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10">
                    Inactive
                  </span>
                )}
                {networkStatus && (
                  <span className="truncate inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                    Network Status: {networkStatus}
                  </span>
                )}
                {coverageLevel && (
                  <span className="truncate inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                    Coverage Level: {coverageLevel}
                  </span>
                )}
                {placeOfService && (
                  <span className="truncate inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                    Place of Service: {placeOfService}
                  </span>
                )}
              </div>
            </div>
            <div className="p-2">
              {notes.length > 0 && (
                <ul className="list-outside list-disc text-gray-700 text-sm">
                  {notes.map((note, i) => (
                    <li key={i} className="ml-4 break-words">
                      {note}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              {benefitRelatedEntities.map(({ name, benefitsRelatedEntity }) => (
                <div className="pt-1">
                  <ServiceBenefitRelatedEntityDisplay
                    name={name}
                    entity={benefitsRelatedEntity}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </Card>
  );
};

const ResponseMemberDataListContent: React.FC<
  React.PropsWithChildren<{ member: ResponseMember }>
> = ({ member }) => {
  return (
    <>
      <div className="py-1 grid grid-cols-3 gap-4">
        <dt className="text-sm font-medium text-gray-500">Name</dt>
        <dd className="mt-1 text-sm text-gray-900 col-span-2">
          {[member.firstName, member.lastName].join(" ")}
        </dd>
      </div>
      {member.memberId && (
        <div className="py-1 grid grid-cols-3 gap-4">
          <dt className="text-sm font-medium text-gray-500">Member ID</dt>
          <dd className="mt-1 text-sm text-gray-900 col-span-2">
            {member.memberId}
          </dd>
        </div>
      )}
      {member.relationToSubscriber && (
        <div className="py-1 grid grid-cols-3 gap-4">
          <dt className="text-sm font-medium text-gray-500">
            Relation to Subscriber
          </dt>
          <dd className="mt-1 text-sm text-gray-900 col-span-2">
            {member.relationToSubscriber}
          </dd>
        </div>
      )}
      {member.groupNumber && (
        <div className="py-1 grid grid-cols-3 gap-4">
          <dt className="text-sm font-medium text-gray-500">Group Number</dt>
          <dd className="mt-1 text-sm text-gray-900 col-span-2">
            {member.groupNumber}
          </dd>
        </div>
      )}
      {member.planNetworkIdNumber && (
        <div className="py-1 grid grid-cols-3 gap-4">
          <dt className="text-sm font-medium text-gray-500">
            Plan Network ID Number
          </dt>
          <dd className="mt-1 text-sm text-gray-900 col-span-2">
            {member.planNetworkIdNumber}
          </dd>
        </div>
      )}
      {member.dateOfBirth && (
        <div className="py-1 grid grid-cols-3 gap-4">
          <dt className="text-sm font-medium text-gray-500">Date of Birth</dt>
          <dd className="mt-1 text-sm text-gray-900 col-span-2">
            {formatDateOrRange(member.dateOfBirth)}
          </dd>
        </div>
      )}
      {member.gender && (
        <div className="py-1 grid grid-cols-3 gap-4">
          <dt className="text-sm font-medium text-gray-500">Gender</dt>
          <dd className="mt-1 text-sm text-gray-900 col-span-2">
            {member.gender}
          </dd>
        </div>
      )}
      {member.address && (
        <div className="py-1 grid grid-cols-3 gap-4">
          <dt className="text-sm font-medium text-gray-500">Address</dt>
          <dd className="mt-1 text-sm text-gray-900 col-span-2">
            {[
              member.address?.address1,
              member.address?.address2,
              [
                member.address?.city,
                member.address?.state,
                member.address?.postalCode,
              ].join(", "),
            ]
              .filter((line) => isDefined(line) && line !== "")
              .map((line) => (
                <p>{line}</p>
              ))}
          </dd>
        </div>
      )}
    </>
  );
};

const OtherPayerDisplay: React.FC<{
  key: string;
  title: string;
  benefits: BenefitsInformation[];
}> = ({ key, title, benefits }) => {
  // Get all deduplicated notes
  const notes = Array.from(
    new Set(
      benefits.flatMap(
        (benefit) =>
          benefit.additionalInformation
            ?.map((info) => info.description)
            ?.filter(isDefined) ?? []
      )
    )
  );
  // Get all unique planNetworkIdNumbers
  const planNetworkIdNumbers = Array.from(
    new Set(
      benefits
        .map(
          (benefit) =>
            benefit.benefitsAdditionalInformation?.planNetworkIdNumber
        )
        .filter(isDefined)
    )
  );
  // Get all unique planNetworkNames
  const planNetworkNames = Array.from(
    new Set(
      benefits
        .map(
          (benefit) => benefit.benefitsAdditionalInformation?.planNetworkName
        )
        .filter(isDefined)
    )
  );
  return (
    <Card id={key} className="flex-col gap-1">
      <h2 className="font-medium text-lg">{title}</h2>
      {benefits.map((benefit, i) => (
        <dl key={i} className="divide-y divide-gray-200 border rounded-md p-2">
          <div className="py-1 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Name</dt>
            <dd className="mt-1 text-sm text-gray-900 col-span-2">
              {benefit.name}
            </dd>
          </div>
          {benefit.benefitsDateInformation?.coordinationOfBenefits && (
            <div className="py-1 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Coordination of Benefits Date
              </dt>
              <dd className="mt-1 text-sm text-gray-900 col-span-2">
                {formatDate(
                  benefit.benefitsDateInformation.coordinationOfBenefits
                )}
              </dd>
            </div>
          )}
          {benefit.serviceTypes && benefit.serviceTypes?.length > 0 && (
            <div className="py-1 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Service Types
              </dt>
              <dd className="mt-1 text-sm text-gray-900 col-span-2">
                {benefit.serviceTypes.join(", ")}
              </dd>
            </div>
          )}
          {benefit.benefitsRelatedEntities?.map((benefitsRelatedEntity) => (
            <div>
              {benefitsRelatedEntity?.entityIdentifier && (
                <div className="py-1 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    Entity Identifier
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 col-span-2">
                    {benefitsRelatedEntity.entityIdentifier}
                  </dd>
                </div>
              )}
              {benefitsRelatedEntity?.entityFirstname && (
                <div className="py-1 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    First Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 col-span-2">
                    {benefitsRelatedEntity.entityFirstname}
                  </dd>
                </div>
              )}
              {benefitsRelatedEntity?.entityName && (
                <div className="py-1 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 col-span-2">
                    {benefitsRelatedEntity.entityName}
                  </dd>
                </div>
              )}
              {benefitsRelatedEntity?.entityRelationship && (
                <div className="py-1 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    Relationship
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 col-span-2">
                    {benefitsRelatedEntity.entityRelationship}
                  </dd>
                </div>
              )}
              {benefitsRelatedEntity?.entityIdentificationValue && (
                <div className="py-1 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {benefitsRelatedEntity.entityIdentification ??
                      "Identification Number"}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 col-span-2">
                    {benefitsRelatedEntity.entityIdentificationValue}
                  </dd>
                </div>
              )}
              {benefitsRelatedEntity?.address && (
                <div className="py-1 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900 col-span-2">
                    {[
                      benefitsRelatedEntity.address.address1,
                      benefitsRelatedEntity.address.address2,
                      [
                        benefitsRelatedEntity.address.postalCode,
                        benefitsRelatedEntity.address.city,
                        benefitsRelatedEntity.address.state,
                      ]
                        .filter(Boolean)
                        .join(", "),
                    ]
                      .filter(Boolean)
                      .join("\n")}
                  </dd>
                </div>
              )}
              {benefitsRelatedEntity?.contactInformation && (
                <div className="py-1 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    Contact Information
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 col-span-2">
                    {(
                      benefitsRelatedEntity.contactInformation.contacts ?? []
                    ).map((contact) => (
                      <ContactDisplay contact={contact} />
                    ))}
                  </dd>
                </div>
              )}
            </div>
          ))}
          {(notes.length > 0 ||
            planNetworkIdNumbers.length > 0 ||
            planNetworkNames.length > 0) && (
            <ul className="list-outside list-disc text-gray-700 text-sm pt-1">
              {notes.map((note, i) => (
                <li key={i} className="ml-4 break-words">
                  {note}
                </li>
              ))}
              {planNetworkIdNumbers.map((idNumber, i) => (
                <li key={i} className="ml-4 break-words">
                  Plan Network Id Number: {idNumber}
                </li>
              ))}
              {planNetworkNames.map((network, i) => (
                <li key={i} className="ml-4 break-words">
                  Plan Network: {network}
                </li>
              ))}
            </ul>
          )}
        </dl>
      ))}
    </Card>
  );
};

export const FullEligibilityDisplay: React.FC<
  React.PropsWithChildren<{
    eligibility: EligibilityRequest;
    rawEligibilityResponse: RawEligibilityResponse;
  }>
> = ({ rawEligibilityResponse, eligibility }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  useEffect(() => {
    analytics?.track("Full Eligibility Viewed", {
      payerId: eligibility.insurancePolicy.payer.id,
      organizationId: user.organization.id,
      organizationName: user.organization.name,
      locationId: user.activeLocation.id,
      locationName: user.activeLocation.name,
    });
  }, []);

  const initialInNetwork = !!eligibility.insurancePolicy.inNetwork;
  const [inNetwork, setInNetwork] = useState(initialInNetwork);
  const payer = rawEligibilityResponse?.payer;
  const plan = rawEligibilityResponse?.planInformation;
  const provider = rawEligibilityResponse?.provider;
  const subscriber = rawEligibilityResponse?.subscriber;
  const dependents = rawEligibilityResponse?.dependents;
  const benefits = rawEligibilityResponse?.benefitsInformation ?? [];
  const planDateInformation = rawEligibilityResponse?.planDateInformation;
  const primaryCareProvider = benefits.find(
    (b) => b.code === BenefitCode.PrimaryCareProvider
  );
  const otherOrAdditionalPayors = benefits.filter(
    (b) => b.code === BenefitCode.OtherorAdditionalPayor
  );
  const contactFollowingEntityforEligibilityorBenefitInformations =
    benefits.filter(
      (b) =>
        b.code ===
        BenefitCode.ContactFollowingEntityforEligibilityorBenefitInformation
    );
  const groupedBenefits = benefits.reduce(
    (
      acc: {
        [svcType: string]: {
          serviceTypeDisplay: string;
          benefits: { [benefitCode: string]: BenefitsInformation[] };
        };
      },
      benefit
    ) => {
      // If no service type codes present, default to Health Plan
      (
        benefit.serviceTypeCodes ?? [ServiceTypeCode.HealthBenefitPlanCoverage]
      ).forEach((svcType: string, idx) => {
        if (
          inNetwork &&
          benefit.inPlanNetworkIndicatorCode === YesNoResponse.No
        )
          return;
        if (
          !inNetwork &&
          benefit.inPlanNetworkIndicatorCode === YesNoResponse.Yes
        )
          return;
        if (!isDefined(benefit.code)) return;
        let serviceTypeDisplay = (benefit.serviceTypes ?? [])[idx];
        if (svcType === ServiceTypeCode.HealthBenefitPlanCoverage)
          serviceTypeDisplay = "Health Benefit Plan Coverage";
        const svcTypeGroup = acc[svcType] ?? {};
        const benefitGroup = svcTypeGroup.benefits?.[benefit.code!] ?? [];
        acc = {
          ...acc,
          [svcType]: {
            ...(acc[svcType] ?? { serviceTypeDisplay }),
            benefits: {
              ...(svcTypeGroup.benefits ?? {}),
              [benefit.code]: [...benefitGroup, benefit],
            },
          },
        };
      });
      return acc;
    },
    {}
  );

  const benefitDescriptions = benefits.filter(
    (b) => b.code === BenefitCode.BenefitDescription
  );

  const planBenefitDescriptions = benefitDescriptions.filter(
    (b) => b.code === "30" || !isDefined(b.serviceTypeCodes)
  );

  const tableOfContents = [
    ...(isDefined(plan) ? [{ id: "plan", label: "Plan Information" }] : []),
    ...(isDefined(payer) ? [{ id: "payer", label: "Payer Information" }] : []),
    ...(isDefined(subscriber)
      ? [{ id: "subscriber", label: "Subscriber Information" }]
      : []),
    ...(isDefined(dependents) && dependents.length > 0
      ? [{ id: "dependents", label: "Dependents Information" }]
      : []),
    ...(isDefined(provider)
      ? [{ id: "provider", label: "Provider Information" }]
      : []),
    ...(isDefined(primaryCareProvider)
      ? [{ id: "primaryCareProvider", label: "Primary Care Provider" }]
      : []),
    ...(otherOrAdditionalPayors.length > 0
      ? [{ id: "otherOrAdditionalPayers", label: "Other or Additional Payers" }]
      : []),
    ...(benefitDescriptions.length > 0
      ? [{ id: "messages", label: "Plan Messages" }]
      : []),
  ];

  const benefitDisclaimers = benefits.filter(
    (b) => b.code === BenefitCode.BenefitDisclaimer
  );

  const bottomTableOfContents = [
    ...(benefitDisclaimers.length > 0
      ? [{ id: "disclaimers", label: "Benefit Disclaimers" }]
      : []),
  ];

  const defaultServiceTypes =
    eligibility.insurancePolicy.payer.defaultServiceTypes;

  const servicePriorities = defaultServiceTypes.map((serviceType, i) => ({
    serviceType,
    priorityOrder: i,
  }));

  const sortedGroupedBenefits = Object.entries(groupedBenefits).sort((a, b) => {
    const aPriority = servicePriorities.find(
      (config) => config.serviceType === a[0]
    )?.priorityOrder;
    const bPriority = servicePriorities.find(
      (config) => config.serviceType === b[0]
    )?.priorityOrder;
    if (!isDefined(aPriority) && !isDefined(bPriority)) return 0;
    if (!isDefined(bPriority)) return -1;
    if (!isDefined(aPriority)) return 1;
    return aPriority - bPriority;
  });

  const benefitsToc = sortedGroupedBenefits
    .map(([serviceType, benefitGroups]) => [
      {
        id: serviceType,
        label: benefitGroups.serviceTypeDisplay,
        serviceType: true,
        favorite: servicePriorities.some((c) => c.serviceType == serviceType),
      },
    ])
    .flat();

  return (
    <div className="relative max-h-[80vh] z-0 flex flex-1 overflow-hidden gap-2 print:overflow-visible print:max-h-none">
      <aside className="relative w-64 hidden overflow-y-auto pl-2 sm:flex-shrink-0 sm:flex sm:flex-col print:hidden">
        <nav className="space-y-1 flex flex-col px-1" aria-label="Sidebar">
          <h3 className="font-medium capitalize">Insurance Information</h3>
          {tableOfContents.map((item) => (
            <HashLink
              key={item.id}
              smooth
              to={`#${item.id}`}
              // @ts-ignore This is a real prop
              activeClassName="bg-gray-100 text-gray-900"
              className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 p-1 flex text-sm rounded-md"
            >
              <span className="truncate">{item.label}</span>
            </HashLink>
          ))}
          <h3 className="font-medium capitalize">Benefits</h3>
          {benefitsToc.map((item) => (
            <HashLink
              key={item.id}
              smooth
              to={`#${item.id}`}
              // @ts-ignore This is a real prop
              activeClassName="bg-gray-100 text-gray-900"
              className={classNames(
                "hover:bg-gray-100 hover:text-gray-900 flex text-sm rounded-md items-center gap-1 p-1",
                item.serviceType
                  ? "font-semibold text-md text-gray-700"
                  : "text-gray-600"
              )}
            >
              <span className="truncate">{item.label}</span>
              {item.favorite && <HeartIcon className="w-4 h-4 text-gray-500" />}
            </HashLink>
          ))}
          {bottomTableOfContents.map((item) => (
            <HashLink
              key={item.id}
              smooth
              to={`#${item.id}`}
              // @ts-ignore This is a real prop
              activeClassName="bg-gray-100 text-gray-900"
              className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 p-1 flex rounded-md font-medium"
            >
              <span className="truncate">{item.label}</span>
            </HashLink>
          ))}
        </nav>
      </aside>
      <main className="relative z-0 flex-1 overflow-y-scroll focus:outline-none print:overflow-visible px-2">
        {/* Start main area*/}
        <div className="inset-0">
          <div>
            <div className="flex justify-between sm:flex-row sm:items-center sm:space-x-1 pb-2">
              <div className="text-lg leading-6 font-medium text-gray-900">
                Eligibility Requested on{" "}
                {formatDateMMDDYYYY(eligibility.createdAt)}
                <span className="text-gray-500 pl-2">
                  (
                  {eligibility.clearinghouse === Clearinghouse.Change
                    ? "Change Healthcare"
                    : eligibility.clearinghouse}
                  )
                </span>
              </div>
              <span className="inline-flex shadow-sm rounded-md">
                <button
                  type="button"
                  className={classNames(
                    "inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500",
                    inNetwork
                      ? "bg-indigo-100 text-indigo-900 hover:bg-indigo-200"
                      : "bg-white hover:bg-indigo-200"
                  )}
                  onClick={() => setInNetwork(true)}
                >
                  In Network
                </button>
                <button
                  type="button"
                  className={classNames(
                    "inline-flex items-center px-2 py-1 rounded-r-md border-t border-r border-b border-gray-300 text-sm font-medium text-gray-500",
                    inNetwork
                      ? "bg-white hover:bg-indigo-200"
                      : "bg-indigo-100 text-indigo-900 hover:bg-indigo-200"
                  )}
                  onClick={() => setInNetwork(false)}
                >
                  Out Network
                </button>
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {eligibility.deduplicatedErrors.length > 0 && (
              <Card>
                <div className="w-full">
                  <EligibilityError eligibility={eligibility} />
                </div>
              </Card>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {payer && (
                <Card id="payer" className="flex-col">
                  <h2 className="font-medium text-lg">Payer Information</h2>
                  <dl className="divide-y divide-gray-200">
                    {payer.name && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {payer.name}
                          {payer.payorIdentification
                            ? ` (${payer.payorIdentification})`
                            : null}
                        </dd>
                      </div>
                    )}
                    {payer.contactInformation?.contacts &&
                      payer.contactInformation.contacts.length > 0 && (
                        <div className="py-1 grid grid-cols-3 gap-4">
                          <dt className="text-sm font-medium text-gray-500">
                            Contact Information
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 col-span-2">
                            {payer.contactInformation &&
                              payer.contactInformation.contacts?.length && (
                                <p>{payer.contactInformation.name}</p>
                              )}
                            {payer.contactInformation.contacts.map(
                              (contact) => (
                                <ContactDisplay contact={contact} />
                              )
                            )}
                          </dd>
                        </div>
                      )}

                    {/* TODO: Once we have booklets or other resource links, add here */}
                    {/* <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Resources
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                        </dd>
                      </div> */}
                  </dl>
                </Card>
              )}
              {(plan || planDateInformation) && (
                <Card id="plan" className="flex-col">
                  <h2 className="font-medium text-lg">Plan Information</h2>
                  <dl className="divide-y divide-gray-200">
                    {plan?.groupDescription && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Group Description
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {plan.groupDescription}
                        </dd>
                      </div>
                    )}
                    {plan?.groupNumber && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Group Number
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {plan.groupNumber}
                        </dd>
                      </div>
                    )}
                    {plan?.insurancePolicyNumber && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Policy Number
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {plan.insurancePolicyNumber}
                        </dd>
                      </div>
                    )}
                    {planDateInformation?.plan && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Plan Range
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {formatDateOrRange(planDateInformation.plan)}
                        </dd>
                      </div>
                    )}
                    {planDateInformation?.policyEffective && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Policy Effective
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {formatDateOrRange(
                            planDateInformation.policyEffective
                          )}
                        </dd>
                      </div>
                    )}
                    {planDateInformation?.policyExpiration && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Policy Expiration
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {formatDateOrRange(
                            planDateInformation.policyExpiration
                          )}
                        </dd>
                      </div>
                    )}
                    {planDateInformation?.eligibilityBegin && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Eligibility Begin
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {formatDateOrRange(
                            planDateInformation.eligibilityBegin
                          )}
                        </dd>
                      </div>
                    )}
                    {planDateInformation?.eligibilityEnd && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Eligibility End
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {formatDateOrRange(
                            planDateInformation.eligibilityEnd
                          )}
                        </dd>
                      </div>
                    )}
                    {planDateInformation?.benefitBegin && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Benefit Begin
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {formatDateOrRange(planDateInformation.benefitBegin)}
                        </dd>
                      </div>
                    )}
                    {planDateInformation?.benefitEnd && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Benefit End
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {formatDateOrRange(planDateInformation.benefitEnd)}
                        </dd>
                      </div>
                    )}
                    {planDateInformation?.enrollment && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Enrollment Date
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {formatDateOrRange(planDateInformation.enrollment)}
                        </dd>
                      </div>
                    )}
                    {/* Don't think we need to display the active coverage service list?
                  {planStatus &&
                    planStatus.length > 0 &&
                    planStatus.map((status) => (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          {status.status}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {status.serviceTypes &&
                            status.serviceTypes.length > 0 &&
                            status.serviceTypes.join(", ")}
                        </dd>
                      </div>
                    ))} */}
                  </dl>
                </Card>
              )}
              {subscriber && (
                <Card id="subscriber" className="flex-col">
                  <h2 className="font-medium text-lg">
                    Subscriber Information
                  </h2>
                  <dl className="divide-y divide-gray-200">
                    <ResponseMemberDataListContent member={subscriber} />
                  </dl>
                </Card>
              )}
              {dependents && (
                <Card id="dependents" className="flex-col">
                  <h2 className="font-medium text-lg">
                    Dependents Information
                  </h2>
                  <dl className="divide-y divide-gray-200">
                    {dependents.map((dependent) => (
                      <ResponseMemberDataListContent member={dependent} />
                    ))}
                  </dl>
                </Card>
              )}
              {provider && (
                <Card id="provider" className="flex-col">
                  <h2 className="font-medium text-lg">Provider Information</h2>
                  <dl className="divide-y divide-gray-200">
                    <div className="py-1 grid grid-cols-3 gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 col-span-2">
                        {[
                          provider.firstName ?? provider.providerFirstName,
                          provider.lastName ?? provider.providerName,
                        ].join(" ")}
                      </dd>
                    </div>
                    <div className="py-1 grid grid-cols-3 gap-4">
                      <dt className="text-sm font-medium text-gray-500">NPI</dt>
                      <dd className="mt-1 text-sm text-gray-900 col-span-2">
                        {provider.npi}
                      </dd>
                    </div>
                    {provider.organizationName && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Organization
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {provider.organizationName}
                        </dd>
                      </div>
                    )}
                  </dl>
                </Card>
              )}
              {primaryCareProvider && (
                <Card id="primaryCareProvider" className="flex-col">
                  <h2 className="font-medium text-lg">Primary Care Provider</h2>
                  <dl className="divide-y divide-gray-200">
                    <div className="py-1 grid grid-cols-3 gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 col-span-2">
                        {[
                          primaryCareProvider.benefitsRelatedEntity?.entityName,
                        ].join(" ")}
                      </dd>
                    </div>
                    {primaryCareProvider.benefitsRelatedEntity?.address && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Address
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {[
                            primaryCareProvider.benefitsRelatedEntity.address
                              .address1,
                            primaryCareProvider.benefitsRelatedEntity.address
                              .address2,
                            [
                              primaryCareProvider.benefitsRelatedEntity.address
                                .postalCode,
                              primaryCareProvider.benefitsRelatedEntity.address
                                .city,
                              primaryCareProvider.benefitsRelatedEntity.address
                                .state,
                            ]
                              .filter(Boolean)
                              .join(", "),
                          ]
                            .filter(Boolean)
                            .join("\n")}
                        </dd>
                      </div>
                    )}
                    {primaryCareProvider.benefitsRelatedEntity
                      ?.contactInformation && (
                      <div className="py-1 grid grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Contact Information
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 col-span-2">
                          {(
                            primaryCareProvider.benefitsRelatedEntity
                              .contactInformation.contacts ?? []
                          ).map((contact) => (
                            <ContactDisplay contact={contact} />
                          ))}
                        </dd>
                      </div>
                    )}
                  </dl>
                </Card>
              )}
              {contactFollowingEntityforEligibilityorBenefitInformations.length >
                0 && (
                <OtherPayerDisplay
                  key="contactFollowingEntityforEligibilityorBenefitInformations"
                  title="Contact Following Entity for Eligibility or Benefit Information"
                  benefits={
                    contactFollowingEntityforEligibilityorBenefitInformations
                  }
                />
              )}
              {otherOrAdditionalPayors.length > 0 && (
                <OtherPayerDisplay
                  key="otherOrAdditionalPayors"
                  title="Other or Additional Payer Information"
                  benefits={otherOrAdditionalPayors}
                />
              )}
            </div>
            {/* Plan Benefit Descriptions */}
            {planBenefitDescriptions.length > 0 && (
              <>
                <h2 className="font-medium text-lg" id="messages">
                  Plan Messages
                </h2>
                {planBenefitDescriptions.map((b) => (
                  <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-sm text-yellow-700">
                          <ul>
                            {b.additionalInformation?.map((note) => (
                              <li>{note.description}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            {sortedGroupedBenefits.map(([serviceType, benefits]) => {
              if (serviceType === "30") {
                return (
                  <PlanBenefitDetails
                    key={serviceType}
                    serviceType={serviceType}
                    benefits={benefits}
                  />
                );
              }
              return (
                <BenefitDetails
                  key={serviceType}
                  serviceType={serviceType}
                  benefits={benefits}
                />
              );
            })}
            {/* Benefit Disclaimers at end */}
            {benefitDisclaimers.length > 0 && (
              <>
                <h2 className="font-medium text-lg" id="disclaimers">
                  Benefit Disclaimers
                </h2>
                {benefitDisclaimers.map((b) => (
                  <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-sm text-yellow-700">
                          <ul>
                            {b.additionalInformation?.map((note) => (
                              <li>{note.description}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        {/* End main area */}
      </main>
    </div>
  );
};
