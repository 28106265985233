import { useParams } from "react-router-dom";
import { SettingsLayout } from "..";
import {
  GetMyOrganization_me_organization as Organization,
  GetMyOrganization_me_organization_locations as Location,
  GetMyOrganization_me_organization_locations_estimationWorkflowStatuses as EstimationWorkflowStatus,
} from "../../../generated/GetMyOrganization";
import { Card, SubmitButton, Tooltip } from "../../../components";
import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  LocationMarkerIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import React from "react";
import { WorkflowStage } from "../../../generated/globalTypes";
import { EstimationWorkflowStatusIndicator } from "../../estimations/list";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { FormProvider, useForm } from "react-hook-form";
import {
  Dialog,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogContent,
  DialogDescription,
} from "../../../components/ui/dialog";
import {
  UpsertEstimationWorkflowStatus,
  UpsertEstimationWorkflowStatusVariables,
} from "../../../generated/UpsertEstimationWorkflowStatus";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { v4 as uuidV4 } from "uuid";
import { cn, isDefined } from "../../../utils";
import {
  RemoveAndReplaceEstimationWorkflowStatus,
  RemoveAndReplaceEstimationWorkflowStatusVariables,
} from "../../../generated/RemoveAndReplaceEstimationWorkflowStatus";
import { Switch, Transition } from "@headlessui/react";
import { OvalSpinner } from "../../../components/loading";
import {
  UpdateLocationEstimateSetupMode,
  UpdateLocationEstimateSetupModeVariables,
} from "../../../generated/UpdateLocationEstimateSetupMode";
import {
  UpdateLocationSalting,
  UpdateLocationSaltingVariables,
} from "../../../generated/UpdateLocationSalting";
import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";

const UPSERT_ESTIMATION_WORKFLOW_STATUS = gql`
  mutation UpsertEstimationWorkflowStatus(
    $where: EstimationWorkflowStatusWhereUniqueInput!
    $create: EstimationWorkflowStatusCreateInput!
    $update: EstimationWorkflowStatusUpdateInput!
  ) {
    upsertOneEstimationWorkflowStatus(
      where: $where
      create: $create
      update: $update
    ) {
      id
      position
      name
      description
      stage
      location {
        id
        estimationWorkflowStatuses(
          orderBy: [{ stage: asc }, { position: asc }]
        ) {
          id
          position
          name
          description
          stage
          locationDefault {
            id
          }
        }
      }
    }
  }
`;

const REMOVE_AND_REPLACE_ESTIMATION_WORKFLOW_STATUS = gql`
  mutation RemoveAndReplaceEstimationWorkflowStatus(
    $id: String!
    $replacementStatusId: String!
  ) {
    removeAndReplaceEstimationWorkflowStatus(
      id: $id
      replacementStatusId: $replacementStatusId
    ) {
      id
      position
      name
      description
      stage
      location {
        id
        estimationWorkflowStatuses(
          orderBy: [{ stage: asc }, { position: asc }]
        ) {
          id
          position
          name
          description
          stage
          locationDefault {
            id
          }
        }
      }
    }
  }
`;

const RemoveEstimationWorkflowStatusDialogButton: React.FC<{
  location: Location;
  status: EstimationWorkflowStatus;
}> = ({ location, status }) => {
  const [open, setOpen] = useState(false);
  const [
    removeAndReplaceEstimationWorkflowStatus,
    removeAndReplaceEstimationWorkflowStatusResult,
  ] = useMutation<
    RemoveAndReplaceEstimationWorkflowStatus,
    RemoveAndReplaceEstimationWorkflowStatusVariables
  >(REMOVE_AND_REPLACE_ESTIMATION_WORKFLOW_STATUS);
  const [moveStatusId, setMoveStatusId] = useState<string>();

  const stageStatuses = location.estimationWorkflowStatuses.filter(
    (s) => s.stage === status.stage
  );

  const isValid = isDefined(moveStatusId);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="rounded-md p-1 hover:bg-gray-100">
        <TrashIcon className="w-4 h-4 text-gray-500" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Remove the {status.name} Estimation Workflow Status?
          </DialogTitle>
          <DialogDescription>
            <div>
              Are you sure you want to remove the {status.name} Estimation
              Workflow Status? This action cannot be undone.
            </div>
            <div className="flex flex-col gap-1 pt-2">
              <div>
                <label
                  htmlFor="moveStatusId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Status to move {status.name} policies to after deletion
                </label>
                <Select value={moveStatusId} onValueChange={setMoveStatusId}>
                  <SelectTrigger>
                    <SelectValue
                      placeholder={`Select a ${status.stage} status`}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {stageStatuses
                        .filter((s) => status.id !== s.id)
                        .map((s) => (
                          <SelectItem value={s.id}>{s.name}</SelectItem>
                        ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <SubmitButton
                    onClick={() => {
                      removeAndReplaceEstimationWorkflowStatus({
                        variables: {
                          id: status.id,
                          replacementStatusId: moveStatusId!,
                        },
                        onCompleted: () => {
                          toast.success("Estimation Workflow status removed");
                          setOpen(false);
                        },
                        onError: () => {
                          toast.error(
                            "Failed to remove Estimation Workflow status"
                          );
                        },
                      });
                    }}
                    disabled={
                      !isValid ||
                      removeAndReplaceEstimationWorkflowStatusResult.loading
                    }
                  >
                    Save
                  </SubmitButton>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

type EstimationWorkflowStatusForm = {
  id: string | null;
  name: string;
  description: string | null;
  stage: WorkflowStage;
  default: boolean;
};

const EstimationWorkflowFormDialogButton: React.FC<{
  stage: WorkflowStage;
  status: EstimationWorkflowStatus | null;
  position: number;
  locationId: string;
  dialogTrigger: React.ReactNode;
}> = ({ stage, status, position, locationId, dialogTrigger }) => {
  const [open, setOpen] = useState(false);
  const [upsertEstimationWorkflowStatus, upsertEstimationWorkflowStatusResult] =
    useMutation<
      UpsertEstimationWorkflowStatus,
      UpsertEstimationWorkflowStatusVariables
    >(UPSERT_ESTIMATION_WORKFLOW_STATUS);
  const form = useForm<EstimationWorkflowStatusForm>({
    defaultValues: {
      id: status?.id ?? uuidV4(),
      name: status?.name,
      description: status?.description,
      default: status?.locationDefault ? true : false,
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="rounded-md p-1 hover:bg-gray-100">
        {dialogTrigger}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {status ? "Edit" : "Add"} the {stage} Estimation Workflow Status
          </DialogTitle>
          <DialogDescription>
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit((data) => {
                  upsertEstimationWorkflowStatus({
                    variables: {
                      where: {
                        id: data.id,
                      },
                      create: {
                        position,
                        name: data.name,
                        description: data.description,
                        stage,
                        location: {
                          connect: { id: locationId },
                        },
                        ...(data.default
                          ? {
                              locationDefault: {
                                connect: { id: locationId },
                              },
                            }
                          : {}),
                      },
                      update: {
                        name: { set: data.name },
                        description: { set: data.description },
                        ...(data.default
                          ? {
                              locationDefault: {
                                connect: { id: locationId },
                              },
                            }
                          : {}),
                      },
                    },
                    onCompleted: () => {
                      toast.success("Estimation Workflow status updated");
                      setOpen(false);
                    },
                    onError: () => {
                      toast.error(
                        "Failed to update Estimation Workflow status"
                      );
                    },
                  });
                })}
              >
                <div className="flex flex-col gap-1 pt-2">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      required
                      {...form.register("name")}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      {...form.register("description")}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  {stage === WorkflowStage.Todo && (
                    <div>
                      <label
                        htmlFor="default"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Default Status
                      </label>
                      <input type="checkbox" {...form.register("default")} />
                    </div>
                  )}
                  <div className="flex justify-end gap-2 pt-2">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <div>
                      <SubmitButton
                        disabled={upsertEstimationWorkflowStatusResult.loading}
                      >
                        Save
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const MOVE_ESTIMATION_WORKFLOW_STATUS_POSITION_UP = gql`
  mutation MoveEstimationWorkflowStatusPositionUp($id: String!) {
    moveEstimationWorkflowStatusPositionUp(id: $id) {
      id
      position
      name
      description
      stage
      location {
        id
        estimationWorkflowStatuses(
          orderBy: [{ stage: asc }, { position: asc }]
        ) {
          id
          position
          name
          description
          stage
        }
      }
    }
  }
`;
const MOVE_ESTIMATION_WORKFLOW_STATUS_POSITION_DOWN = gql`
  mutation MoveEstimationWorkflowStatusPositionDown($id: String!) {
    moveEstimationWorkflowStatusPositionDown(id: $id) {
      id
      position
      name
      description
      stage
      location {
        id
        estimationWorkflowStatuses(
          orderBy: [{ stage: asc }, { position: asc }]
        ) {
          id
          position
          name
          description
          stage
        }
      }
    }
  }
`;

const LocationEstimationWorkflowSettings: React.FC<
  React.PropsWithChildren<{ location: Location }>
> = ({ location }) => {
  const estimationWorkflowStatuses = location.estimationWorkflowStatuses;
  const [
    moveEstimationWorkflowStatusPositionUp,
    moveEstimationWorkflowStatusPositionUpResult,
  ] = useMutation(MOVE_ESTIMATION_WORKFLOW_STATUS_POSITION_UP);
  const [
    moveEstimationWorkflowStatusPositionDown,
    moveEstimationWorkflowStatusPositionDownResult,
  ] = useMutation(MOVE_ESTIMATION_WORKFLOW_STATUS_POSITION_DOWN);

  const stages = [
    WorkflowStage.Todo,
    WorkflowStage.InProgress,
    WorkflowStage.Complete,
    WorkflowStage.Canceled,
  ];

  return (
    <div className="flex flex-col gap-4 w-full py-2">
      <div className="text-gray-500">
        Your Estimation Workflow defines the statuses that policies go through
        during your estimation process. You can customize the statuses and the
        order in which they appear in the workflow.
      </div>
      {stages.map((stage) => {
        const stageStatuses = estimationWorkflowStatuses.filter(
          (status) => status.stage === stage
        );
        const lastPosition = Math.max(...stageStatuses.map((s) => s.position));
        const nextLargestPosition = Math.max(
          ...estimationWorkflowStatuses.map((s) => s.position)
        );
        const newPosition =
          nextLargestPosition > lastPosition
            ? // If there are no later positions, just add 1
              nextLargestPosition + 1
            : // Take the middle of the last position of the stage and the next largest position
              (lastPosition + nextLargestPosition) / 2;

        return (
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <div className="font-semibold text-gray-500">{stage}</div>
              <EstimationWorkflowFormDialogButton
                stage={stage}
                status={null}
                position={newPosition}
                locationId={location.id}
                dialogTrigger={<PlusIcon className="w-4 h-4 text-gray-500" />}
              />
              {/* <Button size="icon" onClick={() => {}}>
                <PlusIcon className="w-5 h-5" />
              </Button> */}
            </div>

            {stageStatuses.map((status, i) => {
              return (
                <div className="flex justify-between items-center border border-gray-100 py-1 px-4 rounded-md w-full">
                  <div className="flex items-center gap-2">
                    <EstimationWorkflowStatusIndicator status={status} />
                    {status.name}
                    {status.description && (
                      <span className="text-gray-500 text-sm">
                        {status.description}
                      </span>
                    )}
                  </div>
                  <div className="flex items-center gap-1">
                    {status.locationDefault && (
                      <span className="text-sm text-indigo-500">Default</span>
                    )}
                    <button
                      className="p-1 rounded-md hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={
                        i === 0 ||
                        moveEstimationWorkflowStatusPositionUpResult.loading
                      }
                      onClick={() => {
                        moveEstimationWorkflowStatusPositionUp({
                          variables: {
                            id: status.id,
                          },
                        });
                      }}
                    >
                      <ArrowUpIcon className="w-4 h-4 text-gray-500" />
                    </button>
                    <button
                      className="p-1 rounded-md hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={
                        i === stageStatuses.length - 1 ||
                        moveEstimationWorkflowStatusPositionDownResult.loading
                      }
                      onClick={() => {
                        moveEstimationWorkflowStatusPositionDown({
                          variables: {
                            id: status.id,
                          },
                        });
                      }}
                    >
                      <ArrowDownIcon className="w-4 h-4 text-gray-500" />
                    </button>

                    <EstimationWorkflowFormDialogButton
                      stage={stage}
                      status={status}
                      position={status.position}
                      locationId={location.id}
                      dialogTrigger={
                        <PencilIcon className="w-4 h-4 text-gray-500" />
                      }
                    />

                    {stageStatuses.length > 1 &&
                      !isDefined(status.locationDefault) && (
                        <RemoveEstimationWorkflowStatusDialogButton
                          status={status}
                          location={location}
                        />
                      )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const UPDATE_LOCATION_ESTIMATE_SETUP_MODE = gql`
  mutation UpdateLocationEstimateSetupMode(
    $locationId: String!
    $enabled: Boolean!
  ) {
    updateOneLocation(
      where: { id: $locationId }
      data: { estimateSetupMode: { set: $enabled } }
    ) {
      id
      estimateSetupMode
    }
  }
`;

const UPDATE_LOCATION_SALTING = gql`
  mutation UpdateLocationSalting(
    $locationId: String!
    $enabled: Boolean!
    $lookbackMonths: Int!
    $matchAccount: Boolean!
    $matchProvider: Boolean!
    $matchAppointmentLabel: Boolean!
  ) {
    updateOneLocation(
      where: { id: $locationId }
      data: {
        saltingEnabled: { set: $enabled }
        saltingLookbackMonths: { set: $lookbackMonths }
        saltingMatchAccount: { set: $matchAccount }
        saltingMatchProvider: { set: $matchProvider }
        saltingMatchAppointmentLabel: { set: $matchAppointmentLabel }
      }
    ) {
      id
      saltingEnabled
      saltingLookbackMonths
      saltingMatchAccount
      saltingMatchProvider
      saltingMatchAppointmentLabel
    }
  }
`;

const LocationEstimateSettings: React.FC<
  React.PropsWithChildren<{ location: Location }>
> = ({ location }) => {
  const [estimateSetupModeEnabled, setEstimateSetupModeEnabled] = useState(
    location.estimateSetupMode
  );
  const [
    updateLocationEstimateSetupMode,
    updateLocationEstimateSetupModeResult,
  ] = useMutation<
    UpdateLocationEstimateSetupMode,
    UpdateLocationEstimateSetupModeVariables
  >(UPDATE_LOCATION_ESTIMATE_SETUP_MODE);

  const [updateLocationSalting, updateLocationSaltingResult] = useMutation<
    UpdateLocationSalting,
    UpdateLocationSaltingVariables
  >(UPDATE_LOCATION_SALTING);

  const [saltingEnabled, setSaltingEnabled] = useState(
    Boolean(location.saltingEnabled)
  );

  const [saltingLookbackMonths, setSaltingLookbackMonths] = useState(
    location.saltingLookbackMonths
  );

  const [saltedItems, setSaltedItems] = useState<{
    accountType: boolean;
    provider: boolean;
    appointmentLabels: boolean;
  }>({
    accountType: Boolean(location.saltingMatchAccount),
    provider: Boolean(location.saltingMatchProvider),
    appointmentLabels: Boolean(location.saltingMatchAppointmentLabel),
  });

  const handleCheckboxChange = (
    checkbox: "accountType" | "provider" | "appointmentLabels"
  ) => {
    setSaltedItems({
      ...saltedItems,
      [checkbox]: !saltedItems[checkbox],
    });
  };

  const saveCheckboxes = () => {
    updateLocationSalting({
      variables: {
        locationId: location.id,
        enabled: saltingEnabled,
        lookbackMonths: saltingLookbackMonths,
        matchAccount: saltedItems.accountType,
        matchProvider: saltedItems.provider,
        matchAppointmentLabel: saltedItems.appointmentLabels,
      },
      onCompleted: () => {
        toast.success(`Estimate SALTing configuration saved`);
      },
      onError: () => {
        toast.error("Error saving Estimate SALTing configuration");
      },
    });
  };

  const toggleLocationBillingSettings = (val: boolean) => {
    if (val) {
      setEstimateSetupModeEnabled(true);
      updateLocationEstimateSetupMode({
        variables: { locationId: location.id, enabled: true },
        onCompleted: (data) => {
          toast.success("Estimate setup mode enabled");
        },
        onError: (error) => {
          toast.error("Error enabling Estimate setup mode");
          setEstimateSetupModeEnabled(false);
        },
      });
    } else {
      setEstimateSetupModeEnabled(false);
      updateLocationEstimateSetupMode({
        variables: { locationId: location.id, enabled: false },
        onCompleted: (data) => {
          toast.success("Estimate setup mode disabled");
        },
        onError: (error) => {
          toast.error("Error disabling Estimate setup mode");
          setEstimateSetupModeEnabled(true);
        },
      });
    }
  };

  const toggleSaltingEnabled = (val: boolean) => {
    updateLocationSalting({
      variables: {
        locationId: location.id,
        enabled: val,
        lookbackMonths: val ? 3 : 0,
        matchAccount: val,
        matchProvider: val,
        matchAppointmentLabel: val,
      },
      onCompleted: (data) => {
        setSaltingEnabled(val);
        setSaltedItems({
          accountType: val,
          provider: val,
          appointmentLabels: val,
        });
        setSaltingLookbackMonths(
          data.updateOneLocation!.saltingLookbackMonths!
        );
        setSaltedItems({
          accountType: data.updateOneLocation!.saltingMatchAccount!,
          provider: data.updateOneLocation!.saltingMatchProvider!,
          appointmentLabels:
            data.updateOneLocation!.saltingMatchAppointmentLabel!,
        });
        toast.success(`Estimate SALTing ${val ? "enabled" : "disabled"}`);
      },
      onError: (error) => {
        toast.error("Error enabling Estimate SALTing");
      },
    });
  };

  const handleLookbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue) && newValue >= 1 && newValue <= 24) {
      setSaltingLookbackMonths(newValue);
    }
  };

  return (
    <div>
      <div className="py-2">
        <div className="text-sm font-semibold text-gray-500">
          Estimate Setup Mode
        </div>
        <div className="text-gray-500 text-sm">
          When the location is in Estimate Setup Mode, all estimates
          funcationality will only be visible for admins. This allows you to
          configure your estimates settings without affecting other users using
          Pledge.
        </div>
        <div className="flex items-center justify-between border rounded-md p-4 my-2">
          <div className="text-sm">Estimate Setup Mode</div>
          <div className="flex flex-auto items-center justify-end gap-2">
            {updateLocationEstimateSetupModeResult.loading && (
              <OvalSpinner className="w-4 h-4 text-gray-700" />
            )}
            <Switch
              checked={estimateSetupModeEnabled}
              onChange={toggleLocationBillingSettings}
              className={cn(
                estimateSetupModeEnabled ? "bg-indigo-600" : "bg-gray-200",
                "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              )}
            >
              <span
                aria-hidden="true"
                className={cn(
                  estimateSetupModeEnabled
                    ? "translate-x-3.5"
                    : "translate-x-0",
                  "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
      <div className="py-2">
        <div className="text-sm font-semibold text-gray-500">
          Estimate SALTing Configuration
        </div>
        <div className="text-gray-500 text-sm">
          When enabled, SALTing (Same As Last Time) will be used to look up
          similar past appointments and apply the same charge codes during
          estimation. This will take precedence over your charge template rules.
        </div>
        <div className="border rounded-md p-4 my-2">
          <div className="flex items-center justify-between">
            <div className="text-sm">Enable SALTing</div>
            <div className="flex flex-auto items-center justify-end gap-2">
              <Switch
                checked={saltingEnabled}
                onChange={toggleSaltingEnabled}
                className={cn(
                  saltingEnabled ? "bg-indigo-600" : "bg-gray-200",
                  "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                )}
                disabled={updateLocationSaltingResult.loading}
              >
                <span
                  aria-hidden="true"
                  className={cn(
                    saltingEnabled ? "translate-x-3.5" : "translate-x-0",
                    "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          </div>
          <Transition
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-200 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            show={saltingEnabled}
          >
            <div className="flex flex-col items-start p-5">
              <div className="text-sm font-semibold pb-3">
                {" "}
                Choose which fields to match on when looking up similar past
                appointments.
              </div>
              <div className="space-y-2">
                <div className="flex items-center">
                  <Checkbox.Root
                    className={`w-4 h-4 border-2 rounded flex items-center justify-center ${
                      saltedItems.accountType
                        ? "bg-blue-500 border-blue-500"
                        : "bg-white border-gray-300"
                    }`}
                    id="accountType"
                    checked={saltedItems.accountType}
                    onCheckedChange={() => handleCheckboxChange("accountType")}
                  >
                    <Checkbox.Indicator className="text-white">
                      <CheckIcon />
                    </Checkbox.Indicator>
                  </Checkbox.Root>
                  <label className="ml-2 text-sm" htmlFor="accountType">
                    Account Type
                  </label>
                </div>
                <div className="flex items-center">
                  <Checkbox.Root
                    className={`w-4 h-4 border-2 rounded flex items-center justify-center ${
                      saltedItems.provider
                        ? "bg-blue-500 border-blue-500"
                        : "bg-white border-gray-300"
                    }`}
                    id="provider"
                    checked={saltedItems.provider}
                    onCheckedChange={() => handleCheckboxChange("provider")}
                  >
                    <Checkbox.Indicator className="text-white">
                      <CheckIcon />
                    </Checkbox.Indicator>
                  </Checkbox.Root>
                  <label className="ml-2 text-sm" htmlFor="provider">
                    Provider
                  </label>
                </div>
                <div className="flex items-center">
                  <Checkbox.Root
                    className={`w-4 h-4 border-2 rounded flex items-center justify-center ${
                      saltedItems.appointmentLabels
                        ? "bg-blue-500 border-blue-500"
                        : "bg-white border-gray-300"
                    }`}
                    id="appointmentLabels"
                    checked={saltedItems.appointmentLabels}
                    onCheckedChange={() =>
                      handleCheckboxChange("appointmentLabels")
                    }
                  >
                    <Checkbox.Indicator className="text-white">
                      <CheckIcon />
                    </Checkbox.Indicator>
                  </Checkbox.Root>
                  <label className="ml-2 text-sm" htmlFor="checkbox3">
                    Appointment Labels
                  </label>
                </div>
              </div>
              <div className="mt-2 flex flex-col justify-center space-y-2">
                <div className="flex">
                  <span className="text-sm font-semibold">
                    Choose number of months to look back for past appointments
                    (1-24).
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <Input
                    id="monthsToLookBack"
                    type="number"
                    className="allow-spin-buttons w-32"
                    min="1"
                    max="24"
                    value={saltingLookbackMonths}
                    onChange={handleLookbackChange}
                  />
                </div>
              </div>
              <Button
                className="mt-3"
                variant="default"
                onClick={() => {
                  saveCheckboxes();
                }}
              >
                Save
              </Button>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export const WorkspaceLocationEstimationWorkflowSettings: React.FC<{
  organization: Organization;
}> = ({ organization }) => {
  const { locationId } = useParams<{ locationId: string }>();
  const location = organization.locations.find((l) => l.id === locationId)!;
  console.log({ location, setupMode: location.estimateSetupMode });

  return (
    <SettingsLayout
      title="Estimation Workflow Settings"
      description={`Manage ${location.name}'s estimation workflow settings.`}
      key={locationId}
    >
      <div className="flex flex-col gap-4">
        <Card>
          <LocationEstimationWorkflowSettings location={location} />
        </Card>
        <Card>
          <LocationEstimateSettings location={location} />
        </Card>
      </div>
    </SettingsLayout>
  );
};
