import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  Table as ReactTable,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { ChargemasterRow } from "./list";
import { OvalSpinner } from "../../components/loading";
import { DataTablePagination } from "../../components/ui/table-helpers/data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";
import { cn, formatRelativeDay, mapNullable } from "../../utils";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../components/ui/collapsible";
import { Badge } from "../../components/ui/badge";
import { parseISO } from "date-fns";

interface DataTableProps<TValue> {
  columns: ColumnDef<ChargemasterRow, TValue>[];
  defaultColumnFilters?: ColumnFiltersState;
  data: ChargemasterRow[];
}

export function DataTable<TValue>({
  columns,
  data,
  defaultColumnFilters,
  children,
  onRowClick,
  loading,
  loadingMore,
  isPending,
}: DataTableProps<TValue> & {
  children?: (table: ReactTable<ChargemasterRow>) => React.ReactElement;
  onRowClick?: (row: ChargemasterRow) => void;
  loading?: boolean;
  loadingMore?: boolean;
  isPending: boolean;
}) {
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState({});
  // const [pagination, setPagination] = React.useState<PaginationState>({
  //   pageSize: 20,
  //   pageIndex: 0,
  // });
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      archived: false,
    });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    defaultColumnFilters ?? []
  );
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "lastUsedAt",
      desc: true,
    },
  ]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      globalFilter,
      // pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 35,
    overscan: 5,
  });

  return (
    <>
      {children && children(table)}
      <div className="space-y-4">
        <div className="w-full">
          <DataTableToolbar isPending={isPending} table={table} />
        </div>
        <div className="border bg-white">
          <div ref={tableContainerRef} className="max-h-[85vh] overflow-auto">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow
                    key={headerGroup.id}
                    className="divide-x"
                    onClick={() => {}}
                  >
                    {/* <TableHead className="sticky top-0 z-10 bg-white"></TableHead> */}
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          key={header.id}
                          className="sticky top-0 z-10 p-2 bg-white"
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  <>
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        style={{
                          height: `${
                            virtualizer.getVirtualItems()[0]?.start ?? 0
                          }px`,
                          padding: 0,
                        }}
                      />
                    </TableRow>
                    {virtualizer.getVirtualItems().map((virtualRow) => {
                      const row = rows[virtualRow.index];
                      return (
                        <Collapsible key={row.id} asChild>
                          <>
                            <TableRow
                              key={row.id}
                              data-state={row.getIsSelected() && "selected"}
                              onClick={() => {
                                onRowClick?.(row.original);
                              }}
                              className={cn(
                                "divide-x",
                                onRowClick && "cursor-pointer"
                              )}
                            >
                              {/* <TableCell className="whitespace-nowrap p-2">
                              <CollapsibleTrigger>
                                <Badge>
                                  {
                                    row.original.chargemasterGroup.chargemasters
                                      .length
                                  }
                                </Badge>
                              </CollapsibleTrigger>
                            </TableCell> */}
                              {row.getVisibleCells().map((cell, i) => (
                                <TableCell
                                  key={cell.id}
                                  className={cn(
                                    "whitespace-nowrap p-2",
                                    // CSS to make the last column sticky
                                    // "last:sticky last:right-0  last:bg-white last:drop-shadow"
                                    cell.column.columnDef.className
                                  )}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                            {row.original.chargemasterGroup.chargemasters.map(
                              (c) => {
                                return (
                                  <CollapsibleContent asChild key={c.id}>
                                    <TableRow
                                      key={c.id}
                                      className="divide-x bg-slate-50"
                                    >
                                      <TableCell></TableCell>
                                      <TableCell
                                        className={cn("whitespace-nowrap p-2")}
                                      >
                                        {mapNullable((d: Date) =>
                                          formatRelativeDay(parseISO(d))
                                        )(c.lastUsedAt)}
                                      </TableCell>
                                      <TableCell
                                        className={cn("whitespace-nowrap p-2")}
                                      >
                                        {c.code}
                                      </TableCell>
                                      <TableCell
                                        className={cn("whitespace-nowrap p-2")}
                                      >
                                        {c.name}
                                      </TableCell>
                                      <TableCell
                                        className={cn("whitespace-nowrap p-2")}
                                      >
                                        {c.modifier1}
                                      </TableCell>
                                      <TableCell
                                        className={cn("whitespace-nowrap p-2")}
                                      >
                                        {c.modifier2}
                                      </TableCell>
                                      <TableCell
                                        className={cn("whitespace-nowrap p-2")}
                                      ></TableCell>
                                      <TableCell
                                        className={cn("whitespace-nowrap p-2")}
                                      ></TableCell>
                                      <TableCell
                                        className={cn("whitespace-nowrap p-2")}
                                      ></TableCell>
                                      <TableCell
                                        className={cn("whitespace-nowrap p-2")}
                                      ></TableCell>
                                      {/* {row.getVisibleCells().map((cell, i) => {
                                        return (
                                          <TableCell
                                            key={cell.id}
                                            className={cn("whitespace-nowrap p-2")}
                                          >
                                            {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext()
                                            )}
                                          </TableCell>
                                        );
                                      })} */}
                                    </TableRow>
                                  </CollapsibleContent>
                                );
                              }
                            )}
                          </>
                        </Collapsible>
                      );
                    })}
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        style={{
                          height: `${
                            virtualizer.getTotalSize() -
                            (virtualizer.getVirtualItems()[
                              virtualizer.getVirtualItems().length - 1
                            ]?.end ?? 0)
                          }px`,
                          padding: 0,
                        }}
                      />
                    </TableRow>
                  </>
                ) : loading ? (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center p-2"
                    >
                      <div className="w-full flex justify-center">
                        <OvalSpinner />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center p-2"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
        {/* <div className="w-full">
          <DataTablePagination table={table} loading={loading || loadingMore} />
        </div> */}
      </div>
    </>
  );
}
