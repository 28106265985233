import React, { Fragment, useState } from "react";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { compareDesc, parseISO } from "date-fns";
import * as RQ from "react-query";

import { INSURANCE_POLICY_SUMMARY_FIELDS } from "../../graphql";
import { Card, Modal, SubmitButton } from "../../components";
import { Button } from "../../components/ui/button";
import { Transition } from "@headlessui/react";
import { VerificationWorklistRow, columns } from "./columns";
import { DataTable } from "./table";
import { formatDateMMDDYYYY, isDefined, mapNullable } from "../../utils";
import { useUser } from "../../user-context";
import { SkipForward } from "lucide-react";
import { toast } from "react-toastify";
import {
  Metric,
  Text,
  Icon,
  List,
  ListItem,
  Bold,
  ProgressBar,
} from "@tremor/react";
import {
  CheckCircleIcon,
  DocumentDownloadIcon,
  XCircleIcon,
  MinusCircleIcon,
  ExclamationCircleIcon,
  XIcon,
  CogIcon,
} from "@heroicons/react/outline";
import { HorizontalPadding } from "../layout";
import { WorkflowStage } from "../../generated/globalTypes";
import { useAnalytics } from "../../analytics-context";
import { CSVLink } from "react-csv";
import {
  BulkVerifyBenefits,
  BulkVerifyBenefitsVariables,
} from "../../generated/BulkVerifyBenefits";
import {
  GetBulkVerifyBenefitsWorkflow,
  GetBulkVerifyBenefitsWorkflowVariables,
} from "../../generated/GetBulkVerifyBenefitsWorkflow";
import { InsurancePolicyRow } from "../worklists/policies/split-pane";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../../components/ui/dropdown-menu";
import { ACCEPT_MULTIPLE_INSURANCE_POLICIES } from ".";
import {
  AcceptMultipleInsurancePolicies,
  AcceptMultipleInsurancePoliciesVariables,
} from "../../generated/AcceptMultipleInsurancePolicies";
import { GetRunningBulkVerifyBenefitsWorkflowsForLocation } from "../../generated/GetRunningBulkVerifyBenefitsWorkflowsForLocation";
import {
  GetVerificationWorkflowPolicies,
  GetVerificationWorkflowPoliciesVariables,
  GetVerificationWorkflowPolicies_insurancePolicies_verificationWorkflowStatus as VerificationWorkflowStatus,
} from "../../generated/GetVerificationWorkflowPolicies";
import { StatusIndicator } from "../billing";
import { useDashboardResizable } from "../../dashboard-resizable";
import { VerificationSidePanel } from "./side-panel";
import {
  CancelMultipleInsurancePolicyVerificationWorkflows,
  CancelMultipleInsurancePolicyVerificationWorkflowsVariables,
} from "../../generated/CancelMultipleInsurancePolicyVerificationWorkflows";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { constants } from "../../constants";

export const GET_VERIFICATION_WORKFLOW_POLICIES = gql`
  query GetVerificationWorkflowPolicies($locationId: String!) {
    location(where: { id: $locationId }) {
      id
      verificationWorkflowStatuses(
        orderBy: [{ stage: asc }, { position: asc }]
      ) {
        id
        stage
        name
        description
      }
    }
    verificationWorkflowPolicies {
      id
      createdAt
      updatedAt

      # Verification Workflow state
      verificationWorkflowStatus {
        id
        name
        stage
      }
      lastVerificationWorkflowReason
      lastVerificationWorkflowStartedAt
      lastVerificationWorkflowCompletedAt
      lastVerificationWorkflowCanceledAt
      verificationWorkflowActiveAt
      verificationWorkflowArchivedAt

      memberId
      priority
      ignoredAt
      acceptedAt
      insurancePolicyVerificationStatus
      patient {
        id
        displayName
      }
      accounts {
        id
        accountType {
          id
          name
        }
      }
      mostRecentCoverageBenefits {
        id
        createdAt
        insurancePolicyId
        providerServiceConfiguration {
          id
          name
          shortName
          serviceType
          priorityOrder
        }
        networkStatus
        coverageLevel
        empty
        authRequired
        copay
        copayBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        coinsurance
        coinsuranceBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        remainingDeductible
        remainingDeductibleBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        maxDeductible
        maxDeductibleBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        deductibleApplies
        metDeductible
        deductibleAppliesBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        remainingOutOfPocket
        remainingOutOfPocketBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        maxOutOfPocket
        maxOutOfPocketBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        metOutOfPocketMax
        remainingVisits
        remainingVisitsBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
          quantityQualifier
        }
        maxVisits
        maxVisitsBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
          quantityQualifier
        }
        nonCovered
        nonCoveredBenefit {
          id
          eligibilityRequestId
        }
        activeCoverageBenefit {
          id
          eligibilityRequestId
        }
        # NOTE: Removed this to avoid n+1 queries and just turned off the benefit hovercard instead
        # appliedBenefitAccumulatorAdjustments {
        #   id
        #   accumulatorAdjustment {
        #     id
        #     type
        #     appointment {
        #       id
        #       start
        #       provider {
        #         id
        #         displayName
        #       }
        #     }
        #   }
        #   providerServiceConfiguration {
        #     id
        #   }
        #   appliedDeductible
        #   appliedOutOfPocket
        #   appliedVisits
        # }
      }
      payer {
        id
        name
      }
      mostRecentEligibilityRequest {
        id
        createdAt
      }
      nextAppointment {
        id
        start
        end
        appointmentLabelings {
          id
          appointmentLabel {
            id
            name
          }
        }
        provider {
          id
          displayName
        }
      }
    }
  }
`;

export const ConfirmationDialog = <T,>({
  setOpen,
  selected,
  onConfirm,
  loading,
  title,
  children,
}: {
  setOpen: (open: boolean) => void;
  selected: T[];
  onConfirm: (selected: T[]) => void;
  loading?: boolean;
  title: (selected: T[]) => React.ReactNode;
  children: (selected: T[]) => React.ReactNode;
}) => {
  return (
    <Modal open={true} setOpen={setOpen}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title(selected)}
          </h3>
          <div className="mt-2 py-4">{children(selected)}</div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
          <SubmitButton
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            loading={loading}
            onClick={() => {
              onConfirm(selected);
            }}
          >
            Confirm
          </SubmitButton>
        </div>
      </div>
    </Modal>
  );
};

const BULK_VERIFY_BENEFITS = gql`
  mutation BulkVerifyBenefits(
    $appointmentPolicies: [BulkVerifyBenefitsInput!]!
  ) {
    bulkVerifyBenefits(appointmentPolicies: $appointmentPolicies) {
      workflowId
    }
  }
`;

const GET_BULK_VERIFY_BENEFITS_WORKFLOW = gql`
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  query GetBulkVerifyBenefitsWorkflow($workflowId: String!) {
    getBulkVerifyBenefitsWorkflow(workflowId: $workflowId) {
      cursor
      batchSize
      currentVerification {
        insurancePolicy {
          id
          ...InsurancePolicySummaryFields
          patient {
            id
            displayName
          }
        }
        appointment {
          id
        }
      }
      results {
        insurancePolicyId
        appointmentId
        status
      }
    }
  }
`;

const BulkVerifyBenefitsWorkflowProgressAlert: React.FC<{
  workflowId: string;
}> = ({ workflowId }) => {
  const apollo = useApolloClient();
  const [show, setShow] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const { data, loading, startPolling, stopPolling } = useQuery<
    GetBulkVerifyBenefitsWorkflow,
    GetBulkVerifyBenefitsWorkflowVariables
  >(GET_BULK_VERIFY_BENEFITS_WORKFLOW, {
    variables: {
      workflowId,
    },
    pollInterval: 2000,
  });

  const cursor = data?.getBulkVerifyBenefitsWorkflow?.cursor;
  const batchSize = data?.getBulkVerifyBenefitsWorkflow?.batchSize;

  const isComplete =
    isDefined(cursor) && isDefined(batchSize) && cursor === batchSize;

  React.useEffect(() => {
    if (isComplete) {
      stopPolling();
      apollo.refetchQueries({
        include: [GET_BULK_VERIFY_BENEFITS_WORKFLOW],
      });
    }
  }, [isComplete, stopPolling, apollo]);

  if (!show) return null;
  return (
    <>
      <div className="rounded-md bg-blue-50 p-4 w-full">
        <div className="flex">
          <div className="flex-shrink-0">
            <CogIcon
              className="h-5 w-5 text-blue-400 animate-[spin_3s_linear_infinite]"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              Verifying {cursor} of {batchSize} policies.
            </p>
            <div className="mt-3 text-sm md:ml-6 md:mt-0 flex gap-2">
              <button
                type="button"
                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                onClick={() => {
                  setShowDialog(true);
                }}
              >
                View Batch
                <span aria-hidden="true"> &rarr;</span>
              </button>
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-blue-50 p-1.5 text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDialog && (
        <BulkVerifyBenefitsDialog
          existingWorkflowId={workflowId}
          selected={[]}
          close={() => {
            setShowDialog(false);
          }}
        />
      )}
    </>
  );
};

const BulkVerifyBenefitsWorkflowProgress: React.FC<{
  workflowId: string;
  selected: VerificationWorklistRow[];
  setOpen: (open: boolean) => void;
}> = ({ workflowId, setOpen }) => {
  const queryClient = RQ.useQueryClient();
  const { data, loading, startPolling, stopPolling } = useQuery<
    GetBulkVerifyBenefitsWorkflow,
    GetBulkVerifyBenefitsWorkflowVariables
  >(GET_BULK_VERIFY_BENEFITS_WORKFLOW, {
    variables: {
      workflowId,
    },
    pollInterval: 2000,
  });

  const cursor = data?.getBulkVerifyBenefitsWorkflow?.cursor;
  const batchSize = data?.getBulkVerifyBenefitsWorkflow?.batchSize;
  const currentVerification =
    data?.getBulkVerifyBenefitsWorkflow?.currentVerification;

  const isComplete =
    isDefined(cursor) && isDefined(batchSize) && cursor === batchSize;

  React.useEffect(() => {
    if (isComplete) {
      stopPolling();
      queryClient.invalidateQueries("verificationWorklist");
    }
  }, [isComplete, stopPolling, queryClient]);

  const results = data?.getBulkVerifyBenefitsWorkflow?.results ?? [];
  const active = results.reduce(
    (acc, result) => (result.status === "active" ? acc + 1 : acc),
    0
  );
  const inactive = results.reduce(
    (acc, result) => (result.status === "inactive" ? acc + 1 : acc),
    0
  );
  const error = results.reduce(
    (acc, result) => (result.status === "error" ? acc + 1 : acc),
    0
  );
  const skipped = results.reduce(
    (acc, result) => (result.status === "skipped" ? acc + 1 : acc),
    0
  );

  const progress = batchSize ? (100 * (cursor ?? 0)) / batchSize : 0;

  return (
    <>
      <div className="mt-3 text-center sm:mt-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Benefits Verification Batch
        </h3>
        <div>
          {currentVerification && (
            <div className="flex flex-col gap-1">
              <div className="flex justify-between pt-4">
                <div className="flex gap-2 items-center">
                  <Metric>{cursor}</Metric> complete
                </div>
                <div className="flex gap-2 items-center">
                  of <Metric>{batchSize}</Metric> policies
                </div>
              </div>
              <ProgressBar value={progress} color="emerald" className="mt-1" />
              {!isComplete && (
                <div className="text-left">
                  <div className="border-y py-1 border-gray-100">
                    <div className="flex gap-2">
                      <div>Currently Verifying</div>
                      <div className="font-medium">
                        {
                          currentVerification.insurancePolicy.patient
                            .displayName
                        }
                      </div>
                    </div>
                    <InsurancePolicyRow
                      priority={currentVerification.insurancePolicy.priority}
                      insurancePolicy={currentVerification.insurancePolicy}
                      patientId={currentVerification.insurancePolicy.patient.id}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <List className="my-4">
            <ListItem>
              <div className="flex justify-start items-center truncate space-x-4">
                <Icon
                  variant="light"
                  icon={CheckCircleIcon}
                  size="md"
                  color="green"
                />
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>Active</Bold>
                  </Text>
                </div>
              </div>
              <Text>{active}</Text>
            </ListItem>
            <ListItem>
              <div className="flex justify-start items-center truncate space-x-4">
                <Icon
                  variant="light"
                  icon={ExclamationCircleIcon}
                  size="md"
                  color="yellow"
                />
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>Inactive</Bold>
                  </Text>
                </div>
              </div>
              <Text>{inactive}</Text>
            </ListItem>
            <ListItem>
              <div className="flex justify-start items-center truncate space-x-4">
                <Icon
                  variant="light"
                  icon={XCircleIcon}
                  size="md"
                  color="red"
                />
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>Error</Bold>
                  </Text>
                </div>
              </div>
              <Text>{error}</Text>
            </ListItem>
            <ListItem>
              <div className="flex justify-start items-center truncate space-x-4">
                <Icon
                  variant="light"
                  icon={MinusCircleIcon}
                  size="md"
                  color="gray"
                />
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>Skipped</Bold>
                  </Text>
                </div>
              </div>
              <Text>{skipped}</Text>
            </ListItem>
          </List>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Close
        </button>
      </div>
    </>
  );
};

export const BulkVerifyBenefitsDialog = ({
  close,
  selected,
  existingWorkflowId,
}: {
  close: () => void;
  selected: VerificationWorklistRow[];
  // If an existing workflow id is provided, the dialog will show the progress immediately
  existingWorkflowId?: string;
}) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const [bulkVerifyBenefits, bulkVerifyBenefitsResult] = useMutation<
    BulkVerifyBenefits,
    BulkVerifyBenefitsVariables
  >(BULK_VERIFY_BENEFITS);
  const verifyBenefitItems = selected.filter(
    (row) => row.verificationWorkflowStatus.stage === WorkflowStage.Todo
  );

  const workflowId =
    existingWorkflowId ??
    bulkVerifyBenefitsResult.data?.bulkVerifyBenefits?.workflowId;

  return (
    <Modal open={true} setOpen={close}>
      <div>
        {!bulkVerifyBenefitsResult.loading && workflowId ? (
          <BulkVerifyBenefitsWorkflowProgress
            workflowId={workflowId}
            selected={selected}
            setOpen={() => close()}
          />
        ) : (
          <>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Verify benefits for {verifyBenefitItems.length} policies?
              </h3>
            </div>
            <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={() => close()}
              >
                Close
              </button>
              <SubmitButton
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                loading={bulkVerifyBenefitsResult.loading}
                onClick={() => {
                  bulkVerifyBenefits({
                    variables: {
                      appointmentPolicies: verifyBenefitItems.map((item) => ({
                        appointmentId: item.nextAppointment?.id ?? null,
                        insurancePolicyId: item.policy.id,
                      })),
                    },
                    onCompleted: () => {
                      analytics?.track("Policies Bulk Verified", {
                        organizationId: user.organization.id,
                        organizationName: user.organization.name,
                        locationId: user.activeLocation.id,
                        locationName: user.activeLocation.name,
                        count: verifyBenefitItems.length,
                      });
                      toast.success("Successfully verified benefits");
                    },
                    onError: (e) => {
                      toast.error("Failed to verify benefits");
                    },
                  });
                }}
              >
                Confirm
              </SubmitButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export const BulkWorklistItemActionMenu: React.FC<{
  worklistRows: VerificationWorklistRow[];
  trigger: React.ReactNode;
  // After successful compltion of the bulk action, this callback is called
  // to reset the table selection and refresh the worklist
  onClose: () => void;
}> = ({ worklistRows, trigger, onClose }) => {
  const queryClient = RQ.useQueryClient();
  const user = useUser()!;
  const analytics = useAnalytics();
  const [bulkVerifyBenefitsDialogOpen, setBulkVerifyBenefitsDialogOpen] =
    useState(false);
  const [bulkConfirmBenefitsDialogOpen, setBulkConfirmBenefitsDialogOpen] =
    useState(false);

  const [
    acceptMultipleInsurancePolicies,
    acceptMultipleInsurancePoliciesResult,
  ] = useMutation<
    AcceptMultipleInsurancePolicies,
    AcceptMultipleInsurancePoliciesVariables
  >(ACCEPT_MULTIPLE_INSURANCE_POLICIES);
  const confirmablePolicies = worklistRows.filter(
    (item) => !isDefined(item.acceptedAt)
  );

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[150px]">
          <DropdownMenuItem
            onClick={() => {
              setBulkVerifyBenefitsDialogOpen(true);
            }}
          >
            Verify Benefits
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              setBulkConfirmBenefitsDialogOpen(true);
            }}
          >
            Confirm Benefits
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {bulkVerifyBenefitsDialogOpen && (
        <BulkVerifyBenefitsDialog
          close={() => {
            setBulkVerifyBenefitsDialogOpen(false);
            onClose();
          }}
          selected={worklistRows}
        />
      )}
      {bulkConfirmBenefitsDialogOpen && (
        <ConfirmationDialog
          setOpen={(open) => {
            setBulkConfirmBenefitsDialogOpen(open);
            if (!open) onClose();
          }}
          selected={worklistRows}
          loading={acceptMultipleInsurancePoliciesResult.loading}
          onConfirm={() => {
            acceptMultipleInsurancePolicies({
              variables: {
                insurancePolicyIds: confirmablePolicies.map(
                  (item) => item.policy.id
                ),
              },
              onCompleted: () => {
                toast.success("Confirmed policies");
                analytics?.track("Policies Bulk Confirmed", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                  count: confirmablePolicies.length,
                });
                setBulkConfirmBenefitsDialogOpen(false);
                queryClient.invalidateQueries("verificationWorklist");
              },
              onError: () => {
                toast.error("Error confirming policies");
              },
            });
          }}
          title={(selected) => (
            <div className="flex gap-2 items-center text-xl font-medium">
              Confirm {confirmablePolicies.length} patients from worklist?
            </div>
          )}
          children={() => (
            <div className="flex gap-2 items-center">
              <div>
                {confirmablePolicies.length} policies will be confirmed.
              </div>
            </div>
          )}
        />
      )}
    </>
  );
};

const GET_RUNNING_BULK_VERIFY_BENEFITS_WORKFLOWS_FOR_LOCATION = gql`
  query GetRunningBulkVerifyBenefitsWorkflowsForLocation {
    getRunningBulkVerifyBenefitsWorkflowsForLocation
  }
`;

const CANCEL_MULTIPLE_INSURANCE_POLICY_VERIFICATION_WORKFLOWS = gql`
  mutation CancelMultipleInsurancePolicyVerificationWorkflows(
    $insurancePolicyIds: [String!]!
  ) {
    cancelMultipleInsurancePolicyVerificationWorkflows(
      insurancePolicyIds: $insurancePolicyIds
    ) {
      id
      verificationWorkflowStatus {
        id
        stage
        name
      }
    }
  }
`;

const UPDATE_MULTIPLE_INSURANCE_POLICY_VERIFICATION_WORKFLOW_STATUSES = gql`
  mutation UpdateMultipleInsurancePolicyVerificationWorkflowStatuses(
    $insurancePolicyIds: [String!]!
    $statusId: String!
  ) {
    updateMultipleInsurancePolicyVerificationWorkflowStatuses(
      insurancePolicyIds: $insurancePolicyIds
      statusId: $statusId
    ) {
      id
      verificationWorkflowStatus {
        id
        stage
        name
      }
    }
  }
`;

const BulkUpdateStatusDialog = ({
  setOpen,
  selected,
  statuses,
  onComplete,
}: {
  setOpen: (open: boolean) => void;
  selected: VerificationWorklistRow[];
  statuses: VerificationWorkflowStatus[];
  onComplete: () => void;
}) => {
  const [
    updateMultipleInsurancePolicyVerificationWorkflowStatuses,
    updateMultipleInsurancePolicyVerificationWorkflowStatusesResult,
  ] = useMutation(
    UPDATE_MULTIPLE_INSURANCE_POLICY_VERIFICATION_WORKFLOW_STATUSES
  );
  const [statusId, setStatusId] = useState<string>();
  const isValid = isDefined(statusId);
  return (
    <Modal open={true} setOpen={setOpen}>
      <div>
        <div className="mt-3 sm:mt-5">
          <h3 className="text-center text-lg leading-6 font-medium text-gray-900">
            Update status for {selected.length} policies?
          </h3>
          <div className="mt-2 py-4">
            <label
              htmlFor="statusId"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <Select value={statusId} onValueChange={setStatusId}>
              <SelectTrigger>
                <SelectValue placeholder={`Select a status`} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {statuses.map((s) => (
                    <SelectItem value={s.id}>{s.name}</SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
          <SubmitButton
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            loading={
              updateMultipleInsurancePolicyVerificationWorkflowStatusesResult.loading
            }
            onClick={() => {
              updateMultipleInsurancePolicyVerificationWorkflowStatuses({
                variables: {
                  insurancePolicyIds: selected.map((item) => item.id),
                  statusId,
                },
                onCompleted: () => {
                  toast.success("Updated policies");
                  setOpen(false);
                  onComplete();
                },
                onError: () => {
                  toast.error("Error updating policies");
                },
              });
            }}
          >
            Confirm
          </SubmitButton>
        </div>
      </div>
    </Modal>
  );
};

export const VerificationWorkflowStatusIndicator: React.FC<{
  status: { stage: WorkflowStage };
}> = ({ status }) => {
  return (
    <StatusIndicator
      colorClass={
        status.stage === WorkflowStage.Todo
          ? "bg-gray-400"
          : status.stage === WorkflowStage.InProgress
          ? "bg-yellow-500"
          : status.stage === WorkflowStage.Complete
          ? "bg-green-500"
          : status.stage === WorkflowStage.Canceled
          ? "bg-red-500"
          : "bg-gray-400"
      }
    />
  );
};

export const InsurancePolicyWorklist: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const user = useUser()!;
  const queryClient = RQ.useQueryClient();
  const apollo = useApolloClient();
  const [bulkRemoveDialogOpen, setBulkRemoveDialogOpen] = useState(false);
  const [bulkUpdateStatusDialogOpen, setBulkUpdateStatusDialogOpen] =
    useState(false);
  const [bulkVerifyBenefitsDialogOpen, setBulkVerifyBenefitsDialogOpen] =
    useState(false);
  const [bulkConfirmBenefitsDialogOpen, setBulkConfirmBenefitsDialogOpen] =
    useState(false);
  const { setPanelChild } = useDashboardResizable();

  const runningBulkVerifyWorkflowsResult =
    useQuery<GetRunningBulkVerifyBenefitsWorkflowsForLocation>(
      GET_RUNNING_BULK_VERIFY_BENEFITS_WORKFLOWS_FOR_LOCATION
    );

  const { data, loading } = useQuery<
    GetVerificationWorkflowPolicies,
    GetVerificationWorkflowPoliciesVariables
  >(GET_VERIFICATION_WORKFLOW_POLICIES, {
    variables: {
      locationId: user.activeLocation.id,
    },
    fetchPolicy: "cache-only",
  });

  const worklistQuery = RQ.useQuery({
    queryKey: ["verificationWorklist"],
    queryFn: () =>
      fetch(`${constants.VITE_GRAPHQL_URL}/api/worklists/verifications`, {
        credentials: "include",
        headers: {
          "x-pledge-demo": window.localStorage.getItem("demo") ?? "false",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          apollo.writeQuery<
            GetVerificationWorkflowPolicies,
            GetVerificationWorkflowPoliciesVariables
          >({
            query: GET_VERIFICATION_WORKFLOW_POLICIES,
            variables: {
              locationId: user.activeLocation.id,
            },
            data: res,
            overwrite: true,
          });
        }),
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const [
    cancelMultipleInsurancePolicyVerificationWorkflows,
    cancelMultipleInsurancePolicyVerificationWorkflowsResult,
  ] = useMutation<
    CancelMultipleInsurancePolicyVerificationWorkflows,
    CancelMultipleInsurancePolicyVerificationWorkflowsVariables
  >(CANCEL_MULTIPLE_INSURANCE_POLICY_VERIFICATION_WORKFLOWS);
  const [
    acceptMultipleInsurancePolicies,
    acceptMultipleInsurancePoliciesResult,
  ] = useMutation<
    AcceptMultipleInsurancePolicies,
    AcceptMultipleInsurancePoliciesVariables
  >(ACCEPT_MULTIPLE_INSURANCE_POLICIES);

  const rows = data?.verificationWorkflowPolicies ?? [];

  const tableData: VerificationWorklistRow[] = rows.map((row) => {
    const policy = row;
    const patientName = policy.patient.displayName;
    const mostRecentEligibility = policy.mostRecentEligibilityRequest;
    const firstTracked = policy.mostRecentCoverageBenefits
      .map((cb) => parseISO(cb.createdAt))
      .flat()
      .sort(compareDesc)
      .at(0);
    let lastVerified = mostRecentEligibility?.createdAt
      ? parseISO(mostRecentEligibility.createdAt)
      : null;
    const nextAppointment = row.nextAppointment;
    let nextAppointmentStart = nextAppointment?.start
      ? parseISO(nextAppointment?.start)
      : null;
    const coverageBenefits = policy.mostRecentCoverageBenefits;
    const requested = coverageBenefits.length;
    const nonEmpty = coverageBenefits.filter((s) => !s.empty).length;
    const completeness = requested === 0 ? null : nonEmpty / requested;
    return {
      id: row.id,
      item: row,
      status: row.verificationWorkflowStatus.name,
      verificationWorkflowStatus: row.verificationWorkflowStatus,
      lastVerificationWorkflowReason: row.lastVerificationWorkflowReason,
      date: parseISO(row.verificationWorkflowActiveAt!),
      patientName,
      policy,
      payerName: policy.payer.name,
      firstTracked,
      lastVerified,
      completeness,
      memberId: policy.memberId,
      eligibilityStatus: policy.insurancePolicyVerificationStatus,
      mostRecentEligibility,
      nextAppointment,
      nextAppointmentStart,
    };
  });

  const runningWorkflows =
    runningBulkVerifyWorkflowsResult.data
      ?.getRunningBulkVerifyBenefitsWorkflowsForLocation ?? [];

  const locationVerificationWorkflowStatuses =
    data?.location?.verificationWorkflowStatuses ?? [];

  const statuses = locationVerificationWorkflowStatuses.map((s) => ({
    id: s.id,
    name: s.name,
    stage: s.stage,
    count: rows.filter((row) => row.verificationWorkflowStatus.id === s.id)
      .length,
  }));

  return (
    <div className="flex flex-col gap-8">
      {runningWorkflows.length > 0 && (
        <HorizontalPadding>
          <div className="flex flex-col gap-2 w-full">
            {runningWorkflows.map((workflowId) => (
              <BulkVerifyBenefitsWorkflowProgressAlert
                key={workflowId}
                workflowId={workflowId}
              />
            ))}
          </div>
        </HorizontalPadding>
      )}
      <DataTable
        data={tableData}
        columns={columns}
        loading={loading || worklistQuery.isLoading}
        // loadingMore={networkStatus === NetworkStatus.fetchMore}
        defaultColumnFilters={[
          {
            id: "status",
            // TODO: Pull the initial workflow status for the location
            value: ["Unverified"],
          },
        ]}
      >
        {(table) => {
          const selectedRows = table.getSelectedRowModel().rows;

          return (
            <>
              <HorizontalPadding>
                <div className="flex gap-4">
                  {statuses.map((status) => (
                    <Card
                      key={status.id}
                      onClick={() => {
                        table
                          .getColumn("status")
                          ?.setFilterValue([status.name]);
                      }}
                      className="min-w-[12rem] hover:bg-gray-50 hover:cursor-pointer"
                    >
                      <div className="w-full flex flex-col gap-2 text-center">
                        <div className="flex justify-center items-center gap-2">
                          <VerificationWorkflowStatusIndicator
                            status={status}
                          />
                          {status.name}
                        </div>
                        <div className="text-xl">{status.count}</div>
                      </div>
                    </Card>
                  ))}
                </div>
              </HorizontalPadding>

              <div className="fixed bottom-0 right-0 w-full z-10">
                <BottomDrawer
                  open={selectedRows.length > 0}
                  setOpen={() => table.resetRowSelection()}
                  selectedRows={selectedRows.map((r) => r.original)}
                  deselect={() => table.resetRowSelection()}
                  selectAll={() => table.toggleAllRowsSelected()}
                  allSelected={table.getIsAllRowsSelected()}
                  ActionButtons={
                    <>
                      <SubmitButton
                        type="button"
                        loading={
                          cancelMultipleInsurancePolicyVerificationWorkflowsResult.loading
                        }
                        onClick={() => {
                          setBulkRemoveDialogOpen(true);
                        }}
                        className="inline-flex items-center justify-center bg-red-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                      >
                        Cancel
                      </SubmitButton>

                      <DownloadPoliciesNeedingVerificationWorklistButton
                        rows={selectedRows.map((row) => row.original)}
                      />

                      <Button
                        className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                        onClick={() => {
                          setPanelChild(
                            <VerificationSidePanel
                              ids={selectedRows.map((row) => row.original.id)}
                            />
                          );
                          table.resetRowSelection();
                        }}
                        type="button"
                      >
                        Execute {selectedRows.length} Tasks
                        <SkipForward className="ml-2 h-4 w-4" />
                      </Button>

                      {selectedRows.every(
                        (r) =>
                          r.original.verificationWorkflowStatus.stage ===
                          WorkflowStage.Todo
                      ) && (
                        <Button
                          onClick={() => {
                            setBulkVerifyBenefitsDialogOpen(true);
                          }}
                          className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                        >
                          Verify Benefits
                        </Button>
                      )}

                      {selectedRows.every(
                        (r) =>
                          r.original.verificationWorkflowStatus.stage ===
                          WorkflowStage.InProgress
                      ) && (
                        <Button
                          onClick={() => {
                            setBulkConfirmBenefitsDialogOpen(true);
                          }}
                          className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                        >
                          Confirm Benefits
                        </Button>
                      )}

                      <Button
                        onClick={() => {
                          setBulkUpdateStatusDialogOpen(true);
                        }}
                        className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                      >
                        Update Status
                      </Button>
                    </>
                  }
                />
              </div>
              {bulkRemoveDialogOpen && (
                <ConfirmationDialog
                  setOpen={setBulkRemoveDialogOpen}
                  selected={selectedRows}
                  loading={
                    cancelMultipleInsurancePolicyVerificationWorkflowsResult.loading
                  }
                  onConfirm={(selected) => {
                    cancelMultipleInsurancePolicyVerificationWorkflows({
                      variables: {
                        insurancePolicyIds: selected.map((r) => r.original.id),
                      },
                      onCompleted: () => {
                        toast.success("Removed from worklist");
                        table.resetRowSelection();
                        setBulkRemoveDialogOpen(false);
                        queryClient.invalidateQueries("verificationWorklist");
                      },
                      onError: () => {
                        toast.error("Error removing from worklist");
                      },
                    });
                  }}
                  title={(selected) => (
                    <div className="flex gap-2 items-center text-xl font-medium">
                      Remove {selected.length} patients from worklist?
                    </div>
                  )}
                  children={() => (
                    <div className="flex gap-2 items-center">
                      <div>
                        {selectedRows.length} patients will be removed from the
                        worklist.
                      </div>
                    </div>
                  )}
                />
              )}
              {bulkVerifyBenefitsDialogOpen && (
                <BulkVerifyBenefitsDialog
                  close={() => {
                    setBulkVerifyBenefitsDialogOpen(false);
                    table.resetRowSelection();
                  }}
                  selected={selectedRows.map((r) => r.original)}
                />
              )}
              {bulkConfirmBenefitsDialogOpen && (
                <ConfirmationDialog
                  setOpen={(open) => {
                    setBulkConfirmBenefitsDialogOpen(open);
                    table.resetRowSelection();
                  }}
                  selected={selectedRows.map((r) => r.original)}
                  loading={acceptMultipleInsurancePoliciesResult.loading}
                  onConfirm={() => {
                    acceptMultipleInsurancePolicies({
                      variables: {
                        insurancePolicyIds: selectedRows.map(
                          (r) => r.original.id
                        ),
                      },
                      onCompleted: () => {
                        toast.success("Confirmed policies");
                        setBulkConfirmBenefitsDialogOpen(false);
                        queryClient.invalidateQueries("verificationWorklist");
                        table.resetRowSelection();
                      },
                      onError: () => {
                        toast.error("Error confirming policies");
                      },
                    });
                  }}
                  title={(selected) => (
                    <div className="flex gap-2 items-center text-xl font-medium">
                      Confirm {selectedRows.length} patients from worklist?
                    </div>
                  )}
                  children={() => (
                    <div className="flex gap-2 items-center">
                      <div>
                        {selectedRows.length} policies will be confirmed.
                      </div>
                    </div>
                  )}
                />
              )}
              {bulkUpdateStatusDialogOpen && (
                <BulkUpdateStatusDialog
                  setOpen={setBulkUpdateStatusDialogOpen}
                  selected={selectedRows.map((r) => r.original)}
                  statuses={locationVerificationWorkflowStatuses}
                  onComplete={() => {
                    table.resetRowSelection();
                  }}
                />
              )}
            </>
          );
        }}
      </DataTable>
    </div>
  );
};

const DownloadPoliciesNeedingVerificationWorklistButton: React.FC<
  React.PropsWithChildren<{
    rows: VerificationWorklistRow[];
  }>
> = ({ rows }) => {
  const user = useUser()!;
  const analytics = useAnalytics();

  const headerRow = [
    "Date",
    "Patient",
    "Account(s)",
    "Reason",
    "Next Appointment",
    "Member ID(s)",
    "Payer(s)",
    "Verification Status(s)",
    "Last Verified(s)",
    "Completeness Score(s)",
    "Services with Benefits",
    "Services without Benefits",
  ];
  const csvRows = rows.map((row) => {
    return [
      formatDateMMDDYYYY(row.date.toISOString()),
      row.patientName,
      row.policy.accounts
        .filter((a) => a.accountType)
        .map((a) => a.accountType!.name)
        .join(", "),
      row.lastVerificationWorkflowReason,
      row.nextAppointmentStart
        ? formatDateMMDDYYYY(row.nextAppointmentStart.toISOString())
        : null,
      row.memberId ?? "N/A",
      row.payerName,
      row.policy.insurancePolicyVerificationStatus,
      mapNullable(formatDateMMDDYYYY)(
        row.policy.mostRecentEligibilityRequest?.createdAt
      ) ?? "N/A",
      row.completeness,
      row.policy.mostRecentCoverageBenefits
        .filter((cb) => cb.empty === false)
        .map((cb) => cb.providerServiceConfiguration.name)
        .join(", "),
      row.policy.mostRecentCoverageBenefits
        .filter((cb) => cb.empty === true)
        .map((cb) => cb.providerServiceConfiguration.name)
        .join(", "),
    ];
  });

  return (
    <>
      <CSVLink
        data={[headerRow, ...csvRows]}
        filename="policies-needing-verification-worklist.csv"
        className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
        onClick={() => {
          analytics?.track("New Patients Worklist Downloaded", {
            organizationId: user.organization.id,
            organizationName: user.organization.name,
            locationId: user.activeLocation.id,
            locationName: user.activeLocation.name,
          });
        }}
        target="_blank"
      >
        Download
        <DocumentDownloadIcon
          className="ml-2 -mr-1 h-5 w-5"
          aria-hidden="true"
        />
      </CSVLink>
    </>
  );
};

export const BottomDrawer: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    selectedRows: VerificationWorklistRow[];
    selectAll: () => void;
    deselect: () => void;
    allSelected: boolean;
    ActionButtons: React.ReactNode;
  }>
> = ({
  open,
  setOpen,
  selectedRows,
  selectAll,
  allSelected,
  deselect,
  ActionButtons,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <div>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div className="flex h-full flex-col bg-indigo-600 text-white py-6 shadow-xl">
            <div className="px-4 sm:px-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 pl-16">
                  <div className="contents gap-1">
                    {selectedRows.length} patients
                    <span className="text-gray-300">selected</span>
                  </div>
                  {!allSelected && (
                    <button
                      onClick={selectAll}
                      className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                    >
                      Select All
                    </button>
                  )}
                  <button
                    onClick={deselect}
                    className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                  >
                    Deselect
                  </button>
                </div>
                <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                  {ActionButtons}
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};
