import React from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../components/ui/command";
import { Check, ChevronsUpDown } from "lucide-react";
import { Input } from "../../components/ui/input";
import { Badge } from "../../components/ui/badge";
import fuzzysort from "fuzzysort";
import { Button } from "../../components/ui/button";
import { cn, isDefined } from "../../utils";
import { AttributeTypes, Visit } from "../../evaluate-rule";
import { Comparison } from "../../generated/globalTypes";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { SearchableCombobox } from "../../components/ui/searchable-combobox";
import { CodeCodeset } from "../../generated/BenefitMappingFormOptions";
import { CodeCategoryCombobox } from "../../components/ui/cpt-code-category-combobox";
import { CodeSection } from "../../generated/BenefitMappingFormOptions";

type ChargemasterGroup = {
  id: string;
  code: string;
  description: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
};

const ChargemasterGroupDisplay: React.FC<{
  chargemasterGroup: ChargemasterGroup;
}> = ({ chargemasterGroup }) => {
  const modifiers = [
    chargemasterGroup.modifier1,
    chargemasterGroup.modifier2,
    chargemasterGroup.modifier3,
    chargemasterGroup.modifier4,
  ].filter((m) => m);
  return (
    <>
      {chargemasterGroup.code} - {chargemasterGroup.description}{" "}
      {modifiers.length > 0 && <>({modifiers.join(" | ")})</>}
    </>
  );
};

export const ChargemasterGroupCombobox: React.FC<{
  value?: string[];
  chargemasterGroups: ChargemasterGroup[];
  onSelect: (chargemasterGroupIds: string[]) => void;
  isMulti?: boolean;
}> = ({ value: val, chargemasterGroups, onSelect, isMulti }) => {
  const value = val ?? [];
  const selected = chargemasterGroups.filter((cg) => value.includes(cg.id));
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "justify-between w-full h-fit min-w-10",
            value.length > 0 && "text-muted-foreground"
          )}
        >
          {selected.length > 0 ? (
            <div className="flex gap-1 flex-wrap">
              {selected.map((cg) => (
                <Badge
                  key={cg.id}
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  <ChargemasterGroupDisplay chargemasterGroup={cg} />
                </Badge>
              ))}
            </div>
          ) : (
            "Select charge"
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-full"
        side="bottom"
        avoidCollisions={false}
      >
        <Command
          filter={(item, search) => {
            const name = parseValue(item);
            const result = fuzzysort.single(search, name);
            const normalized = normalizeScore(result?.score ?? null);
            return normalized;
          }}
        >
          <CommandInput placeholder="Search charge..." />
          <CommandEmpty>No charges found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-y-auto">
            {chargemasterGroups.map((cg) => (
              <CommandItem
                value={`${cg.id}:${cg.code}${cg.description}`}
                key={cg.id}
                onSelect={() => {
                  // If isMulti then append selection to list
                  if (isMulti) {
                    if (value.includes(cg.id)) {
                      onSelect(value.filter((v) => !v.includes(cg.id)));
                    } else {
                      onSelect([...value, cg.id]);
                    }
                  }
                  // If not isMulti then replace list with selection
                  else {
                    if (value.includes(cg.id)) {
                      onSelect([]);
                    } else {
                      onSelect([cg.id]);
                    }
                  }
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value.includes(cg.id) ? "opacity-100" : "opacity-0"
                  )}
                />
                <ChargemasterGroupDisplay chargemasterGroup={cg} />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const parseValue = (value: string) => {
  const [_val, label] = value.split(":");
  return label;
};
const MIN_SCORE = -3000;
const normalizeScore = (score: number | null) => {
  if (score === null || score < MIN_SCORE) return 0;
  return (score - MIN_SCORE) / -MIN_SCORE;
};

export const PayerCombobox: React.FC<{
  value?: string[];
  payers: { id: string; name: string }[];
  onSelect: (payersIds: string[]) => void;
  isMulti?: boolean;
}> = ({ value: vString, onSelect, payers, isMulti }) => {
  const value = vString ?? [];
  const selected = payers.filter((p) => value.includes(p.id));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "justify-between w-full h-fit min-w-10",
            value.length > 0 && "text-muted-foreground"
          )}
        >
          {selected.length > 0 ? (
            <div className="flex gap-1 flex-wrap">
              {selected.map((p) => (
                <Badge
                  key={p.id}
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {p.name}
                </Badge>
              ))}
            </div>
          ) : (
            "Select payer"
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-full"
        side="bottom"
        avoidCollisions={false}
      >
        <Command
          filter={(item, search) => {
            const name = parseValue(item);
            const result = fuzzysort.single(search, name);
            const normalized = normalizeScore(result?.score ?? null);
            return normalized;
          }}
        >
          <CommandInput placeholder="Search payers..." />
          <CommandEmpty>No payers found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-y-auto">
            {payers.map((p) => (
              <CommandItem
                value={`${p.id}:${p.name}`}
                key={p.id}
                onSelect={() => {
                  // If isMulti then append selection to list
                  if (isMulti) {
                    if (value.includes(p.id)) {
                      onSelect(value.filter((v) => !v.includes(p.id)));
                    } else {
                      onSelect([...value, p.id]);
                    }
                  }
                  // If not isMulti then replace list with selection
                  else {
                    if (value.includes(p.id)) {
                      onSelect([]);
                    } else {
                      onSelect([p.id]);
                    }
                  }
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value.includes(p.id) ? "opacity-100" : "opacity-0"
                  )}
                />
                {p.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export const ProviderCombobox: React.FC<{
  value?: string[];
  providers: { id: string; displayName: string }[];
  onSelect: (payersIds: string[]) => void;
  isMulti?: boolean;
}> = ({ value: val, onSelect, providers, isMulti }) => {
  const value = val ?? [];
  const selected = providers.filter((at) => value.includes(at.id));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "justify-between w-full h-fit min-w-10",
            value.length > 0 && "text-muted-foreground"
          )}
        >
          {selected.length > 0 ? (
            <div className="flex gap-1 flex-wrap">
              {selected.map((p) => (
                <Badge
                  key={p.id}
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {p.displayName}
                </Badge>
              ))}
            </div>
          ) : (
            "Select account"
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-full"
        side="bottom"
        avoidCollisions={false}
      >
        <Command
          filter={(item, search) => {
            const name = parseValue(item);
            const result = fuzzysort.single(search, name);
            const normalized = normalizeScore(result?.score ?? null);
            return normalized;
          }}
        >
          <CommandInput placeholder="Search accounts..." />
          <CommandEmpty>No accounts found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-y-auto">
            {providers.map((p) => (
              <CommandItem
                value={`${p.id}:${p.displayName}`}
                key={p.id}
                onSelect={() => {
                  // If isMulti then append selection to list
                  if (isMulti) {
                    if (value.includes(p.id)) {
                      onSelect(value.filter((v) => !v.includes(p.id)));
                    } else {
                      onSelect([...value, p.id]);
                    }
                  }
                  // If not isMulti then replace list with selection
                  else {
                    if (value.includes(p.id)) {
                      onSelect([]);
                    } else {
                      onSelect([p.id]);
                    }
                  }
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value.includes(p.id) ? "opacity-100" : "opacity-0"
                  )}
                />
                {p.displayName}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export const AppointmentTypeCombobox: React.FC<{
  value?: string[];
  appointmentTypes: string[];
  onSelect: (ids: string[]) => void;
  isMulti?: boolean;
}> = ({ value: val, onSelect, appointmentTypes, isMulti }) => {
  const value = val ?? [];
  const selected = appointmentTypes.filter((at) => value.includes(at));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "justify-between w-full h-fit min-w-10",
            value.length > 0 && "text-muted-foreground"
          )}
        >
          {selected.length > 0 ? (
            <div className="flex gap-1 flex-wrap">
              {selected.map((at) => (
                <Badge
                  key={at}
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {at}
                </Badge>
              ))}
            </div>
          ) : (
            "Select provider classification"
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-full"
        side="bottom"
        avoidCollisions={false}
      >
        <Command
          filter={(item, search) => {
            const result = fuzzysort.single(search, item);
            const normalized = normalizeScore(result?.score ?? null);
            return normalized;
          }}
        >
          <CommandInput placeholder="Search appointment types..." />
          <CommandEmpty>No appointment types found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-y-auto">
            {appointmentTypes.map((at) => (
              <CommandItem
                value={at}
                key={at}
                onSelect={() => {
                  // If isMulti then append selection to list
                  if (isMulti) {
                    if (value.includes(at)) {
                      onSelect(value.filter((v) => !v.includes(at)));
                    } else {
                      onSelect([...value, at]);
                    }
                  }
                  // If not isMulti then replace list with selection
                  else {
                    if (value.includes(at)) {
                      onSelect([]);
                    } else {
                      onSelect([at]);
                    }
                  }
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value.includes(at) ? "opacity-100" : "opacity-0"
                  )}
                />
                {at}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export const AppointmentLabelCombobox: React.FC<{
  value?: string[];
  appointmentLabels: { id: string; name: string }[];
  onSelect: (appointmentLabelIds: string[]) => void;
  isMulti?: boolean;
}> = ({ value: val, onSelect, appointmentLabels, isMulti }) => {
  const value = val ?? [];
  const selected = appointmentLabels.filter((at) => value.includes(at.id));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "justify-between w-full h-fit min-w-10",
            value.length > 0 && "text-muted-foreground"
          )}
        >
          {selected.length > 0 ? (
            <div className="flex gap-1 flex-wrap">
              {selected.map((p) => (
                <Badge
                  key={p.id}
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {p.name}
                </Badge>
              ))}
            </div>
          ) : (
            "Select appointment label"
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-full"
        side="bottom"
        avoidCollisions={false}
      >
        <Command
          filter={(item, search) => {
            const name = parseValue(item);
            const result = fuzzysort.single(search, name);
            const normalized = normalizeScore(result?.score ?? null);
            return normalized;
          }}
        >
          <CommandInput placeholder="Search appointment labels..." />
          <CommandEmpty>No appointment label.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-y-auto">
            {appointmentLabels.map((ptc) => (
              <CommandItem
                value={`${ptc.id}:${ptc.name}`}
                key={ptc.id}
                onSelect={() => {
                  // If isMulti then append selection to list
                  if (isMulti) {
                    if (value.includes(ptc.id)) {
                      onSelect(value.filter((v) => !v.includes(ptc.id)));
                    } else {
                      onSelect([...value, ptc.id]);
                    }
                  }
                  // If not isMulti then replace list with selection
                  else {
                    if (value.includes(ptc.id)) {
                      onSelect([]);
                    } else {
                      onSelect([ptc.id]);
                    }
                  }
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value.includes(ptc.id) ? "opacity-100" : "opacity-0"
                  )}
                />
                {ptc.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export const PatientLabelCombobox: React.FC<{
  value?: string[];
  patientLabels: { id: string; name: string }[];
  onSelect: (patientLabelIds: string[]) => void;
  isMulti?: boolean;
}> = ({ value: val, onSelect, patientLabels, isMulti }) => {
  const value = val ?? [];
  const selected = patientLabels.filter((at) => value.includes(at.id));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "justify-between w-full h-fit min-w-10",
            value.length > 0 && "text-muted-foreground"
          )}
        >
          {selected.length > 0 ? (
            <div className="flex gap-1 flex-wrap">
              {selected.map((p) => (
                <Badge
                  key={p.id}
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {p.name}
                </Badge>
              ))}
            </div>
          ) : (
            "Select patient label"
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-full"
        side="bottom"
        avoidCollisions={false}
      >
        <Command
          filter={(item, search) => {
            const name = parseValue(item);
            const result = fuzzysort.single(search, name);
            const normalized = normalizeScore(result?.score ?? null);
            return normalized;
          }}
        >
          <CommandInput placeholder="Search patient labels..." />
          <CommandEmpty>No patient labels found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-y-auto">
            {patientLabels.map((ptc) => (
              <CommandItem
                value={`${ptc.id}:${ptc.name}`}
                key={ptc.id}
                onSelect={() => {
                  // If isMulti then append selection to list
                  if (isMulti) {
                    if (value.includes(ptc.id)) {
                      onSelect(value.filter((v) => !v.includes(ptc.id)));
                    } else {
                      onSelect([...value, ptc.id]);
                    }
                  }
                  // If not isMulti then replace list with selection
                  else {
                    if (value.includes(ptc.id)) {
                      onSelect([]);
                    } else {
                      onSelect([ptc.id]);
                    }
                  }
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value.includes(ptc.id) ? "opacity-100" : "opacity-0"
                  )}
                />
                {ptc.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export const ProviderTaxonomyCodeCombobox: React.FC<{
  value?: string[];
  providerTaxonomyCodes: { id: string; displayName: string }[];
  onSelect: (payersIds: string[]) => void;
  isMulti?: boolean;
}> = ({ value: val, onSelect, providerTaxonomyCodes, isMulti }) => {
  const value = val ?? [];
  const selected = providerTaxonomyCodes.filter((at) => value.includes(at.id));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "justify-between w-full h-fit min-w-10",
            value.length > 0 && "text-muted-foreground"
          )}
        >
          {selected.length > 0 ? (
            <div className="flex gap-1 flex-wrap">
              {selected.map((p) => (
                <Badge
                  key={p.id}
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {p.displayName}
                </Badge>
              ))}
            </div>
          ) : (
            "Select provider classification"
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-full"
        side="bottom"
        avoidCollisions={false}
      >
        <Command
          filter={(item, search) => {
            const name = parseValue(item);
            const result = fuzzysort.single(search, name);
            const normalized = normalizeScore(result?.score ?? null);
            return normalized;
          }}
        >
          <CommandInput placeholder="Search provider classifications..." />
          <CommandEmpty>No provider classifications found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-y-auto">
            {providerTaxonomyCodes.map((ptc) => (
              <CommandItem
                value={`${ptc.id}:${ptc.displayName}`}
                key={ptc.id}
                onSelect={() => {
                  // If isMulti then append selection to list
                  if (isMulti) {
                    if (value.includes(ptc.id)) {
                      onSelect(value.filter((v) => !v.includes(ptc.id)));
                    } else {
                      onSelect([...value, ptc.id]);
                    }
                  }
                  // If not isMulti then replace list with selection
                  else {
                    if (value.includes(ptc.id)) {
                      onSelect([]);
                    } else {
                      onSelect([ptc.id]);
                    }
                  }
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value.includes(ptc.id) ? "opacity-100" : "opacity-0"
                  )}
                />
                {ptc.displayName}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export const AccountTypeCombobox: React.FC<{
  value?: string[];
  accountTypes: { id: string; name: string }[];
  onSelect: (payersIds: string[]) => void;
  isMulti?: boolean;
}> = ({ value: val, onSelect, accountTypes, isMulti }) => {
  const value = val ?? [];
  const selected = accountTypes.filter((at) => value.includes(at.id));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "justify-between w-full h-fit min-w-10",
            value.length > 0 && "text-muted-foreground"
          )}
        >
          {selected.length > 0 ? (
            <div className="flex gap-1 flex-wrap">
              {selected.map((p) => (
                <Badge
                  key={p.id}
                  variant="secondary"
                  className="rounded-sm px-1 font-normal"
                >
                  {p.name}
                </Badge>
              ))}
            </div>
          ) : (
            "Select account"
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-full"
        side="bottom"
        avoidCollisions={false}
      >
        <Command
          filter={(item, search) => {
            const name = parseValue(item);
            const result = fuzzysort.single(search, name);
            const normalized = normalizeScore(result?.score ?? null);
            return normalized;
          }}
        >
          <CommandInput placeholder="Search accounts..." />
          <CommandEmpty>No accounts found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-y-auto">
            {accountTypes.map((at) => (
              <CommandItem
                value={`${at.id}:${at.name}`}
                key={at.id}
                onSelect={() => {
                  // If isMulti then append selection to list
                  if (isMulti) {
                    if (value.includes(at.id)) {
                      onSelect(value.filter((v) => !v.includes(at.id)));
                    } else {
                      onSelect([...value, at.id]);
                    }
                  }
                  // If not isMulti then replace list with selection
                  else {
                    if (value.includes(at.id)) {
                      onSelect([]);
                    } else {
                      onSelect([at.id]);
                    }
                  }
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value.includes(at.id) ? "opacity-100" : "opacity-0"
                  )}
                />
                {at.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export const AttributeInput: React.FC<{
  value: string | string[] | null;
  attributeType: AttributeTypes;
  comparison: Comparison;
  onChange: (value: string | string[]) => void;
  payers: { id: string; name: string }[];
  accountTypes: { id: string; name: string }[];
  providers: { id: string; displayName: string }[];
  providerTaxonomyCodes: { id: string; displayName: string }[];
  appointmentTypes: string[];
  appointmentLabels: { id: string; name: string }[];
  patientLabels: { id: string; name: string }[];
  chargemasterGroups: { id: string; code: string; description: string }[];
  codeCategories: CodeCodeset[];
}> = ({
  value,
  attributeType,
  comparison,
  onChange,
  payers,
  accountTypes,
  providers,
  providerTaxonomyCodes,
  appointmentTypes,
  appointmentLabels,
  patientLabels,
  chargemasterGroups,
  codeCategories,
}) => {
  const isMulti =
    comparison === Comparison.In || comparison === Comparison.NotIn;

  if (comparison === Comparison.Contains || comparison === Comparison.Equals) {
    return (
      <Input
        defaultValue={value as string}
        onBlur={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
    );
  }
  if (
    comparison === Comparison.GreaterThan ||
    comparison === Comparison.LessThan ||
    comparison === Comparison.GreaterThanOrEqual ||
    comparison === Comparison.LessThanOrEqual
  ) {
    return (
      <Input
        type="number"
        defaultValue={value as string}
        onBlur={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
    );
  }
  if (comparison === Comparison.IsMet) {
    return (
      <Select value={value as string} onValueChange={onChange}>
        <SelectTrigger>
          <SelectValue placeholder="" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="true">Yes</SelectItem>
            <SelectItem value="false">No</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    );
  }

  if (isDefined(comparison)) {
    switch (attributeType) {
      case "accountTypeId":
        const accountTypeIds = isDefined(value)
          ? Array.isArray(value)
            ? value
            : [value]
          : undefined;
        return (
          <AccountTypeCombobox
            value={accountTypeIds}
            accountTypes={accountTypes}
            onSelect={(value) => {
              onChange(value);
            }}
            isMulti={isMulti}
          />
        );
      case "appointmentType":
        const selectedAppointmentTypes = isDefined(value)
          ? Array.isArray(value)
            ? value
            : [value]
          : undefined;
        return (
          <AppointmentTypeCombobox
            value={selectedAppointmentTypes}
            appointmentTypes={appointmentTypes}
            onSelect={(value) => {
              onChange(value);
            }}
            isMulti={isMulti}
          />
        );
      case "appointmentLabelId":
        const appointmentLabelIds = isDefined(value)
          ? Array.isArray(value)
            ? value
            : [value]
          : undefined;
        return (
          <AppointmentLabelCombobox
            value={appointmentLabelIds}
            appointmentLabels={appointmentLabels}
            onSelect={(value) => {
              onChange(value);
            }}
            isMulti={isMulti}
          />
        );
      case "patientLabelId":
        const patientLabelIds = isDefined(value)
          ? Array.isArray(value)
            ? value
            : [value]
          : undefined;
        return (
          <PatientLabelCombobox
            value={patientLabelIds}
            patientLabels={patientLabels}
            onSelect={(value) => {
              onChange(value);
            }}
            isMulti={isMulti}
          />
        );
      case "providerTaxonomyCodeId":
        const providerTaxonomyCodeIds = isDefined(value)
          ? Array.isArray(value)
            ? value
            : [value]
          : undefined;
        return (
          <ProviderTaxonomyCodeCombobox
            value={providerTaxonomyCodeIds}
            providerTaxonomyCodes={providerTaxonomyCodes}
            onSelect={(value) => {
              onChange(value);
            }}
            isMulti={isMulti}
          />
        );
      case "providerId":
        const providerIds = isDefined(value)
          ? Array.isArray(value)
            ? value
            : [value]
          : undefined;
        return (
          <ProviderCombobox
            value={providerIds}
            providers={providers}
            onSelect={(value) => {
              onChange(value);
            }}
            isMulti={isMulti}
          />
        );
      case "payerId":
        const payerIds = isDefined(value)
          ? Array.isArray(value)
            ? value
            : [value]
          : undefined;
        return (
          <PayerCombobox
            value={payerIds}
            payers={payers}
            onSelect={(value) => {
              onChange(value);
            }}
            isMulti={isMulti}
          />
        );
      case "chargemasterGroupIds":
        const chargemasterGroupIds = isDefined(value)
          ? Array.isArray(value)
            ? value
            : [value]
          : undefined;
        return (
          <ChargemasterGroupCombobox
            value={chargemasterGroupIds}
            chargemasterGroups={chargemasterGroups}
            onSelect={(value) => {
              onChange(value);
            }}
            isMulti={isMulti}
          />
        );
      case "codeGroups":
        const codeCategory = isDefined(value)
          ? Array.isArray(value)
            ? value
            : [value]
          : undefined;
        return (
          <CodeCategoryCombobox
            placeholder="Search code groups..."
            emptyMessage="No code groups found"
            value={codeCategory ?? []}
            codeCategories={codeCategories}
            onSelect={(value) => {
              onChange(value);
            }}
          />
        );
      case "anyVisitChargemasterGroupIds":
        const anyVisitChargemasterGroupIds = isDefined(value)
          ? Array.isArray(value)
            ? value
            : [value]
          : undefined;
        return (
          <ChargemasterGroupCombobox
            value={anyVisitChargemasterGroupIds}
            chargemasterGroups={chargemasterGroups}
            onSelect={(value) => {
              onChange(value);
            }}
            isMulti={isMulti}
          />
        );
      case "remainingDeductible":
        return (
          <Input
            type="number"
            defaultValue={value as string}
            onBlur={(e) => {
              onChange(e.currentTarget.value);
            }}
          />
        );
      case "remainingOutOfPocket":
        return (
          <Input
            type="number"
            defaultValue={value as string}
            onBlur={(e) => {
              onChange(e.currentTarget.value);
            }}
          />
        );
      case "remainingVisits":
        return (
          <Input
            type="number"
            defaultValue={value as string}
            onBlur={(e) => {
              onChange(e.currentTarget.value);
            }}
          />
        );
      case "inNetwork":
        return (
          <Select value={value as string} onValueChange={onChange}>
            <SelectTrigger>
              <SelectValue placeholder="" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="true">Yes</SelectItem>
                <SelectItem value="false">No</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        );
    }
  }
  return (
    <Input disabled={true} placeholder="Select an attribute and comparison" />
  );
};
