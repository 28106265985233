/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccumulatorAdjustmentType {
  Adjudication = "Adjudication",
  Estimate = "Estimate",
  Visit = "Visit",
}

export enum AdjustmentType {
  BalanceTransfer = "BalanceTransfer",
  Discount = "Discount",
  Other = "Other",
  Refund = "Refund",
  WriteOff = "WriteOff",
}

export enum AppointmentBenefitStatus {
  Assigned = "Assigned",
  Tracked = "Tracked",
  Untracked = "Untracked",
}

export enum AppointmentStatus {
  Booked = "Booked",
  Canceled = "Canceled",
  CheckedIn = "CheckedIn",
  Completed = "Completed",
  NoShow = "NoShow",
  Rescheduled = "Rescheduled",
}

export enum AuthorizationRequirement {
  NO = "NO",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  UNKNOWN = "UNKNOWN",
  YES = "YES",
}

export enum BenefitNoteRuleActionType {
  Downrank = "Downrank",
  Exclude = "Exclude",
  Include = "Include",
  Uprank = "Uprank",
}

export enum BenefitNoteRuleMatchType {
  Contains = "Contains",
  DoesNotContain = "DoesNotContain",
}

export enum BillCollectionMode {
  Bill = "Bill",
  Deposit = "Deposit",
  Estimate = "Estimate",
  Unestimated = "Unestimated",
}

export enum BillCommunicationState {
  Failed = "Failed",
  NotShared = "NotShared",
  Sent = "Sent",
  Sharing = "Sharing",
  Viewed = "Viewed",
}

export enum BillState {
  Archived = "Archived",
  Estimated = "Estimated",
  InReview = "InReview",
  Pending = "Pending",
  Ready = "Ready",
  Reconciled = "Reconciled",
  Resolved = "Resolved",
}

export enum BirthSex {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum ChangePayerConnectivityType {
  BOTH = "BOTH",
  ELECTRONIC = "ELECTRONIC",
  NO_CONNECTION = "NO_CONNECTION",
  PORTAL = "PORTAL",
}

export enum ChargeInsuranceState {
  Billed = "Billed",
  Pending = "Pending",
  Processed = "Processed",
}

export enum Clearinghouse {
  Availity = "Availity",
  Change = "Change",
}

export enum CollectionRequestType {
  Deposit = "Deposit",
  PostVisit = "PostVisit",
  PreVisit = "PreVisit",
}

export enum CommercialType {
  EPO = "EPO",
  HMO = "HMO",
  INDEMNITY = "INDEMNITY",
  POS = "POS",
  PPO = "PPO",
}

export enum CommunicationContentType {
  AUTOPAY_FAILURE_NOTIFICATION = "AUTOPAY_FAILURE_NOTIFICATION",
  AUTOPAY_NOTIFICATION = "AUTOPAY_NOTIFICATION",
  BENEFITS = "BENEFITS",
  BILL = "BILL",
  DUE_DATE_BILL_REMINDER = "DUE_DATE_BILL_REMINDER",
  ESTIMATE = "ESTIMATE",
  FIFTEEN_DAYS_AFTER_DUE_BILL_REMINDER = "FIFTEEN_DAYS_AFTER_DUE_BILL_REMINDER",
  FORTY_FIVE_DAYS_AFTER_DUE_BILL_REMINDER = "FORTY_FIVE_DAYS_AFTER_DUE_BILL_REMINDER",
  NINETY_DAYS_AFTER_DUE_BILL_REMINDER = "NINETY_DAYS_AFTER_DUE_BILL_REMINDER",
  PRE_VISIT_REMINDER = "PRE_VISIT_REMINDER",
  REMINDER = "REMINDER",
  SEVEN_DAYS_BEFORE_DUE_BILL_REMINDER = "SEVEN_DAYS_BEFORE_DUE_BILL_REMINDER",
  SIXTEEN_DAYS_BEFORE_DUE_BILL_REMINDER = "SIXTEEN_DAYS_BEFORE_DUE_BILL_REMINDER",
  SIXTY_DAYS_AFTER_DUE_BILL_REMINDER = "SIXTY_DAYS_AFTER_DUE_BILL_REMINDER",
  THIRTY_DAYS_AFTER_DUE_BILL_REMINDER = "THIRTY_DAYS_AFTER_DUE_BILL_REMINDER",
  TIME_OF_SERVICE_PAYMENT_REQUEST = "TIME_OF_SERVICE_PAYMENT_REQUEST",
  TWENTY_SIX_DAYS_BEFORE_DUE_BILL_REMINDER = "TWENTY_SIX_DAYS_BEFORE_DUE_BILL_REMINDER",
}

export enum CommunicationErrorType {
  BLOCKED = "BLOCKED",
  BOUNCED = "BOUNCED",
  DROPPED = "DROPPED",
  FAILED = "FAILED",
  UNDELIVERED = "UNDELIVERED",
  UNKNOWN = "UNKNOWN",
}

export enum CommunicationPreference {
  EMAIL = "EMAIL",
  TEXT = "TEXT",
}

export enum CommunicationType {
  EMAIL = "EMAIL",
  TEXT = "TEXT",
}

export enum Comparison {
  Contains = "Contains",
  Equals = "Equals",
  GreaterThan = "GreaterThan",
  GreaterThanOrEqual = "GreaterThanOrEqual",
  In = "In",
  IsMet = "IsMet",
  LessThan = "LessThan",
  LessThanOrEqual = "LessThanOrEqual",
  NotEquals = "NotEquals",
  NotIn = "NotIn",
}

export enum CoveragePriority {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
}

export enum EligibilityRequestStatus {
  CommunicationError = "CommunicationError",
  Complete = "Complete",
  InProgress = "InProgress",
  RequestError = "RequestError",
}

export enum EstimateStatus {
  Canceled = "Canceled",
  Draft = "Draft",
  Finalized = "Finalized",
  Invoiced = "Invoiced",
  Issued = "Issued",
  Superceded = "Superceded",
  Voided = "Voided",
}

export enum EstimateType {
  PostVisit = "PostVisit",
  PreVisit = "PreVisit",
}

export enum FeeType {
  Platform = "Platform",
  Processing = "Processing",
}

export enum FeedbackType {
  PATIENT_AUTH_BILL_PORTAL = "PATIENT_AUTH_BILL_PORTAL",
  PATIENT_BENEFITS = "PATIENT_BENEFITS",
  PATIENT_PUBLIC_PORTAL = "PATIENT_PUBLIC_PORTAL",
  PATIENT_UNAUTH_PAGE = "PATIENT_UNAUTH_PAGE",
}

export enum InsurancePolicyBenefitStatus {
  Confirmed = "Confirmed",
  NeedsConfirmation = "NeedsConfirmation",
  NeedsVerification = "NeedsVerification",
}

export enum InsurancePolicyVerificationStatus {
  Active = "Active",
  Error = "Error",
  Inactive = "Inactive",
  NeedsReverification = "NeedsReverification",
  Unverified = "Unverified",
}

export enum InsurancePolicyWorklistItemTaskType {
  AssignBenefits = "AssignBenefits",
  ConfirmBenefits = "ConfirmBenefits",
  CreateEstimate = "CreateEstimate",
  Custom = "Custom",
  VerifyBenefits = "VerifyBenefits",
}

export enum InsurancePolicyWorklistReason {
  NewPolicy = "NewPolicy",
  Reverification = "Reverification",
  UpcomingVisit = "UpcomingVisit",
}

export enum InsuranceType {
  AUTO = "AUTO",
  CHAMPVA = "CHAMPVA",
  COMMERCIAL = "COMMERCIAL",
  MEDICAID = "MEDICAID",
  MEDICARE = "MEDICARE",
  MEDIGAP = "MEDIGAP",
  NO_FAULT = "NO_FAULT",
  OTHER = "OTHER",
  SELF_PAY = "SELF_PAY",
  TRICARE = "TRICARE",
  WORKERS_COMP = "WORKERS_COMP",
}

export enum IntegrationLinkType {
  Account = "Account",
  AccountCoverage = "AccountCoverage",
  Appointment = "Appointment",
  Bill = "Bill",
  BillPayment = "BillPayment",
  ChargePayment = "ChargePayment",
  Claim = "Claim",
  ClaimLine = "ClaimLine",
  ClaimRemittance = "ClaimRemittance",
  ClaimRemittanceLine = "ClaimRemittanceLine",
  ExternalPaymentMethod = "ExternalPaymentMethod",
  InsuranceBillableCharge = "InsuranceBillableCharge",
  Patient = "Patient",
  Payer = "Payer",
  Provider = "Provider",
  Statement = "Statement",
  StatementTransaction = "StatementTransaction",
  Transaction = "Transaction",
}

export enum IntegrationType {
  AdvancedMD = "AdvancedMD",
  Charm = "Charm",
  ChiroSpring = "ChiroSpring",
  Chirotouch = "Chirotouch",
  EClinicalWorks = "EClinicalWorks",
  Elation = "Elation",
  ElationBilling = "ElationBilling",
  HealthJump = "HealthJump",
  Jane = "Jane",
}

export enum LedgerGranularityLevel {
  Bill = "Bill",
  Charge = "Charge",
}

export enum MedicaidType {
  CHIP = "CHIP",
  DUAL_ELIGIBLE = "DUAL_ELIGIBLE",
  EXPANSION = "EXPANSION",
  QUALIFIED_DISABLED_WORKING_INDIVIDUAL = "QUALIFIED_DISABLED_WORKING_INDIVIDUAL",
  QUALIFIED_MEDICARE_BENEFICIARY = "QUALIFIED_MEDICARE_BENEFICIARY",
  QUALIFYING_INDIVIDUAL = "QUALIFYING_INDIVIDUAL",
  SPECIFIED_LOW_INCOME_MEDICARE_BENEFICIARY = "SPECIFIED_LOW_INCOME_MEDICARE_BENEFICIARY",
  STANDARD = "STANDARD",
}

export enum MedicareType {
  ADVANTAGE = "ADVANTAGE",
  ORIGINAL = "ORIGINAL",
  PART_A = "PART_A",
  PART_B = "PART_B",
  PART_C = "PART_C",
  PART_D = "PART_D",
}

export enum MedigapPlanType {
  PLAN_A = "PLAN_A",
  PLAN_B = "PLAN_B",
  PLAN_C = "PLAN_C",
  PLAN_D = "PLAN_D",
  PLAN_F = "PLAN_F",
  PLAN_F_HIGH_DEDUCTIBLE = "PLAN_F_HIGH_DEDUCTIBLE",
  PLAN_G = "PLAN_G",
  PLAN_G_HIGH_DEDUCTIBLE = "PLAN_G_HIGH_DEDUCTIBLE",
  PLAN_K = "PLAN_K",
  PLAN_L = "PLAN_L",
  PLAN_M = "PLAN_M",
  PLAN_N = "PLAN_N",
}

export enum NetworkStatus {
  NO = "NO",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  UNKNOWN = "UNKNOWN",
  YES = "YES",
}

export enum NoteType {
  Appointment = "Appointment",
  Patient = "Patient",
}

export enum NullsOrder {
  first = "first",
  last = "last",
}

export enum PaymentMethod {
  Cash = "Cash",
  Check = "Check",
  CreditCard = "CreditCard",
  DirectDebit = "DirectDebit",
}

export enum PaymentRequestBatchStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  Failed = "Failed",
  Running = "Running",
  Scheduled = "Scheduled",
}

export enum PaymentRequestStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  Failed = "Failed",
  Running = "Running",
  Scheduled = "Scheduled",
}

export enum PaymentRequestType {
  Autopay = "Autopay",
  Link = "Link",
  Print = "Print",
  Task = "Task",
}

export enum PaymentType {
  Insurance = "Insurance",
  Patient = "Patient",
}

export enum PostingStatus {
  Failed = "Failed",
  Pending = "Pending",
  Posted = "Posted",
  Posting = "Posting",
  Skipped = "Skipped",
}

export enum ProviderType {
  Individual = "Individual",
  Organization = "Organization",
}

export enum QueryMode {
  default = "default",
  insensitive = "insensitive",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export enum StripeConnectAccountType {
  Express = "Express",
  Standard = "Standard",
}

export enum SyncDirection {
  Export = "Export",
  Import = "Import",
}

export enum TaskType {
  ChargeExternalPaymentMethod = "ChargeExternalPaymentMethod",
}

export enum TransactionType {
  Adjustment = "Adjustment",
  Charge = "Charge",
  Payment = "Payment",
}

export enum TricareType {
  FOR_LIFE = "FOR_LIFE",
  PRIME = "PRIME",
  RESERVE_SELECT = "RESERVE_SELECT",
  RETIRED_RESERVE = "RETIRED_RESERVE",
  SELECT = "SELECT",
  YOUNG_ADULT = "YOUNG_ADULT",
}

export enum UserEnteredBenefitType {
  Coinsurance = "Coinsurance",
  Copay = "Copay",
  MaxDeductible = "MaxDeductible",
  RemainingDeductible = "RemainingDeductible",
  RemainingOutOfPocket = "RemainingOutOfPocket",
  RemainingVisits = "RemainingVisits",
}

export enum ValidationStatus {
  Hidden = "Hidden",
  Optional = "Optional",
  Required = "Required",
}

export enum VerificationWorkflowReason {
  NewPolicy = "NewPolicy",
  Reverification = "Reverification",
}

export enum WorkflowStage {
  Canceled = "Canceled",
  Complete = "Complete",
  InProgress = "InProgress",
  Todo = "Todo",
}

export enum WorkflowType {
  ImportUpcomingAppointments = "ImportUpcomingAppointments",
  ReplicateDeletionsFromChirotouch = "ReplicateDeletionsFromChirotouch",
  SyncFromAdvancedMD = "SyncFromAdvancedMD",
  SyncFromCharm = "SyncFromCharm",
  SyncFromChiroSpring = "SyncFromChiroSpring",
  SyncFromChirotouchWorkflow = "SyncFromChirotouchWorkflow",
  SyncFromEClinicalWorks = "SyncFromEClinicalWorks",
  SyncFromElation = "SyncFromElation",
  SyncFromElationBilling = "SyncFromElationBilling",
  SyncFromHealthJump = "SyncFromHealthJump",
  SyncFromJane = "SyncFromJane",
}

export interface AccountCoverageCreateManyAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  payerId: string;
  planId?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  insurancePolicyId: string;
}

export interface AccountCoverageCreateManyAccountInputEnvelope {
  data: AccountCoverageCreateManyAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface AccountCoverageCreateManyInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  accountId: string;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  payerId: string;
  planId?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
}

export interface AccountCoverageCreateManyInsurancePolicyInputEnvelope {
  data: AccountCoverageCreateManyInsurancePolicyInput[];
  skipDuplicates?: boolean | null;
}

export interface AccountCoverageCreateManyPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  accountId: string;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  planId?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  insurancePolicyId: string;
}

export interface AccountCoverageCreateManyPayerInputEnvelope {
  data: AccountCoverageCreateManyPayerInput[];
  skipDuplicates?: boolean | null;
}

export interface AccountCoverageCreateManyPlanInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  accountId: string;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  payerId: string;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  insurancePolicyId: string;
}

export interface AccountCoverageCreateManyPlanInputEnvelope {
  data: AccountCoverageCreateManyPlanInput[];
  skipDuplicates?: boolean | null;
}

export interface AccountCoverageCreateNestedManyWithoutAccountInput {
  create?: AccountCoverageCreateWithoutAccountInput[] | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutAccountInput[] | null;
  createMany?: AccountCoverageCreateManyAccountInputEnvelope | null;
  connect?: AccountCoverageWhereUniqueInput[] | null;
}

export interface AccountCoverageCreateNestedManyWithoutInsurancePolicyInput {
  create?: AccountCoverageCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutInsurancePolicyInput[] | null;
  createMany?: AccountCoverageCreateManyInsurancePolicyInputEnvelope | null;
  connect?: AccountCoverageWhereUniqueInput[] | null;
}

export interface AccountCoverageCreateNestedManyWithoutPayerInput {
  create?: AccountCoverageCreateWithoutPayerInput[] | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutPayerInput[] | null;
  createMany?: AccountCoverageCreateManyPayerInputEnvelope | null;
  connect?: AccountCoverageWhereUniqueInput[] | null;
}

export interface AccountCoverageCreateNestedManyWithoutPlanInput {
  create?: AccountCoverageCreateWithoutPlanInput[] | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutPlanInput[] | null;
  createMany?: AccountCoverageCreateManyPlanInputEnvelope | null;
  connect?: AccountCoverageWhereUniqueInput[] | null;
}

export interface AccountCoverageCreateNestedOneWithoutCommunicationsInput {
  create?: AccountCoverageCreateWithoutCommunicationsInput | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutCommunicationsInput | null;
  connect?: AccountCoverageWhereUniqueInput | null;
}

export interface AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput {
  create?: AccountCoverageCreateWithoutEligibilityRequestsInput | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutEligibilityRequestsInput | null;
  connect?: AccountCoverageWhereUniqueInput | null;
}

export interface AccountCoverageCreateNestedOneWithoutInsuranceBillableChargesInput {
  create?: AccountCoverageCreateWithoutInsuranceBillableChargesInput | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutInsuranceBillableChargesInput | null;
  connect?: AccountCoverageWhereUniqueInput | null;
}

export interface AccountCoverageCreateNestedOneWithoutIntegrationLinksInput {
  create?: AccountCoverageCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: AccountCoverageWhereUniqueInput | null;
}

export interface AccountCoverageCreateNestedOneWithoutUserEnteredBenefitsInput {
  create?: AccountCoverageCreateWithoutUserEnteredBenefitsInput | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutUserEnteredBenefitsInput | null;
  connect?: AccountCoverageWhereUniqueInput | null;
}

export interface AccountCoverageCreateOrConnectWithoutAccountInput {
  where: AccountCoverageWhereUniqueInput;
  create: AccountCoverageCreateWithoutAccountInput;
}

export interface AccountCoverageCreateOrConnectWithoutCommunicationsInput {
  where: AccountCoverageWhereUniqueInput;
  create: AccountCoverageCreateWithoutCommunicationsInput;
}

export interface AccountCoverageCreateOrConnectWithoutEligibilityRequestsInput {
  where: AccountCoverageWhereUniqueInput;
  create: AccountCoverageCreateWithoutEligibilityRequestsInput;
}

export interface AccountCoverageCreateOrConnectWithoutInsuranceBillableChargesInput {
  where: AccountCoverageWhereUniqueInput;
  create: AccountCoverageCreateWithoutInsuranceBillableChargesInput;
}

export interface AccountCoverageCreateOrConnectWithoutInsurancePolicyInput {
  where: AccountCoverageWhereUniqueInput;
  create: AccountCoverageCreateWithoutInsurancePolicyInput;
}

export interface AccountCoverageCreateOrConnectWithoutIntegrationLinksInput {
  where: AccountCoverageWhereUniqueInput;
  create: AccountCoverageCreateWithoutIntegrationLinksInput;
}

export interface AccountCoverageCreateOrConnectWithoutPayerInput {
  where: AccountCoverageWhereUniqueInput;
  create: AccountCoverageCreateWithoutPayerInput;
}

export interface AccountCoverageCreateOrConnectWithoutPlanInput {
  where: AccountCoverageWhereUniqueInput;
  create: AccountCoverageCreateWithoutPlanInput;
}

export interface AccountCoverageCreateOrConnectWithoutUserEnteredBenefitsInput {
  where: AccountCoverageWhereUniqueInput;
  create: AccountCoverageCreateWithoutUserEnteredBenefitsInput;
}

export interface AccountCoverageCreateWithoutAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  payer: PayerCreateNestedOneWithoutAccountCoveragesInput;
  plan?: PlanCreateNestedOneWithoutAccountCoveragesInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAccountCoveragesInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAccountCoverageInput | null;
  communications?: CommunicationCreateNestedManyWithoutAccountCoverageInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountCoverageInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutAccountCoverageInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutAccountCoverageInput | null;
}

export interface AccountCoverageCreateWithoutCommunicationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  account: AccountCreateNestedOneWithoutAccountCoveragesInput;
  payer: PayerCreateNestedOneWithoutAccountCoveragesInput;
  plan?: PlanCreateNestedOneWithoutAccountCoveragesInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAccountCoveragesInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAccountCoverageInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountCoverageInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutAccountCoverageInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutAccountCoverageInput | null;
}

export interface AccountCoverageCreateWithoutEligibilityRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  account: AccountCreateNestedOneWithoutAccountCoveragesInput;
  payer: PayerCreateNestedOneWithoutAccountCoveragesInput;
  plan?: PlanCreateNestedOneWithoutAccountCoveragesInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAccountCoveragesInput;
  communications?: CommunicationCreateNestedManyWithoutAccountCoverageInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountCoverageInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutAccountCoverageInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutAccountCoverageInput | null;
}

export interface AccountCoverageCreateWithoutInsuranceBillableChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  account: AccountCreateNestedOneWithoutAccountCoveragesInput;
  payer: PayerCreateNestedOneWithoutAccountCoveragesInput;
  plan?: PlanCreateNestedOneWithoutAccountCoveragesInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAccountCoveragesInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAccountCoverageInput | null;
  communications?: CommunicationCreateNestedManyWithoutAccountCoverageInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountCoverageInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutAccountCoverageInput | null;
}

export interface AccountCoverageCreateWithoutInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  account: AccountCreateNestedOneWithoutAccountCoveragesInput;
  payer: PayerCreateNestedOneWithoutAccountCoveragesInput;
  plan?: PlanCreateNestedOneWithoutAccountCoveragesInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAccountCoverageInput | null;
  communications?: CommunicationCreateNestedManyWithoutAccountCoverageInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountCoverageInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutAccountCoverageInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutAccountCoverageInput | null;
}

export interface AccountCoverageCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  account: AccountCreateNestedOneWithoutAccountCoveragesInput;
  payer: PayerCreateNestedOneWithoutAccountCoveragesInput;
  plan?: PlanCreateNestedOneWithoutAccountCoveragesInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAccountCoveragesInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAccountCoverageInput | null;
  communications?: CommunicationCreateNestedManyWithoutAccountCoverageInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutAccountCoverageInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutAccountCoverageInput | null;
}

export interface AccountCoverageCreateWithoutPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  account: AccountCreateNestedOneWithoutAccountCoveragesInput;
  plan?: PlanCreateNestedOneWithoutAccountCoveragesInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAccountCoveragesInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAccountCoverageInput | null;
  communications?: CommunicationCreateNestedManyWithoutAccountCoverageInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountCoverageInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutAccountCoverageInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutAccountCoverageInput | null;
}

export interface AccountCoverageCreateWithoutPlanInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  account: AccountCreateNestedOneWithoutAccountCoveragesInput;
  payer: PayerCreateNestedOneWithoutAccountCoveragesInput;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAccountCoveragesInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAccountCoverageInput | null;
  communications?: CommunicationCreateNestedManyWithoutAccountCoverageInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountCoverageInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutAccountCoverageInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutAccountCoverageInput | null;
}

export interface AccountCoverageCreateWithoutUserEnteredBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  relationToSubscriber?: string | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  active?: boolean | null;
  validFrom?: any | null;
  validTo?: any | null;
  planStatus?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  account: AccountCreateNestedOneWithoutAccountCoveragesInput;
  payer: PayerCreateNestedOneWithoutAccountCoveragesInput;
  plan?: PlanCreateNestedOneWithoutAccountCoveragesInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAccountCoveragesInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAccountCoverageInput | null;
  communications?: CommunicationCreateNestedManyWithoutAccountCoverageInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountCoverageInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutAccountCoverageInput | null;
}

export interface AccountCoverageListRelationFilter {
  every?: AccountCoverageWhereInput | null;
  some?: AccountCoverageWhereInput | null;
  none?: AccountCoverageWhereInput | null;
}

export interface AccountCoverageOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface AccountCoverageRelationFilter {
  is?: AccountCoverageWhereInput | null;
  isNot?: AccountCoverageWhereInput | null;
}

export interface AccountCoverageScalarWhereInput {
  AND?: AccountCoverageScalarWhereInput[] | null;
  OR?: AccountCoverageScalarWhereInput[] | null;
  NOT?: AccountCoverageScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  accountId?: UuidFilter | null;
  relationToSubscriber?: StringNullableFilter | null;
  memberId?: StringFilter | null;
  groupId?: StringNullableFilter | null;
  groupName?: StringNullableFilter | null;
  payerId?: UuidFilter | null;
  planId?: UuidNullableFilter | null;
  active?: BoolFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  validTo?: DateTimeNullableFilter | null;
  planStatus?: StringNullableFilter | null;
  priority?: EnumCoveragePriorityNullableFilter | null;
  deletedAt?: DateTimeNullableFilter | null;
  insurancePolicyId?: UuidFilter | null;
}

export interface AccountCoverageUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  planStatus?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
}

export interface AccountCoverageUpdateManyWithWhereWithoutAccountInput {
  where: AccountCoverageScalarWhereInput;
  data: AccountCoverageUpdateManyMutationInput;
}

export interface AccountCoverageUpdateManyWithWhereWithoutInsurancePolicyInput {
  where: AccountCoverageScalarWhereInput;
  data: AccountCoverageUpdateManyMutationInput;
}

export interface AccountCoverageUpdateManyWithWhereWithoutPayerInput {
  where: AccountCoverageScalarWhereInput;
  data: AccountCoverageUpdateManyMutationInput;
}

export interface AccountCoverageUpdateManyWithWhereWithoutPlanInput {
  where: AccountCoverageScalarWhereInput;
  data: AccountCoverageUpdateManyMutationInput;
}

export interface AccountCoverageUpdateManyWithoutAccountNestedInput {
  create?: AccountCoverageCreateWithoutAccountInput[] | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutAccountInput[] | null;
  upsert?: AccountCoverageUpsertWithWhereUniqueWithoutAccountInput[] | null;
  createMany?: AccountCoverageCreateManyAccountInputEnvelope | null;
  set?: AccountCoverageWhereUniqueInput[] | null;
  disconnect?: AccountCoverageWhereUniqueInput[] | null;
  delete?: AccountCoverageWhereUniqueInput[] | null;
  connect?: AccountCoverageWhereUniqueInput[] | null;
  update?: AccountCoverageUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: AccountCoverageUpdateManyWithWhereWithoutAccountInput[] | null;
  deleteMany?: AccountCoverageScalarWhereInput[] | null;
}

export interface AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput {
  create?: AccountCoverageCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutInsurancePolicyInput[] | null;
  upsert?: AccountCoverageUpsertWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  createMany?: AccountCoverageCreateManyInsurancePolicyInputEnvelope | null;
  set?: AccountCoverageWhereUniqueInput[] | null;
  disconnect?: AccountCoverageWhereUniqueInput[] | null;
  delete?: AccountCoverageWhereUniqueInput[] | null;
  connect?: AccountCoverageWhereUniqueInput[] | null;
  update?: AccountCoverageUpdateWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  updateMany?: AccountCoverageUpdateManyWithWhereWithoutInsurancePolicyInput[] | null;
  deleteMany?: AccountCoverageScalarWhereInput[] | null;
}

export interface AccountCoverageUpdateManyWithoutPayerNestedInput {
  create?: AccountCoverageCreateWithoutPayerInput[] | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutPayerInput[] | null;
  upsert?: AccountCoverageUpsertWithWhereUniqueWithoutPayerInput[] | null;
  createMany?: AccountCoverageCreateManyPayerInputEnvelope | null;
  set?: AccountCoverageWhereUniqueInput[] | null;
  disconnect?: AccountCoverageWhereUniqueInput[] | null;
  delete?: AccountCoverageWhereUniqueInput[] | null;
  connect?: AccountCoverageWhereUniqueInput[] | null;
  update?: AccountCoverageUpdateWithWhereUniqueWithoutPayerInput[] | null;
  updateMany?: AccountCoverageUpdateManyWithWhereWithoutPayerInput[] | null;
  deleteMany?: AccountCoverageScalarWhereInput[] | null;
}

export interface AccountCoverageUpdateManyWithoutPlanNestedInput {
  create?: AccountCoverageCreateWithoutPlanInput[] | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutPlanInput[] | null;
  upsert?: AccountCoverageUpsertWithWhereUniqueWithoutPlanInput[] | null;
  createMany?: AccountCoverageCreateManyPlanInputEnvelope | null;
  set?: AccountCoverageWhereUniqueInput[] | null;
  disconnect?: AccountCoverageWhereUniqueInput[] | null;
  delete?: AccountCoverageWhereUniqueInput[] | null;
  connect?: AccountCoverageWhereUniqueInput[] | null;
  update?: AccountCoverageUpdateWithWhereUniqueWithoutPlanInput[] | null;
  updateMany?: AccountCoverageUpdateManyWithWhereWithoutPlanInput[] | null;
  deleteMany?: AccountCoverageScalarWhereInput[] | null;
}

export interface AccountCoverageUpdateOneWithoutCommunicationsNestedInput {
  create?: AccountCoverageCreateWithoutCommunicationsInput | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutCommunicationsInput | null;
  upsert?: AccountCoverageUpsertWithoutCommunicationsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AccountCoverageWhereUniqueInput | null;
  update?: AccountCoverageUpdateWithoutCommunicationsInput | null;
}

export interface AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput {
  create?: AccountCoverageCreateWithoutEligibilityRequestsInput | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutEligibilityRequestsInput | null;
  upsert?: AccountCoverageUpsertWithoutEligibilityRequestsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AccountCoverageWhereUniqueInput | null;
  update?: AccountCoverageUpdateWithoutEligibilityRequestsInput | null;
}

export interface AccountCoverageUpdateOneWithoutInsuranceBillableChargesNestedInput {
  create?: AccountCoverageCreateWithoutInsuranceBillableChargesInput | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutInsuranceBillableChargesInput | null;
  upsert?: AccountCoverageUpsertWithoutInsuranceBillableChargesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AccountCoverageWhereUniqueInput | null;
  update?: AccountCoverageUpdateWithoutInsuranceBillableChargesInput | null;
}

export interface AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput {
  create?: AccountCoverageCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: AccountCoverageUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AccountCoverageWhereUniqueInput | null;
  update?: AccountCoverageUpdateWithoutIntegrationLinksInput | null;
}

export interface AccountCoverageUpdateOneWithoutUserEnteredBenefitsNestedInput {
  create?: AccountCoverageCreateWithoutUserEnteredBenefitsInput | null;
  connectOrCreate?: AccountCoverageCreateOrConnectWithoutUserEnteredBenefitsInput | null;
  upsert?: AccountCoverageUpsertWithoutUserEnteredBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AccountCoverageWhereUniqueInput | null;
  update?: AccountCoverageUpdateWithoutUserEnteredBenefitsInput | null;
}

export interface AccountCoverageUpdateWithWhereUniqueWithoutAccountInput {
  where: AccountCoverageWhereUniqueInput;
  data: AccountCoverageUpdateWithoutAccountInput;
}

export interface AccountCoverageUpdateWithWhereUniqueWithoutInsurancePolicyInput {
  where: AccountCoverageWhereUniqueInput;
  data: AccountCoverageUpdateWithoutInsurancePolicyInput;
}

export interface AccountCoverageUpdateWithWhereUniqueWithoutPayerInput {
  where: AccountCoverageWhereUniqueInput;
  data: AccountCoverageUpdateWithoutPayerInput;
}

export interface AccountCoverageUpdateWithWhereUniqueWithoutPlanInput {
  where: AccountCoverageWhereUniqueInput;
  data: AccountCoverageUpdateWithoutPlanInput;
}

export interface AccountCoverageUpdateWithoutAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  planStatus?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  payer?: PayerUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  plan?: PlanUpdateOneWithoutAccountCoveragesNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAccountCoverageNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAccountCoverageNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountCoverageNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutAccountCoverageNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutAccountCoverageNestedInput | null;
}

export interface AccountCoverageUpdateWithoutCommunicationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  planStatus?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  plan?: PlanUpdateOneWithoutAccountCoveragesNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAccountCoverageNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountCoverageNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutAccountCoverageNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutAccountCoverageNestedInput | null;
}

export interface AccountCoverageUpdateWithoutEligibilityRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  planStatus?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  plan?: PlanUpdateOneWithoutAccountCoveragesNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAccountCoverageNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountCoverageNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutAccountCoverageNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutAccountCoverageNestedInput | null;
}

export interface AccountCoverageUpdateWithoutInsuranceBillableChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  planStatus?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  plan?: PlanUpdateOneWithoutAccountCoveragesNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAccountCoverageNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAccountCoverageNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountCoverageNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutAccountCoverageNestedInput | null;
}

export interface AccountCoverageUpdateWithoutInsurancePolicyInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  planStatus?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  plan?: PlanUpdateOneWithoutAccountCoveragesNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAccountCoverageNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAccountCoverageNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountCoverageNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutAccountCoverageNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutAccountCoverageNestedInput | null;
}

export interface AccountCoverageUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  planStatus?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  plan?: PlanUpdateOneWithoutAccountCoveragesNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAccountCoverageNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAccountCoverageNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutAccountCoverageNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutAccountCoverageNestedInput | null;
}

export interface AccountCoverageUpdateWithoutPayerInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  planStatus?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  plan?: PlanUpdateOneWithoutAccountCoveragesNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAccountCoverageNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAccountCoverageNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountCoverageNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutAccountCoverageNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutAccountCoverageNestedInput | null;
}

export interface AccountCoverageUpdateWithoutPlanInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  planStatus?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAccountCoverageNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAccountCoverageNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountCoverageNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutAccountCoverageNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutAccountCoverageNestedInput | null;
}

export interface AccountCoverageUpdateWithoutUserEnteredBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  planStatus?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  plan?: PlanUpdateOneWithoutAccountCoveragesNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAccountCoveragesNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAccountCoverageNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAccountCoverageNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountCoverageNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutAccountCoverageNestedInput | null;
}

export interface AccountCoverageUpsertWithWhereUniqueWithoutAccountInput {
  where: AccountCoverageWhereUniqueInput;
  update: AccountCoverageUpdateWithoutAccountInput;
  create: AccountCoverageCreateWithoutAccountInput;
}

export interface AccountCoverageUpsertWithWhereUniqueWithoutInsurancePolicyInput {
  where: AccountCoverageWhereUniqueInput;
  update: AccountCoverageUpdateWithoutInsurancePolicyInput;
  create: AccountCoverageCreateWithoutInsurancePolicyInput;
}

export interface AccountCoverageUpsertWithWhereUniqueWithoutPayerInput {
  where: AccountCoverageWhereUniqueInput;
  update: AccountCoverageUpdateWithoutPayerInput;
  create: AccountCoverageCreateWithoutPayerInput;
}

export interface AccountCoverageUpsertWithWhereUniqueWithoutPlanInput {
  where: AccountCoverageWhereUniqueInput;
  update: AccountCoverageUpdateWithoutPlanInput;
  create: AccountCoverageCreateWithoutPlanInput;
}

export interface AccountCoverageUpsertWithoutCommunicationsInput {
  update: AccountCoverageUpdateWithoutCommunicationsInput;
  create: AccountCoverageCreateWithoutCommunicationsInput;
}

export interface AccountCoverageUpsertWithoutEligibilityRequestsInput {
  update: AccountCoverageUpdateWithoutEligibilityRequestsInput;
  create: AccountCoverageCreateWithoutEligibilityRequestsInput;
}

export interface AccountCoverageUpsertWithoutInsuranceBillableChargesInput {
  update: AccountCoverageUpdateWithoutInsuranceBillableChargesInput;
  create: AccountCoverageCreateWithoutInsuranceBillableChargesInput;
}

export interface AccountCoverageUpsertWithoutIntegrationLinksInput {
  update: AccountCoverageUpdateWithoutIntegrationLinksInput;
  create: AccountCoverageCreateWithoutIntegrationLinksInput;
}

export interface AccountCoverageUpsertWithoutUserEnteredBenefitsInput {
  update: AccountCoverageUpdateWithoutUserEnteredBenefitsInput;
  create: AccountCoverageCreateWithoutUserEnteredBenefitsInput;
}

export interface AccountCoverageWhereInput {
  AND?: AccountCoverageWhereInput[] | null;
  OR?: AccountCoverageWhereInput[] | null;
  NOT?: AccountCoverageWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  accountId?: UuidFilter | null;
  relationToSubscriber?: StringNullableFilter | null;
  memberId?: StringFilter | null;
  groupId?: StringNullableFilter | null;
  groupName?: StringNullableFilter | null;
  payerId?: UuidFilter | null;
  planId?: UuidNullableFilter | null;
  active?: BoolFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  validTo?: DateTimeNullableFilter | null;
  planStatus?: StringNullableFilter | null;
  priority?: EnumCoveragePriorityNullableFilter | null;
  deletedAt?: DateTimeNullableFilter | null;
  insurancePolicyId?: UuidFilter | null;
  account?: AccountRelationFilter | null;
  payer?: PayerRelationFilter | null;
  plan?: PlanRelationFilter | null;
  insurancePolicy?: InsurancePolicyRelationFilter | null;
  eligibilityRequests?: EligibilityRequestListRelationFilter | null;
  communications?: CommunicationListRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
  insuranceBillableCharges?: InsuranceBillableChargeListRelationFilter | null;
  userEnteredBenefits?: UserEnteredBenefitListRelationFilter | null;
}

export interface AccountCoverageWhereUniqueInput {
  id?: string | null;
}

export interface AccountCreateManyAccountTypeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  locationId: string;
  deletedAt?: any | null;
  patientId: string;
  guarantorId?: string | null;
  accountNumber?: string | null;
}

export interface AccountCreateManyAccountTypeInputEnvelope {
  data: AccountCreateManyAccountTypeInput[];
  skipDuplicates?: boolean | null;
}

export interface AccountCreateManyGuarantorInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  locationId: string;
  deletedAt?: any | null;
  patientId: string;
  accountNumber?: string | null;
  accountTypeId?: string | null;
}

export interface AccountCreateManyGuarantorInputEnvelope {
  data: AccountCreateManyGuarantorInput[];
  skipDuplicates?: boolean | null;
}

export interface AccountCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  deletedAt?: any | null;
  patientId: string;
  guarantorId?: string | null;
  accountNumber?: string | null;
  accountTypeId?: string | null;
}

export interface AccountCreateManyLocationInputEnvelope {
  data: AccountCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface AccountCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  locationId: string;
  deletedAt?: any | null;
  patientId: string;
  guarantorId?: string | null;
  accountNumber?: string | null;
  accountTypeId?: string | null;
}

export interface AccountCreateManyOrganizationInputEnvelope {
  data: AccountCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface AccountCreateManyPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  locationId: string;
  deletedAt?: any | null;
  guarantorId?: string | null;
  accountNumber?: string | null;
  accountTypeId?: string | null;
}

export interface AccountCreateManyPatientInputEnvelope {
  data: AccountCreateManyPatientInput[];
  skipDuplicates?: boolean | null;
}

export interface AccountCreateNestedManyWithoutAccountTypeInput {
  create?: AccountCreateWithoutAccountTypeInput[] | null;
  connectOrCreate?: AccountCreateOrConnectWithoutAccountTypeInput[] | null;
  createMany?: AccountCreateManyAccountTypeInputEnvelope | null;
  connect?: AccountWhereUniqueInput[] | null;
}

export interface AccountCreateNestedManyWithoutGuarantorInput {
  create?: AccountCreateWithoutGuarantorInput[] | null;
  connectOrCreate?: AccountCreateOrConnectWithoutGuarantorInput[] | null;
  createMany?: AccountCreateManyGuarantorInputEnvelope | null;
  connect?: AccountWhereUniqueInput[] | null;
}

export interface AccountCreateNestedManyWithoutLocationInput {
  create?: AccountCreateWithoutLocationInput[] | null;
  connectOrCreate?: AccountCreateOrConnectWithoutLocationInput[] | null;
  createMany?: AccountCreateManyLocationInputEnvelope | null;
  connect?: AccountWhereUniqueInput[] | null;
}

export interface AccountCreateNestedManyWithoutOrganizationInput {
  create?: AccountCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: AccountCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: AccountCreateManyOrganizationInputEnvelope | null;
  connect?: AccountWhereUniqueInput[] | null;
}

export interface AccountCreateNestedManyWithoutPatientInput {
  create?: AccountCreateWithoutPatientInput[] | null;
  connectOrCreate?: AccountCreateOrConnectWithoutPatientInput[] | null;
  createMany?: AccountCreateManyPatientInputEnvelope | null;
  connect?: AccountWhereUniqueInput[] | null;
}

export interface AccountCreateNestedOneWithoutAccountCoveragesInput {
  create?: AccountCreateWithoutAccountCoveragesInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutAccountCoveragesInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateNestedOneWithoutAppointmentsInput {
  create?: AccountCreateWithoutAppointmentsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutAppointmentsInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateNestedOneWithoutBillsInput {
  create?: AccountCreateWithoutBillsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutBillsInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateNestedOneWithoutClaimsInput {
  create?: AccountCreateWithoutClaimsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutClaimsInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateNestedOneWithoutEstimatesInput {
  create?: AccountCreateWithoutEstimatesInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutEstimatesInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateNestedOneWithoutExternalPaymentMethodsInput {
  create?: AccountCreateWithoutExternalPaymentMethodsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutExternalPaymentMethodsInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateNestedOneWithoutIntegrationLinksInput {
  create?: AccountCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateNestedOneWithoutTransactionsInput {
  create?: AccountCreateWithoutTransactionsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutTransactionsInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateNestedOneWithoutVisitCollectionRequestsInput {
  create?: AccountCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  connect?: AccountWhereUniqueInput | null;
}

export interface AccountCreateOrConnectWithoutAccountCoveragesInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutAccountCoveragesInput;
}

export interface AccountCreateOrConnectWithoutAccountTypeInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutAccountTypeInput;
}

export interface AccountCreateOrConnectWithoutAppointmentsInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutAppointmentsInput;
}

export interface AccountCreateOrConnectWithoutBillsInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutBillsInput;
}

export interface AccountCreateOrConnectWithoutClaimsInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutClaimsInput;
}

export interface AccountCreateOrConnectWithoutEstimatesInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutEstimatesInput;
}

export interface AccountCreateOrConnectWithoutExternalPaymentMethodsInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutExternalPaymentMethodsInput;
}

export interface AccountCreateOrConnectWithoutGuarantorInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutGuarantorInput;
}

export interface AccountCreateOrConnectWithoutIntegrationLinksInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutIntegrationLinksInput;
}

export interface AccountCreateOrConnectWithoutLocationInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutLocationInput;
}

export interface AccountCreateOrConnectWithoutOrganizationInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutOrganizationInput;
}

export interface AccountCreateOrConnectWithoutPatientInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutPatientInput;
}

export interface AccountCreateOrConnectWithoutTransactionsInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutTransactionsInput;
}

export interface AccountCreateOrConnectWithoutVisitCollectionRequestsInput {
  where: AccountWhereUniqueInput;
  create: AccountCreateWithoutVisitCollectionRequestsInput;
}

export interface AccountCreateWithoutAccountCoveragesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutAccountTypeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutAppointmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutBillsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutClaimsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutEstimatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutExternalPaymentMethodsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutGuarantorInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutTransactionsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAccountInput | null;
}

export interface AccountCreateWithoutVisitCollectionRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  accountNumber?: string | null;
  organization: OrganizationCreateNestedOneWithoutAccountsInput;
  location: LocationCreateNestedOneWithoutAccountsInput;
  appointments?: AppointmentCreateNestedManyWithoutAccountInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutAccountInput | null;
  patient: PatientCreateNestedOneWithoutAccountsInput;
  guarantor?: PatientCreateNestedOneWithoutGuarantorAccountsInput | null;
  accountType?: AccountTypeCreateNestedOneWithoutAccountsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAccountInput | null;
  claims?: ClaimCreateNestedManyWithoutAccountInput | null;
  transactions?: TransactionCreateNestedManyWithoutAccountInput | null;
  bills?: BillCreateNestedManyWithoutAccountInput | null;
  estimates?: EstimateCreateNestedManyWithoutAccountInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedManyWithoutAccountInput | null;
}

export interface AccountListRelationFilter {
  every?: AccountWhereInput | null;
  some?: AccountWhereInput | null;
  none?: AccountWhereInput | null;
}

export interface AccountOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface AccountOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  organizationId?: SortOrder | null;
  locationId?: SortOrder | null;
  deletedAt?: SortOrderInput | null;
  patientId?: SortOrder | null;
  guarantorId?: SortOrderInput | null;
  accountNumber?: SortOrderInput | null;
  accountTypeId?: SortOrderInput | null;
  organization?: OrganizationOrderByWithRelationInput | null;
  location?: LocationOrderByWithRelationInput | null;
  appointments?: AppointmentOrderByRelationAggregateInput | null;
  accountCoverages?: AccountCoverageOrderByRelationAggregateInput | null;
  patient?: PatientOrderByWithRelationInput | null;
  guarantor?: PatientOrderByWithRelationInput | null;
  accountType?: AccountTypeOrderByWithRelationInput | null;
  integrationLinks?: IntegrationLinkOrderByRelationAggregateInput | null;
  claims?: ClaimOrderByRelationAggregateInput | null;
  transactions?: TransactionOrderByRelationAggregateInput | null;
  bills?: BillOrderByRelationAggregateInput | null;
  estimates?: EstimateOrderByRelationAggregateInput | null;
  externalPaymentMethods?: ExternalPaymentMethodOrderByRelationAggregateInput | null;
  visitCollectionRequests?: VisitCollectionRequestOrderByRelationAggregateInput | null;
}

export interface AccountRelationFilter {
  is?: AccountWhereInput | null;
  isNot?: AccountWhereInput | null;
}

export interface AccountScalarWhereInput {
  AND?: AccountScalarWhereInput[] | null;
  OR?: AccountScalarWhereInput[] | null;
  NOT?: AccountScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  deletedAt?: DateTimeNullableFilter | null;
  patientId?: UuidFilter | null;
  guarantorId?: UuidNullableFilter | null;
  accountNumber?: StringNullableFilter | null;
  accountTypeId?: UuidNullableFilter | null;
}

export interface AccountTypeCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  name: string;
}

export interface AccountTypeCreateManyLocationInputEnvelope {
  data: AccountTypeCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface AccountTypeCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  locationId: string;
  name: string;
}

export interface AccountTypeCreateManyOrganizationInputEnvelope {
  data: AccountTypeCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface AccountTypeCreateNestedManyWithoutLocationInput {
  create?: AccountTypeCreateWithoutLocationInput[] | null;
  connectOrCreate?: AccountTypeCreateOrConnectWithoutLocationInput[] | null;
  createMany?: AccountTypeCreateManyLocationInputEnvelope | null;
  connect?: AccountTypeWhereUniqueInput[] | null;
}

export interface AccountTypeCreateNestedManyWithoutOrganizationInput {
  create?: AccountTypeCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: AccountTypeCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: AccountTypeCreateManyOrganizationInputEnvelope | null;
  connect?: AccountTypeWhereUniqueInput[] | null;
}

export interface AccountTypeCreateNestedOneWithoutAccountsInput {
  create?: AccountTypeCreateWithoutAccountsInput | null;
  connectOrCreate?: AccountTypeCreateOrConnectWithoutAccountsInput | null;
  connect?: AccountTypeWhereUniqueInput | null;
}

export interface AccountTypeCreateOrConnectWithoutAccountsInput {
  where: AccountTypeWhereUniqueInput;
  create: AccountTypeCreateWithoutAccountsInput;
}

export interface AccountTypeCreateOrConnectWithoutLocationInput {
  where: AccountTypeWhereUniqueInput;
  create: AccountTypeCreateWithoutLocationInput;
}

export interface AccountTypeCreateOrConnectWithoutOrganizationInput {
  where: AccountTypeWhereUniqueInput;
  create: AccountTypeCreateWithoutOrganizationInput;
}

export interface AccountTypeCreateWithoutAccountsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  organization: OrganizationCreateNestedOneWithoutAccountTypesInput;
  location: LocationCreateNestedOneWithoutAccountTypesInput;
}

export interface AccountTypeCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  organization: OrganizationCreateNestedOneWithoutAccountTypesInput;
  accounts?: AccountCreateNestedManyWithoutAccountTypeInput | null;
}

export interface AccountTypeCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  location: LocationCreateNestedOneWithoutAccountTypesInput;
  accounts?: AccountCreateNestedManyWithoutAccountTypeInput | null;
}

export interface AccountTypeListRelationFilter {
  every?: AccountTypeWhereInput | null;
  some?: AccountTypeWhereInput | null;
  none?: AccountTypeWhereInput | null;
}

export interface AccountTypeLocationIdNameCompoundUniqueInput {
  locationId: string;
  name: string;
}

export interface AccountTypeOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface AccountTypeOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  organizationId?: SortOrder | null;
  locationId?: SortOrder | null;
  name?: SortOrder | null;
  organization?: OrganizationOrderByWithRelationInput | null;
  location?: LocationOrderByWithRelationInput | null;
  accounts?: AccountOrderByRelationAggregateInput | null;
}

export interface AccountTypeRelationFilter {
  is?: AccountTypeWhereInput | null;
  isNot?: AccountTypeWhereInput | null;
}

export interface AccountTypeScalarWhereInput {
  AND?: AccountTypeScalarWhereInput[] | null;
  OR?: AccountTypeScalarWhereInput[] | null;
  NOT?: AccountTypeScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
}

export interface AccountTypeUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
}

export interface AccountTypeUpdateManyWithWhereWithoutLocationInput {
  where: AccountTypeScalarWhereInput;
  data: AccountTypeUpdateManyMutationInput;
}

export interface AccountTypeUpdateManyWithWhereWithoutOrganizationInput {
  where: AccountTypeScalarWhereInput;
  data: AccountTypeUpdateManyMutationInput;
}

export interface AccountTypeUpdateManyWithoutLocationNestedInput {
  create?: AccountTypeCreateWithoutLocationInput[] | null;
  connectOrCreate?: AccountTypeCreateOrConnectWithoutLocationInput[] | null;
  upsert?: AccountTypeUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: AccountTypeCreateManyLocationInputEnvelope | null;
  set?: AccountTypeWhereUniqueInput[] | null;
  disconnect?: AccountTypeWhereUniqueInput[] | null;
  delete?: AccountTypeWhereUniqueInput[] | null;
  connect?: AccountTypeWhereUniqueInput[] | null;
  update?: AccountTypeUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: AccountTypeUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: AccountTypeScalarWhereInput[] | null;
}

export interface AccountTypeUpdateManyWithoutOrganizationNestedInput {
  create?: AccountTypeCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: AccountTypeCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: AccountTypeUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: AccountTypeCreateManyOrganizationInputEnvelope | null;
  set?: AccountTypeWhereUniqueInput[] | null;
  disconnect?: AccountTypeWhereUniqueInput[] | null;
  delete?: AccountTypeWhereUniqueInput[] | null;
  connect?: AccountTypeWhereUniqueInput[] | null;
  update?: AccountTypeUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: AccountTypeUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: AccountTypeScalarWhereInput[] | null;
}

export interface AccountTypeUpdateOneWithoutAccountsNestedInput {
  create?: AccountTypeCreateWithoutAccountsInput | null;
  connectOrCreate?: AccountTypeCreateOrConnectWithoutAccountsInput | null;
  upsert?: AccountTypeUpsertWithoutAccountsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AccountTypeWhereUniqueInput | null;
  update?: AccountTypeUpdateWithoutAccountsInput | null;
}

export interface AccountTypeUpdateWithWhereUniqueWithoutLocationInput {
  where: AccountTypeWhereUniqueInput;
  data: AccountTypeUpdateWithoutLocationInput;
}

export interface AccountTypeUpdateWithWhereUniqueWithoutOrganizationInput {
  where: AccountTypeWhereUniqueInput;
  data: AccountTypeUpdateWithoutOrganizationInput;
}

export interface AccountTypeUpdateWithoutAccountsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountTypesNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountTypesNestedInput | null;
}

export interface AccountTypeUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountTypesNestedInput | null;
  accounts?: AccountUpdateManyWithoutAccountTypeNestedInput | null;
}

export interface AccountTypeUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountTypesNestedInput | null;
  accounts?: AccountUpdateManyWithoutAccountTypeNestedInput | null;
}

export interface AccountTypeUpsertWithWhereUniqueWithoutLocationInput {
  where: AccountTypeWhereUniqueInput;
  update: AccountTypeUpdateWithoutLocationInput;
  create: AccountTypeCreateWithoutLocationInput;
}

export interface AccountTypeUpsertWithWhereUniqueWithoutOrganizationInput {
  where: AccountTypeWhereUniqueInput;
  update: AccountTypeUpdateWithoutOrganizationInput;
  create: AccountTypeCreateWithoutOrganizationInput;
}

export interface AccountTypeUpsertWithoutAccountsInput {
  update: AccountTypeUpdateWithoutAccountsInput;
  create: AccountTypeCreateWithoutAccountsInput;
}

export interface AccountTypeWhereInput {
  AND?: AccountTypeWhereInput[] | null;
  OR?: AccountTypeWhereInput[] | null;
  NOT?: AccountTypeWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
  organization?: OrganizationRelationFilter | null;
  location?: LocationRelationFilter | null;
  accounts?: AccountListRelationFilter | null;
}

export interface AccountTypeWhereUniqueInput {
  id?: string | null;
  locationId_name?: AccountTypeLocationIdNameCompoundUniqueInput | null;
}

export interface AccountUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
}

export interface AccountUpdateManyWithWhereWithoutAccountTypeInput {
  where: AccountScalarWhereInput;
  data: AccountUpdateManyMutationInput;
}

export interface AccountUpdateManyWithWhereWithoutGuarantorInput {
  where: AccountScalarWhereInput;
  data: AccountUpdateManyMutationInput;
}

export interface AccountUpdateManyWithWhereWithoutLocationInput {
  where: AccountScalarWhereInput;
  data: AccountUpdateManyMutationInput;
}

export interface AccountUpdateManyWithWhereWithoutOrganizationInput {
  where: AccountScalarWhereInput;
  data: AccountUpdateManyMutationInput;
}

export interface AccountUpdateManyWithWhereWithoutPatientInput {
  where: AccountScalarWhereInput;
  data: AccountUpdateManyMutationInput;
}

export interface AccountUpdateManyWithoutAccountTypeNestedInput {
  create?: AccountCreateWithoutAccountTypeInput[] | null;
  connectOrCreate?: AccountCreateOrConnectWithoutAccountTypeInput[] | null;
  upsert?: AccountUpsertWithWhereUniqueWithoutAccountTypeInput[] | null;
  createMany?: AccountCreateManyAccountTypeInputEnvelope | null;
  set?: AccountWhereUniqueInput[] | null;
  disconnect?: AccountWhereUniqueInput[] | null;
  delete?: AccountWhereUniqueInput[] | null;
  connect?: AccountWhereUniqueInput[] | null;
  update?: AccountUpdateWithWhereUniqueWithoutAccountTypeInput[] | null;
  updateMany?: AccountUpdateManyWithWhereWithoutAccountTypeInput[] | null;
  deleteMany?: AccountScalarWhereInput[] | null;
}

export interface AccountUpdateManyWithoutGuarantorNestedInput {
  create?: AccountCreateWithoutGuarantorInput[] | null;
  connectOrCreate?: AccountCreateOrConnectWithoutGuarantorInput[] | null;
  upsert?: AccountUpsertWithWhereUniqueWithoutGuarantorInput[] | null;
  createMany?: AccountCreateManyGuarantorInputEnvelope | null;
  set?: AccountWhereUniqueInput[] | null;
  disconnect?: AccountWhereUniqueInput[] | null;
  delete?: AccountWhereUniqueInput[] | null;
  connect?: AccountWhereUniqueInput[] | null;
  update?: AccountUpdateWithWhereUniqueWithoutGuarantorInput[] | null;
  updateMany?: AccountUpdateManyWithWhereWithoutGuarantorInput[] | null;
  deleteMany?: AccountScalarWhereInput[] | null;
}

export interface AccountUpdateManyWithoutLocationNestedInput {
  create?: AccountCreateWithoutLocationInput[] | null;
  connectOrCreate?: AccountCreateOrConnectWithoutLocationInput[] | null;
  upsert?: AccountUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: AccountCreateManyLocationInputEnvelope | null;
  set?: AccountWhereUniqueInput[] | null;
  disconnect?: AccountWhereUniqueInput[] | null;
  delete?: AccountWhereUniqueInput[] | null;
  connect?: AccountWhereUniqueInput[] | null;
  update?: AccountUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: AccountUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: AccountScalarWhereInput[] | null;
}

export interface AccountUpdateManyWithoutOrganizationNestedInput {
  create?: AccountCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: AccountCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: AccountUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: AccountCreateManyOrganizationInputEnvelope | null;
  set?: AccountWhereUniqueInput[] | null;
  disconnect?: AccountWhereUniqueInput[] | null;
  delete?: AccountWhereUniqueInput[] | null;
  connect?: AccountWhereUniqueInput[] | null;
  update?: AccountUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: AccountUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: AccountScalarWhereInput[] | null;
}

export interface AccountUpdateManyWithoutPatientNestedInput {
  create?: AccountCreateWithoutPatientInput[] | null;
  connectOrCreate?: AccountCreateOrConnectWithoutPatientInput[] | null;
  upsert?: AccountUpsertWithWhereUniqueWithoutPatientInput[] | null;
  createMany?: AccountCreateManyPatientInputEnvelope | null;
  set?: AccountWhereUniqueInput[] | null;
  disconnect?: AccountWhereUniqueInput[] | null;
  delete?: AccountWhereUniqueInput[] | null;
  connect?: AccountWhereUniqueInput[] | null;
  update?: AccountUpdateWithWhereUniqueWithoutPatientInput[] | null;
  updateMany?: AccountUpdateManyWithWhereWithoutPatientInput[] | null;
  deleteMany?: AccountScalarWhereInput[] | null;
}

export interface AccountUpdateOneRequiredWithoutAccountCoveragesNestedInput {
  create?: AccountCreateWithoutAccountCoveragesInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutAccountCoveragesInput | null;
  upsert?: AccountUpsertWithoutAccountCoveragesInput | null;
  connect?: AccountWhereUniqueInput | null;
  update?: AccountUpdateWithoutAccountCoveragesInput | null;
}

export interface AccountUpdateOneRequiredWithoutAppointmentsNestedInput {
  create?: AccountCreateWithoutAppointmentsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutAppointmentsInput | null;
  upsert?: AccountUpsertWithoutAppointmentsInput | null;
  connect?: AccountWhereUniqueInput | null;
  update?: AccountUpdateWithoutAppointmentsInput | null;
}

export interface AccountUpdateOneRequiredWithoutBillsNestedInput {
  create?: AccountCreateWithoutBillsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutBillsInput | null;
  upsert?: AccountUpsertWithoutBillsInput | null;
  connect?: AccountWhereUniqueInput | null;
  update?: AccountUpdateWithoutBillsInput | null;
}

export interface AccountUpdateOneRequiredWithoutClaimsNestedInput {
  create?: AccountCreateWithoutClaimsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutClaimsInput | null;
  upsert?: AccountUpsertWithoutClaimsInput | null;
  connect?: AccountWhereUniqueInput | null;
  update?: AccountUpdateWithoutClaimsInput | null;
}

export interface AccountUpdateOneRequiredWithoutEstimatesNestedInput {
  create?: AccountCreateWithoutEstimatesInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutEstimatesInput | null;
  upsert?: AccountUpsertWithoutEstimatesInput | null;
  connect?: AccountWhereUniqueInput | null;
  update?: AccountUpdateWithoutEstimatesInput | null;
}

export interface AccountUpdateOneRequiredWithoutExternalPaymentMethodsNestedInput {
  create?: AccountCreateWithoutExternalPaymentMethodsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutExternalPaymentMethodsInput | null;
  upsert?: AccountUpsertWithoutExternalPaymentMethodsInput | null;
  connect?: AccountWhereUniqueInput | null;
  update?: AccountUpdateWithoutExternalPaymentMethodsInput | null;
}

export interface AccountUpdateOneRequiredWithoutTransactionsNestedInput {
  create?: AccountCreateWithoutTransactionsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutTransactionsInput | null;
  upsert?: AccountUpsertWithoutTransactionsInput | null;
  connect?: AccountWhereUniqueInput | null;
  update?: AccountUpdateWithoutTransactionsInput | null;
}

export interface AccountUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput {
  create?: AccountCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  upsert?: AccountUpsertWithoutVisitCollectionRequestsInput | null;
  connect?: AccountWhereUniqueInput | null;
  update?: AccountUpdateWithoutVisitCollectionRequestsInput | null;
}

export interface AccountUpdateOneWithoutIntegrationLinksNestedInput {
  create?: AccountCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: AccountCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: AccountUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AccountWhereUniqueInput | null;
  update?: AccountUpdateWithoutIntegrationLinksInput | null;
}

export interface AccountUpdateWithWhereUniqueWithoutAccountTypeInput {
  where: AccountWhereUniqueInput;
  data: AccountUpdateWithoutAccountTypeInput;
}

export interface AccountUpdateWithWhereUniqueWithoutGuarantorInput {
  where: AccountWhereUniqueInput;
  data: AccountUpdateWithoutGuarantorInput;
}

export interface AccountUpdateWithWhereUniqueWithoutLocationInput {
  where: AccountWhereUniqueInput;
  data: AccountUpdateWithoutLocationInput;
}

export interface AccountUpdateWithWhereUniqueWithoutOrganizationInput {
  where: AccountWhereUniqueInput;
  data: AccountUpdateWithoutOrganizationInput;
}

export interface AccountUpdateWithWhereUniqueWithoutPatientInput {
  where: AccountWhereUniqueInput;
  data: AccountUpdateWithoutPatientInput;
}

export interface AccountUpdateWithoutAccountCoveragesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutAccountTypeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutAppointmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutBillsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutClaimsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutEstimatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutExternalPaymentMethodsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutGuarantorInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutPatientInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutTransactionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpdateWithoutVisitCollectionRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAccountsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAccountsNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutAccountNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutAccountNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutAccountsNestedInput | null;
  guarantor?: PatientUpdateOneWithoutGuarantorAccountsNestedInput | null;
  accountType?: AccountTypeUpdateOneWithoutAccountsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAccountNestedInput | null;
  claims?: ClaimUpdateManyWithoutAccountNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAccountNestedInput | null;
  bills?: BillUpdateManyWithoutAccountNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAccountNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateManyWithoutAccountNestedInput | null;
}

export interface AccountUpsertWithWhereUniqueWithoutAccountTypeInput {
  where: AccountWhereUniqueInput;
  update: AccountUpdateWithoutAccountTypeInput;
  create: AccountCreateWithoutAccountTypeInput;
}

export interface AccountUpsertWithWhereUniqueWithoutGuarantorInput {
  where: AccountWhereUniqueInput;
  update: AccountUpdateWithoutGuarantorInput;
  create: AccountCreateWithoutGuarantorInput;
}

export interface AccountUpsertWithWhereUniqueWithoutLocationInput {
  where: AccountWhereUniqueInput;
  update: AccountUpdateWithoutLocationInput;
  create: AccountCreateWithoutLocationInput;
}

export interface AccountUpsertWithWhereUniqueWithoutOrganizationInput {
  where: AccountWhereUniqueInput;
  update: AccountUpdateWithoutOrganizationInput;
  create: AccountCreateWithoutOrganizationInput;
}

export interface AccountUpsertWithWhereUniqueWithoutPatientInput {
  where: AccountWhereUniqueInput;
  update: AccountUpdateWithoutPatientInput;
  create: AccountCreateWithoutPatientInput;
}

export interface AccountUpsertWithoutAccountCoveragesInput {
  update: AccountUpdateWithoutAccountCoveragesInput;
  create: AccountCreateWithoutAccountCoveragesInput;
}

export interface AccountUpsertWithoutAppointmentsInput {
  update: AccountUpdateWithoutAppointmentsInput;
  create: AccountCreateWithoutAppointmentsInput;
}

export interface AccountUpsertWithoutBillsInput {
  update: AccountUpdateWithoutBillsInput;
  create: AccountCreateWithoutBillsInput;
}

export interface AccountUpsertWithoutClaimsInput {
  update: AccountUpdateWithoutClaimsInput;
  create: AccountCreateWithoutClaimsInput;
}

export interface AccountUpsertWithoutEstimatesInput {
  update: AccountUpdateWithoutEstimatesInput;
  create: AccountCreateWithoutEstimatesInput;
}

export interface AccountUpsertWithoutExternalPaymentMethodsInput {
  update: AccountUpdateWithoutExternalPaymentMethodsInput;
  create: AccountCreateWithoutExternalPaymentMethodsInput;
}

export interface AccountUpsertWithoutIntegrationLinksInput {
  update: AccountUpdateWithoutIntegrationLinksInput;
  create: AccountCreateWithoutIntegrationLinksInput;
}

export interface AccountUpsertWithoutTransactionsInput {
  update: AccountUpdateWithoutTransactionsInput;
  create: AccountCreateWithoutTransactionsInput;
}

export interface AccountUpsertWithoutVisitCollectionRequestsInput {
  update: AccountUpdateWithoutVisitCollectionRequestsInput;
  create: AccountCreateWithoutVisitCollectionRequestsInput;
}

export interface AccountWhereInput {
  AND?: AccountWhereInput[] | null;
  OR?: AccountWhereInput[] | null;
  NOT?: AccountWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  deletedAt?: DateTimeNullableFilter | null;
  patientId?: UuidFilter | null;
  guarantorId?: UuidNullableFilter | null;
  accountNumber?: StringNullableFilter | null;
  accountTypeId?: UuidNullableFilter | null;
  organization?: OrganizationRelationFilter | null;
  location?: LocationRelationFilter | null;
  appointments?: AppointmentListRelationFilter | null;
  accountCoverages?: AccountCoverageListRelationFilter | null;
  patient?: PatientRelationFilter | null;
  guarantor?: PatientRelationFilter | null;
  accountType?: AccountTypeRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
  claims?: ClaimListRelationFilter | null;
  transactions?: TransactionListRelationFilter | null;
  bills?: BillListRelationFilter | null;
  estimates?: EstimateListRelationFilter | null;
  externalPaymentMethods?: ExternalPaymentMethodListRelationFilter | null;
  visitCollectionRequests?: VisitCollectionRequestListRelationFilter | null;
}

export interface AccountWhereUniqueInput {
  id?: string | null;
}

export interface AccumulatorAdjustmentCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  insurancePolicyId: string;
  estimateId?: string | null;
  type: AccumulatorAdjustmentType;
}

export interface AccumulatorAdjustmentCreateManyAppointmentInputEnvelope {
  data: AccumulatorAdjustmentCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface AccumulatorAdjustmentCreateManyEstimateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  insurancePolicyId: string;
  appointmentId: string;
  type: AccumulatorAdjustmentType;
}

export interface AccumulatorAdjustmentCreateManyEstimateInputEnvelope {
  data: AccumulatorAdjustmentCreateManyEstimateInput[];
  skipDuplicates?: boolean | null;
}

export interface AccumulatorAdjustmentCreateManyInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointmentId: string;
  estimateId?: string | null;
  type: AccumulatorAdjustmentType;
}

export interface AccumulatorAdjustmentCreateManyInsurancePolicyInputEnvelope {
  data: AccumulatorAdjustmentCreateManyInsurancePolicyInput[];
  skipDuplicates?: boolean | null;
}

export interface AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput {
  create?: AccumulatorAdjustmentCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: AccumulatorAdjustmentCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: AccumulatorAdjustmentCreateManyAppointmentInputEnvelope | null;
  connect?: AccumulatorAdjustmentWhereUniqueInput[] | null;
}

export interface AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput {
  create?: AccumulatorAdjustmentCreateWithoutEstimateInput[] | null;
  connectOrCreate?: AccumulatorAdjustmentCreateOrConnectWithoutEstimateInput[] | null;
  createMany?: AccumulatorAdjustmentCreateManyEstimateInputEnvelope | null;
  connect?: AccumulatorAdjustmentWhereUniqueInput[] | null;
}

export interface AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput {
  create?: AccumulatorAdjustmentCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: AccumulatorAdjustmentCreateOrConnectWithoutInsurancePolicyInput[] | null;
  createMany?: AccumulatorAdjustmentCreateManyInsurancePolicyInputEnvelope | null;
  connect?: AccumulatorAdjustmentWhereUniqueInput[] | null;
}

export interface AccumulatorAdjustmentCreateNestedOneWithoutBenefitAccumulatorAdjustmentsInput {
  create?: AccumulatorAdjustmentCreateWithoutBenefitAccumulatorAdjustmentsInput | null;
  connectOrCreate?: AccumulatorAdjustmentCreateOrConnectWithoutBenefitAccumulatorAdjustmentsInput | null;
  connect?: AccumulatorAdjustmentWhereUniqueInput | null;
}

export interface AccumulatorAdjustmentCreateOrConnectWithoutAppointmentInput {
  where: AccumulatorAdjustmentWhereUniqueInput;
  create: AccumulatorAdjustmentCreateWithoutAppointmentInput;
}

export interface AccumulatorAdjustmentCreateOrConnectWithoutBenefitAccumulatorAdjustmentsInput {
  where: AccumulatorAdjustmentWhereUniqueInput;
  create: AccumulatorAdjustmentCreateWithoutBenefitAccumulatorAdjustmentsInput;
}

export interface AccumulatorAdjustmentCreateOrConnectWithoutEstimateInput {
  where: AccumulatorAdjustmentWhereUniqueInput;
  create: AccumulatorAdjustmentCreateWithoutEstimateInput;
}

export interface AccumulatorAdjustmentCreateOrConnectWithoutInsurancePolicyInput {
  where: AccumulatorAdjustmentWhereUniqueInput;
  create: AccumulatorAdjustmentCreateWithoutInsurancePolicyInput;
}

export interface AccumulatorAdjustmentCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: AccumulatorAdjustmentType;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAccumulatorAdjustmentsInput;
  estimate?: EstimateCreateNestedOneWithoutAccumulatorAdjustmentsInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutAccumulatorAdjustmentInput | null;
}

export interface AccumulatorAdjustmentCreateWithoutBenefitAccumulatorAdjustmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: AccumulatorAdjustmentType;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAccumulatorAdjustmentsInput;
  appointment: AppointmentCreateNestedOneWithoutAccumulatorAdjustmentsInput;
  estimate?: EstimateCreateNestedOneWithoutAccumulatorAdjustmentsInput | null;
}

export interface AccumulatorAdjustmentCreateWithoutEstimateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: AccumulatorAdjustmentType;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAccumulatorAdjustmentsInput;
  appointment: AppointmentCreateNestedOneWithoutAccumulatorAdjustmentsInput;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutAccumulatorAdjustmentInput | null;
}

export interface AccumulatorAdjustmentCreateWithoutInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: AccumulatorAdjustmentType;
  appointment: AppointmentCreateNestedOneWithoutAccumulatorAdjustmentsInput;
  estimate?: EstimateCreateNestedOneWithoutAccumulatorAdjustmentsInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutAccumulatorAdjustmentInput | null;
}

export interface AccumulatorAdjustmentListRelationFilter {
  every?: AccumulatorAdjustmentWhereInput | null;
  some?: AccumulatorAdjustmentWhereInput | null;
  none?: AccumulatorAdjustmentWhereInput | null;
}

export interface AccumulatorAdjustmentOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface AccumulatorAdjustmentRelationFilter {
  is?: AccumulatorAdjustmentWhereInput | null;
  isNot?: AccumulatorAdjustmentWhereInput | null;
}

export interface AccumulatorAdjustmentScalarWhereInput {
  AND?: AccumulatorAdjustmentScalarWhereInput[] | null;
  OR?: AccumulatorAdjustmentScalarWhereInput[] | null;
  NOT?: AccumulatorAdjustmentScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  insurancePolicyId?: UuidFilter | null;
  appointmentId?: UuidFilter | null;
  estimateId?: UuidNullableFilter | null;
  type?: EnumAccumulatorAdjustmentTypeFilter | null;
}

export interface AccumulatorAdjustmentUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumAccumulatorAdjustmentTypeFieldUpdateOperationsInput | null;
}

export interface AccumulatorAdjustmentUpdateManyWithWhereWithoutAppointmentInput {
  where: AccumulatorAdjustmentScalarWhereInput;
  data: AccumulatorAdjustmentUpdateManyMutationInput;
}

export interface AccumulatorAdjustmentUpdateManyWithWhereWithoutEstimateInput {
  where: AccumulatorAdjustmentScalarWhereInput;
  data: AccumulatorAdjustmentUpdateManyMutationInput;
}

export interface AccumulatorAdjustmentUpdateManyWithWhereWithoutInsurancePolicyInput {
  where: AccumulatorAdjustmentScalarWhereInput;
  data: AccumulatorAdjustmentUpdateManyMutationInput;
}

export interface AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput {
  create?: AccumulatorAdjustmentCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: AccumulatorAdjustmentCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: AccumulatorAdjustmentUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: AccumulatorAdjustmentCreateManyAppointmentInputEnvelope | null;
  set?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  disconnect?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  delete?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  connect?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  update?: AccumulatorAdjustmentUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: AccumulatorAdjustmentUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: AccumulatorAdjustmentScalarWhereInput[] | null;
}

export interface AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput {
  create?: AccumulatorAdjustmentCreateWithoutEstimateInput[] | null;
  connectOrCreate?: AccumulatorAdjustmentCreateOrConnectWithoutEstimateInput[] | null;
  upsert?: AccumulatorAdjustmentUpsertWithWhereUniqueWithoutEstimateInput[] | null;
  createMany?: AccumulatorAdjustmentCreateManyEstimateInputEnvelope | null;
  set?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  disconnect?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  delete?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  connect?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  update?: AccumulatorAdjustmentUpdateWithWhereUniqueWithoutEstimateInput[] | null;
  updateMany?: AccumulatorAdjustmentUpdateManyWithWhereWithoutEstimateInput[] | null;
  deleteMany?: AccumulatorAdjustmentScalarWhereInput[] | null;
}

export interface AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput {
  create?: AccumulatorAdjustmentCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: AccumulatorAdjustmentCreateOrConnectWithoutInsurancePolicyInput[] | null;
  upsert?: AccumulatorAdjustmentUpsertWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  createMany?: AccumulatorAdjustmentCreateManyInsurancePolicyInputEnvelope | null;
  set?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  disconnect?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  delete?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  connect?: AccumulatorAdjustmentWhereUniqueInput[] | null;
  update?: AccumulatorAdjustmentUpdateWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  updateMany?: AccumulatorAdjustmentUpdateManyWithWhereWithoutInsurancePolicyInput[] | null;
  deleteMany?: AccumulatorAdjustmentScalarWhereInput[] | null;
}

export interface AccumulatorAdjustmentUpdateOneRequiredWithoutBenefitAccumulatorAdjustmentsNestedInput {
  create?: AccumulatorAdjustmentCreateWithoutBenefitAccumulatorAdjustmentsInput | null;
  connectOrCreate?: AccumulatorAdjustmentCreateOrConnectWithoutBenefitAccumulatorAdjustmentsInput | null;
  upsert?: AccumulatorAdjustmentUpsertWithoutBenefitAccumulatorAdjustmentsInput | null;
  connect?: AccumulatorAdjustmentWhereUniqueInput | null;
  update?: AccumulatorAdjustmentUpdateWithoutBenefitAccumulatorAdjustmentsInput | null;
}

export interface AccumulatorAdjustmentUpdateWithWhereUniqueWithoutAppointmentInput {
  where: AccumulatorAdjustmentWhereUniqueInput;
  data: AccumulatorAdjustmentUpdateWithoutAppointmentInput;
}

export interface AccumulatorAdjustmentUpdateWithWhereUniqueWithoutEstimateInput {
  where: AccumulatorAdjustmentWhereUniqueInput;
  data: AccumulatorAdjustmentUpdateWithoutEstimateInput;
}

export interface AccumulatorAdjustmentUpdateWithWhereUniqueWithoutInsurancePolicyInput {
  where: AccumulatorAdjustmentWhereUniqueInput;
  data: AccumulatorAdjustmentUpdateWithoutInsurancePolicyInput;
}

export interface AccumulatorAdjustmentUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumAccumulatorAdjustmentTypeFieldUpdateOperationsInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAccumulatorAdjustmentsNestedInput | null;
  estimate?: EstimateUpdateOneWithoutAccumulatorAdjustmentsNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutAccumulatorAdjustmentNestedInput | null;
}

export interface AccumulatorAdjustmentUpdateWithoutBenefitAccumulatorAdjustmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumAccumulatorAdjustmentTypeFieldUpdateOperationsInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAccumulatorAdjustmentsNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutAccumulatorAdjustmentsNestedInput | null;
  estimate?: EstimateUpdateOneWithoutAccumulatorAdjustmentsNestedInput | null;
}

export interface AccumulatorAdjustmentUpdateWithoutEstimateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumAccumulatorAdjustmentTypeFieldUpdateOperationsInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAccumulatorAdjustmentsNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutAccumulatorAdjustmentsNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutAccumulatorAdjustmentNestedInput | null;
}

export interface AccumulatorAdjustmentUpdateWithoutInsurancePolicyInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumAccumulatorAdjustmentTypeFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutAccumulatorAdjustmentsNestedInput | null;
  estimate?: EstimateUpdateOneWithoutAccumulatorAdjustmentsNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutAccumulatorAdjustmentNestedInput | null;
}

export interface AccumulatorAdjustmentUpsertWithWhereUniqueWithoutAppointmentInput {
  where: AccumulatorAdjustmentWhereUniqueInput;
  update: AccumulatorAdjustmentUpdateWithoutAppointmentInput;
  create: AccumulatorAdjustmentCreateWithoutAppointmentInput;
}

export interface AccumulatorAdjustmentUpsertWithWhereUniqueWithoutEstimateInput {
  where: AccumulatorAdjustmentWhereUniqueInput;
  update: AccumulatorAdjustmentUpdateWithoutEstimateInput;
  create: AccumulatorAdjustmentCreateWithoutEstimateInput;
}

export interface AccumulatorAdjustmentUpsertWithWhereUniqueWithoutInsurancePolicyInput {
  where: AccumulatorAdjustmentWhereUniqueInput;
  update: AccumulatorAdjustmentUpdateWithoutInsurancePolicyInput;
  create: AccumulatorAdjustmentCreateWithoutInsurancePolicyInput;
}

export interface AccumulatorAdjustmentUpsertWithoutBenefitAccumulatorAdjustmentsInput {
  update: AccumulatorAdjustmentUpdateWithoutBenefitAccumulatorAdjustmentsInput;
  create: AccumulatorAdjustmentCreateWithoutBenefitAccumulatorAdjustmentsInput;
}

export interface AccumulatorAdjustmentWhereInput {
  AND?: AccumulatorAdjustmentWhereInput[] | null;
  OR?: AccumulatorAdjustmentWhereInput[] | null;
  NOT?: AccumulatorAdjustmentWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  insurancePolicyId?: UuidFilter | null;
  appointmentId?: UuidFilter | null;
  estimateId?: UuidNullableFilter | null;
  type?: EnumAccumulatorAdjustmentTypeFilter | null;
  insurancePolicy?: InsurancePolicyRelationFilter | null;
  appointment?: AppointmentRelationFilter | null;
  estimate?: EstimateRelationFilter | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentListRelationFilter | null;
}

export interface AccumulatorAdjustmentWhereUniqueInput {
  id?: string | null;
}

export interface AdjustmentCreateNestedOneWithoutTransactionInput {
  create?: AdjustmentCreateWithoutTransactionInput | null;
  connectOrCreate?: AdjustmentCreateOrConnectWithoutTransactionInput | null;
  connect?: AdjustmentWhereUniqueInput | null;
}

export interface AdjustmentCreateOrConnectWithoutTransactionInput {
  where: AdjustmentWhereUniqueInput;
  create: AdjustmentCreateWithoutTransactionInput;
}

export interface AdjustmentCreateWithoutTransactionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: AdjustmentType;
}

export interface AdjustmentRelationFilter {
  is?: AdjustmentWhereInput | null;
  isNot?: AdjustmentWhereInput | null;
}

export interface AdjustmentUpdateOneWithoutTransactionNestedInput {
  create?: AdjustmentCreateWithoutTransactionInput | null;
  connectOrCreate?: AdjustmentCreateOrConnectWithoutTransactionInput | null;
  upsert?: AdjustmentUpsertWithoutTransactionInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AdjustmentWhereUniqueInput | null;
  update?: AdjustmentUpdateWithoutTransactionInput | null;
}

export interface AdjustmentUpdateWithoutTransactionInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumAdjustmentTypeFieldUpdateOperationsInput | null;
}

export interface AdjustmentUpsertWithoutTransactionInput {
  update: AdjustmentUpdateWithoutTransactionInput;
  create: AdjustmentCreateWithoutTransactionInput;
}

export interface AdjustmentWhereInput {
  AND?: AdjustmentWhereInput[] | null;
  OR?: AdjustmentWhereInput[] | null;
  NOT?: AdjustmentWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  patientAmount?: IntFilter | null;
  insuranceAmount?: IntFilter | null;
  type?: EnumAdjustmentTypeFilter | null;
  transactionId?: UuidFilter | null;
  transaction?: TransactionRelationFilter | null;
}

export interface AdjustmentWhereUniqueInput {
  id?: string | null;
  transactionId?: string | null;
}

export interface AppointmentBenefitAssignmentCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointment: AppointmentCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  coverageBenefit: CoverageBenefitCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  healthPlanCoverageBenefit?: CoverageBenefitCreateNestedOneWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
  benefitMapping?: BenefitMappingCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
  createdBy?: UserCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface AppointmentBenefitAssignmentCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  insurancePolicyId: string;
  coverageBenefitId: string;
  healthPlanCoverageBenefitId?: string | null;
  benefitMappingId?: string | null;
  createdById?: string | null;
}

export interface AppointmentBenefitAssignmentCreateManyAppointmentInputEnvelope {
  data: AppointmentBenefitAssignmentCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentBenefitAssignmentCreateManyBenefitMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointmentId: string;
  insurancePolicyId: string;
  coverageBenefitId: string;
  healthPlanCoverageBenefitId?: string | null;
  createdById?: string | null;
}

export interface AppointmentBenefitAssignmentCreateManyBenefitMappingInputEnvelope {
  data: AppointmentBenefitAssignmentCreateManyBenefitMappingInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentBenefitAssignmentCreateManyCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointmentId: string;
  insurancePolicyId: string;
  healthPlanCoverageBenefitId?: string | null;
  benefitMappingId?: string | null;
  createdById?: string | null;
}

export interface AppointmentBenefitAssignmentCreateManyCoverageBenefitInputEnvelope {
  data: AppointmentBenefitAssignmentCreateManyCoverageBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentBenefitAssignmentCreateManyCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointmentId: string;
  insurancePolicyId: string;
  coverageBenefitId: string;
  healthPlanCoverageBenefitId?: string | null;
  benefitMappingId?: string | null;
}

export interface AppointmentBenefitAssignmentCreateManyCreatedByInputEnvelope {
  data: AppointmentBenefitAssignmentCreateManyCreatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentBenefitAssignmentCreateManyHealthPlanCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointmentId: string;
  insurancePolicyId: string;
  coverageBenefitId: string;
  benefitMappingId?: string | null;
  createdById?: string | null;
}

export interface AppointmentBenefitAssignmentCreateManyHealthPlanCoverageBenefitInputEnvelope {
  data: AppointmentBenefitAssignmentCreateManyHealthPlanCoverageBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentBenefitAssignmentCreateManyInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointmentId: string;
  coverageBenefitId: string;
  healthPlanCoverageBenefitId?: string | null;
  benefitMappingId?: string | null;
  createdById?: string | null;
}

export interface AppointmentBenefitAssignmentCreateManyInsurancePolicyInputEnvelope {
  data: AppointmentBenefitAssignmentCreateManyInsurancePolicyInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput {
  create?: AppointmentBenefitAssignmentCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyAppointmentInputEnvelope | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
}

export interface AppointmentBenefitAssignmentCreateNestedManyWithoutBenefitMappingInput {
  create?: AppointmentBenefitAssignmentCreateWithoutBenefitMappingInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutBenefitMappingInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyBenefitMappingInputEnvelope | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
}

export interface AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput {
  create?: AppointmentBenefitAssignmentCreateWithoutCoverageBenefitInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutCoverageBenefitInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyCoverageBenefitInputEnvelope | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
}

export interface AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput {
  create?: AppointmentBenefitAssignmentCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyCreatedByInputEnvelope | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
}

export interface AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput {
  create?: AppointmentBenefitAssignmentCreateWithoutHealthPlanCoverageBenefitInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutHealthPlanCoverageBenefitInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyHealthPlanCoverageBenefitInputEnvelope | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
}

export interface AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput {
  create?: AppointmentBenefitAssignmentCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutInsurancePolicyInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyInsurancePolicyInputEnvelope | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
}

export interface AppointmentBenefitAssignmentCreateOrConnectWithoutAppointmentInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  create: AppointmentBenefitAssignmentCreateWithoutAppointmentInput;
}

export interface AppointmentBenefitAssignmentCreateOrConnectWithoutBenefitMappingInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  create: AppointmentBenefitAssignmentCreateWithoutBenefitMappingInput;
}

export interface AppointmentBenefitAssignmentCreateOrConnectWithoutCoverageBenefitInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  create: AppointmentBenefitAssignmentCreateWithoutCoverageBenefitInput;
}

export interface AppointmentBenefitAssignmentCreateOrConnectWithoutCreatedByInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  create: AppointmentBenefitAssignmentCreateWithoutCreatedByInput;
}

export interface AppointmentBenefitAssignmentCreateOrConnectWithoutHealthPlanCoverageBenefitInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  create: AppointmentBenefitAssignmentCreateWithoutHealthPlanCoverageBenefitInput;
}

export interface AppointmentBenefitAssignmentCreateOrConnectWithoutInsurancePolicyInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  create: AppointmentBenefitAssignmentCreateWithoutInsurancePolicyInput;
}

export interface AppointmentBenefitAssignmentCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  coverageBenefit: CoverageBenefitCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  healthPlanCoverageBenefit?: CoverageBenefitCreateNestedOneWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
  benefitMapping?: BenefitMappingCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
  createdBy?: UserCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface AppointmentBenefitAssignmentCreateWithoutBenefitMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointment: AppointmentCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  coverageBenefit: CoverageBenefitCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  healthPlanCoverageBenefit?: CoverageBenefitCreateNestedOneWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
  createdBy?: UserCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface AppointmentBenefitAssignmentCreateWithoutCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointment: AppointmentCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  healthPlanCoverageBenefit?: CoverageBenefitCreateNestedOneWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
  benefitMapping?: BenefitMappingCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
  createdBy?: UserCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface AppointmentBenefitAssignmentCreateWithoutCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointment: AppointmentCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  coverageBenefit: CoverageBenefitCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  healthPlanCoverageBenefit?: CoverageBenefitCreateNestedOneWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
  benefitMapping?: BenefitMappingCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface AppointmentBenefitAssignmentCreateWithoutHealthPlanCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointment: AppointmentCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  coverageBenefit: CoverageBenefitCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  benefitMapping?: BenefitMappingCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
  createdBy?: UserCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface AppointmentBenefitAssignmentCreateWithoutInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointment: AppointmentCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  coverageBenefit: CoverageBenefitCreateNestedOneWithoutAppointmentBenefitAssignmentsInput;
  healthPlanCoverageBenefit?: CoverageBenefitCreateNestedOneWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
  benefitMapping?: BenefitMappingCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
  createdBy?: UserCreateNestedOneWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface AppointmentBenefitAssignmentListRelationFilter {
  every?: AppointmentBenefitAssignmentWhereInput | null;
  some?: AppointmentBenefitAssignmentWhereInput | null;
  none?: AppointmentBenefitAssignmentWhereInput | null;
}

export interface AppointmentBenefitAssignmentOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface AppointmentBenefitAssignmentScalarWhereInput {
  AND?: AppointmentBenefitAssignmentScalarWhereInput[] | null;
  OR?: AppointmentBenefitAssignmentScalarWhereInput[] | null;
  NOT?: AppointmentBenefitAssignmentScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  appointmentId?: UuidFilter | null;
  insurancePolicyId?: UuidFilter | null;
  coverageBenefitId?: UuidFilter | null;
  healthPlanCoverageBenefitId?: UuidNullableFilter | null;
  benefitMappingId?: UuidNullableFilter | null;
  createdById?: UuidNullableFilter | null;
}

export interface AppointmentBenefitAssignmentUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
}

export interface AppointmentBenefitAssignmentUpdateManyWithWhereWithoutAppointmentInput {
  where: AppointmentBenefitAssignmentScalarWhereInput;
  data: AppointmentBenefitAssignmentUpdateManyMutationInput;
}

export interface AppointmentBenefitAssignmentUpdateManyWithWhereWithoutBenefitMappingInput {
  where: AppointmentBenefitAssignmentScalarWhereInput;
  data: AppointmentBenefitAssignmentUpdateManyMutationInput;
}

export interface AppointmentBenefitAssignmentUpdateManyWithWhereWithoutCoverageBenefitInput {
  where: AppointmentBenefitAssignmentScalarWhereInput;
  data: AppointmentBenefitAssignmentUpdateManyMutationInput;
}

export interface AppointmentBenefitAssignmentUpdateManyWithWhereWithoutCreatedByInput {
  where: AppointmentBenefitAssignmentScalarWhereInput;
  data: AppointmentBenefitAssignmentUpdateManyMutationInput;
}

export interface AppointmentBenefitAssignmentUpdateManyWithWhereWithoutHealthPlanCoverageBenefitInput {
  where: AppointmentBenefitAssignmentScalarWhereInput;
  data: AppointmentBenefitAssignmentUpdateManyMutationInput;
}

export interface AppointmentBenefitAssignmentUpdateManyWithWhereWithoutInsurancePolicyInput {
  where: AppointmentBenefitAssignmentScalarWhereInput;
  data: AppointmentBenefitAssignmentUpdateManyMutationInput;
}

export interface AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput {
  create?: AppointmentBenefitAssignmentCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyAppointmentInputEnvelope | null;
  set?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  disconnect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  delete?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  update?: AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: AppointmentBenefitAssignmentUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: AppointmentBenefitAssignmentScalarWhereInput[] | null;
}

export interface AppointmentBenefitAssignmentUpdateManyWithoutBenefitMappingNestedInput {
  create?: AppointmentBenefitAssignmentCreateWithoutBenefitMappingInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutBenefitMappingInput[] | null;
  upsert?: AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutBenefitMappingInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyBenefitMappingInputEnvelope | null;
  set?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  disconnect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  delete?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  update?: AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutBenefitMappingInput[] | null;
  updateMany?: AppointmentBenefitAssignmentUpdateManyWithWhereWithoutBenefitMappingInput[] | null;
  deleteMany?: AppointmentBenefitAssignmentScalarWhereInput[] | null;
}

export interface AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput {
  create?: AppointmentBenefitAssignmentCreateWithoutCoverageBenefitInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutCoverageBenefitInput[] | null;
  upsert?: AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutCoverageBenefitInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyCoverageBenefitInputEnvelope | null;
  set?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  disconnect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  delete?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  update?: AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutCoverageBenefitInput[] | null;
  updateMany?: AppointmentBenefitAssignmentUpdateManyWithWhereWithoutCoverageBenefitInput[] | null;
  deleteMany?: AppointmentBenefitAssignmentScalarWhereInput[] | null;
}

export interface AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput {
  create?: AppointmentBenefitAssignmentCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyCreatedByInputEnvelope | null;
  set?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  disconnect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  delete?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  update?: AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: AppointmentBenefitAssignmentUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: AppointmentBenefitAssignmentScalarWhereInput[] | null;
}

export interface AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput {
  create?: AppointmentBenefitAssignmentCreateWithoutHealthPlanCoverageBenefitInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutHealthPlanCoverageBenefitInput[] | null;
  upsert?: AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutHealthPlanCoverageBenefitInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyHealthPlanCoverageBenefitInputEnvelope | null;
  set?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  disconnect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  delete?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  update?: AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutHealthPlanCoverageBenefitInput[] | null;
  updateMany?: AppointmentBenefitAssignmentUpdateManyWithWhereWithoutHealthPlanCoverageBenefitInput[] | null;
  deleteMany?: AppointmentBenefitAssignmentScalarWhereInput[] | null;
}

export interface AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput {
  create?: AppointmentBenefitAssignmentCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: AppointmentBenefitAssignmentCreateOrConnectWithoutInsurancePolicyInput[] | null;
  upsert?: AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  createMany?: AppointmentBenefitAssignmentCreateManyInsurancePolicyInputEnvelope | null;
  set?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  disconnect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  delete?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  connect?: AppointmentBenefitAssignmentWhereUniqueInput[] | null;
  update?: AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  updateMany?: AppointmentBenefitAssignmentUpdateManyWithWhereWithoutInsurancePolicyInput[] | null;
  deleteMany?: AppointmentBenefitAssignmentScalarWhereInput[] | null;
}

export interface AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutAppointmentInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  data: AppointmentBenefitAssignmentUpdateWithoutAppointmentInput;
}

export interface AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutBenefitMappingInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  data: AppointmentBenefitAssignmentUpdateWithoutBenefitMappingInput;
}

export interface AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutCoverageBenefitInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  data: AppointmentBenefitAssignmentUpdateWithoutCoverageBenefitInput;
}

export interface AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutCreatedByInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  data: AppointmentBenefitAssignmentUpdateWithoutCreatedByInput;
}

export interface AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutHealthPlanCoverageBenefitInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  data: AppointmentBenefitAssignmentUpdateWithoutHealthPlanCoverageBenefitInput;
}

export interface AppointmentBenefitAssignmentUpdateWithWhereUniqueWithoutInsurancePolicyInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  data: AppointmentBenefitAssignmentUpdateWithoutInsurancePolicyInput;
}

export interface AppointmentBenefitAssignmentUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  healthPlanCoverageBenefit?: CoverageBenefitUpdateOneWithoutHealthPlanAppointmentBenefitAssignmentsNestedInput | null;
  benefitMapping?: BenefitMappingUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput | null;
  createdBy?: UserUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput | null;
}

export interface AppointmentBenefitAssignmentUpdateWithoutBenefitMappingInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  healthPlanCoverageBenefit?: CoverageBenefitUpdateOneWithoutHealthPlanAppointmentBenefitAssignmentsNestedInput | null;
  createdBy?: UserUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput | null;
}

export interface AppointmentBenefitAssignmentUpdateWithoutCoverageBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  healthPlanCoverageBenefit?: CoverageBenefitUpdateOneWithoutHealthPlanAppointmentBenefitAssignmentsNestedInput | null;
  benefitMapping?: BenefitMappingUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput | null;
  createdBy?: UserUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput | null;
}

export interface AppointmentBenefitAssignmentUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  healthPlanCoverageBenefit?: CoverageBenefitUpdateOneWithoutHealthPlanAppointmentBenefitAssignmentsNestedInput | null;
  benefitMapping?: BenefitMappingUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput | null;
}

export interface AppointmentBenefitAssignmentUpdateWithoutHealthPlanCoverageBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  benefitMapping?: BenefitMappingUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput | null;
  createdBy?: UserUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput | null;
}

export interface AppointmentBenefitAssignmentUpdateWithoutInsurancePolicyInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput | null;
  healthPlanCoverageBenefit?: CoverageBenefitUpdateOneWithoutHealthPlanAppointmentBenefitAssignmentsNestedInput | null;
  benefitMapping?: BenefitMappingUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput | null;
  createdBy?: UserUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput | null;
}

export interface AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutAppointmentInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  update: AppointmentBenefitAssignmentUpdateWithoutAppointmentInput;
  create: AppointmentBenefitAssignmentCreateWithoutAppointmentInput;
}

export interface AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutBenefitMappingInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  update: AppointmentBenefitAssignmentUpdateWithoutBenefitMappingInput;
  create: AppointmentBenefitAssignmentCreateWithoutBenefitMappingInput;
}

export interface AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutCoverageBenefitInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  update: AppointmentBenefitAssignmentUpdateWithoutCoverageBenefitInput;
  create: AppointmentBenefitAssignmentCreateWithoutCoverageBenefitInput;
}

export interface AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutCreatedByInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  update: AppointmentBenefitAssignmentUpdateWithoutCreatedByInput;
  create: AppointmentBenefitAssignmentCreateWithoutCreatedByInput;
}

export interface AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutHealthPlanCoverageBenefitInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  update: AppointmentBenefitAssignmentUpdateWithoutHealthPlanCoverageBenefitInput;
  create: AppointmentBenefitAssignmentCreateWithoutHealthPlanCoverageBenefitInput;
}

export interface AppointmentBenefitAssignmentUpsertWithWhereUniqueWithoutInsurancePolicyInput {
  where: AppointmentBenefitAssignmentWhereUniqueInput;
  update: AppointmentBenefitAssignmentUpdateWithoutInsurancePolicyInput;
  create: AppointmentBenefitAssignmentCreateWithoutInsurancePolicyInput;
}

export interface AppointmentBenefitAssignmentWhereInput {
  AND?: AppointmentBenefitAssignmentWhereInput[] | null;
  OR?: AppointmentBenefitAssignmentWhereInput[] | null;
  NOT?: AppointmentBenefitAssignmentWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  appointmentId?: UuidFilter | null;
  insurancePolicyId?: UuidFilter | null;
  coverageBenefitId?: UuidFilter | null;
  healthPlanCoverageBenefitId?: UuidNullableFilter | null;
  benefitMappingId?: UuidNullableFilter | null;
  createdById?: UuidNullableFilter | null;
  appointment?: AppointmentRelationFilter | null;
  insurancePolicy?: InsurancePolicyRelationFilter | null;
  coverageBenefit?: CoverageBenefitRelationFilter | null;
  healthPlanCoverageBenefit?: CoverageBenefitRelationFilter | null;
  benefitMapping?: BenefitMappingRelationFilter | null;
  createdBy?: UserRelationFilter | null;
}

export interface AppointmentBenefitAssignmentWhereUniqueInput {
  id?: string | null;
}

export interface AppointmentCreateManyAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  locationId: string;
  providerId?: string | null;
  estimationWorkflowStatusId: string;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  appointmentLocationId?: string | null;
  preRegistrationSkippedAt?: any | null;
}

export interface AppointmentCreateManyAccountInputEnvelope {
  data: AppointmentCreateManyAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentCreateManyAppointmentLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  locationId: string;
  accountId: string;
  providerId?: string | null;
  estimationWorkflowStatusId: string;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
}

export interface AppointmentCreateManyAppointmentLocationInputEnvelope {
  data: AppointmentCreateManyAppointmentLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentCreateManyEstimationWorkflowStatusInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  locationId: string;
  accountId: string;
  providerId?: string | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  appointmentLocationId?: string | null;
  preRegistrationSkippedAt?: any | null;
}

export interface AppointmentCreateManyEstimationWorkflowStatusInputEnvelope {
  data: AppointmentCreateManyEstimationWorkflowStatusInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  accountId: string;
  providerId?: string | null;
  estimationWorkflowStatusId: string;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  appointmentLocationId?: string | null;
  preRegistrationSkippedAt?: any | null;
}

export interface AppointmentCreateManyLocationInputEnvelope {
  data: AppointmentCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  locationId: string;
  accountId: string;
  providerId?: string | null;
  estimationWorkflowStatusId: string;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  appointmentLocationId?: string | null;
  preRegistrationSkippedAt?: any | null;
}

export interface AppointmentCreateManyOrganizationInputEnvelope {
  data: AppointmentCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentCreateManyProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  locationId: string;
  accountId: string;
  estimationWorkflowStatusId: string;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  appointmentLocationId?: string | null;
  preRegistrationSkippedAt?: any | null;
}

export interface AppointmentCreateManyProviderInputEnvelope {
  data: AppointmentCreateManyProviderInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentCreateNestedManyWithoutAccountInput {
  create?: AppointmentCreateWithoutAccountInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAccountInput[] | null;
  createMany?: AppointmentCreateManyAccountInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedManyWithoutAppointmentLocationInput {
  create?: AppointmentCreateWithoutAppointmentLocationInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAppointmentLocationInput[] | null;
  createMany?: AppointmentCreateManyAppointmentLocationInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedManyWithoutEstimationWorkflowStatusInput {
  create?: AppointmentCreateWithoutEstimationWorkflowStatusInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutEstimationWorkflowStatusInput[] | null;
  createMany?: AppointmentCreateManyEstimationWorkflowStatusInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedManyWithoutLocationInput {
  create?: AppointmentCreateWithoutLocationInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutLocationInput[] | null;
  createMany?: AppointmentCreateManyLocationInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedManyWithoutOrganizationInput {
  create?: AppointmentCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: AppointmentCreateManyOrganizationInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedManyWithoutProviderInput {
  create?: AppointmentCreateWithoutProviderInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutProviderInput[] | null;
  createMany?: AppointmentCreateManyProviderInputEnvelope | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedManyWithoutProvidersInput {
  create?: AppointmentCreateWithoutProvidersInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutProvidersInput[] | null;
  connect?: AppointmentWhereUniqueInput[] | null;
}

export interface AppointmentCreateNestedOneWithoutAccumulatorAdjustmentsInput {
  create?: AppointmentCreateWithoutAccumulatorAdjustmentsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAccumulatorAdjustmentsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutAppointmentBenefitAssignmentsInput {
  create?: AppointmentCreateWithoutAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAppointmentBenefitAssignmentsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutAppointmentLabelingsInput {
  create?: AppointmentCreateWithoutAppointmentLabelingsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAppointmentLabelingsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutBillInput {
  create?: AppointmentCreateWithoutBillInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutBillInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutClaimsInput {
  create?: AppointmentCreateWithoutClaimsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutClaimsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutCommunicationsInput {
  create?: AppointmentCreateWithoutCommunicationsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutCommunicationsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutEligibilityRequestsInput {
  create?: AppointmentCreateWithoutEligibilityRequestsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutEligibilityRequestsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutEstimatesInput {
  create?: AppointmentCreateWithoutEstimatesInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutEstimatesInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutInsurancePolicyWorklistItemsInput {
  create?: AppointmentCreateWithoutInsurancePolicyWorklistItemsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutInsurancePolicyWorklistItemsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutIntegrationLinksInput {
  create?: AppointmentCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutNotesInput {
  create?: AppointmentCreateWithoutNotesInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutNotesInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutTransactionsInput {
  create?: AppointmentCreateWithoutTransactionsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutTransactionsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateNestedOneWithoutVisitCollectionRequestsInput {
  create?: AppointmentCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
}

export interface AppointmentCreateOrConnectWithoutAccountInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutAccountInput;
}

export interface AppointmentCreateOrConnectWithoutAccumulatorAdjustmentsInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutAccumulatorAdjustmentsInput;
}

export interface AppointmentCreateOrConnectWithoutAppointmentBenefitAssignmentsInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutAppointmentBenefitAssignmentsInput;
}

export interface AppointmentCreateOrConnectWithoutAppointmentLabelingsInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutAppointmentLabelingsInput;
}

export interface AppointmentCreateOrConnectWithoutAppointmentLocationInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutAppointmentLocationInput;
}

export interface AppointmentCreateOrConnectWithoutBillInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutBillInput;
}

export interface AppointmentCreateOrConnectWithoutClaimsInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutClaimsInput;
}

export interface AppointmentCreateOrConnectWithoutCommunicationsInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutCommunicationsInput;
}

export interface AppointmentCreateOrConnectWithoutEligibilityRequestsInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutEligibilityRequestsInput;
}

export interface AppointmentCreateOrConnectWithoutEstimatesInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutEstimatesInput;
}

export interface AppointmentCreateOrConnectWithoutEstimationWorkflowStatusInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutEstimationWorkflowStatusInput;
}

export interface AppointmentCreateOrConnectWithoutInsurancePolicyWorklistItemsInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutInsurancePolicyWorklistItemsInput;
}

export interface AppointmentCreateOrConnectWithoutIntegrationLinksInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutIntegrationLinksInput;
}

export interface AppointmentCreateOrConnectWithoutLocationInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutLocationInput;
}

export interface AppointmentCreateOrConnectWithoutNotesInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutNotesInput;
}

export interface AppointmentCreateOrConnectWithoutOrganizationInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutOrganizationInput;
}

export interface AppointmentCreateOrConnectWithoutProviderInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutProviderInput;
}

export interface AppointmentCreateOrConnectWithoutProvidersInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutProvidersInput;
}

export interface AppointmentCreateOrConnectWithoutTransactionsInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutTransactionsInput;
}

export interface AppointmentCreateOrConnectWithoutVisitCollectionRequestsInput {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutVisitCollectionRequestsInput;
}

export interface AppointmentCreateWithoutAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutAccumulatorAdjustmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutAppointmentBenefitAssignmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutAppointmentLabelingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutAppointmentLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutClaimsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutCommunicationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutEligibilityRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutEstimatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutEstimationWorkflowStatusInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutInsurancePolicyWorklistItemsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutNotesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutProvidersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutTransactionsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentCreateWithoutVisitCollectionRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  start?: any | null;
  end?: any | null;
  appointmentType?: string | null;
  status?: AppointmentStatus | null;
  lastEstimationWorkflowStartedAt?: any | null;
  lastEstimationWorkflowCompletedAt?: any | null;
  lastEstimationWorkflowCanceledAt?: any | null;
  estimationWorkflowActiveAt?: any | null;
  estimationWorkflowArchivedAt?: any | null;
  lastEstimationError?: string | null;
  checkedInAt?: any | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  preRegistrationSkippedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutAppointmentsInput;
  location: LocationCreateNestedOneWithoutAppointmentsInput;
  account: AccountCreateNestedOneWithoutAppointmentsInput;
  providers?: ProviderCreateNestedManyWithoutAppointmentsInput | null;
  provider?: ProviderCreateNestedOneWithoutTempAppointmentsInput | null;
  estimationWorkflowStatus: EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutAppointmentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutAppointmentInput | null;
  claims?: ClaimCreateNestedManyWithoutAppointmentInput | null;
  transactions?: TransactionCreateNestedManyWithoutAppointmentInput | null;
  bill?: BillCreateNestedManyWithoutAppointmentInput | null;
  estimates?: EstimateCreateNestedManyWithoutAppointmentInput | null;
  appointmentLocation?: AppointmentLocationCreateNestedOneWithoutAccountsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutAppointmentInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput | null;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutAppointmentInput | null;
  communications?: CommunicationCreateNestedManyWithoutAppointmentInput | null;
  notes?: NoteCreateNestedManyWithoutAppointmentInput | null;
}

export interface AppointmentLabelCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  name: string;
}

export interface AppointmentLabelCreateManyLocationInputEnvelope {
  data: AppointmentLabelCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentLabelCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  locationId: string;
  name: string;
}

export interface AppointmentLabelCreateManyOrganizationInputEnvelope {
  data: AppointmentLabelCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentLabelCreateNestedManyWithoutLocationInput {
  create?: AppointmentLabelCreateWithoutLocationInput[] | null;
  connectOrCreate?: AppointmentLabelCreateOrConnectWithoutLocationInput[] | null;
  createMany?: AppointmentLabelCreateManyLocationInputEnvelope | null;
  connect?: AppointmentLabelWhereUniqueInput[] | null;
}

export interface AppointmentLabelCreateNestedManyWithoutOrganizationInput {
  create?: AppointmentLabelCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: AppointmentLabelCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: AppointmentLabelCreateManyOrganizationInputEnvelope | null;
  connect?: AppointmentLabelWhereUniqueInput[] | null;
}

export interface AppointmentLabelCreateNestedOneWithoutAppointmentLabelingsInput {
  create?: AppointmentLabelCreateWithoutAppointmentLabelingsInput | null;
  connectOrCreate?: AppointmentLabelCreateOrConnectWithoutAppointmentLabelingsInput | null;
  connect?: AppointmentLabelWhereUniqueInput | null;
}

export interface AppointmentLabelCreateOrConnectWithoutAppointmentLabelingsInput {
  where: AppointmentLabelWhereUniqueInput;
  create: AppointmentLabelCreateWithoutAppointmentLabelingsInput;
}

export interface AppointmentLabelCreateOrConnectWithoutLocationInput {
  where: AppointmentLabelWhereUniqueInput;
  create: AppointmentLabelCreateWithoutLocationInput;
}

export interface AppointmentLabelCreateOrConnectWithoutOrganizationInput {
  where: AppointmentLabelWhereUniqueInput;
  create: AppointmentLabelCreateWithoutOrganizationInput;
}

export interface AppointmentLabelCreateWithoutAppointmentLabelingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  organization: OrganizationCreateNestedOneWithoutAppointmentLabelsInput;
  location: LocationCreateNestedOneWithoutAppointmentLabelsInput;
}

export interface AppointmentLabelCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  organization: OrganizationCreateNestedOneWithoutAppointmentLabelsInput;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentLabelInput | null;
}

export interface AppointmentLabelCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  location: LocationCreateNestedOneWithoutAppointmentLabelsInput;
  appointmentLabelings?: AppointmentLabelingCreateNestedManyWithoutAppointmentLabelInput | null;
}

export interface AppointmentLabelListRelationFilter {
  every?: AppointmentLabelWhereInput | null;
  some?: AppointmentLabelWhereInput | null;
  none?: AppointmentLabelWhereInput | null;
}

export interface AppointmentLabelLocationIdNameCompoundUniqueInput {
  locationId: string;
  name: string;
}

export interface AppointmentLabelOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface AppointmentLabelRelationFilter {
  is?: AppointmentLabelWhereInput | null;
  isNot?: AppointmentLabelWhereInput | null;
}

export interface AppointmentLabelScalarWhereInput {
  AND?: AppointmentLabelScalarWhereInput[] | null;
  OR?: AppointmentLabelScalarWhereInput[] | null;
  NOT?: AppointmentLabelScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
}

export interface AppointmentLabelUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
}

export interface AppointmentLabelUpdateManyWithWhereWithoutLocationInput {
  where: AppointmentLabelScalarWhereInput;
  data: AppointmentLabelUpdateManyMutationInput;
}

export interface AppointmentLabelUpdateManyWithWhereWithoutOrganizationInput {
  where: AppointmentLabelScalarWhereInput;
  data: AppointmentLabelUpdateManyMutationInput;
}

export interface AppointmentLabelUpdateManyWithoutLocationNestedInput {
  create?: AppointmentLabelCreateWithoutLocationInput[] | null;
  connectOrCreate?: AppointmentLabelCreateOrConnectWithoutLocationInput[] | null;
  upsert?: AppointmentLabelUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: AppointmentLabelCreateManyLocationInputEnvelope | null;
  set?: AppointmentLabelWhereUniqueInput[] | null;
  disconnect?: AppointmentLabelWhereUniqueInput[] | null;
  delete?: AppointmentLabelWhereUniqueInput[] | null;
  connect?: AppointmentLabelWhereUniqueInput[] | null;
  update?: AppointmentLabelUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: AppointmentLabelUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: AppointmentLabelScalarWhereInput[] | null;
}

export interface AppointmentLabelUpdateManyWithoutOrganizationNestedInput {
  create?: AppointmentLabelCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: AppointmentLabelCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: AppointmentLabelUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: AppointmentLabelCreateManyOrganizationInputEnvelope | null;
  set?: AppointmentLabelWhereUniqueInput[] | null;
  disconnect?: AppointmentLabelWhereUniqueInput[] | null;
  delete?: AppointmentLabelWhereUniqueInput[] | null;
  connect?: AppointmentLabelWhereUniqueInput[] | null;
  update?: AppointmentLabelUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: AppointmentLabelUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: AppointmentLabelScalarWhereInput[] | null;
}

export interface AppointmentLabelUpdateOneRequiredWithoutAppointmentLabelingsNestedInput {
  create?: AppointmentLabelCreateWithoutAppointmentLabelingsInput | null;
  connectOrCreate?: AppointmentLabelCreateOrConnectWithoutAppointmentLabelingsInput | null;
  upsert?: AppointmentLabelUpsertWithoutAppointmentLabelingsInput | null;
  connect?: AppointmentLabelWhereUniqueInput | null;
  update?: AppointmentLabelUpdateWithoutAppointmentLabelingsInput | null;
}

export interface AppointmentLabelUpdateWithWhereUniqueWithoutLocationInput {
  where: AppointmentLabelWhereUniqueInput;
  data: AppointmentLabelUpdateWithoutLocationInput;
}

export interface AppointmentLabelUpdateWithWhereUniqueWithoutOrganizationInput {
  where: AppointmentLabelWhereUniqueInput;
  data: AppointmentLabelUpdateWithoutOrganizationInput;
}

export interface AppointmentLabelUpdateWithoutAppointmentLabelingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentLabelsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentLabelsNestedInput | null;
}

export interface AppointmentLabelUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentLabelsNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentLabelNestedInput | null;
}

export interface AppointmentLabelUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentLabelsNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentLabelNestedInput | null;
}

export interface AppointmentLabelUpsertWithWhereUniqueWithoutLocationInput {
  where: AppointmentLabelWhereUniqueInput;
  update: AppointmentLabelUpdateWithoutLocationInput;
  create: AppointmentLabelCreateWithoutLocationInput;
}

export interface AppointmentLabelUpsertWithWhereUniqueWithoutOrganizationInput {
  where: AppointmentLabelWhereUniqueInput;
  update: AppointmentLabelUpdateWithoutOrganizationInput;
  create: AppointmentLabelCreateWithoutOrganizationInput;
}

export interface AppointmentLabelUpsertWithoutAppointmentLabelingsInput {
  update: AppointmentLabelUpdateWithoutAppointmentLabelingsInput;
  create: AppointmentLabelCreateWithoutAppointmentLabelingsInput;
}

export interface AppointmentLabelWhereInput {
  AND?: AppointmentLabelWhereInput[] | null;
  OR?: AppointmentLabelWhereInput[] | null;
  NOT?: AppointmentLabelWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
  organization?: OrganizationRelationFilter | null;
  location?: LocationRelationFilter | null;
  appointmentLabelings?: AppointmentLabelingListRelationFilter | null;
}

export interface AppointmentLabelWhereUniqueInput {
  id?: string | null;
  locationId_name?: AppointmentLabelLocationIdNameCompoundUniqueInput | null;
}

export interface AppointmentLabelingAppointmentIdAppointmentLabelIdCompoundUniqueInput {
  appointmentId: string;
  appointmentLabelId: string;
}

export interface AppointmentLabelingCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointmentLabelId: string;
}

export interface AppointmentLabelingCreateManyAppointmentInputEnvelope {
  data: AppointmentLabelingCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentLabelingCreateManyAppointmentLabelInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointmentId: string;
}

export interface AppointmentLabelingCreateManyAppointmentLabelInputEnvelope {
  data: AppointmentLabelingCreateManyAppointmentLabelInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentLabelingCreateNestedManyWithoutAppointmentInput {
  create?: AppointmentLabelingCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: AppointmentLabelingCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: AppointmentLabelingCreateManyAppointmentInputEnvelope | null;
  connect?: AppointmentLabelingWhereUniqueInput[] | null;
}

export interface AppointmentLabelingCreateNestedManyWithoutAppointmentLabelInput {
  create?: AppointmentLabelingCreateWithoutAppointmentLabelInput[] | null;
  connectOrCreate?: AppointmentLabelingCreateOrConnectWithoutAppointmentLabelInput[] | null;
  createMany?: AppointmentLabelingCreateManyAppointmentLabelInputEnvelope | null;
  connect?: AppointmentLabelingWhereUniqueInput[] | null;
}

export interface AppointmentLabelingCreateOrConnectWithoutAppointmentInput {
  where: AppointmentLabelingWhereUniqueInput;
  create: AppointmentLabelingCreateWithoutAppointmentInput;
}

export interface AppointmentLabelingCreateOrConnectWithoutAppointmentLabelInput {
  where: AppointmentLabelingWhereUniqueInput;
  create: AppointmentLabelingCreateWithoutAppointmentLabelInput;
}

export interface AppointmentLabelingCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointmentLabel: AppointmentLabelCreateNestedOneWithoutAppointmentLabelingsInput;
}

export interface AppointmentLabelingCreateWithoutAppointmentLabelInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointment: AppointmentCreateNestedOneWithoutAppointmentLabelingsInput;
}

export interface AppointmentLabelingListRelationFilter {
  every?: AppointmentLabelingWhereInput | null;
  some?: AppointmentLabelingWhereInput | null;
  none?: AppointmentLabelingWhereInput | null;
}

export interface AppointmentLabelingOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface AppointmentLabelingScalarWhereInput {
  AND?: AppointmentLabelingScalarWhereInput[] | null;
  OR?: AppointmentLabelingScalarWhereInput[] | null;
  NOT?: AppointmentLabelingScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  appointmentId?: UuidFilter | null;
  appointmentLabelId?: UuidFilter | null;
}

export interface AppointmentLabelingUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
}

export interface AppointmentLabelingUpdateManyWithWhereWithoutAppointmentInput {
  where: AppointmentLabelingScalarWhereInput;
  data: AppointmentLabelingUpdateManyMutationInput;
}

export interface AppointmentLabelingUpdateManyWithWhereWithoutAppointmentLabelInput {
  where: AppointmentLabelingScalarWhereInput;
  data: AppointmentLabelingUpdateManyMutationInput;
}

export interface AppointmentLabelingUpdateManyWithoutAppointmentLabelNestedInput {
  create?: AppointmentLabelingCreateWithoutAppointmentLabelInput[] | null;
  connectOrCreate?: AppointmentLabelingCreateOrConnectWithoutAppointmentLabelInput[] | null;
  upsert?: AppointmentLabelingUpsertWithWhereUniqueWithoutAppointmentLabelInput[] | null;
  createMany?: AppointmentLabelingCreateManyAppointmentLabelInputEnvelope | null;
  set?: AppointmentLabelingWhereUniqueInput[] | null;
  disconnect?: AppointmentLabelingWhereUniqueInput[] | null;
  delete?: AppointmentLabelingWhereUniqueInput[] | null;
  connect?: AppointmentLabelingWhereUniqueInput[] | null;
  update?: AppointmentLabelingUpdateWithWhereUniqueWithoutAppointmentLabelInput[] | null;
  updateMany?: AppointmentLabelingUpdateManyWithWhereWithoutAppointmentLabelInput[] | null;
  deleteMany?: AppointmentLabelingScalarWhereInput[] | null;
}

export interface AppointmentLabelingUpdateManyWithoutAppointmentNestedInput {
  create?: AppointmentLabelingCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: AppointmentLabelingCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: AppointmentLabelingUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: AppointmentLabelingCreateManyAppointmentInputEnvelope | null;
  set?: AppointmentLabelingWhereUniqueInput[] | null;
  disconnect?: AppointmentLabelingWhereUniqueInput[] | null;
  delete?: AppointmentLabelingWhereUniqueInput[] | null;
  connect?: AppointmentLabelingWhereUniqueInput[] | null;
  update?: AppointmentLabelingUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: AppointmentLabelingUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: AppointmentLabelingScalarWhereInput[] | null;
}

export interface AppointmentLabelingUpdateWithWhereUniqueWithoutAppointmentInput {
  where: AppointmentLabelingWhereUniqueInput;
  data: AppointmentLabelingUpdateWithoutAppointmentInput;
}

export interface AppointmentLabelingUpdateWithWhereUniqueWithoutAppointmentLabelInput {
  where: AppointmentLabelingWhereUniqueInput;
  data: AppointmentLabelingUpdateWithoutAppointmentLabelInput;
}

export interface AppointmentLabelingUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  appointmentLabel?: AppointmentLabelUpdateOneRequiredWithoutAppointmentLabelingsNestedInput | null;
}

export interface AppointmentLabelingUpdateWithoutAppointmentLabelInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutAppointmentLabelingsNestedInput | null;
}

export interface AppointmentLabelingUpsertWithWhereUniqueWithoutAppointmentInput {
  where: AppointmentLabelingWhereUniqueInput;
  update: AppointmentLabelingUpdateWithoutAppointmentInput;
  create: AppointmentLabelingCreateWithoutAppointmentInput;
}

export interface AppointmentLabelingUpsertWithWhereUniqueWithoutAppointmentLabelInput {
  where: AppointmentLabelingWhereUniqueInput;
  update: AppointmentLabelingUpdateWithoutAppointmentLabelInput;
  create: AppointmentLabelingCreateWithoutAppointmentLabelInput;
}

export interface AppointmentLabelingWhereInput {
  AND?: AppointmentLabelingWhereInput[] | null;
  OR?: AppointmentLabelingWhereInput[] | null;
  NOT?: AppointmentLabelingWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  appointmentId?: UuidFilter | null;
  appointmentLabelId?: UuidFilter | null;
  appointment?: AppointmentRelationFilter | null;
  appointmentLabel?: AppointmentLabelRelationFilter | null;
}

export interface AppointmentLabelingWhereUniqueInput {
  id?: string | null;
  appointmentId_appointmentLabelId?: AppointmentLabelingAppointmentIdAppointmentLabelIdCompoundUniqueInput | null;
}

export interface AppointmentListRelationFilter {
  every?: AppointmentWhereInput | null;
  some?: AppointmentWhereInput | null;
  none?: AppointmentWhereInput | null;
}

export interface AppointmentLocationCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  externalLocationReference: string;
}

export interface AppointmentLocationCreateManyOrganizationInputEnvelope {
  data: AppointmentLocationCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface AppointmentLocationCreateNestedManyWithoutOrganizationInput {
  create?: AppointmentLocationCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: AppointmentLocationCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: AppointmentLocationCreateManyOrganizationInputEnvelope | null;
  connect?: AppointmentLocationWhereUniqueInput[] | null;
}

export interface AppointmentLocationCreateNestedOneWithoutAccountsInput {
  create?: AppointmentLocationCreateWithoutAccountsInput | null;
  connectOrCreate?: AppointmentLocationCreateOrConnectWithoutAccountsInput | null;
  connect?: AppointmentLocationWhereUniqueInput | null;
}

export interface AppointmentLocationCreateOrConnectWithoutAccountsInput {
  where: AppointmentLocationWhereUniqueInput;
  create: AppointmentLocationCreateWithoutAccountsInput;
}

export interface AppointmentLocationCreateOrConnectWithoutOrganizationInput {
  where: AppointmentLocationWhereUniqueInput;
  create: AppointmentLocationCreateWithoutOrganizationInput;
}

export interface AppointmentLocationCreateWithoutAccountsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  externalLocationReference: string;
  organization: OrganizationCreateNestedOneWithoutAppointmentLocationsInput;
}

export interface AppointmentLocationCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  externalLocationReference: string;
  accounts?: AppointmentCreateNestedManyWithoutAppointmentLocationInput | null;
}

export interface AppointmentLocationListRelationFilter {
  every?: AppointmentLocationWhereInput | null;
  some?: AppointmentLocationWhereInput | null;
  none?: AppointmentLocationWhereInput | null;
}

export interface AppointmentLocationOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface AppointmentLocationOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  organizationId?: SortOrder | null;
  name?: SortOrder | null;
  externalLocationReference?: SortOrder | null;
  organization?: OrganizationOrderByWithRelationInput | null;
  accounts?: AppointmentOrderByRelationAggregateInput | null;
}

export interface AppointmentLocationOrganizationIdNameCompoundUniqueInput {
  organizationId: string;
  name: string;
}

export interface AppointmentLocationRelationFilter {
  is?: AppointmentLocationWhereInput | null;
  isNot?: AppointmentLocationWhereInput | null;
}

export interface AppointmentLocationScalarWhereInput {
  AND?: AppointmentLocationScalarWhereInput[] | null;
  OR?: AppointmentLocationScalarWhereInput[] | null;
  NOT?: AppointmentLocationScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  name?: StringFilter | null;
  externalLocationReference?: StringFilter | null;
}

export interface AppointmentLocationUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  externalLocationReference?: StringFieldUpdateOperationsInput | null;
}

export interface AppointmentLocationUpdateManyWithWhereWithoutOrganizationInput {
  where: AppointmentLocationScalarWhereInput;
  data: AppointmentLocationUpdateManyMutationInput;
}

export interface AppointmentLocationUpdateManyWithoutOrganizationNestedInput {
  create?: AppointmentLocationCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: AppointmentLocationCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: AppointmentLocationUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: AppointmentLocationCreateManyOrganizationInputEnvelope | null;
  set?: AppointmentLocationWhereUniqueInput[] | null;
  disconnect?: AppointmentLocationWhereUniqueInput[] | null;
  delete?: AppointmentLocationWhereUniqueInput[] | null;
  connect?: AppointmentLocationWhereUniqueInput[] | null;
  update?: AppointmentLocationUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: AppointmentLocationUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: AppointmentLocationScalarWhereInput[] | null;
}

export interface AppointmentLocationUpdateOneWithoutAccountsNestedInput {
  create?: AppointmentLocationCreateWithoutAccountsInput | null;
  connectOrCreate?: AppointmentLocationCreateOrConnectWithoutAccountsInput | null;
  upsert?: AppointmentLocationUpsertWithoutAccountsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AppointmentLocationWhereUniqueInput | null;
  update?: AppointmentLocationUpdateWithoutAccountsInput | null;
}

export interface AppointmentLocationUpdateWithWhereUniqueWithoutOrganizationInput {
  where: AppointmentLocationWhereUniqueInput;
  data: AppointmentLocationUpdateWithoutOrganizationInput;
}

export interface AppointmentLocationUpdateWithoutAccountsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  externalLocationReference?: StringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentLocationsNestedInput | null;
}

export interface AppointmentLocationUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  externalLocationReference?: StringFieldUpdateOperationsInput | null;
  accounts?: AppointmentUpdateManyWithoutAppointmentLocationNestedInput | null;
}

export interface AppointmentLocationUpsertWithWhereUniqueWithoutOrganizationInput {
  where: AppointmentLocationWhereUniqueInput;
  update: AppointmentLocationUpdateWithoutOrganizationInput;
  create: AppointmentLocationCreateWithoutOrganizationInput;
}

export interface AppointmentLocationUpsertWithoutAccountsInput {
  update: AppointmentLocationUpdateWithoutAccountsInput;
  create: AppointmentLocationCreateWithoutAccountsInput;
}

export interface AppointmentLocationWhereInput {
  AND?: AppointmentLocationWhereInput[] | null;
  OR?: AppointmentLocationWhereInput[] | null;
  NOT?: AppointmentLocationWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  name?: StringFilter | null;
  externalLocationReference?: StringFilter | null;
  organization?: OrganizationRelationFilter | null;
  accounts?: AppointmentListRelationFilter | null;
}

export interface AppointmentLocationWhereUniqueInput {
  id?: string | null;
  organizationId_name?: AppointmentLocationOrganizationIdNameCompoundUniqueInput | null;
}

export interface AppointmentOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface AppointmentOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  organizationId?: SortOrder | null;
  start?: SortOrderInput | null;
  end?: SortOrderInput | null;
  appointmentType?: SortOrderInput | null;
  status?: SortOrderInput | null;
  locationId?: SortOrder | null;
  accountId?: SortOrder | null;
  providerId?: SortOrderInput | null;
  estimationWorkflowStatusId?: SortOrder | null;
  lastEstimationWorkflowStartedAt?: SortOrderInput | null;
  lastEstimationWorkflowCompletedAt?: SortOrderInput | null;
  lastEstimationWorkflowCanceledAt?: SortOrderInput | null;
  estimationWorkflowActiveAt?: SortOrderInput | null;
  estimationWorkflowArchivedAt?: SortOrderInput | null;
  lastEstimationError?: SortOrderInput | null;
  checkedInAt?: SortOrderInput | null;
  estimationPausedAt?: SortOrderInput | null;
  preVisitReminderPausedAt?: SortOrderInput | null;
  timeOfServiceAutoChargePausedAt?: SortOrderInput | null;
  appointmentLocationId?: SortOrderInput | null;
  preRegistrationSkippedAt?: SortOrderInput | null;
  organization?: OrganizationOrderByWithRelationInput | null;
  location?: LocationOrderByWithRelationInput | null;
  account?: AccountOrderByWithRelationInput | null;
  providers?: ProviderOrderByRelationAggregateInput | null;
  provider?: ProviderOrderByWithRelationInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusOrderByWithRelationInput | null;
  eligibilityRequests?: EligibilityRequestOrderByRelationAggregateInput | null;
  integrationLinks?: IntegrationLinkOrderByRelationAggregateInput | null;
  claims?: ClaimOrderByRelationAggregateInput | null;
  transactions?: TransactionOrderByRelationAggregateInput | null;
  bill?: BillOrderByRelationAggregateInput | null;
  estimates?: EstimateOrderByRelationAggregateInput | null;
  appointmentLocation?: AppointmentLocationOrderByWithRelationInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentOrderByRelationAggregateInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemOrderByRelationAggregateInput | null;
  visitCollectionRequests?: VisitCollectionRequestOrderByRelationAggregateInput | null;
  appointmentLabelings?: AppointmentLabelingOrderByRelationAggregateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentOrderByRelationAggregateInput | null;
  communications?: CommunicationOrderByRelationAggregateInput | null;
  notes?: NoteOrderByRelationAggregateInput | null;
}

export interface AppointmentRelationFilter {
  is?: AppointmentWhereInput | null;
  isNot?: AppointmentWhereInput | null;
}

export interface AppointmentScalarWhereInput {
  AND?: AppointmentScalarWhereInput[] | null;
  OR?: AppointmentScalarWhereInput[] | null;
  NOT?: AppointmentScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  start?: DateTimeNullableFilter | null;
  end?: DateTimeNullableFilter | null;
  appointmentType?: StringNullableFilter | null;
  status?: EnumAppointmentStatusNullableFilter | null;
  locationId?: UuidFilter | null;
  accountId?: UuidFilter | null;
  providerId?: UuidNullableFilter | null;
  estimationWorkflowStatusId?: UuidFilter | null;
  lastEstimationWorkflowStartedAt?: DateTimeNullableFilter | null;
  lastEstimationWorkflowCompletedAt?: DateTimeNullableFilter | null;
  lastEstimationWorkflowCanceledAt?: DateTimeNullableFilter | null;
  estimationWorkflowActiveAt?: DateTimeNullableFilter | null;
  estimationWorkflowArchivedAt?: DateTimeNullableFilter | null;
  lastEstimationError?: StringNullableFilter | null;
  checkedInAt?: DateTimeNullableFilter | null;
  estimationPausedAt?: DateTimeNullableFilter | null;
  preVisitReminderPausedAt?: DateTimeNullableFilter | null;
  timeOfServiceAutoChargePausedAt?: DateTimeNullableFilter | null;
  appointmentLocationId?: UuidNullableFilter | null;
  preRegistrationSkippedAt?: DateTimeNullableFilter | null;
}

export interface AppointmentUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
}

export interface AppointmentUpdateManyWithWhereWithoutAccountInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithWhereWithoutAppointmentLocationInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithWhereWithoutEstimationWorkflowStatusInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithWhereWithoutLocationInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithWhereWithoutOrganizationInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithWhereWithoutProviderInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithWhereWithoutProvidersInput {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
}

export interface AppointmentUpdateManyWithoutAccountNestedInput {
  create?: AppointmentCreateWithoutAccountInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAccountInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutAccountInput[] | null;
  createMany?: AppointmentCreateManyAccountInputEnvelope | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutAccountInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateManyWithoutAppointmentLocationNestedInput {
  create?: AppointmentCreateWithoutAppointmentLocationInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAppointmentLocationInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutAppointmentLocationInput[] | null;
  createMany?: AppointmentCreateManyAppointmentLocationInputEnvelope | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutAppointmentLocationInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutAppointmentLocationInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateManyWithoutEstimationWorkflowStatusNestedInput {
  create?: AppointmentCreateWithoutEstimationWorkflowStatusInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutEstimationWorkflowStatusInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutEstimationWorkflowStatusInput[] | null;
  createMany?: AppointmentCreateManyEstimationWorkflowStatusInputEnvelope | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutEstimationWorkflowStatusInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutEstimationWorkflowStatusInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateManyWithoutLocationNestedInput {
  create?: AppointmentCreateWithoutLocationInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutLocationInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: AppointmentCreateManyLocationInputEnvelope | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateManyWithoutOrganizationNestedInput {
  create?: AppointmentCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: AppointmentCreateManyOrganizationInputEnvelope | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateManyWithoutProviderNestedInput {
  create?: AppointmentCreateWithoutProviderInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutProviderInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutProviderInput[] | null;
  createMany?: AppointmentCreateManyProviderInputEnvelope | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutProviderInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutProviderInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateManyWithoutProvidersNestedInput {
  create?: AppointmentCreateWithoutProvidersInput[] | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutProvidersInput[] | null;
  upsert?: AppointmentUpsertWithWhereUniqueWithoutProvidersInput[] | null;
  set?: AppointmentWhereUniqueInput[] | null;
  disconnect?: AppointmentWhereUniqueInput[] | null;
  delete?: AppointmentWhereUniqueInput[] | null;
  connect?: AppointmentWhereUniqueInput[] | null;
  update?: AppointmentUpdateWithWhereUniqueWithoutProvidersInput[] | null;
  updateMany?: AppointmentUpdateManyWithWhereWithoutProvidersInput[] | null;
  deleteMany?: AppointmentScalarWhereInput[] | null;
}

export interface AppointmentUpdateOneRequiredWithoutAccumulatorAdjustmentsNestedInput {
  create?: AppointmentCreateWithoutAccumulatorAdjustmentsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAccumulatorAdjustmentsInput | null;
  upsert?: AppointmentUpsertWithoutAccumulatorAdjustmentsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutAccumulatorAdjustmentsInput | null;
}

export interface AppointmentUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput {
  create?: AppointmentCreateWithoutAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAppointmentBenefitAssignmentsInput | null;
  upsert?: AppointmentUpsertWithoutAppointmentBenefitAssignmentsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface AppointmentUpdateOneRequiredWithoutAppointmentLabelingsNestedInput {
  create?: AppointmentCreateWithoutAppointmentLabelingsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutAppointmentLabelingsInput | null;
  upsert?: AppointmentUpsertWithoutAppointmentLabelingsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutAppointmentLabelingsInput | null;
}

export interface AppointmentUpdateOneRequiredWithoutEstimatesNestedInput {
  create?: AppointmentCreateWithoutEstimatesInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutEstimatesInput | null;
  upsert?: AppointmentUpsertWithoutEstimatesInput | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutEstimatesInput | null;
}

export interface AppointmentUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput {
  create?: AppointmentCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  upsert?: AppointmentUpsertWithoutVisitCollectionRequestsInput | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutVisitCollectionRequestsInput | null;
}

export interface AppointmentUpdateOneWithoutBillNestedInput {
  create?: AppointmentCreateWithoutBillInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutBillInput | null;
  upsert?: AppointmentUpsertWithoutBillInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutBillInput | null;
}

export interface AppointmentUpdateOneWithoutClaimsNestedInput {
  create?: AppointmentCreateWithoutClaimsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutClaimsInput | null;
  upsert?: AppointmentUpsertWithoutClaimsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutClaimsInput | null;
}

export interface AppointmentUpdateOneWithoutCommunicationsNestedInput {
  create?: AppointmentCreateWithoutCommunicationsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutCommunicationsInput | null;
  upsert?: AppointmentUpsertWithoutCommunicationsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutCommunicationsInput | null;
}

export interface AppointmentUpdateOneWithoutEligibilityRequestsNestedInput {
  create?: AppointmentCreateWithoutEligibilityRequestsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutEligibilityRequestsInput | null;
  upsert?: AppointmentUpsertWithoutEligibilityRequestsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutEligibilityRequestsInput | null;
}

export interface AppointmentUpdateOneWithoutInsurancePolicyWorklistItemsNestedInput {
  create?: AppointmentCreateWithoutInsurancePolicyWorklistItemsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutInsurancePolicyWorklistItemsInput | null;
  upsert?: AppointmentUpsertWithoutInsurancePolicyWorklistItemsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutInsurancePolicyWorklistItemsInput | null;
}

export interface AppointmentUpdateOneWithoutIntegrationLinksNestedInput {
  create?: AppointmentCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: AppointmentUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutIntegrationLinksInput | null;
}

export interface AppointmentUpdateOneWithoutNotesNestedInput {
  create?: AppointmentCreateWithoutNotesInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutNotesInput | null;
  upsert?: AppointmentUpsertWithoutNotesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutNotesInput | null;
}

export interface AppointmentUpdateOneWithoutTransactionsNestedInput {
  create?: AppointmentCreateWithoutTransactionsInput | null;
  connectOrCreate?: AppointmentCreateOrConnectWithoutTransactionsInput | null;
  upsert?: AppointmentUpsertWithoutTransactionsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: AppointmentWhereUniqueInput | null;
  update?: AppointmentUpdateWithoutTransactionsInput | null;
}

export interface AppointmentUpdateWithWhereUniqueWithoutAccountInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutAccountInput;
}

export interface AppointmentUpdateWithWhereUniqueWithoutAppointmentLocationInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutAppointmentLocationInput;
}

export interface AppointmentUpdateWithWhereUniqueWithoutEstimationWorkflowStatusInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutEstimationWorkflowStatusInput;
}

export interface AppointmentUpdateWithWhereUniqueWithoutLocationInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutLocationInput;
}

export interface AppointmentUpdateWithWhereUniqueWithoutOrganizationInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutOrganizationInput;
}

export interface AppointmentUpdateWithWhereUniqueWithoutProviderInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutProviderInput;
}

export interface AppointmentUpdateWithWhereUniqueWithoutProvidersInput {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutProvidersInput;
}

export interface AppointmentUpdateWithoutAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutAccumulatorAdjustmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutAppointmentBenefitAssignmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutAppointmentLabelingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutAppointmentLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutBillInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutClaimsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutCommunicationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutEligibilityRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutEstimatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutEstimationWorkflowStatusInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutInsurancePolicyWorklistItemsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutNotesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutProviderInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutProvidersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutTransactionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpdateWithoutVisitCollectionRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  start?: NullableDateTimeFieldUpdateOperationsInput | null;
  end?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentType?: NullableStringFieldUpdateOperationsInput | null;
  status?: NullableEnumAppointmentStatusFieldUpdateOperationsInput | null;
  lastEstimationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastEstimationError?: NullableStringFieldUpdateOperationsInput | null;
  checkedInAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preRegistrationSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  providers?: ProviderUpdateManyWithoutAppointmentsNestedInput | null;
  provider?: ProviderUpdateOneWithoutTempAppointmentsNestedInput | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutAppointmentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutAppointmentNestedInput | null;
  claims?: ClaimUpdateManyWithoutAppointmentNestedInput | null;
  transactions?: TransactionUpdateManyWithoutAppointmentNestedInput | null;
  bill?: BillUpdateManyWithoutAppointmentNestedInput | null;
  estimates?: EstimateUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLocation?: AppointmentLocationUpdateOneWithoutAccountsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutAppointmentNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput | null;
  appointmentLabelings?: AppointmentLabelingUpdateManyWithoutAppointmentNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutAppointmentNestedInput | null;
  communications?: CommunicationUpdateManyWithoutAppointmentNestedInput | null;
  notes?: NoteUpdateManyWithoutAppointmentNestedInput | null;
}

export interface AppointmentUpsertWithWhereUniqueWithoutAccountInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutAccountInput;
  create: AppointmentCreateWithoutAccountInput;
}

export interface AppointmentUpsertWithWhereUniqueWithoutAppointmentLocationInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutAppointmentLocationInput;
  create: AppointmentCreateWithoutAppointmentLocationInput;
}

export interface AppointmentUpsertWithWhereUniqueWithoutEstimationWorkflowStatusInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutEstimationWorkflowStatusInput;
  create: AppointmentCreateWithoutEstimationWorkflowStatusInput;
}

export interface AppointmentUpsertWithWhereUniqueWithoutLocationInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutLocationInput;
  create: AppointmentCreateWithoutLocationInput;
}

export interface AppointmentUpsertWithWhereUniqueWithoutOrganizationInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutOrganizationInput;
  create: AppointmentCreateWithoutOrganizationInput;
}

export interface AppointmentUpsertWithWhereUniqueWithoutProviderInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutProviderInput;
  create: AppointmentCreateWithoutProviderInput;
}

export interface AppointmentUpsertWithWhereUniqueWithoutProvidersInput {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutProvidersInput;
  create: AppointmentCreateWithoutProvidersInput;
}

export interface AppointmentUpsertWithoutAccumulatorAdjustmentsInput {
  update: AppointmentUpdateWithoutAccumulatorAdjustmentsInput;
  create: AppointmentCreateWithoutAccumulatorAdjustmentsInput;
}

export interface AppointmentUpsertWithoutAppointmentBenefitAssignmentsInput {
  update: AppointmentUpdateWithoutAppointmentBenefitAssignmentsInput;
  create: AppointmentCreateWithoutAppointmentBenefitAssignmentsInput;
}

export interface AppointmentUpsertWithoutAppointmentLabelingsInput {
  update: AppointmentUpdateWithoutAppointmentLabelingsInput;
  create: AppointmentCreateWithoutAppointmentLabelingsInput;
}

export interface AppointmentUpsertWithoutBillInput {
  update: AppointmentUpdateWithoutBillInput;
  create: AppointmentCreateWithoutBillInput;
}

export interface AppointmentUpsertWithoutClaimsInput {
  update: AppointmentUpdateWithoutClaimsInput;
  create: AppointmentCreateWithoutClaimsInput;
}

export interface AppointmentUpsertWithoutCommunicationsInput {
  update: AppointmentUpdateWithoutCommunicationsInput;
  create: AppointmentCreateWithoutCommunicationsInput;
}

export interface AppointmentUpsertWithoutEligibilityRequestsInput {
  update: AppointmentUpdateWithoutEligibilityRequestsInput;
  create: AppointmentCreateWithoutEligibilityRequestsInput;
}

export interface AppointmentUpsertWithoutEstimatesInput {
  update: AppointmentUpdateWithoutEstimatesInput;
  create: AppointmentCreateWithoutEstimatesInput;
}

export interface AppointmentUpsertWithoutInsurancePolicyWorklistItemsInput {
  update: AppointmentUpdateWithoutInsurancePolicyWorklistItemsInput;
  create: AppointmentCreateWithoutInsurancePolicyWorklistItemsInput;
}

export interface AppointmentUpsertWithoutIntegrationLinksInput {
  update: AppointmentUpdateWithoutIntegrationLinksInput;
  create: AppointmentCreateWithoutIntegrationLinksInput;
}

export interface AppointmentUpsertWithoutNotesInput {
  update: AppointmentUpdateWithoutNotesInput;
  create: AppointmentCreateWithoutNotesInput;
}

export interface AppointmentUpsertWithoutTransactionsInput {
  update: AppointmentUpdateWithoutTransactionsInput;
  create: AppointmentCreateWithoutTransactionsInput;
}

export interface AppointmentUpsertWithoutVisitCollectionRequestsInput {
  update: AppointmentUpdateWithoutVisitCollectionRequestsInput;
  create: AppointmentCreateWithoutVisitCollectionRequestsInput;
}

export interface AppointmentWhereInput {
  AND?: AppointmentWhereInput[] | null;
  OR?: AppointmentWhereInput[] | null;
  NOT?: AppointmentWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  start?: DateTimeNullableFilter | null;
  end?: DateTimeNullableFilter | null;
  appointmentType?: StringNullableFilter | null;
  status?: EnumAppointmentStatusNullableFilter | null;
  locationId?: UuidFilter | null;
  accountId?: UuidFilter | null;
  providerId?: UuidNullableFilter | null;
  estimationWorkflowStatusId?: UuidFilter | null;
  lastEstimationWorkflowStartedAt?: DateTimeNullableFilter | null;
  lastEstimationWorkflowCompletedAt?: DateTimeNullableFilter | null;
  lastEstimationWorkflowCanceledAt?: DateTimeNullableFilter | null;
  estimationWorkflowActiveAt?: DateTimeNullableFilter | null;
  estimationWorkflowArchivedAt?: DateTimeNullableFilter | null;
  lastEstimationError?: StringNullableFilter | null;
  checkedInAt?: DateTimeNullableFilter | null;
  estimationPausedAt?: DateTimeNullableFilter | null;
  preVisitReminderPausedAt?: DateTimeNullableFilter | null;
  timeOfServiceAutoChargePausedAt?: DateTimeNullableFilter | null;
  appointmentLocationId?: UuidNullableFilter | null;
  preRegistrationSkippedAt?: DateTimeNullableFilter | null;
  organization?: OrganizationRelationFilter | null;
  location?: LocationRelationFilter | null;
  account?: AccountRelationFilter | null;
  providers?: ProviderListRelationFilter | null;
  provider?: ProviderRelationFilter | null;
  estimationWorkflowStatus?: EstimationWorkflowStatusRelationFilter | null;
  eligibilityRequests?: EligibilityRequestListRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
  claims?: ClaimListRelationFilter | null;
  transactions?: TransactionListRelationFilter | null;
  bill?: BillListRelationFilter | null;
  estimates?: EstimateListRelationFilter | null;
  appointmentLocation?: AppointmentLocationRelationFilter | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentListRelationFilter | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemListRelationFilter | null;
  visitCollectionRequests?: VisitCollectionRequestListRelationFilter | null;
  appointmentLabelings?: AppointmentLabelingListRelationFilter | null;
  accumulatorAdjustments?: AccumulatorAdjustmentListRelationFilter | null;
  communications?: CommunicationListRelationFilter | null;
  notes?: NoteListRelationFilter | null;
}

export interface AppointmentWhereUniqueInput {
  id?: string | null;
}

export interface BenefitAccumulatorAdjustmentCreateManyAccumulatorAdjustmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  visitsUsed: number;
  deductibleAmountUsed: number;
  outOfPocketAmountUsed: number;
  providerServiceConfigurationId: string;
}

export interface BenefitAccumulatorAdjustmentCreateManyAccumulatorAdjustmentInputEnvelope {
  data: BenefitAccumulatorAdjustmentCreateManyAccumulatorAdjustmentInput[];
  skipDuplicates?: boolean | null;
}

export interface BenefitAccumulatorAdjustmentCreateManyProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  visitsUsed: number;
  deductibleAmountUsed: number;
  outOfPocketAmountUsed: number;
  accumulatorAdjustmentId: string;
}

export interface BenefitAccumulatorAdjustmentCreateManyProviderServiceConfigurationInputEnvelope {
  data: BenefitAccumulatorAdjustmentCreateManyProviderServiceConfigurationInput[];
  skipDuplicates?: boolean | null;
}

export interface BenefitAccumulatorAdjustmentCreateNestedManyWithoutAccumulatorAdjustmentInput {
  create?: BenefitAccumulatorAdjustmentCreateWithoutAccumulatorAdjustmentInput[] | null;
  connectOrCreate?: BenefitAccumulatorAdjustmentCreateOrConnectWithoutAccumulatorAdjustmentInput[] | null;
  createMany?: BenefitAccumulatorAdjustmentCreateManyAccumulatorAdjustmentInputEnvelope | null;
  connect?: BenefitAccumulatorAdjustmentWhereUniqueInput[] | null;
}

export interface BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput {
  create?: BenefitAccumulatorAdjustmentCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: BenefitAccumulatorAdjustmentCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  createMany?: BenefitAccumulatorAdjustmentCreateManyProviderServiceConfigurationInputEnvelope | null;
  connect?: BenefitAccumulatorAdjustmentWhereUniqueInput[] | null;
}

export interface BenefitAccumulatorAdjustmentCreateOrConnectWithoutAccumulatorAdjustmentInput {
  where: BenefitAccumulatorAdjustmentWhereUniqueInput;
  create: BenefitAccumulatorAdjustmentCreateWithoutAccumulatorAdjustmentInput;
}

export interface BenefitAccumulatorAdjustmentCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: BenefitAccumulatorAdjustmentWhereUniqueInput;
  create: BenefitAccumulatorAdjustmentCreateWithoutProviderServiceConfigurationInput;
}

export interface BenefitAccumulatorAdjustmentCreateWithoutAccumulatorAdjustmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  visitsUsed: number;
  deductibleAmountUsed: number;
  outOfPocketAmountUsed: number;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutBenefitAccumulatorAdjustmentsInput;
}

export interface BenefitAccumulatorAdjustmentCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  visitsUsed: number;
  deductibleAmountUsed: number;
  outOfPocketAmountUsed: number;
  accumulatorAdjustment: AccumulatorAdjustmentCreateNestedOneWithoutBenefitAccumulatorAdjustmentsInput;
}

export interface BenefitAccumulatorAdjustmentListRelationFilter {
  every?: BenefitAccumulatorAdjustmentWhereInput | null;
  some?: BenefitAccumulatorAdjustmentWhereInput | null;
  none?: BenefitAccumulatorAdjustmentWhereInput | null;
}

export interface BenefitAccumulatorAdjustmentScalarWhereInput {
  AND?: BenefitAccumulatorAdjustmentScalarWhereInput[] | null;
  OR?: BenefitAccumulatorAdjustmentScalarWhereInput[] | null;
  NOT?: BenefitAccumulatorAdjustmentScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  networkStatus?: BoolFilter | null;
  coverageLevel?: StringNullableFilter | null;
  visitsUsed?: IntFilter | null;
  deductibleAmountUsed?: IntFilter | null;
  outOfPocketAmountUsed?: IntFilter | null;
  providerServiceConfigurationId?: UuidFilter | null;
  accumulatorAdjustmentId?: UuidFilter | null;
}

export interface BenefitAccumulatorAdjustmentUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  visitsUsed?: IntFieldUpdateOperationsInput | null;
  deductibleAmountUsed?: IntFieldUpdateOperationsInput | null;
  outOfPocketAmountUsed?: IntFieldUpdateOperationsInput | null;
}

export interface BenefitAccumulatorAdjustmentUpdateManyWithWhereWithoutAccumulatorAdjustmentInput {
  where: BenefitAccumulatorAdjustmentScalarWhereInput;
  data: BenefitAccumulatorAdjustmentUpdateManyMutationInput;
}

export interface BenefitAccumulatorAdjustmentUpdateManyWithWhereWithoutProviderServiceConfigurationInput {
  where: BenefitAccumulatorAdjustmentScalarWhereInput;
  data: BenefitAccumulatorAdjustmentUpdateManyMutationInput;
}

export interface BenefitAccumulatorAdjustmentUpdateManyWithoutAccumulatorAdjustmentNestedInput {
  create?: BenefitAccumulatorAdjustmentCreateWithoutAccumulatorAdjustmentInput[] | null;
  connectOrCreate?: BenefitAccumulatorAdjustmentCreateOrConnectWithoutAccumulatorAdjustmentInput[] | null;
  upsert?: BenefitAccumulatorAdjustmentUpsertWithWhereUniqueWithoutAccumulatorAdjustmentInput[] | null;
  createMany?: BenefitAccumulatorAdjustmentCreateManyAccumulatorAdjustmentInputEnvelope | null;
  set?: BenefitAccumulatorAdjustmentWhereUniqueInput[] | null;
  disconnect?: BenefitAccumulatorAdjustmentWhereUniqueInput[] | null;
  delete?: BenefitAccumulatorAdjustmentWhereUniqueInput[] | null;
  connect?: BenefitAccumulatorAdjustmentWhereUniqueInput[] | null;
  update?: BenefitAccumulatorAdjustmentUpdateWithWhereUniqueWithoutAccumulatorAdjustmentInput[] | null;
  updateMany?: BenefitAccumulatorAdjustmentUpdateManyWithWhereWithoutAccumulatorAdjustmentInput[] | null;
  deleteMany?: BenefitAccumulatorAdjustmentScalarWhereInput[] | null;
}

export interface BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput {
  create?: BenefitAccumulatorAdjustmentCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: BenefitAccumulatorAdjustmentCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  upsert?: BenefitAccumulatorAdjustmentUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  createMany?: BenefitAccumulatorAdjustmentCreateManyProviderServiceConfigurationInputEnvelope | null;
  set?: BenefitAccumulatorAdjustmentWhereUniqueInput[] | null;
  disconnect?: BenefitAccumulatorAdjustmentWhereUniqueInput[] | null;
  delete?: BenefitAccumulatorAdjustmentWhereUniqueInput[] | null;
  connect?: BenefitAccumulatorAdjustmentWhereUniqueInput[] | null;
  update?: BenefitAccumulatorAdjustmentUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  updateMany?: BenefitAccumulatorAdjustmentUpdateManyWithWhereWithoutProviderServiceConfigurationInput[] | null;
  deleteMany?: BenefitAccumulatorAdjustmentScalarWhereInput[] | null;
}

export interface BenefitAccumulatorAdjustmentUpdateWithWhereUniqueWithoutAccumulatorAdjustmentInput {
  where: BenefitAccumulatorAdjustmentWhereUniqueInput;
  data: BenefitAccumulatorAdjustmentUpdateWithoutAccumulatorAdjustmentInput;
}

export interface BenefitAccumulatorAdjustmentUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: BenefitAccumulatorAdjustmentWhereUniqueInput;
  data: BenefitAccumulatorAdjustmentUpdateWithoutProviderServiceConfigurationInput;
}

export interface BenefitAccumulatorAdjustmentUpdateWithoutAccumulatorAdjustmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  visitsUsed?: IntFieldUpdateOperationsInput | null;
  deductibleAmountUsed?: IntFieldUpdateOperationsInput | null;
  outOfPocketAmountUsed?: IntFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutBenefitAccumulatorAdjustmentsNestedInput | null;
}

export interface BenefitAccumulatorAdjustmentUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  visitsUsed?: IntFieldUpdateOperationsInput | null;
  deductibleAmountUsed?: IntFieldUpdateOperationsInput | null;
  outOfPocketAmountUsed?: IntFieldUpdateOperationsInput | null;
  accumulatorAdjustment?: AccumulatorAdjustmentUpdateOneRequiredWithoutBenefitAccumulatorAdjustmentsNestedInput | null;
}

export interface BenefitAccumulatorAdjustmentUpsertWithWhereUniqueWithoutAccumulatorAdjustmentInput {
  where: BenefitAccumulatorAdjustmentWhereUniqueInput;
  update: BenefitAccumulatorAdjustmentUpdateWithoutAccumulatorAdjustmentInput;
  create: BenefitAccumulatorAdjustmentCreateWithoutAccumulatorAdjustmentInput;
}

export interface BenefitAccumulatorAdjustmentUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: BenefitAccumulatorAdjustmentWhereUniqueInput;
  update: BenefitAccumulatorAdjustmentUpdateWithoutProviderServiceConfigurationInput;
  create: BenefitAccumulatorAdjustmentCreateWithoutProviderServiceConfigurationInput;
}

export interface BenefitAccumulatorAdjustmentWhereInput {
  AND?: BenefitAccumulatorAdjustmentWhereInput[] | null;
  OR?: BenefitAccumulatorAdjustmentWhereInput[] | null;
  NOT?: BenefitAccumulatorAdjustmentWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  networkStatus?: BoolFilter | null;
  coverageLevel?: StringNullableFilter | null;
  visitsUsed?: IntFilter | null;
  deductibleAmountUsed?: IntFilter | null;
  outOfPocketAmountUsed?: IntFilter | null;
  providerServiceConfigurationId?: UuidFilter | null;
  accumulatorAdjustmentId?: UuidFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationRelationFilter | null;
  accumulatorAdjustment?: AccumulatorAdjustmentRelationFilter | null;
}

export interface BenefitAccumulatorAdjustmentWhereUniqueInput {
  id?: string | null;
}

export interface BenefitMappingConditionCreateManyBenefitMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
}

export interface BenefitMappingConditionCreateManyBenefitMappingInputEnvelope {
  data: BenefitMappingConditionCreateManyBenefitMappingInput[];
  skipDuplicates?: boolean | null;
}

export interface BenefitMappingConditionCreateNestedManyWithoutBenefitMappingInput {
  create?: BenefitMappingConditionCreateWithoutBenefitMappingInput[] | null;
  connectOrCreate?: BenefitMappingConditionCreateOrConnectWithoutBenefitMappingInput[] | null;
  createMany?: BenefitMappingConditionCreateManyBenefitMappingInputEnvelope | null;
  connect?: BenefitMappingConditionWhereUniqueInput[] | null;
}

export interface BenefitMappingConditionCreateOrConnectWithoutBenefitMappingInput {
  where: BenefitMappingConditionWhereUniqueInput;
  create: BenefitMappingConditionCreateWithoutBenefitMappingInput;
}

export interface BenefitMappingConditionCreateWithoutBenefitMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
}

export interface BenefitMappingConditionListRelationFilter {
  every?: BenefitMappingConditionWhereInput | null;
  some?: BenefitMappingConditionWhereInput | null;
  none?: BenefitMappingConditionWhereInput | null;
}

export interface BenefitMappingConditionScalarWhereInput {
  AND?: BenefitMappingConditionScalarWhereInput[] | null;
  OR?: BenefitMappingConditionScalarWhereInput[] | null;
  NOT?: BenefitMappingConditionScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  benefitMappingId?: UuidFilter | null;
  attribute?: JsonFilter | null;
  comparison?: EnumComparisonFilter | null;
  attributeType?: StringFilter | null;
}

export interface BenefitMappingConditionUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
}

export interface BenefitMappingConditionUpdateManyWithWhereWithoutBenefitMappingInput {
  where: BenefitMappingConditionScalarWhereInput;
  data: BenefitMappingConditionUpdateManyMutationInput;
}

export interface BenefitMappingConditionUpdateManyWithoutBenefitMappingNestedInput {
  create?: BenefitMappingConditionCreateWithoutBenefitMappingInput[] | null;
  connectOrCreate?: BenefitMappingConditionCreateOrConnectWithoutBenefitMappingInput[] | null;
  upsert?: BenefitMappingConditionUpsertWithWhereUniqueWithoutBenefitMappingInput[] | null;
  createMany?: BenefitMappingConditionCreateManyBenefitMappingInputEnvelope | null;
  set?: BenefitMappingConditionWhereUniqueInput[] | null;
  disconnect?: BenefitMappingConditionWhereUniqueInput[] | null;
  delete?: BenefitMappingConditionWhereUniqueInput[] | null;
  connect?: BenefitMappingConditionWhereUniqueInput[] | null;
  update?: BenefitMappingConditionUpdateWithWhereUniqueWithoutBenefitMappingInput[] | null;
  updateMany?: BenefitMappingConditionUpdateManyWithWhereWithoutBenefitMappingInput[] | null;
  deleteMany?: BenefitMappingConditionScalarWhereInput[] | null;
}

export interface BenefitMappingConditionUpdateWithWhereUniqueWithoutBenefitMappingInput {
  where: BenefitMappingConditionWhereUniqueInput;
  data: BenefitMappingConditionUpdateWithoutBenefitMappingInput;
}

export interface BenefitMappingConditionUpdateWithoutBenefitMappingInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
}

export interface BenefitMappingConditionUpsertWithWhereUniqueWithoutBenefitMappingInput {
  where: BenefitMappingConditionWhereUniqueInput;
  update: BenefitMappingConditionUpdateWithoutBenefitMappingInput;
  create: BenefitMappingConditionCreateWithoutBenefitMappingInput;
}

export interface BenefitMappingConditionWhereInput {
  AND?: BenefitMappingConditionWhereInput[] | null;
  OR?: BenefitMappingConditionWhereInput[] | null;
  NOT?: BenefitMappingConditionWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  benefitMappingId?: UuidFilter | null;
  attribute?: JsonFilter | null;
  comparison?: EnumComparisonFilter | null;
  attributeType?: StringFilter | null;
  benefitMapping?: BenefitMappingRelationFilter | null;
}

export interface BenefitMappingConditionWhereUniqueInput {
  id?: string | null;
}

export interface BenefitMappingCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedBenefitMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedBenefitMappingsInput;
  location: LocationCreateNestedOneWithoutBenefitMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutBenefitMappingsInput | null;
  benefitMappingConditions?: BenefitMappingConditionCreateNestedManyWithoutBenefitMappingInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutBenefitMappingInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutBenefitMappingInput | null;
}

export interface BenefitMappingCreateManyCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUpdatedById: string;
  priority: number;
  locationId: string;
  name: string;
  notes?: string | null;
  providerServiceConfigurationId?: string | null;
}

export interface BenefitMappingCreateManyCreatedByInputEnvelope {
  data: BenefitMappingCreateManyCreatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface BenefitMappingCreateManyLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  priority: number;
  locationId: string;
  name: string;
  notes?: string | null;
  providerServiceConfigurationId?: string | null;
}

export interface BenefitMappingCreateManyLastUpdatedByInputEnvelope {
  data: BenefitMappingCreateManyLastUpdatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface BenefitMappingCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  lastUpdatedById: string;
  priority: number;
  name: string;
  notes?: string | null;
  providerServiceConfigurationId?: string | null;
}

export interface BenefitMappingCreateManyLocationInputEnvelope {
  data: BenefitMappingCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface BenefitMappingCreateManyProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  lastUpdatedById: string;
  priority: number;
  locationId: string;
  name: string;
  notes?: string | null;
}

export interface BenefitMappingCreateManyProviderServiceConfigurationInputEnvelope {
  data: BenefitMappingCreateManyProviderServiceConfigurationInput[];
  skipDuplicates?: boolean | null;
}

export interface BenefitMappingCreateNestedManyWithoutCreatedByInput {
  create?: BenefitMappingCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: BenefitMappingCreateManyCreatedByInputEnvelope | null;
  connect?: BenefitMappingWhereUniqueInput[] | null;
}

export interface BenefitMappingCreateNestedManyWithoutLastUpdatedByInput {
  create?: BenefitMappingCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutLastUpdatedByInput[] | null;
  createMany?: BenefitMappingCreateManyLastUpdatedByInputEnvelope | null;
  connect?: BenefitMappingWhereUniqueInput[] | null;
}

export interface BenefitMappingCreateNestedManyWithoutLocationInput {
  create?: BenefitMappingCreateWithoutLocationInput[] | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutLocationInput[] | null;
  createMany?: BenefitMappingCreateManyLocationInputEnvelope | null;
  connect?: BenefitMappingWhereUniqueInput[] | null;
}

export interface BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput {
  create?: BenefitMappingCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  createMany?: BenefitMappingCreateManyProviderServiceConfigurationInputEnvelope | null;
  connect?: BenefitMappingWhereUniqueInput[] | null;
}

export interface BenefitMappingCreateNestedOneWithoutAppointmentBenefitAssignmentsInput {
  create?: BenefitMappingCreateWithoutAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutAppointmentBenefitAssignmentsInput | null;
  connect?: BenefitMappingWhereUniqueInput | null;
}

export interface BenefitMappingCreateNestedOneWithoutBenefitMappingFallbacksInput {
  create?: BenefitMappingCreateWithoutBenefitMappingFallbacksInput | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutBenefitMappingFallbacksInput | null;
  connect?: BenefitMappingWhereUniqueInput | null;
}

export interface BenefitMappingCreateOrConnectWithoutAppointmentBenefitAssignmentsInput {
  where: BenefitMappingWhereUniqueInput;
  create: BenefitMappingCreateWithoutAppointmentBenefitAssignmentsInput;
}

export interface BenefitMappingCreateOrConnectWithoutBenefitMappingFallbacksInput {
  where: BenefitMappingWhereUniqueInput;
  create: BenefitMappingCreateWithoutBenefitMappingFallbacksInput;
}

export interface BenefitMappingCreateOrConnectWithoutCreatedByInput {
  where: BenefitMappingWhereUniqueInput;
  create: BenefitMappingCreateWithoutCreatedByInput;
}

export interface BenefitMappingCreateOrConnectWithoutLastUpdatedByInput {
  where: BenefitMappingWhereUniqueInput;
  create: BenefitMappingCreateWithoutLastUpdatedByInput;
}

export interface BenefitMappingCreateOrConnectWithoutLocationInput {
  where: BenefitMappingWhereUniqueInput;
  create: BenefitMappingCreateWithoutLocationInput;
}

export interface BenefitMappingCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: BenefitMappingWhereUniqueInput;
  create: BenefitMappingCreateWithoutProviderServiceConfigurationInput;
}

export interface BenefitMappingCreateWithoutAppointmentBenefitAssignmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedBenefitMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedBenefitMappingsInput;
  location: LocationCreateNestedOneWithoutBenefitMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutBenefitMappingsInput | null;
  benefitMappingConditions?: BenefitMappingConditionCreateNestedManyWithoutBenefitMappingInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutBenefitMappingInput | null;
}

export interface BenefitMappingCreateWithoutBenefitMappingFallbacksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedBenefitMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedBenefitMappingsInput;
  location: LocationCreateNestedOneWithoutBenefitMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutBenefitMappingsInput | null;
  benefitMappingConditions?: BenefitMappingConditionCreateNestedManyWithoutBenefitMappingInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutBenefitMappingInput | null;
}

export interface BenefitMappingCreateWithoutCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedBenefitMappingsInput;
  location: LocationCreateNestedOneWithoutBenefitMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutBenefitMappingsInput | null;
  benefitMappingConditions?: BenefitMappingConditionCreateNestedManyWithoutBenefitMappingInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutBenefitMappingInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutBenefitMappingInput | null;
}

export interface BenefitMappingCreateWithoutLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedBenefitMappingsInput;
  location: LocationCreateNestedOneWithoutBenefitMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutBenefitMappingsInput | null;
  benefitMappingConditions?: BenefitMappingConditionCreateNestedManyWithoutBenefitMappingInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutBenefitMappingInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutBenefitMappingInput | null;
}

export interface BenefitMappingCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedBenefitMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedBenefitMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutBenefitMappingsInput | null;
  benefitMappingConditions?: BenefitMappingConditionCreateNestedManyWithoutBenefitMappingInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutBenefitMappingInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutBenefitMappingInput | null;
}

export interface BenefitMappingCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedBenefitMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedBenefitMappingsInput;
  location: LocationCreateNestedOneWithoutBenefitMappingsInput;
  benefitMappingConditions?: BenefitMappingConditionCreateNestedManyWithoutBenefitMappingInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutBenefitMappingInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutBenefitMappingInput | null;
}

export interface BenefitMappingFallbackCreateManyBenefitMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  providerServiceConfigurationId: string;
}

export interface BenefitMappingFallbackCreateManyBenefitMappingInputEnvelope {
  data: BenefitMappingFallbackCreateManyBenefitMappingInput[];
  skipDuplicates?: boolean | null;
}

export interface BenefitMappingFallbackCreateManyProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  benefitMappingId: string;
}

export interface BenefitMappingFallbackCreateManyProviderServiceConfigurationInputEnvelope {
  data: BenefitMappingFallbackCreateManyProviderServiceConfigurationInput[];
  skipDuplicates?: boolean | null;
}

export interface BenefitMappingFallbackCreateNestedManyWithoutBenefitMappingInput {
  create?: BenefitMappingFallbackCreateWithoutBenefitMappingInput[] | null;
  connectOrCreate?: BenefitMappingFallbackCreateOrConnectWithoutBenefitMappingInput[] | null;
  createMany?: BenefitMappingFallbackCreateManyBenefitMappingInputEnvelope | null;
  connect?: BenefitMappingFallbackWhereUniqueInput[] | null;
}

export interface BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput {
  create?: BenefitMappingFallbackCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: BenefitMappingFallbackCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  createMany?: BenefitMappingFallbackCreateManyProviderServiceConfigurationInputEnvelope | null;
  connect?: BenefitMappingFallbackWhereUniqueInput[] | null;
}

export interface BenefitMappingFallbackCreateOrConnectWithoutBenefitMappingInput {
  where: BenefitMappingFallbackWhereUniqueInput;
  create: BenefitMappingFallbackCreateWithoutBenefitMappingInput;
}

export interface BenefitMappingFallbackCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: BenefitMappingFallbackWhereUniqueInput;
  create: BenefitMappingFallbackCreateWithoutProviderServiceConfigurationInput;
}

export interface BenefitMappingFallbackCreateWithoutBenefitMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutBenefitMappingFallbacksInput;
}

export interface BenefitMappingFallbackCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  benefitMapping: BenefitMappingCreateNestedOneWithoutBenefitMappingFallbacksInput;
}

export interface BenefitMappingFallbackListRelationFilter {
  every?: BenefitMappingFallbackWhereInput | null;
  some?: BenefitMappingFallbackWhereInput | null;
  none?: BenefitMappingFallbackWhereInput | null;
}

export interface BenefitMappingFallbackScalarWhereInput {
  AND?: BenefitMappingFallbackScalarWhereInput[] | null;
  OR?: BenefitMappingFallbackScalarWhereInput[] | null;
  NOT?: BenefitMappingFallbackScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  priority?: FloatFilter | null;
  benefitMappingId?: UuidFilter | null;
  providerServiceConfigurationId?: UuidFilter | null;
}

export interface BenefitMappingFallbackUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
}

export interface BenefitMappingFallbackUpdateManyWithWhereWithoutBenefitMappingInput {
  where: BenefitMappingFallbackScalarWhereInput;
  data: BenefitMappingFallbackUpdateManyMutationInput;
}

export interface BenefitMappingFallbackUpdateManyWithWhereWithoutProviderServiceConfigurationInput {
  where: BenefitMappingFallbackScalarWhereInput;
  data: BenefitMappingFallbackUpdateManyMutationInput;
}

export interface BenefitMappingFallbackUpdateManyWithoutBenefitMappingNestedInput {
  create?: BenefitMappingFallbackCreateWithoutBenefitMappingInput[] | null;
  connectOrCreate?: BenefitMappingFallbackCreateOrConnectWithoutBenefitMappingInput[] | null;
  upsert?: BenefitMappingFallbackUpsertWithWhereUniqueWithoutBenefitMappingInput[] | null;
  createMany?: BenefitMappingFallbackCreateManyBenefitMappingInputEnvelope | null;
  set?: BenefitMappingFallbackWhereUniqueInput[] | null;
  disconnect?: BenefitMappingFallbackWhereUniqueInput[] | null;
  delete?: BenefitMappingFallbackWhereUniqueInput[] | null;
  connect?: BenefitMappingFallbackWhereUniqueInput[] | null;
  update?: BenefitMappingFallbackUpdateWithWhereUniqueWithoutBenefitMappingInput[] | null;
  updateMany?: BenefitMappingFallbackUpdateManyWithWhereWithoutBenefitMappingInput[] | null;
  deleteMany?: BenefitMappingFallbackScalarWhereInput[] | null;
}

export interface BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput {
  create?: BenefitMappingFallbackCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: BenefitMappingFallbackCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  upsert?: BenefitMappingFallbackUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  createMany?: BenefitMappingFallbackCreateManyProviderServiceConfigurationInputEnvelope | null;
  set?: BenefitMappingFallbackWhereUniqueInput[] | null;
  disconnect?: BenefitMappingFallbackWhereUniqueInput[] | null;
  delete?: BenefitMappingFallbackWhereUniqueInput[] | null;
  connect?: BenefitMappingFallbackWhereUniqueInput[] | null;
  update?: BenefitMappingFallbackUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  updateMany?: BenefitMappingFallbackUpdateManyWithWhereWithoutProviderServiceConfigurationInput[] | null;
  deleteMany?: BenefitMappingFallbackScalarWhereInput[] | null;
}

export interface BenefitMappingFallbackUpdateWithWhereUniqueWithoutBenefitMappingInput {
  where: BenefitMappingFallbackWhereUniqueInput;
  data: BenefitMappingFallbackUpdateWithoutBenefitMappingInput;
}

export interface BenefitMappingFallbackUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: BenefitMappingFallbackWhereUniqueInput;
  data: BenefitMappingFallbackUpdateWithoutProviderServiceConfigurationInput;
}

export interface BenefitMappingFallbackUpdateWithoutBenefitMappingInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutBenefitMappingFallbacksNestedInput | null;
}

export interface BenefitMappingFallbackUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  benefitMapping?: BenefitMappingUpdateOneRequiredWithoutBenefitMappingFallbacksNestedInput | null;
}

export interface BenefitMappingFallbackUpsertWithWhereUniqueWithoutBenefitMappingInput {
  where: BenefitMappingFallbackWhereUniqueInput;
  update: BenefitMappingFallbackUpdateWithoutBenefitMappingInput;
  create: BenefitMappingFallbackCreateWithoutBenefitMappingInput;
}

export interface BenefitMappingFallbackUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: BenefitMappingFallbackWhereUniqueInput;
  update: BenefitMappingFallbackUpdateWithoutProviderServiceConfigurationInput;
  create: BenefitMappingFallbackCreateWithoutProviderServiceConfigurationInput;
}

export interface BenefitMappingFallbackWhereInput {
  AND?: BenefitMappingFallbackWhereInput[] | null;
  OR?: BenefitMappingFallbackWhereInput[] | null;
  NOT?: BenefitMappingFallbackWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  priority?: FloatFilter | null;
  benefitMappingId?: UuidFilter | null;
  providerServiceConfigurationId?: UuidFilter | null;
  benefitMapping?: BenefitMappingRelationFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationRelationFilter | null;
}

export interface BenefitMappingFallbackWhereUniqueInput {
  id?: string | null;
}

export interface BenefitMappingListRelationFilter {
  every?: BenefitMappingWhereInput | null;
  some?: BenefitMappingWhereInput | null;
  none?: BenefitMappingWhereInput | null;
}

export interface BenefitMappingOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface BenefitMappingRelationFilter {
  is?: BenefitMappingWhereInput | null;
  isNot?: BenefitMappingWhereInput | null;
}

export interface BenefitMappingScalarWhereInput {
  AND?: BenefitMappingScalarWhereInput[] | null;
  OR?: BenefitMappingScalarWhereInput[] | null;
  NOT?: BenefitMappingScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  priority?: FloatFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
  notes?: StringNullableFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
}

export interface BenefitMappingUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedBenefitMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedBenefitMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutBenefitMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutBenefitMappingsNestedInput | null;
  benefitMappingConditions?: BenefitMappingConditionUpdateManyWithoutBenefitMappingNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutBenefitMappingNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutBenefitMappingNestedInput | null;
}

export interface BenefitMappingUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
}

export interface BenefitMappingUpdateManyWithWhereWithoutCreatedByInput {
  where: BenefitMappingScalarWhereInput;
  data: BenefitMappingUpdateManyMutationInput;
}

export interface BenefitMappingUpdateManyWithWhereWithoutLastUpdatedByInput {
  where: BenefitMappingScalarWhereInput;
  data: BenefitMappingUpdateManyMutationInput;
}

export interface BenefitMappingUpdateManyWithWhereWithoutLocationInput {
  where: BenefitMappingScalarWhereInput;
  data: BenefitMappingUpdateManyMutationInput;
}

export interface BenefitMappingUpdateManyWithWhereWithoutProviderServiceConfigurationInput {
  where: BenefitMappingScalarWhereInput;
  data: BenefitMappingUpdateManyMutationInput;
}

export interface BenefitMappingUpdateManyWithoutCreatedByNestedInput {
  create?: BenefitMappingCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: BenefitMappingUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: BenefitMappingCreateManyCreatedByInputEnvelope | null;
  set?: BenefitMappingWhereUniqueInput[] | null;
  disconnect?: BenefitMappingWhereUniqueInput[] | null;
  delete?: BenefitMappingWhereUniqueInput[] | null;
  connect?: BenefitMappingWhereUniqueInput[] | null;
  update?: BenefitMappingUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: BenefitMappingUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: BenefitMappingScalarWhereInput[] | null;
}

export interface BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput {
  create?: BenefitMappingCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutLastUpdatedByInput[] | null;
  upsert?: BenefitMappingUpsertWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  createMany?: BenefitMappingCreateManyLastUpdatedByInputEnvelope | null;
  set?: BenefitMappingWhereUniqueInput[] | null;
  disconnect?: BenefitMappingWhereUniqueInput[] | null;
  delete?: BenefitMappingWhereUniqueInput[] | null;
  connect?: BenefitMappingWhereUniqueInput[] | null;
  update?: BenefitMappingUpdateWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  updateMany?: BenefitMappingUpdateManyWithWhereWithoutLastUpdatedByInput[] | null;
  deleteMany?: BenefitMappingScalarWhereInput[] | null;
}

export interface BenefitMappingUpdateManyWithoutLocationNestedInput {
  create?: BenefitMappingCreateWithoutLocationInput[] | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutLocationInput[] | null;
  upsert?: BenefitMappingUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: BenefitMappingCreateManyLocationInputEnvelope | null;
  set?: BenefitMappingWhereUniqueInput[] | null;
  disconnect?: BenefitMappingWhereUniqueInput[] | null;
  delete?: BenefitMappingWhereUniqueInput[] | null;
  connect?: BenefitMappingWhereUniqueInput[] | null;
  update?: BenefitMappingUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: BenefitMappingUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: BenefitMappingScalarWhereInput[] | null;
}

export interface BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput {
  create?: BenefitMappingCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  upsert?: BenefitMappingUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  createMany?: BenefitMappingCreateManyProviderServiceConfigurationInputEnvelope | null;
  set?: BenefitMappingWhereUniqueInput[] | null;
  disconnect?: BenefitMappingWhereUniqueInput[] | null;
  delete?: BenefitMappingWhereUniqueInput[] | null;
  connect?: BenefitMappingWhereUniqueInput[] | null;
  update?: BenefitMappingUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  updateMany?: BenefitMappingUpdateManyWithWhereWithoutProviderServiceConfigurationInput[] | null;
  deleteMany?: BenefitMappingScalarWhereInput[] | null;
}

export interface BenefitMappingUpdateOneRequiredWithoutBenefitMappingFallbacksNestedInput {
  create?: BenefitMappingCreateWithoutBenefitMappingFallbacksInput | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutBenefitMappingFallbacksInput | null;
  upsert?: BenefitMappingUpsertWithoutBenefitMappingFallbacksInput | null;
  connect?: BenefitMappingWhereUniqueInput | null;
  update?: BenefitMappingUpdateWithoutBenefitMappingFallbacksInput | null;
}

export interface BenefitMappingUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput {
  create?: BenefitMappingCreateWithoutAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: BenefitMappingCreateOrConnectWithoutAppointmentBenefitAssignmentsInput | null;
  upsert?: BenefitMappingUpsertWithoutAppointmentBenefitAssignmentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: BenefitMappingWhereUniqueInput | null;
  update?: BenefitMappingUpdateWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface BenefitMappingUpdateWithWhereUniqueWithoutCreatedByInput {
  where: BenefitMappingWhereUniqueInput;
  data: BenefitMappingUpdateWithoutCreatedByInput;
}

export interface BenefitMappingUpdateWithWhereUniqueWithoutLastUpdatedByInput {
  where: BenefitMappingWhereUniqueInput;
  data: BenefitMappingUpdateWithoutLastUpdatedByInput;
}

export interface BenefitMappingUpdateWithWhereUniqueWithoutLocationInput {
  where: BenefitMappingWhereUniqueInput;
  data: BenefitMappingUpdateWithoutLocationInput;
}

export interface BenefitMappingUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: BenefitMappingWhereUniqueInput;
  data: BenefitMappingUpdateWithoutProviderServiceConfigurationInput;
}

export interface BenefitMappingUpdateWithoutAppointmentBenefitAssignmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedBenefitMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedBenefitMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutBenefitMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutBenefitMappingsNestedInput | null;
  benefitMappingConditions?: BenefitMappingConditionUpdateManyWithoutBenefitMappingNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutBenefitMappingNestedInput | null;
}

export interface BenefitMappingUpdateWithoutBenefitMappingFallbacksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedBenefitMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedBenefitMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutBenefitMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutBenefitMappingsNestedInput | null;
  benefitMappingConditions?: BenefitMappingConditionUpdateManyWithoutBenefitMappingNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutBenefitMappingNestedInput | null;
}

export interface BenefitMappingUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedBenefitMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutBenefitMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutBenefitMappingsNestedInput | null;
  benefitMappingConditions?: BenefitMappingConditionUpdateManyWithoutBenefitMappingNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutBenefitMappingNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutBenefitMappingNestedInput | null;
}

export interface BenefitMappingUpdateWithoutLastUpdatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedBenefitMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutBenefitMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutBenefitMappingsNestedInput | null;
  benefitMappingConditions?: BenefitMappingConditionUpdateManyWithoutBenefitMappingNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutBenefitMappingNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutBenefitMappingNestedInput | null;
}

export interface BenefitMappingUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedBenefitMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedBenefitMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutBenefitMappingsNestedInput | null;
  benefitMappingConditions?: BenefitMappingConditionUpdateManyWithoutBenefitMappingNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutBenefitMappingNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutBenefitMappingNestedInput | null;
}

export interface BenefitMappingUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedBenefitMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedBenefitMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutBenefitMappingsNestedInput | null;
  benefitMappingConditions?: BenefitMappingConditionUpdateManyWithoutBenefitMappingNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutBenefitMappingNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutBenefitMappingNestedInput | null;
}

export interface BenefitMappingUpsertWithWhereUniqueWithoutCreatedByInput {
  where: BenefitMappingWhereUniqueInput;
  update: BenefitMappingUpdateWithoutCreatedByInput;
  create: BenefitMappingCreateWithoutCreatedByInput;
}

export interface BenefitMappingUpsertWithWhereUniqueWithoutLastUpdatedByInput {
  where: BenefitMappingWhereUniqueInput;
  update: BenefitMappingUpdateWithoutLastUpdatedByInput;
  create: BenefitMappingCreateWithoutLastUpdatedByInput;
}

export interface BenefitMappingUpsertWithWhereUniqueWithoutLocationInput {
  where: BenefitMappingWhereUniqueInput;
  update: BenefitMappingUpdateWithoutLocationInput;
  create: BenefitMappingCreateWithoutLocationInput;
}

export interface BenefitMappingUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: BenefitMappingWhereUniqueInput;
  update: BenefitMappingUpdateWithoutProviderServiceConfigurationInput;
  create: BenefitMappingCreateWithoutProviderServiceConfigurationInput;
}

export interface BenefitMappingUpsertWithoutAppointmentBenefitAssignmentsInput {
  update: BenefitMappingUpdateWithoutAppointmentBenefitAssignmentsInput;
  create: BenefitMappingCreateWithoutAppointmentBenefitAssignmentsInput;
}

export interface BenefitMappingUpsertWithoutBenefitMappingFallbacksInput {
  update: BenefitMappingUpdateWithoutBenefitMappingFallbacksInput;
  create: BenefitMappingCreateWithoutBenefitMappingFallbacksInput;
}

export interface BenefitMappingWhereInput {
  AND?: BenefitMappingWhereInput[] | null;
  OR?: BenefitMappingWhereInput[] | null;
  NOT?: BenefitMappingWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  priority?: FloatFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
  notes?: StringNullableFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
  createdBy?: UserRelationFilter | null;
  lastUpdatedBy?: UserRelationFilter | null;
  location?: LocationRelationFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationRelationFilter | null;
  benefitMappingConditions?: BenefitMappingConditionListRelationFilter | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentListRelationFilter | null;
  benefitMappingFallbacks?: BenefitMappingFallbackListRelationFilter | null;
}

export interface BenefitMappingWhereUniqueInput {
  id?: string | null;
}

export interface BenefitNoteRuleCreateManyProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  value: string;
  matchType: BenefitNoteRuleMatchType;
  actionType: BenefitNoteRuleActionType;
  rank?: number | null;
  providerServiceConfigurationOverrideId?: string | null;
}

export interface BenefitNoteRuleCreateManyProviderServiceConfigurationInputEnvelope {
  data: BenefitNoteRuleCreateManyProviderServiceConfigurationInput[];
  skipDuplicates?: boolean | null;
}

export interface BenefitNoteRuleCreateManyProviderServiceConfigurationOverrideInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  value: string;
  matchType: BenefitNoteRuleMatchType;
  actionType: BenefitNoteRuleActionType;
  rank?: number | null;
  providerServiceConfigurationId?: string | null;
}

export interface BenefitNoteRuleCreateManyProviderServiceConfigurationOverrideInputEnvelope {
  data: BenefitNoteRuleCreateManyProviderServiceConfigurationOverrideInput[];
  skipDuplicates?: boolean | null;
}

export interface BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput {
  create?: BenefitNoteRuleCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: BenefitNoteRuleCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  createMany?: BenefitNoteRuleCreateManyProviderServiceConfigurationInputEnvelope | null;
  connect?: BenefitNoteRuleWhereUniqueInput[] | null;
}

export interface BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationOverrideInput {
  create?: BenefitNoteRuleCreateWithoutProviderServiceConfigurationOverrideInput[] | null;
  connectOrCreate?: BenefitNoteRuleCreateOrConnectWithoutProviderServiceConfigurationOverrideInput[] | null;
  createMany?: BenefitNoteRuleCreateManyProviderServiceConfigurationOverrideInputEnvelope | null;
  connect?: BenefitNoteRuleWhereUniqueInput[] | null;
}

export interface BenefitNoteRuleCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: BenefitNoteRuleWhereUniqueInput;
  create: BenefitNoteRuleCreateWithoutProviderServiceConfigurationInput;
}

export interface BenefitNoteRuleCreateOrConnectWithoutProviderServiceConfigurationOverrideInput {
  where: BenefitNoteRuleWhereUniqueInput;
  create: BenefitNoteRuleCreateWithoutProviderServiceConfigurationOverrideInput;
}

export interface BenefitNoteRuleCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  value: string;
  matchType: BenefitNoteRuleMatchType;
  actionType: BenefitNoteRuleActionType;
  rank?: number | null;
  providerServiceConfigurationOverride?: ProviderServiceConfigurationOverrideCreateNestedOneWithoutBenefitNoteRulesInput | null;
}

export interface BenefitNoteRuleCreateWithoutProviderServiceConfigurationOverrideInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  value: string;
  matchType: BenefitNoteRuleMatchType;
  actionType: BenefitNoteRuleActionType;
  rank?: number | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutBenefitNoteRulesInput | null;
}

export interface BenefitNoteRuleListRelationFilter {
  every?: BenefitNoteRuleWhereInput | null;
  some?: BenefitNoteRuleWhereInput | null;
  none?: BenefitNoteRuleWhereInput | null;
}

export interface BenefitNoteRuleScalarWhereInput {
  AND?: BenefitNoteRuleScalarWhereInput[] | null;
  OR?: BenefitNoteRuleScalarWhereInput[] | null;
  NOT?: BenefitNoteRuleScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  value?: StringFilter | null;
  matchType?: EnumBenefitNoteRuleMatchTypeFilter | null;
  actionType?: EnumBenefitNoteRuleActionTypeFilter | null;
  rank?: FloatNullableFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
  providerServiceConfigurationOverrideId?: UuidNullableFilter | null;
}

export interface BenefitNoteRuleUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  value?: StringFieldUpdateOperationsInput | null;
  matchType?: EnumBenefitNoteRuleMatchTypeFieldUpdateOperationsInput | null;
  actionType?: EnumBenefitNoteRuleActionTypeFieldUpdateOperationsInput | null;
  rank?: NullableFloatFieldUpdateOperationsInput | null;
}

export interface BenefitNoteRuleUpdateManyWithWhereWithoutProviderServiceConfigurationInput {
  where: BenefitNoteRuleScalarWhereInput;
  data: BenefitNoteRuleUpdateManyMutationInput;
}

export interface BenefitNoteRuleUpdateManyWithWhereWithoutProviderServiceConfigurationOverrideInput {
  where: BenefitNoteRuleScalarWhereInput;
  data: BenefitNoteRuleUpdateManyMutationInput;
}

export interface BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput {
  create?: BenefitNoteRuleCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: BenefitNoteRuleCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  upsert?: BenefitNoteRuleUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  createMany?: BenefitNoteRuleCreateManyProviderServiceConfigurationInputEnvelope | null;
  set?: BenefitNoteRuleWhereUniqueInput[] | null;
  disconnect?: BenefitNoteRuleWhereUniqueInput[] | null;
  delete?: BenefitNoteRuleWhereUniqueInput[] | null;
  connect?: BenefitNoteRuleWhereUniqueInput[] | null;
  update?: BenefitNoteRuleUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  updateMany?: BenefitNoteRuleUpdateManyWithWhereWithoutProviderServiceConfigurationInput[] | null;
  deleteMany?: BenefitNoteRuleScalarWhereInput[] | null;
}

export interface BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationOverrideNestedInput {
  create?: BenefitNoteRuleCreateWithoutProviderServiceConfigurationOverrideInput[] | null;
  connectOrCreate?: BenefitNoteRuleCreateOrConnectWithoutProviderServiceConfigurationOverrideInput[] | null;
  upsert?: BenefitNoteRuleUpsertWithWhereUniqueWithoutProviderServiceConfigurationOverrideInput[] | null;
  createMany?: BenefitNoteRuleCreateManyProviderServiceConfigurationOverrideInputEnvelope | null;
  set?: BenefitNoteRuleWhereUniqueInput[] | null;
  disconnect?: BenefitNoteRuleWhereUniqueInput[] | null;
  delete?: BenefitNoteRuleWhereUniqueInput[] | null;
  connect?: BenefitNoteRuleWhereUniqueInput[] | null;
  update?: BenefitNoteRuleUpdateWithWhereUniqueWithoutProviderServiceConfigurationOverrideInput[] | null;
  updateMany?: BenefitNoteRuleUpdateManyWithWhereWithoutProviderServiceConfigurationOverrideInput[] | null;
  deleteMany?: BenefitNoteRuleScalarWhereInput[] | null;
}

export interface BenefitNoteRuleUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: BenefitNoteRuleWhereUniqueInput;
  data: BenefitNoteRuleUpdateWithoutProviderServiceConfigurationInput;
}

export interface BenefitNoteRuleUpdateWithWhereUniqueWithoutProviderServiceConfigurationOverrideInput {
  where: BenefitNoteRuleWhereUniqueInput;
  data: BenefitNoteRuleUpdateWithoutProviderServiceConfigurationOverrideInput;
}

export interface BenefitNoteRuleUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  value?: StringFieldUpdateOperationsInput | null;
  matchType?: EnumBenefitNoteRuleMatchTypeFieldUpdateOperationsInput | null;
  actionType?: EnumBenefitNoteRuleActionTypeFieldUpdateOperationsInput | null;
  rank?: NullableFloatFieldUpdateOperationsInput | null;
  providerServiceConfigurationOverride?: ProviderServiceConfigurationOverrideUpdateOneWithoutBenefitNoteRulesNestedInput | null;
}

export interface BenefitNoteRuleUpdateWithoutProviderServiceConfigurationOverrideInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  value?: StringFieldUpdateOperationsInput | null;
  matchType?: EnumBenefitNoteRuleMatchTypeFieldUpdateOperationsInput | null;
  actionType?: EnumBenefitNoteRuleActionTypeFieldUpdateOperationsInput | null;
  rank?: NullableFloatFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutBenefitNoteRulesNestedInput | null;
}

export interface BenefitNoteRuleUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: BenefitNoteRuleWhereUniqueInput;
  update: BenefitNoteRuleUpdateWithoutProviderServiceConfigurationInput;
  create: BenefitNoteRuleCreateWithoutProviderServiceConfigurationInput;
}

export interface BenefitNoteRuleUpsertWithWhereUniqueWithoutProviderServiceConfigurationOverrideInput {
  where: BenefitNoteRuleWhereUniqueInput;
  update: BenefitNoteRuleUpdateWithoutProviderServiceConfigurationOverrideInput;
  create: BenefitNoteRuleCreateWithoutProviderServiceConfigurationOverrideInput;
}

export interface BenefitNoteRuleWhereInput {
  AND?: BenefitNoteRuleWhereInput[] | null;
  OR?: BenefitNoteRuleWhereInput[] | null;
  NOT?: BenefitNoteRuleWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  value?: StringFilter | null;
  matchType?: EnumBenefitNoteRuleMatchTypeFilter | null;
  actionType?: EnumBenefitNoteRuleActionTypeFilter | null;
  rank?: FloatNullableFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
  providerServiceConfigurationOverrideId?: UuidNullableFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationRelationFilter | null;
  providerServiceConfigurationOverride?: ProviderServiceConfigurationOverrideRelationFilter | null;
}

export interface BenefitNoteRuleWhereUniqueInput {
  id?: string | null;
}

export interface BillCreateManyAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  appointmentId?: string | null;
  billingProviderId?: string | null;
}

export interface BillCreateManyAccountInputEnvelope {
  data: BillCreateManyAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface BillCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  accountId: string;
  billingProviderId?: string | null;
}

export interface BillCreateManyAppointmentInputEnvelope {
  data: BillCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface BillCreateManyBillingProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  accountId: string;
  appointmentId?: string | null;
}

export interface BillCreateManyBillingProviderInputEnvelope {
  data: BillCreateManyBillingProviderInput[];
  skipDuplicates?: boolean | null;
}

export interface BillCreateNestedManyWithoutAccountInput {
  create?: BillCreateWithoutAccountInput[] | null;
  connectOrCreate?: BillCreateOrConnectWithoutAccountInput[] | null;
  createMany?: BillCreateManyAccountInputEnvelope | null;
  connect?: BillWhereUniqueInput[] | null;
}

export interface BillCreateNestedManyWithoutAppointmentInput {
  create?: BillCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: BillCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: BillCreateManyAppointmentInputEnvelope | null;
  connect?: BillWhereUniqueInput[] | null;
}

export interface BillCreateNestedManyWithoutBillingProviderInput {
  create?: BillCreateWithoutBillingProviderInput[] | null;
  connectOrCreate?: BillCreateOrConnectWithoutBillingProviderInput[] | null;
  createMany?: BillCreateManyBillingProviderInputEnvelope | null;
  connect?: BillWhereUniqueInput[] | null;
}

export interface BillCreateNestedManyWithoutCommunicationsInput {
  create?: BillCreateWithoutCommunicationsInput[] | null;
  connectOrCreate?: BillCreateOrConnectWithoutCommunicationsInput[] | null;
  connect?: BillWhereUniqueInput[] | null;
}

export interface BillCreateNestedManyWithoutRenderingProvidersInput {
  create?: BillCreateWithoutRenderingProvidersInput[] | null;
  connectOrCreate?: BillCreateOrConnectWithoutRenderingProvidersInput[] | null;
  connect?: BillWhereUniqueInput[] | null;
}

export interface BillCreateNestedOneWithoutBillPaymentsInput {
  create?: BillCreateWithoutBillPaymentsInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutBillPaymentsInput | null;
  connect?: BillWhereUniqueInput | null;
}

export interface BillCreateNestedOneWithoutChargesInput {
  create?: BillCreateWithoutChargesInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutChargesInput | null;
  connect?: BillWhereUniqueInput | null;
}

export interface BillCreateNestedOneWithoutEstimatesInput {
  create?: BillCreateWithoutEstimatesInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutEstimatesInput | null;
  connect?: BillWhereUniqueInput | null;
}

export interface BillCreateNestedOneWithoutIntegrationLinksInput {
  create?: BillCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: BillWhereUniqueInput | null;
}

export interface BillCreateNestedOneWithoutPaymentIntentBillsInput {
  create?: BillCreateWithoutPaymentIntentBillsInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutPaymentIntentBillsInput | null;
  connect?: BillWhereUniqueInput | null;
}

export interface BillCreateNestedOneWithoutPaymentRequestTargetsInput {
  create?: BillCreateWithoutPaymentRequestTargetsInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutPaymentRequestTargetsInput | null;
  connect?: BillWhereUniqueInput | null;
}

export interface BillCreateNestedOneWithoutSaltedEstimatesInput {
  create?: BillCreateWithoutSaltedEstimatesInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutSaltedEstimatesInput | null;
  connect?: BillWhereUniqueInput | null;
}

export interface BillCreateNestedOneWithoutVisitCollectionRequestsInput {
  create?: BillCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  connect?: BillWhereUniqueInput | null;
}

export interface BillCreateOrConnectWithoutAccountInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutAccountInput;
}

export interface BillCreateOrConnectWithoutAppointmentInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutAppointmentInput;
}

export interface BillCreateOrConnectWithoutBillPaymentsInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutBillPaymentsInput;
}

export interface BillCreateOrConnectWithoutBillingProviderInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutBillingProviderInput;
}

export interface BillCreateOrConnectWithoutChargesInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutChargesInput;
}

export interface BillCreateOrConnectWithoutCommunicationsInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutCommunicationsInput;
}

export interface BillCreateOrConnectWithoutEstimatesInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutEstimatesInput;
}

export interface BillCreateOrConnectWithoutIntegrationLinksInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutIntegrationLinksInput;
}

export interface BillCreateOrConnectWithoutPaymentIntentBillsInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutPaymentIntentBillsInput;
}

export interface BillCreateOrConnectWithoutPaymentRequestTargetsInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutPaymentRequestTargetsInput;
}

export interface BillCreateOrConnectWithoutRenderingProvidersInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutRenderingProvidersInput;
}

export interface BillCreateOrConnectWithoutSaltedEstimatesInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutSaltedEstimatesInput;
}

export interface BillCreateOrConnectWithoutVisitCollectionRequestsInput {
  where: BillWhereUniqueInput;
  create: BillCreateWithoutVisitCollectionRequestsInput;
}

export interface BillCreateWithoutAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutBillPaymentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutBillingProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutCommunicationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutEstimatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutPaymentIntentBillsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutPaymentRequestTargetsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutRenderingProvidersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutSaltedEstimatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillCreateWithoutVisitCollectionRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dateOfService?: any | null;
  issuedOn?: any | null;
  readyOn?: any | null;
  inReviewOn?: any | null;
  inReviewOverrideOn?: any | null;
  archivedEstimateOn?: any | null;
  paidAt?: any | null;
  onHoldAt?: any | null;
  dueDate?: any | null;
  status: BillState;
  communicationStatus?: BillCommunicationState | null;
  billCode?: string | null;
  firstDeliveredAt?: any | null;
  lastDeliveredAt?: any | null;
  timeOfServiceAutoChargeScheduledAt?: any | null;
  pendingInsurance?: boolean | null;
  charges?: ChargeCreateNestedManyWithoutBillInput | null;
  account: AccountCreateNestedOneWithoutBillsInput;
  appointment?: AppointmentCreateNestedOneWithoutBillInput | null;
  communications?: CommunicationCreateNestedManyWithoutBillsInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledBillsInput | null;
  renderingProviders?: ProviderCreateNestedManyWithoutRenderedBillsInput | null;
  estimates?: EstimateCreateNestedManyWithoutBillInput | null;
  saltedEstimates?: EstimateCreateNestedManyWithoutSaltedBillInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutBillInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutBillInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutBillInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillInput | null;
}

export interface BillListRelationFilter {
  every?: BillWhereInput | null;
  some?: BillWhereInput | null;
  none?: BillWhereInput | null;
}

export interface BillOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface BillOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  dateOfService?: SortOrderInput | null;
  issuedOn?: SortOrderInput | null;
  readyOn?: SortOrderInput | null;
  inReviewOn?: SortOrderInput | null;
  inReviewOverrideOn?: SortOrderInput | null;
  archivedEstimateOn?: SortOrderInput | null;
  paidAt?: SortOrderInput | null;
  onHoldAt?: SortOrderInput | null;
  dueDate?: SortOrderInput | null;
  status?: SortOrder | null;
  communicationStatus?: SortOrderInput | null;
  billCode?: SortOrderInput | null;
  firstDeliveredAt?: SortOrderInput | null;
  lastDeliveredAt?: SortOrderInput | null;
  timeOfServiceAutoChargeScheduledAt?: SortOrderInput | null;
  pendingInsurance?: SortOrderInput | null;
  accountId?: SortOrder | null;
  appointmentId?: SortOrderInput | null;
  billingProviderId?: SortOrderInput | null;
  charges?: ChargeOrderByRelationAggregateInput | null;
  account?: AccountOrderByWithRelationInput | null;
  appointment?: AppointmentOrderByWithRelationInput | null;
  communications?: CommunicationOrderByRelationAggregateInput | null;
  billingProvider?: ProviderOrderByWithRelationInput | null;
  renderingProviders?: ProviderOrderByRelationAggregateInput | null;
  estimates?: EstimateOrderByRelationAggregateInput | null;
  saltedEstimates?: EstimateOrderByRelationAggregateInput | null;
  paymentIntentBills?: PaymentIntentBillOrderByRelationAggregateInput | null;
  billPayments?: BillPaymentOrderByRelationAggregateInput | null;
  paymentRequestTargets?: PaymentRequestTargetOrderByRelationAggregateInput | null;
  visitCollectionRequests?: VisitCollectionRequestOrderByRelationAggregateInput | null;
  integrationLinks?: IntegrationLinkOrderByRelationAggregateInput | null;
}

export interface BillPaymentBillIdPaymentTransactionIdCompoundUniqueInput {
  billId: string;
  paymentTransactionId: string;
}

export interface BillPaymentCreateManyBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  paymentTransactionId: string;
  amount: number;
  paymentIntentId?: string | null;
  refundingBillPaymentId?: string | null;
}

export interface BillPaymentCreateManyBillInputEnvelope {
  data: BillPaymentCreateManyBillInput[];
  skipDuplicates?: boolean | null;
}

export interface BillPaymentCreateManyPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  billId: string;
  paymentTransactionId: string;
  amount: number;
  refundingBillPaymentId?: string | null;
}

export interface BillPaymentCreateManyPaymentIntentInputEnvelope {
  data: BillPaymentCreateManyPaymentIntentInput[];
  skipDuplicates?: boolean | null;
}

export interface BillPaymentCreateManyPaymentTransactionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  billId: string;
  amount: number;
  paymentIntentId?: string | null;
  refundingBillPaymentId?: string | null;
}

export interface BillPaymentCreateManyPaymentTransactionInputEnvelope {
  data: BillPaymentCreateManyPaymentTransactionInput[];
  skipDuplicates?: boolean | null;
}

export interface BillPaymentCreateManyRefundingBillPaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  billId: string;
  paymentTransactionId: string;
  amount: number;
  paymentIntentId?: string | null;
}

export interface BillPaymentCreateManyRefundingBillPaymentInputEnvelope {
  data: BillPaymentCreateManyRefundingBillPaymentInput[];
  skipDuplicates?: boolean | null;
}

export interface BillPaymentCreateNestedManyWithoutBillInput {
  create?: BillPaymentCreateWithoutBillInput[] | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutBillInput[] | null;
  createMany?: BillPaymentCreateManyBillInputEnvelope | null;
  connect?: BillPaymentWhereUniqueInput[] | null;
}

export interface BillPaymentCreateNestedManyWithoutPaymentIntentInput {
  create?: BillPaymentCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutPaymentIntentInput[] | null;
  createMany?: BillPaymentCreateManyPaymentIntentInputEnvelope | null;
  connect?: BillPaymentWhereUniqueInput[] | null;
}

export interface BillPaymentCreateNestedManyWithoutPaymentTransactionInput {
  create?: BillPaymentCreateWithoutPaymentTransactionInput[] | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutPaymentTransactionInput[] | null;
  createMany?: BillPaymentCreateManyPaymentTransactionInputEnvelope | null;
  connect?: BillPaymentWhereUniqueInput[] | null;
}

export interface BillPaymentCreateNestedManyWithoutRefundingBillPaymentInput {
  create?: BillPaymentCreateWithoutRefundingBillPaymentInput[] | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutRefundingBillPaymentInput[] | null;
  createMany?: BillPaymentCreateManyRefundingBillPaymentInputEnvelope | null;
  connect?: BillPaymentWhereUniqueInput[] | null;
}

export interface BillPaymentCreateNestedOneWithoutIntegrationLinksInput {
  create?: BillPaymentCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: BillPaymentWhereUniqueInput | null;
}

export interface BillPaymentCreateNestedOneWithoutRefundedByBillPaymentsInput {
  create?: BillPaymentCreateWithoutRefundedByBillPaymentsInput | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutRefundedByBillPaymentsInput | null;
  connect?: BillPaymentWhereUniqueInput | null;
}

export interface BillPaymentCreateOrConnectWithoutBillInput {
  where: BillPaymentWhereUniqueInput;
  create: BillPaymentCreateWithoutBillInput;
}

export interface BillPaymentCreateOrConnectWithoutIntegrationLinksInput {
  where: BillPaymentWhereUniqueInput;
  create: BillPaymentCreateWithoutIntegrationLinksInput;
}

export interface BillPaymentCreateOrConnectWithoutPaymentIntentInput {
  where: BillPaymentWhereUniqueInput;
  create: BillPaymentCreateWithoutPaymentIntentInput;
}

export interface BillPaymentCreateOrConnectWithoutPaymentTransactionInput {
  where: BillPaymentWhereUniqueInput;
  create: BillPaymentCreateWithoutPaymentTransactionInput;
}

export interface BillPaymentCreateOrConnectWithoutRefundedByBillPaymentsInput {
  where: BillPaymentWhereUniqueInput;
  create: BillPaymentCreateWithoutRefundedByBillPaymentsInput;
}

export interface BillPaymentCreateOrConnectWithoutRefundingBillPaymentInput {
  where: BillPaymentWhereUniqueInput;
  create: BillPaymentCreateWithoutRefundingBillPaymentInput;
}

export interface BillPaymentCreateWithoutBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  paymentTransaction: TransactionCreateNestedOneWithoutBillPaymentsInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutBillPaymentsInput | null;
  refundingBillPayment?: BillPaymentCreateNestedOneWithoutRefundedByBillPaymentsInput | null;
  refundedByBillPayments?: BillPaymentCreateNestedManyWithoutRefundingBillPaymentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillPaymentInput | null;
}

export interface BillPaymentCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  bill: BillCreateNestedOneWithoutBillPaymentsInput;
  paymentTransaction: TransactionCreateNestedOneWithoutBillPaymentsInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutBillPaymentsInput | null;
  refundingBillPayment?: BillPaymentCreateNestedOneWithoutRefundedByBillPaymentsInput | null;
  refundedByBillPayments?: BillPaymentCreateNestedManyWithoutRefundingBillPaymentInput | null;
}

export interface BillPaymentCreateWithoutPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  bill: BillCreateNestedOneWithoutBillPaymentsInput;
  paymentTransaction: TransactionCreateNestedOneWithoutBillPaymentsInput;
  refundingBillPayment?: BillPaymentCreateNestedOneWithoutRefundedByBillPaymentsInput | null;
  refundedByBillPayments?: BillPaymentCreateNestedManyWithoutRefundingBillPaymentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillPaymentInput | null;
}

export interface BillPaymentCreateWithoutPaymentTransactionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  bill: BillCreateNestedOneWithoutBillPaymentsInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutBillPaymentsInput | null;
  refundingBillPayment?: BillPaymentCreateNestedOneWithoutRefundedByBillPaymentsInput | null;
  refundedByBillPayments?: BillPaymentCreateNestedManyWithoutRefundingBillPaymentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillPaymentInput | null;
}

export interface BillPaymentCreateWithoutRefundedByBillPaymentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  bill: BillCreateNestedOneWithoutBillPaymentsInput;
  paymentTransaction: TransactionCreateNestedOneWithoutBillPaymentsInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutBillPaymentsInput | null;
  refundingBillPayment?: BillPaymentCreateNestedOneWithoutRefundedByBillPaymentsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillPaymentInput | null;
}

export interface BillPaymentCreateWithoutRefundingBillPaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  bill: BillCreateNestedOneWithoutBillPaymentsInput;
  paymentTransaction: TransactionCreateNestedOneWithoutBillPaymentsInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutBillPaymentsInput | null;
  refundedByBillPayments?: BillPaymentCreateNestedManyWithoutRefundingBillPaymentInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutBillPaymentInput | null;
}

export interface BillPaymentListRelationFilter {
  every?: BillPaymentWhereInput | null;
  some?: BillPaymentWhereInput | null;
  none?: BillPaymentWhereInput | null;
}

export interface BillPaymentOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface BillPaymentRelationFilter {
  is?: BillPaymentWhereInput | null;
  isNot?: BillPaymentWhereInput | null;
}

export interface BillPaymentScalarWhereInput {
  AND?: BillPaymentScalarWhereInput[] | null;
  OR?: BillPaymentScalarWhereInput[] | null;
  NOT?: BillPaymentScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  billId?: UuidFilter | null;
  paymentTransactionId?: UuidFilter | null;
  amount?: IntFilter | null;
  paymentIntentId?: UuidNullableFilter | null;
  refundingBillPaymentId?: UuidNullableFilter | null;
}

export interface BillPaymentUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
}

export interface BillPaymentUpdateManyWithWhereWithoutBillInput {
  where: BillPaymentScalarWhereInput;
  data: BillPaymentUpdateManyMutationInput;
}

export interface BillPaymentUpdateManyWithWhereWithoutPaymentIntentInput {
  where: BillPaymentScalarWhereInput;
  data: BillPaymentUpdateManyMutationInput;
}

export interface BillPaymentUpdateManyWithWhereWithoutPaymentTransactionInput {
  where: BillPaymentScalarWhereInput;
  data: BillPaymentUpdateManyMutationInput;
}

export interface BillPaymentUpdateManyWithWhereWithoutRefundingBillPaymentInput {
  where: BillPaymentScalarWhereInput;
  data: BillPaymentUpdateManyMutationInput;
}

export interface BillPaymentUpdateManyWithoutBillNestedInput {
  create?: BillPaymentCreateWithoutBillInput[] | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutBillInput[] | null;
  upsert?: BillPaymentUpsertWithWhereUniqueWithoutBillInput[] | null;
  createMany?: BillPaymentCreateManyBillInputEnvelope | null;
  set?: BillPaymentWhereUniqueInput[] | null;
  disconnect?: BillPaymentWhereUniqueInput[] | null;
  delete?: BillPaymentWhereUniqueInput[] | null;
  connect?: BillPaymentWhereUniqueInput[] | null;
  update?: BillPaymentUpdateWithWhereUniqueWithoutBillInput[] | null;
  updateMany?: BillPaymentUpdateManyWithWhereWithoutBillInput[] | null;
  deleteMany?: BillPaymentScalarWhereInput[] | null;
}

export interface BillPaymentUpdateManyWithoutPaymentIntentNestedInput {
  create?: BillPaymentCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutPaymentIntentInput[] | null;
  upsert?: BillPaymentUpsertWithWhereUniqueWithoutPaymentIntentInput[] | null;
  createMany?: BillPaymentCreateManyPaymentIntentInputEnvelope | null;
  set?: BillPaymentWhereUniqueInput[] | null;
  disconnect?: BillPaymentWhereUniqueInput[] | null;
  delete?: BillPaymentWhereUniqueInput[] | null;
  connect?: BillPaymentWhereUniqueInput[] | null;
  update?: BillPaymentUpdateWithWhereUniqueWithoutPaymentIntentInput[] | null;
  updateMany?: BillPaymentUpdateManyWithWhereWithoutPaymentIntentInput[] | null;
  deleteMany?: BillPaymentScalarWhereInput[] | null;
}

export interface BillPaymentUpdateManyWithoutPaymentTransactionNestedInput {
  create?: BillPaymentCreateWithoutPaymentTransactionInput[] | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutPaymentTransactionInput[] | null;
  upsert?: BillPaymentUpsertWithWhereUniqueWithoutPaymentTransactionInput[] | null;
  createMany?: BillPaymentCreateManyPaymentTransactionInputEnvelope | null;
  set?: BillPaymentWhereUniqueInput[] | null;
  disconnect?: BillPaymentWhereUniqueInput[] | null;
  delete?: BillPaymentWhereUniqueInput[] | null;
  connect?: BillPaymentWhereUniqueInput[] | null;
  update?: BillPaymentUpdateWithWhereUniqueWithoutPaymentTransactionInput[] | null;
  updateMany?: BillPaymentUpdateManyWithWhereWithoutPaymentTransactionInput[] | null;
  deleteMany?: BillPaymentScalarWhereInput[] | null;
}

export interface BillPaymentUpdateManyWithoutRefundingBillPaymentNestedInput {
  create?: BillPaymentCreateWithoutRefundingBillPaymentInput[] | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutRefundingBillPaymentInput[] | null;
  upsert?: BillPaymentUpsertWithWhereUniqueWithoutRefundingBillPaymentInput[] | null;
  createMany?: BillPaymentCreateManyRefundingBillPaymentInputEnvelope | null;
  set?: BillPaymentWhereUniqueInput[] | null;
  disconnect?: BillPaymentWhereUniqueInput[] | null;
  delete?: BillPaymentWhereUniqueInput[] | null;
  connect?: BillPaymentWhereUniqueInput[] | null;
  update?: BillPaymentUpdateWithWhereUniqueWithoutRefundingBillPaymentInput[] | null;
  updateMany?: BillPaymentUpdateManyWithWhereWithoutRefundingBillPaymentInput[] | null;
  deleteMany?: BillPaymentScalarWhereInput[] | null;
}

export interface BillPaymentUpdateOneWithoutIntegrationLinksNestedInput {
  create?: BillPaymentCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: BillPaymentUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: BillPaymentWhereUniqueInput | null;
  update?: BillPaymentUpdateWithoutIntegrationLinksInput | null;
}

export interface BillPaymentUpdateOneWithoutRefundedByBillPaymentsNestedInput {
  create?: BillPaymentCreateWithoutRefundedByBillPaymentsInput | null;
  connectOrCreate?: BillPaymentCreateOrConnectWithoutRefundedByBillPaymentsInput | null;
  upsert?: BillPaymentUpsertWithoutRefundedByBillPaymentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: BillPaymentWhereUniqueInput | null;
  update?: BillPaymentUpdateWithoutRefundedByBillPaymentsInput | null;
}

export interface BillPaymentUpdateWithWhereUniqueWithoutBillInput {
  where: BillPaymentWhereUniqueInput;
  data: BillPaymentUpdateWithoutBillInput;
}

export interface BillPaymentUpdateWithWhereUniqueWithoutPaymentIntentInput {
  where: BillPaymentWhereUniqueInput;
  data: BillPaymentUpdateWithoutPaymentIntentInput;
}

export interface BillPaymentUpdateWithWhereUniqueWithoutPaymentTransactionInput {
  where: BillPaymentWhereUniqueInput;
  data: BillPaymentUpdateWithoutPaymentTransactionInput;
}

export interface BillPaymentUpdateWithWhereUniqueWithoutRefundingBillPaymentInput {
  where: BillPaymentWhereUniqueInput;
  data: BillPaymentUpdateWithoutRefundingBillPaymentInput;
}

export interface BillPaymentUpdateWithoutBillInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  paymentTransaction?: TransactionUpdateOneRequiredWithoutBillPaymentsNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutBillPaymentsNestedInput | null;
  refundingBillPayment?: BillPaymentUpdateOneWithoutRefundedByBillPaymentsNestedInput | null;
  refundedByBillPayments?: BillPaymentUpdateManyWithoutRefundingBillPaymentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillPaymentNestedInput | null;
}

export interface BillPaymentUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  bill?: BillUpdateOneRequiredWithoutBillPaymentsNestedInput | null;
  paymentTransaction?: TransactionUpdateOneRequiredWithoutBillPaymentsNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutBillPaymentsNestedInput | null;
  refundingBillPayment?: BillPaymentUpdateOneWithoutRefundedByBillPaymentsNestedInput | null;
  refundedByBillPayments?: BillPaymentUpdateManyWithoutRefundingBillPaymentNestedInput | null;
}

export interface BillPaymentUpdateWithoutPaymentIntentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  bill?: BillUpdateOneRequiredWithoutBillPaymentsNestedInput | null;
  paymentTransaction?: TransactionUpdateOneRequiredWithoutBillPaymentsNestedInput | null;
  refundingBillPayment?: BillPaymentUpdateOneWithoutRefundedByBillPaymentsNestedInput | null;
  refundedByBillPayments?: BillPaymentUpdateManyWithoutRefundingBillPaymentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillPaymentNestedInput | null;
}

export interface BillPaymentUpdateWithoutPaymentTransactionInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  bill?: BillUpdateOneRequiredWithoutBillPaymentsNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutBillPaymentsNestedInput | null;
  refundingBillPayment?: BillPaymentUpdateOneWithoutRefundedByBillPaymentsNestedInput | null;
  refundedByBillPayments?: BillPaymentUpdateManyWithoutRefundingBillPaymentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillPaymentNestedInput | null;
}

export interface BillPaymentUpdateWithoutRefundedByBillPaymentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  bill?: BillUpdateOneRequiredWithoutBillPaymentsNestedInput | null;
  paymentTransaction?: TransactionUpdateOneRequiredWithoutBillPaymentsNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutBillPaymentsNestedInput | null;
  refundingBillPayment?: BillPaymentUpdateOneWithoutRefundedByBillPaymentsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillPaymentNestedInput | null;
}

export interface BillPaymentUpdateWithoutRefundingBillPaymentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  bill?: BillUpdateOneRequiredWithoutBillPaymentsNestedInput | null;
  paymentTransaction?: TransactionUpdateOneRequiredWithoutBillPaymentsNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutBillPaymentsNestedInput | null;
  refundedByBillPayments?: BillPaymentUpdateManyWithoutRefundingBillPaymentNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillPaymentNestedInput | null;
}

export interface BillPaymentUpsertWithWhereUniqueWithoutBillInput {
  where: BillPaymentWhereUniqueInput;
  update: BillPaymentUpdateWithoutBillInput;
  create: BillPaymentCreateWithoutBillInput;
}

export interface BillPaymentUpsertWithWhereUniqueWithoutPaymentIntentInput {
  where: BillPaymentWhereUniqueInput;
  update: BillPaymentUpdateWithoutPaymentIntentInput;
  create: BillPaymentCreateWithoutPaymentIntentInput;
}

export interface BillPaymentUpsertWithWhereUniqueWithoutPaymentTransactionInput {
  where: BillPaymentWhereUniqueInput;
  update: BillPaymentUpdateWithoutPaymentTransactionInput;
  create: BillPaymentCreateWithoutPaymentTransactionInput;
}

export interface BillPaymentUpsertWithWhereUniqueWithoutRefundingBillPaymentInput {
  where: BillPaymentWhereUniqueInput;
  update: BillPaymentUpdateWithoutRefundingBillPaymentInput;
  create: BillPaymentCreateWithoutRefundingBillPaymentInput;
}

export interface BillPaymentUpsertWithoutIntegrationLinksInput {
  update: BillPaymentUpdateWithoutIntegrationLinksInput;
  create: BillPaymentCreateWithoutIntegrationLinksInput;
}

export interface BillPaymentUpsertWithoutRefundedByBillPaymentsInput {
  update: BillPaymentUpdateWithoutRefundedByBillPaymentsInput;
  create: BillPaymentCreateWithoutRefundedByBillPaymentsInput;
}

export interface BillPaymentWhereInput {
  AND?: BillPaymentWhereInput[] | null;
  OR?: BillPaymentWhereInput[] | null;
  NOT?: BillPaymentWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  billId?: UuidFilter | null;
  paymentTransactionId?: UuidFilter | null;
  amount?: IntFilter | null;
  paymentIntentId?: UuidNullableFilter | null;
  refundingBillPaymentId?: UuidNullableFilter | null;
  bill?: BillRelationFilter | null;
  paymentTransaction?: TransactionRelationFilter | null;
  paymentIntent?: PaymentIntentRelationFilter | null;
  refundingBillPayment?: BillPaymentRelationFilter | null;
  refundedByBillPayments?: BillPaymentListRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
}

export interface BillPaymentWhereUniqueInput {
  id?: string | null;
  billId_paymentTransactionId?: BillPaymentBillIdPaymentTransactionIdCompoundUniqueInput | null;
}

export interface BillRelationFilter {
  is?: BillWhereInput | null;
  isNot?: BillWhereInput | null;
}

export interface BillScalarWhereInput {
  AND?: BillScalarWhereInput[] | null;
  OR?: BillScalarWhereInput[] | null;
  NOT?: BillScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  dateOfService?: DateTimeNullableFilter | null;
  issuedOn?: DateTimeNullableFilter | null;
  readyOn?: DateTimeNullableFilter | null;
  inReviewOn?: DateTimeNullableFilter | null;
  inReviewOverrideOn?: DateTimeNullableFilter | null;
  archivedEstimateOn?: DateTimeNullableFilter | null;
  paidAt?: DateTimeNullableFilter | null;
  onHoldAt?: DateTimeNullableFilter | null;
  dueDate?: DateTimeNullableFilter | null;
  status?: EnumBillStateFilter | null;
  communicationStatus?: EnumBillCommunicationStateNullableFilter | null;
  billCode?: StringNullableFilter | null;
  firstDeliveredAt?: DateTimeNullableFilter | null;
  lastDeliveredAt?: DateTimeNullableFilter | null;
  timeOfServiceAutoChargeScheduledAt?: DateTimeNullableFilter | null;
  pendingInsurance?: BoolNullableFilter | null;
  accountId?: UuidFilter | null;
  appointmentId?: UuidNullableFilter | null;
  billingProviderId?: UuidNullableFilter | null;
}

export interface BillUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
}

export interface BillUpdateManyWithWhereWithoutAccountInput {
  where: BillScalarWhereInput;
  data: BillUpdateManyMutationInput;
}

export interface BillUpdateManyWithWhereWithoutAppointmentInput {
  where: BillScalarWhereInput;
  data: BillUpdateManyMutationInput;
}

export interface BillUpdateManyWithWhereWithoutBillingProviderInput {
  where: BillScalarWhereInput;
  data: BillUpdateManyMutationInput;
}

export interface BillUpdateManyWithWhereWithoutCommunicationsInput {
  where: BillScalarWhereInput;
  data: BillUpdateManyMutationInput;
}

export interface BillUpdateManyWithWhereWithoutRenderingProvidersInput {
  where: BillScalarWhereInput;
  data: BillUpdateManyMutationInput;
}

export interface BillUpdateManyWithoutAccountNestedInput {
  create?: BillCreateWithoutAccountInput[] | null;
  connectOrCreate?: BillCreateOrConnectWithoutAccountInput[] | null;
  upsert?: BillUpsertWithWhereUniqueWithoutAccountInput[] | null;
  createMany?: BillCreateManyAccountInputEnvelope | null;
  set?: BillWhereUniqueInput[] | null;
  disconnect?: BillWhereUniqueInput[] | null;
  delete?: BillWhereUniqueInput[] | null;
  connect?: BillWhereUniqueInput[] | null;
  update?: BillUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: BillUpdateManyWithWhereWithoutAccountInput[] | null;
  deleteMany?: BillScalarWhereInput[] | null;
}

export interface BillUpdateManyWithoutAppointmentNestedInput {
  create?: BillCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: BillCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: BillUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: BillCreateManyAppointmentInputEnvelope | null;
  set?: BillWhereUniqueInput[] | null;
  disconnect?: BillWhereUniqueInput[] | null;
  delete?: BillWhereUniqueInput[] | null;
  connect?: BillWhereUniqueInput[] | null;
  update?: BillUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: BillUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: BillScalarWhereInput[] | null;
}

export interface BillUpdateManyWithoutBillingProviderNestedInput {
  create?: BillCreateWithoutBillingProviderInput[] | null;
  connectOrCreate?: BillCreateOrConnectWithoutBillingProviderInput[] | null;
  upsert?: BillUpsertWithWhereUniqueWithoutBillingProviderInput[] | null;
  createMany?: BillCreateManyBillingProviderInputEnvelope | null;
  set?: BillWhereUniqueInput[] | null;
  disconnect?: BillWhereUniqueInput[] | null;
  delete?: BillWhereUniqueInput[] | null;
  connect?: BillWhereUniqueInput[] | null;
  update?: BillUpdateWithWhereUniqueWithoutBillingProviderInput[] | null;
  updateMany?: BillUpdateManyWithWhereWithoutBillingProviderInput[] | null;
  deleteMany?: BillScalarWhereInput[] | null;
}

export interface BillUpdateManyWithoutCommunicationsNestedInput {
  create?: BillCreateWithoutCommunicationsInput[] | null;
  connectOrCreate?: BillCreateOrConnectWithoutCommunicationsInput[] | null;
  upsert?: BillUpsertWithWhereUniqueWithoutCommunicationsInput[] | null;
  set?: BillWhereUniqueInput[] | null;
  disconnect?: BillWhereUniqueInput[] | null;
  delete?: BillWhereUniqueInput[] | null;
  connect?: BillWhereUniqueInput[] | null;
  update?: BillUpdateWithWhereUniqueWithoutCommunicationsInput[] | null;
  updateMany?: BillUpdateManyWithWhereWithoutCommunicationsInput[] | null;
  deleteMany?: BillScalarWhereInput[] | null;
}

export interface BillUpdateManyWithoutRenderingProvidersNestedInput {
  create?: BillCreateWithoutRenderingProvidersInput[] | null;
  connectOrCreate?: BillCreateOrConnectWithoutRenderingProvidersInput[] | null;
  upsert?: BillUpsertWithWhereUniqueWithoutRenderingProvidersInput[] | null;
  set?: BillWhereUniqueInput[] | null;
  disconnect?: BillWhereUniqueInput[] | null;
  delete?: BillWhereUniqueInput[] | null;
  connect?: BillWhereUniqueInput[] | null;
  update?: BillUpdateWithWhereUniqueWithoutRenderingProvidersInput[] | null;
  updateMany?: BillUpdateManyWithWhereWithoutRenderingProvidersInput[] | null;
  deleteMany?: BillScalarWhereInput[] | null;
}

export interface BillUpdateOneRequiredWithoutBillPaymentsNestedInput {
  create?: BillCreateWithoutBillPaymentsInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutBillPaymentsInput | null;
  upsert?: BillUpsertWithoutBillPaymentsInput | null;
  connect?: BillWhereUniqueInput | null;
  update?: BillUpdateWithoutBillPaymentsInput | null;
}

export interface BillUpdateOneRequiredWithoutPaymentIntentBillsNestedInput {
  create?: BillCreateWithoutPaymentIntentBillsInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutPaymentIntentBillsInput | null;
  upsert?: BillUpsertWithoutPaymentIntentBillsInput | null;
  connect?: BillWhereUniqueInput | null;
  update?: BillUpdateWithoutPaymentIntentBillsInput | null;
}

export interface BillUpdateOneRequiredWithoutPaymentRequestTargetsNestedInput {
  create?: BillCreateWithoutPaymentRequestTargetsInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutPaymentRequestTargetsInput | null;
  upsert?: BillUpsertWithoutPaymentRequestTargetsInput | null;
  connect?: BillWhereUniqueInput | null;
  update?: BillUpdateWithoutPaymentRequestTargetsInput | null;
}

export interface BillUpdateOneWithoutChargesNestedInput {
  create?: BillCreateWithoutChargesInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutChargesInput | null;
  upsert?: BillUpsertWithoutChargesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: BillWhereUniqueInput | null;
  update?: BillUpdateWithoutChargesInput | null;
}

export interface BillUpdateOneWithoutEstimatesNestedInput {
  create?: BillCreateWithoutEstimatesInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutEstimatesInput | null;
  upsert?: BillUpsertWithoutEstimatesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: BillWhereUniqueInput | null;
  update?: BillUpdateWithoutEstimatesInput | null;
}

export interface BillUpdateOneWithoutIntegrationLinksNestedInput {
  create?: BillCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: BillUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: BillWhereUniqueInput | null;
  update?: BillUpdateWithoutIntegrationLinksInput | null;
}

export interface BillUpdateOneWithoutSaltedEstimatesNestedInput {
  create?: BillCreateWithoutSaltedEstimatesInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutSaltedEstimatesInput | null;
  upsert?: BillUpsertWithoutSaltedEstimatesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: BillWhereUniqueInput | null;
  update?: BillUpdateWithoutSaltedEstimatesInput | null;
}

export interface BillUpdateOneWithoutVisitCollectionRequestsNestedInput {
  create?: BillCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: BillCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  upsert?: BillUpsertWithoutVisitCollectionRequestsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: BillWhereUniqueInput | null;
  update?: BillUpdateWithoutVisitCollectionRequestsInput | null;
}

export interface BillUpdateWithWhereUniqueWithoutAccountInput {
  where: BillWhereUniqueInput;
  data: BillUpdateWithoutAccountInput;
}

export interface BillUpdateWithWhereUniqueWithoutAppointmentInput {
  where: BillWhereUniqueInput;
  data: BillUpdateWithoutAppointmentInput;
}

export interface BillUpdateWithWhereUniqueWithoutBillingProviderInput {
  where: BillWhereUniqueInput;
  data: BillUpdateWithoutBillingProviderInput;
}

export interface BillUpdateWithWhereUniqueWithoutCommunicationsInput {
  where: BillWhereUniqueInput;
  data: BillUpdateWithoutCommunicationsInput;
}

export interface BillUpdateWithWhereUniqueWithoutRenderingProvidersInput {
  where: BillWhereUniqueInput;
  data: BillUpdateWithoutRenderingProvidersInput;
}

export interface BillUpdateWithoutAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutBillPaymentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutBillingProviderInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutCommunicationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutEstimatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutPaymentIntentBillsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutPaymentRequestTargetsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutRenderingProvidersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutSaltedEstimatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpdateWithoutVisitCollectionRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dateOfService?: NullableDateTimeFieldUpdateOperationsInput | null;
  issuedOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  readyOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  inReviewOverrideOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  archivedEstimateOn?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  onHoldAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumBillStateFieldUpdateOperationsInput | null;
  communicationStatus?: NullableEnumBillCommunicationStateFieldUpdateOperationsInput | null;
  billCode?: NullableStringFieldUpdateOperationsInput | null;
  firstDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastDeliveredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargeScheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  pendingInsurance?: NullableBoolFieldUpdateOperationsInput | null;
  charges?: ChargeUpdateManyWithoutBillNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutBillsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutBillNestedInput | null;
  communications?: CommunicationUpdateManyWithoutBillsNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledBillsNestedInput | null;
  renderingProviders?: ProviderUpdateManyWithoutRenderedBillsNestedInput | null;
  estimates?: EstimateUpdateManyWithoutBillNestedInput | null;
  saltedEstimates?: EstimateUpdateManyWithoutSaltedBillNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutBillNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutBillNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutBillNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutBillNestedInput | null;
}

export interface BillUpsertWithWhereUniqueWithoutAccountInput {
  where: BillWhereUniqueInput;
  update: BillUpdateWithoutAccountInput;
  create: BillCreateWithoutAccountInput;
}

export interface BillUpsertWithWhereUniqueWithoutAppointmentInput {
  where: BillWhereUniqueInput;
  update: BillUpdateWithoutAppointmentInput;
  create: BillCreateWithoutAppointmentInput;
}

export interface BillUpsertWithWhereUniqueWithoutBillingProviderInput {
  where: BillWhereUniqueInput;
  update: BillUpdateWithoutBillingProviderInput;
  create: BillCreateWithoutBillingProviderInput;
}

export interface BillUpsertWithWhereUniqueWithoutCommunicationsInput {
  where: BillWhereUniqueInput;
  update: BillUpdateWithoutCommunicationsInput;
  create: BillCreateWithoutCommunicationsInput;
}

export interface BillUpsertWithWhereUniqueWithoutRenderingProvidersInput {
  where: BillWhereUniqueInput;
  update: BillUpdateWithoutRenderingProvidersInput;
  create: BillCreateWithoutRenderingProvidersInput;
}

export interface BillUpsertWithoutBillPaymentsInput {
  update: BillUpdateWithoutBillPaymentsInput;
  create: BillCreateWithoutBillPaymentsInput;
}

export interface BillUpsertWithoutChargesInput {
  update: BillUpdateWithoutChargesInput;
  create: BillCreateWithoutChargesInput;
}

export interface BillUpsertWithoutEstimatesInput {
  update: BillUpdateWithoutEstimatesInput;
  create: BillCreateWithoutEstimatesInput;
}

export interface BillUpsertWithoutIntegrationLinksInput {
  update: BillUpdateWithoutIntegrationLinksInput;
  create: BillCreateWithoutIntegrationLinksInput;
}

export interface BillUpsertWithoutPaymentIntentBillsInput {
  update: BillUpdateWithoutPaymentIntentBillsInput;
  create: BillCreateWithoutPaymentIntentBillsInput;
}

export interface BillUpsertWithoutPaymentRequestTargetsInput {
  update: BillUpdateWithoutPaymentRequestTargetsInput;
  create: BillCreateWithoutPaymentRequestTargetsInput;
}

export interface BillUpsertWithoutSaltedEstimatesInput {
  update: BillUpdateWithoutSaltedEstimatesInput;
  create: BillCreateWithoutSaltedEstimatesInput;
}

export interface BillUpsertWithoutVisitCollectionRequestsInput {
  update: BillUpdateWithoutVisitCollectionRequestsInput;
  create: BillCreateWithoutVisitCollectionRequestsInput;
}

export interface BillWhereInput {
  AND?: BillWhereInput[] | null;
  OR?: BillWhereInput[] | null;
  NOT?: BillWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  dateOfService?: DateTimeNullableFilter | null;
  issuedOn?: DateTimeNullableFilter | null;
  readyOn?: DateTimeNullableFilter | null;
  inReviewOn?: DateTimeNullableFilter | null;
  inReviewOverrideOn?: DateTimeNullableFilter | null;
  archivedEstimateOn?: DateTimeNullableFilter | null;
  paidAt?: DateTimeNullableFilter | null;
  onHoldAt?: DateTimeNullableFilter | null;
  dueDate?: DateTimeNullableFilter | null;
  status?: EnumBillStateFilter | null;
  communicationStatus?: EnumBillCommunicationStateNullableFilter | null;
  billCode?: StringNullableFilter | null;
  firstDeliveredAt?: DateTimeNullableFilter | null;
  lastDeliveredAt?: DateTimeNullableFilter | null;
  timeOfServiceAutoChargeScheduledAt?: DateTimeNullableFilter | null;
  pendingInsurance?: BoolNullableFilter | null;
  accountId?: UuidFilter | null;
  appointmentId?: UuidNullableFilter | null;
  billingProviderId?: UuidNullableFilter | null;
  charges?: ChargeListRelationFilter | null;
  account?: AccountRelationFilter | null;
  appointment?: AppointmentRelationFilter | null;
  communications?: CommunicationListRelationFilter | null;
  billingProvider?: ProviderRelationFilter | null;
  renderingProviders?: ProviderListRelationFilter | null;
  estimates?: EstimateListRelationFilter | null;
  saltedEstimates?: EstimateListRelationFilter | null;
  paymentIntentBills?: PaymentIntentBillListRelationFilter | null;
  billPayments?: BillPaymentListRelationFilter | null;
  paymentRequestTargets?: PaymentRequestTargetListRelationFilter | null;
  visitCollectionRequests?: VisitCollectionRequestListRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
}

export interface BillWhereUniqueInput {
  id?: string | null;
  billCode?: string | null;
}

export interface BillingGroupCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  npi: string;
  stripeConnectedAccountId?: string | null;
}

export interface BillingGroupCreateManyOrganizationInputEnvelope {
  data: BillingGroupCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface BillingGroupCreateManyStripeConnectedAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  name: string;
  npi: string;
}

export interface BillingGroupCreateManyStripeConnectedAccountInputEnvelope {
  data: BillingGroupCreateManyStripeConnectedAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface BillingGroupCreateNestedManyWithoutOrganizationInput {
  create?: BillingGroupCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: BillingGroupCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: BillingGroupCreateManyOrganizationInputEnvelope | null;
  connect?: BillingGroupWhereUniqueInput[] | null;
}

export interface BillingGroupCreateNestedManyWithoutStripeConnectedAccountInput {
  create?: BillingGroupCreateWithoutStripeConnectedAccountInput[] | null;
  connectOrCreate?: BillingGroupCreateOrConnectWithoutStripeConnectedAccountInput[] | null;
  createMany?: BillingGroupCreateManyStripeConnectedAccountInputEnvelope | null;
  connect?: BillingGroupWhereUniqueInput[] | null;
}

export interface BillingGroupCreateNestedOneWithoutProvidersInput {
  create?: BillingGroupCreateWithoutProvidersInput | null;
  connectOrCreate?: BillingGroupCreateOrConnectWithoutProvidersInput | null;
  connect?: BillingGroupWhereUniqueInput | null;
}

export interface BillingGroupCreateOrConnectWithoutOrganizationInput {
  where: BillingGroupWhereUniqueInput;
  create: BillingGroupCreateWithoutOrganizationInput;
}

export interface BillingGroupCreateOrConnectWithoutProvidersInput {
  where: BillingGroupWhereUniqueInput;
  create: BillingGroupCreateWithoutProvidersInput;
}

export interface BillingGroupCreateOrConnectWithoutStripeConnectedAccountInput {
  where: BillingGroupWhereUniqueInput;
  create: BillingGroupCreateWithoutStripeConnectedAccountInput;
}

export interface BillingGroupCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  npi: string;
  stripeConnectedAccount?: StripeConnectedAccountCreateNestedOneWithoutBillingGroupsInput | null;
  providers?: ProviderCreateNestedManyWithoutBillingGroupInput | null;
}

export interface BillingGroupCreateWithoutProvidersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  npi: string;
  organization: OrganizationCreateNestedOneWithoutBillingGroupsInput;
  stripeConnectedAccount?: StripeConnectedAccountCreateNestedOneWithoutBillingGroupsInput | null;
}

export interface BillingGroupCreateWithoutStripeConnectedAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  npi: string;
  organization: OrganizationCreateNestedOneWithoutBillingGroupsInput;
  providers?: ProviderCreateNestedManyWithoutBillingGroupInput | null;
}

export interface BillingGroupListRelationFilter {
  every?: BillingGroupWhereInput | null;
  some?: BillingGroupWhereInput | null;
  none?: BillingGroupWhereInput | null;
}

export interface BillingGroupOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface BillingGroupOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  organizationId?: SortOrder | null;
  name?: SortOrder | null;
  npi?: SortOrder | null;
  stripeConnectedAccountId?: SortOrderInput | null;
  organization?: OrganizationOrderByWithRelationInput | null;
  stripeConnectedAccount?: StripeConnectedAccountOrderByWithRelationInput | null;
  providers?: ProviderOrderByRelationAggregateInput | null;
}

export interface BillingGroupRelationFilter {
  is?: BillingGroupWhereInput | null;
  isNot?: BillingGroupWhereInput | null;
}

export interface BillingGroupScalarWhereInput {
  AND?: BillingGroupScalarWhereInput[] | null;
  OR?: BillingGroupScalarWhereInput[] | null;
  NOT?: BillingGroupScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  name?: StringFilter | null;
  npi?: StringFilter | null;
  stripeConnectedAccountId?: UuidNullableFilter | null;
}

export interface BillingGroupUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  npi?: StringFieldUpdateOperationsInput | null;
}

export interface BillingGroupUpdateManyWithWhereWithoutOrganizationInput {
  where: BillingGroupScalarWhereInput;
  data: BillingGroupUpdateManyMutationInput;
}

export interface BillingGroupUpdateManyWithWhereWithoutStripeConnectedAccountInput {
  where: BillingGroupScalarWhereInput;
  data: BillingGroupUpdateManyMutationInput;
}

export interface BillingGroupUpdateManyWithoutOrganizationNestedInput {
  create?: BillingGroupCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: BillingGroupCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: BillingGroupUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: BillingGroupCreateManyOrganizationInputEnvelope | null;
  set?: BillingGroupWhereUniqueInput[] | null;
  disconnect?: BillingGroupWhereUniqueInput[] | null;
  delete?: BillingGroupWhereUniqueInput[] | null;
  connect?: BillingGroupWhereUniqueInput[] | null;
  update?: BillingGroupUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: BillingGroupUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: BillingGroupScalarWhereInput[] | null;
}

export interface BillingGroupUpdateManyWithoutStripeConnectedAccountNestedInput {
  create?: BillingGroupCreateWithoutStripeConnectedAccountInput[] | null;
  connectOrCreate?: BillingGroupCreateOrConnectWithoutStripeConnectedAccountInput[] | null;
  upsert?: BillingGroupUpsertWithWhereUniqueWithoutStripeConnectedAccountInput[] | null;
  createMany?: BillingGroupCreateManyStripeConnectedAccountInputEnvelope | null;
  set?: BillingGroupWhereUniqueInput[] | null;
  disconnect?: BillingGroupWhereUniqueInput[] | null;
  delete?: BillingGroupWhereUniqueInput[] | null;
  connect?: BillingGroupWhereUniqueInput[] | null;
  update?: BillingGroupUpdateWithWhereUniqueWithoutStripeConnectedAccountInput[] | null;
  updateMany?: BillingGroupUpdateManyWithWhereWithoutStripeConnectedAccountInput[] | null;
  deleteMany?: BillingGroupScalarWhereInput[] | null;
}

export interface BillingGroupUpdateOneWithoutProvidersNestedInput {
  create?: BillingGroupCreateWithoutProvidersInput | null;
  connectOrCreate?: BillingGroupCreateOrConnectWithoutProvidersInput | null;
  upsert?: BillingGroupUpsertWithoutProvidersInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: BillingGroupWhereUniqueInput | null;
  update?: BillingGroupUpdateWithoutProvidersInput | null;
}

export interface BillingGroupUpdateWithWhereUniqueWithoutOrganizationInput {
  where: BillingGroupWhereUniqueInput;
  data: BillingGroupUpdateWithoutOrganizationInput;
}

export interface BillingGroupUpdateWithWhereUniqueWithoutStripeConnectedAccountInput {
  where: BillingGroupWhereUniqueInput;
  data: BillingGroupUpdateWithoutStripeConnectedAccountInput;
}

export interface BillingGroupUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  npi?: StringFieldUpdateOperationsInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneWithoutBillingGroupsNestedInput | null;
  providers?: ProviderUpdateManyWithoutBillingGroupNestedInput | null;
}

export interface BillingGroupUpdateWithoutProvidersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  npi?: StringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutBillingGroupsNestedInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneWithoutBillingGroupsNestedInput | null;
}

export interface BillingGroupUpdateWithoutStripeConnectedAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  npi?: StringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutBillingGroupsNestedInput | null;
  providers?: ProviderUpdateManyWithoutBillingGroupNestedInput | null;
}

export interface BillingGroupUpsertWithWhereUniqueWithoutOrganizationInput {
  where: BillingGroupWhereUniqueInput;
  update: BillingGroupUpdateWithoutOrganizationInput;
  create: BillingGroupCreateWithoutOrganizationInput;
}

export interface BillingGroupUpsertWithWhereUniqueWithoutStripeConnectedAccountInput {
  where: BillingGroupWhereUniqueInput;
  update: BillingGroupUpdateWithoutStripeConnectedAccountInput;
  create: BillingGroupCreateWithoutStripeConnectedAccountInput;
}

export interface BillingGroupUpsertWithoutProvidersInput {
  update: BillingGroupUpdateWithoutProvidersInput;
  create: BillingGroupCreateWithoutProvidersInput;
}

export interface BillingGroupWhereInput {
  AND?: BillingGroupWhereInput[] | null;
  OR?: BillingGroupWhereInput[] | null;
  NOT?: BillingGroupWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  name?: StringFilter | null;
  npi?: StringFilter | null;
  stripeConnectedAccountId?: UuidNullableFilter | null;
  organization?: OrganizationRelationFilter | null;
  stripeConnectedAccount?: StripeConnectedAccountRelationFilter | null;
  providers?: ProviderListRelationFilter | null;
}

export interface BillingGroupWhereUniqueInput {
  id?: string | null;
}

export interface BoolFieldUpdateOperationsInput {
  set?: boolean | null;
}

export interface BoolFilter {
  equals?: boolean | null;
  not?: NestedBoolFilter | null;
}

export interface BoolNullableFilter {
  equals?: boolean | null;
  not?: NestedBoolNullableFilter | null;
}

export interface BulkVerifyBenefitsInput {
  insurancePolicyId: string;
  appointmentId?: string | null;
}

export interface ChargeCreateManyBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  transactionId: string;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  chargemasterId?: string | null;
  billingProviderId?: string | null;
  renderingProviderId?: string | null;
}

export interface ChargeCreateManyBillInputEnvelope {
  data: ChargeCreateManyBillInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeCreateManyBillingProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  transactionId: string;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  chargemasterId?: string | null;
  billId?: string | null;
  renderingProviderId?: string | null;
}

export interface ChargeCreateManyBillingProviderInputEnvelope {
  data: ChargeCreateManyBillingProviderInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeCreateManyChargemasterInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  transactionId: string;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  billId?: string | null;
  billingProviderId?: string | null;
  renderingProviderId?: string | null;
}

export interface ChargeCreateManyChargemasterInputEnvelope {
  data: ChargeCreateManyChargemasterInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeCreateManyRenderingProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  transactionId: string;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  chargemasterId?: string | null;
  billId?: string | null;
  billingProviderId?: string | null;
}

export interface ChargeCreateManyRenderingProviderInputEnvelope {
  data: ChargeCreateManyRenderingProviderInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeCreateNestedManyWithoutBillInput {
  create?: ChargeCreateWithoutBillInput[] | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutBillInput[] | null;
  createMany?: ChargeCreateManyBillInputEnvelope | null;
  connect?: ChargeWhereUniqueInput[] | null;
}

export interface ChargeCreateNestedManyWithoutBillingProviderInput {
  create?: ChargeCreateWithoutBillingProviderInput[] | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutBillingProviderInput[] | null;
  createMany?: ChargeCreateManyBillingProviderInputEnvelope | null;
  connect?: ChargeWhereUniqueInput[] | null;
}

export interface ChargeCreateNestedManyWithoutChargemasterInput {
  create?: ChargeCreateWithoutChargemasterInput[] | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutChargemasterInput[] | null;
  createMany?: ChargeCreateManyChargemasterInputEnvelope | null;
  connect?: ChargeWhereUniqueInput[] | null;
}

export interface ChargeCreateNestedManyWithoutRenderingProviderInput {
  create?: ChargeCreateWithoutRenderingProviderInput[] | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutRenderingProviderInput[] | null;
  createMany?: ChargeCreateManyRenderingProviderInputEnvelope | null;
  connect?: ChargeWhereUniqueInput[] | null;
}

export interface ChargeCreateNestedOneWithoutClaimLineInput {
  create?: ChargeCreateWithoutClaimLineInput | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutClaimLineInput | null;
  connect?: ChargeWhereUniqueInput | null;
}

export interface ChargeCreateNestedOneWithoutEstimatedChargesInput {
  create?: ChargeCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutEstimatedChargesInput | null;
  connect?: ChargeWhereUniqueInput | null;
}

export interface ChargeCreateNestedOneWithoutInsuranceBillableChargesInput {
  create?: ChargeCreateWithoutInsuranceBillableChargesInput | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutInsuranceBillableChargesInput | null;
  connect?: ChargeWhereUniqueInput | null;
}

export interface ChargeCreateNestedOneWithoutPaymentIntentChargesInput {
  create?: ChargeCreateWithoutPaymentIntentChargesInput | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutPaymentIntentChargesInput | null;
  connect?: ChargeWhereUniqueInput | null;
}

export interface ChargeCreateNestedOneWithoutTransactionInput {
  create?: ChargeCreateWithoutTransactionInput | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutTransactionInput | null;
  connect?: ChargeWhereUniqueInput | null;
}

export interface ChargeCreateOrConnectWithoutBillInput {
  where: ChargeWhereUniqueInput;
  create: ChargeCreateWithoutBillInput;
}

export interface ChargeCreateOrConnectWithoutBillingProviderInput {
  where: ChargeWhereUniqueInput;
  create: ChargeCreateWithoutBillingProviderInput;
}

export interface ChargeCreateOrConnectWithoutChargemasterInput {
  where: ChargeWhereUniqueInput;
  create: ChargeCreateWithoutChargemasterInput;
}

export interface ChargeCreateOrConnectWithoutClaimLineInput {
  where: ChargeWhereUniqueInput;
  create: ChargeCreateWithoutClaimLineInput;
}

export interface ChargeCreateOrConnectWithoutEstimatedChargesInput {
  where: ChargeWhereUniqueInput;
  create: ChargeCreateWithoutEstimatedChargesInput;
}

export interface ChargeCreateOrConnectWithoutInsuranceBillableChargesInput {
  where: ChargeWhereUniqueInput;
  create: ChargeCreateWithoutInsuranceBillableChargesInput;
}

export interface ChargeCreateOrConnectWithoutPaymentIntentChargesInput {
  where: ChargeWhereUniqueInput;
  create: ChargeCreateWithoutPaymentIntentChargesInput;
}

export interface ChargeCreateOrConnectWithoutRenderingProviderInput {
  where: ChargeWhereUniqueInput;
  create: ChargeCreateWithoutRenderingProviderInput;
}

export interface ChargeCreateOrConnectWithoutTransactionInput {
  where: ChargeWhereUniqueInput;
  create: ChargeCreateWithoutTransactionInput;
}

export interface ChargeCreateWithoutBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  transaction: TransactionCreateNestedOneWithoutChargeInput;
  chargemaster?: ChargemasterCreateNestedOneWithoutChargesInput | null;
  claimLine?: ClaimLineCreateNestedManyWithoutChargeInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutChargeInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutChargeInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledChargesInput | null;
  renderingProvider?: ProviderCreateNestedOneWithoutRenderedChargesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargeInput | null;
}

export interface ChargeCreateWithoutBillingProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  transaction: TransactionCreateNestedOneWithoutChargeInput;
  chargemaster?: ChargemasterCreateNestedOneWithoutChargesInput | null;
  claimLine?: ClaimLineCreateNestedManyWithoutChargeInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutChargeInput | null;
  bill?: BillCreateNestedOneWithoutChargesInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutChargeInput | null;
  renderingProvider?: ProviderCreateNestedOneWithoutRenderedChargesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargeInput | null;
}

export interface ChargeCreateWithoutChargemasterInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  transaction: TransactionCreateNestedOneWithoutChargeInput;
  claimLine?: ClaimLineCreateNestedManyWithoutChargeInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutChargeInput | null;
  bill?: BillCreateNestedOneWithoutChargesInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutChargeInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledChargesInput | null;
  renderingProvider?: ProviderCreateNestedOneWithoutRenderedChargesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargeInput | null;
}

export interface ChargeCreateWithoutClaimLineInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  transaction: TransactionCreateNestedOneWithoutChargeInput;
  chargemaster?: ChargemasterCreateNestedOneWithoutChargesInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutChargeInput | null;
  bill?: BillCreateNestedOneWithoutChargesInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutChargeInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledChargesInput | null;
  renderingProvider?: ProviderCreateNestedOneWithoutRenderedChargesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargeInput | null;
}

export interface ChargeCreateWithoutEstimatedChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  transaction: TransactionCreateNestedOneWithoutChargeInput;
  chargemaster?: ChargemasterCreateNestedOneWithoutChargesInput | null;
  claimLine?: ClaimLineCreateNestedManyWithoutChargeInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutChargeInput | null;
  bill?: BillCreateNestedOneWithoutChargesInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutChargeInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledChargesInput | null;
  renderingProvider?: ProviderCreateNestedOneWithoutRenderedChargesInput | null;
}

export interface ChargeCreateWithoutInsuranceBillableChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  transaction: TransactionCreateNestedOneWithoutChargeInput;
  chargemaster?: ChargemasterCreateNestedOneWithoutChargesInput | null;
  claimLine?: ClaimLineCreateNestedManyWithoutChargeInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutChargeInput | null;
  bill?: BillCreateNestedOneWithoutChargesInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledChargesInput | null;
  renderingProvider?: ProviderCreateNestedOneWithoutRenderedChargesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargeInput | null;
}

export interface ChargeCreateWithoutPaymentIntentChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  transaction: TransactionCreateNestedOneWithoutChargeInput;
  chargemaster?: ChargemasterCreateNestedOneWithoutChargesInput | null;
  claimLine?: ClaimLineCreateNestedManyWithoutChargeInput | null;
  bill?: BillCreateNestedOneWithoutChargesInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutChargeInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledChargesInput | null;
  renderingProvider?: ProviderCreateNestedOneWithoutRenderedChargesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargeInput | null;
}

export interface ChargeCreateWithoutRenderingProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  transaction: TransactionCreateNestedOneWithoutChargeInput;
  chargemaster?: ChargemasterCreateNestedOneWithoutChargesInput | null;
  claimLine?: ClaimLineCreateNestedManyWithoutChargeInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutChargeInput | null;
  bill?: BillCreateNestedOneWithoutChargesInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutChargeInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledChargesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargeInput | null;
}

export interface ChargeCreateWithoutTransactionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeAmount: number;
  patientAmount: number;
  insuranceAmount: number;
  writeOffAmount: number;
  allowedAmount: number;
  copayAmount: number;
  deductibleAmount: number;
  coinsuranceAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  units?: number | null;
  customCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  chargemaster?: ChargemasterCreateNestedOneWithoutChargesInput | null;
  claimLine?: ClaimLineCreateNestedManyWithoutChargeInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutChargeInput | null;
  bill?: BillCreateNestedOneWithoutChargesInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeCreateNestedManyWithoutChargeInput | null;
  billingProvider?: ProviderCreateNestedOneWithoutBilledChargesInput | null;
  renderingProvider?: ProviderCreateNestedOneWithoutRenderedChargesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargeInput | null;
}

export interface ChargeListRelationFilter {
  every?: ChargeWhereInput | null;
  some?: ChargeWhereInput | null;
  none?: ChargeWhereInput | null;
}

export interface ChargeOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ChargePatientPaymentAllocationInput {
  chargeId?: string | null;
  billId?: string | null;
  amount: number;
}

export interface ChargePaymentChargeTransactionIdPaymentTransactionIdCompoundUniqueInput {
  chargeTransactionId: string;
  paymentTransactionId: string;
}

export interface ChargePaymentCreateManyChargeTransactionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  paymentTransactionId: string;
  amount: number;
  paymentIntentId?: string | null;
  refundingChargePaymentId?: string | null;
}

export interface ChargePaymentCreateManyChargeTransactionInputEnvelope {
  data: ChargePaymentCreateManyChargeTransactionInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargePaymentCreateManyPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeTransactionId: string;
  paymentTransactionId: string;
  amount: number;
  refundingChargePaymentId?: string | null;
}

export interface ChargePaymentCreateManyPaymentIntentInputEnvelope {
  data: ChargePaymentCreateManyPaymentIntentInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargePaymentCreateManyPaymentTransactionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeTransactionId: string;
  amount: number;
  paymentIntentId?: string | null;
  refundingChargePaymentId?: string | null;
}

export interface ChargePaymentCreateManyPaymentTransactionInputEnvelope {
  data: ChargePaymentCreateManyPaymentTransactionInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargePaymentCreateManyRefundingChargePaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeTransactionId: string;
  paymentTransactionId: string;
  amount: number;
  paymentIntentId?: string | null;
}

export interface ChargePaymentCreateManyRefundingChargePaymentInputEnvelope {
  data: ChargePaymentCreateManyRefundingChargePaymentInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargePaymentCreateNestedManyWithoutChargeTransactionInput {
  create?: ChargePaymentCreateWithoutChargeTransactionInput[] | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutChargeTransactionInput[] | null;
  createMany?: ChargePaymentCreateManyChargeTransactionInputEnvelope | null;
  connect?: ChargePaymentWhereUniqueInput[] | null;
}

export interface ChargePaymentCreateNestedManyWithoutPaymentIntentInput {
  create?: ChargePaymentCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutPaymentIntentInput[] | null;
  createMany?: ChargePaymentCreateManyPaymentIntentInputEnvelope | null;
  connect?: ChargePaymentWhereUniqueInput[] | null;
}

export interface ChargePaymentCreateNestedManyWithoutPaymentTransactionInput {
  create?: ChargePaymentCreateWithoutPaymentTransactionInput[] | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutPaymentTransactionInput[] | null;
  createMany?: ChargePaymentCreateManyPaymentTransactionInputEnvelope | null;
  connect?: ChargePaymentWhereUniqueInput[] | null;
}

export interface ChargePaymentCreateNestedManyWithoutRefundingChargePaymentInput {
  create?: ChargePaymentCreateWithoutRefundingChargePaymentInput[] | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutRefundingChargePaymentInput[] | null;
  createMany?: ChargePaymentCreateManyRefundingChargePaymentInputEnvelope | null;
  connect?: ChargePaymentWhereUniqueInput[] | null;
}

export interface ChargePaymentCreateNestedOneWithoutIntegrationLinksInput {
  create?: ChargePaymentCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: ChargePaymentWhereUniqueInput | null;
}

export interface ChargePaymentCreateNestedOneWithoutRefundedByChargePaymentsInput {
  create?: ChargePaymentCreateWithoutRefundedByChargePaymentsInput | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutRefundedByChargePaymentsInput | null;
  connect?: ChargePaymentWhereUniqueInput | null;
}

export interface ChargePaymentCreateOrConnectWithoutChargeTransactionInput {
  where: ChargePaymentWhereUniqueInput;
  create: ChargePaymentCreateWithoutChargeTransactionInput;
}

export interface ChargePaymentCreateOrConnectWithoutIntegrationLinksInput {
  where: ChargePaymentWhereUniqueInput;
  create: ChargePaymentCreateWithoutIntegrationLinksInput;
}

export interface ChargePaymentCreateOrConnectWithoutPaymentIntentInput {
  where: ChargePaymentWhereUniqueInput;
  create: ChargePaymentCreateWithoutPaymentIntentInput;
}

export interface ChargePaymentCreateOrConnectWithoutPaymentTransactionInput {
  where: ChargePaymentWhereUniqueInput;
  create: ChargePaymentCreateWithoutPaymentTransactionInput;
}

export interface ChargePaymentCreateOrConnectWithoutRefundedByChargePaymentsInput {
  where: ChargePaymentWhereUniqueInput;
  create: ChargePaymentCreateWithoutRefundedByChargePaymentsInput;
}

export interface ChargePaymentCreateOrConnectWithoutRefundingChargePaymentInput {
  where: ChargePaymentWhereUniqueInput;
  create: ChargePaymentCreateWithoutRefundingChargePaymentInput;
}

export interface ChargePaymentCreateWithoutChargeTransactionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  paymentTransaction: TransactionCreateNestedOneWithoutPaymentAllocationsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutChargePaymentInput | null;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutChargePaymentsInput | null;
  refundingChargePayment?: ChargePaymentCreateNestedOneWithoutRefundedByChargePaymentsInput | null;
  refundedByChargePayments?: ChargePaymentCreateNestedManyWithoutRefundingChargePaymentInput | null;
}

export interface ChargePaymentCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  chargeTransaction: TransactionCreateNestedOneWithoutChargeAllocationsInput;
  paymentTransaction: TransactionCreateNestedOneWithoutPaymentAllocationsInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutChargePaymentsInput | null;
  refundingChargePayment?: ChargePaymentCreateNestedOneWithoutRefundedByChargePaymentsInput | null;
  refundedByChargePayments?: ChargePaymentCreateNestedManyWithoutRefundingChargePaymentInput | null;
}

export interface ChargePaymentCreateWithoutPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  chargeTransaction: TransactionCreateNestedOneWithoutChargeAllocationsInput;
  paymentTransaction: TransactionCreateNestedOneWithoutPaymentAllocationsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutChargePaymentInput | null;
  refundingChargePayment?: ChargePaymentCreateNestedOneWithoutRefundedByChargePaymentsInput | null;
  refundedByChargePayments?: ChargePaymentCreateNestedManyWithoutRefundingChargePaymentInput | null;
}

export interface ChargePaymentCreateWithoutPaymentTransactionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  chargeTransaction: TransactionCreateNestedOneWithoutChargeAllocationsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutChargePaymentInput | null;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutChargePaymentsInput | null;
  refundingChargePayment?: ChargePaymentCreateNestedOneWithoutRefundedByChargePaymentsInput | null;
  refundedByChargePayments?: ChargePaymentCreateNestedManyWithoutRefundingChargePaymentInput | null;
}

export interface ChargePaymentCreateWithoutRefundedByChargePaymentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  chargeTransaction: TransactionCreateNestedOneWithoutChargeAllocationsInput;
  paymentTransaction: TransactionCreateNestedOneWithoutPaymentAllocationsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutChargePaymentInput | null;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutChargePaymentsInput | null;
  refundingChargePayment?: ChargePaymentCreateNestedOneWithoutRefundedByChargePaymentsInput | null;
}

export interface ChargePaymentCreateWithoutRefundingChargePaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  chargeTransaction: TransactionCreateNestedOneWithoutChargeAllocationsInput;
  paymentTransaction: TransactionCreateNestedOneWithoutPaymentAllocationsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutChargePaymentInput | null;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutChargePaymentsInput | null;
  refundedByChargePayments?: ChargePaymentCreateNestedManyWithoutRefundingChargePaymentInput | null;
}

export interface ChargePaymentListRelationFilter {
  every?: ChargePaymentWhereInput | null;
  some?: ChargePaymentWhereInput | null;
  none?: ChargePaymentWhereInput | null;
}

export interface ChargePaymentRelationFilter {
  is?: ChargePaymentWhereInput | null;
  isNot?: ChargePaymentWhereInput | null;
}

export interface ChargePaymentScalarWhereInput {
  AND?: ChargePaymentScalarWhereInput[] | null;
  OR?: ChargePaymentScalarWhereInput[] | null;
  NOT?: ChargePaymentScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  chargeTransactionId?: UuidFilter | null;
  paymentTransactionId?: UuidFilter | null;
  amount?: IntFilter | null;
  paymentIntentId?: UuidNullableFilter | null;
  refundingChargePaymentId?: UuidNullableFilter | null;
}

export interface ChargePaymentUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
}

export interface ChargePaymentUpdateManyWithWhereWithoutChargeTransactionInput {
  where: ChargePaymentScalarWhereInput;
  data: ChargePaymentUpdateManyMutationInput;
}

export interface ChargePaymentUpdateManyWithWhereWithoutPaymentIntentInput {
  where: ChargePaymentScalarWhereInput;
  data: ChargePaymentUpdateManyMutationInput;
}

export interface ChargePaymentUpdateManyWithWhereWithoutPaymentTransactionInput {
  where: ChargePaymentScalarWhereInput;
  data: ChargePaymentUpdateManyMutationInput;
}

export interface ChargePaymentUpdateManyWithWhereWithoutRefundingChargePaymentInput {
  where: ChargePaymentScalarWhereInput;
  data: ChargePaymentUpdateManyMutationInput;
}

export interface ChargePaymentUpdateManyWithoutChargeTransactionNestedInput {
  create?: ChargePaymentCreateWithoutChargeTransactionInput[] | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutChargeTransactionInput[] | null;
  upsert?: ChargePaymentUpsertWithWhereUniqueWithoutChargeTransactionInput[] | null;
  createMany?: ChargePaymentCreateManyChargeTransactionInputEnvelope | null;
  set?: ChargePaymentWhereUniqueInput[] | null;
  disconnect?: ChargePaymentWhereUniqueInput[] | null;
  delete?: ChargePaymentWhereUniqueInput[] | null;
  connect?: ChargePaymentWhereUniqueInput[] | null;
  update?: ChargePaymentUpdateWithWhereUniqueWithoutChargeTransactionInput[] | null;
  updateMany?: ChargePaymentUpdateManyWithWhereWithoutChargeTransactionInput[] | null;
  deleteMany?: ChargePaymentScalarWhereInput[] | null;
}

export interface ChargePaymentUpdateManyWithoutPaymentIntentNestedInput {
  create?: ChargePaymentCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutPaymentIntentInput[] | null;
  upsert?: ChargePaymentUpsertWithWhereUniqueWithoutPaymentIntentInput[] | null;
  createMany?: ChargePaymentCreateManyPaymentIntentInputEnvelope | null;
  set?: ChargePaymentWhereUniqueInput[] | null;
  disconnect?: ChargePaymentWhereUniqueInput[] | null;
  delete?: ChargePaymentWhereUniqueInput[] | null;
  connect?: ChargePaymentWhereUniqueInput[] | null;
  update?: ChargePaymentUpdateWithWhereUniqueWithoutPaymentIntentInput[] | null;
  updateMany?: ChargePaymentUpdateManyWithWhereWithoutPaymentIntentInput[] | null;
  deleteMany?: ChargePaymentScalarWhereInput[] | null;
}

export interface ChargePaymentUpdateManyWithoutPaymentTransactionNestedInput {
  create?: ChargePaymentCreateWithoutPaymentTransactionInput[] | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutPaymentTransactionInput[] | null;
  upsert?: ChargePaymentUpsertWithWhereUniqueWithoutPaymentTransactionInput[] | null;
  createMany?: ChargePaymentCreateManyPaymentTransactionInputEnvelope | null;
  set?: ChargePaymentWhereUniqueInput[] | null;
  disconnect?: ChargePaymentWhereUniqueInput[] | null;
  delete?: ChargePaymentWhereUniqueInput[] | null;
  connect?: ChargePaymentWhereUniqueInput[] | null;
  update?: ChargePaymentUpdateWithWhereUniqueWithoutPaymentTransactionInput[] | null;
  updateMany?: ChargePaymentUpdateManyWithWhereWithoutPaymentTransactionInput[] | null;
  deleteMany?: ChargePaymentScalarWhereInput[] | null;
}

export interface ChargePaymentUpdateManyWithoutRefundingChargePaymentNestedInput {
  create?: ChargePaymentCreateWithoutRefundingChargePaymentInput[] | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutRefundingChargePaymentInput[] | null;
  upsert?: ChargePaymentUpsertWithWhereUniqueWithoutRefundingChargePaymentInput[] | null;
  createMany?: ChargePaymentCreateManyRefundingChargePaymentInputEnvelope | null;
  set?: ChargePaymentWhereUniqueInput[] | null;
  disconnect?: ChargePaymentWhereUniqueInput[] | null;
  delete?: ChargePaymentWhereUniqueInput[] | null;
  connect?: ChargePaymentWhereUniqueInput[] | null;
  update?: ChargePaymentUpdateWithWhereUniqueWithoutRefundingChargePaymentInput[] | null;
  updateMany?: ChargePaymentUpdateManyWithWhereWithoutRefundingChargePaymentInput[] | null;
  deleteMany?: ChargePaymentScalarWhereInput[] | null;
}

export interface ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput {
  create?: ChargePaymentCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: ChargePaymentUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ChargePaymentWhereUniqueInput | null;
  update?: ChargePaymentUpdateWithoutIntegrationLinksInput | null;
}

export interface ChargePaymentUpdateOneWithoutRefundedByChargePaymentsNestedInput {
  create?: ChargePaymentCreateWithoutRefundedByChargePaymentsInput | null;
  connectOrCreate?: ChargePaymentCreateOrConnectWithoutRefundedByChargePaymentsInput | null;
  upsert?: ChargePaymentUpsertWithoutRefundedByChargePaymentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ChargePaymentWhereUniqueInput | null;
  update?: ChargePaymentUpdateWithoutRefundedByChargePaymentsInput | null;
}

export interface ChargePaymentUpdateWithWhereUniqueWithoutChargeTransactionInput {
  where: ChargePaymentWhereUniqueInput;
  data: ChargePaymentUpdateWithoutChargeTransactionInput;
}

export interface ChargePaymentUpdateWithWhereUniqueWithoutPaymentIntentInput {
  where: ChargePaymentWhereUniqueInput;
  data: ChargePaymentUpdateWithoutPaymentIntentInput;
}

export interface ChargePaymentUpdateWithWhereUniqueWithoutPaymentTransactionInput {
  where: ChargePaymentWhereUniqueInput;
  data: ChargePaymentUpdateWithoutPaymentTransactionInput;
}

export interface ChargePaymentUpdateWithWhereUniqueWithoutRefundingChargePaymentInput {
  where: ChargePaymentWhereUniqueInput;
  data: ChargePaymentUpdateWithoutRefundingChargePaymentInput;
}

export interface ChargePaymentUpdateWithoutChargeTransactionInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  paymentTransaction?: TransactionUpdateOneRequiredWithoutPaymentAllocationsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutChargePaymentNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutChargePaymentsNestedInput | null;
  refundingChargePayment?: ChargePaymentUpdateOneWithoutRefundedByChargePaymentsNestedInput | null;
  refundedByChargePayments?: ChargePaymentUpdateManyWithoutRefundingChargePaymentNestedInput | null;
}

export interface ChargePaymentUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  chargeTransaction?: TransactionUpdateOneRequiredWithoutChargeAllocationsNestedInput | null;
  paymentTransaction?: TransactionUpdateOneRequiredWithoutPaymentAllocationsNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutChargePaymentsNestedInput | null;
  refundingChargePayment?: ChargePaymentUpdateOneWithoutRefundedByChargePaymentsNestedInput | null;
  refundedByChargePayments?: ChargePaymentUpdateManyWithoutRefundingChargePaymentNestedInput | null;
}

export interface ChargePaymentUpdateWithoutPaymentIntentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  chargeTransaction?: TransactionUpdateOneRequiredWithoutChargeAllocationsNestedInput | null;
  paymentTransaction?: TransactionUpdateOneRequiredWithoutPaymentAllocationsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutChargePaymentNestedInput | null;
  refundingChargePayment?: ChargePaymentUpdateOneWithoutRefundedByChargePaymentsNestedInput | null;
  refundedByChargePayments?: ChargePaymentUpdateManyWithoutRefundingChargePaymentNestedInput | null;
}

export interface ChargePaymentUpdateWithoutPaymentTransactionInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  chargeTransaction?: TransactionUpdateOneRequiredWithoutChargeAllocationsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutChargePaymentNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutChargePaymentsNestedInput | null;
  refundingChargePayment?: ChargePaymentUpdateOneWithoutRefundedByChargePaymentsNestedInput | null;
  refundedByChargePayments?: ChargePaymentUpdateManyWithoutRefundingChargePaymentNestedInput | null;
}

export interface ChargePaymentUpdateWithoutRefundedByChargePaymentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  chargeTransaction?: TransactionUpdateOneRequiredWithoutChargeAllocationsNestedInput | null;
  paymentTransaction?: TransactionUpdateOneRequiredWithoutPaymentAllocationsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutChargePaymentNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutChargePaymentsNestedInput | null;
  refundingChargePayment?: ChargePaymentUpdateOneWithoutRefundedByChargePaymentsNestedInput | null;
}

export interface ChargePaymentUpdateWithoutRefundingChargePaymentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  chargeTransaction?: TransactionUpdateOneRequiredWithoutChargeAllocationsNestedInput | null;
  paymentTransaction?: TransactionUpdateOneRequiredWithoutPaymentAllocationsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutChargePaymentNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutChargePaymentsNestedInput | null;
  refundedByChargePayments?: ChargePaymentUpdateManyWithoutRefundingChargePaymentNestedInput | null;
}

export interface ChargePaymentUpsertWithWhereUniqueWithoutChargeTransactionInput {
  where: ChargePaymentWhereUniqueInput;
  update: ChargePaymentUpdateWithoutChargeTransactionInput;
  create: ChargePaymentCreateWithoutChargeTransactionInput;
}

export interface ChargePaymentUpsertWithWhereUniqueWithoutPaymentIntentInput {
  where: ChargePaymentWhereUniqueInput;
  update: ChargePaymentUpdateWithoutPaymentIntentInput;
  create: ChargePaymentCreateWithoutPaymentIntentInput;
}

export interface ChargePaymentUpsertWithWhereUniqueWithoutPaymentTransactionInput {
  where: ChargePaymentWhereUniqueInput;
  update: ChargePaymentUpdateWithoutPaymentTransactionInput;
  create: ChargePaymentCreateWithoutPaymentTransactionInput;
}

export interface ChargePaymentUpsertWithWhereUniqueWithoutRefundingChargePaymentInput {
  where: ChargePaymentWhereUniqueInput;
  update: ChargePaymentUpdateWithoutRefundingChargePaymentInput;
  create: ChargePaymentCreateWithoutRefundingChargePaymentInput;
}

export interface ChargePaymentUpsertWithoutIntegrationLinksInput {
  update: ChargePaymentUpdateWithoutIntegrationLinksInput;
  create: ChargePaymentCreateWithoutIntegrationLinksInput;
}

export interface ChargePaymentUpsertWithoutRefundedByChargePaymentsInput {
  update: ChargePaymentUpdateWithoutRefundedByChargePaymentsInput;
  create: ChargePaymentCreateWithoutRefundedByChargePaymentsInput;
}

export interface ChargePaymentWhereInput {
  AND?: ChargePaymentWhereInput[] | null;
  OR?: ChargePaymentWhereInput[] | null;
  NOT?: ChargePaymentWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  chargeTransactionId?: UuidFilter | null;
  paymentTransactionId?: UuidFilter | null;
  amount?: IntFilter | null;
  paymentIntentId?: UuidNullableFilter | null;
  refundingChargePaymentId?: UuidNullableFilter | null;
  chargeTransaction?: TransactionRelationFilter | null;
  paymentTransaction?: TransactionRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
  paymentIntent?: PaymentIntentRelationFilter | null;
  refundingChargePayment?: ChargePaymentRelationFilter | null;
  refundedByChargePayments?: ChargePaymentListRelationFilter | null;
}

export interface ChargePaymentWhereUniqueInput {
  id?: string | null;
  chargeTransactionId_paymentTransactionId?: ChargePaymentChargeTransactionIdPaymentTransactionIdCompoundUniqueInput | null;
}

export interface ChargeRelationFilter {
  is?: ChargeWhereInput | null;
  isNot?: ChargeWhereInput | null;
}

export interface ChargeScalarWhereInput {
  AND?: ChargeScalarWhereInput[] | null;
  OR?: ChargeScalarWhereInput[] | null;
  NOT?: ChargeScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  chargeAmount?: IntFilter | null;
  patientAmount?: IntFilter | null;
  insuranceAmount?: IntFilter | null;
  writeOffAmount?: IntFilter | null;
  allowedAmount?: IntFilter | null;
  copayAmount?: IntFilter | null;
  deductibleAmount?: IntFilter | null;
  coinsuranceAmount?: IntFilter | null;
  otherAmount?: IntFilter | null;
  taxAmount?: IntNullableFilter | null;
  units?: FloatNullableFilter | null;
  transactionId?: UuidFilter | null;
  customCode?: StringNullableFilter | null;
  modifier1?: StringFilter | null;
  modifier2?: StringFilter | null;
  modifier3?: StringFilter | null;
  modifier4?: StringFilter | null;
  chargemasterId?: UuidNullableFilter | null;
  billId?: UuidNullableFilter | null;
  billingProviderId?: UuidNullableFilter | null;
  renderingProviderId?: UuidNullableFilter | null;
}

export interface ChargeTemplateChargeCreateManyChargeTemplateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargemasterGroupId: string;
  units: number;
  priority: number;
}

export interface ChargeTemplateChargeCreateManyChargeTemplateInputEnvelope {
  data: ChargeTemplateChargeCreateManyChargeTemplateInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeTemplateChargeCreateManyChargemasterGroupInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  units: number;
  priority: number;
  chargeTemplateId: string;
}

export interface ChargeTemplateChargeCreateManyChargemasterGroupInputEnvelope {
  data: ChargeTemplateChargeCreateManyChargemasterGroupInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeTemplateChargeCreateNestedManyWithoutChargeTemplateInput {
  create?: ChargeTemplateChargeCreateWithoutChargeTemplateInput[] | null;
  connectOrCreate?: ChargeTemplateChargeCreateOrConnectWithoutChargeTemplateInput[] | null;
  createMany?: ChargeTemplateChargeCreateManyChargeTemplateInputEnvelope | null;
  connect?: ChargeTemplateChargeWhereUniqueInput[] | null;
}

export interface ChargeTemplateChargeCreateNestedManyWithoutChargemasterGroupInput {
  create?: ChargeTemplateChargeCreateWithoutChargemasterGroupInput[] | null;
  connectOrCreate?: ChargeTemplateChargeCreateOrConnectWithoutChargemasterGroupInput[] | null;
  createMany?: ChargeTemplateChargeCreateManyChargemasterGroupInputEnvelope | null;
  connect?: ChargeTemplateChargeWhereUniqueInput[] | null;
}

export interface ChargeTemplateChargeCreateOrConnectWithoutChargeTemplateInput {
  where: ChargeTemplateChargeWhereUniqueInput;
  create: ChargeTemplateChargeCreateWithoutChargeTemplateInput;
}

export interface ChargeTemplateChargeCreateOrConnectWithoutChargemasterGroupInput {
  where: ChargeTemplateChargeWhereUniqueInput;
  create: ChargeTemplateChargeCreateWithoutChargemasterGroupInput;
}

export interface ChargeTemplateChargeCreateWithoutChargeTemplateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  units: number;
  priority: number;
  chargemasterGroup: ChargemasterGroupCreateNestedOneWithoutChargeTemplateChargesInput;
}

export interface ChargeTemplateChargeCreateWithoutChargemasterGroupInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  units: number;
  priority: number;
  chargeTemplate: ChargeTemplateCreateNestedOneWithoutChargeTemplateChargesInput;
}

export interface ChargeTemplateChargeListRelationFilter {
  every?: ChargeTemplateChargeWhereInput | null;
  some?: ChargeTemplateChargeWhereInput | null;
  none?: ChargeTemplateChargeWhereInput | null;
}

export interface ChargeTemplateChargeScalarWhereInput {
  AND?: ChargeTemplateChargeScalarWhereInput[] | null;
  OR?: ChargeTemplateChargeScalarWhereInput[] | null;
  NOT?: ChargeTemplateChargeScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  chargemasterGroupId?: UuidFilter | null;
  units?: FloatFilter | null;
  priority?: FloatFilter | null;
  chargeTemplateId?: UuidFilter | null;
}

export interface ChargeTemplateChargeUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
}

export interface ChargeTemplateChargeUpdateManyWithWhereWithoutChargeTemplateInput {
  where: ChargeTemplateChargeScalarWhereInput;
  data: ChargeTemplateChargeUpdateManyMutationInput;
}

export interface ChargeTemplateChargeUpdateManyWithWhereWithoutChargemasterGroupInput {
  where: ChargeTemplateChargeScalarWhereInput;
  data: ChargeTemplateChargeUpdateManyMutationInput;
}

export interface ChargeTemplateChargeUpdateManyWithoutChargeTemplateNestedInput {
  create?: ChargeTemplateChargeCreateWithoutChargeTemplateInput[] | null;
  connectOrCreate?: ChargeTemplateChargeCreateOrConnectWithoutChargeTemplateInput[] | null;
  upsert?: ChargeTemplateChargeUpsertWithWhereUniqueWithoutChargeTemplateInput[] | null;
  createMany?: ChargeTemplateChargeCreateManyChargeTemplateInputEnvelope | null;
  set?: ChargeTemplateChargeWhereUniqueInput[] | null;
  disconnect?: ChargeTemplateChargeWhereUniqueInput[] | null;
  delete?: ChargeTemplateChargeWhereUniqueInput[] | null;
  connect?: ChargeTemplateChargeWhereUniqueInput[] | null;
  update?: ChargeTemplateChargeUpdateWithWhereUniqueWithoutChargeTemplateInput[] | null;
  updateMany?: ChargeTemplateChargeUpdateManyWithWhereWithoutChargeTemplateInput[] | null;
  deleteMany?: ChargeTemplateChargeScalarWhereInput[] | null;
}

export interface ChargeTemplateChargeUpdateManyWithoutChargemasterGroupNestedInput {
  create?: ChargeTemplateChargeCreateWithoutChargemasterGroupInput[] | null;
  connectOrCreate?: ChargeTemplateChargeCreateOrConnectWithoutChargemasterGroupInput[] | null;
  upsert?: ChargeTemplateChargeUpsertWithWhereUniqueWithoutChargemasterGroupInput[] | null;
  createMany?: ChargeTemplateChargeCreateManyChargemasterGroupInputEnvelope | null;
  set?: ChargeTemplateChargeWhereUniqueInput[] | null;
  disconnect?: ChargeTemplateChargeWhereUniqueInput[] | null;
  delete?: ChargeTemplateChargeWhereUniqueInput[] | null;
  connect?: ChargeTemplateChargeWhereUniqueInput[] | null;
  update?: ChargeTemplateChargeUpdateWithWhereUniqueWithoutChargemasterGroupInput[] | null;
  updateMany?: ChargeTemplateChargeUpdateManyWithWhereWithoutChargemasterGroupInput[] | null;
  deleteMany?: ChargeTemplateChargeScalarWhereInput[] | null;
}

export interface ChargeTemplateChargeUpdateWithWhereUniqueWithoutChargeTemplateInput {
  where: ChargeTemplateChargeWhereUniqueInput;
  data: ChargeTemplateChargeUpdateWithoutChargeTemplateInput;
}

export interface ChargeTemplateChargeUpdateWithWhereUniqueWithoutChargemasterGroupInput {
  where: ChargeTemplateChargeWhereUniqueInput;
  data: ChargeTemplateChargeUpdateWithoutChargemasterGroupInput;
}

export interface ChargeTemplateChargeUpdateWithoutChargeTemplateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  chargemasterGroup?: ChargemasterGroupUpdateOneRequiredWithoutChargeTemplateChargesNestedInput | null;
}

export interface ChargeTemplateChargeUpdateWithoutChargemasterGroupInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneRequiredWithoutChargeTemplateChargesNestedInput | null;
}

export interface ChargeTemplateChargeUpsertWithWhereUniqueWithoutChargeTemplateInput {
  where: ChargeTemplateChargeWhereUniqueInput;
  update: ChargeTemplateChargeUpdateWithoutChargeTemplateInput;
  create: ChargeTemplateChargeCreateWithoutChargeTemplateInput;
}

export interface ChargeTemplateChargeUpsertWithWhereUniqueWithoutChargemasterGroupInput {
  where: ChargeTemplateChargeWhereUniqueInput;
  update: ChargeTemplateChargeUpdateWithoutChargemasterGroupInput;
  create: ChargeTemplateChargeCreateWithoutChargemasterGroupInput;
}

export interface ChargeTemplateChargeWhereInput {
  AND?: ChargeTemplateChargeWhereInput[] | null;
  OR?: ChargeTemplateChargeWhereInput[] | null;
  NOT?: ChargeTemplateChargeWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  chargemasterGroupId?: UuidFilter | null;
  units?: FloatFilter | null;
  priority?: FloatFilter | null;
  chargeTemplateId?: UuidFilter | null;
  chargemasterGroup?: ChargemasterGroupRelationFilter | null;
  chargeTemplate?: ChargeTemplateRelationFilter | null;
}

export interface ChargeTemplateChargeWhereUniqueInput {
  id?: string | null;
}

export interface ChargeTemplateCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  pending?: boolean | null;
  location: LocationCreateNestedOneWithoutChargeTemplatesInput;
  chargeTemplateCharges?: ChargeTemplateChargeCreateNestedManyWithoutChargeTemplateInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutChargeTemplateInput | null;
  estimates?: EstimateCreateNestedManyWithoutChargeTemplateInput | null;
}

export interface ChargeTemplateCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  pending?: boolean | null;
}

export interface ChargeTemplateCreateManyLocationInputEnvelope {
  data: ChargeTemplateCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeTemplateCreateNestedManyWithoutLocationInput {
  create?: ChargeTemplateCreateWithoutLocationInput[] | null;
  connectOrCreate?: ChargeTemplateCreateOrConnectWithoutLocationInput[] | null;
  createMany?: ChargeTemplateCreateManyLocationInputEnvelope | null;
  connect?: ChargeTemplateWhereUniqueInput[] | null;
}

export interface ChargeTemplateCreateNestedOneWithoutChargeTemplateChargesInput {
  create?: ChargeTemplateCreateWithoutChargeTemplateChargesInput | null;
  connectOrCreate?: ChargeTemplateCreateOrConnectWithoutChargeTemplateChargesInput | null;
  connect?: ChargeTemplateWhereUniqueInput | null;
}

export interface ChargeTemplateCreateNestedOneWithoutChargeTemplateMappingsInput {
  create?: ChargeTemplateCreateWithoutChargeTemplateMappingsInput | null;
  connectOrCreate?: ChargeTemplateCreateOrConnectWithoutChargeTemplateMappingsInput | null;
  connect?: ChargeTemplateWhereUniqueInput | null;
}

export interface ChargeTemplateCreateNestedOneWithoutEstimatesInput {
  create?: ChargeTemplateCreateWithoutEstimatesInput | null;
  connectOrCreate?: ChargeTemplateCreateOrConnectWithoutEstimatesInput | null;
  connect?: ChargeTemplateWhereUniqueInput | null;
}

export interface ChargeTemplateCreateOrConnectWithoutChargeTemplateChargesInput {
  where: ChargeTemplateWhereUniqueInput;
  create: ChargeTemplateCreateWithoutChargeTemplateChargesInput;
}

export interface ChargeTemplateCreateOrConnectWithoutChargeTemplateMappingsInput {
  where: ChargeTemplateWhereUniqueInput;
  create: ChargeTemplateCreateWithoutChargeTemplateMappingsInput;
}

export interface ChargeTemplateCreateOrConnectWithoutEstimatesInput {
  where: ChargeTemplateWhereUniqueInput;
  create: ChargeTemplateCreateWithoutEstimatesInput;
}

export interface ChargeTemplateCreateOrConnectWithoutLocationInput {
  where: ChargeTemplateWhereUniqueInput;
  create: ChargeTemplateCreateWithoutLocationInput;
}

export interface ChargeTemplateCreateWithoutChargeTemplateChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  pending?: boolean | null;
  location: LocationCreateNestedOneWithoutChargeTemplatesInput;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutChargeTemplateInput | null;
  estimates?: EstimateCreateNestedManyWithoutChargeTemplateInput | null;
}

export interface ChargeTemplateCreateWithoutChargeTemplateMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  pending?: boolean | null;
  location: LocationCreateNestedOneWithoutChargeTemplatesInput;
  chargeTemplateCharges?: ChargeTemplateChargeCreateNestedManyWithoutChargeTemplateInput | null;
  estimates?: EstimateCreateNestedManyWithoutChargeTemplateInput | null;
}

export interface ChargeTemplateCreateWithoutEstimatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  pending?: boolean | null;
  location: LocationCreateNestedOneWithoutChargeTemplatesInput;
  chargeTemplateCharges?: ChargeTemplateChargeCreateNestedManyWithoutChargeTemplateInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutChargeTemplateInput | null;
}

export interface ChargeTemplateCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  pending?: boolean | null;
  chargeTemplateCharges?: ChargeTemplateChargeCreateNestedManyWithoutChargeTemplateInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutChargeTemplateInput | null;
  estimates?: EstimateCreateNestedManyWithoutChargeTemplateInput | null;
}

export interface ChargeTemplateListRelationFilter {
  every?: ChargeTemplateWhereInput | null;
  some?: ChargeTemplateWhereInput | null;
  none?: ChargeTemplateWhereInput | null;
}

export interface ChargeTemplateMappingConditionCreateManyChargeTemplateMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
  providerServiceConfigurationId?: string | null;
}

export interface ChargeTemplateMappingConditionCreateManyChargeTemplateMappingInputEnvelope {
  data: ChargeTemplateMappingConditionCreateManyChargeTemplateMappingInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeTemplateMappingConditionCreateManyProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  chargeTemplateMappingId: string;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
}

export interface ChargeTemplateMappingConditionCreateManyProviderServiceConfigurationInputEnvelope {
  data: ChargeTemplateMappingConditionCreateManyProviderServiceConfigurationInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeTemplateMappingConditionCreateNestedManyWithoutChargeTemplateMappingInput {
  create?: ChargeTemplateMappingConditionCreateWithoutChargeTemplateMappingInput[] | null;
  connectOrCreate?: ChargeTemplateMappingConditionCreateOrConnectWithoutChargeTemplateMappingInput[] | null;
  createMany?: ChargeTemplateMappingConditionCreateManyChargeTemplateMappingInputEnvelope | null;
  connect?: ChargeTemplateMappingConditionWhereUniqueInput[] | null;
}

export interface ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput {
  create?: ChargeTemplateMappingConditionCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: ChargeTemplateMappingConditionCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  createMany?: ChargeTemplateMappingConditionCreateManyProviderServiceConfigurationInputEnvelope | null;
  connect?: ChargeTemplateMappingConditionWhereUniqueInput[] | null;
}

export interface ChargeTemplateMappingConditionCreateOrConnectWithoutChargeTemplateMappingInput {
  where: ChargeTemplateMappingConditionWhereUniqueInput;
  create: ChargeTemplateMappingConditionCreateWithoutChargeTemplateMappingInput;
}

export interface ChargeTemplateMappingConditionCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: ChargeTemplateMappingConditionWhereUniqueInput;
  create: ChargeTemplateMappingConditionCreateWithoutProviderServiceConfigurationInput;
}

export interface ChargeTemplateMappingConditionCreateWithoutChargeTemplateMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutChargeTemplateMappingConditionsInput | null;
}

export interface ChargeTemplateMappingConditionCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
  chargeTemplateMapping: ChargeTemplateMappingCreateNestedOneWithoutChargeTemplateMappingConditionsInput;
}

export interface ChargeTemplateMappingConditionListRelationFilter {
  every?: ChargeTemplateMappingConditionWhereInput | null;
  some?: ChargeTemplateMappingConditionWhereInput | null;
  none?: ChargeTemplateMappingConditionWhereInput | null;
}

export interface ChargeTemplateMappingConditionScalarWhereInput {
  AND?: ChargeTemplateMappingConditionScalarWhereInput[] | null;
  OR?: ChargeTemplateMappingConditionScalarWhereInput[] | null;
  NOT?: ChargeTemplateMappingConditionScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  chargeTemplateMappingId?: UuidFilter | null;
  attribute?: JsonFilter | null;
  comparison?: EnumComparisonFilter | null;
  attributeType?: StringFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
}

export interface ChargeTemplateMappingConditionUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
}

export interface ChargeTemplateMappingConditionUpdateManyWithWhereWithoutChargeTemplateMappingInput {
  where: ChargeTemplateMappingConditionScalarWhereInput;
  data: ChargeTemplateMappingConditionUpdateManyMutationInput;
}

export interface ChargeTemplateMappingConditionUpdateManyWithWhereWithoutProviderServiceConfigurationInput {
  where: ChargeTemplateMappingConditionScalarWhereInput;
  data: ChargeTemplateMappingConditionUpdateManyMutationInput;
}

export interface ChargeTemplateMappingConditionUpdateManyWithoutChargeTemplateMappingNestedInput {
  create?: ChargeTemplateMappingConditionCreateWithoutChargeTemplateMappingInput[] | null;
  connectOrCreate?: ChargeTemplateMappingConditionCreateOrConnectWithoutChargeTemplateMappingInput[] | null;
  upsert?: ChargeTemplateMappingConditionUpsertWithWhereUniqueWithoutChargeTemplateMappingInput[] | null;
  createMany?: ChargeTemplateMappingConditionCreateManyChargeTemplateMappingInputEnvelope | null;
  set?: ChargeTemplateMappingConditionWhereUniqueInput[] | null;
  disconnect?: ChargeTemplateMappingConditionWhereUniqueInput[] | null;
  delete?: ChargeTemplateMappingConditionWhereUniqueInput[] | null;
  connect?: ChargeTemplateMappingConditionWhereUniqueInput[] | null;
  update?: ChargeTemplateMappingConditionUpdateWithWhereUniqueWithoutChargeTemplateMappingInput[] | null;
  updateMany?: ChargeTemplateMappingConditionUpdateManyWithWhereWithoutChargeTemplateMappingInput[] | null;
  deleteMany?: ChargeTemplateMappingConditionScalarWhereInput[] | null;
}

export interface ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput {
  create?: ChargeTemplateMappingConditionCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: ChargeTemplateMappingConditionCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  upsert?: ChargeTemplateMappingConditionUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  createMany?: ChargeTemplateMappingConditionCreateManyProviderServiceConfigurationInputEnvelope | null;
  set?: ChargeTemplateMappingConditionWhereUniqueInput[] | null;
  disconnect?: ChargeTemplateMappingConditionWhereUniqueInput[] | null;
  delete?: ChargeTemplateMappingConditionWhereUniqueInput[] | null;
  connect?: ChargeTemplateMappingConditionWhereUniqueInput[] | null;
  update?: ChargeTemplateMappingConditionUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  updateMany?: ChargeTemplateMappingConditionUpdateManyWithWhereWithoutProviderServiceConfigurationInput[] | null;
  deleteMany?: ChargeTemplateMappingConditionScalarWhereInput[] | null;
}

export interface ChargeTemplateMappingConditionUpdateWithWhereUniqueWithoutChargeTemplateMappingInput {
  where: ChargeTemplateMappingConditionWhereUniqueInput;
  data: ChargeTemplateMappingConditionUpdateWithoutChargeTemplateMappingInput;
}

export interface ChargeTemplateMappingConditionUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: ChargeTemplateMappingConditionWhereUniqueInput;
  data: ChargeTemplateMappingConditionUpdateWithoutProviderServiceConfigurationInput;
}

export interface ChargeTemplateMappingConditionUpdateWithoutChargeTemplateMappingInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutChargeTemplateMappingConditionsNestedInput | null;
}

export interface ChargeTemplateMappingConditionUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
  chargeTemplateMapping?: ChargeTemplateMappingUpdateOneRequiredWithoutChargeTemplateMappingConditionsNestedInput | null;
}

export interface ChargeTemplateMappingConditionUpsertWithWhereUniqueWithoutChargeTemplateMappingInput {
  where: ChargeTemplateMappingConditionWhereUniqueInput;
  update: ChargeTemplateMappingConditionUpdateWithoutChargeTemplateMappingInput;
  create: ChargeTemplateMappingConditionCreateWithoutChargeTemplateMappingInput;
}

export interface ChargeTemplateMappingConditionUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: ChargeTemplateMappingConditionWhereUniqueInput;
  update: ChargeTemplateMappingConditionUpdateWithoutProviderServiceConfigurationInput;
  create: ChargeTemplateMappingConditionCreateWithoutProviderServiceConfigurationInput;
}

export interface ChargeTemplateMappingConditionWhereInput {
  AND?: ChargeTemplateMappingConditionWhereInput[] | null;
  OR?: ChargeTemplateMappingConditionWhereInput[] | null;
  NOT?: ChargeTemplateMappingConditionWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  chargeTemplateMappingId?: UuidFilter | null;
  attribute?: JsonFilter | null;
  comparison?: EnumComparisonFilter | null;
  attributeType?: StringFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
  chargeTemplateMapping?: ChargeTemplateMappingRelationFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationRelationFilter | null;
}

export interface ChargeTemplateMappingConditionWhereUniqueInput {
  id?: string | null;
}

export interface ChargeTemplateMappingCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  pending?: boolean | null;
  createdBy: UserCreateNestedOneWithoutCreatedChargeTemplateMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedChargeTemplateMappingsInput;
  location: LocationCreateNestedOneWithoutChargeTemplateMappingsInput;
  chargeTemplate: ChargeTemplateCreateNestedOneWithoutChargeTemplateMappingsInput;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutChargeTemplateMappingInput | null;
}

export interface ChargeTemplateMappingCreateManyChargeTemplateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  lastUpdatedById: string;
  priority: number;
  locationId: string;
  name: string;
  notes?: string | null;
  pending?: boolean | null;
}

export interface ChargeTemplateMappingCreateManyChargeTemplateInputEnvelope {
  data: ChargeTemplateMappingCreateManyChargeTemplateInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeTemplateMappingCreateManyCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUpdatedById: string;
  priority: number;
  locationId: string;
  chargeTemplateId: string;
  name: string;
  notes?: string | null;
  pending?: boolean | null;
}

export interface ChargeTemplateMappingCreateManyCreatedByInputEnvelope {
  data: ChargeTemplateMappingCreateManyCreatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeTemplateMappingCreateManyLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  priority: number;
  locationId: string;
  chargeTemplateId: string;
  name: string;
  notes?: string | null;
  pending?: boolean | null;
}

export interface ChargeTemplateMappingCreateManyLastUpdatedByInputEnvelope {
  data: ChargeTemplateMappingCreateManyLastUpdatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeTemplateMappingCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  lastUpdatedById: string;
  priority: number;
  chargeTemplateId: string;
  name: string;
  notes?: string | null;
  pending?: boolean | null;
}

export interface ChargeTemplateMappingCreateManyLocationInputEnvelope {
  data: ChargeTemplateMappingCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargeTemplateMappingCreateNestedManyWithoutChargeTemplateInput {
  create?: ChargeTemplateMappingCreateWithoutChargeTemplateInput[] | null;
  connectOrCreate?: ChargeTemplateMappingCreateOrConnectWithoutChargeTemplateInput[] | null;
  createMany?: ChargeTemplateMappingCreateManyChargeTemplateInputEnvelope | null;
  connect?: ChargeTemplateMappingWhereUniqueInput[] | null;
}

export interface ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput {
  create?: ChargeTemplateMappingCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: ChargeTemplateMappingCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: ChargeTemplateMappingCreateManyCreatedByInputEnvelope | null;
  connect?: ChargeTemplateMappingWhereUniqueInput[] | null;
}

export interface ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput {
  create?: ChargeTemplateMappingCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: ChargeTemplateMappingCreateOrConnectWithoutLastUpdatedByInput[] | null;
  createMany?: ChargeTemplateMappingCreateManyLastUpdatedByInputEnvelope | null;
  connect?: ChargeTemplateMappingWhereUniqueInput[] | null;
}

export interface ChargeTemplateMappingCreateNestedManyWithoutLocationInput {
  create?: ChargeTemplateMappingCreateWithoutLocationInput[] | null;
  connectOrCreate?: ChargeTemplateMappingCreateOrConnectWithoutLocationInput[] | null;
  createMany?: ChargeTemplateMappingCreateManyLocationInputEnvelope | null;
  connect?: ChargeTemplateMappingWhereUniqueInput[] | null;
}

export interface ChargeTemplateMappingCreateNestedOneWithoutChargeTemplateMappingConditionsInput {
  create?: ChargeTemplateMappingCreateWithoutChargeTemplateMappingConditionsInput | null;
  connectOrCreate?: ChargeTemplateMappingCreateOrConnectWithoutChargeTemplateMappingConditionsInput | null;
  connect?: ChargeTemplateMappingWhereUniqueInput | null;
}

export interface ChargeTemplateMappingCreateOrConnectWithoutChargeTemplateInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  create: ChargeTemplateMappingCreateWithoutChargeTemplateInput;
}

export interface ChargeTemplateMappingCreateOrConnectWithoutChargeTemplateMappingConditionsInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  create: ChargeTemplateMappingCreateWithoutChargeTemplateMappingConditionsInput;
}

export interface ChargeTemplateMappingCreateOrConnectWithoutCreatedByInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  create: ChargeTemplateMappingCreateWithoutCreatedByInput;
}

export interface ChargeTemplateMappingCreateOrConnectWithoutLastUpdatedByInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  create: ChargeTemplateMappingCreateWithoutLastUpdatedByInput;
}

export interface ChargeTemplateMappingCreateOrConnectWithoutLocationInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  create: ChargeTemplateMappingCreateWithoutLocationInput;
}

export interface ChargeTemplateMappingCreateWithoutChargeTemplateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  pending?: boolean | null;
  createdBy: UserCreateNestedOneWithoutCreatedChargeTemplateMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedChargeTemplateMappingsInput;
  location: LocationCreateNestedOneWithoutChargeTemplateMappingsInput;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutChargeTemplateMappingInput | null;
}

export interface ChargeTemplateMappingCreateWithoutChargeTemplateMappingConditionsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  pending?: boolean | null;
  createdBy: UserCreateNestedOneWithoutCreatedChargeTemplateMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedChargeTemplateMappingsInput;
  location: LocationCreateNestedOneWithoutChargeTemplateMappingsInput;
  chargeTemplate: ChargeTemplateCreateNestedOneWithoutChargeTemplateMappingsInput;
}

export interface ChargeTemplateMappingCreateWithoutCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  pending?: boolean | null;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedChargeTemplateMappingsInput;
  location: LocationCreateNestedOneWithoutChargeTemplateMappingsInput;
  chargeTemplate: ChargeTemplateCreateNestedOneWithoutChargeTemplateMappingsInput;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutChargeTemplateMappingInput | null;
}

export interface ChargeTemplateMappingCreateWithoutLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  pending?: boolean | null;
  createdBy: UserCreateNestedOneWithoutCreatedChargeTemplateMappingsInput;
  location: LocationCreateNestedOneWithoutChargeTemplateMappingsInput;
  chargeTemplate: ChargeTemplateCreateNestedOneWithoutChargeTemplateMappingsInput;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutChargeTemplateMappingInput | null;
}

export interface ChargeTemplateMappingCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  pending?: boolean | null;
  createdBy: UserCreateNestedOneWithoutCreatedChargeTemplateMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedChargeTemplateMappingsInput;
  chargeTemplate: ChargeTemplateCreateNestedOneWithoutChargeTemplateMappingsInput;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutChargeTemplateMappingInput | null;
}

export interface ChargeTemplateMappingListRelationFilter {
  every?: ChargeTemplateMappingWhereInput | null;
  some?: ChargeTemplateMappingWhereInput | null;
  none?: ChargeTemplateMappingWhereInput | null;
}

export interface ChargeTemplateMappingOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ChargeTemplateMappingRelationFilter {
  is?: ChargeTemplateMappingWhereInput | null;
  isNot?: ChargeTemplateMappingWhereInput | null;
}

export interface ChargeTemplateMappingScalarWhereInput {
  AND?: ChargeTemplateMappingScalarWhereInput[] | null;
  OR?: ChargeTemplateMappingScalarWhereInput[] | null;
  NOT?: ChargeTemplateMappingScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  priority?: FloatFilter | null;
  locationId?: UuidFilter | null;
  chargeTemplateId?: UuidFilter | null;
  name?: StringFilter | null;
  notes?: StringNullableFilter | null;
  pending?: BoolFilter | null;
}

export interface ChargeTemplateMappingUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedChargeTemplateMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedChargeTemplateMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutChargeTemplateMappingNestedInput | null;
}

export interface ChargeTemplateMappingUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
}

export interface ChargeTemplateMappingUpdateManyWithWhereWithoutChargeTemplateInput {
  where: ChargeTemplateMappingScalarWhereInput;
  data: ChargeTemplateMappingUpdateManyMutationInput;
}

export interface ChargeTemplateMappingUpdateManyWithWhereWithoutCreatedByInput {
  where: ChargeTemplateMappingScalarWhereInput;
  data: ChargeTemplateMappingUpdateManyMutationInput;
}

export interface ChargeTemplateMappingUpdateManyWithWhereWithoutLastUpdatedByInput {
  where: ChargeTemplateMappingScalarWhereInput;
  data: ChargeTemplateMappingUpdateManyMutationInput;
}

export interface ChargeTemplateMappingUpdateManyWithWhereWithoutLocationInput {
  where: ChargeTemplateMappingScalarWhereInput;
  data: ChargeTemplateMappingUpdateManyMutationInput;
}

export interface ChargeTemplateMappingUpdateManyWithoutChargeTemplateNestedInput {
  create?: ChargeTemplateMappingCreateWithoutChargeTemplateInput[] | null;
  connectOrCreate?: ChargeTemplateMappingCreateOrConnectWithoutChargeTemplateInput[] | null;
  upsert?: ChargeTemplateMappingUpsertWithWhereUniqueWithoutChargeTemplateInput[] | null;
  createMany?: ChargeTemplateMappingCreateManyChargeTemplateInputEnvelope | null;
  set?: ChargeTemplateMappingWhereUniqueInput[] | null;
  disconnect?: ChargeTemplateMappingWhereUniqueInput[] | null;
  delete?: ChargeTemplateMappingWhereUniqueInput[] | null;
  connect?: ChargeTemplateMappingWhereUniqueInput[] | null;
  update?: ChargeTemplateMappingUpdateWithWhereUniqueWithoutChargeTemplateInput[] | null;
  updateMany?: ChargeTemplateMappingUpdateManyWithWhereWithoutChargeTemplateInput[] | null;
  deleteMany?: ChargeTemplateMappingScalarWhereInput[] | null;
}

export interface ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput {
  create?: ChargeTemplateMappingCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: ChargeTemplateMappingCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: ChargeTemplateMappingUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: ChargeTemplateMappingCreateManyCreatedByInputEnvelope | null;
  set?: ChargeTemplateMappingWhereUniqueInput[] | null;
  disconnect?: ChargeTemplateMappingWhereUniqueInput[] | null;
  delete?: ChargeTemplateMappingWhereUniqueInput[] | null;
  connect?: ChargeTemplateMappingWhereUniqueInput[] | null;
  update?: ChargeTemplateMappingUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: ChargeTemplateMappingUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: ChargeTemplateMappingScalarWhereInput[] | null;
}

export interface ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput {
  create?: ChargeTemplateMappingCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: ChargeTemplateMappingCreateOrConnectWithoutLastUpdatedByInput[] | null;
  upsert?: ChargeTemplateMappingUpsertWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  createMany?: ChargeTemplateMappingCreateManyLastUpdatedByInputEnvelope | null;
  set?: ChargeTemplateMappingWhereUniqueInput[] | null;
  disconnect?: ChargeTemplateMappingWhereUniqueInput[] | null;
  delete?: ChargeTemplateMappingWhereUniqueInput[] | null;
  connect?: ChargeTemplateMappingWhereUniqueInput[] | null;
  update?: ChargeTemplateMappingUpdateWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  updateMany?: ChargeTemplateMappingUpdateManyWithWhereWithoutLastUpdatedByInput[] | null;
  deleteMany?: ChargeTemplateMappingScalarWhereInput[] | null;
}

export interface ChargeTemplateMappingUpdateManyWithoutLocationNestedInput {
  create?: ChargeTemplateMappingCreateWithoutLocationInput[] | null;
  connectOrCreate?: ChargeTemplateMappingCreateOrConnectWithoutLocationInput[] | null;
  upsert?: ChargeTemplateMappingUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: ChargeTemplateMappingCreateManyLocationInputEnvelope | null;
  set?: ChargeTemplateMappingWhereUniqueInput[] | null;
  disconnect?: ChargeTemplateMappingWhereUniqueInput[] | null;
  delete?: ChargeTemplateMappingWhereUniqueInput[] | null;
  connect?: ChargeTemplateMappingWhereUniqueInput[] | null;
  update?: ChargeTemplateMappingUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: ChargeTemplateMappingUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: ChargeTemplateMappingScalarWhereInput[] | null;
}

export interface ChargeTemplateMappingUpdateOneRequiredWithoutChargeTemplateMappingConditionsNestedInput {
  create?: ChargeTemplateMappingCreateWithoutChargeTemplateMappingConditionsInput | null;
  connectOrCreate?: ChargeTemplateMappingCreateOrConnectWithoutChargeTemplateMappingConditionsInput | null;
  upsert?: ChargeTemplateMappingUpsertWithoutChargeTemplateMappingConditionsInput | null;
  connect?: ChargeTemplateMappingWhereUniqueInput | null;
  update?: ChargeTemplateMappingUpdateWithoutChargeTemplateMappingConditionsInput | null;
}

export interface ChargeTemplateMappingUpdateWithWhereUniqueWithoutChargeTemplateInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  data: ChargeTemplateMappingUpdateWithoutChargeTemplateInput;
}

export interface ChargeTemplateMappingUpdateWithWhereUniqueWithoutCreatedByInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  data: ChargeTemplateMappingUpdateWithoutCreatedByInput;
}

export interface ChargeTemplateMappingUpdateWithWhereUniqueWithoutLastUpdatedByInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  data: ChargeTemplateMappingUpdateWithoutLastUpdatedByInput;
}

export interface ChargeTemplateMappingUpdateWithWhereUniqueWithoutLocationInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  data: ChargeTemplateMappingUpdateWithoutLocationInput;
}

export interface ChargeTemplateMappingUpdateWithoutChargeTemplateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedChargeTemplateMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedChargeTemplateMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutChargeTemplateMappingNestedInput | null;
}

export interface ChargeTemplateMappingUpdateWithoutChargeTemplateMappingConditionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedChargeTemplateMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedChargeTemplateMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput | null;
}

export interface ChargeTemplateMappingUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedChargeTemplateMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutChargeTemplateMappingNestedInput | null;
}

export interface ChargeTemplateMappingUpdateWithoutLastUpdatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedChargeTemplateMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutChargeTemplateMappingNestedInput | null;
}

export interface ChargeTemplateMappingUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedChargeTemplateMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedChargeTemplateMappingsNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutChargeTemplateMappingNestedInput | null;
}

export interface ChargeTemplateMappingUpsertWithWhereUniqueWithoutChargeTemplateInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  update: ChargeTemplateMappingUpdateWithoutChargeTemplateInput;
  create: ChargeTemplateMappingCreateWithoutChargeTemplateInput;
}

export interface ChargeTemplateMappingUpsertWithWhereUniqueWithoutCreatedByInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  update: ChargeTemplateMappingUpdateWithoutCreatedByInput;
  create: ChargeTemplateMappingCreateWithoutCreatedByInput;
}

export interface ChargeTemplateMappingUpsertWithWhereUniqueWithoutLastUpdatedByInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  update: ChargeTemplateMappingUpdateWithoutLastUpdatedByInput;
  create: ChargeTemplateMappingCreateWithoutLastUpdatedByInput;
}

export interface ChargeTemplateMappingUpsertWithWhereUniqueWithoutLocationInput {
  where: ChargeTemplateMappingWhereUniqueInput;
  update: ChargeTemplateMappingUpdateWithoutLocationInput;
  create: ChargeTemplateMappingCreateWithoutLocationInput;
}

export interface ChargeTemplateMappingUpsertWithoutChargeTemplateMappingConditionsInput {
  update: ChargeTemplateMappingUpdateWithoutChargeTemplateMappingConditionsInput;
  create: ChargeTemplateMappingCreateWithoutChargeTemplateMappingConditionsInput;
}

export interface ChargeTemplateMappingWhereInput {
  AND?: ChargeTemplateMappingWhereInput[] | null;
  OR?: ChargeTemplateMappingWhereInput[] | null;
  NOT?: ChargeTemplateMappingWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  priority?: FloatFilter | null;
  locationId?: UuidFilter | null;
  chargeTemplateId?: UuidFilter | null;
  name?: StringFilter | null;
  notes?: StringNullableFilter | null;
  pending?: BoolFilter | null;
  createdBy?: UserRelationFilter | null;
  lastUpdatedBy?: UserRelationFilter | null;
  location?: LocationRelationFilter | null;
  chargeTemplate?: ChargeTemplateRelationFilter | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionListRelationFilter | null;
}

export interface ChargeTemplateMappingWhereUniqueInput {
  id?: string | null;
}

export interface ChargeTemplateOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ChargeTemplateRelationFilter {
  is?: ChargeTemplateWhereInput | null;
  isNot?: ChargeTemplateWhereInput | null;
}

export interface ChargeTemplateScalarWhereInput {
  AND?: ChargeTemplateScalarWhereInput[] | null;
  OR?: ChargeTemplateScalarWhereInput[] | null;
  NOT?: ChargeTemplateScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  locationId?: UuidFilter | null;
  pending?: BoolFilter | null;
}

export interface ChargeTemplateUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargeTemplatesNestedInput | null;
  chargeTemplateCharges?: ChargeTemplateChargeUpdateManyWithoutChargeTemplateNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutChargeTemplateNestedInput | null;
  estimates?: EstimateUpdateManyWithoutChargeTemplateNestedInput | null;
}

export interface ChargeTemplateUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
}

export interface ChargeTemplateUpdateManyWithWhereWithoutLocationInput {
  where: ChargeTemplateScalarWhereInput;
  data: ChargeTemplateUpdateManyMutationInput;
}

export interface ChargeTemplateUpdateManyWithoutLocationNestedInput {
  create?: ChargeTemplateCreateWithoutLocationInput[] | null;
  connectOrCreate?: ChargeTemplateCreateOrConnectWithoutLocationInput[] | null;
  upsert?: ChargeTemplateUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: ChargeTemplateCreateManyLocationInputEnvelope | null;
  set?: ChargeTemplateWhereUniqueInput[] | null;
  disconnect?: ChargeTemplateWhereUniqueInput[] | null;
  delete?: ChargeTemplateWhereUniqueInput[] | null;
  connect?: ChargeTemplateWhereUniqueInput[] | null;
  update?: ChargeTemplateUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: ChargeTemplateUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: ChargeTemplateScalarWhereInput[] | null;
}

export interface ChargeTemplateUpdateOneRequiredWithoutChargeTemplateChargesNestedInput {
  create?: ChargeTemplateCreateWithoutChargeTemplateChargesInput | null;
  connectOrCreate?: ChargeTemplateCreateOrConnectWithoutChargeTemplateChargesInput | null;
  upsert?: ChargeTemplateUpsertWithoutChargeTemplateChargesInput | null;
  connect?: ChargeTemplateWhereUniqueInput | null;
  update?: ChargeTemplateUpdateWithoutChargeTemplateChargesInput | null;
}

export interface ChargeTemplateUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput {
  create?: ChargeTemplateCreateWithoutChargeTemplateMappingsInput | null;
  connectOrCreate?: ChargeTemplateCreateOrConnectWithoutChargeTemplateMappingsInput | null;
  upsert?: ChargeTemplateUpsertWithoutChargeTemplateMappingsInput | null;
  connect?: ChargeTemplateWhereUniqueInput | null;
  update?: ChargeTemplateUpdateWithoutChargeTemplateMappingsInput | null;
}

export interface ChargeTemplateUpdateOneWithoutEstimatesNestedInput {
  create?: ChargeTemplateCreateWithoutEstimatesInput | null;
  connectOrCreate?: ChargeTemplateCreateOrConnectWithoutEstimatesInput | null;
  upsert?: ChargeTemplateUpsertWithoutEstimatesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ChargeTemplateWhereUniqueInput | null;
  update?: ChargeTemplateUpdateWithoutEstimatesInput | null;
}

export interface ChargeTemplateUpdateWithWhereUniqueWithoutLocationInput {
  where: ChargeTemplateWhereUniqueInput;
  data: ChargeTemplateUpdateWithoutLocationInput;
}

export interface ChargeTemplateUpdateWithoutChargeTemplateChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargeTemplatesNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutChargeTemplateNestedInput | null;
  estimates?: EstimateUpdateManyWithoutChargeTemplateNestedInput | null;
}

export interface ChargeTemplateUpdateWithoutChargeTemplateMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargeTemplatesNestedInput | null;
  chargeTemplateCharges?: ChargeTemplateChargeUpdateManyWithoutChargeTemplateNestedInput | null;
  estimates?: EstimateUpdateManyWithoutChargeTemplateNestedInput | null;
}

export interface ChargeTemplateUpdateWithoutEstimatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargeTemplatesNestedInput | null;
  chargeTemplateCharges?: ChargeTemplateChargeUpdateManyWithoutChargeTemplateNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutChargeTemplateNestedInput | null;
}

export interface ChargeTemplateUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  chargeTemplateCharges?: ChargeTemplateChargeUpdateManyWithoutChargeTemplateNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutChargeTemplateNestedInput | null;
  estimates?: EstimateUpdateManyWithoutChargeTemplateNestedInput | null;
}

export interface ChargeTemplateUpsertWithWhereUniqueWithoutLocationInput {
  where: ChargeTemplateWhereUniqueInput;
  update: ChargeTemplateUpdateWithoutLocationInput;
  create: ChargeTemplateCreateWithoutLocationInput;
}

export interface ChargeTemplateUpsertWithoutChargeTemplateChargesInput {
  update: ChargeTemplateUpdateWithoutChargeTemplateChargesInput;
  create: ChargeTemplateCreateWithoutChargeTemplateChargesInput;
}

export interface ChargeTemplateUpsertWithoutChargeTemplateMappingsInput {
  update: ChargeTemplateUpdateWithoutChargeTemplateMappingsInput;
  create: ChargeTemplateCreateWithoutChargeTemplateMappingsInput;
}

export interface ChargeTemplateUpsertWithoutEstimatesInput {
  update: ChargeTemplateUpdateWithoutEstimatesInput;
  create: ChargeTemplateCreateWithoutEstimatesInput;
}

export interface ChargeTemplateWhereInput {
  AND?: ChargeTemplateWhereInput[] | null;
  OR?: ChargeTemplateWhereInput[] | null;
  NOT?: ChargeTemplateWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  locationId?: UuidFilter | null;
  pending?: BoolFilter | null;
  location?: LocationRelationFilter | null;
  chargeTemplateCharges?: ChargeTemplateChargeListRelationFilter | null;
  chargeTemplateMappings?: ChargeTemplateMappingListRelationFilter | null;
  estimates?: EstimateListRelationFilter | null;
}

export interface ChargeTemplateWhereUniqueInput {
  id?: string | null;
}

export interface ChargeUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  writeOffAmount?: IntFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  units?: NullableFloatFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
}

export interface ChargeUpdateManyWithWhereWithoutBillInput {
  where: ChargeScalarWhereInput;
  data: ChargeUpdateManyMutationInput;
}

export interface ChargeUpdateManyWithWhereWithoutBillingProviderInput {
  where: ChargeScalarWhereInput;
  data: ChargeUpdateManyMutationInput;
}

export interface ChargeUpdateManyWithWhereWithoutChargemasterInput {
  where: ChargeScalarWhereInput;
  data: ChargeUpdateManyMutationInput;
}

export interface ChargeUpdateManyWithWhereWithoutRenderingProviderInput {
  where: ChargeScalarWhereInput;
  data: ChargeUpdateManyMutationInput;
}

export interface ChargeUpdateManyWithoutBillNestedInput {
  create?: ChargeCreateWithoutBillInput[] | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutBillInput[] | null;
  upsert?: ChargeUpsertWithWhereUniqueWithoutBillInput[] | null;
  createMany?: ChargeCreateManyBillInputEnvelope | null;
  set?: ChargeWhereUniqueInput[] | null;
  disconnect?: ChargeWhereUniqueInput[] | null;
  delete?: ChargeWhereUniqueInput[] | null;
  connect?: ChargeWhereUniqueInput[] | null;
  update?: ChargeUpdateWithWhereUniqueWithoutBillInput[] | null;
  updateMany?: ChargeUpdateManyWithWhereWithoutBillInput[] | null;
  deleteMany?: ChargeScalarWhereInput[] | null;
}

export interface ChargeUpdateManyWithoutBillingProviderNestedInput {
  create?: ChargeCreateWithoutBillingProviderInput[] | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutBillingProviderInput[] | null;
  upsert?: ChargeUpsertWithWhereUniqueWithoutBillingProviderInput[] | null;
  createMany?: ChargeCreateManyBillingProviderInputEnvelope | null;
  set?: ChargeWhereUniqueInput[] | null;
  disconnect?: ChargeWhereUniqueInput[] | null;
  delete?: ChargeWhereUniqueInput[] | null;
  connect?: ChargeWhereUniqueInput[] | null;
  update?: ChargeUpdateWithWhereUniqueWithoutBillingProviderInput[] | null;
  updateMany?: ChargeUpdateManyWithWhereWithoutBillingProviderInput[] | null;
  deleteMany?: ChargeScalarWhereInput[] | null;
}

export interface ChargeUpdateManyWithoutChargemasterNestedInput {
  create?: ChargeCreateWithoutChargemasterInput[] | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutChargemasterInput[] | null;
  upsert?: ChargeUpsertWithWhereUniqueWithoutChargemasterInput[] | null;
  createMany?: ChargeCreateManyChargemasterInputEnvelope | null;
  set?: ChargeWhereUniqueInput[] | null;
  disconnect?: ChargeWhereUniqueInput[] | null;
  delete?: ChargeWhereUniqueInput[] | null;
  connect?: ChargeWhereUniqueInput[] | null;
  update?: ChargeUpdateWithWhereUniqueWithoutChargemasterInput[] | null;
  updateMany?: ChargeUpdateManyWithWhereWithoutChargemasterInput[] | null;
  deleteMany?: ChargeScalarWhereInput[] | null;
}

export interface ChargeUpdateManyWithoutRenderingProviderNestedInput {
  create?: ChargeCreateWithoutRenderingProviderInput[] | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutRenderingProviderInput[] | null;
  upsert?: ChargeUpsertWithWhereUniqueWithoutRenderingProviderInput[] | null;
  createMany?: ChargeCreateManyRenderingProviderInputEnvelope | null;
  set?: ChargeWhereUniqueInput[] | null;
  disconnect?: ChargeWhereUniqueInput[] | null;
  delete?: ChargeWhereUniqueInput[] | null;
  connect?: ChargeWhereUniqueInput[] | null;
  update?: ChargeUpdateWithWhereUniqueWithoutRenderingProviderInput[] | null;
  updateMany?: ChargeUpdateManyWithWhereWithoutRenderingProviderInput[] | null;
  deleteMany?: ChargeScalarWhereInput[] | null;
}

export interface ChargeUpdateOneRequiredWithoutInsuranceBillableChargesNestedInput {
  create?: ChargeCreateWithoutInsuranceBillableChargesInput | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutInsuranceBillableChargesInput | null;
  upsert?: ChargeUpsertWithoutInsuranceBillableChargesInput | null;
  connect?: ChargeWhereUniqueInput | null;
  update?: ChargeUpdateWithoutInsuranceBillableChargesInput | null;
}

export interface ChargeUpdateOneRequiredWithoutPaymentIntentChargesNestedInput {
  create?: ChargeCreateWithoutPaymentIntentChargesInput | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutPaymentIntentChargesInput | null;
  upsert?: ChargeUpsertWithoutPaymentIntentChargesInput | null;
  connect?: ChargeWhereUniqueInput | null;
  update?: ChargeUpdateWithoutPaymentIntentChargesInput | null;
}

export interface ChargeUpdateOneWithoutClaimLineNestedInput {
  create?: ChargeCreateWithoutClaimLineInput | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutClaimLineInput | null;
  upsert?: ChargeUpsertWithoutClaimLineInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ChargeWhereUniqueInput | null;
  update?: ChargeUpdateWithoutClaimLineInput | null;
}

export interface ChargeUpdateOneWithoutEstimatedChargesNestedInput {
  create?: ChargeCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutEstimatedChargesInput | null;
  upsert?: ChargeUpsertWithoutEstimatedChargesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ChargeWhereUniqueInput | null;
  update?: ChargeUpdateWithoutEstimatedChargesInput | null;
}

export interface ChargeUpdateOneWithoutTransactionNestedInput {
  create?: ChargeCreateWithoutTransactionInput | null;
  connectOrCreate?: ChargeCreateOrConnectWithoutTransactionInput | null;
  upsert?: ChargeUpsertWithoutTransactionInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ChargeWhereUniqueInput | null;
  update?: ChargeUpdateWithoutTransactionInput | null;
}

export interface ChargeUpdateWithWhereUniqueWithoutBillInput {
  where: ChargeWhereUniqueInput;
  data: ChargeUpdateWithoutBillInput;
}

export interface ChargeUpdateWithWhereUniqueWithoutBillingProviderInput {
  where: ChargeWhereUniqueInput;
  data: ChargeUpdateWithoutBillingProviderInput;
}

export interface ChargeUpdateWithWhereUniqueWithoutChargemasterInput {
  where: ChargeWhereUniqueInput;
  data: ChargeUpdateWithoutChargemasterInput;
}

export interface ChargeUpdateWithWhereUniqueWithoutRenderingProviderInput {
  where: ChargeWhereUniqueInput;
  data: ChargeUpdateWithoutRenderingProviderInput;
}

export interface ChargeUpdateWithoutBillInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  writeOffAmount?: IntFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  units?: NullableFloatFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutChargeNestedInput | null;
  chargemaster?: ChargemasterUpdateOneWithoutChargesNestedInput | null;
  claimLine?: ClaimLineUpdateManyWithoutChargeNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutChargeNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutChargeNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledChargesNestedInput | null;
  renderingProvider?: ProviderUpdateOneWithoutRenderedChargesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargeNestedInput | null;
}

export interface ChargeUpdateWithoutBillingProviderInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  writeOffAmount?: IntFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  units?: NullableFloatFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutChargeNestedInput | null;
  chargemaster?: ChargemasterUpdateOneWithoutChargesNestedInput | null;
  claimLine?: ClaimLineUpdateManyWithoutChargeNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutChargeNestedInput | null;
  bill?: BillUpdateOneWithoutChargesNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutChargeNestedInput | null;
  renderingProvider?: ProviderUpdateOneWithoutRenderedChargesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargeNestedInput | null;
}

export interface ChargeUpdateWithoutChargemasterInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  writeOffAmount?: IntFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  units?: NullableFloatFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutChargeNestedInput | null;
  claimLine?: ClaimLineUpdateManyWithoutChargeNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutChargeNestedInput | null;
  bill?: BillUpdateOneWithoutChargesNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutChargeNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledChargesNestedInput | null;
  renderingProvider?: ProviderUpdateOneWithoutRenderedChargesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargeNestedInput | null;
}

export interface ChargeUpdateWithoutClaimLineInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  writeOffAmount?: IntFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  units?: NullableFloatFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutChargeNestedInput | null;
  chargemaster?: ChargemasterUpdateOneWithoutChargesNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutChargeNestedInput | null;
  bill?: BillUpdateOneWithoutChargesNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutChargeNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledChargesNestedInput | null;
  renderingProvider?: ProviderUpdateOneWithoutRenderedChargesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargeNestedInput | null;
}

export interface ChargeUpdateWithoutEstimatedChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  writeOffAmount?: IntFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  units?: NullableFloatFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutChargeNestedInput | null;
  chargemaster?: ChargemasterUpdateOneWithoutChargesNestedInput | null;
  claimLine?: ClaimLineUpdateManyWithoutChargeNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutChargeNestedInput | null;
  bill?: BillUpdateOneWithoutChargesNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutChargeNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledChargesNestedInput | null;
  renderingProvider?: ProviderUpdateOneWithoutRenderedChargesNestedInput | null;
}

export interface ChargeUpdateWithoutInsuranceBillableChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  writeOffAmount?: IntFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  units?: NullableFloatFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutChargeNestedInput | null;
  chargemaster?: ChargemasterUpdateOneWithoutChargesNestedInput | null;
  claimLine?: ClaimLineUpdateManyWithoutChargeNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutChargeNestedInput | null;
  bill?: BillUpdateOneWithoutChargesNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledChargesNestedInput | null;
  renderingProvider?: ProviderUpdateOneWithoutRenderedChargesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargeNestedInput | null;
}

export interface ChargeUpdateWithoutPaymentIntentChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  writeOffAmount?: IntFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  units?: NullableFloatFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutChargeNestedInput | null;
  chargemaster?: ChargemasterUpdateOneWithoutChargesNestedInput | null;
  claimLine?: ClaimLineUpdateManyWithoutChargeNestedInput | null;
  bill?: BillUpdateOneWithoutChargesNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutChargeNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledChargesNestedInput | null;
  renderingProvider?: ProviderUpdateOneWithoutRenderedChargesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargeNestedInput | null;
}

export interface ChargeUpdateWithoutRenderingProviderInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  writeOffAmount?: IntFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  units?: NullableFloatFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutChargeNestedInput | null;
  chargemaster?: ChargemasterUpdateOneWithoutChargesNestedInput | null;
  claimLine?: ClaimLineUpdateManyWithoutChargeNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutChargeNestedInput | null;
  bill?: BillUpdateOneWithoutChargesNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutChargeNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledChargesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargeNestedInput | null;
}

export interface ChargeUpdateWithoutTransactionInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  writeOffAmount?: IntFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  units?: NullableFloatFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  chargemaster?: ChargemasterUpdateOneWithoutChargesNestedInput | null;
  claimLine?: ClaimLineUpdateManyWithoutChargeNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutChargeNestedInput | null;
  bill?: BillUpdateOneWithoutChargesNestedInput | null;
  insuranceBillableCharges?: InsuranceBillableChargeUpdateManyWithoutChargeNestedInput | null;
  billingProvider?: ProviderUpdateOneWithoutBilledChargesNestedInput | null;
  renderingProvider?: ProviderUpdateOneWithoutRenderedChargesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargeNestedInput | null;
}

export interface ChargeUpsertWithWhereUniqueWithoutBillInput {
  where: ChargeWhereUniqueInput;
  update: ChargeUpdateWithoutBillInput;
  create: ChargeCreateWithoutBillInput;
}

export interface ChargeUpsertWithWhereUniqueWithoutBillingProviderInput {
  where: ChargeWhereUniqueInput;
  update: ChargeUpdateWithoutBillingProviderInput;
  create: ChargeCreateWithoutBillingProviderInput;
}

export interface ChargeUpsertWithWhereUniqueWithoutChargemasterInput {
  where: ChargeWhereUniqueInput;
  update: ChargeUpdateWithoutChargemasterInput;
  create: ChargeCreateWithoutChargemasterInput;
}

export interface ChargeUpsertWithWhereUniqueWithoutRenderingProviderInput {
  where: ChargeWhereUniqueInput;
  update: ChargeUpdateWithoutRenderingProviderInput;
  create: ChargeCreateWithoutRenderingProviderInput;
}

export interface ChargeUpsertWithoutClaimLineInput {
  update: ChargeUpdateWithoutClaimLineInput;
  create: ChargeCreateWithoutClaimLineInput;
}

export interface ChargeUpsertWithoutEstimatedChargesInput {
  update: ChargeUpdateWithoutEstimatedChargesInput;
  create: ChargeCreateWithoutEstimatedChargesInput;
}

export interface ChargeUpsertWithoutInsuranceBillableChargesInput {
  update: ChargeUpdateWithoutInsuranceBillableChargesInput;
  create: ChargeCreateWithoutInsuranceBillableChargesInput;
}

export interface ChargeUpsertWithoutPaymentIntentChargesInput {
  update: ChargeUpdateWithoutPaymentIntentChargesInput;
  create: ChargeCreateWithoutPaymentIntentChargesInput;
}

export interface ChargeUpsertWithoutTransactionInput {
  update: ChargeUpdateWithoutTransactionInput;
  create: ChargeCreateWithoutTransactionInput;
}

export interface ChargeWhereInput {
  AND?: ChargeWhereInput[] | null;
  OR?: ChargeWhereInput[] | null;
  NOT?: ChargeWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  chargeAmount?: IntFilter | null;
  patientAmount?: IntFilter | null;
  insuranceAmount?: IntFilter | null;
  writeOffAmount?: IntFilter | null;
  allowedAmount?: IntFilter | null;
  copayAmount?: IntFilter | null;
  deductibleAmount?: IntFilter | null;
  coinsuranceAmount?: IntFilter | null;
  otherAmount?: IntFilter | null;
  taxAmount?: IntNullableFilter | null;
  units?: FloatNullableFilter | null;
  transactionId?: UuidFilter | null;
  customCode?: StringNullableFilter | null;
  modifier1?: StringFilter | null;
  modifier2?: StringFilter | null;
  modifier3?: StringFilter | null;
  modifier4?: StringFilter | null;
  chargemasterId?: UuidNullableFilter | null;
  billId?: UuidNullableFilter | null;
  billingProviderId?: UuidNullableFilter | null;
  renderingProviderId?: UuidNullableFilter | null;
  transaction?: TransactionRelationFilter | null;
  chargemaster?: ChargemasterRelationFilter | null;
  claimLine?: ClaimLineListRelationFilter | null;
  paymentIntentCharges?: PaymentIntentChargeListRelationFilter | null;
  bill?: BillRelationFilter | null;
  insuranceBillableCharges?: InsuranceBillableChargeListRelationFilter | null;
  billingProvider?: ProviderRelationFilter | null;
  renderingProvider?: ProviderRelationFilter | null;
  estimatedCharges?: EstimatedChargeListRelationFilter | null;
}

export interface ChargeWhereUniqueInput {
  id?: string | null;
  transactionId?: string | null;
}

export interface ChargemasterCreateManyChargemasterGroupInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUsedAt?: any | null;
  locationId: string;
  amount?: number | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
}

export interface ChargemasterCreateManyChargemasterGroupInputEnvelope {
  data: ChargemasterCreateManyChargemasterGroupInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargemasterCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUsedAt?: any | null;
  amount?: number | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  chargemasterGroupId?: string | null;
}

export interface ChargemasterCreateManyLocationInputEnvelope {
  data: ChargemasterCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargemasterCreateNestedManyWithoutChargemasterGroupInput {
  create?: ChargemasterCreateWithoutChargemasterGroupInput[] | null;
  connectOrCreate?: ChargemasterCreateOrConnectWithoutChargemasterGroupInput[] | null;
  createMany?: ChargemasterCreateManyChargemasterGroupInputEnvelope | null;
  connect?: ChargemasterWhereUniqueInput[] | null;
}

export interface ChargemasterCreateNestedManyWithoutLocationInput {
  create?: ChargemasterCreateWithoutLocationInput[] | null;
  connectOrCreate?: ChargemasterCreateOrConnectWithoutLocationInput[] | null;
  createMany?: ChargemasterCreateManyLocationInputEnvelope | null;
  connect?: ChargemasterWhereUniqueInput[] | null;
}

export interface ChargemasterCreateNestedOneWithoutChargesInput {
  create?: ChargemasterCreateWithoutChargesInput | null;
  connectOrCreate?: ChargemasterCreateOrConnectWithoutChargesInput | null;
  connect?: ChargemasterWhereUniqueInput | null;
}

export interface ChargemasterCreateNestedOneWithoutEstimatedChargesInput {
  create?: ChargemasterCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: ChargemasterCreateOrConnectWithoutEstimatedChargesInput | null;
  connect?: ChargemasterWhereUniqueInput | null;
}

export interface ChargemasterCreateNestedOneWithoutPrimaryChargemasterGroupInput {
  create?: ChargemasterCreateWithoutPrimaryChargemasterGroupInput | null;
  connectOrCreate?: ChargemasterCreateOrConnectWithoutPrimaryChargemasterGroupInput | null;
  connect?: ChargemasterWhereUniqueInput | null;
}

export interface ChargemasterCreateOrConnectWithoutChargemasterGroupInput {
  where: ChargemasterWhereUniqueInput;
  create: ChargemasterCreateWithoutChargemasterGroupInput;
}

export interface ChargemasterCreateOrConnectWithoutChargesInput {
  where: ChargemasterWhereUniqueInput;
  create: ChargemasterCreateWithoutChargesInput;
}

export interface ChargemasterCreateOrConnectWithoutEstimatedChargesInput {
  where: ChargemasterWhereUniqueInput;
  create: ChargemasterCreateWithoutEstimatedChargesInput;
}

export interface ChargemasterCreateOrConnectWithoutLocationInput {
  where: ChargemasterWhereUniqueInput;
  create: ChargemasterCreateWithoutLocationInput;
}

export interface ChargemasterCreateOrConnectWithoutPrimaryChargemasterGroupInput {
  where: ChargemasterWhereUniqueInput;
  create: ChargemasterCreateWithoutPrimaryChargemasterGroupInput;
}

export interface ChargemasterCreateWithoutChargemasterGroupInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUsedAt?: any | null;
  amount?: number | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  location: LocationCreateNestedOneWithoutChargemastersInput;
  primaryChargemasterGroup?: ChargemasterGroupCreateNestedOneWithoutPrimaryChargemasterInput | null;
  charges?: ChargeCreateNestedManyWithoutChargemasterInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargemasterInput | null;
}

export interface ChargemasterCreateWithoutChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUsedAt?: any | null;
  amount?: number | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  location: LocationCreateNestedOneWithoutChargemastersInput;
  chargemasterGroup?: ChargemasterGroupCreateNestedOneWithoutChargemastersInput | null;
  primaryChargemasterGroup?: ChargemasterGroupCreateNestedOneWithoutPrimaryChargemasterInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargemasterInput | null;
}

export interface ChargemasterCreateWithoutEstimatedChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUsedAt?: any | null;
  amount?: number | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  location: LocationCreateNestedOneWithoutChargemastersInput;
  chargemasterGroup?: ChargemasterGroupCreateNestedOneWithoutChargemastersInput | null;
  primaryChargemasterGroup?: ChargemasterGroupCreateNestedOneWithoutPrimaryChargemasterInput | null;
  charges?: ChargeCreateNestedManyWithoutChargemasterInput | null;
}

export interface ChargemasterCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUsedAt?: any | null;
  amount?: number | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  chargemasterGroup?: ChargemasterGroupCreateNestedOneWithoutChargemastersInput | null;
  primaryChargemasterGroup?: ChargemasterGroupCreateNestedOneWithoutPrimaryChargemasterInput | null;
  charges?: ChargeCreateNestedManyWithoutChargemasterInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargemasterInput | null;
}

export interface ChargemasterCreateWithoutPrimaryChargemasterGroupInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUsedAt?: any | null;
  amount?: number | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  location: LocationCreateNestedOneWithoutChargemastersInput;
  chargemasterGroup?: ChargemasterGroupCreateNestedOneWithoutChargemastersInput | null;
  charges?: ChargeCreateNestedManyWithoutChargemasterInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutChargemasterInput | null;
}

export interface ChargemasterGroupCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  amount?: number | null;
  cashPay?: boolean | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  primaryChargemasterId: string;
}

export interface ChargemasterGroupCreateManyLocationInputEnvelope {
  data: ChargemasterGroupCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface ChargemasterGroupCreateNestedManyWithoutLocationInput {
  create?: ChargemasterGroupCreateWithoutLocationInput[] | null;
  connectOrCreate?: ChargemasterGroupCreateOrConnectWithoutLocationInput[] | null;
  createMany?: ChargemasterGroupCreateManyLocationInputEnvelope | null;
  connect?: ChargemasterGroupWhereUniqueInput[] | null;
}

export interface ChargemasterGroupCreateNestedOneWithoutChargeTemplateChargesInput {
  create?: ChargemasterGroupCreateWithoutChargeTemplateChargesInput | null;
  connectOrCreate?: ChargemasterGroupCreateOrConnectWithoutChargeTemplateChargesInput | null;
  connect?: ChargemasterGroupWhereUniqueInput | null;
}

export interface ChargemasterGroupCreateNestedOneWithoutChargemastersInput {
  create?: ChargemasterGroupCreateWithoutChargemastersInput | null;
  connectOrCreate?: ChargemasterGroupCreateOrConnectWithoutChargemastersInput | null;
  connect?: ChargemasterGroupWhereUniqueInput | null;
}

export interface ChargemasterGroupCreateNestedOneWithoutPrimaryChargemasterInput {
  create?: ChargemasterGroupCreateWithoutPrimaryChargemasterInput | null;
  connectOrCreate?: ChargemasterGroupCreateOrConnectWithoutPrimaryChargemasterInput | null;
  connect?: ChargemasterGroupWhereUniqueInput | null;
}

export interface ChargemasterGroupCreateNestedOneWithoutScheduledServiceFeesInput {
  create?: ChargemasterGroupCreateWithoutScheduledServiceFeesInput | null;
  connectOrCreate?: ChargemasterGroupCreateOrConnectWithoutScheduledServiceFeesInput | null;
  connect?: ChargemasterGroupWhereUniqueInput | null;
}

export interface ChargemasterGroupCreateOrConnectWithoutChargeTemplateChargesInput {
  where: ChargemasterGroupWhereUniqueInput;
  create: ChargemasterGroupCreateWithoutChargeTemplateChargesInput;
}

export interface ChargemasterGroupCreateOrConnectWithoutChargemastersInput {
  where: ChargemasterGroupWhereUniqueInput;
  create: ChargemasterGroupCreateWithoutChargemastersInput;
}

export interface ChargemasterGroupCreateOrConnectWithoutLocationInput {
  where: ChargemasterGroupWhereUniqueInput;
  create: ChargemasterGroupCreateWithoutLocationInput;
}

export interface ChargemasterGroupCreateOrConnectWithoutPrimaryChargemasterInput {
  where: ChargemasterGroupWhereUniqueInput;
  create: ChargemasterGroupCreateWithoutPrimaryChargemasterInput;
}

export interface ChargemasterGroupCreateOrConnectWithoutScheduledServiceFeesInput {
  where: ChargemasterGroupWhereUniqueInput;
  create: ChargemasterGroupCreateWithoutScheduledServiceFeesInput;
}

export interface ChargemasterGroupCreateWithoutChargeTemplateChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  amount?: number | null;
  cashPay?: boolean | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  location: LocationCreateNestedOneWithoutChargemasterGroupsInput;
  primaryChargemaster: ChargemasterCreateNestedOneWithoutPrimaryChargemasterGroupInput;
  scheduledServiceFees?: ScheduledServiceFeeCreateNestedManyWithoutChargemasterGroupInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutChargemasterGroupInput | null;
}

export interface ChargemasterGroupCreateWithoutChargemastersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  amount?: number | null;
  cashPay?: boolean | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  location: LocationCreateNestedOneWithoutChargemasterGroupsInput;
  primaryChargemaster: ChargemasterCreateNestedOneWithoutPrimaryChargemasterGroupInput;
  scheduledServiceFees?: ScheduledServiceFeeCreateNestedManyWithoutChargemasterGroupInput | null;
  chargeTemplateCharges?: ChargeTemplateChargeCreateNestedManyWithoutChargemasterGroupInput | null;
}

export interface ChargemasterGroupCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  amount?: number | null;
  cashPay?: boolean | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  primaryChargemaster: ChargemasterCreateNestedOneWithoutPrimaryChargemasterGroupInput;
  scheduledServiceFees?: ScheduledServiceFeeCreateNestedManyWithoutChargemasterGroupInput | null;
  chargeTemplateCharges?: ChargeTemplateChargeCreateNestedManyWithoutChargemasterGroupInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutChargemasterGroupInput | null;
}

export interface ChargemasterGroupCreateWithoutPrimaryChargemasterInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  amount?: number | null;
  cashPay?: boolean | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  location: LocationCreateNestedOneWithoutChargemasterGroupsInput;
  scheduledServiceFees?: ScheduledServiceFeeCreateNestedManyWithoutChargemasterGroupInput | null;
  chargeTemplateCharges?: ChargeTemplateChargeCreateNestedManyWithoutChargemasterGroupInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutChargemasterGroupInput | null;
}

export interface ChargemasterGroupCreateWithoutScheduledServiceFeesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  amount?: number | null;
  cashPay?: boolean | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description?: string | null;
  location: LocationCreateNestedOneWithoutChargemasterGroupsInput;
  primaryChargemaster: ChargemasterCreateNestedOneWithoutPrimaryChargemasterGroupInput;
  chargeTemplateCharges?: ChargeTemplateChargeCreateNestedManyWithoutChargemasterGroupInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutChargemasterGroupInput | null;
}

export interface ChargemasterGroupListRelationFilter {
  every?: ChargemasterGroupWhereInput | null;
  some?: ChargemasterGroupWhereInput | null;
  none?: ChargemasterGroupWhereInput | null;
}

export interface ChargemasterGroupLocationIdCodeModifier1Modifier2Modifier3Modifier4DescriptionCompoundUniqueInput {
  locationId: string;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description: string;
}

export interface ChargemasterGroupOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ChargemasterGroupRelationFilter {
  is?: ChargemasterGroupWhereInput | null;
  isNot?: ChargemasterGroupWhereInput | null;
}

export interface ChargemasterGroupScalarWhereInput {
  AND?: ChargemasterGroupScalarWhereInput[] | null;
  OR?: ChargemasterGroupScalarWhereInput[] | null;
  NOT?: ChargemasterGroupScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  archivedAt?: DateTimeNullableFilter | null;
  locationId?: UuidFilter | null;
  amount?: IntFilter | null;
  cashPay?: BoolFilter | null;
  code?: StringFilter | null;
  modifier1?: StringFilter | null;
  modifier2?: StringFilter | null;
  modifier3?: StringFilter | null;
  modifier4?: StringFilter | null;
  description?: StringFilter | null;
  primaryChargemasterId?: UuidFilter | null;
}

export interface ChargemasterGroupUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  cashPay?: BoolFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
}

export interface ChargemasterGroupUpdateManyWithWhereWithoutLocationInput {
  where: ChargemasterGroupScalarWhereInput;
  data: ChargemasterGroupUpdateManyMutationInput;
}

export interface ChargemasterGroupUpdateManyWithoutLocationNestedInput {
  create?: ChargemasterGroupCreateWithoutLocationInput[] | null;
  connectOrCreate?: ChargemasterGroupCreateOrConnectWithoutLocationInput[] | null;
  upsert?: ChargemasterGroupUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: ChargemasterGroupCreateManyLocationInputEnvelope | null;
  set?: ChargemasterGroupWhereUniqueInput[] | null;
  disconnect?: ChargemasterGroupWhereUniqueInput[] | null;
  delete?: ChargemasterGroupWhereUniqueInput[] | null;
  connect?: ChargemasterGroupWhereUniqueInput[] | null;
  update?: ChargemasterGroupUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: ChargemasterGroupUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: ChargemasterGroupScalarWhereInput[] | null;
}

export interface ChargemasterGroupUpdateOneRequiredWithoutChargeTemplateChargesNestedInput {
  create?: ChargemasterGroupCreateWithoutChargeTemplateChargesInput | null;
  connectOrCreate?: ChargemasterGroupCreateOrConnectWithoutChargeTemplateChargesInput | null;
  upsert?: ChargemasterGroupUpsertWithoutChargeTemplateChargesInput | null;
  connect?: ChargemasterGroupWhereUniqueInput | null;
  update?: ChargemasterGroupUpdateWithoutChargeTemplateChargesInput | null;
}

export interface ChargemasterGroupUpdateOneRequiredWithoutScheduledServiceFeesNestedInput {
  create?: ChargemasterGroupCreateWithoutScheduledServiceFeesInput | null;
  connectOrCreate?: ChargemasterGroupCreateOrConnectWithoutScheduledServiceFeesInput | null;
  upsert?: ChargemasterGroupUpsertWithoutScheduledServiceFeesInput | null;
  connect?: ChargemasterGroupWhereUniqueInput | null;
  update?: ChargemasterGroupUpdateWithoutScheduledServiceFeesInput | null;
}

export interface ChargemasterGroupUpdateOneWithoutChargemastersNestedInput {
  create?: ChargemasterGroupCreateWithoutChargemastersInput | null;
  connectOrCreate?: ChargemasterGroupCreateOrConnectWithoutChargemastersInput | null;
  upsert?: ChargemasterGroupUpsertWithoutChargemastersInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ChargemasterGroupWhereUniqueInput | null;
  update?: ChargemasterGroupUpdateWithoutChargemastersInput | null;
}

export interface ChargemasterGroupUpdateOneWithoutPrimaryChargemasterNestedInput {
  create?: ChargemasterGroupCreateWithoutPrimaryChargemasterInput | null;
  connectOrCreate?: ChargemasterGroupCreateOrConnectWithoutPrimaryChargemasterInput | null;
  upsert?: ChargemasterGroupUpsertWithoutPrimaryChargemasterInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ChargemasterGroupWhereUniqueInput | null;
  update?: ChargemasterGroupUpdateWithoutPrimaryChargemasterInput | null;
}

export interface ChargemasterGroupUpdateWithWhereUniqueWithoutLocationInput {
  where: ChargemasterGroupWhereUniqueInput;
  data: ChargemasterGroupUpdateWithoutLocationInput;
}

export interface ChargemasterGroupUpdateWithoutChargeTemplateChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  cashPay?: BoolFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargemasterGroupsNestedInput | null;
  primaryChargemaster?: ChargemasterUpdateOneRequiredWithoutPrimaryChargemasterGroupNestedInput | null;
  scheduledServiceFees?: ScheduledServiceFeeUpdateManyWithoutChargemasterGroupNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutChargemasterGroupNestedInput | null;
}

export interface ChargemasterGroupUpdateWithoutChargemastersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  cashPay?: BoolFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargemasterGroupsNestedInput | null;
  primaryChargemaster?: ChargemasterUpdateOneRequiredWithoutPrimaryChargemasterGroupNestedInput | null;
  scheduledServiceFees?: ScheduledServiceFeeUpdateManyWithoutChargemasterGroupNestedInput | null;
  chargeTemplateCharges?: ChargeTemplateChargeUpdateManyWithoutChargemasterGroupNestedInput | null;
}

export interface ChargemasterGroupUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  cashPay?: BoolFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
  primaryChargemaster?: ChargemasterUpdateOneRequiredWithoutPrimaryChargemasterGroupNestedInput | null;
  scheduledServiceFees?: ScheduledServiceFeeUpdateManyWithoutChargemasterGroupNestedInput | null;
  chargeTemplateCharges?: ChargeTemplateChargeUpdateManyWithoutChargemasterGroupNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutChargemasterGroupNestedInput | null;
}

export interface ChargemasterGroupUpdateWithoutPrimaryChargemasterInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  cashPay?: BoolFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargemasterGroupsNestedInput | null;
  scheduledServiceFees?: ScheduledServiceFeeUpdateManyWithoutChargemasterGroupNestedInput | null;
  chargeTemplateCharges?: ChargeTemplateChargeUpdateManyWithoutChargemasterGroupNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutChargemasterGroupNestedInput | null;
}

export interface ChargemasterGroupUpdateWithoutScheduledServiceFeesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  cashPay?: BoolFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargemasterGroupsNestedInput | null;
  primaryChargemaster?: ChargemasterUpdateOneRequiredWithoutPrimaryChargemasterGroupNestedInput | null;
  chargeTemplateCharges?: ChargeTemplateChargeUpdateManyWithoutChargemasterGroupNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutChargemasterGroupNestedInput | null;
}

export interface ChargemasterGroupUpsertWithWhereUniqueWithoutLocationInput {
  where: ChargemasterGroupWhereUniqueInput;
  update: ChargemasterGroupUpdateWithoutLocationInput;
  create: ChargemasterGroupCreateWithoutLocationInput;
}

export interface ChargemasterGroupUpsertWithoutChargeTemplateChargesInput {
  update: ChargemasterGroupUpdateWithoutChargeTemplateChargesInput;
  create: ChargemasterGroupCreateWithoutChargeTemplateChargesInput;
}

export interface ChargemasterGroupUpsertWithoutChargemastersInput {
  update: ChargemasterGroupUpdateWithoutChargemastersInput;
  create: ChargemasterGroupCreateWithoutChargemastersInput;
}

export interface ChargemasterGroupUpsertWithoutPrimaryChargemasterInput {
  update: ChargemasterGroupUpdateWithoutPrimaryChargemasterInput;
  create: ChargemasterGroupCreateWithoutPrimaryChargemasterInput;
}

export interface ChargemasterGroupUpsertWithoutScheduledServiceFeesInput {
  update: ChargemasterGroupUpdateWithoutScheduledServiceFeesInput;
  create: ChargemasterGroupCreateWithoutScheduledServiceFeesInput;
}

export interface ChargemasterGroupWhereInput {
  AND?: ChargemasterGroupWhereInput[] | null;
  OR?: ChargemasterGroupWhereInput[] | null;
  NOT?: ChargemasterGroupWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  archivedAt?: DateTimeNullableFilter | null;
  locationId?: UuidFilter | null;
  amount?: IntFilter | null;
  cashPay?: BoolFilter | null;
  code?: StringFilter | null;
  modifier1?: StringFilter | null;
  modifier2?: StringFilter | null;
  modifier3?: StringFilter | null;
  modifier4?: StringFilter | null;
  description?: StringFilter | null;
  primaryChargemasterId?: UuidFilter | null;
  location?: LocationRelationFilter | null;
  primaryChargemaster?: ChargemasterRelationFilter | null;
  scheduledServiceFees?: ScheduledServiceFeeListRelationFilter | null;
  chargeTemplateCharges?: ChargeTemplateChargeListRelationFilter | null;
  chargemasters?: ChargemasterListRelationFilter | null;
}

export interface ChargemasterGroupWhereUniqueInput {
  id?: string | null;
  primaryChargemasterId?: string | null;
  locationId_code_modifier1_modifier2_modifier3_modifier4_description?: ChargemasterGroupLocationIdCodeModifier1Modifier2Modifier3Modifier4DescriptionCompoundUniqueInput | null;
}

export interface ChargemasterListRelationFilter {
  every?: ChargemasterWhereInput | null;
  some?: ChargemasterWhereInput | null;
  none?: ChargemasterWhereInput | null;
}

export interface ChargemasterLocationIdCodeModifier1Modifier2Modifier3Modifier4DescriptionCompoundUniqueInput {
  locationId: string;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  description: string;
}

export interface ChargemasterOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ChargemasterRelationFilter {
  is?: ChargemasterWhereInput | null;
  isNot?: ChargemasterWhereInput | null;
}

export interface ChargemasterScalarWhereInput {
  AND?: ChargemasterScalarWhereInput[] | null;
  OR?: ChargemasterScalarWhereInput[] | null;
  NOT?: ChargemasterScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  lastUsedAt?: DateTimeNullableFilter | null;
  locationId?: UuidFilter | null;
  amount?: IntFilter | null;
  code?: StringFilter | null;
  modifier1?: StringFilter | null;
  modifier2?: StringFilter | null;
  modifier3?: StringFilter | null;
  modifier4?: StringFilter | null;
  description?: StringFilter | null;
  chargemasterGroupId?: UuidNullableFilter | null;
}

export interface ChargemasterUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  lastUsedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
}

export interface ChargemasterUpdateManyWithWhereWithoutChargemasterGroupInput {
  where: ChargemasterScalarWhereInput;
  data: ChargemasterUpdateManyMutationInput;
}

export interface ChargemasterUpdateManyWithWhereWithoutLocationInput {
  where: ChargemasterScalarWhereInput;
  data: ChargemasterUpdateManyMutationInput;
}

export interface ChargemasterUpdateManyWithoutChargemasterGroupNestedInput {
  create?: ChargemasterCreateWithoutChargemasterGroupInput[] | null;
  connectOrCreate?: ChargemasterCreateOrConnectWithoutChargemasterGroupInput[] | null;
  upsert?: ChargemasterUpsertWithWhereUniqueWithoutChargemasterGroupInput[] | null;
  createMany?: ChargemasterCreateManyChargemasterGroupInputEnvelope | null;
  set?: ChargemasterWhereUniqueInput[] | null;
  disconnect?: ChargemasterWhereUniqueInput[] | null;
  delete?: ChargemasterWhereUniqueInput[] | null;
  connect?: ChargemasterWhereUniqueInput[] | null;
  update?: ChargemasterUpdateWithWhereUniqueWithoutChargemasterGroupInput[] | null;
  updateMany?: ChargemasterUpdateManyWithWhereWithoutChargemasterGroupInput[] | null;
  deleteMany?: ChargemasterScalarWhereInput[] | null;
}

export interface ChargemasterUpdateManyWithoutLocationNestedInput {
  create?: ChargemasterCreateWithoutLocationInput[] | null;
  connectOrCreate?: ChargemasterCreateOrConnectWithoutLocationInput[] | null;
  upsert?: ChargemasterUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: ChargemasterCreateManyLocationInputEnvelope | null;
  set?: ChargemasterWhereUniqueInput[] | null;
  disconnect?: ChargemasterWhereUniqueInput[] | null;
  delete?: ChargemasterWhereUniqueInput[] | null;
  connect?: ChargemasterWhereUniqueInput[] | null;
  update?: ChargemasterUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: ChargemasterUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: ChargemasterScalarWhereInput[] | null;
}

export interface ChargemasterUpdateOneRequiredWithoutEstimatedChargesNestedInput {
  create?: ChargemasterCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: ChargemasterCreateOrConnectWithoutEstimatedChargesInput | null;
  upsert?: ChargemasterUpsertWithoutEstimatedChargesInput | null;
  connect?: ChargemasterWhereUniqueInput | null;
  update?: ChargemasterUpdateWithoutEstimatedChargesInput | null;
}

export interface ChargemasterUpdateOneRequiredWithoutPrimaryChargemasterGroupNestedInput {
  create?: ChargemasterCreateWithoutPrimaryChargemasterGroupInput | null;
  connectOrCreate?: ChargemasterCreateOrConnectWithoutPrimaryChargemasterGroupInput | null;
  upsert?: ChargemasterUpsertWithoutPrimaryChargemasterGroupInput | null;
  connect?: ChargemasterWhereUniqueInput | null;
  update?: ChargemasterUpdateWithoutPrimaryChargemasterGroupInput | null;
}

export interface ChargemasterUpdateOneWithoutChargesNestedInput {
  create?: ChargemasterCreateWithoutChargesInput | null;
  connectOrCreate?: ChargemasterCreateOrConnectWithoutChargesInput | null;
  upsert?: ChargemasterUpsertWithoutChargesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ChargemasterWhereUniqueInput | null;
  update?: ChargemasterUpdateWithoutChargesInput | null;
}

export interface ChargemasterUpdateWithWhereUniqueWithoutChargemasterGroupInput {
  where: ChargemasterWhereUniqueInput;
  data: ChargemasterUpdateWithoutChargemasterGroupInput;
}

export interface ChargemasterUpdateWithWhereUniqueWithoutLocationInput {
  where: ChargemasterWhereUniqueInput;
  data: ChargemasterUpdateWithoutLocationInput;
}

export interface ChargemasterUpdateWithoutChargemasterGroupInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  lastUsedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargemastersNestedInput | null;
  primaryChargemasterGroup?: ChargemasterGroupUpdateOneWithoutPrimaryChargemasterNestedInput | null;
  charges?: ChargeUpdateManyWithoutChargemasterNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargemasterNestedInput | null;
}

export interface ChargemasterUpdateWithoutChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  lastUsedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargemastersNestedInput | null;
  chargemasterGroup?: ChargemasterGroupUpdateOneWithoutChargemastersNestedInput | null;
  primaryChargemasterGroup?: ChargemasterGroupUpdateOneWithoutPrimaryChargemasterNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargemasterNestedInput | null;
}

export interface ChargemasterUpdateWithoutEstimatedChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  lastUsedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargemastersNestedInput | null;
  chargemasterGroup?: ChargemasterGroupUpdateOneWithoutChargemastersNestedInput | null;
  primaryChargemasterGroup?: ChargemasterGroupUpdateOneWithoutPrimaryChargemasterNestedInput | null;
  charges?: ChargeUpdateManyWithoutChargemasterNestedInput | null;
}

export interface ChargemasterUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  lastUsedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
  chargemasterGroup?: ChargemasterGroupUpdateOneWithoutChargemastersNestedInput | null;
  primaryChargemasterGroup?: ChargemasterGroupUpdateOneWithoutPrimaryChargemasterNestedInput | null;
  charges?: ChargeUpdateManyWithoutChargemasterNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargemasterNestedInput | null;
}

export interface ChargemasterUpdateWithoutPrimaryChargemasterGroupInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  lastUsedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  modifier1?: StringFieldUpdateOperationsInput | null;
  modifier2?: StringFieldUpdateOperationsInput | null;
  modifier3?: StringFieldUpdateOperationsInput | null;
  modifier4?: StringFieldUpdateOperationsInput | null;
  description?: StringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutChargemastersNestedInput | null;
  chargemasterGroup?: ChargemasterGroupUpdateOneWithoutChargemastersNestedInput | null;
  charges?: ChargeUpdateManyWithoutChargemasterNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutChargemasterNestedInput | null;
}

export interface ChargemasterUpsertWithWhereUniqueWithoutChargemasterGroupInput {
  where: ChargemasterWhereUniqueInput;
  update: ChargemasterUpdateWithoutChargemasterGroupInput;
  create: ChargemasterCreateWithoutChargemasterGroupInput;
}

export interface ChargemasterUpsertWithWhereUniqueWithoutLocationInput {
  where: ChargemasterWhereUniqueInput;
  update: ChargemasterUpdateWithoutLocationInput;
  create: ChargemasterCreateWithoutLocationInput;
}

export interface ChargemasterUpsertWithoutChargesInput {
  update: ChargemasterUpdateWithoutChargesInput;
  create: ChargemasterCreateWithoutChargesInput;
}

export interface ChargemasterUpsertWithoutEstimatedChargesInput {
  update: ChargemasterUpdateWithoutEstimatedChargesInput;
  create: ChargemasterCreateWithoutEstimatedChargesInput;
}

export interface ChargemasterUpsertWithoutPrimaryChargemasterGroupInput {
  update: ChargemasterUpdateWithoutPrimaryChargemasterGroupInput;
  create: ChargemasterCreateWithoutPrimaryChargemasterGroupInput;
}

export interface ChargemasterWhereInput {
  AND?: ChargemasterWhereInput[] | null;
  OR?: ChargemasterWhereInput[] | null;
  NOT?: ChargemasterWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  lastUsedAt?: DateTimeNullableFilter | null;
  locationId?: UuidFilter | null;
  amount?: IntFilter | null;
  code?: StringFilter | null;
  modifier1?: StringFilter | null;
  modifier2?: StringFilter | null;
  modifier3?: StringFilter | null;
  modifier4?: StringFilter | null;
  description?: StringFilter | null;
  chargemasterGroupId?: UuidNullableFilter | null;
  location?: LocationRelationFilter | null;
  chargemasterGroup?: ChargemasterGroupRelationFilter | null;
  primaryChargemasterGroup?: ChargemasterGroupRelationFilter | null;
  charges?: ChargeListRelationFilter | null;
  estimatedCharges?: EstimatedChargeListRelationFilter | null;
}

export interface ChargemasterWhereUniqueInput {
  id?: string | null;
  locationId_code_modifier1_modifier2_modifier3_modifier4_description?: ChargemasterLocationIdCodeModifier1Modifier2Modifier3Modifier4DescriptionCompoundUniqueInput | null;
}

export interface ClaimCreateManyAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointmentId?: string | null;
  payerId: string;
  serviceDate?: any | null;
}

export interface ClaimCreateManyAccountInputEnvelope {
  data: ClaimCreateManyAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface ClaimCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  accountId: string;
  payerId: string;
  serviceDate?: any | null;
}

export interface ClaimCreateManyAppointmentInputEnvelope {
  data: ClaimCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface ClaimCreateManyPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  accountId: string;
  appointmentId?: string | null;
  serviceDate?: any | null;
}

export interface ClaimCreateManyPayerInputEnvelope {
  data: ClaimCreateManyPayerInput[];
  skipDuplicates?: boolean | null;
}

export interface ClaimCreateNestedManyWithoutAccountInput {
  create?: ClaimCreateWithoutAccountInput[] | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutAccountInput[] | null;
  createMany?: ClaimCreateManyAccountInputEnvelope | null;
  connect?: ClaimWhereUniqueInput[] | null;
}

export interface ClaimCreateNestedManyWithoutAppointmentInput {
  create?: ClaimCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: ClaimCreateManyAppointmentInputEnvelope | null;
  connect?: ClaimWhereUniqueInput[] | null;
}

export interface ClaimCreateNestedManyWithoutPayerInput {
  create?: ClaimCreateWithoutPayerInput[] | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutPayerInput[] | null;
  createMany?: ClaimCreateManyPayerInputEnvelope | null;
  connect?: ClaimWhereUniqueInput[] | null;
}

export interface ClaimCreateNestedOneWithoutClaimLinesInput {
  create?: ClaimCreateWithoutClaimLinesInput | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutClaimLinesInput | null;
  connect?: ClaimWhereUniqueInput | null;
}

export interface ClaimCreateNestedOneWithoutClaimRemittancesInput {
  create?: ClaimCreateWithoutClaimRemittancesInput | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutClaimRemittancesInput | null;
  connect?: ClaimWhereUniqueInput | null;
}

export interface ClaimCreateNestedOneWithoutIntegrationLinksInput {
  create?: ClaimCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: ClaimWhereUniqueInput | null;
}

export interface ClaimCreateOrConnectWithoutAccountInput {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutAccountInput;
}

export interface ClaimCreateOrConnectWithoutAppointmentInput {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutAppointmentInput;
}

export interface ClaimCreateOrConnectWithoutClaimLinesInput {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutClaimLinesInput;
}

export interface ClaimCreateOrConnectWithoutClaimRemittancesInput {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutClaimRemittancesInput;
}

export interface ClaimCreateOrConnectWithoutIntegrationLinksInput {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutIntegrationLinksInput;
}

export interface ClaimCreateOrConnectWithoutPayerInput {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutPayerInput;
}

export interface ClaimCreateWithoutAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceDate?: any | null;
  appointment?: AppointmentCreateNestedOneWithoutClaimsInput | null;
  claimLines?: ClaimLineCreateNestedManyWithoutClaimInput | null;
  payer: PayerCreateNestedOneWithoutClaimsInput;
  claimRemittances?: ClaimRemittanceCreateNestedManyWithoutClaimInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimInput | null;
}

export interface ClaimCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceDate?: any | null;
  account: AccountCreateNestedOneWithoutClaimsInput;
  claimLines?: ClaimLineCreateNestedManyWithoutClaimInput | null;
  payer: PayerCreateNestedOneWithoutClaimsInput;
  claimRemittances?: ClaimRemittanceCreateNestedManyWithoutClaimInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimInput | null;
}

export interface ClaimCreateWithoutClaimLinesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceDate?: any | null;
  account: AccountCreateNestedOneWithoutClaimsInput;
  appointment?: AppointmentCreateNestedOneWithoutClaimsInput | null;
  payer: PayerCreateNestedOneWithoutClaimsInput;
  claimRemittances?: ClaimRemittanceCreateNestedManyWithoutClaimInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimInput | null;
}

export interface ClaimCreateWithoutClaimRemittancesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceDate?: any | null;
  account: AccountCreateNestedOneWithoutClaimsInput;
  appointment?: AppointmentCreateNestedOneWithoutClaimsInput | null;
  claimLines?: ClaimLineCreateNestedManyWithoutClaimInput | null;
  payer: PayerCreateNestedOneWithoutClaimsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimInput | null;
}

export interface ClaimCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceDate?: any | null;
  account: AccountCreateNestedOneWithoutClaimsInput;
  appointment?: AppointmentCreateNestedOneWithoutClaimsInput | null;
  claimLines?: ClaimLineCreateNestedManyWithoutClaimInput | null;
  payer: PayerCreateNestedOneWithoutClaimsInput;
  claimRemittances?: ClaimRemittanceCreateNestedManyWithoutClaimInput | null;
}

export interface ClaimCreateWithoutPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceDate?: any | null;
  account: AccountCreateNestedOneWithoutClaimsInput;
  appointment?: AppointmentCreateNestedOneWithoutClaimsInput | null;
  claimLines?: ClaimLineCreateNestedManyWithoutClaimInput | null;
  claimRemittances?: ClaimRemittanceCreateNestedManyWithoutClaimInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimInput | null;
}

export interface ClaimLineCreateManyChargeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  claimId: string;
  customCode?: string | null;
  chargeAmount: number;
  unitQualifier: string;
  units: number;
  placeOfService?: string | null;
}

export interface ClaimLineCreateManyChargeInputEnvelope {
  data: ClaimLineCreateManyChargeInput[];
  skipDuplicates?: boolean | null;
}

export interface ClaimLineCreateManyClaimInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customCode?: string | null;
  chargeAmount: number;
  unitQualifier: string;
  units: number;
  placeOfService?: string | null;
  chargeId?: string | null;
}

export interface ClaimLineCreateManyClaimInputEnvelope {
  data: ClaimLineCreateManyClaimInput[];
  skipDuplicates?: boolean | null;
}

export interface ClaimLineCreateNestedManyWithoutChargeInput {
  create?: ClaimLineCreateWithoutChargeInput[] | null;
  connectOrCreate?: ClaimLineCreateOrConnectWithoutChargeInput[] | null;
  createMany?: ClaimLineCreateManyChargeInputEnvelope | null;
  connect?: ClaimLineWhereUniqueInput[] | null;
}

export interface ClaimLineCreateNestedManyWithoutClaimInput {
  create?: ClaimLineCreateWithoutClaimInput[] | null;
  connectOrCreate?: ClaimLineCreateOrConnectWithoutClaimInput[] | null;
  createMany?: ClaimLineCreateManyClaimInputEnvelope | null;
  connect?: ClaimLineWhereUniqueInput[] | null;
}

export interface ClaimLineCreateNestedOneWithoutClaimRemittanceLineInput {
  create?: ClaimLineCreateWithoutClaimRemittanceLineInput | null;
  connectOrCreate?: ClaimLineCreateOrConnectWithoutClaimRemittanceLineInput | null;
  connect?: ClaimLineWhereUniqueInput | null;
}

export interface ClaimLineCreateNestedOneWithoutIntegrationLinksInput {
  create?: ClaimLineCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ClaimLineCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: ClaimLineWhereUniqueInput | null;
}

export interface ClaimLineCreateOrConnectWithoutChargeInput {
  where: ClaimLineWhereUniqueInput;
  create: ClaimLineCreateWithoutChargeInput;
}

export interface ClaimLineCreateOrConnectWithoutClaimInput {
  where: ClaimLineWhereUniqueInput;
  create: ClaimLineCreateWithoutClaimInput;
}

export interface ClaimLineCreateOrConnectWithoutClaimRemittanceLineInput {
  where: ClaimLineWhereUniqueInput;
  create: ClaimLineCreateWithoutClaimRemittanceLineInput;
}

export interface ClaimLineCreateOrConnectWithoutIntegrationLinksInput {
  where: ClaimLineWhereUniqueInput;
  create: ClaimLineCreateWithoutIntegrationLinksInput;
}

export interface ClaimLineCreateWithoutChargeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customCode?: string | null;
  chargeAmount: number;
  unitQualifier: string;
  units: number;
  placeOfService?: string | null;
  claim: ClaimCreateNestedOneWithoutClaimLinesInput;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutClaimLineInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimLineInput | null;
}

export interface ClaimLineCreateWithoutClaimInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customCode?: string | null;
  chargeAmount: number;
  unitQualifier: string;
  units: number;
  placeOfService?: string | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutClaimLineInput | null;
  charge?: ChargeCreateNestedOneWithoutClaimLineInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimLineInput | null;
}

export interface ClaimLineCreateWithoutClaimRemittanceLineInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customCode?: string | null;
  chargeAmount: number;
  unitQualifier: string;
  units: number;
  placeOfService?: string | null;
  claim: ClaimCreateNestedOneWithoutClaimLinesInput;
  charge?: ChargeCreateNestedOneWithoutClaimLineInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimLineInput | null;
}

export interface ClaimLineCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customCode?: string | null;
  chargeAmount: number;
  unitQualifier: string;
  units: number;
  placeOfService?: string | null;
  claim: ClaimCreateNestedOneWithoutClaimLinesInput;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutClaimLineInput | null;
  charge?: ChargeCreateNestedOneWithoutClaimLineInput | null;
}

export interface ClaimLineListRelationFilter {
  every?: ClaimLineWhereInput | null;
  some?: ClaimLineWhereInput | null;
  none?: ClaimLineWhereInput | null;
}

export interface ClaimLineRelationFilter {
  is?: ClaimLineWhereInput | null;
  isNot?: ClaimLineWhereInput | null;
}

export interface ClaimLineScalarWhereInput {
  AND?: ClaimLineScalarWhereInput[] | null;
  OR?: ClaimLineScalarWhereInput[] | null;
  NOT?: ClaimLineScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  claimId?: UuidFilter | null;
  customCode?: StringNullableFilter | null;
  chargeAmount?: IntFilter | null;
  unitQualifier?: StringFilter | null;
  units?: FloatFilter | null;
  placeOfService?: StringNullableFilter | null;
  chargeId?: UuidNullableFilter | null;
}

export interface ClaimLineUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  unitQualifier?: StringFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
}

export interface ClaimLineUpdateManyWithWhereWithoutChargeInput {
  where: ClaimLineScalarWhereInput;
  data: ClaimLineUpdateManyMutationInput;
}

export interface ClaimLineUpdateManyWithWhereWithoutClaimInput {
  where: ClaimLineScalarWhereInput;
  data: ClaimLineUpdateManyMutationInput;
}

export interface ClaimLineUpdateManyWithoutChargeNestedInput {
  create?: ClaimLineCreateWithoutChargeInput[] | null;
  connectOrCreate?: ClaimLineCreateOrConnectWithoutChargeInput[] | null;
  upsert?: ClaimLineUpsertWithWhereUniqueWithoutChargeInput[] | null;
  createMany?: ClaimLineCreateManyChargeInputEnvelope | null;
  set?: ClaimLineWhereUniqueInput[] | null;
  disconnect?: ClaimLineWhereUniqueInput[] | null;
  delete?: ClaimLineWhereUniqueInput[] | null;
  connect?: ClaimLineWhereUniqueInput[] | null;
  update?: ClaimLineUpdateWithWhereUniqueWithoutChargeInput[] | null;
  updateMany?: ClaimLineUpdateManyWithWhereWithoutChargeInput[] | null;
  deleteMany?: ClaimLineScalarWhereInput[] | null;
}

export interface ClaimLineUpdateManyWithoutClaimNestedInput {
  create?: ClaimLineCreateWithoutClaimInput[] | null;
  connectOrCreate?: ClaimLineCreateOrConnectWithoutClaimInput[] | null;
  upsert?: ClaimLineUpsertWithWhereUniqueWithoutClaimInput[] | null;
  createMany?: ClaimLineCreateManyClaimInputEnvelope | null;
  set?: ClaimLineWhereUniqueInput[] | null;
  disconnect?: ClaimLineWhereUniqueInput[] | null;
  delete?: ClaimLineWhereUniqueInput[] | null;
  connect?: ClaimLineWhereUniqueInput[] | null;
  update?: ClaimLineUpdateWithWhereUniqueWithoutClaimInput[] | null;
  updateMany?: ClaimLineUpdateManyWithWhereWithoutClaimInput[] | null;
  deleteMany?: ClaimLineScalarWhereInput[] | null;
}

export interface ClaimLineUpdateOneWithoutClaimRemittanceLineNestedInput {
  create?: ClaimLineCreateWithoutClaimRemittanceLineInput | null;
  connectOrCreate?: ClaimLineCreateOrConnectWithoutClaimRemittanceLineInput | null;
  upsert?: ClaimLineUpsertWithoutClaimRemittanceLineInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ClaimLineWhereUniqueInput | null;
  update?: ClaimLineUpdateWithoutClaimRemittanceLineInput | null;
}

export interface ClaimLineUpdateOneWithoutIntegrationLinksNestedInput {
  create?: ClaimLineCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ClaimLineCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: ClaimLineUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ClaimLineWhereUniqueInput | null;
  update?: ClaimLineUpdateWithoutIntegrationLinksInput | null;
}

export interface ClaimLineUpdateWithWhereUniqueWithoutChargeInput {
  where: ClaimLineWhereUniqueInput;
  data: ClaimLineUpdateWithoutChargeInput;
}

export interface ClaimLineUpdateWithWhereUniqueWithoutClaimInput {
  where: ClaimLineWhereUniqueInput;
  data: ClaimLineUpdateWithoutClaimInput;
}

export interface ClaimLineUpdateWithoutChargeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  unitQualifier?: StringFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  claim?: ClaimUpdateOneRequiredWithoutClaimLinesNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutClaimLineNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimLineNestedInput | null;
}

export interface ClaimLineUpdateWithoutClaimInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  unitQualifier?: StringFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutClaimLineNestedInput | null;
  charge?: ChargeUpdateOneWithoutClaimLineNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimLineNestedInput | null;
}

export interface ClaimLineUpdateWithoutClaimRemittanceLineInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  unitQualifier?: StringFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  claim?: ClaimUpdateOneRequiredWithoutClaimLinesNestedInput | null;
  charge?: ChargeUpdateOneWithoutClaimLineNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimLineNestedInput | null;
}

export interface ClaimLineUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  chargeAmount?: IntFieldUpdateOperationsInput | null;
  unitQualifier?: StringFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  claim?: ClaimUpdateOneRequiredWithoutClaimLinesNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutClaimLineNestedInput | null;
  charge?: ChargeUpdateOneWithoutClaimLineNestedInput | null;
}

export interface ClaimLineUpsertWithWhereUniqueWithoutChargeInput {
  where: ClaimLineWhereUniqueInput;
  update: ClaimLineUpdateWithoutChargeInput;
  create: ClaimLineCreateWithoutChargeInput;
}

export interface ClaimLineUpsertWithWhereUniqueWithoutClaimInput {
  where: ClaimLineWhereUniqueInput;
  update: ClaimLineUpdateWithoutClaimInput;
  create: ClaimLineCreateWithoutClaimInput;
}

export interface ClaimLineUpsertWithoutClaimRemittanceLineInput {
  update: ClaimLineUpdateWithoutClaimRemittanceLineInput;
  create: ClaimLineCreateWithoutClaimRemittanceLineInput;
}

export interface ClaimLineUpsertWithoutIntegrationLinksInput {
  update: ClaimLineUpdateWithoutIntegrationLinksInput;
  create: ClaimLineCreateWithoutIntegrationLinksInput;
}

export interface ClaimLineWhereInput {
  AND?: ClaimLineWhereInput[] | null;
  OR?: ClaimLineWhereInput[] | null;
  NOT?: ClaimLineWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  claimId?: UuidFilter | null;
  customCode?: StringNullableFilter | null;
  chargeAmount?: IntFilter | null;
  unitQualifier?: StringFilter | null;
  units?: FloatFilter | null;
  placeOfService?: StringNullableFilter | null;
  chargeId?: UuidNullableFilter | null;
  claim?: ClaimRelationFilter | null;
  claimRemittanceLine?: ClaimRemittanceLineRelationFilter | null;
  charge?: ChargeRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
}

export interface ClaimLineWhereUniqueInput {
  id?: string | null;
}

export interface ClaimListRelationFilter {
  every?: ClaimWhereInput | null;
  some?: ClaimWhereInput | null;
  none?: ClaimWhereInput | null;
}

export interface ClaimOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ClaimRelationFilter {
  is?: ClaimWhereInput | null;
  isNot?: ClaimWhereInput | null;
}

export interface ClaimRemittanceCreateManyClaimInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceDate?: any | null;
}

export interface ClaimRemittanceCreateManyClaimInputEnvelope {
  data: ClaimRemittanceCreateManyClaimInput[];
  skipDuplicates?: boolean | null;
}

export interface ClaimRemittanceCreateNestedManyWithoutClaimInput {
  create?: ClaimRemittanceCreateWithoutClaimInput[] | null;
  connectOrCreate?: ClaimRemittanceCreateOrConnectWithoutClaimInput[] | null;
  createMany?: ClaimRemittanceCreateManyClaimInputEnvelope | null;
  connect?: ClaimRemittanceWhereUniqueInput[] | null;
}

export interface ClaimRemittanceCreateNestedOneWithoutClaimRemittanceLinesInput {
  create?: ClaimRemittanceCreateWithoutClaimRemittanceLinesInput | null;
  connectOrCreate?: ClaimRemittanceCreateOrConnectWithoutClaimRemittanceLinesInput | null;
  connect?: ClaimRemittanceWhereUniqueInput | null;
}

export interface ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput {
  create?: ClaimRemittanceCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ClaimRemittanceCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: ClaimRemittanceWhereUniqueInput | null;
}

export interface ClaimRemittanceCreateOrConnectWithoutClaimInput {
  where: ClaimRemittanceWhereUniqueInput;
  create: ClaimRemittanceCreateWithoutClaimInput;
}

export interface ClaimRemittanceCreateOrConnectWithoutClaimRemittanceLinesInput {
  where: ClaimRemittanceWhereUniqueInput;
  create: ClaimRemittanceCreateWithoutClaimRemittanceLinesInput;
}

export interface ClaimRemittanceCreateOrConnectWithoutIntegrationLinksInput {
  where: ClaimRemittanceWhereUniqueInput;
  create: ClaimRemittanceCreateWithoutIntegrationLinksInput;
}

export interface ClaimRemittanceCreateWithoutClaimInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceDate?: any | null;
  claimRemittanceLines?: ClaimRemittanceLineCreateNestedManyWithoutClaimRemittanceInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimRemittanceInput | null;
}

export interface ClaimRemittanceCreateWithoutClaimRemittanceLinesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceDate?: any | null;
  claim?: ClaimCreateNestedOneWithoutClaimRemittancesInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimRemittanceInput | null;
}

export interface ClaimRemittanceCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceDate?: any | null;
  claim?: ClaimCreateNestedOneWithoutClaimRemittancesInput | null;
  claimRemittanceLines?: ClaimRemittanceLineCreateNestedManyWithoutClaimRemittanceInput | null;
}

export interface ClaimRemittanceLineCreateManyClaimRemittanceInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customCode?: string | null;
  serviceDate?: any | null;
  chargeAmount?: number | null;
  allowedAmount?: number | null;
  patientResponsibilityAmount?: number | null;
  writeOffAmount?: number | null;
  patientDeductibleAmount?: number | null;
  patientCopayAmount?: number | null;
  patientCoinsuranceAmount?: number | null;
  paidUnits?: any | null;
  claimLineId?: string | null;
}

export interface ClaimRemittanceLineCreateManyClaimRemittanceInputEnvelope {
  data: ClaimRemittanceLineCreateManyClaimRemittanceInput[];
  skipDuplicates?: boolean | null;
}

export interface ClaimRemittanceLineCreateNestedManyWithoutClaimRemittanceInput {
  create?: ClaimRemittanceLineCreateWithoutClaimRemittanceInput[] | null;
  connectOrCreate?: ClaimRemittanceLineCreateOrConnectWithoutClaimRemittanceInput[] | null;
  createMany?: ClaimRemittanceLineCreateManyClaimRemittanceInputEnvelope | null;
  connect?: ClaimRemittanceLineWhereUniqueInput[] | null;
}

export interface ClaimRemittanceLineCreateNestedOneWithoutClaimLineInput {
  create?: ClaimRemittanceLineCreateWithoutClaimLineInput | null;
  connectOrCreate?: ClaimRemittanceLineCreateOrConnectWithoutClaimLineInput | null;
  connect?: ClaimRemittanceLineWhereUniqueInput | null;
}

export interface ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput {
  create?: ClaimRemittanceLineCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ClaimRemittanceLineCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: ClaimRemittanceLineWhereUniqueInput | null;
}

export interface ClaimRemittanceLineCreateOrConnectWithoutClaimLineInput {
  where: ClaimRemittanceLineWhereUniqueInput;
  create: ClaimRemittanceLineCreateWithoutClaimLineInput;
}

export interface ClaimRemittanceLineCreateOrConnectWithoutClaimRemittanceInput {
  where: ClaimRemittanceLineWhereUniqueInput;
  create: ClaimRemittanceLineCreateWithoutClaimRemittanceInput;
}

export interface ClaimRemittanceLineCreateOrConnectWithoutIntegrationLinksInput {
  where: ClaimRemittanceLineWhereUniqueInput;
  create: ClaimRemittanceLineCreateWithoutIntegrationLinksInput;
}

export interface ClaimRemittanceLineCreateWithoutClaimLineInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customCode?: string | null;
  serviceDate?: any | null;
  chargeAmount?: number | null;
  allowedAmount?: number | null;
  patientResponsibilityAmount?: number | null;
  writeOffAmount?: number | null;
  patientDeductibleAmount?: number | null;
  patientCopayAmount?: number | null;
  patientCoinsuranceAmount?: number | null;
  paidUnits?: any | null;
  claimRemittance: ClaimRemittanceCreateNestedOneWithoutClaimRemittanceLinesInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimRemittanceLineInput | null;
}

export interface ClaimRemittanceLineCreateWithoutClaimRemittanceInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customCode?: string | null;
  serviceDate?: any | null;
  chargeAmount?: number | null;
  allowedAmount?: number | null;
  patientResponsibilityAmount?: number | null;
  writeOffAmount?: number | null;
  patientDeductibleAmount?: number | null;
  patientCopayAmount?: number | null;
  patientCoinsuranceAmount?: number | null;
  paidUnits?: any | null;
  claimLine?: ClaimLineCreateNestedOneWithoutClaimRemittanceLineInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutClaimRemittanceLineInput | null;
}

export interface ClaimRemittanceLineCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  customCode?: string | null;
  serviceDate?: any | null;
  chargeAmount?: number | null;
  allowedAmount?: number | null;
  patientResponsibilityAmount?: number | null;
  writeOffAmount?: number | null;
  patientDeductibleAmount?: number | null;
  patientCopayAmount?: number | null;
  patientCoinsuranceAmount?: number | null;
  paidUnits?: any | null;
  claimRemittance: ClaimRemittanceCreateNestedOneWithoutClaimRemittanceLinesInput;
  claimLine?: ClaimLineCreateNestedOneWithoutClaimRemittanceLineInput | null;
}

export interface ClaimRemittanceLineListRelationFilter {
  every?: ClaimRemittanceLineWhereInput | null;
  some?: ClaimRemittanceLineWhereInput | null;
  none?: ClaimRemittanceLineWhereInput | null;
}

export interface ClaimRemittanceLineRelationFilter {
  is?: ClaimRemittanceLineWhereInput | null;
  isNot?: ClaimRemittanceLineWhereInput | null;
}

export interface ClaimRemittanceLineScalarWhereInput {
  AND?: ClaimRemittanceLineScalarWhereInput[] | null;
  OR?: ClaimRemittanceLineScalarWhereInput[] | null;
  NOT?: ClaimRemittanceLineScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  claimRemittanceId?: UuidFilter | null;
  customCode?: StringNullableFilter | null;
  serviceDate?: DateTimeNullableFilter | null;
  chargeAmount?: IntNullableFilter | null;
  allowedAmount?: IntNullableFilter | null;
  patientResponsibilityAmount?: IntNullableFilter | null;
  writeOffAmount?: IntNullableFilter | null;
  patientDeductibleAmount?: IntNullableFilter | null;
  patientCopayAmount?: IntNullableFilter | null;
  patientCoinsuranceAmount?: IntNullableFilter | null;
  paidUnits?: DecimalNullableFilter | null;
  claimLineId?: UuidNullableFilter | null;
}

export interface ClaimRemittanceLineUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: NullableIntFieldUpdateOperationsInput | null;
  allowedAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientResponsibilityAmount?: NullableIntFieldUpdateOperationsInput | null;
  writeOffAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientDeductibleAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientCopayAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientCoinsuranceAmount?: NullableIntFieldUpdateOperationsInput | null;
  paidUnits?: NullableDecimalFieldUpdateOperationsInput | null;
}

export interface ClaimRemittanceLineUpdateManyWithWhereWithoutClaimRemittanceInput {
  where: ClaimRemittanceLineScalarWhereInput;
  data: ClaimRemittanceLineUpdateManyMutationInput;
}

export interface ClaimRemittanceLineUpdateManyWithoutClaimRemittanceNestedInput {
  create?: ClaimRemittanceLineCreateWithoutClaimRemittanceInput[] | null;
  connectOrCreate?: ClaimRemittanceLineCreateOrConnectWithoutClaimRemittanceInput[] | null;
  upsert?: ClaimRemittanceLineUpsertWithWhereUniqueWithoutClaimRemittanceInput[] | null;
  createMany?: ClaimRemittanceLineCreateManyClaimRemittanceInputEnvelope | null;
  set?: ClaimRemittanceLineWhereUniqueInput[] | null;
  disconnect?: ClaimRemittanceLineWhereUniqueInput[] | null;
  delete?: ClaimRemittanceLineWhereUniqueInput[] | null;
  connect?: ClaimRemittanceLineWhereUniqueInput[] | null;
  update?: ClaimRemittanceLineUpdateWithWhereUniqueWithoutClaimRemittanceInput[] | null;
  updateMany?: ClaimRemittanceLineUpdateManyWithWhereWithoutClaimRemittanceInput[] | null;
  deleteMany?: ClaimRemittanceLineScalarWhereInput[] | null;
}

export interface ClaimRemittanceLineUpdateOneWithoutClaimLineNestedInput {
  create?: ClaimRemittanceLineCreateWithoutClaimLineInput | null;
  connectOrCreate?: ClaimRemittanceLineCreateOrConnectWithoutClaimLineInput | null;
  upsert?: ClaimRemittanceLineUpsertWithoutClaimLineInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ClaimRemittanceLineWhereUniqueInput | null;
  update?: ClaimRemittanceLineUpdateWithoutClaimLineInput | null;
}

export interface ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput {
  create?: ClaimRemittanceLineCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ClaimRemittanceLineCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: ClaimRemittanceLineUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ClaimRemittanceLineWhereUniqueInput | null;
  update?: ClaimRemittanceLineUpdateWithoutIntegrationLinksInput | null;
}

export interface ClaimRemittanceLineUpdateWithWhereUniqueWithoutClaimRemittanceInput {
  where: ClaimRemittanceLineWhereUniqueInput;
  data: ClaimRemittanceLineUpdateWithoutClaimRemittanceInput;
}

export interface ClaimRemittanceLineUpdateWithoutClaimLineInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: NullableIntFieldUpdateOperationsInput | null;
  allowedAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientResponsibilityAmount?: NullableIntFieldUpdateOperationsInput | null;
  writeOffAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientDeductibleAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientCopayAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientCoinsuranceAmount?: NullableIntFieldUpdateOperationsInput | null;
  paidUnits?: NullableDecimalFieldUpdateOperationsInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneRequiredWithoutClaimRemittanceLinesNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimRemittanceLineNestedInput | null;
}

export interface ClaimRemittanceLineUpdateWithoutClaimRemittanceInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: NullableIntFieldUpdateOperationsInput | null;
  allowedAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientResponsibilityAmount?: NullableIntFieldUpdateOperationsInput | null;
  writeOffAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientDeductibleAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientCopayAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientCoinsuranceAmount?: NullableIntFieldUpdateOperationsInput | null;
  paidUnits?: NullableDecimalFieldUpdateOperationsInput | null;
  claimLine?: ClaimLineUpdateOneWithoutClaimRemittanceLineNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimRemittanceLineNestedInput | null;
}

export interface ClaimRemittanceLineUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  chargeAmount?: NullableIntFieldUpdateOperationsInput | null;
  allowedAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientResponsibilityAmount?: NullableIntFieldUpdateOperationsInput | null;
  writeOffAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientDeductibleAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientCopayAmount?: NullableIntFieldUpdateOperationsInput | null;
  patientCoinsuranceAmount?: NullableIntFieldUpdateOperationsInput | null;
  paidUnits?: NullableDecimalFieldUpdateOperationsInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneRequiredWithoutClaimRemittanceLinesNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutClaimRemittanceLineNestedInput | null;
}

export interface ClaimRemittanceLineUpsertWithWhereUniqueWithoutClaimRemittanceInput {
  where: ClaimRemittanceLineWhereUniqueInput;
  update: ClaimRemittanceLineUpdateWithoutClaimRemittanceInput;
  create: ClaimRemittanceLineCreateWithoutClaimRemittanceInput;
}

export interface ClaimRemittanceLineUpsertWithoutClaimLineInput {
  update: ClaimRemittanceLineUpdateWithoutClaimLineInput;
  create: ClaimRemittanceLineCreateWithoutClaimLineInput;
}

export interface ClaimRemittanceLineUpsertWithoutIntegrationLinksInput {
  update: ClaimRemittanceLineUpdateWithoutIntegrationLinksInput;
  create: ClaimRemittanceLineCreateWithoutIntegrationLinksInput;
}

export interface ClaimRemittanceLineWhereInput {
  AND?: ClaimRemittanceLineWhereInput[] | null;
  OR?: ClaimRemittanceLineWhereInput[] | null;
  NOT?: ClaimRemittanceLineWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  claimRemittanceId?: UuidFilter | null;
  customCode?: StringNullableFilter | null;
  serviceDate?: DateTimeNullableFilter | null;
  chargeAmount?: IntNullableFilter | null;
  allowedAmount?: IntNullableFilter | null;
  patientResponsibilityAmount?: IntNullableFilter | null;
  writeOffAmount?: IntNullableFilter | null;
  patientDeductibleAmount?: IntNullableFilter | null;
  patientCopayAmount?: IntNullableFilter | null;
  patientCoinsuranceAmount?: IntNullableFilter | null;
  paidUnits?: DecimalNullableFilter | null;
  claimLineId?: UuidNullableFilter | null;
  claimRemittance?: ClaimRemittanceRelationFilter | null;
  claimLine?: ClaimLineRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
}

export interface ClaimRemittanceLineWhereUniqueInput {
  id?: string | null;
  claimLineId?: string | null;
}

export interface ClaimRemittanceListRelationFilter {
  every?: ClaimRemittanceWhereInput | null;
  some?: ClaimRemittanceWhereInput | null;
  none?: ClaimRemittanceWhereInput | null;
}

export interface ClaimRemittanceRelationFilter {
  is?: ClaimRemittanceWhereInput | null;
  isNot?: ClaimRemittanceWhereInput | null;
}

export interface ClaimRemittanceScalarWhereInput {
  AND?: ClaimRemittanceScalarWhereInput[] | null;
  OR?: ClaimRemittanceScalarWhereInput[] | null;
  NOT?: ClaimRemittanceScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  claimId?: UuidNullableFilter | null;
  serviceDate?: DateTimeNullableFilter | null;
}

export interface ClaimRemittanceUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
}

export interface ClaimRemittanceUpdateManyWithWhereWithoutClaimInput {
  where: ClaimRemittanceScalarWhereInput;
  data: ClaimRemittanceUpdateManyMutationInput;
}

export interface ClaimRemittanceUpdateManyWithoutClaimNestedInput {
  create?: ClaimRemittanceCreateWithoutClaimInput[] | null;
  connectOrCreate?: ClaimRemittanceCreateOrConnectWithoutClaimInput[] | null;
  upsert?: ClaimRemittanceUpsertWithWhereUniqueWithoutClaimInput[] | null;
  createMany?: ClaimRemittanceCreateManyClaimInputEnvelope | null;
  set?: ClaimRemittanceWhereUniqueInput[] | null;
  disconnect?: ClaimRemittanceWhereUniqueInput[] | null;
  delete?: ClaimRemittanceWhereUniqueInput[] | null;
  connect?: ClaimRemittanceWhereUniqueInput[] | null;
  update?: ClaimRemittanceUpdateWithWhereUniqueWithoutClaimInput[] | null;
  updateMany?: ClaimRemittanceUpdateManyWithWhereWithoutClaimInput[] | null;
  deleteMany?: ClaimRemittanceScalarWhereInput[] | null;
}

export interface ClaimRemittanceUpdateOneRequiredWithoutClaimRemittanceLinesNestedInput {
  create?: ClaimRemittanceCreateWithoutClaimRemittanceLinesInput | null;
  connectOrCreate?: ClaimRemittanceCreateOrConnectWithoutClaimRemittanceLinesInput | null;
  upsert?: ClaimRemittanceUpsertWithoutClaimRemittanceLinesInput | null;
  connect?: ClaimRemittanceWhereUniqueInput | null;
  update?: ClaimRemittanceUpdateWithoutClaimRemittanceLinesInput | null;
}

export interface ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput {
  create?: ClaimRemittanceCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ClaimRemittanceCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: ClaimRemittanceUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ClaimRemittanceWhereUniqueInput | null;
  update?: ClaimRemittanceUpdateWithoutIntegrationLinksInput | null;
}

export interface ClaimRemittanceUpdateWithWhereUniqueWithoutClaimInput {
  where: ClaimRemittanceWhereUniqueInput;
  data: ClaimRemittanceUpdateWithoutClaimInput;
}

export interface ClaimRemittanceUpdateWithoutClaimInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  claimRemittanceLines?: ClaimRemittanceLineUpdateManyWithoutClaimRemittanceNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimRemittanceNestedInput | null;
}

export interface ClaimRemittanceUpdateWithoutClaimRemittanceLinesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  claim?: ClaimUpdateOneWithoutClaimRemittancesNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimRemittanceNestedInput | null;
}

export interface ClaimRemittanceUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  claim?: ClaimUpdateOneWithoutClaimRemittancesNestedInput | null;
  claimRemittanceLines?: ClaimRemittanceLineUpdateManyWithoutClaimRemittanceNestedInput | null;
}

export interface ClaimRemittanceUpsertWithWhereUniqueWithoutClaimInput {
  where: ClaimRemittanceWhereUniqueInput;
  update: ClaimRemittanceUpdateWithoutClaimInput;
  create: ClaimRemittanceCreateWithoutClaimInput;
}

export interface ClaimRemittanceUpsertWithoutClaimRemittanceLinesInput {
  update: ClaimRemittanceUpdateWithoutClaimRemittanceLinesInput;
  create: ClaimRemittanceCreateWithoutClaimRemittanceLinesInput;
}

export interface ClaimRemittanceUpsertWithoutIntegrationLinksInput {
  update: ClaimRemittanceUpdateWithoutIntegrationLinksInput;
  create: ClaimRemittanceCreateWithoutIntegrationLinksInput;
}

export interface ClaimRemittanceWhereInput {
  AND?: ClaimRemittanceWhereInput[] | null;
  OR?: ClaimRemittanceWhereInput[] | null;
  NOT?: ClaimRemittanceWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  claimId?: UuidNullableFilter | null;
  serviceDate?: DateTimeNullableFilter | null;
  claim?: ClaimRelationFilter | null;
  claimRemittanceLines?: ClaimRemittanceLineListRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
}

export interface ClaimRemittanceWhereUniqueInput {
  id?: string | null;
}

export interface ClaimScalarWhereInput {
  AND?: ClaimScalarWhereInput[] | null;
  OR?: ClaimScalarWhereInput[] | null;
  NOT?: ClaimScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  accountId?: UuidFilter | null;
  appointmentId?: UuidNullableFilter | null;
  payerId?: UuidFilter | null;
  serviceDate?: DateTimeNullableFilter | null;
}

export interface ClaimUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
}

export interface ClaimUpdateManyWithWhereWithoutAccountInput {
  where: ClaimScalarWhereInput;
  data: ClaimUpdateManyMutationInput;
}

export interface ClaimUpdateManyWithWhereWithoutAppointmentInput {
  where: ClaimScalarWhereInput;
  data: ClaimUpdateManyMutationInput;
}

export interface ClaimUpdateManyWithWhereWithoutPayerInput {
  where: ClaimScalarWhereInput;
  data: ClaimUpdateManyMutationInput;
}

export interface ClaimUpdateManyWithoutAccountNestedInput {
  create?: ClaimCreateWithoutAccountInput[] | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutAccountInput[] | null;
  upsert?: ClaimUpsertWithWhereUniqueWithoutAccountInput[] | null;
  createMany?: ClaimCreateManyAccountInputEnvelope | null;
  set?: ClaimWhereUniqueInput[] | null;
  disconnect?: ClaimWhereUniqueInput[] | null;
  delete?: ClaimWhereUniqueInput[] | null;
  connect?: ClaimWhereUniqueInput[] | null;
  update?: ClaimUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: ClaimUpdateManyWithWhereWithoutAccountInput[] | null;
  deleteMany?: ClaimScalarWhereInput[] | null;
}

export interface ClaimUpdateManyWithoutAppointmentNestedInput {
  create?: ClaimCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: ClaimUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: ClaimCreateManyAppointmentInputEnvelope | null;
  set?: ClaimWhereUniqueInput[] | null;
  disconnect?: ClaimWhereUniqueInput[] | null;
  delete?: ClaimWhereUniqueInput[] | null;
  connect?: ClaimWhereUniqueInput[] | null;
  update?: ClaimUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: ClaimUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: ClaimScalarWhereInput[] | null;
}

export interface ClaimUpdateManyWithoutPayerNestedInput {
  create?: ClaimCreateWithoutPayerInput[] | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutPayerInput[] | null;
  upsert?: ClaimUpsertWithWhereUniqueWithoutPayerInput[] | null;
  createMany?: ClaimCreateManyPayerInputEnvelope | null;
  set?: ClaimWhereUniqueInput[] | null;
  disconnect?: ClaimWhereUniqueInput[] | null;
  delete?: ClaimWhereUniqueInput[] | null;
  connect?: ClaimWhereUniqueInput[] | null;
  update?: ClaimUpdateWithWhereUniqueWithoutPayerInput[] | null;
  updateMany?: ClaimUpdateManyWithWhereWithoutPayerInput[] | null;
  deleteMany?: ClaimScalarWhereInput[] | null;
}

export interface ClaimUpdateOneRequiredWithoutClaimLinesNestedInput {
  create?: ClaimCreateWithoutClaimLinesInput | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutClaimLinesInput | null;
  upsert?: ClaimUpsertWithoutClaimLinesInput | null;
  connect?: ClaimWhereUniqueInput | null;
  update?: ClaimUpdateWithoutClaimLinesInput | null;
}

export interface ClaimUpdateOneWithoutClaimRemittancesNestedInput {
  create?: ClaimCreateWithoutClaimRemittancesInput | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutClaimRemittancesInput | null;
  upsert?: ClaimUpsertWithoutClaimRemittancesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ClaimWhereUniqueInput | null;
  update?: ClaimUpdateWithoutClaimRemittancesInput | null;
}

export interface ClaimUpdateOneWithoutIntegrationLinksNestedInput {
  create?: ClaimCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ClaimCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: ClaimUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ClaimWhereUniqueInput | null;
  update?: ClaimUpdateWithoutIntegrationLinksInput | null;
}

export interface ClaimUpdateWithWhereUniqueWithoutAccountInput {
  where: ClaimWhereUniqueInput;
  data: ClaimUpdateWithoutAccountInput;
}

export interface ClaimUpdateWithWhereUniqueWithoutAppointmentInput {
  where: ClaimWhereUniqueInput;
  data: ClaimUpdateWithoutAppointmentInput;
}

export interface ClaimUpdateWithWhereUniqueWithoutPayerInput {
  where: ClaimWhereUniqueInput;
  data: ClaimUpdateWithoutPayerInput;
}

export interface ClaimUpdateWithoutAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneWithoutClaimsNestedInput | null;
  claimLines?: ClaimLineUpdateManyWithoutClaimNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutClaimsNestedInput | null;
  claimRemittances?: ClaimRemittanceUpdateManyWithoutClaimNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimNestedInput | null;
}

export interface ClaimUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutClaimsNestedInput | null;
  claimLines?: ClaimLineUpdateManyWithoutClaimNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutClaimsNestedInput | null;
  claimRemittances?: ClaimRemittanceUpdateManyWithoutClaimNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimNestedInput | null;
}

export interface ClaimUpdateWithoutClaimLinesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutClaimsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutClaimsNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutClaimsNestedInput | null;
  claimRemittances?: ClaimRemittanceUpdateManyWithoutClaimNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimNestedInput | null;
}

export interface ClaimUpdateWithoutClaimRemittancesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutClaimsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutClaimsNestedInput | null;
  claimLines?: ClaimLineUpdateManyWithoutClaimNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutClaimsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimNestedInput | null;
}

export interface ClaimUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutClaimsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutClaimsNestedInput | null;
  claimLines?: ClaimLineUpdateManyWithoutClaimNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutClaimsNestedInput | null;
  claimRemittances?: ClaimRemittanceUpdateManyWithoutClaimNestedInput | null;
}

export interface ClaimUpdateWithoutPayerInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutClaimsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutClaimsNestedInput | null;
  claimLines?: ClaimLineUpdateManyWithoutClaimNestedInput | null;
  claimRemittances?: ClaimRemittanceUpdateManyWithoutClaimNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutClaimNestedInput | null;
}

export interface ClaimUpsertWithWhereUniqueWithoutAccountInput {
  where: ClaimWhereUniqueInput;
  update: ClaimUpdateWithoutAccountInput;
  create: ClaimCreateWithoutAccountInput;
}

export interface ClaimUpsertWithWhereUniqueWithoutAppointmentInput {
  where: ClaimWhereUniqueInput;
  update: ClaimUpdateWithoutAppointmentInput;
  create: ClaimCreateWithoutAppointmentInput;
}

export interface ClaimUpsertWithWhereUniqueWithoutPayerInput {
  where: ClaimWhereUniqueInput;
  update: ClaimUpdateWithoutPayerInput;
  create: ClaimCreateWithoutPayerInput;
}

export interface ClaimUpsertWithoutClaimLinesInput {
  update: ClaimUpdateWithoutClaimLinesInput;
  create: ClaimCreateWithoutClaimLinesInput;
}

export interface ClaimUpsertWithoutClaimRemittancesInput {
  update: ClaimUpdateWithoutClaimRemittancesInput;
  create: ClaimCreateWithoutClaimRemittancesInput;
}

export interface ClaimUpsertWithoutIntegrationLinksInput {
  update: ClaimUpdateWithoutIntegrationLinksInput;
  create: ClaimCreateWithoutIntegrationLinksInput;
}

export interface ClaimWhereInput {
  AND?: ClaimWhereInput[] | null;
  OR?: ClaimWhereInput[] | null;
  NOT?: ClaimWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  accountId?: UuidFilter | null;
  appointmentId?: UuidNullableFilter | null;
  payerId?: UuidFilter | null;
  serviceDate?: DateTimeNullableFilter | null;
  account?: AccountRelationFilter | null;
  appointment?: AppointmentRelationFilter | null;
  claimLines?: ClaimLineListRelationFilter | null;
  payer?: PayerRelationFilter | null;
  claimRemittances?: ClaimRemittanceListRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
}

export interface ClaimWhereUniqueInput {
  id?: string | null;
}

export interface CoinsuranceBenefitCreateManyEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  percentage: any;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: CoinsuranceBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
}

export interface CoinsuranceBenefitCreateManyEligibilityRequestInputEnvelope {
  data: CoinsuranceBenefitCreateManyEligibilityRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput {
  create?: CoinsuranceBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: CoinsuranceBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  createMany?: CoinsuranceBenefitCreateManyEligibilityRequestInputEnvelope | null;
  connect?: CoinsuranceBenefitWhereUniqueInput[] | null;
}

export interface CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput {
  create?: CoinsuranceBenefitCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: CoinsuranceBenefitCreateOrConnectWithoutCoverageBenefitsInput | null;
  connect?: CoinsuranceBenefitWhereUniqueInput | null;
}

export interface CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput {
  create?: CoinsuranceBenefitCreateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connectOrCreate?: CoinsuranceBenefitCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connect?: CoinsuranceBenefitWhereUniqueInput | null;
}

export interface CoinsuranceBenefitCreateOrConnectWithoutCoverageBenefitsInput {
  where: CoinsuranceBenefitWhereUniqueInput;
  create: CoinsuranceBenefitCreateWithoutCoverageBenefitsInput;
}

export interface CoinsuranceBenefitCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput {
  where: CoinsuranceBenefitWhereUniqueInput;
  create: CoinsuranceBenefitCreateWithoutEligibilityCoverageBenefitSnapshotsInput;
}

export interface CoinsuranceBenefitCreateOrConnectWithoutEligibilityRequestInput {
  where: CoinsuranceBenefitWhereUniqueInput;
  create: CoinsuranceBenefitCreateWithoutEligibilityRequestInput;
}

export interface CoinsuranceBenefitCreateWithoutCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  percentage: any;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: CoinsuranceBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutCoinsuranceBenefitsInput;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutCoinsuranceBenefitInput | null;
}

export interface CoinsuranceBenefitCreateWithoutEligibilityCoverageBenefitSnapshotsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  percentage: any;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: CoinsuranceBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutCoinsuranceBenefitsInput;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCoinsuranceBenefitInput | null;
}

export interface CoinsuranceBenefitCreateWithoutEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  percentage: any;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: CoinsuranceBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutCoinsuranceBenefitInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCoinsuranceBenefitInput | null;
}

export interface CoinsuranceBenefitCreatenotesInput {
  set: string[];
}

export interface CoinsuranceBenefitListRelationFilter {
  every?: CoinsuranceBenefitWhereInput | null;
  some?: CoinsuranceBenefitWhereInput | null;
  none?: CoinsuranceBenefitWhereInput | null;
}

export interface CoinsuranceBenefitRelationFilter {
  is?: CoinsuranceBenefitWhereInput | null;
  isNot?: CoinsuranceBenefitWhereInput | null;
}

export interface CoinsuranceBenefitScalarWhereInput {
  AND?: CoinsuranceBenefitScalarWhereInput[] | null;
  OR?: CoinsuranceBenefitScalarWhereInput[] | null;
  NOT?: CoinsuranceBenefitScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  percentage?: DecimalFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
}

export interface CoinsuranceBenefitUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  percentage?: DecimalFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: CoinsuranceBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
}

export interface CoinsuranceBenefitUpdateManyWithWhereWithoutEligibilityRequestInput {
  where: CoinsuranceBenefitScalarWhereInput;
  data: CoinsuranceBenefitUpdateManyMutationInput;
}

export interface CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput {
  create?: CoinsuranceBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: CoinsuranceBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  upsert?: CoinsuranceBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  createMany?: CoinsuranceBenefitCreateManyEligibilityRequestInputEnvelope | null;
  set?: CoinsuranceBenefitWhereUniqueInput[] | null;
  disconnect?: CoinsuranceBenefitWhereUniqueInput[] | null;
  delete?: CoinsuranceBenefitWhereUniqueInput[] | null;
  connect?: CoinsuranceBenefitWhereUniqueInput[] | null;
  update?: CoinsuranceBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  updateMany?: CoinsuranceBenefitUpdateManyWithWhereWithoutEligibilityRequestInput[] | null;
  deleteMany?: CoinsuranceBenefitScalarWhereInput[] | null;
}

export interface CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput {
  create?: CoinsuranceBenefitCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: CoinsuranceBenefitCreateOrConnectWithoutCoverageBenefitsInput | null;
  upsert?: CoinsuranceBenefitUpsertWithoutCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: CoinsuranceBenefitWhereUniqueInput | null;
  update?: CoinsuranceBenefitUpdateWithoutCoverageBenefitsInput | null;
}

export interface CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput {
  create?: CoinsuranceBenefitCreateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connectOrCreate?: CoinsuranceBenefitCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  upsert?: CoinsuranceBenefitUpsertWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: CoinsuranceBenefitWhereUniqueInput | null;
  update?: CoinsuranceBenefitUpdateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
}

export interface CoinsuranceBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput {
  where: CoinsuranceBenefitWhereUniqueInput;
  data: CoinsuranceBenefitUpdateWithoutEligibilityRequestInput;
}

export interface CoinsuranceBenefitUpdateWithoutCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  percentage?: DecimalFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: CoinsuranceBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutCoinsuranceBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutCoinsuranceBenefitNestedInput | null;
}

export interface CoinsuranceBenefitUpdateWithoutEligibilityCoverageBenefitSnapshotsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  percentage?: DecimalFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: CoinsuranceBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutCoinsuranceBenefitsNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCoinsuranceBenefitNestedInput | null;
}

export interface CoinsuranceBenefitUpdateWithoutEligibilityRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  percentage?: DecimalFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: CoinsuranceBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutCoinsuranceBenefitNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCoinsuranceBenefitNestedInput | null;
}

export interface CoinsuranceBenefitUpdatenotesInput {
  set?: string[] | null;
  push?: string[] | null;
}

export interface CoinsuranceBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput {
  where: CoinsuranceBenefitWhereUniqueInput;
  update: CoinsuranceBenefitUpdateWithoutEligibilityRequestInput;
  create: CoinsuranceBenefitCreateWithoutEligibilityRequestInput;
}

export interface CoinsuranceBenefitUpsertWithoutCoverageBenefitsInput {
  update: CoinsuranceBenefitUpdateWithoutCoverageBenefitsInput;
  create: CoinsuranceBenefitCreateWithoutCoverageBenefitsInput;
}

export interface CoinsuranceBenefitUpsertWithoutEligibilityCoverageBenefitSnapshotsInput {
  update: CoinsuranceBenefitUpdateWithoutEligibilityCoverageBenefitSnapshotsInput;
  create: CoinsuranceBenefitCreateWithoutEligibilityCoverageBenefitSnapshotsInput;
}

export interface CoinsuranceBenefitWhereInput {
  AND?: CoinsuranceBenefitWhereInput[] | null;
  OR?: CoinsuranceBenefitWhereInput[] | null;
  NOT?: CoinsuranceBenefitWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  percentage?: DecimalFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
  eligibilityRequest?: EligibilityRequestRelationFilter | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
  coverageBenefits?: CoverageBenefitListRelationFilter | null;
}

export interface CoinsuranceBenefitWhereUniqueInput {
  id?: string | null;
}

export interface CommunicationCreateManyAccountCoverageInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  insurancePolicyId?: string | null;
  patientId?: string | null;
  sentById?: string | null;
  paymentRequestId?: string | null;
  appointmentId?: string | null;
  visitCollectionRequestId?: string | null;
}

export interface CommunicationCreateManyAccountCoverageInputEnvelope {
  data: CommunicationCreateManyAccountCoverageInput[];
  skipDuplicates?: boolean | null;
}

export interface CommunicationCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverageId?: string | null;
  insurancePolicyId?: string | null;
  patientId?: string | null;
  sentById?: string | null;
  paymentRequestId?: string | null;
  visitCollectionRequestId?: string | null;
}

export interface CommunicationCreateManyAppointmentInputEnvelope {
  data: CommunicationCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface CommunicationCreateManyInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverageId?: string | null;
  patientId?: string | null;
  sentById?: string | null;
  paymentRequestId?: string | null;
  appointmentId?: string | null;
  visitCollectionRequestId?: string | null;
}

export interface CommunicationCreateManyInsurancePolicyInputEnvelope {
  data: CommunicationCreateManyInsurancePolicyInput[];
  skipDuplicates?: boolean | null;
}

export interface CommunicationCreateManyPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverageId?: string | null;
  insurancePolicyId?: string | null;
  sentById?: string | null;
  paymentRequestId?: string | null;
  appointmentId?: string | null;
  visitCollectionRequestId?: string | null;
}

export interface CommunicationCreateManyPatientInputEnvelope {
  data: CommunicationCreateManyPatientInput[];
  skipDuplicates?: boolean | null;
}

export interface CommunicationCreateManyPaymentRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverageId?: string | null;
  insurancePolicyId?: string | null;
  patientId?: string | null;
  sentById?: string | null;
  appointmentId?: string | null;
  visitCollectionRequestId?: string | null;
}

export interface CommunicationCreateManyPaymentRequestInputEnvelope {
  data: CommunicationCreateManyPaymentRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface CommunicationCreateManySentByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverageId?: string | null;
  insurancePolicyId?: string | null;
  patientId?: string | null;
  paymentRequestId?: string | null;
  appointmentId?: string | null;
  visitCollectionRequestId?: string | null;
}

export interface CommunicationCreateManySentByInputEnvelope {
  data: CommunicationCreateManySentByInput[];
  skipDuplicates?: boolean | null;
}

export interface CommunicationCreateManyVisitCollectionRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverageId?: string | null;
  insurancePolicyId?: string | null;
  patientId?: string | null;
  sentById?: string | null;
  paymentRequestId?: string | null;
  appointmentId?: string | null;
}

export interface CommunicationCreateManyVisitCollectionRequestInputEnvelope {
  data: CommunicationCreateManyVisitCollectionRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface CommunicationCreateNestedManyWithoutAccountCoverageInput {
  create?: CommunicationCreateWithoutAccountCoverageInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutAccountCoverageInput[] | null;
  createMany?: CommunicationCreateManyAccountCoverageInputEnvelope | null;
  connect?: CommunicationWhereUniqueInput[] | null;
}

export interface CommunicationCreateNestedManyWithoutAppointmentInput {
  create?: CommunicationCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: CommunicationCreateManyAppointmentInputEnvelope | null;
  connect?: CommunicationWhereUniqueInput[] | null;
}

export interface CommunicationCreateNestedManyWithoutBillsInput {
  create?: CommunicationCreateWithoutBillsInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutBillsInput[] | null;
  connect?: CommunicationWhereUniqueInput[] | null;
}

export interface CommunicationCreateNestedManyWithoutInsurancePolicyInput {
  create?: CommunicationCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutInsurancePolicyInput[] | null;
  createMany?: CommunicationCreateManyInsurancePolicyInputEnvelope | null;
  connect?: CommunicationWhereUniqueInput[] | null;
}

export interface CommunicationCreateNestedManyWithoutPatientInput {
  create?: CommunicationCreateWithoutPatientInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutPatientInput[] | null;
  createMany?: CommunicationCreateManyPatientInputEnvelope | null;
  connect?: CommunicationWhereUniqueInput[] | null;
}

export interface CommunicationCreateNestedManyWithoutPaymentRequestInput {
  create?: CommunicationCreateWithoutPaymentRequestInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutPaymentRequestInput[] | null;
  createMany?: CommunicationCreateManyPaymentRequestInputEnvelope | null;
  connect?: CommunicationWhereUniqueInput[] | null;
}

export interface CommunicationCreateNestedManyWithoutSentByInput {
  create?: CommunicationCreateWithoutSentByInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutSentByInput[] | null;
  createMany?: CommunicationCreateManySentByInputEnvelope | null;
  connect?: CommunicationWhereUniqueInput[] | null;
}

export interface CommunicationCreateNestedManyWithoutVisitCollectionRequestInput {
  create?: CommunicationCreateWithoutVisitCollectionRequestInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutVisitCollectionRequestInput[] | null;
  createMany?: CommunicationCreateManyVisitCollectionRequestInputEnvelope | null;
  connect?: CommunicationWhereUniqueInput[] | null;
}

export interface CommunicationCreateNestedOneWithoutShortLinksInput {
  create?: CommunicationCreateWithoutShortLinksInput | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutShortLinksInput | null;
  connect?: CommunicationWhereUniqueInput | null;
}

export interface CommunicationCreateOrConnectWithoutAccountCoverageInput {
  where: CommunicationWhereUniqueInput;
  create: CommunicationCreateWithoutAccountCoverageInput;
}

export interface CommunicationCreateOrConnectWithoutAppointmentInput {
  where: CommunicationWhereUniqueInput;
  create: CommunicationCreateWithoutAppointmentInput;
}

export interface CommunicationCreateOrConnectWithoutBillsInput {
  where: CommunicationWhereUniqueInput;
  create: CommunicationCreateWithoutBillsInput;
}

export interface CommunicationCreateOrConnectWithoutInsurancePolicyInput {
  where: CommunicationWhereUniqueInput;
  create: CommunicationCreateWithoutInsurancePolicyInput;
}

export interface CommunicationCreateOrConnectWithoutPatientInput {
  where: CommunicationWhereUniqueInput;
  create: CommunicationCreateWithoutPatientInput;
}

export interface CommunicationCreateOrConnectWithoutPaymentRequestInput {
  where: CommunicationWhereUniqueInput;
  create: CommunicationCreateWithoutPaymentRequestInput;
}

export interface CommunicationCreateOrConnectWithoutSentByInput {
  where: CommunicationWhereUniqueInput;
  create: CommunicationCreateWithoutSentByInput;
}

export interface CommunicationCreateOrConnectWithoutShortLinksInput {
  where: CommunicationWhereUniqueInput;
  create: CommunicationCreateWithoutShortLinksInput;
}

export interface CommunicationCreateOrConnectWithoutVisitCollectionRequestInput {
  where: CommunicationWhereUniqueInput;
  create: CommunicationCreateWithoutVisitCollectionRequestInput;
}

export interface CommunicationCreateWithoutAccountCoverageInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  insurancePolicy?: InsurancePolicyCreateNestedOneWithoutCommunicationsInput | null;
  patient?: PatientCreateNestedOneWithoutCommunicationsInput | null;
  sentBy?: UserCreateNestedOneWithoutSentCommunicationsInput | null;
  bills?: BillCreateNestedManyWithoutCommunicationsInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutCommunicationsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutCommunicationsInput | null;
  visitCollectionRequest?: VisitCollectionRequestCreateNestedOneWithoutCommunicationsInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutCommunicationInput | null;
}

export interface CommunicationCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutCommunicationsInput | null;
  insurancePolicy?: InsurancePolicyCreateNestedOneWithoutCommunicationsInput | null;
  patient?: PatientCreateNestedOneWithoutCommunicationsInput | null;
  sentBy?: UserCreateNestedOneWithoutSentCommunicationsInput | null;
  bills?: BillCreateNestedManyWithoutCommunicationsInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutCommunicationsInput | null;
  visitCollectionRequest?: VisitCollectionRequestCreateNestedOneWithoutCommunicationsInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutCommunicationInput | null;
}

export interface CommunicationCreateWithoutBillsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutCommunicationsInput | null;
  insurancePolicy?: InsurancePolicyCreateNestedOneWithoutCommunicationsInput | null;
  patient?: PatientCreateNestedOneWithoutCommunicationsInput | null;
  sentBy?: UserCreateNestedOneWithoutSentCommunicationsInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutCommunicationsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutCommunicationsInput | null;
  visitCollectionRequest?: VisitCollectionRequestCreateNestedOneWithoutCommunicationsInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutCommunicationInput | null;
}

export interface CommunicationCreateWithoutInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutCommunicationsInput | null;
  patient?: PatientCreateNestedOneWithoutCommunicationsInput | null;
  sentBy?: UserCreateNestedOneWithoutSentCommunicationsInput | null;
  bills?: BillCreateNestedManyWithoutCommunicationsInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutCommunicationsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutCommunicationsInput | null;
  visitCollectionRequest?: VisitCollectionRequestCreateNestedOneWithoutCommunicationsInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutCommunicationInput | null;
}

export interface CommunicationCreateWithoutPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutCommunicationsInput | null;
  insurancePolicy?: InsurancePolicyCreateNestedOneWithoutCommunicationsInput | null;
  sentBy?: UserCreateNestedOneWithoutSentCommunicationsInput | null;
  bills?: BillCreateNestedManyWithoutCommunicationsInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutCommunicationsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutCommunicationsInput | null;
  visitCollectionRequest?: VisitCollectionRequestCreateNestedOneWithoutCommunicationsInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutCommunicationInput | null;
}

export interface CommunicationCreateWithoutPaymentRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutCommunicationsInput | null;
  insurancePolicy?: InsurancePolicyCreateNestedOneWithoutCommunicationsInput | null;
  patient?: PatientCreateNestedOneWithoutCommunicationsInput | null;
  sentBy?: UserCreateNestedOneWithoutSentCommunicationsInput | null;
  bills?: BillCreateNestedManyWithoutCommunicationsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutCommunicationsInput | null;
  visitCollectionRequest?: VisitCollectionRequestCreateNestedOneWithoutCommunicationsInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutCommunicationInput | null;
}

export interface CommunicationCreateWithoutSentByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutCommunicationsInput | null;
  insurancePolicy?: InsurancePolicyCreateNestedOneWithoutCommunicationsInput | null;
  patient?: PatientCreateNestedOneWithoutCommunicationsInput | null;
  bills?: BillCreateNestedManyWithoutCommunicationsInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutCommunicationsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutCommunicationsInput | null;
  visitCollectionRequest?: VisitCollectionRequestCreateNestedOneWithoutCommunicationsInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutCommunicationInput | null;
}

export interface CommunicationCreateWithoutShortLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutCommunicationsInput | null;
  insurancePolicy?: InsurancePolicyCreateNestedOneWithoutCommunicationsInput | null;
  patient?: PatientCreateNestedOneWithoutCommunicationsInput | null;
  sentBy?: UserCreateNestedOneWithoutSentCommunicationsInput | null;
  bills?: BillCreateNestedManyWithoutCommunicationsInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutCommunicationsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutCommunicationsInput | null;
  visitCollectionRequest?: VisitCollectionRequestCreateNestedOneWithoutCommunicationsInput | null;
}

export interface CommunicationCreateWithoutVisitCollectionRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: CommunicationType;
  contentType: CommunicationContentType;
  handle?: string | null;
  messageId?: string | null;
  scheduledAt?: any | null;
  sentAt?: any | null;
  lastOpenedAt?: any | null;
  firstClickedAt?: any | null;
  openCount?: number | null;
  lastErrorType?: CommunicationErrorType | null;
  lastErrorReason?: string | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutCommunicationsInput | null;
  insurancePolicy?: InsurancePolicyCreateNestedOneWithoutCommunicationsInput | null;
  patient?: PatientCreateNestedOneWithoutCommunicationsInput | null;
  sentBy?: UserCreateNestedOneWithoutSentCommunicationsInput | null;
  bills?: BillCreateNestedManyWithoutCommunicationsInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutCommunicationsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutCommunicationsInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutCommunicationInput | null;
}

export interface CommunicationListRelationFilter {
  every?: CommunicationWhereInput | null;
  some?: CommunicationWhereInput | null;
  none?: CommunicationWhereInput | null;
}

export interface CommunicationOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface CommunicationRelationFilter {
  is?: CommunicationWhereInput | null;
  isNot?: CommunicationWhereInput | null;
}

export interface CommunicationScalarWhereInput {
  AND?: CommunicationScalarWhereInput[] | null;
  OR?: CommunicationScalarWhereInput[] | null;
  NOT?: CommunicationScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  type?: EnumCommunicationTypeFilter | null;
  contentType?: EnumCommunicationContentTypeFilter | null;
  handle?: StringNullableFilter | null;
  messageId?: StringNullableFilter | null;
  scheduledAt?: DateTimeNullableFilter | null;
  sentAt?: DateTimeNullableFilter | null;
  lastOpenedAt?: DateTimeNullableFilter | null;
  firstClickedAt?: DateTimeNullableFilter | null;
  openCount?: IntNullableFilter | null;
  lastErrorType?: EnumCommunicationErrorTypeNullableFilter | null;
  lastErrorReason?: StringNullableFilter | null;
  accountCoverageId?: UuidNullableFilter | null;
  insurancePolicyId?: UuidNullableFilter | null;
  patientId?: UuidNullableFilter | null;
  sentById?: UuidNullableFilter | null;
  paymentRequestId?: UuidNullableFilter | null;
  appointmentId?: UuidNullableFilter | null;
  visitCollectionRequestId?: UuidNullableFilter | null;
}

export interface CommunicationUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumCommunicationTypeFieldUpdateOperationsInput | null;
  contentType?: EnumCommunicationContentTypeFieldUpdateOperationsInput | null;
  handle?: NullableStringFieldUpdateOperationsInput | null;
  messageId?: NullableStringFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sentAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastOpenedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  firstClickedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  openCount?: NullableIntFieldUpdateOperationsInput | null;
  lastErrorType?: NullableEnumCommunicationErrorTypeFieldUpdateOperationsInput | null;
  lastErrorReason?: NullableStringFieldUpdateOperationsInput | null;
}

export interface CommunicationUpdateManyWithWhereWithoutAccountCoverageInput {
  where: CommunicationScalarWhereInput;
  data: CommunicationUpdateManyMutationInput;
}

export interface CommunicationUpdateManyWithWhereWithoutAppointmentInput {
  where: CommunicationScalarWhereInput;
  data: CommunicationUpdateManyMutationInput;
}

export interface CommunicationUpdateManyWithWhereWithoutBillsInput {
  where: CommunicationScalarWhereInput;
  data: CommunicationUpdateManyMutationInput;
}

export interface CommunicationUpdateManyWithWhereWithoutInsurancePolicyInput {
  where: CommunicationScalarWhereInput;
  data: CommunicationUpdateManyMutationInput;
}

export interface CommunicationUpdateManyWithWhereWithoutPatientInput {
  where: CommunicationScalarWhereInput;
  data: CommunicationUpdateManyMutationInput;
}

export interface CommunicationUpdateManyWithWhereWithoutPaymentRequestInput {
  where: CommunicationScalarWhereInput;
  data: CommunicationUpdateManyMutationInput;
}

export interface CommunicationUpdateManyWithWhereWithoutSentByInput {
  where: CommunicationScalarWhereInput;
  data: CommunicationUpdateManyMutationInput;
}

export interface CommunicationUpdateManyWithWhereWithoutVisitCollectionRequestInput {
  where: CommunicationScalarWhereInput;
  data: CommunicationUpdateManyMutationInput;
}

export interface CommunicationUpdateManyWithoutAccountCoverageNestedInput {
  create?: CommunicationCreateWithoutAccountCoverageInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutAccountCoverageInput[] | null;
  upsert?: CommunicationUpsertWithWhereUniqueWithoutAccountCoverageInput[] | null;
  createMany?: CommunicationCreateManyAccountCoverageInputEnvelope | null;
  set?: CommunicationWhereUniqueInput[] | null;
  disconnect?: CommunicationWhereUniqueInput[] | null;
  delete?: CommunicationWhereUniqueInput[] | null;
  connect?: CommunicationWhereUniqueInput[] | null;
  update?: CommunicationUpdateWithWhereUniqueWithoutAccountCoverageInput[] | null;
  updateMany?: CommunicationUpdateManyWithWhereWithoutAccountCoverageInput[] | null;
  deleteMany?: CommunicationScalarWhereInput[] | null;
}

export interface CommunicationUpdateManyWithoutAppointmentNestedInput {
  create?: CommunicationCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: CommunicationUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: CommunicationCreateManyAppointmentInputEnvelope | null;
  set?: CommunicationWhereUniqueInput[] | null;
  disconnect?: CommunicationWhereUniqueInput[] | null;
  delete?: CommunicationWhereUniqueInput[] | null;
  connect?: CommunicationWhereUniqueInput[] | null;
  update?: CommunicationUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: CommunicationUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: CommunicationScalarWhereInput[] | null;
}

export interface CommunicationUpdateManyWithoutBillsNestedInput {
  create?: CommunicationCreateWithoutBillsInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutBillsInput[] | null;
  upsert?: CommunicationUpsertWithWhereUniqueWithoutBillsInput[] | null;
  set?: CommunicationWhereUniqueInput[] | null;
  disconnect?: CommunicationWhereUniqueInput[] | null;
  delete?: CommunicationWhereUniqueInput[] | null;
  connect?: CommunicationWhereUniqueInput[] | null;
  update?: CommunicationUpdateWithWhereUniqueWithoutBillsInput[] | null;
  updateMany?: CommunicationUpdateManyWithWhereWithoutBillsInput[] | null;
  deleteMany?: CommunicationScalarWhereInput[] | null;
}

export interface CommunicationUpdateManyWithoutInsurancePolicyNestedInput {
  create?: CommunicationCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutInsurancePolicyInput[] | null;
  upsert?: CommunicationUpsertWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  createMany?: CommunicationCreateManyInsurancePolicyInputEnvelope | null;
  set?: CommunicationWhereUniqueInput[] | null;
  disconnect?: CommunicationWhereUniqueInput[] | null;
  delete?: CommunicationWhereUniqueInput[] | null;
  connect?: CommunicationWhereUniqueInput[] | null;
  update?: CommunicationUpdateWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  updateMany?: CommunicationUpdateManyWithWhereWithoutInsurancePolicyInput[] | null;
  deleteMany?: CommunicationScalarWhereInput[] | null;
}

export interface CommunicationUpdateManyWithoutPatientNestedInput {
  create?: CommunicationCreateWithoutPatientInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutPatientInput[] | null;
  upsert?: CommunicationUpsertWithWhereUniqueWithoutPatientInput[] | null;
  createMany?: CommunicationCreateManyPatientInputEnvelope | null;
  set?: CommunicationWhereUniqueInput[] | null;
  disconnect?: CommunicationWhereUniqueInput[] | null;
  delete?: CommunicationWhereUniqueInput[] | null;
  connect?: CommunicationWhereUniqueInput[] | null;
  update?: CommunicationUpdateWithWhereUniqueWithoutPatientInput[] | null;
  updateMany?: CommunicationUpdateManyWithWhereWithoutPatientInput[] | null;
  deleteMany?: CommunicationScalarWhereInput[] | null;
}

export interface CommunicationUpdateManyWithoutPaymentRequestNestedInput {
  create?: CommunicationCreateWithoutPaymentRequestInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutPaymentRequestInput[] | null;
  upsert?: CommunicationUpsertWithWhereUniqueWithoutPaymentRequestInput[] | null;
  createMany?: CommunicationCreateManyPaymentRequestInputEnvelope | null;
  set?: CommunicationWhereUniqueInput[] | null;
  disconnect?: CommunicationWhereUniqueInput[] | null;
  delete?: CommunicationWhereUniqueInput[] | null;
  connect?: CommunicationWhereUniqueInput[] | null;
  update?: CommunicationUpdateWithWhereUniqueWithoutPaymentRequestInput[] | null;
  updateMany?: CommunicationUpdateManyWithWhereWithoutPaymentRequestInput[] | null;
  deleteMany?: CommunicationScalarWhereInput[] | null;
}

export interface CommunicationUpdateManyWithoutSentByNestedInput {
  create?: CommunicationCreateWithoutSentByInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutSentByInput[] | null;
  upsert?: CommunicationUpsertWithWhereUniqueWithoutSentByInput[] | null;
  createMany?: CommunicationCreateManySentByInputEnvelope | null;
  set?: CommunicationWhereUniqueInput[] | null;
  disconnect?: CommunicationWhereUniqueInput[] | null;
  delete?: CommunicationWhereUniqueInput[] | null;
  connect?: CommunicationWhereUniqueInput[] | null;
  update?: CommunicationUpdateWithWhereUniqueWithoutSentByInput[] | null;
  updateMany?: CommunicationUpdateManyWithWhereWithoutSentByInput[] | null;
  deleteMany?: CommunicationScalarWhereInput[] | null;
}

export interface CommunicationUpdateManyWithoutVisitCollectionRequestNestedInput {
  create?: CommunicationCreateWithoutVisitCollectionRequestInput[] | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutVisitCollectionRequestInput[] | null;
  upsert?: CommunicationUpsertWithWhereUniqueWithoutVisitCollectionRequestInput[] | null;
  createMany?: CommunicationCreateManyVisitCollectionRequestInputEnvelope | null;
  set?: CommunicationWhereUniqueInput[] | null;
  disconnect?: CommunicationWhereUniqueInput[] | null;
  delete?: CommunicationWhereUniqueInput[] | null;
  connect?: CommunicationWhereUniqueInput[] | null;
  update?: CommunicationUpdateWithWhereUniqueWithoutVisitCollectionRequestInput[] | null;
  updateMany?: CommunicationUpdateManyWithWhereWithoutVisitCollectionRequestInput[] | null;
  deleteMany?: CommunicationScalarWhereInput[] | null;
}

export interface CommunicationUpdateOneWithoutShortLinksNestedInput {
  create?: CommunicationCreateWithoutShortLinksInput | null;
  connectOrCreate?: CommunicationCreateOrConnectWithoutShortLinksInput | null;
  upsert?: CommunicationUpsertWithoutShortLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: CommunicationWhereUniqueInput | null;
  update?: CommunicationUpdateWithoutShortLinksInput | null;
}

export interface CommunicationUpdateWithWhereUniqueWithoutAccountCoverageInput {
  where: CommunicationWhereUniqueInput;
  data: CommunicationUpdateWithoutAccountCoverageInput;
}

export interface CommunicationUpdateWithWhereUniqueWithoutAppointmentInput {
  where: CommunicationWhereUniqueInput;
  data: CommunicationUpdateWithoutAppointmentInput;
}

export interface CommunicationUpdateWithWhereUniqueWithoutBillsInput {
  where: CommunicationWhereUniqueInput;
  data: CommunicationUpdateWithoutBillsInput;
}

export interface CommunicationUpdateWithWhereUniqueWithoutInsurancePolicyInput {
  where: CommunicationWhereUniqueInput;
  data: CommunicationUpdateWithoutInsurancePolicyInput;
}

export interface CommunicationUpdateWithWhereUniqueWithoutPatientInput {
  where: CommunicationWhereUniqueInput;
  data: CommunicationUpdateWithoutPatientInput;
}

export interface CommunicationUpdateWithWhereUniqueWithoutPaymentRequestInput {
  where: CommunicationWhereUniqueInput;
  data: CommunicationUpdateWithoutPaymentRequestInput;
}

export interface CommunicationUpdateWithWhereUniqueWithoutSentByInput {
  where: CommunicationWhereUniqueInput;
  data: CommunicationUpdateWithoutSentByInput;
}

export interface CommunicationUpdateWithWhereUniqueWithoutVisitCollectionRequestInput {
  where: CommunicationWhereUniqueInput;
  data: CommunicationUpdateWithoutVisitCollectionRequestInput;
}

export interface CommunicationUpdateWithoutAccountCoverageInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumCommunicationTypeFieldUpdateOperationsInput | null;
  contentType?: EnumCommunicationContentTypeFieldUpdateOperationsInput | null;
  handle?: NullableStringFieldUpdateOperationsInput | null;
  messageId?: NullableStringFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sentAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastOpenedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  firstClickedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  openCount?: NullableIntFieldUpdateOperationsInput | null;
  lastErrorType?: NullableEnumCommunicationErrorTypeFieldUpdateOperationsInput | null;
  lastErrorReason?: NullableStringFieldUpdateOperationsInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneWithoutCommunicationsNestedInput | null;
  patient?: PatientUpdateOneWithoutCommunicationsNestedInput | null;
  sentBy?: UserUpdateOneWithoutSentCommunicationsNestedInput | null;
  bills?: BillUpdateManyWithoutCommunicationsNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutCommunicationsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutCommunicationsNestedInput | null;
  visitCollectionRequest?: VisitCollectionRequestUpdateOneWithoutCommunicationsNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutCommunicationNestedInput | null;
}

export interface CommunicationUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumCommunicationTypeFieldUpdateOperationsInput | null;
  contentType?: EnumCommunicationContentTypeFieldUpdateOperationsInput | null;
  handle?: NullableStringFieldUpdateOperationsInput | null;
  messageId?: NullableStringFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sentAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastOpenedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  firstClickedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  openCount?: NullableIntFieldUpdateOperationsInput | null;
  lastErrorType?: NullableEnumCommunicationErrorTypeFieldUpdateOperationsInput | null;
  lastErrorReason?: NullableStringFieldUpdateOperationsInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutCommunicationsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneWithoutCommunicationsNestedInput | null;
  patient?: PatientUpdateOneWithoutCommunicationsNestedInput | null;
  sentBy?: UserUpdateOneWithoutSentCommunicationsNestedInput | null;
  bills?: BillUpdateManyWithoutCommunicationsNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutCommunicationsNestedInput | null;
  visitCollectionRequest?: VisitCollectionRequestUpdateOneWithoutCommunicationsNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutCommunicationNestedInput | null;
}

export interface CommunicationUpdateWithoutBillsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumCommunicationTypeFieldUpdateOperationsInput | null;
  contentType?: EnumCommunicationContentTypeFieldUpdateOperationsInput | null;
  handle?: NullableStringFieldUpdateOperationsInput | null;
  messageId?: NullableStringFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sentAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastOpenedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  firstClickedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  openCount?: NullableIntFieldUpdateOperationsInput | null;
  lastErrorType?: NullableEnumCommunicationErrorTypeFieldUpdateOperationsInput | null;
  lastErrorReason?: NullableStringFieldUpdateOperationsInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutCommunicationsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneWithoutCommunicationsNestedInput | null;
  patient?: PatientUpdateOneWithoutCommunicationsNestedInput | null;
  sentBy?: UserUpdateOneWithoutSentCommunicationsNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutCommunicationsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutCommunicationsNestedInput | null;
  visitCollectionRequest?: VisitCollectionRequestUpdateOneWithoutCommunicationsNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutCommunicationNestedInput | null;
}

export interface CommunicationUpdateWithoutInsurancePolicyInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumCommunicationTypeFieldUpdateOperationsInput | null;
  contentType?: EnumCommunicationContentTypeFieldUpdateOperationsInput | null;
  handle?: NullableStringFieldUpdateOperationsInput | null;
  messageId?: NullableStringFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sentAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastOpenedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  firstClickedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  openCount?: NullableIntFieldUpdateOperationsInput | null;
  lastErrorType?: NullableEnumCommunicationErrorTypeFieldUpdateOperationsInput | null;
  lastErrorReason?: NullableStringFieldUpdateOperationsInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutCommunicationsNestedInput | null;
  patient?: PatientUpdateOneWithoutCommunicationsNestedInput | null;
  sentBy?: UserUpdateOneWithoutSentCommunicationsNestedInput | null;
  bills?: BillUpdateManyWithoutCommunicationsNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutCommunicationsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutCommunicationsNestedInput | null;
  visitCollectionRequest?: VisitCollectionRequestUpdateOneWithoutCommunicationsNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutCommunicationNestedInput | null;
}

export interface CommunicationUpdateWithoutPatientInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumCommunicationTypeFieldUpdateOperationsInput | null;
  contentType?: EnumCommunicationContentTypeFieldUpdateOperationsInput | null;
  handle?: NullableStringFieldUpdateOperationsInput | null;
  messageId?: NullableStringFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sentAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastOpenedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  firstClickedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  openCount?: NullableIntFieldUpdateOperationsInput | null;
  lastErrorType?: NullableEnumCommunicationErrorTypeFieldUpdateOperationsInput | null;
  lastErrorReason?: NullableStringFieldUpdateOperationsInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutCommunicationsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneWithoutCommunicationsNestedInput | null;
  sentBy?: UserUpdateOneWithoutSentCommunicationsNestedInput | null;
  bills?: BillUpdateManyWithoutCommunicationsNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutCommunicationsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutCommunicationsNestedInput | null;
  visitCollectionRequest?: VisitCollectionRequestUpdateOneWithoutCommunicationsNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutCommunicationNestedInput | null;
}

export interface CommunicationUpdateWithoutPaymentRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumCommunicationTypeFieldUpdateOperationsInput | null;
  contentType?: EnumCommunicationContentTypeFieldUpdateOperationsInput | null;
  handle?: NullableStringFieldUpdateOperationsInput | null;
  messageId?: NullableStringFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sentAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastOpenedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  firstClickedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  openCount?: NullableIntFieldUpdateOperationsInput | null;
  lastErrorType?: NullableEnumCommunicationErrorTypeFieldUpdateOperationsInput | null;
  lastErrorReason?: NullableStringFieldUpdateOperationsInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutCommunicationsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneWithoutCommunicationsNestedInput | null;
  patient?: PatientUpdateOneWithoutCommunicationsNestedInput | null;
  sentBy?: UserUpdateOneWithoutSentCommunicationsNestedInput | null;
  bills?: BillUpdateManyWithoutCommunicationsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutCommunicationsNestedInput | null;
  visitCollectionRequest?: VisitCollectionRequestUpdateOneWithoutCommunicationsNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutCommunicationNestedInput | null;
}

export interface CommunicationUpdateWithoutSentByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumCommunicationTypeFieldUpdateOperationsInput | null;
  contentType?: EnumCommunicationContentTypeFieldUpdateOperationsInput | null;
  handle?: NullableStringFieldUpdateOperationsInput | null;
  messageId?: NullableStringFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sentAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastOpenedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  firstClickedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  openCount?: NullableIntFieldUpdateOperationsInput | null;
  lastErrorType?: NullableEnumCommunicationErrorTypeFieldUpdateOperationsInput | null;
  lastErrorReason?: NullableStringFieldUpdateOperationsInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutCommunicationsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneWithoutCommunicationsNestedInput | null;
  patient?: PatientUpdateOneWithoutCommunicationsNestedInput | null;
  bills?: BillUpdateManyWithoutCommunicationsNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutCommunicationsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutCommunicationsNestedInput | null;
  visitCollectionRequest?: VisitCollectionRequestUpdateOneWithoutCommunicationsNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutCommunicationNestedInput | null;
}

export interface CommunicationUpdateWithoutShortLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumCommunicationTypeFieldUpdateOperationsInput | null;
  contentType?: EnumCommunicationContentTypeFieldUpdateOperationsInput | null;
  handle?: NullableStringFieldUpdateOperationsInput | null;
  messageId?: NullableStringFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sentAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastOpenedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  firstClickedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  openCount?: NullableIntFieldUpdateOperationsInput | null;
  lastErrorType?: NullableEnumCommunicationErrorTypeFieldUpdateOperationsInput | null;
  lastErrorReason?: NullableStringFieldUpdateOperationsInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutCommunicationsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneWithoutCommunicationsNestedInput | null;
  patient?: PatientUpdateOneWithoutCommunicationsNestedInput | null;
  sentBy?: UserUpdateOneWithoutSentCommunicationsNestedInput | null;
  bills?: BillUpdateManyWithoutCommunicationsNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutCommunicationsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutCommunicationsNestedInput | null;
  visitCollectionRequest?: VisitCollectionRequestUpdateOneWithoutCommunicationsNestedInput | null;
}

export interface CommunicationUpdateWithoutVisitCollectionRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumCommunicationTypeFieldUpdateOperationsInput | null;
  contentType?: EnumCommunicationContentTypeFieldUpdateOperationsInput | null;
  handle?: NullableStringFieldUpdateOperationsInput | null;
  messageId?: NullableStringFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sentAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastOpenedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  firstClickedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  openCount?: NullableIntFieldUpdateOperationsInput | null;
  lastErrorType?: NullableEnumCommunicationErrorTypeFieldUpdateOperationsInput | null;
  lastErrorReason?: NullableStringFieldUpdateOperationsInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutCommunicationsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneWithoutCommunicationsNestedInput | null;
  patient?: PatientUpdateOneWithoutCommunicationsNestedInput | null;
  sentBy?: UserUpdateOneWithoutSentCommunicationsNestedInput | null;
  bills?: BillUpdateManyWithoutCommunicationsNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutCommunicationsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutCommunicationsNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutCommunicationNestedInput | null;
}

export interface CommunicationUpsertWithWhereUniqueWithoutAccountCoverageInput {
  where: CommunicationWhereUniqueInput;
  update: CommunicationUpdateWithoutAccountCoverageInput;
  create: CommunicationCreateWithoutAccountCoverageInput;
}

export interface CommunicationUpsertWithWhereUniqueWithoutAppointmentInput {
  where: CommunicationWhereUniqueInput;
  update: CommunicationUpdateWithoutAppointmentInput;
  create: CommunicationCreateWithoutAppointmentInput;
}

export interface CommunicationUpsertWithWhereUniqueWithoutBillsInput {
  where: CommunicationWhereUniqueInput;
  update: CommunicationUpdateWithoutBillsInput;
  create: CommunicationCreateWithoutBillsInput;
}

export interface CommunicationUpsertWithWhereUniqueWithoutInsurancePolicyInput {
  where: CommunicationWhereUniqueInput;
  update: CommunicationUpdateWithoutInsurancePolicyInput;
  create: CommunicationCreateWithoutInsurancePolicyInput;
}

export interface CommunicationUpsertWithWhereUniqueWithoutPatientInput {
  where: CommunicationWhereUniqueInput;
  update: CommunicationUpdateWithoutPatientInput;
  create: CommunicationCreateWithoutPatientInput;
}

export interface CommunicationUpsertWithWhereUniqueWithoutPaymentRequestInput {
  where: CommunicationWhereUniqueInput;
  update: CommunicationUpdateWithoutPaymentRequestInput;
  create: CommunicationCreateWithoutPaymentRequestInput;
}

export interface CommunicationUpsertWithWhereUniqueWithoutSentByInput {
  where: CommunicationWhereUniqueInput;
  update: CommunicationUpdateWithoutSentByInput;
  create: CommunicationCreateWithoutSentByInput;
}

export interface CommunicationUpsertWithWhereUniqueWithoutVisitCollectionRequestInput {
  where: CommunicationWhereUniqueInput;
  update: CommunicationUpdateWithoutVisitCollectionRequestInput;
  create: CommunicationCreateWithoutVisitCollectionRequestInput;
}

export interface CommunicationUpsertWithoutShortLinksInput {
  update: CommunicationUpdateWithoutShortLinksInput;
  create: CommunicationCreateWithoutShortLinksInput;
}

export interface CommunicationWhereInput {
  AND?: CommunicationWhereInput[] | null;
  OR?: CommunicationWhereInput[] | null;
  NOT?: CommunicationWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  type?: EnumCommunicationTypeFilter | null;
  contentType?: EnumCommunicationContentTypeFilter | null;
  handle?: StringNullableFilter | null;
  messageId?: StringNullableFilter | null;
  scheduledAt?: DateTimeNullableFilter | null;
  sentAt?: DateTimeNullableFilter | null;
  lastOpenedAt?: DateTimeNullableFilter | null;
  firstClickedAt?: DateTimeNullableFilter | null;
  openCount?: IntNullableFilter | null;
  lastErrorType?: EnumCommunicationErrorTypeNullableFilter | null;
  lastErrorReason?: StringNullableFilter | null;
  accountCoverageId?: UuidNullableFilter | null;
  insurancePolicyId?: UuidNullableFilter | null;
  patientId?: UuidNullableFilter | null;
  sentById?: UuidNullableFilter | null;
  paymentRequestId?: UuidNullableFilter | null;
  appointmentId?: UuidNullableFilter | null;
  visitCollectionRequestId?: UuidNullableFilter | null;
  accountCoverage?: AccountCoverageRelationFilter | null;
  insurancePolicy?: InsurancePolicyRelationFilter | null;
  patient?: PatientRelationFilter | null;
  sentBy?: UserRelationFilter | null;
  bills?: BillListRelationFilter | null;
  paymentRequest?: PaymentRequestRelationFilter | null;
  appointment?: AppointmentRelationFilter | null;
  visitCollectionRequest?: VisitCollectionRequestRelationFilter | null;
  shortLinks?: ShortLinkListRelationFilter | null;
}

export interface CommunicationWhereUniqueInput {
  id?: string | null;
}

export interface CopayBenefitCreateManyEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  amount: number;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: CopayBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
}

export interface CopayBenefitCreateManyEligibilityRequestInputEnvelope {
  data: CopayBenefitCreateManyEligibilityRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface CopayBenefitCreateNestedManyWithoutEligibilityRequestInput {
  create?: CopayBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: CopayBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  createMany?: CopayBenefitCreateManyEligibilityRequestInputEnvelope | null;
  connect?: CopayBenefitWhereUniqueInput[] | null;
}

export interface CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput {
  create?: CopayBenefitCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: CopayBenefitCreateOrConnectWithoutCoverageBenefitsInput | null;
  connect?: CopayBenefitWhereUniqueInput | null;
}

export interface CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput {
  create?: CopayBenefitCreateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connectOrCreate?: CopayBenefitCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connect?: CopayBenefitWhereUniqueInput | null;
}

export interface CopayBenefitCreateOrConnectWithoutCoverageBenefitsInput {
  where: CopayBenefitWhereUniqueInput;
  create: CopayBenefitCreateWithoutCoverageBenefitsInput;
}

export interface CopayBenefitCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput {
  where: CopayBenefitWhereUniqueInput;
  create: CopayBenefitCreateWithoutEligibilityCoverageBenefitSnapshotsInput;
}

export interface CopayBenefitCreateOrConnectWithoutEligibilityRequestInput {
  where: CopayBenefitWhereUniqueInput;
  create: CopayBenefitCreateWithoutEligibilityRequestInput;
}

export interface CopayBenefitCreateWithoutCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  amount: number;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: CopayBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutCopayBenefitsInput;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutCopayBenefitInput | null;
}

export interface CopayBenefitCreateWithoutEligibilityCoverageBenefitSnapshotsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  amount: number;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: CopayBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutCopayBenefitsInput;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCopayBenefitInput | null;
}

export interface CopayBenefitCreateWithoutEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  amount: number;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: CopayBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutCopayBenefitInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCopayBenefitInput | null;
}

export interface CopayBenefitCreatenotesInput {
  set: string[];
}

export interface CopayBenefitListRelationFilter {
  every?: CopayBenefitWhereInput | null;
  some?: CopayBenefitWhereInput | null;
  none?: CopayBenefitWhereInput | null;
}

export interface CopayBenefitRelationFilter {
  is?: CopayBenefitWhereInput | null;
  isNot?: CopayBenefitWhereInput | null;
}

export interface CopayBenefitScalarWhereInput {
  AND?: CopayBenefitScalarWhereInput[] | null;
  OR?: CopayBenefitScalarWhereInput[] | null;
  NOT?: CopayBenefitScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  amount?: IntFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
}

export interface CopayBenefitUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: CopayBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
}

export interface CopayBenefitUpdateManyWithWhereWithoutEligibilityRequestInput {
  where: CopayBenefitScalarWhereInput;
  data: CopayBenefitUpdateManyMutationInput;
}

export interface CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput {
  create?: CopayBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: CopayBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  upsert?: CopayBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  createMany?: CopayBenefitCreateManyEligibilityRequestInputEnvelope | null;
  set?: CopayBenefitWhereUniqueInput[] | null;
  disconnect?: CopayBenefitWhereUniqueInput[] | null;
  delete?: CopayBenefitWhereUniqueInput[] | null;
  connect?: CopayBenefitWhereUniqueInput[] | null;
  update?: CopayBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  updateMany?: CopayBenefitUpdateManyWithWhereWithoutEligibilityRequestInput[] | null;
  deleteMany?: CopayBenefitScalarWhereInput[] | null;
}

export interface CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput {
  create?: CopayBenefitCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: CopayBenefitCreateOrConnectWithoutCoverageBenefitsInput | null;
  upsert?: CopayBenefitUpsertWithoutCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: CopayBenefitWhereUniqueInput | null;
  update?: CopayBenefitUpdateWithoutCoverageBenefitsInput | null;
}

export interface CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput {
  create?: CopayBenefitCreateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connectOrCreate?: CopayBenefitCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  upsert?: CopayBenefitUpsertWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: CopayBenefitWhereUniqueInput | null;
  update?: CopayBenefitUpdateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
}

export interface CopayBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput {
  where: CopayBenefitWhereUniqueInput;
  data: CopayBenefitUpdateWithoutEligibilityRequestInput;
}

export interface CopayBenefitUpdateWithoutCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: CopayBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutCopayBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutCopayBenefitNestedInput | null;
}

export interface CopayBenefitUpdateWithoutEligibilityCoverageBenefitSnapshotsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: CopayBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutCopayBenefitsNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCopayBenefitNestedInput | null;
}

export interface CopayBenefitUpdateWithoutEligibilityRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: CopayBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutCopayBenefitNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCopayBenefitNestedInput | null;
}

export interface CopayBenefitUpdatenotesInput {
  set?: string[] | null;
  push?: string[] | null;
}

export interface CopayBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput {
  where: CopayBenefitWhereUniqueInput;
  update: CopayBenefitUpdateWithoutEligibilityRequestInput;
  create: CopayBenefitCreateWithoutEligibilityRequestInput;
}

export interface CopayBenefitUpsertWithoutCoverageBenefitsInput {
  update: CopayBenefitUpdateWithoutCoverageBenefitsInput;
  create: CopayBenefitCreateWithoutCoverageBenefitsInput;
}

export interface CopayBenefitUpsertWithoutEligibilityCoverageBenefitSnapshotsInput {
  update: CopayBenefitUpdateWithoutEligibilityCoverageBenefitSnapshotsInput;
  create: CopayBenefitCreateWithoutEligibilityCoverageBenefitSnapshotsInput;
}

export interface CopayBenefitWhereInput {
  AND?: CopayBenefitWhereInput[] | null;
  OR?: CopayBenefitWhereInput[] | null;
  NOT?: CopayBenefitWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  amount?: IntFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
  eligibilityRequest?: EligibilityRequestRelationFilter | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
  coverageBenefits?: CoverageBenefitListRelationFilter | null;
}

export interface CopayBenefitWhereUniqueInput {
  id?: string | null;
}

export interface CoverageBenefitCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateManyActiveCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyActiveCoverageBenefitInputEnvelope {
  data: CoverageBenefitCreateManyActiveCoverageBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyCoinsuranceBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyCoinsuranceBenefitInputEnvelope {
  data: CoverageBenefitCreateManyCoinsuranceBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyCopayBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyCopayBenefitInputEnvelope {
  data: CoverageBenefitCreateManyCopayBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyCreatedByInputEnvelope {
  data: CoverageBenefitCreateManyCreatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyDeductibleAppliesBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyDeductibleAppliesBenefitInputEnvelope {
  data: CoverageBenefitCreateManyDeductibleAppliesBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyEligibilityCoverageBenefitSnapshotInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyEligibilityCoverageBenefitSnapshotInputEnvelope {
  data: CoverageBenefitCreateManyEligibilityCoverageBenefitSnapshotInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyInsurancePolicyInputEnvelope {
  data: CoverageBenefitCreateManyInsurancePolicyInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyMaxDeductibleBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyMaxDeductibleBenefitInputEnvelope {
  data: CoverageBenefitCreateManyMaxDeductibleBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyMaxOutOfPocketBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyMaxOutOfPocketBenefitInputEnvelope {
  data: CoverageBenefitCreateManyMaxOutOfPocketBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyMaxVisitsBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyMaxVisitsBenefitInputEnvelope {
  data: CoverageBenefitCreateManyMaxVisitsBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyNonCoveredBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyNonCoveredBenefitInputEnvelope {
  data: CoverageBenefitCreateManyNonCoveredBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyPlanBenefitTemplateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
}

export interface CoverageBenefitCreateManyPlanBenefitTemplateInputEnvelope {
  data: CoverageBenefitCreateManyPlanBenefitTemplateInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyProviderServiceConfigurationInputEnvelope {
  data: CoverageBenefitCreateManyProviderServiceConfigurationInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyRemainingDeductibleBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyRemainingDeductibleBenefitInputEnvelope {
  data: CoverageBenefitCreateManyRemainingDeductibleBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyRemainingOutOfPocketBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyRemainingOutOfPocketBenefitInputEnvelope {
  data: CoverageBenefitCreateManyRemainingOutOfPocketBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateManyRemainingVisitsBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  createdById?: string | null;
  providerServiceConfigurationId: string;
  eligibilityCoverageBenefitSnapshotId?: string | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  insurancePolicyId: string;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
  locked?: boolean | null;
  planBenefitTemplateId?: string | null;
}

export interface CoverageBenefitCreateManyRemainingVisitsBenefitInputEnvelope {
  data: CoverageBenefitCreateManyRemainingVisitsBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface CoverageBenefitCreateNestedManyWithoutActiveCoverageBenefitInput {
  create?: CoverageBenefitCreateWithoutActiveCoverageBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutActiveCoverageBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyActiveCoverageBenefitInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutCoinsuranceBenefitInput {
  create?: CoverageBenefitCreateWithoutCoinsuranceBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutCoinsuranceBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyCoinsuranceBenefitInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutCopayBenefitInput {
  create?: CoverageBenefitCreateWithoutCopayBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutCopayBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyCopayBenefitInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutCreatedByInput {
  create?: CoverageBenefitCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: CoverageBenefitCreateManyCreatedByInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutDeductibleAppliesBenefitInput {
  create?: CoverageBenefitCreateWithoutDeductibleAppliesBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutDeductibleAppliesBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyDeductibleAppliesBenefitInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput {
  create?: CoverageBenefitCreateWithoutEligibilityCoverageBenefitSnapshotInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotInput[] | null;
  createMany?: CoverageBenefitCreateManyEligibilityCoverageBenefitSnapshotInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput {
  create?: CoverageBenefitCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutInsurancePolicyInput[] | null;
  createMany?: CoverageBenefitCreateManyInsurancePolicyInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutMaxDeductibleBenefitInput {
  create?: CoverageBenefitCreateWithoutMaxDeductibleBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutMaxDeductibleBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyMaxDeductibleBenefitInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutMaxOutOfPocketBenefitInput {
  create?: CoverageBenefitCreateWithoutMaxOutOfPocketBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutMaxOutOfPocketBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyMaxOutOfPocketBenefitInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutMaxVisitsBenefitInput {
  create?: CoverageBenefitCreateWithoutMaxVisitsBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutMaxVisitsBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyMaxVisitsBenefitInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutNonCoveredBenefitInput {
  create?: CoverageBenefitCreateWithoutNonCoveredBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutNonCoveredBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyNonCoveredBenefitInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutPlanBenefitTemplateInput {
  create?: CoverageBenefitCreateWithoutPlanBenefitTemplateInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutPlanBenefitTemplateInput[] | null;
  createMany?: CoverageBenefitCreateManyPlanBenefitTemplateInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput {
  create?: CoverageBenefitCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  createMany?: CoverageBenefitCreateManyProviderServiceConfigurationInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutRemainingDeductibleBenefitInput {
  create?: CoverageBenefitCreateWithoutRemainingDeductibleBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutRemainingDeductibleBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyRemainingDeductibleBenefitInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutRemainingOutOfPocketBenefitInput {
  create?: CoverageBenefitCreateWithoutRemainingOutOfPocketBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutRemainingOutOfPocketBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyRemainingOutOfPocketBenefitInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedManyWithoutRemainingVisitsBenefitInput {
  create?: CoverageBenefitCreateWithoutRemainingVisitsBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutRemainingVisitsBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyRemainingVisitsBenefitInputEnvelope | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
}

export interface CoverageBenefitCreateNestedOneWithoutAppointmentBenefitAssignmentsInput {
  create?: CoverageBenefitCreateWithoutAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutAppointmentBenefitAssignmentsInput | null;
  connect?: CoverageBenefitWhereUniqueInput | null;
}

export interface CoverageBenefitCreateNestedOneWithoutEstimatedChargesInput {
  create?: CoverageBenefitCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutEstimatedChargesInput | null;
  connect?: CoverageBenefitWhereUniqueInput | null;
}

export interface CoverageBenefitCreateNestedOneWithoutHealthPlanAppointmentBenefitAssignmentsInput {
  create?: CoverageBenefitCreateWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
  connect?: CoverageBenefitWhereUniqueInput | null;
}

export interface CoverageBenefitCreateNestedOneWithoutVisitCollectionRequestsInput {
  create?: CoverageBenefitCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  connect?: CoverageBenefitWhereUniqueInput | null;
}

export interface CoverageBenefitCreateOrConnectWithoutActiveCoverageBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutActiveCoverageBenefitInput;
}

export interface CoverageBenefitCreateOrConnectWithoutAppointmentBenefitAssignmentsInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutAppointmentBenefitAssignmentsInput;
}

export interface CoverageBenefitCreateOrConnectWithoutCoinsuranceBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutCoinsuranceBenefitInput;
}

export interface CoverageBenefitCreateOrConnectWithoutCopayBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutCopayBenefitInput;
}

export interface CoverageBenefitCreateOrConnectWithoutCreatedByInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutCreatedByInput;
}

export interface CoverageBenefitCreateOrConnectWithoutDeductibleAppliesBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutDeductibleAppliesBenefitInput;
}

export interface CoverageBenefitCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutEligibilityCoverageBenefitSnapshotInput;
}

export interface CoverageBenefitCreateOrConnectWithoutEstimatedChargesInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutEstimatedChargesInput;
}

export interface CoverageBenefitCreateOrConnectWithoutHealthPlanAppointmentBenefitAssignmentsInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutHealthPlanAppointmentBenefitAssignmentsInput;
}

export interface CoverageBenefitCreateOrConnectWithoutInsurancePolicyInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutInsurancePolicyInput;
}

export interface CoverageBenefitCreateOrConnectWithoutMaxDeductibleBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutMaxDeductibleBenefitInput;
}

export interface CoverageBenefitCreateOrConnectWithoutMaxOutOfPocketBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutMaxOutOfPocketBenefitInput;
}

export interface CoverageBenefitCreateOrConnectWithoutMaxVisitsBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutMaxVisitsBenefitInput;
}

export interface CoverageBenefitCreateOrConnectWithoutNonCoveredBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutNonCoveredBenefitInput;
}

export interface CoverageBenefitCreateOrConnectWithoutPlanBenefitTemplateInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutPlanBenefitTemplateInput;
}

export interface CoverageBenefitCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutProviderServiceConfigurationInput;
}

export interface CoverageBenefitCreateOrConnectWithoutRemainingDeductibleBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutRemainingDeductibleBenefitInput;
}

export interface CoverageBenefitCreateOrConnectWithoutRemainingOutOfPocketBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutRemainingOutOfPocketBenefitInput;
}

export interface CoverageBenefitCreateOrConnectWithoutRemainingVisitsBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutRemainingVisitsBenefitInput;
}

export interface CoverageBenefitCreateOrConnectWithoutVisitCollectionRequestsInput {
  where: CoverageBenefitWhereUniqueInput;
  create: CoverageBenefitCreateWithoutVisitCollectionRequestsInput;
}

export interface CoverageBenefitCreateWithoutActiveCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutAppointmentBenefitAssignmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutCoinsuranceBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutCopayBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutDeductibleAppliesBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutEligibilityCoverageBenefitSnapshotInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutEstimatedChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutHealthPlanAppointmentBenefitAssignmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutMaxDeductibleBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutMaxOutOfPocketBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutMaxVisitsBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutNonCoveredBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutPlanBenefitTemplateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
}

export interface CoverageBenefitCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutRemainingDeductibleBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutRemainingOutOfPocketBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutRemainingVisitsBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitCreateWithoutVisitCollectionRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  hidden?: boolean | null;
  empty: boolean;
  authRequired?: boolean | null;
  networkStatus: boolean;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  locked?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutCoverageBenefitsInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutCoverageBenefitsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCoverageBenefitInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutHealthPlanCoverageBenefitInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput | null;
  planBenefitTemplate?: PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput | null;
}

export interface CoverageBenefitListRelationFilter {
  every?: CoverageBenefitWhereInput | null;
  some?: CoverageBenefitWhereInput | null;
  none?: CoverageBenefitWhereInput | null;
}

export interface CoverageBenefitRelationFilter {
  is?: CoverageBenefitWhereInput | null;
  isNot?: CoverageBenefitWhereInput | null;
}

export interface CoverageBenefitScalarWhereInput {
  AND?: CoverageBenefitScalarWhereInput[] | null;
  OR?: CoverageBenefitScalarWhereInput[] | null;
  NOT?: CoverageBenefitScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  hidden?: BoolFilter | null;
  createdById?: UuidNullableFilter | null;
  providerServiceConfigurationId?: UuidFilter | null;
  eligibilityCoverageBenefitSnapshotId?: UuidNullableFilter | null;
  empty?: BoolFilter | null;
  authRequired?: BoolNullableFilter | null;
  networkStatus?: BoolFilter | null;
  coverageLevel?: StringNullableFilter | null;
  insurancePolicyId?: UuidFilter | null;
  copay?: IntNullableFilter | null;
  copayBenefitId?: UuidNullableFilter | null;
  coinsurance?: DecimalNullableFilter | null;
  coinsuranceBenefitId?: UuidNullableFilter | null;
  remainingDeductible?: IntNullableFilter | null;
  remainingDeductibleBenefitId?: UuidNullableFilter | null;
  maxDeductible?: IntNullableFilter | null;
  maxDeductibleBenefitId?: UuidNullableFilter | null;
  deductibleApplies?: BoolNullableFilter | null;
  deductibleAppliesBenefitId?: UuidNullableFilter | null;
  remainingOutOfPocket?: IntNullableFilter | null;
  remainingOutOfPocketBenefitId?: UuidNullableFilter | null;
  maxOutOfPocket?: IntNullableFilter | null;
  maxOutOfPocketBenefitId?: UuidNullableFilter | null;
  remainingVisits?: IntNullableFilter | null;
  remainingVisitsBenefitId?: UuidNullableFilter | null;
  maxVisits?: IntNullableFilter | null;
  maxVisitsBenefitId?: UuidNullableFilter | null;
  nonCovered?: BoolFilter | null;
  nonCoveredBenefitId?: UuidNullableFilter | null;
  activeCoverageBenefitId?: UuidNullableFilter | null;
  locked?: BoolFilter | null;
  planBenefitTemplateId?: UuidNullableFilter | null;
}

export interface CoverageBenefitUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutActiveCoverageBenefitInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutCoinsuranceBenefitInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutCopayBenefitInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutCreatedByInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutDeductibleAppliesBenefitInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutEligibilityCoverageBenefitSnapshotInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutInsurancePolicyInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutMaxDeductibleBenefitInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutMaxOutOfPocketBenefitInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutMaxVisitsBenefitInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutNonCoveredBenefitInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutPlanBenefitTemplateInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutProviderServiceConfigurationInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutRemainingDeductibleBenefitInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutRemainingOutOfPocketBenefitInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithWhereWithoutRemainingVisitsBenefitInput {
  where: CoverageBenefitScalarWhereInput;
  data: CoverageBenefitUpdateManyMutationInput;
}

export interface CoverageBenefitUpdateManyWithoutActiveCoverageBenefitNestedInput {
  create?: CoverageBenefitCreateWithoutActiveCoverageBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutActiveCoverageBenefitInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutActiveCoverageBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyActiveCoverageBenefitInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutActiveCoverageBenefitInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutActiveCoverageBenefitInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutCoinsuranceBenefitNestedInput {
  create?: CoverageBenefitCreateWithoutCoinsuranceBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutCoinsuranceBenefitInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutCoinsuranceBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyCoinsuranceBenefitInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutCoinsuranceBenefitInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutCoinsuranceBenefitInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutCopayBenefitNestedInput {
  create?: CoverageBenefitCreateWithoutCopayBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutCopayBenefitInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutCopayBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyCopayBenefitInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutCopayBenefitInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutCopayBenefitInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutCreatedByNestedInput {
  create?: CoverageBenefitCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: CoverageBenefitCreateManyCreatedByInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutDeductibleAppliesBenefitNestedInput {
  create?: CoverageBenefitCreateWithoutDeductibleAppliesBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutDeductibleAppliesBenefitInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutDeductibleAppliesBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyDeductibleAppliesBenefitInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutDeductibleAppliesBenefitInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutDeductibleAppliesBenefitInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput {
  create?: CoverageBenefitCreateWithoutEligibilityCoverageBenefitSnapshotInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutEligibilityCoverageBenefitSnapshotInput[] | null;
  createMany?: CoverageBenefitCreateManyEligibilityCoverageBenefitSnapshotInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutEligibilityCoverageBenefitSnapshotInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutEligibilityCoverageBenefitSnapshotInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput {
  create?: CoverageBenefitCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutInsurancePolicyInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  createMany?: CoverageBenefitCreateManyInsurancePolicyInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutInsurancePolicyInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutMaxDeductibleBenefitNestedInput {
  create?: CoverageBenefitCreateWithoutMaxDeductibleBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutMaxDeductibleBenefitInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutMaxDeductibleBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyMaxDeductibleBenefitInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutMaxDeductibleBenefitInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutMaxDeductibleBenefitInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutMaxOutOfPocketBenefitNestedInput {
  create?: CoverageBenefitCreateWithoutMaxOutOfPocketBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutMaxOutOfPocketBenefitInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutMaxOutOfPocketBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyMaxOutOfPocketBenefitInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutMaxOutOfPocketBenefitInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutMaxOutOfPocketBenefitInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutMaxVisitsBenefitNestedInput {
  create?: CoverageBenefitCreateWithoutMaxVisitsBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutMaxVisitsBenefitInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutMaxVisitsBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyMaxVisitsBenefitInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutMaxVisitsBenefitInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutMaxVisitsBenefitInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutNonCoveredBenefitNestedInput {
  create?: CoverageBenefitCreateWithoutNonCoveredBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutNonCoveredBenefitInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutNonCoveredBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyNonCoveredBenefitInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutNonCoveredBenefitInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutNonCoveredBenefitInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutPlanBenefitTemplateNestedInput {
  create?: CoverageBenefitCreateWithoutPlanBenefitTemplateInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutPlanBenefitTemplateInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutPlanBenefitTemplateInput[] | null;
  createMany?: CoverageBenefitCreateManyPlanBenefitTemplateInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutPlanBenefitTemplateInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutPlanBenefitTemplateInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput {
  create?: CoverageBenefitCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  createMany?: CoverageBenefitCreateManyProviderServiceConfigurationInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutProviderServiceConfigurationInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutRemainingDeductibleBenefitNestedInput {
  create?: CoverageBenefitCreateWithoutRemainingDeductibleBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutRemainingDeductibleBenefitInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutRemainingDeductibleBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyRemainingDeductibleBenefitInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutRemainingDeductibleBenefitInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutRemainingDeductibleBenefitInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutRemainingOutOfPocketBenefitNestedInput {
  create?: CoverageBenefitCreateWithoutRemainingOutOfPocketBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutRemainingOutOfPocketBenefitInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutRemainingOutOfPocketBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyRemainingOutOfPocketBenefitInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutRemainingOutOfPocketBenefitInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutRemainingOutOfPocketBenefitInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateManyWithoutRemainingVisitsBenefitNestedInput {
  create?: CoverageBenefitCreateWithoutRemainingVisitsBenefitInput[] | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutRemainingVisitsBenefitInput[] | null;
  upsert?: CoverageBenefitUpsertWithWhereUniqueWithoutRemainingVisitsBenefitInput[] | null;
  createMany?: CoverageBenefitCreateManyRemainingVisitsBenefitInputEnvelope | null;
  set?: CoverageBenefitWhereUniqueInput[] | null;
  disconnect?: CoverageBenefitWhereUniqueInput[] | null;
  delete?: CoverageBenefitWhereUniqueInput[] | null;
  connect?: CoverageBenefitWhereUniqueInput[] | null;
  update?: CoverageBenefitUpdateWithWhereUniqueWithoutRemainingVisitsBenefitInput[] | null;
  updateMany?: CoverageBenefitUpdateManyWithWhereWithoutRemainingVisitsBenefitInput[] | null;
  deleteMany?: CoverageBenefitScalarWhereInput[] | null;
}

export interface CoverageBenefitUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput {
  create?: CoverageBenefitCreateWithoutAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutAppointmentBenefitAssignmentsInput | null;
  upsert?: CoverageBenefitUpsertWithoutAppointmentBenefitAssignmentsInput | null;
  connect?: CoverageBenefitWhereUniqueInput | null;
  update?: CoverageBenefitUpdateWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface CoverageBenefitUpdateOneWithoutEstimatedChargesNestedInput {
  create?: CoverageBenefitCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutEstimatedChargesInput | null;
  upsert?: CoverageBenefitUpsertWithoutEstimatedChargesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: CoverageBenefitWhereUniqueInput | null;
  update?: CoverageBenefitUpdateWithoutEstimatedChargesInput | null;
}

export interface CoverageBenefitUpdateOneWithoutHealthPlanAppointmentBenefitAssignmentsNestedInput {
  create?: CoverageBenefitCreateWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
  upsert?: CoverageBenefitUpsertWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: CoverageBenefitWhereUniqueInput | null;
  update?: CoverageBenefitUpdateWithoutHealthPlanAppointmentBenefitAssignmentsInput | null;
}

export interface CoverageBenefitUpdateOneWithoutVisitCollectionRequestsNestedInput {
  create?: CoverageBenefitCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: CoverageBenefitCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  upsert?: CoverageBenefitUpsertWithoutVisitCollectionRequestsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: CoverageBenefitWhereUniqueInput | null;
  update?: CoverageBenefitUpdateWithoutVisitCollectionRequestsInput | null;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutActiveCoverageBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutActiveCoverageBenefitInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutCoinsuranceBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutCoinsuranceBenefitInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutCopayBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutCopayBenefitInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutCreatedByInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutCreatedByInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutDeductibleAppliesBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutDeductibleAppliesBenefitInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutEligibilityCoverageBenefitSnapshotInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutEligibilityCoverageBenefitSnapshotInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutInsurancePolicyInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutInsurancePolicyInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutMaxDeductibleBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutMaxDeductibleBenefitInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutMaxOutOfPocketBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutMaxOutOfPocketBenefitInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutMaxVisitsBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutMaxVisitsBenefitInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutNonCoveredBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutNonCoveredBenefitInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutPlanBenefitTemplateInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutPlanBenefitTemplateInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutProviderServiceConfigurationInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutRemainingDeductibleBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutRemainingDeductibleBenefitInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutRemainingOutOfPocketBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutRemainingOutOfPocketBenefitInput;
}

export interface CoverageBenefitUpdateWithWhereUniqueWithoutRemainingVisitsBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  data: CoverageBenefitUpdateWithoutRemainingVisitsBenefitInput;
}

export interface CoverageBenefitUpdateWithoutActiveCoverageBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutAppointmentBenefitAssignmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutCoinsuranceBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutCopayBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutDeductibleAppliesBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutEligibilityCoverageBenefitSnapshotInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutEstimatedChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutHealthPlanAppointmentBenefitAssignmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutInsurancePolicyInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutMaxDeductibleBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutMaxOutOfPocketBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutMaxVisitsBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutNonCoveredBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutPlanBenefitTemplateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutRemainingDeductibleBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutRemainingOutOfPocketBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutRemainingVisitsBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpdateWithoutVisitCollectionRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  locked?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutCoverageBenefitsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutCoverageBenefitsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCoverageBenefitNestedInput | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutHealthPlanCoverageBenefitNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput | null;
  planBenefitTemplate?: PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput | null;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutActiveCoverageBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutActiveCoverageBenefitInput;
  create: CoverageBenefitCreateWithoutActiveCoverageBenefitInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutCoinsuranceBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutCoinsuranceBenefitInput;
  create: CoverageBenefitCreateWithoutCoinsuranceBenefitInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutCopayBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutCopayBenefitInput;
  create: CoverageBenefitCreateWithoutCopayBenefitInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutCreatedByInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutCreatedByInput;
  create: CoverageBenefitCreateWithoutCreatedByInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutDeductibleAppliesBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutDeductibleAppliesBenefitInput;
  create: CoverageBenefitCreateWithoutDeductibleAppliesBenefitInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutEligibilityCoverageBenefitSnapshotInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutEligibilityCoverageBenefitSnapshotInput;
  create: CoverageBenefitCreateWithoutEligibilityCoverageBenefitSnapshotInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutInsurancePolicyInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutInsurancePolicyInput;
  create: CoverageBenefitCreateWithoutInsurancePolicyInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutMaxDeductibleBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutMaxDeductibleBenefitInput;
  create: CoverageBenefitCreateWithoutMaxDeductibleBenefitInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutMaxOutOfPocketBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutMaxOutOfPocketBenefitInput;
  create: CoverageBenefitCreateWithoutMaxOutOfPocketBenefitInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutMaxVisitsBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutMaxVisitsBenefitInput;
  create: CoverageBenefitCreateWithoutMaxVisitsBenefitInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutNonCoveredBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutNonCoveredBenefitInput;
  create: CoverageBenefitCreateWithoutNonCoveredBenefitInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutPlanBenefitTemplateInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutPlanBenefitTemplateInput;
  create: CoverageBenefitCreateWithoutPlanBenefitTemplateInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutProviderServiceConfigurationInput;
  create: CoverageBenefitCreateWithoutProviderServiceConfigurationInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutRemainingDeductibleBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutRemainingDeductibleBenefitInput;
  create: CoverageBenefitCreateWithoutRemainingDeductibleBenefitInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutRemainingOutOfPocketBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutRemainingOutOfPocketBenefitInput;
  create: CoverageBenefitCreateWithoutRemainingOutOfPocketBenefitInput;
}

export interface CoverageBenefitUpsertWithWhereUniqueWithoutRemainingVisitsBenefitInput {
  where: CoverageBenefitWhereUniqueInput;
  update: CoverageBenefitUpdateWithoutRemainingVisitsBenefitInput;
  create: CoverageBenefitCreateWithoutRemainingVisitsBenefitInput;
}

export interface CoverageBenefitUpsertWithoutAppointmentBenefitAssignmentsInput {
  update: CoverageBenefitUpdateWithoutAppointmentBenefitAssignmentsInput;
  create: CoverageBenefitCreateWithoutAppointmentBenefitAssignmentsInput;
}

export interface CoverageBenefitUpsertWithoutEstimatedChargesInput {
  update: CoverageBenefitUpdateWithoutEstimatedChargesInput;
  create: CoverageBenefitCreateWithoutEstimatedChargesInput;
}

export interface CoverageBenefitUpsertWithoutHealthPlanAppointmentBenefitAssignmentsInput {
  update: CoverageBenefitUpdateWithoutHealthPlanAppointmentBenefitAssignmentsInput;
  create: CoverageBenefitCreateWithoutHealthPlanAppointmentBenefitAssignmentsInput;
}

export interface CoverageBenefitUpsertWithoutVisitCollectionRequestsInput {
  update: CoverageBenefitUpdateWithoutVisitCollectionRequestsInput;
  create: CoverageBenefitCreateWithoutVisitCollectionRequestsInput;
}

export interface CoverageBenefitWhereInput {
  AND?: CoverageBenefitWhereInput[] | null;
  OR?: CoverageBenefitWhereInput[] | null;
  NOT?: CoverageBenefitWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  hidden?: BoolFilter | null;
  createdById?: UuidNullableFilter | null;
  providerServiceConfigurationId?: UuidFilter | null;
  eligibilityCoverageBenefitSnapshotId?: UuidNullableFilter | null;
  empty?: BoolFilter | null;
  authRequired?: BoolNullableFilter | null;
  networkStatus?: BoolFilter | null;
  coverageLevel?: StringNullableFilter | null;
  insurancePolicyId?: UuidFilter | null;
  copay?: IntNullableFilter | null;
  copayBenefitId?: UuidNullableFilter | null;
  coinsurance?: DecimalNullableFilter | null;
  coinsuranceBenefitId?: UuidNullableFilter | null;
  remainingDeductible?: IntNullableFilter | null;
  remainingDeductibleBenefitId?: UuidNullableFilter | null;
  maxDeductible?: IntNullableFilter | null;
  maxDeductibleBenefitId?: UuidNullableFilter | null;
  deductibleApplies?: BoolNullableFilter | null;
  deductibleAppliesBenefitId?: UuidNullableFilter | null;
  remainingOutOfPocket?: IntNullableFilter | null;
  remainingOutOfPocketBenefitId?: UuidNullableFilter | null;
  maxOutOfPocket?: IntNullableFilter | null;
  maxOutOfPocketBenefitId?: UuidNullableFilter | null;
  remainingVisits?: IntNullableFilter | null;
  remainingVisitsBenefitId?: UuidNullableFilter | null;
  maxVisits?: IntNullableFilter | null;
  maxVisitsBenefitId?: UuidNullableFilter | null;
  nonCovered?: BoolFilter | null;
  nonCoveredBenefitId?: UuidNullableFilter | null;
  activeCoverageBenefitId?: UuidNullableFilter | null;
  locked?: BoolFilter | null;
  planBenefitTemplateId?: UuidNullableFilter | null;
  createdBy?: UserRelationFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationRelationFilter | null;
  eligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotRelationFilter | null;
  insurancePolicy?: InsurancePolicyRelationFilter | null;
  copayBenefit?: CopayBenefitRelationFilter | null;
  coinsuranceBenefit?: CoinsuranceBenefitRelationFilter | null;
  remainingDeductibleBenefit?: DeductibleBenefitRelationFilter | null;
  maxDeductibleBenefit?: DeductibleBenefitRelationFilter | null;
  deductibleAppliesBenefit?: DeductibleBenefitRelationFilter | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitRelationFilter | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitRelationFilter | null;
  remainingVisitsBenefit?: LimitationBenefitRelationFilter | null;
  maxVisitsBenefit?: LimitationBenefitRelationFilter | null;
  nonCoveredBenefit?: OtherBenefitRelationFilter | null;
  activeCoverageBenefit?: OtherBenefitRelationFilter | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentListRelationFilter | null;
  healthPlanAppointmentBenefitAssignments?: AppointmentBenefitAssignmentListRelationFilter | null;
  estimatedCharges?: EstimatedChargeListRelationFilter | null;
  visitCollectionRequests?: VisitCollectionRequestListRelationFilter | null;
  planBenefitTemplate?: PlanBenefitTemplateRelationFilter | null;
}

export interface CoverageBenefitWhereUniqueInput {
  id?: string | null;
}

export interface DateTimeFieldUpdateOperationsInput {
  set?: any | null;
}

export interface DateTimeFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeFilter | null;
}

export interface DateTimeNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeNullableFilter | null;
}

export interface DecimalFieldUpdateOperationsInput {
  set?: any | null;
  increment?: any | null;
  decrement?: any | null;
  multiply?: any | null;
  divide?: any | null;
}

export interface DecimalFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDecimalFilter | null;
}

export interface DecimalNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDecimalNullableFilter | null;
}

export interface DeductibleBenefitCreateManyEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: DeductibleBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
}

export interface DeductibleBenefitCreateManyEligibilityRequestInputEnvelope {
  data: DeductibleBenefitCreateManyEligibilityRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput {
  create?: DeductibleBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  createMany?: DeductibleBenefitCreateManyEligibilityRequestInputEnvelope | null;
  connect?: DeductibleBenefitWhereUniqueInput[] | null;
}

export interface DeductibleBenefitCreateNestedOneWithoutAppliesCoverageBenefitInput {
  create?: DeductibleBenefitCreateWithoutAppliesCoverageBenefitInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutAppliesCoverageBenefitInput | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
}

export interface DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput {
  create?: DeductibleBenefitCreateWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
}

export interface DeductibleBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput {
  create?: DeductibleBenefitCreateWithoutMaxCoverageBenefitsInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutMaxCoverageBenefitsInput | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
}

export interface DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  create?: DeductibleBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
}

export interface DeductibleBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput {
  create?: DeductibleBenefitCreateWithoutRemainingCoverageBenefitsInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutRemainingCoverageBenefitsInput | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
}

export interface DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  create?: DeductibleBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
}

export interface DeductibleBenefitCreateOrConnectWithoutAppliesCoverageBenefitInput {
  where: DeductibleBenefitWhereUniqueInput;
  create: DeductibleBenefitCreateWithoutAppliesCoverageBenefitInput;
}

export interface DeductibleBenefitCreateOrConnectWithoutAppliesEligibilityCoverageBenefitSnapshotInput {
  where: DeductibleBenefitWhereUniqueInput;
  create: DeductibleBenefitCreateWithoutAppliesEligibilityCoverageBenefitSnapshotInput;
}

export interface DeductibleBenefitCreateOrConnectWithoutEligibilityRequestInput {
  where: DeductibleBenefitWhereUniqueInput;
  create: DeductibleBenefitCreateWithoutEligibilityRequestInput;
}

export interface DeductibleBenefitCreateOrConnectWithoutMaxCoverageBenefitsInput {
  where: DeductibleBenefitWhereUniqueInput;
  create: DeductibleBenefitCreateWithoutMaxCoverageBenefitsInput;
}

export interface DeductibleBenefitCreateOrConnectWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  where: DeductibleBenefitWhereUniqueInput;
  create: DeductibleBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput;
}

export interface DeductibleBenefitCreateOrConnectWithoutRemainingCoverageBenefitsInput {
  where: DeductibleBenefitWhereUniqueInput;
  create: DeductibleBenefitCreateWithoutRemainingCoverageBenefitsInput;
}

export interface DeductibleBenefitCreateOrConnectWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  where: DeductibleBenefitWhereUniqueInput;
  create: DeductibleBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput;
}

export interface DeductibleBenefitCreateWithoutAppliesCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: DeductibleBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutDeductibleBenefitsInput;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
}

export interface DeductibleBenefitCreateWithoutAppliesEligibilityCoverageBenefitSnapshotInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: DeductibleBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutDeductibleBenefitsInput;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
  appliesCoverageBenefit?: CoverageBenefitCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
}

export interface DeductibleBenefitCreateWithoutEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: DeductibleBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
  appliesCoverageBenefit?: CoverageBenefitCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
}

export interface DeductibleBenefitCreateWithoutMaxCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: DeductibleBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutDeductibleBenefitsInput;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  appliesCoverageBenefit?: CoverageBenefitCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
}

export interface DeductibleBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: DeductibleBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutDeductibleBenefitsInput;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
  appliesCoverageBenefit?: CoverageBenefitCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
}

export interface DeductibleBenefitCreateWithoutRemainingCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: DeductibleBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutDeductibleBenefitsInput;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
  appliesCoverageBenefit?: CoverageBenefitCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
}

export interface DeductibleBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: DeductibleBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutDeductibleBenefitsInput;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingDeductibleBenefitInput | null;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
  appliesCoverageBenefit?: CoverageBenefitCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxDeductibleBenefitInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutDeductibleAppliesBenefitInput | null;
}

export interface DeductibleBenefitCreatenotesInput {
  set: string[];
}

export interface DeductibleBenefitListRelationFilter {
  every?: DeductibleBenefitWhereInput | null;
  some?: DeductibleBenefitWhereInput | null;
  none?: DeductibleBenefitWhereInput | null;
}

export interface DeductibleBenefitRelationFilter {
  is?: DeductibleBenefitWhereInput | null;
  isNot?: DeductibleBenefitWhereInput | null;
}

export interface DeductibleBenefitScalarWhereInput {
  AND?: DeductibleBenefitScalarWhereInput[] | null;
  OR?: DeductibleBenefitScalarWhereInput[] | null;
  NOT?: DeductibleBenefitScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  max?: IntNullableFilter | null;
  remaining?: IntNullableFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
}

export interface DeductibleBenefitUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: DeductibleBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
}

export interface DeductibleBenefitUpdateManyWithWhereWithoutEligibilityRequestInput {
  where: DeductibleBenefitScalarWhereInput;
  data: DeductibleBenefitUpdateManyMutationInput;
}

export interface DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput {
  create?: DeductibleBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  upsert?: DeductibleBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  createMany?: DeductibleBenefitCreateManyEligibilityRequestInputEnvelope | null;
  set?: DeductibleBenefitWhereUniqueInput[] | null;
  disconnect?: DeductibleBenefitWhereUniqueInput[] | null;
  delete?: DeductibleBenefitWhereUniqueInput[] | null;
  connect?: DeductibleBenefitWhereUniqueInput[] | null;
  update?: DeductibleBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  updateMany?: DeductibleBenefitUpdateManyWithWhereWithoutEligibilityRequestInput[] | null;
  deleteMany?: DeductibleBenefitScalarWhereInput[] | null;
}

export interface DeductibleBenefitUpdateOneWithoutAppliesCoverageBenefitNestedInput {
  create?: DeductibleBenefitCreateWithoutAppliesCoverageBenefitInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutAppliesCoverageBenefitInput | null;
  upsert?: DeductibleBenefitUpsertWithoutAppliesCoverageBenefitInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
  update?: DeductibleBenefitUpdateWithoutAppliesCoverageBenefitInput | null;
}

export interface DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput {
  create?: DeductibleBenefitCreateWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  upsert?: DeductibleBenefitUpsertWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
  update?: DeductibleBenefitUpdateWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
}

export interface DeductibleBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput {
  create?: DeductibleBenefitCreateWithoutMaxCoverageBenefitsInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutMaxCoverageBenefitsInput | null;
  upsert?: DeductibleBenefitUpsertWithoutMaxCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
  update?: DeductibleBenefitUpdateWithoutMaxCoverageBenefitsInput | null;
}

export interface DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput {
  create?: DeductibleBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  upsert?: DeductibleBenefitUpsertWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
  update?: DeductibleBenefitUpdateWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
}

export interface DeductibleBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput {
  create?: DeductibleBenefitCreateWithoutRemainingCoverageBenefitsInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutRemainingCoverageBenefitsInput | null;
  upsert?: DeductibleBenefitUpsertWithoutRemainingCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
  update?: DeductibleBenefitUpdateWithoutRemainingCoverageBenefitsInput | null;
}

export interface DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput {
  create?: DeductibleBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: DeductibleBenefitCreateOrConnectWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  upsert?: DeductibleBenefitUpsertWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: DeductibleBenefitWhereUniqueInput | null;
  update?: DeductibleBenefitUpdateWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
}

export interface DeductibleBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput {
  where: DeductibleBenefitWhereUniqueInput;
  data: DeductibleBenefitUpdateWithoutEligibilityRequestInput;
}

export interface DeductibleBenefitUpdateWithoutAppliesCoverageBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: DeductibleBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutDeductibleBenefitsNestedInput | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
}

export interface DeductibleBenefitUpdateWithoutAppliesEligibilityCoverageBenefitSnapshotInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: DeductibleBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutDeductibleBenefitsNestedInput | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
  appliesCoverageBenefit?: CoverageBenefitUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
}

export interface DeductibleBenefitUpdateWithoutEligibilityRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: DeductibleBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
  appliesCoverageBenefit?: CoverageBenefitUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
}

export interface DeductibleBenefitUpdateWithoutMaxCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: DeductibleBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutDeductibleBenefitsNestedInput | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  appliesCoverageBenefit?: CoverageBenefitUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
}

export interface DeductibleBenefitUpdateWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: DeductibleBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutDeductibleBenefitsNestedInput | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
  appliesCoverageBenefit?: CoverageBenefitUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
}

export interface DeductibleBenefitUpdateWithoutRemainingCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: DeductibleBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutDeductibleBenefitsNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
  appliesCoverageBenefit?: CoverageBenefitUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
}

export interface DeductibleBenefitUpdateWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: DeductibleBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutDeductibleBenefitsNestedInput | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingDeductibleBenefitNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
  appliesCoverageBenefit?: CoverageBenefitUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxDeductibleBenefitNestedInput | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutDeductibleAppliesBenefitNestedInput | null;
}

export interface DeductibleBenefitUpdatenotesInput {
  set?: string[] | null;
  push?: string[] | null;
}

export interface DeductibleBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput {
  where: DeductibleBenefitWhereUniqueInput;
  update: DeductibleBenefitUpdateWithoutEligibilityRequestInput;
  create: DeductibleBenefitCreateWithoutEligibilityRequestInput;
}

export interface DeductibleBenefitUpsertWithoutAppliesCoverageBenefitInput {
  update: DeductibleBenefitUpdateWithoutAppliesCoverageBenefitInput;
  create: DeductibleBenefitCreateWithoutAppliesCoverageBenefitInput;
}

export interface DeductibleBenefitUpsertWithoutAppliesEligibilityCoverageBenefitSnapshotInput {
  update: DeductibleBenefitUpdateWithoutAppliesEligibilityCoverageBenefitSnapshotInput;
  create: DeductibleBenefitCreateWithoutAppliesEligibilityCoverageBenefitSnapshotInput;
}

export interface DeductibleBenefitUpsertWithoutMaxCoverageBenefitsInput {
  update: DeductibleBenefitUpdateWithoutMaxCoverageBenefitsInput;
  create: DeductibleBenefitCreateWithoutMaxCoverageBenefitsInput;
}

export interface DeductibleBenefitUpsertWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  update: DeductibleBenefitUpdateWithoutMaxEligibilityCoverageBenefitSnapshotInput;
  create: DeductibleBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput;
}

export interface DeductibleBenefitUpsertWithoutRemainingCoverageBenefitsInput {
  update: DeductibleBenefitUpdateWithoutRemainingCoverageBenefitsInput;
  create: DeductibleBenefitCreateWithoutRemainingCoverageBenefitsInput;
}

export interface DeductibleBenefitUpsertWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  update: DeductibleBenefitUpdateWithoutRemainingEligibilityCoverageBenefitSnapshotInput;
  create: DeductibleBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput;
}

export interface DeductibleBenefitWhereInput {
  AND?: DeductibleBenefitWhereInput[] | null;
  OR?: DeductibleBenefitWhereInput[] | null;
  NOT?: DeductibleBenefitWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  max?: IntNullableFilter | null;
  remaining?: IntNullableFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
  eligibilityRequest?: EligibilityRequestRelationFilter | null;
  remainingCoverageBenefits?: CoverageBenefitListRelationFilter | null;
  maxCoverageBenefits?: CoverageBenefitListRelationFilter | null;
  appliesCoverageBenefit?: CoverageBenefitListRelationFilter | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
  appliesEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
}

export interface DeductibleBenefitWhereUniqueInput {
  id?: string | null;
}

export interface DepositMappingConditionCreateManyDepositMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
  providerServiceConfigurationId?: string | null;
}

export interface DepositMappingConditionCreateManyDepositMappingInputEnvelope {
  data: DepositMappingConditionCreateManyDepositMappingInput[];
  skipDuplicates?: boolean | null;
}

export interface DepositMappingConditionCreateManyProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  depositMappingId: string;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
}

export interface DepositMappingConditionCreateManyProviderServiceConfigurationInputEnvelope {
  data: DepositMappingConditionCreateManyProviderServiceConfigurationInput[];
  skipDuplicates?: boolean | null;
}

export interface DepositMappingConditionCreateNestedManyWithoutDepositMappingInput {
  create?: DepositMappingConditionCreateWithoutDepositMappingInput[] | null;
  connectOrCreate?: DepositMappingConditionCreateOrConnectWithoutDepositMappingInput[] | null;
  createMany?: DepositMappingConditionCreateManyDepositMappingInputEnvelope | null;
  connect?: DepositMappingConditionWhereUniqueInput[] | null;
}

export interface DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput {
  create?: DepositMappingConditionCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: DepositMappingConditionCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  createMany?: DepositMappingConditionCreateManyProviderServiceConfigurationInputEnvelope | null;
  connect?: DepositMappingConditionWhereUniqueInput[] | null;
}

export interface DepositMappingConditionCreateOrConnectWithoutDepositMappingInput {
  where: DepositMappingConditionWhereUniqueInput;
  create: DepositMappingConditionCreateWithoutDepositMappingInput;
}

export interface DepositMappingConditionCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: DepositMappingConditionWhereUniqueInput;
  create: DepositMappingConditionCreateWithoutProviderServiceConfigurationInput;
}

export interface DepositMappingConditionCreateWithoutDepositMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutDepositMappingConditionsInput | null;
}

export interface DepositMappingConditionCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
  depositMapping: DepositMappingCreateNestedOneWithoutDepositMappingConditionsInput;
}

export interface DepositMappingConditionListRelationFilter {
  every?: DepositMappingConditionWhereInput | null;
  some?: DepositMappingConditionWhereInput | null;
  none?: DepositMappingConditionWhereInput | null;
}

export interface DepositMappingConditionScalarWhereInput {
  AND?: DepositMappingConditionScalarWhereInput[] | null;
  OR?: DepositMappingConditionScalarWhereInput[] | null;
  NOT?: DepositMappingConditionScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  depositMappingId?: UuidFilter | null;
  attribute?: JsonFilter | null;
  comparison?: EnumComparisonFilter | null;
  attributeType?: StringFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
}

export interface DepositMappingConditionUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
}

export interface DepositMappingConditionUpdateManyWithWhereWithoutDepositMappingInput {
  where: DepositMappingConditionScalarWhereInput;
  data: DepositMappingConditionUpdateManyMutationInput;
}

export interface DepositMappingConditionUpdateManyWithWhereWithoutProviderServiceConfigurationInput {
  where: DepositMappingConditionScalarWhereInput;
  data: DepositMappingConditionUpdateManyMutationInput;
}

export interface DepositMappingConditionUpdateManyWithoutDepositMappingNestedInput {
  create?: DepositMappingConditionCreateWithoutDepositMappingInput[] | null;
  connectOrCreate?: DepositMappingConditionCreateOrConnectWithoutDepositMappingInput[] | null;
  upsert?: DepositMappingConditionUpsertWithWhereUniqueWithoutDepositMappingInput[] | null;
  createMany?: DepositMappingConditionCreateManyDepositMappingInputEnvelope | null;
  set?: DepositMappingConditionWhereUniqueInput[] | null;
  disconnect?: DepositMappingConditionWhereUniqueInput[] | null;
  delete?: DepositMappingConditionWhereUniqueInput[] | null;
  connect?: DepositMappingConditionWhereUniqueInput[] | null;
  update?: DepositMappingConditionUpdateWithWhereUniqueWithoutDepositMappingInput[] | null;
  updateMany?: DepositMappingConditionUpdateManyWithWhereWithoutDepositMappingInput[] | null;
  deleteMany?: DepositMappingConditionScalarWhereInput[] | null;
}

export interface DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput {
  create?: DepositMappingConditionCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: DepositMappingConditionCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  upsert?: DepositMappingConditionUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  createMany?: DepositMappingConditionCreateManyProviderServiceConfigurationInputEnvelope | null;
  set?: DepositMappingConditionWhereUniqueInput[] | null;
  disconnect?: DepositMappingConditionWhereUniqueInput[] | null;
  delete?: DepositMappingConditionWhereUniqueInput[] | null;
  connect?: DepositMappingConditionWhereUniqueInput[] | null;
  update?: DepositMappingConditionUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  updateMany?: DepositMappingConditionUpdateManyWithWhereWithoutProviderServiceConfigurationInput[] | null;
  deleteMany?: DepositMappingConditionScalarWhereInput[] | null;
}

export interface DepositMappingConditionUpdateWithWhereUniqueWithoutDepositMappingInput {
  where: DepositMappingConditionWhereUniqueInput;
  data: DepositMappingConditionUpdateWithoutDepositMappingInput;
}

export interface DepositMappingConditionUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: DepositMappingConditionWhereUniqueInput;
  data: DepositMappingConditionUpdateWithoutProviderServiceConfigurationInput;
}

export interface DepositMappingConditionUpdateWithoutDepositMappingInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutDepositMappingConditionsNestedInput | null;
}

export interface DepositMappingConditionUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
  depositMapping?: DepositMappingUpdateOneRequiredWithoutDepositMappingConditionsNestedInput | null;
}

export interface DepositMappingConditionUpsertWithWhereUniqueWithoutDepositMappingInput {
  where: DepositMappingConditionWhereUniqueInput;
  update: DepositMappingConditionUpdateWithoutDepositMappingInput;
  create: DepositMappingConditionCreateWithoutDepositMappingInput;
}

export interface DepositMappingConditionUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: DepositMappingConditionWhereUniqueInput;
  update: DepositMappingConditionUpdateWithoutProviderServiceConfigurationInput;
  create: DepositMappingConditionCreateWithoutProviderServiceConfigurationInput;
}

export interface DepositMappingConditionWhereInput {
  AND?: DepositMappingConditionWhereInput[] | null;
  OR?: DepositMappingConditionWhereInput[] | null;
  NOT?: DepositMappingConditionWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  depositMappingId?: UuidFilter | null;
  attribute?: JsonFilter | null;
  comparison?: EnumComparisonFilter | null;
  attributeType?: StringFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
  depositMapping?: DepositMappingRelationFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationRelationFilter | null;
}

export interface DepositMappingConditionWhereUniqueInput {
  id?: string | null;
}

export interface DepositMappingCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  amount?: number | null;
  useCopay: boolean;
  createdBy: UserCreateNestedOneWithoutCreatedDepositMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedDepositMappingsInput;
  location: LocationCreateNestedOneWithoutDepositMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutDepositMappingsInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutDepositMappingInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutDepositMappingInput | null;
}

export interface DepositMappingCreateManyCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUpdatedById: string;
  priority: number;
  locationId: string;
  name: string;
  notes?: string | null;
  amount?: number | null;
  useCopay: boolean;
  providerServiceConfigurationId?: string | null;
}

export interface DepositMappingCreateManyCreatedByInputEnvelope {
  data: DepositMappingCreateManyCreatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface DepositMappingCreateManyLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  priority: number;
  locationId: string;
  name: string;
  notes?: string | null;
  amount?: number | null;
  useCopay: boolean;
  providerServiceConfigurationId?: string | null;
}

export interface DepositMappingCreateManyLastUpdatedByInputEnvelope {
  data: DepositMappingCreateManyLastUpdatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface DepositMappingCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  lastUpdatedById: string;
  priority: number;
  name: string;
  notes?: string | null;
  amount?: number | null;
  useCopay: boolean;
  providerServiceConfigurationId?: string | null;
}

export interface DepositMappingCreateManyLocationInputEnvelope {
  data: DepositMappingCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface DepositMappingCreateManyProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  lastUpdatedById: string;
  priority: number;
  locationId: string;
  name: string;
  notes?: string | null;
  amount?: number | null;
  useCopay: boolean;
}

export interface DepositMappingCreateManyProviderServiceConfigurationInputEnvelope {
  data: DepositMappingCreateManyProviderServiceConfigurationInput[];
  skipDuplicates?: boolean | null;
}

export interface DepositMappingCreateNestedManyWithoutCreatedByInput {
  create?: DepositMappingCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: DepositMappingCreateManyCreatedByInputEnvelope | null;
  connect?: DepositMappingWhereUniqueInput[] | null;
}

export interface DepositMappingCreateNestedManyWithoutLastUpdatedByInput {
  create?: DepositMappingCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutLastUpdatedByInput[] | null;
  createMany?: DepositMappingCreateManyLastUpdatedByInputEnvelope | null;
  connect?: DepositMappingWhereUniqueInput[] | null;
}

export interface DepositMappingCreateNestedManyWithoutLocationInput {
  create?: DepositMappingCreateWithoutLocationInput[] | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutLocationInput[] | null;
  createMany?: DepositMappingCreateManyLocationInputEnvelope | null;
  connect?: DepositMappingWhereUniqueInput[] | null;
}

export interface DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput {
  create?: DepositMappingCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  createMany?: DepositMappingCreateManyProviderServiceConfigurationInputEnvelope | null;
  connect?: DepositMappingWhereUniqueInput[] | null;
}

export interface DepositMappingCreateNestedOneWithoutDepositMappingConditionsInput {
  create?: DepositMappingCreateWithoutDepositMappingConditionsInput | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutDepositMappingConditionsInput | null;
  connect?: DepositMappingWhereUniqueInput | null;
}

export interface DepositMappingCreateNestedOneWithoutVisitCollectionRequestsInput {
  create?: DepositMappingCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  connect?: DepositMappingWhereUniqueInput | null;
}

export interface DepositMappingCreateOrConnectWithoutCreatedByInput {
  where: DepositMappingWhereUniqueInput;
  create: DepositMappingCreateWithoutCreatedByInput;
}

export interface DepositMappingCreateOrConnectWithoutDepositMappingConditionsInput {
  where: DepositMappingWhereUniqueInput;
  create: DepositMappingCreateWithoutDepositMappingConditionsInput;
}

export interface DepositMappingCreateOrConnectWithoutLastUpdatedByInput {
  where: DepositMappingWhereUniqueInput;
  create: DepositMappingCreateWithoutLastUpdatedByInput;
}

export interface DepositMappingCreateOrConnectWithoutLocationInput {
  where: DepositMappingWhereUniqueInput;
  create: DepositMappingCreateWithoutLocationInput;
}

export interface DepositMappingCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: DepositMappingWhereUniqueInput;
  create: DepositMappingCreateWithoutProviderServiceConfigurationInput;
}

export interface DepositMappingCreateOrConnectWithoutVisitCollectionRequestsInput {
  where: DepositMappingWhereUniqueInput;
  create: DepositMappingCreateWithoutVisitCollectionRequestsInput;
}

export interface DepositMappingCreateWithoutCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  amount?: number | null;
  useCopay: boolean;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedDepositMappingsInput;
  location: LocationCreateNestedOneWithoutDepositMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutDepositMappingsInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutDepositMappingInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutDepositMappingInput | null;
}

export interface DepositMappingCreateWithoutDepositMappingConditionsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  amount?: number | null;
  useCopay: boolean;
  createdBy: UserCreateNestedOneWithoutCreatedDepositMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedDepositMappingsInput;
  location: LocationCreateNestedOneWithoutDepositMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutDepositMappingsInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutDepositMappingInput | null;
}

export interface DepositMappingCreateWithoutLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  amount?: number | null;
  useCopay: boolean;
  createdBy: UserCreateNestedOneWithoutCreatedDepositMappingsInput;
  location: LocationCreateNestedOneWithoutDepositMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutDepositMappingsInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutDepositMappingInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutDepositMappingInput | null;
}

export interface DepositMappingCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  amount?: number | null;
  useCopay: boolean;
  createdBy: UserCreateNestedOneWithoutCreatedDepositMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedDepositMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutDepositMappingsInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutDepositMappingInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutDepositMappingInput | null;
}

export interface DepositMappingCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  amount?: number | null;
  useCopay: boolean;
  createdBy: UserCreateNestedOneWithoutCreatedDepositMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedDepositMappingsInput;
  location: LocationCreateNestedOneWithoutDepositMappingsInput;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutDepositMappingInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutDepositMappingInput | null;
}

export interface DepositMappingCreateWithoutVisitCollectionRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  amount?: number | null;
  useCopay: boolean;
  createdBy: UserCreateNestedOneWithoutCreatedDepositMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedDepositMappingsInput;
  location: LocationCreateNestedOneWithoutDepositMappingsInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutDepositMappingsInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutDepositMappingInput | null;
}

export interface DepositMappingListRelationFilter {
  every?: DepositMappingWhereInput | null;
  some?: DepositMappingWhereInput | null;
  none?: DepositMappingWhereInput | null;
}

export interface DepositMappingOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface DepositMappingRelationFilter {
  is?: DepositMappingWhereInput | null;
  isNot?: DepositMappingWhereInput | null;
}

export interface DepositMappingScalarWhereInput {
  AND?: DepositMappingScalarWhereInput[] | null;
  OR?: DepositMappingScalarWhereInput[] | null;
  NOT?: DepositMappingScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  priority?: FloatFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
  notes?: StringNullableFilter | null;
  amount?: IntNullableFilter | null;
  useCopay?: BoolFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
}

export interface DepositMappingUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  useCopay?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedDepositMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedDepositMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutDepositMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutDepositMappingsNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutDepositMappingNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutDepositMappingNestedInput | null;
}

export interface DepositMappingUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  useCopay?: BoolFieldUpdateOperationsInput | null;
}

export interface DepositMappingUpdateManyWithWhereWithoutCreatedByInput {
  where: DepositMappingScalarWhereInput;
  data: DepositMappingUpdateManyMutationInput;
}

export interface DepositMappingUpdateManyWithWhereWithoutLastUpdatedByInput {
  where: DepositMappingScalarWhereInput;
  data: DepositMappingUpdateManyMutationInput;
}

export interface DepositMappingUpdateManyWithWhereWithoutLocationInput {
  where: DepositMappingScalarWhereInput;
  data: DepositMappingUpdateManyMutationInput;
}

export interface DepositMappingUpdateManyWithWhereWithoutProviderServiceConfigurationInput {
  where: DepositMappingScalarWhereInput;
  data: DepositMappingUpdateManyMutationInput;
}

export interface DepositMappingUpdateManyWithoutCreatedByNestedInput {
  create?: DepositMappingCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: DepositMappingUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: DepositMappingCreateManyCreatedByInputEnvelope | null;
  set?: DepositMappingWhereUniqueInput[] | null;
  disconnect?: DepositMappingWhereUniqueInput[] | null;
  delete?: DepositMappingWhereUniqueInput[] | null;
  connect?: DepositMappingWhereUniqueInput[] | null;
  update?: DepositMappingUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: DepositMappingUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: DepositMappingScalarWhereInput[] | null;
}

export interface DepositMappingUpdateManyWithoutLastUpdatedByNestedInput {
  create?: DepositMappingCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutLastUpdatedByInput[] | null;
  upsert?: DepositMappingUpsertWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  createMany?: DepositMappingCreateManyLastUpdatedByInputEnvelope | null;
  set?: DepositMappingWhereUniqueInput[] | null;
  disconnect?: DepositMappingWhereUniqueInput[] | null;
  delete?: DepositMappingWhereUniqueInput[] | null;
  connect?: DepositMappingWhereUniqueInput[] | null;
  update?: DepositMappingUpdateWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  updateMany?: DepositMappingUpdateManyWithWhereWithoutLastUpdatedByInput[] | null;
  deleteMany?: DepositMappingScalarWhereInput[] | null;
}

export interface DepositMappingUpdateManyWithoutLocationNestedInput {
  create?: DepositMappingCreateWithoutLocationInput[] | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutLocationInput[] | null;
  upsert?: DepositMappingUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: DepositMappingCreateManyLocationInputEnvelope | null;
  set?: DepositMappingWhereUniqueInput[] | null;
  disconnect?: DepositMappingWhereUniqueInput[] | null;
  delete?: DepositMappingWhereUniqueInput[] | null;
  connect?: DepositMappingWhereUniqueInput[] | null;
  update?: DepositMappingUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: DepositMappingUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: DepositMappingScalarWhereInput[] | null;
}

export interface DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput {
  create?: DepositMappingCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  upsert?: DepositMappingUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  createMany?: DepositMappingCreateManyProviderServiceConfigurationInputEnvelope | null;
  set?: DepositMappingWhereUniqueInput[] | null;
  disconnect?: DepositMappingWhereUniqueInput[] | null;
  delete?: DepositMappingWhereUniqueInput[] | null;
  connect?: DepositMappingWhereUniqueInput[] | null;
  update?: DepositMappingUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  updateMany?: DepositMappingUpdateManyWithWhereWithoutProviderServiceConfigurationInput[] | null;
  deleteMany?: DepositMappingScalarWhereInput[] | null;
}

export interface DepositMappingUpdateOneRequiredWithoutDepositMappingConditionsNestedInput {
  create?: DepositMappingCreateWithoutDepositMappingConditionsInput | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutDepositMappingConditionsInput | null;
  upsert?: DepositMappingUpsertWithoutDepositMappingConditionsInput | null;
  connect?: DepositMappingWhereUniqueInput | null;
  update?: DepositMappingUpdateWithoutDepositMappingConditionsInput | null;
}

export interface DepositMappingUpdateOneWithoutVisitCollectionRequestsNestedInput {
  create?: DepositMappingCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: DepositMappingCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  upsert?: DepositMappingUpsertWithoutVisitCollectionRequestsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: DepositMappingWhereUniqueInput | null;
  update?: DepositMappingUpdateWithoutVisitCollectionRequestsInput | null;
}

export interface DepositMappingUpdateWithWhereUniqueWithoutCreatedByInput {
  where: DepositMappingWhereUniqueInput;
  data: DepositMappingUpdateWithoutCreatedByInput;
}

export interface DepositMappingUpdateWithWhereUniqueWithoutLastUpdatedByInput {
  where: DepositMappingWhereUniqueInput;
  data: DepositMappingUpdateWithoutLastUpdatedByInput;
}

export interface DepositMappingUpdateWithWhereUniqueWithoutLocationInput {
  where: DepositMappingWhereUniqueInput;
  data: DepositMappingUpdateWithoutLocationInput;
}

export interface DepositMappingUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: DepositMappingWhereUniqueInput;
  data: DepositMappingUpdateWithoutProviderServiceConfigurationInput;
}

export interface DepositMappingUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  useCopay?: BoolFieldUpdateOperationsInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedDepositMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutDepositMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutDepositMappingsNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutDepositMappingNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutDepositMappingNestedInput | null;
}

export interface DepositMappingUpdateWithoutDepositMappingConditionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  useCopay?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedDepositMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedDepositMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutDepositMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutDepositMappingsNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutDepositMappingNestedInput | null;
}

export interface DepositMappingUpdateWithoutLastUpdatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  useCopay?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedDepositMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutDepositMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutDepositMappingsNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutDepositMappingNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutDepositMappingNestedInput | null;
}

export interface DepositMappingUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  useCopay?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedDepositMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedDepositMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutDepositMappingsNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutDepositMappingNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutDepositMappingNestedInput | null;
}

export interface DepositMappingUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  useCopay?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedDepositMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedDepositMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutDepositMappingsNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutDepositMappingNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutDepositMappingNestedInput | null;
}

export interface DepositMappingUpdateWithoutVisitCollectionRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  useCopay?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedDepositMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedDepositMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutDepositMappingsNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutDepositMappingsNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutDepositMappingNestedInput | null;
}

export interface DepositMappingUpsertWithWhereUniqueWithoutCreatedByInput {
  where: DepositMappingWhereUniqueInput;
  update: DepositMappingUpdateWithoutCreatedByInput;
  create: DepositMappingCreateWithoutCreatedByInput;
}

export interface DepositMappingUpsertWithWhereUniqueWithoutLastUpdatedByInput {
  where: DepositMappingWhereUniqueInput;
  update: DepositMappingUpdateWithoutLastUpdatedByInput;
  create: DepositMappingCreateWithoutLastUpdatedByInput;
}

export interface DepositMappingUpsertWithWhereUniqueWithoutLocationInput {
  where: DepositMappingWhereUniqueInput;
  update: DepositMappingUpdateWithoutLocationInput;
  create: DepositMappingCreateWithoutLocationInput;
}

export interface DepositMappingUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: DepositMappingWhereUniqueInput;
  update: DepositMappingUpdateWithoutProviderServiceConfigurationInput;
  create: DepositMappingCreateWithoutProviderServiceConfigurationInput;
}

export interface DepositMappingUpsertWithoutDepositMappingConditionsInput {
  update: DepositMappingUpdateWithoutDepositMappingConditionsInput;
  create: DepositMappingCreateWithoutDepositMappingConditionsInput;
}

export interface DepositMappingUpsertWithoutVisitCollectionRequestsInput {
  update: DepositMappingUpdateWithoutVisitCollectionRequestsInput;
  create: DepositMappingCreateWithoutVisitCollectionRequestsInput;
}

export interface DepositMappingWhereInput {
  AND?: DepositMappingWhereInput[] | null;
  OR?: DepositMappingWhereInput[] | null;
  NOT?: DepositMappingWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  priority?: FloatFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
  notes?: StringNullableFilter | null;
  amount?: IntNullableFilter | null;
  useCopay?: BoolFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
  createdBy?: UserRelationFilter | null;
  lastUpdatedBy?: UserRelationFilter | null;
  location?: LocationRelationFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationRelationFilter | null;
  depositMappingConditions?: DepositMappingConditionListRelationFilter | null;
  visitCollectionRequests?: VisitCollectionRequestListRelationFilter | null;
}

export interface DepositMappingWhereUniqueInput {
  id?: string | null;
}

export interface EligibilityBenefitsExtractionCreateManyEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
}

export interface EligibilityBenefitsExtractionCreateManyEligibilityRequestInputEnvelope {
  data: EligibilityBenefitsExtractionCreateManyEligibilityRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput {
  create?: EligibilityBenefitsExtractionCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: EligibilityBenefitsExtractionCreateOrConnectWithoutEligibilityRequestInput[] | null;
  createMany?: EligibilityBenefitsExtractionCreateManyEligibilityRequestInputEnvelope | null;
  connect?: EligibilityBenefitsExtractionWhereUniqueInput[] | null;
}

export interface EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput {
  create?: EligibilityBenefitsExtractionCreateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connectOrCreate?: EligibilityBenefitsExtractionCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connect?: EligibilityBenefitsExtractionWhereUniqueInput | null;
}

export interface EligibilityBenefitsExtractionCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput {
  where: EligibilityBenefitsExtractionWhereUniqueInput;
  create: EligibilityBenefitsExtractionCreateWithoutEligibilityCoverageBenefitSnapshotsInput;
}

export interface EligibilityBenefitsExtractionCreateOrConnectWithoutEligibilityRequestInput {
  where: EligibilityBenefitsExtractionWhereUniqueInput;
  create: EligibilityBenefitsExtractionCreateWithoutEligibilityRequestInput;
}

export interface EligibilityBenefitsExtractionCreateWithoutEligibilityCoverageBenefitSnapshotsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutEligibilityBenefitsExtractionsInput;
}

export interface EligibilityBenefitsExtractionCreateWithoutEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutEligibilityBenefitExtractionInput | null;
}

export interface EligibilityBenefitsExtractionListRelationFilter {
  every?: EligibilityBenefitsExtractionWhereInput | null;
  some?: EligibilityBenefitsExtractionWhereInput | null;
  none?: EligibilityBenefitsExtractionWhereInput | null;
}

export interface EligibilityBenefitsExtractionRelationFilter {
  is?: EligibilityBenefitsExtractionWhereInput | null;
  isNot?: EligibilityBenefitsExtractionWhereInput | null;
}

export interface EligibilityBenefitsExtractionScalarWhereInput {
  AND?: EligibilityBenefitsExtractionScalarWhereInput[] | null;
  OR?: EligibilityBenefitsExtractionScalarWhereInput[] | null;
  NOT?: EligibilityBenefitsExtractionScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
}

export interface EligibilityBenefitsExtractionUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
}

export interface EligibilityBenefitsExtractionUpdateManyWithWhereWithoutEligibilityRequestInput {
  where: EligibilityBenefitsExtractionScalarWhereInput;
  data: EligibilityBenefitsExtractionUpdateManyMutationInput;
}

export interface EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput {
  create?: EligibilityBenefitsExtractionCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: EligibilityBenefitsExtractionCreateOrConnectWithoutEligibilityRequestInput[] | null;
  upsert?: EligibilityBenefitsExtractionUpsertWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  createMany?: EligibilityBenefitsExtractionCreateManyEligibilityRequestInputEnvelope | null;
  set?: EligibilityBenefitsExtractionWhereUniqueInput[] | null;
  disconnect?: EligibilityBenefitsExtractionWhereUniqueInput[] | null;
  delete?: EligibilityBenefitsExtractionWhereUniqueInput[] | null;
  connect?: EligibilityBenefitsExtractionWhereUniqueInput[] | null;
  update?: EligibilityBenefitsExtractionUpdateWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  updateMany?: EligibilityBenefitsExtractionUpdateManyWithWhereWithoutEligibilityRequestInput[] | null;
  deleteMany?: EligibilityBenefitsExtractionScalarWhereInput[] | null;
}

export interface EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput {
  create?: EligibilityBenefitsExtractionCreateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connectOrCreate?: EligibilityBenefitsExtractionCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  upsert?: EligibilityBenefitsExtractionUpsertWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connect?: EligibilityBenefitsExtractionWhereUniqueInput | null;
  update?: EligibilityBenefitsExtractionUpdateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
}

export interface EligibilityBenefitsExtractionUpdateWithWhereUniqueWithoutEligibilityRequestInput {
  where: EligibilityBenefitsExtractionWhereUniqueInput;
  data: EligibilityBenefitsExtractionUpdateWithoutEligibilityRequestInput;
}

export interface EligibilityBenefitsExtractionUpdateWithoutEligibilityCoverageBenefitSnapshotsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutEligibilityBenefitsExtractionsNestedInput | null;
}

export interface EligibilityBenefitsExtractionUpdateWithoutEligibilityRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutEligibilityBenefitExtractionNestedInput | null;
}

export interface EligibilityBenefitsExtractionUpsertWithWhereUniqueWithoutEligibilityRequestInput {
  where: EligibilityBenefitsExtractionWhereUniqueInput;
  update: EligibilityBenefitsExtractionUpdateWithoutEligibilityRequestInput;
  create: EligibilityBenefitsExtractionCreateWithoutEligibilityRequestInput;
}

export interface EligibilityBenefitsExtractionUpsertWithoutEligibilityCoverageBenefitSnapshotsInput {
  update: EligibilityBenefitsExtractionUpdateWithoutEligibilityCoverageBenefitSnapshotsInput;
  create: EligibilityBenefitsExtractionCreateWithoutEligibilityCoverageBenefitSnapshotsInput;
}

export interface EligibilityBenefitsExtractionWhereInput {
  AND?: EligibilityBenefitsExtractionWhereInput[] | null;
  OR?: EligibilityBenefitsExtractionWhereInput[] | null;
  NOT?: EligibilityBenefitsExtractionWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  eligibilityRequest?: EligibilityRequestRelationFilter | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
}

export interface EligibilityBenefitsExtractionWhereUniqueInput {
  id?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyActiveCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyActiveCoverageBenefitInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyActiveCoverageBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyCoinsuranceBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyCoinsuranceBenefitInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyCoinsuranceBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyCopayBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyCopayBenefitInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyCopayBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyDeductibleAppliesBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyDeductibleAppliesBenefitInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyDeductibleAppliesBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyEligibilityBenefitExtractionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyEligibilityBenefitExtractionInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyEligibilityBenefitExtractionInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyMaxDeductibleBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyMaxDeductibleBenefitInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyMaxDeductibleBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyMaxOutOfPocketBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyMaxOutOfPocketBenefitInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyMaxOutOfPocketBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyMaxVisitsBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyMaxVisitsBenefitInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyMaxVisitsBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyNonCoveredBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyNonCoveredBenefitInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyNonCoveredBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyProviderServiceConfigurationInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyProviderServiceConfigurationInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyRemainingDeductibleBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyRemainingDeductibleBenefitInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyRemainingDeductibleBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyRemainingOutOfPocketBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  remainingVisitsBenefitId?: string | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyRemainingOutOfPocketBenefitInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyRemainingOutOfPocketBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyRemainingVisitsBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  eligibilityBenefitExtractionId: string;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  copayBenefitId?: string | null;
  coinsurance?: any | null;
  coinsuranceBenefitId?: string | null;
  remainingDeductible?: number | null;
  remainingDeductibleBenefitId?: string | null;
  maxDeductible?: number | null;
  maxDeductibleBenefitId?: string | null;
  deductibleApplies?: boolean | null;
  deductibleAppliesBenefitId?: string | null;
  remainingOutOfPocket?: number | null;
  remainingOutOfPocketBenefitId?: string | null;
  maxOutOfPocket?: number | null;
  maxOutOfPocketBenefitId?: string | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  maxVisitsBenefitId?: string | null;
  nonCovered: boolean;
  nonCoveredBenefitId?: string | null;
  activeCoverageBenefitId?: string | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateManyRemainingVisitsBenefitInputEnvelope {
  data: EligibilityCoverageBenefitSnapshotCreateManyRemainingVisitsBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutActiveCoverageBenefitInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutActiveCoverageBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutActiveCoverageBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyActiveCoverageBenefitInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutCoinsuranceBenefitInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutCoinsuranceBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutCoinsuranceBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyCoinsuranceBenefitInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutCopayBenefitInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutCopayBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutCopayBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyCopayBenefitInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutDeductibleAppliesBenefitInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutDeductibleAppliesBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutDeductibleAppliesBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyDeductibleAppliesBenefitInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutEligibilityBenefitExtractionInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutEligibilityBenefitExtractionInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutEligibilityBenefitExtractionInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyEligibilityBenefitExtractionInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxDeductibleBenefitInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutMaxDeductibleBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutMaxDeductibleBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyMaxDeductibleBenefitInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxOutOfPocketBenefitInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutMaxOutOfPocketBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutMaxOutOfPocketBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyMaxOutOfPocketBenefitInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxVisitsBenefitInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutMaxVisitsBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutMaxVisitsBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyMaxVisitsBenefitInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutNonCoveredBenefitInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutNonCoveredBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutNonCoveredBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyNonCoveredBenefitInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyProviderServiceConfigurationInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingDeductibleBenefitInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingDeductibleBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutRemainingDeductibleBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyRemainingDeductibleBenefitInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingOutOfPocketBenefitInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingOutOfPocketBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutRemainingOutOfPocketBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyRemainingOutOfPocketBenefitInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingVisitsBenefitInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingVisitsBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutRemainingVisitsBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyRemainingVisitsBenefitInputEnvelope | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateNestedOneWithoutCoverageBenefitsInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutCoverageBenefitsInput | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutActiveCoverageBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutActiveCoverageBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutCoinsuranceBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutCoinsuranceBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutCopayBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutCopayBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutCoverageBenefitsInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutCoverageBenefitsInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutDeductibleAppliesBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutDeductibleAppliesBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutEligibilityBenefitExtractionInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutEligibilityBenefitExtractionInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutMaxDeductibleBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutMaxDeductibleBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutMaxOutOfPocketBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutMaxOutOfPocketBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutMaxVisitsBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutMaxVisitsBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutNonCoveredBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutNonCoveredBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutProviderServiceConfigurationInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutRemainingDeductibleBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingDeductibleBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutRemainingOutOfPocketBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingOutOfPocketBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutRemainingVisitsBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingVisitsBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutActiveCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutCoinsuranceBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutCopayBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutDeductibleAppliesBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutEligibilityBenefitExtractionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutMaxDeductibleBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutMaxOutOfPocketBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutMaxVisitsBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutNonCoveredBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutRemainingDeductibleBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutRemainingOutOfPocketBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  remainingVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotCreateWithoutRemainingVisitsBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  empty: boolean;
  networkStatus: boolean;
  coverageLevel?: string | null;
  authRequired?: boolean | null;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  maxDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  maxOutOfPocket?: number | null;
  remainingVisits?: number | null;
  maxVisits?: number | null;
  nonCovered: boolean;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  eligibilityBenefitExtraction: EligibilityBenefitsExtractionCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput;
  copayBenefit?: CopayBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxDeductibleBenefit?: DeductibleBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitCreateNestedOneWithoutAppliesEligibilityCoverageBenefitSnapshotInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  maxVisitsBenefit?: LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  nonCoveredBenefit?: OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  activeCoverageBenefit?: OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutEligibilityCoverageBenefitSnapshotInput | null;
}

export interface EligibilityCoverageBenefitSnapshotListRelationFilter {
  every?: EligibilityCoverageBenefitSnapshotWhereInput | null;
  some?: EligibilityCoverageBenefitSnapshotWhereInput | null;
  none?: EligibilityCoverageBenefitSnapshotWhereInput | null;
}

export interface EligibilityCoverageBenefitSnapshotRelationFilter {
  is?: EligibilityCoverageBenefitSnapshotWhereInput | null;
  isNot?: EligibilityCoverageBenefitSnapshotWhereInput | null;
}

export interface EligibilityCoverageBenefitSnapshotScalarWhereInput {
  AND?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
  OR?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
  NOT?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  providerServiceConfigurationId?: UuidFilter | null;
  eligibilityBenefitExtractionId?: UuidFilter | null;
  empty?: BoolFilter | null;
  networkStatus?: BoolFilter | null;
  coverageLevel?: StringNullableFilter | null;
  authRequired?: BoolNullableFilter | null;
  copay?: IntNullableFilter | null;
  copayBenefitId?: UuidNullableFilter | null;
  coinsurance?: DecimalNullableFilter | null;
  coinsuranceBenefitId?: UuidNullableFilter | null;
  remainingDeductible?: IntNullableFilter | null;
  remainingDeductibleBenefitId?: UuidNullableFilter | null;
  maxDeductible?: IntNullableFilter | null;
  maxDeductibleBenefitId?: UuidNullableFilter | null;
  deductibleApplies?: BoolNullableFilter | null;
  deductibleAppliesBenefitId?: UuidNullableFilter | null;
  remainingOutOfPocket?: IntNullableFilter | null;
  remainingOutOfPocketBenefitId?: UuidNullableFilter | null;
  maxOutOfPocket?: IntNullableFilter | null;
  maxOutOfPocketBenefitId?: UuidNullableFilter | null;
  remainingVisits?: IntNullableFilter | null;
  remainingVisitsBenefitId?: UuidNullableFilter | null;
  maxVisits?: IntNullableFilter | null;
  maxVisitsBenefitId?: UuidNullableFilter | null;
  nonCovered?: BoolFilter | null;
  nonCoveredBenefitId?: UuidNullableFilter | null;
  activeCoverageBenefitId?: UuidNullableFilter | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutActiveCoverageBenefitInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutCoinsuranceBenefitInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutCopayBenefitInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutDeductibleAppliesBenefitInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutEligibilityBenefitExtractionInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutMaxDeductibleBenefitInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutMaxOutOfPocketBenefitInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutMaxVisitsBenefitInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutNonCoveredBenefitInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutProviderServiceConfigurationInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutRemainingDeductibleBenefitInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutRemainingOutOfPocketBenefitInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutRemainingVisitsBenefitInput {
  where: EligibilityCoverageBenefitSnapshotScalarWhereInput;
  data: EligibilityCoverageBenefitSnapshotUpdateManyMutationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutActiveCoverageBenefitNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutActiveCoverageBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutActiveCoverageBenefitInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutActiveCoverageBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyActiveCoverageBenefitInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutActiveCoverageBenefitInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutActiveCoverageBenefitInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutCoinsuranceBenefitNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutCoinsuranceBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutCoinsuranceBenefitInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutCoinsuranceBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyCoinsuranceBenefitInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutCoinsuranceBenefitInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutCoinsuranceBenefitInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutCopayBenefitNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutCopayBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutCopayBenefitInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutCopayBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyCopayBenefitInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutCopayBenefitInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutCopayBenefitInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutDeductibleAppliesBenefitNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutDeductibleAppliesBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutDeductibleAppliesBenefitInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutDeductibleAppliesBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyDeductibleAppliesBenefitInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutDeductibleAppliesBenefitInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutDeductibleAppliesBenefitInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutEligibilityBenefitExtractionNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutEligibilityBenefitExtractionInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutEligibilityBenefitExtractionInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutEligibilityBenefitExtractionInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyEligibilityBenefitExtractionInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutEligibilityBenefitExtractionInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutEligibilityBenefitExtractionInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxDeductibleBenefitNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutMaxDeductibleBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutMaxDeductibleBenefitInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutMaxDeductibleBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyMaxDeductibleBenefitInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutMaxDeductibleBenefitInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutMaxDeductibleBenefitInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxOutOfPocketBenefitNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutMaxOutOfPocketBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutMaxOutOfPocketBenefitInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutMaxOutOfPocketBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyMaxOutOfPocketBenefitInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutMaxOutOfPocketBenefitInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutMaxOutOfPocketBenefitInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxVisitsBenefitNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutMaxVisitsBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutMaxVisitsBenefitInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutMaxVisitsBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyMaxVisitsBenefitInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutMaxVisitsBenefitInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutMaxVisitsBenefitInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutNonCoveredBenefitNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutNonCoveredBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutNonCoveredBenefitInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutNonCoveredBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyNonCoveredBenefitInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutNonCoveredBenefitInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutNonCoveredBenefitInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyProviderServiceConfigurationInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutProviderServiceConfigurationInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingDeductibleBenefitNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingDeductibleBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutRemainingDeductibleBenefitInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutRemainingDeductibleBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyRemainingDeductibleBenefitInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutRemainingDeductibleBenefitInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutRemainingDeductibleBenefitInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingOutOfPocketBenefitNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingOutOfPocketBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutRemainingOutOfPocketBenefitInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutRemainingOutOfPocketBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyRemainingOutOfPocketBenefitInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutRemainingOutOfPocketBenefitInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutRemainingOutOfPocketBenefitInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingVisitsBenefitNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingVisitsBenefitInput[] | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutRemainingVisitsBenefitInput[] | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutRemainingVisitsBenefitInput[] | null;
  createMany?: EligibilityCoverageBenefitSnapshotCreateManyRemainingVisitsBenefitInputEnvelope | null;
  set?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  disconnect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  delete?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput[] | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutRemainingVisitsBenefitInput[] | null;
  updateMany?: EligibilityCoverageBenefitSnapshotUpdateManyWithWhereWithoutRemainingVisitsBenefitInput[] | null;
  deleteMany?: EligibilityCoverageBenefitSnapshotScalarWhereInput[] | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateOneWithoutCoverageBenefitsNestedInput {
  create?: EligibilityCoverageBenefitSnapshotCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: EligibilityCoverageBenefitSnapshotCreateOrConnectWithoutCoverageBenefitsInput | null;
  upsert?: EligibilityCoverageBenefitSnapshotUpsertWithoutCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: EligibilityCoverageBenefitSnapshotWhereUniqueInput | null;
  update?: EligibilityCoverageBenefitSnapshotUpdateWithoutCoverageBenefitsInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutActiveCoverageBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutActiveCoverageBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutCoinsuranceBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutCoinsuranceBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutCopayBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutCopayBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutDeductibleAppliesBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutDeductibleAppliesBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutEligibilityBenefitExtractionInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutEligibilityBenefitExtractionInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutMaxDeductibleBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutMaxDeductibleBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutMaxOutOfPocketBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutMaxOutOfPocketBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutMaxVisitsBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutMaxVisitsBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutNonCoveredBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutNonCoveredBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutProviderServiceConfigurationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutRemainingDeductibleBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutRemainingDeductibleBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutRemainingOutOfPocketBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutRemainingOutOfPocketBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithWhereUniqueWithoutRemainingVisitsBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  data: EligibilityCoverageBenefitSnapshotUpdateWithoutRemainingVisitsBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutActiveCoverageBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutCoinsuranceBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutCopayBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutDeductibleAppliesBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutEligibilityBenefitExtractionInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutMaxDeductibleBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutMaxOutOfPocketBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutMaxVisitsBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutNonCoveredBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutRemainingDeductibleBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutRemainingOutOfPocketBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingVisitsBenefit?: LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpdateWithoutRemainingVisitsBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  empty?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: BoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  nonCovered?: BoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  copayBenefit?: CopayBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  coinsuranceBenefit?: CoinsuranceBenefitUpdateOneWithoutEligibilityCoverageBenefitSnapshotsNestedInput | null;
  remainingDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxDeductibleBenefit?: DeductibleBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  deductibleAppliesBenefit?: DeductibleBenefitUpdateOneWithoutAppliesEligibilityCoverageBenefitSnapshotNestedInput | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  maxVisitsBenefit?: LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput | null;
  nonCoveredBenefit?: OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput | null;
  activeCoverageBenefit?: OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutEligibilityCoverageBenefitSnapshotNestedInput | null;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutActiveCoverageBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutActiveCoverageBenefitInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutActiveCoverageBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutCoinsuranceBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutCoinsuranceBenefitInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutCoinsuranceBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutCopayBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutCopayBenefitInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutCopayBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutDeductibleAppliesBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutDeductibleAppliesBenefitInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutDeductibleAppliesBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutEligibilityBenefitExtractionInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutEligibilityBenefitExtractionInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutEligibilityBenefitExtractionInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutMaxDeductibleBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutMaxDeductibleBenefitInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutMaxDeductibleBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutMaxOutOfPocketBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutMaxOutOfPocketBenefitInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutMaxOutOfPocketBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutMaxVisitsBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutMaxVisitsBenefitInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutMaxVisitsBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutNonCoveredBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutNonCoveredBenefitInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutNonCoveredBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutProviderServiceConfigurationInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutProviderServiceConfigurationInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutRemainingDeductibleBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutRemainingDeductibleBenefitInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingDeductibleBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutRemainingOutOfPocketBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutRemainingOutOfPocketBenefitInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingOutOfPocketBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithWhereUniqueWithoutRemainingVisitsBenefitInput {
  where: EligibilityCoverageBenefitSnapshotWhereUniqueInput;
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutRemainingVisitsBenefitInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutRemainingVisitsBenefitInput;
}

export interface EligibilityCoverageBenefitSnapshotUpsertWithoutCoverageBenefitsInput {
  update: EligibilityCoverageBenefitSnapshotUpdateWithoutCoverageBenefitsInput;
  create: EligibilityCoverageBenefitSnapshotCreateWithoutCoverageBenefitsInput;
}

export interface EligibilityCoverageBenefitSnapshotWhereInput {
  AND?: EligibilityCoverageBenefitSnapshotWhereInput[] | null;
  OR?: EligibilityCoverageBenefitSnapshotWhereInput[] | null;
  NOT?: EligibilityCoverageBenefitSnapshotWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  providerServiceConfigurationId?: UuidFilter | null;
  eligibilityBenefitExtractionId?: UuidFilter | null;
  empty?: BoolFilter | null;
  networkStatus?: BoolFilter | null;
  coverageLevel?: StringNullableFilter | null;
  authRequired?: BoolNullableFilter | null;
  copay?: IntNullableFilter | null;
  copayBenefitId?: UuidNullableFilter | null;
  coinsurance?: DecimalNullableFilter | null;
  coinsuranceBenefitId?: UuidNullableFilter | null;
  remainingDeductible?: IntNullableFilter | null;
  remainingDeductibleBenefitId?: UuidNullableFilter | null;
  maxDeductible?: IntNullableFilter | null;
  maxDeductibleBenefitId?: UuidNullableFilter | null;
  deductibleApplies?: BoolNullableFilter | null;
  deductibleAppliesBenefitId?: UuidNullableFilter | null;
  remainingOutOfPocket?: IntNullableFilter | null;
  remainingOutOfPocketBenefitId?: UuidNullableFilter | null;
  maxOutOfPocket?: IntNullableFilter | null;
  maxOutOfPocketBenefitId?: UuidNullableFilter | null;
  remainingVisits?: IntNullableFilter | null;
  remainingVisitsBenefitId?: UuidNullableFilter | null;
  maxVisits?: IntNullableFilter | null;
  maxVisitsBenefitId?: UuidNullableFilter | null;
  nonCovered?: BoolFilter | null;
  nonCoveredBenefitId?: UuidNullableFilter | null;
  activeCoverageBenefitId?: UuidNullableFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationRelationFilter | null;
  eligibilityBenefitExtraction?: EligibilityBenefitsExtractionRelationFilter | null;
  copayBenefit?: CopayBenefitRelationFilter | null;
  coinsuranceBenefit?: CoinsuranceBenefitRelationFilter | null;
  remainingDeductibleBenefit?: DeductibleBenefitRelationFilter | null;
  maxDeductibleBenefit?: DeductibleBenefitRelationFilter | null;
  deductibleAppliesBenefit?: DeductibleBenefitRelationFilter | null;
  remainingOutOfPocketBenefit?: OutOfPocketBenefitRelationFilter | null;
  maxOutOfPocketBenefit?: OutOfPocketBenefitRelationFilter | null;
  remainingVisitsBenefit?: LimitationBenefitRelationFilter | null;
  maxVisitsBenefit?: LimitationBenefitRelationFilter | null;
  nonCoveredBenefit?: OtherBenefitRelationFilter | null;
  activeCoverageBenefit?: OtherBenefitRelationFilter | null;
  coverageBenefits?: CoverageBenefitListRelationFilter | null;
}

export interface EligibilityCoverageBenefitSnapshotWhereUniqueInput {
  id?: string | null;
}

export interface EligibilityRequestCreateManyAccountCoverageInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  insurancePolicyId: string;
  providerId?: string | null;
  appointmentId?: string | null;
  requestedById?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
}

export interface EligibilityRequestCreateManyAccountCoverageInputEnvelope {
  data: EligibilityRequestCreateManyAccountCoverageInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityRequestCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  accountCoverageId?: string | null;
  insurancePolicyId: string;
  providerId?: string | null;
  requestedById?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
}

export interface EligibilityRequestCreateManyAppointmentInputEnvelope {
  data: EligibilityRequestCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityRequestCreateManyInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  accountCoverageId?: string | null;
  providerId?: string | null;
  appointmentId?: string | null;
  requestedById?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
}

export interface EligibilityRequestCreateManyInsurancePolicyInputEnvelope {
  data: EligibilityRequestCreateManyInsurancePolicyInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityRequestCreateManyProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  accountCoverageId?: string | null;
  insurancePolicyId: string;
  appointmentId?: string | null;
  requestedById?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
}

export interface EligibilityRequestCreateManyProviderInputEnvelope {
  data: EligibilityRequestCreateManyProviderInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityRequestCreateManyRequestedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  accountCoverageId?: string | null;
  insurancePolicyId: string;
  providerId?: string | null;
  appointmentId?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
}

export interface EligibilityRequestCreateManyRequestedByInputEnvelope {
  data: EligibilityRequestCreateManyRequestedByInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityRequestCreateNestedManyWithoutAccountCoverageInput {
  create?: EligibilityRequestCreateWithoutAccountCoverageInput[] | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutAccountCoverageInput[] | null;
  createMany?: EligibilityRequestCreateManyAccountCoverageInputEnvelope | null;
  connect?: EligibilityRequestWhereUniqueInput[] | null;
}

export interface EligibilityRequestCreateNestedManyWithoutAppointmentInput {
  create?: EligibilityRequestCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: EligibilityRequestCreateManyAppointmentInputEnvelope | null;
  connect?: EligibilityRequestWhereUniqueInput[] | null;
}

export interface EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput {
  create?: EligibilityRequestCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutInsurancePolicyInput[] | null;
  createMany?: EligibilityRequestCreateManyInsurancePolicyInputEnvelope | null;
  connect?: EligibilityRequestWhereUniqueInput[] | null;
}

export interface EligibilityRequestCreateNestedManyWithoutProviderInput {
  create?: EligibilityRequestCreateWithoutProviderInput[] | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutProviderInput[] | null;
  createMany?: EligibilityRequestCreateManyProviderInputEnvelope | null;
  connect?: EligibilityRequestWhereUniqueInput[] | null;
}

export interface EligibilityRequestCreateNestedManyWithoutRequestedByInput {
  create?: EligibilityRequestCreateWithoutRequestedByInput[] | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutRequestedByInput[] | null;
  createMany?: EligibilityRequestCreateManyRequestedByInputEnvelope | null;
  connect?: EligibilityRequestWhereUniqueInput[] | null;
}

export interface EligibilityRequestCreateNestedOneWithoutCoinsuranceBenefitsInput {
  create?: EligibilityRequestCreateWithoutCoinsuranceBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutCoinsuranceBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
}

export interface EligibilityRequestCreateNestedOneWithoutCopayBenefitsInput {
  create?: EligibilityRequestCreateWithoutCopayBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutCopayBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
}

export interface EligibilityRequestCreateNestedOneWithoutDeductibleBenefitsInput {
  create?: EligibilityRequestCreateWithoutDeductibleBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutDeductibleBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
}

export interface EligibilityRequestCreateNestedOneWithoutEligibilityBenefitsExtractionsInput {
  create?: EligibilityRequestCreateWithoutEligibilityBenefitsExtractionsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutEligibilityBenefitsExtractionsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
}

export interface EligibilityRequestCreateNestedOneWithoutLimitationBenefitsInput {
  create?: EligibilityRequestCreateWithoutLimitationBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutLimitationBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
}

export interface EligibilityRequestCreateNestedOneWithoutOtherBenefitsInput {
  create?: EligibilityRequestCreateWithoutOtherBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutOtherBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
}

export interface EligibilityRequestCreateNestedOneWithoutOutOfPocketBenefitsInput {
  create?: EligibilityRequestCreateWithoutOutOfPocketBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutOutOfPocketBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
}

export interface EligibilityRequestCreateOrConnectWithoutAccountCoverageInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutAccountCoverageInput;
}

export interface EligibilityRequestCreateOrConnectWithoutAppointmentInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutAppointmentInput;
}

export interface EligibilityRequestCreateOrConnectWithoutCoinsuranceBenefitsInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutCoinsuranceBenefitsInput;
}

export interface EligibilityRequestCreateOrConnectWithoutCopayBenefitsInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutCopayBenefitsInput;
}

export interface EligibilityRequestCreateOrConnectWithoutDeductibleBenefitsInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutDeductibleBenefitsInput;
}

export interface EligibilityRequestCreateOrConnectWithoutEligibilityBenefitsExtractionsInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutEligibilityBenefitsExtractionsInput;
}

export interface EligibilityRequestCreateOrConnectWithoutInsurancePolicyInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutInsurancePolicyInput;
}

export interface EligibilityRequestCreateOrConnectWithoutLimitationBenefitsInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutLimitationBenefitsInput;
}

export interface EligibilityRequestCreateOrConnectWithoutOtherBenefitsInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutOtherBenefitsInput;
}

export interface EligibilityRequestCreateOrConnectWithoutOutOfPocketBenefitsInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutOutOfPocketBenefitsInput;
}

export interface EligibilityRequestCreateOrConnectWithoutProviderInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutProviderInput;
}

export interface EligibilityRequestCreateOrConnectWithoutRequestedByInput {
  where: EligibilityRequestWhereUniqueInput;
  create: EligibilityRequestCreateWithoutRequestedByInput;
}

export interface EligibilityRequestCreateWithoutAccountCoverageInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput;
  provider?: ProviderCreateNestedOneWithoutEligibilityRequestsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutEligibilityRequestsInput | null;
  requestedBy?: UserCreateNestedOneWithoutEligibilityRequestsInput | null;
  deductibleBenefits?: DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  copayBenefits?: CopayBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  limitationBenefits?: LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  otherBenefits?: OtherBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput;
  provider?: ProviderCreateNestedOneWithoutEligibilityRequestsInput | null;
  requestedBy?: UserCreateNestedOneWithoutEligibilityRequestsInput | null;
  deductibleBenefits?: DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  copayBenefits?: CopayBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  limitationBenefits?: LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  otherBenefits?: OtherBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestCreateWithoutCoinsuranceBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput;
  provider?: ProviderCreateNestedOneWithoutEligibilityRequestsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutEligibilityRequestsInput | null;
  requestedBy?: UserCreateNestedOneWithoutEligibilityRequestsInput | null;
  deductibleBenefits?: DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  copayBenefits?: CopayBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  limitationBenefits?: LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  otherBenefits?: OtherBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestCreateWithoutCopayBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput;
  provider?: ProviderCreateNestedOneWithoutEligibilityRequestsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutEligibilityRequestsInput | null;
  requestedBy?: UserCreateNestedOneWithoutEligibilityRequestsInput | null;
  deductibleBenefits?: DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  limitationBenefits?: LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  otherBenefits?: OtherBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestCreateWithoutDeductibleBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput;
  provider?: ProviderCreateNestedOneWithoutEligibilityRequestsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutEligibilityRequestsInput | null;
  requestedBy?: UserCreateNestedOneWithoutEligibilityRequestsInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  copayBenefits?: CopayBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  limitationBenefits?: LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  otherBenefits?: OtherBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestCreateWithoutEligibilityBenefitsExtractionsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput;
  provider?: ProviderCreateNestedOneWithoutEligibilityRequestsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutEligibilityRequestsInput | null;
  requestedBy?: UserCreateNestedOneWithoutEligibilityRequestsInput | null;
  deductibleBenefits?: DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  copayBenefits?: CopayBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  limitationBenefits?: LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  otherBenefits?: OtherBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestCreateWithoutInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput | null;
  provider?: ProviderCreateNestedOneWithoutEligibilityRequestsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutEligibilityRequestsInput | null;
  requestedBy?: UserCreateNestedOneWithoutEligibilityRequestsInput | null;
  deductibleBenefits?: DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  copayBenefits?: CopayBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  limitationBenefits?: LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  otherBenefits?: OtherBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestCreateWithoutLimitationBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput;
  provider?: ProviderCreateNestedOneWithoutEligibilityRequestsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutEligibilityRequestsInput | null;
  requestedBy?: UserCreateNestedOneWithoutEligibilityRequestsInput | null;
  deductibleBenefits?: DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  copayBenefits?: CopayBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  otherBenefits?: OtherBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestCreateWithoutOtherBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput;
  provider?: ProviderCreateNestedOneWithoutEligibilityRequestsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutEligibilityRequestsInput | null;
  requestedBy?: UserCreateNestedOneWithoutEligibilityRequestsInput | null;
  deductibleBenefits?: DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  copayBenefits?: CopayBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  limitationBenefits?: LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestCreateWithoutOutOfPocketBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput;
  provider?: ProviderCreateNestedOneWithoutEligibilityRequestsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutEligibilityRequestsInput | null;
  requestedBy?: UserCreateNestedOneWithoutEligibilityRequestsInput | null;
  deductibleBenefits?: DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  copayBenefits?: CopayBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  limitationBenefits?: LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  otherBenefits?: OtherBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestCreateWithoutProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput;
  appointment?: AppointmentCreateNestedOneWithoutEligibilityRequestsInput | null;
  requestedBy?: UserCreateNestedOneWithoutEligibilityRequestsInput | null;
  deductibleBenefits?: DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  copayBenefits?: CopayBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  limitationBenefits?: LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  otherBenefits?: OtherBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestCreateWithoutRequestedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  finishedAt?: any | null;
  status?: EligibilityRequestStatus | null;
  clearinghouse?: Clearinghouse | null;
  clearinghouseStatusCode?: string | null;
  automated?: boolean | null;
  inNetwork: boolean;
  eligible: boolean;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutEligibilityRequestsInput | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput;
  provider?: ProviderCreateNestedOneWithoutEligibilityRequestsInput | null;
  appointment?: AppointmentCreateNestedOneWithoutEligibilityRequestsInput | null;
  deductibleBenefits?: DeductibleBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  copayBenefits?: CopayBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  limitationBenefits?: LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  otherBenefits?: OtherBenefitCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionCreateNestedManyWithoutEligibilityRequestInput | null;
}

export interface EligibilityRequestListRelationFilter {
  every?: EligibilityRequestWhereInput | null;
  some?: EligibilityRequestWhereInput | null;
  none?: EligibilityRequestWhereInput | null;
}

export interface EligibilityRequestOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface EligibilityRequestRelationFilter {
  is?: EligibilityRequestWhereInput | null;
  isNot?: EligibilityRequestWhereInput | null;
}

export interface EligibilityRequestScalarWhereInput {
  AND?: EligibilityRequestScalarWhereInput[] | null;
  OR?: EligibilityRequestScalarWhereInput[] | null;
  NOT?: EligibilityRequestScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  finishedAt?: DateTimeNullableFilter | null;
  status?: EnumEligibilityRequestStatusFilter | null;
  clearinghouse?: EnumClearinghouseFilter | null;
  clearinghouseStatusCode?: StringNullableFilter | null;
  accountCoverageId?: UuidNullableFilter | null;
  insurancePolicyId?: UuidFilter | null;
  providerId?: UuidNullableFilter | null;
  appointmentId?: UuidNullableFilter | null;
  requestedById?: UuidNullableFilter | null;
  automated?: BoolFilter | null;
  inNetwork?: BoolFilter | null;
  eligible?: BoolFilter | null;
  request?: JsonNullableFilter | null;
  response?: JsonNullableFilter | null;
}

export interface EligibilityRequestUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
}

export interface EligibilityRequestUpdateManyWithWhereWithoutAccountCoverageInput {
  where: EligibilityRequestScalarWhereInput;
  data: EligibilityRequestUpdateManyMutationInput;
}

export interface EligibilityRequestUpdateManyWithWhereWithoutAppointmentInput {
  where: EligibilityRequestScalarWhereInput;
  data: EligibilityRequestUpdateManyMutationInput;
}

export interface EligibilityRequestUpdateManyWithWhereWithoutInsurancePolicyInput {
  where: EligibilityRequestScalarWhereInput;
  data: EligibilityRequestUpdateManyMutationInput;
}

export interface EligibilityRequestUpdateManyWithWhereWithoutProviderInput {
  where: EligibilityRequestScalarWhereInput;
  data: EligibilityRequestUpdateManyMutationInput;
}

export interface EligibilityRequestUpdateManyWithWhereWithoutRequestedByInput {
  where: EligibilityRequestScalarWhereInput;
  data: EligibilityRequestUpdateManyMutationInput;
}

export interface EligibilityRequestUpdateManyWithoutAccountCoverageNestedInput {
  create?: EligibilityRequestCreateWithoutAccountCoverageInput[] | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutAccountCoverageInput[] | null;
  upsert?: EligibilityRequestUpsertWithWhereUniqueWithoutAccountCoverageInput[] | null;
  createMany?: EligibilityRequestCreateManyAccountCoverageInputEnvelope | null;
  set?: EligibilityRequestWhereUniqueInput[] | null;
  disconnect?: EligibilityRequestWhereUniqueInput[] | null;
  delete?: EligibilityRequestWhereUniqueInput[] | null;
  connect?: EligibilityRequestWhereUniqueInput[] | null;
  update?: EligibilityRequestUpdateWithWhereUniqueWithoutAccountCoverageInput[] | null;
  updateMany?: EligibilityRequestUpdateManyWithWhereWithoutAccountCoverageInput[] | null;
  deleteMany?: EligibilityRequestScalarWhereInput[] | null;
}

export interface EligibilityRequestUpdateManyWithoutAppointmentNestedInput {
  create?: EligibilityRequestCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: EligibilityRequestUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: EligibilityRequestCreateManyAppointmentInputEnvelope | null;
  set?: EligibilityRequestWhereUniqueInput[] | null;
  disconnect?: EligibilityRequestWhereUniqueInput[] | null;
  delete?: EligibilityRequestWhereUniqueInput[] | null;
  connect?: EligibilityRequestWhereUniqueInput[] | null;
  update?: EligibilityRequestUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: EligibilityRequestUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: EligibilityRequestScalarWhereInput[] | null;
}

export interface EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput {
  create?: EligibilityRequestCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutInsurancePolicyInput[] | null;
  upsert?: EligibilityRequestUpsertWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  createMany?: EligibilityRequestCreateManyInsurancePolicyInputEnvelope | null;
  set?: EligibilityRequestWhereUniqueInput[] | null;
  disconnect?: EligibilityRequestWhereUniqueInput[] | null;
  delete?: EligibilityRequestWhereUniqueInput[] | null;
  connect?: EligibilityRequestWhereUniqueInput[] | null;
  update?: EligibilityRequestUpdateWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  updateMany?: EligibilityRequestUpdateManyWithWhereWithoutInsurancePolicyInput[] | null;
  deleteMany?: EligibilityRequestScalarWhereInput[] | null;
}

export interface EligibilityRequestUpdateManyWithoutProviderNestedInput {
  create?: EligibilityRequestCreateWithoutProviderInput[] | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutProviderInput[] | null;
  upsert?: EligibilityRequestUpsertWithWhereUniqueWithoutProviderInput[] | null;
  createMany?: EligibilityRequestCreateManyProviderInputEnvelope | null;
  set?: EligibilityRequestWhereUniqueInput[] | null;
  disconnect?: EligibilityRequestWhereUniqueInput[] | null;
  delete?: EligibilityRequestWhereUniqueInput[] | null;
  connect?: EligibilityRequestWhereUniqueInput[] | null;
  update?: EligibilityRequestUpdateWithWhereUniqueWithoutProviderInput[] | null;
  updateMany?: EligibilityRequestUpdateManyWithWhereWithoutProviderInput[] | null;
  deleteMany?: EligibilityRequestScalarWhereInput[] | null;
}

export interface EligibilityRequestUpdateManyWithoutRequestedByNestedInput {
  create?: EligibilityRequestCreateWithoutRequestedByInput[] | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutRequestedByInput[] | null;
  upsert?: EligibilityRequestUpsertWithWhereUniqueWithoutRequestedByInput[] | null;
  createMany?: EligibilityRequestCreateManyRequestedByInputEnvelope | null;
  set?: EligibilityRequestWhereUniqueInput[] | null;
  disconnect?: EligibilityRequestWhereUniqueInput[] | null;
  delete?: EligibilityRequestWhereUniqueInput[] | null;
  connect?: EligibilityRequestWhereUniqueInput[] | null;
  update?: EligibilityRequestUpdateWithWhereUniqueWithoutRequestedByInput[] | null;
  updateMany?: EligibilityRequestUpdateManyWithWhereWithoutRequestedByInput[] | null;
  deleteMany?: EligibilityRequestScalarWhereInput[] | null;
}

export interface EligibilityRequestUpdateOneRequiredWithoutCoinsuranceBenefitsNestedInput {
  create?: EligibilityRequestCreateWithoutCoinsuranceBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutCoinsuranceBenefitsInput | null;
  upsert?: EligibilityRequestUpsertWithoutCoinsuranceBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
  update?: EligibilityRequestUpdateWithoutCoinsuranceBenefitsInput | null;
}

export interface EligibilityRequestUpdateOneRequiredWithoutCopayBenefitsNestedInput {
  create?: EligibilityRequestCreateWithoutCopayBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutCopayBenefitsInput | null;
  upsert?: EligibilityRequestUpsertWithoutCopayBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
  update?: EligibilityRequestUpdateWithoutCopayBenefitsInput | null;
}

export interface EligibilityRequestUpdateOneRequiredWithoutDeductibleBenefitsNestedInput {
  create?: EligibilityRequestCreateWithoutDeductibleBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutDeductibleBenefitsInput | null;
  upsert?: EligibilityRequestUpsertWithoutDeductibleBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
  update?: EligibilityRequestUpdateWithoutDeductibleBenefitsInput | null;
}

export interface EligibilityRequestUpdateOneRequiredWithoutEligibilityBenefitsExtractionsNestedInput {
  create?: EligibilityRequestCreateWithoutEligibilityBenefitsExtractionsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutEligibilityBenefitsExtractionsInput | null;
  upsert?: EligibilityRequestUpsertWithoutEligibilityBenefitsExtractionsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
  update?: EligibilityRequestUpdateWithoutEligibilityBenefitsExtractionsInput | null;
}

export interface EligibilityRequestUpdateOneRequiredWithoutLimitationBenefitsNestedInput {
  create?: EligibilityRequestCreateWithoutLimitationBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutLimitationBenefitsInput | null;
  upsert?: EligibilityRequestUpsertWithoutLimitationBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
  update?: EligibilityRequestUpdateWithoutLimitationBenefitsInput | null;
}

export interface EligibilityRequestUpdateOneRequiredWithoutOtherBenefitsNestedInput {
  create?: EligibilityRequestCreateWithoutOtherBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutOtherBenefitsInput | null;
  upsert?: EligibilityRequestUpsertWithoutOtherBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
  update?: EligibilityRequestUpdateWithoutOtherBenefitsInput | null;
}

export interface EligibilityRequestUpdateOneRequiredWithoutOutOfPocketBenefitsNestedInput {
  create?: EligibilityRequestCreateWithoutOutOfPocketBenefitsInput | null;
  connectOrCreate?: EligibilityRequestCreateOrConnectWithoutOutOfPocketBenefitsInput | null;
  upsert?: EligibilityRequestUpsertWithoutOutOfPocketBenefitsInput | null;
  connect?: EligibilityRequestWhereUniqueInput | null;
  update?: EligibilityRequestUpdateWithoutOutOfPocketBenefitsInput | null;
}

export interface EligibilityRequestUpdateWithWhereUniqueWithoutAccountCoverageInput {
  where: EligibilityRequestWhereUniqueInput;
  data: EligibilityRequestUpdateWithoutAccountCoverageInput;
}

export interface EligibilityRequestUpdateWithWhereUniqueWithoutAppointmentInput {
  where: EligibilityRequestWhereUniqueInput;
  data: EligibilityRequestUpdateWithoutAppointmentInput;
}

export interface EligibilityRequestUpdateWithWhereUniqueWithoutInsurancePolicyInput {
  where: EligibilityRequestWhereUniqueInput;
  data: EligibilityRequestUpdateWithoutInsurancePolicyInput;
}

export interface EligibilityRequestUpdateWithWhereUniqueWithoutProviderInput {
  where: EligibilityRequestWhereUniqueInput;
  data: EligibilityRequestUpdateWithoutProviderInput;
}

export interface EligibilityRequestUpdateWithWhereUniqueWithoutRequestedByInput {
  where: EligibilityRequestWhereUniqueInput;
  data: EligibilityRequestUpdateWithoutRequestedByInput;
}

export interface EligibilityRequestUpdateWithoutAccountCoverageInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput | null;
  provider?: ProviderUpdateOneWithoutEligibilityRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutEligibilityRequestsNestedInput | null;
  requestedBy?: UserUpdateOneWithoutEligibilityRequestsNestedInput | null;
  deductibleBenefits?: DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  copayBenefits?: CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  limitationBenefits?: LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  otherBenefits?: OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput | null;
  provider?: ProviderUpdateOneWithoutEligibilityRequestsNestedInput | null;
  requestedBy?: UserUpdateOneWithoutEligibilityRequestsNestedInput | null;
  deductibleBenefits?: DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  copayBenefits?: CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  limitationBenefits?: LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  otherBenefits?: OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpdateWithoutCoinsuranceBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput | null;
  provider?: ProviderUpdateOneWithoutEligibilityRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutEligibilityRequestsNestedInput | null;
  requestedBy?: UserUpdateOneWithoutEligibilityRequestsNestedInput | null;
  deductibleBenefits?: DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  copayBenefits?: CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  limitationBenefits?: LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  otherBenefits?: OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpdateWithoutCopayBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput | null;
  provider?: ProviderUpdateOneWithoutEligibilityRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutEligibilityRequestsNestedInput | null;
  requestedBy?: UserUpdateOneWithoutEligibilityRequestsNestedInput | null;
  deductibleBenefits?: DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  limitationBenefits?: LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  otherBenefits?: OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpdateWithoutDeductibleBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput | null;
  provider?: ProviderUpdateOneWithoutEligibilityRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutEligibilityRequestsNestedInput | null;
  requestedBy?: UserUpdateOneWithoutEligibilityRequestsNestedInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  copayBenefits?: CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  limitationBenefits?: LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  otherBenefits?: OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpdateWithoutEligibilityBenefitsExtractionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput | null;
  provider?: ProviderUpdateOneWithoutEligibilityRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutEligibilityRequestsNestedInput | null;
  requestedBy?: UserUpdateOneWithoutEligibilityRequestsNestedInput | null;
  deductibleBenefits?: DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  copayBenefits?: CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  limitationBenefits?: LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  otherBenefits?: OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpdateWithoutInsurancePolicyInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput | null;
  provider?: ProviderUpdateOneWithoutEligibilityRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutEligibilityRequestsNestedInput | null;
  requestedBy?: UserUpdateOneWithoutEligibilityRequestsNestedInput | null;
  deductibleBenefits?: DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  copayBenefits?: CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  limitationBenefits?: LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  otherBenefits?: OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpdateWithoutLimitationBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput | null;
  provider?: ProviderUpdateOneWithoutEligibilityRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutEligibilityRequestsNestedInput | null;
  requestedBy?: UserUpdateOneWithoutEligibilityRequestsNestedInput | null;
  deductibleBenefits?: DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  copayBenefits?: CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  otherBenefits?: OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpdateWithoutOtherBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput | null;
  provider?: ProviderUpdateOneWithoutEligibilityRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutEligibilityRequestsNestedInput | null;
  requestedBy?: UserUpdateOneWithoutEligibilityRequestsNestedInput | null;
  deductibleBenefits?: DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  copayBenefits?: CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  limitationBenefits?: LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpdateWithoutOutOfPocketBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput | null;
  provider?: ProviderUpdateOneWithoutEligibilityRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutEligibilityRequestsNestedInput | null;
  requestedBy?: UserUpdateOneWithoutEligibilityRequestsNestedInput | null;
  deductibleBenefits?: DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  copayBenefits?: CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  limitationBenefits?: LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  otherBenefits?: OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpdateWithoutProviderInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutEligibilityRequestsNestedInput | null;
  requestedBy?: UserUpdateOneWithoutEligibilityRequestsNestedInput | null;
  deductibleBenefits?: DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  copayBenefits?: CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  limitationBenefits?: LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  otherBenefits?: OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpdateWithoutRequestedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  finishedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  status?: EnumEligibilityRequestStatusFieldUpdateOperationsInput | null;
  clearinghouse?: EnumClearinghouseFieldUpdateOperationsInput | null;
  clearinghouseStatusCode?: NullableStringFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  eligible?: BoolFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutEligibilityRequestsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput | null;
  provider?: ProviderUpdateOneWithoutEligibilityRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutEligibilityRequestsNestedInput | null;
  deductibleBenefits?: DeductibleBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  outOfPocketBenefits?: OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  coinsuranceBenefits?: CoinsuranceBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  copayBenefits?: CopayBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  limitationBenefits?: LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  otherBenefits?: OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityResponseErrors?: EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionUpdateManyWithoutEligibilityRequestNestedInput | null;
}

export interface EligibilityRequestUpsertWithWhereUniqueWithoutAccountCoverageInput {
  where: EligibilityRequestWhereUniqueInput;
  update: EligibilityRequestUpdateWithoutAccountCoverageInput;
  create: EligibilityRequestCreateWithoutAccountCoverageInput;
}

export interface EligibilityRequestUpsertWithWhereUniqueWithoutAppointmentInput {
  where: EligibilityRequestWhereUniqueInput;
  update: EligibilityRequestUpdateWithoutAppointmentInput;
  create: EligibilityRequestCreateWithoutAppointmentInput;
}

export interface EligibilityRequestUpsertWithWhereUniqueWithoutInsurancePolicyInput {
  where: EligibilityRequestWhereUniqueInput;
  update: EligibilityRequestUpdateWithoutInsurancePolicyInput;
  create: EligibilityRequestCreateWithoutInsurancePolicyInput;
}

export interface EligibilityRequestUpsertWithWhereUniqueWithoutProviderInput {
  where: EligibilityRequestWhereUniqueInput;
  update: EligibilityRequestUpdateWithoutProviderInput;
  create: EligibilityRequestCreateWithoutProviderInput;
}

export interface EligibilityRequestUpsertWithWhereUniqueWithoutRequestedByInput {
  where: EligibilityRequestWhereUniqueInput;
  update: EligibilityRequestUpdateWithoutRequestedByInput;
  create: EligibilityRequestCreateWithoutRequestedByInput;
}

export interface EligibilityRequestUpsertWithoutCoinsuranceBenefitsInput {
  update: EligibilityRequestUpdateWithoutCoinsuranceBenefitsInput;
  create: EligibilityRequestCreateWithoutCoinsuranceBenefitsInput;
}

export interface EligibilityRequestUpsertWithoutCopayBenefitsInput {
  update: EligibilityRequestUpdateWithoutCopayBenefitsInput;
  create: EligibilityRequestCreateWithoutCopayBenefitsInput;
}

export interface EligibilityRequestUpsertWithoutDeductibleBenefitsInput {
  update: EligibilityRequestUpdateWithoutDeductibleBenefitsInput;
  create: EligibilityRequestCreateWithoutDeductibleBenefitsInput;
}

export interface EligibilityRequestUpsertWithoutEligibilityBenefitsExtractionsInput {
  update: EligibilityRequestUpdateWithoutEligibilityBenefitsExtractionsInput;
  create: EligibilityRequestCreateWithoutEligibilityBenefitsExtractionsInput;
}

export interface EligibilityRequestUpsertWithoutLimitationBenefitsInput {
  update: EligibilityRequestUpdateWithoutLimitationBenefitsInput;
  create: EligibilityRequestCreateWithoutLimitationBenefitsInput;
}

export interface EligibilityRequestUpsertWithoutOtherBenefitsInput {
  update: EligibilityRequestUpdateWithoutOtherBenefitsInput;
  create: EligibilityRequestCreateWithoutOtherBenefitsInput;
}

export interface EligibilityRequestUpsertWithoutOutOfPocketBenefitsInput {
  update: EligibilityRequestUpdateWithoutOutOfPocketBenefitsInput;
  create: EligibilityRequestCreateWithoutOutOfPocketBenefitsInput;
}

export interface EligibilityRequestWhereInput {
  AND?: EligibilityRequestWhereInput[] | null;
  OR?: EligibilityRequestWhereInput[] | null;
  NOT?: EligibilityRequestWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  finishedAt?: DateTimeNullableFilter | null;
  status?: EnumEligibilityRequestStatusFilter | null;
  clearinghouse?: EnumClearinghouseFilter | null;
  clearinghouseStatusCode?: StringNullableFilter | null;
  accountCoverageId?: UuidNullableFilter | null;
  insurancePolicyId?: UuidFilter | null;
  providerId?: UuidNullableFilter | null;
  appointmentId?: UuidNullableFilter | null;
  requestedById?: UuidNullableFilter | null;
  automated?: BoolFilter | null;
  inNetwork?: BoolFilter | null;
  eligible?: BoolFilter | null;
  request?: JsonNullableFilter | null;
  response?: JsonNullableFilter | null;
  accountCoverage?: AccountCoverageRelationFilter | null;
  insurancePolicy?: InsurancePolicyRelationFilter | null;
  provider?: ProviderRelationFilter | null;
  appointment?: AppointmentRelationFilter | null;
  requestedBy?: UserRelationFilter | null;
  deductibleBenefits?: DeductibleBenefitListRelationFilter | null;
  outOfPocketBenefits?: OutOfPocketBenefitListRelationFilter | null;
  coinsuranceBenefits?: CoinsuranceBenefitListRelationFilter | null;
  copayBenefits?: CopayBenefitListRelationFilter | null;
  limitationBenefits?: LimitationBenefitListRelationFilter | null;
  otherBenefits?: OtherBenefitListRelationFilter | null;
  eligibilityResponseErrors?: EligibilityResponseErrorListRelationFilter | null;
  rawEligibilityRequestResponses?: RawEligibilityRequestResponseListRelationFilter | null;
  eligibilityBenefitsExtractions?: EligibilityBenefitsExtractionListRelationFilter | null;
}

export interface EligibilityRequestWhereUniqueInput {
  id?: string | null;
}

export interface EligibilityResponseErrorCreateManyEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code?: string | null;
  field?: string | null;
  description?: string | null;
  followupAction?: string | null;
  location?: string | null;
}

export interface EligibilityResponseErrorCreateManyEligibilityRequestInputEnvelope {
  data: EligibilityResponseErrorCreateManyEligibilityRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface EligibilityResponseErrorCreateNestedManyWithoutEligibilityRequestInput {
  create?: EligibilityResponseErrorCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: EligibilityResponseErrorCreateOrConnectWithoutEligibilityRequestInput[] | null;
  createMany?: EligibilityResponseErrorCreateManyEligibilityRequestInputEnvelope | null;
  connect?: EligibilityResponseErrorWhereUniqueInput[] | null;
}

export interface EligibilityResponseErrorCreateOrConnectWithoutEligibilityRequestInput {
  where: EligibilityResponseErrorWhereUniqueInput;
  create: EligibilityResponseErrorCreateWithoutEligibilityRequestInput;
}

export interface EligibilityResponseErrorCreateWithoutEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code?: string | null;
  field?: string | null;
  description?: string | null;
  followupAction?: string | null;
  location?: string | null;
}

export interface EligibilityResponseErrorListRelationFilter {
  every?: EligibilityResponseErrorWhereInput | null;
  some?: EligibilityResponseErrorWhereInput | null;
  none?: EligibilityResponseErrorWhereInput | null;
}

export interface EligibilityResponseErrorScalarWhereInput {
  AND?: EligibilityResponseErrorScalarWhereInput[] | null;
  OR?: EligibilityResponseErrorScalarWhereInput[] | null;
  NOT?: EligibilityResponseErrorScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  code?: StringNullableFilter | null;
  field?: StringNullableFilter | null;
  description?: StringNullableFilter | null;
  followupAction?: StringNullableFilter | null;
  location?: StringNullableFilter | null;
  eligibilityRequestId?: UuidFilter | null;
}

export interface EligibilityResponseErrorUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: NullableStringFieldUpdateOperationsInput | null;
  field?: NullableStringFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  followupAction?: NullableStringFieldUpdateOperationsInput | null;
  location?: NullableStringFieldUpdateOperationsInput | null;
}

export interface EligibilityResponseErrorUpdateManyWithWhereWithoutEligibilityRequestInput {
  where: EligibilityResponseErrorScalarWhereInput;
  data: EligibilityResponseErrorUpdateManyMutationInput;
}

export interface EligibilityResponseErrorUpdateManyWithoutEligibilityRequestNestedInput {
  create?: EligibilityResponseErrorCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: EligibilityResponseErrorCreateOrConnectWithoutEligibilityRequestInput[] | null;
  upsert?: EligibilityResponseErrorUpsertWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  createMany?: EligibilityResponseErrorCreateManyEligibilityRequestInputEnvelope | null;
  set?: EligibilityResponseErrorWhereUniqueInput[] | null;
  disconnect?: EligibilityResponseErrorWhereUniqueInput[] | null;
  delete?: EligibilityResponseErrorWhereUniqueInput[] | null;
  connect?: EligibilityResponseErrorWhereUniqueInput[] | null;
  update?: EligibilityResponseErrorUpdateWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  updateMany?: EligibilityResponseErrorUpdateManyWithWhereWithoutEligibilityRequestInput[] | null;
  deleteMany?: EligibilityResponseErrorScalarWhereInput[] | null;
}

export interface EligibilityResponseErrorUpdateWithWhereUniqueWithoutEligibilityRequestInput {
  where: EligibilityResponseErrorWhereUniqueInput;
  data: EligibilityResponseErrorUpdateWithoutEligibilityRequestInput;
}

export interface EligibilityResponseErrorUpdateWithoutEligibilityRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: NullableStringFieldUpdateOperationsInput | null;
  field?: NullableStringFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  followupAction?: NullableStringFieldUpdateOperationsInput | null;
  location?: NullableStringFieldUpdateOperationsInput | null;
}

export interface EligibilityResponseErrorUpsertWithWhereUniqueWithoutEligibilityRequestInput {
  where: EligibilityResponseErrorWhereUniqueInput;
  update: EligibilityResponseErrorUpdateWithoutEligibilityRequestInput;
  create: EligibilityResponseErrorCreateWithoutEligibilityRequestInput;
}

export interface EligibilityResponseErrorWhereInput {
  AND?: EligibilityResponseErrorWhereInput[] | null;
  OR?: EligibilityResponseErrorWhereInput[] | null;
  NOT?: EligibilityResponseErrorWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  code?: StringNullableFilter | null;
  field?: StringNullableFilter | null;
  description?: StringNullableFilter | null;
  followupAction?: StringNullableFilter | null;
  location?: StringNullableFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  eligibilityRequest?: EligibilityRequestRelationFilter | null;
}

export interface EligibilityResponseErrorWhereUniqueInput {
  id?: string | null;
}

export interface EnumAccumulatorAdjustmentTypeFieldUpdateOperationsInput {
  set?: AccumulatorAdjustmentType | null;
}

export interface EnumAccumulatorAdjustmentTypeFilter {
  equals?: AccumulatorAdjustmentType | null;
  in?: AccumulatorAdjustmentType[] | null;
  notIn?: AccumulatorAdjustmentType[] | null;
  not?: NestedEnumAccumulatorAdjustmentTypeFilter | null;
}

export interface EnumAdjustmentTypeFieldUpdateOperationsInput {
  set?: AdjustmentType | null;
}

export interface EnumAdjustmentTypeFilter {
  equals?: AdjustmentType | null;
  in?: AdjustmentType[] | null;
  notIn?: AdjustmentType[] | null;
  not?: NestedEnumAdjustmentTypeFilter | null;
}

export interface EnumAppointmentStatusNullableFilter {
  equals?: AppointmentStatus | null;
  in?: AppointmentStatus[] | null;
  notIn?: AppointmentStatus[] | null;
  not?: NestedEnumAppointmentStatusNullableFilter | null;
}

export interface EnumAuthorizationRequirementNullableFilter {
  equals?: AuthorizationRequirement | null;
  in?: AuthorizationRequirement[] | null;
  notIn?: AuthorizationRequirement[] | null;
  not?: NestedEnumAuthorizationRequirementNullableFilter | null;
}

export interface EnumBenefitNoteRuleActionTypeFieldUpdateOperationsInput {
  set?: BenefitNoteRuleActionType | null;
}

export interface EnumBenefitNoteRuleActionTypeFilter {
  equals?: BenefitNoteRuleActionType | null;
  in?: BenefitNoteRuleActionType[] | null;
  notIn?: BenefitNoteRuleActionType[] | null;
  not?: NestedEnumBenefitNoteRuleActionTypeFilter | null;
}

export interface EnumBenefitNoteRuleMatchTypeFieldUpdateOperationsInput {
  set?: BenefitNoteRuleMatchType | null;
}

export interface EnumBenefitNoteRuleMatchTypeFilter {
  equals?: BenefitNoteRuleMatchType | null;
  in?: BenefitNoteRuleMatchType[] | null;
  notIn?: BenefitNoteRuleMatchType[] | null;
  not?: NestedEnumBenefitNoteRuleMatchTypeFilter | null;
}

export interface EnumBillCommunicationStateNullableFilter {
  equals?: BillCommunicationState | null;
  in?: BillCommunicationState[] | null;
  notIn?: BillCommunicationState[] | null;
  not?: NestedEnumBillCommunicationStateNullableFilter | null;
}

export interface EnumBillStateFieldUpdateOperationsInput {
  set?: BillState | null;
}

export interface EnumBillStateFilter {
  equals?: BillState | null;
  in?: BillState[] | null;
  notIn?: BillState[] | null;
  not?: NestedEnumBillStateFilter | null;
}

export interface EnumBirthSexNullableFilter {
  equals?: BirthSex | null;
  in?: BirthSex[] | null;
  notIn?: BirthSex[] | null;
  not?: NestedEnumBirthSexNullableFilter | null;
}

export interface EnumChangePayerConnectivityTypeNullableFilter {
  equals?: ChangePayerConnectivityType | null;
  in?: ChangePayerConnectivityType[] | null;
  notIn?: ChangePayerConnectivityType[] | null;
  not?: NestedEnumChangePayerConnectivityTypeNullableFilter | null;
}

export interface EnumChargeInsuranceStateFieldUpdateOperationsInput {
  set?: ChargeInsuranceState | null;
}

export interface EnumChargeInsuranceStateFilter {
  equals?: ChargeInsuranceState | null;
  in?: ChargeInsuranceState[] | null;
  notIn?: ChargeInsuranceState[] | null;
  not?: NestedEnumChargeInsuranceStateFilter | null;
}

export interface EnumClearinghouseFieldUpdateOperationsInput {
  set?: Clearinghouse | null;
}

export interface EnumClearinghouseFilter {
  equals?: Clearinghouse | null;
  in?: Clearinghouse[] | null;
  notIn?: Clearinghouse[] | null;
  not?: NestedEnumClearinghouseFilter | null;
}

export interface EnumClearinghouseNullableFilter {
  equals?: Clearinghouse | null;
  in?: Clearinghouse[] | null;
  notIn?: Clearinghouse[] | null;
  not?: NestedEnumClearinghouseNullableFilter | null;
}

export interface EnumCollectionRequestTypeFieldUpdateOperationsInput {
  set?: CollectionRequestType | null;
}

export interface EnumCollectionRequestTypeFilter {
  equals?: CollectionRequestType | null;
  in?: CollectionRequestType[] | null;
  notIn?: CollectionRequestType[] | null;
  not?: NestedEnumCollectionRequestTypeFilter | null;
}

export interface EnumCommercialTypeNullableFilter {
  equals?: CommercialType | null;
  in?: CommercialType[] | null;
  notIn?: CommercialType[] | null;
  not?: NestedEnumCommercialTypeNullableFilter | null;
}

export interface EnumCommunicationContentTypeFieldUpdateOperationsInput {
  set?: CommunicationContentType | null;
}

export interface EnumCommunicationContentTypeFilter {
  equals?: CommunicationContentType | null;
  in?: CommunicationContentType[] | null;
  notIn?: CommunicationContentType[] | null;
  not?: NestedEnumCommunicationContentTypeFilter | null;
}

export interface EnumCommunicationErrorTypeNullableFilter {
  equals?: CommunicationErrorType | null;
  in?: CommunicationErrorType[] | null;
  notIn?: CommunicationErrorType[] | null;
  not?: NestedEnumCommunicationErrorTypeNullableFilter | null;
}

export interface EnumCommunicationPreferenceNullableListFilter {
  equals?: CommunicationPreference[] | null;
  has?: CommunicationPreference | null;
  hasEvery?: CommunicationPreference[] | null;
  hasSome?: CommunicationPreference[] | null;
  isEmpty?: boolean | null;
}

export interface EnumCommunicationTypeFieldUpdateOperationsInput {
  set?: CommunicationType | null;
}

export interface EnumCommunicationTypeFilter {
  equals?: CommunicationType | null;
  in?: CommunicationType[] | null;
  notIn?: CommunicationType[] | null;
  not?: NestedEnumCommunicationTypeFilter | null;
}

export interface EnumComparisonFieldUpdateOperationsInput {
  set?: Comparison | null;
}

export interface EnumComparisonFilter {
  equals?: Comparison | null;
  in?: Comparison[] | null;
  notIn?: Comparison[] | null;
  not?: NestedEnumComparisonFilter | null;
}

export interface EnumCoveragePriorityNullableFilter {
  equals?: CoveragePriority | null;
  in?: CoveragePriority[] | null;
  notIn?: CoveragePriority[] | null;
  not?: NestedEnumCoveragePriorityNullableFilter | null;
}

export interface EnumEligibilityRequestStatusFieldUpdateOperationsInput {
  set?: EligibilityRequestStatus | null;
}

export interface EnumEligibilityRequestStatusFilter {
  equals?: EligibilityRequestStatus | null;
  in?: EligibilityRequestStatus[] | null;
  notIn?: EligibilityRequestStatus[] | null;
  not?: NestedEnumEligibilityRequestStatusFilter | null;
}

export interface EnumEstimateStatusFieldUpdateOperationsInput {
  set?: EstimateStatus | null;
}

export interface EnumEstimateStatusFilter {
  equals?: EstimateStatus | null;
  in?: EstimateStatus[] | null;
  notIn?: EstimateStatus[] | null;
  not?: NestedEnumEstimateStatusFilter | null;
}

export interface EnumEstimateTypeFieldUpdateOperationsInput {
  set?: EstimateType | null;
}

export interface EnumEstimateTypeFilter {
  equals?: EstimateType | null;
  in?: EstimateType[] | null;
  notIn?: EstimateType[] | null;
  not?: NestedEnumEstimateTypeFilter | null;
}

export interface EnumFeeTypeFieldUpdateOperationsInput {
  set?: FeeType | null;
}

export interface EnumFeeTypeFilter {
  equals?: FeeType | null;
  in?: FeeType[] | null;
  notIn?: FeeType[] | null;
  not?: NestedEnumFeeTypeFilter | null;
}

export interface EnumFeedbackTypeFieldUpdateOperationsInput {
  set?: FeedbackType | null;
}

export interface EnumFeedbackTypeFilter {
  equals?: FeedbackType | null;
  in?: FeedbackType[] | null;
  notIn?: FeedbackType[] | null;
  not?: NestedEnumFeedbackTypeFilter | null;
}

export interface EnumInsurancePolicyWorklistItemTaskTypeFieldUpdateOperationsInput {
  set?: InsurancePolicyWorklistItemTaskType | null;
}

export interface EnumInsurancePolicyWorklistItemTaskTypeFilter {
  equals?: InsurancePolicyWorklistItemTaskType | null;
  in?: InsurancePolicyWorklistItemTaskType[] | null;
  notIn?: InsurancePolicyWorklistItemTaskType[] | null;
  not?: NestedEnumInsurancePolicyWorklistItemTaskTypeFilter | null;
}

export interface EnumInsurancePolicyWorklistReasonFieldUpdateOperationsInput {
  set?: InsurancePolicyWorklistReason | null;
}

export interface EnumInsurancePolicyWorklistReasonFilter {
  equals?: InsurancePolicyWorklistReason | null;
  in?: InsurancePolicyWorklistReason[] | null;
  notIn?: InsurancePolicyWorklistReason[] | null;
  not?: NestedEnumInsurancePolicyWorklistReasonFilter | null;
}

export interface EnumInsuranceTypeFieldUpdateOperationsInput {
  set?: InsuranceType | null;
}

export interface EnumInsuranceTypeFilter {
  equals?: InsuranceType | null;
  in?: InsuranceType[] | null;
  notIn?: InsuranceType[] | null;
  not?: NestedEnumInsuranceTypeFilter | null;
}

export interface EnumIntegrationLinkTypeFieldUpdateOperationsInput {
  set?: IntegrationLinkType | null;
}

export interface EnumIntegrationLinkTypeFilter {
  equals?: IntegrationLinkType | null;
  in?: IntegrationLinkType[] | null;
  notIn?: IntegrationLinkType[] | null;
  not?: NestedEnumIntegrationLinkTypeFilter | null;
}

export interface EnumIntegrationTypeFieldUpdateOperationsInput {
  set?: IntegrationType | null;
}

export interface EnumIntegrationTypeFilter {
  equals?: IntegrationType | null;
  in?: IntegrationType[] | null;
  notIn?: IntegrationType[] | null;
  not?: NestedEnumIntegrationTypeFilter | null;
}

export interface EnumLedgerGranularityLevelFieldUpdateOperationsInput {
  set?: LedgerGranularityLevel | null;
}

export interface EnumLedgerGranularityLevelFilter {
  equals?: LedgerGranularityLevel | null;
  in?: LedgerGranularityLevel[] | null;
  notIn?: LedgerGranularityLevel[] | null;
  not?: NestedEnumLedgerGranularityLevelFilter | null;
}

export interface EnumMedicaidTypeNullableFilter {
  equals?: MedicaidType | null;
  in?: MedicaidType[] | null;
  notIn?: MedicaidType[] | null;
  not?: NestedEnumMedicaidTypeNullableFilter | null;
}

export interface EnumMedicareTypeNullableFilter {
  equals?: MedicareType | null;
  in?: MedicareType[] | null;
  notIn?: MedicareType[] | null;
  not?: NestedEnumMedicareTypeNullableFilter | null;
}

export interface EnumMedigapPlanTypeNullableFilter {
  equals?: MedigapPlanType | null;
  in?: MedigapPlanType[] | null;
  notIn?: MedigapPlanType[] | null;
  not?: NestedEnumMedigapPlanTypeNullableFilter | null;
}

export interface EnumNetworkStatusNullableFilter {
  equals?: NetworkStatus | null;
  in?: NetworkStatus[] | null;
  notIn?: NetworkStatus[] | null;
  not?: NestedEnumNetworkStatusNullableFilter | null;
}

export interface EnumNoteTypeFieldUpdateOperationsInput {
  set?: NoteType | null;
}

export interface EnumNoteTypeFilter {
  equals?: NoteType | null;
  in?: NoteType[] | null;
  notIn?: NoteType[] | null;
  not?: NestedEnumNoteTypeFilter | null;
}

export interface EnumPaymentMethodNullableFilter {
  equals?: PaymentMethod | null;
  in?: PaymentMethod[] | null;
  notIn?: PaymentMethod[] | null;
  not?: NestedEnumPaymentMethodNullableFilter | null;
}

export interface EnumPaymentRequestBatchStatusFieldUpdateOperationsInput {
  set?: PaymentRequestBatchStatus | null;
}

export interface EnumPaymentRequestBatchStatusFilter {
  equals?: PaymentRequestBatchStatus | null;
  in?: PaymentRequestBatchStatus[] | null;
  notIn?: PaymentRequestBatchStatus[] | null;
  not?: NestedEnumPaymentRequestBatchStatusFilter | null;
}

export interface EnumPaymentRequestStatusFieldUpdateOperationsInput {
  set?: PaymentRequestStatus | null;
}

export interface EnumPaymentRequestStatusFilter {
  equals?: PaymentRequestStatus | null;
  in?: PaymentRequestStatus[] | null;
  notIn?: PaymentRequestStatus[] | null;
  not?: NestedEnumPaymentRequestStatusFilter | null;
}

export interface EnumPaymentRequestTypeFieldUpdateOperationsInput {
  set?: PaymentRequestType | null;
}

export interface EnumPaymentRequestTypeFilter {
  equals?: PaymentRequestType | null;
  in?: PaymentRequestType[] | null;
  notIn?: PaymentRequestType[] | null;
  not?: NestedEnumPaymentRequestTypeFilter | null;
}

export interface EnumPaymentTypeFieldUpdateOperationsInput {
  set?: PaymentType | null;
}

export interface EnumPaymentTypeFilter {
  equals?: PaymentType | null;
  in?: PaymentType[] | null;
  notIn?: PaymentType[] | null;
  not?: NestedEnumPaymentTypeFilter | null;
}

export interface EnumPostingStatusNullableFilter {
  equals?: PostingStatus | null;
  in?: PostingStatus[] | null;
  notIn?: PostingStatus[] | null;
  not?: NestedEnumPostingStatusNullableFilter | null;
}

export interface EnumProviderTypeFieldUpdateOperationsInput {
  set?: ProviderType | null;
}

export interface EnumProviderTypeFilter {
  equals?: ProviderType | null;
  in?: ProviderType[] | null;
  notIn?: ProviderType[] | null;
  not?: NestedEnumProviderTypeFilter | null;
}

export interface EnumStripeConnectAccountTypeFieldUpdateOperationsInput {
  set?: StripeConnectAccountType | null;
}

export interface EnumStripeConnectAccountTypeFilter {
  equals?: StripeConnectAccountType | null;
  in?: StripeConnectAccountType[] | null;
  notIn?: StripeConnectAccountType[] | null;
  not?: NestedEnumStripeConnectAccountTypeFilter | null;
}

export interface EnumSyncDirectionFieldUpdateOperationsInput {
  set?: SyncDirection | null;
}

export interface EnumSyncDirectionFilter {
  equals?: SyncDirection | null;
  in?: SyncDirection[] | null;
  notIn?: SyncDirection[] | null;
  not?: NestedEnumSyncDirectionFilter | null;
}

export interface EnumTaskTypeFieldUpdateOperationsInput {
  set?: TaskType | null;
}

export interface EnumTaskTypeFilter {
  equals?: TaskType | null;
  in?: TaskType[] | null;
  notIn?: TaskType[] | null;
  not?: NestedEnumTaskTypeFilter | null;
}

export interface EnumTransactionTypeFieldUpdateOperationsInput {
  set?: TransactionType | null;
}

export interface EnumTransactionTypeFilter {
  equals?: TransactionType | null;
  in?: TransactionType[] | null;
  notIn?: TransactionType[] | null;
  not?: NestedEnumTransactionTypeFilter | null;
}

export interface EnumTricareTypeNullableFilter {
  equals?: TricareType | null;
  in?: TricareType[] | null;
  notIn?: TricareType[] | null;
  not?: NestedEnumTricareTypeNullableFilter | null;
}

export interface EnumUserEnteredBenefitTypeFieldUpdateOperationsInput {
  set?: UserEnteredBenefitType | null;
}

export interface EnumUserEnteredBenefitTypeFilter {
  equals?: UserEnteredBenefitType | null;
  in?: UserEnteredBenefitType[] | null;
  notIn?: UserEnteredBenefitType[] | null;
  not?: NestedEnumUserEnteredBenefitTypeFilter | null;
}

export interface EnumValidationStatusFieldUpdateOperationsInput {
  set?: ValidationStatus | null;
}

export interface EnumValidationStatusFilter {
  equals?: ValidationStatus | null;
  in?: ValidationStatus[] | null;
  notIn?: ValidationStatus[] | null;
  not?: NestedEnumValidationStatusFilter | null;
}

export interface EnumVerificationWorkflowReasonNullableFilter {
  equals?: VerificationWorkflowReason | null;
  in?: VerificationWorkflowReason[] | null;
  notIn?: VerificationWorkflowReason[] | null;
  not?: NestedEnumVerificationWorkflowReasonNullableFilter | null;
}

export interface EnumWorkflowStageFieldUpdateOperationsInput {
  set?: WorkflowStage | null;
}

export interface EnumWorkflowStageFilter {
  equals?: WorkflowStage | null;
  in?: WorkflowStage[] | null;
  notIn?: WorkflowStage[] | null;
  not?: NestedEnumWorkflowStageFilter | null;
}

export interface EnumWorkflowTypeNullableFilter {
  equals?: WorkflowType | null;
  in?: WorkflowType[] | null;
  notIn?: WorkflowType[] | null;
  not?: NestedEnumWorkflowTypeNullableFilter | null;
}

export interface EstimateCalculationInput {
  insurancePolicyId?: string | null;
  chargemasterId: string;
  units: number;
  allowedAmount: number;
  copay?: number | null;
  coinsurance?: any | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  remainingOutOfPocket?: number | null;
  coverageBenefitId?: string | null;
  scheduledServiceFeeId?: string | null;
}

export interface EstimateCreateManyAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  createdById?: string | null;
  appointmentId: string;
  chargeTemplateId?: string | null;
  feeScheduleId?: string | null;
  feeScheduleMappingId?: string | null;
  billId?: string | null;
  saltedBillId?: string | null;
  note?: string | null;
  supercededById?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
}

export interface EstimateCreateManyAccountInputEnvelope {
  data: EstimateCreateManyAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimateCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  createdById?: string | null;
  accountId: string;
  chargeTemplateId?: string | null;
  feeScheduleId?: string | null;
  feeScheduleMappingId?: string | null;
  billId?: string | null;
  saltedBillId?: string | null;
  note?: string | null;
  supercededById?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
}

export interface EstimateCreateManyAppointmentInputEnvelope {
  data: EstimateCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimateCreateManyBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  createdById?: string | null;
  accountId: string;
  appointmentId: string;
  chargeTemplateId?: string | null;
  feeScheduleId?: string | null;
  feeScheduleMappingId?: string | null;
  saltedBillId?: string | null;
  note?: string | null;
  supercededById?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
}

export interface EstimateCreateManyBillInputEnvelope {
  data: EstimateCreateManyBillInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimateCreateManyChargeTemplateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  createdById?: string | null;
  accountId: string;
  appointmentId: string;
  feeScheduleId?: string | null;
  feeScheduleMappingId?: string | null;
  billId?: string | null;
  saltedBillId?: string | null;
  note?: string | null;
  supercededById?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
}

export interface EstimateCreateManyChargeTemplateInputEnvelope {
  data: EstimateCreateManyChargeTemplateInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimateCreateManyCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  accountId: string;
  appointmentId: string;
  chargeTemplateId?: string | null;
  feeScheduleId?: string | null;
  feeScheduleMappingId?: string | null;
  billId?: string | null;
  saltedBillId?: string | null;
  note?: string | null;
  supercededById?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
}

export interface EstimateCreateManyCreatedByInputEnvelope {
  data: EstimateCreateManyCreatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimateCreateManyFeeScheduleInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  createdById?: string | null;
  accountId: string;
  appointmentId: string;
  chargeTemplateId?: string | null;
  feeScheduleMappingId?: string | null;
  billId?: string | null;
  saltedBillId?: string | null;
  note?: string | null;
  supercededById?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
}

export interface EstimateCreateManyFeeScheduleInputEnvelope {
  data: EstimateCreateManyFeeScheduleInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimateCreateManyFeeScheduleMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  createdById?: string | null;
  accountId: string;
  appointmentId: string;
  chargeTemplateId?: string | null;
  feeScheduleId?: string | null;
  billId?: string | null;
  saltedBillId?: string | null;
  note?: string | null;
  supercededById?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
}

export interface EstimateCreateManyFeeScheduleMappingInputEnvelope {
  data: EstimateCreateManyFeeScheduleMappingInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimateCreateManySaltedBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  createdById?: string | null;
  accountId: string;
  appointmentId: string;
  chargeTemplateId?: string | null;
  feeScheduleId?: string | null;
  feeScheduleMappingId?: string | null;
  billId?: string | null;
  note?: string | null;
  supercededById?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
}

export interface EstimateCreateManySaltedBillInputEnvelope {
  data: EstimateCreateManySaltedBillInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimateCreateNestedManyWithoutAccountInput {
  create?: EstimateCreateWithoutAccountInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutAccountInput[] | null;
  createMany?: EstimateCreateManyAccountInputEnvelope | null;
  connect?: EstimateWhereUniqueInput[] | null;
}

export interface EstimateCreateNestedManyWithoutAppointmentInput {
  create?: EstimateCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: EstimateCreateManyAppointmentInputEnvelope | null;
  connect?: EstimateWhereUniqueInput[] | null;
}

export interface EstimateCreateNestedManyWithoutBillInput {
  create?: EstimateCreateWithoutBillInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutBillInput[] | null;
  createMany?: EstimateCreateManyBillInputEnvelope | null;
  connect?: EstimateWhereUniqueInput[] | null;
}

export interface EstimateCreateNestedManyWithoutChargeTemplateInput {
  create?: EstimateCreateWithoutChargeTemplateInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutChargeTemplateInput[] | null;
  createMany?: EstimateCreateManyChargeTemplateInputEnvelope | null;
  connect?: EstimateWhereUniqueInput[] | null;
}

export interface EstimateCreateNestedManyWithoutCreatedByInput {
  create?: EstimateCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: EstimateCreateManyCreatedByInputEnvelope | null;
  connect?: EstimateWhereUniqueInput[] | null;
}

export interface EstimateCreateNestedManyWithoutFeeScheduleInput {
  create?: EstimateCreateWithoutFeeScheduleInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutFeeScheduleInput[] | null;
  createMany?: EstimateCreateManyFeeScheduleInputEnvelope | null;
  connect?: EstimateWhereUniqueInput[] | null;
}

export interface EstimateCreateNestedManyWithoutFeeScheduleMappingInput {
  create?: EstimateCreateWithoutFeeScheduleMappingInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutFeeScheduleMappingInput[] | null;
  createMany?: EstimateCreateManyFeeScheduleMappingInputEnvelope | null;
  connect?: EstimateWhereUniqueInput[] | null;
}

export interface EstimateCreateNestedManyWithoutSaltedBillInput {
  create?: EstimateCreateWithoutSaltedBillInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutSaltedBillInput[] | null;
  createMany?: EstimateCreateManySaltedBillInputEnvelope | null;
  connect?: EstimateWhereUniqueInput[] | null;
}

export interface EstimateCreateNestedOneWithoutAccumulatorAdjustmentsInput {
  create?: EstimateCreateWithoutAccumulatorAdjustmentsInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutAccumulatorAdjustmentsInput | null;
  connect?: EstimateWhereUniqueInput | null;
}

export interface EstimateCreateNestedOneWithoutEstimateInsurancePoliciesInput {
  create?: EstimateCreateWithoutEstimateInsurancePoliciesInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutEstimateInsurancePoliciesInput | null;
  connect?: EstimateWhereUniqueInput | null;
}

export interface EstimateCreateNestedOneWithoutEstimatedChargesInput {
  create?: EstimateCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutEstimatedChargesInput | null;
  connect?: EstimateWhereUniqueInput | null;
}

export interface EstimateCreateNestedOneWithoutSupercededByInput {
  create?: EstimateCreateWithoutSupercededByInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutSupercededByInput | null;
  connect?: EstimateWhereUniqueInput | null;
}

export interface EstimateCreateNestedOneWithoutSupercedesInput {
  create?: EstimateCreateWithoutSupercedesInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutSupercedesInput | null;
  connect?: EstimateWhereUniqueInput | null;
}

export interface EstimateCreateNestedOneWithoutVisitCollectionRequestsInput {
  create?: EstimateCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  connect?: EstimateWhereUniqueInput | null;
}

export interface EstimateCreateOrConnectWithoutAccountInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutAccountInput;
}

export interface EstimateCreateOrConnectWithoutAccumulatorAdjustmentsInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutAccumulatorAdjustmentsInput;
}

export interface EstimateCreateOrConnectWithoutAppointmentInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutAppointmentInput;
}

export interface EstimateCreateOrConnectWithoutBillInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutBillInput;
}

export interface EstimateCreateOrConnectWithoutChargeTemplateInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutChargeTemplateInput;
}

export interface EstimateCreateOrConnectWithoutCreatedByInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutCreatedByInput;
}

export interface EstimateCreateOrConnectWithoutEstimateInsurancePoliciesInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutEstimateInsurancePoliciesInput;
}

export interface EstimateCreateOrConnectWithoutEstimatedChargesInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutEstimatedChargesInput;
}

export interface EstimateCreateOrConnectWithoutFeeScheduleInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutFeeScheduleInput;
}

export interface EstimateCreateOrConnectWithoutFeeScheduleMappingInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutFeeScheduleMappingInput;
}

export interface EstimateCreateOrConnectWithoutSaltedBillInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutSaltedBillInput;
}

export interface EstimateCreateOrConnectWithoutSupercededByInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutSupercededByInput;
}

export interface EstimateCreateOrConnectWithoutSupercedesInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutSupercedesInput;
}

export interface EstimateCreateOrConnectWithoutVisitCollectionRequestsInput {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutVisitCollectionRequestsInput;
}

export interface EstimateCreateWithoutAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutAccumulatorAdjustmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutChargeTemplateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutEstimateInsurancePoliciesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutEstimatedChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutFeeScheduleInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutFeeScheduleMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutSaltedBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutSupercededByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutSupercedesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutEstimateInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateCreateWithoutVisitCollectionRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: EstimateStatus;
  type: EstimateType;
  note?: string | null;
  outOfDate?: any | null;
  isManual?: boolean | null;
  createdBy?: UserCreateNestedOneWithoutEstimatesInput | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimateInput | null;
  account: AccountCreateNestedOneWithoutEstimatesInput;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput | null;
  appointment: AppointmentCreateNestedOneWithoutEstimatesInput;
  chargeTemplate?: ChargeTemplateCreateNestedOneWithoutEstimatesInput | null;
  feeSchedule?: FeeScheduleCreateNestedOneWithoutEstimatesInput | null;
  feeScheduleMapping?: FeeScheduleMappingCreateNestedOneWithoutEstimateInput | null;
  bill?: BillCreateNestedOneWithoutEstimatesInput | null;
  saltedBill?: BillCreateNestedOneWithoutSaltedEstimatesInput | null;
  supercededBy?: EstimateCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: EstimateCreateNestedOneWithoutSupercededByInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutEstimateInput | null;
}

export interface EstimateInsurancePolicyCreateManyEstimateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  insurancePolicyId: string;
  priority: number;
  active?: boolean | null;
}

export interface EstimateInsurancePolicyCreateManyEstimateInputEnvelope {
  data: EstimateInsurancePolicyCreateManyEstimateInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimateInsurancePolicyCreateManyInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateId: string;
  priority: number;
  active?: boolean | null;
}

export interface EstimateInsurancePolicyCreateManyInsurancePolicyInputEnvelope {
  data: EstimateInsurancePolicyCreateManyInsurancePolicyInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimateInsurancePolicyCreateNestedManyWithoutEstimateInput {
  create?: EstimateInsurancePolicyCreateWithoutEstimateInput[] | null;
  connectOrCreate?: EstimateInsurancePolicyCreateOrConnectWithoutEstimateInput[] | null;
  createMany?: EstimateInsurancePolicyCreateManyEstimateInputEnvelope | null;
  connect?: EstimateInsurancePolicyWhereUniqueInput[] | null;
}

export interface EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput {
  create?: EstimateInsurancePolicyCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: EstimateInsurancePolicyCreateOrConnectWithoutInsurancePolicyInput[] | null;
  createMany?: EstimateInsurancePolicyCreateManyInsurancePolicyInputEnvelope | null;
  connect?: EstimateInsurancePolicyWhereUniqueInput[] | null;
}

export interface EstimateInsurancePolicyCreateNestedOneWithoutEstimatedChargesInput {
  create?: EstimateInsurancePolicyCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: EstimateInsurancePolicyCreateOrConnectWithoutEstimatedChargesInput | null;
  connect?: EstimateInsurancePolicyWhereUniqueInput | null;
}

export interface EstimateInsurancePolicyCreateOrConnectWithoutEstimateInput {
  where: EstimateInsurancePolicyWhereUniqueInput;
  create: EstimateInsurancePolicyCreateWithoutEstimateInput;
}

export interface EstimateInsurancePolicyCreateOrConnectWithoutEstimatedChargesInput {
  where: EstimateInsurancePolicyWhereUniqueInput;
  create: EstimateInsurancePolicyCreateWithoutEstimatedChargesInput;
}

export interface EstimateInsurancePolicyCreateOrConnectWithoutInsurancePolicyInput {
  where: EstimateInsurancePolicyWhereUniqueInput;
  create: EstimateInsurancePolicyCreateWithoutInsurancePolicyInput;
}

export interface EstimateInsurancePolicyCreateWithoutEstimateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  active?: boolean | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEstimateInsurancePoliciesInput;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimatedInsurancePolicyInput | null;
}

export interface EstimateInsurancePolicyCreateWithoutEstimatedChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  active?: boolean | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutEstimateInsurancePoliciesInput;
  estimate: EstimateCreateNestedOneWithoutEstimateInsurancePoliciesInput;
}

export interface EstimateInsurancePolicyCreateWithoutInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  active?: boolean | null;
  estimate: EstimateCreateNestedOneWithoutEstimateInsurancePoliciesInput;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutEstimatedInsurancePolicyInput | null;
}

export interface EstimateInsurancePolicyListRelationFilter {
  every?: EstimateInsurancePolicyWhereInput | null;
  some?: EstimateInsurancePolicyWhereInput | null;
  none?: EstimateInsurancePolicyWhereInput | null;
}

export interface EstimateInsurancePolicyRelationFilter {
  is?: EstimateInsurancePolicyWhereInput | null;
  isNot?: EstimateInsurancePolicyWhereInput | null;
}

export interface EstimateInsurancePolicyScalarWhereInput {
  AND?: EstimateInsurancePolicyScalarWhereInput[] | null;
  OR?: EstimateInsurancePolicyScalarWhereInput[] | null;
  NOT?: EstimateInsurancePolicyScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  insurancePolicyId?: UuidFilter | null;
  estimateId?: UuidFilter | null;
  priority?: IntFilter | null;
  active?: BoolFilter | null;
}

export interface EstimateInsurancePolicyUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: IntFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
}

export interface EstimateInsurancePolicyUpdateManyWithWhereWithoutEstimateInput {
  where: EstimateInsurancePolicyScalarWhereInput;
  data: EstimateInsurancePolicyUpdateManyMutationInput;
}

export interface EstimateInsurancePolicyUpdateManyWithWhereWithoutInsurancePolicyInput {
  where: EstimateInsurancePolicyScalarWhereInput;
  data: EstimateInsurancePolicyUpdateManyMutationInput;
}

export interface EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput {
  create?: EstimateInsurancePolicyCreateWithoutEstimateInput[] | null;
  connectOrCreate?: EstimateInsurancePolicyCreateOrConnectWithoutEstimateInput[] | null;
  upsert?: EstimateInsurancePolicyUpsertWithWhereUniqueWithoutEstimateInput[] | null;
  createMany?: EstimateInsurancePolicyCreateManyEstimateInputEnvelope | null;
  set?: EstimateInsurancePolicyWhereUniqueInput[] | null;
  disconnect?: EstimateInsurancePolicyWhereUniqueInput[] | null;
  delete?: EstimateInsurancePolicyWhereUniqueInput[] | null;
  connect?: EstimateInsurancePolicyWhereUniqueInput[] | null;
  update?: EstimateInsurancePolicyUpdateWithWhereUniqueWithoutEstimateInput[] | null;
  updateMany?: EstimateInsurancePolicyUpdateManyWithWhereWithoutEstimateInput[] | null;
  deleteMany?: EstimateInsurancePolicyScalarWhereInput[] | null;
}

export interface EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput {
  create?: EstimateInsurancePolicyCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: EstimateInsurancePolicyCreateOrConnectWithoutInsurancePolicyInput[] | null;
  upsert?: EstimateInsurancePolicyUpsertWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  createMany?: EstimateInsurancePolicyCreateManyInsurancePolicyInputEnvelope | null;
  set?: EstimateInsurancePolicyWhereUniqueInput[] | null;
  disconnect?: EstimateInsurancePolicyWhereUniqueInput[] | null;
  delete?: EstimateInsurancePolicyWhereUniqueInput[] | null;
  connect?: EstimateInsurancePolicyWhereUniqueInput[] | null;
  update?: EstimateInsurancePolicyUpdateWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  updateMany?: EstimateInsurancePolicyUpdateManyWithWhereWithoutInsurancePolicyInput[] | null;
  deleteMany?: EstimateInsurancePolicyScalarWhereInput[] | null;
}

export interface EstimateInsurancePolicyUpdateOneWithoutEstimatedChargesNestedInput {
  create?: EstimateInsurancePolicyCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: EstimateInsurancePolicyCreateOrConnectWithoutEstimatedChargesInput | null;
  upsert?: EstimateInsurancePolicyUpsertWithoutEstimatedChargesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: EstimateInsurancePolicyWhereUniqueInput | null;
  update?: EstimateInsurancePolicyUpdateWithoutEstimatedChargesInput | null;
}

export interface EstimateInsurancePolicyUpdateWithWhereUniqueWithoutEstimateInput {
  where: EstimateInsurancePolicyWhereUniqueInput;
  data: EstimateInsurancePolicyUpdateWithoutEstimateInput;
}

export interface EstimateInsurancePolicyUpdateWithWhereUniqueWithoutInsurancePolicyInput {
  where: EstimateInsurancePolicyWhereUniqueInput;
  data: EstimateInsurancePolicyUpdateWithoutInsurancePolicyInput;
}

export interface EstimateInsurancePolicyUpdateWithoutEstimateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: IntFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEstimateInsurancePoliciesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimatedInsurancePolicyNestedInput | null;
}

export interface EstimateInsurancePolicyUpdateWithoutEstimatedChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: IntFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutEstimateInsurancePoliciesNestedInput | null;
  estimate?: EstimateUpdateOneRequiredWithoutEstimateInsurancePoliciesNestedInput | null;
}

export interface EstimateInsurancePolicyUpdateWithoutInsurancePolicyInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: IntFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  estimate?: EstimateUpdateOneRequiredWithoutEstimateInsurancePoliciesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimatedInsurancePolicyNestedInput | null;
}

export interface EstimateInsurancePolicyUpsertWithWhereUniqueWithoutEstimateInput {
  where: EstimateInsurancePolicyWhereUniqueInput;
  update: EstimateInsurancePolicyUpdateWithoutEstimateInput;
  create: EstimateInsurancePolicyCreateWithoutEstimateInput;
}

export interface EstimateInsurancePolicyUpsertWithWhereUniqueWithoutInsurancePolicyInput {
  where: EstimateInsurancePolicyWhereUniqueInput;
  update: EstimateInsurancePolicyUpdateWithoutInsurancePolicyInput;
  create: EstimateInsurancePolicyCreateWithoutInsurancePolicyInput;
}

export interface EstimateInsurancePolicyUpsertWithoutEstimatedChargesInput {
  update: EstimateInsurancePolicyUpdateWithoutEstimatedChargesInput;
  create: EstimateInsurancePolicyCreateWithoutEstimatedChargesInput;
}

export interface EstimateInsurancePolicyWhereInput {
  AND?: EstimateInsurancePolicyWhereInput[] | null;
  OR?: EstimateInsurancePolicyWhereInput[] | null;
  NOT?: EstimateInsurancePolicyWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  insurancePolicyId?: UuidFilter | null;
  estimateId?: UuidFilter | null;
  priority?: IntFilter | null;
  active?: BoolFilter | null;
  insurancePolicy?: InsurancePolicyRelationFilter | null;
  estimate?: EstimateRelationFilter | null;
  estimatedCharges?: EstimatedChargeListRelationFilter | null;
}

export interface EstimateInsurancePolicyWhereUniqueInput {
  id?: string | null;
}

export interface EstimateListRelationFilter {
  every?: EstimateWhereInput | null;
  some?: EstimateWhereInput | null;
  none?: EstimateWhereInput | null;
}

export interface EstimateOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface EstimatePolicyInput {
  insurancePolicyId?: string | null;
  cashPay: boolean;
  active?: boolean | null;
  planType?: PlanTypeInput | null;
}

export interface EstimateRelationFilter {
  is?: EstimateWhereInput | null;
  isNot?: EstimateWhereInput | null;
}

export interface EstimateScalarWhereInput {
  AND?: EstimateScalarWhereInput[] | null;
  OR?: EstimateScalarWhereInput[] | null;
  NOT?: EstimateScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  status?: EnumEstimateStatusFilter | null;
  type?: EnumEstimateTypeFilter | null;
  createdById?: UuidNullableFilter | null;
  accountId?: UuidFilter | null;
  appointmentId?: UuidFilter | null;
  chargeTemplateId?: UuidNullableFilter | null;
  feeScheduleId?: UuidNullableFilter | null;
  feeScheduleMappingId?: UuidNullableFilter | null;
  billId?: UuidNullableFilter | null;
  saltedBillId?: UuidNullableFilter | null;
  note?: StringNullableFilter | null;
  supercededById?: UuidNullableFilter | null;
  outOfDate?: DateTimeNullableFilter | null;
  isManual?: BoolFilter | null;
}

export interface EstimateUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
}

export interface EstimateUpdateManyWithWhereWithoutAccountInput {
  where: EstimateScalarWhereInput;
  data: EstimateUpdateManyMutationInput;
}

export interface EstimateUpdateManyWithWhereWithoutAppointmentInput {
  where: EstimateScalarWhereInput;
  data: EstimateUpdateManyMutationInput;
}

export interface EstimateUpdateManyWithWhereWithoutBillInput {
  where: EstimateScalarWhereInput;
  data: EstimateUpdateManyMutationInput;
}

export interface EstimateUpdateManyWithWhereWithoutChargeTemplateInput {
  where: EstimateScalarWhereInput;
  data: EstimateUpdateManyMutationInput;
}

export interface EstimateUpdateManyWithWhereWithoutCreatedByInput {
  where: EstimateScalarWhereInput;
  data: EstimateUpdateManyMutationInput;
}

export interface EstimateUpdateManyWithWhereWithoutFeeScheduleInput {
  where: EstimateScalarWhereInput;
  data: EstimateUpdateManyMutationInput;
}

export interface EstimateUpdateManyWithWhereWithoutFeeScheduleMappingInput {
  where: EstimateScalarWhereInput;
  data: EstimateUpdateManyMutationInput;
}

export interface EstimateUpdateManyWithWhereWithoutSaltedBillInput {
  where: EstimateScalarWhereInput;
  data: EstimateUpdateManyMutationInput;
}

export interface EstimateUpdateManyWithoutAccountNestedInput {
  create?: EstimateCreateWithoutAccountInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutAccountInput[] | null;
  upsert?: EstimateUpsertWithWhereUniqueWithoutAccountInput[] | null;
  createMany?: EstimateCreateManyAccountInputEnvelope | null;
  set?: EstimateWhereUniqueInput[] | null;
  disconnect?: EstimateWhereUniqueInput[] | null;
  delete?: EstimateWhereUniqueInput[] | null;
  connect?: EstimateWhereUniqueInput[] | null;
  update?: EstimateUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: EstimateUpdateManyWithWhereWithoutAccountInput[] | null;
  deleteMany?: EstimateScalarWhereInput[] | null;
}

export interface EstimateUpdateManyWithoutAppointmentNestedInput {
  create?: EstimateCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: EstimateUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: EstimateCreateManyAppointmentInputEnvelope | null;
  set?: EstimateWhereUniqueInput[] | null;
  disconnect?: EstimateWhereUniqueInput[] | null;
  delete?: EstimateWhereUniqueInput[] | null;
  connect?: EstimateWhereUniqueInput[] | null;
  update?: EstimateUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: EstimateUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: EstimateScalarWhereInput[] | null;
}

export interface EstimateUpdateManyWithoutBillNestedInput {
  create?: EstimateCreateWithoutBillInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutBillInput[] | null;
  upsert?: EstimateUpsertWithWhereUniqueWithoutBillInput[] | null;
  createMany?: EstimateCreateManyBillInputEnvelope | null;
  set?: EstimateWhereUniqueInput[] | null;
  disconnect?: EstimateWhereUniqueInput[] | null;
  delete?: EstimateWhereUniqueInput[] | null;
  connect?: EstimateWhereUniqueInput[] | null;
  update?: EstimateUpdateWithWhereUniqueWithoutBillInput[] | null;
  updateMany?: EstimateUpdateManyWithWhereWithoutBillInput[] | null;
  deleteMany?: EstimateScalarWhereInput[] | null;
}

export interface EstimateUpdateManyWithoutChargeTemplateNestedInput {
  create?: EstimateCreateWithoutChargeTemplateInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutChargeTemplateInput[] | null;
  upsert?: EstimateUpsertWithWhereUniqueWithoutChargeTemplateInput[] | null;
  createMany?: EstimateCreateManyChargeTemplateInputEnvelope | null;
  set?: EstimateWhereUniqueInput[] | null;
  disconnect?: EstimateWhereUniqueInput[] | null;
  delete?: EstimateWhereUniqueInput[] | null;
  connect?: EstimateWhereUniqueInput[] | null;
  update?: EstimateUpdateWithWhereUniqueWithoutChargeTemplateInput[] | null;
  updateMany?: EstimateUpdateManyWithWhereWithoutChargeTemplateInput[] | null;
  deleteMany?: EstimateScalarWhereInput[] | null;
}

export interface EstimateUpdateManyWithoutCreatedByNestedInput {
  create?: EstimateCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: EstimateUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: EstimateCreateManyCreatedByInputEnvelope | null;
  set?: EstimateWhereUniqueInput[] | null;
  disconnect?: EstimateWhereUniqueInput[] | null;
  delete?: EstimateWhereUniqueInput[] | null;
  connect?: EstimateWhereUniqueInput[] | null;
  update?: EstimateUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: EstimateUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: EstimateScalarWhereInput[] | null;
}

export interface EstimateUpdateManyWithoutFeeScheduleMappingNestedInput {
  create?: EstimateCreateWithoutFeeScheduleMappingInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutFeeScheduleMappingInput[] | null;
  upsert?: EstimateUpsertWithWhereUniqueWithoutFeeScheduleMappingInput[] | null;
  createMany?: EstimateCreateManyFeeScheduleMappingInputEnvelope | null;
  set?: EstimateWhereUniqueInput[] | null;
  disconnect?: EstimateWhereUniqueInput[] | null;
  delete?: EstimateWhereUniqueInput[] | null;
  connect?: EstimateWhereUniqueInput[] | null;
  update?: EstimateUpdateWithWhereUniqueWithoutFeeScheduleMappingInput[] | null;
  updateMany?: EstimateUpdateManyWithWhereWithoutFeeScheduleMappingInput[] | null;
  deleteMany?: EstimateScalarWhereInput[] | null;
}

export interface EstimateUpdateManyWithoutFeeScheduleNestedInput {
  create?: EstimateCreateWithoutFeeScheduleInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutFeeScheduleInput[] | null;
  upsert?: EstimateUpsertWithWhereUniqueWithoutFeeScheduleInput[] | null;
  createMany?: EstimateCreateManyFeeScheduleInputEnvelope | null;
  set?: EstimateWhereUniqueInput[] | null;
  disconnect?: EstimateWhereUniqueInput[] | null;
  delete?: EstimateWhereUniqueInput[] | null;
  connect?: EstimateWhereUniqueInput[] | null;
  update?: EstimateUpdateWithWhereUniqueWithoutFeeScheduleInput[] | null;
  updateMany?: EstimateUpdateManyWithWhereWithoutFeeScheduleInput[] | null;
  deleteMany?: EstimateScalarWhereInput[] | null;
}

export interface EstimateUpdateManyWithoutSaltedBillNestedInput {
  create?: EstimateCreateWithoutSaltedBillInput[] | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutSaltedBillInput[] | null;
  upsert?: EstimateUpsertWithWhereUniqueWithoutSaltedBillInput[] | null;
  createMany?: EstimateCreateManySaltedBillInputEnvelope | null;
  set?: EstimateWhereUniqueInput[] | null;
  disconnect?: EstimateWhereUniqueInput[] | null;
  delete?: EstimateWhereUniqueInput[] | null;
  connect?: EstimateWhereUniqueInput[] | null;
  update?: EstimateUpdateWithWhereUniqueWithoutSaltedBillInput[] | null;
  updateMany?: EstimateUpdateManyWithWhereWithoutSaltedBillInput[] | null;
  deleteMany?: EstimateScalarWhereInput[] | null;
}

export interface EstimateUpdateOneRequiredWithoutEstimateInsurancePoliciesNestedInput {
  create?: EstimateCreateWithoutEstimateInsurancePoliciesInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutEstimateInsurancePoliciesInput | null;
  upsert?: EstimateUpsertWithoutEstimateInsurancePoliciesInput | null;
  connect?: EstimateWhereUniqueInput | null;
  update?: EstimateUpdateWithoutEstimateInsurancePoliciesInput | null;
}

export interface EstimateUpdateOneRequiredWithoutEstimatedChargesNestedInput {
  create?: EstimateCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutEstimatedChargesInput | null;
  upsert?: EstimateUpsertWithoutEstimatedChargesInput | null;
  connect?: EstimateWhereUniqueInput | null;
  update?: EstimateUpdateWithoutEstimatedChargesInput | null;
}

export interface EstimateUpdateOneWithoutAccumulatorAdjustmentsNestedInput {
  create?: EstimateCreateWithoutAccumulatorAdjustmentsInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutAccumulatorAdjustmentsInput | null;
  upsert?: EstimateUpsertWithoutAccumulatorAdjustmentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: EstimateWhereUniqueInput | null;
  update?: EstimateUpdateWithoutAccumulatorAdjustmentsInput | null;
}

export interface EstimateUpdateOneWithoutSupercededByNestedInput {
  create?: EstimateCreateWithoutSupercededByInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutSupercededByInput | null;
  upsert?: EstimateUpsertWithoutSupercededByInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: EstimateWhereUniqueInput | null;
  update?: EstimateUpdateWithoutSupercededByInput | null;
}

export interface EstimateUpdateOneWithoutSupercedesNestedInput {
  create?: EstimateCreateWithoutSupercedesInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutSupercedesInput | null;
  upsert?: EstimateUpsertWithoutSupercedesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: EstimateWhereUniqueInput | null;
  update?: EstimateUpdateWithoutSupercedesInput | null;
}

export interface EstimateUpdateOneWithoutVisitCollectionRequestsNestedInput {
  create?: EstimateCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: EstimateCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  upsert?: EstimateUpsertWithoutVisitCollectionRequestsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: EstimateWhereUniqueInput | null;
  update?: EstimateUpdateWithoutVisitCollectionRequestsInput | null;
}

export interface EstimateUpdateWithWhereUniqueWithoutAccountInput {
  where: EstimateWhereUniqueInput;
  data: EstimateUpdateWithoutAccountInput;
}

export interface EstimateUpdateWithWhereUniqueWithoutAppointmentInput {
  where: EstimateWhereUniqueInput;
  data: EstimateUpdateWithoutAppointmentInput;
}

export interface EstimateUpdateWithWhereUniqueWithoutBillInput {
  where: EstimateWhereUniqueInput;
  data: EstimateUpdateWithoutBillInput;
}

export interface EstimateUpdateWithWhereUniqueWithoutChargeTemplateInput {
  where: EstimateWhereUniqueInput;
  data: EstimateUpdateWithoutChargeTemplateInput;
}

export interface EstimateUpdateWithWhereUniqueWithoutCreatedByInput {
  where: EstimateWhereUniqueInput;
  data: EstimateUpdateWithoutCreatedByInput;
}

export interface EstimateUpdateWithWhereUniqueWithoutFeeScheduleInput {
  where: EstimateWhereUniqueInput;
  data: EstimateUpdateWithoutFeeScheduleInput;
}

export interface EstimateUpdateWithWhereUniqueWithoutFeeScheduleMappingInput {
  where: EstimateWhereUniqueInput;
  data: EstimateUpdateWithoutFeeScheduleMappingInput;
}

export interface EstimateUpdateWithWhereUniqueWithoutSaltedBillInput {
  where: EstimateWhereUniqueInput;
  data: EstimateUpdateWithoutSaltedBillInput;
}

export interface EstimateUpdateWithoutAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutAccumulatorAdjustmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutBillInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutChargeTemplateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutEstimateInsurancePoliciesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutEstimatedChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutFeeScheduleInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutFeeScheduleMappingInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutSaltedBillInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutSupercededByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutSupercedesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutEstimateNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpdateWithoutVisitCollectionRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumEstimateStatusFieldUpdateOperationsInput | null;
  type?: EnumEstimateTypeFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  outOfDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  isManual?: BoolFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutEstimatesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutEstimateNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutEstimatesNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutEstimateNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutEstimatesNestedInput | null;
  chargeTemplate?: ChargeTemplateUpdateOneWithoutEstimatesNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneWithoutEstimatesNestedInput | null;
  feeScheduleMapping?: FeeScheduleMappingUpdateOneWithoutEstimateNestedInput | null;
  bill?: BillUpdateOneWithoutEstimatesNestedInput | null;
  saltedBill?: BillUpdateOneWithoutSaltedEstimatesNestedInput | null;
  supercededBy?: EstimateUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: EstimateUpdateOneWithoutSupercededByNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutEstimateNestedInput | null;
}

export interface EstimateUpsertWithWhereUniqueWithoutAccountInput {
  where: EstimateWhereUniqueInput;
  update: EstimateUpdateWithoutAccountInput;
  create: EstimateCreateWithoutAccountInput;
}

export interface EstimateUpsertWithWhereUniqueWithoutAppointmentInput {
  where: EstimateWhereUniqueInput;
  update: EstimateUpdateWithoutAppointmentInput;
  create: EstimateCreateWithoutAppointmentInput;
}

export interface EstimateUpsertWithWhereUniqueWithoutBillInput {
  where: EstimateWhereUniqueInput;
  update: EstimateUpdateWithoutBillInput;
  create: EstimateCreateWithoutBillInput;
}

export interface EstimateUpsertWithWhereUniqueWithoutChargeTemplateInput {
  where: EstimateWhereUniqueInput;
  update: EstimateUpdateWithoutChargeTemplateInput;
  create: EstimateCreateWithoutChargeTemplateInput;
}

export interface EstimateUpsertWithWhereUniqueWithoutCreatedByInput {
  where: EstimateWhereUniqueInput;
  update: EstimateUpdateWithoutCreatedByInput;
  create: EstimateCreateWithoutCreatedByInput;
}

export interface EstimateUpsertWithWhereUniqueWithoutFeeScheduleInput {
  where: EstimateWhereUniqueInput;
  update: EstimateUpdateWithoutFeeScheduleInput;
  create: EstimateCreateWithoutFeeScheduleInput;
}

export interface EstimateUpsertWithWhereUniqueWithoutFeeScheduleMappingInput {
  where: EstimateWhereUniqueInput;
  update: EstimateUpdateWithoutFeeScheduleMappingInput;
  create: EstimateCreateWithoutFeeScheduleMappingInput;
}

export interface EstimateUpsertWithWhereUniqueWithoutSaltedBillInput {
  where: EstimateWhereUniqueInput;
  update: EstimateUpdateWithoutSaltedBillInput;
  create: EstimateCreateWithoutSaltedBillInput;
}

export interface EstimateUpsertWithoutAccumulatorAdjustmentsInput {
  update: EstimateUpdateWithoutAccumulatorAdjustmentsInput;
  create: EstimateCreateWithoutAccumulatorAdjustmentsInput;
}

export interface EstimateUpsertWithoutEstimateInsurancePoliciesInput {
  update: EstimateUpdateWithoutEstimateInsurancePoliciesInput;
  create: EstimateCreateWithoutEstimateInsurancePoliciesInput;
}

export interface EstimateUpsertWithoutEstimatedChargesInput {
  update: EstimateUpdateWithoutEstimatedChargesInput;
  create: EstimateCreateWithoutEstimatedChargesInput;
}

export interface EstimateUpsertWithoutSupercededByInput {
  update: EstimateUpdateWithoutSupercededByInput;
  create: EstimateCreateWithoutSupercededByInput;
}

export interface EstimateUpsertWithoutSupercedesInput {
  update: EstimateUpdateWithoutSupercedesInput;
  create: EstimateCreateWithoutSupercedesInput;
}

export interface EstimateUpsertWithoutVisitCollectionRequestsInput {
  update: EstimateUpdateWithoutVisitCollectionRequestsInput;
  create: EstimateCreateWithoutVisitCollectionRequestsInput;
}

export interface EstimateWhereInput {
  AND?: EstimateWhereInput[] | null;
  OR?: EstimateWhereInput[] | null;
  NOT?: EstimateWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  status?: EnumEstimateStatusFilter | null;
  type?: EnumEstimateTypeFilter | null;
  createdById?: UuidNullableFilter | null;
  accountId?: UuidFilter | null;
  appointmentId?: UuidFilter | null;
  chargeTemplateId?: UuidNullableFilter | null;
  feeScheduleId?: UuidNullableFilter | null;
  feeScheduleMappingId?: UuidNullableFilter | null;
  billId?: UuidNullableFilter | null;
  saltedBillId?: UuidNullableFilter | null;
  note?: StringNullableFilter | null;
  supercededById?: UuidNullableFilter | null;
  outOfDate?: DateTimeNullableFilter | null;
  isManual?: BoolFilter | null;
  createdBy?: UserRelationFilter | null;
  estimatedCharges?: EstimatedChargeListRelationFilter | null;
  account?: AccountRelationFilter | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyListRelationFilter | null;
  appointment?: AppointmentRelationFilter | null;
  chargeTemplate?: ChargeTemplateRelationFilter | null;
  feeSchedule?: FeeScheduleRelationFilter | null;
  feeScheduleMapping?: FeeScheduleMappingRelationFilter | null;
  bill?: BillRelationFilter | null;
  saltedBill?: BillRelationFilter | null;
  supercededBy?: EstimateRelationFilter | null;
  supercedes?: EstimateRelationFilter | null;
  visitCollectionRequests?: VisitCollectionRequestListRelationFilter | null;
  accumulatorAdjustments?: AccumulatorAdjustmentListRelationFilter | null;
}

export interface EstimateWhereUniqueInput {
  id?: string | null;
  supercededById?: string | null;
}

export interface EstimatedChargeCreateManyChargeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  estimateId: string;
  estimatedInsurancePolicyId?: string | null;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  chargemasterId: string;
  units: number;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  coverageBenefitId?: string | null;
  scheduledServiceFeeId?: string | null;
}

export interface EstimatedChargeCreateManyChargeInputEnvelope {
  data: EstimatedChargeCreateManyChargeInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimatedChargeCreateManyChargemasterInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  estimateId: string;
  estimatedInsurancePolicyId?: string | null;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  units: number;
  chargeId?: string | null;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  coverageBenefitId?: string | null;
  scheduledServiceFeeId?: string | null;
}

export interface EstimatedChargeCreateManyChargemasterInputEnvelope {
  data: EstimatedChargeCreateManyChargemasterInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimatedChargeCreateManyCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  estimateId: string;
  estimatedInsurancePolicyId?: string | null;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  chargemasterId: string;
  units: number;
  chargeId?: string | null;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  scheduledServiceFeeId?: string | null;
}

export interface EstimatedChargeCreateManyCoverageBenefitInputEnvelope {
  data: EstimatedChargeCreateManyCoverageBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimatedChargeCreateManyEstimateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  estimatedInsurancePolicyId?: string | null;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  chargemasterId: string;
  units: number;
  chargeId?: string | null;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  coverageBenefitId?: string | null;
  scheduledServiceFeeId?: string | null;
}

export interface EstimatedChargeCreateManyEstimateInputEnvelope {
  data: EstimatedChargeCreateManyEstimateInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimatedChargeCreateManyEstimatedInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  estimateId: string;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  chargemasterId: string;
  units: number;
  chargeId?: string | null;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  coverageBenefitId?: string | null;
  scheduledServiceFeeId?: string | null;
}

export interface EstimatedChargeCreateManyEstimatedInsurancePolicyInputEnvelope {
  data: EstimatedChargeCreateManyEstimatedInsurancePolicyInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimatedChargeCreateManyScheduledServiceFeeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  estimateId: string;
  estimatedInsurancePolicyId?: string | null;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  chargemasterId: string;
  units: number;
  chargeId?: string | null;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  coverageBenefitId?: string | null;
}

export interface EstimatedChargeCreateManyScheduledServiceFeeInputEnvelope {
  data: EstimatedChargeCreateManyScheduledServiceFeeInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimatedChargeCreateNestedManyWithoutChargeInput {
  create?: EstimatedChargeCreateWithoutChargeInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutChargeInput[] | null;
  createMany?: EstimatedChargeCreateManyChargeInputEnvelope | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
}

export interface EstimatedChargeCreateNestedManyWithoutChargemasterInput {
  create?: EstimatedChargeCreateWithoutChargemasterInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutChargemasterInput[] | null;
  createMany?: EstimatedChargeCreateManyChargemasterInputEnvelope | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
}

export interface EstimatedChargeCreateNestedManyWithoutCoverageBenefitInput {
  create?: EstimatedChargeCreateWithoutCoverageBenefitInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutCoverageBenefitInput[] | null;
  createMany?: EstimatedChargeCreateManyCoverageBenefitInputEnvelope | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
}

export interface EstimatedChargeCreateNestedManyWithoutEstimateInput {
  create?: EstimatedChargeCreateWithoutEstimateInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutEstimateInput[] | null;
  createMany?: EstimatedChargeCreateManyEstimateInputEnvelope | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
}

export interface EstimatedChargeCreateNestedManyWithoutEstimatedInsurancePolicyInput {
  create?: EstimatedChargeCreateWithoutEstimatedInsurancePolicyInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutEstimatedInsurancePolicyInput[] | null;
  createMany?: EstimatedChargeCreateManyEstimatedInsurancePolicyInputEnvelope | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
}

export interface EstimatedChargeCreateNestedManyWithoutScheduledServiceFeeInput {
  create?: EstimatedChargeCreateWithoutScheduledServiceFeeInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutScheduledServiceFeeInput[] | null;
  createMany?: EstimatedChargeCreateManyScheduledServiceFeeInputEnvelope | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
}

export interface EstimatedChargeCreateOrConnectWithoutChargeInput {
  where: EstimatedChargeWhereUniqueInput;
  create: EstimatedChargeCreateWithoutChargeInput;
}

export interface EstimatedChargeCreateOrConnectWithoutChargemasterInput {
  where: EstimatedChargeWhereUniqueInput;
  create: EstimatedChargeCreateWithoutChargemasterInput;
}

export interface EstimatedChargeCreateOrConnectWithoutCoverageBenefitInput {
  where: EstimatedChargeWhereUniqueInput;
  create: EstimatedChargeCreateWithoutCoverageBenefitInput;
}

export interface EstimatedChargeCreateOrConnectWithoutEstimateInput {
  where: EstimatedChargeWhereUniqueInput;
  create: EstimatedChargeCreateWithoutEstimateInput;
}

export interface EstimatedChargeCreateOrConnectWithoutEstimatedInsurancePolicyInput {
  where: EstimatedChargeWhereUniqueInput;
  create: EstimatedChargeCreateWithoutEstimatedInsurancePolicyInput;
}

export interface EstimatedChargeCreateOrConnectWithoutScheduledServiceFeeInput {
  where: EstimatedChargeWhereUniqueInput;
  create: EstimatedChargeCreateWithoutScheduledServiceFeeInput;
}

export interface EstimatedChargeCreateWithoutChargeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  units: number;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  estimate: EstimateCreateNestedOneWithoutEstimatedChargesInput;
  estimatedInsurancePolicy?: EstimateInsurancePolicyCreateNestedOneWithoutEstimatedChargesInput | null;
  chargemaster: ChargemasterCreateNestedOneWithoutEstimatedChargesInput;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutEstimatedChargesInput | null;
  scheduledServiceFee?: ScheduledServiceFeeCreateNestedOneWithoutEstimatedChargesInput | null;
}

export interface EstimatedChargeCreateWithoutChargemasterInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  units: number;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  estimate: EstimateCreateNestedOneWithoutEstimatedChargesInput;
  estimatedInsurancePolicy?: EstimateInsurancePolicyCreateNestedOneWithoutEstimatedChargesInput | null;
  charge?: ChargeCreateNestedOneWithoutEstimatedChargesInput | null;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutEstimatedChargesInput | null;
  scheduledServiceFee?: ScheduledServiceFeeCreateNestedOneWithoutEstimatedChargesInput | null;
}

export interface EstimatedChargeCreateWithoutCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  units: number;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  estimate: EstimateCreateNestedOneWithoutEstimatedChargesInput;
  estimatedInsurancePolicy?: EstimateInsurancePolicyCreateNestedOneWithoutEstimatedChargesInput | null;
  chargemaster: ChargemasterCreateNestedOneWithoutEstimatedChargesInput;
  charge?: ChargeCreateNestedOneWithoutEstimatedChargesInput | null;
  scheduledServiceFee?: ScheduledServiceFeeCreateNestedOneWithoutEstimatedChargesInput | null;
}

export interface EstimatedChargeCreateWithoutEstimateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  units: number;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  estimatedInsurancePolicy?: EstimateInsurancePolicyCreateNestedOneWithoutEstimatedChargesInput | null;
  chargemaster: ChargemasterCreateNestedOneWithoutEstimatedChargesInput;
  charge?: ChargeCreateNestedOneWithoutEstimatedChargesInput | null;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutEstimatedChargesInput | null;
  scheduledServiceFee?: ScheduledServiceFeeCreateNestedOneWithoutEstimatedChargesInput | null;
}

export interface EstimatedChargeCreateWithoutEstimatedInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  units: number;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  estimate: EstimateCreateNestedOneWithoutEstimatedChargesInput;
  chargemaster: ChargemasterCreateNestedOneWithoutEstimatedChargesInput;
  charge?: ChargeCreateNestedOneWithoutEstimatedChargesInput | null;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutEstimatedChargesInput | null;
  scheduledServiceFee?: ScheduledServiceFeeCreateNestedOneWithoutEstimatedChargesInput | null;
}

export interface EstimatedChargeCreateWithoutScheduledServiceFeeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  allowedAmount: number;
  copayAmount: number;
  coinsuranceAmount: number;
  deductibleAmount: number;
  outOfPocketAmount: number;
  otherAmount: number;
  taxAmount?: number | null;
  insuranceAmount: number;
  units: number;
  coinsurance?: any | null;
  copay?: number | null;
  remainingDeductible?: number | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  remainingOutOfPocket?: number | null;
  remainingVisits?: number | null;
  estimate: EstimateCreateNestedOneWithoutEstimatedChargesInput;
  estimatedInsurancePolicy?: EstimateInsurancePolicyCreateNestedOneWithoutEstimatedChargesInput | null;
  chargemaster: ChargemasterCreateNestedOneWithoutEstimatedChargesInput;
  charge?: ChargeCreateNestedOneWithoutEstimatedChargesInput | null;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutEstimatedChargesInput | null;
}

export interface EstimatedChargeEstimateIdEstimatedInsurancePolicyIdChargemasterIdCompoundUniqueInput {
  estimateId: string;
  estimatedInsurancePolicyId: string;
  chargemasterId: string;
}

export interface EstimatedChargeListRelationFilter {
  every?: EstimatedChargeWhereInput | null;
  some?: EstimatedChargeWhereInput | null;
  none?: EstimatedChargeWhereInput | null;
}

export interface EstimatedChargeScalarWhereInput {
  AND?: EstimatedChargeScalarWhereInput[] | null;
  OR?: EstimatedChargeScalarWhereInput[] | null;
  NOT?: EstimatedChargeScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  priority?: FloatFilter | null;
  estimateId?: UuidFilter | null;
  estimatedInsurancePolicyId?: UuidNullableFilter | null;
  allowedAmount?: IntFilter | null;
  copayAmount?: IntFilter | null;
  coinsuranceAmount?: IntFilter | null;
  deductibleAmount?: IntFilter | null;
  outOfPocketAmount?: IntFilter | null;
  otherAmount?: IntFilter | null;
  taxAmount?: IntNullableFilter | null;
  insuranceAmount?: IntFilter | null;
  chargemasterId?: UuidFilter | null;
  units?: FloatFilter | null;
  chargeId?: UuidNullableFilter | null;
  coinsurance?: DecimalNullableFilter | null;
  copay?: IntNullableFilter | null;
  remainingDeductible?: IntNullableFilter | null;
  deductibleApplies?: BoolNullableFilter | null;
  maxDeductible?: IntNullableFilter | null;
  remainingOutOfPocket?: IntNullableFilter | null;
  remainingVisits?: IntNullableFilter | null;
  coverageBenefitId?: UuidNullableFilter | null;
  scheduledServiceFeeId?: UuidNullableFilter | null;
}

export interface EstimatedChargeUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  outOfPocketAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
}

export interface EstimatedChargeUpdateManyWithWhereWithoutChargeInput {
  where: EstimatedChargeScalarWhereInput;
  data: EstimatedChargeUpdateManyMutationInput;
}

export interface EstimatedChargeUpdateManyWithWhereWithoutChargemasterInput {
  where: EstimatedChargeScalarWhereInput;
  data: EstimatedChargeUpdateManyMutationInput;
}

export interface EstimatedChargeUpdateManyWithWhereWithoutCoverageBenefitInput {
  where: EstimatedChargeScalarWhereInput;
  data: EstimatedChargeUpdateManyMutationInput;
}

export interface EstimatedChargeUpdateManyWithWhereWithoutEstimateInput {
  where: EstimatedChargeScalarWhereInput;
  data: EstimatedChargeUpdateManyMutationInput;
}

export interface EstimatedChargeUpdateManyWithWhereWithoutEstimatedInsurancePolicyInput {
  where: EstimatedChargeScalarWhereInput;
  data: EstimatedChargeUpdateManyMutationInput;
}

export interface EstimatedChargeUpdateManyWithWhereWithoutScheduledServiceFeeInput {
  where: EstimatedChargeScalarWhereInput;
  data: EstimatedChargeUpdateManyMutationInput;
}

export interface EstimatedChargeUpdateManyWithoutChargeNestedInput {
  create?: EstimatedChargeCreateWithoutChargeInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutChargeInput[] | null;
  upsert?: EstimatedChargeUpsertWithWhereUniqueWithoutChargeInput[] | null;
  createMany?: EstimatedChargeCreateManyChargeInputEnvelope | null;
  set?: EstimatedChargeWhereUniqueInput[] | null;
  disconnect?: EstimatedChargeWhereUniqueInput[] | null;
  delete?: EstimatedChargeWhereUniqueInput[] | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
  update?: EstimatedChargeUpdateWithWhereUniqueWithoutChargeInput[] | null;
  updateMany?: EstimatedChargeUpdateManyWithWhereWithoutChargeInput[] | null;
  deleteMany?: EstimatedChargeScalarWhereInput[] | null;
}

export interface EstimatedChargeUpdateManyWithoutChargemasterNestedInput {
  create?: EstimatedChargeCreateWithoutChargemasterInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutChargemasterInput[] | null;
  upsert?: EstimatedChargeUpsertWithWhereUniqueWithoutChargemasterInput[] | null;
  createMany?: EstimatedChargeCreateManyChargemasterInputEnvelope | null;
  set?: EstimatedChargeWhereUniqueInput[] | null;
  disconnect?: EstimatedChargeWhereUniqueInput[] | null;
  delete?: EstimatedChargeWhereUniqueInput[] | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
  update?: EstimatedChargeUpdateWithWhereUniqueWithoutChargemasterInput[] | null;
  updateMany?: EstimatedChargeUpdateManyWithWhereWithoutChargemasterInput[] | null;
  deleteMany?: EstimatedChargeScalarWhereInput[] | null;
}

export interface EstimatedChargeUpdateManyWithoutCoverageBenefitNestedInput {
  create?: EstimatedChargeCreateWithoutCoverageBenefitInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutCoverageBenefitInput[] | null;
  upsert?: EstimatedChargeUpsertWithWhereUniqueWithoutCoverageBenefitInput[] | null;
  createMany?: EstimatedChargeCreateManyCoverageBenefitInputEnvelope | null;
  set?: EstimatedChargeWhereUniqueInput[] | null;
  disconnect?: EstimatedChargeWhereUniqueInput[] | null;
  delete?: EstimatedChargeWhereUniqueInput[] | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
  update?: EstimatedChargeUpdateWithWhereUniqueWithoutCoverageBenefitInput[] | null;
  updateMany?: EstimatedChargeUpdateManyWithWhereWithoutCoverageBenefitInput[] | null;
  deleteMany?: EstimatedChargeScalarWhereInput[] | null;
}

export interface EstimatedChargeUpdateManyWithoutEstimateNestedInput {
  create?: EstimatedChargeCreateWithoutEstimateInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutEstimateInput[] | null;
  upsert?: EstimatedChargeUpsertWithWhereUniqueWithoutEstimateInput[] | null;
  createMany?: EstimatedChargeCreateManyEstimateInputEnvelope | null;
  set?: EstimatedChargeWhereUniqueInput[] | null;
  disconnect?: EstimatedChargeWhereUniqueInput[] | null;
  delete?: EstimatedChargeWhereUniqueInput[] | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
  update?: EstimatedChargeUpdateWithWhereUniqueWithoutEstimateInput[] | null;
  updateMany?: EstimatedChargeUpdateManyWithWhereWithoutEstimateInput[] | null;
  deleteMany?: EstimatedChargeScalarWhereInput[] | null;
}

export interface EstimatedChargeUpdateManyWithoutEstimatedInsurancePolicyNestedInput {
  create?: EstimatedChargeCreateWithoutEstimatedInsurancePolicyInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutEstimatedInsurancePolicyInput[] | null;
  upsert?: EstimatedChargeUpsertWithWhereUniqueWithoutEstimatedInsurancePolicyInput[] | null;
  createMany?: EstimatedChargeCreateManyEstimatedInsurancePolicyInputEnvelope | null;
  set?: EstimatedChargeWhereUniqueInput[] | null;
  disconnect?: EstimatedChargeWhereUniqueInput[] | null;
  delete?: EstimatedChargeWhereUniqueInput[] | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
  update?: EstimatedChargeUpdateWithWhereUniqueWithoutEstimatedInsurancePolicyInput[] | null;
  updateMany?: EstimatedChargeUpdateManyWithWhereWithoutEstimatedInsurancePolicyInput[] | null;
  deleteMany?: EstimatedChargeScalarWhereInput[] | null;
}

export interface EstimatedChargeUpdateManyWithoutScheduledServiceFeeNestedInput {
  create?: EstimatedChargeCreateWithoutScheduledServiceFeeInput[] | null;
  connectOrCreate?: EstimatedChargeCreateOrConnectWithoutScheduledServiceFeeInput[] | null;
  upsert?: EstimatedChargeUpsertWithWhereUniqueWithoutScheduledServiceFeeInput[] | null;
  createMany?: EstimatedChargeCreateManyScheduledServiceFeeInputEnvelope | null;
  set?: EstimatedChargeWhereUniqueInput[] | null;
  disconnect?: EstimatedChargeWhereUniqueInput[] | null;
  delete?: EstimatedChargeWhereUniqueInput[] | null;
  connect?: EstimatedChargeWhereUniqueInput[] | null;
  update?: EstimatedChargeUpdateWithWhereUniqueWithoutScheduledServiceFeeInput[] | null;
  updateMany?: EstimatedChargeUpdateManyWithWhereWithoutScheduledServiceFeeInput[] | null;
  deleteMany?: EstimatedChargeScalarWhereInput[] | null;
}

export interface EstimatedChargeUpdateWithWhereUniqueWithoutChargeInput {
  where: EstimatedChargeWhereUniqueInput;
  data: EstimatedChargeUpdateWithoutChargeInput;
}

export interface EstimatedChargeUpdateWithWhereUniqueWithoutChargemasterInput {
  where: EstimatedChargeWhereUniqueInput;
  data: EstimatedChargeUpdateWithoutChargemasterInput;
}

export interface EstimatedChargeUpdateWithWhereUniqueWithoutCoverageBenefitInput {
  where: EstimatedChargeWhereUniqueInput;
  data: EstimatedChargeUpdateWithoutCoverageBenefitInput;
}

export interface EstimatedChargeUpdateWithWhereUniqueWithoutEstimateInput {
  where: EstimatedChargeWhereUniqueInput;
  data: EstimatedChargeUpdateWithoutEstimateInput;
}

export interface EstimatedChargeUpdateWithWhereUniqueWithoutEstimatedInsurancePolicyInput {
  where: EstimatedChargeWhereUniqueInput;
  data: EstimatedChargeUpdateWithoutEstimatedInsurancePolicyInput;
}

export interface EstimatedChargeUpdateWithWhereUniqueWithoutScheduledServiceFeeInput {
  where: EstimatedChargeWhereUniqueInput;
  data: EstimatedChargeUpdateWithoutScheduledServiceFeeInput;
}

export interface EstimatedChargeUpdateWithoutChargeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  outOfPocketAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  estimate?: EstimateUpdateOneRequiredWithoutEstimatedChargesNestedInput | null;
  estimatedInsurancePolicy?: EstimateInsurancePolicyUpdateOneWithoutEstimatedChargesNestedInput | null;
  chargemaster?: ChargemasterUpdateOneRequiredWithoutEstimatedChargesNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutEstimatedChargesNestedInput | null;
  scheduledServiceFee?: ScheduledServiceFeeUpdateOneWithoutEstimatedChargesNestedInput | null;
}

export interface EstimatedChargeUpdateWithoutChargemasterInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  outOfPocketAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  estimate?: EstimateUpdateOneRequiredWithoutEstimatedChargesNestedInput | null;
  estimatedInsurancePolicy?: EstimateInsurancePolicyUpdateOneWithoutEstimatedChargesNestedInput | null;
  charge?: ChargeUpdateOneWithoutEstimatedChargesNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutEstimatedChargesNestedInput | null;
  scheduledServiceFee?: ScheduledServiceFeeUpdateOneWithoutEstimatedChargesNestedInput | null;
}

export interface EstimatedChargeUpdateWithoutCoverageBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  outOfPocketAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  estimate?: EstimateUpdateOneRequiredWithoutEstimatedChargesNestedInput | null;
  estimatedInsurancePolicy?: EstimateInsurancePolicyUpdateOneWithoutEstimatedChargesNestedInput | null;
  chargemaster?: ChargemasterUpdateOneRequiredWithoutEstimatedChargesNestedInput | null;
  charge?: ChargeUpdateOneWithoutEstimatedChargesNestedInput | null;
  scheduledServiceFee?: ScheduledServiceFeeUpdateOneWithoutEstimatedChargesNestedInput | null;
}

export interface EstimatedChargeUpdateWithoutEstimateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  outOfPocketAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  estimatedInsurancePolicy?: EstimateInsurancePolicyUpdateOneWithoutEstimatedChargesNestedInput | null;
  chargemaster?: ChargemasterUpdateOneRequiredWithoutEstimatedChargesNestedInput | null;
  charge?: ChargeUpdateOneWithoutEstimatedChargesNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutEstimatedChargesNestedInput | null;
  scheduledServiceFee?: ScheduledServiceFeeUpdateOneWithoutEstimatedChargesNestedInput | null;
}

export interface EstimatedChargeUpdateWithoutEstimatedInsurancePolicyInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  outOfPocketAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  estimate?: EstimateUpdateOneRequiredWithoutEstimatedChargesNestedInput | null;
  chargemaster?: ChargemasterUpdateOneRequiredWithoutEstimatedChargesNestedInput | null;
  charge?: ChargeUpdateOneWithoutEstimatedChargesNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutEstimatedChargesNestedInput | null;
  scheduledServiceFee?: ScheduledServiceFeeUpdateOneWithoutEstimatedChargesNestedInput | null;
}

export interface EstimatedChargeUpdateWithoutScheduledServiceFeeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  copayAmount?: IntFieldUpdateOperationsInput | null;
  coinsuranceAmount?: IntFieldUpdateOperationsInput | null;
  deductibleAmount?: IntFieldUpdateOperationsInput | null;
  outOfPocketAmount?: IntFieldUpdateOperationsInput | null;
  otherAmount?: IntFieldUpdateOperationsInput | null;
  taxAmount?: NullableIntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  units?: FloatFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  estimate?: EstimateUpdateOneRequiredWithoutEstimatedChargesNestedInput | null;
  estimatedInsurancePolicy?: EstimateInsurancePolicyUpdateOneWithoutEstimatedChargesNestedInput | null;
  chargemaster?: ChargemasterUpdateOneRequiredWithoutEstimatedChargesNestedInput | null;
  charge?: ChargeUpdateOneWithoutEstimatedChargesNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutEstimatedChargesNestedInput | null;
}

export interface EstimatedChargeUpsertWithWhereUniqueWithoutChargeInput {
  where: EstimatedChargeWhereUniqueInput;
  update: EstimatedChargeUpdateWithoutChargeInput;
  create: EstimatedChargeCreateWithoutChargeInput;
}

export interface EstimatedChargeUpsertWithWhereUniqueWithoutChargemasterInput {
  where: EstimatedChargeWhereUniqueInput;
  update: EstimatedChargeUpdateWithoutChargemasterInput;
  create: EstimatedChargeCreateWithoutChargemasterInput;
}

export interface EstimatedChargeUpsertWithWhereUniqueWithoutCoverageBenefitInput {
  where: EstimatedChargeWhereUniqueInput;
  update: EstimatedChargeUpdateWithoutCoverageBenefitInput;
  create: EstimatedChargeCreateWithoutCoverageBenefitInput;
}

export interface EstimatedChargeUpsertWithWhereUniqueWithoutEstimateInput {
  where: EstimatedChargeWhereUniqueInput;
  update: EstimatedChargeUpdateWithoutEstimateInput;
  create: EstimatedChargeCreateWithoutEstimateInput;
}

export interface EstimatedChargeUpsertWithWhereUniqueWithoutEstimatedInsurancePolicyInput {
  where: EstimatedChargeWhereUniqueInput;
  update: EstimatedChargeUpdateWithoutEstimatedInsurancePolicyInput;
  create: EstimatedChargeCreateWithoutEstimatedInsurancePolicyInput;
}

export interface EstimatedChargeUpsertWithWhereUniqueWithoutScheduledServiceFeeInput {
  where: EstimatedChargeWhereUniqueInput;
  update: EstimatedChargeUpdateWithoutScheduledServiceFeeInput;
  create: EstimatedChargeCreateWithoutScheduledServiceFeeInput;
}

export interface EstimatedChargeWhereInput {
  AND?: EstimatedChargeWhereInput[] | null;
  OR?: EstimatedChargeWhereInput[] | null;
  NOT?: EstimatedChargeWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  priority?: FloatFilter | null;
  estimateId?: UuidFilter | null;
  estimatedInsurancePolicyId?: UuidNullableFilter | null;
  allowedAmount?: IntFilter | null;
  copayAmount?: IntFilter | null;
  coinsuranceAmount?: IntFilter | null;
  deductibleAmount?: IntFilter | null;
  outOfPocketAmount?: IntFilter | null;
  otherAmount?: IntFilter | null;
  taxAmount?: IntNullableFilter | null;
  insuranceAmount?: IntFilter | null;
  chargemasterId?: UuidFilter | null;
  units?: FloatFilter | null;
  chargeId?: UuidNullableFilter | null;
  coinsurance?: DecimalNullableFilter | null;
  copay?: IntNullableFilter | null;
  remainingDeductible?: IntNullableFilter | null;
  deductibleApplies?: BoolNullableFilter | null;
  maxDeductible?: IntNullableFilter | null;
  remainingOutOfPocket?: IntNullableFilter | null;
  remainingVisits?: IntNullableFilter | null;
  coverageBenefitId?: UuidNullableFilter | null;
  scheduledServiceFeeId?: UuidNullableFilter | null;
  estimate?: EstimateRelationFilter | null;
  estimatedInsurancePolicy?: EstimateInsurancePolicyRelationFilter | null;
  chargemaster?: ChargemasterRelationFilter | null;
  charge?: ChargeRelationFilter | null;
  coverageBenefit?: CoverageBenefitRelationFilter | null;
  scheduledServiceFee?: ScheduledServiceFeeRelationFilter | null;
}

export interface EstimatedChargeWhereUniqueInput {
  id?: string | null;
  estimateId_estimatedInsurancePolicyId_chargemasterId?: EstimatedChargeEstimateIdEstimatedInsurancePolicyIdChargemasterIdCompoundUniqueInput | null;
}

export interface EstimationWorkflowStatusCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
  location: LocationCreateNestedOneWithoutEstimationWorkflowStatusesInput;
  appointments?: AppointmentCreateNestedManyWithoutEstimationWorkflowStatusInput | null;
  locationDefault?: LocationCreateNestedOneWithoutDefaultEstimationWorkflowStatusInput | null;
}

export interface EstimationWorkflowStatusCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
}

export interface EstimationWorkflowStatusCreateManyLocationInputEnvelope {
  data: EstimationWorkflowStatusCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface EstimationWorkflowStatusCreateNestedManyWithoutLocationInput {
  create?: EstimationWorkflowStatusCreateWithoutLocationInput[] | null;
  connectOrCreate?: EstimationWorkflowStatusCreateOrConnectWithoutLocationInput[] | null;
  createMany?: EstimationWorkflowStatusCreateManyLocationInputEnvelope | null;
  connect?: EstimationWorkflowStatusWhereUniqueInput[] | null;
}

export interface EstimationWorkflowStatusCreateNestedOneWithoutAppointmentsInput {
  create?: EstimationWorkflowStatusCreateWithoutAppointmentsInput | null;
  connectOrCreate?: EstimationWorkflowStatusCreateOrConnectWithoutAppointmentsInput | null;
  connect?: EstimationWorkflowStatusWhereUniqueInput | null;
}

export interface EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput {
  create?: EstimationWorkflowStatusCreateWithoutLocationDefaultInput | null;
  connectOrCreate?: EstimationWorkflowStatusCreateOrConnectWithoutLocationDefaultInput | null;
  connect?: EstimationWorkflowStatusWhereUniqueInput | null;
}

export interface EstimationWorkflowStatusCreateOrConnectWithoutAppointmentsInput {
  where: EstimationWorkflowStatusWhereUniqueInput;
  create: EstimationWorkflowStatusCreateWithoutAppointmentsInput;
}

export interface EstimationWorkflowStatusCreateOrConnectWithoutLocationDefaultInput {
  where: EstimationWorkflowStatusWhereUniqueInput;
  create: EstimationWorkflowStatusCreateWithoutLocationDefaultInput;
}

export interface EstimationWorkflowStatusCreateOrConnectWithoutLocationInput {
  where: EstimationWorkflowStatusWhereUniqueInput;
  create: EstimationWorkflowStatusCreateWithoutLocationInput;
}

export interface EstimationWorkflowStatusCreateWithoutAppointmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
  location: LocationCreateNestedOneWithoutEstimationWorkflowStatusesInput;
  locationDefault?: LocationCreateNestedOneWithoutDefaultEstimationWorkflowStatusInput | null;
}

export interface EstimationWorkflowStatusCreateWithoutLocationDefaultInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
  location: LocationCreateNestedOneWithoutEstimationWorkflowStatusesInput;
  appointments?: AppointmentCreateNestedManyWithoutEstimationWorkflowStatusInput | null;
}

export interface EstimationWorkflowStatusCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
  appointments?: AppointmentCreateNestedManyWithoutEstimationWorkflowStatusInput | null;
  locationDefault?: LocationCreateNestedOneWithoutDefaultEstimationWorkflowStatusInput | null;
}

export interface EstimationWorkflowStatusListRelationFilter {
  every?: EstimationWorkflowStatusWhereInput | null;
  some?: EstimationWorkflowStatusWhereInput | null;
  none?: EstimationWorkflowStatusWhereInput | null;
}

export interface EstimationWorkflowStatusOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface EstimationWorkflowStatusOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  name?: SortOrder | null;
  position?: SortOrder | null;
  locationId?: SortOrder | null;
  stage?: SortOrder | null;
  description?: SortOrderInput | null;
  location?: LocationOrderByWithRelationInput | null;
  appointments?: AppointmentOrderByRelationAggregateInput | null;
  locationDefault?: LocationOrderByWithRelationInput | null;
}

export interface EstimationWorkflowStatusRelationFilter {
  is?: EstimationWorkflowStatusWhereInput | null;
  isNot?: EstimationWorkflowStatusWhereInput | null;
}

export interface EstimationWorkflowStatusScalarWhereInput {
  AND?: EstimationWorkflowStatusScalarWhereInput[] | null;
  OR?: EstimationWorkflowStatusScalarWhereInput[] | null;
  NOT?: EstimationWorkflowStatusScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  position?: FloatFilter | null;
  locationId?: UuidFilter | null;
  stage?: EnumWorkflowStageFilter | null;
  description?: StringNullableFilter | null;
}

export interface EstimationWorkflowStatusUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutEstimationWorkflowStatusesNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutEstimationWorkflowStatusNestedInput | null;
  locationDefault?: LocationUpdateOneWithoutDefaultEstimationWorkflowStatusNestedInput | null;
}

export interface EstimationWorkflowStatusUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
}

export interface EstimationWorkflowStatusUpdateManyWithWhereWithoutLocationInput {
  where: EstimationWorkflowStatusScalarWhereInput;
  data: EstimationWorkflowStatusUpdateManyMutationInput;
}

export interface EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput {
  create?: EstimationWorkflowStatusCreateWithoutLocationInput[] | null;
  connectOrCreate?: EstimationWorkflowStatusCreateOrConnectWithoutLocationInput[] | null;
  upsert?: EstimationWorkflowStatusUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: EstimationWorkflowStatusCreateManyLocationInputEnvelope | null;
  set?: EstimationWorkflowStatusWhereUniqueInput[] | null;
  disconnect?: EstimationWorkflowStatusWhereUniqueInput[] | null;
  delete?: EstimationWorkflowStatusWhereUniqueInput[] | null;
  connect?: EstimationWorkflowStatusWhereUniqueInput[] | null;
  update?: EstimationWorkflowStatusUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: EstimationWorkflowStatusUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: EstimationWorkflowStatusScalarWhereInput[] | null;
}

export interface EstimationWorkflowStatusUpdateOneRequiredWithoutAppointmentsNestedInput {
  create?: EstimationWorkflowStatusCreateWithoutAppointmentsInput | null;
  connectOrCreate?: EstimationWorkflowStatusCreateOrConnectWithoutAppointmentsInput | null;
  upsert?: EstimationWorkflowStatusUpsertWithoutAppointmentsInput | null;
  connect?: EstimationWorkflowStatusWhereUniqueInput | null;
  update?: EstimationWorkflowStatusUpdateWithoutAppointmentsInput | null;
}

export interface EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput {
  create?: EstimationWorkflowStatusCreateWithoutLocationDefaultInput | null;
  connectOrCreate?: EstimationWorkflowStatusCreateOrConnectWithoutLocationDefaultInput | null;
  upsert?: EstimationWorkflowStatusUpsertWithoutLocationDefaultInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: EstimationWorkflowStatusWhereUniqueInput | null;
  update?: EstimationWorkflowStatusUpdateWithoutLocationDefaultInput | null;
}

export interface EstimationWorkflowStatusUpdateWithWhereUniqueWithoutLocationInput {
  where: EstimationWorkflowStatusWhereUniqueInput;
  data: EstimationWorkflowStatusUpdateWithoutLocationInput;
}

export interface EstimationWorkflowStatusUpdateWithoutAppointmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutEstimationWorkflowStatusesNestedInput | null;
  locationDefault?: LocationUpdateOneWithoutDefaultEstimationWorkflowStatusNestedInput | null;
}

export interface EstimationWorkflowStatusUpdateWithoutLocationDefaultInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutEstimationWorkflowStatusesNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutEstimationWorkflowStatusNestedInput | null;
}

export interface EstimationWorkflowStatusUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  appointments?: AppointmentUpdateManyWithoutEstimationWorkflowStatusNestedInput | null;
  locationDefault?: LocationUpdateOneWithoutDefaultEstimationWorkflowStatusNestedInput | null;
}

export interface EstimationWorkflowStatusUpsertWithWhereUniqueWithoutLocationInput {
  where: EstimationWorkflowStatusWhereUniqueInput;
  update: EstimationWorkflowStatusUpdateWithoutLocationInput;
  create: EstimationWorkflowStatusCreateWithoutLocationInput;
}

export interface EstimationWorkflowStatusUpsertWithoutAppointmentsInput {
  update: EstimationWorkflowStatusUpdateWithoutAppointmentsInput;
  create: EstimationWorkflowStatusCreateWithoutAppointmentsInput;
}

export interface EstimationWorkflowStatusUpsertWithoutLocationDefaultInput {
  update: EstimationWorkflowStatusUpdateWithoutLocationDefaultInput;
  create: EstimationWorkflowStatusCreateWithoutLocationDefaultInput;
}

export interface EstimationWorkflowStatusWhereInput {
  AND?: EstimationWorkflowStatusWhereInput[] | null;
  OR?: EstimationWorkflowStatusWhereInput[] | null;
  NOT?: EstimationWorkflowStatusWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  position?: FloatFilter | null;
  locationId?: UuidFilter | null;
  stage?: EnumWorkflowStageFilter | null;
  description?: StringNullableFilter | null;
  location?: LocationRelationFilter | null;
  appointments?: AppointmentListRelationFilter | null;
  locationDefault?: LocationRelationFilter | null;
}

export interface EstimationWorkflowStatusWhereUniqueInput {
  id?: string | null;
}

export interface ExternalPaymentMethodCreateManyAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  cardBrand?: string | null;
  lastFour?: string | null;
  expirationMonth?: number | null;
  expirationYear?: number | null;
  funding?: string | null;
  default?: boolean | null;
  detatchedAt?: any | null;
}

export interface ExternalPaymentMethodCreateManyAccountInputEnvelope {
  data: ExternalPaymentMethodCreateManyAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface ExternalPaymentMethodCreateNestedManyWithoutAccountInput {
  create?: ExternalPaymentMethodCreateWithoutAccountInput[] | null;
  connectOrCreate?: ExternalPaymentMethodCreateOrConnectWithoutAccountInput[] | null;
  createMany?: ExternalPaymentMethodCreateManyAccountInputEnvelope | null;
  connect?: ExternalPaymentMethodWhereUniqueInput[] | null;
}

export interface ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput {
  create?: ExternalPaymentMethodCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ExternalPaymentMethodCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: ExternalPaymentMethodWhereUniqueInput | null;
}

export interface ExternalPaymentMethodCreateOrConnectWithoutAccountInput {
  where: ExternalPaymentMethodWhereUniqueInput;
  create: ExternalPaymentMethodCreateWithoutAccountInput;
}

export interface ExternalPaymentMethodCreateOrConnectWithoutIntegrationLinksInput {
  where: ExternalPaymentMethodWhereUniqueInput;
  create: ExternalPaymentMethodCreateWithoutIntegrationLinksInput;
}

export interface ExternalPaymentMethodCreateWithoutAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  cardBrand?: string | null;
  lastFour?: string | null;
  expirationMonth?: number | null;
  expirationYear?: number | null;
  funding?: string | null;
  default?: boolean | null;
  detatchedAt?: any | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutExternalPaymentMethodsInput | null;
}

export interface ExternalPaymentMethodCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  cardBrand?: string | null;
  lastFour?: string | null;
  expirationMonth?: number | null;
  expirationYear?: number | null;
  funding?: string | null;
  default?: boolean | null;
  detatchedAt?: any | null;
  account: AccountCreateNestedOneWithoutExternalPaymentMethodsInput;
}

export interface ExternalPaymentMethodListRelationFilter {
  every?: ExternalPaymentMethodWhereInput | null;
  some?: ExternalPaymentMethodWhereInput | null;
  none?: ExternalPaymentMethodWhereInput | null;
}

export interface ExternalPaymentMethodOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ExternalPaymentMethodRelationFilter {
  is?: ExternalPaymentMethodWhereInput | null;
  isNot?: ExternalPaymentMethodWhereInput | null;
}

export interface ExternalPaymentMethodScalarWhereInput {
  AND?: ExternalPaymentMethodScalarWhereInput[] | null;
  OR?: ExternalPaymentMethodScalarWhereInput[] | null;
  NOT?: ExternalPaymentMethodScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  cardBrand?: StringNullableFilter | null;
  lastFour?: StringNullableFilter | null;
  expirationMonth?: IntNullableFilter | null;
  expirationYear?: IntNullableFilter | null;
  funding?: StringNullableFilter | null;
  accountId?: UuidFilter | null;
  default?: BoolFilter | null;
  detatchedAt?: DateTimeNullableFilter | null;
}

export interface ExternalPaymentMethodUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  cardBrand?: NullableStringFieldUpdateOperationsInput | null;
  lastFour?: NullableStringFieldUpdateOperationsInput | null;
  expirationMonth?: NullableIntFieldUpdateOperationsInput | null;
  expirationYear?: NullableIntFieldUpdateOperationsInput | null;
  funding?: NullableStringFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
  detatchedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
}

export interface ExternalPaymentMethodUpdateManyWithWhereWithoutAccountInput {
  where: ExternalPaymentMethodScalarWhereInput;
  data: ExternalPaymentMethodUpdateManyMutationInput;
}

export interface ExternalPaymentMethodUpdateManyWithoutAccountNestedInput {
  create?: ExternalPaymentMethodCreateWithoutAccountInput[] | null;
  connectOrCreate?: ExternalPaymentMethodCreateOrConnectWithoutAccountInput[] | null;
  upsert?: ExternalPaymentMethodUpsertWithWhereUniqueWithoutAccountInput[] | null;
  createMany?: ExternalPaymentMethodCreateManyAccountInputEnvelope | null;
  set?: ExternalPaymentMethodWhereUniqueInput[] | null;
  disconnect?: ExternalPaymentMethodWhereUniqueInput[] | null;
  delete?: ExternalPaymentMethodWhereUniqueInput[] | null;
  connect?: ExternalPaymentMethodWhereUniqueInput[] | null;
  update?: ExternalPaymentMethodUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: ExternalPaymentMethodUpdateManyWithWhereWithoutAccountInput[] | null;
  deleteMany?: ExternalPaymentMethodScalarWhereInput[] | null;
}

export interface ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput {
  create?: ExternalPaymentMethodCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ExternalPaymentMethodCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: ExternalPaymentMethodUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ExternalPaymentMethodWhereUniqueInput | null;
  update?: ExternalPaymentMethodUpdateWithoutIntegrationLinksInput | null;
}

export interface ExternalPaymentMethodUpdateWithWhereUniqueWithoutAccountInput {
  where: ExternalPaymentMethodWhereUniqueInput;
  data: ExternalPaymentMethodUpdateWithoutAccountInput;
}

export interface ExternalPaymentMethodUpdateWithoutAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  cardBrand?: NullableStringFieldUpdateOperationsInput | null;
  lastFour?: NullableStringFieldUpdateOperationsInput | null;
  expirationMonth?: NullableIntFieldUpdateOperationsInput | null;
  expirationYear?: NullableIntFieldUpdateOperationsInput | null;
  funding?: NullableStringFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
  detatchedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutExternalPaymentMethodsNestedInput | null;
}

export interface ExternalPaymentMethodUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  cardBrand?: NullableStringFieldUpdateOperationsInput | null;
  lastFour?: NullableStringFieldUpdateOperationsInput | null;
  expirationMonth?: NullableIntFieldUpdateOperationsInput | null;
  expirationYear?: NullableIntFieldUpdateOperationsInput | null;
  funding?: NullableStringFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
  detatchedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutExternalPaymentMethodsNestedInput | null;
}

export interface ExternalPaymentMethodUpsertWithWhereUniqueWithoutAccountInput {
  where: ExternalPaymentMethodWhereUniqueInput;
  update: ExternalPaymentMethodUpdateWithoutAccountInput;
  create: ExternalPaymentMethodCreateWithoutAccountInput;
}

export interface ExternalPaymentMethodUpsertWithoutIntegrationLinksInput {
  update: ExternalPaymentMethodUpdateWithoutIntegrationLinksInput;
  create: ExternalPaymentMethodCreateWithoutIntegrationLinksInput;
}

export interface ExternalPaymentMethodWhereInput {
  AND?: ExternalPaymentMethodWhereInput[] | null;
  OR?: ExternalPaymentMethodWhereInput[] | null;
  NOT?: ExternalPaymentMethodWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  cardBrand?: StringNullableFilter | null;
  lastFour?: StringNullableFilter | null;
  expirationMonth?: IntNullableFilter | null;
  expirationYear?: IntNullableFilter | null;
  funding?: StringNullableFilter | null;
  accountId?: UuidFilter | null;
  default?: BoolFilter | null;
  detatchedAt?: DateTimeNullableFilter | null;
  account?: AccountRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
}

export interface ExternalPaymentMethodWhereUniqueInput {
  id?: string | null;
}

export interface FeeScheduleCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  organizationId: string;
  validAfter?: any | null;
  validBefore?: any | null;
  pending?: boolean | null;
}

export interface FeeScheduleCreateManyLocationInputEnvelope {
  data: FeeScheduleCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface FeeScheduleCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  locationId: string;
  validAfter?: any | null;
  validBefore?: any | null;
  pending?: boolean | null;
}

export interface FeeScheduleCreateManyOrganizationInputEnvelope {
  data: FeeScheduleCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface FeeScheduleCreateNestedManyWithoutLocationInput {
  create?: FeeScheduleCreateWithoutLocationInput[] | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutLocationInput[] | null;
  createMany?: FeeScheduleCreateManyLocationInputEnvelope | null;
  connect?: FeeScheduleWhereUniqueInput[] | null;
}

export interface FeeScheduleCreateNestedManyWithoutOrganizationInput {
  create?: FeeScheduleCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: FeeScheduleCreateManyOrganizationInputEnvelope | null;
  connect?: FeeScheduleWhereUniqueInput[] | null;
}

export interface FeeScheduleCreateNestedOneWithoutEstimatesInput {
  create?: FeeScheduleCreateWithoutEstimatesInput | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutEstimatesInput | null;
  connect?: FeeScheduleWhereUniqueInput | null;
}

export interface FeeScheduleCreateNestedOneWithoutFeeScheduleMappingsInput {
  create?: FeeScheduleCreateWithoutFeeScheduleMappingsInput | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutFeeScheduleMappingsInput | null;
  connect?: FeeScheduleWhereUniqueInput | null;
}

export interface FeeScheduleCreateNestedOneWithoutPayerFeeSchedulesInput {
  create?: FeeScheduleCreateWithoutPayerFeeSchedulesInput | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutPayerFeeSchedulesInput | null;
  connect?: FeeScheduleWhereUniqueInput | null;
}

export interface FeeScheduleCreateNestedOneWithoutScheduledServiceFeesInput {
  create?: FeeScheduleCreateWithoutScheduledServiceFeesInput | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutScheduledServiceFeesInput | null;
  connect?: FeeScheduleWhereUniqueInput | null;
}

export interface FeeScheduleCreateOrConnectWithoutEstimatesInput {
  where: FeeScheduleWhereUniqueInput;
  create: FeeScheduleCreateWithoutEstimatesInput;
}

export interface FeeScheduleCreateOrConnectWithoutFeeScheduleMappingsInput {
  where: FeeScheduleWhereUniqueInput;
  create: FeeScheduleCreateWithoutFeeScheduleMappingsInput;
}

export interface FeeScheduleCreateOrConnectWithoutLocationInput {
  where: FeeScheduleWhereUniqueInput;
  create: FeeScheduleCreateWithoutLocationInput;
}

export interface FeeScheduleCreateOrConnectWithoutOrganizationInput {
  where: FeeScheduleWhereUniqueInput;
  create: FeeScheduleCreateWithoutOrganizationInput;
}

export interface FeeScheduleCreateOrConnectWithoutPayerFeeSchedulesInput {
  where: FeeScheduleWhereUniqueInput;
  create: FeeScheduleCreateWithoutPayerFeeSchedulesInput;
}

export interface FeeScheduleCreateOrConnectWithoutScheduledServiceFeesInput {
  where: FeeScheduleWhereUniqueInput;
  create: FeeScheduleCreateWithoutScheduledServiceFeesInput;
}

export interface FeeScheduleCreateWithoutEstimatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  validAfter?: any | null;
  validBefore?: any | null;
  pending?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutFeeSchedulesInput;
  location: LocationCreateNestedOneWithoutFeeSchedulesInput;
  scheduledServiceFees?: ScheduledServiceFeeCreateNestedManyWithoutFeeScheduleInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutFeeScheduleInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutFeeScheduleInput | null;
}

export interface FeeScheduleCreateWithoutFeeScheduleMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  validAfter?: any | null;
  validBefore?: any | null;
  pending?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutFeeSchedulesInput;
  location: LocationCreateNestedOneWithoutFeeSchedulesInput;
  scheduledServiceFees?: ScheduledServiceFeeCreateNestedManyWithoutFeeScheduleInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutFeeScheduleInput | null;
  estimates?: EstimateCreateNestedManyWithoutFeeScheduleInput | null;
}

export interface FeeScheduleCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  validAfter?: any | null;
  validBefore?: any | null;
  pending?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutFeeSchedulesInput;
  scheduledServiceFees?: ScheduledServiceFeeCreateNestedManyWithoutFeeScheduleInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutFeeScheduleInput | null;
  estimates?: EstimateCreateNestedManyWithoutFeeScheduleInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutFeeScheduleInput | null;
}

export interface FeeScheduleCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  validAfter?: any | null;
  validBefore?: any | null;
  pending?: boolean | null;
  location: LocationCreateNestedOneWithoutFeeSchedulesInput;
  scheduledServiceFees?: ScheduledServiceFeeCreateNestedManyWithoutFeeScheduleInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutFeeScheduleInput | null;
  estimates?: EstimateCreateNestedManyWithoutFeeScheduleInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutFeeScheduleInput | null;
}

export interface FeeScheduleCreateWithoutPayerFeeSchedulesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  validAfter?: any | null;
  validBefore?: any | null;
  pending?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutFeeSchedulesInput;
  location: LocationCreateNestedOneWithoutFeeSchedulesInput;
  scheduledServiceFees?: ScheduledServiceFeeCreateNestedManyWithoutFeeScheduleInput | null;
  estimates?: EstimateCreateNestedManyWithoutFeeScheduleInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutFeeScheduleInput | null;
}

export interface FeeScheduleCreateWithoutScheduledServiceFeesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  validAfter?: any | null;
  validBefore?: any | null;
  pending?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutFeeSchedulesInput;
  location: LocationCreateNestedOneWithoutFeeSchedulesInput;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutFeeScheduleInput | null;
  estimates?: EstimateCreateNestedManyWithoutFeeScheduleInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutFeeScheduleInput | null;
}

export interface FeeScheduleFeeInput {
  id?: string | null;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  allowedAmount: number;
  name: string;
  delete: boolean;
}

export interface FeeScheduleListRelationFilter {
  every?: FeeScheduleWhereInput | null;
  some?: FeeScheduleWhereInput | null;
  none?: FeeScheduleWhereInput | null;
}

export interface FeeScheduleMappingConditionCreateManyFeeScheduleMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
}

export interface FeeScheduleMappingConditionCreateManyFeeScheduleMappingInputEnvelope {
  data: FeeScheduleMappingConditionCreateManyFeeScheduleMappingInput[];
  skipDuplicates?: boolean | null;
}

export interface FeeScheduleMappingConditionCreateNestedManyWithoutFeeScheduleMappingInput {
  create?: FeeScheduleMappingConditionCreateWithoutFeeScheduleMappingInput[] | null;
  connectOrCreate?: FeeScheduleMappingConditionCreateOrConnectWithoutFeeScheduleMappingInput[] | null;
  createMany?: FeeScheduleMappingConditionCreateManyFeeScheduleMappingInputEnvelope | null;
  connect?: FeeScheduleMappingConditionWhereUniqueInput[] | null;
}

export interface FeeScheduleMappingConditionCreateOrConnectWithoutFeeScheduleMappingInput {
  where: FeeScheduleMappingConditionWhereUniqueInput;
  create: FeeScheduleMappingConditionCreateWithoutFeeScheduleMappingInput;
}

export interface FeeScheduleMappingConditionCreateWithoutFeeScheduleMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
}

export interface FeeScheduleMappingConditionListRelationFilter {
  every?: FeeScheduleMappingConditionWhereInput | null;
  some?: FeeScheduleMappingConditionWhereInput | null;
  none?: FeeScheduleMappingConditionWhereInput | null;
}

export interface FeeScheduleMappingConditionScalarWhereInput {
  AND?: FeeScheduleMappingConditionScalarWhereInput[] | null;
  OR?: FeeScheduleMappingConditionScalarWhereInput[] | null;
  NOT?: FeeScheduleMappingConditionScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  feeScheduleMappingId?: UuidFilter | null;
  attribute?: JsonFilter | null;
  comparison?: EnumComparisonFilter | null;
  attributeType?: StringFilter | null;
}

export interface FeeScheduleMappingConditionUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
}

export interface FeeScheduleMappingConditionUpdateManyWithWhereWithoutFeeScheduleMappingInput {
  where: FeeScheduleMappingConditionScalarWhereInput;
  data: FeeScheduleMappingConditionUpdateManyMutationInput;
}

export interface FeeScheduleMappingConditionUpdateManyWithoutFeeScheduleMappingNestedInput {
  create?: FeeScheduleMappingConditionCreateWithoutFeeScheduleMappingInput[] | null;
  connectOrCreate?: FeeScheduleMappingConditionCreateOrConnectWithoutFeeScheduleMappingInput[] | null;
  upsert?: FeeScheduleMappingConditionUpsertWithWhereUniqueWithoutFeeScheduleMappingInput[] | null;
  createMany?: FeeScheduleMappingConditionCreateManyFeeScheduleMappingInputEnvelope | null;
  set?: FeeScheduleMappingConditionWhereUniqueInput[] | null;
  disconnect?: FeeScheduleMappingConditionWhereUniqueInput[] | null;
  delete?: FeeScheduleMappingConditionWhereUniqueInput[] | null;
  connect?: FeeScheduleMappingConditionWhereUniqueInput[] | null;
  update?: FeeScheduleMappingConditionUpdateWithWhereUniqueWithoutFeeScheduleMappingInput[] | null;
  updateMany?: FeeScheduleMappingConditionUpdateManyWithWhereWithoutFeeScheduleMappingInput[] | null;
  deleteMany?: FeeScheduleMappingConditionScalarWhereInput[] | null;
}

export interface FeeScheduleMappingConditionUpdateWithWhereUniqueWithoutFeeScheduleMappingInput {
  where: FeeScheduleMappingConditionWhereUniqueInput;
  data: FeeScheduleMappingConditionUpdateWithoutFeeScheduleMappingInput;
}

export interface FeeScheduleMappingConditionUpdateWithoutFeeScheduleMappingInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
}

export interface FeeScheduleMappingConditionUpsertWithWhereUniqueWithoutFeeScheduleMappingInput {
  where: FeeScheduleMappingConditionWhereUniqueInput;
  update: FeeScheduleMappingConditionUpdateWithoutFeeScheduleMappingInput;
  create: FeeScheduleMappingConditionCreateWithoutFeeScheduleMappingInput;
}

export interface FeeScheduleMappingConditionWhereInput {
  AND?: FeeScheduleMappingConditionWhereInput[] | null;
  OR?: FeeScheduleMappingConditionWhereInput[] | null;
  NOT?: FeeScheduleMappingConditionWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  feeScheduleMappingId?: UuidFilter | null;
  attribute?: JsonFilter | null;
  comparison?: EnumComparisonFilter | null;
  attributeType?: StringFilter | null;
  feeScheduleMapping?: FeeScheduleMappingRelationFilter | null;
}

export interface FeeScheduleMappingConditionWhereUniqueInput {
  id?: string | null;
}

export interface FeeScheduleMappingCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedFeeScheduleMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedFeeScheduleMappingsInput;
  location: LocationCreateNestedOneWithoutFeeScheduleMappingsInput;
  feeSchedule: FeeScheduleCreateNestedOneWithoutFeeScheduleMappingsInput;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionCreateNestedManyWithoutFeeScheduleMappingInput | null;
  estimate?: EstimateCreateNestedManyWithoutFeeScheduleMappingInput | null;
}

export interface FeeScheduleMappingCreateManyCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUpdatedById: string;
  priority: number;
  locationId: string;
  feeScheduleId: string;
  name: string;
  notes?: string | null;
}

export interface FeeScheduleMappingCreateManyCreatedByInputEnvelope {
  data: FeeScheduleMappingCreateManyCreatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface FeeScheduleMappingCreateManyFeeScheduleInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  lastUpdatedById: string;
  priority: number;
  locationId: string;
  name: string;
  notes?: string | null;
}

export interface FeeScheduleMappingCreateManyFeeScheduleInputEnvelope {
  data: FeeScheduleMappingCreateManyFeeScheduleInput[];
  skipDuplicates?: boolean | null;
}

export interface FeeScheduleMappingCreateManyLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  priority: number;
  locationId: string;
  feeScheduleId: string;
  name: string;
  notes?: string | null;
}

export interface FeeScheduleMappingCreateManyLastUpdatedByInputEnvelope {
  data: FeeScheduleMappingCreateManyLastUpdatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface FeeScheduleMappingCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  lastUpdatedById: string;
  priority: number;
  feeScheduleId: string;
  name: string;
  notes?: string | null;
}

export interface FeeScheduleMappingCreateManyLocationInputEnvelope {
  data: FeeScheduleMappingCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface FeeScheduleMappingCreateNestedManyWithoutCreatedByInput {
  create?: FeeScheduleMappingCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: FeeScheduleMappingCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: FeeScheduleMappingCreateManyCreatedByInputEnvelope | null;
  connect?: FeeScheduleMappingWhereUniqueInput[] | null;
}

export interface FeeScheduleMappingCreateNestedManyWithoutFeeScheduleInput {
  create?: FeeScheduleMappingCreateWithoutFeeScheduleInput[] | null;
  connectOrCreate?: FeeScheduleMappingCreateOrConnectWithoutFeeScheduleInput[] | null;
  createMany?: FeeScheduleMappingCreateManyFeeScheduleInputEnvelope | null;
  connect?: FeeScheduleMappingWhereUniqueInput[] | null;
}

export interface FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput {
  create?: FeeScheduleMappingCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: FeeScheduleMappingCreateOrConnectWithoutLastUpdatedByInput[] | null;
  createMany?: FeeScheduleMappingCreateManyLastUpdatedByInputEnvelope | null;
  connect?: FeeScheduleMappingWhereUniqueInput[] | null;
}

export interface FeeScheduleMappingCreateNestedManyWithoutLocationInput {
  create?: FeeScheduleMappingCreateWithoutLocationInput[] | null;
  connectOrCreate?: FeeScheduleMappingCreateOrConnectWithoutLocationInput[] | null;
  createMany?: FeeScheduleMappingCreateManyLocationInputEnvelope | null;
  connect?: FeeScheduleMappingWhereUniqueInput[] | null;
}

export interface FeeScheduleMappingCreateNestedOneWithoutEstimateInput {
  create?: FeeScheduleMappingCreateWithoutEstimateInput | null;
  connectOrCreate?: FeeScheduleMappingCreateOrConnectWithoutEstimateInput | null;
  connect?: FeeScheduleMappingWhereUniqueInput | null;
}

export interface FeeScheduleMappingCreateOrConnectWithoutCreatedByInput {
  where: FeeScheduleMappingWhereUniqueInput;
  create: FeeScheduleMappingCreateWithoutCreatedByInput;
}

export interface FeeScheduleMappingCreateOrConnectWithoutEstimateInput {
  where: FeeScheduleMappingWhereUniqueInput;
  create: FeeScheduleMappingCreateWithoutEstimateInput;
}

export interface FeeScheduleMappingCreateOrConnectWithoutFeeScheduleInput {
  where: FeeScheduleMappingWhereUniqueInput;
  create: FeeScheduleMappingCreateWithoutFeeScheduleInput;
}

export interface FeeScheduleMappingCreateOrConnectWithoutLastUpdatedByInput {
  where: FeeScheduleMappingWhereUniqueInput;
  create: FeeScheduleMappingCreateWithoutLastUpdatedByInput;
}

export interface FeeScheduleMappingCreateOrConnectWithoutLocationInput {
  where: FeeScheduleMappingWhereUniqueInput;
  create: FeeScheduleMappingCreateWithoutLocationInput;
}

export interface FeeScheduleMappingCreateWithoutCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedFeeScheduleMappingsInput;
  location: LocationCreateNestedOneWithoutFeeScheduleMappingsInput;
  feeSchedule: FeeScheduleCreateNestedOneWithoutFeeScheduleMappingsInput;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionCreateNestedManyWithoutFeeScheduleMappingInput | null;
  estimate?: EstimateCreateNestedManyWithoutFeeScheduleMappingInput | null;
}

export interface FeeScheduleMappingCreateWithoutEstimateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedFeeScheduleMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedFeeScheduleMappingsInput;
  location: LocationCreateNestedOneWithoutFeeScheduleMappingsInput;
  feeSchedule: FeeScheduleCreateNestedOneWithoutFeeScheduleMappingsInput;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionCreateNestedManyWithoutFeeScheduleMappingInput | null;
}

export interface FeeScheduleMappingCreateWithoutFeeScheduleInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedFeeScheduleMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedFeeScheduleMappingsInput;
  location: LocationCreateNestedOneWithoutFeeScheduleMappingsInput;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionCreateNestedManyWithoutFeeScheduleMappingInput | null;
  estimate?: EstimateCreateNestedManyWithoutFeeScheduleMappingInput | null;
}

export interface FeeScheduleMappingCreateWithoutLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedFeeScheduleMappingsInput;
  location: LocationCreateNestedOneWithoutFeeScheduleMappingsInput;
  feeSchedule: FeeScheduleCreateNestedOneWithoutFeeScheduleMappingsInput;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionCreateNestedManyWithoutFeeScheduleMappingInput | null;
  estimate?: EstimateCreateNestedManyWithoutFeeScheduleMappingInput | null;
}

export interface FeeScheduleMappingCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedFeeScheduleMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedFeeScheduleMappingsInput;
  feeSchedule: FeeScheduleCreateNestedOneWithoutFeeScheduleMappingsInput;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionCreateNestedManyWithoutFeeScheduleMappingInput | null;
  estimate?: EstimateCreateNestedManyWithoutFeeScheduleMappingInput | null;
}

export interface FeeScheduleMappingListRelationFilter {
  every?: FeeScheduleMappingWhereInput | null;
  some?: FeeScheduleMappingWhereInput | null;
  none?: FeeScheduleMappingWhereInput | null;
}

export interface FeeScheduleMappingOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface FeeScheduleMappingRelationFilter {
  is?: FeeScheduleMappingWhereInput | null;
  isNot?: FeeScheduleMappingWhereInput | null;
}

export interface FeeScheduleMappingScalarWhereInput {
  AND?: FeeScheduleMappingScalarWhereInput[] | null;
  OR?: FeeScheduleMappingScalarWhereInput[] | null;
  NOT?: FeeScheduleMappingScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  priority?: FloatFilter | null;
  locationId?: UuidFilter | null;
  feeScheduleId?: UuidFilter | null;
  name?: StringFilter | null;
  notes?: StringNullableFilter | null;
}

export interface FeeScheduleMappingUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedFeeScheduleMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedFeeScheduleMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput | null;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionUpdateManyWithoutFeeScheduleMappingNestedInput | null;
  estimate?: EstimateUpdateManyWithoutFeeScheduleMappingNestedInput | null;
}

export interface FeeScheduleMappingUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
}

export interface FeeScheduleMappingUpdateManyWithWhereWithoutCreatedByInput {
  where: FeeScheduleMappingScalarWhereInput;
  data: FeeScheduleMappingUpdateManyMutationInput;
}

export interface FeeScheduleMappingUpdateManyWithWhereWithoutFeeScheduleInput {
  where: FeeScheduleMappingScalarWhereInput;
  data: FeeScheduleMappingUpdateManyMutationInput;
}

export interface FeeScheduleMappingUpdateManyWithWhereWithoutLastUpdatedByInput {
  where: FeeScheduleMappingScalarWhereInput;
  data: FeeScheduleMappingUpdateManyMutationInput;
}

export interface FeeScheduleMappingUpdateManyWithWhereWithoutLocationInput {
  where: FeeScheduleMappingScalarWhereInput;
  data: FeeScheduleMappingUpdateManyMutationInput;
}

export interface FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput {
  create?: FeeScheduleMappingCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: FeeScheduleMappingCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: FeeScheduleMappingUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: FeeScheduleMappingCreateManyCreatedByInputEnvelope | null;
  set?: FeeScheduleMappingWhereUniqueInput[] | null;
  disconnect?: FeeScheduleMappingWhereUniqueInput[] | null;
  delete?: FeeScheduleMappingWhereUniqueInput[] | null;
  connect?: FeeScheduleMappingWhereUniqueInput[] | null;
  update?: FeeScheduleMappingUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: FeeScheduleMappingUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: FeeScheduleMappingScalarWhereInput[] | null;
}

export interface FeeScheduleMappingUpdateManyWithoutFeeScheduleNestedInput {
  create?: FeeScheduleMappingCreateWithoutFeeScheduleInput[] | null;
  connectOrCreate?: FeeScheduleMappingCreateOrConnectWithoutFeeScheduleInput[] | null;
  upsert?: FeeScheduleMappingUpsertWithWhereUniqueWithoutFeeScheduleInput[] | null;
  createMany?: FeeScheduleMappingCreateManyFeeScheduleInputEnvelope | null;
  set?: FeeScheduleMappingWhereUniqueInput[] | null;
  disconnect?: FeeScheduleMappingWhereUniqueInput[] | null;
  delete?: FeeScheduleMappingWhereUniqueInput[] | null;
  connect?: FeeScheduleMappingWhereUniqueInput[] | null;
  update?: FeeScheduleMappingUpdateWithWhereUniqueWithoutFeeScheduleInput[] | null;
  updateMany?: FeeScheduleMappingUpdateManyWithWhereWithoutFeeScheduleInput[] | null;
  deleteMany?: FeeScheduleMappingScalarWhereInput[] | null;
}

export interface FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput {
  create?: FeeScheduleMappingCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: FeeScheduleMappingCreateOrConnectWithoutLastUpdatedByInput[] | null;
  upsert?: FeeScheduleMappingUpsertWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  createMany?: FeeScheduleMappingCreateManyLastUpdatedByInputEnvelope | null;
  set?: FeeScheduleMappingWhereUniqueInput[] | null;
  disconnect?: FeeScheduleMappingWhereUniqueInput[] | null;
  delete?: FeeScheduleMappingWhereUniqueInput[] | null;
  connect?: FeeScheduleMappingWhereUniqueInput[] | null;
  update?: FeeScheduleMappingUpdateWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  updateMany?: FeeScheduleMappingUpdateManyWithWhereWithoutLastUpdatedByInput[] | null;
  deleteMany?: FeeScheduleMappingScalarWhereInput[] | null;
}

export interface FeeScheduleMappingUpdateManyWithoutLocationNestedInput {
  create?: FeeScheduleMappingCreateWithoutLocationInput[] | null;
  connectOrCreate?: FeeScheduleMappingCreateOrConnectWithoutLocationInput[] | null;
  upsert?: FeeScheduleMappingUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: FeeScheduleMappingCreateManyLocationInputEnvelope | null;
  set?: FeeScheduleMappingWhereUniqueInput[] | null;
  disconnect?: FeeScheduleMappingWhereUniqueInput[] | null;
  delete?: FeeScheduleMappingWhereUniqueInput[] | null;
  connect?: FeeScheduleMappingWhereUniqueInput[] | null;
  update?: FeeScheduleMappingUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: FeeScheduleMappingUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: FeeScheduleMappingScalarWhereInput[] | null;
}

export interface FeeScheduleMappingUpdateOneWithoutEstimateNestedInput {
  create?: FeeScheduleMappingCreateWithoutEstimateInput | null;
  connectOrCreate?: FeeScheduleMappingCreateOrConnectWithoutEstimateInput | null;
  upsert?: FeeScheduleMappingUpsertWithoutEstimateInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: FeeScheduleMappingWhereUniqueInput | null;
  update?: FeeScheduleMappingUpdateWithoutEstimateInput | null;
}

export interface FeeScheduleMappingUpdateWithWhereUniqueWithoutCreatedByInput {
  where: FeeScheduleMappingWhereUniqueInput;
  data: FeeScheduleMappingUpdateWithoutCreatedByInput;
}

export interface FeeScheduleMappingUpdateWithWhereUniqueWithoutFeeScheduleInput {
  where: FeeScheduleMappingWhereUniqueInput;
  data: FeeScheduleMappingUpdateWithoutFeeScheduleInput;
}

export interface FeeScheduleMappingUpdateWithWhereUniqueWithoutLastUpdatedByInput {
  where: FeeScheduleMappingWhereUniqueInput;
  data: FeeScheduleMappingUpdateWithoutLastUpdatedByInput;
}

export interface FeeScheduleMappingUpdateWithWhereUniqueWithoutLocationInput {
  where: FeeScheduleMappingWhereUniqueInput;
  data: FeeScheduleMappingUpdateWithoutLocationInput;
}

export interface FeeScheduleMappingUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedFeeScheduleMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput | null;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionUpdateManyWithoutFeeScheduleMappingNestedInput | null;
  estimate?: EstimateUpdateManyWithoutFeeScheduleMappingNestedInput | null;
}

export interface FeeScheduleMappingUpdateWithoutEstimateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedFeeScheduleMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedFeeScheduleMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput | null;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionUpdateManyWithoutFeeScheduleMappingNestedInput | null;
}

export interface FeeScheduleMappingUpdateWithoutFeeScheduleInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedFeeScheduleMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedFeeScheduleMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput | null;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionUpdateManyWithoutFeeScheduleMappingNestedInput | null;
  estimate?: EstimateUpdateManyWithoutFeeScheduleMappingNestedInput | null;
}

export interface FeeScheduleMappingUpdateWithoutLastUpdatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedFeeScheduleMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput | null;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionUpdateManyWithoutFeeScheduleMappingNestedInput | null;
  estimate?: EstimateUpdateManyWithoutFeeScheduleMappingNestedInput | null;
}

export interface FeeScheduleMappingUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedFeeScheduleMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedFeeScheduleMappingsNestedInput | null;
  feeSchedule?: FeeScheduleUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput | null;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionUpdateManyWithoutFeeScheduleMappingNestedInput | null;
  estimate?: EstimateUpdateManyWithoutFeeScheduleMappingNestedInput | null;
}

export interface FeeScheduleMappingUpsertWithWhereUniqueWithoutCreatedByInput {
  where: FeeScheduleMappingWhereUniqueInput;
  update: FeeScheduleMappingUpdateWithoutCreatedByInput;
  create: FeeScheduleMappingCreateWithoutCreatedByInput;
}

export interface FeeScheduleMappingUpsertWithWhereUniqueWithoutFeeScheduleInput {
  where: FeeScheduleMappingWhereUniqueInput;
  update: FeeScheduleMappingUpdateWithoutFeeScheduleInput;
  create: FeeScheduleMappingCreateWithoutFeeScheduleInput;
}

export interface FeeScheduleMappingUpsertWithWhereUniqueWithoutLastUpdatedByInput {
  where: FeeScheduleMappingWhereUniqueInput;
  update: FeeScheduleMappingUpdateWithoutLastUpdatedByInput;
  create: FeeScheduleMappingCreateWithoutLastUpdatedByInput;
}

export interface FeeScheduleMappingUpsertWithWhereUniqueWithoutLocationInput {
  where: FeeScheduleMappingWhereUniqueInput;
  update: FeeScheduleMappingUpdateWithoutLocationInput;
  create: FeeScheduleMappingCreateWithoutLocationInput;
}

export interface FeeScheduleMappingUpsertWithoutEstimateInput {
  update: FeeScheduleMappingUpdateWithoutEstimateInput;
  create: FeeScheduleMappingCreateWithoutEstimateInput;
}

export interface FeeScheduleMappingWhereInput {
  AND?: FeeScheduleMappingWhereInput[] | null;
  OR?: FeeScheduleMappingWhereInput[] | null;
  NOT?: FeeScheduleMappingWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  priority?: FloatFilter | null;
  locationId?: UuidFilter | null;
  feeScheduleId?: UuidFilter | null;
  name?: StringFilter | null;
  notes?: StringNullableFilter | null;
  createdBy?: UserRelationFilter | null;
  lastUpdatedBy?: UserRelationFilter | null;
  location?: LocationRelationFilter | null;
  feeSchedule?: FeeScheduleRelationFilter | null;
  feeScheduleMappingConditions?: FeeScheduleMappingConditionListRelationFilter | null;
  estimate?: EstimateListRelationFilter | null;
}

export interface FeeScheduleMappingWhereUniqueInput {
  id?: string | null;
}

export interface FeeScheduleOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface FeeScheduleRelationFilter {
  is?: FeeScheduleWhereInput | null;
  isNot?: FeeScheduleWhereInput | null;
}

export interface FeeScheduleScalarWhereInput {
  AND?: FeeScheduleScalarWhereInput[] | null;
  OR?: FeeScheduleScalarWhereInput[] | null;
  NOT?: FeeScheduleScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  validAfter?: DateTimeNullableFilter | null;
  validBefore?: DateTimeNullableFilter | null;
  pending?: BoolFilter | null;
}

export interface FeeScheduleUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  validAfter?: NullableDateTimeFieldUpdateOperationsInput | null;
  validBefore?: NullableDateTimeFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
}

export interface FeeScheduleUpdateManyWithWhereWithoutLocationInput {
  where: FeeScheduleScalarWhereInput;
  data: FeeScheduleUpdateManyMutationInput;
}

export interface FeeScheduleUpdateManyWithWhereWithoutOrganizationInput {
  where: FeeScheduleScalarWhereInput;
  data: FeeScheduleUpdateManyMutationInput;
}

export interface FeeScheduleUpdateManyWithoutLocationNestedInput {
  create?: FeeScheduleCreateWithoutLocationInput[] | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutLocationInput[] | null;
  upsert?: FeeScheduleUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: FeeScheduleCreateManyLocationInputEnvelope | null;
  set?: FeeScheduleWhereUniqueInput[] | null;
  disconnect?: FeeScheduleWhereUniqueInput[] | null;
  delete?: FeeScheduleWhereUniqueInput[] | null;
  connect?: FeeScheduleWhereUniqueInput[] | null;
  update?: FeeScheduleUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: FeeScheduleUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: FeeScheduleScalarWhereInput[] | null;
}

export interface FeeScheduleUpdateManyWithoutOrganizationNestedInput {
  create?: FeeScheduleCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: FeeScheduleUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: FeeScheduleCreateManyOrganizationInputEnvelope | null;
  set?: FeeScheduleWhereUniqueInput[] | null;
  disconnect?: FeeScheduleWhereUniqueInput[] | null;
  delete?: FeeScheduleWhereUniqueInput[] | null;
  connect?: FeeScheduleWhereUniqueInput[] | null;
  update?: FeeScheduleUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: FeeScheduleUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: FeeScheduleScalarWhereInput[] | null;
}

export interface FeeScheduleUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput {
  create?: FeeScheduleCreateWithoutFeeScheduleMappingsInput | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutFeeScheduleMappingsInput | null;
  upsert?: FeeScheduleUpsertWithoutFeeScheduleMappingsInput | null;
  connect?: FeeScheduleWhereUniqueInput | null;
  update?: FeeScheduleUpdateWithoutFeeScheduleMappingsInput | null;
}

export interface FeeScheduleUpdateOneRequiredWithoutPayerFeeSchedulesNestedInput {
  create?: FeeScheduleCreateWithoutPayerFeeSchedulesInput | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutPayerFeeSchedulesInput | null;
  upsert?: FeeScheduleUpsertWithoutPayerFeeSchedulesInput | null;
  connect?: FeeScheduleWhereUniqueInput | null;
  update?: FeeScheduleUpdateWithoutPayerFeeSchedulesInput | null;
}

export interface FeeScheduleUpdateOneRequiredWithoutScheduledServiceFeesNestedInput {
  create?: FeeScheduleCreateWithoutScheduledServiceFeesInput | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutScheduledServiceFeesInput | null;
  upsert?: FeeScheduleUpsertWithoutScheduledServiceFeesInput | null;
  connect?: FeeScheduleWhereUniqueInput | null;
  update?: FeeScheduleUpdateWithoutScheduledServiceFeesInput | null;
}

export interface FeeScheduleUpdateOneWithoutEstimatesNestedInput {
  create?: FeeScheduleCreateWithoutEstimatesInput | null;
  connectOrCreate?: FeeScheduleCreateOrConnectWithoutEstimatesInput | null;
  upsert?: FeeScheduleUpsertWithoutEstimatesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: FeeScheduleWhereUniqueInput | null;
  update?: FeeScheduleUpdateWithoutEstimatesInput | null;
}

export interface FeeScheduleUpdateWithWhereUniqueWithoutLocationInput {
  where: FeeScheduleWhereUniqueInput;
  data: FeeScheduleUpdateWithoutLocationInput;
}

export interface FeeScheduleUpdateWithWhereUniqueWithoutOrganizationInput {
  where: FeeScheduleWhereUniqueInput;
  data: FeeScheduleUpdateWithoutOrganizationInput;
}

export interface FeeScheduleUpdateWithoutEstimatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  validAfter?: NullableDateTimeFieldUpdateOperationsInput | null;
  validBefore?: NullableDateTimeFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutFeeSchedulesNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutFeeSchedulesNestedInput | null;
  scheduledServiceFees?: ScheduledServiceFeeUpdateManyWithoutFeeScheduleNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutFeeScheduleNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutFeeScheduleNestedInput | null;
}

export interface FeeScheduleUpdateWithoutFeeScheduleMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  validAfter?: NullableDateTimeFieldUpdateOperationsInput | null;
  validBefore?: NullableDateTimeFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutFeeSchedulesNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutFeeSchedulesNestedInput | null;
  scheduledServiceFees?: ScheduledServiceFeeUpdateManyWithoutFeeScheduleNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutFeeScheduleNestedInput | null;
  estimates?: EstimateUpdateManyWithoutFeeScheduleNestedInput | null;
}

export interface FeeScheduleUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  validAfter?: NullableDateTimeFieldUpdateOperationsInput | null;
  validBefore?: NullableDateTimeFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutFeeSchedulesNestedInput | null;
  scheduledServiceFees?: ScheduledServiceFeeUpdateManyWithoutFeeScheduleNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutFeeScheduleNestedInput | null;
  estimates?: EstimateUpdateManyWithoutFeeScheduleNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutFeeScheduleNestedInput | null;
}

export interface FeeScheduleUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  validAfter?: NullableDateTimeFieldUpdateOperationsInput | null;
  validBefore?: NullableDateTimeFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutFeeSchedulesNestedInput | null;
  scheduledServiceFees?: ScheduledServiceFeeUpdateManyWithoutFeeScheduleNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutFeeScheduleNestedInput | null;
  estimates?: EstimateUpdateManyWithoutFeeScheduleNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutFeeScheduleNestedInput | null;
}

export interface FeeScheduleUpdateWithoutPayerFeeSchedulesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  validAfter?: NullableDateTimeFieldUpdateOperationsInput | null;
  validBefore?: NullableDateTimeFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutFeeSchedulesNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutFeeSchedulesNestedInput | null;
  scheduledServiceFees?: ScheduledServiceFeeUpdateManyWithoutFeeScheduleNestedInput | null;
  estimates?: EstimateUpdateManyWithoutFeeScheduleNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutFeeScheduleNestedInput | null;
}

export interface FeeScheduleUpdateWithoutScheduledServiceFeesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  validAfter?: NullableDateTimeFieldUpdateOperationsInput | null;
  validBefore?: NullableDateTimeFieldUpdateOperationsInput | null;
  pending?: BoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutFeeSchedulesNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutFeeSchedulesNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutFeeScheduleNestedInput | null;
  estimates?: EstimateUpdateManyWithoutFeeScheduleNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutFeeScheduleNestedInput | null;
}

export interface FeeScheduleUpsertWithWhereUniqueWithoutLocationInput {
  where: FeeScheduleWhereUniqueInput;
  update: FeeScheduleUpdateWithoutLocationInput;
  create: FeeScheduleCreateWithoutLocationInput;
}

export interface FeeScheduleUpsertWithWhereUniqueWithoutOrganizationInput {
  where: FeeScheduleWhereUniqueInput;
  update: FeeScheduleUpdateWithoutOrganizationInput;
  create: FeeScheduleCreateWithoutOrganizationInput;
}

export interface FeeScheduleUpsertWithoutEstimatesInput {
  update: FeeScheduleUpdateWithoutEstimatesInput;
  create: FeeScheduleCreateWithoutEstimatesInput;
}

export interface FeeScheduleUpsertWithoutFeeScheduleMappingsInput {
  update: FeeScheduleUpdateWithoutFeeScheduleMappingsInput;
  create: FeeScheduleCreateWithoutFeeScheduleMappingsInput;
}

export interface FeeScheduleUpsertWithoutPayerFeeSchedulesInput {
  update: FeeScheduleUpdateWithoutPayerFeeSchedulesInput;
  create: FeeScheduleCreateWithoutPayerFeeSchedulesInput;
}

export interface FeeScheduleUpsertWithoutScheduledServiceFeesInput {
  update: FeeScheduleUpdateWithoutScheduledServiceFeesInput;
  create: FeeScheduleCreateWithoutScheduledServiceFeesInput;
}

export interface FeeScheduleWhereInput {
  AND?: FeeScheduleWhereInput[] | null;
  OR?: FeeScheduleWhereInput[] | null;
  NOT?: FeeScheduleWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  validAfter?: DateTimeNullableFilter | null;
  validBefore?: DateTimeNullableFilter | null;
  pending?: BoolFilter | null;
  organization?: OrganizationRelationFilter | null;
  location?: LocationRelationFilter | null;
  scheduledServiceFees?: ScheduledServiceFeeListRelationFilter | null;
  payerFeeSchedules?: PayerFeeSchedulesListRelationFilter | null;
  estimates?: EstimateListRelationFilter | null;
  feeScheduleMappings?: FeeScheduleMappingListRelationFilter | null;
}

export interface FeeScheduleWhereUniqueInput {
  id?: string | null;
}

export interface FeedbackCreateManyPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  rating?: number | null;
  comment?: string | null;
  type: FeedbackType;
  patientUserId?: string | null;
  userId?: string | null;
}

export interface FeedbackCreateManyPatientInputEnvelope {
  data: FeedbackCreateManyPatientInput[];
  skipDuplicates?: boolean | null;
}

export interface FeedbackCreateManyPatientUserInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  rating?: number | null;
  comment?: string | null;
  type: FeedbackType;
  userId?: string | null;
  patientId?: string | null;
}

export interface FeedbackCreateManyPatientUserInputEnvelope {
  data: FeedbackCreateManyPatientUserInput[];
  skipDuplicates?: boolean | null;
}

export interface FeedbackCreateManyUserInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  rating?: number | null;
  comment?: string | null;
  type: FeedbackType;
  patientUserId?: string | null;
  patientId?: string | null;
}

export interface FeedbackCreateManyUserInputEnvelope {
  data: FeedbackCreateManyUserInput[];
  skipDuplicates?: boolean | null;
}

export interface FeedbackCreateNestedManyWithoutPatientInput {
  create?: FeedbackCreateWithoutPatientInput[] | null;
  connectOrCreate?: FeedbackCreateOrConnectWithoutPatientInput[] | null;
  createMany?: FeedbackCreateManyPatientInputEnvelope | null;
  connect?: FeedbackWhereUniqueInput[] | null;
}

export interface FeedbackCreateNestedManyWithoutPatientUserInput {
  create?: FeedbackCreateWithoutPatientUserInput[] | null;
  connectOrCreate?: FeedbackCreateOrConnectWithoutPatientUserInput[] | null;
  createMany?: FeedbackCreateManyPatientUserInputEnvelope | null;
  connect?: FeedbackWhereUniqueInput[] | null;
}

export interface FeedbackCreateNestedManyWithoutUserInput {
  create?: FeedbackCreateWithoutUserInput[] | null;
  connectOrCreate?: FeedbackCreateOrConnectWithoutUserInput[] | null;
  createMany?: FeedbackCreateManyUserInputEnvelope | null;
  connect?: FeedbackWhereUniqueInput[] | null;
}

export interface FeedbackCreateOrConnectWithoutPatientInput {
  where: FeedbackWhereUniqueInput;
  create: FeedbackCreateWithoutPatientInput;
}

export interface FeedbackCreateOrConnectWithoutPatientUserInput {
  where: FeedbackWhereUniqueInput;
  create: FeedbackCreateWithoutPatientUserInput;
}

export interface FeedbackCreateOrConnectWithoutUserInput {
  where: FeedbackWhereUniqueInput;
  create: FeedbackCreateWithoutUserInput;
}

export interface FeedbackCreateWithoutPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  rating?: number | null;
  comment?: string | null;
  type: FeedbackType;
  patientUser?: PatientUserCreateNestedOneWithoutFeedbacksInput | null;
  user?: UserCreateNestedOneWithoutFeedbacksInput | null;
}

export interface FeedbackCreateWithoutPatientUserInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  rating?: number | null;
  comment?: string | null;
  type: FeedbackType;
  user?: UserCreateNestedOneWithoutFeedbacksInput | null;
  patient?: PatientCreateNestedOneWithoutFeedbacksInput | null;
}

export interface FeedbackCreateWithoutUserInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  rating?: number | null;
  comment?: string | null;
  type: FeedbackType;
  patientUser?: PatientUserCreateNestedOneWithoutFeedbacksInput | null;
  patient?: PatientCreateNestedOneWithoutFeedbacksInput | null;
}

export interface FeedbackListRelationFilter {
  every?: FeedbackWhereInput | null;
  some?: FeedbackWhereInput | null;
  none?: FeedbackWhereInput | null;
}

export interface FeedbackOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface FeedbackScalarWhereInput {
  AND?: FeedbackScalarWhereInput[] | null;
  OR?: FeedbackScalarWhereInput[] | null;
  NOT?: FeedbackScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  rating?: IntNullableFilter | null;
  comment?: StringNullableFilter | null;
  type?: EnumFeedbackTypeFilter | null;
  patientUserId?: UuidNullableFilter | null;
  userId?: UuidNullableFilter | null;
  patientId?: UuidNullableFilter | null;
}

export interface FeedbackUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  rating?: NullableIntFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  type?: EnumFeedbackTypeFieldUpdateOperationsInput | null;
}

export interface FeedbackUpdateManyWithWhereWithoutPatientInput {
  where: FeedbackScalarWhereInput;
  data: FeedbackUpdateManyMutationInput;
}

export interface FeedbackUpdateManyWithWhereWithoutPatientUserInput {
  where: FeedbackScalarWhereInput;
  data: FeedbackUpdateManyMutationInput;
}

export interface FeedbackUpdateManyWithWhereWithoutUserInput {
  where: FeedbackScalarWhereInput;
  data: FeedbackUpdateManyMutationInput;
}

export interface FeedbackUpdateManyWithoutPatientNestedInput {
  create?: FeedbackCreateWithoutPatientInput[] | null;
  connectOrCreate?: FeedbackCreateOrConnectWithoutPatientInput[] | null;
  upsert?: FeedbackUpsertWithWhereUniqueWithoutPatientInput[] | null;
  createMany?: FeedbackCreateManyPatientInputEnvelope | null;
  set?: FeedbackWhereUniqueInput[] | null;
  disconnect?: FeedbackWhereUniqueInput[] | null;
  delete?: FeedbackWhereUniqueInput[] | null;
  connect?: FeedbackWhereUniqueInput[] | null;
  update?: FeedbackUpdateWithWhereUniqueWithoutPatientInput[] | null;
  updateMany?: FeedbackUpdateManyWithWhereWithoutPatientInput[] | null;
  deleteMany?: FeedbackScalarWhereInput[] | null;
}

export interface FeedbackUpdateManyWithoutPatientUserNestedInput {
  create?: FeedbackCreateWithoutPatientUserInput[] | null;
  connectOrCreate?: FeedbackCreateOrConnectWithoutPatientUserInput[] | null;
  upsert?: FeedbackUpsertWithWhereUniqueWithoutPatientUserInput[] | null;
  createMany?: FeedbackCreateManyPatientUserInputEnvelope | null;
  set?: FeedbackWhereUniqueInput[] | null;
  disconnect?: FeedbackWhereUniqueInput[] | null;
  delete?: FeedbackWhereUniqueInput[] | null;
  connect?: FeedbackWhereUniqueInput[] | null;
  update?: FeedbackUpdateWithWhereUniqueWithoutPatientUserInput[] | null;
  updateMany?: FeedbackUpdateManyWithWhereWithoutPatientUserInput[] | null;
  deleteMany?: FeedbackScalarWhereInput[] | null;
}

export interface FeedbackUpdateManyWithoutUserNestedInput {
  create?: FeedbackCreateWithoutUserInput[] | null;
  connectOrCreate?: FeedbackCreateOrConnectWithoutUserInput[] | null;
  upsert?: FeedbackUpsertWithWhereUniqueWithoutUserInput[] | null;
  createMany?: FeedbackCreateManyUserInputEnvelope | null;
  set?: FeedbackWhereUniqueInput[] | null;
  disconnect?: FeedbackWhereUniqueInput[] | null;
  delete?: FeedbackWhereUniqueInput[] | null;
  connect?: FeedbackWhereUniqueInput[] | null;
  update?: FeedbackUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: FeedbackUpdateManyWithWhereWithoutUserInput[] | null;
  deleteMany?: FeedbackScalarWhereInput[] | null;
}

export interface FeedbackUpdateWithWhereUniqueWithoutPatientInput {
  where: FeedbackWhereUniqueInput;
  data: FeedbackUpdateWithoutPatientInput;
}

export interface FeedbackUpdateWithWhereUniqueWithoutPatientUserInput {
  where: FeedbackWhereUniqueInput;
  data: FeedbackUpdateWithoutPatientUserInput;
}

export interface FeedbackUpdateWithWhereUniqueWithoutUserInput {
  where: FeedbackWhereUniqueInput;
  data: FeedbackUpdateWithoutUserInput;
}

export interface FeedbackUpdateWithoutPatientInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  rating?: NullableIntFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  type?: EnumFeedbackTypeFieldUpdateOperationsInput | null;
  patientUser?: PatientUserUpdateOneWithoutFeedbacksNestedInput | null;
  user?: UserUpdateOneWithoutFeedbacksNestedInput | null;
}

export interface FeedbackUpdateWithoutPatientUserInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  rating?: NullableIntFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  type?: EnumFeedbackTypeFieldUpdateOperationsInput | null;
  user?: UserUpdateOneWithoutFeedbacksNestedInput | null;
  patient?: PatientUpdateOneWithoutFeedbacksNestedInput | null;
}

export interface FeedbackUpdateWithoutUserInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  rating?: NullableIntFieldUpdateOperationsInput | null;
  comment?: NullableStringFieldUpdateOperationsInput | null;
  type?: EnumFeedbackTypeFieldUpdateOperationsInput | null;
  patientUser?: PatientUserUpdateOneWithoutFeedbacksNestedInput | null;
  patient?: PatientUpdateOneWithoutFeedbacksNestedInput | null;
}

export interface FeedbackUpsertWithWhereUniqueWithoutPatientInput {
  where: FeedbackWhereUniqueInput;
  update: FeedbackUpdateWithoutPatientInput;
  create: FeedbackCreateWithoutPatientInput;
}

export interface FeedbackUpsertWithWhereUniqueWithoutPatientUserInput {
  where: FeedbackWhereUniqueInput;
  update: FeedbackUpdateWithoutPatientUserInput;
  create: FeedbackCreateWithoutPatientUserInput;
}

export interface FeedbackUpsertWithWhereUniqueWithoutUserInput {
  where: FeedbackWhereUniqueInput;
  update: FeedbackUpdateWithoutUserInput;
  create: FeedbackCreateWithoutUserInput;
}

export interface FeedbackWhereInput {
  AND?: FeedbackWhereInput[] | null;
  OR?: FeedbackWhereInput[] | null;
  NOT?: FeedbackWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  rating?: IntNullableFilter | null;
  comment?: StringNullableFilter | null;
  type?: EnumFeedbackTypeFilter | null;
  patientUserId?: UuidNullableFilter | null;
  userId?: UuidNullableFilter | null;
  patientId?: UuidNullableFilter | null;
  patientUser?: PatientUserRelationFilter | null;
  user?: UserRelationFilter | null;
  patient?: PatientRelationFilter | null;
}

export interface FeedbackWhereUniqueInput {
  id?: string | null;
}

export interface FindOrganizationPatientMatchesInput {
  firstName: string;
  lastName: string;
  dateOfBirth: any;
}

export interface FloatFieldUpdateOperationsInput {
  set?: number | null;
  increment?: number | null;
  decrement?: number | null;
  multiply?: number | null;
  divide?: number | null;
}

export interface FloatFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatFilter | null;
}

export interface FloatNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatNullableFilter | null;
}

export interface InsuranceBillableChargeCreateManyAccountCoverageInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: ChargeInsuranceState;
  billedAt?: any | null;
  paidAt?: any | null;
  chargeId: string;
}

export interface InsuranceBillableChargeCreateManyAccountCoverageInputEnvelope {
  data: InsuranceBillableChargeCreateManyAccountCoverageInput[];
  skipDuplicates?: boolean | null;
}

export interface InsuranceBillableChargeCreateManyChargeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: ChargeInsuranceState;
  billedAt?: any | null;
  paidAt?: any | null;
  accountCoverageId?: string | null;
}

export interface InsuranceBillableChargeCreateManyChargeInputEnvelope {
  data: InsuranceBillableChargeCreateManyChargeInput[];
  skipDuplicates?: boolean | null;
}

export interface InsuranceBillableChargeCreateNestedManyWithoutAccountCoverageInput {
  create?: InsuranceBillableChargeCreateWithoutAccountCoverageInput[] | null;
  connectOrCreate?: InsuranceBillableChargeCreateOrConnectWithoutAccountCoverageInput[] | null;
  createMany?: InsuranceBillableChargeCreateManyAccountCoverageInputEnvelope | null;
  connect?: InsuranceBillableChargeWhereUniqueInput[] | null;
}

export interface InsuranceBillableChargeCreateNestedManyWithoutChargeInput {
  create?: InsuranceBillableChargeCreateWithoutChargeInput[] | null;
  connectOrCreate?: InsuranceBillableChargeCreateOrConnectWithoutChargeInput[] | null;
  createMany?: InsuranceBillableChargeCreateManyChargeInputEnvelope | null;
  connect?: InsuranceBillableChargeWhereUniqueInput[] | null;
}

export interface InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput {
  create?: InsuranceBillableChargeCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: InsuranceBillableChargeCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: InsuranceBillableChargeWhereUniqueInput | null;
}

export interface InsuranceBillableChargeCreateOrConnectWithoutAccountCoverageInput {
  where: InsuranceBillableChargeWhereUniqueInput;
  create: InsuranceBillableChargeCreateWithoutAccountCoverageInput;
}

export interface InsuranceBillableChargeCreateOrConnectWithoutChargeInput {
  where: InsuranceBillableChargeWhereUniqueInput;
  create: InsuranceBillableChargeCreateWithoutChargeInput;
}

export interface InsuranceBillableChargeCreateOrConnectWithoutIntegrationLinksInput {
  where: InsuranceBillableChargeWhereUniqueInput;
  create: InsuranceBillableChargeCreateWithoutIntegrationLinksInput;
}

export interface InsuranceBillableChargeCreateWithoutAccountCoverageInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: ChargeInsuranceState;
  billedAt?: any | null;
  paidAt?: any | null;
  charge: ChargeCreateNestedOneWithoutInsuranceBillableChargesInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutInsuranceBillableChargeInput | null;
}

export interface InsuranceBillableChargeCreateWithoutChargeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: ChargeInsuranceState;
  billedAt?: any | null;
  paidAt?: any | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutInsuranceBillableChargesInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutInsuranceBillableChargeInput | null;
}

export interface InsuranceBillableChargeCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: ChargeInsuranceState;
  billedAt?: any | null;
  paidAt?: any | null;
  charge: ChargeCreateNestedOneWithoutInsuranceBillableChargesInput;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutInsuranceBillableChargesInput | null;
}

export interface InsuranceBillableChargeListRelationFilter {
  every?: InsuranceBillableChargeWhereInput | null;
  some?: InsuranceBillableChargeWhereInput | null;
  none?: InsuranceBillableChargeWhereInput | null;
}

export interface InsuranceBillableChargeRelationFilter {
  is?: InsuranceBillableChargeWhereInput | null;
  isNot?: InsuranceBillableChargeWhereInput | null;
}

export interface InsuranceBillableChargeScalarWhereInput {
  AND?: InsuranceBillableChargeScalarWhereInput[] | null;
  OR?: InsuranceBillableChargeScalarWhereInput[] | null;
  NOT?: InsuranceBillableChargeScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  status?: EnumChargeInsuranceStateFilter | null;
  billedAt?: DateTimeNullableFilter | null;
  paidAt?: DateTimeNullableFilter | null;
  chargeId?: UuidFilter | null;
  accountCoverageId?: UuidNullableFilter | null;
}

export interface InsuranceBillableChargeUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumChargeInsuranceStateFieldUpdateOperationsInput | null;
  billedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
}

export interface InsuranceBillableChargeUpdateManyWithWhereWithoutAccountCoverageInput {
  where: InsuranceBillableChargeScalarWhereInput;
  data: InsuranceBillableChargeUpdateManyMutationInput;
}

export interface InsuranceBillableChargeUpdateManyWithWhereWithoutChargeInput {
  where: InsuranceBillableChargeScalarWhereInput;
  data: InsuranceBillableChargeUpdateManyMutationInput;
}

export interface InsuranceBillableChargeUpdateManyWithoutAccountCoverageNestedInput {
  create?: InsuranceBillableChargeCreateWithoutAccountCoverageInput[] | null;
  connectOrCreate?: InsuranceBillableChargeCreateOrConnectWithoutAccountCoverageInput[] | null;
  upsert?: InsuranceBillableChargeUpsertWithWhereUniqueWithoutAccountCoverageInput[] | null;
  createMany?: InsuranceBillableChargeCreateManyAccountCoverageInputEnvelope | null;
  set?: InsuranceBillableChargeWhereUniqueInput[] | null;
  disconnect?: InsuranceBillableChargeWhereUniqueInput[] | null;
  delete?: InsuranceBillableChargeWhereUniqueInput[] | null;
  connect?: InsuranceBillableChargeWhereUniqueInput[] | null;
  update?: InsuranceBillableChargeUpdateWithWhereUniqueWithoutAccountCoverageInput[] | null;
  updateMany?: InsuranceBillableChargeUpdateManyWithWhereWithoutAccountCoverageInput[] | null;
  deleteMany?: InsuranceBillableChargeScalarWhereInput[] | null;
}

export interface InsuranceBillableChargeUpdateManyWithoutChargeNestedInput {
  create?: InsuranceBillableChargeCreateWithoutChargeInput[] | null;
  connectOrCreate?: InsuranceBillableChargeCreateOrConnectWithoutChargeInput[] | null;
  upsert?: InsuranceBillableChargeUpsertWithWhereUniqueWithoutChargeInput[] | null;
  createMany?: InsuranceBillableChargeCreateManyChargeInputEnvelope | null;
  set?: InsuranceBillableChargeWhereUniqueInput[] | null;
  disconnect?: InsuranceBillableChargeWhereUniqueInput[] | null;
  delete?: InsuranceBillableChargeWhereUniqueInput[] | null;
  connect?: InsuranceBillableChargeWhereUniqueInput[] | null;
  update?: InsuranceBillableChargeUpdateWithWhereUniqueWithoutChargeInput[] | null;
  updateMany?: InsuranceBillableChargeUpdateManyWithWhereWithoutChargeInput[] | null;
  deleteMany?: InsuranceBillableChargeScalarWhereInput[] | null;
}

export interface InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput {
  create?: InsuranceBillableChargeCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: InsuranceBillableChargeCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: InsuranceBillableChargeUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: InsuranceBillableChargeWhereUniqueInput | null;
  update?: InsuranceBillableChargeUpdateWithoutIntegrationLinksInput | null;
}

export interface InsuranceBillableChargeUpdateWithWhereUniqueWithoutAccountCoverageInput {
  where: InsuranceBillableChargeWhereUniqueInput;
  data: InsuranceBillableChargeUpdateWithoutAccountCoverageInput;
}

export interface InsuranceBillableChargeUpdateWithWhereUniqueWithoutChargeInput {
  where: InsuranceBillableChargeWhereUniqueInput;
  data: InsuranceBillableChargeUpdateWithoutChargeInput;
}

export interface InsuranceBillableChargeUpdateWithoutAccountCoverageInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumChargeInsuranceStateFieldUpdateOperationsInput | null;
  billedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  charge?: ChargeUpdateOneRequiredWithoutInsuranceBillableChargesNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutInsuranceBillableChargeNestedInput | null;
}

export interface InsuranceBillableChargeUpdateWithoutChargeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumChargeInsuranceStateFieldUpdateOperationsInput | null;
  billedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutInsuranceBillableChargesNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutInsuranceBillableChargeNestedInput | null;
}

export interface InsuranceBillableChargeUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumChargeInsuranceStateFieldUpdateOperationsInput | null;
  billedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  paidAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  charge?: ChargeUpdateOneRequiredWithoutInsuranceBillableChargesNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutInsuranceBillableChargesNestedInput | null;
}

export interface InsuranceBillableChargeUpsertWithWhereUniqueWithoutAccountCoverageInput {
  where: InsuranceBillableChargeWhereUniqueInput;
  update: InsuranceBillableChargeUpdateWithoutAccountCoverageInput;
  create: InsuranceBillableChargeCreateWithoutAccountCoverageInput;
}

export interface InsuranceBillableChargeUpsertWithWhereUniqueWithoutChargeInput {
  where: InsuranceBillableChargeWhereUniqueInput;
  update: InsuranceBillableChargeUpdateWithoutChargeInput;
  create: InsuranceBillableChargeCreateWithoutChargeInput;
}

export interface InsuranceBillableChargeUpsertWithoutIntegrationLinksInput {
  update: InsuranceBillableChargeUpdateWithoutIntegrationLinksInput;
  create: InsuranceBillableChargeCreateWithoutIntegrationLinksInput;
}

export interface InsuranceBillableChargeWhereInput {
  AND?: InsuranceBillableChargeWhereInput[] | null;
  OR?: InsuranceBillableChargeWhereInput[] | null;
  NOT?: InsuranceBillableChargeWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  status?: EnumChargeInsuranceStateFilter | null;
  billedAt?: DateTimeNullableFilter | null;
  paidAt?: DateTimeNullableFilter | null;
  chargeId?: UuidFilter | null;
  accountCoverageId?: UuidNullableFilter | null;
  charge?: ChargeRelationFilter | null;
  accountCoverage?: AccountCoverageRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
}

export interface InsuranceBillableChargeWhereUniqueInput {
  id?: string | null;
}

export interface InsurancePolicyCreateManyAcceptedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientId: string;
  organizationId: string;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  payerId: string;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  planId?: string | null;
  customPatientNote?: string | null;
  planTypeId?: string | null;
  verificationWorkflowStatusId: string;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
}

export interface InsurancePolicyCreateManyAcceptedByInputEnvelope {
  data: InsurancePolicyCreateManyAcceptedByInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientId: string;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  payerId: string;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  acceptedById?: string | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  planId?: string | null;
  customPatientNote?: string | null;
  planTypeId?: string | null;
  verificationWorkflowStatusId: string;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
}

export interface InsurancePolicyCreateManyOrganizationInputEnvelope {
  data: InsurancePolicyCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyCreateManyPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  payerId: string;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  acceptedById?: string | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  planId?: string | null;
  customPatientNote?: string | null;
  planTypeId?: string | null;
  verificationWorkflowStatusId: string;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
}

export interface InsurancePolicyCreateManyPatientInputEnvelope {
  data: InsurancePolicyCreateManyPatientInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyCreateManyPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientId: string;
  organizationId: string;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  acceptedById?: string | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  planId?: string | null;
  customPatientNote?: string | null;
  planTypeId?: string | null;
  verificationWorkflowStatusId: string;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
}

export interface InsurancePolicyCreateManyPayerInputEnvelope {
  data: InsurancePolicyCreateManyPayerInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyCreateManyPlanInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientId: string;
  organizationId: string;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  payerId: string;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  acceptedById?: string | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  planTypeId?: string | null;
  verificationWorkflowStatusId: string;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
}

export interface InsurancePolicyCreateManyPlanInputEnvelope {
  data: InsurancePolicyCreateManyPlanInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyCreateManyPlanTypeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientId: string;
  organizationId: string;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  payerId: string;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  acceptedById?: string | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  planId?: string | null;
  customPatientNote?: string | null;
  verificationWorkflowStatusId: string;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
}

export interface InsurancePolicyCreateManyPlanTypeInputEnvelope {
  data: InsurancePolicyCreateManyPlanTypeInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyCreateManyVerificationWorkflowStatusInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientId: string;
  organizationId: string;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  payerId: string;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  acceptedById?: string | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  planId?: string | null;
  customPatientNote?: string | null;
  planTypeId?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
}

export interface InsurancePolicyCreateManyVerificationWorkflowStatusInputEnvelope {
  data: InsurancePolicyCreateManyVerificationWorkflowStatusInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyCreateNestedManyWithoutAcceptedByInput {
  create?: InsurancePolicyCreateWithoutAcceptedByInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutAcceptedByInput[] | null;
  createMany?: InsurancePolicyCreateManyAcceptedByInputEnvelope | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
}

export interface InsurancePolicyCreateNestedManyWithoutOrganizationInput {
  create?: InsurancePolicyCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: InsurancePolicyCreateManyOrganizationInputEnvelope | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
}

export interface InsurancePolicyCreateNestedManyWithoutPatientInput {
  create?: InsurancePolicyCreateWithoutPatientInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutPatientInput[] | null;
  createMany?: InsurancePolicyCreateManyPatientInputEnvelope | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
}

export interface InsurancePolicyCreateNestedManyWithoutPayerInput {
  create?: InsurancePolicyCreateWithoutPayerInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutPayerInput[] | null;
  createMany?: InsurancePolicyCreateManyPayerInputEnvelope | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
}

export interface InsurancePolicyCreateNestedManyWithoutPlanInput {
  create?: InsurancePolicyCreateWithoutPlanInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutPlanInput[] | null;
  createMany?: InsurancePolicyCreateManyPlanInputEnvelope | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
}

export interface InsurancePolicyCreateNestedManyWithoutPlanTypeInput {
  create?: InsurancePolicyCreateWithoutPlanTypeInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutPlanTypeInput[] | null;
  createMany?: InsurancePolicyCreateManyPlanTypeInputEnvelope | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
}

export interface InsurancePolicyCreateNestedManyWithoutVerificationWorkflowStatusInput {
  create?: InsurancePolicyCreateWithoutVerificationWorkflowStatusInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutVerificationWorkflowStatusInput[] | null;
  createMany?: InsurancePolicyCreateManyVerificationWorkflowStatusInputEnvelope | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
}

export interface InsurancePolicyCreateNestedOneWithoutAccountCoveragesInput {
  create?: InsurancePolicyCreateWithoutAccountCoveragesInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutAccountCoveragesInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
}

export interface InsurancePolicyCreateNestedOneWithoutAccumulatorAdjustmentsInput {
  create?: InsurancePolicyCreateWithoutAccumulatorAdjustmentsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutAccumulatorAdjustmentsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
}

export interface InsurancePolicyCreateNestedOneWithoutAppointmentBenefitAssignmentsInput {
  create?: InsurancePolicyCreateWithoutAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutAppointmentBenefitAssignmentsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
}

export interface InsurancePolicyCreateNestedOneWithoutCommunicationsInput {
  create?: InsurancePolicyCreateWithoutCommunicationsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutCommunicationsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
}

export interface InsurancePolicyCreateNestedOneWithoutCoverageBenefitsInput {
  create?: InsurancePolicyCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutCoverageBenefitsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
}

export interface InsurancePolicyCreateNestedOneWithoutEligibilityRequestsInput {
  create?: InsurancePolicyCreateWithoutEligibilityRequestsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutEligibilityRequestsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
}

export interface InsurancePolicyCreateNestedOneWithoutEstimateInsurancePoliciesInput {
  create?: InsurancePolicyCreateWithoutEstimateInsurancePoliciesInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutEstimateInsurancePoliciesInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
}

export interface InsurancePolicyCreateNestedOneWithoutInsurancePolicyWorklistItemsInput {
  create?: InsurancePolicyCreateWithoutInsurancePolicyWorklistItemsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutInsurancePolicyWorklistItemsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
}

export interface InsurancePolicyCreateNestedOneWithoutUserEnteredBenefitsInput {
  create?: InsurancePolicyCreateWithoutUserEnteredBenefitsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutUserEnteredBenefitsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
}

export interface InsurancePolicyCreateOrConnectWithoutAcceptedByInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutAcceptedByInput;
}

export interface InsurancePolicyCreateOrConnectWithoutAccountCoveragesInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutAccountCoveragesInput;
}

export interface InsurancePolicyCreateOrConnectWithoutAccumulatorAdjustmentsInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutAccumulatorAdjustmentsInput;
}

export interface InsurancePolicyCreateOrConnectWithoutAppointmentBenefitAssignmentsInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutAppointmentBenefitAssignmentsInput;
}

export interface InsurancePolicyCreateOrConnectWithoutCommunicationsInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutCommunicationsInput;
}

export interface InsurancePolicyCreateOrConnectWithoutCoverageBenefitsInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutCoverageBenefitsInput;
}

export interface InsurancePolicyCreateOrConnectWithoutEligibilityRequestsInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutEligibilityRequestsInput;
}

export interface InsurancePolicyCreateOrConnectWithoutEstimateInsurancePoliciesInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutEstimateInsurancePoliciesInput;
}

export interface InsurancePolicyCreateOrConnectWithoutInsurancePolicyWorklistItemsInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutInsurancePolicyWorklistItemsInput;
}

export interface InsurancePolicyCreateOrConnectWithoutOrganizationInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutOrganizationInput;
}

export interface InsurancePolicyCreateOrConnectWithoutPatientInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutPatientInput;
}

export interface InsurancePolicyCreateOrConnectWithoutPayerInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutPayerInput;
}

export interface InsurancePolicyCreateOrConnectWithoutPlanInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutPlanInput;
}

export interface InsurancePolicyCreateOrConnectWithoutPlanTypeInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutPlanTypeInput;
}

export interface InsurancePolicyCreateOrConnectWithoutUserEnteredBenefitsInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutUserEnteredBenefitsInput;
}

export interface InsurancePolicyCreateOrConnectWithoutVerificationWorkflowStatusInput {
  where: InsurancePolicyWhereUniqueInput;
  create: InsurancePolicyCreateWithoutVerificationWorkflowStatusInput;
}

export interface InsurancePolicyCreateWithoutAcceptedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutAccountCoveragesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutAccumulatorAdjustmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutAppointmentBenefitAssignmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutCommunicationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutEligibilityRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutEstimateInsurancePoliciesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutInsurancePolicyWorklistItemsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutPlanInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutPlanTypeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutUserEnteredBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  verificationWorkflowStatus: VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyCreateWithoutVerificationWorkflowStatusInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  memberId: string;
  groupId?: string | null;
  groupName?: string | null;
  effectiveDate?: any | null;
  renewalDate?: any | null;
  terminationDate?: any | null;
  active?: boolean | null;
  relationToSubscriber?: string | null;
  priority?: CoveragePriority | null;
  deletedAt?: any | null;
  acceptedAt?: any | null;
  ignoredAt?: any | null;
  sharingSkippedAt?: any | null;
  customPatientNote?: string | null;
  lastVerificationWorkflowStartedAt?: any | null;
  lastVerificationWorkflowCompletedAt?: any | null;
  lastVerificationWorkflowCanceledAt?: any | null;
  verificationWorkflowActiveAt?: any | null;
  verificationWorkflowArchivedAt?: any | null;
  lastVerificationWorkflowReason?: VerificationWorkflowReason | null;
  patient: PatientCreateNestedOneWithoutInsurancePoliciesInput;
  organization: OrganizationCreateNestedOneWithoutInsurancePoliciesInput;
  payer: PayerCreateNestedOneWithoutInsurancePoliciesInput;
  acceptedBy?: UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput | null;
  plan?: PlanCreateNestedOneWithoutInsurancePoliciesInput | null;
  planType?: PlanTypeCreateNestedOneWithoutInsurancePoliciesInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutInsurancePolicyInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutInsurancePolicyInput | null;
  communications?: CommunicationCreateNestedManyWithoutInsurancePolicyInput | null;
  userEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutInsurancePolicyInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutInsurancePolicyInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyCreateNestedManyWithoutInsurancePolicyInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentCreateNestedManyWithoutInsurancePolicyInput | null;
}

export interface InsurancePolicyListRelationFilter {
  every?: InsurancePolicyWhereInput | null;
  some?: InsurancePolicyWhereInput | null;
  none?: InsurancePolicyWhereInput | null;
}

export interface InsurancePolicyOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface InsurancePolicyPatientIdPayerIdMemberIdCompoundUniqueInput {
  patientId: string;
  payerId: string;
  memberId: string;
}

export interface InsurancePolicyRelationFilter {
  is?: InsurancePolicyWhereInput | null;
  isNot?: InsurancePolicyWhereInput | null;
}

export interface InsurancePolicyScalarWhereInput {
  AND?: InsurancePolicyScalarWhereInput[] | null;
  OR?: InsurancePolicyScalarWhereInput[] | null;
  NOT?: InsurancePolicyScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  patientId?: UuidFilter | null;
  organizationId?: UuidFilter | null;
  memberId?: StringFilter | null;
  groupId?: StringNullableFilter | null;
  groupName?: StringNullableFilter | null;
  payerId?: UuidFilter | null;
  effectiveDate?: DateTimeNullableFilter | null;
  renewalDate?: DateTimeNullableFilter | null;
  terminationDate?: DateTimeNullableFilter | null;
  active?: BoolFilter | null;
  relationToSubscriber?: StringNullableFilter | null;
  priority?: EnumCoveragePriorityNullableFilter | null;
  deletedAt?: DateTimeNullableFilter | null;
  acceptedAt?: DateTimeNullableFilter | null;
  acceptedById?: UuidNullableFilter | null;
  ignoredAt?: DateTimeNullableFilter | null;
  sharingSkippedAt?: DateTimeNullableFilter | null;
  planId?: UuidNullableFilter | null;
  customPatientNote?: StringNullableFilter | null;
  planTypeId?: UuidNullableFilter | null;
  verificationWorkflowStatusId?: UuidFilter | null;
  lastVerificationWorkflowStartedAt?: DateTimeNullableFilter | null;
  lastVerificationWorkflowCompletedAt?: DateTimeNullableFilter | null;
  lastVerificationWorkflowCanceledAt?: DateTimeNullableFilter | null;
  verificationWorkflowActiveAt?: DateTimeNullableFilter | null;
  verificationWorkflowArchivedAt?: DateTimeNullableFilter | null;
  lastVerificationWorkflowReason?: EnumVerificationWorkflowReasonNullableFilter | null;
}

export interface InsurancePolicyUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
}

export interface InsurancePolicyUpdateManyWithWhereWithoutAcceptedByInput {
  where: InsurancePolicyScalarWhereInput;
  data: InsurancePolicyUpdateManyMutationInput;
}

export interface InsurancePolicyUpdateManyWithWhereWithoutOrganizationInput {
  where: InsurancePolicyScalarWhereInput;
  data: InsurancePolicyUpdateManyMutationInput;
}

export interface InsurancePolicyUpdateManyWithWhereWithoutPatientInput {
  where: InsurancePolicyScalarWhereInput;
  data: InsurancePolicyUpdateManyMutationInput;
}

export interface InsurancePolicyUpdateManyWithWhereWithoutPayerInput {
  where: InsurancePolicyScalarWhereInput;
  data: InsurancePolicyUpdateManyMutationInput;
}

export interface InsurancePolicyUpdateManyWithWhereWithoutPlanInput {
  where: InsurancePolicyScalarWhereInput;
  data: InsurancePolicyUpdateManyMutationInput;
}

export interface InsurancePolicyUpdateManyWithWhereWithoutPlanTypeInput {
  where: InsurancePolicyScalarWhereInput;
  data: InsurancePolicyUpdateManyMutationInput;
}

export interface InsurancePolicyUpdateManyWithWhereWithoutVerificationWorkflowStatusInput {
  where: InsurancePolicyScalarWhereInput;
  data: InsurancePolicyUpdateManyMutationInput;
}

export interface InsurancePolicyUpdateManyWithoutAcceptedByNestedInput {
  create?: InsurancePolicyCreateWithoutAcceptedByInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutAcceptedByInput[] | null;
  upsert?: InsurancePolicyUpsertWithWhereUniqueWithoutAcceptedByInput[] | null;
  createMany?: InsurancePolicyCreateManyAcceptedByInputEnvelope | null;
  set?: InsurancePolicyWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWhereUniqueInput[] | null;
  delete?: InsurancePolicyWhereUniqueInput[] | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
  update?: InsurancePolicyUpdateWithWhereUniqueWithoutAcceptedByInput[] | null;
  updateMany?: InsurancePolicyUpdateManyWithWhereWithoutAcceptedByInput[] | null;
  deleteMany?: InsurancePolicyScalarWhereInput[] | null;
}

export interface InsurancePolicyUpdateManyWithoutOrganizationNestedInput {
  create?: InsurancePolicyCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: InsurancePolicyUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: InsurancePolicyCreateManyOrganizationInputEnvelope | null;
  set?: InsurancePolicyWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWhereUniqueInput[] | null;
  delete?: InsurancePolicyWhereUniqueInput[] | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
  update?: InsurancePolicyUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: InsurancePolicyUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: InsurancePolicyScalarWhereInput[] | null;
}

export interface InsurancePolicyUpdateManyWithoutPatientNestedInput {
  create?: InsurancePolicyCreateWithoutPatientInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutPatientInput[] | null;
  upsert?: InsurancePolicyUpsertWithWhereUniqueWithoutPatientInput[] | null;
  createMany?: InsurancePolicyCreateManyPatientInputEnvelope | null;
  set?: InsurancePolicyWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWhereUniqueInput[] | null;
  delete?: InsurancePolicyWhereUniqueInput[] | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
  update?: InsurancePolicyUpdateWithWhereUniqueWithoutPatientInput[] | null;
  updateMany?: InsurancePolicyUpdateManyWithWhereWithoutPatientInput[] | null;
  deleteMany?: InsurancePolicyScalarWhereInput[] | null;
}

export interface InsurancePolicyUpdateManyWithoutPayerNestedInput {
  create?: InsurancePolicyCreateWithoutPayerInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutPayerInput[] | null;
  upsert?: InsurancePolicyUpsertWithWhereUniqueWithoutPayerInput[] | null;
  createMany?: InsurancePolicyCreateManyPayerInputEnvelope | null;
  set?: InsurancePolicyWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWhereUniqueInput[] | null;
  delete?: InsurancePolicyWhereUniqueInput[] | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
  update?: InsurancePolicyUpdateWithWhereUniqueWithoutPayerInput[] | null;
  updateMany?: InsurancePolicyUpdateManyWithWhereWithoutPayerInput[] | null;
  deleteMany?: InsurancePolicyScalarWhereInput[] | null;
}

export interface InsurancePolicyUpdateManyWithoutPlanNestedInput {
  create?: InsurancePolicyCreateWithoutPlanInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutPlanInput[] | null;
  upsert?: InsurancePolicyUpsertWithWhereUniqueWithoutPlanInput[] | null;
  createMany?: InsurancePolicyCreateManyPlanInputEnvelope | null;
  set?: InsurancePolicyWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWhereUniqueInput[] | null;
  delete?: InsurancePolicyWhereUniqueInput[] | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
  update?: InsurancePolicyUpdateWithWhereUniqueWithoutPlanInput[] | null;
  updateMany?: InsurancePolicyUpdateManyWithWhereWithoutPlanInput[] | null;
  deleteMany?: InsurancePolicyScalarWhereInput[] | null;
}

export interface InsurancePolicyUpdateManyWithoutPlanTypeNestedInput {
  create?: InsurancePolicyCreateWithoutPlanTypeInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutPlanTypeInput[] | null;
  upsert?: InsurancePolicyUpsertWithWhereUniqueWithoutPlanTypeInput[] | null;
  createMany?: InsurancePolicyCreateManyPlanTypeInputEnvelope | null;
  set?: InsurancePolicyWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWhereUniqueInput[] | null;
  delete?: InsurancePolicyWhereUniqueInput[] | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
  update?: InsurancePolicyUpdateWithWhereUniqueWithoutPlanTypeInput[] | null;
  updateMany?: InsurancePolicyUpdateManyWithWhereWithoutPlanTypeInput[] | null;
  deleteMany?: InsurancePolicyScalarWhereInput[] | null;
}

export interface InsurancePolicyUpdateManyWithoutVerificationWorkflowStatusNestedInput {
  create?: InsurancePolicyCreateWithoutVerificationWorkflowStatusInput[] | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutVerificationWorkflowStatusInput[] | null;
  upsert?: InsurancePolicyUpsertWithWhereUniqueWithoutVerificationWorkflowStatusInput[] | null;
  createMany?: InsurancePolicyCreateManyVerificationWorkflowStatusInputEnvelope | null;
  set?: InsurancePolicyWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWhereUniqueInput[] | null;
  delete?: InsurancePolicyWhereUniqueInput[] | null;
  connect?: InsurancePolicyWhereUniqueInput[] | null;
  update?: InsurancePolicyUpdateWithWhereUniqueWithoutVerificationWorkflowStatusInput[] | null;
  updateMany?: InsurancePolicyUpdateManyWithWhereWithoutVerificationWorkflowStatusInput[] | null;
  deleteMany?: InsurancePolicyScalarWhereInput[] | null;
}

export interface InsurancePolicyUpdateOneRequiredWithoutAccountCoveragesNestedInput {
  create?: InsurancePolicyCreateWithoutAccountCoveragesInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutAccountCoveragesInput | null;
  upsert?: InsurancePolicyUpsertWithoutAccountCoveragesInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
  update?: InsurancePolicyUpdateWithoutAccountCoveragesInput | null;
}

export interface InsurancePolicyUpdateOneRequiredWithoutAccumulatorAdjustmentsNestedInput {
  create?: InsurancePolicyCreateWithoutAccumulatorAdjustmentsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutAccumulatorAdjustmentsInput | null;
  upsert?: InsurancePolicyUpsertWithoutAccumulatorAdjustmentsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
  update?: InsurancePolicyUpdateWithoutAccumulatorAdjustmentsInput | null;
}

export interface InsurancePolicyUpdateOneRequiredWithoutAppointmentBenefitAssignmentsNestedInput {
  create?: InsurancePolicyCreateWithoutAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutAppointmentBenefitAssignmentsInput | null;
  upsert?: InsurancePolicyUpsertWithoutAppointmentBenefitAssignmentsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
  update?: InsurancePolicyUpdateWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface InsurancePolicyUpdateOneRequiredWithoutCoverageBenefitsNestedInput {
  create?: InsurancePolicyCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutCoverageBenefitsInput | null;
  upsert?: InsurancePolicyUpsertWithoutCoverageBenefitsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
  update?: InsurancePolicyUpdateWithoutCoverageBenefitsInput | null;
}

export interface InsurancePolicyUpdateOneRequiredWithoutEligibilityRequestsNestedInput {
  create?: InsurancePolicyCreateWithoutEligibilityRequestsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutEligibilityRequestsInput | null;
  upsert?: InsurancePolicyUpsertWithoutEligibilityRequestsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
  update?: InsurancePolicyUpdateWithoutEligibilityRequestsInput | null;
}

export interface InsurancePolicyUpdateOneRequiredWithoutEstimateInsurancePoliciesNestedInput {
  create?: InsurancePolicyCreateWithoutEstimateInsurancePoliciesInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutEstimateInsurancePoliciesInput | null;
  upsert?: InsurancePolicyUpsertWithoutEstimateInsurancePoliciesInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
  update?: InsurancePolicyUpdateWithoutEstimateInsurancePoliciesInput | null;
}

export interface InsurancePolicyUpdateOneRequiredWithoutInsurancePolicyWorklistItemsNestedInput {
  create?: InsurancePolicyCreateWithoutInsurancePolicyWorklistItemsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutInsurancePolicyWorklistItemsInput | null;
  upsert?: InsurancePolicyUpsertWithoutInsurancePolicyWorklistItemsInput | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
  update?: InsurancePolicyUpdateWithoutInsurancePolicyWorklistItemsInput | null;
}

export interface InsurancePolicyUpdateOneWithoutCommunicationsNestedInput {
  create?: InsurancePolicyCreateWithoutCommunicationsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutCommunicationsInput | null;
  upsert?: InsurancePolicyUpsertWithoutCommunicationsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
  update?: InsurancePolicyUpdateWithoutCommunicationsInput | null;
}

export interface InsurancePolicyUpdateOneWithoutUserEnteredBenefitsNestedInput {
  create?: InsurancePolicyCreateWithoutUserEnteredBenefitsInput | null;
  connectOrCreate?: InsurancePolicyCreateOrConnectWithoutUserEnteredBenefitsInput | null;
  upsert?: InsurancePolicyUpsertWithoutUserEnteredBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: InsurancePolicyWhereUniqueInput | null;
  update?: InsurancePolicyUpdateWithoutUserEnteredBenefitsInput | null;
}

export interface InsurancePolicyUpdateWithWhereUniqueWithoutAcceptedByInput {
  where: InsurancePolicyWhereUniqueInput;
  data: InsurancePolicyUpdateWithoutAcceptedByInput;
}

export interface InsurancePolicyUpdateWithWhereUniqueWithoutOrganizationInput {
  where: InsurancePolicyWhereUniqueInput;
  data: InsurancePolicyUpdateWithoutOrganizationInput;
}

export interface InsurancePolicyUpdateWithWhereUniqueWithoutPatientInput {
  where: InsurancePolicyWhereUniqueInput;
  data: InsurancePolicyUpdateWithoutPatientInput;
}

export interface InsurancePolicyUpdateWithWhereUniqueWithoutPayerInput {
  where: InsurancePolicyWhereUniqueInput;
  data: InsurancePolicyUpdateWithoutPayerInput;
}

export interface InsurancePolicyUpdateWithWhereUniqueWithoutPlanInput {
  where: InsurancePolicyWhereUniqueInput;
  data: InsurancePolicyUpdateWithoutPlanInput;
}

export interface InsurancePolicyUpdateWithWhereUniqueWithoutPlanTypeInput {
  where: InsurancePolicyWhereUniqueInput;
  data: InsurancePolicyUpdateWithoutPlanTypeInput;
}

export interface InsurancePolicyUpdateWithWhereUniqueWithoutVerificationWorkflowStatusInput {
  where: InsurancePolicyWhereUniqueInput;
  data: InsurancePolicyUpdateWithoutVerificationWorkflowStatusInput;
}

export interface InsurancePolicyUpdateWithoutAcceptedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutAccountCoveragesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutAccumulatorAdjustmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutAppointmentBenefitAssignmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutCommunicationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutEligibilityRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutEstimateInsurancePoliciesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutInsurancePolicyWorklistItemsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutPatientInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutPayerInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutPlanInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutPlanTypeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutUserEnteredBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpdateWithoutVerificationWorkflowStatusInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  memberId?: StringFieldUpdateOperationsInput | null;
  groupId?: NullableStringFieldUpdateOperationsInput | null;
  groupName?: NullableStringFieldUpdateOperationsInput | null;
  effectiveDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  renewalDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  terminationDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  active?: BoolFieldUpdateOperationsInput | null;
  relationToSubscriber?: NullableStringFieldUpdateOperationsInput | null;
  priority?: NullableEnumCoveragePriorityFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  acceptedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  ignoredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  sharingSkippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  customPatientNote?: NullableStringFieldUpdateOperationsInput | null;
  lastVerificationWorkflowStartedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCompletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowCanceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowActiveAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  verificationWorkflowArchivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastVerificationWorkflowReason?: NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  payer?: PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput | null;
  acceptedBy?: UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput | null;
  plan?: PlanUpdateOneWithoutInsurancePoliciesNestedInput | null;
  planType?: PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutInsurancePolicyNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutInsurancePolicyNestedInput | null;
  communications?: CommunicationUpdateManyWithoutInsurancePolicyNestedInput | null;
  userEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutInsurancePolicyNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutInsurancePolicyNestedInput | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyUpdateManyWithoutInsurancePolicyNestedInput | null;
  accumulatorAdjustments?: AccumulatorAdjustmentUpdateManyWithoutInsurancePolicyNestedInput | null;
}

export interface InsurancePolicyUpsertWithWhereUniqueWithoutAcceptedByInput {
  where: InsurancePolicyWhereUniqueInput;
  update: InsurancePolicyUpdateWithoutAcceptedByInput;
  create: InsurancePolicyCreateWithoutAcceptedByInput;
}

export interface InsurancePolicyUpsertWithWhereUniqueWithoutOrganizationInput {
  where: InsurancePolicyWhereUniqueInput;
  update: InsurancePolicyUpdateWithoutOrganizationInput;
  create: InsurancePolicyCreateWithoutOrganizationInput;
}

export interface InsurancePolicyUpsertWithWhereUniqueWithoutPatientInput {
  where: InsurancePolicyWhereUniqueInput;
  update: InsurancePolicyUpdateWithoutPatientInput;
  create: InsurancePolicyCreateWithoutPatientInput;
}

export interface InsurancePolicyUpsertWithWhereUniqueWithoutPayerInput {
  where: InsurancePolicyWhereUniqueInput;
  update: InsurancePolicyUpdateWithoutPayerInput;
  create: InsurancePolicyCreateWithoutPayerInput;
}

export interface InsurancePolicyUpsertWithWhereUniqueWithoutPlanInput {
  where: InsurancePolicyWhereUniqueInput;
  update: InsurancePolicyUpdateWithoutPlanInput;
  create: InsurancePolicyCreateWithoutPlanInput;
}

export interface InsurancePolicyUpsertWithWhereUniqueWithoutPlanTypeInput {
  where: InsurancePolicyWhereUniqueInput;
  update: InsurancePolicyUpdateWithoutPlanTypeInput;
  create: InsurancePolicyCreateWithoutPlanTypeInput;
}

export interface InsurancePolicyUpsertWithWhereUniqueWithoutVerificationWorkflowStatusInput {
  where: InsurancePolicyWhereUniqueInput;
  update: InsurancePolicyUpdateWithoutVerificationWorkflowStatusInput;
  create: InsurancePolicyCreateWithoutVerificationWorkflowStatusInput;
}

export interface InsurancePolicyUpsertWithoutAccountCoveragesInput {
  update: InsurancePolicyUpdateWithoutAccountCoveragesInput;
  create: InsurancePolicyCreateWithoutAccountCoveragesInput;
}

export interface InsurancePolicyUpsertWithoutAccumulatorAdjustmentsInput {
  update: InsurancePolicyUpdateWithoutAccumulatorAdjustmentsInput;
  create: InsurancePolicyCreateWithoutAccumulatorAdjustmentsInput;
}

export interface InsurancePolicyUpsertWithoutAppointmentBenefitAssignmentsInput {
  update: InsurancePolicyUpdateWithoutAppointmentBenefitAssignmentsInput;
  create: InsurancePolicyCreateWithoutAppointmentBenefitAssignmentsInput;
}

export interface InsurancePolicyUpsertWithoutCommunicationsInput {
  update: InsurancePolicyUpdateWithoutCommunicationsInput;
  create: InsurancePolicyCreateWithoutCommunicationsInput;
}

export interface InsurancePolicyUpsertWithoutCoverageBenefitsInput {
  update: InsurancePolicyUpdateWithoutCoverageBenefitsInput;
  create: InsurancePolicyCreateWithoutCoverageBenefitsInput;
}

export interface InsurancePolicyUpsertWithoutEligibilityRequestsInput {
  update: InsurancePolicyUpdateWithoutEligibilityRequestsInput;
  create: InsurancePolicyCreateWithoutEligibilityRequestsInput;
}

export interface InsurancePolicyUpsertWithoutEstimateInsurancePoliciesInput {
  update: InsurancePolicyUpdateWithoutEstimateInsurancePoliciesInput;
  create: InsurancePolicyCreateWithoutEstimateInsurancePoliciesInput;
}

export interface InsurancePolicyUpsertWithoutInsurancePolicyWorklistItemsInput {
  update: InsurancePolicyUpdateWithoutInsurancePolicyWorklistItemsInput;
  create: InsurancePolicyCreateWithoutInsurancePolicyWorklistItemsInput;
}

export interface InsurancePolicyUpsertWithoutUserEnteredBenefitsInput {
  update: InsurancePolicyUpdateWithoutUserEnteredBenefitsInput;
  create: InsurancePolicyCreateWithoutUserEnteredBenefitsInput;
}

export interface InsurancePolicyWhereInput {
  AND?: InsurancePolicyWhereInput[] | null;
  OR?: InsurancePolicyWhereInput[] | null;
  NOT?: InsurancePolicyWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  patientId?: UuidFilter | null;
  organizationId?: UuidFilter | null;
  memberId?: StringFilter | null;
  groupId?: StringNullableFilter | null;
  groupName?: StringNullableFilter | null;
  payerId?: UuidFilter | null;
  effectiveDate?: DateTimeNullableFilter | null;
  renewalDate?: DateTimeNullableFilter | null;
  terminationDate?: DateTimeNullableFilter | null;
  active?: BoolFilter | null;
  relationToSubscriber?: StringNullableFilter | null;
  priority?: EnumCoveragePriorityNullableFilter | null;
  deletedAt?: DateTimeNullableFilter | null;
  acceptedAt?: DateTimeNullableFilter | null;
  acceptedById?: UuidNullableFilter | null;
  ignoredAt?: DateTimeNullableFilter | null;
  sharingSkippedAt?: DateTimeNullableFilter | null;
  planId?: UuidNullableFilter | null;
  customPatientNote?: StringNullableFilter | null;
  planTypeId?: UuidNullableFilter | null;
  verificationWorkflowStatusId?: UuidFilter | null;
  lastVerificationWorkflowStartedAt?: DateTimeNullableFilter | null;
  lastVerificationWorkflowCompletedAt?: DateTimeNullableFilter | null;
  lastVerificationWorkflowCanceledAt?: DateTimeNullableFilter | null;
  verificationWorkflowActiveAt?: DateTimeNullableFilter | null;
  verificationWorkflowArchivedAt?: DateTimeNullableFilter | null;
  lastVerificationWorkflowReason?: EnumVerificationWorkflowReasonNullableFilter | null;
  patient?: PatientRelationFilter | null;
  organization?: OrganizationRelationFilter | null;
  payer?: PayerRelationFilter | null;
  acceptedBy?: UserRelationFilter | null;
  plan?: PlanRelationFilter | null;
  planType?: PlanTypeRelationFilter | null;
  verificationWorkflowStatus?: VerificationWorkflowStatusRelationFilter | null;
  accountCoverages?: AccountCoverageListRelationFilter | null;
  eligibilityRequests?: EligibilityRequestListRelationFilter | null;
  communications?: CommunicationListRelationFilter | null;
  userEnteredBenefits?: UserEnteredBenefitListRelationFilter | null;
  coverageBenefits?: CoverageBenefitListRelationFilter | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentListRelationFilter | null;
  insurancePolicyWorklistItems?: InsurancePolicyWorklistItemListRelationFilter | null;
  estimateInsurancePolicies?: EstimateInsurancePolicyListRelationFilter | null;
  accumulatorAdjustments?: AccumulatorAdjustmentListRelationFilter | null;
}

export interface InsurancePolicyWhereUniqueInput {
  id?: string | null;
  patientId_payerId_memberId?: InsurancePolicyPatientIdPayerIdMemberIdCompoundUniqueInput | null;
}

export interface InsurancePolicyWorklistItemCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  insurancePolicyId: string;
  reason: InsurancePolicyWorklistReason;
  completedAt?: any | null;
  completedById?: string | null;
  canceledAt?: any | null;
  canceledById?: string | null;
  expiredAt?: any | null;
}

export interface InsurancePolicyWorklistItemCreateManyAppointmentInputEnvelope {
  data: InsurancePolicyWorklistItemCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyWorklistItemCreateManyCanceledByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  insurancePolicyId: string;
  appointmentId?: string | null;
  reason: InsurancePolicyWorklistReason;
  completedAt?: any | null;
  completedById?: string | null;
  canceledAt?: any | null;
  expiredAt?: any | null;
}

export interface InsurancePolicyWorklistItemCreateManyCanceledByInputEnvelope {
  data: InsurancePolicyWorklistItemCreateManyCanceledByInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyWorklistItemCreateManyCompletedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  insurancePolicyId: string;
  appointmentId?: string | null;
  reason: InsurancePolicyWorklistReason;
  completedAt?: any | null;
  canceledAt?: any | null;
  canceledById?: string | null;
  expiredAt?: any | null;
}

export interface InsurancePolicyWorklistItemCreateManyCompletedByInputEnvelope {
  data: InsurancePolicyWorklistItemCreateManyCompletedByInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyWorklistItemCreateManyInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  appointmentId?: string | null;
  reason: InsurancePolicyWorklistReason;
  completedAt?: any | null;
  completedById?: string | null;
  canceledAt?: any | null;
  canceledById?: string | null;
  expiredAt?: any | null;
}

export interface InsurancePolicyWorklistItemCreateManyInsurancePolicyInputEnvelope {
  data: InsurancePolicyWorklistItemCreateManyInsurancePolicyInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyWorklistItemCreateNestedManyWithoutAppointmentInput {
  create?: InsurancePolicyWorklistItemCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: InsurancePolicyWorklistItemCreateManyAppointmentInputEnvelope | null;
  connect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
}

export interface InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput {
  create?: InsurancePolicyWorklistItemCreateWithoutCanceledByInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemCreateOrConnectWithoutCanceledByInput[] | null;
  createMany?: InsurancePolicyWorklistItemCreateManyCanceledByInputEnvelope | null;
  connect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
}

export interface InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput {
  create?: InsurancePolicyWorklistItemCreateWithoutCompletedByInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemCreateOrConnectWithoutCompletedByInput[] | null;
  createMany?: InsurancePolicyWorklistItemCreateManyCompletedByInputEnvelope | null;
  connect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
}

export interface InsurancePolicyWorklistItemCreateNestedManyWithoutInsurancePolicyInput {
  create?: InsurancePolicyWorklistItemCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemCreateOrConnectWithoutInsurancePolicyInput[] | null;
  createMany?: InsurancePolicyWorklistItemCreateManyInsurancePolicyInputEnvelope | null;
  connect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
}

export interface InsurancePolicyWorklistItemCreateNestedOneWithoutInsurancePolicyWorklistItemTasksInput {
  create?: InsurancePolicyWorklistItemCreateWithoutInsurancePolicyWorklistItemTasksInput | null;
  connectOrCreate?: InsurancePolicyWorklistItemCreateOrConnectWithoutInsurancePolicyWorklistItemTasksInput | null;
  connect?: InsurancePolicyWorklistItemWhereUniqueInput | null;
}

export interface InsurancePolicyWorklistItemCreateOrConnectWithoutAppointmentInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  create: InsurancePolicyWorklistItemCreateWithoutAppointmentInput;
}

export interface InsurancePolicyWorklistItemCreateOrConnectWithoutCanceledByInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  create: InsurancePolicyWorklistItemCreateWithoutCanceledByInput;
}

export interface InsurancePolicyWorklistItemCreateOrConnectWithoutCompletedByInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  create: InsurancePolicyWorklistItemCreateWithoutCompletedByInput;
}

export interface InsurancePolicyWorklistItemCreateOrConnectWithoutInsurancePolicyInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  create: InsurancePolicyWorklistItemCreateWithoutInsurancePolicyInput;
}

export interface InsurancePolicyWorklistItemCreateOrConnectWithoutInsurancePolicyWorklistItemTasksInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  create: InsurancePolicyWorklistItemCreateWithoutInsurancePolicyWorklistItemTasksInput;
}

export interface InsurancePolicyWorklistItemCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  reason: InsurancePolicyWorklistReason;
  completedAt?: any | null;
  canceledAt?: any | null;
  expiredAt?: any | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutInsurancePolicyWorklistItemsInput;
  completedBy?: UserCreateNestedOneWithoutCompletedInsurancePolicyWorklistItemsInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledInsurancePolicyWorklistItemsInput | null;
  insurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutInsurancePolicyWorklistItemInput | null;
}

export interface InsurancePolicyWorklistItemCreateWithoutCanceledByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  reason: InsurancePolicyWorklistReason;
  completedAt?: any | null;
  canceledAt?: any | null;
  expiredAt?: any | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutInsurancePolicyWorklistItemsInput;
  appointment?: AppointmentCreateNestedOneWithoutInsurancePolicyWorklistItemsInput | null;
  completedBy?: UserCreateNestedOneWithoutCompletedInsurancePolicyWorklistItemsInput | null;
  insurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutInsurancePolicyWorklistItemInput | null;
}

export interface InsurancePolicyWorklistItemCreateWithoutCompletedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  reason: InsurancePolicyWorklistReason;
  completedAt?: any | null;
  canceledAt?: any | null;
  expiredAt?: any | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutInsurancePolicyWorklistItemsInput;
  appointment?: AppointmentCreateNestedOneWithoutInsurancePolicyWorklistItemsInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledInsurancePolicyWorklistItemsInput | null;
  insurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutInsurancePolicyWorklistItemInput | null;
}

export interface InsurancePolicyWorklistItemCreateWithoutInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  reason: InsurancePolicyWorklistReason;
  completedAt?: any | null;
  canceledAt?: any | null;
  expiredAt?: any | null;
  appointment?: AppointmentCreateNestedOneWithoutInsurancePolicyWorklistItemsInput | null;
  completedBy?: UserCreateNestedOneWithoutCompletedInsurancePolicyWorklistItemsInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledInsurancePolicyWorklistItemsInput | null;
  insurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutInsurancePolicyWorklistItemInput | null;
}

export interface InsurancePolicyWorklistItemCreateWithoutInsurancePolicyWorklistItemTasksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  reason: InsurancePolicyWorklistReason;
  completedAt?: any | null;
  canceledAt?: any | null;
  expiredAt?: any | null;
  insurancePolicy: InsurancePolicyCreateNestedOneWithoutInsurancePolicyWorklistItemsInput;
  appointment?: AppointmentCreateNestedOneWithoutInsurancePolicyWorklistItemsInput | null;
  completedBy?: UserCreateNestedOneWithoutCompletedInsurancePolicyWorklistItemsInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledInsurancePolicyWorklistItemsInput | null;
}

export interface InsurancePolicyWorklistItemListRelationFilter {
  every?: InsurancePolicyWorklistItemWhereInput | null;
  some?: InsurancePolicyWorklistItemWhereInput | null;
  none?: InsurancePolicyWorklistItemWhereInput | null;
}

export interface InsurancePolicyWorklistItemOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface InsurancePolicyWorklistItemRelationFilter {
  is?: InsurancePolicyWorklistItemWhereInput | null;
  isNot?: InsurancePolicyWorklistItemWhereInput | null;
}

export interface InsurancePolicyWorklistItemScalarWhereInput {
  AND?: InsurancePolicyWorklistItemScalarWhereInput[] | null;
  OR?: InsurancePolicyWorklistItemScalarWhereInput[] | null;
  NOT?: InsurancePolicyWorklistItemScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  insurancePolicyId?: UuidFilter | null;
  appointmentId?: UuidNullableFilter | null;
  reason?: EnumInsurancePolicyWorklistReasonFilter | null;
  completedAt?: DateTimeNullableFilter | null;
  completedById?: UuidNullableFilter | null;
  canceledAt?: DateTimeNullableFilter | null;
  canceledById?: UuidNullableFilter | null;
  expiredAt?: DateTimeNullableFilter | null;
}

export interface InsurancePolicyWorklistItemTaskCreateManyCompletedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  insurancePolicyWorklistItemId: string;
  completedAt?: any | null;
  skippedAt?: any | null;
  skippedById?: string | null;
  type: InsurancePolicyWorklistItemTaskType;
  title: string;
  body?: string | null;
}

export interface InsurancePolicyWorklistItemTaskCreateManyCompletedByInputEnvelope {
  data: InsurancePolicyWorklistItemTaskCreateManyCompletedByInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyWorklistItemTaskCreateManyInsurancePolicyWorklistItemInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  completedAt?: any | null;
  completedById?: string | null;
  skippedAt?: any | null;
  skippedById?: string | null;
  type: InsurancePolicyWorklistItemTaskType;
  title: string;
  body?: string | null;
}

export interface InsurancePolicyWorklistItemTaskCreateManyInsurancePolicyWorklistItemInputEnvelope {
  data: InsurancePolicyWorklistItemTaskCreateManyInsurancePolicyWorklistItemInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyWorklistItemTaskCreateManySkippedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  insurancePolicyWorklistItemId: string;
  completedAt?: any | null;
  completedById?: string | null;
  skippedAt?: any | null;
  type: InsurancePolicyWorklistItemTaskType;
  title: string;
  body?: string | null;
}

export interface InsurancePolicyWorklistItemTaskCreateManySkippedByInputEnvelope {
  data: InsurancePolicyWorklistItemTaskCreateManySkippedByInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput {
  create?: InsurancePolicyWorklistItemTaskCreateWithoutCompletedByInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemTaskCreateOrConnectWithoutCompletedByInput[] | null;
  createMany?: InsurancePolicyWorklistItemTaskCreateManyCompletedByInputEnvelope | null;
  connect?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
}

export interface InsurancePolicyWorklistItemTaskCreateNestedManyWithoutInsurancePolicyWorklistItemInput {
  create?: InsurancePolicyWorklistItemTaskCreateWithoutInsurancePolicyWorklistItemInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemTaskCreateOrConnectWithoutInsurancePolicyWorklistItemInput[] | null;
  createMany?: InsurancePolicyWorklistItemTaskCreateManyInsurancePolicyWorklistItemInputEnvelope | null;
  connect?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
}

export interface InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput {
  create?: InsurancePolicyWorklistItemTaskCreateWithoutSkippedByInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemTaskCreateOrConnectWithoutSkippedByInput[] | null;
  createMany?: InsurancePolicyWorklistItemTaskCreateManySkippedByInputEnvelope | null;
  connect?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
}

export interface InsurancePolicyWorklistItemTaskCreateOrConnectWithoutCompletedByInput {
  where: InsurancePolicyWorklistItemTaskWhereUniqueInput;
  create: InsurancePolicyWorklistItemTaskCreateWithoutCompletedByInput;
}

export interface InsurancePolicyWorklistItemTaskCreateOrConnectWithoutInsurancePolicyWorklistItemInput {
  where: InsurancePolicyWorklistItemTaskWhereUniqueInput;
  create: InsurancePolicyWorklistItemTaskCreateWithoutInsurancePolicyWorklistItemInput;
}

export interface InsurancePolicyWorklistItemTaskCreateOrConnectWithoutSkippedByInput {
  where: InsurancePolicyWorklistItemTaskWhereUniqueInput;
  create: InsurancePolicyWorklistItemTaskCreateWithoutSkippedByInput;
}

export interface InsurancePolicyWorklistItemTaskCreateWithoutCompletedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  completedAt?: any | null;
  skippedAt?: any | null;
  type: InsurancePolicyWorklistItemTaskType;
  title: string;
  body?: string | null;
  insurancePolicyWorklistItem: InsurancePolicyWorklistItemCreateNestedOneWithoutInsurancePolicyWorklistItemTasksInput;
  skippedBy?: UserCreateNestedOneWithoutSkippedInsurancePolicyWorklistItemTasksInput | null;
}

export interface InsurancePolicyWorklistItemTaskCreateWithoutInsurancePolicyWorklistItemInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  completedAt?: any | null;
  skippedAt?: any | null;
  type: InsurancePolicyWorklistItemTaskType;
  title: string;
  body?: string | null;
  completedBy?: UserCreateNestedOneWithoutCompletedInsurancePolicyWorklistItemTaskasInput | null;
  skippedBy?: UserCreateNestedOneWithoutSkippedInsurancePolicyWorklistItemTasksInput | null;
}

export interface InsurancePolicyWorklistItemTaskCreateWithoutSkippedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  completedAt?: any | null;
  skippedAt?: any | null;
  type: InsurancePolicyWorklistItemTaskType;
  title: string;
  body?: string | null;
  insurancePolicyWorklistItem: InsurancePolicyWorklistItemCreateNestedOneWithoutInsurancePolicyWorklistItemTasksInput;
  completedBy?: UserCreateNestedOneWithoutCompletedInsurancePolicyWorklistItemTaskasInput | null;
}

export interface InsurancePolicyWorklistItemTaskListRelationFilter {
  every?: InsurancePolicyWorklistItemTaskWhereInput | null;
  some?: InsurancePolicyWorklistItemTaskWhereInput | null;
  none?: InsurancePolicyWorklistItemTaskWhereInput | null;
}

export interface InsurancePolicyWorklistItemTaskScalarWhereInput {
  AND?: InsurancePolicyWorklistItemTaskScalarWhereInput[] | null;
  OR?: InsurancePolicyWorklistItemTaskScalarWhereInput[] | null;
  NOT?: InsurancePolicyWorklistItemTaskScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  priority?: FloatFilter | null;
  insurancePolicyWorklistItemId?: UuidFilter | null;
  completedAt?: DateTimeNullableFilter | null;
  completedById?: UuidNullableFilter | null;
  skippedAt?: DateTimeNullableFilter | null;
  skippedById?: UuidNullableFilter | null;
  type?: EnumInsurancePolicyWorklistItemTaskTypeFilter | null;
  title?: StringFilter | null;
  body?: StringNullableFilter | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  reason: InsurancePolicyWorklistReason;
  disabled?: boolean | null;
  organizationId: string;
  taskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreatetaskTemplatesInput | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateCreateManyLocationInputEnvelope {
  data: InsurancePolicyWorklistItemTaskTemplateCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  reason: InsurancePolicyWorklistReason;
  disabled?: boolean | null;
  locationId?: string | null;
  taskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreatetaskTemplatesInput | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateCreateManyOrganizationInputEnvelope {
  data: InsurancePolicyWorklistItemTaskTemplateCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput {
  create?: InsurancePolicyWorklistItemTaskTemplateCreateWithoutLocationInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemTaskTemplateCreateOrConnectWithoutLocationInput[] | null;
  createMany?: InsurancePolicyWorklistItemTaskTemplateCreateManyLocationInputEnvelope | null;
  connect?: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput[] | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput {
  create?: InsurancePolicyWorklistItemTaskTemplateCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemTaskTemplateCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: InsurancePolicyWorklistItemTaskTemplateCreateManyOrganizationInputEnvelope | null;
  connect?: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput[] | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateCreateOrConnectWithoutLocationInput {
  where: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput;
  create: InsurancePolicyWorklistItemTaskTemplateCreateWithoutLocationInput;
}

export interface InsurancePolicyWorklistItemTaskTemplateCreateOrConnectWithoutOrganizationInput {
  where: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput;
  create: InsurancePolicyWorklistItemTaskTemplateCreateWithoutOrganizationInput;
}

export interface InsurancePolicyWorklistItemTaskTemplateCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  reason: InsurancePolicyWorklistReason;
  disabled?: boolean | null;
  taskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreatetaskTemplatesInput | null;
  organization: OrganizationCreateNestedOneWithoutInsurancePolicyWorklistItemTaskTemplatesInput;
}

export interface InsurancePolicyWorklistItemTaskTemplateCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  reason: InsurancePolicyWorklistReason;
  disabled?: boolean | null;
  taskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreatetaskTemplatesInput | null;
  location?: LocationCreateNestedOneWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateCreatetaskTemplatesInput {
  set: any[];
}

export interface InsurancePolicyWorklistItemTaskTemplateListRelationFilter {
  every?: InsurancePolicyWorklistItemTaskTemplateWhereInput | null;
  some?: InsurancePolicyWorklistItemTaskTemplateWhereInput | null;
  none?: InsurancePolicyWorklistItemTaskTemplateWhereInput | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateScalarWhereInput {
  AND?: InsurancePolicyWorklistItemTaskTemplateScalarWhereInput[] | null;
  OR?: InsurancePolicyWorklistItemTaskTemplateScalarWhereInput[] | null;
  NOT?: InsurancePolicyWorklistItemTaskTemplateScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  reason?: EnumInsurancePolicyWorklistReasonFilter | null;
  disabled?: BoolFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidNullableFilter | null;
  taskTemplates?: JsonNullableListFilter | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  reason?: EnumInsurancePolicyWorklistReasonFieldUpdateOperationsInput | null;
  disabled?: BoolFieldUpdateOperationsInput | null;
  taskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdatetaskTemplatesInput | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpdateManyWithWhereWithoutLocationInput {
  where: InsurancePolicyWorklistItemTaskTemplateScalarWhereInput;
  data: InsurancePolicyWorklistItemTaskTemplateUpdateManyMutationInput;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpdateManyWithWhereWithoutOrganizationInput {
  where: InsurancePolicyWorklistItemTaskTemplateScalarWhereInput;
  data: InsurancePolicyWorklistItemTaskTemplateUpdateManyMutationInput;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput {
  create?: InsurancePolicyWorklistItemTaskTemplateCreateWithoutLocationInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemTaskTemplateCreateOrConnectWithoutLocationInput[] | null;
  upsert?: InsurancePolicyWorklistItemTaskTemplateUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: InsurancePolicyWorklistItemTaskTemplateCreateManyLocationInputEnvelope | null;
  set?: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput[] | null;
  delete?: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput[] | null;
  connect?: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput[] | null;
  update?: InsurancePolicyWorklistItemTaskTemplateUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: InsurancePolicyWorklistItemTaskTemplateScalarWhereInput[] | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput {
  create?: InsurancePolicyWorklistItemTaskTemplateCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemTaskTemplateCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: InsurancePolicyWorklistItemTaskTemplateUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: InsurancePolicyWorklistItemTaskTemplateCreateManyOrganizationInputEnvelope | null;
  set?: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput[] | null;
  delete?: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput[] | null;
  connect?: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput[] | null;
  update?: InsurancePolicyWorklistItemTaskTemplateUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: InsurancePolicyWorklistItemTaskTemplateScalarWhereInput[] | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpdateWithWhereUniqueWithoutLocationInput {
  where: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput;
  data: InsurancePolicyWorklistItemTaskTemplateUpdateWithoutLocationInput;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpdateWithWhereUniqueWithoutOrganizationInput {
  where: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput;
  data: InsurancePolicyWorklistItemTaskTemplateUpdateWithoutOrganizationInput;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  reason?: EnumInsurancePolicyWorklistReasonFieldUpdateOperationsInput | null;
  disabled?: BoolFieldUpdateOperationsInput | null;
  taskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdatetaskTemplatesInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutInsurancePolicyWorklistItemTaskTemplatesNestedInput | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  reason?: EnumInsurancePolicyWorklistReasonFieldUpdateOperationsInput | null;
  disabled?: BoolFieldUpdateOperationsInput | null;
  taskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdatetaskTemplatesInput | null;
  location?: LocationUpdateOneWithoutInsurancePolicyWorklistItemTaskTemplatesNestedInput | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpdatetaskTemplatesInput {
  set?: any[] | null;
  push?: any[] | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpsertWithWhereUniqueWithoutLocationInput {
  where: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput;
  update: InsurancePolicyWorklistItemTaskTemplateUpdateWithoutLocationInput;
  create: InsurancePolicyWorklistItemTaskTemplateCreateWithoutLocationInput;
}

export interface InsurancePolicyWorklistItemTaskTemplateUpsertWithWhereUniqueWithoutOrganizationInput {
  where: InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput;
  update: InsurancePolicyWorklistItemTaskTemplateUpdateWithoutOrganizationInput;
  create: InsurancePolicyWorklistItemTaskTemplateCreateWithoutOrganizationInput;
}

export interface InsurancePolicyWorklistItemTaskTemplateWhereInput {
  AND?: InsurancePolicyWorklistItemTaskTemplateWhereInput[] | null;
  OR?: InsurancePolicyWorklistItemTaskTemplateWhereInput[] | null;
  NOT?: InsurancePolicyWorklistItemTaskTemplateWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  reason?: EnumInsurancePolicyWorklistReasonFilter | null;
  disabled?: BoolFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidNullableFilter | null;
  taskTemplates?: JsonNullableListFilter | null;
  organization?: OrganizationRelationFilter | null;
  location?: LocationRelationFilter | null;
}

export interface InsurancePolicyWorklistItemTaskTemplateWhereUniqueInput {
  id?: string | null;
}

export interface InsurancePolicyWorklistItemTaskUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  skippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: EnumInsurancePolicyWorklistItemTaskTypeFieldUpdateOperationsInput | null;
  title?: StringFieldUpdateOperationsInput | null;
  body?: NullableStringFieldUpdateOperationsInput | null;
}

export interface InsurancePolicyWorklistItemTaskUpdateManyWithWhereWithoutCompletedByInput {
  where: InsurancePolicyWorklistItemTaskScalarWhereInput;
  data: InsurancePolicyWorklistItemTaskUpdateManyMutationInput;
}

export interface InsurancePolicyWorklistItemTaskUpdateManyWithWhereWithoutInsurancePolicyWorklistItemInput {
  where: InsurancePolicyWorklistItemTaskScalarWhereInput;
  data: InsurancePolicyWorklistItemTaskUpdateManyMutationInput;
}

export interface InsurancePolicyWorklistItemTaskUpdateManyWithWhereWithoutSkippedByInput {
  where: InsurancePolicyWorklistItemTaskScalarWhereInput;
  data: InsurancePolicyWorklistItemTaskUpdateManyMutationInput;
}

export interface InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput {
  create?: InsurancePolicyWorklistItemTaskCreateWithoutCompletedByInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemTaskCreateOrConnectWithoutCompletedByInput[] | null;
  upsert?: InsurancePolicyWorklistItemTaskUpsertWithWhereUniqueWithoutCompletedByInput[] | null;
  createMany?: InsurancePolicyWorklistItemTaskCreateManyCompletedByInputEnvelope | null;
  set?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  delete?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  connect?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  update?: InsurancePolicyWorklistItemTaskUpdateWithWhereUniqueWithoutCompletedByInput[] | null;
  updateMany?: InsurancePolicyWorklistItemTaskUpdateManyWithWhereWithoutCompletedByInput[] | null;
  deleteMany?: InsurancePolicyWorklistItemTaskScalarWhereInput[] | null;
}

export interface InsurancePolicyWorklistItemTaskUpdateManyWithoutInsurancePolicyWorklistItemNestedInput {
  create?: InsurancePolicyWorklistItemTaskCreateWithoutInsurancePolicyWorklistItemInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemTaskCreateOrConnectWithoutInsurancePolicyWorklistItemInput[] | null;
  upsert?: InsurancePolicyWorklistItemTaskUpsertWithWhereUniqueWithoutInsurancePolicyWorklistItemInput[] | null;
  createMany?: InsurancePolicyWorklistItemTaskCreateManyInsurancePolicyWorklistItemInputEnvelope | null;
  set?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  delete?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  connect?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  update?: InsurancePolicyWorklistItemTaskUpdateWithWhereUniqueWithoutInsurancePolicyWorklistItemInput[] | null;
  updateMany?: InsurancePolicyWorklistItemTaskUpdateManyWithWhereWithoutInsurancePolicyWorklistItemInput[] | null;
  deleteMany?: InsurancePolicyWorklistItemTaskScalarWhereInput[] | null;
}

export interface InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput {
  create?: InsurancePolicyWorklistItemTaskCreateWithoutSkippedByInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemTaskCreateOrConnectWithoutSkippedByInput[] | null;
  upsert?: InsurancePolicyWorklistItemTaskUpsertWithWhereUniqueWithoutSkippedByInput[] | null;
  createMany?: InsurancePolicyWorklistItemTaskCreateManySkippedByInputEnvelope | null;
  set?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  delete?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  connect?: InsurancePolicyWorklistItemTaskWhereUniqueInput[] | null;
  update?: InsurancePolicyWorklistItemTaskUpdateWithWhereUniqueWithoutSkippedByInput[] | null;
  updateMany?: InsurancePolicyWorklistItemTaskUpdateManyWithWhereWithoutSkippedByInput[] | null;
  deleteMany?: InsurancePolicyWorklistItemTaskScalarWhereInput[] | null;
}

export interface InsurancePolicyWorklistItemTaskUpdateWithWhereUniqueWithoutCompletedByInput {
  where: InsurancePolicyWorklistItemTaskWhereUniqueInput;
  data: InsurancePolicyWorklistItemTaskUpdateWithoutCompletedByInput;
}

export interface InsurancePolicyWorklistItemTaskUpdateWithWhereUniqueWithoutInsurancePolicyWorklistItemInput {
  where: InsurancePolicyWorklistItemTaskWhereUniqueInput;
  data: InsurancePolicyWorklistItemTaskUpdateWithoutInsurancePolicyWorklistItemInput;
}

export interface InsurancePolicyWorklistItemTaskUpdateWithWhereUniqueWithoutSkippedByInput {
  where: InsurancePolicyWorklistItemTaskWhereUniqueInput;
  data: InsurancePolicyWorklistItemTaskUpdateWithoutSkippedByInput;
}

export interface InsurancePolicyWorklistItemTaskUpdateWithoutCompletedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  skippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: EnumInsurancePolicyWorklistItemTaskTypeFieldUpdateOperationsInput | null;
  title?: StringFieldUpdateOperationsInput | null;
  body?: NullableStringFieldUpdateOperationsInput | null;
  insurancePolicyWorklistItem?: InsurancePolicyWorklistItemUpdateOneRequiredWithoutInsurancePolicyWorklistItemTasksNestedInput | null;
  skippedBy?: UserUpdateOneWithoutSkippedInsurancePolicyWorklistItemTasksNestedInput | null;
}

export interface InsurancePolicyWorklistItemTaskUpdateWithoutInsurancePolicyWorklistItemInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  skippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: EnumInsurancePolicyWorklistItemTaskTypeFieldUpdateOperationsInput | null;
  title?: StringFieldUpdateOperationsInput | null;
  body?: NullableStringFieldUpdateOperationsInput | null;
  completedBy?: UserUpdateOneWithoutCompletedInsurancePolicyWorklistItemTaskasNestedInput | null;
  skippedBy?: UserUpdateOneWithoutSkippedInsurancePolicyWorklistItemTasksNestedInput | null;
}

export interface InsurancePolicyWorklistItemTaskUpdateWithoutSkippedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  skippedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  type?: EnumInsurancePolicyWorklistItemTaskTypeFieldUpdateOperationsInput | null;
  title?: StringFieldUpdateOperationsInput | null;
  body?: NullableStringFieldUpdateOperationsInput | null;
  insurancePolicyWorklistItem?: InsurancePolicyWorklistItemUpdateOneRequiredWithoutInsurancePolicyWorklistItemTasksNestedInput | null;
  completedBy?: UserUpdateOneWithoutCompletedInsurancePolicyWorklistItemTaskasNestedInput | null;
}

export interface InsurancePolicyWorklistItemTaskUpsertWithWhereUniqueWithoutCompletedByInput {
  where: InsurancePolicyWorklistItemTaskWhereUniqueInput;
  update: InsurancePolicyWorklistItemTaskUpdateWithoutCompletedByInput;
  create: InsurancePolicyWorklistItemTaskCreateWithoutCompletedByInput;
}

export interface InsurancePolicyWorklistItemTaskUpsertWithWhereUniqueWithoutInsurancePolicyWorklistItemInput {
  where: InsurancePolicyWorklistItemTaskWhereUniqueInput;
  update: InsurancePolicyWorklistItemTaskUpdateWithoutInsurancePolicyWorklistItemInput;
  create: InsurancePolicyWorklistItemTaskCreateWithoutInsurancePolicyWorklistItemInput;
}

export interface InsurancePolicyWorklistItemTaskUpsertWithWhereUniqueWithoutSkippedByInput {
  where: InsurancePolicyWorklistItemTaskWhereUniqueInput;
  update: InsurancePolicyWorklistItemTaskUpdateWithoutSkippedByInput;
  create: InsurancePolicyWorklistItemTaskCreateWithoutSkippedByInput;
}

export interface InsurancePolicyWorklistItemTaskWhereInput {
  AND?: InsurancePolicyWorklistItemTaskWhereInput[] | null;
  OR?: InsurancePolicyWorklistItemTaskWhereInput[] | null;
  NOT?: InsurancePolicyWorklistItemTaskWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  priority?: FloatFilter | null;
  insurancePolicyWorklistItemId?: UuidFilter | null;
  completedAt?: DateTimeNullableFilter | null;
  completedById?: UuidNullableFilter | null;
  skippedAt?: DateTimeNullableFilter | null;
  skippedById?: UuidNullableFilter | null;
  type?: EnumInsurancePolicyWorklistItemTaskTypeFilter | null;
  title?: StringFilter | null;
  body?: StringNullableFilter | null;
  insurancePolicyWorklistItem?: InsurancePolicyWorklistItemRelationFilter | null;
  completedBy?: UserRelationFilter | null;
  skippedBy?: UserRelationFilter | null;
}

export interface InsurancePolicyWorklistItemTaskWhereUniqueInput {
  id?: string | null;
}

export interface InsurancePolicyWorklistItemUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  reason?: EnumInsurancePolicyWorklistReasonFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  expiredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
}

export interface InsurancePolicyWorklistItemUpdateManyWithWhereWithoutAppointmentInput {
  where: InsurancePolicyWorklistItemScalarWhereInput;
  data: InsurancePolicyWorklistItemUpdateManyMutationInput;
}

export interface InsurancePolicyWorklistItemUpdateManyWithWhereWithoutCanceledByInput {
  where: InsurancePolicyWorklistItemScalarWhereInput;
  data: InsurancePolicyWorklistItemUpdateManyMutationInput;
}

export interface InsurancePolicyWorklistItemUpdateManyWithWhereWithoutCompletedByInput {
  where: InsurancePolicyWorklistItemScalarWhereInput;
  data: InsurancePolicyWorklistItemUpdateManyMutationInput;
}

export interface InsurancePolicyWorklistItemUpdateManyWithWhereWithoutInsurancePolicyInput {
  where: InsurancePolicyWorklistItemScalarWhereInput;
  data: InsurancePolicyWorklistItemUpdateManyMutationInput;
}

export interface InsurancePolicyWorklistItemUpdateManyWithoutAppointmentNestedInput {
  create?: InsurancePolicyWorklistItemCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: InsurancePolicyWorklistItemUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: InsurancePolicyWorklistItemCreateManyAppointmentInputEnvelope | null;
  set?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  delete?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  connect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  update?: InsurancePolicyWorklistItemUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: InsurancePolicyWorklistItemUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: InsurancePolicyWorklistItemScalarWhereInput[] | null;
}

export interface InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput {
  create?: InsurancePolicyWorklistItemCreateWithoutCanceledByInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemCreateOrConnectWithoutCanceledByInput[] | null;
  upsert?: InsurancePolicyWorklistItemUpsertWithWhereUniqueWithoutCanceledByInput[] | null;
  createMany?: InsurancePolicyWorklistItemCreateManyCanceledByInputEnvelope | null;
  set?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  delete?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  connect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  update?: InsurancePolicyWorklistItemUpdateWithWhereUniqueWithoutCanceledByInput[] | null;
  updateMany?: InsurancePolicyWorklistItemUpdateManyWithWhereWithoutCanceledByInput[] | null;
  deleteMany?: InsurancePolicyWorklistItemScalarWhereInput[] | null;
}

export interface InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput {
  create?: InsurancePolicyWorklistItemCreateWithoutCompletedByInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemCreateOrConnectWithoutCompletedByInput[] | null;
  upsert?: InsurancePolicyWorklistItemUpsertWithWhereUniqueWithoutCompletedByInput[] | null;
  createMany?: InsurancePolicyWorklistItemCreateManyCompletedByInputEnvelope | null;
  set?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  delete?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  connect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  update?: InsurancePolicyWorklistItemUpdateWithWhereUniqueWithoutCompletedByInput[] | null;
  updateMany?: InsurancePolicyWorklistItemUpdateManyWithWhereWithoutCompletedByInput[] | null;
  deleteMany?: InsurancePolicyWorklistItemScalarWhereInput[] | null;
}

export interface InsurancePolicyWorklistItemUpdateManyWithoutInsurancePolicyNestedInput {
  create?: InsurancePolicyWorklistItemCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: InsurancePolicyWorklistItemCreateOrConnectWithoutInsurancePolicyInput[] | null;
  upsert?: InsurancePolicyWorklistItemUpsertWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  createMany?: InsurancePolicyWorklistItemCreateManyInsurancePolicyInputEnvelope | null;
  set?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  disconnect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  delete?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  connect?: InsurancePolicyWorklistItemWhereUniqueInput[] | null;
  update?: InsurancePolicyWorklistItemUpdateWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  updateMany?: InsurancePolicyWorklistItemUpdateManyWithWhereWithoutInsurancePolicyInput[] | null;
  deleteMany?: InsurancePolicyWorklistItemScalarWhereInput[] | null;
}

export interface InsurancePolicyWorklistItemUpdateOneRequiredWithoutInsurancePolicyWorklistItemTasksNestedInput {
  create?: InsurancePolicyWorklistItemCreateWithoutInsurancePolicyWorklistItemTasksInput | null;
  connectOrCreate?: InsurancePolicyWorklistItemCreateOrConnectWithoutInsurancePolicyWorklistItemTasksInput | null;
  upsert?: InsurancePolicyWorklistItemUpsertWithoutInsurancePolicyWorklistItemTasksInput | null;
  connect?: InsurancePolicyWorklistItemWhereUniqueInput | null;
  update?: InsurancePolicyWorklistItemUpdateWithoutInsurancePolicyWorklistItemTasksInput | null;
}

export interface InsurancePolicyWorklistItemUpdateWithWhereUniqueWithoutAppointmentInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  data: InsurancePolicyWorklistItemUpdateWithoutAppointmentInput;
}

export interface InsurancePolicyWorklistItemUpdateWithWhereUniqueWithoutCanceledByInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  data: InsurancePolicyWorklistItemUpdateWithoutCanceledByInput;
}

export interface InsurancePolicyWorklistItemUpdateWithWhereUniqueWithoutCompletedByInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  data: InsurancePolicyWorklistItemUpdateWithoutCompletedByInput;
}

export interface InsurancePolicyWorklistItemUpdateWithWhereUniqueWithoutInsurancePolicyInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  data: InsurancePolicyWorklistItemUpdateWithoutInsurancePolicyInput;
}

export interface InsurancePolicyWorklistItemUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  reason?: EnumInsurancePolicyWorklistReasonFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  expiredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutInsurancePolicyWorklistItemsNestedInput | null;
  completedBy?: UserUpdateOneWithoutCompletedInsurancePolicyWorklistItemsNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledInsurancePolicyWorklistItemsNestedInput | null;
  insurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutInsurancePolicyWorklistItemNestedInput | null;
}

export interface InsurancePolicyWorklistItemUpdateWithoutCanceledByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  reason?: EnumInsurancePolicyWorklistReasonFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  expiredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutInsurancePolicyWorklistItemsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutInsurancePolicyWorklistItemsNestedInput | null;
  completedBy?: UserUpdateOneWithoutCompletedInsurancePolicyWorklistItemsNestedInput | null;
  insurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutInsurancePolicyWorklistItemNestedInput | null;
}

export interface InsurancePolicyWorklistItemUpdateWithoutCompletedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  reason?: EnumInsurancePolicyWorklistReasonFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  expiredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutInsurancePolicyWorklistItemsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutInsurancePolicyWorklistItemsNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledInsurancePolicyWorklistItemsNestedInput | null;
  insurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutInsurancePolicyWorklistItemNestedInput | null;
}

export interface InsurancePolicyWorklistItemUpdateWithoutInsurancePolicyInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  reason?: EnumInsurancePolicyWorklistReasonFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  expiredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneWithoutInsurancePolicyWorklistItemsNestedInput | null;
  completedBy?: UserUpdateOneWithoutCompletedInsurancePolicyWorklistItemsNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledInsurancePolicyWorklistItemsNestedInput | null;
  insurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutInsurancePolicyWorklistItemNestedInput | null;
}

export interface InsurancePolicyWorklistItemUpdateWithoutInsurancePolicyWorklistItemTasksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  reason?: EnumInsurancePolicyWorklistReasonFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  expiredAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneRequiredWithoutInsurancePolicyWorklistItemsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutInsurancePolicyWorklistItemsNestedInput | null;
  completedBy?: UserUpdateOneWithoutCompletedInsurancePolicyWorklistItemsNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledInsurancePolicyWorklistItemsNestedInput | null;
}

export interface InsurancePolicyWorklistItemUpsertWithWhereUniqueWithoutAppointmentInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  update: InsurancePolicyWorklistItemUpdateWithoutAppointmentInput;
  create: InsurancePolicyWorklistItemCreateWithoutAppointmentInput;
}

export interface InsurancePolicyWorklistItemUpsertWithWhereUniqueWithoutCanceledByInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  update: InsurancePolicyWorklistItemUpdateWithoutCanceledByInput;
  create: InsurancePolicyWorklistItemCreateWithoutCanceledByInput;
}

export interface InsurancePolicyWorklistItemUpsertWithWhereUniqueWithoutCompletedByInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  update: InsurancePolicyWorklistItemUpdateWithoutCompletedByInput;
  create: InsurancePolicyWorklistItemCreateWithoutCompletedByInput;
}

export interface InsurancePolicyWorklistItemUpsertWithWhereUniqueWithoutInsurancePolicyInput {
  where: InsurancePolicyWorklistItemWhereUniqueInput;
  update: InsurancePolicyWorklistItemUpdateWithoutInsurancePolicyInput;
  create: InsurancePolicyWorklistItemCreateWithoutInsurancePolicyInput;
}

export interface InsurancePolicyWorklistItemUpsertWithoutInsurancePolicyWorklistItemTasksInput {
  update: InsurancePolicyWorklistItemUpdateWithoutInsurancePolicyWorklistItemTasksInput;
  create: InsurancePolicyWorklistItemCreateWithoutInsurancePolicyWorklistItemTasksInput;
}

export interface InsurancePolicyWorklistItemWhereInput {
  AND?: InsurancePolicyWorklistItemWhereInput[] | null;
  OR?: InsurancePolicyWorklistItemWhereInput[] | null;
  NOT?: InsurancePolicyWorklistItemWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  insurancePolicyId?: UuidFilter | null;
  appointmentId?: UuidNullableFilter | null;
  reason?: EnumInsurancePolicyWorklistReasonFilter | null;
  completedAt?: DateTimeNullableFilter | null;
  completedById?: UuidNullableFilter | null;
  canceledAt?: DateTimeNullableFilter | null;
  canceledById?: UuidNullableFilter | null;
  expiredAt?: DateTimeNullableFilter | null;
  insurancePolicy?: InsurancePolicyRelationFilter | null;
  appointment?: AppointmentRelationFilter | null;
  completedBy?: UserRelationFilter | null;
  canceledBy?: UserRelationFilter | null;
  insurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskListRelationFilter | null;
}

export interface InsurancePolicyWorklistItemWhereUniqueInput {
  id?: string | null;
}

export interface IntFieldUpdateOperationsInput {
  set?: number | null;
  increment?: number | null;
  decrement?: number | null;
  multiply?: number | null;
  divide?: number | null;
}

export interface IntFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntFilter | null;
}

export interface IntNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntNullableFilter | null;
}

export interface IntegrationCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  type: IntegrationType;
  organizationId: string;
  databaseConnectionUrl?: string | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: boolean | null;
  timezone?: string | null;
  paymentPostingMethod?: PaymentMethod | null;
  chirotouchProviderPaymentPostingBehavior?: string | null;
}

export interface IntegrationCreateManyLocationInputEnvelope {
  data: IntegrationCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  type: IntegrationType;
  locationId: string;
  databaseConnectionUrl?: string | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: boolean | null;
  timezone?: string | null;
  paymentPostingMethod?: PaymentMethod | null;
  chirotouchProviderPaymentPostingBehavior?: string | null;
}

export interface IntegrationCreateManyOrganizationInputEnvelope {
  data: IntegrationCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationCreateNestedManyWithoutLocationInput {
  create?: IntegrationCreateWithoutLocationInput[] | null;
  connectOrCreate?: IntegrationCreateOrConnectWithoutLocationInput[] | null;
  createMany?: IntegrationCreateManyLocationInputEnvelope | null;
  connect?: IntegrationWhereUniqueInput[] | null;
}

export interface IntegrationCreateNestedManyWithoutOrganizationInput {
  create?: IntegrationCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: IntegrationCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: IntegrationCreateManyOrganizationInputEnvelope | null;
  connect?: IntegrationWhereUniqueInput[] | null;
}

export interface IntegrationCreateNestedOneWithoutIntegrationLinksInput {
  create?: IntegrationCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: IntegrationCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: IntegrationWhereUniqueInput | null;
}

export interface IntegrationCreateNestedOneWithoutIntegrationSyncSchedulesInput {
  create?: IntegrationCreateWithoutIntegrationSyncSchedulesInput | null;
  connectOrCreate?: IntegrationCreateOrConnectWithoutIntegrationSyncSchedulesInput | null;
  connect?: IntegrationWhereUniqueInput | null;
}

export interface IntegrationCreateNestedOneWithoutIntegrationSyncsInput {
  create?: IntegrationCreateWithoutIntegrationSyncsInput | null;
  connectOrCreate?: IntegrationCreateOrConnectWithoutIntegrationSyncsInput | null;
  connect?: IntegrationWhereUniqueInput | null;
}

export interface IntegrationCreateOrConnectWithoutIntegrationLinksInput {
  where: IntegrationWhereUniqueInput;
  create: IntegrationCreateWithoutIntegrationLinksInput;
}

export interface IntegrationCreateOrConnectWithoutIntegrationSyncSchedulesInput {
  where: IntegrationWhereUniqueInput;
  create: IntegrationCreateWithoutIntegrationSyncSchedulesInput;
}

export interface IntegrationCreateOrConnectWithoutIntegrationSyncsInput {
  where: IntegrationWhereUniqueInput;
  create: IntegrationCreateWithoutIntegrationSyncsInput;
}

export interface IntegrationCreateOrConnectWithoutLocationInput {
  where: IntegrationWhereUniqueInput;
  create: IntegrationCreateWithoutLocationInput;
}

export interface IntegrationCreateOrConnectWithoutOrganizationInput {
  where: IntegrationWhereUniqueInput;
  create: IntegrationCreateWithoutOrganizationInput;
}

export interface IntegrationCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  type: IntegrationType;
  databaseConnectionUrl?: string | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: boolean | null;
  timezone?: string | null;
  paymentPostingMethod?: PaymentMethod | null;
  chirotouchProviderPaymentPostingBehavior?: string | null;
  organization: OrganizationCreateNestedOneWithoutIntegrationsInput;
  location: LocationCreateNestedOneWithoutIntegrationsInput;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutIntegrationInput | null;
  integrationSyncSchedules?: IntegrationSyncScheduleCreateNestedManyWithoutIntegrationInput | null;
}

export interface IntegrationCreateWithoutIntegrationSyncSchedulesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  type: IntegrationType;
  databaseConnectionUrl?: string | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: boolean | null;
  timezone?: string | null;
  paymentPostingMethod?: PaymentMethod | null;
  chirotouchProviderPaymentPostingBehavior?: string | null;
  organization: OrganizationCreateNestedOneWithoutIntegrationsInput;
  location: LocationCreateNestedOneWithoutIntegrationsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutIntegrationInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutIntegrationInput | null;
}

export interface IntegrationCreateWithoutIntegrationSyncsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  type: IntegrationType;
  databaseConnectionUrl?: string | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: boolean | null;
  timezone?: string | null;
  paymentPostingMethod?: PaymentMethod | null;
  chirotouchProviderPaymentPostingBehavior?: string | null;
  organization: OrganizationCreateNestedOneWithoutIntegrationsInput;
  location: LocationCreateNestedOneWithoutIntegrationsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutIntegrationInput | null;
  integrationSyncSchedules?: IntegrationSyncScheduleCreateNestedManyWithoutIntegrationInput | null;
}

export interface IntegrationCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  type: IntegrationType;
  databaseConnectionUrl?: string | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: boolean | null;
  timezone?: string | null;
  paymentPostingMethod?: PaymentMethod | null;
  chirotouchProviderPaymentPostingBehavior?: string | null;
  organization: OrganizationCreateNestedOneWithoutIntegrationsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutIntegrationInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutIntegrationInput | null;
  integrationSyncSchedules?: IntegrationSyncScheduleCreateNestedManyWithoutIntegrationInput | null;
}

export interface IntegrationCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  type: IntegrationType;
  databaseConnectionUrl?: string | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: boolean | null;
  timezone?: string | null;
  paymentPostingMethod?: PaymentMethod | null;
  chirotouchProviderPaymentPostingBehavior?: string | null;
  location: LocationCreateNestedOneWithoutIntegrationsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutIntegrationInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutIntegrationInput | null;
  integrationSyncSchedules?: IntegrationSyncScheduleCreateNestedManyWithoutIntegrationInput | null;
}

export interface IntegrationLinkCreateManyAccountCoverageInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyAccountCoverageInputEnvelope {
  data: IntegrationLinkCreateManyAccountCoverageInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyAccountInputEnvelope {
  data: IntegrationLinkCreateManyAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyAppointmentInputEnvelope {
  data: IntegrationLinkCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyBillInputEnvelope {
  data: IntegrationLinkCreateManyBillInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyBillPaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
}

export interface IntegrationLinkCreateManyBillPaymentInputEnvelope {
  data: IntegrationLinkCreateManyBillPaymentInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyChargePaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyChargePaymentInputEnvelope {
  data: IntegrationLinkCreateManyChargePaymentInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyClaimInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyClaimInputEnvelope {
  data: IntegrationLinkCreateManyClaimInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyClaimLineInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyClaimLineInputEnvelope {
  data: IntegrationLinkCreateManyClaimLineInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyClaimRemittanceInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyClaimRemittanceInputEnvelope {
  data: IntegrationLinkCreateManyClaimRemittanceInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyClaimRemittanceLineInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyClaimRemittanceLineInputEnvelope {
  data: IntegrationLinkCreateManyClaimRemittanceLineInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyExternalPaymentMethodsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyExternalPaymentMethodsInputEnvelope {
  data: IntegrationLinkCreateManyExternalPaymentMethodsInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyInsuranceBillableChargeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyInsuranceBillableChargeInputEnvelope {
  data: IntegrationLinkCreateManyInsuranceBillableChargeInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyIntegrationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyIntegrationInputEnvelope {
  data: IntegrationLinkCreateManyIntegrationInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyPatientInputEnvelope {
  data: IntegrationLinkCreateManyPatientInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyPayerInputEnvelope {
  data: IntegrationLinkCreateManyPayerInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  transactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyProviderInputEnvelope {
  data: IntegrationLinkCreateManyProviderInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateManyTransactionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  accountId?: string | null;
  appointmentId?: string | null;
  claimId?: string | null;
  claimLineId?: string | null;
  claimRemittanceId?: string | null;
  claimRemittanceLineId?: string | null;
  chargePaymentId?: string | null;
  accountCoverageId?: string | null;
  externalPaymentMethodId?: string | null;
  insuranceBillableChargeId?: string | null;
  patientId?: string | null;
  payerId?: string | null;
  providerId?: string | null;
  statementId?: string | null;
  statementTransactionId?: string | null;
  billId?: string | null;
  billPaymentId?: string | null;
}

export interface IntegrationLinkCreateManyTransactionInputEnvelope {
  data: IntegrationLinkCreateManyTransactionInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkCreateNestedManyWithoutAccountCoverageInput {
  create?: IntegrationLinkCreateWithoutAccountCoverageInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutAccountCoverageInput[] | null;
  createMany?: IntegrationLinkCreateManyAccountCoverageInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutAccountInput {
  create?: IntegrationLinkCreateWithoutAccountInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutAccountInput[] | null;
  createMany?: IntegrationLinkCreateManyAccountInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutAppointmentInput {
  create?: IntegrationLinkCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: IntegrationLinkCreateManyAppointmentInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutBillInput {
  create?: IntegrationLinkCreateWithoutBillInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutBillInput[] | null;
  createMany?: IntegrationLinkCreateManyBillInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutBillPaymentInput {
  create?: IntegrationLinkCreateWithoutBillPaymentInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutBillPaymentInput[] | null;
  createMany?: IntegrationLinkCreateManyBillPaymentInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutChargePaymentInput {
  create?: IntegrationLinkCreateWithoutChargePaymentInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutChargePaymentInput[] | null;
  createMany?: IntegrationLinkCreateManyChargePaymentInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutClaimInput {
  create?: IntegrationLinkCreateWithoutClaimInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutClaimInput[] | null;
  createMany?: IntegrationLinkCreateManyClaimInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutClaimLineInput {
  create?: IntegrationLinkCreateWithoutClaimLineInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutClaimLineInput[] | null;
  createMany?: IntegrationLinkCreateManyClaimLineInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutClaimRemittanceInput {
  create?: IntegrationLinkCreateWithoutClaimRemittanceInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutClaimRemittanceInput[] | null;
  createMany?: IntegrationLinkCreateManyClaimRemittanceInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutClaimRemittanceLineInput {
  create?: IntegrationLinkCreateWithoutClaimRemittanceLineInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutClaimRemittanceLineInput[] | null;
  createMany?: IntegrationLinkCreateManyClaimRemittanceLineInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutExternalPaymentMethodsInput {
  create?: IntegrationLinkCreateWithoutExternalPaymentMethodsInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutExternalPaymentMethodsInput[] | null;
  createMany?: IntegrationLinkCreateManyExternalPaymentMethodsInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutInsuranceBillableChargeInput {
  create?: IntegrationLinkCreateWithoutInsuranceBillableChargeInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutInsuranceBillableChargeInput[] | null;
  createMany?: IntegrationLinkCreateManyInsuranceBillableChargeInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutIntegrationInput {
  create?: IntegrationLinkCreateWithoutIntegrationInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutIntegrationInput[] | null;
  createMany?: IntegrationLinkCreateManyIntegrationInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutPatientInput {
  create?: IntegrationLinkCreateWithoutPatientInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutPatientInput[] | null;
  createMany?: IntegrationLinkCreateManyPatientInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutPayerInput {
  create?: IntegrationLinkCreateWithoutPayerInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutPayerInput[] | null;
  createMany?: IntegrationLinkCreateManyPayerInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutProviderInput {
  create?: IntegrationLinkCreateWithoutProviderInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutProviderInput[] | null;
  createMany?: IntegrationLinkCreateManyProviderInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedManyWithoutTransactionInput {
  create?: IntegrationLinkCreateWithoutTransactionInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutTransactionInput[] | null;
  createMany?: IntegrationLinkCreateManyTransactionInputEnvelope | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
}

export interface IntegrationLinkCreateNestedOneWithoutIntegrationLinkSyncsInput {
  create?: IntegrationLinkCreateWithoutIntegrationLinkSyncsInput | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutIntegrationLinkSyncsInput | null;
  connect?: IntegrationLinkWhereUniqueInput | null;
}

export interface IntegrationLinkCreateOrConnectWithoutAccountCoverageInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutAccountCoverageInput;
}

export interface IntegrationLinkCreateOrConnectWithoutAccountInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutAccountInput;
}

export interface IntegrationLinkCreateOrConnectWithoutAppointmentInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutAppointmentInput;
}

export interface IntegrationLinkCreateOrConnectWithoutBillInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutBillInput;
}

export interface IntegrationLinkCreateOrConnectWithoutBillPaymentInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutBillPaymentInput;
}

export interface IntegrationLinkCreateOrConnectWithoutChargePaymentInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutChargePaymentInput;
}

export interface IntegrationLinkCreateOrConnectWithoutClaimInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutClaimInput;
}

export interface IntegrationLinkCreateOrConnectWithoutClaimLineInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutClaimLineInput;
}

export interface IntegrationLinkCreateOrConnectWithoutClaimRemittanceInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutClaimRemittanceInput;
}

export interface IntegrationLinkCreateOrConnectWithoutClaimRemittanceLineInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutClaimRemittanceLineInput;
}

export interface IntegrationLinkCreateOrConnectWithoutExternalPaymentMethodsInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutExternalPaymentMethodsInput;
}

export interface IntegrationLinkCreateOrConnectWithoutInsuranceBillableChargeInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutInsuranceBillableChargeInput;
}

export interface IntegrationLinkCreateOrConnectWithoutIntegrationInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutIntegrationInput;
}

export interface IntegrationLinkCreateOrConnectWithoutIntegrationLinkSyncsInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutIntegrationLinkSyncsInput;
}

export interface IntegrationLinkCreateOrConnectWithoutPatientInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutPatientInput;
}

export interface IntegrationLinkCreateOrConnectWithoutPayerInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutPayerInput;
}

export interface IntegrationLinkCreateOrConnectWithoutProviderInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutProviderInput;
}

export interface IntegrationLinkCreateOrConnectWithoutTransactionInput {
  where: IntegrationLinkWhereUniqueInput;
  create: IntegrationLinkCreateWithoutTransactionInput;
}

export interface IntegrationLinkCreateWithoutAccountCoverageInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutBillPaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutChargePaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutClaimInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutClaimLineInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutClaimRemittanceInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutClaimRemittanceLineInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutExternalPaymentMethodsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutInsuranceBillableChargeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutIntegrationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutIntegrationLinkSyncsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
}

export interface IntegrationLinkCreateWithoutPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  transaction?: TransactionCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkCreateWithoutTransactionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: IntegrationLinkType;
  reference: string;
  lastCheckedAt?: any | null;
  hash: string;
  statementId?: string | null;
  statementTransactionId?: string | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationLinksInput;
  account?: AccountCreateNestedOneWithoutIntegrationLinksInput | null;
  appointment?: AppointmentCreateNestedOneWithoutIntegrationLinksInput | null;
  claim?: ClaimCreateNestedOneWithoutIntegrationLinksInput | null;
  claimLine?: ClaimLineCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittance?: ClaimRemittanceCreateNestedOneWithoutIntegrationLinksInput | null;
  claimRemittanceLine?: ClaimRemittanceLineCreateNestedOneWithoutIntegrationLinksInput | null;
  chargePayment?: ChargePaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutIntegrationLinksInput | null;
  externalPaymentMethods?: ExternalPaymentMethodCreateNestedOneWithoutIntegrationLinksInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeCreateNestedOneWithoutIntegrationLinksInput | null;
  patient?: PatientCreateNestedOneWithoutIntegrationLinksInput | null;
  payer?: PayerCreateNestedOneWithoutIntegrationLinksInput | null;
  provider?: ProviderCreateNestedOneWithoutIntegrationLinksInput | null;
  bill?: BillCreateNestedOneWithoutIntegrationLinksInput | null;
  billPayment?: BillPaymentCreateNestedOneWithoutIntegrationLinksInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput | null;
}

export interface IntegrationLinkIntegrationIdTypeReferenceCompoundUniqueInput {
  integrationId: string;
  type: IntegrationLinkType;
  reference: string;
}

export interface IntegrationLinkListRelationFilter {
  every?: IntegrationLinkWhereInput | null;
  some?: IntegrationLinkWhereInput | null;
  none?: IntegrationLinkWhereInput | null;
}

export interface IntegrationLinkOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface IntegrationLinkRelationFilter {
  is?: IntegrationLinkWhereInput | null;
  isNot?: IntegrationLinkWhereInput | null;
}

export interface IntegrationLinkScalarWhereInput {
  AND?: IntegrationLinkScalarWhereInput[] | null;
  OR?: IntegrationLinkScalarWhereInput[] | null;
  NOT?: IntegrationLinkScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  integrationId?: UuidFilter | null;
  type?: EnumIntegrationLinkTypeFilter | null;
  reference?: StringFilter | null;
  lastCheckedAt?: DateTimeNullableFilter | null;
  hash?: StringFilter | null;
  accountId?: UuidNullableFilter | null;
  appointmentId?: UuidNullableFilter | null;
  claimId?: UuidNullableFilter | null;
  claimLineId?: UuidNullableFilter | null;
  claimRemittanceId?: UuidNullableFilter | null;
  claimRemittanceLineId?: UuidNullableFilter | null;
  chargePaymentId?: UuidNullableFilter | null;
  accountCoverageId?: UuidNullableFilter | null;
  externalPaymentMethodId?: UuidNullableFilter | null;
  insuranceBillableChargeId?: UuidNullableFilter | null;
  patientId?: UuidNullableFilter | null;
  payerId?: UuidNullableFilter | null;
  providerId?: UuidNullableFilter | null;
  statementId?: UuidNullableFilter | null;
  statementTransactionId?: UuidNullableFilter | null;
  transactionId?: UuidNullableFilter | null;
  billId?: UuidNullableFilter | null;
  billPaymentId?: UuidNullableFilter | null;
}

export interface IntegrationLinkSyncCreateManyIntegrationLinkInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  direction: SyncDirection;
  integrationSyncId?: string | null;
}

export interface IntegrationLinkSyncCreateManyIntegrationLinkInputEnvelope {
  data: IntegrationLinkSyncCreateManyIntegrationLinkInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkSyncCreateManyIntegrationSyncInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  direction: SyncDirection;
  integrationLinkId: string;
}

export interface IntegrationLinkSyncCreateManyIntegrationSyncInputEnvelope {
  data: IntegrationLinkSyncCreateManyIntegrationSyncInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkSyncCreateNestedManyWithoutIntegrationLinkInput {
  create?: IntegrationLinkSyncCreateWithoutIntegrationLinkInput[] | null;
  connectOrCreate?: IntegrationLinkSyncCreateOrConnectWithoutIntegrationLinkInput[] | null;
  createMany?: IntegrationLinkSyncCreateManyIntegrationLinkInputEnvelope | null;
  connect?: IntegrationLinkSyncWhereUniqueInput[] | null;
}

export interface IntegrationLinkSyncCreateNestedManyWithoutIntegrationSyncInput {
  create?: IntegrationLinkSyncCreateWithoutIntegrationSyncInput[] | null;
  connectOrCreate?: IntegrationLinkSyncCreateOrConnectWithoutIntegrationSyncInput[] | null;
  createMany?: IntegrationLinkSyncCreateManyIntegrationSyncInputEnvelope | null;
  connect?: IntegrationLinkSyncWhereUniqueInput[] | null;
}

export interface IntegrationLinkSyncCreateOrConnectWithoutIntegrationLinkInput {
  where: IntegrationLinkSyncWhereUniqueInput;
  create: IntegrationLinkSyncCreateWithoutIntegrationLinkInput;
}

export interface IntegrationLinkSyncCreateOrConnectWithoutIntegrationSyncInput {
  where: IntegrationLinkSyncWhereUniqueInput;
  create: IntegrationLinkSyncCreateWithoutIntegrationSyncInput;
}

export interface IntegrationLinkSyncCreateWithoutIntegrationLinkInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  direction: SyncDirection;
  integrationSync?: IntegrationSyncCreateNestedOneWithoutIntegrationLinkSyncsInput | null;
  integrationLinkSyncErrors?: IntegrationLinkSyncErrorCreateNestedManyWithoutIntegrationLinkSyncInput | null;
}

export interface IntegrationLinkSyncCreateWithoutIntegrationSyncInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  direction: SyncDirection;
  integrationLink: IntegrationLinkCreateNestedOneWithoutIntegrationLinkSyncsInput;
  integrationLinkSyncErrors?: IntegrationLinkSyncErrorCreateNestedManyWithoutIntegrationLinkSyncInput | null;
}

export interface IntegrationLinkSyncErrorCreateManyIntegrationLinkSyncInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  message: string;
}

export interface IntegrationLinkSyncErrorCreateManyIntegrationLinkSyncInputEnvelope {
  data: IntegrationLinkSyncErrorCreateManyIntegrationLinkSyncInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationLinkSyncErrorCreateNestedManyWithoutIntegrationLinkSyncInput {
  create?: IntegrationLinkSyncErrorCreateWithoutIntegrationLinkSyncInput[] | null;
  connectOrCreate?: IntegrationLinkSyncErrorCreateOrConnectWithoutIntegrationLinkSyncInput[] | null;
  createMany?: IntegrationLinkSyncErrorCreateManyIntegrationLinkSyncInputEnvelope | null;
  connect?: IntegrationLinkSyncErrorWhereUniqueInput[] | null;
}

export interface IntegrationLinkSyncErrorCreateOrConnectWithoutIntegrationLinkSyncInput {
  where: IntegrationLinkSyncErrorWhereUniqueInput;
  create: IntegrationLinkSyncErrorCreateWithoutIntegrationLinkSyncInput;
}

export interface IntegrationLinkSyncErrorCreateWithoutIntegrationLinkSyncInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  message: string;
}

export interface IntegrationLinkSyncErrorListRelationFilter {
  every?: IntegrationLinkSyncErrorWhereInput | null;
  some?: IntegrationLinkSyncErrorWhereInput | null;
  none?: IntegrationLinkSyncErrorWhereInput | null;
}

export interface IntegrationLinkSyncErrorScalarWhereInput {
  AND?: IntegrationLinkSyncErrorScalarWhereInput[] | null;
  OR?: IntegrationLinkSyncErrorScalarWhereInput[] | null;
  NOT?: IntegrationLinkSyncErrorScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  message?: StringFilter | null;
  integrationLinkSyncId?: UuidFilter | null;
}

export interface IntegrationLinkSyncErrorUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  message?: StringFieldUpdateOperationsInput | null;
}

export interface IntegrationLinkSyncErrorUpdateManyWithWhereWithoutIntegrationLinkSyncInput {
  where: IntegrationLinkSyncErrorScalarWhereInput;
  data: IntegrationLinkSyncErrorUpdateManyMutationInput;
}

export interface IntegrationLinkSyncErrorUpdateManyWithoutIntegrationLinkSyncNestedInput {
  create?: IntegrationLinkSyncErrorCreateWithoutIntegrationLinkSyncInput[] | null;
  connectOrCreate?: IntegrationLinkSyncErrorCreateOrConnectWithoutIntegrationLinkSyncInput[] | null;
  upsert?: IntegrationLinkSyncErrorUpsertWithWhereUniqueWithoutIntegrationLinkSyncInput[] | null;
  createMany?: IntegrationLinkSyncErrorCreateManyIntegrationLinkSyncInputEnvelope | null;
  set?: IntegrationLinkSyncErrorWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkSyncErrorWhereUniqueInput[] | null;
  delete?: IntegrationLinkSyncErrorWhereUniqueInput[] | null;
  connect?: IntegrationLinkSyncErrorWhereUniqueInput[] | null;
  update?: IntegrationLinkSyncErrorUpdateWithWhereUniqueWithoutIntegrationLinkSyncInput[] | null;
  updateMany?: IntegrationLinkSyncErrorUpdateManyWithWhereWithoutIntegrationLinkSyncInput[] | null;
  deleteMany?: IntegrationLinkSyncErrorScalarWhereInput[] | null;
}

export interface IntegrationLinkSyncErrorUpdateWithWhereUniqueWithoutIntegrationLinkSyncInput {
  where: IntegrationLinkSyncErrorWhereUniqueInput;
  data: IntegrationLinkSyncErrorUpdateWithoutIntegrationLinkSyncInput;
}

export interface IntegrationLinkSyncErrorUpdateWithoutIntegrationLinkSyncInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  message?: StringFieldUpdateOperationsInput | null;
}

export interface IntegrationLinkSyncErrorUpsertWithWhereUniqueWithoutIntegrationLinkSyncInput {
  where: IntegrationLinkSyncErrorWhereUniqueInput;
  update: IntegrationLinkSyncErrorUpdateWithoutIntegrationLinkSyncInput;
  create: IntegrationLinkSyncErrorCreateWithoutIntegrationLinkSyncInput;
}

export interface IntegrationLinkSyncErrorWhereInput {
  AND?: IntegrationLinkSyncErrorWhereInput[] | null;
  OR?: IntegrationLinkSyncErrorWhereInput[] | null;
  NOT?: IntegrationLinkSyncErrorWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  message?: StringFilter | null;
  integrationLinkSyncId?: UuidFilter | null;
  integrationLinkSync?: IntegrationLinkSyncRelationFilter | null;
}

export interface IntegrationLinkSyncErrorWhereUniqueInput {
  id?: string | null;
}

export interface IntegrationLinkSyncListRelationFilter {
  every?: IntegrationLinkSyncWhereInput | null;
  some?: IntegrationLinkSyncWhereInput | null;
  none?: IntegrationLinkSyncWhereInput | null;
}

export interface IntegrationLinkSyncRelationFilter {
  is?: IntegrationLinkSyncWhereInput | null;
  isNot?: IntegrationLinkSyncWhereInput | null;
}

export interface IntegrationLinkSyncScalarWhereInput {
  AND?: IntegrationLinkSyncScalarWhereInput[] | null;
  OR?: IntegrationLinkSyncScalarWhereInput[] | null;
  NOT?: IntegrationLinkSyncScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  direction?: EnumSyncDirectionFilter | null;
  integrationLinkId?: UuidFilter | null;
  integrationSyncId?: UuidNullableFilter | null;
}

export interface IntegrationLinkSyncUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  direction?: EnumSyncDirectionFieldUpdateOperationsInput | null;
}

export interface IntegrationLinkSyncUpdateManyWithWhereWithoutIntegrationLinkInput {
  where: IntegrationLinkSyncScalarWhereInput;
  data: IntegrationLinkSyncUpdateManyMutationInput;
}

export interface IntegrationLinkSyncUpdateManyWithWhereWithoutIntegrationSyncInput {
  where: IntegrationLinkSyncScalarWhereInput;
  data: IntegrationLinkSyncUpdateManyMutationInput;
}

export interface IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput {
  create?: IntegrationLinkSyncCreateWithoutIntegrationLinkInput[] | null;
  connectOrCreate?: IntegrationLinkSyncCreateOrConnectWithoutIntegrationLinkInput[] | null;
  upsert?: IntegrationLinkSyncUpsertWithWhereUniqueWithoutIntegrationLinkInput[] | null;
  createMany?: IntegrationLinkSyncCreateManyIntegrationLinkInputEnvelope | null;
  set?: IntegrationLinkSyncWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkSyncWhereUniqueInput[] | null;
  delete?: IntegrationLinkSyncWhereUniqueInput[] | null;
  connect?: IntegrationLinkSyncWhereUniqueInput[] | null;
  update?: IntegrationLinkSyncUpdateWithWhereUniqueWithoutIntegrationLinkInput[] | null;
  updateMany?: IntegrationLinkSyncUpdateManyWithWhereWithoutIntegrationLinkInput[] | null;
  deleteMany?: IntegrationLinkSyncScalarWhereInput[] | null;
}

export interface IntegrationLinkSyncUpdateManyWithoutIntegrationSyncNestedInput {
  create?: IntegrationLinkSyncCreateWithoutIntegrationSyncInput[] | null;
  connectOrCreate?: IntegrationLinkSyncCreateOrConnectWithoutIntegrationSyncInput[] | null;
  upsert?: IntegrationLinkSyncUpsertWithWhereUniqueWithoutIntegrationSyncInput[] | null;
  createMany?: IntegrationLinkSyncCreateManyIntegrationSyncInputEnvelope | null;
  set?: IntegrationLinkSyncWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkSyncWhereUniqueInput[] | null;
  delete?: IntegrationLinkSyncWhereUniqueInput[] | null;
  connect?: IntegrationLinkSyncWhereUniqueInput[] | null;
  update?: IntegrationLinkSyncUpdateWithWhereUniqueWithoutIntegrationSyncInput[] | null;
  updateMany?: IntegrationLinkSyncUpdateManyWithWhereWithoutIntegrationSyncInput[] | null;
  deleteMany?: IntegrationLinkSyncScalarWhereInput[] | null;
}

export interface IntegrationLinkSyncUpdateWithWhereUniqueWithoutIntegrationLinkInput {
  where: IntegrationLinkSyncWhereUniqueInput;
  data: IntegrationLinkSyncUpdateWithoutIntegrationLinkInput;
}

export interface IntegrationLinkSyncUpdateWithWhereUniqueWithoutIntegrationSyncInput {
  where: IntegrationLinkSyncWhereUniqueInput;
  data: IntegrationLinkSyncUpdateWithoutIntegrationSyncInput;
}

export interface IntegrationLinkSyncUpdateWithoutIntegrationLinkInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  direction?: EnumSyncDirectionFieldUpdateOperationsInput | null;
  integrationSync?: IntegrationSyncUpdateOneWithoutIntegrationLinkSyncsNestedInput | null;
  integrationLinkSyncErrors?: IntegrationLinkSyncErrorUpdateManyWithoutIntegrationLinkSyncNestedInput | null;
}

export interface IntegrationLinkSyncUpdateWithoutIntegrationSyncInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  direction?: EnumSyncDirectionFieldUpdateOperationsInput | null;
  integrationLink?: IntegrationLinkUpdateOneRequiredWithoutIntegrationLinkSyncsNestedInput | null;
  integrationLinkSyncErrors?: IntegrationLinkSyncErrorUpdateManyWithoutIntegrationLinkSyncNestedInput | null;
}

export interface IntegrationLinkSyncUpsertWithWhereUniqueWithoutIntegrationLinkInput {
  where: IntegrationLinkSyncWhereUniqueInput;
  update: IntegrationLinkSyncUpdateWithoutIntegrationLinkInput;
  create: IntegrationLinkSyncCreateWithoutIntegrationLinkInput;
}

export interface IntegrationLinkSyncUpsertWithWhereUniqueWithoutIntegrationSyncInput {
  where: IntegrationLinkSyncWhereUniqueInput;
  update: IntegrationLinkSyncUpdateWithoutIntegrationSyncInput;
  create: IntegrationLinkSyncCreateWithoutIntegrationSyncInput;
}

export interface IntegrationLinkSyncWhereInput {
  AND?: IntegrationLinkSyncWhereInput[] | null;
  OR?: IntegrationLinkSyncWhereInput[] | null;
  NOT?: IntegrationLinkSyncWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  direction?: EnumSyncDirectionFilter | null;
  integrationLinkId?: UuidFilter | null;
  integrationSyncId?: UuidNullableFilter | null;
  integrationLink?: IntegrationLinkRelationFilter | null;
  integrationSync?: IntegrationSyncRelationFilter | null;
  integrationLinkSyncErrors?: IntegrationLinkSyncErrorListRelationFilter | null;
}

export interface IntegrationLinkSyncWhereUniqueInput {
  id?: string | null;
}

export interface IntegrationLinkUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutAccountCoverageInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutAccountInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutAppointmentInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutBillInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutBillPaymentInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutChargePaymentInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutClaimInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutClaimLineInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutClaimRemittanceInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutClaimRemittanceLineInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutExternalPaymentMethodsInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutInsuranceBillableChargeInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutIntegrationInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutPatientInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutPayerInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutProviderInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithWhereWithoutTransactionInput {
  where: IntegrationLinkScalarWhereInput;
  data: IntegrationLinkUpdateManyMutationInput;
}

export interface IntegrationLinkUpdateManyWithoutAccountCoverageNestedInput {
  create?: IntegrationLinkCreateWithoutAccountCoverageInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutAccountCoverageInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutAccountCoverageInput[] | null;
  createMany?: IntegrationLinkCreateManyAccountCoverageInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutAccountCoverageInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutAccountCoverageInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutAccountNestedInput {
  create?: IntegrationLinkCreateWithoutAccountInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutAccountInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutAccountInput[] | null;
  createMany?: IntegrationLinkCreateManyAccountInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutAccountInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutAppointmentNestedInput {
  create?: IntegrationLinkCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: IntegrationLinkCreateManyAppointmentInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutBillNestedInput {
  create?: IntegrationLinkCreateWithoutBillInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutBillInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutBillInput[] | null;
  createMany?: IntegrationLinkCreateManyBillInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutBillInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutBillInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutBillPaymentNestedInput {
  create?: IntegrationLinkCreateWithoutBillPaymentInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutBillPaymentInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutBillPaymentInput[] | null;
  createMany?: IntegrationLinkCreateManyBillPaymentInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutBillPaymentInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutBillPaymentInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutChargePaymentNestedInput {
  create?: IntegrationLinkCreateWithoutChargePaymentInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutChargePaymentInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutChargePaymentInput[] | null;
  createMany?: IntegrationLinkCreateManyChargePaymentInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutChargePaymentInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutChargePaymentInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutClaimLineNestedInput {
  create?: IntegrationLinkCreateWithoutClaimLineInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutClaimLineInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutClaimLineInput[] | null;
  createMany?: IntegrationLinkCreateManyClaimLineInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutClaimLineInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutClaimLineInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutClaimNestedInput {
  create?: IntegrationLinkCreateWithoutClaimInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutClaimInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutClaimInput[] | null;
  createMany?: IntegrationLinkCreateManyClaimInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutClaimInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutClaimInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutClaimRemittanceLineNestedInput {
  create?: IntegrationLinkCreateWithoutClaimRemittanceLineInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutClaimRemittanceLineInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutClaimRemittanceLineInput[] | null;
  createMany?: IntegrationLinkCreateManyClaimRemittanceLineInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutClaimRemittanceLineInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutClaimRemittanceLineInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutClaimRemittanceNestedInput {
  create?: IntegrationLinkCreateWithoutClaimRemittanceInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutClaimRemittanceInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutClaimRemittanceInput[] | null;
  createMany?: IntegrationLinkCreateManyClaimRemittanceInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutClaimRemittanceInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutClaimRemittanceInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutExternalPaymentMethodsNestedInput {
  create?: IntegrationLinkCreateWithoutExternalPaymentMethodsInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutExternalPaymentMethodsInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutExternalPaymentMethodsInput[] | null;
  createMany?: IntegrationLinkCreateManyExternalPaymentMethodsInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutExternalPaymentMethodsInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutExternalPaymentMethodsInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutInsuranceBillableChargeNestedInput {
  create?: IntegrationLinkCreateWithoutInsuranceBillableChargeInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutInsuranceBillableChargeInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutInsuranceBillableChargeInput[] | null;
  createMany?: IntegrationLinkCreateManyInsuranceBillableChargeInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutInsuranceBillableChargeInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutInsuranceBillableChargeInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutIntegrationNestedInput {
  create?: IntegrationLinkCreateWithoutIntegrationInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutIntegrationInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutIntegrationInput[] | null;
  createMany?: IntegrationLinkCreateManyIntegrationInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutIntegrationInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutIntegrationInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutPatientNestedInput {
  create?: IntegrationLinkCreateWithoutPatientInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutPatientInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutPatientInput[] | null;
  createMany?: IntegrationLinkCreateManyPatientInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutPatientInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutPatientInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutPayerNestedInput {
  create?: IntegrationLinkCreateWithoutPayerInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutPayerInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutPayerInput[] | null;
  createMany?: IntegrationLinkCreateManyPayerInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutPayerInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutPayerInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutProviderNestedInput {
  create?: IntegrationLinkCreateWithoutProviderInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutProviderInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutProviderInput[] | null;
  createMany?: IntegrationLinkCreateManyProviderInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutProviderInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutProviderInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateManyWithoutTransactionNestedInput {
  create?: IntegrationLinkCreateWithoutTransactionInput[] | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutTransactionInput[] | null;
  upsert?: IntegrationLinkUpsertWithWhereUniqueWithoutTransactionInput[] | null;
  createMany?: IntegrationLinkCreateManyTransactionInputEnvelope | null;
  set?: IntegrationLinkWhereUniqueInput[] | null;
  disconnect?: IntegrationLinkWhereUniqueInput[] | null;
  delete?: IntegrationLinkWhereUniqueInput[] | null;
  connect?: IntegrationLinkWhereUniqueInput[] | null;
  update?: IntegrationLinkUpdateWithWhereUniqueWithoutTransactionInput[] | null;
  updateMany?: IntegrationLinkUpdateManyWithWhereWithoutTransactionInput[] | null;
  deleteMany?: IntegrationLinkScalarWhereInput[] | null;
}

export interface IntegrationLinkUpdateOneRequiredWithoutIntegrationLinkSyncsNestedInput {
  create?: IntegrationLinkCreateWithoutIntegrationLinkSyncsInput | null;
  connectOrCreate?: IntegrationLinkCreateOrConnectWithoutIntegrationLinkSyncsInput | null;
  upsert?: IntegrationLinkUpsertWithoutIntegrationLinkSyncsInput | null;
  connect?: IntegrationLinkWhereUniqueInput | null;
  update?: IntegrationLinkUpdateWithoutIntegrationLinkSyncsInput | null;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutAccountCoverageInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutAccountCoverageInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutAccountInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutAccountInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutAppointmentInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutAppointmentInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutBillInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutBillInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutBillPaymentInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutBillPaymentInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutChargePaymentInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutChargePaymentInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutClaimInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutClaimInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutClaimLineInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutClaimLineInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutClaimRemittanceInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutClaimRemittanceInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutClaimRemittanceLineInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutClaimRemittanceLineInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutExternalPaymentMethodsInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutExternalPaymentMethodsInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutInsuranceBillableChargeInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutInsuranceBillableChargeInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutIntegrationInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutIntegrationInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutPatientInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutPatientInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutPayerInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutPayerInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutProviderInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutProviderInput;
}

export interface IntegrationLinkUpdateWithWhereUniqueWithoutTransactionInput {
  where: IntegrationLinkWhereUniqueInput;
  data: IntegrationLinkUpdateWithoutTransactionInput;
}

export interface IntegrationLinkUpdateWithoutAccountCoverageInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutBillInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutBillPaymentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutChargePaymentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutClaimInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutClaimLineInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutClaimRemittanceInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutClaimRemittanceLineInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutExternalPaymentMethodsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutInsuranceBillableChargeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutIntegrationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutIntegrationLinkSyncsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutPatientInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutPayerInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutProviderInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  transaction?: TransactionUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpdateWithoutTransactionInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumIntegrationLinkTypeFieldUpdateOperationsInput | null;
  reference?: StringFieldUpdateOperationsInput | null;
  lastCheckedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hash?: StringFieldUpdateOperationsInput | null;
  statementId?: NullableStringFieldUpdateOperationsInput | null;
  statementTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput | null;
  account?: AccountUpdateOneWithoutIntegrationLinksNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutIntegrationLinksNestedInput | null;
  claim?: ClaimUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimLine?: ClaimLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittance?: ClaimRemittanceUpdateOneWithoutIntegrationLinksNestedInput | null;
  claimRemittanceLine?: ClaimRemittanceLineUpdateOneWithoutIntegrationLinksNestedInput | null;
  chargePayment?: ChargePaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutIntegrationLinksNestedInput | null;
  externalPaymentMethods?: ExternalPaymentMethodUpdateOneWithoutIntegrationLinksNestedInput | null;
  insuranceBillableCharge?: InsuranceBillableChargeUpdateOneWithoutIntegrationLinksNestedInput | null;
  patient?: PatientUpdateOneWithoutIntegrationLinksNestedInput | null;
  payer?: PayerUpdateOneWithoutIntegrationLinksNestedInput | null;
  provider?: ProviderUpdateOneWithoutIntegrationLinksNestedInput | null;
  bill?: BillUpdateOneWithoutIntegrationLinksNestedInput | null;
  billPayment?: BillPaymentUpdateOneWithoutIntegrationLinksNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationLinkNestedInput | null;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutAccountCoverageInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutAccountCoverageInput;
  create: IntegrationLinkCreateWithoutAccountCoverageInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutAccountInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutAccountInput;
  create: IntegrationLinkCreateWithoutAccountInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutAppointmentInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutAppointmentInput;
  create: IntegrationLinkCreateWithoutAppointmentInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutBillInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutBillInput;
  create: IntegrationLinkCreateWithoutBillInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutBillPaymentInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutBillPaymentInput;
  create: IntegrationLinkCreateWithoutBillPaymentInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutChargePaymentInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutChargePaymentInput;
  create: IntegrationLinkCreateWithoutChargePaymentInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutClaimInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutClaimInput;
  create: IntegrationLinkCreateWithoutClaimInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutClaimLineInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutClaimLineInput;
  create: IntegrationLinkCreateWithoutClaimLineInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutClaimRemittanceInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutClaimRemittanceInput;
  create: IntegrationLinkCreateWithoutClaimRemittanceInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutClaimRemittanceLineInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutClaimRemittanceLineInput;
  create: IntegrationLinkCreateWithoutClaimRemittanceLineInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutExternalPaymentMethodsInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutExternalPaymentMethodsInput;
  create: IntegrationLinkCreateWithoutExternalPaymentMethodsInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutInsuranceBillableChargeInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutInsuranceBillableChargeInput;
  create: IntegrationLinkCreateWithoutInsuranceBillableChargeInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutIntegrationInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutIntegrationInput;
  create: IntegrationLinkCreateWithoutIntegrationInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutPatientInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutPatientInput;
  create: IntegrationLinkCreateWithoutPatientInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutPayerInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutPayerInput;
  create: IntegrationLinkCreateWithoutPayerInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutProviderInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutProviderInput;
  create: IntegrationLinkCreateWithoutProviderInput;
}

export interface IntegrationLinkUpsertWithWhereUniqueWithoutTransactionInput {
  where: IntegrationLinkWhereUniqueInput;
  update: IntegrationLinkUpdateWithoutTransactionInput;
  create: IntegrationLinkCreateWithoutTransactionInput;
}

export interface IntegrationLinkUpsertWithoutIntegrationLinkSyncsInput {
  update: IntegrationLinkUpdateWithoutIntegrationLinkSyncsInput;
  create: IntegrationLinkCreateWithoutIntegrationLinkSyncsInput;
}

export interface IntegrationLinkWhereInput {
  AND?: IntegrationLinkWhereInput[] | null;
  OR?: IntegrationLinkWhereInput[] | null;
  NOT?: IntegrationLinkWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  integrationId?: UuidFilter | null;
  type?: EnumIntegrationLinkTypeFilter | null;
  reference?: StringFilter | null;
  lastCheckedAt?: DateTimeNullableFilter | null;
  hash?: StringFilter | null;
  accountId?: UuidNullableFilter | null;
  appointmentId?: UuidNullableFilter | null;
  claimId?: UuidNullableFilter | null;
  claimLineId?: UuidNullableFilter | null;
  claimRemittanceId?: UuidNullableFilter | null;
  claimRemittanceLineId?: UuidNullableFilter | null;
  chargePaymentId?: UuidNullableFilter | null;
  accountCoverageId?: UuidNullableFilter | null;
  externalPaymentMethodId?: UuidNullableFilter | null;
  insuranceBillableChargeId?: UuidNullableFilter | null;
  patientId?: UuidNullableFilter | null;
  payerId?: UuidNullableFilter | null;
  providerId?: UuidNullableFilter | null;
  statementId?: UuidNullableFilter | null;
  statementTransactionId?: UuidNullableFilter | null;
  transactionId?: UuidNullableFilter | null;
  billId?: UuidNullableFilter | null;
  billPaymentId?: UuidNullableFilter | null;
  integration?: IntegrationRelationFilter | null;
  account?: AccountRelationFilter | null;
  appointment?: AppointmentRelationFilter | null;
  claim?: ClaimRelationFilter | null;
  claimLine?: ClaimLineRelationFilter | null;
  claimRemittance?: ClaimRemittanceRelationFilter | null;
  claimRemittanceLine?: ClaimRemittanceLineRelationFilter | null;
  chargePayment?: ChargePaymentRelationFilter | null;
  accountCoverage?: AccountCoverageRelationFilter | null;
  externalPaymentMethods?: ExternalPaymentMethodRelationFilter | null;
  insuranceBillableCharge?: InsuranceBillableChargeRelationFilter | null;
  patient?: PatientRelationFilter | null;
  payer?: PayerRelationFilter | null;
  provider?: ProviderRelationFilter | null;
  transaction?: TransactionRelationFilter | null;
  bill?: BillRelationFilter | null;
  billPayment?: BillPaymentRelationFilter | null;
  integrationLinkSyncs?: IntegrationLinkSyncListRelationFilter | null;
}

export interface IntegrationLinkWhereUniqueInput {
  id?: string | null;
  integrationId_type_reference?: IntegrationLinkIntegrationIdTypeReferenceCompoundUniqueInput | null;
}

export interface IntegrationListRelationFilter {
  every?: IntegrationWhereInput | null;
  some?: IntegrationWhereInput | null;
  none?: IntegrationWhereInput | null;
}

export interface IntegrationOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface IntegrationRelationFilter {
  is?: IntegrationWhereInput | null;
  isNot?: IntegrationWhereInput | null;
}

export interface IntegrationScalarWhereInput {
  AND?: IntegrationScalarWhereInput[] | null;
  OR?: IntegrationScalarWhereInput[] | null;
  NOT?: IntegrationScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  type?: EnumIntegrationTypeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  databaseConnectionUrl?: StringNullableFilter | null;
  authenticationParams?: JsonNullableFilter | null;
  paymentPostingEnabled?: BoolFilter | null;
  timezone?: StringNullableFilter | null;
  paymentPostingMethod?: EnumPaymentMethodNullableFilter | null;
  chirotouchProviderPaymentPostingBehavior?: StringNullableFilter | null;
}

export interface IntegrationSyncCreateManyIntegrationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  direction: SyncDirection;
  userId?: string | null;
  automated: boolean;
  integrationSyncScheduleId?: string | null;
}

export interface IntegrationSyncCreateManyIntegrationInputEnvelope {
  data: IntegrationSyncCreateManyIntegrationInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationSyncCreateManyIntegrationSyncScheduleInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  direction: SyncDirection;
  integrationId: string;
  userId?: string | null;
  automated: boolean;
}

export interface IntegrationSyncCreateManyIntegrationSyncScheduleInputEnvelope {
  data: IntegrationSyncCreateManyIntegrationSyncScheduleInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationSyncCreateManyUserInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  direction: SyncDirection;
  integrationId: string;
  automated: boolean;
  integrationSyncScheduleId?: string | null;
}

export interface IntegrationSyncCreateManyUserInputEnvelope {
  data: IntegrationSyncCreateManyUserInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationSyncCreateNestedManyWithoutIntegrationInput {
  create?: IntegrationSyncCreateWithoutIntegrationInput[] | null;
  connectOrCreate?: IntegrationSyncCreateOrConnectWithoutIntegrationInput[] | null;
  createMany?: IntegrationSyncCreateManyIntegrationInputEnvelope | null;
  connect?: IntegrationSyncWhereUniqueInput[] | null;
}

export interface IntegrationSyncCreateNestedManyWithoutIntegrationSyncScheduleInput {
  create?: IntegrationSyncCreateWithoutIntegrationSyncScheduleInput[] | null;
  connectOrCreate?: IntegrationSyncCreateOrConnectWithoutIntegrationSyncScheduleInput[] | null;
  createMany?: IntegrationSyncCreateManyIntegrationSyncScheduleInputEnvelope | null;
  connect?: IntegrationSyncWhereUniqueInput[] | null;
}

export interface IntegrationSyncCreateNestedManyWithoutUserInput {
  create?: IntegrationSyncCreateWithoutUserInput[] | null;
  connectOrCreate?: IntegrationSyncCreateOrConnectWithoutUserInput[] | null;
  createMany?: IntegrationSyncCreateManyUserInputEnvelope | null;
  connect?: IntegrationSyncWhereUniqueInput[] | null;
}

export interface IntegrationSyncCreateNestedOneWithoutIntegrationLinkSyncsInput {
  create?: IntegrationSyncCreateWithoutIntegrationLinkSyncsInput | null;
  connectOrCreate?: IntegrationSyncCreateOrConnectWithoutIntegrationLinkSyncsInput | null;
  connect?: IntegrationSyncWhereUniqueInput | null;
}

export interface IntegrationSyncCreateOrConnectWithoutIntegrationInput {
  where: IntegrationSyncWhereUniqueInput;
  create: IntegrationSyncCreateWithoutIntegrationInput;
}

export interface IntegrationSyncCreateOrConnectWithoutIntegrationLinkSyncsInput {
  where: IntegrationSyncWhereUniqueInput;
  create: IntegrationSyncCreateWithoutIntegrationLinkSyncsInput;
}

export interface IntegrationSyncCreateOrConnectWithoutIntegrationSyncScheduleInput {
  where: IntegrationSyncWhereUniqueInput;
  create: IntegrationSyncCreateWithoutIntegrationSyncScheduleInput;
}

export interface IntegrationSyncCreateOrConnectWithoutUserInput {
  where: IntegrationSyncWhereUniqueInput;
  create: IntegrationSyncCreateWithoutUserInput;
}

export interface IntegrationSyncCreateWithoutIntegrationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  direction: SyncDirection;
  automated: boolean;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationSyncInput | null;
  integrationSyncErrors?: IntegrationSyncErrorCreateNestedManyWithoutIntegrationSyncInput | null;
  user?: UserCreateNestedOneWithoutIntegrationSyncsInput | null;
  integrationSyncSchedule?: IntegrationSyncScheduleCreateNestedOneWithoutIntegrationSyncsInput | null;
}

export interface IntegrationSyncCreateWithoutIntegrationLinkSyncsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  direction: SyncDirection;
  automated: boolean;
  integration: IntegrationCreateNestedOneWithoutIntegrationSyncsInput;
  integrationSyncErrors?: IntegrationSyncErrorCreateNestedManyWithoutIntegrationSyncInput | null;
  user?: UserCreateNestedOneWithoutIntegrationSyncsInput | null;
  integrationSyncSchedule?: IntegrationSyncScheduleCreateNestedOneWithoutIntegrationSyncsInput | null;
}

export interface IntegrationSyncCreateWithoutIntegrationSyncScheduleInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  direction: SyncDirection;
  automated: boolean;
  integration: IntegrationCreateNestedOneWithoutIntegrationSyncsInput;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationSyncInput | null;
  integrationSyncErrors?: IntegrationSyncErrorCreateNestedManyWithoutIntegrationSyncInput | null;
  user?: UserCreateNestedOneWithoutIntegrationSyncsInput | null;
}

export interface IntegrationSyncCreateWithoutUserInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  direction: SyncDirection;
  automated: boolean;
  integration: IntegrationCreateNestedOneWithoutIntegrationSyncsInput;
  integrationLinkSyncs?: IntegrationLinkSyncCreateNestedManyWithoutIntegrationSyncInput | null;
  integrationSyncErrors?: IntegrationSyncErrorCreateNestedManyWithoutIntegrationSyncInput | null;
  integrationSyncSchedule?: IntegrationSyncScheduleCreateNestedOneWithoutIntegrationSyncsInput | null;
}

export interface IntegrationSyncErrorCreateManyIntegrationSyncInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  message: string;
}

export interface IntegrationSyncErrorCreateManyIntegrationSyncInputEnvelope {
  data: IntegrationSyncErrorCreateManyIntegrationSyncInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationSyncErrorCreateNestedManyWithoutIntegrationSyncInput {
  create?: IntegrationSyncErrorCreateWithoutIntegrationSyncInput[] | null;
  connectOrCreate?: IntegrationSyncErrorCreateOrConnectWithoutIntegrationSyncInput[] | null;
  createMany?: IntegrationSyncErrorCreateManyIntegrationSyncInputEnvelope | null;
  connect?: IntegrationSyncErrorWhereUniqueInput[] | null;
}

export interface IntegrationSyncErrorCreateOrConnectWithoutIntegrationSyncInput {
  where: IntegrationSyncErrorWhereUniqueInput;
  create: IntegrationSyncErrorCreateWithoutIntegrationSyncInput;
}

export interface IntegrationSyncErrorCreateWithoutIntegrationSyncInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  message: string;
}

export interface IntegrationSyncErrorListRelationFilter {
  every?: IntegrationSyncErrorWhereInput | null;
  some?: IntegrationSyncErrorWhereInput | null;
  none?: IntegrationSyncErrorWhereInput | null;
}

export interface IntegrationSyncErrorScalarWhereInput {
  AND?: IntegrationSyncErrorScalarWhereInput[] | null;
  OR?: IntegrationSyncErrorScalarWhereInput[] | null;
  NOT?: IntegrationSyncErrorScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  message?: StringFilter | null;
  integrationSyncId?: UuidFilter | null;
}

export interface IntegrationSyncErrorUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  message?: StringFieldUpdateOperationsInput | null;
}

export interface IntegrationSyncErrorUpdateManyWithWhereWithoutIntegrationSyncInput {
  where: IntegrationSyncErrorScalarWhereInput;
  data: IntegrationSyncErrorUpdateManyMutationInput;
}

export interface IntegrationSyncErrorUpdateManyWithoutIntegrationSyncNestedInput {
  create?: IntegrationSyncErrorCreateWithoutIntegrationSyncInput[] | null;
  connectOrCreate?: IntegrationSyncErrorCreateOrConnectWithoutIntegrationSyncInput[] | null;
  upsert?: IntegrationSyncErrorUpsertWithWhereUniqueWithoutIntegrationSyncInput[] | null;
  createMany?: IntegrationSyncErrorCreateManyIntegrationSyncInputEnvelope | null;
  set?: IntegrationSyncErrorWhereUniqueInput[] | null;
  disconnect?: IntegrationSyncErrorWhereUniqueInput[] | null;
  delete?: IntegrationSyncErrorWhereUniqueInput[] | null;
  connect?: IntegrationSyncErrorWhereUniqueInput[] | null;
  update?: IntegrationSyncErrorUpdateWithWhereUniqueWithoutIntegrationSyncInput[] | null;
  updateMany?: IntegrationSyncErrorUpdateManyWithWhereWithoutIntegrationSyncInput[] | null;
  deleteMany?: IntegrationSyncErrorScalarWhereInput[] | null;
}

export interface IntegrationSyncErrorUpdateWithWhereUniqueWithoutIntegrationSyncInput {
  where: IntegrationSyncErrorWhereUniqueInput;
  data: IntegrationSyncErrorUpdateWithoutIntegrationSyncInput;
}

export interface IntegrationSyncErrorUpdateWithoutIntegrationSyncInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  message?: StringFieldUpdateOperationsInput | null;
}

export interface IntegrationSyncErrorUpsertWithWhereUniqueWithoutIntegrationSyncInput {
  where: IntegrationSyncErrorWhereUniqueInput;
  update: IntegrationSyncErrorUpdateWithoutIntegrationSyncInput;
  create: IntegrationSyncErrorCreateWithoutIntegrationSyncInput;
}

export interface IntegrationSyncErrorWhereInput {
  AND?: IntegrationSyncErrorWhereInput[] | null;
  OR?: IntegrationSyncErrorWhereInput[] | null;
  NOT?: IntegrationSyncErrorWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  message?: StringFilter | null;
  integrationSyncId?: UuidFilter | null;
  integrationSync?: IntegrationSyncRelationFilter | null;
}

export interface IntegrationSyncErrorWhereUniqueInput {
  id?: string | null;
}

export interface IntegrationSyncListRelationFilter {
  every?: IntegrationSyncWhereInput | null;
  some?: IntegrationSyncWhereInput | null;
  none?: IntegrationSyncWhereInput | null;
}

export interface IntegrationSyncRelationFilter {
  is?: IntegrationSyncWhereInput | null;
  isNot?: IntegrationSyncWhereInput | null;
}

export interface IntegrationSyncScalarWhereInput {
  AND?: IntegrationSyncScalarWhereInput[] | null;
  OR?: IntegrationSyncScalarWhereInput[] | null;
  NOT?: IntegrationSyncScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  direction?: EnumSyncDirectionFilter | null;
  integrationId?: UuidFilter | null;
  userId?: UuidNullableFilter | null;
  automated?: BoolFilter | null;
  integrationSyncScheduleId?: UuidNullableFilter | null;
}

export interface IntegrationSyncScheduleCreateManyIntegrationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  cron: string;
  direction: SyncDirection;
  cursor?: number | null;
  transactionCursor?: number | null;
  statementCursor?: number | null;
  chargePaymentsCursor?: number | null;
  syncData?: any | null;
  type?: WorkflowType | null;
  pausedAt?: any | null;
}

export interface IntegrationSyncScheduleCreateManyIntegrationInputEnvelope {
  data: IntegrationSyncScheduleCreateManyIntegrationInput[];
  skipDuplicates?: boolean | null;
}

export interface IntegrationSyncScheduleCreateNestedManyWithoutIntegrationInput {
  create?: IntegrationSyncScheduleCreateWithoutIntegrationInput[] | null;
  connectOrCreate?: IntegrationSyncScheduleCreateOrConnectWithoutIntegrationInput[] | null;
  createMany?: IntegrationSyncScheduleCreateManyIntegrationInputEnvelope | null;
  connect?: IntegrationSyncScheduleWhereUniqueInput[] | null;
}

export interface IntegrationSyncScheduleCreateNestedOneWithoutIntegrationSyncsInput {
  create?: IntegrationSyncScheduleCreateWithoutIntegrationSyncsInput | null;
  connectOrCreate?: IntegrationSyncScheduleCreateOrConnectWithoutIntegrationSyncsInput | null;
  connect?: IntegrationSyncScheduleWhereUniqueInput | null;
}

export interface IntegrationSyncScheduleCreateOrConnectWithoutIntegrationInput {
  where: IntegrationSyncScheduleWhereUniqueInput;
  create: IntegrationSyncScheduleCreateWithoutIntegrationInput;
}

export interface IntegrationSyncScheduleCreateOrConnectWithoutIntegrationSyncsInput {
  where: IntegrationSyncScheduleWhereUniqueInput;
  create: IntegrationSyncScheduleCreateWithoutIntegrationSyncsInput;
}

export interface IntegrationSyncScheduleCreateWithoutIntegrationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  cron: string;
  direction: SyncDirection;
  cursor?: number | null;
  transactionCursor?: number | null;
  statementCursor?: number | null;
  chargePaymentsCursor?: number | null;
  syncData?: any | null;
  type?: WorkflowType | null;
  pausedAt?: any | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutIntegrationSyncScheduleInput | null;
}

export interface IntegrationSyncScheduleCreateWithoutIntegrationSyncsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  cron: string;
  direction: SyncDirection;
  cursor?: number | null;
  transactionCursor?: number | null;
  statementCursor?: number | null;
  chargePaymentsCursor?: number | null;
  syncData?: any | null;
  type?: WorkflowType | null;
  pausedAt?: any | null;
  integration: IntegrationCreateNestedOneWithoutIntegrationSyncSchedulesInput;
}

export interface IntegrationSyncScheduleListRelationFilter {
  every?: IntegrationSyncScheduleWhereInput | null;
  some?: IntegrationSyncScheduleWhereInput | null;
  none?: IntegrationSyncScheduleWhereInput | null;
}

export interface IntegrationSyncScheduleRelationFilter {
  is?: IntegrationSyncScheduleWhereInput | null;
  isNot?: IntegrationSyncScheduleWhereInput | null;
}

export interface IntegrationSyncScheduleScalarWhereInput {
  AND?: IntegrationSyncScheduleScalarWhereInput[] | null;
  OR?: IntegrationSyncScheduleScalarWhereInput[] | null;
  NOT?: IntegrationSyncScheduleScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  integrationId?: UuidFilter | null;
  cron?: StringFilter | null;
  direction?: EnumSyncDirectionFilter | null;
  cursor?: IntNullableFilter | null;
  transactionCursor?: IntNullableFilter | null;
  statementCursor?: IntNullableFilter | null;
  chargePaymentsCursor?: IntNullableFilter | null;
  syncData?: JsonNullableFilter | null;
  type?: EnumWorkflowTypeNullableFilter | null;
  pausedAt?: DateTimeNullableFilter | null;
}

export interface IntegrationSyncScheduleUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  cron?: StringFieldUpdateOperationsInput | null;
  direction?: EnumSyncDirectionFieldUpdateOperationsInput | null;
  cursor?: NullableIntFieldUpdateOperationsInput | null;
  transactionCursor?: NullableIntFieldUpdateOperationsInput | null;
  statementCursor?: NullableIntFieldUpdateOperationsInput | null;
  chargePaymentsCursor?: NullableIntFieldUpdateOperationsInput | null;
  syncData?: any | null;
  type?: NullableEnumWorkflowTypeFieldUpdateOperationsInput | null;
  pausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
}

export interface IntegrationSyncScheduleUpdateManyWithWhereWithoutIntegrationInput {
  where: IntegrationSyncScheduleScalarWhereInput;
  data: IntegrationSyncScheduleUpdateManyMutationInput;
}

export interface IntegrationSyncScheduleUpdateManyWithoutIntegrationNestedInput {
  create?: IntegrationSyncScheduleCreateWithoutIntegrationInput[] | null;
  connectOrCreate?: IntegrationSyncScheduleCreateOrConnectWithoutIntegrationInput[] | null;
  upsert?: IntegrationSyncScheduleUpsertWithWhereUniqueWithoutIntegrationInput[] | null;
  createMany?: IntegrationSyncScheduleCreateManyIntegrationInputEnvelope | null;
  set?: IntegrationSyncScheduleWhereUniqueInput[] | null;
  disconnect?: IntegrationSyncScheduleWhereUniqueInput[] | null;
  delete?: IntegrationSyncScheduleWhereUniqueInput[] | null;
  connect?: IntegrationSyncScheduleWhereUniqueInput[] | null;
  update?: IntegrationSyncScheduleUpdateWithWhereUniqueWithoutIntegrationInput[] | null;
  updateMany?: IntegrationSyncScheduleUpdateManyWithWhereWithoutIntegrationInput[] | null;
  deleteMany?: IntegrationSyncScheduleScalarWhereInput[] | null;
}

export interface IntegrationSyncScheduleUpdateOneWithoutIntegrationSyncsNestedInput {
  create?: IntegrationSyncScheduleCreateWithoutIntegrationSyncsInput | null;
  connectOrCreate?: IntegrationSyncScheduleCreateOrConnectWithoutIntegrationSyncsInput | null;
  upsert?: IntegrationSyncScheduleUpsertWithoutIntegrationSyncsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: IntegrationSyncScheduleWhereUniqueInput | null;
  update?: IntegrationSyncScheduleUpdateWithoutIntegrationSyncsInput | null;
}

export interface IntegrationSyncScheduleUpdateWithWhereUniqueWithoutIntegrationInput {
  where: IntegrationSyncScheduleWhereUniqueInput;
  data: IntegrationSyncScheduleUpdateWithoutIntegrationInput;
}

export interface IntegrationSyncScheduleUpdateWithoutIntegrationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  cron?: StringFieldUpdateOperationsInput | null;
  direction?: EnumSyncDirectionFieldUpdateOperationsInput | null;
  cursor?: NullableIntFieldUpdateOperationsInput | null;
  transactionCursor?: NullableIntFieldUpdateOperationsInput | null;
  statementCursor?: NullableIntFieldUpdateOperationsInput | null;
  chargePaymentsCursor?: NullableIntFieldUpdateOperationsInput | null;
  syncData?: any | null;
  type?: NullableEnumWorkflowTypeFieldUpdateOperationsInput | null;
  pausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutIntegrationSyncScheduleNestedInput | null;
}

export interface IntegrationSyncScheduleUpdateWithoutIntegrationSyncsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  cron?: StringFieldUpdateOperationsInput | null;
  direction?: EnumSyncDirectionFieldUpdateOperationsInput | null;
  cursor?: NullableIntFieldUpdateOperationsInput | null;
  transactionCursor?: NullableIntFieldUpdateOperationsInput | null;
  statementCursor?: NullableIntFieldUpdateOperationsInput | null;
  chargePaymentsCursor?: NullableIntFieldUpdateOperationsInput | null;
  syncData?: any | null;
  type?: NullableEnumWorkflowTypeFieldUpdateOperationsInput | null;
  pausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationSyncSchedulesNestedInput | null;
}

export interface IntegrationSyncScheduleUpsertWithWhereUniqueWithoutIntegrationInput {
  where: IntegrationSyncScheduleWhereUniqueInput;
  update: IntegrationSyncScheduleUpdateWithoutIntegrationInput;
  create: IntegrationSyncScheduleCreateWithoutIntegrationInput;
}

export interface IntegrationSyncScheduleUpsertWithoutIntegrationSyncsInput {
  update: IntegrationSyncScheduleUpdateWithoutIntegrationSyncsInput;
  create: IntegrationSyncScheduleCreateWithoutIntegrationSyncsInput;
}

export interface IntegrationSyncScheduleWhereInput {
  AND?: IntegrationSyncScheduleWhereInput[] | null;
  OR?: IntegrationSyncScheduleWhereInput[] | null;
  NOT?: IntegrationSyncScheduleWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  integrationId?: UuidFilter | null;
  cron?: StringFilter | null;
  direction?: EnumSyncDirectionFilter | null;
  cursor?: IntNullableFilter | null;
  transactionCursor?: IntNullableFilter | null;
  statementCursor?: IntNullableFilter | null;
  chargePaymentsCursor?: IntNullableFilter | null;
  syncData?: JsonNullableFilter | null;
  type?: EnumWorkflowTypeNullableFilter | null;
  pausedAt?: DateTimeNullableFilter | null;
  integration?: IntegrationRelationFilter | null;
  integrationSyncs?: IntegrationSyncListRelationFilter | null;
}

export interface IntegrationSyncScheduleWhereUniqueInput {
  id?: string | null;
}

export interface IntegrationSyncUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  direction?: EnumSyncDirectionFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
}

export interface IntegrationSyncUpdateManyWithWhereWithoutIntegrationInput {
  where: IntegrationSyncScalarWhereInput;
  data: IntegrationSyncUpdateManyMutationInput;
}

export interface IntegrationSyncUpdateManyWithWhereWithoutIntegrationSyncScheduleInput {
  where: IntegrationSyncScalarWhereInput;
  data: IntegrationSyncUpdateManyMutationInput;
}

export interface IntegrationSyncUpdateManyWithWhereWithoutUserInput {
  where: IntegrationSyncScalarWhereInput;
  data: IntegrationSyncUpdateManyMutationInput;
}

export interface IntegrationSyncUpdateManyWithoutIntegrationNestedInput {
  create?: IntegrationSyncCreateWithoutIntegrationInput[] | null;
  connectOrCreate?: IntegrationSyncCreateOrConnectWithoutIntegrationInput[] | null;
  upsert?: IntegrationSyncUpsertWithWhereUniqueWithoutIntegrationInput[] | null;
  createMany?: IntegrationSyncCreateManyIntegrationInputEnvelope | null;
  set?: IntegrationSyncWhereUniqueInput[] | null;
  disconnect?: IntegrationSyncWhereUniqueInput[] | null;
  delete?: IntegrationSyncWhereUniqueInput[] | null;
  connect?: IntegrationSyncWhereUniqueInput[] | null;
  update?: IntegrationSyncUpdateWithWhereUniqueWithoutIntegrationInput[] | null;
  updateMany?: IntegrationSyncUpdateManyWithWhereWithoutIntegrationInput[] | null;
  deleteMany?: IntegrationSyncScalarWhereInput[] | null;
}

export interface IntegrationSyncUpdateManyWithoutIntegrationSyncScheduleNestedInput {
  create?: IntegrationSyncCreateWithoutIntegrationSyncScheduleInput[] | null;
  connectOrCreate?: IntegrationSyncCreateOrConnectWithoutIntegrationSyncScheduleInput[] | null;
  upsert?: IntegrationSyncUpsertWithWhereUniqueWithoutIntegrationSyncScheduleInput[] | null;
  createMany?: IntegrationSyncCreateManyIntegrationSyncScheduleInputEnvelope | null;
  set?: IntegrationSyncWhereUniqueInput[] | null;
  disconnect?: IntegrationSyncWhereUniqueInput[] | null;
  delete?: IntegrationSyncWhereUniqueInput[] | null;
  connect?: IntegrationSyncWhereUniqueInput[] | null;
  update?: IntegrationSyncUpdateWithWhereUniqueWithoutIntegrationSyncScheduleInput[] | null;
  updateMany?: IntegrationSyncUpdateManyWithWhereWithoutIntegrationSyncScheduleInput[] | null;
  deleteMany?: IntegrationSyncScalarWhereInput[] | null;
}

export interface IntegrationSyncUpdateManyWithoutUserNestedInput {
  create?: IntegrationSyncCreateWithoutUserInput[] | null;
  connectOrCreate?: IntegrationSyncCreateOrConnectWithoutUserInput[] | null;
  upsert?: IntegrationSyncUpsertWithWhereUniqueWithoutUserInput[] | null;
  createMany?: IntegrationSyncCreateManyUserInputEnvelope | null;
  set?: IntegrationSyncWhereUniqueInput[] | null;
  disconnect?: IntegrationSyncWhereUniqueInput[] | null;
  delete?: IntegrationSyncWhereUniqueInput[] | null;
  connect?: IntegrationSyncWhereUniqueInput[] | null;
  update?: IntegrationSyncUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: IntegrationSyncUpdateManyWithWhereWithoutUserInput[] | null;
  deleteMany?: IntegrationSyncScalarWhereInput[] | null;
}

export interface IntegrationSyncUpdateOneWithoutIntegrationLinkSyncsNestedInput {
  create?: IntegrationSyncCreateWithoutIntegrationLinkSyncsInput | null;
  connectOrCreate?: IntegrationSyncCreateOrConnectWithoutIntegrationLinkSyncsInput | null;
  upsert?: IntegrationSyncUpsertWithoutIntegrationLinkSyncsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: IntegrationSyncWhereUniqueInput | null;
  update?: IntegrationSyncUpdateWithoutIntegrationLinkSyncsInput | null;
}

export interface IntegrationSyncUpdateWithWhereUniqueWithoutIntegrationInput {
  where: IntegrationSyncWhereUniqueInput;
  data: IntegrationSyncUpdateWithoutIntegrationInput;
}

export interface IntegrationSyncUpdateWithWhereUniqueWithoutIntegrationSyncScheduleInput {
  where: IntegrationSyncWhereUniqueInput;
  data: IntegrationSyncUpdateWithoutIntegrationSyncScheduleInput;
}

export interface IntegrationSyncUpdateWithWhereUniqueWithoutUserInput {
  where: IntegrationSyncWhereUniqueInput;
  data: IntegrationSyncUpdateWithoutUserInput;
}

export interface IntegrationSyncUpdateWithoutIntegrationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  direction?: EnumSyncDirectionFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationSyncNestedInput | null;
  integrationSyncErrors?: IntegrationSyncErrorUpdateManyWithoutIntegrationSyncNestedInput | null;
  user?: UserUpdateOneWithoutIntegrationSyncsNestedInput | null;
  integrationSyncSchedule?: IntegrationSyncScheduleUpdateOneWithoutIntegrationSyncsNestedInput | null;
}

export interface IntegrationSyncUpdateWithoutIntegrationLinkSyncsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  direction?: EnumSyncDirectionFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationSyncsNestedInput | null;
  integrationSyncErrors?: IntegrationSyncErrorUpdateManyWithoutIntegrationSyncNestedInput | null;
  user?: UserUpdateOneWithoutIntegrationSyncsNestedInput | null;
  integrationSyncSchedule?: IntegrationSyncScheduleUpdateOneWithoutIntegrationSyncsNestedInput | null;
}

export interface IntegrationSyncUpdateWithoutIntegrationSyncScheduleInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  direction?: EnumSyncDirectionFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationSyncsNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationSyncNestedInput | null;
  integrationSyncErrors?: IntegrationSyncErrorUpdateManyWithoutIntegrationSyncNestedInput | null;
  user?: UserUpdateOneWithoutIntegrationSyncsNestedInput | null;
}

export interface IntegrationSyncUpdateWithoutUserInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  direction?: EnumSyncDirectionFieldUpdateOperationsInput | null;
  automated?: BoolFieldUpdateOperationsInput | null;
  integration?: IntegrationUpdateOneRequiredWithoutIntegrationSyncsNestedInput | null;
  integrationLinkSyncs?: IntegrationLinkSyncUpdateManyWithoutIntegrationSyncNestedInput | null;
  integrationSyncErrors?: IntegrationSyncErrorUpdateManyWithoutIntegrationSyncNestedInput | null;
  integrationSyncSchedule?: IntegrationSyncScheduleUpdateOneWithoutIntegrationSyncsNestedInput | null;
}

export interface IntegrationSyncUpsertWithWhereUniqueWithoutIntegrationInput {
  where: IntegrationSyncWhereUniqueInput;
  update: IntegrationSyncUpdateWithoutIntegrationInput;
  create: IntegrationSyncCreateWithoutIntegrationInput;
}

export interface IntegrationSyncUpsertWithWhereUniqueWithoutIntegrationSyncScheduleInput {
  where: IntegrationSyncWhereUniqueInput;
  update: IntegrationSyncUpdateWithoutIntegrationSyncScheduleInput;
  create: IntegrationSyncCreateWithoutIntegrationSyncScheduleInput;
}

export interface IntegrationSyncUpsertWithWhereUniqueWithoutUserInput {
  where: IntegrationSyncWhereUniqueInput;
  update: IntegrationSyncUpdateWithoutUserInput;
  create: IntegrationSyncCreateWithoutUserInput;
}

export interface IntegrationSyncUpsertWithoutIntegrationLinkSyncsInput {
  update: IntegrationSyncUpdateWithoutIntegrationLinkSyncsInput;
  create: IntegrationSyncCreateWithoutIntegrationLinkSyncsInput;
}

export interface IntegrationSyncWhereInput {
  AND?: IntegrationSyncWhereInput[] | null;
  OR?: IntegrationSyncWhereInput[] | null;
  NOT?: IntegrationSyncWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  direction?: EnumSyncDirectionFilter | null;
  integrationId?: UuidFilter | null;
  userId?: UuidNullableFilter | null;
  automated?: BoolFilter | null;
  integrationSyncScheduleId?: UuidNullableFilter | null;
  integration?: IntegrationRelationFilter | null;
  integrationLinkSyncs?: IntegrationLinkSyncListRelationFilter | null;
  integrationSyncErrors?: IntegrationSyncErrorListRelationFilter | null;
  user?: UserRelationFilter | null;
  integrationSyncSchedule?: IntegrationSyncScheduleRelationFilter | null;
}

export interface IntegrationSyncWhereUniqueInput {
  id?: string | null;
}

export interface IntegrationUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  type?: EnumIntegrationTypeFieldUpdateOperationsInput | null;
  databaseConnectionUrl?: NullableStringFieldUpdateOperationsInput | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: BoolFieldUpdateOperationsInput | null;
  timezone?: NullableStringFieldUpdateOperationsInput | null;
  paymentPostingMethod?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  chirotouchProviderPaymentPostingBehavior?: NullableStringFieldUpdateOperationsInput | null;
}

export interface IntegrationUpdateManyWithWhereWithoutLocationInput {
  where: IntegrationScalarWhereInput;
  data: IntegrationUpdateManyMutationInput;
}

export interface IntegrationUpdateManyWithWhereWithoutOrganizationInput {
  where: IntegrationScalarWhereInput;
  data: IntegrationUpdateManyMutationInput;
}

export interface IntegrationUpdateManyWithoutLocationNestedInput {
  create?: IntegrationCreateWithoutLocationInput[] | null;
  connectOrCreate?: IntegrationCreateOrConnectWithoutLocationInput[] | null;
  upsert?: IntegrationUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: IntegrationCreateManyLocationInputEnvelope | null;
  set?: IntegrationWhereUniqueInput[] | null;
  disconnect?: IntegrationWhereUniqueInput[] | null;
  delete?: IntegrationWhereUniqueInput[] | null;
  connect?: IntegrationWhereUniqueInput[] | null;
  update?: IntegrationUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: IntegrationUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: IntegrationScalarWhereInput[] | null;
}

export interface IntegrationUpdateManyWithoutOrganizationNestedInput {
  create?: IntegrationCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: IntegrationCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: IntegrationUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: IntegrationCreateManyOrganizationInputEnvelope | null;
  set?: IntegrationWhereUniqueInput[] | null;
  disconnect?: IntegrationWhereUniqueInput[] | null;
  delete?: IntegrationWhereUniqueInput[] | null;
  connect?: IntegrationWhereUniqueInput[] | null;
  update?: IntegrationUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: IntegrationUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: IntegrationScalarWhereInput[] | null;
}

export interface IntegrationUpdateOneRequiredWithoutIntegrationLinksNestedInput {
  create?: IntegrationCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: IntegrationCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: IntegrationUpsertWithoutIntegrationLinksInput | null;
  connect?: IntegrationWhereUniqueInput | null;
  update?: IntegrationUpdateWithoutIntegrationLinksInput | null;
}

export interface IntegrationUpdateOneRequiredWithoutIntegrationSyncSchedulesNestedInput {
  create?: IntegrationCreateWithoutIntegrationSyncSchedulesInput | null;
  connectOrCreate?: IntegrationCreateOrConnectWithoutIntegrationSyncSchedulesInput | null;
  upsert?: IntegrationUpsertWithoutIntegrationSyncSchedulesInput | null;
  connect?: IntegrationWhereUniqueInput | null;
  update?: IntegrationUpdateWithoutIntegrationSyncSchedulesInput | null;
}

export interface IntegrationUpdateOneRequiredWithoutIntegrationSyncsNestedInput {
  create?: IntegrationCreateWithoutIntegrationSyncsInput | null;
  connectOrCreate?: IntegrationCreateOrConnectWithoutIntegrationSyncsInput | null;
  upsert?: IntegrationUpsertWithoutIntegrationSyncsInput | null;
  connect?: IntegrationWhereUniqueInput | null;
  update?: IntegrationUpdateWithoutIntegrationSyncsInput | null;
}

export interface IntegrationUpdateWithWhereUniqueWithoutLocationInput {
  where: IntegrationWhereUniqueInput;
  data: IntegrationUpdateWithoutLocationInput;
}

export interface IntegrationUpdateWithWhereUniqueWithoutOrganizationInput {
  where: IntegrationWhereUniqueInput;
  data: IntegrationUpdateWithoutOrganizationInput;
}

export interface IntegrationUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  type?: EnumIntegrationTypeFieldUpdateOperationsInput | null;
  databaseConnectionUrl?: NullableStringFieldUpdateOperationsInput | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: BoolFieldUpdateOperationsInput | null;
  timezone?: NullableStringFieldUpdateOperationsInput | null;
  paymentPostingMethod?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  chirotouchProviderPaymentPostingBehavior?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutIntegrationsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutIntegrationsNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutIntegrationNestedInput | null;
  integrationSyncSchedules?: IntegrationSyncScheduleUpdateManyWithoutIntegrationNestedInput | null;
}

export interface IntegrationUpdateWithoutIntegrationSyncSchedulesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  type?: EnumIntegrationTypeFieldUpdateOperationsInput | null;
  databaseConnectionUrl?: NullableStringFieldUpdateOperationsInput | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: BoolFieldUpdateOperationsInput | null;
  timezone?: NullableStringFieldUpdateOperationsInput | null;
  paymentPostingMethod?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  chirotouchProviderPaymentPostingBehavior?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutIntegrationsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutIntegrationsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutIntegrationNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutIntegrationNestedInput | null;
}

export interface IntegrationUpdateWithoutIntegrationSyncsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  type?: EnumIntegrationTypeFieldUpdateOperationsInput | null;
  databaseConnectionUrl?: NullableStringFieldUpdateOperationsInput | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: BoolFieldUpdateOperationsInput | null;
  timezone?: NullableStringFieldUpdateOperationsInput | null;
  paymentPostingMethod?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  chirotouchProviderPaymentPostingBehavior?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutIntegrationsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutIntegrationsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutIntegrationNestedInput | null;
  integrationSyncSchedules?: IntegrationSyncScheduleUpdateManyWithoutIntegrationNestedInput | null;
}

export interface IntegrationUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  type?: EnumIntegrationTypeFieldUpdateOperationsInput | null;
  databaseConnectionUrl?: NullableStringFieldUpdateOperationsInput | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: BoolFieldUpdateOperationsInput | null;
  timezone?: NullableStringFieldUpdateOperationsInput | null;
  paymentPostingMethod?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  chirotouchProviderPaymentPostingBehavior?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutIntegrationsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutIntegrationNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutIntegrationNestedInput | null;
  integrationSyncSchedules?: IntegrationSyncScheduleUpdateManyWithoutIntegrationNestedInput | null;
}

export interface IntegrationUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  type?: EnumIntegrationTypeFieldUpdateOperationsInput | null;
  databaseConnectionUrl?: NullableStringFieldUpdateOperationsInput | null;
  authenticationParams?: any | null;
  paymentPostingEnabled?: BoolFieldUpdateOperationsInput | null;
  timezone?: NullableStringFieldUpdateOperationsInput | null;
  paymentPostingMethod?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  chirotouchProviderPaymentPostingBehavior?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutIntegrationsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutIntegrationNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutIntegrationNestedInput | null;
  integrationSyncSchedules?: IntegrationSyncScheduleUpdateManyWithoutIntegrationNestedInput | null;
}

export interface IntegrationUpsertWithWhereUniqueWithoutLocationInput {
  where: IntegrationWhereUniqueInput;
  update: IntegrationUpdateWithoutLocationInput;
  create: IntegrationCreateWithoutLocationInput;
}

export interface IntegrationUpsertWithWhereUniqueWithoutOrganizationInput {
  where: IntegrationWhereUniqueInput;
  update: IntegrationUpdateWithoutOrganizationInput;
  create: IntegrationCreateWithoutOrganizationInput;
}

export interface IntegrationUpsertWithoutIntegrationLinksInput {
  update: IntegrationUpdateWithoutIntegrationLinksInput;
  create: IntegrationCreateWithoutIntegrationLinksInput;
}

export interface IntegrationUpsertWithoutIntegrationSyncSchedulesInput {
  update: IntegrationUpdateWithoutIntegrationSyncSchedulesInput;
  create: IntegrationCreateWithoutIntegrationSyncSchedulesInput;
}

export interface IntegrationUpsertWithoutIntegrationSyncsInput {
  update: IntegrationUpdateWithoutIntegrationSyncsInput;
  create: IntegrationCreateWithoutIntegrationSyncsInput;
}

export interface IntegrationWhereInput {
  AND?: IntegrationWhereInput[] | null;
  OR?: IntegrationWhereInput[] | null;
  NOT?: IntegrationWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  type?: EnumIntegrationTypeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  databaseConnectionUrl?: StringNullableFilter | null;
  authenticationParams?: JsonNullableFilter | null;
  paymentPostingEnabled?: BoolFilter | null;
  timezone?: StringNullableFilter | null;
  paymentPostingMethod?: EnumPaymentMethodNullableFilter | null;
  chirotouchProviderPaymentPostingBehavior?: StringNullableFilter | null;
  organization?: OrganizationRelationFilter | null;
  location?: LocationRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
  integrationSyncs?: IntegrationSyncListRelationFilter | null;
  integrationSyncSchedules?: IntegrationSyncScheduleListRelationFilter | null;
}

export interface IntegrationWhereUniqueInput {
  id?: string | null;
}

export interface JsonFilter {
  equals?: any | null;
  path?: string[] | null;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: any | null;
  array_starts_with?: any | null;
  array_ends_with?: any | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: any | null;
}

export interface JsonNullableFilter {
  equals?: any | null;
  path?: string[] | null;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: any | null;
  array_starts_with?: any | null;
  array_ends_with?: any | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: any | null;
}

export interface JsonNullableListFilter {
  equals?: any[] | null;
  has?: any | null;
  hasEvery?: any[] | null;
  hasSome?: any[] | null;
  isEmpty?: boolean | null;
}

export interface LimitationBenefitCreateManyEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: LimitationBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
}

export interface LimitationBenefitCreateManyEligibilityRequestInputEnvelope {
  data: LimitationBenefitCreateManyEligibilityRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface LimitationBenefitCreateNestedManyWithoutEligibilityRequestInput {
  create?: LimitationBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: LimitationBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  createMany?: LimitationBenefitCreateManyEligibilityRequestInputEnvelope | null;
  connect?: LimitationBenefitWhereUniqueInput[] | null;
}

export interface LimitationBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput {
  create?: LimitationBenefitCreateWithoutMaxCoverageBenefitsInput | null;
  connectOrCreate?: LimitationBenefitCreateOrConnectWithoutMaxCoverageBenefitsInput | null;
  connect?: LimitationBenefitWhereUniqueInput | null;
}

export interface LimitationBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  create?: LimitationBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: LimitationBenefitCreateOrConnectWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  connect?: LimitationBenefitWhereUniqueInput | null;
}

export interface LimitationBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput {
  create?: LimitationBenefitCreateWithoutRemainingCoverageBenefitsInput | null;
  connectOrCreate?: LimitationBenefitCreateOrConnectWithoutRemainingCoverageBenefitsInput | null;
  connect?: LimitationBenefitWhereUniqueInput | null;
}

export interface LimitationBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  create?: LimitationBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: LimitationBenefitCreateOrConnectWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  connect?: LimitationBenefitWhereUniqueInput | null;
}

export interface LimitationBenefitCreateOrConnectWithoutEligibilityRequestInput {
  where: LimitationBenefitWhereUniqueInput;
  create: LimitationBenefitCreateWithoutEligibilityRequestInput;
}

export interface LimitationBenefitCreateOrConnectWithoutMaxCoverageBenefitsInput {
  where: LimitationBenefitWhereUniqueInput;
  create: LimitationBenefitCreateWithoutMaxCoverageBenefitsInput;
}

export interface LimitationBenefitCreateOrConnectWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  where: LimitationBenefitWhereUniqueInput;
  create: LimitationBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput;
}

export interface LimitationBenefitCreateOrConnectWithoutRemainingCoverageBenefitsInput {
  where: LimitationBenefitWhereUniqueInput;
  create: LimitationBenefitCreateWithoutRemainingCoverageBenefitsInput;
}

export interface LimitationBenefitCreateOrConnectWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  where: LimitationBenefitWhereUniqueInput;
  create: LimitationBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput;
}

export interface LimitationBenefitCreateWithoutEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: LimitationBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingVisitsBenefitInput | null;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxVisitsBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingVisitsBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxVisitsBenefitInput | null;
}

export interface LimitationBenefitCreateWithoutMaxCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: LimitationBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutLimitationBenefitsInput;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingVisitsBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingVisitsBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxVisitsBenefitInput | null;
}

export interface LimitationBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: LimitationBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutLimitationBenefitsInput;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingVisitsBenefitInput | null;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxVisitsBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingVisitsBenefitInput | null;
}

export interface LimitationBenefitCreateWithoutRemainingCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: LimitationBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutLimitationBenefitsInput;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxVisitsBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingVisitsBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxVisitsBenefitInput | null;
}

export interface LimitationBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: LimitationBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutLimitationBenefitsInput;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingVisitsBenefitInput | null;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxVisitsBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxVisitsBenefitInput | null;
}

export interface LimitationBenefitCreatenotesInput {
  set: string[];
}

export interface LimitationBenefitListRelationFilter {
  every?: LimitationBenefitWhereInput | null;
  some?: LimitationBenefitWhereInput | null;
  none?: LimitationBenefitWhereInput | null;
}

export interface LimitationBenefitRelationFilter {
  is?: LimitationBenefitWhereInput | null;
  isNot?: LimitationBenefitWhereInput | null;
}

export interface LimitationBenefitScalarWhereInput {
  AND?: LimitationBenefitScalarWhereInput[] | null;
  OR?: LimitationBenefitScalarWhereInput[] | null;
  NOT?: LimitationBenefitScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  max?: IntNullableFilter | null;
  remaining?: IntNullableFilter | null;
  timePeriodQualifier?: StringNullableFilter | null;
  quantityQualifier?: StringNullableFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
}

export interface LimitationBenefitUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: LimitationBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
}

export interface LimitationBenefitUpdateManyWithWhereWithoutEligibilityRequestInput {
  where: LimitationBenefitScalarWhereInput;
  data: LimitationBenefitUpdateManyMutationInput;
}

export interface LimitationBenefitUpdateManyWithoutEligibilityRequestNestedInput {
  create?: LimitationBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: LimitationBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  upsert?: LimitationBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  createMany?: LimitationBenefitCreateManyEligibilityRequestInputEnvelope | null;
  set?: LimitationBenefitWhereUniqueInput[] | null;
  disconnect?: LimitationBenefitWhereUniqueInput[] | null;
  delete?: LimitationBenefitWhereUniqueInput[] | null;
  connect?: LimitationBenefitWhereUniqueInput[] | null;
  update?: LimitationBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  updateMany?: LimitationBenefitUpdateManyWithWhereWithoutEligibilityRequestInput[] | null;
  deleteMany?: LimitationBenefitScalarWhereInput[] | null;
}

export interface LimitationBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput {
  create?: LimitationBenefitCreateWithoutMaxCoverageBenefitsInput | null;
  connectOrCreate?: LimitationBenefitCreateOrConnectWithoutMaxCoverageBenefitsInput | null;
  upsert?: LimitationBenefitUpsertWithoutMaxCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: LimitationBenefitWhereUniqueInput | null;
  update?: LimitationBenefitUpdateWithoutMaxCoverageBenefitsInput | null;
}

export interface LimitationBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput {
  create?: LimitationBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: LimitationBenefitCreateOrConnectWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  upsert?: LimitationBenefitUpsertWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: LimitationBenefitWhereUniqueInput | null;
  update?: LimitationBenefitUpdateWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
}

export interface LimitationBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput {
  create?: LimitationBenefitCreateWithoutRemainingCoverageBenefitsInput | null;
  connectOrCreate?: LimitationBenefitCreateOrConnectWithoutRemainingCoverageBenefitsInput | null;
  upsert?: LimitationBenefitUpsertWithoutRemainingCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: LimitationBenefitWhereUniqueInput | null;
  update?: LimitationBenefitUpdateWithoutRemainingCoverageBenefitsInput | null;
}

export interface LimitationBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput {
  create?: LimitationBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: LimitationBenefitCreateOrConnectWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  upsert?: LimitationBenefitUpsertWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: LimitationBenefitWhereUniqueInput | null;
  update?: LimitationBenefitUpdateWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
}

export interface LimitationBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput {
  where: LimitationBenefitWhereUniqueInput;
  data: LimitationBenefitUpdateWithoutEligibilityRequestInput;
}

export interface LimitationBenefitUpdateWithoutEligibilityRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: LimitationBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingVisitsBenefitNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxVisitsBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingVisitsBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxVisitsBenefitNestedInput | null;
}

export interface LimitationBenefitUpdateWithoutMaxCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: LimitationBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutLimitationBenefitsNestedInput | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingVisitsBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingVisitsBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxVisitsBenefitNestedInput | null;
}

export interface LimitationBenefitUpdateWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: LimitationBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutLimitationBenefitsNestedInput | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingVisitsBenefitNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxVisitsBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingVisitsBenefitNestedInput | null;
}

export interface LimitationBenefitUpdateWithoutRemainingCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: LimitationBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutLimitationBenefitsNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxVisitsBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingVisitsBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxVisitsBenefitNestedInput | null;
}

export interface LimitationBenefitUpdateWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: LimitationBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutLimitationBenefitsNestedInput | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingVisitsBenefitNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxVisitsBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxVisitsBenefitNestedInput | null;
}

export interface LimitationBenefitUpdatenotesInput {
  set?: string[] | null;
  push?: string[] | null;
}

export interface LimitationBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput {
  where: LimitationBenefitWhereUniqueInput;
  update: LimitationBenefitUpdateWithoutEligibilityRequestInput;
  create: LimitationBenefitCreateWithoutEligibilityRequestInput;
}

export interface LimitationBenefitUpsertWithoutMaxCoverageBenefitsInput {
  update: LimitationBenefitUpdateWithoutMaxCoverageBenefitsInput;
  create: LimitationBenefitCreateWithoutMaxCoverageBenefitsInput;
}

export interface LimitationBenefitUpsertWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  update: LimitationBenefitUpdateWithoutMaxEligibilityCoverageBenefitSnapshotInput;
  create: LimitationBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput;
}

export interface LimitationBenefitUpsertWithoutRemainingCoverageBenefitsInput {
  update: LimitationBenefitUpdateWithoutRemainingCoverageBenefitsInput;
  create: LimitationBenefitCreateWithoutRemainingCoverageBenefitsInput;
}

export interface LimitationBenefitUpsertWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  update: LimitationBenefitUpdateWithoutRemainingEligibilityCoverageBenefitSnapshotInput;
  create: LimitationBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput;
}

export interface LimitationBenefitWhereInput {
  AND?: LimitationBenefitWhereInput[] | null;
  OR?: LimitationBenefitWhereInput[] | null;
  NOT?: LimitationBenefitWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  max?: IntNullableFilter | null;
  remaining?: IntNullableFilter | null;
  timePeriodQualifier?: StringNullableFilter | null;
  quantityQualifier?: StringNullableFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
  eligibilityRequest?: EligibilityRequestRelationFilter | null;
  remainingCoverageBenefits?: CoverageBenefitListRelationFilter | null;
  maxCoverageBenefits?: CoverageBenefitListRelationFilter | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
}

export interface LimitationBenefitWhereUniqueInput {
  id?: string | null;
}

export interface LocationCreateManyDefaultEligibilityProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  organizationId: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  defaultVerificationWorkflowStatusId?: string | null;
  confirmationVerificationWorkflowStatusId?: string | null;
  unconfirmationVerificationWorkflowStatusId?: string | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  defaultEstimationWorkflowStatusId?: string | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
}

export interface LocationCreateManyDefaultEligibilityProviderInputEnvelope {
  data: LocationCreateManyDefaultEligibilityProviderInput[];
  skipDuplicates?: boolean | null;
}

export interface LocationCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  defaultEligibilityProviderId?: string | null;
  verificationWorkflowEnabled?: boolean | null;
  defaultVerificationWorkflowStatusId?: string | null;
  confirmationVerificationWorkflowStatusId?: string | null;
  unconfirmationVerificationWorkflowStatusId?: string | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  defaultEstimationWorkflowStatusId?: string | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
}

export interface LocationCreateManyOrganizationInputEnvelope {
  data: LocationCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface LocationCreateNestedManyWithoutDefaultEligibilityProviderInput {
  create?: LocationCreateWithoutDefaultEligibilityProviderInput[] | null;
  connectOrCreate?: LocationCreateOrConnectWithoutDefaultEligibilityProviderInput[] | null;
  createMany?: LocationCreateManyDefaultEligibilityProviderInputEnvelope | null;
  connect?: LocationWhereUniqueInput[] | null;
}

export interface LocationCreateNestedManyWithoutOrganizationInput {
  create?: LocationCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: LocationCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: LocationCreateManyOrganizationInputEnvelope | null;
  connect?: LocationWhereUniqueInput[] | null;
}

export interface LocationCreateNestedOneWithoutAccountTypesInput {
  create?: LocationCreateWithoutAccountTypesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutAccountTypesInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutAccountsInput {
  create?: LocationCreateWithoutAccountsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutAccountsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutAppointmentLabelsInput {
  create?: LocationCreateWithoutAppointmentLabelsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutAppointmentLabelsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutAppointmentsInput {
  create?: LocationCreateWithoutAppointmentsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutAppointmentsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutBenefitMappingsInput {
  create?: LocationCreateWithoutBenefitMappingsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutBenefitMappingsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutChargeTemplateMappingsInput {
  create?: LocationCreateWithoutChargeTemplateMappingsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutChargeTemplateMappingsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutChargeTemplatesInput {
  create?: LocationCreateWithoutChargeTemplatesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutChargeTemplatesInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutChargemasterGroupsInput {
  create?: LocationCreateWithoutChargemasterGroupsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutChargemasterGroupsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutChargemastersInput {
  create?: LocationCreateWithoutChargemastersInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutChargemastersInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutConfirmationVerificationWorkflowStatusInput {
  create?: LocationCreateWithoutConfirmationVerificationWorkflowStatusInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutConfirmationVerificationWorkflowStatusInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutDefaultEstimationWorkflowStatusInput {
  create?: LocationCreateWithoutDefaultEstimationWorkflowStatusInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutDefaultEstimationWorkflowStatusInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutDefaultVerificationWorkflowStatusInput {
  create?: LocationCreateWithoutDefaultVerificationWorkflowStatusInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutDefaultVerificationWorkflowStatusInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutDepositMappingsInput {
  create?: LocationCreateWithoutDepositMappingsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutDepositMappingsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutEstimationWorkflowStatusesInput {
  create?: LocationCreateWithoutEstimationWorkflowStatusesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutEstimationWorkflowStatusesInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutFeeScheduleMappingsInput {
  create?: LocationCreateWithoutFeeScheduleMappingsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutFeeScheduleMappingsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutFeeSchedulesInput {
  create?: LocationCreateWithoutFeeSchedulesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutFeeSchedulesInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutInsurancePolicyWorklistItemTaskTemplatesInput {
  create?: LocationCreateWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutIntegrationsInput {
  create?: LocationCreateWithoutIntegrationsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutIntegrationsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutPatientLabelsInput {
  create?: LocationCreateWithoutPatientLabelsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutPatientLabelsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutPatientsInput {
  create?: LocationCreateWithoutPatientsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutPatientsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutPayersInput {
  create?: LocationCreateWithoutPayersInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutPayersInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutPaymentRequestBatchesInput {
  create?: LocationCreateWithoutPaymentRequestBatchesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutPaymentRequestBatchesInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutPreVisitMappingsInput {
  create?: LocationCreateWithoutPreVisitMappingsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutPreVisitMappingsInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutProvidersInput {
  create?: LocationCreateWithoutProvidersInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutProvidersInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutTasksInput {
  create?: LocationCreateWithoutTasksInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutTasksInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutUnconfirmationVerificationWorkflowStatusInput {
  create?: LocationCreateWithoutUnconfirmationVerificationWorkflowStatusInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutUnconfirmationVerificationWorkflowStatusInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutUsersInput {
  create?: LocationCreateWithoutUsersInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutUsersInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateNestedOneWithoutVerificationWorkflowStatusesInput {
  create?: LocationCreateWithoutVerificationWorkflowStatusesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutVerificationWorkflowStatusesInput | null;
  connect?: LocationWhereUniqueInput | null;
}

export interface LocationCreateOrConnectWithoutAccountTypesInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutAccountTypesInput;
}

export interface LocationCreateOrConnectWithoutAccountsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutAccountsInput;
}

export interface LocationCreateOrConnectWithoutAppointmentLabelsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutAppointmentLabelsInput;
}

export interface LocationCreateOrConnectWithoutAppointmentsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutAppointmentsInput;
}

export interface LocationCreateOrConnectWithoutBenefitMappingsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutBenefitMappingsInput;
}

export interface LocationCreateOrConnectWithoutChargeTemplateMappingsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutChargeTemplateMappingsInput;
}

export interface LocationCreateOrConnectWithoutChargeTemplatesInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutChargeTemplatesInput;
}

export interface LocationCreateOrConnectWithoutChargemasterGroupsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutChargemasterGroupsInput;
}

export interface LocationCreateOrConnectWithoutChargemastersInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutChargemastersInput;
}

export interface LocationCreateOrConnectWithoutConfirmationVerificationWorkflowStatusInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutConfirmationVerificationWorkflowStatusInput;
}

export interface LocationCreateOrConnectWithoutDefaultEligibilityProviderInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutDefaultEligibilityProviderInput;
}

export interface LocationCreateOrConnectWithoutDefaultEstimationWorkflowStatusInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutDefaultEstimationWorkflowStatusInput;
}

export interface LocationCreateOrConnectWithoutDefaultVerificationWorkflowStatusInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutDefaultVerificationWorkflowStatusInput;
}

export interface LocationCreateOrConnectWithoutDepositMappingsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutDepositMappingsInput;
}

export interface LocationCreateOrConnectWithoutEstimationWorkflowStatusesInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutEstimationWorkflowStatusesInput;
}

export interface LocationCreateOrConnectWithoutFeeScheduleMappingsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutFeeScheduleMappingsInput;
}

export interface LocationCreateOrConnectWithoutFeeSchedulesInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutFeeSchedulesInput;
}

export interface LocationCreateOrConnectWithoutInsurancePolicyWorklistItemTaskTemplatesInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutInsurancePolicyWorklistItemTaskTemplatesInput;
}

export interface LocationCreateOrConnectWithoutIntegrationsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutIntegrationsInput;
}

export interface LocationCreateOrConnectWithoutOrganizationInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutOrganizationInput;
}

export interface LocationCreateOrConnectWithoutPatientLabelsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutPatientLabelsInput;
}

export interface LocationCreateOrConnectWithoutPatientsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutPatientsInput;
}

export interface LocationCreateOrConnectWithoutPayersInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutPayersInput;
}

export interface LocationCreateOrConnectWithoutPaymentRequestBatchesInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutPaymentRequestBatchesInput;
}

export interface LocationCreateOrConnectWithoutPreVisitMappingsInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutPreVisitMappingsInput;
}

export interface LocationCreateOrConnectWithoutProvidersInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutProvidersInput;
}

export interface LocationCreateOrConnectWithoutTasksInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutTasksInput;
}

export interface LocationCreateOrConnectWithoutUnconfirmationVerificationWorkflowStatusInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutUnconfirmationVerificationWorkflowStatusInput;
}

export interface LocationCreateOrConnectWithoutUsersInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutUsersInput;
}

export interface LocationCreateOrConnectWithoutVerificationWorkflowStatusesInput {
  where: LocationWhereUniqueInput;
  create: LocationCreateWithoutVerificationWorkflowStatusesInput;
}

export interface LocationCreateWithoutAccountTypesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutAccountsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutAppointmentLabelsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutAppointmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutBenefitMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutChargeTemplateMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutChargeTemplatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutChargemasterGroupsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutChargemastersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutConfirmationVerificationWorkflowStatusInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutDefaultEligibilityProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutDefaultEstimationWorkflowStatusInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutDefaultVerificationWorkflowStatusInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutDepositMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutEstimationWorkflowStatusesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutFeeScheduleMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutFeeSchedulesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutInsurancePolicyWorklistItemTaskTemplatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutIntegrationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutPatientLabelsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutPatientsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutPayersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutPaymentRequestBatchesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutPreVisitMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutProvidersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutTasksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutUnconfirmationVerificationWorkflowStatusInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutUsersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationCreateWithoutVerificationWorkflowStatusesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  backloadedAt?: any | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  senderEmail?: string | null;
  automatedEligibilityVerificationEnabled?: boolean | null;
  locationDisplayName?: string | null;
  timezone?: string | null;
  skipReceiptNotifications?: boolean | null;
  visitAutopayEnabled?: boolean | null;
  visitAutopayChargeDelay?: number | null;
  adjudicatedAutopayEnabled?: boolean | null;
  balanceAutopayEnabled?: boolean | null;
  balanceAutopayCycleCloseDate?: number | null;
  balanceAutopayChargeDelay?: number | null;
  balanceAutopayHourOfDay?: number | null;
  balanceAutopayMinuteOfHour?: number | null;
  balanceAutopayTimezone?: string | null;
  externalAutopayEnabled?: boolean | null;
  maxAutopayLimitEnabled?: boolean | null;
  defaultMaxAutopayLimit?: number | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: boolean | null;
  estimationWorkflowEnabled?: boolean | null;
  estimateSetupMode?: boolean | null;
  automatedAccumulatorTrackingEnabled?: boolean | null;
  autoconfirmPoliciesEnabled?: boolean | null;
  billingEmail?: string | null;
  billingPhone?: string | null;
  billingCheckPayable?: string | null;
  billingDefaultMessage?: string | null;
  billingPaperDoubleSided?: boolean | null;
  saltingEnabled?: boolean | null;
  saltingLookbackMonths?: number | null;
  saltingMatchAccount?: boolean | null;
  saltingMatchAppointmentLabel?: boolean | null;
  saltingMatchProvider?: boolean | null;
  automatedEstimatesEnabled?: boolean | null;
  preVisitEstimateDaysAhead?: number | null;
  preVisitReminderEnabled?: boolean | null;
  preVisitReminderDaysAhead?: number | null;
  preVisitCardOnFileInput?: ValidationStatus | null;
  preVisitFinancialPolicyInput?: ValidationStatus | null;
  preVisitReminderEstimateDisplay?: ValidationStatus | null;
  automatedTimeOfServiceChargingEnabled?: boolean | null;
  automatedTimeOfServiceChargingDelayMinutes?: number | null;
  automatedTimeOfServiceChargingHourOfDay?: number | null;
  automatedTimeOfServiceChargingMinuteOfHour?: number | null;
  autopostPendingPaymentThresholdDays?: number | null;
  ledgerGranularityLevel?: LedgerGranularityLevel | null;
  organization: OrganizationCreateNestedOneWithoutLocationsInput;
  defaultEligibilityProvider?: ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput | null;
  appointments?: AppointmentCreateNestedManyWithoutLocationInput | null;
  accounts?: AccountCreateNestedManyWithoutLocationInput | null;
  patients?: PatientCreateNestedManyWithoutLocationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutLocationInput | null;
  providers?: ProviderCreateNestedManyWithoutPrimaryLocationInput | null;
  users?: UserCreateNestedManyWithoutActiveLocationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutLocationInput | null;
  payers?: PayerCreateNestedManyWithoutLocationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutLocationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutLocationInput | null;
  tasks?: TaskCreateNestedManyWithoutLocationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutLocationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplates?: ChargeTemplateCreateNestedManyWithoutLocationInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLocationInput | null;
  feeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLocationInput | null;
  chargemasters?: ChargemasterCreateNestedManyWithoutLocationInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusCreateNestedManyWithoutLocationInput | null;
  chargemasterGroups?: ChargemasterGroupCreateNestedManyWithoutLocationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutLocationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutLocationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutLocationInput | null;
  preVisitMappings?: PreVisitMappingCreateNestedManyWithoutLocationInput | null;
}

export interface LocationListRelationFilter {
  every?: LocationWhereInput | null;
  some?: LocationWhereInput | null;
  none?: LocationWhereInput | null;
}

export interface LocationOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface LocationOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  backloadedAt?: SortOrderInput | null;
  name?: SortOrder | null;
  address1?: SortOrder | null;
  address2?: SortOrderInput | null;
  city?: SortOrder | null;
  postalCode?: SortOrder | null;
  state?: SortOrder | null;
  country?: SortOrder | null;
  organizationId?: SortOrder | null;
  replyToEmails?: SortOrderInput | null;
  primaryEmail?: SortOrderInput | null;
  phone?: SortOrderInput | null;
  openHoursBlurb?: SortOrderInput | null;
  senderEmail?: SortOrderInput | null;
  automatedEligibilityVerificationEnabled?: SortOrder | null;
  locationDisplayName?: SortOrderInput | null;
  timezone?: SortOrder | null;
  skipReceiptNotifications?: SortOrder | null;
  visitAutopayEnabled?: SortOrder | null;
  visitAutopayChargeDelay?: SortOrder | null;
  adjudicatedAutopayEnabled?: SortOrder | null;
  balanceAutopayEnabled?: SortOrder | null;
  balanceAutopayCycleCloseDate?: SortOrder | null;
  balanceAutopayChargeDelay?: SortOrder | null;
  balanceAutopayHourOfDay?: SortOrder | null;
  balanceAutopayMinuteOfHour?: SortOrder | null;
  balanceAutopayTimezone?: SortOrder | null;
  externalAutopayEnabled?: SortOrder | null;
  maxAutopayLimitEnabled?: SortOrder | null;
  defaultMaxAutopayLimit?: SortOrderInput | null;
  featureFlags?: SortOrderInput | null;
  defaultEligibilityProviderId?: SortOrderInput | null;
  verificationWorkflowEnabled?: SortOrder | null;
  defaultVerificationWorkflowStatusId?: SortOrderInput | null;
  confirmationVerificationWorkflowStatusId?: SortOrderInput | null;
  unconfirmationVerificationWorkflowStatusId?: SortOrderInput | null;
  estimationWorkflowEnabled?: SortOrder | null;
  estimateSetupMode?: SortOrder | null;
  defaultEstimationWorkflowStatusId?: SortOrderInput | null;
  automatedAccumulatorTrackingEnabled?: SortOrder | null;
  autoconfirmPoliciesEnabled?: SortOrderInput | null;
  billingEmail?: SortOrderInput | null;
  billingPhone?: SortOrderInput | null;
  billingCheckPayable?: SortOrderInput | null;
  billingDefaultMessage?: SortOrderInput | null;
  billingPaperDoubleSided?: SortOrderInput | null;
  saltingEnabled?: SortOrderInput | null;
  saltingLookbackMonths?: SortOrder | null;
  saltingMatchAccount?: SortOrder | null;
  saltingMatchAppointmentLabel?: SortOrder | null;
  saltingMatchProvider?: SortOrder | null;
  automatedEstimatesEnabled?: SortOrder | null;
  preVisitEstimateDaysAhead?: SortOrderInput | null;
  preVisitReminderEnabled?: SortOrder | null;
  preVisitReminderDaysAhead?: SortOrderInput | null;
  preVisitCardOnFileInput?: SortOrder | null;
  preVisitFinancialPolicyInput?: SortOrder | null;
  preVisitReminderEstimateDisplay?: SortOrder | null;
  automatedTimeOfServiceChargingEnabled?: SortOrder | null;
  automatedTimeOfServiceChargingDelayMinutes?: SortOrderInput | null;
  automatedTimeOfServiceChargingHourOfDay?: SortOrderInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: SortOrderInput | null;
  autopostPendingPaymentThresholdDays?: SortOrderInput | null;
  ledgerGranularityLevel?: SortOrder | null;
  organization?: OrganizationOrderByWithRelationInput | null;
  defaultEligibilityProvider?: ProviderOrderByWithRelationInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusOrderByWithRelationInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusOrderByWithRelationInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusOrderByWithRelationInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusOrderByWithRelationInput | null;
  appointments?: AppointmentOrderByRelationAggregateInput | null;
  accounts?: AccountOrderByRelationAggregateInput | null;
  patients?: PatientOrderByRelationAggregateInput | null;
  integrations?: IntegrationOrderByRelationAggregateInput | null;
  providers?: ProviderOrderByRelationAggregateInput | null;
  users?: UserOrderByRelationAggregateInput | null;
  accountTypes?: AccountTypeOrderByRelationAggregateInput | null;
  payers?: PayerOrderByRelationAggregateInput | null;
  feeSchedules?: FeeScheduleOrderByRelationAggregateInput | null;
  paymentRequestBatches?: PaymentRequestBatchOrderByRelationAggregateInput | null;
  tasks?: TaskOrderByRelationAggregateInput | null;
  benefitMappings?: BenefitMappingOrderByRelationAggregateInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateOrderByRelationAggregateInput | null;
  chargeTemplates?: ChargeTemplateOrderByRelationAggregateInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingOrderByRelationAggregateInput | null;
  feeScheduleMappings?: FeeScheduleMappingOrderByRelationAggregateInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusOrderByRelationAggregateInput | null;
  chargemasters?: ChargemasterOrderByRelationAggregateInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusOrderByRelationAggregateInput | null;
  chargemasterGroups?: ChargemasterGroupOrderByRelationAggregateInput | null;
  appointmentLabels?: AppointmentLabelOrderByRelationAggregateInput | null;
  patientLabels?: PatientLabelOrderByRelationAggregateInput | null;
  depositMappings?: DepositMappingOrderByRelationAggregateInput | null;
  preVisitMappings?: PreVisitMappingOrderByRelationAggregateInput | null;
}

export interface LocationRelationFilter {
  is?: LocationWhereInput | null;
  isNot?: LocationWhereInput | null;
}

export interface LocationScalarWhereInput {
  AND?: LocationScalarWhereInput[] | null;
  OR?: LocationScalarWhereInput[] | null;
  NOT?: LocationScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  backloadedAt?: DateTimeNullableFilter | null;
  name?: StringFilter | null;
  address1?: StringFilter | null;
  address2?: StringNullableFilter | null;
  city?: StringFilter | null;
  postalCode?: StringFilter | null;
  state?: StringFilter | null;
  country?: StringFilter | null;
  organizationId?: UuidFilter | null;
  replyToEmails?: JsonNullableFilter | null;
  primaryEmail?: StringNullableFilter | null;
  phone?: StringNullableFilter | null;
  openHoursBlurb?: StringNullableFilter | null;
  senderEmail?: StringNullableFilter | null;
  automatedEligibilityVerificationEnabled?: BoolFilter | null;
  locationDisplayName?: StringNullableFilter | null;
  timezone?: StringFilter | null;
  skipReceiptNotifications?: BoolFilter | null;
  visitAutopayEnabled?: BoolFilter | null;
  visitAutopayChargeDelay?: IntFilter | null;
  adjudicatedAutopayEnabled?: BoolFilter | null;
  balanceAutopayEnabled?: BoolFilter | null;
  balanceAutopayCycleCloseDate?: IntFilter | null;
  balanceAutopayChargeDelay?: IntFilter | null;
  balanceAutopayHourOfDay?: IntFilter | null;
  balanceAutopayMinuteOfHour?: IntFilter | null;
  balanceAutopayTimezone?: StringFilter | null;
  externalAutopayEnabled?: BoolFilter | null;
  maxAutopayLimitEnabled?: BoolFilter | null;
  defaultMaxAutopayLimit?: IntNullableFilter | null;
  featureFlags?: JsonNullableFilter | null;
  defaultEligibilityProviderId?: UuidNullableFilter | null;
  verificationWorkflowEnabled?: BoolFilter | null;
  defaultVerificationWorkflowStatusId?: UuidNullableFilter | null;
  confirmationVerificationWorkflowStatusId?: UuidNullableFilter | null;
  unconfirmationVerificationWorkflowStatusId?: UuidNullableFilter | null;
  estimationWorkflowEnabled?: BoolFilter | null;
  estimateSetupMode?: BoolFilter | null;
  defaultEstimationWorkflowStatusId?: UuidNullableFilter | null;
  automatedAccumulatorTrackingEnabled?: BoolFilter | null;
  autoconfirmPoliciesEnabled?: BoolNullableFilter | null;
  billingEmail?: StringNullableFilter | null;
  billingPhone?: StringNullableFilter | null;
  billingCheckPayable?: StringNullableFilter | null;
  billingDefaultMessage?: StringNullableFilter | null;
  billingPaperDoubleSided?: BoolNullableFilter | null;
  saltingEnabled?: BoolNullableFilter | null;
  saltingLookbackMonths?: IntFilter | null;
  saltingMatchAccount?: BoolFilter | null;
  saltingMatchAppointmentLabel?: BoolFilter | null;
  saltingMatchProvider?: BoolFilter | null;
  automatedEstimatesEnabled?: BoolFilter | null;
  preVisitEstimateDaysAhead?: IntNullableFilter | null;
  preVisitReminderEnabled?: BoolFilter | null;
  preVisitReminderDaysAhead?: IntNullableFilter | null;
  preVisitCardOnFileInput?: EnumValidationStatusFilter | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFilter | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFilter | null;
  automatedTimeOfServiceChargingEnabled?: BoolFilter | null;
  automatedTimeOfServiceChargingDelayMinutes?: IntNullableFilter | null;
  automatedTimeOfServiceChargingHourOfDay?: IntNullableFilter | null;
  automatedTimeOfServiceChargingMinuteOfHour?: IntNullableFilter | null;
  autopostPendingPaymentThresholdDays?: IntNullableFilter | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFilter | null;
}

export interface LocationUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
}

export interface LocationUpdateManyWithWhereWithoutDefaultEligibilityProviderInput {
  where: LocationScalarWhereInput;
  data: LocationUpdateManyMutationInput;
}

export interface LocationUpdateManyWithWhereWithoutOrganizationInput {
  where: LocationScalarWhereInput;
  data: LocationUpdateManyMutationInput;
}

export interface LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput {
  create?: LocationCreateWithoutDefaultEligibilityProviderInput[] | null;
  connectOrCreate?: LocationCreateOrConnectWithoutDefaultEligibilityProviderInput[] | null;
  upsert?: LocationUpsertWithWhereUniqueWithoutDefaultEligibilityProviderInput[] | null;
  createMany?: LocationCreateManyDefaultEligibilityProviderInputEnvelope | null;
  set?: LocationWhereUniqueInput[] | null;
  disconnect?: LocationWhereUniqueInput[] | null;
  delete?: LocationWhereUniqueInput[] | null;
  connect?: LocationWhereUniqueInput[] | null;
  update?: LocationUpdateWithWhereUniqueWithoutDefaultEligibilityProviderInput[] | null;
  updateMany?: LocationUpdateManyWithWhereWithoutDefaultEligibilityProviderInput[] | null;
  deleteMany?: LocationScalarWhereInput[] | null;
}

export interface LocationUpdateManyWithoutOrganizationNestedInput {
  create?: LocationCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: LocationCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: LocationUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: LocationCreateManyOrganizationInputEnvelope | null;
  set?: LocationWhereUniqueInput[] | null;
  disconnect?: LocationWhereUniqueInput[] | null;
  delete?: LocationWhereUniqueInput[] | null;
  connect?: LocationWhereUniqueInput[] | null;
  update?: LocationUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: LocationUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: LocationScalarWhereInput[] | null;
}

export interface LocationUpdateOneRequiredWithoutAccountTypesNestedInput {
  create?: LocationCreateWithoutAccountTypesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutAccountTypesInput | null;
  upsert?: LocationUpsertWithoutAccountTypesInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutAccountTypesInput | null;
}

export interface LocationUpdateOneRequiredWithoutAccountsNestedInput {
  create?: LocationCreateWithoutAccountsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutAccountsInput | null;
  upsert?: LocationUpsertWithoutAccountsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutAccountsInput | null;
}

export interface LocationUpdateOneRequiredWithoutAppointmentLabelsNestedInput {
  create?: LocationCreateWithoutAppointmentLabelsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutAppointmentLabelsInput | null;
  upsert?: LocationUpsertWithoutAppointmentLabelsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutAppointmentLabelsInput | null;
}

export interface LocationUpdateOneRequiredWithoutAppointmentsNestedInput {
  create?: LocationCreateWithoutAppointmentsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutAppointmentsInput | null;
  upsert?: LocationUpsertWithoutAppointmentsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutAppointmentsInput | null;
}

export interface LocationUpdateOneRequiredWithoutBenefitMappingsNestedInput {
  create?: LocationCreateWithoutBenefitMappingsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutBenefitMappingsInput | null;
  upsert?: LocationUpsertWithoutBenefitMappingsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutBenefitMappingsInput | null;
}

export interface LocationUpdateOneRequiredWithoutChargeTemplateMappingsNestedInput {
  create?: LocationCreateWithoutChargeTemplateMappingsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutChargeTemplateMappingsInput | null;
  upsert?: LocationUpsertWithoutChargeTemplateMappingsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutChargeTemplateMappingsInput | null;
}

export interface LocationUpdateOneRequiredWithoutChargeTemplatesNestedInput {
  create?: LocationCreateWithoutChargeTemplatesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutChargeTemplatesInput | null;
  upsert?: LocationUpsertWithoutChargeTemplatesInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutChargeTemplatesInput | null;
}

export interface LocationUpdateOneRequiredWithoutChargemasterGroupsNestedInput {
  create?: LocationCreateWithoutChargemasterGroupsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutChargemasterGroupsInput | null;
  upsert?: LocationUpsertWithoutChargemasterGroupsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutChargemasterGroupsInput | null;
}

export interface LocationUpdateOneRequiredWithoutChargemastersNestedInput {
  create?: LocationCreateWithoutChargemastersInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutChargemastersInput | null;
  upsert?: LocationUpsertWithoutChargemastersInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutChargemastersInput | null;
}

export interface LocationUpdateOneRequiredWithoutDepositMappingsNestedInput {
  create?: LocationCreateWithoutDepositMappingsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutDepositMappingsInput | null;
  upsert?: LocationUpsertWithoutDepositMappingsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutDepositMappingsInput | null;
}

export interface LocationUpdateOneRequiredWithoutEstimationWorkflowStatusesNestedInput {
  create?: LocationCreateWithoutEstimationWorkflowStatusesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutEstimationWorkflowStatusesInput | null;
  upsert?: LocationUpsertWithoutEstimationWorkflowStatusesInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutEstimationWorkflowStatusesInput | null;
}

export interface LocationUpdateOneRequiredWithoutFeeScheduleMappingsNestedInput {
  create?: LocationCreateWithoutFeeScheduleMappingsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutFeeScheduleMappingsInput | null;
  upsert?: LocationUpsertWithoutFeeScheduleMappingsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutFeeScheduleMappingsInput | null;
}

export interface LocationUpdateOneRequiredWithoutFeeSchedulesNestedInput {
  create?: LocationCreateWithoutFeeSchedulesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutFeeSchedulesInput | null;
  upsert?: LocationUpsertWithoutFeeSchedulesInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutFeeSchedulesInput | null;
}

export interface LocationUpdateOneRequiredWithoutIntegrationsNestedInput {
  create?: LocationCreateWithoutIntegrationsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutIntegrationsInput | null;
  upsert?: LocationUpsertWithoutIntegrationsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutIntegrationsInput | null;
}

export interface LocationUpdateOneRequiredWithoutPatientLabelsNestedInput {
  create?: LocationCreateWithoutPatientLabelsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutPatientLabelsInput | null;
  upsert?: LocationUpsertWithoutPatientLabelsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutPatientLabelsInput | null;
}

export interface LocationUpdateOneRequiredWithoutPatientsNestedInput {
  create?: LocationCreateWithoutPatientsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutPatientsInput | null;
  upsert?: LocationUpsertWithoutPatientsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutPatientsInput | null;
}

export interface LocationUpdateOneRequiredWithoutPaymentRequestBatchesNestedInput {
  create?: LocationCreateWithoutPaymentRequestBatchesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutPaymentRequestBatchesInput | null;
  upsert?: LocationUpsertWithoutPaymentRequestBatchesInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutPaymentRequestBatchesInput | null;
}

export interface LocationUpdateOneRequiredWithoutPreVisitMappingsNestedInput {
  create?: LocationCreateWithoutPreVisitMappingsInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutPreVisitMappingsInput | null;
  upsert?: LocationUpsertWithoutPreVisitMappingsInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutPreVisitMappingsInput | null;
}

export interface LocationUpdateOneRequiredWithoutProvidersNestedInput {
  create?: LocationCreateWithoutProvidersInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutProvidersInput | null;
  upsert?: LocationUpsertWithoutProvidersInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutProvidersInput | null;
}

export interface LocationUpdateOneRequiredWithoutTasksNestedInput {
  create?: LocationCreateWithoutTasksInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutTasksInput | null;
  upsert?: LocationUpsertWithoutTasksInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutTasksInput | null;
}

export interface LocationUpdateOneRequiredWithoutUsersNestedInput {
  create?: LocationCreateWithoutUsersInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutUsersInput | null;
  upsert?: LocationUpsertWithoutUsersInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutUsersInput | null;
}

export interface LocationUpdateOneRequiredWithoutVerificationWorkflowStatusesNestedInput {
  create?: LocationCreateWithoutVerificationWorkflowStatusesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutVerificationWorkflowStatusesInput | null;
  upsert?: LocationUpsertWithoutVerificationWorkflowStatusesInput | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutVerificationWorkflowStatusesInput | null;
}

export interface LocationUpdateOneWithoutConfirmationVerificationWorkflowStatusNestedInput {
  create?: LocationCreateWithoutConfirmationVerificationWorkflowStatusInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutConfirmationVerificationWorkflowStatusInput | null;
  upsert?: LocationUpsertWithoutConfirmationVerificationWorkflowStatusInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutConfirmationVerificationWorkflowStatusInput | null;
}

export interface LocationUpdateOneWithoutDefaultEstimationWorkflowStatusNestedInput {
  create?: LocationCreateWithoutDefaultEstimationWorkflowStatusInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutDefaultEstimationWorkflowStatusInput | null;
  upsert?: LocationUpsertWithoutDefaultEstimationWorkflowStatusInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutDefaultEstimationWorkflowStatusInput | null;
}

export interface LocationUpdateOneWithoutDefaultVerificationWorkflowStatusNestedInput {
  create?: LocationCreateWithoutDefaultVerificationWorkflowStatusInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutDefaultVerificationWorkflowStatusInput | null;
  upsert?: LocationUpsertWithoutDefaultVerificationWorkflowStatusInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutDefaultVerificationWorkflowStatusInput | null;
}

export interface LocationUpdateOneWithoutInsurancePolicyWorklistItemTaskTemplatesNestedInput {
  create?: LocationCreateWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
  upsert?: LocationUpsertWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
}

export interface LocationUpdateOneWithoutPayersNestedInput {
  create?: LocationCreateWithoutPayersInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutPayersInput | null;
  upsert?: LocationUpsertWithoutPayersInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutPayersInput | null;
}

export interface LocationUpdateOneWithoutUnconfirmationVerificationWorkflowStatusNestedInput {
  create?: LocationCreateWithoutUnconfirmationVerificationWorkflowStatusInput | null;
  connectOrCreate?: LocationCreateOrConnectWithoutUnconfirmationVerificationWorkflowStatusInput | null;
  upsert?: LocationUpsertWithoutUnconfirmationVerificationWorkflowStatusInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: LocationWhereUniqueInput | null;
  update?: LocationUpdateWithoutUnconfirmationVerificationWorkflowStatusInput | null;
}

export interface LocationUpdateWithWhereUniqueWithoutDefaultEligibilityProviderInput {
  where: LocationWhereUniqueInput;
  data: LocationUpdateWithoutDefaultEligibilityProviderInput;
}

export interface LocationUpdateWithWhereUniqueWithoutOrganizationInput {
  where: LocationWhereUniqueInput;
  data: LocationUpdateWithoutOrganizationInput;
}

export interface LocationUpdateWithoutAccountTypesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutAccountsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutAppointmentLabelsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutAppointmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutBenefitMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutChargeTemplateMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutChargeTemplatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutChargemasterGroupsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutChargemastersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutConfirmationVerificationWorkflowStatusInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutDefaultEligibilityProviderInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutDefaultEstimationWorkflowStatusInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutDefaultVerificationWorkflowStatusInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutDepositMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutEstimationWorkflowStatusesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutFeeScheduleMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutFeeSchedulesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutInsurancePolicyWorklistItemTaskTemplatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutIntegrationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutPatientLabelsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutPatientsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutPayersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutPaymentRequestBatchesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutPreVisitMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutProvidersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutTasksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutUnconfirmationVerificationWorkflowStatusInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutUsersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpdateWithoutVerificationWorkflowStatusesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  backloadedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  address1?: StringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: StringFieldUpdateOperationsInput | null;
  postalCode?: StringFieldUpdateOperationsInput | null;
  state?: StringFieldUpdateOperationsInput | null;
  country?: StringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  automatedEligibilityVerificationEnabled?: BoolFieldUpdateOperationsInput | null;
  locationDisplayName?: NullableStringFieldUpdateOperationsInput | null;
  timezone?: StringFieldUpdateOperationsInput | null;
  skipReceiptNotifications?: BoolFieldUpdateOperationsInput | null;
  visitAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  adjudicatedAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  balanceAutopayCycleCloseDate?: IntFieldUpdateOperationsInput | null;
  balanceAutopayChargeDelay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayHourOfDay?: IntFieldUpdateOperationsInput | null;
  balanceAutopayMinuteOfHour?: IntFieldUpdateOperationsInput | null;
  balanceAutopayTimezone?: StringFieldUpdateOperationsInput | null;
  externalAutopayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimitEnabled?: BoolFieldUpdateOperationsInput | null;
  defaultMaxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  featureFlags?: any | null;
  verificationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimationWorkflowEnabled?: BoolFieldUpdateOperationsInput | null;
  estimateSetupMode?: BoolFieldUpdateOperationsInput | null;
  automatedAccumulatorTrackingEnabled?: BoolFieldUpdateOperationsInput | null;
  autoconfirmPoliciesEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  billingEmail?: NullableStringFieldUpdateOperationsInput | null;
  billingPhone?: NullableStringFieldUpdateOperationsInput | null;
  billingCheckPayable?: NullableStringFieldUpdateOperationsInput | null;
  billingDefaultMessage?: NullableStringFieldUpdateOperationsInput | null;
  billingPaperDoubleSided?: NullableBoolFieldUpdateOperationsInput | null;
  saltingEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  saltingLookbackMonths?: IntFieldUpdateOperationsInput | null;
  saltingMatchAccount?: BoolFieldUpdateOperationsInput | null;
  saltingMatchAppointmentLabel?: BoolFieldUpdateOperationsInput | null;
  saltingMatchProvider?: BoolFieldUpdateOperationsInput | null;
  automatedEstimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitEstimateDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitReminderEnabled?: BoolFieldUpdateOperationsInput | null;
  preVisitReminderDaysAhead?: NullableIntFieldUpdateOperationsInput | null;
  preVisitCardOnFileInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFieldUpdateOperationsInput | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingEnabled?: BoolFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingDelayMinutes?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingHourOfDay?: NullableIntFieldUpdateOperationsInput | null;
  automatedTimeOfServiceChargingMinuteOfHour?: NullableIntFieldUpdateOperationsInput | null;
  autopostPendingPaymentThresholdDays?: NullableIntFieldUpdateOperationsInput | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutLocationsNestedInput | null;
  defaultEligibilityProvider?: ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutLocationNestedInput | null;
  accounts?: AccountUpdateManyWithoutLocationNestedInput | null;
  patients?: PatientUpdateManyWithoutLocationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutLocationNestedInput | null;
  providers?: ProviderUpdateManyWithoutPrimaryLocationNestedInput | null;
  users?: UserUpdateManyWithoutActiveLocationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutLocationNestedInput | null;
  payers?: PayerUpdateManyWithoutLocationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutLocationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutLocationNestedInput | null;
  tasks?: TaskUpdateManyWithoutLocationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutLocationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplates?: ChargeTemplateUpdateManyWithoutLocationNestedInput | null;
  chargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLocationNestedInput | null;
  feeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLocationNestedInput | null;
  chargemasters?: ChargemasterUpdateManyWithoutLocationNestedInput | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusUpdateManyWithoutLocationNestedInput | null;
  chargemasterGroups?: ChargemasterGroupUpdateManyWithoutLocationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutLocationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutLocationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutLocationNestedInput | null;
  preVisitMappings?: PreVisitMappingUpdateManyWithoutLocationNestedInput | null;
}

export interface LocationUpsertWithWhereUniqueWithoutDefaultEligibilityProviderInput {
  where: LocationWhereUniqueInput;
  update: LocationUpdateWithoutDefaultEligibilityProviderInput;
  create: LocationCreateWithoutDefaultEligibilityProviderInput;
}

export interface LocationUpsertWithWhereUniqueWithoutOrganizationInput {
  where: LocationWhereUniqueInput;
  update: LocationUpdateWithoutOrganizationInput;
  create: LocationCreateWithoutOrganizationInput;
}

export interface LocationUpsertWithoutAccountTypesInput {
  update: LocationUpdateWithoutAccountTypesInput;
  create: LocationCreateWithoutAccountTypesInput;
}

export interface LocationUpsertWithoutAccountsInput {
  update: LocationUpdateWithoutAccountsInput;
  create: LocationCreateWithoutAccountsInput;
}

export interface LocationUpsertWithoutAppointmentLabelsInput {
  update: LocationUpdateWithoutAppointmentLabelsInput;
  create: LocationCreateWithoutAppointmentLabelsInput;
}

export interface LocationUpsertWithoutAppointmentsInput {
  update: LocationUpdateWithoutAppointmentsInput;
  create: LocationCreateWithoutAppointmentsInput;
}

export interface LocationUpsertWithoutBenefitMappingsInput {
  update: LocationUpdateWithoutBenefitMappingsInput;
  create: LocationCreateWithoutBenefitMappingsInput;
}

export interface LocationUpsertWithoutChargeTemplateMappingsInput {
  update: LocationUpdateWithoutChargeTemplateMappingsInput;
  create: LocationCreateWithoutChargeTemplateMappingsInput;
}

export interface LocationUpsertWithoutChargeTemplatesInput {
  update: LocationUpdateWithoutChargeTemplatesInput;
  create: LocationCreateWithoutChargeTemplatesInput;
}

export interface LocationUpsertWithoutChargemasterGroupsInput {
  update: LocationUpdateWithoutChargemasterGroupsInput;
  create: LocationCreateWithoutChargemasterGroupsInput;
}

export interface LocationUpsertWithoutChargemastersInput {
  update: LocationUpdateWithoutChargemastersInput;
  create: LocationCreateWithoutChargemastersInput;
}

export interface LocationUpsertWithoutConfirmationVerificationWorkflowStatusInput {
  update: LocationUpdateWithoutConfirmationVerificationWorkflowStatusInput;
  create: LocationCreateWithoutConfirmationVerificationWorkflowStatusInput;
}

export interface LocationUpsertWithoutDefaultEstimationWorkflowStatusInput {
  update: LocationUpdateWithoutDefaultEstimationWorkflowStatusInput;
  create: LocationCreateWithoutDefaultEstimationWorkflowStatusInput;
}

export interface LocationUpsertWithoutDefaultVerificationWorkflowStatusInput {
  update: LocationUpdateWithoutDefaultVerificationWorkflowStatusInput;
  create: LocationCreateWithoutDefaultVerificationWorkflowStatusInput;
}

export interface LocationUpsertWithoutDepositMappingsInput {
  update: LocationUpdateWithoutDepositMappingsInput;
  create: LocationCreateWithoutDepositMappingsInput;
}

export interface LocationUpsertWithoutEstimationWorkflowStatusesInput {
  update: LocationUpdateWithoutEstimationWorkflowStatusesInput;
  create: LocationCreateWithoutEstimationWorkflowStatusesInput;
}

export interface LocationUpsertWithoutFeeScheduleMappingsInput {
  update: LocationUpdateWithoutFeeScheduleMappingsInput;
  create: LocationCreateWithoutFeeScheduleMappingsInput;
}

export interface LocationUpsertWithoutFeeSchedulesInput {
  update: LocationUpdateWithoutFeeSchedulesInput;
  create: LocationCreateWithoutFeeSchedulesInput;
}

export interface LocationUpsertWithoutInsurancePolicyWorklistItemTaskTemplatesInput {
  update: LocationUpdateWithoutInsurancePolicyWorklistItemTaskTemplatesInput;
  create: LocationCreateWithoutInsurancePolicyWorklistItemTaskTemplatesInput;
}

export interface LocationUpsertWithoutIntegrationsInput {
  update: LocationUpdateWithoutIntegrationsInput;
  create: LocationCreateWithoutIntegrationsInput;
}

export interface LocationUpsertWithoutPatientLabelsInput {
  update: LocationUpdateWithoutPatientLabelsInput;
  create: LocationCreateWithoutPatientLabelsInput;
}

export interface LocationUpsertWithoutPatientsInput {
  update: LocationUpdateWithoutPatientsInput;
  create: LocationCreateWithoutPatientsInput;
}

export interface LocationUpsertWithoutPayersInput {
  update: LocationUpdateWithoutPayersInput;
  create: LocationCreateWithoutPayersInput;
}

export interface LocationUpsertWithoutPaymentRequestBatchesInput {
  update: LocationUpdateWithoutPaymentRequestBatchesInput;
  create: LocationCreateWithoutPaymentRequestBatchesInput;
}

export interface LocationUpsertWithoutPreVisitMappingsInput {
  update: LocationUpdateWithoutPreVisitMappingsInput;
  create: LocationCreateWithoutPreVisitMappingsInput;
}

export interface LocationUpsertWithoutProvidersInput {
  update: LocationUpdateWithoutProvidersInput;
  create: LocationCreateWithoutProvidersInput;
}

export interface LocationUpsertWithoutTasksInput {
  update: LocationUpdateWithoutTasksInput;
  create: LocationCreateWithoutTasksInput;
}

export interface LocationUpsertWithoutUnconfirmationVerificationWorkflowStatusInput {
  update: LocationUpdateWithoutUnconfirmationVerificationWorkflowStatusInput;
  create: LocationCreateWithoutUnconfirmationVerificationWorkflowStatusInput;
}

export interface LocationUpsertWithoutUsersInput {
  update: LocationUpdateWithoutUsersInput;
  create: LocationCreateWithoutUsersInput;
}

export interface LocationUpsertWithoutVerificationWorkflowStatusesInput {
  update: LocationUpdateWithoutVerificationWorkflowStatusesInput;
  create: LocationCreateWithoutVerificationWorkflowStatusesInput;
}

export interface LocationWhereInput {
  AND?: LocationWhereInput[] | null;
  OR?: LocationWhereInput[] | null;
  NOT?: LocationWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  backloadedAt?: DateTimeNullableFilter | null;
  name?: StringFilter | null;
  address1?: StringFilter | null;
  address2?: StringNullableFilter | null;
  city?: StringFilter | null;
  postalCode?: StringFilter | null;
  state?: StringFilter | null;
  country?: StringFilter | null;
  organizationId?: UuidFilter | null;
  replyToEmails?: JsonNullableFilter | null;
  primaryEmail?: StringNullableFilter | null;
  phone?: StringNullableFilter | null;
  openHoursBlurb?: StringNullableFilter | null;
  senderEmail?: StringNullableFilter | null;
  automatedEligibilityVerificationEnabled?: BoolFilter | null;
  locationDisplayName?: StringNullableFilter | null;
  timezone?: StringFilter | null;
  skipReceiptNotifications?: BoolFilter | null;
  visitAutopayEnabled?: BoolFilter | null;
  visitAutopayChargeDelay?: IntFilter | null;
  adjudicatedAutopayEnabled?: BoolFilter | null;
  balanceAutopayEnabled?: BoolFilter | null;
  balanceAutopayCycleCloseDate?: IntFilter | null;
  balanceAutopayChargeDelay?: IntFilter | null;
  balanceAutopayHourOfDay?: IntFilter | null;
  balanceAutopayMinuteOfHour?: IntFilter | null;
  balanceAutopayTimezone?: StringFilter | null;
  externalAutopayEnabled?: BoolFilter | null;
  maxAutopayLimitEnabled?: BoolFilter | null;
  defaultMaxAutopayLimit?: IntNullableFilter | null;
  featureFlags?: JsonNullableFilter | null;
  defaultEligibilityProviderId?: UuidNullableFilter | null;
  verificationWorkflowEnabled?: BoolFilter | null;
  defaultVerificationWorkflowStatusId?: UuidNullableFilter | null;
  confirmationVerificationWorkflowStatusId?: UuidNullableFilter | null;
  unconfirmationVerificationWorkflowStatusId?: UuidNullableFilter | null;
  estimationWorkflowEnabled?: BoolFilter | null;
  estimateSetupMode?: BoolFilter | null;
  defaultEstimationWorkflowStatusId?: UuidNullableFilter | null;
  automatedAccumulatorTrackingEnabled?: BoolFilter | null;
  autoconfirmPoliciesEnabled?: BoolNullableFilter | null;
  billingEmail?: StringNullableFilter | null;
  billingPhone?: StringNullableFilter | null;
  billingCheckPayable?: StringNullableFilter | null;
  billingDefaultMessage?: StringNullableFilter | null;
  billingPaperDoubleSided?: BoolNullableFilter | null;
  saltingEnabled?: BoolNullableFilter | null;
  saltingLookbackMonths?: IntFilter | null;
  saltingMatchAccount?: BoolFilter | null;
  saltingMatchAppointmentLabel?: BoolFilter | null;
  saltingMatchProvider?: BoolFilter | null;
  automatedEstimatesEnabled?: BoolFilter | null;
  preVisitEstimateDaysAhead?: IntNullableFilter | null;
  preVisitReminderEnabled?: BoolFilter | null;
  preVisitReminderDaysAhead?: IntNullableFilter | null;
  preVisitCardOnFileInput?: EnumValidationStatusFilter | null;
  preVisitFinancialPolicyInput?: EnumValidationStatusFilter | null;
  preVisitReminderEstimateDisplay?: EnumValidationStatusFilter | null;
  automatedTimeOfServiceChargingEnabled?: BoolFilter | null;
  automatedTimeOfServiceChargingDelayMinutes?: IntNullableFilter | null;
  automatedTimeOfServiceChargingHourOfDay?: IntNullableFilter | null;
  automatedTimeOfServiceChargingMinuteOfHour?: IntNullableFilter | null;
  autopostPendingPaymentThresholdDays?: IntNullableFilter | null;
  ledgerGranularityLevel?: EnumLedgerGranularityLevelFilter | null;
  organization?: OrganizationRelationFilter | null;
  defaultEligibilityProvider?: ProviderRelationFilter | null;
  defaultVerificationWorkflowStatus?: VerificationWorkflowStatusRelationFilter | null;
  confirmationVerificationWorkflowStatus?: VerificationWorkflowStatusRelationFilter | null;
  unconfirmationVerificationWorkflowStatus?: VerificationWorkflowStatusRelationFilter | null;
  defaultEstimationWorkflowStatus?: EstimationWorkflowStatusRelationFilter | null;
  appointments?: AppointmentListRelationFilter | null;
  accounts?: AccountListRelationFilter | null;
  patients?: PatientListRelationFilter | null;
  integrations?: IntegrationListRelationFilter | null;
  providers?: ProviderListRelationFilter | null;
  users?: UserListRelationFilter | null;
  accountTypes?: AccountTypeListRelationFilter | null;
  payers?: PayerListRelationFilter | null;
  feeSchedules?: FeeScheduleListRelationFilter | null;
  paymentRequestBatches?: PaymentRequestBatchListRelationFilter | null;
  tasks?: TaskListRelationFilter | null;
  benefitMappings?: BenefitMappingListRelationFilter | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateListRelationFilter | null;
  chargeTemplates?: ChargeTemplateListRelationFilter | null;
  chargeTemplateMappings?: ChargeTemplateMappingListRelationFilter | null;
  feeScheduleMappings?: FeeScheduleMappingListRelationFilter | null;
  verificationWorkflowStatuses?: VerificationWorkflowStatusListRelationFilter | null;
  chargemasters?: ChargemasterListRelationFilter | null;
  estimationWorkflowStatuses?: EstimationWorkflowStatusListRelationFilter | null;
  chargemasterGroups?: ChargemasterGroupListRelationFilter | null;
  appointmentLabels?: AppointmentLabelListRelationFilter | null;
  patientLabels?: PatientLabelListRelationFilter | null;
  depositMappings?: DepositMappingListRelationFilter | null;
  preVisitMappings?: PreVisitMappingListRelationFilter | null;
}

export interface LocationWhereUniqueInput {
  id?: string | null;
  defaultVerificationWorkflowStatusId?: string | null;
  confirmationVerificationWorkflowStatusId?: string | null;
  unconfirmationVerificationWorkflowStatusId?: string | null;
  defaultEstimationWorkflowStatusId?: string | null;
}

export interface NestedBoolFilter {
  equals?: boolean | null;
  not?: NestedBoolFilter | null;
}

export interface NestedBoolNullableFilter {
  equals?: boolean | null;
  not?: NestedBoolNullableFilter | null;
}

export interface NestedDateTimeFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeFilter | null;
}

export interface NestedDateTimeNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDateTimeNullableFilter | null;
}

export interface NestedDecimalFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDecimalFilter | null;
}

export interface NestedDecimalNullableFilter {
  equals?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  not?: NestedDecimalNullableFilter | null;
}

export interface NestedEnumAccumulatorAdjustmentTypeFilter {
  equals?: AccumulatorAdjustmentType | null;
  in?: AccumulatorAdjustmentType[] | null;
  notIn?: AccumulatorAdjustmentType[] | null;
  not?: NestedEnumAccumulatorAdjustmentTypeFilter | null;
}

export interface NestedEnumAdjustmentTypeFilter {
  equals?: AdjustmentType | null;
  in?: AdjustmentType[] | null;
  notIn?: AdjustmentType[] | null;
  not?: NestedEnumAdjustmentTypeFilter | null;
}

export interface NestedEnumAppointmentStatusNullableFilter {
  equals?: AppointmentStatus | null;
  in?: AppointmentStatus[] | null;
  notIn?: AppointmentStatus[] | null;
  not?: NestedEnumAppointmentStatusNullableFilter | null;
}

export interface NestedEnumAuthorizationRequirementNullableFilter {
  equals?: AuthorizationRequirement | null;
  in?: AuthorizationRequirement[] | null;
  notIn?: AuthorizationRequirement[] | null;
  not?: NestedEnumAuthorizationRequirementNullableFilter | null;
}

export interface NestedEnumBenefitNoteRuleActionTypeFilter {
  equals?: BenefitNoteRuleActionType | null;
  in?: BenefitNoteRuleActionType[] | null;
  notIn?: BenefitNoteRuleActionType[] | null;
  not?: NestedEnumBenefitNoteRuleActionTypeFilter | null;
}

export interface NestedEnumBenefitNoteRuleMatchTypeFilter {
  equals?: BenefitNoteRuleMatchType | null;
  in?: BenefitNoteRuleMatchType[] | null;
  notIn?: BenefitNoteRuleMatchType[] | null;
  not?: NestedEnumBenefitNoteRuleMatchTypeFilter | null;
}

export interface NestedEnumBillCommunicationStateNullableFilter {
  equals?: BillCommunicationState | null;
  in?: BillCommunicationState[] | null;
  notIn?: BillCommunicationState[] | null;
  not?: NestedEnumBillCommunicationStateNullableFilter | null;
}

export interface NestedEnumBillStateFilter {
  equals?: BillState | null;
  in?: BillState[] | null;
  notIn?: BillState[] | null;
  not?: NestedEnumBillStateFilter | null;
}

export interface NestedEnumBirthSexNullableFilter {
  equals?: BirthSex | null;
  in?: BirthSex[] | null;
  notIn?: BirthSex[] | null;
  not?: NestedEnumBirthSexNullableFilter | null;
}

export interface NestedEnumChangePayerConnectivityTypeNullableFilter {
  equals?: ChangePayerConnectivityType | null;
  in?: ChangePayerConnectivityType[] | null;
  notIn?: ChangePayerConnectivityType[] | null;
  not?: NestedEnumChangePayerConnectivityTypeNullableFilter | null;
}

export interface NestedEnumChargeInsuranceStateFilter {
  equals?: ChargeInsuranceState | null;
  in?: ChargeInsuranceState[] | null;
  notIn?: ChargeInsuranceState[] | null;
  not?: NestedEnumChargeInsuranceStateFilter | null;
}

export interface NestedEnumClearinghouseFilter {
  equals?: Clearinghouse | null;
  in?: Clearinghouse[] | null;
  notIn?: Clearinghouse[] | null;
  not?: NestedEnumClearinghouseFilter | null;
}

export interface NestedEnumClearinghouseNullableFilter {
  equals?: Clearinghouse | null;
  in?: Clearinghouse[] | null;
  notIn?: Clearinghouse[] | null;
  not?: NestedEnumClearinghouseNullableFilter | null;
}

export interface NestedEnumCollectionRequestTypeFilter {
  equals?: CollectionRequestType | null;
  in?: CollectionRequestType[] | null;
  notIn?: CollectionRequestType[] | null;
  not?: NestedEnumCollectionRequestTypeFilter | null;
}

export interface NestedEnumCommercialTypeNullableFilter {
  equals?: CommercialType | null;
  in?: CommercialType[] | null;
  notIn?: CommercialType[] | null;
  not?: NestedEnumCommercialTypeNullableFilter | null;
}

export interface NestedEnumCommunicationContentTypeFilter {
  equals?: CommunicationContentType | null;
  in?: CommunicationContentType[] | null;
  notIn?: CommunicationContentType[] | null;
  not?: NestedEnumCommunicationContentTypeFilter | null;
}

export interface NestedEnumCommunicationErrorTypeNullableFilter {
  equals?: CommunicationErrorType | null;
  in?: CommunicationErrorType[] | null;
  notIn?: CommunicationErrorType[] | null;
  not?: NestedEnumCommunicationErrorTypeNullableFilter | null;
}

export interface NestedEnumCommunicationTypeFilter {
  equals?: CommunicationType | null;
  in?: CommunicationType[] | null;
  notIn?: CommunicationType[] | null;
  not?: NestedEnumCommunicationTypeFilter | null;
}

export interface NestedEnumComparisonFilter {
  equals?: Comparison | null;
  in?: Comparison[] | null;
  notIn?: Comparison[] | null;
  not?: NestedEnumComparisonFilter | null;
}

export interface NestedEnumCoveragePriorityNullableFilter {
  equals?: CoveragePriority | null;
  in?: CoveragePriority[] | null;
  notIn?: CoveragePriority[] | null;
  not?: NestedEnumCoveragePriorityNullableFilter | null;
}

export interface NestedEnumEligibilityRequestStatusFilter {
  equals?: EligibilityRequestStatus | null;
  in?: EligibilityRequestStatus[] | null;
  notIn?: EligibilityRequestStatus[] | null;
  not?: NestedEnumEligibilityRequestStatusFilter | null;
}

export interface NestedEnumEstimateStatusFilter {
  equals?: EstimateStatus | null;
  in?: EstimateStatus[] | null;
  notIn?: EstimateStatus[] | null;
  not?: NestedEnumEstimateStatusFilter | null;
}

export interface NestedEnumEstimateTypeFilter {
  equals?: EstimateType | null;
  in?: EstimateType[] | null;
  notIn?: EstimateType[] | null;
  not?: NestedEnumEstimateTypeFilter | null;
}

export interface NestedEnumFeeTypeFilter {
  equals?: FeeType | null;
  in?: FeeType[] | null;
  notIn?: FeeType[] | null;
  not?: NestedEnumFeeTypeFilter | null;
}

export interface NestedEnumFeedbackTypeFilter {
  equals?: FeedbackType | null;
  in?: FeedbackType[] | null;
  notIn?: FeedbackType[] | null;
  not?: NestedEnumFeedbackTypeFilter | null;
}

export interface NestedEnumInsurancePolicyWorklistItemTaskTypeFilter {
  equals?: InsurancePolicyWorklistItemTaskType | null;
  in?: InsurancePolicyWorklistItemTaskType[] | null;
  notIn?: InsurancePolicyWorklistItemTaskType[] | null;
  not?: NestedEnumInsurancePolicyWorklistItemTaskTypeFilter | null;
}

export interface NestedEnumInsurancePolicyWorklistReasonFilter {
  equals?: InsurancePolicyWorklistReason | null;
  in?: InsurancePolicyWorklistReason[] | null;
  notIn?: InsurancePolicyWorklistReason[] | null;
  not?: NestedEnumInsurancePolicyWorklistReasonFilter | null;
}

export interface NestedEnumInsuranceTypeFilter {
  equals?: InsuranceType | null;
  in?: InsuranceType[] | null;
  notIn?: InsuranceType[] | null;
  not?: NestedEnumInsuranceTypeFilter | null;
}

export interface NestedEnumIntegrationLinkTypeFilter {
  equals?: IntegrationLinkType | null;
  in?: IntegrationLinkType[] | null;
  notIn?: IntegrationLinkType[] | null;
  not?: NestedEnumIntegrationLinkTypeFilter | null;
}

export interface NestedEnumIntegrationTypeFilter {
  equals?: IntegrationType | null;
  in?: IntegrationType[] | null;
  notIn?: IntegrationType[] | null;
  not?: NestedEnumIntegrationTypeFilter | null;
}

export interface NestedEnumLedgerGranularityLevelFilter {
  equals?: LedgerGranularityLevel | null;
  in?: LedgerGranularityLevel[] | null;
  notIn?: LedgerGranularityLevel[] | null;
  not?: NestedEnumLedgerGranularityLevelFilter | null;
}

export interface NestedEnumMedicaidTypeNullableFilter {
  equals?: MedicaidType | null;
  in?: MedicaidType[] | null;
  notIn?: MedicaidType[] | null;
  not?: NestedEnumMedicaidTypeNullableFilter | null;
}

export interface NestedEnumMedicareTypeNullableFilter {
  equals?: MedicareType | null;
  in?: MedicareType[] | null;
  notIn?: MedicareType[] | null;
  not?: NestedEnumMedicareTypeNullableFilter | null;
}

export interface NestedEnumMedigapPlanTypeNullableFilter {
  equals?: MedigapPlanType | null;
  in?: MedigapPlanType[] | null;
  notIn?: MedigapPlanType[] | null;
  not?: NestedEnumMedigapPlanTypeNullableFilter | null;
}

export interface NestedEnumNetworkStatusNullableFilter {
  equals?: NetworkStatus | null;
  in?: NetworkStatus[] | null;
  notIn?: NetworkStatus[] | null;
  not?: NestedEnumNetworkStatusNullableFilter | null;
}

export interface NestedEnumNoteTypeFilter {
  equals?: NoteType | null;
  in?: NoteType[] | null;
  notIn?: NoteType[] | null;
  not?: NestedEnumNoteTypeFilter | null;
}

export interface NestedEnumPaymentMethodNullableFilter {
  equals?: PaymentMethod | null;
  in?: PaymentMethod[] | null;
  notIn?: PaymentMethod[] | null;
  not?: NestedEnumPaymentMethodNullableFilter | null;
}

export interface NestedEnumPaymentRequestBatchStatusFilter {
  equals?: PaymentRequestBatchStatus | null;
  in?: PaymentRequestBatchStatus[] | null;
  notIn?: PaymentRequestBatchStatus[] | null;
  not?: NestedEnumPaymentRequestBatchStatusFilter | null;
}

export interface NestedEnumPaymentRequestStatusFilter {
  equals?: PaymentRequestStatus | null;
  in?: PaymentRequestStatus[] | null;
  notIn?: PaymentRequestStatus[] | null;
  not?: NestedEnumPaymentRequestStatusFilter | null;
}

export interface NestedEnumPaymentRequestTypeFilter {
  equals?: PaymentRequestType | null;
  in?: PaymentRequestType[] | null;
  notIn?: PaymentRequestType[] | null;
  not?: NestedEnumPaymentRequestTypeFilter | null;
}

export interface NestedEnumPaymentTypeFilter {
  equals?: PaymentType | null;
  in?: PaymentType[] | null;
  notIn?: PaymentType[] | null;
  not?: NestedEnumPaymentTypeFilter | null;
}

export interface NestedEnumPostingStatusNullableFilter {
  equals?: PostingStatus | null;
  in?: PostingStatus[] | null;
  notIn?: PostingStatus[] | null;
  not?: NestedEnumPostingStatusNullableFilter | null;
}

export interface NestedEnumProviderTypeFilter {
  equals?: ProviderType | null;
  in?: ProviderType[] | null;
  notIn?: ProviderType[] | null;
  not?: NestedEnumProviderTypeFilter | null;
}

export interface NestedEnumStripeConnectAccountTypeFilter {
  equals?: StripeConnectAccountType | null;
  in?: StripeConnectAccountType[] | null;
  notIn?: StripeConnectAccountType[] | null;
  not?: NestedEnumStripeConnectAccountTypeFilter | null;
}

export interface NestedEnumSyncDirectionFilter {
  equals?: SyncDirection | null;
  in?: SyncDirection[] | null;
  notIn?: SyncDirection[] | null;
  not?: NestedEnumSyncDirectionFilter | null;
}

export interface NestedEnumTaskTypeFilter {
  equals?: TaskType | null;
  in?: TaskType[] | null;
  notIn?: TaskType[] | null;
  not?: NestedEnumTaskTypeFilter | null;
}

export interface NestedEnumTransactionTypeFilter {
  equals?: TransactionType | null;
  in?: TransactionType[] | null;
  notIn?: TransactionType[] | null;
  not?: NestedEnumTransactionTypeFilter | null;
}

export interface NestedEnumTricareTypeNullableFilter {
  equals?: TricareType | null;
  in?: TricareType[] | null;
  notIn?: TricareType[] | null;
  not?: NestedEnumTricareTypeNullableFilter | null;
}

export interface NestedEnumUserEnteredBenefitTypeFilter {
  equals?: UserEnteredBenefitType | null;
  in?: UserEnteredBenefitType[] | null;
  notIn?: UserEnteredBenefitType[] | null;
  not?: NestedEnumUserEnteredBenefitTypeFilter | null;
}

export interface NestedEnumValidationStatusFilter {
  equals?: ValidationStatus | null;
  in?: ValidationStatus[] | null;
  notIn?: ValidationStatus[] | null;
  not?: NestedEnumValidationStatusFilter | null;
}

export interface NestedEnumVerificationWorkflowReasonNullableFilter {
  equals?: VerificationWorkflowReason | null;
  in?: VerificationWorkflowReason[] | null;
  notIn?: VerificationWorkflowReason[] | null;
  not?: NestedEnumVerificationWorkflowReasonNullableFilter | null;
}

export interface NestedEnumWorkflowStageFilter {
  equals?: WorkflowStage | null;
  in?: WorkflowStage[] | null;
  notIn?: WorkflowStage[] | null;
  not?: NestedEnumWorkflowStageFilter | null;
}

export interface NestedEnumWorkflowTypeNullableFilter {
  equals?: WorkflowType | null;
  in?: WorkflowType[] | null;
  notIn?: WorkflowType[] | null;
  not?: NestedEnumWorkflowTypeNullableFilter | null;
}

export interface NestedFloatFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatFilter | null;
}

export interface NestedFloatNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedFloatNullableFilter | null;
}

export interface NestedIntFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntFilter | null;
}

export interface NestedIntNullableFilter {
  equals?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: NestedIntNullableFilter | null;
}

export interface NestedStringFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  not?: NestedStringFilter | null;
}

export interface NestedStringNullableFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  not?: NestedStringNullableFilter | null;
}

export interface NestedUuidFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: NestedUuidFilter | null;
}

export interface NestedUuidNullableFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: NestedUuidNullableFilter | null;
}

export interface NoteCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  content: string;
  type: NoteType;
  appointment?: AppointmentCreateNestedOneWithoutNotesInput | null;
  patient: PatientCreateNestedOneWithoutNotesInput;
}

export interface NoteCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  content: string;
  patientId: string;
  type: NoteType;
}

export interface NoteCreateManyAppointmentInputEnvelope {
  data: NoteCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface NoteCreateManyPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  content: string;
  appointmentId?: string | null;
  type: NoteType;
}

export interface NoteCreateManyPatientInputEnvelope {
  data: NoteCreateManyPatientInput[];
  skipDuplicates?: boolean | null;
}

export interface NoteCreateNestedManyWithoutAppointmentInput {
  create?: NoteCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: NoteCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: NoteCreateManyAppointmentInputEnvelope | null;
  connect?: NoteWhereUniqueInput[] | null;
}

export interface NoteCreateNestedManyWithoutPatientInput {
  create?: NoteCreateWithoutPatientInput[] | null;
  connectOrCreate?: NoteCreateOrConnectWithoutPatientInput[] | null;
  createMany?: NoteCreateManyPatientInputEnvelope | null;
  connect?: NoteWhereUniqueInput[] | null;
}

export interface NoteCreateOrConnectWithoutAppointmentInput {
  where: NoteWhereUniqueInput;
  create: NoteCreateWithoutAppointmentInput;
}

export interface NoteCreateOrConnectWithoutPatientInput {
  where: NoteWhereUniqueInput;
  create: NoteCreateWithoutPatientInput;
}

export interface NoteCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  content: string;
  type: NoteType;
  patient: PatientCreateNestedOneWithoutNotesInput;
}

export interface NoteCreateWithoutPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  content: string;
  type: NoteType;
  appointment?: AppointmentCreateNestedOneWithoutNotesInput | null;
}

export interface NoteListRelationFilter {
  every?: NoteWhereInput | null;
  some?: NoteWhereInput | null;
  none?: NoteWhereInput | null;
}

export interface NoteOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface NoteScalarWhereInput {
  AND?: NoteScalarWhereInput[] | null;
  OR?: NoteScalarWhereInput[] | null;
  NOT?: NoteScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  content?: StringFilter | null;
  appointmentId?: UuidNullableFilter | null;
  patientId?: UuidFilter | null;
  type?: EnumNoteTypeFilter | null;
}

export interface NoteUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  type?: EnumNoteTypeFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneWithoutNotesNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutNotesNestedInput | null;
}

export interface NoteUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  type?: EnumNoteTypeFieldUpdateOperationsInput | null;
}

export interface NoteUpdateManyWithWhereWithoutAppointmentInput {
  where: NoteScalarWhereInput;
  data: NoteUpdateManyMutationInput;
}

export interface NoteUpdateManyWithWhereWithoutPatientInput {
  where: NoteScalarWhereInput;
  data: NoteUpdateManyMutationInput;
}

export interface NoteUpdateManyWithoutAppointmentNestedInput {
  create?: NoteCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: NoteCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: NoteUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: NoteCreateManyAppointmentInputEnvelope | null;
  set?: NoteWhereUniqueInput[] | null;
  disconnect?: NoteWhereUniqueInput[] | null;
  delete?: NoteWhereUniqueInput[] | null;
  connect?: NoteWhereUniqueInput[] | null;
  update?: NoteUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: NoteUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: NoteScalarWhereInput[] | null;
}

export interface NoteUpdateManyWithoutPatientNestedInput {
  create?: NoteCreateWithoutPatientInput[] | null;
  connectOrCreate?: NoteCreateOrConnectWithoutPatientInput[] | null;
  upsert?: NoteUpsertWithWhereUniqueWithoutPatientInput[] | null;
  createMany?: NoteCreateManyPatientInputEnvelope | null;
  set?: NoteWhereUniqueInput[] | null;
  disconnect?: NoteWhereUniqueInput[] | null;
  delete?: NoteWhereUniqueInput[] | null;
  connect?: NoteWhereUniqueInput[] | null;
  update?: NoteUpdateWithWhereUniqueWithoutPatientInput[] | null;
  updateMany?: NoteUpdateManyWithWhereWithoutPatientInput[] | null;
  deleteMany?: NoteScalarWhereInput[] | null;
}

export interface NoteUpdateWithWhereUniqueWithoutAppointmentInput {
  where: NoteWhereUniqueInput;
  data: NoteUpdateWithoutAppointmentInput;
}

export interface NoteUpdateWithWhereUniqueWithoutPatientInput {
  where: NoteWhereUniqueInput;
  data: NoteUpdateWithoutPatientInput;
}

export interface NoteUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  type?: EnumNoteTypeFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutNotesNestedInput | null;
}

export interface NoteUpdateWithoutPatientInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  content?: StringFieldUpdateOperationsInput | null;
  type?: EnumNoteTypeFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneWithoutNotesNestedInput | null;
}

export interface NoteUpsertWithWhereUniqueWithoutAppointmentInput {
  where: NoteWhereUniqueInput;
  update: NoteUpdateWithoutAppointmentInput;
  create: NoteCreateWithoutAppointmentInput;
}

export interface NoteUpsertWithWhereUniqueWithoutPatientInput {
  where: NoteWhereUniqueInput;
  update: NoteUpdateWithoutPatientInput;
  create: NoteCreateWithoutPatientInput;
}

export interface NoteWhereInput {
  AND?: NoteWhereInput[] | null;
  OR?: NoteWhereInput[] | null;
  NOT?: NoteWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  content?: StringFilter | null;
  appointmentId?: UuidNullableFilter | null;
  patientId?: UuidFilter | null;
  type?: EnumNoteTypeFilter | null;
  appointment?: AppointmentRelationFilter | null;
  patient?: PatientRelationFilter | null;
}

export interface NoteWhereUniqueInput {
  id?: string | null;
}

export interface NullableBoolFieldUpdateOperationsInput {
  set?: boolean | null;
}

export interface NullableDateTimeFieldUpdateOperationsInput {
  set?: any | null;
}

export interface NullableDecimalFieldUpdateOperationsInput {
  set?: any | null;
  increment?: any | null;
  decrement?: any | null;
  multiply?: any | null;
  divide?: any | null;
}

export interface NullableEnumAppointmentStatusFieldUpdateOperationsInput {
  set?: AppointmentStatus | null;
}

export interface NullableEnumAuthorizationRequirementFieldUpdateOperationsInput {
  set?: AuthorizationRequirement | null;
}

export interface NullableEnumBillCommunicationStateFieldUpdateOperationsInput {
  set?: BillCommunicationState | null;
}

export interface NullableEnumBirthSexFieldUpdateOperationsInput {
  set?: BirthSex | null;
}

export interface NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput {
  set?: ChangePayerConnectivityType | null;
}

export interface NullableEnumClearinghouseFieldUpdateOperationsInput {
  set?: Clearinghouse | null;
}

export interface NullableEnumCommercialTypeFieldUpdateOperationsInput {
  set?: CommercialType | null;
}

export interface NullableEnumCommunicationErrorTypeFieldUpdateOperationsInput {
  set?: CommunicationErrorType | null;
}

export interface NullableEnumCoveragePriorityFieldUpdateOperationsInput {
  set?: CoveragePriority | null;
}

export interface NullableEnumMedicaidTypeFieldUpdateOperationsInput {
  set?: MedicaidType | null;
}

export interface NullableEnumMedicareTypeFieldUpdateOperationsInput {
  set?: MedicareType | null;
}

export interface NullableEnumMedigapPlanTypeFieldUpdateOperationsInput {
  set?: MedigapPlanType | null;
}

export interface NullableEnumNetworkStatusFieldUpdateOperationsInput {
  set?: NetworkStatus | null;
}

export interface NullableEnumPaymentMethodFieldUpdateOperationsInput {
  set?: PaymentMethod | null;
}

export interface NullableEnumPostingStatusFieldUpdateOperationsInput {
  set?: PostingStatus | null;
}

export interface NullableEnumTricareTypeFieldUpdateOperationsInput {
  set?: TricareType | null;
}

export interface NullableEnumVerificationWorkflowReasonFieldUpdateOperationsInput {
  set?: VerificationWorkflowReason | null;
}

export interface NullableEnumWorkflowTypeFieldUpdateOperationsInput {
  set?: WorkflowType | null;
}

export interface NullableFloatFieldUpdateOperationsInput {
  set?: number | null;
  increment?: number | null;
  decrement?: number | null;
  multiply?: number | null;
  divide?: number | null;
}

export interface NullableIntFieldUpdateOperationsInput {
  set?: number | null;
  increment?: number | null;
  decrement?: number | null;
  multiply?: number | null;
  divide?: number | null;
}

export interface NullableStringFieldUpdateOperationsInput {
  set?: string | null;
}

export interface OrganizationCreateNestedOneWithoutAccountTypesInput {
  create?: OrganizationCreateWithoutAccountTypesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutAccountTypesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutAccountsInput {
  create?: OrganizationCreateWithoutAccountsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutAccountsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutAppointmentLabelsInput {
  create?: OrganizationCreateWithoutAppointmentLabelsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutAppointmentLabelsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutAppointmentLocationsInput {
  create?: OrganizationCreateWithoutAppointmentLocationsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutAppointmentLocationsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutAppointmentsInput {
  create?: OrganizationCreateWithoutAppointmentsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutAppointmentsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutBillingGroupsInput {
  create?: OrganizationCreateWithoutBillingGroupsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutBillingGroupsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutFeeSchedulesInput {
  create?: OrganizationCreateWithoutFeeSchedulesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutFeeSchedulesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutInsurancePoliciesInput {
  create?: OrganizationCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutInsurancePoliciesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutInsurancePolicyWorklistItemTaskTemplatesInput {
  create?: OrganizationCreateWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutIntegrationsInput {
  create?: OrganizationCreateWithoutIntegrationsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutIntegrationsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutLocationsInput {
  create?: OrganizationCreateWithoutLocationsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutLocationsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutOrganizationInNetworkPayersInput {
  create?: OrganizationCreateWithoutOrganizationInNetworkPayersInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutOrganizationInNetworkPayersInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutOrganizationPatientGroupsInput {
  create?: OrganizationCreateWithoutOrganizationPatientGroupsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutOrganizationPatientGroupsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutPatientLabelsInput {
  create?: OrganizationCreateWithoutPatientLabelsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutPatientLabelsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutPatientsInput {
  create?: OrganizationCreateWithoutPatientsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutPatientsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutPayersInput {
  create?: OrganizationCreateWithoutPayersInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutPayersInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutPaymentRequestBatchesInput {
  create?: OrganizationCreateWithoutPaymentRequestBatchesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutPaymentRequestBatchesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput {
  create?: OrganizationCreateWithoutProviderServiceConfigurationInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutProviderServiceConfigurationInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutProviderServiceConfigurationOverridesInput {
  create?: OrganizationCreateWithoutProviderServiceConfigurationOverridesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutProviderServiceConfigurationOverridesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutProvidersInput {
  create?: OrganizationCreateWithoutProvidersInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutProvidersInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutReminderWorkflowTemplatesInput {
  create?: OrganizationCreateWithoutReminderWorkflowTemplatesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutReminderWorkflowTemplatesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutShortLinksInput {
  create?: OrganizationCreateWithoutShortLinksInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutShortLinksInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutStripeConnectedAccountsInput {
  create?: OrganizationCreateWithoutStripeConnectedAccountsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutStripeConnectedAccountsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutTasksInput {
  create?: OrganizationCreateWithoutTasksInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutTasksInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateNestedOneWithoutUsersInput {
  create?: OrganizationCreateWithoutUsersInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutUsersInput | null;
  connect?: OrganizationWhereUniqueInput | null;
}

export interface OrganizationCreateOrConnectWithoutAccountTypesInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutAccountTypesInput;
}

export interface OrganizationCreateOrConnectWithoutAccountsInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutAccountsInput;
}

export interface OrganizationCreateOrConnectWithoutAppointmentLabelsInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutAppointmentLabelsInput;
}

export interface OrganizationCreateOrConnectWithoutAppointmentLocationsInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutAppointmentLocationsInput;
}

export interface OrganizationCreateOrConnectWithoutAppointmentsInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutAppointmentsInput;
}

export interface OrganizationCreateOrConnectWithoutBillingGroupsInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutBillingGroupsInput;
}

export interface OrganizationCreateOrConnectWithoutFeeSchedulesInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutFeeSchedulesInput;
}

export interface OrganizationCreateOrConnectWithoutInsurancePoliciesInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutInsurancePoliciesInput;
}

export interface OrganizationCreateOrConnectWithoutInsurancePolicyWorklistItemTaskTemplatesInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutInsurancePolicyWorklistItemTaskTemplatesInput;
}

export interface OrganizationCreateOrConnectWithoutIntegrationsInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutIntegrationsInput;
}

export interface OrganizationCreateOrConnectWithoutLocationsInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutLocationsInput;
}

export interface OrganizationCreateOrConnectWithoutOrganizationInNetworkPayersInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutOrganizationInNetworkPayersInput;
}

export interface OrganizationCreateOrConnectWithoutOrganizationPatientGroupsInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutOrganizationPatientGroupsInput;
}

export interface OrganizationCreateOrConnectWithoutPatientLabelsInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutPatientLabelsInput;
}

export interface OrganizationCreateOrConnectWithoutPatientsInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutPatientsInput;
}

export interface OrganizationCreateOrConnectWithoutPayersInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutPayersInput;
}

export interface OrganizationCreateOrConnectWithoutPaymentRequestBatchesInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutPaymentRequestBatchesInput;
}

export interface OrganizationCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutProviderServiceConfigurationInput;
}

export interface OrganizationCreateOrConnectWithoutProviderServiceConfigurationOverridesInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutProviderServiceConfigurationOverridesInput;
}

export interface OrganizationCreateOrConnectWithoutProvidersInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutProvidersInput;
}

export interface OrganizationCreateOrConnectWithoutReminderWorkflowTemplatesInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutReminderWorkflowTemplatesInput;
}

export interface OrganizationCreateOrConnectWithoutShortLinksInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutShortLinksInput;
}

export interface OrganizationCreateOrConnectWithoutStripeConnectedAccountsInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutStripeConnectedAccountsInput;
}

export interface OrganizationCreateOrConnectWithoutTasksInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutTasksInput;
}

export interface OrganizationCreateOrConnectWithoutUsersInput {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutUsersInput;
}

export interface OrganizationCreateWithoutAccountTypesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutAccountsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutAppointmentLabelsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutAppointmentLocationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutAppointmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutBillingGroupsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutFeeSchedulesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutInsurancePoliciesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutInsurancePolicyWorklistItemTaskTemplatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutIntegrationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutLocationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutOrganizationInNetworkPayersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutOrganizationPatientGroupsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutPatientLabelsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutPatientsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutPayersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutPaymentRequestBatchesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutProviderServiceConfigurationOverridesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutProvidersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutReminderWorkflowTemplatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutShortLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutStripeConnectedAccountsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutTasksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  users?: UserCreateNestedManyWithoutOrganizationInput | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationCreateWithoutUsersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  demo?: boolean | null;
  pendingRegistration?: boolean | null;
  taxId?: string | null;
  stripeAccountId?: string | null;
  stripeDetailsSubmitted?: boolean | null;
  stripeChargesEnabled?: boolean | null;
  stripeApplicationFee?: any | null;
  logoUrl?: string | null;
  replyToEmails?: any | null;
  primaryEmail?: string | null;
  senderEmail?: string | null;
  phone?: string | null;
  openHoursBlurb?: string | null;
  messagingServiceSid?: string | null;
  messagingCampaignVerified?: boolean | null;
  stripeConnectAccountType?: StripeConnectAccountType | null;
  statementDescriptor?: string | null;
  automatedRemindersEnabled?: boolean | null;
  estimatesEnabled?: boolean | null;
  benefitsProductEnabled?: boolean | null;
  billingProductEnabled?: boolean | null;
  insurancePolicyWorklistEnabled?: boolean | null;
  appointmentsPageEnabled?: boolean | null;
  patientPortalAccountLookupMessage?: string | null;
  usePracticeBenefitConfig?: boolean | null;
  providers?: ProviderCreateNestedManyWithoutOrganizationInput | null;
  locations?: LocationCreateNestedManyWithoutOrganizationInput | null;
  patients?: PatientCreateNestedManyWithoutOrganizationInput | null;
  accounts?: AccountCreateNestedManyWithoutOrganizationInput | null;
  appointments?: AppointmentCreateNestedManyWithoutOrganizationInput | null;
  feeSchedules?: FeeScheduleCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput | null;
  integrations?: IntegrationCreateNestedManyWithoutOrganizationInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput | null;
  shortLinks?: ShortLinkCreateNestedManyWithoutOrganizationInput | null;
  accountTypes?: AccountTypeCreateNestedManyWithoutOrganizationInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutOrganizationInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountCreateNestedManyWithoutOrganizationInput | null;
  payers?: PayerCreateNestedManyWithoutOrganizationInput | null;
  paymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutOrganizationInput | null;
  tasks?: TaskCreateNestedManyWithoutOrganizationInput | null;
  appointmentLocations?: AppointmentLocationCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutOrganizationInput | null;
  organizationPatientGroups?: OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateCreateNestedManyWithoutOrganizationInput | null;
  appointmentLabels?: AppointmentLabelCreateNestedManyWithoutOrganizationInput | null;
  patientLabels?: PatientLabelCreateNestedManyWithoutOrganizationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput | null;
}

export interface OrganizationInNetworkPayerCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  payerId: string;
}

export interface OrganizationInNetworkPayerCreateManyOrganizationInputEnvelope {
  data: OrganizationInNetworkPayerCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface OrganizationInNetworkPayerCreateManyPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
}

export interface OrganizationInNetworkPayerCreateManyPayerInputEnvelope {
  data: OrganizationInNetworkPayerCreateManyPayerInput[];
  skipDuplicates?: boolean | null;
}

export interface OrganizationInNetworkPayerCreateNestedManyWithoutOrganizationInput {
  create?: OrganizationInNetworkPayerCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: OrganizationInNetworkPayerCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: OrganizationInNetworkPayerCreateManyOrganizationInputEnvelope | null;
  connect?: OrganizationInNetworkPayerWhereUniqueInput[] | null;
}

export interface OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput {
  create?: OrganizationInNetworkPayerCreateWithoutPayerInput[] | null;
  connectOrCreate?: OrganizationInNetworkPayerCreateOrConnectWithoutPayerInput[] | null;
  createMany?: OrganizationInNetworkPayerCreateManyPayerInputEnvelope | null;
  connect?: OrganizationInNetworkPayerWhereUniqueInput[] | null;
}

export interface OrganizationInNetworkPayerCreateOrConnectWithoutOrganizationInput {
  where: OrganizationInNetworkPayerWhereUniqueInput;
  create: OrganizationInNetworkPayerCreateWithoutOrganizationInput;
}

export interface OrganizationInNetworkPayerCreateOrConnectWithoutPayerInput {
  where: OrganizationInNetworkPayerWhereUniqueInput;
  create: OrganizationInNetworkPayerCreateWithoutPayerInput;
}

export interface OrganizationInNetworkPayerCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  payer: PayerCreateNestedOneWithoutOrganizationInNetworkPayersInput;
}

export interface OrganizationInNetworkPayerCreateWithoutPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutOrganizationInNetworkPayersInput;
}

export interface OrganizationInNetworkPayerListRelationFilter {
  every?: OrganizationInNetworkPayerWhereInput | null;
  some?: OrganizationInNetworkPayerWhereInput | null;
  none?: OrganizationInNetworkPayerWhereInput | null;
}

export interface OrganizationInNetworkPayerOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface OrganizationInNetworkPayerOrganizationIdPayerIdCompoundUniqueInput {
  organizationId: string;
  payerId: string;
}

export interface OrganizationInNetworkPayerScalarWhereInput {
  AND?: OrganizationInNetworkPayerScalarWhereInput[] | null;
  OR?: OrganizationInNetworkPayerScalarWhereInput[] | null;
  NOT?: OrganizationInNetworkPayerScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  payerId?: UuidFilter | null;
}

export interface OrganizationInNetworkPayerUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
}

export interface OrganizationInNetworkPayerUpdateManyWithWhereWithoutOrganizationInput {
  where: OrganizationInNetworkPayerScalarWhereInput;
  data: OrganizationInNetworkPayerUpdateManyMutationInput;
}

export interface OrganizationInNetworkPayerUpdateManyWithWhereWithoutPayerInput {
  where: OrganizationInNetworkPayerScalarWhereInput;
  data: OrganizationInNetworkPayerUpdateManyMutationInput;
}

export interface OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput {
  create?: OrganizationInNetworkPayerCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: OrganizationInNetworkPayerCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: OrganizationInNetworkPayerUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: OrganizationInNetworkPayerCreateManyOrganizationInputEnvelope | null;
  set?: OrganizationInNetworkPayerWhereUniqueInput[] | null;
  disconnect?: OrganizationInNetworkPayerWhereUniqueInput[] | null;
  delete?: OrganizationInNetworkPayerWhereUniqueInput[] | null;
  connect?: OrganizationInNetworkPayerWhereUniqueInput[] | null;
  update?: OrganizationInNetworkPayerUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: OrganizationInNetworkPayerUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: OrganizationInNetworkPayerScalarWhereInput[] | null;
}

export interface OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput {
  create?: OrganizationInNetworkPayerCreateWithoutPayerInput[] | null;
  connectOrCreate?: OrganizationInNetworkPayerCreateOrConnectWithoutPayerInput[] | null;
  upsert?: OrganizationInNetworkPayerUpsertWithWhereUniqueWithoutPayerInput[] | null;
  createMany?: OrganizationInNetworkPayerCreateManyPayerInputEnvelope | null;
  set?: OrganizationInNetworkPayerWhereUniqueInput[] | null;
  disconnect?: OrganizationInNetworkPayerWhereUniqueInput[] | null;
  delete?: OrganizationInNetworkPayerWhereUniqueInput[] | null;
  connect?: OrganizationInNetworkPayerWhereUniqueInput[] | null;
  update?: OrganizationInNetworkPayerUpdateWithWhereUniqueWithoutPayerInput[] | null;
  updateMany?: OrganizationInNetworkPayerUpdateManyWithWhereWithoutPayerInput[] | null;
  deleteMany?: OrganizationInNetworkPayerScalarWhereInput[] | null;
}

export interface OrganizationInNetworkPayerUpdateWithWhereUniqueWithoutOrganizationInput {
  where: OrganizationInNetworkPayerWhereUniqueInput;
  data: OrganizationInNetworkPayerUpdateWithoutOrganizationInput;
}

export interface OrganizationInNetworkPayerUpdateWithWhereUniqueWithoutPayerInput {
  where: OrganizationInNetworkPayerWhereUniqueInput;
  data: OrganizationInNetworkPayerUpdateWithoutPayerInput;
}

export interface OrganizationInNetworkPayerUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  payer?: PayerUpdateOneRequiredWithoutOrganizationInNetworkPayersNestedInput | null;
}

export interface OrganizationInNetworkPayerUpdateWithoutPayerInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutOrganizationInNetworkPayersNestedInput | null;
}

export interface OrganizationInNetworkPayerUpsertWithWhereUniqueWithoutOrganizationInput {
  where: OrganizationInNetworkPayerWhereUniqueInput;
  update: OrganizationInNetworkPayerUpdateWithoutOrganizationInput;
  create: OrganizationInNetworkPayerCreateWithoutOrganizationInput;
}

export interface OrganizationInNetworkPayerUpsertWithWhereUniqueWithoutPayerInput {
  where: OrganizationInNetworkPayerWhereUniqueInput;
  update: OrganizationInNetworkPayerUpdateWithoutPayerInput;
  create: OrganizationInNetworkPayerCreateWithoutPayerInput;
}

export interface OrganizationInNetworkPayerWhereInput {
  AND?: OrganizationInNetworkPayerWhereInput[] | null;
  OR?: OrganizationInNetworkPayerWhereInput[] | null;
  NOT?: OrganizationInNetworkPayerWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  payerId?: UuidFilter | null;
  organization?: OrganizationRelationFilter | null;
  payer?: PayerRelationFilter | null;
}

export interface OrganizationInNetworkPayerWhereUniqueInput {
  id?: string | null;
  organizationId_payerId?: OrganizationInNetworkPayerOrganizationIdPayerIdCompoundUniqueInput | null;
}

export interface OrganizationOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  name?: SortOrder | null;
  demo?: SortOrder | null;
  pendingRegistration?: SortOrder | null;
  taxId?: SortOrderInput | null;
  stripeAccountId?: SortOrderInput | null;
  stripeDetailsSubmitted?: SortOrder | null;
  stripeChargesEnabled?: SortOrder | null;
  stripeApplicationFee?: SortOrder | null;
  logoUrl?: SortOrderInput | null;
  replyToEmails?: SortOrderInput | null;
  primaryEmail?: SortOrderInput | null;
  senderEmail?: SortOrderInput | null;
  phone?: SortOrderInput | null;
  openHoursBlurb?: SortOrderInput | null;
  messagingServiceSid?: SortOrderInput | null;
  messagingCampaignVerified?: SortOrder | null;
  stripeConnectAccountType?: SortOrder | null;
  statementDescriptor?: SortOrderInput | null;
  automatedRemindersEnabled?: SortOrder | null;
  estimatesEnabled?: SortOrder | null;
  benefitsProductEnabled?: SortOrder | null;
  billingProductEnabled?: SortOrder | null;
  insurancePolicyWorklistEnabled?: SortOrder | null;
  appointmentsPageEnabled?: SortOrder | null;
  patientPortalAccountLookupMessage?: SortOrderInput | null;
  usePracticeBenefitConfig?: SortOrder | null;
  users?: UserOrderByRelationAggregateInput | null;
  providers?: ProviderOrderByRelationAggregateInput | null;
  locations?: LocationOrderByRelationAggregateInput | null;
  patients?: PatientOrderByRelationAggregateInput | null;
  accounts?: AccountOrderByRelationAggregateInput | null;
  appointments?: AppointmentOrderByRelationAggregateInput | null;
  feeSchedules?: FeeScheduleOrderByRelationAggregateInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationOrderByRelationAggregateInput | null;
  integrations?: IntegrationOrderByRelationAggregateInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerOrderByRelationAggregateInput | null;
  shortLinks?: ShortLinkOrderByRelationAggregateInput | null;
  accountTypes?: AccountTypeOrderByRelationAggregateInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateOrderByRelationAggregateInput | null;
  billingGroups?: BillingGroupOrderByRelationAggregateInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountOrderByRelationAggregateInput | null;
  payers?: PayerOrderByRelationAggregateInput | null;
  paymentRequestBatches?: PaymentRequestBatchOrderByRelationAggregateInput | null;
  tasks?: TaskOrderByRelationAggregateInput | null;
  appointmentLocations?: AppointmentLocationOrderByRelationAggregateInput | null;
  insurancePolicies?: InsurancePolicyOrderByRelationAggregateInput | null;
  organizationPatientGroups?: OrganizationPatientGroupOrderByRelationAggregateInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateOrderByRelationAggregateInput | null;
  appointmentLabels?: AppointmentLabelOrderByRelationAggregateInput | null;
  patientLabels?: PatientLabelOrderByRelationAggregateInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideOrderByRelationAggregateInput | null;
}

export interface OrganizationPatientGroupCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
}

export interface OrganizationPatientGroupCreateManyOrganizationInputEnvelope {
  data: OrganizationPatientGroupCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface OrganizationPatientGroupCreateNestedManyWithoutOrganizationInput {
  create?: OrganizationPatientGroupCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: OrganizationPatientGroupCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: OrganizationPatientGroupCreateManyOrganizationInputEnvelope | null;
  connect?: OrganizationPatientGroupWhereUniqueInput[] | null;
}

export interface OrganizationPatientGroupCreateNestedOneWithoutPatientsInput {
  create?: OrganizationPatientGroupCreateWithoutPatientsInput | null;
  connectOrCreate?: OrganizationPatientGroupCreateOrConnectWithoutPatientsInput | null;
  connect?: OrganizationPatientGroupWhereUniqueInput | null;
}

export interface OrganizationPatientGroupCreateOrConnectWithoutOrganizationInput {
  where: OrganizationPatientGroupWhereUniqueInput;
  create: OrganizationPatientGroupCreateWithoutOrganizationInput;
}

export interface OrganizationPatientGroupCreateOrConnectWithoutPatientsInput {
  where: OrganizationPatientGroupWhereUniqueInput;
  create: OrganizationPatientGroupCreateWithoutPatientsInput;
}

export interface OrganizationPatientGroupCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patients?: PatientCreateNestedManyWithoutOrganizationPatientGroupInput | null;
}

export interface OrganizationPatientGroupCreateWithoutPatientsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organization: OrganizationCreateNestedOneWithoutOrganizationPatientGroupsInput;
}

export interface OrganizationPatientGroupListRelationFilter {
  every?: OrganizationPatientGroupWhereInput | null;
  some?: OrganizationPatientGroupWhereInput | null;
  none?: OrganizationPatientGroupWhereInput | null;
}

export interface OrganizationPatientGroupOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface OrganizationPatientGroupOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  organizationId?: SortOrder | null;
  organization?: OrganizationOrderByWithRelationInput | null;
  patients?: PatientOrderByRelationAggregateInput | null;
}

export interface OrganizationPatientGroupRelationFilter {
  is?: OrganizationPatientGroupWhereInput | null;
  isNot?: OrganizationPatientGroupWhereInput | null;
}

export interface OrganizationPatientGroupScalarWhereInput {
  AND?: OrganizationPatientGroupScalarWhereInput[] | null;
  OR?: OrganizationPatientGroupScalarWhereInput[] | null;
  NOT?: OrganizationPatientGroupScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
}

export interface OrganizationPatientGroupUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
}

export interface OrganizationPatientGroupUpdateManyWithWhereWithoutOrganizationInput {
  where: OrganizationPatientGroupScalarWhereInput;
  data: OrganizationPatientGroupUpdateManyMutationInput;
}

export interface OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput {
  create?: OrganizationPatientGroupCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: OrganizationPatientGroupCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: OrganizationPatientGroupUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: OrganizationPatientGroupCreateManyOrganizationInputEnvelope | null;
  set?: OrganizationPatientGroupWhereUniqueInput[] | null;
  disconnect?: OrganizationPatientGroupWhereUniqueInput[] | null;
  delete?: OrganizationPatientGroupWhereUniqueInput[] | null;
  connect?: OrganizationPatientGroupWhereUniqueInput[] | null;
  update?: OrganizationPatientGroupUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: OrganizationPatientGroupUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: OrganizationPatientGroupScalarWhereInput[] | null;
}

export interface OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput {
  create?: OrganizationPatientGroupCreateWithoutPatientsInput | null;
  connectOrCreate?: OrganizationPatientGroupCreateOrConnectWithoutPatientsInput | null;
  upsert?: OrganizationPatientGroupUpsertWithoutPatientsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: OrganizationPatientGroupWhereUniqueInput | null;
  update?: OrganizationPatientGroupUpdateWithoutPatientsInput | null;
}

export interface OrganizationPatientGroupUpdateWithWhereUniqueWithoutOrganizationInput {
  where: OrganizationPatientGroupWhereUniqueInput;
  data: OrganizationPatientGroupUpdateWithoutOrganizationInput;
}

export interface OrganizationPatientGroupUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patients?: PatientUpdateManyWithoutOrganizationPatientGroupNestedInput | null;
}

export interface OrganizationPatientGroupUpdateWithoutPatientsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutOrganizationPatientGroupsNestedInput | null;
}

export interface OrganizationPatientGroupUpsertWithWhereUniqueWithoutOrganizationInput {
  where: OrganizationPatientGroupWhereUniqueInput;
  update: OrganizationPatientGroupUpdateWithoutOrganizationInput;
  create: OrganizationPatientGroupCreateWithoutOrganizationInput;
}

export interface OrganizationPatientGroupUpsertWithoutPatientsInput {
  update: OrganizationPatientGroupUpdateWithoutPatientsInput;
  create: OrganizationPatientGroupCreateWithoutPatientsInput;
}

export interface OrganizationPatientGroupWhereInput {
  AND?: OrganizationPatientGroupWhereInput[] | null;
  OR?: OrganizationPatientGroupWhereInput[] | null;
  NOT?: OrganizationPatientGroupWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  organization?: OrganizationRelationFilter | null;
  patients?: PatientListRelationFilter | null;
}

export interface OrganizationPatientGroupWhereUniqueInput {
  id?: string | null;
}

export interface OrganizationRelationFilter {
  is?: OrganizationWhereInput | null;
  isNot?: OrganizationWhereInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutAccountTypesNestedInput {
  create?: OrganizationCreateWithoutAccountTypesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutAccountTypesInput | null;
  upsert?: OrganizationUpsertWithoutAccountTypesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutAccountTypesInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutAccountsNestedInput {
  create?: OrganizationCreateWithoutAccountsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutAccountsInput | null;
  upsert?: OrganizationUpsertWithoutAccountsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutAccountsInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutAppointmentLabelsNestedInput {
  create?: OrganizationCreateWithoutAppointmentLabelsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutAppointmentLabelsInput | null;
  upsert?: OrganizationUpsertWithoutAppointmentLabelsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutAppointmentLabelsInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutAppointmentLocationsNestedInput {
  create?: OrganizationCreateWithoutAppointmentLocationsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutAppointmentLocationsInput | null;
  upsert?: OrganizationUpsertWithoutAppointmentLocationsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutAppointmentLocationsInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutAppointmentsNestedInput {
  create?: OrganizationCreateWithoutAppointmentsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutAppointmentsInput | null;
  upsert?: OrganizationUpsertWithoutAppointmentsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutAppointmentsInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutBillingGroupsNestedInput {
  create?: OrganizationCreateWithoutBillingGroupsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutBillingGroupsInput | null;
  upsert?: OrganizationUpsertWithoutBillingGroupsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutBillingGroupsInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutFeeSchedulesNestedInput {
  create?: OrganizationCreateWithoutFeeSchedulesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutFeeSchedulesInput | null;
  upsert?: OrganizationUpsertWithoutFeeSchedulesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutFeeSchedulesInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutInsurancePoliciesNestedInput {
  create?: OrganizationCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutInsurancePoliciesInput | null;
  upsert?: OrganizationUpsertWithoutInsurancePoliciesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutInsurancePoliciesInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutInsurancePolicyWorklistItemTaskTemplatesNestedInput {
  create?: OrganizationCreateWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
  upsert?: OrganizationUpsertWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutInsurancePolicyWorklistItemTaskTemplatesInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutIntegrationsNestedInput {
  create?: OrganizationCreateWithoutIntegrationsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutIntegrationsInput | null;
  upsert?: OrganizationUpsertWithoutIntegrationsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutIntegrationsInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutLocationsNestedInput {
  create?: OrganizationCreateWithoutLocationsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutLocationsInput | null;
  upsert?: OrganizationUpsertWithoutLocationsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutLocationsInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutOrganizationInNetworkPayersNestedInput {
  create?: OrganizationCreateWithoutOrganizationInNetworkPayersInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutOrganizationInNetworkPayersInput | null;
  upsert?: OrganizationUpsertWithoutOrganizationInNetworkPayersInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutOrganizationInNetworkPayersInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutOrganizationPatientGroupsNestedInput {
  create?: OrganizationCreateWithoutOrganizationPatientGroupsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutOrganizationPatientGroupsInput | null;
  upsert?: OrganizationUpsertWithoutOrganizationPatientGroupsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutOrganizationPatientGroupsInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutPatientLabelsNestedInput {
  create?: OrganizationCreateWithoutPatientLabelsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutPatientLabelsInput | null;
  upsert?: OrganizationUpsertWithoutPatientLabelsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutPatientLabelsInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutPatientsNestedInput {
  create?: OrganizationCreateWithoutPatientsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutPatientsInput | null;
  upsert?: OrganizationUpsertWithoutPatientsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutPatientsInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutPaymentRequestBatchesNestedInput {
  create?: OrganizationCreateWithoutPaymentRequestBatchesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutPaymentRequestBatchesInput | null;
  upsert?: OrganizationUpsertWithoutPaymentRequestBatchesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutPaymentRequestBatchesInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput {
  create?: OrganizationCreateWithoutProviderServiceConfigurationInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutProviderServiceConfigurationInput | null;
  upsert?: OrganizationUpsertWithoutProviderServiceConfigurationInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutProviderServiceConfigurationInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationOverridesNestedInput {
  create?: OrganizationCreateWithoutProviderServiceConfigurationOverridesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutProviderServiceConfigurationOverridesInput | null;
  upsert?: OrganizationUpsertWithoutProviderServiceConfigurationOverridesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutProviderServiceConfigurationOverridesInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutProvidersNestedInput {
  create?: OrganizationCreateWithoutProvidersInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutProvidersInput | null;
  upsert?: OrganizationUpsertWithoutProvidersInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutProvidersInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutReminderWorkflowTemplatesNestedInput {
  create?: OrganizationCreateWithoutReminderWorkflowTemplatesInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutReminderWorkflowTemplatesInput | null;
  upsert?: OrganizationUpsertWithoutReminderWorkflowTemplatesInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutReminderWorkflowTemplatesInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutShortLinksNestedInput {
  create?: OrganizationCreateWithoutShortLinksInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutShortLinksInput | null;
  upsert?: OrganizationUpsertWithoutShortLinksInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutShortLinksInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutStripeConnectedAccountsNestedInput {
  create?: OrganizationCreateWithoutStripeConnectedAccountsInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutStripeConnectedAccountsInput | null;
  upsert?: OrganizationUpsertWithoutStripeConnectedAccountsInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutStripeConnectedAccountsInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutTasksNestedInput {
  create?: OrganizationCreateWithoutTasksInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutTasksInput | null;
  upsert?: OrganizationUpsertWithoutTasksInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutTasksInput | null;
}

export interface OrganizationUpdateOneRequiredWithoutUsersNestedInput {
  create?: OrganizationCreateWithoutUsersInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutUsersInput | null;
  upsert?: OrganizationUpsertWithoutUsersInput | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutUsersInput | null;
}

export interface OrganizationUpdateOneWithoutPayersNestedInput {
  create?: OrganizationCreateWithoutPayersInput | null;
  connectOrCreate?: OrganizationCreateOrConnectWithoutPayersInput | null;
  upsert?: OrganizationUpsertWithoutPayersInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: OrganizationWhereUniqueInput | null;
  update?: OrganizationUpdateWithoutPayersInput | null;
}

export interface OrganizationUpdateWithoutAccountTypesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutAccountsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutAppointmentLabelsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutAppointmentLocationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutAppointmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutBillingGroupsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutFeeSchedulesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutInsurancePoliciesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutInsurancePolicyWorklistItemTaskTemplatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutIntegrationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutLocationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutOrganizationInNetworkPayersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutOrganizationPatientGroupsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutPatientLabelsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutPatientsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutPayersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutPaymentRequestBatchesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutProviderServiceConfigurationOverridesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutProvidersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutReminderWorkflowTemplatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutShortLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutStripeConnectedAccountsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutTasksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  users?: UserUpdateManyWithoutOrganizationNestedInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpdateWithoutUsersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  demo?: BoolFieldUpdateOperationsInput | null;
  pendingRegistration?: BoolFieldUpdateOperationsInput | null;
  taxId?: NullableStringFieldUpdateOperationsInput | null;
  stripeAccountId?: NullableStringFieldUpdateOperationsInput | null;
  stripeDetailsSubmitted?: BoolFieldUpdateOperationsInput | null;
  stripeChargesEnabled?: BoolFieldUpdateOperationsInput | null;
  stripeApplicationFee?: DecimalFieldUpdateOperationsInput | null;
  logoUrl?: NullableStringFieldUpdateOperationsInput | null;
  replyToEmails?: any | null;
  primaryEmail?: NullableStringFieldUpdateOperationsInput | null;
  senderEmail?: NullableStringFieldUpdateOperationsInput | null;
  phone?: NullableStringFieldUpdateOperationsInput | null;
  openHoursBlurb?: NullableStringFieldUpdateOperationsInput | null;
  messagingServiceSid?: NullableStringFieldUpdateOperationsInput | null;
  messagingCampaignVerified?: BoolFieldUpdateOperationsInput | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  statementDescriptor?: NullableStringFieldUpdateOperationsInput | null;
  automatedRemindersEnabled?: BoolFieldUpdateOperationsInput | null;
  estimatesEnabled?: BoolFieldUpdateOperationsInput | null;
  benefitsProductEnabled?: BoolFieldUpdateOperationsInput | null;
  billingProductEnabled?: BoolFieldUpdateOperationsInput | null;
  insurancePolicyWorklistEnabled?: BoolFieldUpdateOperationsInput | null;
  appointmentsPageEnabled?: BoolFieldUpdateOperationsInput | null;
  patientPortalAccountLookupMessage?: NullableStringFieldUpdateOperationsInput | null;
  usePracticeBenefitConfig?: BoolFieldUpdateOperationsInput | null;
  providers?: ProviderUpdateManyWithoutOrganizationNestedInput | null;
  locations?: LocationUpdateManyWithoutOrganizationNestedInput | null;
  patients?: PatientUpdateManyWithoutOrganizationNestedInput | null;
  accounts?: AccountUpdateManyWithoutOrganizationNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutOrganizationNestedInput | null;
  feeSchedules?: FeeScheduleUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput | null;
  integrations?: IntegrationUpdateManyWithoutOrganizationNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutOrganizationNestedInput | null;
  shortLinks?: ShortLinkUpdateManyWithoutOrganizationNestedInput | null;
  accountTypes?: AccountTypeUpdateManyWithoutOrganizationNestedInput | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutOrganizationNestedInput | null;
  stripeConnectedAccounts?: StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput | null;
  payers?: PayerUpdateManyWithoutOrganizationNestedInput | null;
  paymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput | null;
  tasks?: TaskUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLocations?: AppointmentLocationUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutOrganizationNestedInput | null;
  organizationPatientGroups?: OrganizationPatientGroupUpdateManyWithoutOrganizationNestedInput | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateUpdateManyWithoutOrganizationNestedInput | null;
  appointmentLabels?: AppointmentLabelUpdateManyWithoutOrganizationNestedInput | null;
  patientLabels?: PatientLabelUpdateManyWithoutOrganizationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput | null;
}

export interface OrganizationUpsertWithoutAccountTypesInput {
  update: OrganizationUpdateWithoutAccountTypesInput;
  create: OrganizationCreateWithoutAccountTypesInput;
}

export interface OrganizationUpsertWithoutAccountsInput {
  update: OrganizationUpdateWithoutAccountsInput;
  create: OrganizationCreateWithoutAccountsInput;
}

export interface OrganizationUpsertWithoutAppointmentLabelsInput {
  update: OrganizationUpdateWithoutAppointmentLabelsInput;
  create: OrganizationCreateWithoutAppointmentLabelsInput;
}

export interface OrganizationUpsertWithoutAppointmentLocationsInput {
  update: OrganizationUpdateWithoutAppointmentLocationsInput;
  create: OrganizationCreateWithoutAppointmentLocationsInput;
}

export interface OrganizationUpsertWithoutAppointmentsInput {
  update: OrganizationUpdateWithoutAppointmentsInput;
  create: OrganizationCreateWithoutAppointmentsInput;
}

export interface OrganizationUpsertWithoutBillingGroupsInput {
  update: OrganizationUpdateWithoutBillingGroupsInput;
  create: OrganizationCreateWithoutBillingGroupsInput;
}

export interface OrganizationUpsertWithoutFeeSchedulesInput {
  update: OrganizationUpdateWithoutFeeSchedulesInput;
  create: OrganizationCreateWithoutFeeSchedulesInput;
}

export interface OrganizationUpsertWithoutInsurancePoliciesInput {
  update: OrganizationUpdateWithoutInsurancePoliciesInput;
  create: OrganizationCreateWithoutInsurancePoliciesInput;
}

export interface OrganizationUpsertWithoutInsurancePolicyWorklistItemTaskTemplatesInput {
  update: OrganizationUpdateWithoutInsurancePolicyWorklistItemTaskTemplatesInput;
  create: OrganizationCreateWithoutInsurancePolicyWorklistItemTaskTemplatesInput;
}

export interface OrganizationUpsertWithoutIntegrationsInput {
  update: OrganizationUpdateWithoutIntegrationsInput;
  create: OrganizationCreateWithoutIntegrationsInput;
}

export interface OrganizationUpsertWithoutLocationsInput {
  update: OrganizationUpdateWithoutLocationsInput;
  create: OrganizationCreateWithoutLocationsInput;
}

export interface OrganizationUpsertWithoutOrganizationInNetworkPayersInput {
  update: OrganizationUpdateWithoutOrganizationInNetworkPayersInput;
  create: OrganizationCreateWithoutOrganizationInNetworkPayersInput;
}

export interface OrganizationUpsertWithoutOrganizationPatientGroupsInput {
  update: OrganizationUpdateWithoutOrganizationPatientGroupsInput;
  create: OrganizationCreateWithoutOrganizationPatientGroupsInput;
}

export interface OrganizationUpsertWithoutPatientLabelsInput {
  update: OrganizationUpdateWithoutPatientLabelsInput;
  create: OrganizationCreateWithoutPatientLabelsInput;
}

export interface OrganizationUpsertWithoutPatientsInput {
  update: OrganizationUpdateWithoutPatientsInput;
  create: OrganizationCreateWithoutPatientsInput;
}

export interface OrganizationUpsertWithoutPayersInput {
  update: OrganizationUpdateWithoutPayersInput;
  create: OrganizationCreateWithoutPayersInput;
}

export interface OrganizationUpsertWithoutPaymentRequestBatchesInput {
  update: OrganizationUpdateWithoutPaymentRequestBatchesInput;
  create: OrganizationCreateWithoutPaymentRequestBatchesInput;
}

export interface OrganizationUpsertWithoutProviderServiceConfigurationInput {
  update: OrganizationUpdateWithoutProviderServiceConfigurationInput;
  create: OrganizationCreateWithoutProviderServiceConfigurationInput;
}

export interface OrganizationUpsertWithoutProviderServiceConfigurationOverridesInput {
  update: OrganizationUpdateWithoutProviderServiceConfigurationOverridesInput;
  create: OrganizationCreateWithoutProviderServiceConfigurationOverridesInput;
}

export interface OrganizationUpsertWithoutProvidersInput {
  update: OrganizationUpdateWithoutProvidersInput;
  create: OrganizationCreateWithoutProvidersInput;
}

export interface OrganizationUpsertWithoutReminderWorkflowTemplatesInput {
  update: OrganizationUpdateWithoutReminderWorkflowTemplatesInput;
  create: OrganizationCreateWithoutReminderWorkflowTemplatesInput;
}

export interface OrganizationUpsertWithoutShortLinksInput {
  update: OrganizationUpdateWithoutShortLinksInput;
  create: OrganizationCreateWithoutShortLinksInput;
}

export interface OrganizationUpsertWithoutStripeConnectedAccountsInput {
  update: OrganizationUpdateWithoutStripeConnectedAccountsInput;
  create: OrganizationCreateWithoutStripeConnectedAccountsInput;
}

export interface OrganizationUpsertWithoutTasksInput {
  update: OrganizationUpdateWithoutTasksInput;
  create: OrganizationCreateWithoutTasksInput;
}

export interface OrganizationUpsertWithoutUsersInput {
  update: OrganizationUpdateWithoutUsersInput;
  create: OrganizationCreateWithoutUsersInput;
}

export interface OrganizationWhereInput {
  AND?: OrganizationWhereInput[] | null;
  OR?: OrganizationWhereInput[] | null;
  NOT?: OrganizationWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  demo?: BoolFilter | null;
  pendingRegistration?: BoolFilter | null;
  taxId?: StringNullableFilter | null;
  stripeAccountId?: StringNullableFilter | null;
  stripeDetailsSubmitted?: BoolFilter | null;
  stripeChargesEnabled?: BoolFilter | null;
  stripeApplicationFee?: DecimalFilter | null;
  logoUrl?: StringNullableFilter | null;
  replyToEmails?: JsonNullableFilter | null;
  primaryEmail?: StringNullableFilter | null;
  senderEmail?: StringNullableFilter | null;
  phone?: StringNullableFilter | null;
  openHoursBlurb?: StringNullableFilter | null;
  messagingServiceSid?: StringNullableFilter | null;
  messagingCampaignVerified?: BoolFilter | null;
  stripeConnectAccountType?: EnumStripeConnectAccountTypeFilter | null;
  statementDescriptor?: StringNullableFilter | null;
  automatedRemindersEnabled?: BoolFilter | null;
  estimatesEnabled?: BoolFilter | null;
  benefitsProductEnabled?: BoolFilter | null;
  billingProductEnabled?: BoolFilter | null;
  insurancePolicyWorklistEnabled?: BoolFilter | null;
  appointmentsPageEnabled?: BoolFilter | null;
  patientPortalAccountLookupMessage?: StringNullableFilter | null;
  usePracticeBenefitConfig?: BoolFilter | null;
  users?: UserListRelationFilter | null;
  providers?: ProviderListRelationFilter | null;
  locations?: LocationListRelationFilter | null;
  patients?: PatientListRelationFilter | null;
  accounts?: AccountListRelationFilter | null;
  appointments?: AppointmentListRelationFilter | null;
  feeSchedules?: FeeScheduleListRelationFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationListRelationFilter | null;
  integrations?: IntegrationListRelationFilter | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerListRelationFilter | null;
  shortLinks?: ShortLinkListRelationFilter | null;
  accountTypes?: AccountTypeListRelationFilter | null;
  reminderWorkflowTemplates?: ReminderWorkflowTemplateListRelationFilter | null;
  billingGroups?: BillingGroupListRelationFilter | null;
  stripeConnectedAccounts?: StripeConnectedAccountListRelationFilter | null;
  payers?: PayerListRelationFilter | null;
  paymentRequestBatches?: PaymentRequestBatchListRelationFilter | null;
  tasks?: TaskListRelationFilter | null;
  appointmentLocations?: AppointmentLocationListRelationFilter | null;
  insurancePolicies?: InsurancePolicyListRelationFilter | null;
  organizationPatientGroups?: OrganizationPatientGroupListRelationFilter | null;
  insurancePolicyWorklistItemTaskTemplates?: InsurancePolicyWorklistItemTaskTemplateListRelationFilter | null;
  appointmentLabels?: AppointmentLabelListRelationFilter | null;
  patientLabels?: PatientLabelListRelationFilter | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideListRelationFilter | null;
}

export interface OrganizationWhereUniqueInput {
  id?: string | null;
  stripeAccountId?: string | null;
}

export interface OtherBenefitCreateManyEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OtherBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
}

export interface OtherBenefitCreateManyEligibilityRequestInputEnvelope {
  data: OtherBenefitCreateManyEligibilityRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface OtherBenefitCreateNestedManyWithoutEligibilityRequestInput {
  create?: OtherBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: OtherBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  createMany?: OtherBenefitCreateManyEligibilityRequestInputEnvelope | null;
  connect?: OtherBenefitWhereUniqueInput[] | null;
}

export interface OtherBenefitCreateNestedOneWithoutActiveCoverageCoverageBenefitsInput {
  create?: OtherBenefitCreateWithoutActiveCoverageCoverageBenefitsInput | null;
  connectOrCreate?: OtherBenefitCreateOrConnectWithoutActiveCoverageCoverageBenefitsInput | null;
  connect?: OtherBenefitWhereUniqueInput | null;
}

export interface OtherBenefitCreateNestedOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput {
  create?: OtherBenefitCreateWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: OtherBenefitCreateOrConnectWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  connect?: OtherBenefitWhereUniqueInput | null;
}

export interface OtherBenefitCreateNestedOneWithoutNonCoveredCoverageBenefitsInput {
  create?: OtherBenefitCreateWithoutNonCoveredCoverageBenefitsInput | null;
  connectOrCreate?: OtherBenefitCreateOrConnectWithoutNonCoveredCoverageBenefitsInput | null;
  connect?: OtherBenefitWhereUniqueInput | null;
}

export interface OtherBenefitCreateNestedOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput {
  create?: OtherBenefitCreateWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: OtherBenefitCreateOrConnectWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  connect?: OtherBenefitWhereUniqueInput | null;
}

export interface OtherBenefitCreateOrConnectWithoutActiveCoverageCoverageBenefitsInput {
  where: OtherBenefitWhereUniqueInput;
  create: OtherBenefitCreateWithoutActiveCoverageCoverageBenefitsInput;
}

export interface OtherBenefitCreateOrConnectWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput {
  where: OtherBenefitWhereUniqueInput;
  create: OtherBenefitCreateWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput;
}

export interface OtherBenefitCreateOrConnectWithoutEligibilityRequestInput {
  where: OtherBenefitWhereUniqueInput;
  create: OtherBenefitCreateWithoutEligibilityRequestInput;
}

export interface OtherBenefitCreateOrConnectWithoutNonCoveredCoverageBenefitsInput {
  where: OtherBenefitWhereUniqueInput;
  create: OtherBenefitCreateWithoutNonCoveredCoverageBenefitsInput;
}

export interface OtherBenefitCreateOrConnectWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput {
  where: OtherBenefitWhereUniqueInput;
  create: OtherBenefitCreateWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput;
}

export interface OtherBenefitCreateWithoutActiveCoverageCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OtherBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutOtherBenefitsInput;
  nonCoveredEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutNonCoveredBenefitInput | null;
  activeCoverageEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutActiveCoverageBenefitInput | null;
  nonCoveredCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutNonCoveredBenefitInput | null;
}

export interface OtherBenefitCreateWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OtherBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutOtherBenefitsInput;
  nonCoveredEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutNonCoveredBenefitInput | null;
  nonCoveredCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutNonCoveredBenefitInput | null;
  activeCoverageCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutActiveCoverageBenefitInput | null;
}

export interface OtherBenefitCreateWithoutEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OtherBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  nonCoveredEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutNonCoveredBenefitInput | null;
  activeCoverageEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutActiveCoverageBenefitInput | null;
  nonCoveredCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutNonCoveredBenefitInput | null;
  activeCoverageCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutActiveCoverageBenefitInput | null;
}

export interface OtherBenefitCreateWithoutNonCoveredCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OtherBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutOtherBenefitsInput;
  nonCoveredEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutNonCoveredBenefitInput | null;
  activeCoverageEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutActiveCoverageBenefitInput | null;
  activeCoverageCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutActiveCoverageBenefitInput | null;
}

export interface OtherBenefitCreateWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  timePeriodQualifier?: string | null;
  quantityQualifier?: string | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OtherBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutOtherBenefitsInput;
  activeCoverageEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutActiveCoverageBenefitInput | null;
  nonCoveredCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutNonCoveredBenefitInput | null;
  activeCoverageCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutActiveCoverageBenefitInput | null;
}

export interface OtherBenefitCreatenotesInput {
  set: string[];
}

export interface OtherBenefitListRelationFilter {
  every?: OtherBenefitWhereInput | null;
  some?: OtherBenefitWhereInput | null;
  none?: OtherBenefitWhereInput | null;
}

export interface OtherBenefitRelationFilter {
  is?: OtherBenefitWhereInput | null;
  isNot?: OtherBenefitWhereInput | null;
}

export interface OtherBenefitScalarWhereInput {
  AND?: OtherBenefitScalarWhereInput[] | null;
  OR?: OtherBenefitScalarWhereInput[] | null;
  NOT?: OtherBenefitScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  timePeriodQualifier?: StringNullableFilter | null;
  quantityQualifier?: StringNullableFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
}

export interface OtherBenefitUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OtherBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
}

export interface OtherBenefitUpdateManyWithWhereWithoutEligibilityRequestInput {
  where: OtherBenefitScalarWhereInput;
  data: OtherBenefitUpdateManyMutationInput;
}

export interface OtherBenefitUpdateManyWithoutEligibilityRequestNestedInput {
  create?: OtherBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: OtherBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  upsert?: OtherBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  createMany?: OtherBenefitCreateManyEligibilityRequestInputEnvelope | null;
  set?: OtherBenefitWhereUniqueInput[] | null;
  disconnect?: OtherBenefitWhereUniqueInput[] | null;
  delete?: OtherBenefitWhereUniqueInput[] | null;
  connect?: OtherBenefitWhereUniqueInput[] | null;
  update?: OtherBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  updateMany?: OtherBenefitUpdateManyWithWhereWithoutEligibilityRequestInput[] | null;
  deleteMany?: OtherBenefitScalarWhereInput[] | null;
}

export interface OtherBenefitUpdateOneWithoutActiveCoverageCoverageBenefitsNestedInput {
  create?: OtherBenefitCreateWithoutActiveCoverageCoverageBenefitsInput | null;
  connectOrCreate?: OtherBenefitCreateOrConnectWithoutActiveCoverageCoverageBenefitsInput | null;
  upsert?: OtherBenefitUpsertWithoutActiveCoverageCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: OtherBenefitWhereUniqueInput | null;
  update?: OtherBenefitUpdateWithoutActiveCoverageCoverageBenefitsInput | null;
}

export interface OtherBenefitUpdateOneWithoutActiveCoverageEligibilityCoverageBenefitSnapshotNestedInput {
  create?: OtherBenefitCreateWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: OtherBenefitCreateOrConnectWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  upsert?: OtherBenefitUpsertWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: OtherBenefitWhereUniqueInput | null;
  update?: OtherBenefitUpdateWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput | null;
}

export interface OtherBenefitUpdateOneWithoutNonCoveredCoverageBenefitsNestedInput {
  create?: OtherBenefitCreateWithoutNonCoveredCoverageBenefitsInput | null;
  connectOrCreate?: OtherBenefitCreateOrConnectWithoutNonCoveredCoverageBenefitsInput | null;
  upsert?: OtherBenefitUpsertWithoutNonCoveredCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: OtherBenefitWhereUniqueInput | null;
  update?: OtherBenefitUpdateWithoutNonCoveredCoverageBenefitsInput | null;
}

export interface OtherBenefitUpdateOneWithoutNonCoveredEligibilityCoverageBenefitSnapshotNestedInput {
  create?: OtherBenefitCreateWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: OtherBenefitCreateOrConnectWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  upsert?: OtherBenefitUpsertWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: OtherBenefitWhereUniqueInput | null;
  update?: OtherBenefitUpdateWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput | null;
}

export interface OtherBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput {
  where: OtherBenefitWhereUniqueInput;
  data: OtherBenefitUpdateWithoutEligibilityRequestInput;
}

export interface OtherBenefitUpdateWithoutActiveCoverageCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OtherBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutOtherBenefitsNestedInput | null;
  nonCoveredEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutNonCoveredBenefitNestedInput | null;
  activeCoverageEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutActiveCoverageBenefitNestedInput | null;
  nonCoveredCoverageBenefits?: CoverageBenefitUpdateManyWithoutNonCoveredBenefitNestedInput | null;
}

export interface OtherBenefitUpdateWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OtherBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutOtherBenefitsNestedInput | null;
  nonCoveredEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutNonCoveredBenefitNestedInput | null;
  nonCoveredCoverageBenefits?: CoverageBenefitUpdateManyWithoutNonCoveredBenefitNestedInput | null;
  activeCoverageCoverageBenefits?: CoverageBenefitUpdateManyWithoutActiveCoverageBenefitNestedInput | null;
}

export interface OtherBenefitUpdateWithoutEligibilityRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OtherBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  nonCoveredEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutNonCoveredBenefitNestedInput | null;
  activeCoverageEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutActiveCoverageBenefitNestedInput | null;
  nonCoveredCoverageBenefits?: CoverageBenefitUpdateManyWithoutNonCoveredBenefitNestedInput | null;
  activeCoverageCoverageBenefits?: CoverageBenefitUpdateManyWithoutActiveCoverageBenefitNestedInput | null;
}

export interface OtherBenefitUpdateWithoutNonCoveredCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OtherBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutOtherBenefitsNestedInput | null;
  nonCoveredEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutNonCoveredBenefitNestedInput | null;
  activeCoverageEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutActiveCoverageBenefitNestedInput | null;
  activeCoverageCoverageBenefits?: CoverageBenefitUpdateManyWithoutActiveCoverageBenefitNestedInput | null;
}

export interface OtherBenefitUpdateWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  timePeriodQualifier?: NullableStringFieldUpdateOperationsInput | null;
  quantityQualifier?: NullableStringFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OtherBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutOtherBenefitsNestedInput | null;
  activeCoverageEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutActiveCoverageBenefitNestedInput | null;
  nonCoveredCoverageBenefits?: CoverageBenefitUpdateManyWithoutNonCoveredBenefitNestedInput | null;
  activeCoverageCoverageBenefits?: CoverageBenefitUpdateManyWithoutActiveCoverageBenefitNestedInput | null;
}

export interface OtherBenefitUpdatenotesInput {
  set?: string[] | null;
  push?: string[] | null;
}

export interface OtherBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput {
  where: OtherBenefitWhereUniqueInput;
  update: OtherBenefitUpdateWithoutEligibilityRequestInput;
  create: OtherBenefitCreateWithoutEligibilityRequestInput;
}

export interface OtherBenefitUpsertWithoutActiveCoverageCoverageBenefitsInput {
  update: OtherBenefitUpdateWithoutActiveCoverageCoverageBenefitsInput;
  create: OtherBenefitCreateWithoutActiveCoverageCoverageBenefitsInput;
}

export interface OtherBenefitUpsertWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput {
  update: OtherBenefitUpdateWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput;
  create: OtherBenefitCreateWithoutActiveCoverageEligibilityCoverageBenefitSnapshotInput;
}

export interface OtherBenefitUpsertWithoutNonCoveredCoverageBenefitsInput {
  update: OtherBenefitUpdateWithoutNonCoveredCoverageBenefitsInput;
  create: OtherBenefitCreateWithoutNonCoveredCoverageBenefitsInput;
}

export interface OtherBenefitUpsertWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput {
  update: OtherBenefitUpdateWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput;
  create: OtherBenefitCreateWithoutNonCoveredEligibilityCoverageBenefitSnapshotInput;
}

export interface OtherBenefitWhereInput {
  AND?: OtherBenefitWhereInput[] | null;
  OR?: OtherBenefitWhereInput[] | null;
  NOT?: OtherBenefitWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  timePeriodQualifier?: StringNullableFilter | null;
  quantityQualifier?: StringNullableFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
  eligibilityRequest?: EligibilityRequestRelationFilter | null;
  nonCoveredEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
  activeCoverageEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
  nonCoveredCoverageBenefits?: CoverageBenefitListRelationFilter | null;
  activeCoverageCoverageBenefits?: CoverageBenefitListRelationFilter | null;
}

export interface OtherBenefitWhereUniqueInput {
  id?: string | null;
}

export interface OutOfPocketBenefitCreateManyEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OutOfPocketBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
}

export interface OutOfPocketBenefitCreateManyEligibilityRequestInputEnvelope {
  data: OutOfPocketBenefitCreateManyEligibilityRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface OutOfPocketBenefitCreateNestedManyWithoutEligibilityRequestInput {
  create?: OutOfPocketBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: OutOfPocketBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  createMany?: OutOfPocketBenefitCreateManyEligibilityRequestInputEnvelope | null;
  connect?: OutOfPocketBenefitWhereUniqueInput[] | null;
}

export interface OutOfPocketBenefitCreateNestedOneWithoutMaxCoverageBenefitsInput {
  create?: OutOfPocketBenefitCreateWithoutMaxCoverageBenefitsInput | null;
  connectOrCreate?: OutOfPocketBenefitCreateOrConnectWithoutMaxCoverageBenefitsInput | null;
  connect?: OutOfPocketBenefitWhereUniqueInput | null;
}

export interface OutOfPocketBenefitCreateNestedOneWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  create?: OutOfPocketBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: OutOfPocketBenefitCreateOrConnectWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  connect?: OutOfPocketBenefitWhereUniqueInput | null;
}

export interface OutOfPocketBenefitCreateNestedOneWithoutRemainingCoverageBenefitsInput {
  create?: OutOfPocketBenefitCreateWithoutRemainingCoverageBenefitsInput | null;
  connectOrCreate?: OutOfPocketBenefitCreateOrConnectWithoutRemainingCoverageBenefitsInput | null;
  connect?: OutOfPocketBenefitWhereUniqueInput | null;
}

export interface OutOfPocketBenefitCreateNestedOneWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  create?: OutOfPocketBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: OutOfPocketBenefitCreateOrConnectWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  connect?: OutOfPocketBenefitWhereUniqueInput | null;
}

export interface OutOfPocketBenefitCreateOrConnectWithoutEligibilityRequestInput {
  where: OutOfPocketBenefitWhereUniqueInput;
  create: OutOfPocketBenefitCreateWithoutEligibilityRequestInput;
}

export interface OutOfPocketBenefitCreateOrConnectWithoutMaxCoverageBenefitsInput {
  where: OutOfPocketBenefitWhereUniqueInput;
  create: OutOfPocketBenefitCreateWithoutMaxCoverageBenefitsInput;
}

export interface OutOfPocketBenefitCreateOrConnectWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  where: OutOfPocketBenefitWhereUniqueInput;
  create: OutOfPocketBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput;
}

export interface OutOfPocketBenefitCreateOrConnectWithoutRemainingCoverageBenefitsInput {
  where: OutOfPocketBenefitWhereUniqueInput;
  create: OutOfPocketBenefitCreateWithoutRemainingCoverageBenefitsInput;
}

export interface OutOfPocketBenefitCreateOrConnectWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  where: OutOfPocketBenefitWhereUniqueInput;
  create: OutOfPocketBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput;
}

export interface OutOfPocketBenefitCreateWithoutEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OutOfPocketBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingOutOfPocketBenefitInput | null;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxOutOfPocketBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingOutOfPocketBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxOutOfPocketBenefitInput | null;
}

export interface OutOfPocketBenefitCreateWithoutMaxCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OutOfPocketBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutOutOfPocketBenefitsInput;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingOutOfPocketBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingOutOfPocketBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxOutOfPocketBenefitInput | null;
}

export interface OutOfPocketBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OutOfPocketBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutOutOfPocketBenefitsInput;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingOutOfPocketBenefitInput | null;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxOutOfPocketBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingOutOfPocketBenefitInput | null;
}

export interface OutOfPocketBenefitCreateWithoutRemainingCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OutOfPocketBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutOutOfPocketBenefitsInput;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxOutOfPocketBenefitInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutRemainingOutOfPocketBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxOutOfPocketBenefitInput | null;
}

export interface OutOfPocketBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  max?: number | null;
  remaining?: number | null;
  coverageLevel?: string | null;
  serviceType?: string | null;
  inNetwork?: NetworkStatus | null;
  placeOfService?: string | null;
  notes?: OutOfPocketBenefitCreatenotesInput | null;
  validTo?: any | null;
  validFrom?: any | null;
  authRequired?: AuthorizationRequirement | null;
  raw: any;
  eligibilityRequest: EligibilityRequestCreateNestedOneWithoutOutOfPocketBenefitsInput;
  remainingCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutRemainingOutOfPocketBenefitInput | null;
  maxCoverageBenefits?: CoverageBenefitCreateNestedManyWithoutMaxOutOfPocketBenefitInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutMaxOutOfPocketBenefitInput | null;
}

export interface OutOfPocketBenefitCreatenotesInput {
  set: string[];
}

export interface OutOfPocketBenefitListRelationFilter {
  every?: OutOfPocketBenefitWhereInput | null;
  some?: OutOfPocketBenefitWhereInput | null;
  none?: OutOfPocketBenefitWhereInput | null;
}

export interface OutOfPocketBenefitRelationFilter {
  is?: OutOfPocketBenefitWhereInput | null;
  isNot?: OutOfPocketBenefitWhereInput | null;
}

export interface OutOfPocketBenefitScalarWhereInput {
  AND?: OutOfPocketBenefitScalarWhereInput[] | null;
  OR?: OutOfPocketBenefitScalarWhereInput[] | null;
  NOT?: OutOfPocketBenefitScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  max?: IntNullableFilter | null;
  remaining?: IntNullableFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
}

export interface OutOfPocketBenefitUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OutOfPocketBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
}

export interface OutOfPocketBenefitUpdateManyWithWhereWithoutEligibilityRequestInput {
  where: OutOfPocketBenefitScalarWhereInput;
  data: OutOfPocketBenefitUpdateManyMutationInput;
}

export interface OutOfPocketBenefitUpdateManyWithoutEligibilityRequestNestedInput {
  create?: OutOfPocketBenefitCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: OutOfPocketBenefitCreateOrConnectWithoutEligibilityRequestInput[] | null;
  upsert?: OutOfPocketBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  createMany?: OutOfPocketBenefitCreateManyEligibilityRequestInputEnvelope | null;
  set?: OutOfPocketBenefitWhereUniqueInput[] | null;
  disconnect?: OutOfPocketBenefitWhereUniqueInput[] | null;
  delete?: OutOfPocketBenefitWhereUniqueInput[] | null;
  connect?: OutOfPocketBenefitWhereUniqueInput[] | null;
  update?: OutOfPocketBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  updateMany?: OutOfPocketBenefitUpdateManyWithWhereWithoutEligibilityRequestInput[] | null;
  deleteMany?: OutOfPocketBenefitScalarWhereInput[] | null;
}

export interface OutOfPocketBenefitUpdateOneWithoutMaxCoverageBenefitsNestedInput {
  create?: OutOfPocketBenefitCreateWithoutMaxCoverageBenefitsInput | null;
  connectOrCreate?: OutOfPocketBenefitCreateOrConnectWithoutMaxCoverageBenefitsInput | null;
  upsert?: OutOfPocketBenefitUpsertWithoutMaxCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: OutOfPocketBenefitWhereUniqueInput | null;
  update?: OutOfPocketBenefitUpdateWithoutMaxCoverageBenefitsInput | null;
}

export interface OutOfPocketBenefitUpdateOneWithoutMaxEligibilityCoverageBenefitSnapshotNestedInput {
  create?: OutOfPocketBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: OutOfPocketBenefitCreateOrConnectWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  upsert?: OutOfPocketBenefitUpsertWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: OutOfPocketBenefitWhereUniqueInput | null;
  update?: OutOfPocketBenefitUpdateWithoutMaxEligibilityCoverageBenefitSnapshotInput | null;
}

export interface OutOfPocketBenefitUpdateOneWithoutRemainingCoverageBenefitsNestedInput {
  create?: OutOfPocketBenefitCreateWithoutRemainingCoverageBenefitsInput | null;
  connectOrCreate?: OutOfPocketBenefitCreateOrConnectWithoutRemainingCoverageBenefitsInput | null;
  upsert?: OutOfPocketBenefitUpsertWithoutRemainingCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: OutOfPocketBenefitWhereUniqueInput | null;
  update?: OutOfPocketBenefitUpdateWithoutRemainingCoverageBenefitsInput | null;
}

export interface OutOfPocketBenefitUpdateOneWithoutRemainingEligibilityCoverageBenefitSnapshotNestedInput {
  create?: OutOfPocketBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  connectOrCreate?: OutOfPocketBenefitCreateOrConnectWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  upsert?: OutOfPocketBenefitUpsertWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: OutOfPocketBenefitWhereUniqueInput | null;
  update?: OutOfPocketBenefitUpdateWithoutRemainingEligibilityCoverageBenefitSnapshotInput | null;
}

export interface OutOfPocketBenefitUpdateWithWhereUniqueWithoutEligibilityRequestInput {
  where: OutOfPocketBenefitWhereUniqueInput;
  data: OutOfPocketBenefitUpdateWithoutEligibilityRequestInput;
}

export interface OutOfPocketBenefitUpdateWithoutEligibilityRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OutOfPocketBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingOutOfPocketBenefitNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxOutOfPocketBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingOutOfPocketBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxOutOfPocketBenefitNestedInput | null;
}

export interface OutOfPocketBenefitUpdateWithoutMaxCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OutOfPocketBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutOutOfPocketBenefitsNestedInput | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingOutOfPocketBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingOutOfPocketBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxOutOfPocketBenefitNestedInput | null;
}

export interface OutOfPocketBenefitUpdateWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OutOfPocketBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutOutOfPocketBenefitsNestedInput | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingOutOfPocketBenefitNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxOutOfPocketBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingOutOfPocketBenefitNestedInput | null;
}

export interface OutOfPocketBenefitUpdateWithoutRemainingCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OutOfPocketBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutOutOfPocketBenefitsNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxOutOfPocketBenefitNestedInput | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutRemainingOutOfPocketBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxOutOfPocketBenefitNestedInput | null;
}

export interface OutOfPocketBenefitUpdateWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  max?: NullableIntFieldUpdateOperationsInput | null;
  remaining?: NullableIntFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: NullableStringFieldUpdateOperationsInput | null;
  inNetwork?: NullableEnumNetworkStatusFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  notes?: OutOfPocketBenefitUpdatenotesInput | null;
  validTo?: NullableDateTimeFieldUpdateOperationsInput | null;
  validFrom?: NullableDateTimeFieldUpdateOperationsInput | null;
  authRequired?: NullableEnumAuthorizationRequirementFieldUpdateOperationsInput | null;
  raw?: any | null;
  eligibilityRequest?: EligibilityRequestUpdateOneRequiredWithoutOutOfPocketBenefitsNestedInput | null;
  remainingCoverageBenefits?: CoverageBenefitUpdateManyWithoutRemainingOutOfPocketBenefitNestedInput | null;
  maxCoverageBenefits?: CoverageBenefitUpdateManyWithoutMaxOutOfPocketBenefitNestedInput | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutMaxOutOfPocketBenefitNestedInput | null;
}

export interface OutOfPocketBenefitUpdatenotesInput {
  set?: string[] | null;
  push?: string[] | null;
}

export interface OutOfPocketBenefitUpsertWithWhereUniqueWithoutEligibilityRequestInput {
  where: OutOfPocketBenefitWhereUniqueInput;
  update: OutOfPocketBenefitUpdateWithoutEligibilityRequestInput;
  create: OutOfPocketBenefitCreateWithoutEligibilityRequestInput;
}

export interface OutOfPocketBenefitUpsertWithoutMaxCoverageBenefitsInput {
  update: OutOfPocketBenefitUpdateWithoutMaxCoverageBenefitsInput;
  create: OutOfPocketBenefitCreateWithoutMaxCoverageBenefitsInput;
}

export interface OutOfPocketBenefitUpsertWithoutMaxEligibilityCoverageBenefitSnapshotInput {
  update: OutOfPocketBenefitUpdateWithoutMaxEligibilityCoverageBenefitSnapshotInput;
  create: OutOfPocketBenefitCreateWithoutMaxEligibilityCoverageBenefitSnapshotInput;
}

export interface OutOfPocketBenefitUpsertWithoutRemainingCoverageBenefitsInput {
  update: OutOfPocketBenefitUpdateWithoutRemainingCoverageBenefitsInput;
  create: OutOfPocketBenefitCreateWithoutRemainingCoverageBenefitsInput;
}

export interface OutOfPocketBenefitUpsertWithoutRemainingEligibilityCoverageBenefitSnapshotInput {
  update: OutOfPocketBenefitUpdateWithoutRemainingEligibilityCoverageBenefitSnapshotInput;
  create: OutOfPocketBenefitCreateWithoutRemainingEligibilityCoverageBenefitSnapshotInput;
}

export interface OutOfPocketBenefitWhereInput {
  AND?: OutOfPocketBenefitWhereInput[] | null;
  OR?: OutOfPocketBenefitWhereInput[] | null;
  NOT?: OutOfPocketBenefitWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  code?: StringFilter | null;
  max?: IntNullableFilter | null;
  remaining?: IntNullableFilter | null;
  coverageLevel?: StringNullableFilter | null;
  serviceType?: StringNullableFilter | null;
  inNetwork?: EnumNetworkStatusNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  notes?: StringNullableListFilter | null;
  validTo?: DateTimeNullableFilter | null;
  validFrom?: DateTimeNullableFilter | null;
  authRequired?: EnumAuthorizationRequirementNullableFilter | null;
  raw?: JsonFilter | null;
  eligibilityRequest?: EligibilityRequestRelationFilter | null;
  remainingCoverageBenefits?: CoverageBenefitListRelationFilter | null;
  maxCoverageBenefits?: CoverageBenefitListRelationFilter | null;
  remainingEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
  maxEligibilityCoverageBenefitSnapshot?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
}

export interface OutOfPocketBenefitWhereUniqueInput {
  id?: string | null;
}

export interface PatientCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  patientUserId?: string | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  organizationPatientGroupId?: string | null;
  accountNumber: string;
  supercededById?: string | null;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
}

export interface PatientCreateManyLocationInputEnvelope {
  data: PatientCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface PatientCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  locationId: string;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  patientUserId?: string | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  organizationPatientGroupId?: string | null;
  accountNumber: string;
  supercededById?: string | null;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
}

export interface PatientCreateManyOrganizationInputEnvelope {
  data: PatientCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface PatientCreateManyOrganizationPatientGroupInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  locationId: string;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  patientUserId?: string | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  supercededById?: string | null;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
}

export interface PatientCreateManyOrganizationPatientGroupInputEnvelope {
  data: PatientCreateManyOrganizationPatientGroupInput[];
  skipDuplicates?: boolean | null;
}

export interface PatientCreateManyPatientUserInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  locationId: string;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  organizationPatientGroupId?: string | null;
  accountNumber: string;
  supercededById?: string | null;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
}

export interface PatientCreateManyPatientUserInputEnvelope {
  data: PatientCreateManyPatientUserInput[];
  skipDuplicates?: boolean | null;
}

export interface PatientCreateManySupercededByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  locationId: string;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  patientUserId?: string | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  organizationPatientGroupId?: string | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
}

export interface PatientCreateManySupercededByInputEnvelope {
  data: PatientCreateManySupercededByInput[];
  skipDuplicates?: boolean | null;
}

export interface PatientCreateNestedManyWithoutLocationInput {
  create?: PatientCreateWithoutLocationInput[] | null;
  connectOrCreate?: PatientCreateOrConnectWithoutLocationInput[] | null;
  createMany?: PatientCreateManyLocationInputEnvelope | null;
  connect?: PatientWhereUniqueInput[] | null;
}

export interface PatientCreateNestedManyWithoutOrganizationInput {
  create?: PatientCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: PatientCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: PatientCreateManyOrganizationInputEnvelope | null;
  connect?: PatientWhereUniqueInput[] | null;
}

export interface PatientCreateNestedManyWithoutOrganizationPatientGroupInput {
  create?: PatientCreateWithoutOrganizationPatientGroupInput[] | null;
  connectOrCreate?: PatientCreateOrConnectWithoutOrganizationPatientGroupInput[] | null;
  createMany?: PatientCreateManyOrganizationPatientGroupInputEnvelope | null;
  connect?: PatientWhereUniqueInput[] | null;
}

export interface PatientCreateNestedManyWithoutPatientUserInput {
  create?: PatientCreateWithoutPatientUserInput[] | null;
  connectOrCreate?: PatientCreateOrConnectWithoutPatientUserInput[] | null;
  createMany?: PatientCreateManyPatientUserInputEnvelope | null;
  connect?: PatientWhereUniqueInput[] | null;
}

export interface PatientCreateNestedManyWithoutSupercededByInput {
  create?: PatientCreateWithoutSupercededByInput[] | null;
  connectOrCreate?: PatientCreateOrConnectWithoutSupercededByInput[] | null;
  createMany?: PatientCreateManySupercededByInputEnvelope | null;
  connect?: PatientWhereUniqueInput[] | null;
}

export interface PatientCreateNestedOneWithoutAccountsInput {
  create?: PatientCreateWithoutAccountsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutAccountsInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutCommunicationsInput {
  create?: PatientCreateWithoutCommunicationsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutCommunicationsInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutFeedbacksInput {
  create?: PatientCreateWithoutFeedbacksInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutFeedbacksInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutGuarantorAccountsInput {
  create?: PatientCreateWithoutGuarantorAccountsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutGuarantorAccountsInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutInsurancePoliciesInput {
  create?: PatientCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutInsurancePoliciesInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutIntegrationLinksInput {
  create?: PatientCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutNotesInput {
  create?: PatientCreateWithoutNotesInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutNotesInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutPatientLabelingsInput {
  create?: PatientCreateWithoutPatientLabelingsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutPatientLabelingsInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutPaymentIntentsInput {
  create?: PatientCreateWithoutPaymentIntentsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutPaymentIntentsInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutPaymentMethodsInput {
  create?: PatientCreateWithoutPaymentMethodsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutPaymentMethodsInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutPaymentRequestsInput {
  create?: PatientCreateWithoutPaymentRequestsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutPaymentRequestsInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutReminderWorkflowInput {
  create?: PatientCreateWithoutReminderWorkflowInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutReminderWorkflowInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateNestedOneWithoutSupercedesInput {
  create?: PatientCreateWithoutSupercedesInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutSupercedesInput | null;
  connect?: PatientWhereUniqueInput | null;
}

export interface PatientCreateOrConnectWithoutAccountsInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutAccountsInput;
}

export interface PatientCreateOrConnectWithoutCommunicationsInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutCommunicationsInput;
}

export interface PatientCreateOrConnectWithoutFeedbacksInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutFeedbacksInput;
}

export interface PatientCreateOrConnectWithoutGuarantorAccountsInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutGuarantorAccountsInput;
}

export interface PatientCreateOrConnectWithoutInsurancePoliciesInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutInsurancePoliciesInput;
}

export interface PatientCreateOrConnectWithoutIntegrationLinksInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutIntegrationLinksInput;
}

export interface PatientCreateOrConnectWithoutLocationInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutLocationInput;
}

export interface PatientCreateOrConnectWithoutNotesInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutNotesInput;
}

export interface PatientCreateOrConnectWithoutOrganizationInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutOrganizationInput;
}

export interface PatientCreateOrConnectWithoutOrganizationPatientGroupInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutOrganizationPatientGroupInput;
}

export interface PatientCreateOrConnectWithoutPatientLabelingsInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutPatientLabelingsInput;
}

export interface PatientCreateOrConnectWithoutPatientUserInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutPatientUserInput;
}

export interface PatientCreateOrConnectWithoutPaymentIntentsInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutPaymentIntentsInput;
}

export interface PatientCreateOrConnectWithoutPaymentMethodsInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutPaymentMethodsInput;
}

export interface PatientCreateOrConnectWithoutPaymentRequestsInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutPaymentRequestsInput;
}

export interface PatientCreateOrConnectWithoutReminderWorkflowInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutReminderWorkflowInput;
}

export interface PatientCreateOrConnectWithoutSupercededByInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutSupercededByInput;
}

export interface PatientCreateOrConnectWithoutSupercedesInput {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutSupercedesInput;
}

export interface PatientCreateWithoutAccountsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutCommunicationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutFeedbacksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutGuarantorAccountsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutInsurancePoliciesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutNotesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutOrganizationPatientGroupInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutPatientLabelingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutPatientUserInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutPaymentIntentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutPaymentMethodsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutPaymentRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutReminderWorkflowInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutSupercededByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercedes?: PatientCreateNestedManyWithoutSupercededByInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreateWithoutSupercedesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth?: any | null;
  birthSex?: BirthSex | null;
  medicalRecordNumber?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  emailInvalid?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  state?: string | null;
  country?: string | null;
  deletedAt?: any | null;
  stripeCustomerId?: string | null;
  communicationPreferences?: PatientCreatecommunicationPreferencesInput | null;
  autoPayEnabled?: boolean | null;
  visitAutoPayEnabled?: boolean | null;
  maxAutopayLimit?: number | null;
  estimateCommunicationEnrolled?: boolean | null;
  estimationPausedAt?: any | null;
  preVisitReminderPausedAt?: any | null;
  timeOfServiceAutoChargePausedAt?: any | null;
  accountNumber: string;
  emailCommunicationEnabled?: boolean | null;
  smsCommunicationEnabled?: boolean | null;
  twilioOptOut?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutPatientsInput;
  location: LocationCreateNestedOneWithoutPatientsInput;
  patientUser?: PatientUserCreateNestedOneWithoutPatientsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPatientInput | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientInput | null;
  communications?: CommunicationCreateNestedManyWithoutPatientInput | null;
  reminderWorkflow?: ReminderWorkflowCreateNestedOneWithoutPatientInput | null;
  accounts?: AccountCreateNestedManyWithoutPatientInput | null;
  guarantorAccounts?: AccountCreateNestedManyWithoutGuarantorInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientInput | null;
  paymentMethods?: PatientPaymentMethodCreateNestedManyWithoutPatientInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPatientInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPatientInput | null;
  organizationPatientGroup?: OrganizationPatientGroupCreateNestedOneWithoutPatientsInput | null;
  supercededBy?: PatientCreateNestedOneWithoutSupercedesInput | null;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientInput | null;
  notes?: NoteCreateNestedManyWithoutPatientInput | null;
}

export interface PatientCreatecommunicationPreferencesInput {
  set: CommunicationPreference[];
}

export interface PatientLabelCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  name: string;
}

export interface PatientLabelCreateManyLocationInputEnvelope {
  data: PatientLabelCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface PatientLabelCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  locationId: string;
  name: string;
}

export interface PatientLabelCreateManyOrganizationInputEnvelope {
  data: PatientLabelCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface PatientLabelCreateNestedManyWithoutLocationInput {
  create?: PatientLabelCreateWithoutLocationInput[] | null;
  connectOrCreate?: PatientLabelCreateOrConnectWithoutLocationInput[] | null;
  createMany?: PatientLabelCreateManyLocationInputEnvelope | null;
  connect?: PatientLabelWhereUniqueInput[] | null;
}

export interface PatientLabelCreateNestedManyWithoutOrganizationInput {
  create?: PatientLabelCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: PatientLabelCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: PatientLabelCreateManyOrganizationInputEnvelope | null;
  connect?: PatientLabelWhereUniqueInput[] | null;
}

export interface PatientLabelCreateNestedOneWithoutPatientLabelingsInput {
  create?: PatientLabelCreateWithoutPatientLabelingsInput | null;
  connectOrCreate?: PatientLabelCreateOrConnectWithoutPatientLabelingsInput | null;
  connect?: PatientLabelWhereUniqueInput | null;
}

export interface PatientLabelCreateOrConnectWithoutLocationInput {
  where: PatientLabelWhereUniqueInput;
  create: PatientLabelCreateWithoutLocationInput;
}

export interface PatientLabelCreateOrConnectWithoutOrganizationInput {
  where: PatientLabelWhereUniqueInput;
  create: PatientLabelCreateWithoutOrganizationInput;
}

export interface PatientLabelCreateOrConnectWithoutPatientLabelingsInput {
  where: PatientLabelWhereUniqueInput;
  create: PatientLabelCreateWithoutPatientLabelingsInput;
}

export interface PatientLabelCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  organization: OrganizationCreateNestedOneWithoutPatientLabelsInput;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientLabelInput | null;
}

export interface PatientLabelCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  location: LocationCreateNestedOneWithoutPatientLabelsInput;
  patientLabelings?: PatientLabelingCreateNestedManyWithoutPatientLabelInput | null;
}

export interface PatientLabelCreateWithoutPatientLabelingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  organization: OrganizationCreateNestedOneWithoutPatientLabelsInput;
  location: LocationCreateNestedOneWithoutPatientLabelsInput;
}

export interface PatientLabelListRelationFilter {
  every?: PatientLabelWhereInput | null;
  some?: PatientLabelWhereInput | null;
  none?: PatientLabelWhereInput | null;
}

export interface PatientLabelLocationIdNameCompoundUniqueInput {
  locationId: string;
  name: string;
}

export interface PatientLabelOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PatientLabelRelationFilter {
  is?: PatientLabelWhereInput | null;
  isNot?: PatientLabelWhereInput | null;
}

export interface PatientLabelScalarWhereInput {
  AND?: PatientLabelScalarWhereInput[] | null;
  OR?: PatientLabelScalarWhereInput[] | null;
  NOT?: PatientLabelScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
}

export interface PatientLabelUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
}

export interface PatientLabelUpdateManyWithWhereWithoutLocationInput {
  where: PatientLabelScalarWhereInput;
  data: PatientLabelUpdateManyMutationInput;
}

export interface PatientLabelUpdateManyWithWhereWithoutOrganizationInput {
  where: PatientLabelScalarWhereInput;
  data: PatientLabelUpdateManyMutationInput;
}

export interface PatientLabelUpdateManyWithoutLocationNestedInput {
  create?: PatientLabelCreateWithoutLocationInput[] | null;
  connectOrCreate?: PatientLabelCreateOrConnectWithoutLocationInput[] | null;
  upsert?: PatientLabelUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: PatientLabelCreateManyLocationInputEnvelope | null;
  set?: PatientLabelWhereUniqueInput[] | null;
  disconnect?: PatientLabelWhereUniqueInput[] | null;
  delete?: PatientLabelWhereUniqueInput[] | null;
  connect?: PatientLabelWhereUniqueInput[] | null;
  update?: PatientLabelUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: PatientLabelUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: PatientLabelScalarWhereInput[] | null;
}

export interface PatientLabelUpdateManyWithoutOrganizationNestedInput {
  create?: PatientLabelCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: PatientLabelCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: PatientLabelUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: PatientLabelCreateManyOrganizationInputEnvelope | null;
  set?: PatientLabelWhereUniqueInput[] | null;
  disconnect?: PatientLabelWhereUniqueInput[] | null;
  delete?: PatientLabelWhereUniqueInput[] | null;
  connect?: PatientLabelWhereUniqueInput[] | null;
  update?: PatientLabelUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: PatientLabelUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: PatientLabelScalarWhereInput[] | null;
}

export interface PatientLabelUpdateOneRequiredWithoutPatientLabelingsNestedInput {
  create?: PatientLabelCreateWithoutPatientLabelingsInput | null;
  connectOrCreate?: PatientLabelCreateOrConnectWithoutPatientLabelingsInput | null;
  upsert?: PatientLabelUpsertWithoutPatientLabelingsInput | null;
  connect?: PatientLabelWhereUniqueInput | null;
  update?: PatientLabelUpdateWithoutPatientLabelingsInput | null;
}

export interface PatientLabelUpdateWithWhereUniqueWithoutLocationInput {
  where: PatientLabelWhereUniqueInput;
  data: PatientLabelUpdateWithoutLocationInput;
}

export interface PatientLabelUpdateWithWhereUniqueWithoutOrganizationInput {
  where: PatientLabelWhereUniqueInput;
  data: PatientLabelUpdateWithoutOrganizationInput;
}

export interface PatientLabelUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientLabelsNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientLabelNestedInput | null;
}

export interface PatientLabelUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientLabelsNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientLabelNestedInput | null;
}

export interface PatientLabelUpdateWithoutPatientLabelingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientLabelsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientLabelsNestedInput | null;
}

export interface PatientLabelUpsertWithWhereUniqueWithoutLocationInput {
  where: PatientLabelWhereUniqueInput;
  update: PatientLabelUpdateWithoutLocationInput;
  create: PatientLabelCreateWithoutLocationInput;
}

export interface PatientLabelUpsertWithWhereUniqueWithoutOrganizationInput {
  where: PatientLabelWhereUniqueInput;
  update: PatientLabelUpdateWithoutOrganizationInput;
  create: PatientLabelCreateWithoutOrganizationInput;
}

export interface PatientLabelUpsertWithoutPatientLabelingsInput {
  update: PatientLabelUpdateWithoutPatientLabelingsInput;
  create: PatientLabelCreateWithoutPatientLabelingsInput;
}

export interface PatientLabelWhereInput {
  AND?: PatientLabelWhereInput[] | null;
  OR?: PatientLabelWhereInput[] | null;
  NOT?: PatientLabelWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
  organization?: OrganizationRelationFilter | null;
  location?: LocationRelationFilter | null;
  patientLabelings?: PatientLabelingListRelationFilter | null;
}

export interface PatientLabelWhereUniqueInput {
  id?: string | null;
  locationId_name?: PatientLabelLocationIdNameCompoundUniqueInput | null;
}

export interface PatientLabelingCreateManyPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientLabelId: string;
}

export interface PatientLabelingCreateManyPatientInputEnvelope {
  data: PatientLabelingCreateManyPatientInput[];
  skipDuplicates?: boolean | null;
}

export interface PatientLabelingCreateManyPatientLabelInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientId: string;
}

export interface PatientLabelingCreateManyPatientLabelInputEnvelope {
  data: PatientLabelingCreateManyPatientLabelInput[];
  skipDuplicates?: boolean | null;
}

export interface PatientLabelingCreateNestedManyWithoutPatientInput {
  create?: PatientLabelingCreateWithoutPatientInput[] | null;
  connectOrCreate?: PatientLabelingCreateOrConnectWithoutPatientInput[] | null;
  createMany?: PatientLabelingCreateManyPatientInputEnvelope | null;
  connect?: PatientLabelingWhereUniqueInput[] | null;
}

export interface PatientLabelingCreateNestedManyWithoutPatientLabelInput {
  create?: PatientLabelingCreateWithoutPatientLabelInput[] | null;
  connectOrCreate?: PatientLabelingCreateOrConnectWithoutPatientLabelInput[] | null;
  createMany?: PatientLabelingCreateManyPatientLabelInputEnvelope | null;
  connect?: PatientLabelingWhereUniqueInput[] | null;
}

export interface PatientLabelingCreateOrConnectWithoutPatientInput {
  where: PatientLabelingWhereUniqueInput;
  create: PatientLabelingCreateWithoutPatientInput;
}

export interface PatientLabelingCreateOrConnectWithoutPatientLabelInput {
  where: PatientLabelingWhereUniqueInput;
  create: PatientLabelingCreateWithoutPatientLabelInput;
}

export interface PatientLabelingCreateWithoutPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientLabel: PatientLabelCreateNestedOneWithoutPatientLabelingsInput;
}

export interface PatientLabelingCreateWithoutPatientLabelInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patient: PatientCreateNestedOneWithoutPatientLabelingsInput;
}

export interface PatientLabelingListRelationFilter {
  every?: PatientLabelingWhereInput | null;
  some?: PatientLabelingWhereInput | null;
  none?: PatientLabelingWhereInput | null;
}

export interface PatientLabelingOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PatientLabelingPatientIdPatientLabelIdCompoundUniqueInput {
  patientId: string;
  patientLabelId: string;
}

export interface PatientLabelingScalarWhereInput {
  AND?: PatientLabelingScalarWhereInput[] | null;
  OR?: PatientLabelingScalarWhereInput[] | null;
  NOT?: PatientLabelingScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  patientId?: UuidFilter | null;
  patientLabelId?: UuidFilter | null;
}

export interface PatientLabelingUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
}

export interface PatientLabelingUpdateManyWithWhereWithoutPatientInput {
  where: PatientLabelingScalarWhereInput;
  data: PatientLabelingUpdateManyMutationInput;
}

export interface PatientLabelingUpdateManyWithWhereWithoutPatientLabelInput {
  where: PatientLabelingScalarWhereInput;
  data: PatientLabelingUpdateManyMutationInput;
}

export interface PatientLabelingUpdateManyWithoutPatientLabelNestedInput {
  create?: PatientLabelingCreateWithoutPatientLabelInput[] | null;
  connectOrCreate?: PatientLabelingCreateOrConnectWithoutPatientLabelInput[] | null;
  upsert?: PatientLabelingUpsertWithWhereUniqueWithoutPatientLabelInput[] | null;
  createMany?: PatientLabelingCreateManyPatientLabelInputEnvelope | null;
  set?: PatientLabelingWhereUniqueInput[] | null;
  disconnect?: PatientLabelingWhereUniqueInput[] | null;
  delete?: PatientLabelingWhereUniqueInput[] | null;
  connect?: PatientLabelingWhereUniqueInput[] | null;
  update?: PatientLabelingUpdateWithWhereUniqueWithoutPatientLabelInput[] | null;
  updateMany?: PatientLabelingUpdateManyWithWhereWithoutPatientLabelInput[] | null;
  deleteMany?: PatientLabelingScalarWhereInput[] | null;
}

export interface PatientLabelingUpdateManyWithoutPatientNestedInput {
  create?: PatientLabelingCreateWithoutPatientInput[] | null;
  connectOrCreate?: PatientLabelingCreateOrConnectWithoutPatientInput[] | null;
  upsert?: PatientLabelingUpsertWithWhereUniqueWithoutPatientInput[] | null;
  createMany?: PatientLabelingCreateManyPatientInputEnvelope | null;
  set?: PatientLabelingWhereUniqueInput[] | null;
  disconnect?: PatientLabelingWhereUniqueInput[] | null;
  delete?: PatientLabelingWhereUniqueInput[] | null;
  connect?: PatientLabelingWhereUniqueInput[] | null;
  update?: PatientLabelingUpdateWithWhereUniqueWithoutPatientInput[] | null;
  updateMany?: PatientLabelingUpdateManyWithWhereWithoutPatientInput[] | null;
  deleteMany?: PatientLabelingScalarWhereInput[] | null;
}

export interface PatientLabelingUpdateWithWhereUniqueWithoutPatientInput {
  where: PatientLabelingWhereUniqueInput;
  data: PatientLabelingUpdateWithoutPatientInput;
}

export interface PatientLabelingUpdateWithWhereUniqueWithoutPatientLabelInput {
  where: PatientLabelingWhereUniqueInput;
  data: PatientLabelingUpdateWithoutPatientLabelInput;
}

export interface PatientLabelingUpdateWithoutPatientInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientLabel?: PatientLabelUpdateOneRequiredWithoutPatientLabelingsNestedInput | null;
}

export interface PatientLabelingUpdateWithoutPatientLabelInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPatientLabelingsNestedInput | null;
}

export interface PatientLabelingUpsertWithWhereUniqueWithoutPatientInput {
  where: PatientLabelingWhereUniqueInput;
  update: PatientLabelingUpdateWithoutPatientInput;
  create: PatientLabelingCreateWithoutPatientInput;
}

export interface PatientLabelingUpsertWithWhereUniqueWithoutPatientLabelInput {
  where: PatientLabelingWhereUniqueInput;
  update: PatientLabelingUpdateWithoutPatientLabelInput;
  create: PatientLabelingCreateWithoutPatientLabelInput;
}

export interface PatientLabelingWhereInput {
  AND?: PatientLabelingWhereInput[] | null;
  OR?: PatientLabelingWhereInput[] | null;
  NOT?: PatientLabelingWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  patientId?: UuidFilter | null;
  patientLabelId?: UuidFilter | null;
  patient?: PatientRelationFilter | null;
  patientLabel?: PatientLabelRelationFilter | null;
}

export interface PatientLabelingWhereUniqueInput {
  id?: string | null;
  patientId_patientLabelId?: PatientLabelingPatientIdPatientLabelIdCompoundUniqueInput | null;
}

export interface PatientListRelationFilter {
  every?: PatientWhereInput | null;
  some?: PatientWhereInput | null;
  none?: PatientWhereInput | null;
}

export interface PatientMedicalRecordNumberOrganizationIdCompoundUniqueInput {
  medicalRecordNumber: string;
  organizationId: string;
}

export interface PatientOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PatientOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  organizationId?: SortOrder | null;
  locationId?: SortOrder | null;
  firstName?: SortOrder | null;
  lastName?: SortOrder | null;
  preferredName?: SortOrderInput | null;
  dateOfBirth?: SortOrderInput | null;
  birthSex?: SortOrderInput | null;
  medicalRecordNumber?: SortOrderInput | null;
  cellPhone?: SortOrderInput | null;
  email?: SortOrderInput | null;
  emailInvalid?: SortOrderInput | null;
  address1?: SortOrderInput | null;
  address2?: SortOrderInput | null;
  city?: SortOrderInput | null;
  postalCode?: SortOrderInput | null;
  state?: SortOrderInput | null;
  country?: SortOrderInput | null;
  deletedAt?: SortOrderInput | null;
  stripeCustomerId?: SortOrderInput | null;
  communicationPreferences?: SortOrder | null;
  autoPayEnabled?: SortOrder | null;
  visitAutoPayEnabled?: SortOrder | null;
  maxAutopayLimit?: SortOrderInput | null;
  estimateCommunicationEnrolled?: SortOrder | null;
  patientUserId?: SortOrderInput | null;
  estimationPausedAt?: SortOrderInput | null;
  preVisitReminderPausedAt?: SortOrderInput | null;
  timeOfServiceAutoChargePausedAt?: SortOrderInput | null;
  organizationPatientGroupId?: SortOrderInput | null;
  accountNumber?: SortOrder | null;
  supercededById?: SortOrderInput | null;
  emailCommunicationEnabled?: SortOrderInput | null;
  smsCommunicationEnabled?: SortOrderInput | null;
  twilioOptOut?: SortOrderInput | null;
  organization?: OrganizationOrderByWithRelationInput | null;
  location?: LocationOrderByWithRelationInput | null;
  patientUser?: PatientUserOrderByWithRelationInput | null;
  integrationLinks?: IntegrationLinkOrderByRelationAggregateInput | null;
  paymentIntents?: PaymentIntentOrderByRelationAggregateInput | null;
  communications?: CommunicationOrderByRelationAggregateInput | null;
  reminderWorkflow?: ReminderWorkflowOrderByWithRelationInput | null;
  accounts?: AccountOrderByRelationAggregateInput | null;
  guarantorAccounts?: AccountOrderByRelationAggregateInput | null;
  feedbacks?: FeedbackOrderByRelationAggregateInput | null;
  paymentMethods?: PatientPaymentMethodOrderByRelationAggregateInput | null;
  paymentRequests?: PaymentRequestOrderByRelationAggregateInput | null;
  insurancePolicies?: InsurancePolicyOrderByRelationAggregateInput | null;
  organizationPatientGroup?: OrganizationPatientGroupOrderByWithRelationInput | null;
  supercededBy?: PatientOrderByWithRelationInput | null;
  supercedes?: PatientOrderByRelationAggregateInput | null;
  patientLabelings?: PatientLabelingOrderByRelationAggregateInput | null;
  notes?: NoteOrderByRelationAggregateInput | null;
}

export interface PatientPaymentMethodCreateManyPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  stripePaymentMethodId: string;
  detatchedAt?: any | null;
  default?: boolean | null;
  type: string;
  cardBrand?: string | null;
  walletType?: string | null;
  lastFour?: string | null;
  expirationMonth?: number | null;
  expirationYear?: number | null;
  funding?: string | null;
}

export interface PatientPaymentMethodCreateManyPatientInputEnvelope {
  data: PatientPaymentMethodCreateManyPatientInput[];
  skipDuplicates?: boolean | null;
}

export interface PatientPaymentMethodCreateNestedManyWithoutPatientInput {
  create?: PatientPaymentMethodCreateWithoutPatientInput[] | null;
  connectOrCreate?: PatientPaymentMethodCreateOrConnectWithoutPatientInput[] | null;
  createMany?: PatientPaymentMethodCreateManyPatientInputEnvelope | null;
  connect?: PatientPaymentMethodWhereUniqueInput[] | null;
}

export interface PatientPaymentMethodCreateNestedOneWithoutPaymentIntentsInput {
  create?: PatientPaymentMethodCreateWithoutPaymentIntentsInput | null;
  connectOrCreate?: PatientPaymentMethodCreateOrConnectWithoutPaymentIntentsInput | null;
  connect?: PatientPaymentMethodWhereUniqueInput | null;
}

export interface PatientPaymentMethodCreateOrConnectWithoutPatientInput {
  where: PatientPaymentMethodWhereUniqueInput;
  create: PatientPaymentMethodCreateWithoutPatientInput;
}

export interface PatientPaymentMethodCreateOrConnectWithoutPaymentIntentsInput {
  where: PatientPaymentMethodWhereUniqueInput;
  create: PatientPaymentMethodCreateWithoutPaymentIntentsInput;
}

export interface PatientPaymentMethodCreateWithoutPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  stripePaymentMethodId: string;
  detatchedAt?: any | null;
  default?: boolean | null;
  type: string;
  cardBrand?: string | null;
  walletType?: string | null;
  lastFour?: string | null;
  expirationMonth?: number | null;
  expirationYear?: number | null;
  funding?: string | null;
  paymentIntents?: PaymentIntentCreateNestedManyWithoutPatientPaymentMethodInput | null;
}

export interface PatientPaymentMethodCreateWithoutPaymentIntentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  stripePaymentMethodId: string;
  detatchedAt?: any | null;
  default?: boolean | null;
  type: string;
  cardBrand?: string | null;
  walletType?: string | null;
  lastFour?: string | null;
  expirationMonth?: number | null;
  expirationYear?: number | null;
  funding?: string | null;
  patient: PatientCreateNestedOneWithoutPaymentMethodsInput;
}

export interface PatientPaymentMethodListRelationFilter {
  every?: PatientPaymentMethodWhereInput | null;
  some?: PatientPaymentMethodWhereInput | null;
  none?: PatientPaymentMethodWhereInput | null;
}

export interface PatientPaymentMethodOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PatientPaymentMethodRelationFilter {
  is?: PatientPaymentMethodWhereInput | null;
  isNot?: PatientPaymentMethodWhereInput | null;
}

export interface PatientPaymentMethodScalarWhereInput {
  AND?: PatientPaymentMethodScalarWhereInput[] | null;
  OR?: PatientPaymentMethodScalarWhereInput[] | null;
  NOT?: PatientPaymentMethodScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  stripePaymentMethodId?: StringFilter | null;
  detatchedAt?: DateTimeNullableFilter | null;
  default?: BoolFilter | null;
  type?: StringFilter | null;
  cardBrand?: StringNullableFilter | null;
  walletType?: StringNullableFilter | null;
  lastFour?: StringNullableFilter | null;
  expirationMonth?: IntNullableFilter | null;
  expirationYear?: IntNullableFilter | null;
  funding?: StringNullableFilter | null;
  patientId?: UuidFilter | null;
}

export interface PatientPaymentMethodUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  stripePaymentMethodId?: StringFieldUpdateOperationsInput | null;
  detatchedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
  type?: StringFieldUpdateOperationsInput | null;
  cardBrand?: NullableStringFieldUpdateOperationsInput | null;
  walletType?: NullableStringFieldUpdateOperationsInput | null;
  lastFour?: NullableStringFieldUpdateOperationsInput | null;
  expirationMonth?: NullableIntFieldUpdateOperationsInput | null;
  expirationYear?: NullableIntFieldUpdateOperationsInput | null;
  funding?: NullableStringFieldUpdateOperationsInput | null;
}

export interface PatientPaymentMethodUpdateManyWithWhereWithoutPatientInput {
  where: PatientPaymentMethodScalarWhereInput;
  data: PatientPaymentMethodUpdateManyMutationInput;
}

export interface PatientPaymentMethodUpdateManyWithoutPatientNestedInput {
  create?: PatientPaymentMethodCreateWithoutPatientInput[] | null;
  connectOrCreate?: PatientPaymentMethodCreateOrConnectWithoutPatientInput[] | null;
  upsert?: PatientPaymentMethodUpsertWithWhereUniqueWithoutPatientInput[] | null;
  createMany?: PatientPaymentMethodCreateManyPatientInputEnvelope | null;
  set?: PatientPaymentMethodWhereUniqueInput[] | null;
  disconnect?: PatientPaymentMethodWhereUniqueInput[] | null;
  delete?: PatientPaymentMethodWhereUniqueInput[] | null;
  connect?: PatientPaymentMethodWhereUniqueInput[] | null;
  update?: PatientPaymentMethodUpdateWithWhereUniqueWithoutPatientInput[] | null;
  updateMany?: PatientPaymentMethodUpdateManyWithWhereWithoutPatientInput[] | null;
  deleteMany?: PatientPaymentMethodScalarWhereInput[] | null;
}

export interface PatientPaymentMethodUpdateOneWithoutPaymentIntentsNestedInput {
  create?: PatientPaymentMethodCreateWithoutPaymentIntentsInput | null;
  connectOrCreate?: PatientPaymentMethodCreateOrConnectWithoutPaymentIntentsInput | null;
  upsert?: PatientPaymentMethodUpsertWithoutPaymentIntentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PatientPaymentMethodWhereUniqueInput | null;
  update?: PatientPaymentMethodUpdateWithoutPaymentIntentsInput | null;
}

export interface PatientPaymentMethodUpdateWithWhereUniqueWithoutPatientInput {
  where: PatientPaymentMethodWhereUniqueInput;
  data: PatientPaymentMethodUpdateWithoutPatientInput;
}

export interface PatientPaymentMethodUpdateWithoutPatientInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  stripePaymentMethodId?: StringFieldUpdateOperationsInput | null;
  detatchedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
  type?: StringFieldUpdateOperationsInput | null;
  cardBrand?: NullableStringFieldUpdateOperationsInput | null;
  walletType?: NullableStringFieldUpdateOperationsInput | null;
  lastFour?: NullableStringFieldUpdateOperationsInput | null;
  expirationMonth?: NullableIntFieldUpdateOperationsInput | null;
  expirationYear?: NullableIntFieldUpdateOperationsInput | null;
  funding?: NullableStringFieldUpdateOperationsInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientPaymentMethodNestedInput | null;
}

export interface PatientPaymentMethodUpdateWithoutPaymentIntentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  stripePaymentMethodId?: StringFieldUpdateOperationsInput | null;
  detatchedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
  type?: StringFieldUpdateOperationsInput | null;
  cardBrand?: NullableStringFieldUpdateOperationsInput | null;
  walletType?: NullableStringFieldUpdateOperationsInput | null;
  lastFour?: NullableStringFieldUpdateOperationsInput | null;
  expirationMonth?: NullableIntFieldUpdateOperationsInput | null;
  expirationYear?: NullableIntFieldUpdateOperationsInput | null;
  funding?: NullableStringFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentMethodsNestedInput | null;
}

export interface PatientPaymentMethodUpsertWithWhereUniqueWithoutPatientInput {
  where: PatientPaymentMethodWhereUniqueInput;
  update: PatientPaymentMethodUpdateWithoutPatientInput;
  create: PatientPaymentMethodCreateWithoutPatientInput;
}

export interface PatientPaymentMethodUpsertWithoutPaymentIntentsInput {
  update: PatientPaymentMethodUpdateWithoutPaymentIntentsInput;
  create: PatientPaymentMethodCreateWithoutPaymentIntentsInput;
}

export interface PatientPaymentMethodWhereInput {
  AND?: PatientPaymentMethodWhereInput[] | null;
  OR?: PatientPaymentMethodWhereInput[] | null;
  NOT?: PatientPaymentMethodWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  stripePaymentMethodId?: StringFilter | null;
  detatchedAt?: DateTimeNullableFilter | null;
  default?: BoolFilter | null;
  type?: StringFilter | null;
  cardBrand?: StringNullableFilter | null;
  walletType?: StringNullableFilter | null;
  lastFour?: StringNullableFilter | null;
  expirationMonth?: IntNullableFilter | null;
  expirationYear?: IntNullableFilter | null;
  funding?: StringNullableFilter | null;
  patientId?: UuidFilter | null;
  patient?: PatientRelationFilter | null;
  paymentIntents?: PaymentIntentListRelationFilter | null;
}

export interface PatientPaymentMethodWhereUniqueInput {
  id?: string | null;
  stripePaymentMethodId?: string | null;
}

export interface PatientPaymentRequest {
  patientId: string;
  method: string;
  printEnabled?: boolean | null;
}

export interface PatientRelationFilter {
  is?: PatientWhereInput | null;
  isNot?: PatientWhereInput | null;
}

export interface PatientScalarWhereInput {
  AND?: PatientScalarWhereInput[] | null;
  OR?: PatientScalarWhereInput[] | null;
  NOT?: PatientScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  firstName?: StringFilter | null;
  lastName?: StringFilter | null;
  preferredName?: StringNullableFilter | null;
  dateOfBirth?: DateTimeNullableFilter | null;
  birthSex?: EnumBirthSexNullableFilter | null;
  medicalRecordNumber?: StringNullableFilter | null;
  cellPhone?: StringNullableFilter | null;
  email?: StringNullableFilter | null;
  emailInvalid?: BoolNullableFilter | null;
  address1?: StringNullableFilter | null;
  address2?: StringNullableFilter | null;
  city?: StringNullableFilter | null;
  postalCode?: StringNullableFilter | null;
  state?: StringNullableFilter | null;
  country?: StringNullableFilter | null;
  deletedAt?: DateTimeNullableFilter | null;
  stripeCustomerId?: StringNullableFilter | null;
  communicationPreferences?: EnumCommunicationPreferenceNullableListFilter | null;
  autoPayEnabled?: BoolFilter | null;
  visitAutoPayEnabled?: BoolFilter | null;
  maxAutopayLimit?: IntNullableFilter | null;
  estimateCommunicationEnrolled?: BoolFilter | null;
  patientUserId?: UuidNullableFilter | null;
  estimationPausedAt?: DateTimeNullableFilter | null;
  preVisitReminderPausedAt?: DateTimeNullableFilter | null;
  timeOfServiceAutoChargePausedAt?: DateTimeNullableFilter | null;
  organizationPatientGroupId?: UuidNullableFilter | null;
  accountNumber?: StringFilter | null;
  supercededById?: UuidNullableFilter | null;
  emailCommunicationEnabled?: BoolNullableFilter | null;
  smsCommunicationEnabled?: BoolNullableFilter | null;
  twilioOptOut?: BoolNullableFilter | null;
}

export interface PatientUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
}

export interface PatientUpdateManyWithWhereWithoutLocationInput {
  where: PatientScalarWhereInput;
  data: PatientUpdateManyMutationInput;
}

export interface PatientUpdateManyWithWhereWithoutOrganizationInput {
  where: PatientScalarWhereInput;
  data: PatientUpdateManyMutationInput;
}

export interface PatientUpdateManyWithWhereWithoutOrganizationPatientGroupInput {
  where: PatientScalarWhereInput;
  data: PatientUpdateManyMutationInput;
}

export interface PatientUpdateManyWithWhereWithoutPatientUserInput {
  where: PatientScalarWhereInput;
  data: PatientUpdateManyMutationInput;
}

export interface PatientUpdateManyWithWhereWithoutSupercededByInput {
  where: PatientScalarWhereInput;
  data: PatientUpdateManyMutationInput;
}

export interface PatientUpdateManyWithoutLocationNestedInput {
  create?: PatientCreateWithoutLocationInput[] | null;
  connectOrCreate?: PatientCreateOrConnectWithoutLocationInput[] | null;
  upsert?: PatientUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: PatientCreateManyLocationInputEnvelope | null;
  set?: PatientWhereUniqueInput[] | null;
  disconnect?: PatientWhereUniqueInput[] | null;
  delete?: PatientWhereUniqueInput[] | null;
  connect?: PatientWhereUniqueInput[] | null;
  update?: PatientUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: PatientUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: PatientScalarWhereInput[] | null;
}

export interface PatientUpdateManyWithoutOrganizationNestedInput {
  create?: PatientCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: PatientCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: PatientUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: PatientCreateManyOrganizationInputEnvelope | null;
  set?: PatientWhereUniqueInput[] | null;
  disconnect?: PatientWhereUniqueInput[] | null;
  delete?: PatientWhereUniqueInput[] | null;
  connect?: PatientWhereUniqueInput[] | null;
  update?: PatientUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: PatientUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: PatientScalarWhereInput[] | null;
}

export interface PatientUpdateManyWithoutOrganizationPatientGroupNestedInput {
  create?: PatientCreateWithoutOrganizationPatientGroupInput[] | null;
  connectOrCreate?: PatientCreateOrConnectWithoutOrganizationPatientGroupInput[] | null;
  upsert?: PatientUpsertWithWhereUniqueWithoutOrganizationPatientGroupInput[] | null;
  createMany?: PatientCreateManyOrganizationPatientGroupInputEnvelope | null;
  set?: PatientWhereUniqueInput[] | null;
  disconnect?: PatientWhereUniqueInput[] | null;
  delete?: PatientWhereUniqueInput[] | null;
  connect?: PatientWhereUniqueInput[] | null;
  update?: PatientUpdateWithWhereUniqueWithoutOrganizationPatientGroupInput[] | null;
  updateMany?: PatientUpdateManyWithWhereWithoutOrganizationPatientGroupInput[] | null;
  deleteMany?: PatientScalarWhereInput[] | null;
}

export interface PatientUpdateManyWithoutPatientUserNestedInput {
  create?: PatientCreateWithoutPatientUserInput[] | null;
  connectOrCreate?: PatientCreateOrConnectWithoutPatientUserInput[] | null;
  upsert?: PatientUpsertWithWhereUniqueWithoutPatientUserInput[] | null;
  createMany?: PatientCreateManyPatientUserInputEnvelope | null;
  set?: PatientWhereUniqueInput[] | null;
  disconnect?: PatientWhereUniqueInput[] | null;
  delete?: PatientWhereUniqueInput[] | null;
  connect?: PatientWhereUniqueInput[] | null;
  update?: PatientUpdateWithWhereUniqueWithoutPatientUserInput[] | null;
  updateMany?: PatientUpdateManyWithWhereWithoutPatientUserInput[] | null;
  deleteMany?: PatientScalarWhereInput[] | null;
}

export interface PatientUpdateManyWithoutSupercededByNestedInput {
  create?: PatientCreateWithoutSupercededByInput[] | null;
  connectOrCreate?: PatientCreateOrConnectWithoutSupercededByInput[] | null;
  upsert?: PatientUpsertWithWhereUniqueWithoutSupercededByInput[] | null;
  createMany?: PatientCreateManySupercededByInputEnvelope | null;
  set?: PatientWhereUniqueInput[] | null;
  disconnect?: PatientWhereUniqueInput[] | null;
  delete?: PatientWhereUniqueInput[] | null;
  connect?: PatientWhereUniqueInput[] | null;
  update?: PatientUpdateWithWhereUniqueWithoutSupercededByInput[] | null;
  updateMany?: PatientUpdateManyWithWhereWithoutSupercededByInput[] | null;
  deleteMany?: PatientScalarWhereInput[] | null;
}

export interface PatientUpdateOneRequiredWithoutAccountsNestedInput {
  create?: PatientCreateWithoutAccountsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutAccountsInput | null;
  upsert?: PatientUpsertWithoutAccountsInput | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutAccountsInput | null;
}

export interface PatientUpdateOneRequiredWithoutInsurancePoliciesNestedInput {
  create?: PatientCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutInsurancePoliciesInput | null;
  upsert?: PatientUpsertWithoutInsurancePoliciesInput | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutInsurancePoliciesInput | null;
}

export interface PatientUpdateOneRequiredWithoutNotesNestedInput {
  create?: PatientCreateWithoutNotesInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutNotesInput | null;
  upsert?: PatientUpsertWithoutNotesInput | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutNotesInput | null;
}

export interface PatientUpdateOneRequiredWithoutPatientLabelingsNestedInput {
  create?: PatientCreateWithoutPatientLabelingsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutPatientLabelingsInput | null;
  upsert?: PatientUpsertWithoutPatientLabelingsInput | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutPatientLabelingsInput | null;
}

export interface PatientUpdateOneRequiredWithoutPaymentIntentsNestedInput {
  create?: PatientCreateWithoutPaymentIntentsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutPaymentIntentsInput | null;
  upsert?: PatientUpsertWithoutPaymentIntentsInput | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutPaymentIntentsInput | null;
}

export interface PatientUpdateOneRequiredWithoutPaymentMethodsNestedInput {
  create?: PatientCreateWithoutPaymentMethodsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutPaymentMethodsInput | null;
  upsert?: PatientUpsertWithoutPaymentMethodsInput | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutPaymentMethodsInput | null;
}

export interface PatientUpdateOneRequiredWithoutPaymentRequestsNestedInput {
  create?: PatientCreateWithoutPaymentRequestsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutPaymentRequestsInput | null;
  upsert?: PatientUpsertWithoutPaymentRequestsInput | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutPaymentRequestsInput | null;
}

export interface PatientUpdateOneRequiredWithoutReminderWorkflowNestedInput {
  create?: PatientCreateWithoutReminderWorkflowInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutReminderWorkflowInput | null;
  upsert?: PatientUpsertWithoutReminderWorkflowInput | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutReminderWorkflowInput | null;
}

export interface PatientUpdateOneWithoutCommunicationsNestedInput {
  create?: PatientCreateWithoutCommunicationsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutCommunicationsInput | null;
  upsert?: PatientUpsertWithoutCommunicationsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutCommunicationsInput | null;
}

export interface PatientUpdateOneWithoutFeedbacksNestedInput {
  create?: PatientCreateWithoutFeedbacksInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutFeedbacksInput | null;
  upsert?: PatientUpsertWithoutFeedbacksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutFeedbacksInput | null;
}

export interface PatientUpdateOneWithoutGuarantorAccountsNestedInput {
  create?: PatientCreateWithoutGuarantorAccountsInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutGuarantorAccountsInput | null;
  upsert?: PatientUpsertWithoutGuarantorAccountsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutGuarantorAccountsInput | null;
}

export interface PatientUpdateOneWithoutIntegrationLinksNestedInput {
  create?: PatientCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: PatientUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutIntegrationLinksInput | null;
}

export interface PatientUpdateOneWithoutSupercedesNestedInput {
  create?: PatientCreateWithoutSupercedesInput | null;
  connectOrCreate?: PatientCreateOrConnectWithoutSupercedesInput | null;
  upsert?: PatientUpsertWithoutSupercedesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PatientWhereUniqueInput | null;
  update?: PatientUpdateWithoutSupercedesInput | null;
}

export interface PatientUpdateWithWhereUniqueWithoutLocationInput {
  where: PatientWhereUniqueInput;
  data: PatientUpdateWithoutLocationInput;
}

export interface PatientUpdateWithWhereUniqueWithoutOrganizationInput {
  where: PatientWhereUniqueInput;
  data: PatientUpdateWithoutOrganizationInput;
}

export interface PatientUpdateWithWhereUniqueWithoutOrganizationPatientGroupInput {
  where: PatientWhereUniqueInput;
  data: PatientUpdateWithoutOrganizationPatientGroupInput;
}

export interface PatientUpdateWithWhereUniqueWithoutPatientUserInput {
  where: PatientWhereUniqueInput;
  data: PatientUpdateWithoutPatientUserInput;
}

export interface PatientUpdateWithWhereUniqueWithoutSupercededByInput {
  where: PatientWhereUniqueInput;
  data: PatientUpdateWithoutSupercededByInput;
}

export interface PatientUpdateWithoutAccountsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutCommunicationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutFeedbacksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutGuarantorAccountsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutInsurancePoliciesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutNotesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutOrganizationPatientGroupInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutPatientLabelingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutPatientUserInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutPaymentIntentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutPaymentMethodsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutPaymentRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutReminderWorkflowInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutSupercededByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercedes?: PatientUpdateManyWithoutSupercededByNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdateWithoutSupercedesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  preferredName?: NullableStringFieldUpdateOperationsInput | null;
  dateOfBirth?: NullableDateTimeFieldUpdateOperationsInput | null;
  birthSex?: NullableEnumBirthSexFieldUpdateOperationsInput | null;
  medicalRecordNumber?: NullableStringFieldUpdateOperationsInput | null;
  cellPhone?: NullableStringFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  emailInvalid?: NullableBoolFieldUpdateOperationsInput | null;
  address1?: NullableStringFieldUpdateOperationsInput | null;
  address2?: NullableStringFieldUpdateOperationsInput | null;
  city?: NullableStringFieldUpdateOperationsInput | null;
  postalCode?: NullableStringFieldUpdateOperationsInput | null;
  state?: NullableStringFieldUpdateOperationsInput | null;
  country?: NullableStringFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  stripeCustomerId?: NullableStringFieldUpdateOperationsInput | null;
  communicationPreferences?: PatientUpdatecommunicationPreferencesInput | null;
  autoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  visitAutoPayEnabled?: BoolFieldUpdateOperationsInput | null;
  maxAutopayLimit?: NullableIntFieldUpdateOperationsInput | null;
  estimateCommunicationEnrolled?: BoolFieldUpdateOperationsInput | null;
  estimationPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  preVisitReminderPausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  timeOfServiceAutoChargePausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  accountNumber?: StringFieldUpdateOperationsInput | null;
  emailCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  smsCommunicationEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  twilioOptOut?: NullableBoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPatientsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPatientsNestedInput | null;
  patientUser?: PatientUserUpdateOneWithoutPatientsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPatientNestedInput | null;
  paymentIntents?: PaymentIntentUpdateManyWithoutPatientNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPatientNestedInput | null;
  reminderWorkflow?: ReminderWorkflowUpdateOneWithoutPatientNestedInput | null;
  accounts?: AccountUpdateManyWithoutPatientNestedInput | null;
  guarantorAccounts?: AccountUpdateManyWithoutGuarantorNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientNestedInput | null;
  paymentMethods?: PatientPaymentMethodUpdateManyWithoutPatientNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPatientNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPatientNestedInput | null;
  organizationPatientGroup?: OrganizationPatientGroupUpdateOneWithoutPatientsNestedInput | null;
  supercededBy?: PatientUpdateOneWithoutSupercedesNestedInput | null;
  patientLabelings?: PatientLabelingUpdateManyWithoutPatientNestedInput | null;
  notes?: NoteUpdateManyWithoutPatientNestedInput | null;
}

export interface PatientUpdatecommunicationPreferencesInput {
  set?: CommunicationPreference[] | null;
  push?: CommunicationPreference[] | null;
}

export interface PatientUpsertWithWhereUniqueWithoutLocationInput {
  where: PatientWhereUniqueInput;
  update: PatientUpdateWithoutLocationInput;
  create: PatientCreateWithoutLocationInput;
}

export interface PatientUpsertWithWhereUniqueWithoutOrganizationInput {
  where: PatientWhereUniqueInput;
  update: PatientUpdateWithoutOrganizationInput;
  create: PatientCreateWithoutOrganizationInput;
}

export interface PatientUpsertWithWhereUniqueWithoutOrganizationPatientGroupInput {
  where: PatientWhereUniqueInput;
  update: PatientUpdateWithoutOrganizationPatientGroupInput;
  create: PatientCreateWithoutOrganizationPatientGroupInput;
}

export interface PatientUpsertWithWhereUniqueWithoutPatientUserInput {
  where: PatientWhereUniqueInput;
  update: PatientUpdateWithoutPatientUserInput;
  create: PatientCreateWithoutPatientUserInput;
}

export interface PatientUpsertWithWhereUniqueWithoutSupercededByInput {
  where: PatientWhereUniqueInput;
  update: PatientUpdateWithoutSupercededByInput;
  create: PatientCreateWithoutSupercededByInput;
}

export interface PatientUpsertWithoutAccountsInput {
  update: PatientUpdateWithoutAccountsInput;
  create: PatientCreateWithoutAccountsInput;
}

export interface PatientUpsertWithoutCommunicationsInput {
  update: PatientUpdateWithoutCommunicationsInput;
  create: PatientCreateWithoutCommunicationsInput;
}

export interface PatientUpsertWithoutFeedbacksInput {
  update: PatientUpdateWithoutFeedbacksInput;
  create: PatientCreateWithoutFeedbacksInput;
}

export interface PatientUpsertWithoutGuarantorAccountsInput {
  update: PatientUpdateWithoutGuarantorAccountsInput;
  create: PatientCreateWithoutGuarantorAccountsInput;
}

export interface PatientUpsertWithoutInsurancePoliciesInput {
  update: PatientUpdateWithoutInsurancePoliciesInput;
  create: PatientCreateWithoutInsurancePoliciesInput;
}

export interface PatientUpsertWithoutIntegrationLinksInput {
  update: PatientUpdateWithoutIntegrationLinksInput;
  create: PatientCreateWithoutIntegrationLinksInput;
}

export interface PatientUpsertWithoutNotesInput {
  update: PatientUpdateWithoutNotesInput;
  create: PatientCreateWithoutNotesInput;
}

export interface PatientUpsertWithoutPatientLabelingsInput {
  update: PatientUpdateWithoutPatientLabelingsInput;
  create: PatientCreateWithoutPatientLabelingsInput;
}

export interface PatientUpsertWithoutPaymentIntentsInput {
  update: PatientUpdateWithoutPaymentIntentsInput;
  create: PatientCreateWithoutPaymentIntentsInput;
}

export interface PatientUpsertWithoutPaymentMethodsInput {
  update: PatientUpdateWithoutPaymentMethodsInput;
  create: PatientCreateWithoutPaymentMethodsInput;
}

export interface PatientUpsertWithoutPaymentRequestsInput {
  update: PatientUpdateWithoutPaymentRequestsInput;
  create: PatientCreateWithoutPaymentRequestsInput;
}

export interface PatientUpsertWithoutReminderWorkflowInput {
  update: PatientUpdateWithoutReminderWorkflowInput;
  create: PatientCreateWithoutReminderWorkflowInput;
}

export interface PatientUpsertWithoutSupercedesInput {
  update: PatientUpdateWithoutSupercedesInput;
  create: PatientCreateWithoutSupercedesInput;
}

export interface PatientUserCreateNestedOneWithoutFeedbacksInput {
  create?: PatientUserCreateWithoutFeedbacksInput | null;
  connectOrCreate?: PatientUserCreateOrConnectWithoutFeedbacksInput | null;
  connect?: PatientUserWhereUniqueInput | null;
}

export interface PatientUserCreateNestedOneWithoutPatientsInput {
  create?: PatientUserCreateWithoutPatientsInput | null;
  connectOrCreate?: PatientUserCreateOrConnectWithoutPatientsInput | null;
  connect?: PatientUserWhereUniqueInput | null;
}

export interface PatientUserCreateOrConnectWithoutFeedbacksInput {
  where: PatientUserWhereUniqueInput;
  create: PatientUserCreateWithoutFeedbacksInput;
}

export interface PatientUserCreateOrConnectWithoutPatientsInput {
  where: PatientUserWhereUniqueInput;
  create: PatientUserCreateWithoutPatientsInput;
}

export interface PatientUserCreateWithoutFeedbacksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: string | null;
  stytchUserId?: string | null;
  patients?: PatientCreateNestedManyWithoutPatientUserInput | null;
}

export interface PatientUserCreateWithoutPatientsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: string | null;
  stytchUserId?: string | null;
  feedbacks?: FeedbackCreateNestedManyWithoutPatientUserInput | null;
}

export interface PatientUserOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  email?: SortOrderInput | null;
  stytchUserId?: SortOrderInput | null;
  patients?: PatientOrderByRelationAggregateInput | null;
  feedbacks?: FeedbackOrderByRelationAggregateInput | null;
}

export interface PatientUserRelationFilter {
  is?: PatientUserWhereInput | null;
  isNot?: PatientUserWhereInput | null;
}

export interface PatientUserUpdateOneWithoutFeedbacksNestedInput {
  create?: PatientUserCreateWithoutFeedbacksInput | null;
  connectOrCreate?: PatientUserCreateOrConnectWithoutFeedbacksInput | null;
  upsert?: PatientUserUpsertWithoutFeedbacksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PatientUserWhereUniqueInput | null;
  update?: PatientUserUpdateWithoutFeedbacksInput | null;
}

export interface PatientUserUpdateOneWithoutPatientsNestedInput {
  create?: PatientUserCreateWithoutPatientsInput | null;
  connectOrCreate?: PatientUserCreateOrConnectWithoutPatientsInput | null;
  upsert?: PatientUserUpsertWithoutPatientsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PatientUserWhereUniqueInput | null;
  update?: PatientUserUpdateWithoutPatientsInput | null;
}

export interface PatientUserUpdateWithoutFeedbacksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  stytchUserId?: NullableStringFieldUpdateOperationsInput | null;
  patients?: PatientUpdateManyWithoutPatientUserNestedInput | null;
}

export interface PatientUserUpdateWithoutPatientsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: NullableStringFieldUpdateOperationsInput | null;
  stytchUserId?: NullableStringFieldUpdateOperationsInput | null;
  feedbacks?: FeedbackUpdateManyWithoutPatientUserNestedInput | null;
}

export interface PatientUserUpsertWithoutFeedbacksInput {
  update: PatientUserUpdateWithoutFeedbacksInput;
  create: PatientUserCreateWithoutFeedbacksInput;
}

export interface PatientUserUpsertWithoutPatientsInput {
  update: PatientUserUpdateWithoutPatientsInput;
  create: PatientUserCreateWithoutPatientsInput;
}

export interface PatientUserWhereInput {
  AND?: PatientUserWhereInput[] | null;
  OR?: PatientUserWhereInput[] | null;
  NOT?: PatientUserWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  email?: StringNullableFilter | null;
  stytchUserId?: StringNullableFilter | null;
  patients?: PatientListRelationFilter | null;
  feedbacks?: FeedbackListRelationFilter | null;
}

export interface PatientUserWhereUniqueInput {
  id?: string | null;
  email?: string | null;
}

export interface PatientWhereInput {
  AND?: PatientWhereInput[] | null;
  OR?: PatientWhereInput[] | null;
  NOT?: PatientWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  locationId?: UuidFilter | null;
  firstName?: StringFilter | null;
  lastName?: StringFilter | null;
  preferredName?: StringNullableFilter | null;
  dateOfBirth?: DateTimeNullableFilter | null;
  birthSex?: EnumBirthSexNullableFilter | null;
  medicalRecordNumber?: StringNullableFilter | null;
  cellPhone?: StringNullableFilter | null;
  email?: StringNullableFilter | null;
  emailInvalid?: BoolNullableFilter | null;
  address1?: StringNullableFilter | null;
  address2?: StringNullableFilter | null;
  city?: StringNullableFilter | null;
  postalCode?: StringNullableFilter | null;
  state?: StringNullableFilter | null;
  country?: StringNullableFilter | null;
  deletedAt?: DateTimeNullableFilter | null;
  stripeCustomerId?: StringNullableFilter | null;
  communicationPreferences?: EnumCommunicationPreferenceNullableListFilter | null;
  autoPayEnabled?: BoolFilter | null;
  visitAutoPayEnabled?: BoolFilter | null;
  maxAutopayLimit?: IntNullableFilter | null;
  estimateCommunicationEnrolled?: BoolFilter | null;
  patientUserId?: UuidNullableFilter | null;
  estimationPausedAt?: DateTimeNullableFilter | null;
  preVisitReminderPausedAt?: DateTimeNullableFilter | null;
  timeOfServiceAutoChargePausedAt?: DateTimeNullableFilter | null;
  organizationPatientGroupId?: UuidNullableFilter | null;
  accountNumber?: StringFilter | null;
  supercededById?: UuidNullableFilter | null;
  emailCommunicationEnabled?: BoolNullableFilter | null;
  smsCommunicationEnabled?: BoolNullableFilter | null;
  twilioOptOut?: BoolNullableFilter | null;
  organization?: OrganizationRelationFilter | null;
  location?: LocationRelationFilter | null;
  patientUser?: PatientUserRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
  paymentIntents?: PaymentIntentListRelationFilter | null;
  communications?: CommunicationListRelationFilter | null;
  reminderWorkflow?: ReminderWorkflowRelationFilter | null;
  accounts?: AccountListRelationFilter | null;
  guarantorAccounts?: AccountListRelationFilter | null;
  feedbacks?: FeedbackListRelationFilter | null;
  paymentMethods?: PatientPaymentMethodListRelationFilter | null;
  paymentRequests?: PaymentRequestListRelationFilter | null;
  insurancePolicies?: InsurancePolicyListRelationFilter | null;
  organizationPatientGroup?: OrganizationPatientGroupRelationFilter | null;
  supercededBy?: PatientRelationFilter | null;
  supercedes?: PatientListRelationFilter | null;
  patientLabelings?: PatientLabelingListRelationFilter | null;
  notes?: NoteListRelationFilter | null;
}

export interface PatientWhereUniqueInput {
  id?: string | null;
  accountNumber?: string | null;
  medicalRecordNumber_organizationId?: PatientMedicalRecordNumberOrganizationIdCompoundUniqueInput | null;
}

export interface PayerCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  plans?: PlanCreateNestedManyWithoutPayerInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPayerInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPayerInput | null;
  claims?: ClaimCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  organization?: OrganizationCreateNestedOneWithoutPayersInput | null;
  location?: LocationCreateNestedOneWithoutPayersInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPayerInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutPayerInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutPayerInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput | null;
}

export interface PayerCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  tradingPartnerId?: string | null;
  organizationId?: string | null;
}

export interface PayerCreateManyLocationInputEnvelope {
  data: PayerCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface PayerCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  tradingPartnerId?: string | null;
  locationId?: string | null;
}

export interface PayerCreateManyOrganizationInputEnvelope {
  data: PayerCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface PayerCreateNestedManyWithoutLocationInput {
  create?: PayerCreateWithoutLocationInput[] | null;
  connectOrCreate?: PayerCreateOrConnectWithoutLocationInput[] | null;
  createMany?: PayerCreateManyLocationInputEnvelope | null;
  connect?: PayerWhereUniqueInput[] | null;
}

export interface PayerCreateNestedManyWithoutOrganizationInput {
  create?: PayerCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: PayerCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: PayerCreateManyOrganizationInputEnvelope | null;
  connect?: PayerWhereUniqueInput[] | null;
}

export interface PayerCreateNestedOneWithoutAccountCoveragesInput {
  create?: PayerCreateWithoutAccountCoveragesInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutAccountCoveragesInput | null;
  connect?: PayerWhereUniqueInput | null;
}

export interface PayerCreateNestedOneWithoutClaimsInput {
  create?: PayerCreateWithoutClaimsInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutClaimsInput | null;
  connect?: PayerWhereUniqueInput | null;
}

export interface PayerCreateNestedOneWithoutInsurancePoliciesInput {
  create?: PayerCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutInsurancePoliciesInput | null;
  connect?: PayerWhereUniqueInput | null;
}

export interface PayerCreateNestedOneWithoutIntegrationLinksInput {
  create?: PayerCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: PayerWhereUniqueInput | null;
}

export interface PayerCreateNestedOneWithoutOrganizationInNetworkPayersInput {
  create?: PayerCreateWithoutOrganizationInNetworkPayersInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutOrganizationInNetworkPayersInput | null;
  connect?: PayerWhereUniqueInput | null;
}

export interface PayerCreateNestedOneWithoutPayerFeeSchedulesInput {
  create?: PayerCreateWithoutPayerFeeSchedulesInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutPayerFeeSchedulesInput | null;
  connect?: PayerWhereUniqueInput | null;
}

export interface PayerCreateNestedOneWithoutPayerProviderServiceConfigurationOverridesInput {
  create?: PayerCreateWithoutPayerProviderServiceConfigurationOverridesInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutPayerProviderServiceConfigurationOverridesInput | null;
  connect?: PayerWhereUniqueInput | null;
}

export interface PayerCreateNestedOneWithoutPlansInput {
  create?: PayerCreateWithoutPlansInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutPlansInput | null;
  connect?: PayerWhereUniqueInput | null;
}

export interface PayerCreateNestedOneWithoutProviderNetworkParticipationsInput {
  create?: PayerCreateWithoutProviderNetworkParticipationsInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutProviderNetworkParticipationsInput | null;
  connect?: PayerWhereUniqueInput | null;
}

export interface PayerCreateOrConnectWithoutAccountCoveragesInput {
  where: PayerWhereUniqueInput;
  create: PayerCreateWithoutAccountCoveragesInput;
}

export interface PayerCreateOrConnectWithoutClaimsInput {
  where: PayerWhereUniqueInput;
  create: PayerCreateWithoutClaimsInput;
}

export interface PayerCreateOrConnectWithoutInsurancePoliciesInput {
  where: PayerWhereUniqueInput;
  create: PayerCreateWithoutInsurancePoliciesInput;
}

export interface PayerCreateOrConnectWithoutIntegrationLinksInput {
  where: PayerWhereUniqueInput;
  create: PayerCreateWithoutIntegrationLinksInput;
}

export interface PayerCreateOrConnectWithoutLocationInput {
  where: PayerWhereUniqueInput;
  create: PayerCreateWithoutLocationInput;
}

export interface PayerCreateOrConnectWithoutOrganizationInNetworkPayersInput {
  where: PayerWhereUniqueInput;
  create: PayerCreateWithoutOrganizationInNetworkPayersInput;
}

export interface PayerCreateOrConnectWithoutOrganizationInput {
  where: PayerWhereUniqueInput;
  create: PayerCreateWithoutOrganizationInput;
}

export interface PayerCreateOrConnectWithoutPayerFeeSchedulesInput {
  where: PayerWhereUniqueInput;
  create: PayerCreateWithoutPayerFeeSchedulesInput;
}

export interface PayerCreateOrConnectWithoutPayerProviderServiceConfigurationOverridesInput {
  where: PayerWhereUniqueInput;
  create: PayerCreateWithoutPayerProviderServiceConfigurationOverridesInput;
}

export interface PayerCreateOrConnectWithoutPlansInput {
  where: PayerWhereUniqueInput;
  create: PayerCreateWithoutPlansInput;
}

export interface PayerCreateOrConnectWithoutProviderNetworkParticipationsInput {
  where: PayerWhereUniqueInput;
  create: PayerCreateWithoutProviderNetworkParticipationsInput;
}

export interface PayerCreateWithoutAccountCoveragesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  plans?: PlanCreateNestedManyWithoutPayerInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPayerInput | null;
  claims?: ClaimCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  organization?: OrganizationCreateNestedOneWithoutPayersInput | null;
  location?: LocationCreateNestedOneWithoutPayersInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPayerInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutPayerInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutPayerInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput | null;
}

export interface PayerCreateWithoutClaimsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  plans?: PlanCreateNestedManyWithoutPayerInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPayerInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  organization?: OrganizationCreateNestedOneWithoutPayersInput | null;
  location?: LocationCreateNestedOneWithoutPayersInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPayerInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutPayerInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutPayerInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput | null;
}

export interface PayerCreateWithoutInsurancePoliciesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  plans?: PlanCreateNestedManyWithoutPayerInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPayerInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPayerInput | null;
  claims?: ClaimCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  organization?: OrganizationCreateNestedOneWithoutPayersInput | null;
  location?: LocationCreateNestedOneWithoutPayersInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutPayerInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutPayerInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput | null;
}

export interface PayerCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  plans?: PlanCreateNestedManyWithoutPayerInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPayerInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput | null;
  claims?: ClaimCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  organization?: OrganizationCreateNestedOneWithoutPayersInput | null;
  location?: LocationCreateNestedOneWithoutPayersInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPayerInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutPayerInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutPayerInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput | null;
}

export interface PayerCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  plans?: PlanCreateNestedManyWithoutPayerInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPayerInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPayerInput | null;
  claims?: ClaimCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  organization?: OrganizationCreateNestedOneWithoutPayersInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPayerInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutPayerInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutPayerInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput | null;
}

export interface PayerCreateWithoutOrganizationInNetworkPayersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  plans?: PlanCreateNestedManyWithoutPayerInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPayerInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPayerInput | null;
  claims?: ClaimCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  organization?: OrganizationCreateNestedOneWithoutPayersInput | null;
  location?: LocationCreateNestedOneWithoutPayersInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPayerInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutPayerInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutPayerInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput | null;
}

export interface PayerCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  plans?: PlanCreateNestedManyWithoutPayerInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPayerInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPayerInput | null;
  claims?: ClaimCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  location?: LocationCreateNestedOneWithoutPayersInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPayerInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutPayerInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutPayerInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput | null;
}

export interface PayerCreateWithoutPayerFeeSchedulesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  plans?: PlanCreateNestedManyWithoutPayerInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPayerInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPayerInput | null;
  claims?: ClaimCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  organization?: OrganizationCreateNestedOneWithoutPayersInput | null;
  location?: LocationCreateNestedOneWithoutPayersInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPayerInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutPayerInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput | null;
}

export interface PayerCreateWithoutPayerProviderServiceConfigurationOverridesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  plans?: PlanCreateNestedManyWithoutPayerInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPayerInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPayerInput | null;
  claims?: ClaimCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  organization?: OrganizationCreateNestedOneWithoutPayersInput | null;
  location?: LocationCreateNestedOneWithoutPayersInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPayerInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutPayerInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutPayerInput | null;
}

export interface PayerCreateWithoutPlansInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPayerInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPayerInput | null;
  claims?: ClaimCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  organization?: OrganizationCreateNestedOneWithoutPayersInput | null;
  location?: LocationCreateNestedOneWithoutPayersInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPayerInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutPayerInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutPayerInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput | null;
}

export interface PayerCreateWithoutProviderNetworkParticipationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  name: string;
  excludedFromBenefitsWorkflow?: boolean | null;
  plans?: PlanCreateNestedManyWithoutPayerInput | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPayerInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerCreateNestedManyWithoutPayerInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutPayerInput | null;
  claims?: ClaimCreateNestedManyWithoutPayerInput | null;
  tradingPartner?: TradingPartnerCreateNestedOneWithoutPayersInput | null;
  organization?: OrganizationCreateNestedOneWithoutPayersInput | null;
  location?: LocationCreateNestedOneWithoutPayersInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPayerInput | null;
  payerFeeSchedules?: PayerFeeSchedulesCreateNestedManyWithoutPayerInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput | null;
}

export interface PayerFeeSchedulesCreateManyFeeScheduleInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  payerId: string;
}

export interface PayerFeeSchedulesCreateManyFeeScheduleInputEnvelope {
  data: PayerFeeSchedulesCreateManyFeeScheduleInput[];
  skipDuplicates?: boolean | null;
}

export interface PayerFeeSchedulesCreateManyPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  feeScheduleId: string;
}

export interface PayerFeeSchedulesCreateManyPayerInputEnvelope {
  data: PayerFeeSchedulesCreateManyPayerInput[];
  skipDuplicates?: boolean | null;
}

export interface PayerFeeSchedulesCreateNestedManyWithoutFeeScheduleInput {
  create?: PayerFeeSchedulesCreateWithoutFeeScheduleInput[] | null;
  connectOrCreate?: PayerFeeSchedulesCreateOrConnectWithoutFeeScheduleInput[] | null;
  createMany?: PayerFeeSchedulesCreateManyFeeScheduleInputEnvelope | null;
  connect?: PayerFeeSchedulesWhereUniqueInput[] | null;
}

export interface PayerFeeSchedulesCreateNestedManyWithoutPayerInput {
  create?: PayerFeeSchedulesCreateWithoutPayerInput[] | null;
  connectOrCreate?: PayerFeeSchedulesCreateOrConnectWithoutPayerInput[] | null;
  createMany?: PayerFeeSchedulesCreateManyPayerInputEnvelope | null;
  connect?: PayerFeeSchedulesWhereUniqueInput[] | null;
}

export interface PayerFeeSchedulesCreateOrConnectWithoutFeeScheduleInput {
  where: PayerFeeSchedulesWhereUniqueInput;
  create: PayerFeeSchedulesCreateWithoutFeeScheduleInput;
}

export interface PayerFeeSchedulesCreateOrConnectWithoutPayerInput {
  where: PayerFeeSchedulesWhereUniqueInput;
  create: PayerFeeSchedulesCreateWithoutPayerInput;
}

export interface PayerFeeSchedulesCreateWithoutFeeScheduleInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  payer: PayerCreateNestedOneWithoutPayerFeeSchedulesInput;
}

export interface PayerFeeSchedulesCreateWithoutPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  feeSchedule: FeeScheduleCreateNestedOneWithoutPayerFeeSchedulesInput;
}

export interface PayerFeeSchedulesListRelationFilter {
  every?: PayerFeeSchedulesWhereInput | null;
  some?: PayerFeeSchedulesWhereInput | null;
  none?: PayerFeeSchedulesWhereInput | null;
}

export interface PayerFeeSchedulesScalarWhereInput {
  AND?: PayerFeeSchedulesScalarWhereInput[] | null;
  OR?: PayerFeeSchedulesScalarWhereInput[] | null;
  NOT?: PayerFeeSchedulesScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  payerId?: UuidFilter | null;
  feeScheduleId?: UuidFilter | null;
}

export interface PayerFeeSchedulesUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
}

export interface PayerFeeSchedulesUpdateManyWithWhereWithoutFeeScheduleInput {
  where: PayerFeeSchedulesScalarWhereInput;
  data: PayerFeeSchedulesUpdateManyMutationInput;
}

export interface PayerFeeSchedulesUpdateManyWithWhereWithoutPayerInput {
  where: PayerFeeSchedulesScalarWhereInput;
  data: PayerFeeSchedulesUpdateManyMutationInput;
}

export interface PayerFeeSchedulesUpdateManyWithoutFeeScheduleNestedInput {
  create?: PayerFeeSchedulesCreateWithoutFeeScheduleInput[] | null;
  connectOrCreate?: PayerFeeSchedulesCreateOrConnectWithoutFeeScheduleInput[] | null;
  upsert?: PayerFeeSchedulesUpsertWithWhereUniqueWithoutFeeScheduleInput[] | null;
  createMany?: PayerFeeSchedulesCreateManyFeeScheduleInputEnvelope | null;
  set?: PayerFeeSchedulesWhereUniqueInput[] | null;
  disconnect?: PayerFeeSchedulesWhereUniqueInput[] | null;
  delete?: PayerFeeSchedulesWhereUniqueInput[] | null;
  connect?: PayerFeeSchedulesWhereUniqueInput[] | null;
  update?: PayerFeeSchedulesUpdateWithWhereUniqueWithoutFeeScheduleInput[] | null;
  updateMany?: PayerFeeSchedulesUpdateManyWithWhereWithoutFeeScheduleInput[] | null;
  deleteMany?: PayerFeeSchedulesScalarWhereInput[] | null;
}

export interface PayerFeeSchedulesUpdateManyWithoutPayerNestedInput {
  create?: PayerFeeSchedulesCreateWithoutPayerInput[] | null;
  connectOrCreate?: PayerFeeSchedulesCreateOrConnectWithoutPayerInput[] | null;
  upsert?: PayerFeeSchedulesUpsertWithWhereUniqueWithoutPayerInput[] | null;
  createMany?: PayerFeeSchedulesCreateManyPayerInputEnvelope | null;
  set?: PayerFeeSchedulesWhereUniqueInput[] | null;
  disconnect?: PayerFeeSchedulesWhereUniqueInput[] | null;
  delete?: PayerFeeSchedulesWhereUniqueInput[] | null;
  connect?: PayerFeeSchedulesWhereUniqueInput[] | null;
  update?: PayerFeeSchedulesUpdateWithWhereUniqueWithoutPayerInput[] | null;
  updateMany?: PayerFeeSchedulesUpdateManyWithWhereWithoutPayerInput[] | null;
  deleteMany?: PayerFeeSchedulesScalarWhereInput[] | null;
}

export interface PayerFeeSchedulesUpdateWithWhereUniqueWithoutFeeScheduleInput {
  where: PayerFeeSchedulesWhereUniqueInput;
  data: PayerFeeSchedulesUpdateWithoutFeeScheduleInput;
}

export interface PayerFeeSchedulesUpdateWithWhereUniqueWithoutPayerInput {
  where: PayerFeeSchedulesWhereUniqueInput;
  data: PayerFeeSchedulesUpdateWithoutPayerInput;
}

export interface PayerFeeSchedulesUpdateWithoutFeeScheduleInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  payer?: PayerUpdateOneRequiredWithoutPayerFeeSchedulesNestedInput | null;
}

export interface PayerFeeSchedulesUpdateWithoutPayerInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  feeSchedule?: FeeScheduleUpdateOneRequiredWithoutPayerFeeSchedulesNestedInput | null;
}

export interface PayerFeeSchedulesUpsertWithWhereUniqueWithoutFeeScheduleInput {
  where: PayerFeeSchedulesWhereUniqueInput;
  update: PayerFeeSchedulesUpdateWithoutFeeScheduleInput;
  create: PayerFeeSchedulesCreateWithoutFeeScheduleInput;
}

export interface PayerFeeSchedulesUpsertWithWhereUniqueWithoutPayerInput {
  where: PayerFeeSchedulesWhereUniqueInput;
  update: PayerFeeSchedulesUpdateWithoutPayerInput;
  create: PayerFeeSchedulesCreateWithoutPayerInput;
}

export interface PayerFeeSchedulesWhereInput {
  AND?: PayerFeeSchedulesWhereInput[] | null;
  OR?: PayerFeeSchedulesWhereInput[] | null;
  NOT?: PayerFeeSchedulesWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  payerId?: UuidFilter | null;
  feeScheduleId?: UuidFilter | null;
  payer?: PayerRelationFilter | null;
  feeSchedule?: FeeScheduleRelationFilter | null;
}

export interface PayerFeeSchedulesWhereUniqueInput {
  id?: string | null;
}

export interface PayerListRelationFilter {
  every?: PayerWhereInput | null;
  some?: PayerWhereInput | null;
  none?: PayerWhereInput | null;
}

export interface PayerOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PayerProviderServiceConfigurationOverrideCreateManyPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationOverrideId: string;
}

export interface PayerProviderServiceConfigurationOverrideCreateManyPayerInputEnvelope {
  data: PayerProviderServiceConfigurationOverrideCreateManyPayerInput[];
  skipDuplicates?: boolean | null;
}

export interface PayerProviderServiceConfigurationOverrideCreateManyPayerProviderServiceConfigurationOverrideInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  payerId: string;
}

export interface PayerProviderServiceConfigurationOverrideCreateManyPayerProviderServiceConfigurationOverrideInputEnvelope {
  data: PayerProviderServiceConfigurationOverrideCreateManyPayerProviderServiceConfigurationOverrideInput[];
  skipDuplicates?: boolean | null;
}

export interface PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerInput {
  create?: PayerProviderServiceConfigurationOverrideCreateWithoutPayerInput[] | null;
  connectOrCreate?: PayerProviderServiceConfigurationOverrideCreateOrConnectWithoutPayerInput[] | null;
  createMany?: PayerProviderServiceConfigurationOverrideCreateManyPayerInputEnvelope | null;
  connect?: PayerProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
}

export interface PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerProviderServiceConfigurationOverrideInput {
  create?: PayerProviderServiceConfigurationOverrideCreateWithoutPayerProviderServiceConfigurationOverrideInput[] | null;
  connectOrCreate?: PayerProviderServiceConfigurationOverrideCreateOrConnectWithoutPayerProviderServiceConfigurationOverrideInput[] | null;
  createMany?: PayerProviderServiceConfigurationOverrideCreateManyPayerProviderServiceConfigurationOverrideInputEnvelope | null;
  connect?: PayerProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
}

export interface PayerProviderServiceConfigurationOverrideCreateOrConnectWithoutPayerInput {
  where: PayerProviderServiceConfigurationOverrideWhereUniqueInput;
  create: PayerProviderServiceConfigurationOverrideCreateWithoutPayerInput;
}

export interface PayerProviderServiceConfigurationOverrideCreateOrConnectWithoutPayerProviderServiceConfigurationOverrideInput {
  where: PayerProviderServiceConfigurationOverrideWhereUniqueInput;
  create: PayerProviderServiceConfigurationOverrideCreateWithoutPayerProviderServiceConfigurationOverrideInput;
}

export interface PayerProviderServiceConfigurationOverrideCreateWithoutPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  payerProviderServiceConfigurationOverride: ProviderServiceConfigurationOverrideCreateNestedOneWithoutPayerProviderServiceConfigurationOverridesInput;
}

export interface PayerProviderServiceConfigurationOverrideCreateWithoutPayerProviderServiceConfigurationOverrideInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  payer: PayerCreateNestedOneWithoutPayerProviderServiceConfigurationOverridesInput;
}

export interface PayerProviderServiceConfigurationOverrideListRelationFilter {
  every?: PayerProviderServiceConfigurationOverrideWhereInput | null;
  some?: PayerProviderServiceConfigurationOverrideWhereInput | null;
  none?: PayerProviderServiceConfigurationOverrideWhereInput | null;
}

export interface PayerProviderServiceConfigurationOverrideProviderServiceConfigurationOverrideIdPayerIdCompoundUniqueInput {
  providerServiceConfigurationOverrideId: string;
  payerId: string;
}

export interface PayerProviderServiceConfigurationOverrideScalarWhereInput {
  AND?: PayerProviderServiceConfigurationOverrideScalarWhereInput[] | null;
  OR?: PayerProviderServiceConfigurationOverrideScalarWhereInput[] | null;
  NOT?: PayerProviderServiceConfigurationOverrideScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  providerServiceConfigurationOverrideId?: UuidFilter | null;
  payerId?: UuidFilter | null;
}

export interface PayerProviderServiceConfigurationOverrideUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
}

export interface PayerProviderServiceConfigurationOverrideUpdateManyWithWhereWithoutPayerInput {
  where: PayerProviderServiceConfigurationOverrideScalarWhereInput;
  data: PayerProviderServiceConfigurationOverrideUpdateManyMutationInput;
}

export interface PayerProviderServiceConfigurationOverrideUpdateManyWithWhereWithoutPayerProviderServiceConfigurationOverrideInput {
  where: PayerProviderServiceConfigurationOverrideScalarWhereInput;
  data: PayerProviderServiceConfigurationOverrideUpdateManyMutationInput;
}

export interface PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput {
  create?: PayerProviderServiceConfigurationOverrideCreateWithoutPayerInput[] | null;
  connectOrCreate?: PayerProviderServiceConfigurationOverrideCreateOrConnectWithoutPayerInput[] | null;
  upsert?: PayerProviderServiceConfigurationOverrideUpsertWithWhereUniqueWithoutPayerInput[] | null;
  createMany?: PayerProviderServiceConfigurationOverrideCreateManyPayerInputEnvelope | null;
  set?: PayerProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  disconnect?: PayerProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  delete?: PayerProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  connect?: PayerProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  update?: PayerProviderServiceConfigurationOverrideUpdateWithWhereUniqueWithoutPayerInput[] | null;
  updateMany?: PayerProviderServiceConfigurationOverrideUpdateManyWithWhereWithoutPayerInput[] | null;
  deleteMany?: PayerProviderServiceConfigurationOverrideScalarWhereInput[] | null;
}

export interface PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerProviderServiceConfigurationOverrideNestedInput {
  create?: PayerProviderServiceConfigurationOverrideCreateWithoutPayerProviderServiceConfigurationOverrideInput[] | null;
  connectOrCreate?: PayerProviderServiceConfigurationOverrideCreateOrConnectWithoutPayerProviderServiceConfigurationOverrideInput[] | null;
  upsert?: PayerProviderServiceConfigurationOverrideUpsertWithWhereUniqueWithoutPayerProviderServiceConfigurationOverrideInput[] | null;
  createMany?: PayerProviderServiceConfigurationOverrideCreateManyPayerProviderServiceConfigurationOverrideInputEnvelope | null;
  set?: PayerProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  disconnect?: PayerProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  delete?: PayerProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  connect?: PayerProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  update?: PayerProviderServiceConfigurationOverrideUpdateWithWhereUniqueWithoutPayerProviderServiceConfigurationOverrideInput[] | null;
  updateMany?: PayerProviderServiceConfigurationOverrideUpdateManyWithWhereWithoutPayerProviderServiceConfigurationOverrideInput[] | null;
  deleteMany?: PayerProviderServiceConfigurationOverrideScalarWhereInput[] | null;
}

export interface PayerProviderServiceConfigurationOverrideUpdateWithWhereUniqueWithoutPayerInput {
  where: PayerProviderServiceConfigurationOverrideWhereUniqueInput;
  data: PayerProviderServiceConfigurationOverrideUpdateWithoutPayerInput;
}

export interface PayerProviderServiceConfigurationOverrideUpdateWithWhereUniqueWithoutPayerProviderServiceConfigurationOverrideInput {
  where: PayerProviderServiceConfigurationOverrideWhereUniqueInput;
  data: PayerProviderServiceConfigurationOverrideUpdateWithoutPayerProviderServiceConfigurationOverrideInput;
}

export interface PayerProviderServiceConfigurationOverrideUpdateWithoutPayerInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  payerProviderServiceConfigurationOverride?: ProviderServiceConfigurationOverrideUpdateOneRequiredWithoutPayerProviderServiceConfigurationOverridesNestedInput | null;
}

export interface PayerProviderServiceConfigurationOverrideUpdateWithoutPayerProviderServiceConfigurationOverrideInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  payer?: PayerUpdateOneRequiredWithoutPayerProviderServiceConfigurationOverridesNestedInput | null;
}

export interface PayerProviderServiceConfigurationOverrideUpsertWithWhereUniqueWithoutPayerInput {
  where: PayerProviderServiceConfigurationOverrideWhereUniqueInput;
  update: PayerProviderServiceConfigurationOverrideUpdateWithoutPayerInput;
  create: PayerProviderServiceConfigurationOverrideCreateWithoutPayerInput;
}

export interface PayerProviderServiceConfigurationOverrideUpsertWithWhereUniqueWithoutPayerProviderServiceConfigurationOverrideInput {
  where: PayerProviderServiceConfigurationOverrideWhereUniqueInput;
  update: PayerProviderServiceConfigurationOverrideUpdateWithoutPayerProviderServiceConfigurationOverrideInput;
  create: PayerProviderServiceConfigurationOverrideCreateWithoutPayerProviderServiceConfigurationOverrideInput;
}

export interface PayerProviderServiceConfigurationOverrideWhereInput {
  AND?: PayerProviderServiceConfigurationOverrideWhereInput[] | null;
  OR?: PayerProviderServiceConfigurationOverrideWhereInput[] | null;
  NOT?: PayerProviderServiceConfigurationOverrideWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  providerServiceConfigurationOverrideId?: UuidFilter | null;
  payerId?: UuidFilter | null;
  payerProviderServiceConfigurationOverride?: ProviderServiceConfigurationOverrideRelationFilter | null;
  payer?: PayerRelationFilter | null;
}

export interface PayerProviderServiceConfigurationOverrideWhereUniqueInput {
  id?: string | null;
  providerServiceConfigurationOverrideId_payerId?: PayerProviderServiceConfigurationOverrideProviderServiceConfigurationOverrideIdPayerIdCompoundUniqueInput | null;
}

export interface PayerRelationFilter {
  is?: PayerWhereInput | null;
  isNot?: PayerWhereInput | null;
}

export interface PayerScalarWhereInput {
  AND?: PayerScalarWhereInput[] | null;
  OR?: PayerScalarWhereInput[] | null;
  NOT?: PayerScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  changeTradingPartnerId?: StringNullableFilter | null;
  changeEligibilityPortalId?: StringNullableFilter | null;
  changeEligibility?: BoolNullableFilter | null;
  changeClaimStatus?: BoolNullableFilter | null;
  changeEligibilityConnectivityType?: EnumChangePayerConnectivityTypeNullableFilter | null;
  changeClaimStatusConnectivityType?: EnumChangePayerConnectivityTypeNullableFilter | null;
  name?: StringFilter | null;
  excludedFromBenefitsWorkflow?: BoolFilter | null;
  tradingPartnerId?: UuidNullableFilter | null;
  organizationId?: UuidNullableFilter | null;
  locationId?: UuidNullableFilter | null;
}

export interface PayerUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  plans?: PlanUpdateManyWithoutPayerNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPayerNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPayerNestedInput | null;
  claims?: ClaimUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  organization?: OrganizationUpdateOneWithoutPayersNestedInput | null;
  location?: LocationUpdateOneWithoutPayersNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPayerNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutPayerNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
}

export interface PayerUpdateManyWithWhereWithoutLocationInput {
  where: PayerScalarWhereInput;
  data: PayerUpdateManyMutationInput;
}

export interface PayerUpdateManyWithWhereWithoutOrganizationInput {
  where: PayerScalarWhereInput;
  data: PayerUpdateManyMutationInput;
}

export interface PayerUpdateManyWithoutLocationNestedInput {
  create?: PayerCreateWithoutLocationInput[] | null;
  connectOrCreate?: PayerCreateOrConnectWithoutLocationInput[] | null;
  upsert?: PayerUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: PayerCreateManyLocationInputEnvelope | null;
  set?: PayerWhereUniqueInput[] | null;
  disconnect?: PayerWhereUniqueInput[] | null;
  delete?: PayerWhereUniqueInput[] | null;
  connect?: PayerWhereUniqueInput[] | null;
  update?: PayerUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: PayerUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: PayerScalarWhereInput[] | null;
}

export interface PayerUpdateManyWithoutOrganizationNestedInput {
  create?: PayerCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: PayerCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: PayerUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: PayerCreateManyOrganizationInputEnvelope | null;
  set?: PayerWhereUniqueInput[] | null;
  disconnect?: PayerWhereUniqueInput[] | null;
  delete?: PayerWhereUniqueInput[] | null;
  connect?: PayerWhereUniqueInput[] | null;
  update?: PayerUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: PayerUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: PayerScalarWhereInput[] | null;
}

export interface PayerUpdateOneRequiredWithoutAccountCoveragesNestedInput {
  create?: PayerCreateWithoutAccountCoveragesInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutAccountCoveragesInput | null;
  upsert?: PayerUpsertWithoutAccountCoveragesInput | null;
  connect?: PayerWhereUniqueInput | null;
  update?: PayerUpdateWithoutAccountCoveragesInput | null;
}

export interface PayerUpdateOneRequiredWithoutClaimsNestedInput {
  create?: PayerCreateWithoutClaimsInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutClaimsInput | null;
  upsert?: PayerUpsertWithoutClaimsInput | null;
  connect?: PayerWhereUniqueInput | null;
  update?: PayerUpdateWithoutClaimsInput | null;
}

export interface PayerUpdateOneRequiredWithoutInsurancePoliciesNestedInput {
  create?: PayerCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutInsurancePoliciesInput | null;
  upsert?: PayerUpsertWithoutInsurancePoliciesInput | null;
  connect?: PayerWhereUniqueInput | null;
  update?: PayerUpdateWithoutInsurancePoliciesInput | null;
}

export interface PayerUpdateOneRequiredWithoutOrganizationInNetworkPayersNestedInput {
  create?: PayerCreateWithoutOrganizationInNetworkPayersInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutOrganizationInNetworkPayersInput | null;
  upsert?: PayerUpsertWithoutOrganizationInNetworkPayersInput | null;
  connect?: PayerWhereUniqueInput | null;
  update?: PayerUpdateWithoutOrganizationInNetworkPayersInput | null;
}

export interface PayerUpdateOneRequiredWithoutPayerFeeSchedulesNestedInput {
  create?: PayerCreateWithoutPayerFeeSchedulesInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutPayerFeeSchedulesInput | null;
  upsert?: PayerUpsertWithoutPayerFeeSchedulesInput | null;
  connect?: PayerWhereUniqueInput | null;
  update?: PayerUpdateWithoutPayerFeeSchedulesInput | null;
}

export interface PayerUpdateOneRequiredWithoutPayerProviderServiceConfigurationOverridesNestedInput {
  create?: PayerCreateWithoutPayerProviderServiceConfigurationOverridesInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutPayerProviderServiceConfigurationOverridesInput | null;
  upsert?: PayerUpsertWithoutPayerProviderServiceConfigurationOverridesInput | null;
  connect?: PayerWhereUniqueInput | null;
  update?: PayerUpdateWithoutPayerProviderServiceConfigurationOverridesInput | null;
}

export interface PayerUpdateOneRequiredWithoutPlansNestedInput {
  create?: PayerCreateWithoutPlansInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutPlansInput | null;
  upsert?: PayerUpsertWithoutPlansInput | null;
  connect?: PayerWhereUniqueInput | null;
  update?: PayerUpdateWithoutPlansInput | null;
}

export interface PayerUpdateOneRequiredWithoutProviderNetworkParticipationsNestedInput {
  create?: PayerCreateWithoutProviderNetworkParticipationsInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutProviderNetworkParticipationsInput | null;
  upsert?: PayerUpsertWithoutProviderNetworkParticipationsInput | null;
  connect?: PayerWhereUniqueInput | null;
  update?: PayerUpdateWithoutProviderNetworkParticipationsInput | null;
}

export interface PayerUpdateOneWithoutIntegrationLinksNestedInput {
  create?: PayerCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: PayerCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: PayerUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PayerWhereUniqueInput | null;
  update?: PayerUpdateWithoutIntegrationLinksInput | null;
}

export interface PayerUpdateWithWhereUniqueWithoutLocationInput {
  where: PayerWhereUniqueInput;
  data: PayerUpdateWithoutLocationInput;
}

export interface PayerUpdateWithWhereUniqueWithoutOrganizationInput {
  where: PayerWhereUniqueInput;
  data: PayerUpdateWithoutOrganizationInput;
}

export interface PayerUpdateWithoutAccountCoveragesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  plans?: PlanUpdateManyWithoutPayerNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPayerNestedInput | null;
  claims?: ClaimUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  organization?: OrganizationUpdateOneWithoutPayersNestedInput | null;
  location?: LocationUpdateOneWithoutPayersNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPayerNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutPayerNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpdateWithoutClaimsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  plans?: PlanUpdateManyWithoutPayerNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPayerNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  organization?: OrganizationUpdateOneWithoutPayersNestedInput | null;
  location?: LocationUpdateOneWithoutPayersNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPayerNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutPayerNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpdateWithoutInsurancePoliciesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  plans?: PlanUpdateManyWithoutPayerNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPayerNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPayerNestedInput | null;
  claims?: ClaimUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  organization?: OrganizationUpdateOneWithoutPayersNestedInput | null;
  location?: LocationUpdateOneWithoutPayersNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutPayerNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  plans?: PlanUpdateManyWithoutPayerNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPayerNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput | null;
  claims?: ClaimUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  organization?: OrganizationUpdateOneWithoutPayersNestedInput | null;
  location?: LocationUpdateOneWithoutPayersNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPayerNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutPayerNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  plans?: PlanUpdateManyWithoutPayerNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPayerNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPayerNestedInput | null;
  claims?: ClaimUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  organization?: OrganizationUpdateOneWithoutPayersNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPayerNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutPayerNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpdateWithoutOrganizationInNetworkPayersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  plans?: PlanUpdateManyWithoutPayerNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPayerNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPayerNestedInput | null;
  claims?: ClaimUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  organization?: OrganizationUpdateOneWithoutPayersNestedInput | null;
  location?: LocationUpdateOneWithoutPayersNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPayerNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutPayerNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  plans?: PlanUpdateManyWithoutPayerNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPayerNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPayerNestedInput | null;
  claims?: ClaimUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  location?: LocationUpdateOneWithoutPayersNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPayerNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutPayerNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpdateWithoutPayerFeeSchedulesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  plans?: PlanUpdateManyWithoutPayerNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPayerNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPayerNestedInput | null;
  claims?: ClaimUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  organization?: OrganizationUpdateOneWithoutPayersNestedInput | null;
  location?: LocationUpdateOneWithoutPayersNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPayerNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpdateWithoutPayerProviderServiceConfigurationOverridesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  plans?: PlanUpdateManyWithoutPayerNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPayerNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPayerNestedInput | null;
  claims?: ClaimUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  organization?: OrganizationUpdateOneWithoutPayersNestedInput | null;
  location?: LocationUpdateOneWithoutPayersNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPayerNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutPayerNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpdateWithoutPlansInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPayerNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPayerNestedInput | null;
  claims?: ClaimUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  organization?: OrganizationUpdateOneWithoutPayersNestedInput | null;
  location?: LocationUpdateOneWithoutPayersNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPayerNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutPayerNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpdateWithoutProviderNetworkParticipationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  excludedFromBenefitsWorkflow?: BoolFieldUpdateOperationsInput | null;
  plans?: PlanUpdateManyWithoutPayerNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPayerNestedInput | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerUpdateManyWithoutPayerNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutPayerNestedInput | null;
  claims?: ClaimUpdateManyWithoutPayerNestedInput | null;
  tradingPartner?: TradingPartnerUpdateOneWithoutPayersNestedInput | null;
  organization?: OrganizationUpdateOneWithoutPayersNestedInput | null;
  location?: LocationUpdateOneWithoutPayersNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPayerNestedInput | null;
  payerFeeSchedules?: PayerFeeSchedulesUpdateManyWithoutPayerNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerNestedInput | null;
}

export interface PayerUpsertWithWhereUniqueWithoutLocationInput {
  where: PayerWhereUniqueInput;
  update: PayerUpdateWithoutLocationInput;
  create: PayerCreateWithoutLocationInput;
}

export interface PayerUpsertWithWhereUniqueWithoutOrganizationInput {
  where: PayerWhereUniqueInput;
  update: PayerUpdateWithoutOrganizationInput;
  create: PayerCreateWithoutOrganizationInput;
}

export interface PayerUpsertWithoutAccountCoveragesInput {
  update: PayerUpdateWithoutAccountCoveragesInput;
  create: PayerCreateWithoutAccountCoveragesInput;
}

export interface PayerUpsertWithoutClaimsInput {
  update: PayerUpdateWithoutClaimsInput;
  create: PayerCreateWithoutClaimsInput;
}

export interface PayerUpsertWithoutInsurancePoliciesInput {
  update: PayerUpdateWithoutInsurancePoliciesInput;
  create: PayerCreateWithoutInsurancePoliciesInput;
}

export interface PayerUpsertWithoutIntegrationLinksInput {
  update: PayerUpdateWithoutIntegrationLinksInput;
  create: PayerCreateWithoutIntegrationLinksInput;
}

export interface PayerUpsertWithoutOrganizationInNetworkPayersInput {
  update: PayerUpdateWithoutOrganizationInNetworkPayersInput;
  create: PayerCreateWithoutOrganizationInNetworkPayersInput;
}

export interface PayerUpsertWithoutPayerFeeSchedulesInput {
  update: PayerUpdateWithoutPayerFeeSchedulesInput;
  create: PayerCreateWithoutPayerFeeSchedulesInput;
}

export interface PayerUpsertWithoutPayerProviderServiceConfigurationOverridesInput {
  update: PayerUpdateWithoutPayerProviderServiceConfigurationOverridesInput;
  create: PayerCreateWithoutPayerProviderServiceConfigurationOverridesInput;
}

export interface PayerUpsertWithoutPlansInput {
  update: PayerUpdateWithoutPlansInput;
  create: PayerCreateWithoutPlansInput;
}

export interface PayerUpsertWithoutProviderNetworkParticipationsInput {
  update: PayerUpdateWithoutProviderNetworkParticipationsInput;
  create: PayerCreateWithoutProviderNetworkParticipationsInput;
}

export interface PayerWhereInput {
  AND?: PayerWhereInput[] | null;
  OR?: PayerWhereInput[] | null;
  NOT?: PayerWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  changeTradingPartnerId?: StringNullableFilter | null;
  changeEligibilityPortalId?: StringNullableFilter | null;
  changeEligibility?: BoolNullableFilter | null;
  changeClaimStatus?: BoolNullableFilter | null;
  changeEligibilityConnectivityType?: EnumChangePayerConnectivityTypeNullableFilter | null;
  changeClaimStatusConnectivityType?: EnumChangePayerConnectivityTypeNullableFilter | null;
  name?: StringFilter | null;
  excludedFromBenefitsWorkflow?: BoolFilter | null;
  tradingPartnerId?: UuidNullableFilter | null;
  organizationId?: UuidNullableFilter | null;
  locationId?: UuidNullableFilter | null;
  plans?: PlanListRelationFilter | null;
  accountCoverages?: AccountCoverageListRelationFilter | null;
  organizationInNetworkPayers?: OrganizationInNetworkPayerListRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
  claims?: ClaimListRelationFilter | null;
  tradingPartner?: TradingPartnerRelationFilter | null;
  organization?: OrganizationRelationFilter | null;
  location?: LocationRelationFilter | null;
  insurancePolicies?: InsurancePolicyListRelationFilter | null;
  payerFeeSchedules?: PayerFeeSchedulesListRelationFilter | null;
  providerNetworkParticipations?: ProviderNetworkParticipationListRelationFilter | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideListRelationFilter | null;
}

export interface PayerWhereUniqueInput {
  id?: string | null;
}

export interface PaymentCreateManyPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: PaymentType;
  method?: PaymentMethod | null;
  postingStatus?: PostingStatus | null;
  postedAt?: any | null;
  transactionId: string;
  stripeConnectedAccountId?: string | null;
  refundingPaymentId?: string | null;
}

export interface PaymentCreateManyPaymentIntentInputEnvelope {
  data: PaymentCreateManyPaymentIntentInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentCreateManyRefundingPaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: PaymentType;
  method?: PaymentMethod | null;
  postingStatus?: PostingStatus | null;
  postedAt?: any | null;
  transactionId: string;
  paymentIntentId?: string | null;
  stripeConnectedAccountId?: string | null;
}

export interface PaymentCreateManyRefundingPaymentInputEnvelope {
  data: PaymentCreateManyRefundingPaymentInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentCreateManyStripeConnectedAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: PaymentType;
  method?: PaymentMethod | null;
  postingStatus?: PostingStatus | null;
  postedAt?: any | null;
  transactionId: string;
  paymentIntentId?: string | null;
  refundingPaymentId?: string | null;
}

export interface PaymentCreateManyStripeConnectedAccountInputEnvelope {
  data: PaymentCreateManyStripeConnectedAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentCreateNestedManyWithoutPaymentIntentInput {
  create?: PaymentCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutPaymentIntentInput[] | null;
  createMany?: PaymentCreateManyPaymentIntentInputEnvelope | null;
  connect?: PaymentWhereUniqueInput[] | null;
}

export interface PaymentCreateNestedManyWithoutPaymentIntentRefundsInput {
  create?: PaymentCreateWithoutPaymentIntentRefundsInput[] | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutPaymentIntentRefundsInput[] | null;
  connect?: PaymentWhereUniqueInput[] | null;
}

export interface PaymentCreateNestedManyWithoutRefundingPaymentInput {
  create?: PaymentCreateWithoutRefundingPaymentInput[] | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutRefundingPaymentInput[] | null;
  createMany?: PaymentCreateManyRefundingPaymentInputEnvelope | null;
  connect?: PaymentWhereUniqueInput[] | null;
}

export interface PaymentCreateNestedManyWithoutStripeConnectedAccountInput {
  create?: PaymentCreateWithoutStripeConnectedAccountInput[] | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutStripeConnectedAccountInput[] | null;
  createMany?: PaymentCreateManyStripeConnectedAccountInputEnvelope | null;
  connect?: PaymentWhereUniqueInput[] | null;
}

export interface PaymentCreateNestedOneWithoutRefundedByPaymentsInput {
  create?: PaymentCreateWithoutRefundedByPaymentsInput | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutRefundedByPaymentsInput | null;
  connect?: PaymentWhereUniqueInput | null;
}

export interface PaymentCreateNestedOneWithoutStripeFeesInput {
  create?: PaymentCreateWithoutStripeFeesInput | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutStripeFeesInput | null;
  connect?: PaymentWhereUniqueInput | null;
}

export interface PaymentCreateNestedOneWithoutStripePaymentTransferInput {
  create?: PaymentCreateWithoutStripePaymentTransferInput | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutStripePaymentTransferInput | null;
  connect?: PaymentWhereUniqueInput | null;
}

export interface PaymentCreateNestedOneWithoutTransactionInput {
  create?: PaymentCreateWithoutTransactionInput | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutTransactionInput | null;
  connect?: PaymentWhereUniqueInput | null;
}

export interface PaymentCreateOrConnectWithoutPaymentIntentInput {
  where: PaymentWhereUniqueInput;
  create: PaymentCreateWithoutPaymentIntentInput;
}

export interface PaymentCreateOrConnectWithoutPaymentIntentRefundsInput {
  where: PaymentWhereUniqueInput;
  create: PaymentCreateWithoutPaymentIntentRefundsInput;
}

export interface PaymentCreateOrConnectWithoutRefundedByPaymentsInput {
  where: PaymentWhereUniqueInput;
  create: PaymentCreateWithoutRefundedByPaymentsInput;
}

export interface PaymentCreateOrConnectWithoutRefundingPaymentInput {
  where: PaymentWhereUniqueInput;
  create: PaymentCreateWithoutRefundingPaymentInput;
}

export interface PaymentCreateOrConnectWithoutStripeConnectedAccountInput {
  where: PaymentWhereUniqueInput;
  create: PaymentCreateWithoutStripeConnectedAccountInput;
}

export interface PaymentCreateOrConnectWithoutStripeFeesInput {
  where: PaymentWhereUniqueInput;
  create: PaymentCreateWithoutStripeFeesInput;
}

export interface PaymentCreateOrConnectWithoutStripePaymentTransferInput {
  where: PaymentWhereUniqueInput;
  create: PaymentCreateWithoutStripePaymentTransferInput;
}

export interface PaymentCreateOrConnectWithoutTransactionInput {
  where: PaymentWhereUniqueInput;
  create: PaymentCreateWithoutTransactionInput;
}

export interface PaymentCreateWithoutPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: PaymentType;
  method?: PaymentMethod | null;
  postingStatus?: PostingStatus | null;
  postedAt?: any | null;
  transaction: TransactionCreateNestedOneWithoutPaymentInput;
  stripeConnectedAccount?: StripeConnectedAccountCreateNestedOneWithoutPaymentsInput | null;
  stripePaymentTransfer?: StripePaymentTransferCreateNestedOneWithoutPaymentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentsInput | null;
  refundingPayment?: PaymentCreateNestedOneWithoutRefundedByPaymentsInput | null;
  refundedByPayments?: PaymentCreateNestedManyWithoutRefundingPaymentInput | null;
}

export interface PaymentCreateWithoutPaymentIntentRefundsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: PaymentType;
  method?: PaymentMethod | null;
  postingStatus?: PostingStatus | null;
  postedAt?: any | null;
  transaction: TransactionCreateNestedOneWithoutPaymentInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentsInput | null;
  stripeConnectedAccount?: StripeConnectedAccountCreateNestedOneWithoutPaymentsInput | null;
  stripePaymentTransfer?: StripePaymentTransferCreateNestedOneWithoutPaymentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentInput | null;
  refundingPayment?: PaymentCreateNestedOneWithoutRefundedByPaymentsInput | null;
  refundedByPayments?: PaymentCreateNestedManyWithoutRefundingPaymentInput | null;
}

export interface PaymentCreateWithoutRefundedByPaymentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: PaymentType;
  method?: PaymentMethod | null;
  postingStatus?: PostingStatus | null;
  postedAt?: any | null;
  transaction: TransactionCreateNestedOneWithoutPaymentInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentsInput | null;
  stripeConnectedAccount?: StripeConnectedAccountCreateNestedOneWithoutPaymentsInput | null;
  stripePaymentTransfer?: StripePaymentTransferCreateNestedOneWithoutPaymentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentsInput | null;
  refundingPayment?: PaymentCreateNestedOneWithoutRefundedByPaymentsInput | null;
}

export interface PaymentCreateWithoutRefundingPaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: PaymentType;
  method?: PaymentMethod | null;
  postingStatus?: PostingStatus | null;
  postedAt?: any | null;
  transaction: TransactionCreateNestedOneWithoutPaymentInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentsInput | null;
  stripeConnectedAccount?: StripeConnectedAccountCreateNestedOneWithoutPaymentsInput | null;
  stripePaymentTransfer?: StripePaymentTransferCreateNestedOneWithoutPaymentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentsInput | null;
  refundedByPayments?: PaymentCreateNestedManyWithoutRefundingPaymentInput | null;
}

export interface PaymentCreateWithoutStripeConnectedAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: PaymentType;
  method?: PaymentMethod | null;
  postingStatus?: PostingStatus | null;
  postedAt?: any | null;
  transaction: TransactionCreateNestedOneWithoutPaymentInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentsInput | null;
  stripePaymentTransfer?: StripePaymentTransferCreateNestedOneWithoutPaymentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentsInput | null;
  refundingPayment?: PaymentCreateNestedOneWithoutRefundedByPaymentsInput | null;
  refundedByPayments?: PaymentCreateNestedManyWithoutRefundingPaymentInput | null;
}

export interface PaymentCreateWithoutStripeFeesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: PaymentType;
  method?: PaymentMethod | null;
  postingStatus?: PostingStatus | null;
  postedAt?: any | null;
  transaction: TransactionCreateNestedOneWithoutPaymentInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentsInput | null;
  stripeConnectedAccount?: StripeConnectedAccountCreateNestedOneWithoutPaymentsInput | null;
  stripePaymentTransfer?: StripePaymentTransferCreateNestedOneWithoutPaymentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentsInput | null;
  refundingPayment?: PaymentCreateNestedOneWithoutRefundedByPaymentsInput | null;
  refundedByPayments?: PaymentCreateNestedManyWithoutRefundingPaymentInput | null;
}

export interface PaymentCreateWithoutStripePaymentTransferInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: PaymentType;
  method?: PaymentMethod | null;
  postingStatus?: PostingStatus | null;
  postedAt?: any | null;
  transaction: TransactionCreateNestedOneWithoutPaymentInput;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentsInput | null;
  stripeConnectedAccount?: StripeConnectedAccountCreateNestedOneWithoutPaymentsInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentsInput | null;
  refundingPayment?: PaymentCreateNestedOneWithoutRefundedByPaymentsInput | null;
  refundedByPayments?: PaymentCreateNestedManyWithoutRefundingPaymentInput | null;
}

export interface PaymentCreateWithoutTransactionInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: PaymentType;
  method?: PaymentMethod | null;
  postingStatus?: PostingStatus | null;
  postedAt?: any | null;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentsInput | null;
  stripeConnectedAccount?: StripeConnectedAccountCreateNestedOneWithoutPaymentsInput | null;
  stripePaymentTransfer?: StripePaymentTransferCreateNestedOneWithoutPaymentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentsInput | null;
  refundingPayment?: PaymentCreateNestedOneWithoutRefundedByPaymentsInput | null;
  refundedByPayments?: PaymentCreateNestedManyWithoutRefundingPaymentInput | null;
}

export interface PaymentIntentBillCreateManyBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  paymentIntentId: string;
}

export interface PaymentIntentBillCreateManyBillInputEnvelope {
  data: PaymentIntentBillCreateManyBillInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentIntentBillCreateManyPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  billId: string;
}

export interface PaymentIntentBillCreateManyPaymentIntentInputEnvelope {
  data: PaymentIntentBillCreateManyPaymentIntentInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentIntentBillCreateNestedManyWithoutBillInput {
  create?: PaymentIntentBillCreateWithoutBillInput[] | null;
  connectOrCreate?: PaymentIntentBillCreateOrConnectWithoutBillInput[] | null;
  createMany?: PaymentIntentBillCreateManyBillInputEnvelope | null;
  connect?: PaymentIntentBillWhereUniqueInput[] | null;
}

export interface PaymentIntentBillCreateNestedManyWithoutPaymentIntentInput {
  create?: PaymentIntentBillCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: PaymentIntentBillCreateOrConnectWithoutPaymentIntentInput[] | null;
  createMany?: PaymentIntentBillCreateManyPaymentIntentInputEnvelope | null;
  connect?: PaymentIntentBillWhereUniqueInput[] | null;
}

export interface PaymentIntentBillCreateOrConnectWithoutBillInput {
  where: PaymentIntentBillWhereUniqueInput;
  create: PaymentIntentBillCreateWithoutBillInput;
}

export interface PaymentIntentBillCreateOrConnectWithoutPaymentIntentInput {
  where: PaymentIntentBillWhereUniqueInput;
  create: PaymentIntentBillCreateWithoutPaymentIntentInput;
}

export interface PaymentIntentBillCreateWithoutBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  paymentIntent: PaymentIntentCreateNestedOneWithoutPaymentIntentBillsInput;
}

export interface PaymentIntentBillCreateWithoutPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  bill: BillCreateNestedOneWithoutPaymentIntentBillsInput;
}

export interface PaymentIntentBillListRelationFilter {
  every?: PaymentIntentBillWhereInput | null;
  some?: PaymentIntentBillWhereInput | null;
  none?: PaymentIntentBillWhereInput | null;
}

export interface PaymentIntentBillOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PaymentIntentBillScalarWhereInput {
  AND?: PaymentIntentBillScalarWhereInput[] | null;
  OR?: PaymentIntentBillScalarWhereInput[] | null;
  NOT?: PaymentIntentBillScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  amount?: IntFilter | null;
  billId?: UuidFilter | null;
  paymentIntentId?: UuidFilter | null;
}

export interface PaymentIntentBillUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
}

export interface PaymentIntentBillUpdateManyWithWhereWithoutBillInput {
  where: PaymentIntentBillScalarWhereInput;
  data: PaymentIntentBillUpdateManyMutationInput;
}

export interface PaymentIntentBillUpdateManyWithWhereWithoutPaymentIntentInput {
  where: PaymentIntentBillScalarWhereInput;
  data: PaymentIntentBillUpdateManyMutationInput;
}

export interface PaymentIntentBillUpdateManyWithoutBillNestedInput {
  create?: PaymentIntentBillCreateWithoutBillInput[] | null;
  connectOrCreate?: PaymentIntentBillCreateOrConnectWithoutBillInput[] | null;
  upsert?: PaymentIntentBillUpsertWithWhereUniqueWithoutBillInput[] | null;
  createMany?: PaymentIntentBillCreateManyBillInputEnvelope | null;
  set?: PaymentIntentBillWhereUniqueInput[] | null;
  disconnect?: PaymentIntentBillWhereUniqueInput[] | null;
  delete?: PaymentIntentBillWhereUniqueInput[] | null;
  connect?: PaymentIntentBillWhereUniqueInput[] | null;
  update?: PaymentIntentBillUpdateWithWhereUniqueWithoutBillInput[] | null;
  updateMany?: PaymentIntentBillUpdateManyWithWhereWithoutBillInput[] | null;
  deleteMany?: PaymentIntentBillScalarWhereInput[] | null;
}

export interface PaymentIntentBillUpdateManyWithoutPaymentIntentNestedInput {
  create?: PaymentIntentBillCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: PaymentIntentBillCreateOrConnectWithoutPaymentIntentInput[] | null;
  upsert?: PaymentIntentBillUpsertWithWhereUniqueWithoutPaymentIntentInput[] | null;
  createMany?: PaymentIntentBillCreateManyPaymentIntentInputEnvelope | null;
  set?: PaymentIntentBillWhereUniqueInput[] | null;
  disconnect?: PaymentIntentBillWhereUniqueInput[] | null;
  delete?: PaymentIntentBillWhereUniqueInput[] | null;
  connect?: PaymentIntentBillWhereUniqueInput[] | null;
  update?: PaymentIntentBillUpdateWithWhereUniqueWithoutPaymentIntentInput[] | null;
  updateMany?: PaymentIntentBillUpdateManyWithWhereWithoutPaymentIntentInput[] | null;
  deleteMany?: PaymentIntentBillScalarWhereInput[] | null;
}

export interface PaymentIntentBillUpdateWithWhereUniqueWithoutBillInput {
  where: PaymentIntentBillWhereUniqueInput;
  data: PaymentIntentBillUpdateWithoutBillInput;
}

export interface PaymentIntentBillUpdateWithWhereUniqueWithoutPaymentIntentInput {
  where: PaymentIntentBillWhereUniqueInput;
  data: PaymentIntentBillUpdateWithoutPaymentIntentInput;
}

export interface PaymentIntentBillUpdateWithoutBillInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  paymentIntent?: PaymentIntentUpdateOneRequiredWithoutPaymentIntentBillsNestedInput | null;
}

export interface PaymentIntentBillUpdateWithoutPaymentIntentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  bill?: BillUpdateOneRequiredWithoutPaymentIntentBillsNestedInput | null;
}

export interface PaymentIntentBillUpsertWithWhereUniqueWithoutBillInput {
  where: PaymentIntentBillWhereUniqueInput;
  update: PaymentIntentBillUpdateWithoutBillInput;
  create: PaymentIntentBillCreateWithoutBillInput;
}

export interface PaymentIntentBillUpsertWithWhereUniqueWithoutPaymentIntentInput {
  where: PaymentIntentBillWhereUniqueInput;
  update: PaymentIntentBillUpdateWithoutPaymentIntentInput;
  create: PaymentIntentBillCreateWithoutPaymentIntentInput;
}

export interface PaymentIntentBillWhereInput {
  AND?: PaymentIntentBillWhereInput[] | null;
  OR?: PaymentIntentBillWhereInput[] | null;
  NOT?: PaymentIntentBillWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  amount?: IntFilter | null;
  billId?: UuidFilter | null;
  paymentIntentId?: UuidFilter | null;
  bill?: BillRelationFilter | null;
  paymentIntent?: PaymentIntentRelationFilter | null;
}

export interface PaymentIntentBillWhereUniqueInput {
  id?: string | null;
}

export interface PaymentIntentChargeCreateManyChargeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount?: number | null;
  paymentIntentId: string;
}

export interface PaymentIntentChargeCreateManyChargeInputEnvelope {
  data: PaymentIntentChargeCreateManyChargeInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentIntentChargeCreateManyPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount?: number | null;
  chargeId: string;
}

export interface PaymentIntentChargeCreateManyPaymentIntentInputEnvelope {
  data: PaymentIntentChargeCreateManyPaymentIntentInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentIntentChargeCreateNestedManyWithoutChargeInput {
  create?: PaymentIntentChargeCreateWithoutChargeInput[] | null;
  connectOrCreate?: PaymentIntentChargeCreateOrConnectWithoutChargeInput[] | null;
  createMany?: PaymentIntentChargeCreateManyChargeInputEnvelope | null;
  connect?: PaymentIntentChargeWhereUniqueInput[] | null;
}

export interface PaymentIntentChargeCreateNestedManyWithoutPaymentIntentInput {
  create?: PaymentIntentChargeCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: PaymentIntentChargeCreateOrConnectWithoutPaymentIntentInput[] | null;
  createMany?: PaymentIntentChargeCreateManyPaymentIntentInputEnvelope | null;
  connect?: PaymentIntentChargeWhereUniqueInput[] | null;
}

export interface PaymentIntentChargeCreateOrConnectWithoutChargeInput {
  where: PaymentIntentChargeWhereUniqueInput;
  create: PaymentIntentChargeCreateWithoutChargeInput;
}

export interface PaymentIntentChargeCreateOrConnectWithoutPaymentIntentInput {
  where: PaymentIntentChargeWhereUniqueInput;
  create: PaymentIntentChargeCreateWithoutPaymentIntentInput;
}

export interface PaymentIntentChargeCreateWithoutChargeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount?: number | null;
  paymentIntent: PaymentIntentCreateNestedOneWithoutPaymentIntentChargesInput;
}

export interface PaymentIntentChargeCreateWithoutPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount?: number | null;
  charge: ChargeCreateNestedOneWithoutPaymentIntentChargesInput;
}

export interface PaymentIntentChargeListRelationFilter {
  every?: PaymentIntentChargeWhereInput | null;
  some?: PaymentIntentChargeWhereInput | null;
  none?: PaymentIntentChargeWhereInput | null;
}

export interface PaymentIntentChargeScalarWhereInput {
  AND?: PaymentIntentChargeScalarWhereInput[] | null;
  OR?: PaymentIntentChargeScalarWhereInput[] | null;
  NOT?: PaymentIntentChargeScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  amount?: IntNullableFilter | null;
  chargeId?: UuidFilter | null;
  paymentIntentId?: UuidFilter | null;
}

export interface PaymentIntentChargeUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
}

export interface PaymentIntentChargeUpdateManyWithWhereWithoutChargeInput {
  where: PaymentIntentChargeScalarWhereInput;
  data: PaymentIntentChargeUpdateManyMutationInput;
}

export interface PaymentIntentChargeUpdateManyWithWhereWithoutPaymentIntentInput {
  where: PaymentIntentChargeScalarWhereInput;
  data: PaymentIntentChargeUpdateManyMutationInput;
}

export interface PaymentIntentChargeUpdateManyWithoutChargeNestedInput {
  create?: PaymentIntentChargeCreateWithoutChargeInput[] | null;
  connectOrCreate?: PaymentIntentChargeCreateOrConnectWithoutChargeInput[] | null;
  upsert?: PaymentIntentChargeUpsertWithWhereUniqueWithoutChargeInput[] | null;
  createMany?: PaymentIntentChargeCreateManyChargeInputEnvelope | null;
  set?: PaymentIntentChargeWhereUniqueInput[] | null;
  disconnect?: PaymentIntentChargeWhereUniqueInput[] | null;
  delete?: PaymentIntentChargeWhereUniqueInput[] | null;
  connect?: PaymentIntentChargeWhereUniqueInput[] | null;
  update?: PaymentIntentChargeUpdateWithWhereUniqueWithoutChargeInput[] | null;
  updateMany?: PaymentIntentChargeUpdateManyWithWhereWithoutChargeInput[] | null;
  deleteMany?: PaymentIntentChargeScalarWhereInput[] | null;
}

export interface PaymentIntentChargeUpdateManyWithoutPaymentIntentNestedInput {
  create?: PaymentIntentChargeCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: PaymentIntentChargeCreateOrConnectWithoutPaymentIntentInput[] | null;
  upsert?: PaymentIntentChargeUpsertWithWhereUniqueWithoutPaymentIntentInput[] | null;
  createMany?: PaymentIntentChargeCreateManyPaymentIntentInputEnvelope | null;
  set?: PaymentIntentChargeWhereUniqueInput[] | null;
  disconnect?: PaymentIntentChargeWhereUniqueInput[] | null;
  delete?: PaymentIntentChargeWhereUniqueInput[] | null;
  connect?: PaymentIntentChargeWhereUniqueInput[] | null;
  update?: PaymentIntentChargeUpdateWithWhereUniqueWithoutPaymentIntentInput[] | null;
  updateMany?: PaymentIntentChargeUpdateManyWithWhereWithoutPaymentIntentInput[] | null;
  deleteMany?: PaymentIntentChargeScalarWhereInput[] | null;
}

export interface PaymentIntentChargeUpdateWithWhereUniqueWithoutChargeInput {
  where: PaymentIntentChargeWhereUniqueInput;
  data: PaymentIntentChargeUpdateWithoutChargeInput;
}

export interface PaymentIntentChargeUpdateWithWhereUniqueWithoutPaymentIntentInput {
  where: PaymentIntentChargeWhereUniqueInput;
  data: PaymentIntentChargeUpdateWithoutPaymentIntentInput;
}

export interface PaymentIntentChargeUpdateWithoutChargeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  paymentIntent?: PaymentIntentUpdateOneRequiredWithoutPaymentIntentChargesNestedInput | null;
}

export interface PaymentIntentChargeUpdateWithoutPaymentIntentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  charge?: ChargeUpdateOneRequiredWithoutPaymentIntentChargesNestedInput | null;
}

export interface PaymentIntentChargeUpsertWithWhereUniqueWithoutChargeInput {
  where: PaymentIntentChargeWhereUniqueInput;
  update: PaymentIntentChargeUpdateWithoutChargeInput;
  create: PaymentIntentChargeCreateWithoutChargeInput;
}

export interface PaymentIntentChargeUpsertWithWhereUniqueWithoutPaymentIntentInput {
  where: PaymentIntentChargeWhereUniqueInput;
  update: PaymentIntentChargeUpdateWithoutPaymentIntentInput;
  create: PaymentIntentChargeCreateWithoutPaymentIntentInput;
}

export interface PaymentIntentChargeWhereInput {
  AND?: PaymentIntentChargeWhereInput[] | null;
  OR?: PaymentIntentChargeWhereInput[] | null;
  NOT?: PaymentIntentChargeWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  amount?: IntNullableFilter | null;
  chargeId?: UuidFilter | null;
  paymentIntentId?: UuidFilter | null;
  charge?: ChargeRelationFilter | null;
  paymentIntent?: PaymentIntentRelationFilter | null;
}

export interface PaymentIntentChargeWhereUniqueInput {
  id?: string | null;
}

export interface PaymentIntentCreateManyPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  patientPaymentMethodId?: string | null;
  forcePost?: boolean | null;
}

export interface PaymentIntentCreateManyPatientInputEnvelope {
  data: PaymentIntentCreateManyPatientInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentIntentCreateManyPatientPaymentMethodInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  patientId: string;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
}

export interface PaymentIntentCreateManyPatientPaymentMethodInputEnvelope {
  data: PaymentIntentCreateManyPatientPaymentMethodInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentIntentCreateNestedManyWithoutPatientInput {
  create?: PaymentIntentCreateWithoutPatientInput[] | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPatientInput[] | null;
  createMany?: PaymentIntentCreateManyPatientInputEnvelope | null;
  connect?: PaymentIntentWhereUniqueInput[] | null;
}

export interface PaymentIntentCreateNestedManyWithoutPatientPaymentMethodInput {
  create?: PaymentIntentCreateWithoutPatientPaymentMethodInput[] | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPatientPaymentMethodInput[] | null;
  createMany?: PaymentIntentCreateManyPatientPaymentMethodInputEnvelope | null;
  connect?: PaymentIntentWhereUniqueInput[] | null;
}

export interface PaymentIntentCreateNestedOneWithoutBillPaymentsInput {
  create?: PaymentIntentCreateWithoutBillPaymentsInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutBillPaymentsInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
}

export interface PaymentIntentCreateNestedOneWithoutChargePaymentsInput {
  create?: PaymentIntentCreateWithoutChargePaymentsInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutChargePaymentsInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
}

export interface PaymentIntentCreateNestedOneWithoutPaymentIntentBillsInput {
  create?: PaymentIntentCreateWithoutPaymentIntentBillsInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPaymentIntentBillsInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
}

export interface PaymentIntentCreateNestedOneWithoutPaymentIntentChargesInput {
  create?: PaymentIntentCreateWithoutPaymentIntentChargesInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPaymentIntentChargesInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
}

export interface PaymentIntentCreateNestedOneWithoutPaymentIntentRefundsInput {
  create?: PaymentIntentCreateWithoutPaymentIntentRefundsInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPaymentIntentRefundsInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
}

export interface PaymentIntentCreateNestedOneWithoutPaymentRequestInput {
  create?: PaymentIntentCreateWithoutPaymentRequestInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPaymentRequestInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
}

export interface PaymentIntentCreateNestedOneWithoutPaymentsInput {
  create?: PaymentIntentCreateWithoutPaymentsInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPaymentsInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
}

export interface PaymentIntentCreateNestedOneWithoutStripeFeesInput {
  create?: PaymentIntentCreateWithoutStripeFeesInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutStripeFeesInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
}

export interface PaymentIntentCreateNestedOneWithoutStripeTransfersInput {
  create?: PaymentIntentCreateWithoutStripeTransfersInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutStripeTransfersInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
}

export interface PaymentIntentCreateOrConnectWithoutBillPaymentsInput {
  where: PaymentIntentWhereUniqueInput;
  create: PaymentIntentCreateWithoutBillPaymentsInput;
}

export interface PaymentIntentCreateOrConnectWithoutChargePaymentsInput {
  where: PaymentIntentWhereUniqueInput;
  create: PaymentIntentCreateWithoutChargePaymentsInput;
}

export interface PaymentIntentCreateOrConnectWithoutPatientInput {
  where: PaymentIntentWhereUniqueInput;
  create: PaymentIntentCreateWithoutPatientInput;
}

export interface PaymentIntentCreateOrConnectWithoutPatientPaymentMethodInput {
  where: PaymentIntentWhereUniqueInput;
  create: PaymentIntentCreateWithoutPatientPaymentMethodInput;
}

export interface PaymentIntentCreateOrConnectWithoutPaymentIntentBillsInput {
  where: PaymentIntentWhereUniqueInput;
  create: PaymentIntentCreateWithoutPaymentIntentBillsInput;
}

export interface PaymentIntentCreateOrConnectWithoutPaymentIntentChargesInput {
  where: PaymentIntentWhereUniqueInput;
  create: PaymentIntentCreateWithoutPaymentIntentChargesInput;
}

export interface PaymentIntentCreateOrConnectWithoutPaymentIntentRefundsInput {
  where: PaymentIntentWhereUniqueInput;
  create: PaymentIntentCreateWithoutPaymentIntentRefundsInput;
}

export interface PaymentIntentCreateOrConnectWithoutPaymentRequestInput {
  where: PaymentIntentWhereUniqueInput;
  create: PaymentIntentCreateWithoutPaymentRequestInput;
}

export interface PaymentIntentCreateOrConnectWithoutPaymentsInput {
  where: PaymentIntentWhereUniqueInput;
  create: PaymentIntentCreateWithoutPaymentsInput;
}

export interface PaymentIntentCreateOrConnectWithoutStripeFeesInput {
  where: PaymentIntentWhereUniqueInput;
  create: PaymentIntentCreateWithoutStripeFeesInput;
}

export interface PaymentIntentCreateOrConnectWithoutStripeTransfersInput {
  where: PaymentIntentWhereUniqueInput;
  create: PaymentIntentCreateWithoutStripeTransfersInput;
}

export interface PaymentIntentCreateWithoutBillPaymentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
  patient: PatientCreateNestedOneWithoutPaymentIntentsInput;
  patientPaymentMethod?: PatientPaymentMethodCreateNestedOneWithoutPaymentIntentsInput | null;
  payments?: PaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutPaymentIntentInput | null;
  chargePayments?: ChargePaymentCreateNestedManyWithoutPaymentIntentInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutPaymentIntentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutPaymentIntentInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutPaymentIntentInput | null;
}

export interface PaymentIntentCreateWithoutChargePaymentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
  patient: PatientCreateNestedOneWithoutPaymentIntentsInput;
  patientPaymentMethod?: PatientPaymentMethodCreateNestedOneWithoutPaymentIntentsInput | null;
  payments?: PaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutPaymentIntentInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutPaymentIntentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutPaymentIntentInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutPaymentIntentInput | null;
}

export interface PaymentIntentCreateWithoutPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
  patientPaymentMethod?: PatientPaymentMethodCreateNestedOneWithoutPaymentIntentsInput | null;
  payments?: PaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutPaymentIntentInput | null;
  chargePayments?: ChargePaymentCreateNestedManyWithoutPaymentIntentInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutPaymentIntentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutPaymentIntentInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutPaymentIntentInput | null;
}

export interface PaymentIntentCreateWithoutPatientPaymentMethodInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
  patient: PatientCreateNestedOneWithoutPaymentIntentsInput;
  payments?: PaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutPaymentIntentInput | null;
  chargePayments?: ChargePaymentCreateNestedManyWithoutPaymentIntentInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutPaymentIntentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutPaymentIntentInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutPaymentIntentInput | null;
}

export interface PaymentIntentCreateWithoutPaymentIntentBillsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
  patient: PatientCreateNestedOneWithoutPaymentIntentsInput;
  patientPaymentMethod?: PatientPaymentMethodCreateNestedOneWithoutPaymentIntentsInput | null;
  payments?: PaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutPaymentIntentInput | null;
  chargePayments?: ChargePaymentCreateNestedManyWithoutPaymentIntentInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutPaymentIntentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentIntentInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutPaymentIntentInput | null;
}

export interface PaymentIntentCreateWithoutPaymentIntentChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
  patient: PatientCreateNestedOneWithoutPaymentIntentsInput;
  patientPaymentMethod?: PatientPaymentMethodCreateNestedOneWithoutPaymentIntentsInput | null;
  payments?: PaymentCreateNestedManyWithoutPaymentIntentInput | null;
  chargePayments?: ChargePaymentCreateNestedManyWithoutPaymentIntentInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutPaymentIntentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutPaymentIntentInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutPaymentIntentInput | null;
}

export interface PaymentIntentCreateWithoutPaymentIntentRefundsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
  patient: PatientCreateNestedOneWithoutPaymentIntentsInput;
  patientPaymentMethod?: PatientPaymentMethodCreateNestedOneWithoutPaymentIntentsInput | null;
  payments?: PaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutPaymentIntentInput | null;
  chargePayments?: ChargePaymentCreateNestedManyWithoutPaymentIntentInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutPaymentIntentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutPaymentIntentInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutPaymentIntentInput | null;
}

export interface PaymentIntentCreateWithoutPaymentRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
  patient: PatientCreateNestedOneWithoutPaymentIntentsInput;
  patientPaymentMethod?: PatientPaymentMethodCreateNestedOneWithoutPaymentIntentsInput | null;
  payments?: PaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutPaymentIntentInput | null;
  chargePayments?: ChargePaymentCreateNestedManyWithoutPaymentIntentInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutPaymentIntentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutPaymentIntentInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentIntentInput | null;
}

export interface PaymentIntentCreateWithoutPaymentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
  patient: PatientCreateNestedOneWithoutPaymentIntentsInput;
  patientPaymentMethod?: PatientPaymentMethodCreateNestedOneWithoutPaymentIntentsInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutPaymentIntentInput | null;
  chargePayments?: ChargePaymentCreateNestedManyWithoutPaymentIntentInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutPaymentIntentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutPaymentIntentInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutPaymentIntentInput | null;
}

export interface PaymentIntentCreateWithoutStripeFeesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
  patient: PatientCreateNestedOneWithoutPaymentIntentsInput;
  patientPaymentMethod?: PatientPaymentMethodCreateNestedOneWithoutPaymentIntentsInput | null;
  payments?: PaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutPaymentIntentInput | null;
  chargePayments?: ChargePaymentCreateNestedManyWithoutPaymentIntentInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutPaymentIntentInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutPaymentIntentInput | null;
}

export interface PaymentIntentCreateWithoutStripeTransfersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  stripePaymentIntentId: string;
  receiptCode?: string | null;
  autoPay?: boolean | null;
  transferGroup?: string | null;
  lastPaymentError?: string | null;
  lastPaymentErrorCode?: string | null;
  forcePost?: boolean | null;
  patient: PatientCreateNestedOneWithoutPaymentIntentsInput;
  patientPaymentMethod?: PatientPaymentMethodCreateNestedOneWithoutPaymentIntentsInput | null;
  payments?: PaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentCharges?: PaymentIntentChargeCreateNestedManyWithoutPaymentIntentInput | null;
  chargePayments?: ChargePaymentCreateNestedManyWithoutPaymentIntentInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentRefunds?: PaymentIntentRefundCreateNestedManyWithoutPaymentIntentInput | null;
  paymentIntentBills?: PaymentIntentBillCreateNestedManyWithoutPaymentIntentInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentIntentInput | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutPaymentIntentInput | null;
}

export interface PaymentIntentListRelationFilter {
  every?: PaymentIntentWhereInput | null;
  some?: PaymentIntentWhereInput | null;
  none?: PaymentIntentWhereInput | null;
}

export interface PaymentIntentOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PaymentIntentRefundCreateManyPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  amount: number;
  stripeRefundId: string;
}

export interface PaymentIntentRefundCreateManyPaymentIntentInputEnvelope {
  data: PaymentIntentRefundCreateManyPaymentIntentInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentIntentRefundCreateNestedManyWithoutPaymentIntentInput {
  create?: PaymentIntentRefundCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: PaymentIntentRefundCreateOrConnectWithoutPaymentIntentInput[] | null;
  createMany?: PaymentIntentRefundCreateManyPaymentIntentInputEnvelope | null;
  connect?: PaymentIntentRefundWhereUniqueInput[] | null;
}

export interface PaymentIntentRefundCreateNestedManyWithoutPaymentsInput {
  create?: PaymentIntentRefundCreateWithoutPaymentsInput[] | null;
  connectOrCreate?: PaymentIntentRefundCreateOrConnectWithoutPaymentsInput[] | null;
  connect?: PaymentIntentRefundWhereUniqueInput[] | null;
}

export interface PaymentIntentRefundCreateOrConnectWithoutPaymentIntentInput {
  where: PaymentIntentRefundWhereUniqueInput;
  create: PaymentIntentRefundCreateWithoutPaymentIntentInput;
}

export interface PaymentIntentRefundCreateOrConnectWithoutPaymentsInput {
  where: PaymentIntentRefundWhereUniqueInput;
  create: PaymentIntentRefundCreateWithoutPaymentsInput;
}

export interface PaymentIntentRefundCreateWithoutPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  amount: number;
  stripeRefundId: string;
  payments?: PaymentCreateNestedManyWithoutPaymentIntentRefundsInput | null;
}

export interface PaymentIntentRefundCreateWithoutPaymentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: string;
  amount: number;
  stripeRefundId: string;
  paymentIntent: PaymentIntentCreateNestedOneWithoutPaymentIntentRefundsInput;
}

export interface PaymentIntentRefundListRelationFilter {
  every?: PaymentIntentRefundWhereInput | null;
  some?: PaymentIntentRefundWhereInput | null;
  none?: PaymentIntentRefundWhereInput | null;
}

export interface PaymentIntentRefundScalarWhereInput {
  AND?: PaymentIntentRefundScalarWhereInput[] | null;
  OR?: PaymentIntentRefundScalarWhereInput[] | null;
  NOT?: PaymentIntentRefundScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  status?: StringFilter | null;
  amount?: IntFilter | null;
  stripeRefundId?: StringFilter | null;
  paymentIntentId?: UuidFilter | null;
}

export interface PaymentIntentRefundUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeRefundId?: StringFieldUpdateOperationsInput | null;
}

export interface PaymentIntentRefundUpdateManyWithWhereWithoutPaymentIntentInput {
  where: PaymentIntentRefundScalarWhereInput;
  data: PaymentIntentRefundUpdateManyMutationInput;
}

export interface PaymentIntentRefundUpdateManyWithWhereWithoutPaymentsInput {
  where: PaymentIntentRefundScalarWhereInput;
  data: PaymentIntentRefundUpdateManyMutationInput;
}

export interface PaymentIntentRefundUpdateManyWithoutPaymentIntentNestedInput {
  create?: PaymentIntentRefundCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: PaymentIntentRefundCreateOrConnectWithoutPaymentIntentInput[] | null;
  upsert?: PaymentIntentRefundUpsertWithWhereUniqueWithoutPaymentIntentInput[] | null;
  createMany?: PaymentIntentRefundCreateManyPaymentIntentInputEnvelope | null;
  set?: PaymentIntentRefundWhereUniqueInput[] | null;
  disconnect?: PaymentIntentRefundWhereUniqueInput[] | null;
  delete?: PaymentIntentRefundWhereUniqueInput[] | null;
  connect?: PaymentIntentRefundWhereUniqueInput[] | null;
  update?: PaymentIntentRefundUpdateWithWhereUniqueWithoutPaymentIntentInput[] | null;
  updateMany?: PaymentIntentRefundUpdateManyWithWhereWithoutPaymentIntentInput[] | null;
  deleteMany?: PaymentIntentRefundScalarWhereInput[] | null;
}

export interface PaymentIntentRefundUpdateManyWithoutPaymentsNestedInput {
  create?: PaymentIntentRefundCreateWithoutPaymentsInput[] | null;
  connectOrCreate?: PaymentIntentRefundCreateOrConnectWithoutPaymentsInput[] | null;
  upsert?: PaymentIntentRefundUpsertWithWhereUniqueWithoutPaymentsInput[] | null;
  set?: PaymentIntentRefundWhereUniqueInput[] | null;
  disconnect?: PaymentIntentRefundWhereUniqueInput[] | null;
  delete?: PaymentIntentRefundWhereUniqueInput[] | null;
  connect?: PaymentIntentRefundWhereUniqueInput[] | null;
  update?: PaymentIntentRefundUpdateWithWhereUniqueWithoutPaymentsInput[] | null;
  updateMany?: PaymentIntentRefundUpdateManyWithWhereWithoutPaymentsInput[] | null;
  deleteMany?: PaymentIntentRefundScalarWhereInput[] | null;
}

export interface PaymentIntentRefundUpdateWithWhereUniqueWithoutPaymentIntentInput {
  where: PaymentIntentRefundWhereUniqueInput;
  data: PaymentIntentRefundUpdateWithoutPaymentIntentInput;
}

export interface PaymentIntentRefundUpdateWithWhereUniqueWithoutPaymentsInput {
  where: PaymentIntentRefundWhereUniqueInput;
  data: PaymentIntentRefundUpdateWithoutPaymentsInput;
}

export interface PaymentIntentRefundUpdateWithoutPaymentIntentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeRefundId?: StringFieldUpdateOperationsInput | null;
  payments?: PaymentUpdateManyWithoutPaymentIntentRefundsNestedInput | null;
}

export interface PaymentIntentRefundUpdateWithoutPaymentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeRefundId?: StringFieldUpdateOperationsInput | null;
  paymentIntent?: PaymentIntentUpdateOneRequiredWithoutPaymentIntentRefundsNestedInput | null;
}

export interface PaymentIntentRefundUpsertWithWhereUniqueWithoutPaymentIntentInput {
  where: PaymentIntentRefundWhereUniqueInput;
  update: PaymentIntentRefundUpdateWithoutPaymentIntentInput;
  create: PaymentIntentRefundCreateWithoutPaymentIntentInput;
}

export interface PaymentIntentRefundUpsertWithWhereUniqueWithoutPaymentsInput {
  where: PaymentIntentRefundWhereUniqueInput;
  update: PaymentIntentRefundUpdateWithoutPaymentsInput;
  create: PaymentIntentRefundCreateWithoutPaymentsInput;
}

export interface PaymentIntentRefundWhereInput {
  AND?: PaymentIntentRefundWhereInput[] | null;
  OR?: PaymentIntentRefundWhereInput[] | null;
  NOT?: PaymentIntentRefundWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  status?: StringFilter | null;
  amount?: IntFilter | null;
  stripeRefundId?: StringFilter | null;
  paymentIntentId?: UuidFilter | null;
  paymentIntent?: PaymentIntentRelationFilter | null;
  payments?: PaymentListRelationFilter | null;
}

export interface PaymentIntentRefundWhereUniqueInput {
  id?: string | null;
  stripeRefundId?: string | null;
}

export interface PaymentIntentRelationFilter {
  is?: PaymentIntentWhereInput | null;
  isNot?: PaymentIntentWhereInput | null;
}

export interface PaymentIntentScalarWhereInput {
  AND?: PaymentIntentScalarWhereInput[] | null;
  OR?: PaymentIntentScalarWhereInput[] | null;
  NOT?: PaymentIntentScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  status?: StringFilter | null;
  stripePaymentIntentId?: StringFilter | null;
  receiptCode?: StringNullableFilter | null;
  patientId?: UuidFilter | null;
  autoPay?: BoolFilter | null;
  transferGroup?: StringNullableFilter | null;
  lastPaymentError?: StringNullableFilter | null;
  lastPaymentErrorCode?: StringNullableFilter | null;
  patientPaymentMethodId?: UuidNullableFilter | null;
  forcePost?: BoolFilter | null;
}

export interface PaymentIntentUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
}

export interface PaymentIntentUpdateManyWithWhereWithoutPatientInput {
  where: PaymentIntentScalarWhereInput;
  data: PaymentIntentUpdateManyMutationInput;
}

export interface PaymentIntentUpdateManyWithWhereWithoutPatientPaymentMethodInput {
  where: PaymentIntentScalarWhereInput;
  data: PaymentIntentUpdateManyMutationInput;
}

export interface PaymentIntentUpdateManyWithoutPatientNestedInput {
  create?: PaymentIntentCreateWithoutPatientInput[] | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPatientInput[] | null;
  upsert?: PaymentIntentUpsertWithWhereUniqueWithoutPatientInput[] | null;
  createMany?: PaymentIntentCreateManyPatientInputEnvelope | null;
  set?: PaymentIntentWhereUniqueInput[] | null;
  disconnect?: PaymentIntentWhereUniqueInput[] | null;
  delete?: PaymentIntentWhereUniqueInput[] | null;
  connect?: PaymentIntentWhereUniqueInput[] | null;
  update?: PaymentIntentUpdateWithWhereUniqueWithoutPatientInput[] | null;
  updateMany?: PaymentIntentUpdateManyWithWhereWithoutPatientInput[] | null;
  deleteMany?: PaymentIntentScalarWhereInput[] | null;
}

export interface PaymentIntentUpdateManyWithoutPatientPaymentMethodNestedInput {
  create?: PaymentIntentCreateWithoutPatientPaymentMethodInput[] | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPatientPaymentMethodInput[] | null;
  upsert?: PaymentIntentUpsertWithWhereUniqueWithoutPatientPaymentMethodInput[] | null;
  createMany?: PaymentIntentCreateManyPatientPaymentMethodInputEnvelope | null;
  set?: PaymentIntentWhereUniqueInput[] | null;
  disconnect?: PaymentIntentWhereUniqueInput[] | null;
  delete?: PaymentIntentWhereUniqueInput[] | null;
  connect?: PaymentIntentWhereUniqueInput[] | null;
  update?: PaymentIntentUpdateWithWhereUniqueWithoutPatientPaymentMethodInput[] | null;
  updateMany?: PaymentIntentUpdateManyWithWhereWithoutPatientPaymentMethodInput[] | null;
  deleteMany?: PaymentIntentScalarWhereInput[] | null;
}

export interface PaymentIntentUpdateOneRequiredWithoutPaymentIntentBillsNestedInput {
  create?: PaymentIntentCreateWithoutPaymentIntentBillsInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPaymentIntentBillsInput | null;
  upsert?: PaymentIntentUpsertWithoutPaymentIntentBillsInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
  update?: PaymentIntentUpdateWithoutPaymentIntentBillsInput | null;
}

export interface PaymentIntentUpdateOneRequiredWithoutPaymentIntentChargesNestedInput {
  create?: PaymentIntentCreateWithoutPaymentIntentChargesInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPaymentIntentChargesInput | null;
  upsert?: PaymentIntentUpsertWithoutPaymentIntentChargesInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
  update?: PaymentIntentUpdateWithoutPaymentIntentChargesInput | null;
}

export interface PaymentIntentUpdateOneRequiredWithoutPaymentIntentRefundsNestedInput {
  create?: PaymentIntentCreateWithoutPaymentIntentRefundsInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPaymentIntentRefundsInput | null;
  upsert?: PaymentIntentUpsertWithoutPaymentIntentRefundsInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
  update?: PaymentIntentUpdateWithoutPaymentIntentRefundsInput | null;
}

export interface PaymentIntentUpdateOneRequiredWithoutStripeFeesNestedInput {
  create?: PaymentIntentCreateWithoutStripeFeesInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutStripeFeesInput | null;
  upsert?: PaymentIntentUpsertWithoutStripeFeesInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
  update?: PaymentIntentUpdateWithoutStripeFeesInput | null;
}

export interface PaymentIntentUpdateOneRequiredWithoutStripeTransfersNestedInput {
  create?: PaymentIntentCreateWithoutStripeTransfersInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutStripeTransfersInput | null;
  upsert?: PaymentIntentUpsertWithoutStripeTransfersInput | null;
  connect?: PaymentIntentWhereUniqueInput | null;
  update?: PaymentIntentUpdateWithoutStripeTransfersInput | null;
}

export interface PaymentIntentUpdateOneWithoutBillPaymentsNestedInput {
  create?: PaymentIntentCreateWithoutBillPaymentsInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutBillPaymentsInput | null;
  upsert?: PaymentIntentUpsertWithoutBillPaymentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentIntentWhereUniqueInput | null;
  update?: PaymentIntentUpdateWithoutBillPaymentsInput | null;
}

export interface PaymentIntentUpdateOneWithoutChargePaymentsNestedInput {
  create?: PaymentIntentCreateWithoutChargePaymentsInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutChargePaymentsInput | null;
  upsert?: PaymentIntentUpsertWithoutChargePaymentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentIntentWhereUniqueInput | null;
  update?: PaymentIntentUpdateWithoutChargePaymentsInput | null;
}

export interface PaymentIntentUpdateOneWithoutPaymentRequestNestedInput {
  create?: PaymentIntentCreateWithoutPaymentRequestInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPaymentRequestInput | null;
  upsert?: PaymentIntentUpsertWithoutPaymentRequestInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentIntentWhereUniqueInput | null;
  update?: PaymentIntentUpdateWithoutPaymentRequestInput | null;
}

export interface PaymentIntentUpdateOneWithoutPaymentsNestedInput {
  create?: PaymentIntentCreateWithoutPaymentsInput | null;
  connectOrCreate?: PaymentIntentCreateOrConnectWithoutPaymentsInput | null;
  upsert?: PaymentIntentUpsertWithoutPaymentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentIntentWhereUniqueInput | null;
  update?: PaymentIntentUpdateWithoutPaymentsInput | null;
}

export interface PaymentIntentUpdateWithWhereUniqueWithoutPatientInput {
  where: PaymentIntentWhereUniqueInput;
  data: PaymentIntentUpdateWithoutPatientInput;
}

export interface PaymentIntentUpdateWithWhereUniqueWithoutPatientPaymentMethodInput {
  where: PaymentIntentWhereUniqueInput;
  data: PaymentIntentUpdateWithoutPatientPaymentMethodInput;
}

export interface PaymentIntentUpdateWithoutBillPaymentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentIntentsNestedInput | null;
  patientPaymentMethod?: PatientPaymentMethodUpdateOneWithoutPaymentIntentsNestedInput | null;
  payments?: PaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutPaymentIntentNestedInput | null;
  chargePayments?: ChargePaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutPaymentIntentNestedInput | null;
}

export interface PaymentIntentUpdateWithoutChargePaymentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentIntentsNestedInput | null;
  patientPaymentMethod?: PatientPaymentMethodUpdateOneWithoutPaymentIntentsNestedInput | null;
  payments?: PaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutPaymentIntentNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutPaymentIntentNestedInput | null;
}

export interface PaymentIntentUpdateWithoutPatientInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
  patientPaymentMethod?: PatientPaymentMethodUpdateOneWithoutPaymentIntentsNestedInput | null;
  payments?: PaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutPaymentIntentNestedInput | null;
  chargePayments?: ChargePaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutPaymentIntentNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutPaymentIntentNestedInput | null;
}

export interface PaymentIntentUpdateWithoutPatientPaymentMethodInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentIntentsNestedInput | null;
  payments?: PaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutPaymentIntentNestedInput | null;
  chargePayments?: ChargePaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutPaymentIntentNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutPaymentIntentNestedInput | null;
}

export interface PaymentIntentUpdateWithoutPaymentIntentBillsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentIntentsNestedInput | null;
  patientPaymentMethod?: PatientPaymentMethodUpdateOneWithoutPaymentIntentsNestedInput | null;
  payments?: PaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutPaymentIntentNestedInput | null;
  chargePayments?: ChargePaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentIntentNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutPaymentIntentNestedInput | null;
}

export interface PaymentIntentUpdateWithoutPaymentIntentChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentIntentsNestedInput | null;
  patientPaymentMethod?: PatientPaymentMethodUpdateOneWithoutPaymentIntentsNestedInput | null;
  payments?: PaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  chargePayments?: ChargePaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutPaymentIntentNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutPaymentIntentNestedInput | null;
}

export interface PaymentIntentUpdateWithoutPaymentIntentRefundsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentIntentsNestedInput | null;
  patientPaymentMethod?: PatientPaymentMethodUpdateOneWithoutPaymentIntentsNestedInput | null;
  payments?: PaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutPaymentIntentNestedInput | null;
  chargePayments?: ChargePaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutPaymentIntentNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutPaymentIntentNestedInput | null;
}

export interface PaymentIntentUpdateWithoutPaymentRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentIntentsNestedInput | null;
  patientPaymentMethod?: PatientPaymentMethodUpdateOneWithoutPaymentIntentsNestedInput | null;
  payments?: PaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutPaymentIntentNestedInput | null;
  chargePayments?: ChargePaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutPaymentIntentNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentIntentNestedInput | null;
}

export interface PaymentIntentUpdateWithoutPaymentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentIntentsNestedInput | null;
  patientPaymentMethod?: PatientPaymentMethodUpdateOneWithoutPaymentIntentsNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutPaymentIntentNestedInput | null;
  chargePayments?: ChargePaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutPaymentIntentNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutPaymentIntentNestedInput | null;
}

export interface PaymentIntentUpdateWithoutStripeFeesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentIntentsNestedInput | null;
  patientPaymentMethod?: PatientPaymentMethodUpdateOneWithoutPaymentIntentsNestedInput | null;
  payments?: PaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutPaymentIntentNestedInput | null;
  chargePayments?: ChargePaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutPaymentIntentNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutPaymentIntentNestedInput | null;
}

export interface PaymentIntentUpdateWithoutStripeTransfersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: StringFieldUpdateOperationsInput | null;
  stripePaymentIntentId?: StringFieldUpdateOperationsInput | null;
  receiptCode?: NullableStringFieldUpdateOperationsInput | null;
  autoPay?: BoolFieldUpdateOperationsInput | null;
  transferGroup?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentError?: NullableStringFieldUpdateOperationsInput | null;
  lastPaymentErrorCode?: NullableStringFieldUpdateOperationsInput | null;
  forcePost?: BoolFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentIntentsNestedInput | null;
  patientPaymentMethod?: PatientPaymentMethodUpdateOneWithoutPaymentIntentsNestedInput | null;
  payments?: PaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentCharges?: PaymentIntentChargeUpdateManyWithoutPaymentIntentNestedInput | null;
  chargePayments?: ChargePaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentIntentBills?: PaymentIntentBillUpdateManyWithoutPaymentIntentNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentIntentNestedInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutPaymentIntentNestedInput | null;
}

export interface PaymentIntentUpsertWithWhereUniqueWithoutPatientInput {
  where: PaymentIntentWhereUniqueInput;
  update: PaymentIntentUpdateWithoutPatientInput;
  create: PaymentIntentCreateWithoutPatientInput;
}

export interface PaymentIntentUpsertWithWhereUniqueWithoutPatientPaymentMethodInput {
  where: PaymentIntentWhereUniqueInput;
  update: PaymentIntentUpdateWithoutPatientPaymentMethodInput;
  create: PaymentIntentCreateWithoutPatientPaymentMethodInput;
}

export interface PaymentIntentUpsertWithoutBillPaymentsInput {
  update: PaymentIntentUpdateWithoutBillPaymentsInput;
  create: PaymentIntentCreateWithoutBillPaymentsInput;
}

export interface PaymentIntentUpsertWithoutChargePaymentsInput {
  update: PaymentIntentUpdateWithoutChargePaymentsInput;
  create: PaymentIntentCreateWithoutChargePaymentsInput;
}

export interface PaymentIntentUpsertWithoutPaymentIntentBillsInput {
  update: PaymentIntentUpdateWithoutPaymentIntentBillsInput;
  create: PaymentIntentCreateWithoutPaymentIntentBillsInput;
}

export interface PaymentIntentUpsertWithoutPaymentIntentChargesInput {
  update: PaymentIntentUpdateWithoutPaymentIntentChargesInput;
  create: PaymentIntentCreateWithoutPaymentIntentChargesInput;
}

export interface PaymentIntentUpsertWithoutPaymentIntentRefundsInput {
  update: PaymentIntentUpdateWithoutPaymentIntentRefundsInput;
  create: PaymentIntentCreateWithoutPaymentIntentRefundsInput;
}

export interface PaymentIntentUpsertWithoutPaymentRequestInput {
  update: PaymentIntentUpdateWithoutPaymentRequestInput;
  create: PaymentIntentCreateWithoutPaymentRequestInput;
}

export interface PaymentIntentUpsertWithoutPaymentsInput {
  update: PaymentIntentUpdateWithoutPaymentsInput;
  create: PaymentIntentCreateWithoutPaymentsInput;
}

export interface PaymentIntentUpsertWithoutStripeFeesInput {
  update: PaymentIntentUpdateWithoutStripeFeesInput;
  create: PaymentIntentCreateWithoutStripeFeesInput;
}

export interface PaymentIntentUpsertWithoutStripeTransfersInput {
  update: PaymentIntentUpdateWithoutStripeTransfersInput;
  create: PaymentIntentCreateWithoutStripeTransfersInput;
}

export interface PaymentIntentWhereInput {
  AND?: PaymentIntentWhereInput[] | null;
  OR?: PaymentIntentWhereInput[] | null;
  NOT?: PaymentIntentWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  status?: StringFilter | null;
  stripePaymentIntentId?: StringFilter | null;
  receiptCode?: StringNullableFilter | null;
  patientId?: UuidFilter | null;
  autoPay?: BoolFilter | null;
  transferGroup?: StringNullableFilter | null;
  lastPaymentError?: StringNullableFilter | null;
  lastPaymentErrorCode?: StringNullableFilter | null;
  patientPaymentMethodId?: UuidNullableFilter | null;
  forcePost?: BoolFilter | null;
  patient?: PatientRelationFilter | null;
  patientPaymentMethod?: PatientPaymentMethodRelationFilter | null;
  payments?: PaymentListRelationFilter | null;
  paymentIntentCharges?: PaymentIntentChargeListRelationFilter | null;
  chargePayments?: ChargePaymentListRelationFilter | null;
  stripeTransfers?: StripeTransferListRelationFilter | null;
  stripeFees?: StripeFeeListRelationFilter | null;
  paymentIntentRefunds?: PaymentIntentRefundListRelationFilter | null;
  paymentIntentBills?: PaymentIntentBillListRelationFilter | null;
  billPayments?: BillPaymentListRelationFilter | null;
  paymentRequest?: PaymentRequestRelationFilter | null;
}

export interface PaymentIntentWhereUniqueInput {
  id?: string | null;
  stripePaymentIntentId?: string | null;
  receiptCode?: string | null;
}

export interface PaymentListRelationFilter {
  every?: PaymentWhereInput | null;
  some?: PaymentWhereInput | null;
  none?: PaymentWhereInput | null;
}

export interface PaymentOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PaymentRelationFilter {
  is?: PaymentWhereInput | null;
  isNot?: PaymentWhereInput | null;
}

export interface PaymentRequestBatchCreateManyCanceledByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: PaymentRequestBatchStatus;
  failureReason?: string | null;
  totalAmount?: number | null;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  locationId: string;
  organizationId: string;
  createdById?: string | null;
  s3Key?: string | null;
}

export interface PaymentRequestBatchCreateManyCanceledByInputEnvelope {
  data: PaymentRequestBatchCreateManyCanceledByInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentRequestBatchCreateManyCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: PaymentRequestBatchStatus;
  failureReason?: string | null;
  totalAmount?: number | null;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  locationId: string;
  organizationId: string;
  canceledById?: string | null;
  s3Key?: string | null;
}

export interface PaymentRequestBatchCreateManyCreatedByInputEnvelope {
  data: PaymentRequestBatchCreateManyCreatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentRequestBatchCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: PaymentRequestBatchStatus;
  failureReason?: string | null;
  totalAmount?: number | null;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  organizationId: string;
  createdById?: string | null;
  canceledById?: string | null;
  s3Key?: string | null;
}

export interface PaymentRequestBatchCreateManyLocationInputEnvelope {
  data: PaymentRequestBatchCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentRequestBatchCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: PaymentRequestBatchStatus;
  failureReason?: string | null;
  totalAmount?: number | null;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  locationId: string;
  createdById?: string | null;
  canceledById?: string | null;
  s3Key?: string | null;
}

export interface PaymentRequestBatchCreateManyOrganizationInputEnvelope {
  data: PaymentRequestBatchCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentRequestBatchCreateNestedManyWithoutCanceledByInput {
  create?: PaymentRequestBatchCreateWithoutCanceledByInput[] | null;
  connectOrCreate?: PaymentRequestBatchCreateOrConnectWithoutCanceledByInput[] | null;
  createMany?: PaymentRequestBatchCreateManyCanceledByInputEnvelope | null;
  connect?: PaymentRequestBatchWhereUniqueInput[] | null;
}

export interface PaymentRequestBatchCreateNestedManyWithoutCreatedByInput {
  create?: PaymentRequestBatchCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PaymentRequestBatchCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: PaymentRequestBatchCreateManyCreatedByInputEnvelope | null;
  connect?: PaymentRequestBatchWhereUniqueInput[] | null;
}

export interface PaymentRequestBatchCreateNestedManyWithoutLocationInput {
  create?: PaymentRequestBatchCreateWithoutLocationInput[] | null;
  connectOrCreate?: PaymentRequestBatchCreateOrConnectWithoutLocationInput[] | null;
  createMany?: PaymentRequestBatchCreateManyLocationInputEnvelope | null;
  connect?: PaymentRequestBatchWhereUniqueInput[] | null;
}

export interface PaymentRequestBatchCreateNestedManyWithoutOrganizationInput {
  create?: PaymentRequestBatchCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: PaymentRequestBatchCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: PaymentRequestBatchCreateManyOrganizationInputEnvelope | null;
  connect?: PaymentRequestBatchWhereUniqueInput[] | null;
}

export interface PaymentRequestBatchCreateNestedOneWithoutPaymentRequestsInput {
  create?: PaymentRequestBatchCreateWithoutPaymentRequestsInput | null;
  connectOrCreate?: PaymentRequestBatchCreateOrConnectWithoutPaymentRequestsInput | null;
  connect?: PaymentRequestBatchWhereUniqueInput | null;
}

export interface PaymentRequestBatchCreateOrConnectWithoutCanceledByInput {
  where: PaymentRequestBatchWhereUniqueInput;
  create: PaymentRequestBatchCreateWithoutCanceledByInput;
}

export interface PaymentRequestBatchCreateOrConnectWithoutCreatedByInput {
  where: PaymentRequestBatchWhereUniqueInput;
  create: PaymentRequestBatchCreateWithoutCreatedByInput;
}

export interface PaymentRequestBatchCreateOrConnectWithoutLocationInput {
  where: PaymentRequestBatchWhereUniqueInput;
  create: PaymentRequestBatchCreateWithoutLocationInput;
}

export interface PaymentRequestBatchCreateOrConnectWithoutOrganizationInput {
  where: PaymentRequestBatchWhereUniqueInput;
  create: PaymentRequestBatchCreateWithoutOrganizationInput;
}

export interface PaymentRequestBatchCreateOrConnectWithoutPaymentRequestsInput {
  where: PaymentRequestBatchWhereUniqueInput;
  create: PaymentRequestBatchCreateWithoutPaymentRequestsInput;
}

export interface PaymentRequestBatchCreateWithoutCanceledByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: PaymentRequestBatchStatus;
  failureReason?: string | null;
  totalAmount?: number | null;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  s3Key?: string | null;
  location: LocationCreateNestedOneWithoutPaymentRequestBatchesInput;
  organization: OrganizationCreateNestedOneWithoutPaymentRequestBatchesInput;
  createdBy?: UserCreateNestedOneWithoutCreatedPaymentRequestBatchesInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPaymentRequestBatchInput | null;
}

export interface PaymentRequestBatchCreateWithoutCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: PaymentRequestBatchStatus;
  failureReason?: string | null;
  totalAmount?: number | null;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  s3Key?: string | null;
  location: LocationCreateNestedOneWithoutPaymentRequestBatchesInput;
  organization: OrganizationCreateNestedOneWithoutPaymentRequestBatchesInput;
  canceledBy?: UserCreateNestedOneWithoutCanceledPaymentRequestBatchesInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPaymentRequestBatchInput | null;
}

export interface PaymentRequestBatchCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: PaymentRequestBatchStatus;
  failureReason?: string | null;
  totalAmount?: number | null;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  s3Key?: string | null;
  organization: OrganizationCreateNestedOneWithoutPaymentRequestBatchesInput;
  createdBy?: UserCreateNestedOneWithoutCreatedPaymentRequestBatchesInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledPaymentRequestBatchesInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPaymentRequestBatchInput | null;
}

export interface PaymentRequestBatchCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: PaymentRequestBatchStatus;
  failureReason?: string | null;
  totalAmount?: number | null;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  s3Key?: string | null;
  location: LocationCreateNestedOneWithoutPaymentRequestBatchesInput;
  createdBy?: UserCreateNestedOneWithoutCreatedPaymentRequestBatchesInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledPaymentRequestBatchesInput | null;
  paymentRequests?: PaymentRequestCreateNestedManyWithoutPaymentRequestBatchInput | null;
}

export interface PaymentRequestBatchCreateWithoutPaymentRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  status: PaymentRequestBatchStatus;
  failureReason?: string | null;
  totalAmount?: number | null;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  s3Key?: string | null;
  location: LocationCreateNestedOneWithoutPaymentRequestBatchesInput;
  organization: OrganizationCreateNestedOneWithoutPaymentRequestBatchesInput;
  createdBy?: UserCreateNestedOneWithoutCreatedPaymentRequestBatchesInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledPaymentRequestBatchesInput | null;
}

export interface PaymentRequestBatchListRelationFilter {
  every?: PaymentRequestBatchWhereInput | null;
  some?: PaymentRequestBatchWhereInput | null;
  none?: PaymentRequestBatchWhereInput | null;
}

export interface PaymentRequestBatchOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PaymentRequestBatchRelationFilter {
  is?: PaymentRequestBatchWhereInput | null;
  isNot?: PaymentRequestBatchWhereInput | null;
}

export interface PaymentRequestBatchScalarWhereInput {
  AND?: PaymentRequestBatchScalarWhereInput[] | null;
  OR?: PaymentRequestBatchScalarWhereInput[] | null;
  NOT?: PaymentRequestBatchScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  status?: EnumPaymentRequestBatchStatusFilter | null;
  failureReason?: StringNullableFilter | null;
  totalAmount?: IntNullableFilter | null;
  scheduledAt?: DateTimeNullableFilter | null;
  startedAt?: DateTimeNullableFilter | null;
  completedAt?: DateTimeNullableFilter | null;
  canceledAt?: DateTimeNullableFilter | null;
  locationId?: UuidFilter | null;
  organizationId?: UuidFilter | null;
  createdById?: UuidNullableFilter | null;
  canceledById?: UuidNullableFilter | null;
  s3Key?: StringNullableFilter | null;
}

export interface PaymentRequestBatchUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestBatchStatusFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  totalAmount?: NullableIntFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
}

export interface PaymentRequestBatchUpdateManyWithWhereWithoutCanceledByInput {
  where: PaymentRequestBatchScalarWhereInput;
  data: PaymentRequestBatchUpdateManyMutationInput;
}

export interface PaymentRequestBatchUpdateManyWithWhereWithoutCreatedByInput {
  where: PaymentRequestBatchScalarWhereInput;
  data: PaymentRequestBatchUpdateManyMutationInput;
}

export interface PaymentRequestBatchUpdateManyWithWhereWithoutLocationInput {
  where: PaymentRequestBatchScalarWhereInput;
  data: PaymentRequestBatchUpdateManyMutationInput;
}

export interface PaymentRequestBatchUpdateManyWithWhereWithoutOrganizationInput {
  where: PaymentRequestBatchScalarWhereInput;
  data: PaymentRequestBatchUpdateManyMutationInput;
}

export interface PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput {
  create?: PaymentRequestBatchCreateWithoutCanceledByInput[] | null;
  connectOrCreate?: PaymentRequestBatchCreateOrConnectWithoutCanceledByInput[] | null;
  upsert?: PaymentRequestBatchUpsertWithWhereUniqueWithoutCanceledByInput[] | null;
  createMany?: PaymentRequestBatchCreateManyCanceledByInputEnvelope | null;
  set?: PaymentRequestBatchWhereUniqueInput[] | null;
  disconnect?: PaymentRequestBatchWhereUniqueInput[] | null;
  delete?: PaymentRequestBatchWhereUniqueInput[] | null;
  connect?: PaymentRequestBatchWhereUniqueInput[] | null;
  update?: PaymentRequestBatchUpdateWithWhereUniqueWithoutCanceledByInput[] | null;
  updateMany?: PaymentRequestBatchUpdateManyWithWhereWithoutCanceledByInput[] | null;
  deleteMany?: PaymentRequestBatchScalarWhereInput[] | null;
}

export interface PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput {
  create?: PaymentRequestBatchCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PaymentRequestBatchCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: PaymentRequestBatchUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: PaymentRequestBatchCreateManyCreatedByInputEnvelope | null;
  set?: PaymentRequestBatchWhereUniqueInput[] | null;
  disconnect?: PaymentRequestBatchWhereUniqueInput[] | null;
  delete?: PaymentRequestBatchWhereUniqueInput[] | null;
  connect?: PaymentRequestBatchWhereUniqueInput[] | null;
  update?: PaymentRequestBatchUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: PaymentRequestBatchUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: PaymentRequestBatchScalarWhereInput[] | null;
}

export interface PaymentRequestBatchUpdateManyWithoutLocationNestedInput {
  create?: PaymentRequestBatchCreateWithoutLocationInput[] | null;
  connectOrCreate?: PaymentRequestBatchCreateOrConnectWithoutLocationInput[] | null;
  upsert?: PaymentRequestBatchUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: PaymentRequestBatchCreateManyLocationInputEnvelope | null;
  set?: PaymentRequestBatchWhereUniqueInput[] | null;
  disconnect?: PaymentRequestBatchWhereUniqueInput[] | null;
  delete?: PaymentRequestBatchWhereUniqueInput[] | null;
  connect?: PaymentRequestBatchWhereUniqueInput[] | null;
  update?: PaymentRequestBatchUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: PaymentRequestBatchUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: PaymentRequestBatchScalarWhereInput[] | null;
}

export interface PaymentRequestBatchUpdateManyWithoutOrganizationNestedInput {
  create?: PaymentRequestBatchCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: PaymentRequestBatchCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: PaymentRequestBatchUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: PaymentRequestBatchCreateManyOrganizationInputEnvelope | null;
  set?: PaymentRequestBatchWhereUniqueInput[] | null;
  disconnect?: PaymentRequestBatchWhereUniqueInput[] | null;
  delete?: PaymentRequestBatchWhereUniqueInput[] | null;
  connect?: PaymentRequestBatchWhereUniqueInput[] | null;
  update?: PaymentRequestBatchUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: PaymentRequestBatchUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: PaymentRequestBatchScalarWhereInput[] | null;
}

export interface PaymentRequestBatchUpdateOneWithoutPaymentRequestsNestedInput {
  create?: PaymentRequestBatchCreateWithoutPaymentRequestsInput | null;
  connectOrCreate?: PaymentRequestBatchCreateOrConnectWithoutPaymentRequestsInput | null;
  upsert?: PaymentRequestBatchUpsertWithoutPaymentRequestsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentRequestBatchWhereUniqueInput | null;
  update?: PaymentRequestBatchUpdateWithoutPaymentRequestsInput | null;
}

export interface PaymentRequestBatchUpdateWithWhereUniqueWithoutCanceledByInput {
  where: PaymentRequestBatchWhereUniqueInput;
  data: PaymentRequestBatchUpdateWithoutCanceledByInput;
}

export interface PaymentRequestBatchUpdateWithWhereUniqueWithoutCreatedByInput {
  where: PaymentRequestBatchWhereUniqueInput;
  data: PaymentRequestBatchUpdateWithoutCreatedByInput;
}

export interface PaymentRequestBatchUpdateWithWhereUniqueWithoutLocationInput {
  where: PaymentRequestBatchWhereUniqueInput;
  data: PaymentRequestBatchUpdateWithoutLocationInput;
}

export interface PaymentRequestBatchUpdateWithWhereUniqueWithoutOrganizationInput {
  where: PaymentRequestBatchWhereUniqueInput;
  data: PaymentRequestBatchUpdateWithoutOrganizationInput;
}

export interface PaymentRequestBatchUpdateWithoutCanceledByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestBatchStatusFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  totalAmount?: NullableIntFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutPaymentRequestBatchesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPaymentRequestBatchesNestedInput | null;
  createdBy?: UserUpdateOneWithoutCreatedPaymentRequestBatchesNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPaymentRequestBatchNestedInput | null;
}

export interface PaymentRequestBatchUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestBatchStatusFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  totalAmount?: NullableIntFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutPaymentRequestBatchesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPaymentRequestBatchesNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledPaymentRequestBatchesNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPaymentRequestBatchNestedInput | null;
}

export interface PaymentRequestBatchUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestBatchStatusFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  totalAmount?: NullableIntFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPaymentRequestBatchesNestedInput | null;
  createdBy?: UserUpdateOneWithoutCreatedPaymentRequestBatchesNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledPaymentRequestBatchesNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPaymentRequestBatchNestedInput | null;
}

export interface PaymentRequestBatchUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestBatchStatusFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  totalAmount?: NullableIntFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutPaymentRequestBatchesNestedInput | null;
  createdBy?: UserUpdateOneWithoutCreatedPaymentRequestBatchesNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledPaymentRequestBatchesNestedInput | null;
  paymentRequests?: PaymentRequestUpdateManyWithoutPaymentRequestBatchNestedInput | null;
}

export interface PaymentRequestBatchUpdateWithoutPaymentRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestBatchStatusFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  totalAmount?: NullableIntFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutPaymentRequestBatchesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutPaymentRequestBatchesNestedInput | null;
  createdBy?: UserUpdateOneWithoutCreatedPaymentRequestBatchesNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledPaymentRequestBatchesNestedInput | null;
}

export interface PaymentRequestBatchUpsertWithWhereUniqueWithoutCanceledByInput {
  where: PaymentRequestBatchWhereUniqueInput;
  update: PaymentRequestBatchUpdateWithoutCanceledByInput;
  create: PaymentRequestBatchCreateWithoutCanceledByInput;
}

export interface PaymentRequestBatchUpsertWithWhereUniqueWithoutCreatedByInput {
  where: PaymentRequestBatchWhereUniqueInput;
  update: PaymentRequestBatchUpdateWithoutCreatedByInput;
  create: PaymentRequestBatchCreateWithoutCreatedByInput;
}

export interface PaymentRequestBatchUpsertWithWhereUniqueWithoutLocationInput {
  where: PaymentRequestBatchWhereUniqueInput;
  update: PaymentRequestBatchUpdateWithoutLocationInput;
  create: PaymentRequestBatchCreateWithoutLocationInput;
}

export interface PaymentRequestBatchUpsertWithWhereUniqueWithoutOrganizationInput {
  where: PaymentRequestBatchWhereUniqueInput;
  update: PaymentRequestBatchUpdateWithoutOrganizationInput;
  create: PaymentRequestBatchCreateWithoutOrganizationInput;
}

export interface PaymentRequestBatchUpsertWithoutPaymentRequestsInput {
  update: PaymentRequestBatchUpdateWithoutPaymentRequestsInput;
  create: PaymentRequestBatchCreateWithoutPaymentRequestsInput;
}

export interface PaymentRequestBatchWhereInput {
  AND?: PaymentRequestBatchWhereInput[] | null;
  OR?: PaymentRequestBatchWhereInput[] | null;
  NOT?: PaymentRequestBatchWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  status?: EnumPaymentRequestBatchStatusFilter | null;
  failureReason?: StringNullableFilter | null;
  totalAmount?: IntNullableFilter | null;
  scheduledAt?: DateTimeNullableFilter | null;
  startedAt?: DateTimeNullableFilter | null;
  completedAt?: DateTimeNullableFilter | null;
  canceledAt?: DateTimeNullableFilter | null;
  locationId?: UuidFilter | null;
  organizationId?: UuidFilter | null;
  createdById?: UuidNullableFilter | null;
  canceledById?: UuidNullableFilter | null;
  s3Key?: StringNullableFilter | null;
  location?: LocationRelationFilter | null;
  organization?: OrganizationRelationFilter | null;
  createdBy?: UserRelationFilter | null;
  canceledBy?: UserRelationFilter | null;
  paymentRequests?: PaymentRequestListRelationFilter | null;
}

export interface PaymentRequestBatchWhereUniqueInput {
  id?: string | null;
}

export interface PaymentRequestCreateManyPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: PaymentRequestType;
  status: PaymentRequestStatus;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  failedAt?: any | null;
  usedAccessCodeAt?: any | null;
  failureReason?: string | null;
  amount?: number | null;
  paymentRequestBatchId?: string | null;
  paymentIntentId?: string | null;
  s3Key?: string | null;
  accessCode?: string | null;
  printEnabled?: boolean | null;
}

export interface PaymentRequestCreateManyPatientInputEnvelope {
  data: PaymentRequestCreateManyPatientInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentRequestCreateManyPaymentRequestBatchInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: PaymentRequestType;
  status: PaymentRequestStatus;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  failedAt?: any | null;
  usedAccessCodeAt?: any | null;
  failureReason?: string | null;
  amount?: number | null;
  patientId: string;
  paymentIntentId?: string | null;
  s3Key?: string | null;
  accessCode?: string | null;
  printEnabled?: boolean | null;
}

export interface PaymentRequestCreateManyPaymentRequestBatchInputEnvelope {
  data: PaymentRequestCreateManyPaymentRequestBatchInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentRequestCreateNestedManyWithoutPatientInput {
  create?: PaymentRequestCreateWithoutPatientInput[] | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutPatientInput[] | null;
  createMany?: PaymentRequestCreateManyPatientInputEnvelope | null;
  connect?: PaymentRequestWhereUniqueInput[] | null;
}

export interface PaymentRequestCreateNestedManyWithoutPaymentRequestBatchInput {
  create?: PaymentRequestCreateWithoutPaymentRequestBatchInput[] | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutPaymentRequestBatchInput[] | null;
  createMany?: PaymentRequestCreateManyPaymentRequestBatchInputEnvelope | null;
  connect?: PaymentRequestWhereUniqueInput[] | null;
}

export interface PaymentRequestCreateNestedOneWithoutCommunicationsInput {
  create?: PaymentRequestCreateWithoutCommunicationsInput | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutCommunicationsInput | null;
  connect?: PaymentRequestWhereUniqueInput | null;
}

export interface PaymentRequestCreateNestedOneWithoutPaymentIntentInput {
  create?: PaymentRequestCreateWithoutPaymentIntentInput | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutPaymentIntentInput | null;
  connect?: PaymentRequestWhereUniqueInput | null;
}

export interface PaymentRequestCreateNestedOneWithoutPaymentRequestTargetsInput {
  create?: PaymentRequestCreateWithoutPaymentRequestTargetsInput | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutPaymentRequestTargetsInput | null;
  connect?: PaymentRequestWhereUniqueInput | null;
}

export interface PaymentRequestCreateNestedOneWithoutTasksInput {
  create?: PaymentRequestCreateWithoutTasksInput | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutTasksInput | null;
  connect?: PaymentRequestWhereUniqueInput | null;
}

export interface PaymentRequestCreateOrConnectWithoutCommunicationsInput {
  where: PaymentRequestWhereUniqueInput;
  create: PaymentRequestCreateWithoutCommunicationsInput;
}

export interface PaymentRequestCreateOrConnectWithoutPatientInput {
  where: PaymentRequestWhereUniqueInput;
  create: PaymentRequestCreateWithoutPatientInput;
}

export interface PaymentRequestCreateOrConnectWithoutPaymentIntentInput {
  where: PaymentRequestWhereUniqueInput;
  create: PaymentRequestCreateWithoutPaymentIntentInput;
}

export interface PaymentRequestCreateOrConnectWithoutPaymentRequestBatchInput {
  where: PaymentRequestWhereUniqueInput;
  create: PaymentRequestCreateWithoutPaymentRequestBatchInput;
}

export interface PaymentRequestCreateOrConnectWithoutPaymentRequestTargetsInput {
  where: PaymentRequestWhereUniqueInput;
  create: PaymentRequestCreateWithoutPaymentRequestTargetsInput;
}

export interface PaymentRequestCreateOrConnectWithoutTasksInput {
  where: PaymentRequestWhereUniqueInput;
  create: PaymentRequestCreateWithoutTasksInput;
}

export interface PaymentRequestCreateWithoutCommunicationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: PaymentRequestType;
  status: PaymentRequestStatus;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  failedAt?: any | null;
  usedAccessCodeAt?: any | null;
  failureReason?: string | null;
  amount?: number | null;
  s3Key?: string | null;
  accessCode?: string | null;
  printEnabled?: boolean | null;
  paymentRequestBatch?: PaymentRequestBatchCreateNestedOneWithoutPaymentRequestsInput | null;
  patient: PatientCreateNestedOneWithoutPaymentRequestsInput;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutPaymentRequestInput | null;
  tasks?: TaskCreateNestedManyWithoutPaymentRequestInput | null;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentRequestInput | null;
}

export interface PaymentRequestCreateWithoutPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: PaymentRequestType;
  status: PaymentRequestStatus;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  failedAt?: any | null;
  usedAccessCodeAt?: any | null;
  failureReason?: string | null;
  amount?: number | null;
  s3Key?: string | null;
  accessCode?: string | null;
  printEnabled?: boolean | null;
  paymentRequestBatch?: PaymentRequestBatchCreateNestedOneWithoutPaymentRequestsInput | null;
  communications?: CommunicationCreateNestedManyWithoutPaymentRequestInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutPaymentRequestInput | null;
  tasks?: TaskCreateNestedManyWithoutPaymentRequestInput | null;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentRequestInput | null;
}

export interface PaymentRequestCreateWithoutPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: PaymentRequestType;
  status: PaymentRequestStatus;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  failedAt?: any | null;
  usedAccessCodeAt?: any | null;
  failureReason?: string | null;
  amount?: number | null;
  s3Key?: string | null;
  accessCode?: string | null;
  printEnabled?: boolean | null;
  paymentRequestBatch?: PaymentRequestBatchCreateNestedOneWithoutPaymentRequestsInput | null;
  patient: PatientCreateNestedOneWithoutPaymentRequestsInput;
  communications?: CommunicationCreateNestedManyWithoutPaymentRequestInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutPaymentRequestInput | null;
  tasks?: TaskCreateNestedManyWithoutPaymentRequestInput | null;
}

export interface PaymentRequestCreateWithoutPaymentRequestBatchInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: PaymentRequestType;
  status: PaymentRequestStatus;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  failedAt?: any | null;
  usedAccessCodeAt?: any | null;
  failureReason?: string | null;
  amount?: number | null;
  s3Key?: string | null;
  accessCode?: string | null;
  printEnabled?: boolean | null;
  patient: PatientCreateNestedOneWithoutPaymentRequestsInput;
  communications?: CommunicationCreateNestedManyWithoutPaymentRequestInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutPaymentRequestInput | null;
  tasks?: TaskCreateNestedManyWithoutPaymentRequestInput | null;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentRequestInput | null;
}

export interface PaymentRequestCreateWithoutPaymentRequestTargetsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: PaymentRequestType;
  status: PaymentRequestStatus;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  failedAt?: any | null;
  usedAccessCodeAt?: any | null;
  failureReason?: string | null;
  amount?: number | null;
  s3Key?: string | null;
  accessCode?: string | null;
  printEnabled?: boolean | null;
  paymentRequestBatch?: PaymentRequestBatchCreateNestedOneWithoutPaymentRequestsInput | null;
  patient: PatientCreateNestedOneWithoutPaymentRequestsInput;
  communications?: CommunicationCreateNestedManyWithoutPaymentRequestInput | null;
  tasks?: TaskCreateNestedManyWithoutPaymentRequestInput | null;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentRequestInput | null;
}

export interface PaymentRequestCreateWithoutTasksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: PaymentRequestType;
  status: PaymentRequestStatus;
  scheduledAt?: any | null;
  startedAt?: any | null;
  completedAt?: any | null;
  canceledAt?: any | null;
  failedAt?: any | null;
  usedAccessCodeAt?: any | null;
  failureReason?: string | null;
  amount?: number | null;
  s3Key?: string | null;
  accessCode?: string | null;
  printEnabled?: boolean | null;
  paymentRequestBatch?: PaymentRequestBatchCreateNestedOneWithoutPaymentRequestsInput | null;
  patient: PatientCreateNestedOneWithoutPaymentRequestsInput;
  communications?: CommunicationCreateNestedManyWithoutPaymentRequestInput | null;
  paymentRequestTargets?: PaymentRequestTargetCreateNestedManyWithoutPaymentRequestInput | null;
  paymentIntent?: PaymentIntentCreateNestedOneWithoutPaymentRequestInput | null;
}

export interface PaymentRequestListRelationFilter {
  every?: PaymentRequestWhereInput | null;
  some?: PaymentRequestWhereInput | null;
  none?: PaymentRequestWhereInput | null;
}

export interface PaymentRequestOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PaymentRequestRelationFilter {
  is?: PaymentRequestWhereInput | null;
  isNot?: PaymentRequestWhereInput | null;
}

export interface PaymentRequestScalarWhereInput {
  AND?: PaymentRequestScalarWhereInput[] | null;
  OR?: PaymentRequestScalarWhereInput[] | null;
  NOT?: PaymentRequestScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  type?: EnumPaymentRequestTypeFilter | null;
  status?: EnumPaymentRequestStatusFilter | null;
  scheduledAt?: DateTimeNullableFilter | null;
  startedAt?: DateTimeNullableFilter | null;
  completedAt?: DateTimeNullableFilter | null;
  canceledAt?: DateTimeNullableFilter | null;
  failedAt?: DateTimeNullableFilter | null;
  usedAccessCodeAt?: DateTimeNullableFilter | null;
  failureReason?: StringNullableFilter | null;
  amount?: IntNullableFilter | null;
  paymentRequestBatchId?: UuidNullableFilter | null;
  patientId?: UuidFilter | null;
  paymentIntentId?: UuidNullableFilter | null;
  s3Key?: StringNullableFilter | null;
  accessCode?: StringNullableFilter | null;
  printEnabled?: BoolNullableFilter | null;
}

export interface PaymentRequestTargetCreateManyBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  paymentRequestId: string;
}

export interface PaymentRequestTargetCreateManyBillInputEnvelope {
  data: PaymentRequestTargetCreateManyBillInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentRequestTargetCreateManyPaymentRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  billId: string;
}

export interface PaymentRequestTargetCreateManyPaymentRequestInputEnvelope {
  data: PaymentRequestTargetCreateManyPaymentRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface PaymentRequestTargetCreateNestedManyWithoutBillInput {
  create?: PaymentRequestTargetCreateWithoutBillInput[] | null;
  connectOrCreate?: PaymentRequestTargetCreateOrConnectWithoutBillInput[] | null;
  createMany?: PaymentRequestTargetCreateManyBillInputEnvelope | null;
  connect?: PaymentRequestTargetWhereUniqueInput[] | null;
}

export interface PaymentRequestTargetCreateNestedManyWithoutPaymentRequestInput {
  create?: PaymentRequestTargetCreateWithoutPaymentRequestInput[] | null;
  connectOrCreate?: PaymentRequestTargetCreateOrConnectWithoutPaymentRequestInput[] | null;
  createMany?: PaymentRequestTargetCreateManyPaymentRequestInputEnvelope | null;
  connect?: PaymentRequestTargetWhereUniqueInput[] | null;
}

export interface PaymentRequestTargetCreateOrConnectWithoutBillInput {
  where: PaymentRequestTargetWhereUniqueInput;
  create: PaymentRequestTargetCreateWithoutBillInput;
}

export interface PaymentRequestTargetCreateOrConnectWithoutPaymentRequestInput {
  where: PaymentRequestTargetWhereUniqueInput;
  create: PaymentRequestTargetCreateWithoutPaymentRequestInput;
}

export interface PaymentRequestTargetCreateWithoutBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  paymentRequest: PaymentRequestCreateNestedOneWithoutPaymentRequestTargetsInput;
}

export interface PaymentRequestTargetCreateWithoutPaymentRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  bill: BillCreateNestedOneWithoutPaymentRequestTargetsInput;
}

export interface PaymentRequestTargetListRelationFilter {
  every?: PaymentRequestTargetWhereInput | null;
  some?: PaymentRequestTargetWhereInput | null;
  none?: PaymentRequestTargetWhereInput | null;
}

export interface PaymentRequestTargetOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PaymentRequestTargetScalarWhereInput {
  AND?: PaymentRequestTargetScalarWhereInput[] | null;
  OR?: PaymentRequestTargetScalarWhereInput[] | null;
  NOT?: PaymentRequestTargetScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  paymentRequestId?: UuidFilter | null;
  billId?: UuidFilter | null;
}

export interface PaymentRequestTargetUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
}

export interface PaymentRequestTargetUpdateManyWithWhereWithoutBillInput {
  where: PaymentRequestTargetScalarWhereInput;
  data: PaymentRequestTargetUpdateManyMutationInput;
}

export interface PaymentRequestTargetUpdateManyWithWhereWithoutPaymentRequestInput {
  where: PaymentRequestTargetScalarWhereInput;
  data: PaymentRequestTargetUpdateManyMutationInput;
}

export interface PaymentRequestTargetUpdateManyWithoutBillNestedInput {
  create?: PaymentRequestTargetCreateWithoutBillInput[] | null;
  connectOrCreate?: PaymentRequestTargetCreateOrConnectWithoutBillInput[] | null;
  upsert?: PaymentRequestTargetUpsertWithWhereUniqueWithoutBillInput[] | null;
  createMany?: PaymentRequestTargetCreateManyBillInputEnvelope | null;
  set?: PaymentRequestTargetWhereUniqueInput[] | null;
  disconnect?: PaymentRequestTargetWhereUniqueInput[] | null;
  delete?: PaymentRequestTargetWhereUniqueInput[] | null;
  connect?: PaymentRequestTargetWhereUniqueInput[] | null;
  update?: PaymentRequestTargetUpdateWithWhereUniqueWithoutBillInput[] | null;
  updateMany?: PaymentRequestTargetUpdateManyWithWhereWithoutBillInput[] | null;
  deleteMany?: PaymentRequestTargetScalarWhereInput[] | null;
}

export interface PaymentRequestTargetUpdateManyWithoutPaymentRequestNestedInput {
  create?: PaymentRequestTargetCreateWithoutPaymentRequestInput[] | null;
  connectOrCreate?: PaymentRequestTargetCreateOrConnectWithoutPaymentRequestInput[] | null;
  upsert?: PaymentRequestTargetUpsertWithWhereUniqueWithoutPaymentRequestInput[] | null;
  createMany?: PaymentRequestTargetCreateManyPaymentRequestInputEnvelope | null;
  set?: PaymentRequestTargetWhereUniqueInput[] | null;
  disconnect?: PaymentRequestTargetWhereUniqueInput[] | null;
  delete?: PaymentRequestTargetWhereUniqueInput[] | null;
  connect?: PaymentRequestTargetWhereUniqueInput[] | null;
  update?: PaymentRequestTargetUpdateWithWhereUniqueWithoutPaymentRequestInput[] | null;
  updateMany?: PaymentRequestTargetUpdateManyWithWhereWithoutPaymentRequestInput[] | null;
  deleteMany?: PaymentRequestTargetScalarWhereInput[] | null;
}

export interface PaymentRequestTargetUpdateWithWhereUniqueWithoutBillInput {
  where: PaymentRequestTargetWhereUniqueInput;
  data: PaymentRequestTargetUpdateWithoutBillInput;
}

export interface PaymentRequestTargetUpdateWithWhereUniqueWithoutPaymentRequestInput {
  where: PaymentRequestTargetWhereUniqueInput;
  data: PaymentRequestTargetUpdateWithoutPaymentRequestInput;
}

export interface PaymentRequestTargetUpdateWithoutBillInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  paymentRequest?: PaymentRequestUpdateOneRequiredWithoutPaymentRequestTargetsNestedInput | null;
}

export interface PaymentRequestTargetUpdateWithoutPaymentRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  bill?: BillUpdateOneRequiredWithoutPaymentRequestTargetsNestedInput | null;
}

export interface PaymentRequestTargetUpsertWithWhereUniqueWithoutBillInput {
  where: PaymentRequestTargetWhereUniqueInput;
  update: PaymentRequestTargetUpdateWithoutBillInput;
  create: PaymentRequestTargetCreateWithoutBillInput;
}

export interface PaymentRequestTargetUpsertWithWhereUniqueWithoutPaymentRequestInput {
  where: PaymentRequestTargetWhereUniqueInput;
  update: PaymentRequestTargetUpdateWithoutPaymentRequestInput;
  create: PaymentRequestTargetCreateWithoutPaymentRequestInput;
}

export interface PaymentRequestTargetWhereInput {
  AND?: PaymentRequestTargetWhereInput[] | null;
  OR?: PaymentRequestTargetWhereInput[] | null;
  NOT?: PaymentRequestTargetWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  paymentRequestId?: UuidFilter | null;
  billId?: UuidFilter | null;
  paymentRequest?: PaymentRequestRelationFilter | null;
  bill?: BillRelationFilter | null;
}

export interface PaymentRequestTargetWhereUniqueInput {
  id?: string | null;
}

export interface PaymentRequestUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumPaymentRequestTypeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestStatusFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  usedAccessCodeAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  accessCode?: NullableStringFieldUpdateOperationsInput | null;
  printEnabled?: NullableBoolFieldUpdateOperationsInput | null;
}

export interface PaymentRequestUpdateManyWithWhereWithoutPatientInput {
  where: PaymentRequestScalarWhereInput;
  data: PaymentRequestUpdateManyMutationInput;
}

export interface PaymentRequestUpdateManyWithWhereWithoutPaymentRequestBatchInput {
  where: PaymentRequestScalarWhereInput;
  data: PaymentRequestUpdateManyMutationInput;
}

export interface PaymentRequestUpdateManyWithoutPatientNestedInput {
  create?: PaymentRequestCreateWithoutPatientInput[] | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutPatientInput[] | null;
  upsert?: PaymentRequestUpsertWithWhereUniqueWithoutPatientInput[] | null;
  createMany?: PaymentRequestCreateManyPatientInputEnvelope | null;
  set?: PaymentRequestWhereUniqueInput[] | null;
  disconnect?: PaymentRequestWhereUniqueInput[] | null;
  delete?: PaymentRequestWhereUniqueInput[] | null;
  connect?: PaymentRequestWhereUniqueInput[] | null;
  update?: PaymentRequestUpdateWithWhereUniqueWithoutPatientInput[] | null;
  updateMany?: PaymentRequestUpdateManyWithWhereWithoutPatientInput[] | null;
  deleteMany?: PaymentRequestScalarWhereInput[] | null;
}

export interface PaymentRequestUpdateManyWithoutPaymentRequestBatchNestedInput {
  create?: PaymentRequestCreateWithoutPaymentRequestBatchInput[] | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutPaymentRequestBatchInput[] | null;
  upsert?: PaymentRequestUpsertWithWhereUniqueWithoutPaymentRequestBatchInput[] | null;
  createMany?: PaymentRequestCreateManyPaymentRequestBatchInputEnvelope | null;
  set?: PaymentRequestWhereUniqueInput[] | null;
  disconnect?: PaymentRequestWhereUniqueInput[] | null;
  delete?: PaymentRequestWhereUniqueInput[] | null;
  connect?: PaymentRequestWhereUniqueInput[] | null;
  update?: PaymentRequestUpdateWithWhereUniqueWithoutPaymentRequestBatchInput[] | null;
  updateMany?: PaymentRequestUpdateManyWithWhereWithoutPaymentRequestBatchInput[] | null;
  deleteMany?: PaymentRequestScalarWhereInput[] | null;
}

export interface PaymentRequestUpdateOneRequiredWithoutPaymentRequestTargetsNestedInput {
  create?: PaymentRequestCreateWithoutPaymentRequestTargetsInput | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutPaymentRequestTargetsInput | null;
  upsert?: PaymentRequestUpsertWithoutPaymentRequestTargetsInput | null;
  connect?: PaymentRequestWhereUniqueInput | null;
  update?: PaymentRequestUpdateWithoutPaymentRequestTargetsInput | null;
}

export interface PaymentRequestUpdateOneWithoutCommunicationsNestedInput {
  create?: PaymentRequestCreateWithoutCommunicationsInput | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutCommunicationsInput | null;
  upsert?: PaymentRequestUpsertWithoutCommunicationsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentRequestWhereUniqueInput | null;
  update?: PaymentRequestUpdateWithoutCommunicationsInput | null;
}

export interface PaymentRequestUpdateOneWithoutPaymentIntentNestedInput {
  create?: PaymentRequestCreateWithoutPaymentIntentInput | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutPaymentIntentInput | null;
  upsert?: PaymentRequestUpsertWithoutPaymentIntentInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentRequestWhereUniqueInput | null;
  update?: PaymentRequestUpdateWithoutPaymentIntentInput | null;
}

export interface PaymentRequestUpdateOneWithoutTasksNestedInput {
  create?: PaymentRequestCreateWithoutTasksInput | null;
  connectOrCreate?: PaymentRequestCreateOrConnectWithoutTasksInput | null;
  upsert?: PaymentRequestUpsertWithoutTasksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentRequestWhereUniqueInput | null;
  update?: PaymentRequestUpdateWithoutTasksInput | null;
}

export interface PaymentRequestUpdateWithWhereUniqueWithoutPatientInput {
  where: PaymentRequestWhereUniqueInput;
  data: PaymentRequestUpdateWithoutPatientInput;
}

export interface PaymentRequestUpdateWithWhereUniqueWithoutPaymentRequestBatchInput {
  where: PaymentRequestWhereUniqueInput;
  data: PaymentRequestUpdateWithoutPaymentRequestBatchInput;
}

export interface PaymentRequestUpdateWithoutCommunicationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumPaymentRequestTypeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestStatusFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  usedAccessCodeAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  accessCode?: NullableStringFieldUpdateOperationsInput | null;
  printEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  paymentRequestBatch?: PaymentRequestBatchUpdateOneWithoutPaymentRequestsNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentRequestsNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutPaymentRequestNestedInput | null;
  tasks?: TaskUpdateManyWithoutPaymentRequestNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentRequestNestedInput | null;
}

export interface PaymentRequestUpdateWithoutPatientInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumPaymentRequestTypeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestStatusFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  usedAccessCodeAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  accessCode?: NullableStringFieldUpdateOperationsInput | null;
  printEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  paymentRequestBatch?: PaymentRequestBatchUpdateOneWithoutPaymentRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPaymentRequestNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutPaymentRequestNestedInput | null;
  tasks?: TaskUpdateManyWithoutPaymentRequestNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentRequestNestedInput | null;
}

export interface PaymentRequestUpdateWithoutPaymentIntentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumPaymentRequestTypeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestStatusFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  usedAccessCodeAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  accessCode?: NullableStringFieldUpdateOperationsInput | null;
  printEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  paymentRequestBatch?: PaymentRequestBatchUpdateOneWithoutPaymentRequestsNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPaymentRequestNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutPaymentRequestNestedInput | null;
  tasks?: TaskUpdateManyWithoutPaymentRequestNestedInput | null;
}

export interface PaymentRequestUpdateWithoutPaymentRequestBatchInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumPaymentRequestTypeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestStatusFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  usedAccessCodeAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  accessCode?: NullableStringFieldUpdateOperationsInput | null;
  printEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPaymentRequestNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutPaymentRequestNestedInput | null;
  tasks?: TaskUpdateManyWithoutPaymentRequestNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentRequestNestedInput | null;
}

export interface PaymentRequestUpdateWithoutPaymentRequestTargetsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumPaymentRequestTypeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestStatusFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  usedAccessCodeAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  accessCode?: NullableStringFieldUpdateOperationsInput | null;
  printEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  paymentRequestBatch?: PaymentRequestBatchUpdateOneWithoutPaymentRequestsNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPaymentRequestNestedInput | null;
  tasks?: TaskUpdateManyWithoutPaymentRequestNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentRequestNestedInput | null;
}

export interface PaymentRequestUpdateWithoutTasksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumPaymentRequestTypeFieldUpdateOperationsInput | null;
  status?: EnumPaymentRequestStatusFieldUpdateOperationsInput | null;
  scheduledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  usedAccessCodeAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  failureReason?: NullableStringFieldUpdateOperationsInput | null;
  amount?: NullableIntFieldUpdateOperationsInput | null;
  s3Key?: NullableStringFieldUpdateOperationsInput | null;
  accessCode?: NullableStringFieldUpdateOperationsInput | null;
  printEnabled?: NullableBoolFieldUpdateOperationsInput | null;
  paymentRequestBatch?: PaymentRequestBatchUpdateOneWithoutPaymentRequestsNestedInput | null;
  patient?: PatientUpdateOneRequiredWithoutPaymentRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutPaymentRequestNestedInput | null;
  paymentRequestTargets?: PaymentRequestTargetUpdateManyWithoutPaymentRequestNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentRequestNestedInput | null;
}

export interface PaymentRequestUpsertWithWhereUniqueWithoutPatientInput {
  where: PaymentRequestWhereUniqueInput;
  update: PaymentRequestUpdateWithoutPatientInput;
  create: PaymentRequestCreateWithoutPatientInput;
}

export interface PaymentRequestUpsertWithWhereUniqueWithoutPaymentRequestBatchInput {
  where: PaymentRequestWhereUniqueInput;
  update: PaymentRequestUpdateWithoutPaymentRequestBatchInput;
  create: PaymentRequestCreateWithoutPaymentRequestBatchInput;
}

export interface PaymentRequestUpsertWithoutCommunicationsInput {
  update: PaymentRequestUpdateWithoutCommunicationsInput;
  create: PaymentRequestCreateWithoutCommunicationsInput;
}

export interface PaymentRequestUpsertWithoutPaymentIntentInput {
  update: PaymentRequestUpdateWithoutPaymentIntentInput;
  create: PaymentRequestCreateWithoutPaymentIntentInput;
}

export interface PaymentRequestUpsertWithoutPaymentRequestTargetsInput {
  update: PaymentRequestUpdateWithoutPaymentRequestTargetsInput;
  create: PaymentRequestCreateWithoutPaymentRequestTargetsInput;
}

export interface PaymentRequestUpsertWithoutTasksInput {
  update: PaymentRequestUpdateWithoutTasksInput;
  create: PaymentRequestCreateWithoutTasksInput;
}

export interface PaymentRequestWhereInput {
  AND?: PaymentRequestWhereInput[] | null;
  OR?: PaymentRequestWhereInput[] | null;
  NOT?: PaymentRequestWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  type?: EnumPaymentRequestTypeFilter | null;
  status?: EnumPaymentRequestStatusFilter | null;
  scheduledAt?: DateTimeNullableFilter | null;
  startedAt?: DateTimeNullableFilter | null;
  completedAt?: DateTimeNullableFilter | null;
  canceledAt?: DateTimeNullableFilter | null;
  failedAt?: DateTimeNullableFilter | null;
  usedAccessCodeAt?: DateTimeNullableFilter | null;
  failureReason?: StringNullableFilter | null;
  amount?: IntNullableFilter | null;
  paymentRequestBatchId?: UuidNullableFilter | null;
  patientId?: UuidFilter | null;
  paymentIntentId?: UuidNullableFilter | null;
  s3Key?: StringNullableFilter | null;
  accessCode?: StringNullableFilter | null;
  printEnabled?: BoolNullableFilter | null;
  paymentRequestBatch?: PaymentRequestBatchRelationFilter | null;
  patient?: PatientRelationFilter | null;
  communications?: CommunicationListRelationFilter | null;
  paymentRequestTargets?: PaymentRequestTargetListRelationFilter | null;
  tasks?: TaskListRelationFilter | null;
  paymentIntent?: PaymentIntentRelationFilter | null;
}

export interface PaymentRequestWhereUniqueInput {
  id?: string | null;
  paymentIntentId?: string | null;
}

export interface PaymentScalarWhereInput {
  AND?: PaymentScalarWhereInput[] | null;
  OR?: PaymentScalarWhereInput[] | null;
  NOT?: PaymentScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  patientAmount?: IntFilter | null;
  insuranceAmount?: IntFilter | null;
  type?: EnumPaymentTypeFilter | null;
  method?: EnumPaymentMethodNullableFilter | null;
  postingStatus?: EnumPostingStatusNullableFilter | null;
  postedAt?: DateTimeNullableFilter | null;
  transactionId?: UuidFilter | null;
  paymentIntentId?: UuidNullableFilter | null;
  stripeConnectedAccountId?: UuidNullableFilter | null;
  refundingPaymentId?: UuidNullableFilter | null;
}

export interface PaymentUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumPaymentTypeFieldUpdateOperationsInput | null;
  method?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  postingStatus?: NullableEnumPostingStatusFieldUpdateOperationsInput | null;
  postedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
}

export interface PaymentUpdateManyWithWhereWithoutPaymentIntentInput {
  where: PaymentScalarWhereInput;
  data: PaymentUpdateManyMutationInput;
}

export interface PaymentUpdateManyWithWhereWithoutPaymentIntentRefundsInput {
  where: PaymentScalarWhereInput;
  data: PaymentUpdateManyMutationInput;
}

export interface PaymentUpdateManyWithWhereWithoutRefundingPaymentInput {
  where: PaymentScalarWhereInput;
  data: PaymentUpdateManyMutationInput;
}

export interface PaymentUpdateManyWithWhereWithoutStripeConnectedAccountInput {
  where: PaymentScalarWhereInput;
  data: PaymentUpdateManyMutationInput;
}

export interface PaymentUpdateManyWithoutPaymentIntentNestedInput {
  create?: PaymentCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutPaymentIntentInput[] | null;
  upsert?: PaymentUpsertWithWhereUniqueWithoutPaymentIntentInput[] | null;
  createMany?: PaymentCreateManyPaymentIntentInputEnvelope | null;
  set?: PaymentWhereUniqueInput[] | null;
  disconnect?: PaymentWhereUniqueInput[] | null;
  delete?: PaymentWhereUniqueInput[] | null;
  connect?: PaymentWhereUniqueInput[] | null;
  update?: PaymentUpdateWithWhereUniqueWithoutPaymentIntentInput[] | null;
  updateMany?: PaymentUpdateManyWithWhereWithoutPaymentIntentInput[] | null;
  deleteMany?: PaymentScalarWhereInput[] | null;
}

export interface PaymentUpdateManyWithoutPaymentIntentRefundsNestedInput {
  create?: PaymentCreateWithoutPaymentIntentRefundsInput[] | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutPaymentIntentRefundsInput[] | null;
  upsert?: PaymentUpsertWithWhereUniqueWithoutPaymentIntentRefundsInput[] | null;
  set?: PaymentWhereUniqueInput[] | null;
  disconnect?: PaymentWhereUniqueInput[] | null;
  delete?: PaymentWhereUniqueInput[] | null;
  connect?: PaymentWhereUniqueInput[] | null;
  update?: PaymentUpdateWithWhereUniqueWithoutPaymentIntentRefundsInput[] | null;
  updateMany?: PaymentUpdateManyWithWhereWithoutPaymentIntentRefundsInput[] | null;
  deleteMany?: PaymentScalarWhereInput[] | null;
}

export interface PaymentUpdateManyWithoutRefundingPaymentNestedInput {
  create?: PaymentCreateWithoutRefundingPaymentInput[] | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutRefundingPaymentInput[] | null;
  upsert?: PaymentUpsertWithWhereUniqueWithoutRefundingPaymentInput[] | null;
  createMany?: PaymentCreateManyRefundingPaymentInputEnvelope | null;
  set?: PaymentWhereUniqueInput[] | null;
  disconnect?: PaymentWhereUniqueInput[] | null;
  delete?: PaymentWhereUniqueInput[] | null;
  connect?: PaymentWhereUniqueInput[] | null;
  update?: PaymentUpdateWithWhereUniqueWithoutRefundingPaymentInput[] | null;
  updateMany?: PaymentUpdateManyWithWhereWithoutRefundingPaymentInput[] | null;
  deleteMany?: PaymentScalarWhereInput[] | null;
}

export interface PaymentUpdateManyWithoutStripeConnectedAccountNestedInput {
  create?: PaymentCreateWithoutStripeConnectedAccountInput[] | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutStripeConnectedAccountInput[] | null;
  upsert?: PaymentUpsertWithWhereUniqueWithoutStripeConnectedAccountInput[] | null;
  createMany?: PaymentCreateManyStripeConnectedAccountInputEnvelope | null;
  set?: PaymentWhereUniqueInput[] | null;
  disconnect?: PaymentWhereUniqueInput[] | null;
  delete?: PaymentWhereUniqueInput[] | null;
  connect?: PaymentWhereUniqueInput[] | null;
  update?: PaymentUpdateWithWhereUniqueWithoutStripeConnectedAccountInput[] | null;
  updateMany?: PaymentUpdateManyWithWhereWithoutStripeConnectedAccountInput[] | null;
  deleteMany?: PaymentScalarWhereInput[] | null;
}

export interface PaymentUpdateOneWithoutRefundedByPaymentsNestedInput {
  create?: PaymentCreateWithoutRefundedByPaymentsInput | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutRefundedByPaymentsInput | null;
  upsert?: PaymentUpsertWithoutRefundedByPaymentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentWhereUniqueInput | null;
  update?: PaymentUpdateWithoutRefundedByPaymentsInput | null;
}

export interface PaymentUpdateOneWithoutStripeFeesNestedInput {
  create?: PaymentCreateWithoutStripeFeesInput | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutStripeFeesInput | null;
  upsert?: PaymentUpsertWithoutStripeFeesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentWhereUniqueInput | null;
  update?: PaymentUpdateWithoutStripeFeesInput | null;
}

export interface PaymentUpdateOneWithoutStripePaymentTransferNestedInput {
  create?: PaymentCreateWithoutStripePaymentTransferInput | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutStripePaymentTransferInput | null;
  upsert?: PaymentUpsertWithoutStripePaymentTransferInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentWhereUniqueInput | null;
  update?: PaymentUpdateWithoutStripePaymentTransferInput | null;
}

export interface PaymentUpdateOneWithoutTransactionNestedInput {
  create?: PaymentCreateWithoutTransactionInput | null;
  connectOrCreate?: PaymentCreateOrConnectWithoutTransactionInput | null;
  upsert?: PaymentUpsertWithoutTransactionInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PaymentWhereUniqueInput | null;
  update?: PaymentUpdateWithoutTransactionInput | null;
}

export interface PaymentUpdateWithWhereUniqueWithoutPaymentIntentInput {
  where: PaymentWhereUniqueInput;
  data: PaymentUpdateWithoutPaymentIntentInput;
}

export interface PaymentUpdateWithWhereUniqueWithoutPaymentIntentRefundsInput {
  where: PaymentWhereUniqueInput;
  data: PaymentUpdateWithoutPaymentIntentRefundsInput;
}

export interface PaymentUpdateWithWhereUniqueWithoutRefundingPaymentInput {
  where: PaymentWhereUniqueInput;
  data: PaymentUpdateWithoutRefundingPaymentInput;
}

export interface PaymentUpdateWithWhereUniqueWithoutStripeConnectedAccountInput {
  where: PaymentWhereUniqueInput;
  data: PaymentUpdateWithoutStripeConnectedAccountInput;
}

export interface PaymentUpdateWithoutPaymentIntentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumPaymentTypeFieldUpdateOperationsInput | null;
  method?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  postingStatus?: NullableEnumPostingStatusFieldUpdateOperationsInput | null;
  postedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutPaymentNestedInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneWithoutPaymentsNestedInput | null;
  stripePaymentTransfer?: StripePaymentTransferUpdateOneWithoutPaymentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentsNestedInput | null;
  refundingPayment?: PaymentUpdateOneWithoutRefundedByPaymentsNestedInput | null;
  refundedByPayments?: PaymentUpdateManyWithoutRefundingPaymentNestedInput | null;
}

export interface PaymentUpdateWithoutPaymentIntentRefundsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumPaymentTypeFieldUpdateOperationsInput | null;
  method?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  postingStatus?: NullableEnumPostingStatusFieldUpdateOperationsInput | null;
  postedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutPaymentNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentsNestedInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneWithoutPaymentsNestedInput | null;
  stripePaymentTransfer?: StripePaymentTransferUpdateOneWithoutPaymentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentNestedInput | null;
  refundingPayment?: PaymentUpdateOneWithoutRefundedByPaymentsNestedInput | null;
  refundedByPayments?: PaymentUpdateManyWithoutRefundingPaymentNestedInput | null;
}

export interface PaymentUpdateWithoutRefundedByPaymentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumPaymentTypeFieldUpdateOperationsInput | null;
  method?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  postingStatus?: NullableEnumPostingStatusFieldUpdateOperationsInput | null;
  postedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutPaymentNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentsNestedInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneWithoutPaymentsNestedInput | null;
  stripePaymentTransfer?: StripePaymentTransferUpdateOneWithoutPaymentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentsNestedInput | null;
  refundingPayment?: PaymentUpdateOneWithoutRefundedByPaymentsNestedInput | null;
}

export interface PaymentUpdateWithoutRefundingPaymentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumPaymentTypeFieldUpdateOperationsInput | null;
  method?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  postingStatus?: NullableEnumPostingStatusFieldUpdateOperationsInput | null;
  postedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutPaymentNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentsNestedInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneWithoutPaymentsNestedInput | null;
  stripePaymentTransfer?: StripePaymentTransferUpdateOneWithoutPaymentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentsNestedInput | null;
  refundedByPayments?: PaymentUpdateManyWithoutRefundingPaymentNestedInput | null;
}

export interface PaymentUpdateWithoutStripeConnectedAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumPaymentTypeFieldUpdateOperationsInput | null;
  method?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  postingStatus?: NullableEnumPostingStatusFieldUpdateOperationsInput | null;
  postedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutPaymentNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentsNestedInput | null;
  stripePaymentTransfer?: StripePaymentTransferUpdateOneWithoutPaymentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentsNestedInput | null;
  refundingPayment?: PaymentUpdateOneWithoutRefundedByPaymentsNestedInput | null;
  refundedByPayments?: PaymentUpdateManyWithoutRefundingPaymentNestedInput | null;
}

export interface PaymentUpdateWithoutStripeFeesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumPaymentTypeFieldUpdateOperationsInput | null;
  method?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  postingStatus?: NullableEnumPostingStatusFieldUpdateOperationsInput | null;
  postedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutPaymentNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentsNestedInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneWithoutPaymentsNestedInput | null;
  stripePaymentTransfer?: StripePaymentTransferUpdateOneWithoutPaymentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentsNestedInput | null;
  refundingPayment?: PaymentUpdateOneWithoutRefundedByPaymentsNestedInput | null;
  refundedByPayments?: PaymentUpdateManyWithoutRefundingPaymentNestedInput | null;
}

export interface PaymentUpdateWithoutStripePaymentTransferInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumPaymentTypeFieldUpdateOperationsInput | null;
  method?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  postingStatus?: NullableEnumPostingStatusFieldUpdateOperationsInput | null;
  postedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  transaction?: TransactionUpdateOneRequiredWithoutPaymentNestedInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentsNestedInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneWithoutPaymentsNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentsNestedInput | null;
  refundingPayment?: PaymentUpdateOneWithoutRefundedByPaymentsNestedInput | null;
  refundedByPayments?: PaymentUpdateManyWithoutRefundingPaymentNestedInput | null;
}

export interface PaymentUpdateWithoutTransactionInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumPaymentTypeFieldUpdateOperationsInput | null;
  method?: NullableEnumPaymentMethodFieldUpdateOperationsInput | null;
  postingStatus?: NullableEnumPostingStatusFieldUpdateOperationsInput | null;
  postedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  paymentIntent?: PaymentIntentUpdateOneWithoutPaymentsNestedInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneWithoutPaymentsNestedInput | null;
  stripePaymentTransfer?: StripePaymentTransferUpdateOneWithoutPaymentNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutPaymentNestedInput | null;
  paymentIntentRefunds?: PaymentIntentRefundUpdateManyWithoutPaymentsNestedInput | null;
  refundingPayment?: PaymentUpdateOneWithoutRefundedByPaymentsNestedInput | null;
  refundedByPayments?: PaymentUpdateManyWithoutRefundingPaymentNestedInput | null;
}

export interface PaymentUpsertWithWhereUniqueWithoutPaymentIntentInput {
  where: PaymentWhereUniqueInput;
  update: PaymentUpdateWithoutPaymentIntentInput;
  create: PaymentCreateWithoutPaymentIntentInput;
}

export interface PaymentUpsertWithWhereUniqueWithoutPaymentIntentRefundsInput {
  where: PaymentWhereUniqueInput;
  update: PaymentUpdateWithoutPaymentIntentRefundsInput;
  create: PaymentCreateWithoutPaymentIntentRefundsInput;
}

export interface PaymentUpsertWithWhereUniqueWithoutRefundingPaymentInput {
  where: PaymentWhereUniqueInput;
  update: PaymentUpdateWithoutRefundingPaymentInput;
  create: PaymentCreateWithoutRefundingPaymentInput;
}

export interface PaymentUpsertWithWhereUniqueWithoutStripeConnectedAccountInput {
  where: PaymentWhereUniqueInput;
  update: PaymentUpdateWithoutStripeConnectedAccountInput;
  create: PaymentCreateWithoutStripeConnectedAccountInput;
}

export interface PaymentUpsertWithoutRefundedByPaymentsInput {
  update: PaymentUpdateWithoutRefundedByPaymentsInput;
  create: PaymentCreateWithoutRefundedByPaymentsInput;
}

export interface PaymentUpsertWithoutStripeFeesInput {
  update: PaymentUpdateWithoutStripeFeesInput;
  create: PaymentCreateWithoutStripeFeesInput;
}

export interface PaymentUpsertWithoutStripePaymentTransferInput {
  update: PaymentUpdateWithoutStripePaymentTransferInput;
  create: PaymentCreateWithoutStripePaymentTransferInput;
}

export interface PaymentUpsertWithoutTransactionInput {
  update: PaymentUpdateWithoutTransactionInput;
  create: PaymentCreateWithoutTransactionInput;
}

export interface PaymentWhereInput {
  AND?: PaymentWhereInput[] | null;
  OR?: PaymentWhereInput[] | null;
  NOT?: PaymentWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  patientAmount?: IntFilter | null;
  insuranceAmount?: IntFilter | null;
  type?: EnumPaymentTypeFilter | null;
  method?: EnumPaymentMethodNullableFilter | null;
  postingStatus?: EnumPostingStatusNullableFilter | null;
  postedAt?: DateTimeNullableFilter | null;
  transactionId?: UuidFilter | null;
  paymentIntentId?: UuidNullableFilter | null;
  stripeConnectedAccountId?: UuidNullableFilter | null;
  refundingPaymentId?: UuidNullableFilter | null;
  transaction?: TransactionRelationFilter | null;
  paymentIntent?: PaymentIntentRelationFilter | null;
  stripeConnectedAccount?: StripeConnectedAccountRelationFilter | null;
  stripePaymentTransfer?: StripePaymentTransferRelationFilter | null;
  stripeFees?: StripeFeeListRelationFilter | null;
  paymentIntentRefunds?: PaymentIntentRefundListRelationFilter | null;
  refundingPayment?: PaymentRelationFilter | null;
  refundedByPayments?: PaymentListRelationFilter | null;
}

export interface PaymentWhereUniqueInput {
  id?: string | null;
  transactionId?: string | null;
}

export interface PlanBenefitTemplateCreateManyPlanTypeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  isDefault?: boolean | null;
  networkStatus?: boolean | null;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  maxOutOfPocket?: number | null;
  maxVisits?: number | null;
  authRequired?: boolean | null;
  nonCovered?: boolean | null;
  providerServiceConfigurationId?: string | null;
}

export interface PlanBenefitTemplateCreateManyPlanTypeInputEnvelope {
  data: PlanBenefitTemplateCreateManyPlanTypeInput[];
  skipDuplicates?: boolean | null;
}

export interface PlanBenefitTemplateCreateManyProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  isDefault?: boolean | null;
  networkStatus?: boolean | null;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  maxOutOfPocket?: number | null;
  maxVisits?: number | null;
  authRequired?: boolean | null;
  nonCovered?: boolean | null;
  planTypeId: string;
}

export interface PlanBenefitTemplateCreateManyProviderServiceConfigurationInputEnvelope {
  data: PlanBenefitTemplateCreateManyProviderServiceConfigurationInput[];
  skipDuplicates?: boolean | null;
}

export interface PlanBenefitTemplateCreateNestedManyWithoutPlanTypeInput {
  create?: PlanBenefitTemplateCreateWithoutPlanTypeInput[] | null;
  connectOrCreate?: PlanBenefitTemplateCreateOrConnectWithoutPlanTypeInput[] | null;
  createMany?: PlanBenefitTemplateCreateManyPlanTypeInputEnvelope | null;
  connect?: PlanBenefitTemplateWhereUniqueInput[] | null;
}

export interface PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput {
  create?: PlanBenefitTemplateCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: PlanBenefitTemplateCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  createMany?: PlanBenefitTemplateCreateManyProviderServiceConfigurationInputEnvelope | null;
  connect?: PlanBenefitTemplateWhereUniqueInput[] | null;
}

export interface PlanBenefitTemplateCreateNestedOneWithoutCoverageBenefitsInput {
  create?: PlanBenefitTemplateCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: PlanBenefitTemplateCreateOrConnectWithoutCoverageBenefitsInput | null;
  connect?: PlanBenefitTemplateWhereUniqueInput | null;
}

export interface PlanBenefitTemplateCreateOrConnectWithoutCoverageBenefitsInput {
  where: PlanBenefitTemplateWhereUniqueInput;
  create: PlanBenefitTemplateCreateWithoutCoverageBenefitsInput;
}

export interface PlanBenefitTemplateCreateOrConnectWithoutPlanTypeInput {
  where: PlanBenefitTemplateWhereUniqueInput;
  create: PlanBenefitTemplateCreateWithoutPlanTypeInput;
}

export interface PlanBenefitTemplateCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: PlanBenefitTemplateWhereUniqueInput;
  create: PlanBenefitTemplateCreateWithoutProviderServiceConfigurationInput;
}

export interface PlanBenefitTemplateCreateWithoutCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  isDefault?: boolean | null;
  networkStatus?: boolean | null;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  maxOutOfPocket?: number | null;
  maxVisits?: number | null;
  authRequired?: boolean | null;
  nonCovered?: boolean | null;
  planType: PlanTypeCreateNestedOneWithoutPlanBenefitTemplatesInput;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutPlanBenefitTemplatesInput | null;
}

export interface PlanBenefitTemplateCreateWithoutPlanTypeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  isDefault?: boolean | null;
  networkStatus?: boolean | null;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  maxOutOfPocket?: number | null;
  maxVisits?: number | null;
  authRequired?: boolean | null;
  nonCovered?: boolean | null;
  providerServiceConfiguration?: ProviderServiceConfigurationCreateNestedOneWithoutPlanBenefitTemplatesInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutPlanBenefitTemplateInput | null;
}

export interface PlanBenefitTemplateCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  isDefault?: boolean | null;
  networkStatus?: boolean | null;
  coverageLevel?: string | null;
  copay?: number | null;
  coinsurance?: any | null;
  deductibleApplies?: boolean | null;
  maxDeductible?: number | null;
  maxOutOfPocket?: number | null;
  maxVisits?: number | null;
  authRequired?: boolean | null;
  nonCovered?: boolean | null;
  planType: PlanTypeCreateNestedOneWithoutPlanBenefitTemplatesInput;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutPlanBenefitTemplateInput | null;
}

export interface PlanBenefitTemplateListRelationFilter {
  every?: PlanBenefitTemplateWhereInput | null;
  some?: PlanBenefitTemplateWhereInput | null;
  none?: PlanBenefitTemplateWhereInput | null;
}

export interface PlanBenefitTemplateRelationFilter {
  is?: PlanBenefitTemplateWhereInput | null;
  isNot?: PlanBenefitTemplateWhereInput | null;
}

export interface PlanBenefitTemplateScalarWhereInput {
  AND?: PlanBenefitTemplateScalarWhereInput[] | null;
  OR?: PlanBenefitTemplateScalarWhereInput[] | null;
  NOT?: PlanBenefitTemplateScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  isDefault?: BoolFilter | null;
  networkStatus?: BoolNullableFilter | null;
  coverageLevel?: StringNullableFilter | null;
  copay?: IntNullableFilter | null;
  coinsurance?: DecimalNullableFilter | null;
  deductibleApplies?: BoolNullableFilter | null;
  maxDeductible?: IntNullableFilter | null;
  maxOutOfPocket?: IntNullableFilter | null;
  maxVisits?: IntNullableFilter | null;
  authRequired?: BoolNullableFilter | null;
  nonCovered?: BoolNullableFilter | null;
  planTypeId?: UuidFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
}

export interface PlanBenefitTemplateUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  isDefault?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: NullableBoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  nonCovered?: NullableBoolFieldUpdateOperationsInput | null;
}

export interface PlanBenefitTemplateUpdateManyWithWhereWithoutPlanTypeInput {
  where: PlanBenefitTemplateScalarWhereInput;
  data: PlanBenefitTemplateUpdateManyMutationInput;
}

export interface PlanBenefitTemplateUpdateManyWithWhereWithoutProviderServiceConfigurationInput {
  where: PlanBenefitTemplateScalarWhereInput;
  data: PlanBenefitTemplateUpdateManyMutationInput;
}

export interface PlanBenefitTemplateUpdateManyWithoutPlanTypeNestedInput {
  create?: PlanBenefitTemplateCreateWithoutPlanTypeInput[] | null;
  connectOrCreate?: PlanBenefitTemplateCreateOrConnectWithoutPlanTypeInput[] | null;
  upsert?: PlanBenefitTemplateUpsertWithWhereUniqueWithoutPlanTypeInput[] | null;
  createMany?: PlanBenefitTemplateCreateManyPlanTypeInputEnvelope | null;
  set?: PlanBenefitTemplateWhereUniqueInput[] | null;
  disconnect?: PlanBenefitTemplateWhereUniqueInput[] | null;
  delete?: PlanBenefitTemplateWhereUniqueInput[] | null;
  connect?: PlanBenefitTemplateWhereUniqueInput[] | null;
  update?: PlanBenefitTemplateUpdateWithWhereUniqueWithoutPlanTypeInput[] | null;
  updateMany?: PlanBenefitTemplateUpdateManyWithWhereWithoutPlanTypeInput[] | null;
  deleteMany?: PlanBenefitTemplateScalarWhereInput[] | null;
}

export interface PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput {
  create?: PlanBenefitTemplateCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: PlanBenefitTemplateCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  upsert?: PlanBenefitTemplateUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  createMany?: PlanBenefitTemplateCreateManyProviderServiceConfigurationInputEnvelope | null;
  set?: PlanBenefitTemplateWhereUniqueInput[] | null;
  disconnect?: PlanBenefitTemplateWhereUniqueInput[] | null;
  delete?: PlanBenefitTemplateWhereUniqueInput[] | null;
  connect?: PlanBenefitTemplateWhereUniqueInput[] | null;
  update?: PlanBenefitTemplateUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  updateMany?: PlanBenefitTemplateUpdateManyWithWhereWithoutProviderServiceConfigurationInput[] | null;
  deleteMany?: PlanBenefitTemplateScalarWhereInput[] | null;
}

export interface PlanBenefitTemplateUpdateOneWithoutCoverageBenefitsNestedInput {
  create?: PlanBenefitTemplateCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: PlanBenefitTemplateCreateOrConnectWithoutCoverageBenefitsInput | null;
  upsert?: PlanBenefitTemplateUpsertWithoutCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PlanBenefitTemplateWhereUniqueInput | null;
  update?: PlanBenefitTemplateUpdateWithoutCoverageBenefitsInput | null;
}

export interface PlanBenefitTemplateUpdateWithWhereUniqueWithoutPlanTypeInput {
  where: PlanBenefitTemplateWhereUniqueInput;
  data: PlanBenefitTemplateUpdateWithoutPlanTypeInput;
}

export interface PlanBenefitTemplateUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: PlanBenefitTemplateWhereUniqueInput;
  data: PlanBenefitTemplateUpdateWithoutProviderServiceConfigurationInput;
}

export interface PlanBenefitTemplateUpdateWithoutCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  isDefault?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: NullableBoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  nonCovered?: NullableBoolFieldUpdateOperationsInput | null;
  planType?: PlanTypeUpdateOneRequiredWithoutPlanBenefitTemplatesNestedInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutPlanBenefitTemplatesNestedInput | null;
}

export interface PlanBenefitTemplateUpdateWithoutPlanTypeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  isDefault?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: NullableBoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  nonCovered?: NullableBoolFieldUpdateOperationsInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneWithoutPlanBenefitTemplatesNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutPlanBenefitTemplateNestedInput | null;
}

export interface PlanBenefitTemplateUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  isDefault?: BoolFieldUpdateOperationsInput | null;
  networkStatus?: NullableBoolFieldUpdateOperationsInput | null;
  coverageLevel?: NullableStringFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  deductibleApplies?: NullableBoolFieldUpdateOperationsInput | null;
  maxDeductible?: NullableIntFieldUpdateOperationsInput | null;
  maxOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  maxVisits?: NullableIntFieldUpdateOperationsInput | null;
  authRequired?: NullableBoolFieldUpdateOperationsInput | null;
  nonCovered?: NullableBoolFieldUpdateOperationsInput | null;
  planType?: PlanTypeUpdateOneRequiredWithoutPlanBenefitTemplatesNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutPlanBenefitTemplateNestedInput | null;
}

export interface PlanBenefitTemplateUpsertWithWhereUniqueWithoutPlanTypeInput {
  where: PlanBenefitTemplateWhereUniqueInput;
  update: PlanBenefitTemplateUpdateWithoutPlanTypeInput;
  create: PlanBenefitTemplateCreateWithoutPlanTypeInput;
}

export interface PlanBenefitTemplateUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: PlanBenefitTemplateWhereUniqueInput;
  update: PlanBenefitTemplateUpdateWithoutProviderServiceConfigurationInput;
  create: PlanBenefitTemplateCreateWithoutProviderServiceConfigurationInput;
}

export interface PlanBenefitTemplateUpsertWithoutCoverageBenefitsInput {
  update: PlanBenefitTemplateUpdateWithoutCoverageBenefitsInput;
  create: PlanBenefitTemplateCreateWithoutCoverageBenefitsInput;
}

export interface PlanBenefitTemplateWhereInput {
  AND?: PlanBenefitTemplateWhereInput[] | null;
  OR?: PlanBenefitTemplateWhereInput[] | null;
  NOT?: PlanBenefitTemplateWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  isDefault?: BoolFilter | null;
  networkStatus?: BoolNullableFilter | null;
  coverageLevel?: StringNullableFilter | null;
  copay?: IntNullableFilter | null;
  coinsurance?: DecimalNullableFilter | null;
  deductibleApplies?: BoolNullableFilter | null;
  maxDeductible?: IntNullableFilter | null;
  maxOutOfPocket?: IntNullableFilter | null;
  maxVisits?: IntNullableFilter | null;
  authRequired?: BoolNullableFilter | null;
  nonCovered?: BoolNullableFilter | null;
  planTypeId?: UuidFilter | null;
  providerServiceConfigurationId?: UuidNullableFilter | null;
  planType?: PlanTypeRelationFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationRelationFilter | null;
  coverageBenefits?: CoverageBenefitListRelationFilter | null;
}

export interface PlanBenefitTemplateWhereUniqueInput {
  id?: string | null;
}

export interface PlanCreateManyPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  planNumber?: string | null;
  name?: string | null;
  type?: string | null;
}

export interface PlanCreateManyPayerInputEnvelope {
  data: PlanCreateManyPayerInput[];
  skipDuplicates?: boolean | null;
}

export interface PlanCreateNestedManyWithoutPayerInput {
  create?: PlanCreateWithoutPayerInput[] | null;
  connectOrCreate?: PlanCreateOrConnectWithoutPayerInput[] | null;
  createMany?: PlanCreateManyPayerInputEnvelope | null;
  connect?: PlanWhereUniqueInput[] | null;
}

export interface PlanCreateNestedOneWithoutAccountCoveragesInput {
  create?: PlanCreateWithoutAccountCoveragesInput | null;
  connectOrCreate?: PlanCreateOrConnectWithoutAccountCoveragesInput | null;
  connect?: PlanWhereUniqueInput | null;
}

export interface PlanCreateNestedOneWithoutInsurancePoliciesInput {
  create?: PlanCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: PlanCreateOrConnectWithoutInsurancePoliciesInput | null;
  connect?: PlanWhereUniqueInput | null;
}

export interface PlanCreateOrConnectWithoutAccountCoveragesInput {
  where: PlanWhereUniqueInput;
  create: PlanCreateWithoutAccountCoveragesInput;
}

export interface PlanCreateOrConnectWithoutInsurancePoliciesInput {
  where: PlanWhereUniqueInput;
  create: PlanCreateWithoutInsurancePoliciesInput;
}

export interface PlanCreateOrConnectWithoutPayerInput {
  where: PlanWhereUniqueInput;
  create: PlanCreateWithoutPayerInput;
}

export interface PlanCreateWithoutAccountCoveragesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  planNumber?: string | null;
  name?: string | null;
  type?: string | null;
  payer: PayerCreateNestedOneWithoutPlansInput;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPlanInput | null;
}

export interface PlanCreateWithoutInsurancePoliciesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  planNumber?: string | null;
  name?: string | null;
  type?: string | null;
  payer: PayerCreateNestedOneWithoutPlansInput;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPlanInput | null;
}

export interface PlanCreateWithoutPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  planNumber?: string | null;
  name?: string | null;
  type?: string | null;
  accountCoverages?: AccountCoverageCreateNestedManyWithoutPlanInput | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPlanInput | null;
}

export interface PlanListRelationFilter {
  every?: PlanWhereInput | null;
  some?: PlanWhereInput | null;
  none?: PlanWhereInput | null;
}

export interface PlanPayerIdPlanNumberNameCompoundUniqueInput {
  payerId: string;
  planNumber: string;
  name: string;
}

export interface PlanRelationFilter {
  is?: PlanWhereInput | null;
  isNot?: PlanWhereInput | null;
}

export interface PlanScalarWhereInput {
  AND?: PlanScalarWhereInput[] | null;
  OR?: PlanScalarWhereInput[] | null;
  NOT?: PlanScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  planNumber?: StringNullableFilter | null;
  name?: StringNullableFilter | null;
  type?: StringNullableFilter | null;
  payerId?: UuidFilter | null;
}

export interface PlanTypeCreateNestedOneWithoutInsurancePoliciesInput {
  create?: PlanTypeCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: PlanTypeCreateOrConnectWithoutInsurancePoliciesInput | null;
  connect?: PlanTypeWhereUniqueInput | null;
}

export interface PlanTypeCreateNestedOneWithoutPlanBenefitTemplatesInput {
  create?: PlanTypeCreateWithoutPlanBenefitTemplatesInput | null;
  connectOrCreate?: PlanTypeCreateOrConnectWithoutPlanBenefitTemplatesInput | null;
  connect?: PlanTypeWhereUniqueInput | null;
}

export interface PlanTypeCreateOrConnectWithoutInsurancePoliciesInput {
  where: PlanTypeWhereUniqueInput;
  create: PlanTypeCreateWithoutInsurancePoliciesInput;
}

export interface PlanTypeCreateOrConnectWithoutPlanBenefitTemplatesInput {
  where: PlanTypeWhereUniqueInput;
  create: PlanTypeCreateWithoutPlanBenefitTemplatesInput;
}

export interface PlanTypeCreateWithoutInsurancePoliciesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  insuranceType: InsuranceType;
  medicareType?: MedicareType | null;
  medigapPlanType?: MedigapPlanType | null;
  medicaidType?: MedicaidType | null;
  tricareType?: TricareType | null;
  commercialType?: CommercialType | null;
  period?: string | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutPlanTypeInput | null;
}

export interface PlanTypeCreateWithoutPlanBenefitTemplatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  insuranceType: InsuranceType;
  medicareType?: MedicareType | null;
  medigapPlanType?: MedigapPlanType | null;
  medicaidType?: MedicaidType | null;
  tricareType?: TricareType | null;
  commercialType?: CommercialType | null;
  period?: string | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutPlanTypeInput | null;
}

export interface PlanTypeInput {
  id: string;
  insuranceType?: InsuranceType | null;
  medicareType?: MedicareType | null;
  medigapPlanType?: MedigapPlanType | null;
  medicaidType?: MedicaidType | null;
  tricareType?: TricareType | null;
}

export interface PlanTypeInsuranceTypeMedicareTypeMedigapPlanTypeMedicaidTypeTricareTypeCommercialTypePeriodCompoundUniqueInput {
  insuranceType: InsuranceType;
  medicareType: MedicareType;
  medigapPlanType: MedigapPlanType;
  medicaidType: MedicaidType;
  tricareType: TricareType;
  commercialType: CommercialType;
  period: string;
}

export interface PlanTypeRelationFilter {
  is?: PlanTypeWhereInput | null;
  isNot?: PlanTypeWhereInput | null;
}

export interface PlanTypeUpdateOneRequiredWithoutPlanBenefitTemplatesNestedInput {
  create?: PlanTypeCreateWithoutPlanBenefitTemplatesInput | null;
  connectOrCreate?: PlanTypeCreateOrConnectWithoutPlanBenefitTemplatesInput | null;
  upsert?: PlanTypeUpsertWithoutPlanBenefitTemplatesInput | null;
  connect?: PlanTypeWhereUniqueInput | null;
  update?: PlanTypeUpdateWithoutPlanBenefitTemplatesInput | null;
}

export interface PlanTypeUpdateOneWithoutInsurancePoliciesNestedInput {
  create?: PlanTypeCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: PlanTypeCreateOrConnectWithoutInsurancePoliciesInput | null;
  upsert?: PlanTypeUpsertWithoutInsurancePoliciesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PlanTypeWhereUniqueInput | null;
  update?: PlanTypeUpdateWithoutInsurancePoliciesInput | null;
}

export interface PlanTypeUpdateWithoutInsurancePoliciesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  insuranceType?: EnumInsuranceTypeFieldUpdateOperationsInput | null;
  medicareType?: NullableEnumMedicareTypeFieldUpdateOperationsInput | null;
  medigapPlanType?: NullableEnumMedigapPlanTypeFieldUpdateOperationsInput | null;
  medicaidType?: NullableEnumMedicaidTypeFieldUpdateOperationsInput | null;
  tricareType?: NullableEnumTricareTypeFieldUpdateOperationsInput | null;
  commercialType?: NullableEnumCommercialTypeFieldUpdateOperationsInput | null;
  period?: NullableStringFieldUpdateOperationsInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutPlanTypeNestedInput | null;
}

export interface PlanTypeUpdateWithoutPlanBenefitTemplatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  insuranceType?: EnumInsuranceTypeFieldUpdateOperationsInput | null;
  medicareType?: NullableEnumMedicareTypeFieldUpdateOperationsInput | null;
  medigapPlanType?: NullableEnumMedigapPlanTypeFieldUpdateOperationsInput | null;
  medicaidType?: NullableEnumMedicaidTypeFieldUpdateOperationsInput | null;
  tricareType?: NullableEnumTricareTypeFieldUpdateOperationsInput | null;
  commercialType?: NullableEnumCommercialTypeFieldUpdateOperationsInput | null;
  period?: NullableStringFieldUpdateOperationsInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPlanTypeNestedInput | null;
}

export interface PlanTypeUpsertWithoutInsurancePoliciesInput {
  update: PlanTypeUpdateWithoutInsurancePoliciesInput;
  create: PlanTypeCreateWithoutInsurancePoliciesInput;
}

export interface PlanTypeUpsertWithoutPlanBenefitTemplatesInput {
  update: PlanTypeUpdateWithoutPlanBenefitTemplatesInput;
  create: PlanTypeCreateWithoutPlanBenefitTemplatesInput;
}

export interface PlanTypeWhereInput {
  AND?: PlanTypeWhereInput[] | null;
  OR?: PlanTypeWhereInput[] | null;
  NOT?: PlanTypeWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  insuranceType?: EnumInsuranceTypeFilter | null;
  medicareType?: EnumMedicareTypeNullableFilter | null;
  medigapPlanType?: EnumMedigapPlanTypeNullableFilter | null;
  medicaidType?: EnumMedicaidTypeNullableFilter | null;
  tricareType?: EnumTricareTypeNullableFilter | null;
  commercialType?: EnumCommercialTypeNullableFilter | null;
  period?: StringNullableFilter | null;
  planBenefitTemplates?: PlanBenefitTemplateListRelationFilter | null;
  insurancePolicies?: InsurancePolicyListRelationFilter | null;
}

export interface PlanTypeWhereUniqueInput {
  id?: string | null;
  insuranceType_medicareType_medigapPlanType_medicaidType_tricareType_commercialType_period?: PlanTypeInsuranceTypeMedicareTypeMedigapPlanTypeMedicaidTypeTricareTypeCommercialTypePeriodCompoundUniqueInput | null;
}

export interface PlanUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  planNumber?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
}

export interface PlanUpdateManyWithWhereWithoutPayerInput {
  where: PlanScalarWhereInput;
  data: PlanUpdateManyMutationInput;
}

export interface PlanUpdateManyWithoutPayerNestedInput {
  create?: PlanCreateWithoutPayerInput[] | null;
  connectOrCreate?: PlanCreateOrConnectWithoutPayerInput[] | null;
  upsert?: PlanUpsertWithWhereUniqueWithoutPayerInput[] | null;
  createMany?: PlanCreateManyPayerInputEnvelope | null;
  set?: PlanWhereUniqueInput[] | null;
  disconnect?: PlanWhereUniqueInput[] | null;
  delete?: PlanWhereUniqueInput[] | null;
  connect?: PlanWhereUniqueInput[] | null;
  update?: PlanUpdateWithWhereUniqueWithoutPayerInput[] | null;
  updateMany?: PlanUpdateManyWithWhereWithoutPayerInput[] | null;
  deleteMany?: PlanScalarWhereInput[] | null;
}

export interface PlanUpdateOneWithoutAccountCoveragesNestedInput {
  create?: PlanCreateWithoutAccountCoveragesInput | null;
  connectOrCreate?: PlanCreateOrConnectWithoutAccountCoveragesInput | null;
  upsert?: PlanUpsertWithoutAccountCoveragesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PlanWhereUniqueInput | null;
  update?: PlanUpdateWithoutAccountCoveragesInput | null;
}

export interface PlanUpdateOneWithoutInsurancePoliciesNestedInput {
  create?: PlanCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: PlanCreateOrConnectWithoutInsurancePoliciesInput | null;
  upsert?: PlanUpsertWithoutInsurancePoliciesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: PlanWhereUniqueInput | null;
  update?: PlanUpdateWithoutInsurancePoliciesInput | null;
}

export interface PlanUpdateWithWhereUniqueWithoutPayerInput {
  where: PlanWhereUniqueInput;
  data: PlanUpdateWithoutPayerInput;
}

export interface PlanUpdateWithoutAccountCoveragesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  planNumber?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  payer?: PayerUpdateOneRequiredWithoutPlansNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPlanNestedInput | null;
}

export interface PlanUpdateWithoutInsurancePoliciesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  planNumber?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  payer?: PayerUpdateOneRequiredWithoutPlansNestedInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPlanNestedInput | null;
}

export interface PlanUpdateWithoutPayerInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  planNumber?: NullableStringFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  type?: NullableStringFieldUpdateOperationsInput | null;
  accountCoverages?: AccountCoverageUpdateManyWithoutPlanNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutPlanNestedInput | null;
}

export interface PlanUpsertWithWhereUniqueWithoutPayerInput {
  where: PlanWhereUniqueInput;
  update: PlanUpdateWithoutPayerInput;
  create: PlanCreateWithoutPayerInput;
}

export interface PlanUpsertWithoutAccountCoveragesInput {
  update: PlanUpdateWithoutAccountCoveragesInput;
  create: PlanCreateWithoutAccountCoveragesInput;
}

export interface PlanUpsertWithoutInsurancePoliciesInput {
  update: PlanUpdateWithoutInsurancePoliciesInput;
  create: PlanCreateWithoutInsurancePoliciesInput;
}

export interface PlanWhereInput {
  AND?: PlanWhereInput[] | null;
  OR?: PlanWhereInput[] | null;
  NOT?: PlanWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  planNumber?: StringNullableFilter | null;
  name?: StringNullableFilter | null;
  type?: StringNullableFilter | null;
  payerId?: UuidFilter | null;
  payer?: PayerRelationFilter | null;
  accountCoverages?: AccountCoverageListRelationFilter | null;
  insurancePolicies?: InsurancePolicyListRelationFilter | null;
}

export interface PlanWhereUniqueInput {
  id?: string | null;
  payerId_planNumber_name?: PlanPayerIdPlanNumberNameCompoundUniqueInput | null;
}

export interface PreVisitMappingConditionCreateManyPreVisitMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
}

export interface PreVisitMappingConditionCreateManyPreVisitMappingInputEnvelope {
  data: PreVisitMappingConditionCreateManyPreVisitMappingInput[];
  skipDuplicates?: boolean | null;
}

export interface PreVisitMappingConditionCreateNestedManyWithoutPreVisitMappingInput {
  create?: PreVisitMappingConditionCreateWithoutPreVisitMappingInput[] | null;
  connectOrCreate?: PreVisitMappingConditionCreateOrConnectWithoutPreVisitMappingInput[] | null;
  createMany?: PreVisitMappingConditionCreateManyPreVisitMappingInputEnvelope | null;
  connect?: PreVisitMappingConditionWhereUniqueInput[] | null;
}

export interface PreVisitMappingConditionCreateOrConnectWithoutPreVisitMappingInput {
  where: PreVisitMappingConditionWhereUniqueInput;
  create: PreVisitMappingConditionCreateWithoutPreVisitMappingInput;
}

export interface PreVisitMappingConditionCreateWithoutPreVisitMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  attribute: any;
  comparison: Comparison;
  attributeType: string;
}

export interface PreVisitMappingConditionListRelationFilter {
  every?: PreVisitMappingConditionWhereInput | null;
  some?: PreVisitMappingConditionWhereInput | null;
  none?: PreVisitMappingConditionWhereInput | null;
}

export interface PreVisitMappingConditionScalarWhereInput {
  AND?: PreVisitMappingConditionScalarWhereInput[] | null;
  OR?: PreVisitMappingConditionScalarWhereInput[] | null;
  NOT?: PreVisitMappingConditionScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  preVisitMappingId?: UuidFilter | null;
  attribute?: JsonFilter | null;
  comparison?: EnumComparisonFilter | null;
  attributeType?: StringFilter | null;
}

export interface PreVisitMappingConditionUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
}

export interface PreVisitMappingConditionUpdateManyWithWhereWithoutPreVisitMappingInput {
  where: PreVisitMappingConditionScalarWhereInput;
  data: PreVisitMappingConditionUpdateManyMutationInput;
}

export interface PreVisitMappingConditionUpdateManyWithoutPreVisitMappingNestedInput {
  create?: PreVisitMappingConditionCreateWithoutPreVisitMappingInput[] | null;
  connectOrCreate?: PreVisitMappingConditionCreateOrConnectWithoutPreVisitMappingInput[] | null;
  upsert?: PreVisitMappingConditionUpsertWithWhereUniqueWithoutPreVisitMappingInput[] | null;
  createMany?: PreVisitMappingConditionCreateManyPreVisitMappingInputEnvelope | null;
  set?: PreVisitMappingConditionWhereUniqueInput[] | null;
  disconnect?: PreVisitMappingConditionWhereUniqueInput[] | null;
  delete?: PreVisitMappingConditionWhereUniqueInput[] | null;
  connect?: PreVisitMappingConditionWhereUniqueInput[] | null;
  update?: PreVisitMappingConditionUpdateWithWhereUniqueWithoutPreVisitMappingInput[] | null;
  updateMany?: PreVisitMappingConditionUpdateManyWithWhereWithoutPreVisitMappingInput[] | null;
  deleteMany?: PreVisitMappingConditionScalarWhereInput[] | null;
}

export interface PreVisitMappingConditionUpdateWithWhereUniqueWithoutPreVisitMappingInput {
  where: PreVisitMappingConditionWhereUniqueInput;
  data: PreVisitMappingConditionUpdateWithoutPreVisitMappingInput;
}

export interface PreVisitMappingConditionUpdateWithoutPreVisitMappingInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  attribute?: any | null;
  comparison?: EnumComparisonFieldUpdateOperationsInput | null;
  attributeType?: StringFieldUpdateOperationsInput | null;
}

export interface PreVisitMappingConditionUpsertWithWhereUniqueWithoutPreVisitMappingInput {
  where: PreVisitMappingConditionWhereUniqueInput;
  update: PreVisitMappingConditionUpdateWithoutPreVisitMappingInput;
  create: PreVisitMappingConditionCreateWithoutPreVisitMappingInput;
}

export interface PreVisitMappingConditionWhereInput {
  AND?: PreVisitMappingConditionWhereInput[] | null;
  OR?: PreVisitMappingConditionWhereInput[] | null;
  NOT?: PreVisitMappingConditionWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  preVisitMappingId?: UuidFilter | null;
  attribute?: JsonFilter | null;
  comparison?: EnumComparisonFilter | null;
  attributeType?: StringFilter | null;
  preVisitMapping?: PreVisitMappingRelationFilter | null;
}

export interface PreVisitMappingConditionWhereUniqueInput {
  id?: string | null;
}

export interface PreVisitMappingCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedPreVisitMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedPreVisitMappingsInput;
  location: LocationCreateNestedOneWithoutPreVisitMappingsInput;
  preVisitMappingConditions?: PreVisitMappingConditionCreateNestedManyWithoutPreVisitMappingInput | null;
}

export interface PreVisitMappingCreateManyCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUpdatedById: string;
  priority: number;
  locationId: string;
  name: string;
  notes?: string | null;
}

export interface PreVisitMappingCreateManyCreatedByInputEnvelope {
  data: PreVisitMappingCreateManyCreatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface PreVisitMappingCreateManyLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  priority: number;
  locationId: string;
  name: string;
  notes?: string | null;
}

export interface PreVisitMappingCreateManyLastUpdatedByInputEnvelope {
  data: PreVisitMappingCreateManyLastUpdatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface PreVisitMappingCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  lastUpdatedById: string;
  priority: number;
  name: string;
  notes?: string | null;
}

export interface PreVisitMappingCreateManyLocationInputEnvelope {
  data: PreVisitMappingCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface PreVisitMappingCreateNestedManyWithoutCreatedByInput {
  create?: PreVisitMappingCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PreVisitMappingCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: PreVisitMappingCreateManyCreatedByInputEnvelope | null;
  connect?: PreVisitMappingWhereUniqueInput[] | null;
}

export interface PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput {
  create?: PreVisitMappingCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: PreVisitMappingCreateOrConnectWithoutLastUpdatedByInput[] | null;
  createMany?: PreVisitMappingCreateManyLastUpdatedByInputEnvelope | null;
  connect?: PreVisitMappingWhereUniqueInput[] | null;
}

export interface PreVisitMappingCreateNestedManyWithoutLocationInput {
  create?: PreVisitMappingCreateWithoutLocationInput[] | null;
  connectOrCreate?: PreVisitMappingCreateOrConnectWithoutLocationInput[] | null;
  createMany?: PreVisitMappingCreateManyLocationInputEnvelope | null;
  connect?: PreVisitMappingWhereUniqueInput[] | null;
}

export interface PreVisitMappingCreateOrConnectWithoutCreatedByInput {
  where: PreVisitMappingWhereUniqueInput;
  create: PreVisitMappingCreateWithoutCreatedByInput;
}

export interface PreVisitMappingCreateOrConnectWithoutLastUpdatedByInput {
  where: PreVisitMappingWhereUniqueInput;
  create: PreVisitMappingCreateWithoutLastUpdatedByInput;
}

export interface PreVisitMappingCreateOrConnectWithoutLocationInput {
  where: PreVisitMappingWhereUniqueInput;
  create: PreVisitMappingCreateWithoutLocationInput;
}

export interface PreVisitMappingCreateWithoutCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedPreVisitMappingsInput;
  location: LocationCreateNestedOneWithoutPreVisitMappingsInput;
  preVisitMappingConditions?: PreVisitMappingConditionCreateNestedManyWithoutPreVisitMappingInput | null;
}

export interface PreVisitMappingCreateWithoutLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedPreVisitMappingsInput;
  location: LocationCreateNestedOneWithoutPreVisitMappingsInput;
  preVisitMappingConditions?: PreVisitMappingConditionCreateNestedManyWithoutPreVisitMappingInput | null;
}

export interface PreVisitMappingCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  priority: number;
  name: string;
  notes?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedPreVisitMappingsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutUpdatedPreVisitMappingsInput;
  preVisitMappingConditions?: PreVisitMappingConditionCreateNestedManyWithoutPreVisitMappingInput | null;
}

export interface PreVisitMappingListRelationFilter {
  every?: PreVisitMappingWhereInput | null;
  some?: PreVisitMappingWhereInput | null;
  none?: PreVisitMappingWhereInput | null;
}

export interface PreVisitMappingOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PreVisitMappingRelationFilter {
  is?: PreVisitMappingWhereInput | null;
  isNot?: PreVisitMappingWhereInput | null;
}

export interface PreVisitMappingScalarWhereInput {
  AND?: PreVisitMappingScalarWhereInput[] | null;
  OR?: PreVisitMappingScalarWhereInput[] | null;
  NOT?: PreVisitMappingScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  priority?: FloatFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
  notes?: StringNullableFilter | null;
}

export interface PreVisitMappingUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedPreVisitMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedPreVisitMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPreVisitMappingsNestedInput | null;
  preVisitMappingConditions?: PreVisitMappingConditionUpdateManyWithoutPreVisitMappingNestedInput | null;
}

export interface PreVisitMappingUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
}

export interface PreVisitMappingUpdateManyWithWhereWithoutCreatedByInput {
  where: PreVisitMappingScalarWhereInput;
  data: PreVisitMappingUpdateManyMutationInput;
}

export interface PreVisitMappingUpdateManyWithWhereWithoutLastUpdatedByInput {
  where: PreVisitMappingScalarWhereInput;
  data: PreVisitMappingUpdateManyMutationInput;
}

export interface PreVisitMappingUpdateManyWithWhereWithoutLocationInput {
  where: PreVisitMappingScalarWhereInput;
  data: PreVisitMappingUpdateManyMutationInput;
}

export interface PreVisitMappingUpdateManyWithoutCreatedByNestedInput {
  create?: PreVisitMappingCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: PreVisitMappingCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: PreVisitMappingUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: PreVisitMappingCreateManyCreatedByInputEnvelope | null;
  set?: PreVisitMappingWhereUniqueInput[] | null;
  disconnect?: PreVisitMappingWhereUniqueInput[] | null;
  delete?: PreVisitMappingWhereUniqueInput[] | null;
  connect?: PreVisitMappingWhereUniqueInput[] | null;
  update?: PreVisitMappingUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: PreVisitMappingUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: PreVisitMappingScalarWhereInput[] | null;
}

export interface PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput {
  create?: PreVisitMappingCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: PreVisitMappingCreateOrConnectWithoutLastUpdatedByInput[] | null;
  upsert?: PreVisitMappingUpsertWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  createMany?: PreVisitMappingCreateManyLastUpdatedByInputEnvelope | null;
  set?: PreVisitMappingWhereUniqueInput[] | null;
  disconnect?: PreVisitMappingWhereUniqueInput[] | null;
  delete?: PreVisitMappingWhereUniqueInput[] | null;
  connect?: PreVisitMappingWhereUniqueInput[] | null;
  update?: PreVisitMappingUpdateWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  updateMany?: PreVisitMappingUpdateManyWithWhereWithoutLastUpdatedByInput[] | null;
  deleteMany?: PreVisitMappingScalarWhereInput[] | null;
}

export interface PreVisitMappingUpdateManyWithoutLocationNestedInput {
  create?: PreVisitMappingCreateWithoutLocationInput[] | null;
  connectOrCreate?: PreVisitMappingCreateOrConnectWithoutLocationInput[] | null;
  upsert?: PreVisitMappingUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: PreVisitMappingCreateManyLocationInputEnvelope | null;
  set?: PreVisitMappingWhereUniqueInput[] | null;
  disconnect?: PreVisitMappingWhereUniqueInput[] | null;
  delete?: PreVisitMappingWhereUniqueInput[] | null;
  connect?: PreVisitMappingWhereUniqueInput[] | null;
  update?: PreVisitMappingUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: PreVisitMappingUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: PreVisitMappingScalarWhereInput[] | null;
}

export interface PreVisitMappingUpdateWithWhereUniqueWithoutCreatedByInput {
  where: PreVisitMappingWhereUniqueInput;
  data: PreVisitMappingUpdateWithoutCreatedByInput;
}

export interface PreVisitMappingUpdateWithWhereUniqueWithoutLastUpdatedByInput {
  where: PreVisitMappingWhereUniqueInput;
  data: PreVisitMappingUpdateWithoutLastUpdatedByInput;
}

export interface PreVisitMappingUpdateWithWhereUniqueWithoutLocationInput {
  where: PreVisitMappingWhereUniqueInput;
  data: PreVisitMappingUpdateWithoutLocationInput;
}

export interface PreVisitMappingUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedPreVisitMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPreVisitMappingsNestedInput | null;
  preVisitMappingConditions?: PreVisitMappingConditionUpdateManyWithoutPreVisitMappingNestedInput | null;
}

export interface PreVisitMappingUpdateWithoutLastUpdatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedPreVisitMappingsNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutPreVisitMappingsNestedInput | null;
  preVisitMappingConditions?: PreVisitMappingConditionUpdateManyWithoutPreVisitMappingNestedInput | null;
}

export interface PreVisitMappingUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  priority?: FloatFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedPreVisitMappingsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutUpdatedPreVisitMappingsNestedInput | null;
  preVisitMappingConditions?: PreVisitMappingConditionUpdateManyWithoutPreVisitMappingNestedInput | null;
}

export interface PreVisitMappingUpsertWithWhereUniqueWithoutCreatedByInput {
  where: PreVisitMappingWhereUniqueInput;
  update: PreVisitMappingUpdateWithoutCreatedByInput;
  create: PreVisitMappingCreateWithoutCreatedByInput;
}

export interface PreVisitMappingUpsertWithWhereUniqueWithoutLastUpdatedByInput {
  where: PreVisitMappingWhereUniqueInput;
  update: PreVisitMappingUpdateWithoutLastUpdatedByInput;
  create: PreVisitMappingCreateWithoutLastUpdatedByInput;
}

export interface PreVisitMappingUpsertWithWhereUniqueWithoutLocationInput {
  where: PreVisitMappingWhereUniqueInput;
  update: PreVisitMappingUpdateWithoutLocationInput;
  create: PreVisitMappingCreateWithoutLocationInput;
}

export interface PreVisitMappingWhereInput {
  AND?: PreVisitMappingWhereInput[] | null;
  OR?: PreVisitMappingWhereInput[] | null;
  NOT?: PreVisitMappingWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  priority?: FloatFilter | null;
  locationId?: UuidFilter | null;
  name?: StringFilter | null;
  notes?: StringNullableFilter | null;
  createdBy?: UserRelationFilter | null;
  lastUpdatedBy?: UserRelationFilter | null;
  location?: LocationRelationFilter | null;
  preVisitMappingConditions?: PreVisitMappingConditionListRelationFilter | null;
}

export interface PreVisitMappingWhereUniqueInput {
  id?: string | null;
}

export interface ProviderCreateManyBillingGroupInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  organizationId: string;
  primaryLocationId: string;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  providerTaxonomyCodeId?: string | null;
}

export interface ProviderCreateManyBillingGroupInputEnvelope {
  data: ProviderCreateManyBillingGroupInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  primaryLocationId: string;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  billingGroupId?: string | null;
  providerTaxonomyCodeId?: string | null;
}

export interface ProviderCreateManyOrganizationInputEnvelope {
  data: ProviderCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderCreateManyPrimaryLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  organizationId: string;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  billingGroupId?: string | null;
  providerTaxonomyCodeId?: string | null;
}

export interface ProviderCreateManyPrimaryLocationInputEnvelope {
  data: ProviderCreateManyPrimaryLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderCreateManyProviderTaxonomyCodeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  organizationId: string;
  primaryLocationId: string;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  billingGroupId?: string | null;
}

export interface ProviderCreateManyProviderTaxonomyCodeInputEnvelope {
  data: ProviderCreateManyProviderTaxonomyCodeInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderCreateNestedManyWithoutAppointmentsInput {
  create?: ProviderCreateWithoutAppointmentsInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutAppointmentsInput[] | null;
  connect?: ProviderWhereUniqueInput[] | null;
}

export interface ProviderCreateNestedManyWithoutBillingGroupInput {
  create?: ProviderCreateWithoutBillingGroupInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutBillingGroupInput[] | null;
  createMany?: ProviderCreateManyBillingGroupInputEnvelope | null;
  connect?: ProviderWhereUniqueInput[] | null;
}

export interface ProviderCreateNestedManyWithoutOrganizationInput {
  create?: ProviderCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: ProviderCreateManyOrganizationInputEnvelope | null;
  connect?: ProviderWhereUniqueInput[] | null;
}

export interface ProviderCreateNestedManyWithoutPrimaryLocationInput {
  create?: ProviderCreateWithoutPrimaryLocationInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutPrimaryLocationInput[] | null;
  createMany?: ProviderCreateManyPrimaryLocationInputEnvelope | null;
  connect?: ProviderWhereUniqueInput[] | null;
}

export interface ProviderCreateNestedManyWithoutProviderTaxonomyCodeInput {
  create?: ProviderCreateWithoutProviderTaxonomyCodeInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutProviderTaxonomyCodeInput[] | null;
  createMany?: ProviderCreateManyProviderTaxonomyCodeInputEnvelope | null;
  connect?: ProviderWhereUniqueInput[] | null;
}

export interface ProviderCreateNestedManyWithoutRenderedBillsInput {
  create?: ProviderCreateWithoutRenderedBillsInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutRenderedBillsInput[] | null;
  connect?: ProviderWhereUniqueInput[] | null;
}

export interface ProviderCreateNestedOneWithoutBilledBillsInput {
  create?: ProviderCreateWithoutBilledBillsInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutBilledBillsInput | null;
  connect?: ProviderWhereUniqueInput | null;
}

export interface ProviderCreateNestedOneWithoutBilledChargesInput {
  create?: ProviderCreateWithoutBilledChargesInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutBilledChargesInput | null;
  connect?: ProviderWhereUniqueInput | null;
}

export interface ProviderCreateNestedOneWithoutDefaultEligibilityProviderLocationsInput {
  create?: ProviderCreateWithoutDefaultEligibilityProviderLocationsInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutDefaultEligibilityProviderLocationsInput | null;
  connect?: ProviderWhereUniqueInput | null;
}

export interface ProviderCreateNestedOneWithoutEligibilityRequestsInput {
  create?: ProviderCreateWithoutEligibilityRequestsInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutEligibilityRequestsInput | null;
  connect?: ProviderWhereUniqueInput | null;
}

export interface ProviderCreateNestedOneWithoutIntegrationLinksInput {
  create?: ProviderCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: ProviderWhereUniqueInput | null;
}

export interface ProviderCreateNestedOneWithoutProviderNetworkParticipationsInput {
  create?: ProviderCreateWithoutProviderNetworkParticipationsInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutProviderNetworkParticipationsInput | null;
  connect?: ProviderWhereUniqueInput | null;
}

export interface ProviderCreateNestedOneWithoutRenderedChargesInput {
  create?: ProviderCreateWithoutRenderedChargesInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutRenderedChargesInput | null;
  connect?: ProviderWhereUniqueInput | null;
}

export interface ProviderCreateNestedOneWithoutTempAppointmentsInput {
  create?: ProviderCreateWithoutTempAppointmentsInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutTempAppointmentsInput | null;
  connect?: ProviderWhereUniqueInput | null;
}

export interface ProviderCreateOrConnectWithoutAppointmentsInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutAppointmentsInput;
}

export interface ProviderCreateOrConnectWithoutBilledBillsInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutBilledBillsInput;
}

export interface ProviderCreateOrConnectWithoutBilledChargesInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutBilledChargesInput;
}

export interface ProviderCreateOrConnectWithoutBillingGroupInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutBillingGroupInput;
}

export interface ProviderCreateOrConnectWithoutDefaultEligibilityProviderLocationsInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutDefaultEligibilityProviderLocationsInput;
}

export interface ProviderCreateOrConnectWithoutEligibilityRequestsInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutEligibilityRequestsInput;
}

export interface ProviderCreateOrConnectWithoutIntegrationLinksInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutIntegrationLinksInput;
}

export interface ProviderCreateOrConnectWithoutOrganizationInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutOrganizationInput;
}

export interface ProviderCreateOrConnectWithoutPrimaryLocationInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutPrimaryLocationInput;
}

export interface ProviderCreateOrConnectWithoutProviderNetworkParticipationsInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutProviderNetworkParticipationsInput;
}

export interface ProviderCreateOrConnectWithoutProviderTaxonomyCodeInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutProviderTaxonomyCodeInput;
}

export interface ProviderCreateOrConnectWithoutRenderedBillsInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutRenderedBillsInput;
}

export interface ProviderCreateOrConnectWithoutRenderedChargesInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutRenderedChargesInput;
}

export interface ProviderCreateOrConnectWithoutTempAppointmentsInput {
  where: ProviderWhereUniqueInput;
  create: ProviderCreateWithoutTempAppointmentsInput;
}

export interface ProviderCreateWithoutAppointmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutBilledBillsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutBilledChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutBillingGroupInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutDefaultEligibilityProviderLocationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutEligibilityRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutPrimaryLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutProviderNetworkParticipationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
}

export interface ProviderCreateWithoutProviderTaxonomyCodeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutRenderedBillsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutRenderedChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  tempAppointments?: AppointmentCreateNestedManyWithoutProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderCreateWithoutTempAppointmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  hidden?: boolean | null;
  type?: ProviderType | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  credentials?: string | null;
  npi?: string | null;
  organization: OrganizationCreateNestedOneWithoutProvidersInput;
  primaryLocation: LocationCreateNestedOneWithoutProvidersInput;
  billingGroup?: BillingGroupCreateNestedOneWithoutProvidersInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput | null;
  appointments?: AppointmentCreateNestedManyWithoutProvidersInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutProviderInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutProviderInput | null;
  billedBills?: BillCreateNestedManyWithoutBillingProviderInput | null;
  renderedBills?: BillCreateNestedManyWithoutRenderingProvidersInput | null;
  billedCharges?: ChargeCreateNestedManyWithoutBillingProviderInput | null;
  renderedCharges?: ChargeCreateNestedManyWithoutRenderingProviderInput | null;
  defaultEligibilityProviderLocations?: LocationCreateNestedManyWithoutDefaultEligibilityProviderInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationCreateNestedManyWithoutProviderInput | null;
}

export interface ProviderListRelationFilter {
  every?: ProviderWhereInput | null;
  some?: ProviderWhereInput | null;
  none?: ProviderWhereInput | null;
}

export interface ProviderNetworkParticipationCreateManyPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  inNetwork: boolean;
  providerId: string;
}

export interface ProviderNetworkParticipationCreateManyPayerInputEnvelope {
  data: ProviderNetworkParticipationCreateManyPayerInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderNetworkParticipationCreateManyProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  inNetwork: boolean;
  payerId: string;
}

export interface ProviderNetworkParticipationCreateManyProviderInputEnvelope {
  data: ProviderNetworkParticipationCreateManyProviderInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderNetworkParticipationCreateNestedManyWithoutPayerInput {
  create?: ProviderNetworkParticipationCreateWithoutPayerInput[] | null;
  connectOrCreate?: ProviderNetworkParticipationCreateOrConnectWithoutPayerInput[] | null;
  createMany?: ProviderNetworkParticipationCreateManyPayerInputEnvelope | null;
  connect?: ProviderNetworkParticipationWhereUniqueInput[] | null;
}

export interface ProviderNetworkParticipationCreateNestedManyWithoutProviderInput {
  create?: ProviderNetworkParticipationCreateWithoutProviderInput[] | null;
  connectOrCreate?: ProviderNetworkParticipationCreateOrConnectWithoutProviderInput[] | null;
  createMany?: ProviderNetworkParticipationCreateManyProviderInputEnvelope | null;
  connect?: ProviderNetworkParticipationWhereUniqueInput[] | null;
}

export interface ProviderNetworkParticipationCreateOrConnectWithoutPayerInput {
  where: ProviderNetworkParticipationWhereUniqueInput;
  create: ProviderNetworkParticipationCreateWithoutPayerInput;
}

export interface ProviderNetworkParticipationCreateOrConnectWithoutProviderInput {
  where: ProviderNetworkParticipationWhereUniqueInput;
  create: ProviderNetworkParticipationCreateWithoutProviderInput;
}

export interface ProviderNetworkParticipationCreateWithoutPayerInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  inNetwork: boolean;
  provider: ProviderCreateNestedOneWithoutProviderNetworkParticipationsInput;
}

export interface ProviderNetworkParticipationCreateWithoutProviderInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  inNetwork: boolean;
  payer: PayerCreateNestedOneWithoutProviderNetworkParticipationsInput;
}

export interface ProviderNetworkParticipationListRelationFilter {
  every?: ProviderNetworkParticipationWhereInput | null;
  some?: ProviderNetworkParticipationWhereInput | null;
  none?: ProviderNetworkParticipationWhereInput | null;
}

export interface ProviderNetworkParticipationOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ProviderNetworkParticipationProviderIdPayerIdCompoundUniqueInput {
  providerId: string;
  payerId: string;
}

export interface ProviderNetworkParticipationScalarWhereInput {
  AND?: ProviderNetworkParticipationScalarWhereInput[] | null;
  OR?: ProviderNetworkParticipationScalarWhereInput[] | null;
  NOT?: ProviderNetworkParticipationScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  inNetwork?: BoolFilter | null;
  payerId?: UuidFilter | null;
  providerId?: UuidFilter | null;
}

export interface ProviderNetworkParticipationUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
}

export interface ProviderNetworkParticipationUpdateManyWithWhereWithoutPayerInput {
  where: ProviderNetworkParticipationScalarWhereInput;
  data: ProviderNetworkParticipationUpdateManyMutationInput;
}

export interface ProviderNetworkParticipationUpdateManyWithWhereWithoutProviderInput {
  where: ProviderNetworkParticipationScalarWhereInput;
  data: ProviderNetworkParticipationUpdateManyMutationInput;
}

export interface ProviderNetworkParticipationUpdateManyWithoutPayerNestedInput {
  create?: ProviderNetworkParticipationCreateWithoutPayerInput[] | null;
  connectOrCreate?: ProviderNetworkParticipationCreateOrConnectWithoutPayerInput[] | null;
  upsert?: ProviderNetworkParticipationUpsertWithWhereUniqueWithoutPayerInput[] | null;
  createMany?: ProviderNetworkParticipationCreateManyPayerInputEnvelope | null;
  set?: ProviderNetworkParticipationWhereUniqueInput[] | null;
  disconnect?: ProviderNetworkParticipationWhereUniqueInput[] | null;
  delete?: ProviderNetworkParticipationWhereUniqueInput[] | null;
  connect?: ProviderNetworkParticipationWhereUniqueInput[] | null;
  update?: ProviderNetworkParticipationUpdateWithWhereUniqueWithoutPayerInput[] | null;
  updateMany?: ProviderNetworkParticipationUpdateManyWithWhereWithoutPayerInput[] | null;
  deleteMany?: ProviderNetworkParticipationScalarWhereInput[] | null;
}

export interface ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput {
  create?: ProviderNetworkParticipationCreateWithoutProviderInput[] | null;
  connectOrCreate?: ProviderNetworkParticipationCreateOrConnectWithoutProviderInput[] | null;
  upsert?: ProviderNetworkParticipationUpsertWithWhereUniqueWithoutProviderInput[] | null;
  createMany?: ProviderNetworkParticipationCreateManyProviderInputEnvelope | null;
  set?: ProviderNetworkParticipationWhereUniqueInput[] | null;
  disconnect?: ProviderNetworkParticipationWhereUniqueInput[] | null;
  delete?: ProviderNetworkParticipationWhereUniqueInput[] | null;
  connect?: ProviderNetworkParticipationWhereUniqueInput[] | null;
  update?: ProviderNetworkParticipationUpdateWithWhereUniqueWithoutProviderInput[] | null;
  updateMany?: ProviderNetworkParticipationUpdateManyWithWhereWithoutProviderInput[] | null;
  deleteMany?: ProviderNetworkParticipationScalarWhereInput[] | null;
}

export interface ProviderNetworkParticipationUpdateWithWhereUniqueWithoutPayerInput {
  where: ProviderNetworkParticipationWhereUniqueInput;
  data: ProviderNetworkParticipationUpdateWithoutPayerInput;
}

export interface ProviderNetworkParticipationUpdateWithWhereUniqueWithoutProviderInput {
  where: ProviderNetworkParticipationWhereUniqueInput;
  data: ProviderNetworkParticipationUpdateWithoutProviderInput;
}

export interface ProviderNetworkParticipationUpdateWithoutPayerInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  provider?: ProviderUpdateOneRequiredWithoutProviderNetworkParticipationsNestedInput | null;
}

export interface ProviderNetworkParticipationUpdateWithoutProviderInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  inNetwork?: BoolFieldUpdateOperationsInput | null;
  payer?: PayerUpdateOneRequiredWithoutProviderNetworkParticipationsNestedInput | null;
}

export interface ProviderNetworkParticipationUpsertWithWhereUniqueWithoutPayerInput {
  where: ProviderNetworkParticipationWhereUniqueInput;
  update: ProviderNetworkParticipationUpdateWithoutPayerInput;
  create: ProviderNetworkParticipationCreateWithoutPayerInput;
}

export interface ProviderNetworkParticipationUpsertWithWhereUniqueWithoutProviderInput {
  where: ProviderNetworkParticipationWhereUniqueInput;
  update: ProviderNetworkParticipationUpdateWithoutProviderInput;
  create: ProviderNetworkParticipationCreateWithoutProviderInput;
}

export interface ProviderNetworkParticipationWhereInput {
  AND?: ProviderNetworkParticipationWhereInput[] | null;
  OR?: ProviderNetworkParticipationWhereInput[] | null;
  NOT?: ProviderNetworkParticipationWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  inNetwork?: BoolFilter | null;
  payerId?: UuidFilter | null;
  providerId?: UuidFilter | null;
  payer?: PayerRelationFilter | null;
  provider?: ProviderRelationFilter | null;
}

export interface ProviderNetworkParticipationWhereUniqueInput {
  id?: string | null;
  providerId_payerId?: ProviderNetworkParticipationProviderIdPayerIdCompoundUniqueInput | null;
}

export interface ProviderOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ProviderOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  deletedAt?: SortOrderInput | null;
  hidden?: SortOrder | null;
  type?: SortOrder | null;
  organizationId?: SortOrder | null;
  primaryLocationId?: SortOrder | null;
  firstName?: SortOrderInput | null;
  lastName?: SortOrderInput | null;
  organizationName?: SortOrderInput | null;
  credentials?: SortOrderInput | null;
  npi?: SortOrderInput | null;
  billingGroupId?: SortOrderInput | null;
  providerTaxonomyCodeId?: SortOrderInput | null;
  organization?: OrganizationOrderByWithRelationInput | null;
  primaryLocation?: LocationOrderByWithRelationInput | null;
  billingGroup?: BillingGroupOrderByWithRelationInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeOrderByWithRelationInput | null;
  appointments?: AppointmentOrderByRelationAggregateInput | null;
  eligibilityRequests?: EligibilityRequestOrderByRelationAggregateInput | null;
  integrationLinks?: IntegrationLinkOrderByRelationAggregateInput | null;
  billedBills?: BillOrderByRelationAggregateInput | null;
  renderedBills?: BillOrderByRelationAggregateInput | null;
  billedCharges?: ChargeOrderByRelationAggregateInput | null;
  renderedCharges?: ChargeOrderByRelationAggregateInput | null;
  tempAppointments?: AppointmentOrderByRelationAggregateInput | null;
  defaultEligibilityProviderLocations?: LocationOrderByRelationAggregateInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationOrderByRelationAggregateInput | null;
}

export interface ProviderRelationFilter {
  is?: ProviderWhereInput | null;
  isNot?: ProviderWhereInput | null;
}

export interface ProviderScalarWhereInput {
  AND?: ProviderScalarWhereInput[] | null;
  OR?: ProviderScalarWhereInput[] | null;
  NOT?: ProviderScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  deletedAt?: DateTimeNullableFilter | null;
  hidden?: BoolFilter | null;
  type?: EnumProviderTypeFilter | null;
  organizationId?: UuidFilter | null;
  primaryLocationId?: UuidFilter | null;
  firstName?: StringNullableFilter | null;
  lastName?: StringNullableFilter | null;
  organizationName?: StringNullableFilter | null;
  credentials?: StringNullableFilter | null;
  npi?: StringNullableFilter | null;
  billingGroupId?: UuidNullableFilter | null;
  providerTaxonomyCodeId?: UuidNullableFilter | null;
}

export interface ProviderServiceConfigurationCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  providerTaxonomyClassificationId?: string | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
}

export interface ProviderServiceConfigurationCreateManyOrganizationInputEnvelope {
  data: ProviderServiceConfigurationCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderServiceConfigurationCreateManyProviderTaxonomyClassificationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  organizationId: string;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
}

export interface ProviderServiceConfigurationCreateManyProviderTaxonomyClassificationInputEnvelope {
  data: ProviderServiceConfigurationCreateManyProviderTaxonomyClassificationInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderServiceConfigurationCreateNestedManyWithoutOrganizationInput {
  create?: ProviderServiceConfigurationCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: ProviderServiceConfigurationCreateManyOrganizationInputEnvelope | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput[] | null;
}

export interface ProviderServiceConfigurationCreateNestedManyWithoutProviderTaxonomyClassificationInput {
  create?: ProviderServiceConfigurationCreateWithoutProviderTaxonomyClassificationInput[] | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutProviderTaxonomyClassificationInput[] | null;
  createMany?: ProviderServiceConfigurationCreateManyProviderTaxonomyClassificationInputEnvelope | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput[] | null;
}

export interface ProviderServiceConfigurationCreateNestedOneWithoutBenefitAccumulatorAdjustmentsInput {
  create?: ProviderServiceConfigurationCreateWithoutBenefitAccumulatorAdjustmentsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutBenefitAccumulatorAdjustmentsInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationCreateNestedOneWithoutBenefitMappingFallbacksInput {
  create?: ProviderServiceConfigurationCreateWithoutBenefitMappingFallbacksInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutBenefitMappingFallbacksInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationCreateNestedOneWithoutBenefitMappingsInput {
  create?: ProviderServiceConfigurationCreateWithoutBenefitMappingsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutBenefitMappingsInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationCreateNestedOneWithoutBenefitNoteRulesInput {
  create?: ProviderServiceConfigurationCreateWithoutBenefitNoteRulesInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutBenefitNoteRulesInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationCreateNestedOneWithoutChargeTemplateMappingConditionsInput {
  create?: ProviderServiceConfigurationCreateWithoutChargeTemplateMappingConditionsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutChargeTemplateMappingConditionsInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationCreateNestedOneWithoutCoverageBenefitsInput {
  create?: ProviderServiceConfigurationCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutCoverageBenefitsInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationCreateNestedOneWithoutDepositMappingConditionsInput {
  create?: ProviderServiceConfigurationCreateWithoutDepositMappingConditionsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutDepositMappingConditionsInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationCreateNestedOneWithoutDepositMappingsInput {
  create?: ProviderServiceConfigurationCreateWithoutDepositMappingsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutDepositMappingsInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationCreateNestedOneWithoutEligibilityCoverageBenefitSnapshotsInput {
  create?: ProviderServiceConfigurationCreateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationCreateNestedOneWithoutPlanBenefitTemplatesInput {
  create?: ProviderServiceConfigurationCreateWithoutPlanBenefitTemplatesInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutPlanBenefitTemplatesInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationCreateNestedOneWithoutProviderServiceConfigurationOverridesInput {
  create?: ProviderServiceConfigurationCreateWithoutProviderServiceConfigurationOverridesInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutProviderServiceConfigurationOverridesInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutBenefitAccumulatorAdjustmentsInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutBenefitAccumulatorAdjustmentsInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutBenefitMappingFallbacksInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutBenefitMappingFallbacksInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutBenefitMappingsInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutBenefitMappingsInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutBenefitNoteRulesInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutBenefitNoteRulesInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutChargeTemplateMappingConditionsInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutChargeTemplateMappingConditionsInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutCoverageBenefitsInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutCoverageBenefitsInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutDepositMappingConditionsInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutDepositMappingConditionsInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutDepositMappingsInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutDepositMappingsInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutEligibilityCoverageBenefitSnapshotsInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutOrganizationInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutOrganizationInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutPlanBenefitTemplatesInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutPlanBenefitTemplatesInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutProviderServiceConfigurationOverridesInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutProviderServiceConfigurationOverridesInput;
}

export interface ProviderServiceConfigurationCreateOrConnectWithoutProviderTaxonomyClassificationInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  create: ProviderServiceConfigurationCreateWithoutProviderTaxonomyClassificationInput;
}

export interface ProviderServiceConfigurationCreateWithoutBenefitAccumulatorAdjustmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutBenefitMappingFallbacksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutBenefitMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutBenefitNoteRulesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutChargeTemplateMappingConditionsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutDepositMappingConditionsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutDepositMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutEligibilityCoverageBenefitSnapshotsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutPlanBenefitTemplatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutProviderServiceConfigurationOverridesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreateWithoutProviderTaxonomyClassificationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  archivedAt?: any | null;
  name: string;
  shortName?: string | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationCreatefallbackServiceTypesInput | null;
  showInPdf?: boolean | null;
  priorityOrder?: number | null;
  virtual?: boolean | null;
  placeOfService?: string | null;
  specialist?: boolean | null;
  claimType?: string | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationInput;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappings?: BenefitMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappings?: DepositMappingCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  depositMappingConditions?: DepositMappingConditionCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationInput | null;
  planBenefitTemplates?: PlanBenefitTemplateCreateNestedManyWithoutProviderServiceConfigurationInput | null;
}

export interface ProviderServiceConfigurationCreatefallbackServiceTypesInput {
  set: string[];
}

export interface ProviderServiceConfigurationListRelationFilter {
  every?: ProviderServiceConfigurationWhereInput | null;
  some?: ProviderServiceConfigurationWhereInput | null;
  none?: ProviderServiceConfigurationWhereInput | null;
}

export interface ProviderServiceConfigurationOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ProviderServiceConfigurationOverrideCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  providerServiceConfigurationId: string;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationOverrideCreatefallbackServiceTypesInput | null;
}

export interface ProviderServiceConfigurationOverrideCreateManyOrganizationInputEnvelope {
  data: ProviderServiceConfigurationOverrideCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderServiceConfigurationOverrideCreateManyProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationOverrideCreatefallbackServiceTypesInput | null;
}

export interface ProviderServiceConfigurationOverrideCreateManyProviderServiceConfigurationInputEnvelope {
  data: ProviderServiceConfigurationOverrideCreateManyProviderServiceConfigurationInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderServiceConfigurationOverrideCreateNestedManyWithoutOrganizationInput {
  create?: ProviderServiceConfigurationOverrideCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: ProviderServiceConfigurationOverrideCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: ProviderServiceConfigurationOverrideCreateManyOrganizationInputEnvelope | null;
  connect?: ProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
}

export interface ProviderServiceConfigurationOverrideCreateNestedManyWithoutProviderServiceConfigurationInput {
  create?: ProviderServiceConfigurationOverrideCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: ProviderServiceConfigurationOverrideCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  createMany?: ProviderServiceConfigurationOverrideCreateManyProviderServiceConfigurationInputEnvelope | null;
  connect?: ProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
}

export interface ProviderServiceConfigurationOverrideCreateNestedOneWithoutBenefitNoteRulesInput {
  create?: ProviderServiceConfigurationOverrideCreateWithoutBenefitNoteRulesInput | null;
  connectOrCreate?: ProviderServiceConfigurationOverrideCreateOrConnectWithoutBenefitNoteRulesInput | null;
  connect?: ProviderServiceConfigurationOverrideWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationOverrideCreateNestedOneWithoutPayerProviderServiceConfigurationOverridesInput {
  create?: ProviderServiceConfigurationOverrideCreateWithoutPayerProviderServiceConfigurationOverridesInput | null;
  connectOrCreate?: ProviderServiceConfigurationOverrideCreateOrConnectWithoutPayerProviderServiceConfigurationOverridesInput | null;
  connect?: ProviderServiceConfigurationOverrideWhereUniqueInput | null;
}

export interface ProviderServiceConfigurationOverrideCreateOrConnectWithoutBenefitNoteRulesInput {
  where: ProviderServiceConfigurationOverrideWhereUniqueInput;
  create: ProviderServiceConfigurationOverrideCreateWithoutBenefitNoteRulesInput;
}

export interface ProviderServiceConfigurationOverrideCreateOrConnectWithoutOrganizationInput {
  where: ProviderServiceConfigurationOverrideWhereUniqueInput;
  create: ProviderServiceConfigurationOverrideCreateWithoutOrganizationInput;
}

export interface ProviderServiceConfigurationOverrideCreateOrConnectWithoutPayerProviderServiceConfigurationOverridesInput {
  where: ProviderServiceConfigurationOverrideWhereUniqueInput;
  create: ProviderServiceConfigurationOverrideCreateWithoutPayerProviderServiceConfigurationOverridesInput;
}

export interface ProviderServiceConfigurationOverrideCreateOrConnectWithoutProviderServiceConfigurationInput {
  where: ProviderServiceConfigurationOverrideWhereUniqueInput;
  create: ProviderServiceConfigurationOverrideCreateWithoutProviderServiceConfigurationInput;
}

export interface ProviderServiceConfigurationOverrideCreateWithoutBenefitNoteRulesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationOverrideCreatefallbackServiceTypesInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutProviderServiceConfigurationOverridesInput;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationOverridesInput;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerProviderServiceConfigurationOverrideInput | null;
}

export interface ProviderServiceConfigurationOverrideCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationOverrideCreatefallbackServiceTypesInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutProviderServiceConfigurationOverridesInput;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationOverrideInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerProviderServiceConfigurationOverrideInput | null;
}

export interface ProviderServiceConfigurationOverrideCreateWithoutPayerProviderServiceConfigurationOverridesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationOverrideCreatefallbackServiceTypesInput | null;
  providerServiceConfiguration: ProviderServiceConfigurationCreateNestedOneWithoutProviderServiceConfigurationOverridesInput;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationOverridesInput;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationOverrideInput | null;
}

export interface ProviderServiceConfigurationOverrideCreateWithoutProviderServiceConfigurationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  serviceType: string;
  fallbackServiceTypes?: ProviderServiceConfigurationOverrideCreatefallbackServiceTypesInput | null;
  organization: OrganizationCreateNestedOneWithoutProviderServiceConfigurationOverridesInput;
  benefitNoteRules?: BenefitNoteRuleCreateNestedManyWithoutProviderServiceConfigurationOverrideInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideCreateNestedManyWithoutPayerProviderServiceConfigurationOverrideInput | null;
}

export interface ProviderServiceConfigurationOverrideCreatefallbackServiceTypesInput {
  set: string[];
}

export interface ProviderServiceConfigurationOverrideListRelationFilter {
  every?: ProviderServiceConfigurationOverrideWhereInput | null;
  some?: ProviderServiceConfigurationOverrideWhereInput | null;
  none?: ProviderServiceConfigurationOverrideWhereInput | null;
}

export interface ProviderServiceConfigurationOverrideOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ProviderServiceConfigurationOverrideRelationFilter {
  is?: ProviderServiceConfigurationOverrideWhereInput | null;
  isNot?: ProviderServiceConfigurationOverrideWhereInput | null;
}

export interface ProviderServiceConfigurationOverrideScalarWhereInput {
  AND?: ProviderServiceConfigurationOverrideScalarWhereInput[] | null;
  OR?: ProviderServiceConfigurationOverrideScalarWhereInput[] | null;
  NOT?: ProviderServiceConfigurationOverrideScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  providerServiceConfigurationId?: UuidFilter | null;
  organizationId?: UuidFilter | null;
  serviceType?: StringFilter | null;
  fallbackServiceTypes?: StringNullableListFilter | null;
}

export interface ProviderServiceConfigurationOverrideUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationOverrideUpdatefallbackServiceTypesInput | null;
}

export interface ProviderServiceConfigurationOverrideUpdateManyWithWhereWithoutOrganizationInput {
  where: ProviderServiceConfigurationOverrideScalarWhereInput;
  data: ProviderServiceConfigurationOverrideUpdateManyMutationInput;
}

export interface ProviderServiceConfigurationOverrideUpdateManyWithWhereWithoutProviderServiceConfigurationInput {
  where: ProviderServiceConfigurationOverrideScalarWhereInput;
  data: ProviderServiceConfigurationOverrideUpdateManyMutationInput;
}

export interface ProviderServiceConfigurationOverrideUpdateManyWithoutOrganizationNestedInput {
  create?: ProviderServiceConfigurationOverrideCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: ProviderServiceConfigurationOverrideCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: ProviderServiceConfigurationOverrideUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: ProviderServiceConfigurationOverrideCreateManyOrganizationInputEnvelope | null;
  set?: ProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  disconnect?: ProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  delete?: ProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  connect?: ProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  update?: ProviderServiceConfigurationOverrideUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: ProviderServiceConfigurationOverrideUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: ProviderServiceConfigurationOverrideScalarWhereInput[] | null;
}

export interface ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput {
  create?: ProviderServiceConfigurationOverrideCreateWithoutProviderServiceConfigurationInput[] | null;
  connectOrCreate?: ProviderServiceConfigurationOverrideCreateOrConnectWithoutProviderServiceConfigurationInput[] | null;
  upsert?: ProviderServiceConfigurationOverrideUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  createMany?: ProviderServiceConfigurationOverrideCreateManyProviderServiceConfigurationInputEnvelope | null;
  set?: ProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  disconnect?: ProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  delete?: ProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  connect?: ProviderServiceConfigurationOverrideWhereUniqueInput[] | null;
  update?: ProviderServiceConfigurationOverrideUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput[] | null;
  updateMany?: ProviderServiceConfigurationOverrideUpdateManyWithWhereWithoutProviderServiceConfigurationInput[] | null;
  deleteMany?: ProviderServiceConfigurationOverrideScalarWhereInput[] | null;
}

export interface ProviderServiceConfigurationOverrideUpdateOneRequiredWithoutPayerProviderServiceConfigurationOverridesNestedInput {
  create?: ProviderServiceConfigurationOverrideCreateWithoutPayerProviderServiceConfigurationOverridesInput | null;
  connectOrCreate?: ProviderServiceConfigurationOverrideCreateOrConnectWithoutPayerProviderServiceConfigurationOverridesInput | null;
  upsert?: ProviderServiceConfigurationOverrideUpsertWithoutPayerProviderServiceConfigurationOverridesInput | null;
  connect?: ProviderServiceConfigurationOverrideWhereUniqueInput | null;
  update?: ProviderServiceConfigurationOverrideUpdateWithoutPayerProviderServiceConfigurationOverridesInput | null;
}

export interface ProviderServiceConfigurationOverrideUpdateOneWithoutBenefitNoteRulesNestedInput {
  create?: ProviderServiceConfigurationOverrideCreateWithoutBenefitNoteRulesInput | null;
  connectOrCreate?: ProviderServiceConfigurationOverrideCreateOrConnectWithoutBenefitNoteRulesInput | null;
  upsert?: ProviderServiceConfigurationOverrideUpsertWithoutBenefitNoteRulesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderServiceConfigurationOverrideWhereUniqueInput | null;
  update?: ProviderServiceConfigurationOverrideUpdateWithoutBenefitNoteRulesInput | null;
}

export interface ProviderServiceConfigurationOverrideUpdateWithWhereUniqueWithoutOrganizationInput {
  where: ProviderServiceConfigurationOverrideWhereUniqueInput;
  data: ProviderServiceConfigurationOverrideUpdateWithoutOrganizationInput;
}

export interface ProviderServiceConfigurationOverrideUpdateWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: ProviderServiceConfigurationOverrideWhereUniqueInput;
  data: ProviderServiceConfigurationOverrideUpdateWithoutProviderServiceConfigurationInput;
}

export interface ProviderServiceConfigurationOverrideUpdateWithoutBenefitNoteRulesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationOverrideUpdatefallbackServiceTypesInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutProviderServiceConfigurationOverridesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationOverridesNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerProviderServiceConfigurationOverrideNestedInput | null;
}

export interface ProviderServiceConfigurationOverrideUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationOverrideUpdatefallbackServiceTypesInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutProviderServiceConfigurationOverridesNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationOverrideNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerProviderServiceConfigurationOverrideNestedInput | null;
}

export interface ProviderServiceConfigurationOverrideUpdateWithoutPayerProviderServiceConfigurationOverridesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationOverrideUpdatefallbackServiceTypesInput | null;
  providerServiceConfiguration?: ProviderServiceConfigurationUpdateOneRequiredWithoutProviderServiceConfigurationOverridesNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationOverridesNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationOverrideNestedInput | null;
}

export interface ProviderServiceConfigurationOverrideUpdateWithoutProviderServiceConfigurationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationOverrideUpdatefallbackServiceTypesInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationOverridesNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationOverrideNestedInput | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideUpdateManyWithoutPayerProviderServiceConfigurationOverrideNestedInput | null;
}

export interface ProviderServiceConfigurationOverrideUpdatefallbackServiceTypesInput {
  set?: string[] | null;
  push?: string[] | null;
}

export interface ProviderServiceConfigurationOverrideUpsertWithWhereUniqueWithoutOrganizationInput {
  where: ProviderServiceConfigurationOverrideWhereUniqueInput;
  update: ProviderServiceConfigurationOverrideUpdateWithoutOrganizationInput;
  create: ProviderServiceConfigurationOverrideCreateWithoutOrganizationInput;
}

export interface ProviderServiceConfigurationOverrideUpsertWithWhereUniqueWithoutProviderServiceConfigurationInput {
  where: ProviderServiceConfigurationOverrideWhereUniqueInput;
  update: ProviderServiceConfigurationOverrideUpdateWithoutProviderServiceConfigurationInput;
  create: ProviderServiceConfigurationOverrideCreateWithoutProviderServiceConfigurationInput;
}

export interface ProviderServiceConfigurationOverrideUpsertWithoutBenefitNoteRulesInput {
  update: ProviderServiceConfigurationOverrideUpdateWithoutBenefitNoteRulesInput;
  create: ProviderServiceConfigurationOverrideCreateWithoutBenefitNoteRulesInput;
}

export interface ProviderServiceConfigurationOverrideUpsertWithoutPayerProviderServiceConfigurationOverridesInput {
  update: ProviderServiceConfigurationOverrideUpdateWithoutPayerProviderServiceConfigurationOverridesInput;
  create: ProviderServiceConfigurationOverrideCreateWithoutPayerProviderServiceConfigurationOverridesInput;
}

export interface ProviderServiceConfigurationOverrideWhereInput {
  AND?: ProviderServiceConfigurationOverrideWhereInput[] | null;
  OR?: ProviderServiceConfigurationOverrideWhereInput[] | null;
  NOT?: ProviderServiceConfigurationOverrideWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  providerServiceConfigurationId?: UuidFilter | null;
  organizationId?: UuidFilter | null;
  serviceType?: StringFilter | null;
  fallbackServiceTypes?: StringNullableListFilter | null;
  providerServiceConfiguration?: ProviderServiceConfigurationRelationFilter | null;
  organization?: OrganizationRelationFilter | null;
  benefitNoteRules?: BenefitNoteRuleListRelationFilter | null;
  payerProviderServiceConfigurationOverrides?: PayerProviderServiceConfigurationOverrideListRelationFilter | null;
}

export interface ProviderServiceConfigurationOverrideWhereUniqueInput {
  id?: string | null;
}

export interface ProviderServiceConfigurationRelationFilter {
  is?: ProviderServiceConfigurationWhereInput | null;
  isNot?: ProviderServiceConfigurationWhereInput | null;
}

export interface ProviderServiceConfigurationScalarWhereInput {
  AND?: ProviderServiceConfigurationScalarWhereInput[] | null;
  OR?: ProviderServiceConfigurationScalarWhereInput[] | null;
  NOT?: ProviderServiceConfigurationScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  archivedAt?: DateTimeNullableFilter | null;
  organizationId?: UuidFilter | null;
  name?: StringFilter | null;
  shortName?: StringNullableFilter | null;
  serviceType?: StringFilter | null;
  fallbackServiceTypes?: StringNullableListFilter | null;
  showInPdf?: BoolFilter | null;
  providerTaxonomyClassificationId?: UuidNullableFilter | null;
  priorityOrder?: IntNullableFilter | null;
  virtual?: BoolNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  specialist?: BoolNullableFilter | null;
  claimType?: StringNullableFilter | null;
}

export interface ProviderServiceConfigurationUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
}

export interface ProviderServiceConfigurationUpdateManyWithWhereWithoutOrganizationInput {
  where: ProviderServiceConfigurationScalarWhereInput;
  data: ProviderServiceConfigurationUpdateManyMutationInput;
}

export interface ProviderServiceConfigurationUpdateManyWithWhereWithoutProviderTaxonomyClassificationInput {
  where: ProviderServiceConfigurationScalarWhereInput;
  data: ProviderServiceConfigurationUpdateManyMutationInput;
}

export interface ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: ProviderServiceConfigurationUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: ProviderServiceConfigurationCreateManyOrganizationInputEnvelope | null;
  set?: ProviderServiceConfigurationWhereUniqueInput[] | null;
  disconnect?: ProviderServiceConfigurationWhereUniqueInput[] | null;
  delete?: ProviderServiceConfigurationWhereUniqueInput[] | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput[] | null;
  update?: ProviderServiceConfigurationUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: ProviderServiceConfigurationUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: ProviderServiceConfigurationScalarWhereInput[] | null;
}

export interface ProviderServiceConfigurationUpdateManyWithoutProviderTaxonomyClassificationNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutProviderTaxonomyClassificationInput[] | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutProviderTaxonomyClassificationInput[] | null;
  upsert?: ProviderServiceConfigurationUpsertWithWhereUniqueWithoutProviderTaxonomyClassificationInput[] | null;
  createMany?: ProviderServiceConfigurationCreateManyProviderTaxonomyClassificationInputEnvelope | null;
  set?: ProviderServiceConfigurationWhereUniqueInput[] | null;
  disconnect?: ProviderServiceConfigurationWhereUniqueInput[] | null;
  delete?: ProviderServiceConfigurationWhereUniqueInput[] | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput[] | null;
  update?: ProviderServiceConfigurationUpdateWithWhereUniqueWithoutProviderTaxonomyClassificationInput[] | null;
  updateMany?: ProviderServiceConfigurationUpdateManyWithWhereWithoutProviderTaxonomyClassificationInput[] | null;
  deleteMany?: ProviderServiceConfigurationScalarWhereInput[] | null;
}

export interface ProviderServiceConfigurationUpdateOneRequiredWithoutBenefitAccumulatorAdjustmentsNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutBenefitAccumulatorAdjustmentsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutBenefitAccumulatorAdjustmentsInput | null;
  upsert?: ProviderServiceConfigurationUpsertWithoutBenefitAccumulatorAdjustmentsInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
  update?: ProviderServiceConfigurationUpdateWithoutBenefitAccumulatorAdjustmentsInput | null;
}

export interface ProviderServiceConfigurationUpdateOneRequiredWithoutBenefitMappingFallbacksNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutBenefitMappingFallbacksInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutBenefitMappingFallbacksInput | null;
  upsert?: ProviderServiceConfigurationUpsertWithoutBenefitMappingFallbacksInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
  update?: ProviderServiceConfigurationUpdateWithoutBenefitMappingFallbacksInput | null;
}

export interface ProviderServiceConfigurationUpdateOneRequiredWithoutCoverageBenefitsNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutCoverageBenefitsInput | null;
  upsert?: ProviderServiceConfigurationUpsertWithoutCoverageBenefitsInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
  update?: ProviderServiceConfigurationUpdateWithoutCoverageBenefitsInput | null;
}

export interface ProviderServiceConfigurationUpdateOneRequiredWithoutEligibilityCoverageBenefitSnapshotsNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  upsert?: ProviderServiceConfigurationUpsertWithoutEligibilityCoverageBenefitSnapshotsInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
  update?: ProviderServiceConfigurationUpdateWithoutEligibilityCoverageBenefitSnapshotsInput | null;
}

export interface ProviderServiceConfigurationUpdateOneRequiredWithoutProviderServiceConfigurationOverridesNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutProviderServiceConfigurationOverridesInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutProviderServiceConfigurationOverridesInput | null;
  upsert?: ProviderServiceConfigurationUpsertWithoutProviderServiceConfigurationOverridesInput | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
  update?: ProviderServiceConfigurationUpdateWithoutProviderServiceConfigurationOverridesInput | null;
}

export interface ProviderServiceConfigurationUpdateOneWithoutBenefitMappingsNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutBenefitMappingsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutBenefitMappingsInput | null;
  upsert?: ProviderServiceConfigurationUpsertWithoutBenefitMappingsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
  update?: ProviderServiceConfigurationUpdateWithoutBenefitMappingsInput | null;
}

export interface ProviderServiceConfigurationUpdateOneWithoutBenefitNoteRulesNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutBenefitNoteRulesInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutBenefitNoteRulesInput | null;
  upsert?: ProviderServiceConfigurationUpsertWithoutBenefitNoteRulesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
  update?: ProviderServiceConfigurationUpdateWithoutBenefitNoteRulesInput | null;
}

export interface ProviderServiceConfigurationUpdateOneWithoutChargeTemplateMappingConditionsNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutChargeTemplateMappingConditionsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutChargeTemplateMappingConditionsInput | null;
  upsert?: ProviderServiceConfigurationUpsertWithoutChargeTemplateMappingConditionsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
  update?: ProviderServiceConfigurationUpdateWithoutChargeTemplateMappingConditionsInput | null;
}

export interface ProviderServiceConfigurationUpdateOneWithoutDepositMappingConditionsNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutDepositMappingConditionsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutDepositMappingConditionsInput | null;
  upsert?: ProviderServiceConfigurationUpsertWithoutDepositMappingConditionsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
  update?: ProviderServiceConfigurationUpdateWithoutDepositMappingConditionsInput | null;
}

export interface ProviderServiceConfigurationUpdateOneWithoutDepositMappingsNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutDepositMappingsInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutDepositMappingsInput | null;
  upsert?: ProviderServiceConfigurationUpsertWithoutDepositMappingsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
  update?: ProviderServiceConfigurationUpdateWithoutDepositMappingsInput | null;
}

export interface ProviderServiceConfigurationUpdateOneWithoutPlanBenefitTemplatesNestedInput {
  create?: ProviderServiceConfigurationCreateWithoutPlanBenefitTemplatesInput | null;
  connectOrCreate?: ProviderServiceConfigurationCreateOrConnectWithoutPlanBenefitTemplatesInput | null;
  upsert?: ProviderServiceConfigurationUpsertWithoutPlanBenefitTemplatesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderServiceConfigurationWhereUniqueInput | null;
  update?: ProviderServiceConfigurationUpdateWithoutPlanBenefitTemplatesInput | null;
}

export interface ProviderServiceConfigurationUpdateWithWhereUniqueWithoutOrganizationInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  data: ProviderServiceConfigurationUpdateWithoutOrganizationInput;
}

export interface ProviderServiceConfigurationUpdateWithWhereUniqueWithoutProviderTaxonomyClassificationInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  data: ProviderServiceConfigurationUpdateWithoutProviderTaxonomyClassificationInput;
}

export interface ProviderServiceConfigurationUpdateWithoutBenefitAccumulatorAdjustmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutBenefitMappingFallbacksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutBenefitMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutBenefitNoteRulesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutChargeTemplateMappingConditionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutDepositMappingConditionsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutDepositMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutEligibilityCoverageBenefitSnapshotsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutPlanBenefitTemplatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutProviderServiceConfigurationOverridesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdateWithoutProviderTaxonomyClassificationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  archivedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  shortName?: NullableStringFieldUpdateOperationsInput | null;
  serviceType?: StringFieldUpdateOperationsInput | null;
  fallbackServiceTypes?: ProviderServiceConfigurationUpdatefallbackServiceTypesInput | null;
  showInPdf?: BoolFieldUpdateOperationsInput | null;
  priorityOrder?: NullableIntFieldUpdateOperationsInput | null;
  virtual?: NullableBoolFieldUpdateOperationsInput | null;
  placeOfService?: NullableStringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  claimType?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProviderServiceConfigurationNestedInput | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappings?: BenefitMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitMappingFallbacks?: BenefitMappingFallbackUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappings?: DepositMappingUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  depositMappingConditions?: DepositMappingConditionUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  benefitNoteRules?: BenefitNoteRuleUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
  planBenefitTemplates?: PlanBenefitTemplateUpdateManyWithoutProviderServiceConfigurationNestedInput | null;
}

export interface ProviderServiceConfigurationUpdatefallbackServiceTypesInput {
  set?: string[] | null;
  push?: string[] | null;
}

export interface ProviderServiceConfigurationUpsertWithWhereUniqueWithoutOrganizationInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  update: ProviderServiceConfigurationUpdateWithoutOrganizationInput;
  create: ProviderServiceConfigurationCreateWithoutOrganizationInput;
}

export interface ProviderServiceConfigurationUpsertWithWhereUniqueWithoutProviderTaxonomyClassificationInput {
  where: ProviderServiceConfigurationWhereUniqueInput;
  update: ProviderServiceConfigurationUpdateWithoutProviderTaxonomyClassificationInput;
  create: ProviderServiceConfigurationCreateWithoutProviderTaxonomyClassificationInput;
}

export interface ProviderServiceConfigurationUpsertWithoutBenefitAccumulatorAdjustmentsInput {
  update: ProviderServiceConfigurationUpdateWithoutBenefitAccumulatorAdjustmentsInput;
  create: ProviderServiceConfigurationCreateWithoutBenefitAccumulatorAdjustmentsInput;
}

export interface ProviderServiceConfigurationUpsertWithoutBenefitMappingFallbacksInput {
  update: ProviderServiceConfigurationUpdateWithoutBenefitMappingFallbacksInput;
  create: ProviderServiceConfigurationCreateWithoutBenefitMappingFallbacksInput;
}

export interface ProviderServiceConfigurationUpsertWithoutBenefitMappingsInput {
  update: ProviderServiceConfigurationUpdateWithoutBenefitMappingsInput;
  create: ProviderServiceConfigurationCreateWithoutBenefitMappingsInput;
}

export interface ProviderServiceConfigurationUpsertWithoutBenefitNoteRulesInput {
  update: ProviderServiceConfigurationUpdateWithoutBenefitNoteRulesInput;
  create: ProviderServiceConfigurationCreateWithoutBenefitNoteRulesInput;
}

export interface ProviderServiceConfigurationUpsertWithoutChargeTemplateMappingConditionsInput {
  update: ProviderServiceConfigurationUpdateWithoutChargeTemplateMappingConditionsInput;
  create: ProviderServiceConfigurationCreateWithoutChargeTemplateMappingConditionsInput;
}

export interface ProviderServiceConfigurationUpsertWithoutCoverageBenefitsInput {
  update: ProviderServiceConfigurationUpdateWithoutCoverageBenefitsInput;
  create: ProviderServiceConfigurationCreateWithoutCoverageBenefitsInput;
}

export interface ProviderServiceConfigurationUpsertWithoutDepositMappingConditionsInput {
  update: ProviderServiceConfigurationUpdateWithoutDepositMappingConditionsInput;
  create: ProviderServiceConfigurationCreateWithoutDepositMappingConditionsInput;
}

export interface ProviderServiceConfigurationUpsertWithoutDepositMappingsInput {
  update: ProviderServiceConfigurationUpdateWithoutDepositMappingsInput;
  create: ProviderServiceConfigurationCreateWithoutDepositMappingsInput;
}

export interface ProviderServiceConfigurationUpsertWithoutEligibilityCoverageBenefitSnapshotsInput {
  update: ProviderServiceConfigurationUpdateWithoutEligibilityCoverageBenefitSnapshotsInput;
  create: ProviderServiceConfigurationCreateWithoutEligibilityCoverageBenefitSnapshotsInput;
}

export interface ProviderServiceConfigurationUpsertWithoutPlanBenefitTemplatesInput {
  update: ProviderServiceConfigurationUpdateWithoutPlanBenefitTemplatesInput;
  create: ProviderServiceConfigurationCreateWithoutPlanBenefitTemplatesInput;
}

export interface ProviderServiceConfigurationUpsertWithoutProviderServiceConfigurationOverridesInput {
  update: ProviderServiceConfigurationUpdateWithoutProviderServiceConfigurationOverridesInput;
  create: ProviderServiceConfigurationCreateWithoutProviderServiceConfigurationOverridesInput;
}

export interface ProviderServiceConfigurationWhereInput {
  AND?: ProviderServiceConfigurationWhereInput[] | null;
  OR?: ProviderServiceConfigurationWhereInput[] | null;
  NOT?: ProviderServiceConfigurationWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  archivedAt?: DateTimeNullableFilter | null;
  organizationId?: UuidFilter | null;
  name?: StringFilter | null;
  shortName?: StringNullableFilter | null;
  serviceType?: StringFilter | null;
  fallbackServiceTypes?: StringNullableListFilter | null;
  showInPdf?: BoolFilter | null;
  providerTaxonomyClassificationId?: UuidNullableFilter | null;
  priorityOrder?: IntNullableFilter | null;
  virtual?: BoolNullableFilter | null;
  placeOfService?: StringNullableFilter | null;
  specialist?: BoolNullableFilter | null;
  claimType?: StringNullableFilter | null;
  organization?: OrganizationRelationFilter | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationRelationFilter | null;
  eligibilityCoverageBenefitSnapshots?: EligibilityCoverageBenefitSnapshotListRelationFilter | null;
  coverageBenefits?: CoverageBenefitListRelationFilter | null;
  benefitMappings?: BenefitMappingListRelationFilter | null;
  benefitMappingFallbacks?: BenefitMappingFallbackListRelationFilter | null;
  chargeTemplateMappingConditions?: ChargeTemplateMappingConditionListRelationFilter | null;
  benefitAccumulatorAdjustments?: BenefitAccumulatorAdjustmentListRelationFilter | null;
  depositMappings?: DepositMappingListRelationFilter | null;
  depositMappingConditions?: DepositMappingConditionListRelationFilter | null;
  providerServiceConfigurationOverrides?: ProviderServiceConfigurationOverrideListRelationFilter | null;
  benefitNoteRules?: BenefitNoteRuleListRelationFilter | null;
  planBenefitTemplates?: PlanBenefitTemplateListRelationFilter | null;
}

export interface ProviderServiceConfigurationWhereUniqueInput {
  id?: string | null;
}

export interface ProviderTaxonomyClassificationCreateNestedOneWithoutProviderServiceConfigurationsInput {
  create?: ProviderTaxonomyClassificationCreateWithoutProviderServiceConfigurationsInput | null;
  connectOrCreate?: ProviderTaxonomyClassificationCreateOrConnectWithoutProviderServiceConfigurationsInput | null;
  connect?: ProviderTaxonomyClassificationWhereUniqueInput | null;
}

export interface ProviderTaxonomyClassificationCreateNestedOneWithoutProviderTaxonomyCodesInput {
  create?: ProviderTaxonomyClassificationCreateWithoutProviderTaxonomyCodesInput | null;
  connectOrCreate?: ProviderTaxonomyClassificationCreateOrConnectWithoutProviderTaxonomyCodesInput | null;
  connect?: ProviderTaxonomyClassificationWhereUniqueInput | null;
}

export interface ProviderTaxonomyClassificationCreateNestedOneWithoutProviderTaxonomySpecializationsInput {
  create?: ProviderTaxonomyClassificationCreateWithoutProviderTaxonomySpecializationsInput | null;
  connectOrCreate?: ProviderTaxonomyClassificationCreateOrConnectWithoutProviderTaxonomySpecializationsInput | null;
  connect?: ProviderTaxonomyClassificationWhereUniqueInput | null;
}

export interface ProviderTaxonomyClassificationCreateOrConnectWithoutProviderServiceConfigurationsInput {
  where: ProviderTaxonomyClassificationWhereUniqueInput;
  create: ProviderTaxonomyClassificationCreateWithoutProviderServiceConfigurationsInput;
}

export interface ProviderTaxonomyClassificationCreateOrConnectWithoutProviderTaxonomyCodesInput {
  where: ProviderTaxonomyClassificationWhereUniqueInput;
  create: ProviderTaxonomyClassificationCreateWithoutProviderTaxonomyCodesInput;
}

export interface ProviderTaxonomyClassificationCreateOrConnectWithoutProviderTaxonomySpecializationsInput {
  where: ProviderTaxonomyClassificationWhereUniqueInput;
  create: ProviderTaxonomyClassificationCreateWithoutProviderTaxonomySpecializationsInput;
}

export interface ProviderTaxonomyClassificationCreateWithoutProviderServiceConfigurationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  grouping: string;
  specialist?: boolean | null;
  providerTaxonomySpecializations?: ProviderTaxonomySpecializationCreateNestedManyWithoutProviderTaxonomyClassificationInput | null;
  providerTaxonomyCodes?: ProviderTaxonomyCodeCreateNestedManyWithoutProviderTaxonomyClassificationInput | null;
}

export interface ProviderTaxonomyClassificationCreateWithoutProviderTaxonomyCodesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  grouping: string;
  specialist?: boolean | null;
  providerTaxonomySpecializations?: ProviderTaxonomySpecializationCreateNestedManyWithoutProviderTaxonomyClassificationInput | null;
  providerServiceConfigurations?: ProviderServiceConfigurationCreateNestedManyWithoutProviderTaxonomyClassificationInput | null;
}

export interface ProviderTaxonomyClassificationCreateWithoutProviderTaxonomySpecializationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  grouping: string;
  specialist?: boolean | null;
  providerTaxonomyCodes?: ProviderTaxonomyCodeCreateNestedManyWithoutProviderTaxonomyClassificationInput | null;
  providerServiceConfigurations?: ProviderServiceConfigurationCreateNestedManyWithoutProviderTaxonomyClassificationInput | null;
}

export interface ProviderTaxonomyClassificationOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  name?: SortOrder | null;
  grouping?: SortOrder | null;
  specialist?: SortOrderInput | null;
  providerTaxonomySpecializations?: ProviderTaxonomySpecializationOrderByRelationAggregateInput | null;
  providerTaxonomyCodes?: ProviderTaxonomyCodeOrderByRelationAggregateInput | null;
  providerServiceConfigurations?: ProviderServiceConfigurationOrderByRelationAggregateInput | null;
}

export interface ProviderTaxonomyClassificationRelationFilter {
  is?: ProviderTaxonomyClassificationWhereInput | null;
  isNot?: ProviderTaxonomyClassificationWhereInput | null;
}

export interface ProviderTaxonomyClassificationUpdateOneRequiredWithoutProviderTaxonomySpecializationsNestedInput {
  create?: ProviderTaxonomyClassificationCreateWithoutProviderTaxonomySpecializationsInput | null;
  connectOrCreate?: ProviderTaxonomyClassificationCreateOrConnectWithoutProviderTaxonomySpecializationsInput | null;
  upsert?: ProviderTaxonomyClassificationUpsertWithoutProviderTaxonomySpecializationsInput | null;
  connect?: ProviderTaxonomyClassificationWhereUniqueInput | null;
  update?: ProviderTaxonomyClassificationUpdateWithoutProviderTaxonomySpecializationsInput | null;
}

export interface ProviderTaxonomyClassificationUpdateOneWithoutProviderServiceConfigurationsNestedInput {
  create?: ProviderTaxonomyClassificationCreateWithoutProviderServiceConfigurationsInput | null;
  connectOrCreate?: ProviderTaxonomyClassificationCreateOrConnectWithoutProviderServiceConfigurationsInput | null;
  upsert?: ProviderTaxonomyClassificationUpsertWithoutProviderServiceConfigurationsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderTaxonomyClassificationWhereUniqueInput | null;
  update?: ProviderTaxonomyClassificationUpdateWithoutProviderServiceConfigurationsInput | null;
}

export interface ProviderTaxonomyClassificationUpdateOneWithoutProviderTaxonomyCodesNestedInput {
  create?: ProviderTaxonomyClassificationCreateWithoutProviderTaxonomyCodesInput | null;
  connectOrCreate?: ProviderTaxonomyClassificationCreateOrConnectWithoutProviderTaxonomyCodesInput | null;
  upsert?: ProviderTaxonomyClassificationUpsertWithoutProviderTaxonomyCodesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderTaxonomyClassificationWhereUniqueInput | null;
  update?: ProviderTaxonomyClassificationUpdateWithoutProviderTaxonomyCodesInput | null;
}

export interface ProviderTaxonomyClassificationUpdateWithoutProviderServiceConfigurationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  grouping?: StringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  providerTaxonomySpecializations?: ProviderTaxonomySpecializationUpdateManyWithoutProviderTaxonomyClassificationNestedInput | null;
  providerTaxonomyCodes?: ProviderTaxonomyCodeUpdateManyWithoutProviderTaxonomyClassificationNestedInput | null;
}

export interface ProviderTaxonomyClassificationUpdateWithoutProviderTaxonomyCodesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  grouping?: StringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  providerTaxonomySpecializations?: ProviderTaxonomySpecializationUpdateManyWithoutProviderTaxonomyClassificationNestedInput | null;
  providerServiceConfigurations?: ProviderServiceConfigurationUpdateManyWithoutProviderTaxonomyClassificationNestedInput | null;
}

export interface ProviderTaxonomyClassificationUpdateWithoutProviderTaxonomySpecializationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  grouping?: StringFieldUpdateOperationsInput | null;
  specialist?: NullableBoolFieldUpdateOperationsInput | null;
  providerTaxonomyCodes?: ProviderTaxonomyCodeUpdateManyWithoutProviderTaxonomyClassificationNestedInput | null;
  providerServiceConfigurations?: ProviderServiceConfigurationUpdateManyWithoutProviderTaxonomyClassificationNestedInput | null;
}

export interface ProviderTaxonomyClassificationUpsertWithoutProviderServiceConfigurationsInput {
  update: ProviderTaxonomyClassificationUpdateWithoutProviderServiceConfigurationsInput;
  create: ProviderTaxonomyClassificationCreateWithoutProviderServiceConfigurationsInput;
}

export interface ProviderTaxonomyClassificationUpsertWithoutProviderTaxonomyCodesInput {
  update: ProviderTaxonomyClassificationUpdateWithoutProviderTaxonomyCodesInput;
  create: ProviderTaxonomyClassificationCreateWithoutProviderTaxonomyCodesInput;
}

export interface ProviderTaxonomyClassificationUpsertWithoutProviderTaxonomySpecializationsInput {
  update: ProviderTaxonomyClassificationUpdateWithoutProviderTaxonomySpecializationsInput;
  create: ProviderTaxonomyClassificationCreateWithoutProviderTaxonomySpecializationsInput;
}

export interface ProviderTaxonomyClassificationWhereInput {
  AND?: ProviderTaxonomyClassificationWhereInput[] | null;
  OR?: ProviderTaxonomyClassificationWhereInput[] | null;
  NOT?: ProviderTaxonomyClassificationWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  grouping?: StringFilter | null;
  specialist?: BoolNullableFilter | null;
  providerTaxonomySpecializations?: ProviderTaxonomySpecializationListRelationFilter | null;
  providerTaxonomyCodes?: ProviderTaxonomyCodeListRelationFilter | null;
  providerServiceConfigurations?: ProviderServiceConfigurationListRelationFilter | null;
}

export interface ProviderTaxonomyClassificationWhereUniqueInput {
  id?: string | null;
  name?: string | null;
}

export interface ProviderTaxonomyCodeCreateManyProviderTaxonomyClassificationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  displayName: string;
  definition?: string | null;
  section: string;
  notes?: string | null;
  providerTaxonomySpecializationId?: string | null;
}

export interface ProviderTaxonomyCodeCreateManyProviderTaxonomyClassificationInputEnvelope {
  data: ProviderTaxonomyCodeCreateManyProviderTaxonomyClassificationInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderTaxonomyCodeCreateManyProviderTaxonomySpecializationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  displayName: string;
  definition?: string | null;
  section: string;
  notes?: string | null;
  providerTaxonomyClassificationId?: string | null;
}

export interface ProviderTaxonomyCodeCreateManyProviderTaxonomySpecializationInputEnvelope {
  data: ProviderTaxonomyCodeCreateManyProviderTaxonomySpecializationInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderTaxonomyCodeCreateNestedManyWithoutProviderTaxonomyClassificationInput {
  create?: ProviderTaxonomyCodeCreateWithoutProviderTaxonomyClassificationInput[] | null;
  connectOrCreate?: ProviderTaxonomyCodeCreateOrConnectWithoutProviderTaxonomyClassificationInput[] | null;
  createMany?: ProviderTaxonomyCodeCreateManyProviderTaxonomyClassificationInputEnvelope | null;
  connect?: ProviderTaxonomyCodeWhereUniqueInput[] | null;
}

export interface ProviderTaxonomyCodeCreateNestedManyWithoutProviderTaxonomySpecializationInput {
  create?: ProviderTaxonomyCodeCreateWithoutProviderTaxonomySpecializationInput[] | null;
  connectOrCreate?: ProviderTaxonomyCodeCreateOrConnectWithoutProviderTaxonomySpecializationInput[] | null;
  createMany?: ProviderTaxonomyCodeCreateManyProviderTaxonomySpecializationInputEnvelope | null;
  connect?: ProviderTaxonomyCodeWhereUniqueInput[] | null;
}

export interface ProviderTaxonomyCodeCreateNestedOneWithoutProvidersInput {
  create?: ProviderTaxonomyCodeCreateWithoutProvidersInput | null;
  connectOrCreate?: ProviderTaxonomyCodeCreateOrConnectWithoutProvidersInput | null;
  connect?: ProviderTaxonomyCodeWhereUniqueInput | null;
}

export interface ProviderTaxonomyCodeCreateOrConnectWithoutProviderTaxonomyClassificationInput {
  where: ProviderTaxonomyCodeWhereUniqueInput;
  create: ProviderTaxonomyCodeCreateWithoutProviderTaxonomyClassificationInput;
}

export interface ProviderTaxonomyCodeCreateOrConnectWithoutProviderTaxonomySpecializationInput {
  where: ProviderTaxonomyCodeWhereUniqueInput;
  create: ProviderTaxonomyCodeCreateWithoutProviderTaxonomySpecializationInput;
}

export interface ProviderTaxonomyCodeCreateOrConnectWithoutProvidersInput {
  where: ProviderTaxonomyCodeWhereUniqueInput;
  create: ProviderTaxonomyCodeCreateWithoutProvidersInput;
}

export interface ProviderTaxonomyCodeCreateWithoutProviderTaxonomyClassificationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  displayName: string;
  definition?: string | null;
  section: string;
  notes?: string | null;
  providerTaxonomySpecialization?: ProviderTaxonomySpecializationCreateNestedOneWithoutProviderTaxonomyCodesInput | null;
  providers?: ProviderCreateNestedManyWithoutProviderTaxonomyCodeInput | null;
}

export interface ProviderTaxonomyCodeCreateWithoutProviderTaxonomySpecializationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  displayName: string;
  definition?: string | null;
  section: string;
  notes?: string | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderTaxonomyCodesInput | null;
  providers?: ProviderCreateNestedManyWithoutProviderTaxonomyCodeInput | null;
}

export interface ProviderTaxonomyCodeCreateWithoutProvidersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  code: string;
  displayName: string;
  definition?: string | null;
  section: string;
  notes?: string | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderTaxonomyCodesInput | null;
  providerTaxonomySpecialization?: ProviderTaxonomySpecializationCreateNestedOneWithoutProviderTaxonomyCodesInput | null;
}

export interface ProviderTaxonomyCodeListRelationFilter {
  every?: ProviderTaxonomyCodeWhereInput | null;
  some?: ProviderTaxonomyCodeWhereInput | null;
  none?: ProviderTaxonomyCodeWhereInput | null;
}

export interface ProviderTaxonomyCodeOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ProviderTaxonomyCodeOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  code?: SortOrder | null;
  displayName?: SortOrder | null;
  definition?: SortOrderInput | null;
  section?: SortOrder | null;
  notes?: SortOrderInput | null;
  providerTaxonomyClassificationId?: SortOrderInput | null;
  providerTaxonomySpecializationId?: SortOrderInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationOrderByWithRelationInput | null;
  providerTaxonomySpecialization?: ProviderTaxonomySpecializationOrderByWithRelationInput | null;
  providers?: ProviderOrderByRelationAggregateInput | null;
}

export interface ProviderTaxonomyCodeRelationFilter {
  is?: ProviderTaxonomyCodeWhereInput | null;
  isNot?: ProviderTaxonomyCodeWhereInput | null;
}

export interface ProviderTaxonomyCodeScalarWhereInput {
  AND?: ProviderTaxonomyCodeScalarWhereInput[] | null;
  OR?: ProviderTaxonomyCodeScalarWhereInput[] | null;
  NOT?: ProviderTaxonomyCodeScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  code?: StringFilter | null;
  displayName?: StringFilter | null;
  definition?: StringNullableFilter | null;
  section?: StringFilter | null;
  notes?: StringNullableFilter | null;
  providerTaxonomyClassificationId?: UuidNullableFilter | null;
  providerTaxonomySpecializationId?: UuidNullableFilter | null;
}

export interface ProviderTaxonomyCodeUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  displayName?: StringFieldUpdateOperationsInput | null;
  definition?: NullableStringFieldUpdateOperationsInput | null;
  section?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
}

export interface ProviderTaxonomyCodeUpdateManyWithWhereWithoutProviderTaxonomyClassificationInput {
  where: ProviderTaxonomyCodeScalarWhereInput;
  data: ProviderTaxonomyCodeUpdateManyMutationInput;
}

export interface ProviderTaxonomyCodeUpdateManyWithWhereWithoutProviderTaxonomySpecializationInput {
  where: ProviderTaxonomyCodeScalarWhereInput;
  data: ProviderTaxonomyCodeUpdateManyMutationInput;
}

export interface ProviderTaxonomyCodeUpdateManyWithoutProviderTaxonomyClassificationNestedInput {
  create?: ProviderTaxonomyCodeCreateWithoutProviderTaxonomyClassificationInput[] | null;
  connectOrCreate?: ProviderTaxonomyCodeCreateOrConnectWithoutProviderTaxonomyClassificationInput[] | null;
  upsert?: ProviderTaxonomyCodeUpsertWithWhereUniqueWithoutProviderTaxonomyClassificationInput[] | null;
  createMany?: ProviderTaxonomyCodeCreateManyProviderTaxonomyClassificationInputEnvelope | null;
  set?: ProviderTaxonomyCodeWhereUniqueInput[] | null;
  disconnect?: ProviderTaxonomyCodeWhereUniqueInput[] | null;
  delete?: ProviderTaxonomyCodeWhereUniqueInput[] | null;
  connect?: ProviderTaxonomyCodeWhereUniqueInput[] | null;
  update?: ProviderTaxonomyCodeUpdateWithWhereUniqueWithoutProviderTaxonomyClassificationInput[] | null;
  updateMany?: ProviderTaxonomyCodeUpdateManyWithWhereWithoutProviderTaxonomyClassificationInput[] | null;
  deleteMany?: ProviderTaxonomyCodeScalarWhereInput[] | null;
}

export interface ProviderTaxonomyCodeUpdateManyWithoutProviderTaxonomySpecializationNestedInput {
  create?: ProviderTaxonomyCodeCreateWithoutProviderTaxonomySpecializationInput[] | null;
  connectOrCreate?: ProviderTaxonomyCodeCreateOrConnectWithoutProviderTaxonomySpecializationInput[] | null;
  upsert?: ProviderTaxonomyCodeUpsertWithWhereUniqueWithoutProviderTaxonomySpecializationInput[] | null;
  createMany?: ProviderTaxonomyCodeCreateManyProviderTaxonomySpecializationInputEnvelope | null;
  set?: ProviderTaxonomyCodeWhereUniqueInput[] | null;
  disconnect?: ProviderTaxonomyCodeWhereUniqueInput[] | null;
  delete?: ProviderTaxonomyCodeWhereUniqueInput[] | null;
  connect?: ProviderTaxonomyCodeWhereUniqueInput[] | null;
  update?: ProviderTaxonomyCodeUpdateWithWhereUniqueWithoutProviderTaxonomySpecializationInput[] | null;
  updateMany?: ProviderTaxonomyCodeUpdateManyWithWhereWithoutProviderTaxonomySpecializationInput[] | null;
  deleteMany?: ProviderTaxonomyCodeScalarWhereInput[] | null;
}

export interface ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput {
  create?: ProviderTaxonomyCodeCreateWithoutProvidersInput | null;
  connectOrCreate?: ProviderTaxonomyCodeCreateOrConnectWithoutProvidersInput | null;
  upsert?: ProviderTaxonomyCodeUpsertWithoutProvidersInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderTaxonomyCodeWhereUniqueInput | null;
  update?: ProviderTaxonomyCodeUpdateWithoutProvidersInput | null;
}

export interface ProviderTaxonomyCodeUpdateWithWhereUniqueWithoutProviderTaxonomyClassificationInput {
  where: ProviderTaxonomyCodeWhereUniqueInput;
  data: ProviderTaxonomyCodeUpdateWithoutProviderTaxonomyClassificationInput;
}

export interface ProviderTaxonomyCodeUpdateWithWhereUniqueWithoutProviderTaxonomySpecializationInput {
  where: ProviderTaxonomyCodeWhereUniqueInput;
  data: ProviderTaxonomyCodeUpdateWithoutProviderTaxonomySpecializationInput;
}

export interface ProviderTaxonomyCodeUpdateWithoutProviderTaxonomyClassificationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  displayName?: StringFieldUpdateOperationsInput | null;
  definition?: NullableStringFieldUpdateOperationsInput | null;
  section?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  providerTaxonomySpecialization?: ProviderTaxonomySpecializationUpdateOneWithoutProviderTaxonomyCodesNestedInput | null;
  providers?: ProviderUpdateManyWithoutProviderTaxonomyCodeNestedInput | null;
}

export interface ProviderTaxonomyCodeUpdateWithoutProviderTaxonomySpecializationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  displayName?: StringFieldUpdateOperationsInput | null;
  definition?: NullableStringFieldUpdateOperationsInput | null;
  section?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderTaxonomyCodesNestedInput | null;
  providers?: ProviderUpdateManyWithoutProviderTaxonomyCodeNestedInput | null;
}

export interface ProviderTaxonomyCodeUpdateWithoutProvidersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  code?: StringFieldUpdateOperationsInput | null;
  displayName?: StringFieldUpdateOperationsInput | null;
  definition?: NullableStringFieldUpdateOperationsInput | null;
  section?: StringFieldUpdateOperationsInput | null;
  notes?: NullableStringFieldUpdateOperationsInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneWithoutProviderTaxonomyCodesNestedInput | null;
  providerTaxonomySpecialization?: ProviderTaxonomySpecializationUpdateOneWithoutProviderTaxonomyCodesNestedInput | null;
}

export interface ProviderTaxonomyCodeUpsertWithWhereUniqueWithoutProviderTaxonomyClassificationInput {
  where: ProviderTaxonomyCodeWhereUniqueInput;
  update: ProviderTaxonomyCodeUpdateWithoutProviderTaxonomyClassificationInput;
  create: ProviderTaxonomyCodeCreateWithoutProviderTaxonomyClassificationInput;
}

export interface ProviderTaxonomyCodeUpsertWithWhereUniqueWithoutProviderTaxonomySpecializationInput {
  where: ProviderTaxonomyCodeWhereUniqueInput;
  update: ProviderTaxonomyCodeUpdateWithoutProviderTaxonomySpecializationInput;
  create: ProviderTaxonomyCodeCreateWithoutProviderTaxonomySpecializationInput;
}

export interface ProviderTaxonomyCodeUpsertWithoutProvidersInput {
  update: ProviderTaxonomyCodeUpdateWithoutProvidersInput;
  create: ProviderTaxonomyCodeCreateWithoutProvidersInput;
}

export interface ProviderTaxonomyCodeWhereInput {
  AND?: ProviderTaxonomyCodeWhereInput[] | null;
  OR?: ProviderTaxonomyCodeWhereInput[] | null;
  NOT?: ProviderTaxonomyCodeWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  code?: StringFilter | null;
  displayName?: StringFilter | null;
  definition?: StringNullableFilter | null;
  section?: StringFilter | null;
  notes?: StringNullableFilter | null;
  providerTaxonomyClassificationId?: UuidNullableFilter | null;
  providerTaxonomySpecializationId?: UuidNullableFilter | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationRelationFilter | null;
  providerTaxonomySpecialization?: ProviderTaxonomySpecializationRelationFilter | null;
  providers?: ProviderListRelationFilter | null;
}

export interface ProviderTaxonomyCodeWhereUniqueInput {
  id?: string | null;
  code?: string | null;
}

export interface ProviderTaxonomySpecializationCreateManyProviderTaxonomyClassificationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
}

export interface ProviderTaxonomySpecializationCreateManyProviderTaxonomyClassificationInputEnvelope {
  data: ProviderTaxonomySpecializationCreateManyProviderTaxonomyClassificationInput[];
  skipDuplicates?: boolean | null;
}

export interface ProviderTaxonomySpecializationCreateNestedManyWithoutProviderTaxonomyClassificationInput {
  create?: ProviderTaxonomySpecializationCreateWithoutProviderTaxonomyClassificationInput[] | null;
  connectOrCreate?: ProviderTaxonomySpecializationCreateOrConnectWithoutProviderTaxonomyClassificationInput[] | null;
  createMany?: ProviderTaxonomySpecializationCreateManyProviderTaxonomyClassificationInputEnvelope | null;
  connect?: ProviderTaxonomySpecializationWhereUniqueInput[] | null;
}

export interface ProviderTaxonomySpecializationCreateNestedOneWithoutProviderTaxonomyCodesInput {
  create?: ProviderTaxonomySpecializationCreateWithoutProviderTaxonomyCodesInput | null;
  connectOrCreate?: ProviderTaxonomySpecializationCreateOrConnectWithoutProviderTaxonomyCodesInput | null;
  connect?: ProviderTaxonomySpecializationWhereUniqueInput | null;
}

export interface ProviderTaxonomySpecializationCreateOrConnectWithoutProviderTaxonomyClassificationInput {
  where: ProviderTaxonomySpecializationWhereUniqueInput;
  create: ProviderTaxonomySpecializationCreateWithoutProviderTaxonomyClassificationInput;
}

export interface ProviderTaxonomySpecializationCreateOrConnectWithoutProviderTaxonomyCodesInput {
  where: ProviderTaxonomySpecializationWhereUniqueInput;
  create: ProviderTaxonomySpecializationCreateWithoutProviderTaxonomyCodesInput;
}

export interface ProviderTaxonomySpecializationCreateWithoutProviderTaxonomyClassificationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  providerTaxonomyCodes?: ProviderTaxonomyCodeCreateNestedManyWithoutProviderTaxonomySpecializationInput | null;
}

export interface ProviderTaxonomySpecializationCreateWithoutProviderTaxonomyCodesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  providerTaxonomyClassification: ProviderTaxonomyClassificationCreateNestedOneWithoutProviderTaxonomySpecializationsInput;
}

export interface ProviderTaxonomySpecializationListRelationFilter {
  every?: ProviderTaxonomySpecializationWhereInput | null;
  some?: ProviderTaxonomySpecializationWhereInput | null;
  none?: ProviderTaxonomySpecializationWhereInput | null;
}

export interface ProviderTaxonomySpecializationNameProviderTaxonomyClassificationIdCompoundUniqueInput {
  name: string;
  providerTaxonomyClassificationId: string;
}

export interface ProviderTaxonomySpecializationOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ProviderTaxonomySpecializationOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  name?: SortOrder | null;
  providerTaxonomyClassificationId?: SortOrder | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationOrderByWithRelationInput | null;
  providerTaxonomyCodes?: ProviderTaxonomyCodeOrderByRelationAggregateInput | null;
}

export interface ProviderTaxonomySpecializationRelationFilter {
  is?: ProviderTaxonomySpecializationWhereInput | null;
  isNot?: ProviderTaxonomySpecializationWhereInput | null;
}

export interface ProviderTaxonomySpecializationScalarWhereInput {
  AND?: ProviderTaxonomySpecializationScalarWhereInput[] | null;
  OR?: ProviderTaxonomySpecializationScalarWhereInput[] | null;
  NOT?: ProviderTaxonomySpecializationScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  providerTaxonomyClassificationId?: UuidFilter | null;
}

export interface ProviderTaxonomySpecializationUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
}

export interface ProviderTaxonomySpecializationUpdateManyWithWhereWithoutProviderTaxonomyClassificationInput {
  where: ProviderTaxonomySpecializationScalarWhereInput;
  data: ProviderTaxonomySpecializationUpdateManyMutationInput;
}

export interface ProviderTaxonomySpecializationUpdateManyWithoutProviderTaxonomyClassificationNestedInput {
  create?: ProviderTaxonomySpecializationCreateWithoutProviderTaxonomyClassificationInput[] | null;
  connectOrCreate?: ProviderTaxonomySpecializationCreateOrConnectWithoutProviderTaxonomyClassificationInput[] | null;
  upsert?: ProviderTaxonomySpecializationUpsertWithWhereUniqueWithoutProviderTaxonomyClassificationInput[] | null;
  createMany?: ProviderTaxonomySpecializationCreateManyProviderTaxonomyClassificationInputEnvelope | null;
  set?: ProviderTaxonomySpecializationWhereUniqueInput[] | null;
  disconnect?: ProviderTaxonomySpecializationWhereUniqueInput[] | null;
  delete?: ProviderTaxonomySpecializationWhereUniqueInput[] | null;
  connect?: ProviderTaxonomySpecializationWhereUniqueInput[] | null;
  update?: ProviderTaxonomySpecializationUpdateWithWhereUniqueWithoutProviderTaxonomyClassificationInput[] | null;
  updateMany?: ProviderTaxonomySpecializationUpdateManyWithWhereWithoutProviderTaxonomyClassificationInput[] | null;
  deleteMany?: ProviderTaxonomySpecializationScalarWhereInput[] | null;
}

export interface ProviderTaxonomySpecializationUpdateOneWithoutProviderTaxonomyCodesNestedInput {
  create?: ProviderTaxonomySpecializationCreateWithoutProviderTaxonomyCodesInput | null;
  connectOrCreate?: ProviderTaxonomySpecializationCreateOrConnectWithoutProviderTaxonomyCodesInput | null;
  upsert?: ProviderTaxonomySpecializationUpsertWithoutProviderTaxonomyCodesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderTaxonomySpecializationWhereUniqueInput | null;
  update?: ProviderTaxonomySpecializationUpdateWithoutProviderTaxonomyCodesInput | null;
}

export interface ProviderTaxonomySpecializationUpdateWithWhereUniqueWithoutProviderTaxonomyClassificationInput {
  where: ProviderTaxonomySpecializationWhereUniqueInput;
  data: ProviderTaxonomySpecializationUpdateWithoutProviderTaxonomyClassificationInput;
}

export interface ProviderTaxonomySpecializationUpdateWithoutProviderTaxonomyClassificationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  providerTaxonomyCodes?: ProviderTaxonomyCodeUpdateManyWithoutProviderTaxonomySpecializationNestedInput | null;
}

export interface ProviderTaxonomySpecializationUpdateWithoutProviderTaxonomyCodesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationUpdateOneRequiredWithoutProviderTaxonomySpecializationsNestedInput | null;
}

export interface ProviderTaxonomySpecializationUpsertWithWhereUniqueWithoutProviderTaxonomyClassificationInput {
  where: ProviderTaxonomySpecializationWhereUniqueInput;
  update: ProviderTaxonomySpecializationUpdateWithoutProviderTaxonomyClassificationInput;
  create: ProviderTaxonomySpecializationCreateWithoutProviderTaxonomyClassificationInput;
}

export interface ProviderTaxonomySpecializationUpsertWithoutProviderTaxonomyCodesInput {
  update: ProviderTaxonomySpecializationUpdateWithoutProviderTaxonomyCodesInput;
  create: ProviderTaxonomySpecializationCreateWithoutProviderTaxonomyCodesInput;
}

export interface ProviderTaxonomySpecializationWhereInput {
  AND?: ProviderTaxonomySpecializationWhereInput[] | null;
  OR?: ProviderTaxonomySpecializationWhereInput[] | null;
  NOT?: ProviderTaxonomySpecializationWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  providerTaxonomyClassificationId?: UuidFilter | null;
  providerTaxonomyClassification?: ProviderTaxonomyClassificationRelationFilter | null;
  providerTaxonomyCodes?: ProviderTaxonomyCodeListRelationFilter | null;
}

export interface ProviderTaxonomySpecializationWhereUniqueInput {
  id?: string | null;
  name_providerTaxonomyClassificationId?: ProviderTaxonomySpecializationNameProviderTaxonomyClassificationIdCompoundUniqueInput | null;
}

export interface ProviderUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
}

export interface ProviderUpdateManyWithWhereWithoutAppointmentsInput {
  where: ProviderScalarWhereInput;
  data: ProviderUpdateManyMutationInput;
}

export interface ProviderUpdateManyWithWhereWithoutBillingGroupInput {
  where: ProviderScalarWhereInput;
  data: ProviderUpdateManyMutationInput;
}

export interface ProviderUpdateManyWithWhereWithoutOrganizationInput {
  where: ProviderScalarWhereInput;
  data: ProviderUpdateManyMutationInput;
}

export interface ProviderUpdateManyWithWhereWithoutPrimaryLocationInput {
  where: ProviderScalarWhereInput;
  data: ProviderUpdateManyMutationInput;
}

export interface ProviderUpdateManyWithWhereWithoutProviderTaxonomyCodeInput {
  where: ProviderScalarWhereInput;
  data: ProviderUpdateManyMutationInput;
}

export interface ProviderUpdateManyWithWhereWithoutRenderedBillsInput {
  where: ProviderScalarWhereInput;
  data: ProviderUpdateManyMutationInput;
}

export interface ProviderUpdateManyWithoutAppointmentsNestedInput {
  create?: ProviderCreateWithoutAppointmentsInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutAppointmentsInput[] | null;
  upsert?: ProviderUpsertWithWhereUniqueWithoutAppointmentsInput[] | null;
  set?: ProviderWhereUniqueInput[] | null;
  disconnect?: ProviderWhereUniqueInput[] | null;
  delete?: ProviderWhereUniqueInput[] | null;
  connect?: ProviderWhereUniqueInput[] | null;
  update?: ProviderUpdateWithWhereUniqueWithoutAppointmentsInput[] | null;
  updateMany?: ProviderUpdateManyWithWhereWithoutAppointmentsInput[] | null;
  deleteMany?: ProviderScalarWhereInput[] | null;
}

export interface ProviderUpdateManyWithoutBillingGroupNestedInput {
  create?: ProviderCreateWithoutBillingGroupInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutBillingGroupInput[] | null;
  upsert?: ProviderUpsertWithWhereUniqueWithoutBillingGroupInput[] | null;
  createMany?: ProviderCreateManyBillingGroupInputEnvelope | null;
  set?: ProviderWhereUniqueInput[] | null;
  disconnect?: ProviderWhereUniqueInput[] | null;
  delete?: ProviderWhereUniqueInput[] | null;
  connect?: ProviderWhereUniqueInput[] | null;
  update?: ProviderUpdateWithWhereUniqueWithoutBillingGroupInput[] | null;
  updateMany?: ProviderUpdateManyWithWhereWithoutBillingGroupInput[] | null;
  deleteMany?: ProviderScalarWhereInput[] | null;
}

export interface ProviderUpdateManyWithoutOrganizationNestedInput {
  create?: ProviderCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: ProviderUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: ProviderCreateManyOrganizationInputEnvelope | null;
  set?: ProviderWhereUniqueInput[] | null;
  disconnect?: ProviderWhereUniqueInput[] | null;
  delete?: ProviderWhereUniqueInput[] | null;
  connect?: ProviderWhereUniqueInput[] | null;
  update?: ProviderUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: ProviderUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: ProviderScalarWhereInput[] | null;
}

export interface ProviderUpdateManyWithoutPrimaryLocationNestedInput {
  create?: ProviderCreateWithoutPrimaryLocationInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutPrimaryLocationInput[] | null;
  upsert?: ProviderUpsertWithWhereUniqueWithoutPrimaryLocationInput[] | null;
  createMany?: ProviderCreateManyPrimaryLocationInputEnvelope | null;
  set?: ProviderWhereUniqueInput[] | null;
  disconnect?: ProviderWhereUniqueInput[] | null;
  delete?: ProviderWhereUniqueInput[] | null;
  connect?: ProviderWhereUniqueInput[] | null;
  update?: ProviderUpdateWithWhereUniqueWithoutPrimaryLocationInput[] | null;
  updateMany?: ProviderUpdateManyWithWhereWithoutPrimaryLocationInput[] | null;
  deleteMany?: ProviderScalarWhereInput[] | null;
}

export interface ProviderUpdateManyWithoutProviderTaxonomyCodeNestedInput {
  create?: ProviderCreateWithoutProviderTaxonomyCodeInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutProviderTaxonomyCodeInput[] | null;
  upsert?: ProviderUpsertWithWhereUniqueWithoutProviderTaxonomyCodeInput[] | null;
  createMany?: ProviderCreateManyProviderTaxonomyCodeInputEnvelope | null;
  set?: ProviderWhereUniqueInput[] | null;
  disconnect?: ProviderWhereUniqueInput[] | null;
  delete?: ProviderWhereUniqueInput[] | null;
  connect?: ProviderWhereUniqueInput[] | null;
  update?: ProviderUpdateWithWhereUniqueWithoutProviderTaxonomyCodeInput[] | null;
  updateMany?: ProviderUpdateManyWithWhereWithoutProviderTaxonomyCodeInput[] | null;
  deleteMany?: ProviderScalarWhereInput[] | null;
}

export interface ProviderUpdateManyWithoutRenderedBillsNestedInput {
  create?: ProviderCreateWithoutRenderedBillsInput[] | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutRenderedBillsInput[] | null;
  upsert?: ProviderUpsertWithWhereUniqueWithoutRenderedBillsInput[] | null;
  set?: ProviderWhereUniqueInput[] | null;
  disconnect?: ProviderWhereUniqueInput[] | null;
  delete?: ProviderWhereUniqueInput[] | null;
  connect?: ProviderWhereUniqueInput[] | null;
  update?: ProviderUpdateWithWhereUniqueWithoutRenderedBillsInput[] | null;
  updateMany?: ProviderUpdateManyWithWhereWithoutRenderedBillsInput[] | null;
  deleteMany?: ProviderScalarWhereInput[] | null;
}

export interface ProviderUpdateOneRequiredWithoutProviderNetworkParticipationsNestedInput {
  create?: ProviderCreateWithoutProviderNetworkParticipationsInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutProviderNetworkParticipationsInput | null;
  upsert?: ProviderUpsertWithoutProviderNetworkParticipationsInput | null;
  connect?: ProviderWhereUniqueInput | null;
  update?: ProviderUpdateWithoutProviderNetworkParticipationsInput | null;
}

export interface ProviderUpdateOneWithoutBilledBillsNestedInput {
  create?: ProviderCreateWithoutBilledBillsInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutBilledBillsInput | null;
  upsert?: ProviderUpsertWithoutBilledBillsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderWhereUniqueInput | null;
  update?: ProviderUpdateWithoutBilledBillsInput | null;
}

export interface ProviderUpdateOneWithoutBilledChargesNestedInput {
  create?: ProviderCreateWithoutBilledChargesInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutBilledChargesInput | null;
  upsert?: ProviderUpsertWithoutBilledChargesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderWhereUniqueInput | null;
  update?: ProviderUpdateWithoutBilledChargesInput | null;
}

export interface ProviderUpdateOneWithoutDefaultEligibilityProviderLocationsNestedInput {
  create?: ProviderCreateWithoutDefaultEligibilityProviderLocationsInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutDefaultEligibilityProviderLocationsInput | null;
  upsert?: ProviderUpsertWithoutDefaultEligibilityProviderLocationsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderWhereUniqueInput | null;
  update?: ProviderUpdateWithoutDefaultEligibilityProviderLocationsInput | null;
}

export interface ProviderUpdateOneWithoutEligibilityRequestsNestedInput {
  create?: ProviderCreateWithoutEligibilityRequestsInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutEligibilityRequestsInput | null;
  upsert?: ProviderUpsertWithoutEligibilityRequestsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderWhereUniqueInput | null;
  update?: ProviderUpdateWithoutEligibilityRequestsInput | null;
}

export interface ProviderUpdateOneWithoutIntegrationLinksNestedInput {
  create?: ProviderCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: ProviderUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderWhereUniqueInput | null;
  update?: ProviderUpdateWithoutIntegrationLinksInput | null;
}

export interface ProviderUpdateOneWithoutRenderedChargesNestedInput {
  create?: ProviderCreateWithoutRenderedChargesInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutRenderedChargesInput | null;
  upsert?: ProviderUpsertWithoutRenderedChargesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderWhereUniqueInput | null;
  update?: ProviderUpdateWithoutRenderedChargesInput | null;
}

export interface ProviderUpdateOneWithoutTempAppointmentsNestedInput {
  create?: ProviderCreateWithoutTempAppointmentsInput | null;
  connectOrCreate?: ProviderCreateOrConnectWithoutTempAppointmentsInput | null;
  upsert?: ProviderUpsertWithoutTempAppointmentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ProviderWhereUniqueInput | null;
  update?: ProviderUpdateWithoutTempAppointmentsInput | null;
}

export interface ProviderUpdateWithWhereUniqueWithoutAppointmentsInput {
  where: ProviderWhereUniqueInput;
  data: ProviderUpdateWithoutAppointmentsInput;
}

export interface ProviderUpdateWithWhereUniqueWithoutBillingGroupInput {
  where: ProviderWhereUniqueInput;
  data: ProviderUpdateWithoutBillingGroupInput;
}

export interface ProviderUpdateWithWhereUniqueWithoutOrganizationInput {
  where: ProviderWhereUniqueInput;
  data: ProviderUpdateWithoutOrganizationInput;
}

export interface ProviderUpdateWithWhereUniqueWithoutPrimaryLocationInput {
  where: ProviderWhereUniqueInput;
  data: ProviderUpdateWithoutPrimaryLocationInput;
}

export interface ProviderUpdateWithWhereUniqueWithoutProviderTaxonomyCodeInput {
  where: ProviderWhereUniqueInput;
  data: ProviderUpdateWithoutProviderTaxonomyCodeInput;
}

export interface ProviderUpdateWithWhereUniqueWithoutRenderedBillsInput {
  where: ProviderWhereUniqueInput;
  data: ProviderUpdateWithoutRenderedBillsInput;
}

export interface ProviderUpdateWithoutAppointmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutBilledBillsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutBilledChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutBillingGroupInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutDefaultEligibilityProviderLocationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutEligibilityRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutPrimaryLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutProviderNetworkParticipationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
}

export interface ProviderUpdateWithoutProviderTaxonomyCodeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutRenderedBillsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutRenderedChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  tempAppointments?: AppointmentUpdateManyWithoutProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpdateWithoutTempAppointmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  deletedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  hidden?: BoolFieldUpdateOperationsInput | null;
  type?: EnumProviderTypeFieldUpdateOperationsInput | null;
  firstName?: NullableStringFieldUpdateOperationsInput | null;
  lastName?: NullableStringFieldUpdateOperationsInput | null;
  organizationName?: NullableStringFieldUpdateOperationsInput | null;
  credentials?: NullableStringFieldUpdateOperationsInput | null;
  npi?: NullableStringFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutProvidersNestedInput | null;
  primaryLocation?: LocationUpdateOneRequiredWithoutProvidersNestedInput | null;
  billingGroup?: BillingGroupUpdateOneWithoutProvidersNestedInput | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeUpdateOneWithoutProvidersNestedInput | null;
  appointments?: AppointmentUpdateManyWithoutProvidersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutProviderNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutProviderNestedInput | null;
  billedBills?: BillUpdateManyWithoutBillingProviderNestedInput | null;
  renderedBills?: BillUpdateManyWithoutRenderingProvidersNestedInput | null;
  billedCharges?: ChargeUpdateManyWithoutBillingProviderNestedInput | null;
  renderedCharges?: ChargeUpdateManyWithoutRenderingProviderNestedInput | null;
  defaultEligibilityProviderLocations?: LocationUpdateManyWithoutDefaultEligibilityProviderNestedInput | null;
  providerNetworkParticipations?: ProviderNetworkParticipationUpdateManyWithoutProviderNestedInput | null;
}

export interface ProviderUpsertWithWhereUniqueWithoutAppointmentsInput {
  where: ProviderWhereUniqueInput;
  update: ProviderUpdateWithoutAppointmentsInput;
  create: ProviderCreateWithoutAppointmentsInput;
}

export interface ProviderUpsertWithWhereUniqueWithoutBillingGroupInput {
  where: ProviderWhereUniqueInput;
  update: ProviderUpdateWithoutBillingGroupInput;
  create: ProviderCreateWithoutBillingGroupInput;
}

export interface ProviderUpsertWithWhereUniqueWithoutOrganizationInput {
  where: ProviderWhereUniqueInput;
  update: ProviderUpdateWithoutOrganizationInput;
  create: ProviderCreateWithoutOrganizationInput;
}

export interface ProviderUpsertWithWhereUniqueWithoutPrimaryLocationInput {
  where: ProviderWhereUniqueInput;
  update: ProviderUpdateWithoutPrimaryLocationInput;
  create: ProviderCreateWithoutPrimaryLocationInput;
}

export interface ProviderUpsertWithWhereUniqueWithoutProviderTaxonomyCodeInput {
  where: ProviderWhereUniqueInput;
  update: ProviderUpdateWithoutProviderTaxonomyCodeInput;
  create: ProviderCreateWithoutProviderTaxonomyCodeInput;
}

export interface ProviderUpsertWithWhereUniqueWithoutRenderedBillsInput {
  where: ProviderWhereUniqueInput;
  update: ProviderUpdateWithoutRenderedBillsInput;
  create: ProviderCreateWithoutRenderedBillsInput;
}

export interface ProviderUpsertWithoutBilledBillsInput {
  update: ProviderUpdateWithoutBilledBillsInput;
  create: ProviderCreateWithoutBilledBillsInput;
}

export interface ProviderUpsertWithoutBilledChargesInput {
  update: ProviderUpdateWithoutBilledChargesInput;
  create: ProviderCreateWithoutBilledChargesInput;
}

export interface ProviderUpsertWithoutDefaultEligibilityProviderLocationsInput {
  update: ProviderUpdateWithoutDefaultEligibilityProviderLocationsInput;
  create: ProviderCreateWithoutDefaultEligibilityProviderLocationsInput;
}

export interface ProviderUpsertWithoutEligibilityRequestsInput {
  update: ProviderUpdateWithoutEligibilityRequestsInput;
  create: ProviderCreateWithoutEligibilityRequestsInput;
}

export interface ProviderUpsertWithoutIntegrationLinksInput {
  update: ProviderUpdateWithoutIntegrationLinksInput;
  create: ProviderCreateWithoutIntegrationLinksInput;
}

export interface ProviderUpsertWithoutProviderNetworkParticipationsInput {
  update: ProviderUpdateWithoutProviderNetworkParticipationsInput;
  create: ProviderCreateWithoutProviderNetworkParticipationsInput;
}

export interface ProviderUpsertWithoutRenderedChargesInput {
  update: ProviderUpdateWithoutRenderedChargesInput;
  create: ProviderCreateWithoutRenderedChargesInput;
}

export interface ProviderUpsertWithoutTempAppointmentsInput {
  update: ProviderUpdateWithoutTempAppointmentsInput;
  create: ProviderCreateWithoutTempAppointmentsInput;
}

export interface ProviderWhereInput {
  AND?: ProviderWhereInput[] | null;
  OR?: ProviderWhereInput[] | null;
  NOT?: ProviderWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  deletedAt?: DateTimeNullableFilter | null;
  hidden?: BoolFilter | null;
  type?: EnumProviderTypeFilter | null;
  organizationId?: UuidFilter | null;
  primaryLocationId?: UuidFilter | null;
  firstName?: StringNullableFilter | null;
  lastName?: StringNullableFilter | null;
  organizationName?: StringNullableFilter | null;
  credentials?: StringNullableFilter | null;
  npi?: StringNullableFilter | null;
  billingGroupId?: UuidNullableFilter | null;
  providerTaxonomyCodeId?: UuidNullableFilter | null;
  organization?: OrganizationRelationFilter | null;
  primaryLocation?: LocationRelationFilter | null;
  billingGroup?: BillingGroupRelationFilter | null;
  providerTaxonomyCode?: ProviderTaxonomyCodeRelationFilter | null;
  appointments?: AppointmentListRelationFilter | null;
  eligibilityRequests?: EligibilityRequestListRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
  billedBills?: BillListRelationFilter | null;
  renderedBills?: BillListRelationFilter | null;
  billedCharges?: ChargeListRelationFilter | null;
  renderedCharges?: ChargeListRelationFilter | null;
  tempAppointments?: AppointmentListRelationFilter | null;
  defaultEligibilityProviderLocations?: LocationListRelationFilter | null;
  providerNetworkParticipations?: ProviderNetworkParticipationListRelationFilter | null;
}

export interface ProviderWhereUniqueInput {
  id?: string | null;
}

export interface RawEligibilityRequestResponseCreateManyEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  requestedServiceTypeCode?: string | null;
  request?: any | null;
  response?: any | null;
  availityCoverageId?: string | null;
}

export interface RawEligibilityRequestResponseCreateManyEligibilityRequestInputEnvelope {
  data: RawEligibilityRequestResponseCreateManyEligibilityRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface RawEligibilityRequestResponseCreateNestedManyWithoutEligibilityRequestInput {
  create?: RawEligibilityRequestResponseCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: RawEligibilityRequestResponseCreateOrConnectWithoutEligibilityRequestInput[] | null;
  createMany?: RawEligibilityRequestResponseCreateManyEligibilityRequestInputEnvelope | null;
  connect?: RawEligibilityRequestResponseWhereUniqueInput[] | null;
}

export interface RawEligibilityRequestResponseCreateOrConnectWithoutEligibilityRequestInput {
  where: RawEligibilityRequestResponseWhereUniqueInput;
  create: RawEligibilityRequestResponseCreateWithoutEligibilityRequestInput;
}

export interface RawEligibilityRequestResponseCreateWithoutEligibilityRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  requestedServiceTypeCode?: string | null;
  request?: any | null;
  response?: any | null;
  availityCoverageId?: string | null;
}

export interface RawEligibilityRequestResponseListRelationFilter {
  every?: RawEligibilityRequestResponseWhereInput | null;
  some?: RawEligibilityRequestResponseWhereInput | null;
  none?: RawEligibilityRequestResponseWhereInput | null;
}

export interface RawEligibilityRequestResponseScalarWhereInput {
  AND?: RawEligibilityRequestResponseScalarWhereInput[] | null;
  OR?: RawEligibilityRequestResponseScalarWhereInput[] | null;
  NOT?: RawEligibilityRequestResponseScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  requestedServiceTypeCode?: StringNullableFilter | null;
  request?: JsonNullableFilter | null;
  response?: JsonNullableFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  availityCoverageId?: StringNullableFilter | null;
}

export interface RawEligibilityRequestResponseUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  requestedServiceTypeCode?: NullableStringFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  availityCoverageId?: NullableStringFieldUpdateOperationsInput | null;
}

export interface RawEligibilityRequestResponseUpdateManyWithWhereWithoutEligibilityRequestInput {
  where: RawEligibilityRequestResponseScalarWhereInput;
  data: RawEligibilityRequestResponseUpdateManyMutationInput;
}

export interface RawEligibilityRequestResponseUpdateManyWithoutEligibilityRequestNestedInput {
  create?: RawEligibilityRequestResponseCreateWithoutEligibilityRequestInput[] | null;
  connectOrCreate?: RawEligibilityRequestResponseCreateOrConnectWithoutEligibilityRequestInput[] | null;
  upsert?: RawEligibilityRequestResponseUpsertWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  createMany?: RawEligibilityRequestResponseCreateManyEligibilityRequestInputEnvelope | null;
  set?: RawEligibilityRequestResponseWhereUniqueInput[] | null;
  disconnect?: RawEligibilityRequestResponseWhereUniqueInput[] | null;
  delete?: RawEligibilityRequestResponseWhereUniqueInput[] | null;
  connect?: RawEligibilityRequestResponseWhereUniqueInput[] | null;
  update?: RawEligibilityRequestResponseUpdateWithWhereUniqueWithoutEligibilityRequestInput[] | null;
  updateMany?: RawEligibilityRequestResponseUpdateManyWithWhereWithoutEligibilityRequestInput[] | null;
  deleteMany?: RawEligibilityRequestResponseScalarWhereInput[] | null;
}

export interface RawEligibilityRequestResponseUpdateWithWhereUniqueWithoutEligibilityRequestInput {
  where: RawEligibilityRequestResponseWhereUniqueInput;
  data: RawEligibilityRequestResponseUpdateWithoutEligibilityRequestInput;
}

export interface RawEligibilityRequestResponseUpdateWithoutEligibilityRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  requestedServiceTypeCode?: NullableStringFieldUpdateOperationsInput | null;
  request?: any | null;
  response?: any | null;
  availityCoverageId?: NullableStringFieldUpdateOperationsInput | null;
}

export interface RawEligibilityRequestResponseUpsertWithWhereUniqueWithoutEligibilityRequestInput {
  where: RawEligibilityRequestResponseWhereUniqueInput;
  update: RawEligibilityRequestResponseUpdateWithoutEligibilityRequestInput;
  create: RawEligibilityRequestResponseCreateWithoutEligibilityRequestInput;
}

export interface RawEligibilityRequestResponseWhereInput {
  AND?: RawEligibilityRequestResponseWhereInput[] | null;
  OR?: RawEligibilityRequestResponseWhereInput[] | null;
  NOT?: RawEligibilityRequestResponseWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  requestedServiceTypeCode?: StringNullableFilter | null;
  request?: JsonNullableFilter | null;
  response?: JsonNullableFilter | null;
  eligibilityRequestId?: UuidFilter | null;
  availityCoverageId?: StringNullableFilter | null;
  eligibilityRequest?: EligibilityRequestRelationFilter | null;
}

export interface RawEligibilityRequestResponseWhereUniqueInput {
  id?: string | null;
}

export interface ReminderWorkflowCreateManyReminderWorkflowTemplateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  pausedAt?: any | null;
  startedAt?: any | null;
  lastPauseReason?: string | null;
  patientId: string;
}

export interface ReminderWorkflowCreateManyReminderWorkflowTemplateInputEnvelope {
  data: ReminderWorkflowCreateManyReminderWorkflowTemplateInput[];
  skipDuplicates?: boolean | null;
}

export interface ReminderWorkflowCreateNestedManyWithoutReminderWorkflowTemplateInput {
  create?: ReminderWorkflowCreateWithoutReminderWorkflowTemplateInput[] | null;
  connectOrCreate?: ReminderWorkflowCreateOrConnectWithoutReminderWorkflowTemplateInput[] | null;
  createMany?: ReminderWorkflowCreateManyReminderWorkflowTemplateInputEnvelope | null;
  connect?: ReminderWorkflowWhereUniqueInput[] | null;
}

export interface ReminderWorkflowCreateNestedOneWithoutPatientInput {
  create?: ReminderWorkflowCreateWithoutPatientInput | null;
  connectOrCreate?: ReminderWorkflowCreateOrConnectWithoutPatientInput | null;
  connect?: ReminderWorkflowWhereUniqueInput | null;
}

export interface ReminderWorkflowCreateOrConnectWithoutPatientInput {
  where: ReminderWorkflowWhereUniqueInput;
  create: ReminderWorkflowCreateWithoutPatientInput;
}

export interface ReminderWorkflowCreateOrConnectWithoutReminderWorkflowTemplateInput {
  where: ReminderWorkflowWhereUniqueInput;
  create: ReminderWorkflowCreateWithoutReminderWorkflowTemplateInput;
}

export interface ReminderWorkflowCreateWithoutPatientInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  pausedAt?: any | null;
  startedAt?: any | null;
  lastPauseReason?: string | null;
  reminderWorkflowTemplate: ReminderWorkflowTemplateCreateNestedOneWithoutReminderWorkflowsInput;
}

export interface ReminderWorkflowCreateWithoutReminderWorkflowTemplateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  pausedAt?: any | null;
  startedAt?: any | null;
  lastPauseReason?: string | null;
  patient: PatientCreateNestedOneWithoutReminderWorkflowInput;
}

export interface ReminderWorkflowListRelationFilter {
  every?: ReminderWorkflowWhereInput | null;
  some?: ReminderWorkflowWhereInput | null;
  none?: ReminderWorkflowWhereInput | null;
}

export interface ReminderWorkflowOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ReminderWorkflowOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  pausedAt?: SortOrderInput | null;
  startedAt?: SortOrderInput | null;
  lastPauseReason?: SortOrderInput | null;
  patientId?: SortOrder | null;
  reminderWorkflowTemplateId?: SortOrder | null;
  patient?: PatientOrderByWithRelationInput | null;
  reminderWorkflowTemplate?: ReminderWorkflowTemplateOrderByWithRelationInput | null;
}

export interface ReminderWorkflowRelationFilter {
  is?: ReminderWorkflowWhereInput | null;
  isNot?: ReminderWorkflowWhereInput | null;
}

export interface ReminderWorkflowScalarWhereInput {
  AND?: ReminderWorkflowScalarWhereInput[] | null;
  OR?: ReminderWorkflowScalarWhereInput[] | null;
  NOT?: ReminderWorkflowScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  pausedAt?: DateTimeNullableFilter | null;
  startedAt?: DateTimeNullableFilter | null;
  lastPauseReason?: StringNullableFilter | null;
  patientId?: UuidFilter | null;
  reminderWorkflowTemplateId?: UuidFilter | null;
}

export interface ReminderWorkflowTemplateCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dueDays: number;
  twentySixDaysBeforeReminder?: boolean | null;
  sixteenDaysBeforeReminder?: boolean | null;
  sevenDaysBeforeReminder?: boolean | null;
  fifteenDaysAfterReminder?: boolean | null;
  thirtyDaysAfterReminder?: boolean | null;
  fortyFiveDaysAfterReminder?: boolean | null;
  sixtyDaysAfterReminder?: boolean | null;
  ninetyDaysAfterReminder?: boolean | null;
}

export interface ReminderWorkflowTemplateCreateManyOrganizationInputEnvelope {
  data: ReminderWorkflowTemplateCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface ReminderWorkflowTemplateCreateNestedManyWithoutOrganizationInput {
  create?: ReminderWorkflowTemplateCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: ReminderWorkflowTemplateCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: ReminderWorkflowTemplateCreateManyOrganizationInputEnvelope | null;
  connect?: ReminderWorkflowTemplateWhereUniqueInput[] | null;
}

export interface ReminderWorkflowTemplateCreateNestedOneWithoutReminderWorkflowsInput {
  create?: ReminderWorkflowTemplateCreateWithoutReminderWorkflowsInput | null;
  connectOrCreate?: ReminderWorkflowTemplateCreateOrConnectWithoutReminderWorkflowsInput | null;
  connect?: ReminderWorkflowTemplateWhereUniqueInput | null;
}

export interface ReminderWorkflowTemplateCreateOrConnectWithoutOrganizationInput {
  where: ReminderWorkflowTemplateWhereUniqueInput;
  create: ReminderWorkflowTemplateCreateWithoutOrganizationInput;
}

export interface ReminderWorkflowTemplateCreateOrConnectWithoutReminderWorkflowsInput {
  where: ReminderWorkflowTemplateWhereUniqueInput;
  create: ReminderWorkflowTemplateCreateWithoutReminderWorkflowsInput;
}

export interface ReminderWorkflowTemplateCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dueDays: number;
  twentySixDaysBeforeReminder?: boolean | null;
  sixteenDaysBeforeReminder?: boolean | null;
  sevenDaysBeforeReminder?: boolean | null;
  fifteenDaysAfterReminder?: boolean | null;
  thirtyDaysAfterReminder?: boolean | null;
  fortyFiveDaysAfterReminder?: boolean | null;
  sixtyDaysAfterReminder?: boolean | null;
  ninetyDaysAfterReminder?: boolean | null;
  reminderWorkflows?: ReminderWorkflowCreateNestedManyWithoutReminderWorkflowTemplateInput | null;
}

export interface ReminderWorkflowTemplateCreateWithoutReminderWorkflowsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  dueDays: number;
  twentySixDaysBeforeReminder?: boolean | null;
  sixteenDaysBeforeReminder?: boolean | null;
  sevenDaysBeforeReminder?: boolean | null;
  fifteenDaysAfterReminder?: boolean | null;
  thirtyDaysAfterReminder?: boolean | null;
  fortyFiveDaysAfterReminder?: boolean | null;
  sixtyDaysAfterReminder?: boolean | null;
  ninetyDaysAfterReminder?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutReminderWorkflowTemplatesInput;
}

export interface ReminderWorkflowTemplateListRelationFilter {
  every?: ReminderWorkflowTemplateWhereInput | null;
  some?: ReminderWorkflowTemplateWhereInput | null;
  none?: ReminderWorkflowTemplateWhereInput | null;
}

export interface ReminderWorkflowTemplateOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ReminderWorkflowTemplateOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  dueDays?: SortOrder | null;
  twentySixDaysBeforeReminder?: SortOrder | null;
  sixteenDaysBeforeReminder?: SortOrder | null;
  sevenDaysBeforeReminder?: SortOrder | null;
  fifteenDaysAfterReminder?: SortOrder | null;
  thirtyDaysAfterReminder?: SortOrder | null;
  fortyFiveDaysAfterReminder?: SortOrder | null;
  sixtyDaysAfterReminder?: SortOrder | null;
  ninetyDaysAfterReminder?: SortOrder | null;
  organizationId?: SortOrder | null;
  reminderWorkflows?: ReminderWorkflowOrderByRelationAggregateInput | null;
  organization?: OrganizationOrderByWithRelationInput | null;
}

export interface ReminderWorkflowTemplateRelationFilter {
  is?: ReminderWorkflowTemplateWhereInput | null;
  isNot?: ReminderWorkflowTemplateWhereInput | null;
}

export interface ReminderWorkflowTemplateScalarWhereInput {
  AND?: ReminderWorkflowTemplateScalarWhereInput[] | null;
  OR?: ReminderWorkflowTemplateScalarWhereInput[] | null;
  NOT?: ReminderWorkflowTemplateScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  dueDays?: IntFilter | null;
  twentySixDaysBeforeReminder?: BoolFilter | null;
  sixteenDaysBeforeReminder?: BoolFilter | null;
  sevenDaysBeforeReminder?: BoolFilter | null;
  fifteenDaysAfterReminder?: BoolFilter | null;
  thirtyDaysAfterReminder?: BoolFilter | null;
  fortyFiveDaysAfterReminder?: BoolFilter | null;
  sixtyDaysAfterReminder?: BoolFilter | null;
  ninetyDaysAfterReminder?: BoolFilter | null;
  organizationId?: UuidFilter | null;
}

export interface ReminderWorkflowTemplateUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dueDays?: IntFieldUpdateOperationsInput | null;
  twentySixDaysBeforeReminder?: BoolFieldUpdateOperationsInput | null;
  sixteenDaysBeforeReminder?: BoolFieldUpdateOperationsInput | null;
  sevenDaysBeforeReminder?: BoolFieldUpdateOperationsInput | null;
  fifteenDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  thirtyDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  fortyFiveDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  sixtyDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  ninetyDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
}

export interface ReminderWorkflowTemplateUpdateManyWithWhereWithoutOrganizationInput {
  where: ReminderWorkflowTemplateScalarWhereInput;
  data: ReminderWorkflowTemplateUpdateManyMutationInput;
}

export interface ReminderWorkflowTemplateUpdateManyWithoutOrganizationNestedInput {
  create?: ReminderWorkflowTemplateCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: ReminderWorkflowTemplateCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: ReminderWorkflowTemplateUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: ReminderWorkflowTemplateCreateManyOrganizationInputEnvelope | null;
  set?: ReminderWorkflowTemplateWhereUniqueInput[] | null;
  disconnect?: ReminderWorkflowTemplateWhereUniqueInput[] | null;
  delete?: ReminderWorkflowTemplateWhereUniqueInput[] | null;
  connect?: ReminderWorkflowTemplateWhereUniqueInput[] | null;
  update?: ReminderWorkflowTemplateUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: ReminderWorkflowTemplateUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: ReminderWorkflowTemplateScalarWhereInput[] | null;
}

export interface ReminderWorkflowTemplateUpdateOneRequiredWithoutReminderWorkflowsNestedInput {
  create?: ReminderWorkflowTemplateCreateWithoutReminderWorkflowsInput | null;
  connectOrCreate?: ReminderWorkflowTemplateCreateOrConnectWithoutReminderWorkflowsInput | null;
  upsert?: ReminderWorkflowTemplateUpsertWithoutReminderWorkflowsInput | null;
  connect?: ReminderWorkflowTemplateWhereUniqueInput | null;
  update?: ReminderWorkflowTemplateUpdateWithoutReminderWorkflowsInput | null;
}

export interface ReminderWorkflowTemplateUpdateWithWhereUniqueWithoutOrganizationInput {
  where: ReminderWorkflowTemplateWhereUniqueInput;
  data: ReminderWorkflowTemplateUpdateWithoutOrganizationInput;
}

export interface ReminderWorkflowTemplateUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dueDays?: IntFieldUpdateOperationsInput | null;
  twentySixDaysBeforeReminder?: BoolFieldUpdateOperationsInput | null;
  sixteenDaysBeforeReminder?: BoolFieldUpdateOperationsInput | null;
  sevenDaysBeforeReminder?: BoolFieldUpdateOperationsInput | null;
  fifteenDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  thirtyDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  fortyFiveDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  sixtyDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  ninetyDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  reminderWorkflows?: ReminderWorkflowUpdateManyWithoutReminderWorkflowTemplateNestedInput | null;
}

export interface ReminderWorkflowTemplateUpdateWithoutReminderWorkflowsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  dueDays?: IntFieldUpdateOperationsInput | null;
  twentySixDaysBeforeReminder?: BoolFieldUpdateOperationsInput | null;
  sixteenDaysBeforeReminder?: BoolFieldUpdateOperationsInput | null;
  sevenDaysBeforeReminder?: BoolFieldUpdateOperationsInput | null;
  fifteenDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  thirtyDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  fortyFiveDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  sixtyDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  ninetyDaysAfterReminder?: BoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutReminderWorkflowTemplatesNestedInput | null;
}

export interface ReminderWorkflowTemplateUpsertWithWhereUniqueWithoutOrganizationInput {
  where: ReminderWorkflowTemplateWhereUniqueInput;
  update: ReminderWorkflowTemplateUpdateWithoutOrganizationInput;
  create: ReminderWorkflowTemplateCreateWithoutOrganizationInput;
}

export interface ReminderWorkflowTemplateUpsertWithoutReminderWorkflowsInput {
  update: ReminderWorkflowTemplateUpdateWithoutReminderWorkflowsInput;
  create: ReminderWorkflowTemplateCreateWithoutReminderWorkflowsInput;
}

export interface ReminderWorkflowTemplateWhereInput {
  AND?: ReminderWorkflowTemplateWhereInput[] | null;
  OR?: ReminderWorkflowTemplateWhereInput[] | null;
  NOT?: ReminderWorkflowTemplateWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  dueDays?: IntFilter | null;
  twentySixDaysBeforeReminder?: BoolFilter | null;
  sixteenDaysBeforeReminder?: BoolFilter | null;
  sevenDaysBeforeReminder?: BoolFilter | null;
  fifteenDaysAfterReminder?: BoolFilter | null;
  thirtyDaysAfterReminder?: BoolFilter | null;
  fortyFiveDaysAfterReminder?: BoolFilter | null;
  sixtyDaysAfterReminder?: BoolFilter | null;
  ninetyDaysAfterReminder?: BoolFilter | null;
  organizationId?: UuidFilter | null;
  reminderWorkflows?: ReminderWorkflowListRelationFilter | null;
  organization?: OrganizationRelationFilter | null;
}

export interface ReminderWorkflowTemplateWhereUniqueInput {
  id?: string | null;
}

export interface ReminderWorkflowUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  pausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastPauseReason?: NullableStringFieldUpdateOperationsInput | null;
}

export interface ReminderWorkflowUpdateManyWithWhereWithoutReminderWorkflowTemplateInput {
  where: ReminderWorkflowScalarWhereInput;
  data: ReminderWorkflowUpdateManyMutationInput;
}

export interface ReminderWorkflowUpdateManyWithoutReminderWorkflowTemplateNestedInput {
  create?: ReminderWorkflowCreateWithoutReminderWorkflowTemplateInput[] | null;
  connectOrCreate?: ReminderWorkflowCreateOrConnectWithoutReminderWorkflowTemplateInput[] | null;
  upsert?: ReminderWorkflowUpsertWithWhereUniqueWithoutReminderWorkflowTemplateInput[] | null;
  createMany?: ReminderWorkflowCreateManyReminderWorkflowTemplateInputEnvelope | null;
  set?: ReminderWorkflowWhereUniqueInput[] | null;
  disconnect?: ReminderWorkflowWhereUniqueInput[] | null;
  delete?: ReminderWorkflowWhereUniqueInput[] | null;
  connect?: ReminderWorkflowWhereUniqueInput[] | null;
  update?: ReminderWorkflowUpdateWithWhereUniqueWithoutReminderWorkflowTemplateInput[] | null;
  updateMany?: ReminderWorkflowUpdateManyWithWhereWithoutReminderWorkflowTemplateInput[] | null;
  deleteMany?: ReminderWorkflowScalarWhereInput[] | null;
}

export interface ReminderWorkflowUpdateOneWithoutPatientNestedInput {
  create?: ReminderWorkflowCreateWithoutPatientInput | null;
  connectOrCreate?: ReminderWorkflowCreateOrConnectWithoutPatientInput | null;
  upsert?: ReminderWorkflowUpsertWithoutPatientInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ReminderWorkflowWhereUniqueInput | null;
  update?: ReminderWorkflowUpdateWithoutPatientInput | null;
}

export interface ReminderWorkflowUpdateWithWhereUniqueWithoutReminderWorkflowTemplateInput {
  where: ReminderWorkflowWhereUniqueInput;
  data: ReminderWorkflowUpdateWithoutReminderWorkflowTemplateInput;
}

export interface ReminderWorkflowUpdateWithoutPatientInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  pausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastPauseReason?: NullableStringFieldUpdateOperationsInput | null;
  reminderWorkflowTemplate?: ReminderWorkflowTemplateUpdateOneRequiredWithoutReminderWorkflowsNestedInput | null;
}

export interface ReminderWorkflowUpdateWithoutReminderWorkflowTemplateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  pausedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  startedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  lastPauseReason?: NullableStringFieldUpdateOperationsInput | null;
  patient?: PatientUpdateOneRequiredWithoutReminderWorkflowNestedInput | null;
}

export interface ReminderWorkflowUpsertWithWhereUniqueWithoutReminderWorkflowTemplateInput {
  where: ReminderWorkflowWhereUniqueInput;
  update: ReminderWorkflowUpdateWithoutReminderWorkflowTemplateInput;
  create: ReminderWorkflowCreateWithoutReminderWorkflowTemplateInput;
}

export interface ReminderWorkflowUpsertWithoutPatientInput {
  update: ReminderWorkflowUpdateWithoutPatientInput;
  create: ReminderWorkflowCreateWithoutPatientInput;
}

export interface ReminderWorkflowWhereInput {
  AND?: ReminderWorkflowWhereInput[] | null;
  OR?: ReminderWorkflowWhereInput[] | null;
  NOT?: ReminderWorkflowWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  pausedAt?: DateTimeNullableFilter | null;
  startedAt?: DateTimeNullableFilter | null;
  lastPauseReason?: StringNullableFilter | null;
  patientId?: UuidFilter | null;
  reminderWorkflowTemplateId?: UuidFilter | null;
  patient?: PatientRelationFilter | null;
  reminderWorkflowTemplate?: ReminderWorkflowTemplateRelationFilter | null;
}

export interface ReminderWorkflowWhereUniqueInput {
  id?: string | null;
  patientId?: string | null;
}

export interface RequestEligibilityWithParamsInput {
  synchronous?: boolean | null;
  insurancePolicyId: string;
  tradingPartnerId: string;
  memberId: string;
  serviceTypes: string[];
  patient: RequestEligibilityWithParamsInputPatient;
  provider: RequestEligibilityWithParamsInputProvider;
}

export interface RequestEligibilityWithParamsInputPatient {
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth: any;
}

export interface RequestEligibilityWithParamsInputProvider {
  type: ProviderType;
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  npi: string;
}

export interface ScheduledServiceFeeCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  allowedAmount: number;
  name?: string | null;
  feeSchedule: FeeScheduleCreateNestedOneWithoutScheduledServiceFeesInput;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutScheduledServiceFeeInput | null;
  chargemasterGroup: ChargemasterGroupCreateNestedOneWithoutScheduledServiceFeesInput;
}

export interface ScheduledServiceFeeCreateManyChargemasterGroupInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  feeScheduleId: string;
  allowedAmount: number;
  name?: string | null;
}

export interface ScheduledServiceFeeCreateManyChargemasterGroupInputEnvelope {
  data: ScheduledServiceFeeCreateManyChargemasterGroupInput[];
  skipDuplicates?: boolean | null;
}

export interface ScheduledServiceFeeCreateManyFeeScheduleInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  allowedAmount: number;
  name?: string | null;
  chargemasterGroupId: string;
}

export interface ScheduledServiceFeeCreateManyFeeScheduleInputEnvelope {
  data: ScheduledServiceFeeCreateManyFeeScheduleInput[];
  skipDuplicates?: boolean | null;
}

export interface ScheduledServiceFeeCreateNestedManyWithoutChargemasterGroupInput {
  create?: ScheduledServiceFeeCreateWithoutChargemasterGroupInput[] | null;
  connectOrCreate?: ScheduledServiceFeeCreateOrConnectWithoutChargemasterGroupInput[] | null;
  createMany?: ScheduledServiceFeeCreateManyChargemasterGroupInputEnvelope | null;
  connect?: ScheduledServiceFeeWhereUniqueInput[] | null;
}

export interface ScheduledServiceFeeCreateNestedManyWithoutFeeScheduleInput {
  create?: ScheduledServiceFeeCreateWithoutFeeScheduleInput[] | null;
  connectOrCreate?: ScheduledServiceFeeCreateOrConnectWithoutFeeScheduleInput[] | null;
  createMany?: ScheduledServiceFeeCreateManyFeeScheduleInputEnvelope | null;
  connect?: ScheduledServiceFeeWhereUniqueInput[] | null;
}

export interface ScheduledServiceFeeCreateNestedOneWithoutEstimatedChargesInput {
  create?: ScheduledServiceFeeCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: ScheduledServiceFeeCreateOrConnectWithoutEstimatedChargesInput | null;
  connect?: ScheduledServiceFeeWhereUniqueInput | null;
}

export interface ScheduledServiceFeeCreateOrConnectWithoutChargemasterGroupInput {
  where: ScheduledServiceFeeWhereUniqueInput;
  create: ScheduledServiceFeeCreateWithoutChargemasterGroupInput;
}

export interface ScheduledServiceFeeCreateOrConnectWithoutEstimatedChargesInput {
  where: ScheduledServiceFeeWhereUniqueInput;
  create: ScheduledServiceFeeCreateWithoutEstimatedChargesInput;
}

export interface ScheduledServiceFeeCreateOrConnectWithoutFeeScheduleInput {
  where: ScheduledServiceFeeWhereUniqueInput;
  create: ScheduledServiceFeeCreateWithoutFeeScheduleInput;
}

export interface ScheduledServiceFeeCreateWithoutChargemasterGroupInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  allowedAmount: number;
  name?: string | null;
  feeSchedule: FeeScheduleCreateNestedOneWithoutScheduledServiceFeesInput;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutScheduledServiceFeeInput | null;
}

export interface ScheduledServiceFeeCreateWithoutEstimatedChargesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  allowedAmount: number;
  name?: string | null;
  feeSchedule: FeeScheduleCreateNestedOneWithoutScheduledServiceFeesInput;
  chargemasterGroup: ChargemasterGroupCreateNestedOneWithoutScheduledServiceFeesInput;
}

export interface ScheduledServiceFeeCreateWithoutFeeScheduleInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  allowedAmount: number;
  name?: string | null;
  estimatedCharges?: EstimatedChargeCreateNestedManyWithoutScheduledServiceFeeInput | null;
  chargemasterGroup: ChargemasterGroupCreateNestedOneWithoutScheduledServiceFeesInput;
}

export interface ScheduledServiceFeeFeeScheduleIdChargemasterGroupIdCompoundUniqueInput {
  feeScheduleId: string;
  chargemasterGroupId: string;
}

export interface ScheduledServiceFeeListRelationFilter {
  every?: ScheduledServiceFeeWhereInput | null;
  some?: ScheduledServiceFeeWhereInput | null;
  none?: ScheduledServiceFeeWhereInput | null;
}

export interface ScheduledServiceFeeRelationFilter {
  is?: ScheduledServiceFeeWhereInput | null;
  isNot?: ScheduledServiceFeeWhereInput | null;
}

export interface ScheduledServiceFeeScalarWhereInput {
  AND?: ScheduledServiceFeeScalarWhereInput[] | null;
  OR?: ScheduledServiceFeeScalarWhereInput[] | null;
  NOT?: ScheduledServiceFeeScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  feeScheduleId?: UuidFilter | null;
  allowedAmount?: IntFilter | null;
  name?: StringNullableFilter | null;
  chargemasterGroupId?: UuidFilter | null;
}

export interface ScheduledServiceFeeUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  feeSchedule?: FeeScheduleUpdateOneRequiredWithoutScheduledServiceFeesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutScheduledServiceFeeNestedInput | null;
  chargemasterGroup?: ChargemasterGroupUpdateOneRequiredWithoutScheduledServiceFeesNestedInput | null;
}

export interface ScheduledServiceFeeUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
}

export interface ScheduledServiceFeeUpdateManyWithWhereWithoutChargemasterGroupInput {
  where: ScheduledServiceFeeScalarWhereInput;
  data: ScheduledServiceFeeUpdateManyMutationInput;
}

export interface ScheduledServiceFeeUpdateManyWithWhereWithoutFeeScheduleInput {
  where: ScheduledServiceFeeScalarWhereInput;
  data: ScheduledServiceFeeUpdateManyMutationInput;
}

export interface ScheduledServiceFeeUpdateManyWithoutChargemasterGroupNestedInput {
  create?: ScheduledServiceFeeCreateWithoutChargemasterGroupInput[] | null;
  connectOrCreate?: ScheduledServiceFeeCreateOrConnectWithoutChargemasterGroupInput[] | null;
  upsert?: ScheduledServiceFeeUpsertWithWhereUniqueWithoutChargemasterGroupInput[] | null;
  createMany?: ScheduledServiceFeeCreateManyChargemasterGroupInputEnvelope | null;
  set?: ScheduledServiceFeeWhereUniqueInput[] | null;
  disconnect?: ScheduledServiceFeeWhereUniqueInput[] | null;
  delete?: ScheduledServiceFeeWhereUniqueInput[] | null;
  connect?: ScheduledServiceFeeWhereUniqueInput[] | null;
  update?: ScheduledServiceFeeUpdateWithWhereUniqueWithoutChargemasterGroupInput[] | null;
  updateMany?: ScheduledServiceFeeUpdateManyWithWhereWithoutChargemasterGroupInput[] | null;
  deleteMany?: ScheduledServiceFeeScalarWhereInput[] | null;
}

export interface ScheduledServiceFeeUpdateManyWithoutFeeScheduleNestedInput {
  create?: ScheduledServiceFeeCreateWithoutFeeScheduleInput[] | null;
  connectOrCreate?: ScheduledServiceFeeCreateOrConnectWithoutFeeScheduleInput[] | null;
  upsert?: ScheduledServiceFeeUpsertWithWhereUniqueWithoutFeeScheduleInput[] | null;
  createMany?: ScheduledServiceFeeCreateManyFeeScheduleInputEnvelope | null;
  set?: ScheduledServiceFeeWhereUniqueInput[] | null;
  disconnect?: ScheduledServiceFeeWhereUniqueInput[] | null;
  delete?: ScheduledServiceFeeWhereUniqueInput[] | null;
  connect?: ScheduledServiceFeeWhereUniqueInput[] | null;
  update?: ScheduledServiceFeeUpdateWithWhereUniqueWithoutFeeScheduleInput[] | null;
  updateMany?: ScheduledServiceFeeUpdateManyWithWhereWithoutFeeScheduleInput[] | null;
  deleteMany?: ScheduledServiceFeeScalarWhereInput[] | null;
}

export interface ScheduledServiceFeeUpdateOneWithoutEstimatedChargesNestedInput {
  create?: ScheduledServiceFeeCreateWithoutEstimatedChargesInput | null;
  connectOrCreate?: ScheduledServiceFeeCreateOrConnectWithoutEstimatedChargesInput | null;
  upsert?: ScheduledServiceFeeUpsertWithoutEstimatedChargesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: ScheduledServiceFeeWhereUniqueInput | null;
  update?: ScheduledServiceFeeUpdateWithoutEstimatedChargesInput | null;
}

export interface ScheduledServiceFeeUpdateWithWhereUniqueWithoutChargemasterGroupInput {
  where: ScheduledServiceFeeWhereUniqueInput;
  data: ScheduledServiceFeeUpdateWithoutChargemasterGroupInput;
}

export interface ScheduledServiceFeeUpdateWithWhereUniqueWithoutFeeScheduleInput {
  where: ScheduledServiceFeeWhereUniqueInput;
  data: ScheduledServiceFeeUpdateWithoutFeeScheduleInput;
}

export interface ScheduledServiceFeeUpdateWithoutChargemasterGroupInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  feeSchedule?: FeeScheduleUpdateOneRequiredWithoutScheduledServiceFeesNestedInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutScheduledServiceFeeNestedInput | null;
}

export interface ScheduledServiceFeeUpdateWithoutEstimatedChargesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  feeSchedule?: FeeScheduleUpdateOneRequiredWithoutScheduledServiceFeesNestedInput | null;
  chargemasterGroup?: ChargemasterGroupUpdateOneRequiredWithoutScheduledServiceFeesNestedInput | null;
}

export interface ScheduledServiceFeeUpdateWithoutFeeScheduleInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  allowedAmount?: IntFieldUpdateOperationsInput | null;
  name?: NullableStringFieldUpdateOperationsInput | null;
  estimatedCharges?: EstimatedChargeUpdateManyWithoutScheduledServiceFeeNestedInput | null;
  chargemasterGroup?: ChargemasterGroupUpdateOneRequiredWithoutScheduledServiceFeesNestedInput | null;
}

export interface ScheduledServiceFeeUpsertWithWhereUniqueWithoutChargemasterGroupInput {
  where: ScheduledServiceFeeWhereUniqueInput;
  update: ScheduledServiceFeeUpdateWithoutChargemasterGroupInput;
  create: ScheduledServiceFeeCreateWithoutChargemasterGroupInput;
}

export interface ScheduledServiceFeeUpsertWithWhereUniqueWithoutFeeScheduleInput {
  where: ScheduledServiceFeeWhereUniqueInput;
  update: ScheduledServiceFeeUpdateWithoutFeeScheduleInput;
  create: ScheduledServiceFeeCreateWithoutFeeScheduleInput;
}

export interface ScheduledServiceFeeUpsertWithoutEstimatedChargesInput {
  update: ScheduledServiceFeeUpdateWithoutEstimatedChargesInput;
  create: ScheduledServiceFeeCreateWithoutEstimatedChargesInput;
}

export interface ScheduledServiceFeeWhereInput {
  AND?: ScheduledServiceFeeWhereInput[] | null;
  OR?: ScheduledServiceFeeWhereInput[] | null;
  NOT?: ScheduledServiceFeeWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  feeScheduleId?: UuidFilter | null;
  allowedAmount?: IntFilter | null;
  name?: StringNullableFilter | null;
  chargemasterGroupId?: UuidFilter | null;
  feeSchedule?: FeeScheduleRelationFilter | null;
  estimatedCharges?: EstimatedChargeListRelationFilter | null;
  chargemasterGroup?: ChargemasterGroupRelationFilter | null;
}

export interface ScheduledServiceFeeWhereUniqueInput {
  id?: string | null;
  feeScheduleId_chargemasterGroupId?: ScheduledServiceFeeFeeScheduleIdChargemasterGroupIdCompoundUniqueInput | null;
}

export interface ShortLinkCreateManyCommunicationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  url: string;
  slug: string;
  clicked: number;
  organizationId: string;
}

export interface ShortLinkCreateManyCommunicationInputEnvelope {
  data: ShortLinkCreateManyCommunicationInput[];
  skipDuplicates?: boolean | null;
}

export interface ShortLinkCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  url: string;
  slug: string;
  clicked: number;
  communicationId?: string | null;
}

export interface ShortLinkCreateManyOrganizationInputEnvelope {
  data: ShortLinkCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface ShortLinkCreateNestedManyWithoutCommunicationInput {
  create?: ShortLinkCreateWithoutCommunicationInput[] | null;
  connectOrCreate?: ShortLinkCreateOrConnectWithoutCommunicationInput[] | null;
  createMany?: ShortLinkCreateManyCommunicationInputEnvelope | null;
  connect?: ShortLinkWhereUniqueInput[] | null;
}

export interface ShortLinkCreateNestedManyWithoutOrganizationInput {
  create?: ShortLinkCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: ShortLinkCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: ShortLinkCreateManyOrganizationInputEnvelope | null;
  connect?: ShortLinkWhereUniqueInput[] | null;
}

export interface ShortLinkCreateOrConnectWithoutCommunicationInput {
  where: ShortLinkWhereUniqueInput;
  create: ShortLinkCreateWithoutCommunicationInput;
}

export interface ShortLinkCreateOrConnectWithoutOrganizationInput {
  where: ShortLinkWhereUniqueInput;
  create: ShortLinkCreateWithoutOrganizationInput;
}

export interface ShortLinkCreateWithoutCommunicationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  url: string;
  slug: string;
  clicked: number;
  organization: OrganizationCreateNestedOneWithoutShortLinksInput;
}

export interface ShortLinkCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  url: string;
  slug: string;
  clicked: number;
  communication?: CommunicationCreateNestedOneWithoutShortLinksInput | null;
}

export interface ShortLinkListRelationFilter {
  every?: ShortLinkWhereInput | null;
  some?: ShortLinkWhereInput | null;
  none?: ShortLinkWhereInput | null;
}

export interface ShortLinkOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface ShortLinkScalarWhereInput {
  AND?: ShortLinkScalarWhereInput[] | null;
  OR?: ShortLinkScalarWhereInput[] | null;
  NOT?: ShortLinkScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  url?: StringFilter | null;
  slug?: StringFilter | null;
  clicked?: IntFilter | null;
  organizationId?: UuidFilter | null;
  communicationId?: UuidNullableFilter | null;
}

export interface ShortLinkUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  url?: StringFieldUpdateOperationsInput | null;
  slug?: StringFieldUpdateOperationsInput | null;
  clicked?: IntFieldUpdateOperationsInput | null;
}

export interface ShortLinkUpdateManyWithWhereWithoutCommunicationInput {
  where: ShortLinkScalarWhereInput;
  data: ShortLinkUpdateManyMutationInput;
}

export interface ShortLinkUpdateManyWithWhereWithoutOrganizationInput {
  where: ShortLinkScalarWhereInput;
  data: ShortLinkUpdateManyMutationInput;
}

export interface ShortLinkUpdateManyWithoutCommunicationNestedInput {
  create?: ShortLinkCreateWithoutCommunicationInput[] | null;
  connectOrCreate?: ShortLinkCreateOrConnectWithoutCommunicationInput[] | null;
  upsert?: ShortLinkUpsertWithWhereUniqueWithoutCommunicationInput[] | null;
  createMany?: ShortLinkCreateManyCommunicationInputEnvelope | null;
  set?: ShortLinkWhereUniqueInput[] | null;
  disconnect?: ShortLinkWhereUniqueInput[] | null;
  delete?: ShortLinkWhereUniqueInput[] | null;
  connect?: ShortLinkWhereUniqueInput[] | null;
  update?: ShortLinkUpdateWithWhereUniqueWithoutCommunicationInput[] | null;
  updateMany?: ShortLinkUpdateManyWithWhereWithoutCommunicationInput[] | null;
  deleteMany?: ShortLinkScalarWhereInput[] | null;
}

export interface ShortLinkUpdateManyWithoutOrganizationNestedInput {
  create?: ShortLinkCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: ShortLinkCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: ShortLinkUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: ShortLinkCreateManyOrganizationInputEnvelope | null;
  set?: ShortLinkWhereUniqueInput[] | null;
  disconnect?: ShortLinkWhereUniqueInput[] | null;
  delete?: ShortLinkWhereUniqueInput[] | null;
  connect?: ShortLinkWhereUniqueInput[] | null;
  update?: ShortLinkUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: ShortLinkUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: ShortLinkScalarWhereInput[] | null;
}

export interface ShortLinkUpdateWithWhereUniqueWithoutCommunicationInput {
  where: ShortLinkWhereUniqueInput;
  data: ShortLinkUpdateWithoutCommunicationInput;
}

export interface ShortLinkUpdateWithWhereUniqueWithoutOrganizationInput {
  where: ShortLinkWhereUniqueInput;
  data: ShortLinkUpdateWithoutOrganizationInput;
}

export interface ShortLinkUpdateWithoutCommunicationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  url?: StringFieldUpdateOperationsInput | null;
  slug?: StringFieldUpdateOperationsInput | null;
  clicked?: IntFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutShortLinksNestedInput | null;
}

export interface ShortLinkUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  url?: StringFieldUpdateOperationsInput | null;
  slug?: StringFieldUpdateOperationsInput | null;
  clicked?: IntFieldUpdateOperationsInput | null;
  communication?: CommunicationUpdateOneWithoutShortLinksNestedInput | null;
}

export interface ShortLinkUpsertWithWhereUniqueWithoutCommunicationInput {
  where: ShortLinkWhereUniqueInput;
  update: ShortLinkUpdateWithoutCommunicationInput;
  create: ShortLinkCreateWithoutCommunicationInput;
}

export interface ShortLinkUpsertWithWhereUniqueWithoutOrganizationInput {
  where: ShortLinkWhereUniqueInput;
  update: ShortLinkUpdateWithoutOrganizationInput;
  create: ShortLinkCreateWithoutOrganizationInput;
}

export interface ShortLinkWhereInput {
  AND?: ShortLinkWhereInput[] | null;
  OR?: ShortLinkWhereInput[] | null;
  NOT?: ShortLinkWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  url?: StringFilter | null;
  slug?: StringFilter | null;
  clicked?: IntFilter | null;
  organizationId?: UuidFilter | null;
  communicationId?: UuidNullableFilter | null;
  organization?: OrganizationRelationFilter | null;
  communication?: CommunicationRelationFilter | null;
}

export interface ShortLinkWhereUniqueInput {
  id?: string | null;
  slug?: string | null;
}

export interface SortOrderInput {
  sort: SortOrder;
  nulls?: NullsOrder | null;
}

export interface StringFieldUpdateOperationsInput {
  set?: string | null;
}

export interface StringFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringFilter | null;
}

export interface StringNullableFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringNullableFilter | null;
}

export interface StringNullableListFilter {
  equals?: string[] | null;
  has?: string | null;
  hasEvery?: string[] | null;
  hasSome?: string[] | null;
  isEmpty?: boolean | null;
}

export interface StripeConnectedAccountCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  stripeAccountId: string;
  accountType: StripeConnectAccountType;
  default?: boolean | null;
}

export interface StripeConnectedAccountCreateManyOrganizationInputEnvelope {
  data: StripeConnectedAccountCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface StripeConnectedAccountCreateNestedManyWithoutOrganizationInput {
  create?: StripeConnectedAccountCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: StripeConnectedAccountCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: StripeConnectedAccountCreateManyOrganizationInputEnvelope | null;
  connect?: StripeConnectedAccountWhereUniqueInput[] | null;
}

export interface StripeConnectedAccountCreateNestedOneWithoutBillingGroupsInput {
  create?: StripeConnectedAccountCreateWithoutBillingGroupsInput | null;
  connectOrCreate?: StripeConnectedAccountCreateOrConnectWithoutBillingGroupsInput | null;
  connect?: StripeConnectedAccountWhereUniqueInput | null;
}

export interface StripeConnectedAccountCreateNestedOneWithoutPaymentsInput {
  create?: StripeConnectedAccountCreateWithoutPaymentsInput | null;
  connectOrCreate?: StripeConnectedAccountCreateOrConnectWithoutPaymentsInput | null;
  connect?: StripeConnectedAccountWhereUniqueInput | null;
}

export interface StripeConnectedAccountCreateNestedOneWithoutStripeFeesInput {
  create?: StripeConnectedAccountCreateWithoutStripeFeesInput | null;
  connectOrCreate?: StripeConnectedAccountCreateOrConnectWithoutStripeFeesInput | null;
  connect?: StripeConnectedAccountWhereUniqueInput | null;
}

export interface StripeConnectedAccountCreateNestedOneWithoutStripeTransfersInput {
  create?: StripeConnectedAccountCreateWithoutStripeTransfersInput | null;
  connectOrCreate?: StripeConnectedAccountCreateOrConnectWithoutStripeTransfersInput | null;
  connect?: StripeConnectedAccountWhereUniqueInput | null;
}

export interface StripeConnectedAccountCreateOrConnectWithoutBillingGroupsInput {
  where: StripeConnectedAccountWhereUniqueInput;
  create: StripeConnectedAccountCreateWithoutBillingGroupsInput;
}

export interface StripeConnectedAccountCreateOrConnectWithoutOrganizationInput {
  where: StripeConnectedAccountWhereUniqueInput;
  create: StripeConnectedAccountCreateWithoutOrganizationInput;
}

export interface StripeConnectedAccountCreateOrConnectWithoutPaymentsInput {
  where: StripeConnectedAccountWhereUniqueInput;
  create: StripeConnectedAccountCreateWithoutPaymentsInput;
}

export interface StripeConnectedAccountCreateOrConnectWithoutStripeFeesInput {
  where: StripeConnectedAccountWhereUniqueInput;
  create: StripeConnectedAccountCreateWithoutStripeFeesInput;
}

export interface StripeConnectedAccountCreateOrConnectWithoutStripeTransfersInput {
  where: StripeConnectedAccountWhereUniqueInput;
  create: StripeConnectedAccountCreateWithoutStripeTransfersInput;
}

export interface StripeConnectedAccountCreateWithoutBillingGroupsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  stripeAccountId: string;
  accountType: StripeConnectAccountType;
  default?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutStripeConnectedAccountsInput;
  payments?: PaymentCreateNestedManyWithoutStripeConnectedAccountInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutStripeConnectedAccountInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutStripeConnectedAccountInput | null;
}

export interface StripeConnectedAccountCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  stripeAccountId: string;
  accountType: StripeConnectAccountType;
  default?: boolean | null;
  billingGroups?: BillingGroupCreateNestedManyWithoutStripeConnectedAccountInput | null;
  payments?: PaymentCreateNestedManyWithoutStripeConnectedAccountInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutStripeConnectedAccountInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutStripeConnectedAccountInput | null;
}

export interface StripeConnectedAccountCreateWithoutPaymentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  stripeAccountId: string;
  accountType: StripeConnectAccountType;
  default?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutStripeConnectedAccountsInput;
  billingGroups?: BillingGroupCreateNestedManyWithoutStripeConnectedAccountInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutStripeConnectedAccountInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutStripeConnectedAccountInput | null;
}

export interface StripeConnectedAccountCreateWithoutStripeFeesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  stripeAccountId: string;
  accountType: StripeConnectAccountType;
  default?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutStripeConnectedAccountsInput;
  billingGroups?: BillingGroupCreateNestedManyWithoutStripeConnectedAccountInput | null;
  payments?: PaymentCreateNestedManyWithoutStripeConnectedAccountInput | null;
  stripeTransfers?: StripeTransferCreateNestedManyWithoutStripeConnectedAccountInput | null;
}

export interface StripeConnectedAccountCreateWithoutStripeTransfersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  stripeAccountId: string;
  accountType: StripeConnectAccountType;
  default?: boolean | null;
  organization: OrganizationCreateNestedOneWithoutStripeConnectedAccountsInput;
  billingGroups?: BillingGroupCreateNestedManyWithoutStripeConnectedAccountInput | null;
  payments?: PaymentCreateNestedManyWithoutStripeConnectedAccountInput | null;
  stripeFees?: StripeFeeCreateNestedManyWithoutStripeConnectedAccountInput | null;
}

export interface StripeConnectedAccountListRelationFilter {
  every?: StripeConnectedAccountWhereInput | null;
  some?: StripeConnectedAccountWhereInput | null;
  none?: StripeConnectedAccountWhereInput | null;
}

export interface StripeConnectedAccountOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface StripeConnectedAccountOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  name?: SortOrder | null;
  stripeAccountId?: SortOrder | null;
  accountType?: SortOrder | null;
  default?: SortOrder | null;
  organizationId?: SortOrder | null;
  organization?: OrganizationOrderByWithRelationInput | null;
  billingGroups?: BillingGroupOrderByRelationAggregateInput | null;
  payments?: PaymentOrderByRelationAggregateInput | null;
  stripeTransfers?: StripeTransferOrderByRelationAggregateInput | null;
  stripeFees?: StripeFeeOrderByRelationAggregateInput | null;
}

export interface StripeConnectedAccountRelationFilter {
  is?: StripeConnectedAccountWhereInput | null;
  isNot?: StripeConnectedAccountWhereInput | null;
}

export interface StripeConnectedAccountScalarWhereInput {
  AND?: StripeConnectedAccountScalarWhereInput[] | null;
  OR?: StripeConnectedAccountScalarWhereInput[] | null;
  NOT?: StripeConnectedAccountScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  stripeAccountId?: StringFilter | null;
  accountType?: EnumStripeConnectAccountTypeFilter | null;
  default?: BoolFilter | null;
  organizationId?: UuidFilter | null;
}

export interface StripeConnectedAccountUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  stripeAccountId?: StringFieldUpdateOperationsInput | null;
  accountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
}

export interface StripeConnectedAccountUpdateManyWithWhereWithoutOrganizationInput {
  where: StripeConnectedAccountScalarWhereInput;
  data: StripeConnectedAccountUpdateManyMutationInput;
}

export interface StripeConnectedAccountUpdateManyWithoutOrganizationNestedInput {
  create?: StripeConnectedAccountCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: StripeConnectedAccountCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: StripeConnectedAccountUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: StripeConnectedAccountCreateManyOrganizationInputEnvelope | null;
  set?: StripeConnectedAccountWhereUniqueInput[] | null;
  disconnect?: StripeConnectedAccountWhereUniqueInput[] | null;
  delete?: StripeConnectedAccountWhereUniqueInput[] | null;
  connect?: StripeConnectedAccountWhereUniqueInput[] | null;
  update?: StripeConnectedAccountUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: StripeConnectedAccountUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: StripeConnectedAccountScalarWhereInput[] | null;
}

export interface StripeConnectedAccountUpdateOneRequiredWithoutStripeFeesNestedInput {
  create?: StripeConnectedAccountCreateWithoutStripeFeesInput | null;
  connectOrCreate?: StripeConnectedAccountCreateOrConnectWithoutStripeFeesInput | null;
  upsert?: StripeConnectedAccountUpsertWithoutStripeFeesInput | null;
  connect?: StripeConnectedAccountWhereUniqueInput | null;
  update?: StripeConnectedAccountUpdateWithoutStripeFeesInput | null;
}

export interface StripeConnectedAccountUpdateOneRequiredWithoutStripeTransfersNestedInput {
  create?: StripeConnectedAccountCreateWithoutStripeTransfersInput | null;
  connectOrCreate?: StripeConnectedAccountCreateOrConnectWithoutStripeTransfersInput | null;
  upsert?: StripeConnectedAccountUpsertWithoutStripeTransfersInput | null;
  connect?: StripeConnectedAccountWhereUniqueInput | null;
  update?: StripeConnectedAccountUpdateWithoutStripeTransfersInput | null;
}

export interface StripeConnectedAccountUpdateOneWithoutBillingGroupsNestedInput {
  create?: StripeConnectedAccountCreateWithoutBillingGroupsInput | null;
  connectOrCreate?: StripeConnectedAccountCreateOrConnectWithoutBillingGroupsInput | null;
  upsert?: StripeConnectedAccountUpsertWithoutBillingGroupsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: StripeConnectedAccountWhereUniqueInput | null;
  update?: StripeConnectedAccountUpdateWithoutBillingGroupsInput | null;
}

export interface StripeConnectedAccountUpdateOneWithoutPaymentsNestedInput {
  create?: StripeConnectedAccountCreateWithoutPaymentsInput | null;
  connectOrCreate?: StripeConnectedAccountCreateOrConnectWithoutPaymentsInput | null;
  upsert?: StripeConnectedAccountUpsertWithoutPaymentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: StripeConnectedAccountWhereUniqueInput | null;
  update?: StripeConnectedAccountUpdateWithoutPaymentsInput | null;
}

export interface StripeConnectedAccountUpdateWithWhereUniqueWithoutOrganizationInput {
  where: StripeConnectedAccountWhereUniqueInput;
  data: StripeConnectedAccountUpdateWithoutOrganizationInput;
}

export interface StripeConnectedAccountUpdateWithoutBillingGroupsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  stripeAccountId?: StringFieldUpdateOperationsInput | null;
  accountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutStripeConnectedAccountsNestedInput | null;
  payments?: PaymentUpdateManyWithoutStripeConnectedAccountNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutStripeConnectedAccountNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutStripeConnectedAccountNestedInput | null;
}

export interface StripeConnectedAccountUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  stripeAccountId?: StringFieldUpdateOperationsInput | null;
  accountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutStripeConnectedAccountNestedInput | null;
  payments?: PaymentUpdateManyWithoutStripeConnectedAccountNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutStripeConnectedAccountNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutStripeConnectedAccountNestedInput | null;
}

export interface StripeConnectedAccountUpdateWithoutPaymentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  stripeAccountId?: StringFieldUpdateOperationsInput | null;
  accountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutStripeConnectedAccountsNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutStripeConnectedAccountNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutStripeConnectedAccountNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutStripeConnectedAccountNestedInput | null;
}

export interface StripeConnectedAccountUpdateWithoutStripeFeesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  stripeAccountId?: StringFieldUpdateOperationsInput | null;
  accountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutStripeConnectedAccountsNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutStripeConnectedAccountNestedInput | null;
  payments?: PaymentUpdateManyWithoutStripeConnectedAccountNestedInput | null;
  stripeTransfers?: StripeTransferUpdateManyWithoutStripeConnectedAccountNestedInput | null;
}

export interface StripeConnectedAccountUpdateWithoutStripeTransfersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  stripeAccountId?: StringFieldUpdateOperationsInput | null;
  accountType?: EnumStripeConnectAccountTypeFieldUpdateOperationsInput | null;
  default?: BoolFieldUpdateOperationsInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutStripeConnectedAccountsNestedInput | null;
  billingGroups?: BillingGroupUpdateManyWithoutStripeConnectedAccountNestedInput | null;
  payments?: PaymentUpdateManyWithoutStripeConnectedAccountNestedInput | null;
  stripeFees?: StripeFeeUpdateManyWithoutStripeConnectedAccountNestedInput | null;
}

export interface StripeConnectedAccountUpsertWithWhereUniqueWithoutOrganizationInput {
  where: StripeConnectedAccountWhereUniqueInput;
  update: StripeConnectedAccountUpdateWithoutOrganizationInput;
  create: StripeConnectedAccountCreateWithoutOrganizationInput;
}

export interface StripeConnectedAccountUpsertWithoutBillingGroupsInput {
  update: StripeConnectedAccountUpdateWithoutBillingGroupsInput;
  create: StripeConnectedAccountCreateWithoutBillingGroupsInput;
}

export interface StripeConnectedAccountUpsertWithoutPaymentsInput {
  update: StripeConnectedAccountUpdateWithoutPaymentsInput;
  create: StripeConnectedAccountCreateWithoutPaymentsInput;
}

export interface StripeConnectedAccountUpsertWithoutStripeFeesInput {
  update: StripeConnectedAccountUpdateWithoutStripeFeesInput;
  create: StripeConnectedAccountCreateWithoutStripeFeesInput;
}

export interface StripeConnectedAccountUpsertWithoutStripeTransfersInput {
  update: StripeConnectedAccountUpdateWithoutStripeTransfersInput;
  create: StripeConnectedAccountCreateWithoutStripeTransfersInput;
}

export interface StripeConnectedAccountWhereInput {
  AND?: StripeConnectedAccountWhereInput[] | null;
  OR?: StripeConnectedAccountWhereInput[] | null;
  NOT?: StripeConnectedAccountWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  stripeAccountId?: StringFilter | null;
  accountType?: EnumStripeConnectAccountTypeFilter | null;
  default?: BoolFilter | null;
  organizationId?: UuidFilter | null;
  organization?: OrganizationRelationFilter | null;
  billingGroups?: BillingGroupListRelationFilter | null;
  payments?: PaymentListRelationFilter | null;
  stripeTransfers?: StripeTransferListRelationFilter | null;
  stripeFees?: StripeFeeListRelationFilter | null;
}

export interface StripeConnectedAccountWhereUniqueInput {
  id?: string | null;
  stripeAccountId?: string | null;
}

export interface StripeFeeCreateManyPaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: FeeType;
  amount: number;
  paymentIntentId: string;
  stripeConnectedAccountId: string;
  stripeBalanceTransactionId?: string | null;
}

export interface StripeFeeCreateManyPaymentInputEnvelope {
  data: StripeFeeCreateManyPaymentInput[];
  skipDuplicates?: boolean | null;
}

export interface StripeFeeCreateManyPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: FeeType;
  amount: number;
  stripeConnectedAccountId: string;
  paymentId?: string | null;
  stripeBalanceTransactionId?: string | null;
}

export interface StripeFeeCreateManyPaymentIntentInputEnvelope {
  data: StripeFeeCreateManyPaymentIntentInput[];
  skipDuplicates?: boolean | null;
}

export interface StripeFeeCreateManyStripeConnectedAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: FeeType;
  amount: number;
  paymentIntentId: string;
  paymentId?: string | null;
  stripeBalanceTransactionId?: string | null;
}

export interface StripeFeeCreateManyStripeConnectedAccountInputEnvelope {
  data: StripeFeeCreateManyStripeConnectedAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface StripeFeeCreateNestedManyWithoutPaymentInput {
  create?: StripeFeeCreateWithoutPaymentInput[] | null;
  connectOrCreate?: StripeFeeCreateOrConnectWithoutPaymentInput[] | null;
  createMany?: StripeFeeCreateManyPaymentInputEnvelope | null;
  connect?: StripeFeeWhereUniqueInput[] | null;
}

export interface StripeFeeCreateNestedManyWithoutPaymentIntentInput {
  create?: StripeFeeCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: StripeFeeCreateOrConnectWithoutPaymentIntentInput[] | null;
  createMany?: StripeFeeCreateManyPaymentIntentInputEnvelope | null;
  connect?: StripeFeeWhereUniqueInput[] | null;
}

export interface StripeFeeCreateNestedManyWithoutStripeConnectedAccountInput {
  create?: StripeFeeCreateWithoutStripeConnectedAccountInput[] | null;
  connectOrCreate?: StripeFeeCreateOrConnectWithoutStripeConnectedAccountInput[] | null;
  createMany?: StripeFeeCreateManyStripeConnectedAccountInputEnvelope | null;
  connect?: StripeFeeWhereUniqueInput[] | null;
}

export interface StripeFeeCreateOrConnectWithoutPaymentInput {
  where: StripeFeeWhereUniqueInput;
  create: StripeFeeCreateWithoutPaymentInput;
}

export interface StripeFeeCreateOrConnectWithoutPaymentIntentInput {
  where: StripeFeeWhereUniqueInput;
  create: StripeFeeCreateWithoutPaymentIntentInput;
}

export interface StripeFeeCreateOrConnectWithoutStripeConnectedAccountInput {
  where: StripeFeeWhereUniqueInput;
  create: StripeFeeCreateWithoutStripeConnectedAccountInput;
}

export interface StripeFeeCreateWithoutPaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: FeeType;
  amount: number;
  stripeBalanceTransactionId?: string | null;
  paymentIntent: PaymentIntentCreateNestedOneWithoutStripeFeesInput;
  stripeConnectedAccount: StripeConnectedAccountCreateNestedOneWithoutStripeFeesInput;
}

export interface StripeFeeCreateWithoutPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: FeeType;
  amount: number;
  stripeBalanceTransactionId?: string | null;
  stripeConnectedAccount: StripeConnectedAccountCreateNestedOneWithoutStripeFeesInput;
  payment?: PaymentCreateNestedOneWithoutStripeFeesInput | null;
}

export interface StripeFeeCreateWithoutStripeConnectedAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: FeeType;
  amount: number;
  stripeBalanceTransactionId?: string | null;
  paymentIntent: PaymentIntentCreateNestedOneWithoutStripeFeesInput;
  payment?: PaymentCreateNestedOneWithoutStripeFeesInput | null;
}

export interface StripeFeeListRelationFilter {
  every?: StripeFeeWhereInput | null;
  some?: StripeFeeWhereInput | null;
  none?: StripeFeeWhereInput | null;
}

export interface StripeFeeOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface StripeFeeScalarWhereInput {
  AND?: StripeFeeScalarWhereInput[] | null;
  OR?: StripeFeeScalarWhereInput[] | null;
  NOT?: StripeFeeScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  type?: EnumFeeTypeFilter | null;
  amount?: IntFilter | null;
  paymentIntentId?: UuidFilter | null;
  stripeConnectedAccountId?: UuidFilter | null;
  paymentId?: UuidNullableFilter | null;
  stripeBalanceTransactionId?: StringNullableFilter | null;
}

export interface StripeFeeUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumFeeTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeBalanceTransactionId?: NullableStringFieldUpdateOperationsInput | null;
}

export interface StripeFeeUpdateManyWithWhereWithoutPaymentInput {
  where: StripeFeeScalarWhereInput;
  data: StripeFeeUpdateManyMutationInput;
}

export interface StripeFeeUpdateManyWithWhereWithoutPaymentIntentInput {
  where: StripeFeeScalarWhereInput;
  data: StripeFeeUpdateManyMutationInput;
}

export interface StripeFeeUpdateManyWithWhereWithoutStripeConnectedAccountInput {
  where: StripeFeeScalarWhereInput;
  data: StripeFeeUpdateManyMutationInput;
}

export interface StripeFeeUpdateManyWithoutPaymentIntentNestedInput {
  create?: StripeFeeCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: StripeFeeCreateOrConnectWithoutPaymentIntentInput[] | null;
  upsert?: StripeFeeUpsertWithWhereUniqueWithoutPaymentIntentInput[] | null;
  createMany?: StripeFeeCreateManyPaymentIntentInputEnvelope | null;
  set?: StripeFeeWhereUniqueInput[] | null;
  disconnect?: StripeFeeWhereUniqueInput[] | null;
  delete?: StripeFeeWhereUniqueInput[] | null;
  connect?: StripeFeeWhereUniqueInput[] | null;
  update?: StripeFeeUpdateWithWhereUniqueWithoutPaymentIntentInput[] | null;
  updateMany?: StripeFeeUpdateManyWithWhereWithoutPaymentIntentInput[] | null;
  deleteMany?: StripeFeeScalarWhereInput[] | null;
}

export interface StripeFeeUpdateManyWithoutPaymentNestedInput {
  create?: StripeFeeCreateWithoutPaymentInput[] | null;
  connectOrCreate?: StripeFeeCreateOrConnectWithoutPaymentInput[] | null;
  upsert?: StripeFeeUpsertWithWhereUniqueWithoutPaymentInput[] | null;
  createMany?: StripeFeeCreateManyPaymentInputEnvelope | null;
  set?: StripeFeeWhereUniqueInput[] | null;
  disconnect?: StripeFeeWhereUniqueInput[] | null;
  delete?: StripeFeeWhereUniqueInput[] | null;
  connect?: StripeFeeWhereUniqueInput[] | null;
  update?: StripeFeeUpdateWithWhereUniqueWithoutPaymentInput[] | null;
  updateMany?: StripeFeeUpdateManyWithWhereWithoutPaymentInput[] | null;
  deleteMany?: StripeFeeScalarWhereInput[] | null;
}

export interface StripeFeeUpdateManyWithoutStripeConnectedAccountNestedInput {
  create?: StripeFeeCreateWithoutStripeConnectedAccountInput[] | null;
  connectOrCreate?: StripeFeeCreateOrConnectWithoutStripeConnectedAccountInput[] | null;
  upsert?: StripeFeeUpsertWithWhereUniqueWithoutStripeConnectedAccountInput[] | null;
  createMany?: StripeFeeCreateManyStripeConnectedAccountInputEnvelope | null;
  set?: StripeFeeWhereUniqueInput[] | null;
  disconnect?: StripeFeeWhereUniqueInput[] | null;
  delete?: StripeFeeWhereUniqueInput[] | null;
  connect?: StripeFeeWhereUniqueInput[] | null;
  update?: StripeFeeUpdateWithWhereUniqueWithoutStripeConnectedAccountInput[] | null;
  updateMany?: StripeFeeUpdateManyWithWhereWithoutStripeConnectedAccountInput[] | null;
  deleteMany?: StripeFeeScalarWhereInput[] | null;
}

export interface StripeFeeUpdateWithWhereUniqueWithoutPaymentInput {
  where: StripeFeeWhereUniqueInput;
  data: StripeFeeUpdateWithoutPaymentInput;
}

export interface StripeFeeUpdateWithWhereUniqueWithoutPaymentIntentInput {
  where: StripeFeeWhereUniqueInput;
  data: StripeFeeUpdateWithoutPaymentIntentInput;
}

export interface StripeFeeUpdateWithWhereUniqueWithoutStripeConnectedAccountInput {
  where: StripeFeeWhereUniqueInput;
  data: StripeFeeUpdateWithoutStripeConnectedAccountInput;
}

export interface StripeFeeUpdateWithoutPaymentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumFeeTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeBalanceTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  paymentIntent?: PaymentIntentUpdateOneRequiredWithoutStripeFeesNestedInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneRequiredWithoutStripeFeesNestedInput | null;
}

export interface StripeFeeUpdateWithoutPaymentIntentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumFeeTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeBalanceTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneRequiredWithoutStripeFeesNestedInput | null;
  payment?: PaymentUpdateOneWithoutStripeFeesNestedInput | null;
}

export interface StripeFeeUpdateWithoutStripeConnectedAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumFeeTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeBalanceTransactionId?: NullableStringFieldUpdateOperationsInput | null;
  paymentIntent?: PaymentIntentUpdateOneRequiredWithoutStripeFeesNestedInput | null;
  payment?: PaymentUpdateOneWithoutStripeFeesNestedInput | null;
}

export interface StripeFeeUpsertWithWhereUniqueWithoutPaymentInput {
  where: StripeFeeWhereUniqueInput;
  update: StripeFeeUpdateWithoutPaymentInput;
  create: StripeFeeCreateWithoutPaymentInput;
}

export interface StripeFeeUpsertWithWhereUniqueWithoutPaymentIntentInput {
  where: StripeFeeWhereUniqueInput;
  update: StripeFeeUpdateWithoutPaymentIntentInput;
  create: StripeFeeCreateWithoutPaymentIntentInput;
}

export interface StripeFeeUpsertWithWhereUniqueWithoutStripeConnectedAccountInput {
  where: StripeFeeWhereUniqueInput;
  update: StripeFeeUpdateWithoutStripeConnectedAccountInput;
  create: StripeFeeCreateWithoutStripeConnectedAccountInput;
}

export interface StripeFeeWhereInput {
  AND?: StripeFeeWhereInput[] | null;
  OR?: StripeFeeWhereInput[] | null;
  NOT?: StripeFeeWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  type?: EnumFeeTypeFilter | null;
  amount?: IntFilter | null;
  paymentIntentId?: UuidFilter | null;
  stripeConnectedAccountId?: UuidFilter | null;
  paymentId?: UuidNullableFilter | null;
  stripeBalanceTransactionId?: StringNullableFilter | null;
  paymentIntent?: PaymentIntentRelationFilter | null;
  stripeConnectedAccount?: StripeConnectedAccountRelationFilter | null;
  payment?: PaymentRelationFilter | null;
}

export interface StripeFeeWhereUniqueInput {
  id?: string | null;
}

export interface StripePaymentTransferCreateManyStripeTransferInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  paymentId?: string | null;
}

export interface StripePaymentTransferCreateManyStripeTransferInputEnvelope {
  data: StripePaymentTransferCreateManyStripeTransferInput[];
  skipDuplicates?: boolean | null;
}

export interface StripePaymentTransferCreateNestedManyWithoutStripeTransferInput {
  create?: StripePaymentTransferCreateWithoutStripeTransferInput[] | null;
  connectOrCreate?: StripePaymentTransferCreateOrConnectWithoutStripeTransferInput[] | null;
  createMany?: StripePaymentTransferCreateManyStripeTransferInputEnvelope | null;
  connect?: StripePaymentTransferWhereUniqueInput[] | null;
}

export interface StripePaymentTransferCreateNestedOneWithoutPaymentInput {
  create?: StripePaymentTransferCreateWithoutPaymentInput | null;
  connectOrCreate?: StripePaymentTransferCreateOrConnectWithoutPaymentInput | null;
  connect?: StripePaymentTransferWhereUniqueInput | null;
}

export interface StripePaymentTransferCreateOrConnectWithoutPaymentInput {
  where: StripePaymentTransferWhereUniqueInput;
  create: StripePaymentTransferCreateWithoutPaymentInput;
}

export interface StripePaymentTransferCreateOrConnectWithoutStripeTransferInput {
  where: StripePaymentTransferWhereUniqueInput;
  create: StripePaymentTransferCreateWithoutStripeTransferInput;
}

export interface StripePaymentTransferCreateWithoutPaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  stripeTransfer: StripeTransferCreateNestedOneWithoutStripePaymentTransfersInput;
}

export interface StripePaymentTransferCreateWithoutStripeTransferInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  payment?: PaymentCreateNestedOneWithoutStripePaymentTransferInput | null;
}

export interface StripePaymentTransferListRelationFilter {
  every?: StripePaymentTransferWhereInput | null;
  some?: StripePaymentTransferWhereInput | null;
  none?: StripePaymentTransferWhereInput | null;
}

export interface StripePaymentTransferRelationFilter {
  is?: StripePaymentTransferWhereInput | null;
  isNot?: StripePaymentTransferWhereInput | null;
}

export interface StripePaymentTransferScalarWhereInput {
  AND?: StripePaymentTransferScalarWhereInput[] | null;
  OR?: StripePaymentTransferScalarWhereInput[] | null;
  NOT?: StripePaymentTransferScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  amount?: IntFilter | null;
  paymentId?: UuidNullableFilter | null;
  stripeTransferId?: UuidFilter | null;
}

export interface StripePaymentTransferUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
}

export interface StripePaymentTransferUpdateManyWithWhereWithoutStripeTransferInput {
  where: StripePaymentTransferScalarWhereInput;
  data: StripePaymentTransferUpdateManyMutationInput;
}

export interface StripePaymentTransferUpdateManyWithoutStripeTransferNestedInput {
  create?: StripePaymentTransferCreateWithoutStripeTransferInput[] | null;
  connectOrCreate?: StripePaymentTransferCreateOrConnectWithoutStripeTransferInput[] | null;
  upsert?: StripePaymentTransferUpsertWithWhereUniqueWithoutStripeTransferInput[] | null;
  createMany?: StripePaymentTransferCreateManyStripeTransferInputEnvelope | null;
  set?: StripePaymentTransferWhereUniqueInput[] | null;
  disconnect?: StripePaymentTransferWhereUniqueInput[] | null;
  delete?: StripePaymentTransferWhereUniqueInput[] | null;
  connect?: StripePaymentTransferWhereUniqueInput[] | null;
  update?: StripePaymentTransferUpdateWithWhereUniqueWithoutStripeTransferInput[] | null;
  updateMany?: StripePaymentTransferUpdateManyWithWhereWithoutStripeTransferInput[] | null;
  deleteMany?: StripePaymentTransferScalarWhereInput[] | null;
}

export interface StripePaymentTransferUpdateOneWithoutPaymentNestedInput {
  create?: StripePaymentTransferCreateWithoutPaymentInput | null;
  connectOrCreate?: StripePaymentTransferCreateOrConnectWithoutPaymentInput | null;
  upsert?: StripePaymentTransferUpsertWithoutPaymentInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: StripePaymentTransferWhereUniqueInput | null;
  update?: StripePaymentTransferUpdateWithoutPaymentInput | null;
}

export interface StripePaymentTransferUpdateWithWhereUniqueWithoutStripeTransferInput {
  where: StripePaymentTransferWhereUniqueInput;
  data: StripePaymentTransferUpdateWithoutStripeTransferInput;
}

export interface StripePaymentTransferUpdateWithoutPaymentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeTransfer?: StripeTransferUpdateOneRequiredWithoutStripePaymentTransfersNestedInput | null;
}

export interface StripePaymentTransferUpdateWithoutStripeTransferInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  payment?: PaymentUpdateOneWithoutStripePaymentTransferNestedInput | null;
}

export interface StripePaymentTransferUpsertWithWhereUniqueWithoutStripeTransferInput {
  where: StripePaymentTransferWhereUniqueInput;
  update: StripePaymentTransferUpdateWithoutStripeTransferInput;
  create: StripePaymentTransferCreateWithoutStripeTransferInput;
}

export interface StripePaymentTransferUpsertWithoutPaymentInput {
  update: StripePaymentTransferUpdateWithoutPaymentInput;
  create: StripePaymentTransferCreateWithoutPaymentInput;
}

export interface StripePaymentTransferWhereInput {
  AND?: StripePaymentTransferWhereInput[] | null;
  OR?: StripePaymentTransferWhereInput[] | null;
  NOT?: StripePaymentTransferWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  amount?: IntFilter | null;
  paymentId?: UuidNullableFilter | null;
  stripeTransferId?: UuidFilter | null;
  payment?: PaymentRelationFilter | null;
  stripeTransfer?: StripeTransferRelationFilter | null;
}

export interface StripePaymentTransferWhereUniqueInput {
  id?: string | null;
  paymentId?: string | null;
}

export interface StripeTransferCreateManyPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  stripeTransferId: string;
  stripeConnectedAccountId: string;
}

export interface StripeTransferCreateManyPaymentIntentInputEnvelope {
  data: StripeTransferCreateManyPaymentIntentInput[];
  skipDuplicates?: boolean | null;
}

export interface StripeTransferCreateManyStripeConnectedAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  stripeTransferId: string;
  paymentIntentId: string;
}

export interface StripeTransferCreateManyStripeConnectedAccountInputEnvelope {
  data: StripeTransferCreateManyStripeConnectedAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface StripeTransferCreateNestedManyWithoutPaymentIntentInput {
  create?: StripeTransferCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: StripeTransferCreateOrConnectWithoutPaymentIntentInput[] | null;
  createMany?: StripeTransferCreateManyPaymentIntentInputEnvelope | null;
  connect?: StripeTransferWhereUniqueInput[] | null;
}

export interface StripeTransferCreateNestedManyWithoutStripeConnectedAccountInput {
  create?: StripeTransferCreateWithoutStripeConnectedAccountInput[] | null;
  connectOrCreate?: StripeTransferCreateOrConnectWithoutStripeConnectedAccountInput[] | null;
  createMany?: StripeTransferCreateManyStripeConnectedAccountInputEnvelope | null;
  connect?: StripeTransferWhereUniqueInput[] | null;
}

export interface StripeTransferCreateNestedOneWithoutStripePaymentTransfersInput {
  create?: StripeTransferCreateWithoutStripePaymentTransfersInput | null;
  connectOrCreate?: StripeTransferCreateOrConnectWithoutStripePaymentTransfersInput | null;
  connect?: StripeTransferWhereUniqueInput | null;
}

export interface StripeTransferCreateOrConnectWithoutPaymentIntentInput {
  where: StripeTransferWhereUniqueInput;
  create: StripeTransferCreateWithoutPaymentIntentInput;
}

export interface StripeTransferCreateOrConnectWithoutStripeConnectedAccountInput {
  where: StripeTransferWhereUniqueInput;
  create: StripeTransferCreateWithoutStripeConnectedAccountInput;
}

export interface StripeTransferCreateOrConnectWithoutStripePaymentTransfersInput {
  where: StripeTransferWhereUniqueInput;
  create: StripeTransferCreateWithoutStripePaymentTransfersInput;
}

export interface StripeTransferCreateWithoutPaymentIntentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  stripeTransferId: string;
  stripeConnectedAccount: StripeConnectedAccountCreateNestedOneWithoutStripeTransfersInput;
  stripePaymentTransfers?: StripePaymentTransferCreateNestedManyWithoutStripeTransferInput | null;
}

export interface StripeTransferCreateWithoutStripeConnectedAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  stripeTransferId: string;
  paymentIntent: PaymentIntentCreateNestedOneWithoutStripeTransfersInput;
  stripePaymentTransfers?: StripePaymentTransferCreateNestedManyWithoutStripeTransferInput | null;
}

export interface StripeTransferCreateWithoutStripePaymentTransfersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  amount: number;
  stripeTransferId: string;
  paymentIntent: PaymentIntentCreateNestedOneWithoutStripeTransfersInput;
  stripeConnectedAccount: StripeConnectedAccountCreateNestedOneWithoutStripeTransfersInput;
}

export interface StripeTransferListRelationFilter {
  every?: StripeTransferWhereInput | null;
  some?: StripeTransferWhereInput | null;
  none?: StripeTransferWhereInput | null;
}

export interface StripeTransferOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface StripeTransferRelationFilter {
  is?: StripeTransferWhereInput | null;
  isNot?: StripeTransferWhereInput | null;
}

export interface StripeTransferScalarWhereInput {
  AND?: StripeTransferScalarWhereInput[] | null;
  OR?: StripeTransferScalarWhereInput[] | null;
  NOT?: StripeTransferScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  amount?: IntFilter | null;
  stripeTransferId?: StringFilter | null;
  paymentIntentId?: UuidFilter | null;
  stripeConnectedAccountId?: UuidFilter | null;
}

export interface StripeTransferUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeTransferId?: StringFieldUpdateOperationsInput | null;
}

export interface StripeTransferUpdateManyWithWhereWithoutPaymentIntentInput {
  where: StripeTransferScalarWhereInput;
  data: StripeTransferUpdateManyMutationInput;
}

export interface StripeTransferUpdateManyWithWhereWithoutStripeConnectedAccountInput {
  where: StripeTransferScalarWhereInput;
  data: StripeTransferUpdateManyMutationInput;
}

export interface StripeTransferUpdateManyWithoutPaymentIntentNestedInput {
  create?: StripeTransferCreateWithoutPaymentIntentInput[] | null;
  connectOrCreate?: StripeTransferCreateOrConnectWithoutPaymentIntentInput[] | null;
  upsert?: StripeTransferUpsertWithWhereUniqueWithoutPaymentIntentInput[] | null;
  createMany?: StripeTransferCreateManyPaymentIntentInputEnvelope | null;
  set?: StripeTransferWhereUniqueInput[] | null;
  disconnect?: StripeTransferWhereUniqueInput[] | null;
  delete?: StripeTransferWhereUniqueInput[] | null;
  connect?: StripeTransferWhereUniqueInput[] | null;
  update?: StripeTransferUpdateWithWhereUniqueWithoutPaymentIntentInput[] | null;
  updateMany?: StripeTransferUpdateManyWithWhereWithoutPaymentIntentInput[] | null;
  deleteMany?: StripeTransferScalarWhereInput[] | null;
}

export interface StripeTransferUpdateManyWithoutStripeConnectedAccountNestedInput {
  create?: StripeTransferCreateWithoutStripeConnectedAccountInput[] | null;
  connectOrCreate?: StripeTransferCreateOrConnectWithoutStripeConnectedAccountInput[] | null;
  upsert?: StripeTransferUpsertWithWhereUniqueWithoutStripeConnectedAccountInput[] | null;
  createMany?: StripeTransferCreateManyStripeConnectedAccountInputEnvelope | null;
  set?: StripeTransferWhereUniqueInput[] | null;
  disconnect?: StripeTransferWhereUniqueInput[] | null;
  delete?: StripeTransferWhereUniqueInput[] | null;
  connect?: StripeTransferWhereUniqueInput[] | null;
  update?: StripeTransferUpdateWithWhereUniqueWithoutStripeConnectedAccountInput[] | null;
  updateMany?: StripeTransferUpdateManyWithWhereWithoutStripeConnectedAccountInput[] | null;
  deleteMany?: StripeTransferScalarWhereInput[] | null;
}

export interface StripeTransferUpdateOneRequiredWithoutStripePaymentTransfersNestedInput {
  create?: StripeTransferCreateWithoutStripePaymentTransfersInput | null;
  connectOrCreate?: StripeTransferCreateOrConnectWithoutStripePaymentTransfersInput | null;
  upsert?: StripeTransferUpsertWithoutStripePaymentTransfersInput | null;
  connect?: StripeTransferWhereUniqueInput | null;
  update?: StripeTransferUpdateWithoutStripePaymentTransfersInput | null;
}

export interface StripeTransferUpdateWithWhereUniqueWithoutPaymentIntentInput {
  where: StripeTransferWhereUniqueInput;
  data: StripeTransferUpdateWithoutPaymentIntentInput;
}

export interface StripeTransferUpdateWithWhereUniqueWithoutStripeConnectedAccountInput {
  where: StripeTransferWhereUniqueInput;
  data: StripeTransferUpdateWithoutStripeConnectedAccountInput;
}

export interface StripeTransferUpdateWithoutPaymentIntentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeTransferId?: StringFieldUpdateOperationsInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneRequiredWithoutStripeTransfersNestedInput | null;
  stripePaymentTransfers?: StripePaymentTransferUpdateManyWithoutStripeTransferNestedInput | null;
}

export interface StripeTransferUpdateWithoutStripeConnectedAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeTransferId?: StringFieldUpdateOperationsInput | null;
  paymentIntent?: PaymentIntentUpdateOneRequiredWithoutStripeTransfersNestedInput | null;
  stripePaymentTransfers?: StripePaymentTransferUpdateManyWithoutStripeTransferNestedInput | null;
}

export interface StripeTransferUpdateWithoutStripePaymentTransfersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  stripeTransferId?: StringFieldUpdateOperationsInput | null;
  paymentIntent?: PaymentIntentUpdateOneRequiredWithoutStripeTransfersNestedInput | null;
  stripeConnectedAccount?: StripeConnectedAccountUpdateOneRequiredWithoutStripeTransfersNestedInput | null;
}

export interface StripeTransferUpsertWithWhereUniqueWithoutPaymentIntentInput {
  where: StripeTransferWhereUniqueInput;
  update: StripeTransferUpdateWithoutPaymentIntentInput;
  create: StripeTransferCreateWithoutPaymentIntentInput;
}

export interface StripeTransferUpsertWithWhereUniqueWithoutStripeConnectedAccountInput {
  where: StripeTransferWhereUniqueInput;
  update: StripeTransferUpdateWithoutStripeConnectedAccountInput;
  create: StripeTransferCreateWithoutStripeConnectedAccountInput;
}

export interface StripeTransferUpsertWithoutStripePaymentTransfersInput {
  update: StripeTransferUpdateWithoutStripePaymentTransfersInput;
  create: StripeTransferCreateWithoutStripePaymentTransfersInput;
}

export interface StripeTransferWhereInput {
  AND?: StripeTransferWhereInput[] | null;
  OR?: StripeTransferWhereInput[] | null;
  NOT?: StripeTransferWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  amount?: IntFilter | null;
  stripeTransferId?: StringFilter | null;
  paymentIntentId?: UuidFilter | null;
  stripeConnectedAccountId?: UuidFilter | null;
  paymentIntent?: PaymentIntentRelationFilter | null;
  stripeConnectedAccount?: StripeConnectedAccountRelationFilter | null;
  stripePaymentTransfers?: StripePaymentTransferListRelationFilter | null;
}

export interface StripeTransferWhereUniqueInput {
  id?: string | null;
  stripeTransferId?: string | null;
}

export interface TaskCreateManyCanceledByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  paymentRequestId?: string | null;
  completedById?: string | null;
  canceledAt?: any | null;
  snoozedUntil?: any | null;
  snoozedById?: string | null;
  dueDate?: any | null;
  locationId: string;
  organizationId: string;
}

export interface TaskCreateManyCanceledByInputEnvelope {
  data: TaskCreateManyCanceledByInput[];
  skipDuplicates?: boolean | null;
}

export interface TaskCreateManyCompletedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  paymentRequestId?: string | null;
  canceledAt?: any | null;
  canceledById?: string | null;
  snoozedUntil?: any | null;
  snoozedById?: string | null;
  dueDate?: any | null;
  locationId: string;
  organizationId: string;
}

export interface TaskCreateManyCompletedByInputEnvelope {
  data: TaskCreateManyCompletedByInput[];
  skipDuplicates?: boolean | null;
}

export interface TaskCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  paymentRequestId?: string | null;
  completedById?: string | null;
  canceledAt?: any | null;
  canceledById?: string | null;
  snoozedUntil?: any | null;
  snoozedById?: string | null;
  dueDate?: any | null;
  organizationId: string;
}

export interface TaskCreateManyLocationInputEnvelope {
  data: TaskCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface TaskCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  paymentRequestId?: string | null;
  completedById?: string | null;
  canceledAt?: any | null;
  canceledById?: string | null;
  snoozedUntil?: any | null;
  snoozedById?: string | null;
  dueDate?: any | null;
  locationId: string;
}

export interface TaskCreateManyOrganizationInputEnvelope {
  data: TaskCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface TaskCreateManyPaymentRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  completedById?: string | null;
  canceledAt?: any | null;
  canceledById?: string | null;
  snoozedUntil?: any | null;
  snoozedById?: string | null;
  dueDate?: any | null;
  locationId: string;
  organizationId: string;
}

export interface TaskCreateManyPaymentRequestInputEnvelope {
  data: TaskCreateManyPaymentRequestInput[];
  skipDuplicates?: boolean | null;
}

export interface TaskCreateManySnoozedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  paymentRequestId?: string | null;
  completedById?: string | null;
  canceledAt?: any | null;
  canceledById?: string | null;
  snoozedUntil?: any | null;
  dueDate?: any | null;
  locationId: string;
  organizationId: string;
}

export interface TaskCreateManySnoozedByInputEnvelope {
  data: TaskCreateManySnoozedByInput[];
  skipDuplicates?: boolean | null;
}

export interface TaskCreateNestedManyWithoutCanceledByInput {
  create?: TaskCreateWithoutCanceledByInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutCanceledByInput[] | null;
  createMany?: TaskCreateManyCanceledByInputEnvelope | null;
  connect?: TaskWhereUniqueInput[] | null;
}

export interface TaskCreateNestedManyWithoutCompletedByInput {
  create?: TaskCreateWithoutCompletedByInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutCompletedByInput[] | null;
  createMany?: TaskCreateManyCompletedByInputEnvelope | null;
  connect?: TaskWhereUniqueInput[] | null;
}

export interface TaskCreateNestedManyWithoutLocationInput {
  create?: TaskCreateWithoutLocationInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutLocationInput[] | null;
  createMany?: TaskCreateManyLocationInputEnvelope | null;
  connect?: TaskWhereUniqueInput[] | null;
}

export interface TaskCreateNestedManyWithoutOrganizationInput {
  create?: TaskCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: TaskCreateManyOrganizationInputEnvelope | null;
  connect?: TaskWhereUniqueInput[] | null;
}

export interface TaskCreateNestedManyWithoutPaymentRequestInput {
  create?: TaskCreateWithoutPaymentRequestInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutPaymentRequestInput[] | null;
  createMany?: TaskCreateManyPaymentRequestInputEnvelope | null;
  connect?: TaskWhereUniqueInput[] | null;
}

export interface TaskCreateNestedManyWithoutSnoozedByInput {
  create?: TaskCreateWithoutSnoozedByInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutSnoozedByInput[] | null;
  createMany?: TaskCreateManySnoozedByInputEnvelope | null;
  connect?: TaskWhereUniqueInput[] | null;
}

export interface TaskCreateOrConnectWithoutCanceledByInput {
  where: TaskWhereUniqueInput;
  create: TaskCreateWithoutCanceledByInput;
}

export interface TaskCreateOrConnectWithoutCompletedByInput {
  where: TaskWhereUniqueInput;
  create: TaskCreateWithoutCompletedByInput;
}

export interface TaskCreateOrConnectWithoutLocationInput {
  where: TaskWhereUniqueInput;
  create: TaskCreateWithoutLocationInput;
}

export interface TaskCreateOrConnectWithoutOrganizationInput {
  where: TaskWhereUniqueInput;
  create: TaskCreateWithoutOrganizationInput;
}

export interface TaskCreateOrConnectWithoutPaymentRequestInput {
  where: TaskWhereUniqueInput;
  create: TaskCreateWithoutPaymentRequestInput;
}

export interface TaskCreateOrConnectWithoutSnoozedByInput {
  where: TaskWhereUniqueInput;
  create: TaskCreateWithoutSnoozedByInput;
}

export interface TaskCreateWithoutCanceledByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  canceledAt?: any | null;
  snoozedUntil?: any | null;
  dueDate?: any | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutTasksInput | null;
  completedBy?: UserCreateNestedOneWithoutCompletedTasksInput | null;
  snoozedBy?: UserCreateNestedOneWithoutSnoozedTasksInput | null;
  location: LocationCreateNestedOneWithoutTasksInput;
  organization: OrganizationCreateNestedOneWithoutTasksInput;
}

export interface TaskCreateWithoutCompletedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  canceledAt?: any | null;
  snoozedUntil?: any | null;
  dueDate?: any | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutTasksInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledTasksInput | null;
  snoozedBy?: UserCreateNestedOneWithoutSnoozedTasksInput | null;
  location: LocationCreateNestedOneWithoutTasksInput;
  organization: OrganizationCreateNestedOneWithoutTasksInput;
}

export interface TaskCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  canceledAt?: any | null;
  snoozedUntil?: any | null;
  dueDate?: any | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutTasksInput | null;
  completedBy?: UserCreateNestedOneWithoutCompletedTasksInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledTasksInput | null;
  snoozedBy?: UserCreateNestedOneWithoutSnoozedTasksInput | null;
  organization: OrganizationCreateNestedOneWithoutTasksInput;
}

export interface TaskCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  canceledAt?: any | null;
  snoozedUntil?: any | null;
  dueDate?: any | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutTasksInput | null;
  completedBy?: UserCreateNestedOneWithoutCompletedTasksInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledTasksInput | null;
  snoozedBy?: UserCreateNestedOneWithoutSnoozedTasksInput | null;
  location: LocationCreateNestedOneWithoutTasksInput;
}

export interface TaskCreateWithoutPaymentRequestInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  canceledAt?: any | null;
  snoozedUntil?: any | null;
  dueDate?: any | null;
  completedBy?: UserCreateNestedOneWithoutCompletedTasksInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledTasksInput | null;
  snoozedBy?: UserCreateNestedOneWithoutSnoozedTasksInput | null;
  location: LocationCreateNestedOneWithoutTasksInput;
  organization: OrganizationCreateNestedOneWithoutTasksInput;
}

export interface TaskCreateWithoutSnoozedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: TaskType;
  completedAt?: any | null;
  canceledAt?: any | null;
  snoozedUntil?: any | null;
  dueDate?: any | null;
  paymentRequest?: PaymentRequestCreateNestedOneWithoutTasksInput | null;
  completedBy?: UserCreateNestedOneWithoutCompletedTasksInput | null;
  canceledBy?: UserCreateNestedOneWithoutCanceledTasksInput | null;
  location: LocationCreateNestedOneWithoutTasksInput;
  organization: OrganizationCreateNestedOneWithoutTasksInput;
}

export interface TaskListRelationFilter {
  every?: TaskWhereInput | null;
  some?: TaskWhereInput | null;
  none?: TaskWhereInput | null;
}

export interface TaskOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface TaskScalarWhereInput {
  AND?: TaskScalarWhereInput[] | null;
  OR?: TaskScalarWhereInput[] | null;
  NOT?: TaskScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  type?: EnumTaskTypeFilter | null;
  completedAt?: DateTimeNullableFilter | null;
  paymentRequestId?: UuidNullableFilter | null;
  completedById?: UuidNullableFilter | null;
  canceledAt?: DateTimeNullableFilter | null;
  canceledById?: UuidNullableFilter | null;
  snoozedUntil?: DateTimeNullableFilter | null;
  snoozedById?: UuidNullableFilter | null;
  dueDate?: DateTimeNullableFilter | null;
  locationId?: UuidFilter | null;
  organizationId?: UuidFilter | null;
}

export interface TaskUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumTaskTypeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  snoozedUntil?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
}

export interface TaskUpdateManyWithWhereWithoutCanceledByInput {
  where: TaskScalarWhereInput;
  data: TaskUpdateManyMutationInput;
}

export interface TaskUpdateManyWithWhereWithoutCompletedByInput {
  where: TaskScalarWhereInput;
  data: TaskUpdateManyMutationInput;
}

export interface TaskUpdateManyWithWhereWithoutLocationInput {
  where: TaskScalarWhereInput;
  data: TaskUpdateManyMutationInput;
}

export interface TaskUpdateManyWithWhereWithoutOrganizationInput {
  where: TaskScalarWhereInput;
  data: TaskUpdateManyMutationInput;
}

export interface TaskUpdateManyWithWhereWithoutPaymentRequestInput {
  where: TaskScalarWhereInput;
  data: TaskUpdateManyMutationInput;
}

export interface TaskUpdateManyWithWhereWithoutSnoozedByInput {
  where: TaskScalarWhereInput;
  data: TaskUpdateManyMutationInput;
}

export interface TaskUpdateManyWithoutCanceledByNestedInput {
  create?: TaskCreateWithoutCanceledByInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutCanceledByInput[] | null;
  upsert?: TaskUpsertWithWhereUniqueWithoutCanceledByInput[] | null;
  createMany?: TaskCreateManyCanceledByInputEnvelope | null;
  set?: TaskWhereUniqueInput[] | null;
  disconnect?: TaskWhereUniqueInput[] | null;
  delete?: TaskWhereUniqueInput[] | null;
  connect?: TaskWhereUniqueInput[] | null;
  update?: TaskUpdateWithWhereUniqueWithoutCanceledByInput[] | null;
  updateMany?: TaskUpdateManyWithWhereWithoutCanceledByInput[] | null;
  deleteMany?: TaskScalarWhereInput[] | null;
}

export interface TaskUpdateManyWithoutCompletedByNestedInput {
  create?: TaskCreateWithoutCompletedByInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutCompletedByInput[] | null;
  upsert?: TaskUpsertWithWhereUniqueWithoutCompletedByInput[] | null;
  createMany?: TaskCreateManyCompletedByInputEnvelope | null;
  set?: TaskWhereUniqueInput[] | null;
  disconnect?: TaskWhereUniqueInput[] | null;
  delete?: TaskWhereUniqueInput[] | null;
  connect?: TaskWhereUniqueInput[] | null;
  update?: TaskUpdateWithWhereUniqueWithoutCompletedByInput[] | null;
  updateMany?: TaskUpdateManyWithWhereWithoutCompletedByInput[] | null;
  deleteMany?: TaskScalarWhereInput[] | null;
}

export interface TaskUpdateManyWithoutLocationNestedInput {
  create?: TaskCreateWithoutLocationInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutLocationInput[] | null;
  upsert?: TaskUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: TaskCreateManyLocationInputEnvelope | null;
  set?: TaskWhereUniqueInput[] | null;
  disconnect?: TaskWhereUniqueInput[] | null;
  delete?: TaskWhereUniqueInput[] | null;
  connect?: TaskWhereUniqueInput[] | null;
  update?: TaskUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: TaskUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: TaskScalarWhereInput[] | null;
}

export interface TaskUpdateManyWithoutOrganizationNestedInput {
  create?: TaskCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: TaskUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: TaskCreateManyOrganizationInputEnvelope | null;
  set?: TaskWhereUniqueInput[] | null;
  disconnect?: TaskWhereUniqueInput[] | null;
  delete?: TaskWhereUniqueInput[] | null;
  connect?: TaskWhereUniqueInput[] | null;
  update?: TaskUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: TaskUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: TaskScalarWhereInput[] | null;
}

export interface TaskUpdateManyWithoutPaymentRequestNestedInput {
  create?: TaskCreateWithoutPaymentRequestInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutPaymentRequestInput[] | null;
  upsert?: TaskUpsertWithWhereUniqueWithoutPaymentRequestInput[] | null;
  createMany?: TaskCreateManyPaymentRequestInputEnvelope | null;
  set?: TaskWhereUniqueInput[] | null;
  disconnect?: TaskWhereUniqueInput[] | null;
  delete?: TaskWhereUniqueInput[] | null;
  connect?: TaskWhereUniqueInput[] | null;
  update?: TaskUpdateWithWhereUniqueWithoutPaymentRequestInput[] | null;
  updateMany?: TaskUpdateManyWithWhereWithoutPaymentRequestInput[] | null;
  deleteMany?: TaskScalarWhereInput[] | null;
}

export interface TaskUpdateManyWithoutSnoozedByNestedInput {
  create?: TaskCreateWithoutSnoozedByInput[] | null;
  connectOrCreate?: TaskCreateOrConnectWithoutSnoozedByInput[] | null;
  upsert?: TaskUpsertWithWhereUniqueWithoutSnoozedByInput[] | null;
  createMany?: TaskCreateManySnoozedByInputEnvelope | null;
  set?: TaskWhereUniqueInput[] | null;
  disconnect?: TaskWhereUniqueInput[] | null;
  delete?: TaskWhereUniqueInput[] | null;
  connect?: TaskWhereUniqueInput[] | null;
  update?: TaskUpdateWithWhereUniqueWithoutSnoozedByInput[] | null;
  updateMany?: TaskUpdateManyWithWhereWithoutSnoozedByInput[] | null;
  deleteMany?: TaskScalarWhereInput[] | null;
}

export interface TaskUpdateWithWhereUniqueWithoutCanceledByInput {
  where: TaskWhereUniqueInput;
  data: TaskUpdateWithoutCanceledByInput;
}

export interface TaskUpdateWithWhereUniqueWithoutCompletedByInput {
  where: TaskWhereUniqueInput;
  data: TaskUpdateWithoutCompletedByInput;
}

export interface TaskUpdateWithWhereUniqueWithoutLocationInput {
  where: TaskWhereUniqueInput;
  data: TaskUpdateWithoutLocationInput;
}

export interface TaskUpdateWithWhereUniqueWithoutOrganizationInput {
  where: TaskWhereUniqueInput;
  data: TaskUpdateWithoutOrganizationInput;
}

export interface TaskUpdateWithWhereUniqueWithoutPaymentRequestInput {
  where: TaskWhereUniqueInput;
  data: TaskUpdateWithoutPaymentRequestInput;
}

export interface TaskUpdateWithWhereUniqueWithoutSnoozedByInput {
  where: TaskWhereUniqueInput;
  data: TaskUpdateWithoutSnoozedByInput;
}

export interface TaskUpdateWithoutCanceledByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumTaskTypeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  snoozedUntil?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutTasksNestedInput | null;
  completedBy?: UserUpdateOneWithoutCompletedTasksNestedInput | null;
  snoozedBy?: UserUpdateOneWithoutSnoozedTasksNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutTasksNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutTasksNestedInput | null;
}

export interface TaskUpdateWithoutCompletedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumTaskTypeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  snoozedUntil?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutTasksNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledTasksNestedInput | null;
  snoozedBy?: UserUpdateOneWithoutSnoozedTasksNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutTasksNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutTasksNestedInput | null;
}

export interface TaskUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumTaskTypeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  snoozedUntil?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutTasksNestedInput | null;
  completedBy?: UserUpdateOneWithoutCompletedTasksNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledTasksNestedInput | null;
  snoozedBy?: UserUpdateOneWithoutSnoozedTasksNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutTasksNestedInput | null;
}

export interface TaskUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumTaskTypeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  snoozedUntil?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutTasksNestedInput | null;
  completedBy?: UserUpdateOneWithoutCompletedTasksNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledTasksNestedInput | null;
  snoozedBy?: UserUpdateOneWithoutSnoozedTasksNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutTasksNestedInput | null;
}

export interface TaskUpdateWithoutPaymentRequestInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumTaskTypeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  snoozedUntil?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  completedBy?: UserUpdateOneWithoutCompletedTasksNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledTasksNestedInput | null;
  snoozedBy?: UserUpdateOneWithoutSnoozedTasksNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutTasksNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutTasksNestedInput | null;
}

export interface TaskUpdateWithoutSnoozedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumTaskTypeFieldUpdateOperationsInput | null;
  completedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  canceledAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  snoozedUntil?: NullableDateTimeFieldUpdateOperationsInput | null;
  dueDate?: NullableDateTimeFieldUpdateOperationsInput | null;
  paymentRequest?: PaymentRequestUpdateOneWithoutTasksNestedInput | null;
  completedBy?: UserUpdateOneWithoutCompletedTasksNestedInput | null;
  canceledBy?: UserUpdateOneWithoutCanceledTasksNestedInput | null;
  location?: LocationUpdateOneRequiredWithoutTasksNestedInput | null;
  organization?: OrganizationUpdateOneRequiredWithoutTasksNestedInput | null;
}

export interface TaskUpsertWithWhereUniqueWithoutCanceledByInput {
  where: TaskWhereUniqueInput;
  update: TaskUpdateWithoutCanceledByInput;
  create: TaskCreateWithoutCanceledByInput;
}

export interface TaskUpsertWithWhereUniqueWithoutCompletedByInput {
  where: TaskWhereUniqueInput;
  update: TaskUpdateWithoutCompletedByInput;
  create: TaskCreateWithoutCompletedByInput;
}

export interface TaskUpsertWithWhereUniqueWithoutLocationInput {
  where: TaskWhereUniqueInput;
  update: TaskUpdateWithoutLocationInput;
  create: TaskCreateWithoutLocationInput;
}

export interface TaskUpsertWithWhereUniqueWithoutOrganizationInput {
  where: TaskWhereUniqueInput;
  update: TaskUpdateWithoutOrganizationInput;
  create: TaskCreateWithoutOrganizationInput;
}

export interface TaskUpsertWithWhereUniqueWithoutPaymentRequestInput {
  where: TaskWhereUniqueInput;
  update: TaskUpdateWithoutPaymentRequestInput;
  create: TaskCreateWithoutPaymentRequestInput;
}

export interface TaskUpsertWithWhereUniqueWithoutSnoozedByInput {
  where: TaskWhereUniqueInput;
  update: TaskUpdateWithoutSnoozedByInput;
  create: TaskCreateWithoutSnoozedByInput;
}

export interface TaskWhereInput {
  AND?: TaskWhereInput[] | null;
  OR?: TaskWhereInput[] | null;
  NOT?: TaskWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  type?: EnumTaskTypeFilter | null;
  completedAt?: DateTimeNullableFilter | null;
  paymentRequestId?: UuidNullableFilter | null;
  completedById?: UuidNullableFilter | null;
  canceledAt?: DateTimeNullableFilter | null;
  canceledById?: UuidNullableFilter | null;
  snoozedUntil?: DateTimeNullableFilter | null;
  snoozedById?: UuidNullableFilter | null;
  dueDate?: DateTimeNullableFilter | null;
  locationId?: UuidFilter | null;
  organizationId?: UuidFilter | null;
  paymentRequest?: PaymentRequestRelationFilter | null;
  completedBy?: UserRelationFilter | null;
  canceledBy?: UserRelationFilter | null;
  snoozedBy?: UserRelationFilter | null;
  location?: LocationRelationFilter | null;
  organization?: OrganizationRelationFilter | null;
}

export interface TaskWhereUniqueInput {
  id?: string | null;
}

export interface TradingPartnerCreateNestedOneWithoutPayersInput {
  create?: TradingPartnerCreateWithoutPayersInput | null;
  connectOrCreate?: TradingPartnerCreateOrConnectWithoutPayersInput | null;
  connect?: TradingPartnerWhereUniqueInput | null;
}

export interface TradingPartnerCreateOrConnectWithoutPayersInput {
  where: TradingPartnerWhereUniqueInput;
  create: TradingPartnerCreateWithoutPayersInput;
}

export interface TradingPartnerCreateWithoutPayersInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  changeTradingPartnerId?: string | null;
  changeEligibilityPortalId?: string | null;
  changeEligibility?: boolean | null;
  changeClaimStatus?: boolean | null;
  changeEligibilityConnectivityType?: ChangePayerConnectivityType | null;
  changeClaimStatusConnectivityType?: ChangePayerConnectivityType | null;
  availityPayerId?: string | null;
  availityDefaultState?: string | null;
  availityDefaultProviderType?: string | null;
  availityDefaultPlaceOfService?: string | null;
  availityDefaultSubmitterId?: string | null;
  availityRequiresTaxId?: boolean | null;
  availitySupportedServiceTypes?: TradingPartnerCreateavailitySupportedServiceTypesInput | null;
  availityMaxServiceTypes?: number | null;
  supportsMultipleServiceTypeCodes?: boolean | null;
  primaryClearingHouse?: Clearinghouse | null;
  name: string;
}

export interface TradingPartnerCreateavailitySupportedServiceTypesInput {
  set: string[];
}

export interface TradingPartnerRelationFilter {
  is?: TradingPartnerWhereInput | null;
  isNot?: TradingPartnerWhereInput | null;
}

export interface TradingPartnerUpdateOneWithoutPayersNestedInput {
  create?: TradingPartnerCreateWithoutPayersInput | null;
  connectOrCreate?: TradingPartnerCreateOrConnectWithoutPayersInput | null;
  upsert?: TradingPartnerUpsertWithoutPayersInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: TradingPartnerWhereUniqueInput | null;
  update?: TradingPartnerUpdateWithoutPayersInput | null;
}

export interface TradingPartnerUpdateWithoutPayersInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  changeTradingPartnerId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibilityPortalId?: NullableStringFieldUpdateOperationsInput | null;
  changeEligibility?: NullableBoolFieldUpdateOperationsInput | null;
  changeClaimStatus?: NullableBoolFieldUpdateOperationsInput | null;
  changeEligibilityConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  changeClaimStatusConnectivityType?: NullableEnumChangePayerConnectivityTypeFieldUpdateOperationsInput | null;
  availityPayerId?: NullableStringFieldUpdateOperationsInput | null;
  availityDefaultState?: NullableStringFieldUpdateOperationsInput | null;
  availityDefaultProviderType?: NullableStringFieldUpdateOperationsInput | null;
  availityDefaultPlaceOfService?: NullableStringFieldUpdateOperationsInput | null;
  availityDefaultSubmitterId?: NullableStringFieldUpdateOperationsInput | null;
  availityRequiresTaxId?: BoolFieldUpdateOperationsInput | null;
  availitySupportedServiceTypes?: TradingPartnerUpdateavailitySupportedServiceTypesInput | null;
  availityMaxServiceTypes?: NullableIntFieldUpdateOperationsInput | null;
  supportsMultipleServiceTypeCodes?: BoolFieldUpdateOperationsInput | null;
  primaryClearingHouse?: NullableEnumClearinghouseFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
}

export interface TradingPartnerUpdateavailitySupportedServiceTypesInput {
  set?: string[] | null;
  push?: string[] | null;
}

export interface TradingPartnerUpsertWithoutPayersInput {
  update: TradingPartnerUpdateWithoutPayersInput;
  create: TradingPartnerCreateWithoutPayersInput;
}

export interface TradingPartnerWhereInput {
  AND?: TradingPartnerWhereInput[] | null;
  OR?: TradingPartnerWhereInput[] | null;
  NOT?: TradingPartnerWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  changeTradingPartnerId?: StringNullableFilter | null;
  changeEligibilityPortalId?: StringNullableFilter | null;
  changeEligibility?: BoolNullableFilter | null;
  changeClaimStatus?: BoolNullableFilter | null;
  changeEligibilityConnectivityType?: EnumChangePayerConnectivityTypeNullableFilter | null;
  changeClaimStatusConnectivityType?: EnumChangePayerConnectivityTypeNullableFilter | null;
  availityPayerId?: StringNullableFilter | null;
  availityDefaultState?: StringNullableFilter | null;
  availityDefaultProviderType?: StringNullableFilter | null;
  availityDefaultPlaceOfService?: StringNullableFilter | null;
  availityDefaultSubmitterId?: StringNullableFilter | null;
  availityRequiresTaxId?: BoolFilter | null;
  availitySupportedServiceTypes?: StringNullableListFilter | null;
  availityMaxServiceTypes?: IntNullableFilter | null;
  supportsMultipleServiceTypeCodes?: BoolFilter | null;
  primaryClearingHouse?: EnumClearinghouseNullableFilter | null;
  name?: StringFilter | null;
  payers?: PayerListRelationFilter | null;
}

export interface TradingPartnerWhereUniqueInput {
  id?: string | null;
  name?: string | null;
}

export interface TransactionCreateManyAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  transactedAt?: any | null;
  appointmentId?: string | null;
  patientAmount: number;
  insuranceAmount: number;
  type: TransactionType;
  description?: string | null;
  customCode?: string | null;
}

export interface TransactionCreateManyAccountInputEnvelope {
  data: TransactionCreateManyAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface TransactionCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  transactedAt?: any | null;
  accountId: string;
  patientAmount: number;
  insuranceAmount: number;
  type: TransactionType;
  description?: string | null;
  customCode?: string | null;
}

export interface TransactionCreateManyAppointmentInputEnvelope {
  data: TransactionCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface TransactionCreateNestedManyWithoutAccountInput {
  create?: TransactionCreateWithoutAccountInput[] | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutAccountInput[] | null;
  createMany?: TransactionCreateManyAccountInputEnvelope | null;
  connect?: TransactionWhereUniqueInput[] | null;
}

export interface TransactionCreateNestedManyWithoutAppointmentInput {
  create?: TransactionCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: TransactionCreateManyAppointmentInputEnvelope | null;
  connect?: TransactionWhereUniqueInput[] | null;
}

export interface TransactionCreateNestedOneWithoutBillPaymentsInput {
  create?: TransactionCreateWithoutBillPaymentsInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutBillPaymentsInput | null;
  connect?: TransactionWhereUniqueInput | null;
}

export interface TransactionCreateNestedOneWithoutChargeAllocationsInput {
  create?: TransactionCreateWithoutChargeAllocationsInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutChargeAllocationsInput | null;
  connect?: TransactionWhereUniqueInput | null;
}

export interface TransactionCreateNestedOneWithoutChargeInput {
  create?: TransactionCreateWithoutChargeInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutChargeInput | null;
  connect?: TransactionWhereUniqueInput | null;
}

export interface TransactionCreateNestedOneWithoutIntegrationLinksInput {
  create?: TransactionCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutIntegrationLinksInput | null;
  connect?: TransactionWhereUniqueInput | null;
}

export interface TransactionCreateNestedOneWithoutPaymentAllocationsInput {
  create?: TransactionCreateWithoutPaymentAllocationsInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutPaymentAllocationsInput | null;
  connect?: TransactionWhereUniqueInput | null;
}

export interface TransactionCreateNestedOneWithoutPaymentInput {
  create?: TransactionCreateWithoutPaymentInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutPaymentInput | null;
  connect?: TransactionWhereUniqueInput | null;
}

export interface TransactionCreateOrConnectWithoutAccountInput {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateWithoutAccountInput;
}

export interface TransactionCreateOrConnectWithoutAppointmentInput {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateWithoutAppointmentInput;
}

export interface TransactionCreateOrConnectWithoutBillPaymentsInput {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateWithoutBillPaymentsInput;
}

export interface TransactionCreateOrConnectWithoutChargeAllocationsInput {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateWithoutChargeAllocationsInput;
}

export interface TransactionCreateOrConnectWithoutChargeInput {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateWithoutChargeInput;
}

export interface TransactionCreateOrConnectWithoutIntegrationLinksInput {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateWithoutIntegrationLinksInput;
}

export interface TransactionCreateOrConnectWithoutPaymentAllocationsInput {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateWithoutPaymentAllocationsInput;
}

export interface TransactionCreateOrConnectWithoutPaymentInput {
  where: TransactionWhereUniqueInput;
  create: TransactionCreateWithoutPaymentInput;
}

export interface TransactionCreateWithoutAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  transactedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: TransactionType;
  description?: string | null;
  customCode?: string | null;
  appointment?: AppointmentCreateNestedOneWithoutTransactionsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutTransactionInput | null;
  charge?: ChargeCreateNestedOneWithoutTransactionInput | null;
  payment?: PaymentCreateNestedOneWithoutTransactionInput | null;
  adjustment?: AdjustmentCreateNestedOneWithoutTransactionInput | null;
  chargeAllocations?: ChargePaymentCreateNestedManyWithoutChargeTransactionInput | null;
  paymentAllocations?: ChargePaymentCreateNestedManyWithoutPaymentTransactionInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentTransactionInput | null;
}

export interface TransactionCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  transactedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: TransactionType;
  description?: string | null;
  customCode?: string | null;
  account: AccountCreateNestedOneWithoutTransactionsInput;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutTransactionInput | null;
  charge?: ChargeCreateNestedOneWithoutTransactionInput | null;
  payment?: PaymentCreateNestedOneWithoutTransactionInput | null;
  adjustment?: AdjustmentCreateNestedOneWithoutTransactionInput | null;
  chargeAllocations?: ChargePaymentCreateNestedManyWithoutChargeTransactionInput | null;
  paymentAllocations?: ChargePaymentCreateNestedManyWithoutPaymentTransactionInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentTransactionInput | null;
}

export interface TransactionCreateWithoutBillPaymentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  transactedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: TransactionType;
  description?: string | null;
  customCode?: string | null;
  account: AccountCreateNestedOneWithoutTransactionsInput;
  appointment?: AppointmentCreateNestedOneWithoutTransactionsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutTransactionInput | null;
  charge?: ChargeCreateNestedOneWithoutTransactionInput | null;
  payment?: PaymentCreateNestedOneWithoutTransactionInput | null;
  adjustment?: AdjustmentCreateNestedOneWithoutTransactionInput | null;
  chargeAllocations?: ChargePaymentCreateNestedManyWithoutChargeTransactionInput | null;
  paymentAllocations?: ChargePaymentCreateNestedManyWithoutPaymentTransactionInput | null;
}

export interface TransactionCreateWithoutChargeAllocationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  transactedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: TransactionType;
  description?: string | null;
  customCode?: string | null;
  account: AccountCreateNestedOneWithoutTransactionsInput;
  appointment?: AppointmentCreateNestedOneWithoutTransactionsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutTransactionInput | null;
  charge?: ChargeCreateNestedOneWithoutTransactionInput | null;
  payment?: PaymentCreateNestedOneWithoutTransactionInput | null;
  adjustment?: AdjustmentCreateNestedOneWithoutTransactionInput | null;
  paymentAllocations?: ChargePaymentCreateNestedManyWithoutPaymentTransactionInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentTransactionInput | null;
}

export interface TransactionCreateWithoutChargeInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  transactedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: TransactionType;
  description?: string | null;
  customCode?: string | null;
  account: AccountCreateNestedOneWithoutTransactionsInput;
  appointment?: AppointmentCreateNestedOneWithoutTransactionsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutTransactionInput | null;
  payment?: PaymentCreateNestedOneWithoutTransactionInput | null;
  adjustment?: AdjustmentCreateNestedOneWithoutTransactionInput | null;
  chargeAllocations?: ChargePaymentCreateNestedManyWithoutChargeTransactionInput | null;
  paymentAllocations?: ChargePaymentCreateNestedManyWithoutPaymentTransactionInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentTransactionInput | null;
}

export interface TransactionCreateWithoutIntegrationLinksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  transactedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: TransactionType;
  description?: string | null;
  customCode?: string | null;
  account: AccountCreateNestedOneWithoutTransactionsInput;
  appointment?: AppointmentCreateNestedOneWithoutTransactionsInput | null;
  charge?: ChargeCreateNestedOneWithoutTransactionInput | null;
  payment?: PaymentCreateNestedOneWithoutTransactionInput | null;
  adjustment?: AdjustmentCreateNestedOneWithoutTransactionInput | null;
  chargeAllocations?: ChargePaymentCreateNestedManyWithoutChargeTransactionInput | null;
  paymentAllocations?: ChargePaymentCreateNestedManyWithoutPaymentTransactionInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentTransactionInput | null;
}

export interface TransactionCreateWithoutPaymentAllocationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  transactedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: TransactionType;
  description?: string | null;
  customCode?: string | null;
  account: AccountCreateNestedOneWithoutTransactionsInput;
  appointment?: AppointmentCreateNestedOneWithoutTransactionsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutTransactionInput | null;
  charge?: ChargeCreateNestedOneWithoutTransactionInput | null;
  payment?: PaymentCreateNestedOneWithoutTransactionInput | null;
  adjustment?: AdjustmentCreateNestedOneWithoutTransactionInput | null;
  chargeAllocations?: ChargePaymentCreateNestedManyWithoutChargeTransactionInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentTransactionInput | null;
}

export interface TransactionCreateWithoutPaymentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  transactedAt?: any | null;
  patientAmount: number;
  insuranceAmount: number;
  type: TransactionType;
  description?: string | null;
  customCode?: string | null;
  account: AccountCreateNestedOneWithoutTransactionsInput;
  appointment?: AppointmentCreateNestedOneWithoutTransactionsInput | null;
  integrationLinks?: IntegrationLinkCreateNestedManyWithoutTransactionInput | null;
  charge?: ChargeCreateNestedOneWithoutTransactionInput | null;
  adjustment?: AdjustmentCreateNestedOneWithoutTransactionInput | null;
  chargeAllocations?: ChargePaymentCreateNestedManyWithoutChargeTransactionInput | null;
  paymentAllocations?: ChargePaymentCreateNestedManyWithoutPaymentTransactionInput | null;
  billPayments?: BillPaymentCreateNestedManyWithoutPaymentTransactionInput | null;
}

export interface TransactionListRelationFilter {
  every?: TransactionWhereInput | null;
  some?: TransactionWhereInput | null;
  none?: TransactionWhereInput | null;
}

export interface TransactionOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface TransactionRelationFilter {
  is?: TransactionWhereInput | null;
  isNot?: TransactionWhereInput | null;
}

export interface TransactionScalarWhereInput {
  AND?: TransactionScalarWhereInput[] | null;
  OR?: TransactionScalarWhereInput[] | null;
  NOT?: TransactionScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  transactedAt?: DateTimeFilter | null;
  accountId?: UuidFilter | null;
  appointmentId?: UuidNullableFilter | null;
  patientAmount?: IntFilter | null;
  insuranceAmount?: IntFilter | null;
  type?: EnumTransactionTypeFilter | null;
  description?: StringNullableFilter | null;
  customCode?: StringNullableFilter | null;
}

export interface TransactionUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  transactedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumTransactionTypeFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
}

export interface TransactionUpdateManyWithWhereWithoutAccountInput {
  where: TransactionScalarWhereInput;
  data: TransactionUpdateManyMutationInput;
}

export interface TransactionUpdateManyWithWhereWithoutAppointmentInput {
  where: TransactionScalarWhereInput;
  data: TransactionUpdateManyMutationInput;
}

export interface TransactionUpdateManyWithoutAccountNestedInput {
  create?: TransactionCreateWithoutAccountInput[] | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutAccountInput[] | null;
  upsert?: TransactionUpsertWithWhereUniqueWithoutAccountInput[] | null;
  createMany?: TransactionCreateManyAccountInputEnvelope | null;
  set?: TransactionWhereUniqueInput[] | null;
  disconnect?: TransactionWhereUniqueInput[] | null;
  delete?: TransactionWhereUniqueInput[] | null;
  connect?: TransactionWhereUniqueInput[] | null;
  update?: TransactionUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: TransactionUpdateManyWithWhereWithoutAccountInput[] | null;
  deleteMany?: TransactionScalarWhereInput[] | null;
}

export interface TransactionUpdateManyWithoutAppointmentNestedInput {
  create?: TransactionCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: TransactionUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: TransactionCreateManyAppointmentInputEnvelope | null;
  set?: TransactionWhereUniqueInput[] | null;
  disconnect?: TransactionWhereUniqueInput[] | null;
  delete?: TransactionWhereUniqueInput[] | null;
  connect?: TransactionWhereUniqueInput[] | null;
  update?: TransactionUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: TransactionUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: TransactionScalarWhereInput[] | null;
}

export interface TransactionUpdateOneRequiredWithoutBillPaymentsNestedInput {
  create?: TransactionCreateWithoutBillPaymentsInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutBillPaymentsInput | null;
  upsert?: TransactionUpsertWithoutBillPaymentsInput | null;
  connect?: TransactionWhereUniqueInput | null;
  update?: TransactionUpdateWithoutBillPaymentsInput | null;
}

export interface TransactionUpdateOneRequiredWithoutChargeAllocationsNestedInput {
  create?: TransactionCreateWithoutChargeAllocationsInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutChargeAllocationsInput | null;
  upsert?: TransactionUpsertWithoutChargeAllocationsInput | null;
  connect?: TransactionWhereUniqueInput | null;
  update?: TransactionUpdateWithoutChargeAllocationsInput | null;
}

export interface TransactionUpdateOneRequiredWithoutChargeNestedInput {
  create?: TransactionCreateWithoutChargeInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutChargeInput | null;
  upsert?: TransactionUpsertWithoutChargeInput | null;
  connect?: TransactionWhereUniqueInput | null;
  update?: TransactionUpdateWithoutChargeInput | null;
}

export interface TransactionUpdateOneRequiredWithoutPaymentAllocationsNestedInput {
  create?: TransactionCreateWithoutPaymentAllocationsInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutPaymentAllocationsInput | null;
  upsert?: TransactionUpsertWithoutPaymentAllocationsInput | null;
  connect?: TransactionWhereUniqueInput | null;
  update?: TransactionUpdateWithoutPaymentAllocationsInput | null;
}

export interface TransactionUpdateOneRequiredWithoutPaymentNestedInput {
  create?: TransactionCreateWithoutPaymentInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutPaymentInput | null;
  upsert?: TransactionUpsertWithoutPaymentInput | null;
  connect?: TransactionWhereUniqueInput | null;
  update?: TransactionUpdateWithoutPaymentInput | null;
}

export interface TransactionUpdateOneWithoutIntegrationLinksNestedInput {
  create?: TransactionCreateWithoutIntegrationLinksInput | null;
  connectOrCreate?: TransactionCreateOrConnectWithoutIntegrationLinksInput | null;
  upsert?: TransactionUpsertWithoutIntegrationLinksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: TransactionWhereUniqueInput | null;
  update?: TransactionUpdateWithoutIntegrationLinksInput | null;
}

export interface TransactionUpdateWithWhereUniqueWithoutAccountInput {
  where: TransactionWhereUniqueInput;
  data: TransactionUpdateWithoutAccountInput;
}

export interface TransactionUpdateWithWhereUniqueWithoutAppointmentInput {
  where: TransactionWhereUniqueInput;
  data: TransactionUpdateWithoutAppointmentInput;
}

export interface TransactionUpdateWithoutAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  transactedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumTransactionTypeFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  appointment?: AppointmentUpdateOneWithoutTransactionsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutTransactionNestedInput | null;
  charge?: ChargeUpdateOneWithoutTransactionNestedInput | null;
  payment?: PaymentUpdateOneWithoutTransactionNestedInput | null;
  adjustment?: AdjustmentUpdateOneWithoutTransactionNestedInput | null;
  chargeAllocations?: ChargePaymentUpdateManyWithoutChargeTransactionNestedInput | null;
  paymentAllocations?: ChargePaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
}

export interface TransactionUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  transactedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumTransactionTypeFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutTransactionsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutTransactionNestedInput | null;
  charge?: ChargeUpdateOneWithoutTransactionNestedInput | null;
  payment?: PaymentUpdateOneWithoutTransactionNestedInput | null;
  adjustment?: AdjustmentUpdateOneWithoutTransactionNestedInput | null;
  chargeAllocations?: ChargePaymentUpdateManyWithoutChargeTransactionNestedInput | null;
  paymentAllocations?: ChargePaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
}

export interface TransactionUpdateWithoutBillPaymentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  transactedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumTransactionTypeFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutTransactionsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutTransactionsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutTransactionNestedInput | null;
  charge?: ChargeUpdateOneWithoutTransactionNestedInput | null;
  payment?: PaymentUpdateOneWithoutTransactionNestedInput | null;
  adjustment?: AdjustmentUpdateOneWithoutTransactionNestedInput | null;
  chargeAllocations?: ChargePaymentUpdateManyWithoutChargeTransactionNestedInput | null;
  paymentAllocations?: ChargePaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
}

export interface TransactionUpdateWithoutChargeAllocationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  transactedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumTransactionTypeFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutTransactionsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutTransactionsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutTransactionNestedInput | null;
  charge?: ChargeUpdateOneWithoutTransactionNestedInput | null;
  payment?: PaymentUpdateOneWithoutTransactionNestedInput | null;
  adjustment?: AdjustmentUpdateOneWithoutTransactionNestedInput | null;
  paymentAllocations?: ChargePaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
}

export interface TransactionUpdateWithoutChargeInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  transactedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumTransactionTypeFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutTransactionsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutTransactionsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutTransactionNestedInput | null;
  payment?: PaymentUpdateOneWithoutTransactionNestedInput | null;
  adjustment?: AdjustmentUpdateOneWithoutTransactionNestedInput | null;
  chargeAllocations?: ChargePaymentUpdateManyWithoutChargeTransactionNestedInput | null;
  paymentAllocations?: ChargePaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
}

export interface TransactionUpdateWithoutIntegrationLinksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  transactedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumTransactionTypeFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutTransactionsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutTransactionsNestedInput | null;
  charge?: ChargeUpdateOneWithoutTransactionNestedInput | null;
  payment?: PaymentUpdateOneWithoutTransactionNestedInput | null;
  adjustment?: AdjustmentUpdateOneWithoutTransactionNestedInput | null;
  chargeAllocations?: ChargePaymentUpdateManyWithoutChargeTransactionNestedInput | null;
  paymentAllocations?: ChargePaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
}

export interface TransactionUpdateWithoutPaymentAllocationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  transactedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumTransactionTypeFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutTransactionsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutTransactionsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutTransactionNestedInput | null;
  charge?: ChargeUpdateOneWithoutTransactionNestedInput | null;
  payment?: PaymentUpdateOneWithoutTransactionNestedInput | null;
  adjustment?: AdjustmentUpdateOneWithoutTransactionNestedInput | null;
  chargeAllocations?: ChargePaymentUpdateManyWithoutChargeTransactionNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
}

export interface TransactionUpdateWithoutPaymentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  transactedAt?: DateTimeFieldUpdateOperationsInput | null;
  patientAmount?: IntFieldUpdateOperationsInput | null;
  insuranceAmount?: IntFieldUpdateOperationsInput | null;
  type?: EnumTransactionTypeFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  customCode?: NullableStringFieldUpdateOperationsInput | null;
  account?: AccountUpdateOneRequiredWithoutTransactionsNestedInput | null;
  appointment?: AppointmentUpdateOneWithoutTransactionsNestedInput | null;
  integrationLinks?: IntegrationLinkUpdateManyWithoutTransactionNestedInput | null;
  charge?: ChargeUpdateOneWithoutTransactionNestedInput | null;
  adjustment?: AdjustmentUpdateOneWithoutTransactionNestedInput | null;
  chargeAllocations?: ChargePaymentUpdateManyWithoutChargeTransactionNestedInput | null;
  paymentAllocations?: ChargePaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
  billPayments?: BillPaymentUpdateManyWithoutPaymentTransactionNestedInput | null;
}

export interface TransactionUpsertWithWhereUniqueWithoutAccountInput {
  where: TransactionWhereUniqueInput;
  update: TransactionUpdateWithoutAccountInput;
  create: TransactionCreateWithoutAccountInput;
}

export interface TransactionUpsertWithWhereUniqueWithoutAppointmentInput {
  where: TransactionWhereUniqueInput;
  update: TransactionUpdateWithoutAppointmentInput;
  create: TransactionCreateWithoutAppointmentInput;
}

export interface TransactionUpsertWithoutBillPaymentsInput {
  update: TransactionUpdateWithoutBillPaymentsInput;
  create: TransactionCreateWithoutBillPaymentsInput;
}

export interface TransactionUpsertWithoutChargeAllocationsInput {
  update: TransactionUpdateWithoutChargeAllocationsInput;
  create: TransactionCreateWithoutChargeAllocationsInput;
}

export interface TransactionUpsertWithoutChargeInput {
  update: TransactionUpdateWithoutChargeInput;
  create: TransactionCreateWithoutChargeInput;
}

export interface TransactionUpsertWithoutIntegrationLinksInput {
  update: TransactionUpdateWithoutIntegrationLinksInput;
  create: TransactionCreateWithoutIntegrationLinksInput;
}

export interface TransactionUpsertWithoutPaymentAllocationsInput {
  update: TransactionUpdateWithoutPaymentAllocationsInput;
  create: TransactionCreateWithoutPaymentAllocationsInput;
}

export interface TransactionUpsertWithoutPaymentInput {
  update: TransactionUpdateWithoutPaymentInput;
  create: TransactionCreateWithoutPaymentInput;
}

export interface TransactionWhereInput {
  AND?: TransactionWhereInput[] | null;
  OR?: TransactionWhereInput[] | null;
  NOT?: TransactionWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  transactedAt?: DateTimeFilter | null;
  accountId?: UuidFilter | null;
  appointmentId?: UuidNullableFilter | null;
  patientAmount?: IntFilter | null;
  insuranceAmount?: IntFilter | null;
  type?: EnumTransactionTypeFilter | null;
  description?: StringNullableFilter | null;
  customCode?: StringNullableFilter | null;
  account?: AccountRelationFilter | null;
  appointment?: AppointmentRelationFilter | null;
  integrationLinks?: IntegrationLinkListRelationFilter | null;
  charge?: ChargeRelationFilter | null;
  payment?: PaymentRelationFilter | null;
  adjustment?: AdjustmentRelationFilter | null;
  chargeAllocations?: ChargePaymentListRelationFilter | null;
  paymentAllocations?: ChargePaymentListRelationFilter | null;
  billPayments?: BillPaymentListRelationFilter | null;
}

export interface TransactionWhereUniqueInput {
  id?: string | null;
}

export interface UpsertChargemasterGroupsInput {
  id?: string | null;
  code: string;
  description: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  cashPay: boolean;
  amount: number;
}

export interface UserCreateManyActiveLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  organizationId: string;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
}

export interface UserCreateManyActiveLocationInputEnvelope {
  data: UserCreateManyActiveLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface UserCreateManyOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  activeLocationId: string;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
}

export interface UserCreateManyOrganizationInputEnvelope {
  data: UserCreateManyOrganizationInput[];
  skipDuplicates?: boolean | null;
}

export interface UserCreateNestedManyWithoutActiveLocationInput {
  create?: UserCreateWithoutActiveLocationInput[] | null;
  connectOrCreate?: UserCreateOrConnectWithoutActiveLocationInput[] | null;
  createMany?: UserCreateManyActiveLocationInputEnvelope | null;
  connect?: UserWhereUniqueInput[] | null;
}

export interface UserCreateNestedManyWithoutOrganizationInput {
  create?: UserCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: UserCreateOrConnectWithoutOrganizationInput[] | null;
  createMany?: UserCreateManyOrganizationInputEnvelope | null;
  connect?: UserWhereUniqueInput[] | null;
}

export interface UserCreateNestedOneWithoutAcceptedInsurancePoliciesInput {
  create?: UserCreateWithoutAcceptedInsurancePoliciesInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutAcceptedInsurancePoliciesInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutAppointmentBenefitAssignmentsInput {
  create?: UserCreateWithoutAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutAppointmentBenefitAssignmentsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCanceledInsurancePolicyWorklistItemsInput {
  create?: UserCreateWithoutCanceledInsurancePolicyWorklistItemsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCanceledInsurancePolicyWorklistItemsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCanceledPaymentRequestBatchesInput {
  create?: UserCreateWithoutCanceledPaymentRequestBatchesInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCanceledPaymentRequestBatchesInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCanceledTasksInput {
  create?: UserCreateWithoutCanceledTasksInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCanceledTasksInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCompletedInsurancePolicyWorklistItemTaskasInput {
  create?: UserCreateWithoutCompletedInsurancePolicyWorklistItemTaskasInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCompletedInsurancePolicyWorklistItemTaskasInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCompletedInsurancePolicyWorklistItemsInput {
  create?: UserCreateWithoutCompletedInsurancePolicyWorklistItemsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCompletedInsurancePolicyWorklistItemsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCompletedTasksInput {
  create?: UserCreateWithoutCompletedTasksInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCompletedTasksInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCoverageBenefitsInput {
  create?: UserCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCoverageBenefitsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCreatedBenefitMappingsInput {
  create?: UserCreateWithoutCreatedBenefitMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedBenefitMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCreatedChargeTemplateMappingsInput {
  create?: UserCreateWithoutCreatedChargeTemplateMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedChargeTemplateMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCreatedDepositMappingsInput {
  create?: UserCreateWithoutCreatedDepositMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedDepositMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCreatedFeeScheduleMappingsInput {
  create?: UserCreateWithoutCreatedFeeScheduleMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedFeeScheduleMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCreatedPaymentRequestBatchesInput {
  create?: UserCreateWithoutCreatedPaymentRequestBatchesInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedPaymentRequestBatchesInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCreatedPreVisitMappingsInput {
  create?: UserCreateWithoutCreatedPreVisitMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedPreVisitMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutCreatedUserEnteredBenefitsInput {
  create?: UserCreateWithoutCreatedUserEnteredBenefitsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedUserEnteredBenefitsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutEligibilityRequestsInput {
  create?: UserCreateWithoutEligibilityRequestsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutEligibilityRequestsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutEstimatesInput {
  create?: UserCreateWithoutEstimatesInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutEstimatesInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutFeedbacksInput {
  create?: UserCreateWithoutFeedbacksInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutFeedbacksInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutIntegrationSyncsInput {
  create?: UserCreateWithoutIntegrationSyncsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutIntegrationSyncsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutLastUpdatedUserEnteredBenefitsInput {
  create?: UserCreateWithoutLastUpdatedUserEnteredBenefitsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutLastUpdatedUserEnteredBenefitsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutSentCommunicationsInput {
  create?: UserCreateWithoutSentCommunicationsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutSentCommunicationsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutSkippedInsurancePolicyWorklistItemTasksInput {
  create?: UserCreateWithoutSkippedInsurancePolicyWorklistItemTasksInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutSkippedInsurancePolicyWorklistItemTasksInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutSnoozedTasksInput {
  create?: UserCreateWithoutSnoozedTasksInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutSnoozedTasksInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutUpdatedBenefitMappingsInput {
  create?: UserCreateWithoutUpdatedBenefitMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutUpdatedBenefitMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutUpdatedChargeTemplateMappingsInput {
  create?: UserCreateWithoutUpdatedChargeTemplateMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutUpdatedChargeTemplateMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutUpdatedDepositMappingsInput {
  create?: UserCreateWithoutUpdatedDepositMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutUpdatedDepositMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutUpdatedFeeScheduleMappingsInput {
  create?: UserCreateWithoutUpdatedFeeScheduleMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutUpdatedFeeScheduleMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutUpdatedPreVisitMappingsInput {
  create?: UserCreateWithoutUpdatedPreVisitMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutUpdatedPreVisitMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateNestedOneWithoutVisitCollectionRequestsInput {
  create?: UserCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  connect?: UserWhereUniqueInput | null;
}

export interface UserCreateOrConnectWithoutAcceptedInsurancePoliciesInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutAcceptedInsurancePoliciesInput;
}

export interface UserCreateOrConnectWithoutActiveLocationInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutActiveLocationInput;
}

export interface UserCreateOrConnectWithoutAppointmentBenefitAssignmentsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutAppointmentBenefitAssignmentsInput;
}

export interface UserCreateOrConnectWithoutCanceledInsurancePolicyWorklistItemsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCanceledInsurancePolicyWorklistItemsInput;
}

export interface UserCreateOrConnectWithoutCanceledPaymentRequestBatchesInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCanceledPaymentRequestBatchesInput;
}

export interface UserCreateOrConnectWithoutCanceledTasksInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCanceledTasksInput;
}

export interface UserCreateOrConnectWithoutCompletedInsurancePolicyWorklistItemTaskasInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCompletedInsurancePolicyWorklistItemTaskasInput;
}

export interface UserCreateOrConnectWithoutCompletedInsurancePolicyWorklistItemsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCompletedInsurancePolicyWorklistItemsInput;
}

export interface UserCreateOrConnectWithoutCompletedTasksInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCompletedTasksInput;
}

export interface UserCreateOrConnectWithoutCoverageBenefitsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCoverageBenefitsInput;
}

export interface UserCreateOrConnectWithoutCreatedBenefitMappingsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCreatedBenefitMappingsInput;
}

export interface UserCreateOrConnectWithoutCreatedChargeTemplateMappingsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCreatedChargeTemplateMappingsInput;
}

export interface UserCreateOrConnectWithoutCreatedDepositMappingsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCreatedDepositMappingsInput;
}

export interface UserCreateOrConnectWithoutCreatedFeeScheduleMappingsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCreatedFeeScheduleMappingsInput;
}

export interface UserCreateOrConnectWithoutCreatedPaymentRequestBatchesInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCreatedPaymentRequestBatchesInput;
}

export interface UserCreateOrConnectWithoutCreatedPreVisitMappingsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCreatedPreVisitMappingsInput;
}

export interface UserCreateOrConnectWithoutCreatedUserEnteredBenefitsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCreatedUserEnteredBenefitsInput;
}

export interface UserCreateOrConnectWithoutEligibilityRequestsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEligibilityRequestsInput;
}

export interface UserCreateOrConnectWithoutEstimatesInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEstimatesInput;
}

export interface UserCreateOrConnectWithoutFeedbacksInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutFeedbacksInput;
}

export interface UserCreateOrConnectWithoutIntegrationSyncsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutIntegrationSyncsInput;
}

export interface UserCreateOrConnectWithoutLastUpdatedUserEnteredBenefitsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutLastUpdatedUserEnteredBenefitsInput;
}

export interface UserCreateOrConnectWithoutOrganizationInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutOrganizationInput;
}

export interface UserCreateOrConnectWithoutSentCommunicationsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSentCommunicationsInput;
}

export interface UserCreateOrConnectWithoutSkippedInsurancePolicyWorklistItemTasksInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSkippedInsurancePolicyWorklistItemTasksInput;
}

export interface UserCreateOrConnectWithoutSnoozedTasksInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSnoozedTasksInput;
}

export interface UserCreateOrConnectWithoutUpdatedBenefitMappingsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutUpdatedBenefitMappingsInput;
}

export interface UserCreateOrConnectWithoutUpdatedChargeTemplateMappingsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutUpdatedChargeTemplateMappingsInput;
}

export interface UserCreateOrConnectWithoutUpdatedDepositMappingsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutUpdatedDepositMappingsInput;
}

export interface UserCreateOrConnectWithoutUpdatedFeeScheduleMappingsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutUpdatedFeeScheduleMappingsInput;
}

export interface UserCreateOrConnectWithoutUpdatedPreVisitMappingsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutUpdatedPreVisitMappingsInput;
}

export interface UserCreateOrConnectWithoutVisitCollectionRequestsInput {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutVisitCollectionRequestsInput;
}

export interface UserCreateWithoutAcceptedInsurancePoliciesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutActiveLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutAppointmentBenefitAssignmentsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCanceledInsurancePolicyWorklistItemsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCanceledPaymentRequestBatchesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCanceledTasksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCompletedInsurancePolicyWorklistItemTaskasInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCompletedInsurancePolicyWorklistItemsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCompletedTasksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCoverageBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCreatedBenefitMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCreatedChargeTemplateMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCreatedDepositMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCreatedFeeScheduleMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCreatedPaymentRequestBatchesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCreatedPreVisitMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutCreatedUserEnteredBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutEligibilityRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutEstimatesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutFeedbacksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutIntegrationSyncsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutLastUpdatedUserEnteredBenefitsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutOrganizationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutSentCommunicationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutSkippedInsurancePolicyWorklistItemTasksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutSnoozedTasksInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutUpdatedBenefitMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutUpdatedChargeTemplateMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutUpdatedDepositMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutUpdatedFeeScheduleMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutUpdatedPreVisitMappingsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  visitCollectionRequests?: VisitCollectionRequestCreateNestedManyWithoutCreatedByInput | null;
}

export interface UserCreateWithoutVisitCollectionRequestsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email: string;
  password?: string | null;
  firstName: string;
  lastName: string;
  isAdmin?: boolean | null;
  deactivatedAt?: any | null;
  appointmentViewPreferences?: any | null;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  activeLocation: LocationCreateNestedOneWithoutUsersInput;
  sentCommunications?: CommunicationCreateNestedManyWithoutSentByInput | null;
  eligibilityRequests?: EligibilityRequestCreateNestedManyWithoutRequestedByInput | null;
  feedbacks?: FeedbackCreateNestedManyWithoutUserInput | null;
  integrationSyncs?: IntegrationSyncCreateNestedManyWithoutUserInput | null;
  estimates?: EstimateCreateNestedManyWithoutCreatedByInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutCreatedByInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCreatedByInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchCreateNestedManyWithoutCanceledByInput | null;
  completedTasks?: TaskCreateNestedManyWithoutCompletedByInput | null;
  canceledTasks?: TaskCreateNestedManyWithoutCanceledByInput | null;
  snoozedTasks?: TaskCreateNestedManyWithoutSnoozedByInput | null;
  coverageBenefits?: CoverageBenefitCreateNestedManyWithoutCreatedByInput | null;
  acceptedInsurancePolicies?: InsurancePolicyCreateNestedManyWithoutAcceptedByInput | null;
  createdBenefitMappings?: BenefitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedBenefitMappings?: BenefitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdDepositMappings?: DepositMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedDepositMappings?: DepositMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentCreateNestedManyWithoutCreatedByInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCanceledByInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemCreateNestedManyWithoutCompletedByInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutCompletedByInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskCreateNestedManyWithoutSkippedByInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingCreateNestedManyWithoutLastUpdatedByInput | null;
  createdPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutCreatedByInput | null;
  updatedPreVisitMappings?: PreVisitMappingCreateNestedManyWithoutLastUpdatedByInput | null;
}

export interface UserEnteredBenefitCreateManyAccountCoverageInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  lastUpdatedById: string;
  insurancePolicyId?: string | null;
  type: UserEnteredBenefitType;
  coinsurance?: any | null;
  copay?: number | null;
  remainingOutOfPocket?: number | null;
  remainingDeductible?: number | null;
  remainingVisits?: number | null;
  note?: string | null;
}

export interface UserEnteredBenefitCreateManyAccountCoverageInputEnvelope {
  data: UserEnteredBenefitCreateManyAccountCoverageInput[];
  skipDuplicates?: boolean | null;
}

export interface UserEnteredBenefitCreateManyCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  lastUpdatedById: string;
  accountCoverageId?: string | null;
  insurancePolicyId?: string | null;
  type: UserEnteredBenefitType;
  coinsurance?: any | null;
  copay?: number | null;
  remainingOutOfPocket?: number | null;
  remainingDeductible?: number | null;
  remainingVisits?: number | null;
  note?: string | null;
}

export interface UserEnteredBenefitCreateManyCreatedByInputEnvelope {
  data: UserEnteredBenefitCreateManyCreatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface UserEnteredBenefitCreateManyInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  lastUpdatedById: string;
  accountCoverageId?: string | null;
  type: UserEnteredBenefitType;
  coinsurance?: any | null;
  copay?: number | null;
  remainingOutOfPocket?: number | null;
  remainingDeductible?: number | null;
  remainingVisits?: number | null;
  note?: string | null;
}

export interface UserEnteredBenefitCreateManyInsurancePolicyInputEnvelope {
  data: UserEnteredBenefitCreateManyInsurancePolicyInput[];
  skipDuplicates?: boolean | null;
}

export interface UserEnteredBenefitCreateManyLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  createdById: string;
  accountCoverageId?: string | null;
  insurancePolicyId?: string | null;
  type: UserEnteredBenefitType;
  coinsurance?: any | null;
  copay?: number | null;
  remainingOutOfPocket?: number | null;
  remainingDeductible?: number | null;
  remainingVisits?: number | null;
  note?: string | null;
}

export interface UserEnteredBenefitCreateManyLastUpdatedByInputEnvelope {
  data: UserEnteredBenefitCreateManyLastUpdatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface UserEnteredBenefitCreateNestedManyWithoutAccountCoverageInput {
  create?: UserEnteredBenefitCreateWithoutAccountCoverageInput[] | null;
  connectOrCreate?: UserEnteredBenefitCreateOrConnectWithoutAccountCoverageInput[] | null;
  createMany?: UserEnteredBenefitCreateManyAccountCoverageInputEnvelope | null;
  connect?: UserEnteredBenefitWhereUniqueInput[] | null;
}

export interface UserEnteredBenefitCreateNestedManyWithoutCreatedByInput {
  create?: UserEnteredBenefitCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: UserEnteredBenefitCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: UserEnteredBenefitCreateManyCreatedByInputEnvelope | null;
  connect?: UserEnteredBenefitWhereUniqueInput[] | null;
}

export interface UserEnteredBenefitCreateNestedManyWithoutInsurancePolicyInput {
  create?: UserEnteredBenefitCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: UserEnteredBenefitCreateOrConnectWithoutInsurancePolicyInput[] | null;
  createMany?: UserEnteredBenefitCreateManyInsurancePolicyInputEnvelope | null;
  connect?: UserEnteredBenefitWhereUniqueInput[] | null;
}

export interface UserEnteredBenefitCreateNestedManyWithoutLastUpdatedByInput {
  create?: UserEnteredBenefitCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: UserEnteredBenefitCreateOrConnectWithoutLastUpdatedByInput[] | null;
  createMany?: UserEnteredBenefitCreateManyLastUpdatedByInputEnvelope | null;
  connect?: UserEnteredBenefitWhereUniqueInput[] | null;
}

export interface UserEnteredBenefitCreateOrConnectWithoutAccountCoverageInput {
  where: UserEnteredBenefitWhereUniqueInput;
  create: UserEnteredBenefitCreateWithoutAccountCoverageInput;
}

export interface UserEnteredBenefitCreateOrConnectWithoutCreatedByInput {
  where: UserEnteredBenefitWhereUniqueInput;
  create: UserEnteredBenefitCreateWithoutCreatedByInput;
}

export interface UserEnteredBenefitCreateOrConnectWithoutInsurancePolicyInput {
  where: UserEnteredBenefitWhereUniqueInput;
  create: UserEnteredBenefitCreateWithoutInsurancePolicyInput;
}

export interface UserEnteredBenefitCreateOrConnectWithoutLastUpdatedByInput {
  where: UserEnteredBenefitWhereUniqueInput;
  create: UserEnteredBenefitCreateWithoutLastUpdatedByInput;
}

export interface UserEnteredBenefitCreateWithoutAccountCoverageInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: UserEnteredBenefitType;
  coinsurance?: any | null;
  copay?: number | null;
  remainingOutOfPocket?: number | null;
  remainingDeductible?: number | null;
  remainingVisits?: number | null;
  note?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedUserEnteredBenefitsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutLastUpdatedUserEnteredBenefitsInput;
  insurancePolicy?: InsurancePolicyCreateNestedOneWithoutUserEnteredBenefitsInput | null;
}

export interface UserEnteredBenefitCreateWithoutCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: UserEnteredBenefitType;
  coinsurance?: any | null;
  copay?: number | null;
  remainingOutOfPocket?: number | null;
  remainingDeductible?: number | null;
  remainingVisits?: number | null;
  note?: string | null;
  lastUpdatedBy: UserCreateNestedOneWithoutLastUpdatedUserEnteredBenefitsInput;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutUserEnteredBenefitsInput | null;
  insurancePolicy?: InsurancePolicyCreateNestedOneWithoutUserEnteredBenefitsInput | null;
}

export interface UserEnteredBenefitCreateWithoutInsurancePolicyInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: UserEnteredBenefitType;
  coinsurance?: any | null;
  copay?: number | null;
  remainingOutOfPocket?: number | null;
  remainingDeductible?: number | null;
  remainingVisits?: number | null;
  note?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedUserEnteredBenefitsInput;
  lastUpdatedBy: UserCreateNestedOneWithoutLastUpdatedUserEnteredBenefitsInput;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutUserEnteredBenefitsInput | null;
}

export interface UserEnteredBenefitCreateWithoutLastUpdatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  type: UserEnteredBenefitType;
  coinsurance?: any | null;
  copay?: number | null;
  remainingOutOfPocket?: number | null;
  remainingDeductible?: number | null;
  remainingVisits?: number | null;
  note?: string | null;
  createdBy: UserCreateNestedOneWithoutCreatedUserEnteredBenefitsInput;
  accountCoverage?: AccountCoverageCreateNestedOneWithoutUserEnteredBenefitsInput | null;
  insurancePolicy?: InsurancePolicyCreateNestedOneWithoutUserEnteredBenefitsInput | null;
}

export interface UserEnteredBenefitListRelationFilter {
  every?: UserEnteredBenefitWhereInput | null;
  some?: UserEnteredBenefitWhereInput | null;
  none?: UserEnteredBenefitWhereInput | null;
}

export interface UserEnteredBenefitScalarWhereInput {
  AND?: UserEnteredBenefitScalarWhereInput[] | null;
  OR?: UserEnteredBenefitScalarWhereInput[] | null;
  NOT?: UserEnteredBenefitScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  accountCoverageId?: UuidNullableFilter | null;
  insurancePolicyId?: UuidNullableFilter | null;
  type?: EnumUserEnteredBenefitTypeFilter | null;
  coinsurance?: DecimalNullableFilter | null;
  copay?: IntNullableFilter | null;
  remainingOutOfPocket?: IntNullableFilter | null;
  remainingDeductible?: IntNullableFilter | null;
  remainingVisits?: IntNullableFilter | null;
  note?: StringNullableFilter | null;
}

export interface UserEnteredBenefitUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumUserEnteredBenefitTypeFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
}

export interface UserEnteredBenefitUpdateManyWithWhereWithoutAccountCoverageInput {
  where: UserEnteredBenefitScalarWhereInput;
  data: UserEnteredBenefitUpdateManyMutationInput;
}

export interface UserEnteredBenefitUpdateManyWithWhereWithoutCreatedByInput {
  where: UserEnteredBenefitScalarWhereInput;
  data: UserEnteredBenefitUpdateManyMutationInput;
}

export interface UserEnteredBenefitUpdateManyWithWhereWithoutInsurancePolicyInput {
  where: UserEnteredBenefitScalarWhereInput;
  data: UserEnteredBenefitUpdateManyMutationInput;
}

export interface UserEnteredBenefitUpdateManyWithWhereWithoutLastUpdatedByInput {
  where: UserEnteredBenefitScalarWhereInput;
  data: UserEnteredBenefitUpdateManyMutationInput;
}

export interface UserEnteredBenefitUpdateManyWithoutAccountCoverageNestedInput {
  create?: UserEnteredBenefitCreateWithoutAccountCoverageInput[] | null;
  connectOrCreate?: UserEnteredBenefitCreateOrConnectWithoutAccountCoverageInput[] | null;
  upsert?: UserEnteredBenefitUpsertWithWhereUniqueWithoutAccountCoverageInput[] | null;
  createMany?: UserEnteredBenefitCreateManyAccountCoverageInputEnvelope | null;
  set?: UserEnteredBenefitWhereUniqueInput[] | null;
  disconnect?: UserEnteredBenefitWhereUniqueInput[] | null;
  delete?: UserEnteredBenefitWhereUniqueInput[] | null;
  connect?: UserEnteredBenefitWhereUniqueInput[] | null;
  update?: UserEnteredBenefitUpdateWithWhereUniqueWithoutAccountCoverageInput[] | null;
  updateMany?: UserEnteredBenefitUpdateManyWithWhereWithoutAccountCoverageInput[] | null;
  deleteMany?: UserEnteredBenefitScalarWhereInput[] | null;
}

export interface UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput {
  create?: UserEnteredBenefitCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: UserEnteredBenefitCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: UserEnteredBenefitUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: UserEnteredBenefitCreateManyCreatedByInputEnvelope | null;
  set?: UserEnteredBenefitWhereUniqueInput[] | null;
  disconnect?: UserEnteredBenefitWhereUniqueInput[] | null;
  delete?: UserEnteredBenefitWhereUniqueInput[] | null;
  connect?: UserEnteredBenefitWhereUniqueInput[] | null;
  update?: UserEnteredBenefitUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: UserEnteredBenefitUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: UserEnteredBenefitScalarWhereInput[] | null;
}

export interface UserEnteredBenefitUpdateManyWithoutInsurancePolicyNestedInput {
  create?: UserEnteredBenefitCreateWithoutInsurancePolicyInput[] | null;
  connectOrCreate?: UserEnteredBenefitCreateOrConnectWithoutInsurancePolicyInput[] | null;
  upsert?: UserEnteredBenefitUpsertWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  createMany?: UserEnteredBenefitCreateManyInsurancePolicyInputEnvelope | null;
  set?: UserEnteredBenefitWhereUniqueInput[] | null;
  disconnect?: UserEnteredBenefitWhereUniqueInput[] | null;
  delete?: UserEnteredBenefitWhereUniqueInput[] | null;
  connect?: UserEnteredBenefitWhereUniqueInput[] | null;
  update?: UserEnteredBenefitUpdateWithWhereUniqueWithoutInsurancePolicyInput[] | null;
  updateMany?: UserEnteredBenefitUpdateManyWithWhereWithoutInsurancePolicyInput[] | null;
  deleteMany?: UserEnteredBenefitScalarWhereInput[] | null;
}

export interface UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput {
  create?: UserEnteredBenefitCreateWithoutLastUpdatedByInput[] | null;
  connectOrCreate?: UserEnteredBenefitCreateOrConnectWithoutLastUpdatedByInput[] | null;
  upsert?: UserEnteredBenefitUpsertWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  createMany?: UserEnteredBenefitCreateManyLastUpdatedByInputEnvelope | null;
  set?: UserEnteredBenefitWhereUniqueInput[] | null;
  disconnect?: UserEnteredBenefitWhereUniqueInput[] | null;
  delete?: UserEnteredBenefitWhereUniqueInput[] | null;
  connect?: UserEnteredBenefitWhereUniqueInput[] | null;
  update?: UserEnteredBenefitUpdateWithWhereUniqueWithoutLastUpdatedByInput[] | null;
  updateMany?: UserEnteredBenefitUpdateManyWithWhereWithoutLastUpdatedByInput[] | null;
  deleteMany?: UserEnteredBenefitScalarWhereInput[] | null;
}

export interface UserEnteredBenefitUpdateWithWhereUniqueWithoutAccountCoverageInput {
  where: UserEnteredBenefitWhereUniqueInput;
  data: UserEnteredBenefitUpdateWithoutAccountCoverageInput;
}

export interface UserEnteredBenefitUpdateWithWhereUniqueWithoutCreatedByInput {
  where: UserEnteredBenefitWhereUniqueInput;
  data: UserEnteredBenefitUpdateWithoutCreatedByInput;
}

export interface UserEnteredBenefitUpdateWithWhereUniqueWithoutInsurancePolicyInput {
  where: UserEnteredBenefitWhereUniqueInput;
  data: UserEnteredBenefitUpdateWithoutInsurancePolicyInput;
}

export interface UserEnteredBenefitUpdateWithWhereUniqueWithoutLastUpdatedByInput {
  where: UserEnteredBenefitWhereUniqueInput;
  data: UserEnteredBenefitUpdateWithoutLastUpdatedByInput;
}

export interface UserEnteredBenefitUpdateWithoutAccountCoverageInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumUserEnteredBenefitTypeFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedUserEnteredBenefitsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutLastUpdatedUserEnteredBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneWithoutUserEnteredBenefitsNestedInput | null;
}

export interface UserEnteredBenefitUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumUserEnteredBenefitTypeFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutLastUpdatedUserEnteredBenefitsNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutUserEnteredBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneWithoutUserEnteredBenefitsNestedInput | null;
}

export interface UserEnteredBenefitUpdateWithoutInsurancePolicyInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumUserEnteredBenefitTypeFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedUserEnteredBenefitsNestedInput | null;
  lastUpdatedBy?: UserUpdateOneRequiredWithoutLastUpdatedUserEnteredBenefitsNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutUserEnteredBenefitsNestedInput | null;
}

export interface UserEnteredBenefitUpdateWithoutLastUpdatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  type?: EnumUserEnteredBenefitTypeFieldUpdateOperationsInput | null;
  coinsurance?: NullableDecimalFieldUpdateOperationsInput | null;
  copay?: NullableIntFieldUpdateOperationsInput | null;
  remainingOutOfPocket?: NullableIntFieldUpdateOperationsInput | null;
  remainingDeductible?: NullableIntFieldUpdateOperationsInput | null;
  remainingVisits?: NullableIntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneRequiredWithoutCreatedUserEnteredBenefitsNestedInput | null;
  accountCoverage?: AccountCoverageUpdateOneWithoutUserEnteredBenefitsNestedInput | null;
  insurancePolicy?: InsurancePolicyUpdateOneWithoutUserEnteredBenefitsNestedInput | null;
}

export interface UserEnteredBenefitUpsertWithWhereUniqueWithoutAccountCoverageInput {
  where: UserEnteredBenefitWhereUniqueInput;
  update: UserEnteredBenefitUpdateWithoutAccountCoverageInput;
  create: UserEnteredBenefitCreateWithoutAccountCoverageInput;
}

export interface UserEnteredBenefitUpsertWithWhereUniqueWithoutCreatedByInput {
  where: UserEnteredBenefitWhereUniqueInput;
  update: UserEnteredBenefitUpdateWithoutCreatedByInput;
  create: UserEnteredBenefitCreateWithoutCreatedByInput;
}

export interface UserEnteredBenefitUpsertWithWhereUniqueWithoutInsurancePolicyInput {
  where: UserEnteredBenefitWhereUniqueInput;
  update: UserEnteredBenefitUpdateWithoutInsurancePolicyInput;
  create: UserEnteredBenefitCreateWithoutInsurancePolicyInput;
}

export interface UserEnteredBenefitUpsertWithWhereUniqueWithoutLastUpdatedByInput {
  where: UserEnteredBenefitWhereUniqueInput;
  update: UserEnteredBenefitUpdateWithoutLastUpdatedByInput;
  create: UserEnteredBenefitCreateWithoutLastUpdatedByInput;
}

export interface UserEnteredBenefitWhereInput {
  AND?: UserEnteredBenefitWhereInput[] | null;
  OR?: UserEnteredBenefitWhereInput[] | null;
  NOT?: UserEnteredBenefitWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  createdById?: UuidFilter | null;
  lastUpdatedById?: UuidFilter | null;
  accountCoverageId?: UuidNullableFilter | null;
  insurancePolicyId?: UuidNullableFilter | null;
  type?: EnumUserEnteredBenefitTypeFilter | null;
  coinsurance?: DecimalNullableFilter | null;
  copay?: IntNullableFilter | null;
  remainingOutOfPocket?: IntNullableFilter | null;
  remainingDeductible?: IntNullableFilter | null;
  remainingVisits?: IntNullableFilter | null;
  note?: StringNullableFilter | null;
  createdBy?: UserRelationFilter | null;
  lastUpdatedBy?: UserRelationFilter | null;
  accountCoverage?: AccountCoverageRelationFilter | null;
  insurancePolicy?: InsurancePolicyRelationFilter | null;
}

export interface UserEnteredBenefitWhereUniqueInput {
  id?: string | null;
}

export interface UserListRelationFilter {
  every?: UserWhereInput | null;
  some?: UserWhereInput | null;
  none?: UserWhereInput | null;
}

export interface UserOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface UserRelationFilter {
  is?: UserWhereInput | null;
  isNot?: UserWhereInput | null;
}

export interface UserScalarWhereInput {
  AND?: UserScalarWhereInput[] | null;
  OR?: UserScalarWhereInput[] | null;
  NOT?: UserScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  activeLocationId?: UuidFilter | null;
  email?: StringFilter | null;
  password?: StringNullableFilter | null;
  firstName?: StringFilter | null;
  lastName?: StringFilter | null;
  isAdmin?: BoolNullableFilter | null;
  deactivatedAt?: DateTimeNullableFilter | null;
  appointmentViewPreferences?: JsonNullableFilter | null;
}

export interface UserUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
}

export interface UserUpdateManyWithWhereWithoutActiveLocationInput {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
}

export interface UserUpdateManyWithWhereWithoutOrganizationInput {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
}

export interface UserUpdateManyWithoutActiveLocationNestedInput {
  create?: UserCreateWithoutActiveLocationInput[] | null;
  connectOrCreate?: UserCreateOrConnectWithoutActiveLocationInput[] | null;
  upsert?: UserUpsertWithWhereUniqueWithoutActiveLocationInput[] | null;
  createMany?: UserCreateManyActiveLocationInputEnvelope | null;
  set?: UserWhereUniqueInput[] | null;
  disconnect?: UserWhereUniqueInput[] | null;
  delete?: UserWhereUniqueInput[] | null;
  connect?: UserWhereUniqueInput[] | null;
  update?: UserUpdateWithWhereUniqueWithoutActiveLocationInput[] | null;
  updateMany?: UserUpdateManyWithWhereWithoutActiveLocationInput[] | null;
  deleteMany?: UserScalarWhereInput[] | null;
}

export interface UserUpdateManyWithoutOrganizationNestedInput {
  create?: UserCreateWithoutOrganizationInput[] | null;
  connectOrCreate?: UserCreateOrConnectWithoutOrganizationInput[] | null;
  upsert?: UserUpsertWithWhereUniqueWithoutOrganizationInput[] | null;
  createMany?: UserCreateManyOrganizationInputEnvelope | null;
  set?: UserWhereUniqueInput[] | null;
  disconnect?: UserWhereUniqueInput[] | null;
  delete?: UserWhereUniqueInput[] | null;
  connect?: UserWhereUniqueInput[] | null;
  update?: UserUpdateWithWhereUniqueWithoutOrganizationInput[] | null;
  updateMany?: UserUpdateManyWithWhereWithoutOrganizationInput[] | null;
  deleteMany?: UserScalarWhereInput[] | null;
}

export interface UserUpdateOneRequiredWithoutCreatedBenefitMappingsNestedInput {
  create?: UserCreateWithoutCreatedBenefitMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedBenefitMappingsInput | null;
  upsert?: UserUpsertWithoutCreatedBenefitMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCreatedBenefitMappingsInput | null;
}

export interface UserUpdateOneRequiredWithoutCreatedChargeTemplateMappingsNestedInput {
  create?: UserCreateWithoutCreatedChargeTemplateMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedChargeTemplateMappingsInput | null;
  upsert?: UserUpsertWithoutCreatedChargeTemplateMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCreatedChargeTemplateMappingsInput | null;
}

export interface UserUpdateOneRequiredWithoutCreatedDepositMappingsNestedInput {
  create?: UserCreateWithoutCreatedDepositMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedDepositMappingsInput | null;
  upsert?: UserUpsertWithoutCreatedDepositMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCreatedDepositMappingsInput | null;
}

export interface UserUpdateOneRequiredWithoutCreatedFeeScheduleMappingsNestedInput {
  create?: UserCreateWithoutCreatedFeeScheduleMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedFeeScheduleMappingsInput | null;
  upsert?: UserUpsertWithoutCreatedFeeScheduleMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCreatedFeeScheduleMappingsInput | null;
}

export interface UserUpdateOneRequiredWithoutCreatedPreVisitMappingsNestedInput {
  create?: UserCreateWithoutCreatedPreVisitMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedPreVisitMappingsInput | null;
  upsert?: UserUpsertWithoutCreatedPreVisitMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCreatedPreVisitMappingsInput | null;
}

export interface UserUpdateOneRequiredWithoutCreatedUserEnteredBenefitsNestedInput {
  create?: UserCreateWithoutCreatedUserEnteredBenefitsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedUserEnteredBenefitsInput | null;
  upsert?: UserUpsertWithoutCreatedUserEnteredBenefitsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCreatedUserEnteredBenefitsInput | null;
}

export interface UserUpdateOneRequiredWithoutLastUpdatedUserEnteredBenefitsNestedInput {
  create?: UserCreateWithoutLastUpdatedUserEnteredBenefitsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutLastUpdatedUserEnteredBenefitsInput | null;
  upsert?: UserUpsertWithoutLastUpdatedUserEnteredBenefitsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutLastUpdatedUserEnteredBenefitsInput | null;
}

export interface UserUpdateOneRequiredWithoutUpdatedBenefitMappingsNestedInput {
  create?: UserCreateWithoutUpdatedBenefitMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutUpdatedBenefitMappingsInput | null;
  upsert?: UserUpsertWithoutUpdatedBenefitMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutUpdatedBenefitMappingsInput | null;
}

export interface UserUpdateOneRequiredWithoutUpdatedChargeTemplateMappingsNestedInput {
  create?: UserCreateWithoutUpdatedChargeTemplateMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutUpdatedChargeTemplateMappingsInput | null;
  upsert?: UserUpsertWithoutUpdatedChargeTemplateMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutUpdatedChargeTemplateMappingsInput | null;
}

export interface UserUpdateOneRequiredWithoutUpdatedDepositMappingsNestedInput {
  create?: UserCreateWithoutUpdatedDepositMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutUpdatedDepositMappingsInput | null;
  upsert?: UserUpsertWithoutUpdatedDepositMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutUpdatedDepositMappingsInput | null;
}

export interface UserUpdateOneRequiredWithoutUpdatedFeeScheduleMappingsNestedInput {
  create?: UserCreateWithoutUpdatedFeeScheduleMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutUpdatedFeeScheduleMappingsInput | null;
  upsert?: UserUpsertWithoutUpdatedFeeScheduleMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutUpdatedFeeScheduleMappingsInput | null;
}

export interface UserUpdateOneRequiredWithoutUpdatedPreVisitMappingsNestedInput {
  create?: UserCreateWithoutUpdatedPreVisitMappingsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutUpdatedPreVisitMappingsInput | null;
  upsert?: UserUpsertWithoutUpdatedPreVisitMappingsInput | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutUpdatedPreVisitMappingsInput | null;
}

export interface UserUpdateOneWithoutAcceptedInsurancePoliciesNestedInput {
  create?: UserCreateWithoutAcceptedInsurancePoliciesInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutAcceptedInsurancePoliciesInput | null;
  upsert?: UserUpsertWithoutAcceptedInsurancePoliciesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutAcceptedInsurancePoliciesInput | null;
}

export interface UserUpdateOneWithoutAppointmentBenefitAssignmentsNestedInput {
  create?: UserCreateWithoutAppointmentBenefitAssignmentsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutAppointmentBenefitAssignmentsInput | null;
  upsert?: UserUpsertWithoutAppointmentBenefitAssignmentsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutAppointmentBenefitAssignmentsInput | null;
}

export interface UserUpdateOneWithoutCanceledInsurancePolicyWorklistItemsNestedInput {
  create?: UserCreateWithoutCanceledInsurancePolicyWorklistItemsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCanceledInsurancePolicyWorklistItemsInput | null;
  upsert?: UserUpsertWithoutCanceledInsurancePolicyWorklistItemsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCanceledInsurancePolicyWorklistItemsInput | null;
}

export interface UserUpdateOneWithoutCanceledPaymentRequestBatchesNestedInput {
  create?: UserCreateWithoutCanceledPaymentRequestBatchesInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCanceledPaymentRequestBatchesInput | null;
  upsert?: UserUpsertWithoutCanceledPaymentRequestBatchesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCanceledPaymentRequestBatchesInput | null;
}

export interface UserUpdateOneWithoutCanceledTasksNestedInput {
  create?: UserCreateWithoutCanceledTasksInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCanceledTasksInput | null;
  upsert?: UserUpsertWithoutCanceledTasksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCanceledTasksInput | null;
}

export interface UserUpdateOneWithoutCompletedInsurancePolicyWorklistItemTaskasNestedInput {
  create?: UserCreateWithoutCompletedInsurancePolicyWorklistItemTaskasInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCompletedInsurancePolicyWorklistItemTaskasInput | null;
  upsert?: UserUpsertWithoutCompletedInsurancePolicyWorklistItemTaskasInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCompletedInsurancePolicyWorklistItemTaskasInput | null;
}

export interface UserUpdateOneWithoutCompletedInsurancePolicyWorklistItemsNestedInput {
  create?: UserCreateWithoutCompletedInsurancePolicyWorklistItemsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCompletedInsurancePolicyWorklistItemsInput | null;
  upsert?: UserUpsertWithoutCompletedInsurancePolicyWorklistItemsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCompletedInsurancePolicyWorklistItemsInput | null;
}

export interface UserUpdateOneWithoutCompletedTasksNestedInput {
  create?: UserCreateWithoutCompletedTasksInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCompletedTasksInput | null;
  upsert?: UserUpsertWithoutCompletedTasksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCompletedTasksInput | null;
}

export interface UserUpdateOneWithoutCoverageBenefitsNestedInput {
  create?: UserCreateWithoutCoverageBenefitsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCoverageBenefitsInput | null;
  upsert?: UserUpsertWithoutCoverageBenefitsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCoverageBenefitsInput | null;
}

export interface UserUpdateOneWithoutCreatedPaymentRequestBatchesNestedInput {
  create?: UserCreateWithoutCreatedPaymentRequestBatchesInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutCreatedPaymentRequestBatchesInput | null;
  upsert?: UserUpsertWithoutCreatedPaymentRequestBatchesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutCreatedPaymentRequestBatchesInput | null;
}

export interface UserUpdateOneWithoutEligibilityRequestsNestedInput {
  create?: UserCreateWithoutEligibilityRequestsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutEligibilityRequestsInput | null;
  upsert?: UserUpsertWithoutEligibilityRequestsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutEligibilityRequestsInput | null;
}

export interface UserUpdateOneWithoutEstimatesNestedInput {
  create?: UserCreateWithoutEstimatesInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutEstimatesInput | null;
  upsert?: UserUpsertWithoutEstimatesInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutEstimatesInput | null;
}

export interface UserUpdateOneWithoutFeedbacksNestedInput {
  create?: UserCreateWithoutFeedbacksInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutFeedbacksInput | null;
  upsert?: UserUpsertWithoutFeedbacksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutFeedbacksInput | null;
}

export interface UserUpdateOneWithoutIntegrationSyncsNestedInput {
  create?: UserCreateWithoutIntegrationSyncsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutIntegrationSyncsInput | null;
  upsert?: UserUpsertWithoutIntegrationSyncsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutIntegrationSyncsInput | null;
}

export interface UserUpdateOneWithoutSentCommunicationsNestedInput {
  create?: UserCreateWithoutSentCommunicationsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutSentCommunicationsInput | null;
  upsert?: UserUpsertWithoutSentCommunicationsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutSentCommunicationsInput | null;
}

export interface UserUpdateOneWithoutSkippedInsurancePolicyWorklistItemTasksNestedInput {
  create?: UserCreateWithoutSkippedInsurancePolicyWorklistItemTasksInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutSkippedInsurancePolicyWorklistItemTasksInput | null;
  upsert?: UserUpsertWithoutSkippedInsurancePolicyWorklistItemTasksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutSkippedInsurancePolicyWorklistItemTasksInput | null;
}

export interface UserUpdateOneWithoutSnoozedTasksNestedInput {
  create?: UserCreateWithoutSnoozedTasksInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutSnoozedTasksInput | null;
  upsert?: UserUpsertWithoutSnoozedTasksInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutSnoozedTasksInput | null;
}

export interface UserUpdateOneWithoutVisitCollectionRequestsNestedInput {
  create?: UserCreateWithoutVisitCollectionRequestsInput | null;
  connectOrCreate?: UserCreateOrConnectWithoutVisitCollectionRequestsInput | null;
  upsert?: UserUpsertWithoutVisitCollectionRequestsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: UserWhereUniqueInput | null;
  update?: UserUpdateWithoutVisitCollectionRequestsInput | null;
}

export interface UserUpdateWithWhereUniqueWithoutActiveLocationInput {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutActiveLocationInput;
}

export interface UserUpdateWithWhereUniqueWithoutOrganizationInput {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutOrganizationInput;
}

export interface UserUpdateWithoutAcceptedInsurancePoliciesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutActiveLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutAppointmentBenefitAssignmentsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCanceledInsurancePolicyWorklistItemsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCanceledPaymentRequestBatchesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCanceledTasksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCompletedInsurancePolicyWorklistItemTaskasInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCompletedInsurancePolicyWorklistItemsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCompletedTasksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCoverageBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCreatedBenefitMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCreatedChargeTemplateMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCreatedDepositMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCreatedFeeScheduleMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCreatedPaymentRequestBatchesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCreatedPreVisitMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutCreatedUserEnteredBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutEligibilityRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutEstimatesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutFeedbacksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutIntegrationSyncsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutLastUpdatedUserEnteredBenefitsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutOrganizationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutSentCommunicationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutSkippedInsurancePolicyWorklistItemTasksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutSnoozedTasksInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutUpdatedBenefitMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutUpdatedChargeTemplateMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutUpdatedDepositMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutUpdatedFeeScheduleMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutUpdatedPreVisitMappingsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  visitCollectionRequests?: VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput | null;
}

export interface UserUpdateWithoutVisitCollectionRequestsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  email?: StringFieldUpdateOperationsInput | null;
  password?: NullableStringFieldUpdateOperationsInput | null;
  firstName?: StringFieldUpdateOperationsInput | null;
  lastName?: StringFieldUpdateOperationsInput | null;
  isAdmin?: NullableBoolFieldUpdateOperationsInput | null;
  deactivatedAt?: NullableDateTimeFieldUpdateOperationsInput | null;
  appointmentViewPreferences?: any | null;
  organization?: OrganizationUpdateOneRequiredWithoutUsersNestedInput | null;
  activeLocation?: LocationUpdateOneRequiredWithoutUsersNestedInput | null;
  sentCommunications?: CommunicationUpdateManyWithoutSentByNestedInput | null;
  eligibilityRequests?: EligibilityRequestUpdateManyWithoutRequestedByNestedInput | null;
  feedbacks?: FeedbackUpdateManyWithoutUserNestedInput | null;
  integrationSyncs?: IntegrationSyncUpdateManyWithoutUserNestedInput | null;
  estimates?: EstimateUpdateManyWithoutCreatedByNestedInput | null;
  createdUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutCreatedByNestedInput | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCreatedByNestedInput | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchUpdateManyWithoutCanceledByNestedInput | null;
  completedTasks?: TaskUpdateManyWithoutCompletedByNestedInput | null;
  canceledTasks?: TaskUpdateManyWithoutCanceledByNestedInput | null;
  snoozedTasks?: TaskUpdateManyWithoutSnoozedByNestedInput | null;
  coverageBenefits?: CoverageBenefitUpdateManyWithoutCreatedByNestedInput | null;
  acceptedInsurancePolicies?: InsurancePolicyUpdateManyWithoutAcceptedByNestedInput | null;
  createdBenefitMappings?: BenefitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedBenefitMappings?: BenefitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdDepositMappings?: DepositMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedDepositMappings?: DepositMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentUpdateManyWithoutCreatedByNestedInput | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCanceledByNestedInput | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemUpdateManyWithoutCompletedByNestedInput | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskUpdateManyWithoutCompletedByNestedInput | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskUpdateManyWithoutSkippedByNestedInput | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
  createdPreVisitMappings?: PreVisitMappingUpdateManyWithoutCreatedByNestedInput | null;
  updatedPreVisitMappings?: PreVisitMappingUpdateManyWithoutLastUpdatedByNestedInput | null;
}

export interface UserUpsertWithWhereUniqueWithoutActiveLocationInput {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutActiveLocationInput;
  create: UserCreateWithoutActiveLocationInput;
}

export interface UserUpsertWithWhereUniqueWithoutOrganizationInput {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutOrganizationInput;
  create: UserCreateWithoutOrganizationInput;
}

export interface UserUpsertWithoutAcceptedInsurancePoliciesInput {
  update: UserUpdateWithoutAcceptedInsurancePoliciesInput;
  create: UserCreateWithoutAcceptedInsurancePoliciesInput;
}

export interface UserUpsertWithoutAppointmentBenefitAssignmentsInput {
  update: UserUpdateWithoutAppointmentBenefitAssignmentsInput;
  create: UserCreateWithoutAppointmentBenefitAssignmentsInput;
}

export interface UserUpsertWithoutCanceledInsurancePolicyWorklistItemsInput {
  update: UserUpdateWithoutCanceledInsurancePolicyWorklistItemsInput;
  create: UserCreateWithoutCanceledInsurancePolicyWorklistItemsInput;
}

export interface UserUpsertWithoutCanceledPaymentRequestBatchesInput {
  update: UserUpdateWithoutCanceledPaymentRequestBatchesInput;
  create: UserCreateWithoutCanceledPaymentRequestBatchesInput;
}

export interface UserUpsertWithoutCanceledTasksInput {
  update: UserUpdateWithoutCanceledTasksInput;
  create: UserCreateWithoutCanceledTasksInput;
}

export interface UserUpsertWithoutCompletedInsurancePolicyWorklistItemTaskasInput {
  update: UserUpdateWithoutCompletedInsurancePolicyWorklistItemTaskasInput;
  create: UserCreateWithoutCompletedInsurancePolicyWorklistItemTaskasInput;
}

export interface UserUpsertWithoutCompletedInsurancePolicyWorklistItemsInput {
  update: UserUpdateWithoutCompletedInsurancePolicyWorklistItemsInput;
  create: UserCreateWithoutCompletedInsurancePolicyWorklistItemsInput;
}

export interface UserUpsertWithoutCompletedTasksInput {
  update: UserUpdateWithoutCompletedTasksInput;
  create: UserCreateWithoutCompletedTasksInput;
}

export interface UserUpsertWithoutCoverageBenefitsInput {
  update: UserUpdateWithoutCoverageBenefitsInput;
  create: UserCreateWithoutCoverageBenefitsInput;
}

export interface UserUpsertWithoutCreatedBenefitMappingsInput {
  update: UserUpdateWithoutCreatedBenefitMappingsInput;
  create: UserCreateWithoutCreatedBenefitMappingsInput;
}

export interface UserUpsertWithoutCreatedChargeTemplateMappingsInput {
  update: UserUpdateWithoutCreatedChargeTemplateMappingsInput;
  create: UserCreateWithoutCreatedChargeTemplateMappingsInput;
}

export interface UserUpsertWithoutCreatedDepositMappingsInput {
  update: UserUpdateWithoutCreatedDepositMappingsInput;
  create: UserCreateWithoutCreatedDepositMappingsInput;
}

export interface UserUpsertWithoutCreatedFeeScheduleMappingsInput {
  update: UserUpdateWithoutCreatedFeeScheduleMappingsInput;
  create: UserCreateWithoutCreatedFeeScheduleMappingsInput;
}

export interface UserUpsertWithoutCreatedPaymentRequestBatchesInput {
  update: UserUpdateWithoutCreatedPaymentRequestBatchesInput;
  create: UserCreateWithoutCreatedPaymentRequestBatchesInput;
}

export interface UserUpsertWithoutCreatedPreVisitMappingsInput {
  update: UserUpdateWithoutCreatedPreVisitMappingsInput;
  create: UserCreateWithoutCreatedPreVisitMappingsInput;
}

export interface UserUpsertWithoutCreatedUserEnteredBenefitsInput {
  update: UserUpdateWithoutCreatedUserEnteredBenefitsInput;
  create: UserCreateWithoutCreatedUserEnteredBenefitsInput;
}

export interface UserUpsertWithoutEligibilityRequestsInput {
  update: UserUpdateWithoutEligibilityRequestsInput;
  create: UserCreateWithoutEligibilityRequestsInput;
}

export interface UserUpsertWithoutEstimatesInput {
  update: UserUpdateWithoutEstimatesInput;
  create: UserCreateWithoutEstimatesInput;
}

export interface UserUpsertWithoutFeedbacksInput {
  update: UserUpdateWithoutFeedbacksInput;
  create: UserCreateWithoutFeedbacksInput;
}

export interface UserUpsertWithoutIntegrationSyncsInput {
  update: UserUpdateWithoutIntegrationSyncsInput;
  create: UserCreateWithoutIntegrationSyncsInput;
}

export interface UserUpsertWithoutLastUpdatedUserEnteredBenefitsInput {
  update: UserUpdateWithoutLastUpdatedUserEnteredBenefitsInput;
  create: UserCreateWithoutLastUpdatedUserEnteredBenefitsInput;
}

export interface UserUpsertWithoutSentCommunicationsInput {
  update: UserUpdateWithoutSentCommunicationsInput;
  create: UserCreateWithoutSentCommunicationsInput;
}

export interface UserUpsertWithoutSkippedInsurancePolicyWorklistItemTasksInput {
  update: UserUpdateWithoutSkippedInsurancePolicyWorklistItemTasksInput;
  create: UserCreateWithoutSkippedInsurancePolicyWorklistItemTasksInput;
}

export interface UserUpsertWithoutSnoozedTasksInput {
  update: UserUpdateWithoutSnoozedTasksInput;
  create: UserCreateWithoutSnoozedTasksInput;
}

export interface UserUpsertWithoutUpdatedBenefitMappingsInput {
  update: UserUpdateWithoutUpdatedBenefitMappingsInput;
  create: UserCreateWithoutUpdatedBenefitMappingsInput;
}

export interface UserUpsertWithoutUpdatedChargeTemplateMappingsInput {
  update: UserUpdateWithoutUpdatedChargeTemplateMappingsInput;
  create: UserCreateWithoutUpdatedChargeTemplateMappingsInput;
}

export interface UserUpsertWithoutUpdatedDepositMappingsInput {
  update: UserUpdateWithoutUpdatedDepositMappingsInput;
  create: UserCreateWithoutUpdatedDepositMappingsInput;
}

export interface UserUpsertWithoutUpdatedFeeScheduleMappingsInput {
  update: UserUpdateWithoutUpdatedFeeScheduleMappingsInput;
  create: UserCreateWithoutUpdatedFeeScheduleMappingsInput;
}

export interface UserUpsertWithoutUpdatedPreVisitMappingsInput {
  update: UserUpdateWithoutUpdatedPreVisitMappingsInput;
  create: UserCreateWithoutUpdatedPreVisitMappingsInput;
}

export interface UserUpsertWithoutVisitCollectionRequestsInput {
  update: UserUpdateWithoutVisitCollectionRequestsInput;
  create: UserCreateWithoutVisitCollectionRequestsInput;
}

export interface UserWhereInput {
  AND?: UserWhereInput[] | null;
  OR?: UserWhereInput[] | null;
  NOT?: UserWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  organizationId?: UuidFilter | null;
  activeLocationId?: UuidFilter | null;
  email?: StringFilter | null;
  password?: StringNullableFilter | null;
  firstName?: StringFilter | null;
  lastName?: StringFilter | null;
  isAdmin?: BoolNullableFilter | null;
  deactivatedAt?: DateTimeNullableFilter | null;
  appointmentViewPreferences?: JsonNullableFilter | null;
  organization?: OrganizationRelationFilter | null;
  activeLocation?: LocationRelationFilter | null;
  sentCommunications?: CommunicationListRelationFilter | null;
  eligibilityRequests?: EligibilityRequestListRelationFilter | null;
  feedbacks?: FeedbackListRelationFilter | null;
  integrationSyncs?: IntegrationSyncListRelationFilter | null;
  estimates?: EstimateListRelationFilter | null;
  createdUserEnteredBenefits?: UserEnteredBenefitListRelationFilter | null;
  lastUpdatedUserEnteredBenefits?: UserEnteredBenefitListRelationFilter | null;
  createdPaymentRequestBatches?: PaymentRequestBatchListRelationFilter | null;
  canceledPaymentRequestBatches?: PaymentRequestBatchListRelationFilter | null;
  completedTasks?: TaskListRelationFilter | null;
  canceledTasks?: TaskListRelationFilter | null;
  snoozedTasks?: TaskListRelationFilter | null;
  coverageBenefits?: CoverageBenefitListRelationFilter | null;
  acceptedInsurancePolicies?: InsurancePolicyListRelationFilter | null;
  createdBenefitMappings?: BenefitMappingListRelationFilter | null;
  updatedBenefitMappings?: BenefitMappingListRelationFilter | null;
  createdDepositMappings?: DepositMappingListRelationFilter | null;
  updatedDepositMappings?: DepositMappingListRelationFilter | null;
  appointmentBenefitAssignments?: AppointmentBenefitAssignmentListRelationFilter | null;
  canceledInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemListRelationFilter | null;
  completedInsurancePolicyWorklistItems?: InsurancePolicyWorklistItemListRelationFilter | null;
  completedInsurancePolicyWorklistItemTaskas?: InsurancePolicyWorklistItemTaskListRelationFilter | null;
  skippedInsurancePolicyWorklistItemTasks?: InsurancePolicyWorklistItemTaskListRelationFilter | null;
  createdChargeTemplateMappings?: ChargeTemplateMappingListRelationFilter | null;
  updatedChargeTemplateMappings?: ChargeTemplateMappingListRelationFilter | null;
  createdFeeScheduleMappings?: FeeScheduleMappingListRelationFilter | null;
  updatedFeeScheduleMappings?: FeeScheduleMappingListRelationFilter | null;
  createdPreVisitMappings?: PreVisitMappingListRelationFilter | null;
  updatedPreVisitMappings?: PreVisitMappingListRelationFilter | null;
  visitCollectionRequests?: VisitCollectionRequestListRelationFilter | null;
}

export interface UserWhereUniqueInput {
  id?: string | null;
  email?: string | null;
}

export interface UuidFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  mode?: QueryMode | null;
  not?: NestedUuidFilter | null;
}

export interface UuidNullableFilter {
  equals?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  mode?: QueryMode | null;
  not?: NestedUuidNullableFilter | null;
}

export interface VerificationWorkflowStatusCreateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
  location: LocationCreateNestedOneWithoutVerificationWorkflowStatusesInput;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutVerificationWorkflowStatusInput | null;
  locationDefault?: LocationCreateNestedOneWithoutDefaultVerificationWorkflowStatusInput | null;
  confirmationLocation?: LocationCreateNestedOneWithoutConfirmationVerificationWorkflowStatusInput | null;
  unconfirmationLocation?: LocationCreateNestedOneWithoutUnconfirmationVerificationWorkflowStatusInput | null;
}

export interface VerificationWorkflowStatusCreateManyLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
}

export interface VerificationWorkflowStatusCreateManyLocationInputEnvelope {
  data: VerificationWorkflowStatusCreateManyLocationInput[];
  skipDuplicates?: boolean | null;
}

export interface VerificationWorkflowStatusCreateNestedManyWithoutLocationInput {
  create?: VerificationWorkflowStatusCreateWithoutLocationInput[] | null;
  connectOrCreate?: VerificationWorkflowStatusCreateOrConnectWithoutLocationInput[] | null;
  createMany?: VerificationWorkflowStatusCreateManyLocationInputEnvelope | null;
  connect?: VerificationWorkflowStatusWhereUniqueInput[] | null;
}

export interface VerificationWorkflowStatusCreateNestedOneWithoutConfirmationLocationInput {
  create?: VerificationWorkflowStatusCreateWithoutConfirmationLocationInput | null;
  connectOrCreate?: VerificationWorkflowStatusCreateOrConnectWithoutConfirmationLocationInput | null;
  connect?: VerificationWorkflowStatusWhereUniqueInput | null;
}

export interface VerificationWorkflowStatusCreateNestedOneWithoutInsurancePoliciesInput {
  create?: VerificationWorkflowStatusCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: VerificationWorkflowStatusCreateOrConnectWithoutInsurancePoliciesInput | null;
  connect?: VerificationWorkflowStatusWhereUniqueInput | null;
}

export interface VerificationWorkflowStatusCreateNestedOneWithoutLocationDefaultInput {
  create?: VerificationWorkflowStatusCreateWithoutLocationDefaultInput | null;
  connectOrCreate?: VerificationWorkflowStatusCreateOrConnectWithoutLocationDefaultInput | null;
  connect?: VerificationWorkflowStatusWhereUniqueInput | null;
}

export interface VerificationWorkflowStatusCreateNestedOneWithoutUnconfirmationLocationInput {
  create?: VerificationWorkflowStatusCreateWithoutUnconfirmationLocationInput | null;
  connectOrCreate?: VerificationWorkflowStatusCreateOrConnectWithoutUnconfirmationLocationInput | null;
  connect?: VerificationWorkflowStatusWhereUniqueInput | null;
}

export interface VerificationWorkflowStatusCreateOrConnectWithoutConfirmationLocationInput {
  where: VerificationWorkflowStatusWhereUniqueInput;
  create: VerificationWorkflowStatusCreateWithoutConfirmationLocationInput;
}

export interface VerificationWorkflowStatusCreateOrConnectWithoutInsurancePoliciesInput {
  where: VerificationWorkflowStatusWhereUniqueInput;
  create: VerificationWorkflowStatusCreateWithoutInsurancePoliciesInput;
}

export interface VerificationWorkflowStatusCreateOrConnectWithoutLocationDefaultInput {
  where: VerificationWorkflowStatusWhereUniqueInput;
  create: VerificationWorkflowStatusCreateWithoutLocationDefaultInput;
}

export interface VerificationWorkflowStatusCreateOrConnectWithoutLocationInput {
  where: VerificationWorkflowStatusWhereUniqueInput;
  create: VerificationWorkflowStatusCreateWithoutLocationInput;
}

export interface VerificationWorkflowStatusCreateOrConnectWithoutUnconfirmationLocationInput {
  where: VerificationWorkflowStatusWhereUniqueInput;
  create: VerificationWorkflowStatusCreateWithoutUnconfirmationLocationInput;
}

export interface VerificationWorkflowStatusCreateWithoutConfirmationLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
  location: LocationCreateNestedOneWithoutVerificationWorkflowStatusesInput;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutVerificationWorkflowStatusInput | null;
  locationDefault?: LocationCreateNestedOneWithoutDefaultVerificationWorkflowStatusInput | null;
  unconfirmationLocation?: LocationCreateNestedOneWithoutUnconfirmationVerificationWorkflowStatusInput | null;
}

export interface VerificationWorkflowStatusCreateWithoutInsurancePoliciesInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
  location: LocationCreateNestedOneWithoutVerificationWorkflowStatusesInput;
  locationDefault?: LocationCreateNestedOneWithoutDefaultVerificationWorkflowStatusInput | null;
  confirmationLocation?: LocationCreateNestedOneWithoutConfirmationVerificationWorkflowStatusInput | null;
  unconfirmationLocation?: LocationCreateNestedOneWithoutUnconfirmationVerificationWorkflowStatusInput | null;
}

export interface VerificationWorkflowStatusCreateWithoutLocationDefaultInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
  location: LocationCreateNestedOneWithoutVerificationWorkflowStatusesInput;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutVerificationWorkflowStatusInput | null;
  confirmationLocation?: LocationCreateNestedOneWithoutConfirmationVerificationWorkflowStatusInput | null;
  unconfirmationLocation?: LocationCreateNestedOneWithoutUnconfirmationVerificationWorkflowStatusInput | null;
}

export interface VerificationWorkflowStatusCreateWithoutLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutVerificationWorkflowStatusInput | null;
  locationDefault?: LocationCreateNestedOneWithoutDefaultVerificationWorkflowStatusInput | null;
  confirmationLocation?: LocationCreateNestedOneWithoutConfirmationVerificationWorkflowStatusInput | null;
  unconfirmationLocation?: LocationCreateNestedOneWithoutUnconfirmationVerificationWorkflowStatusInput | null;
}

export interface VerificationWorkflowStatusCreateWithoutUnconfirmationLocationInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  name: string;
  position: number;
  stage: WorkflowStage;
  description?: string | null;
  location: LocationCreateNestedOneWithoutVerificationWorkflowStatusesInput;
  insurancePolicies?: InsurancePolicyCreateNestedManyWithoutVerificationWorkflowStatusInput | null;
  locationDefault?: LocationCreateNestedOneWithoutDefaultVerificationWorkflowStatusInput | null;
  confirmationLocation?: LocationCreateNestedOneWithoutConfirmationVerificationWorkflowStatusInput | null;
}

export interface VerificationWorkflowStatusListRelationFilter {
  every?: VerificationWorkflowStatusWhereInput | null;
  some?: VerificationWorkflowStatusWhereInput | null;
  none?: VerificationWorkflowStatusWhereInput | null;
}

export interface VerificationWorkflowStatusOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface VerificationWorkflowStatusOrderByWithRelationInput {
  id?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  name?: SortOrder | null;
  position?: SortOrder | null;
  locationId?: SortOrder | null;
  stage?: SortOrder | null;
  description?: SortOrderInput | null;
  location?: LocationOrderByWithRelationInput | null;
  insurancePolicies?: InsurancePolicyOrderByRelationAggregateInput | null;
  locationDefault?: LocationOrderByWithRelationInput | null;
  confirmationLocation?: LocationOrderByWithRelationInput | null;
  unconfirmationLocation?: LocationOrderByWithRelationInput | null;
}

export interface VerificationWorkflowStatusRelationFilter {
  is?: VerificationWorkflowStatusWhereInput | null;
  isNot?: VerificationWorkflowStatusWhereInput | null;
}

export interface VerificationWorkflowStatusScalarWhereInput {
  AND?: VerificationWorkflowStatusScalarWhereInput[] | null;
  OR?: VerificationWorkflowStatusScalarWhereInput[] | null;
  NOT?: VerificationWorkflowStatusScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  position?: FloatFilter | null;
  locationId?: UuidFilter | null;
  stage?: EnumWorkflowStageFilter | null;
  description?: StringNullableFilter | null;
}

export interface VerificationWorkflowStatusUpdateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutVerificationWorkflowStatusesNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutVerificationWorkflowStatusNestedInput | null;
  locationDefault?: LocationUpdateOneWithoutDefaultVerificationWorkflowStatusNestedInput | null;
  confirmationLocation?: LocationUpdateOneWithoutConfirmationVerificationWorkflowStatusNestedInput | null;
  unconfirmationLocation?: LocationUpdateOneWithoutUnconfirmationVerificationWorkflowStatusNestedInput | null;
}

export interface VerificationWorkflowStatusUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
}

export interface VerificationWorkflowStatusUpdateManyWithWhereWithoutLocationInput {
  where: VerificationWorkflowStatusScalarWhereInput;
  data: VerificationWorkflowStatusUpdateManyMutationInput;
}

export interface VerificationWorkflowStatusUpdateManyWithoutLocationNestedInput {
  create?: VerificationWorkflowStatusCreateWithoutLocationInput[] | null;
  connectOrCreate?: VerificationWorkflowStatusCreateOrConnectWithoutLocationInput[] | null;
  upsert?: VerificationWorkflowStatusUpsertWithWhereUniqueWithoutLocationInput[] | null;
  createMany?: VerificationWorkflowStatusCreateManyLocationInputEnvelope | null;
  set?: VerificationWorkflowStatusWhereUniqueInput[] | null;
  disconnect?: VerificationWorkflowStatusWhereUniqueInput[] | null;
  delete?: VerificationWorkflowStatusWhereUniqueInput[] | null;
  connect?: VerificationWorkflowStatusWhereUniqueInput[] | null;
  update?: VerificationWorkflowStatusUpdateWithWhereUniqueWithoutLocationInput[] | null;
  updateMany?: VerificationWorkflowStatusUpdateManyWithWhereWithoutLocationInput[] | null;
  deleteMany?: VerificationWorkflowStatusScalarWhereInput[] | null;
}

export interface VerificationWorkflowStatusUpdateOneRequiredWithoutInsurancePoliciesNestedInput {
  create?: VerificationWorkflowStatusCreateWithoutInsurancePoliciesInput | null;
  connectOrCreate?: VerificationWorkflowStatusCreateOrConnectWithoutInsurancePoliciesInput | null;
  upsert?: VerificationWorkflowStatusUpsertWithoutInsurancePoliciesInput | null;
  connect?: VerificationWorkflowStatusWhereUniqueInput | null;
  update?: VerificationWorkflowStatusUpdateWithoutInsurancePoliciesInput | null;
}

export interface VerificationWorkflowStatusUpdateOneWithoutConfirmationLocationNestedInput {
  create?: VerificationWorkflowStatusCreateWithoutConfirmationLocationInput | null;
  connectOrCreate?: VerificationWorkflowStatusCreateOrConnectWithoutConfirmationLocationInput | null;
  upsert?: VerificationWorkflowStatusUpsertWithoutConfirmationLocationInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: VerificationWorkflowStatusWhereUniqueInput | null;
  update?: VerificationWorkflowStatusUpdateWithoutConfirmationLocationInput | null;
}

export interface VerificationWorkflowStatusUpdateOneWithoutLocationDefaultNestedInput {
  create?: VerificationWorkflowStatusCreateWithoutLocationDefaultInput | null;
  connectOrCreate?: VerificationWorkflowStatusCreateOrConnectWithoutLocationDefaultInput | null;
  upsert?: VerificationWorkflowStatusUpsertWithoutLocationDefaultInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: VerificationWorkflowStatusWhereUniqueInput | null;
  update?: VerificationWorkflowStatusUpdateWithoutLocationDefaultInput | null;
}

export interface VerificationWorkflowStatusUpdateOneWithoutUnconfirmationLocationNestedInput {
  create?: VerificationWorkflowStatusCreateWithoutUnconfirmationLocationInput | null;
  connectOrCreate?: VerificationWorkflowStatusCreateOrConnectWithoutUnconfirmationLocationInput | null;
  upsert?: VerificationWorkflowStatusUpsertWithoutUnconfirmationLocationInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: VerificationWorkflowStatusWhereUniqueInput | null;
  update?: VerificationWorkflowStatusUpdateWithoutUnconfirmationLocationInput | null;
}

export interface VerificationWorkflowStatusUpdateWithWhereUniqueWithoutLocationInput {
  where: VerificationWorkflowStatusWhereUniqueInput;
  data: VerificationWorkflowStatusUpdateWithoutLocationInput;
}

export interface VerificationWorkflowStatusUpdateWithoutConfirmationLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutVerificationWorkflowStatusesNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutVerificationWorkflowStatusNestedInput | null;
  locationDefault?: LocationUpdateOneWithoutDefaultVerificationWorkflowStatusNestedInput | null;
  unconfirmationLocation?: LocationUpdateOneWithoutUnconfirmationVerificationWorkflowStatusNestedInput | null;
}

export interface VerificationWorkflowStatusUpdateWithoutInsurancePoliciesInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutVerificationWorkflowStatusesNestedInput | null;
  locationDefault?: LocationUpdateOneWithoutDefaultVerificationWorkflowStatusNestedInput | null;
  confirmationLocation?: LocationUpdateOneWithoutConfirmationVerificationWorkflowStatusNestedInput | null;
  unconfirmationLocation?: LocationUpdateOneWithoutUnconfirmationVerificationWorkflowStatusNestedInput | null;
}

export interface VerificationWorkflowStatusUpdateWithoutLocationDefaultInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutVerificationWorkflowStatusesNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutVerificationWorkflowStatusNestedInput | null;
  confirmationLocation?: LocationUpdateOneWithoutConfirmationVerificationWorkflowStatusNestedInput | null;
  unconfirmationLocation?: LocationUpdateOneWithoutUnconfirmationVerificationWorkflowStatusNestedInput | null;
}

export interface VerificationWorkflowStatusUpdateWithoutLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutVerificationWorkflowStatusNestedInput | null;
  locationDefault?: LocationUpdateOneWithoutDefaultVerificationWorkflowStatusNestedInput | null;
  confirmationLocation?: LocationUpdateOneWithoutConfirmationVerificationWorkflowStatusNestedInput | null;
  unconfirmationLocation?: LocationUpdateOneWithoutUnconfirmationVerificationWorkflowStatusNestedInput | null;
}

export interface VerificationWorkflowStatusUpdateWithoutUnconfirmationLocationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  name?: StringFieldUpdateOperationsInput | null;
  position?: FloatFieldUpdateOperationsInput | null;
  stage?: EnumWorkflowStageFieldUpdateOperationsInput | null;
  description?: NullableStringFieldUpdateOperationsInput | null;
  location?: LocationUpdateOneRequiredWithoutVerificationWorkflowStatusesNestedInput | null;
  insurancePolicies?: InsurancePolicyUpdateManyWithoutVerificationWorkflowStatusNestedInput | null;
  locationDefault?: LocationUpdateOneWithoutDefaultVerificationWorkflowStatusNestedInput | null;
  confirmationLocation?: LocationUpdateOneWithoutConfirmationVerificationWorkflowStatusNestedInput | null;
}

export interface VerificationWorkflowStatusUpsertWithWhereUniqueWithoutLocationInput {
  where: VerificationWorkflowStatusWhereUniqueInput;
  update: VerificationWorkflowStatusUpdateWithoutLocationInput;
  create: VerificationWorkflowStatusCreateWithoutLocationInput;
}

export interface VerificationWorkflowStatusUpsertWithoutConfirmationLocationInput {
  update: VerificationWorkflowStatusUpdateWithoutConfirmationLocationInput;
  create: VerificationWorkflowStatusCreateWithoutConfirmationLocationInput;
}

export interface VerificationWorkflowStatusUpsertWithoutInsurancePoliciesInput {
  update: VerificationWorkflowStatusUpdateWithoutInsurancePoliciesInput;
  create: VerificationWorkflowStatusCreateWithoutInsurancePoliciesInput;
}

export interface VerificationWorkflowStatusUpsertWithoutLocationDefaultInput {
  update: VerificationWorkflowStatusUpdateWithoutLocationDefaultInput;
  create: VerificationWorkflowStatusCreateWithoutLocationDefaultInput;
}

export interface VerificationWorkflowStatusUpsertWithoutUnconfirmationLocationInput {
  update: VerificationWorkflowStatusUpdateWithoutUnconfirmationLocationInput;
  create: VerificationWorkflowStatusCreateWithoutUnconfirmationLocationInput;
}

export interface VerificationWorkflowStatusWhereInput {
  AND?: VerificationWorkflowStatusWhereInput[] | null;
  OR?: VerificationWorkflowStatusWhereInput[] | null;
  NOT?: VerificationWorkflowStatusWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  name?: StringFilter | null;
  position?: FloatFilter | null;
  locationId?: UuidFilter | null;
  stage?: EnumWorkflowStageFilter | null;
  description?: StringNullableFilter | null;
  location?: LocationRelationFilter | null;
  insurancePolicies?: InsurancePolicyListRelationFilter | null;
  locationDefault?: LocationRelationFilter | null;
  confirmationLocation?: LocationRelationFilter | null;
  unconfirmationLocation?: LocationRelationFilter | null;
}

export interface VerificationWorkflowStatusWhereUniqueInput {
  id?: string | null;
}

export interface VisitCollectionRequestCreateManyAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  createdById?: string | null;
  estimateId?: string | null;
  coverageBenefitId?: string | null;
  depositMappingId?: string | null;
  amount: number;
  appointmentId: string;
  billId?: string | null;
  note?: string | null;
}

export interface VisitCollectionRequestCreateManyAccountInputEnvelope {
  data: VisitCollectionRequestCreateManyAccountInput[];
  skipDuplicates?: boolean | null;
}

export interface VisitCollectionRequestCreateManyAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  createdById?: string | null;
  estimateId?: string | null;
  accountId: string;
  coverageBenefitId?: string | null;
  depositMappingId?: string | null;
  amount: number;
  billId?: string | null;
  note?: string | null;
}

export interface VisitCollectionRequestCreateManyAppointmentInputEnvelope {
  data: VisitCollectionRequestCreateManyAppointmentInput[];
  skipDuplicates?: boolean | null;
}

export interface VisitCollectionRequestCreateManyBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  createdById?: string | null;
  estimateId?: string | null;
  accountId: string;
  coverageBenefitId?: string | null;
  depositMappingId?: string | null;
  amount: number;
  appointmentId: string;
  note?: string | null;
}

export interface VisitCollectionRequestCreateManyBillInputEnvelope {
  data: VisitCollectionRequestCreateManyBillInput[];
  skipDuplicates?: boolean | null;
}

export interface VisitCollectionRequestCreateManyCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  createdById?: string | null;
  estimateId?: string | null;
  accountId: string;
  depositMappingId?: string | null;
  amount: number;
  appointmentId: string;
  billId?: string | null;
  note?: string | null;
}

export interface VisitCollectionRequestCreateManyCoverageBenefitInputEnvelope {
  data: VisitCollectionRequestCreateManyCoverageBenefitInput[];
  skipDuplicates?: boolean | null;
}

export interface VisitCollectionRequestCreateManyCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  estimateId?: string | null;
  accountId: string;
  coverageBenefitId?: string | null;
  depositMappingId?: string | null;
  amount: number;
  appointmentId: string;
  billId?: string | null;
  note?: string | null;
}

export interface VisitCollectionRequestCreateManyCreatedByInputEnvelope {
  data: VisitCollectionRequestCreateManyCreatedByInput[];
  skipDuplicates?: boolean | null;
}

export interface VisitCollectionRequestCreateManyDepositMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  createdById?: string | null;
  estimateId?: string | null;
  accountId: string;
  coverageBenefitId?: string | null;
  amount: number;
  appointmentId: string;
  billId?: string | null;
  note?: string | null;
}

export interface VisitCollectionRequestCreateManyDepositMappingInputEnvelope {
  data: VisitCollectionRequestCreateManyDepositMappingInput[];
  skipDuplicates?: boolean | null;
}

export interface VisitCollectionRequestCreateManyEstimateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  createdById?: string | null;
  accountId: string;
  coverageBenefitId?: string | null;
  depositMappingId?: string | null;
  amount: number;
  appointmentId: string;
  billId?: string | null;
  note?: string | null;
}

export interface VisitCollectionRequestCreateManyEstimateInputEnvelope {
  data: VisitCollectionRequestCreateManyEstimateInput[];
  skipDuplicates?: boolean | null;
}

export interface VisitCollectionRequestCreateNestedManyWithoutAccountInput {
  create?: VisitCollectionRequestCreateWithoutAccountInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutAccountInput[] | null;
  createMany?: VisitCollectionRequestCreateManyAccountInputEnvelope | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
}

export interface VisitCollectionRequestCreateNestedManyWithoutAppointmentInput {
  create?: VisitCollectionRequestCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutAppointmentInput[] | null;
  createMany?: VisitCollectionRequestCreateManyAppointmentInputEnvelope | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
}

export interface VisitCollectionRequestCreateNestedManyWithoutBillInput {
  create?: VisitCollectionRequestCreateWithoutBillInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutBillInput[] | null;
  createMany?: VisitCollectionRequestCreateManyBillInputEnvelope | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
}

export interface VisitCollectionRequestCreateNestedManyWithoutCoverageBenefitInput {
  create?: VisitCollectionRequestCreateWithoutCoverageBenefitInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutCoverageBenefitInput[] | null;
  createMany?: VisitCollectionRequestCreateManyCoverageBenefitInputEnvelope | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
}

export interface VisitCollectionRequestCreateNestedManyWithoutCreatedByInput {
  create?: VisitCollectionRequestCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutCreatedByInput[] | null;
  createMany?: VisitCollectionRequestCreateManyCreatedByInputEnvelope | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
}

export interface VisitCollectionRequestCreateNestedManyWithoutDepositMappingInput {
  create?: VisitCollectionRequestCreateWithoutDepositMappingInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutDepositMappingInput[] | null;
  createMany?: VisitCollectionRequestCreateManyDepositMappingInputEnvelope | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
}

export interface VisitCollectionRequestCreateNestedManyWithoutEstimateInput {
  create?: VisitCollectionRequestCreateWithoutEstimateInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutEstimateInput[] | null;
  createMany?: VisitCollectionRequestCreateManyEstimateInputEnvelope | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
}

export interface VisitCollectionRequestCreateNestedOneWithoutCommunicationsInput {
  create?: VisitCollectionRequestCreateWithoutCommunicationsInput | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutCommunicationsInput | null;
  connect?: VisitCollectionRequestWhereUniqueInput | null;
}

export interface VisitCollectionRequestCreateOrConnectWithoutAccountInput {
  where: VisitCollectionRequestWhereUniqueInput;
  create: VisitCollectionRequestCreateWithoutAccountInput;
}

export interface VisitCollectionRequestCreateOrConnectWithoutAppointmentInput {
  where: VisitCollectionRequestWhereUniqueInput;
  create: VisitCollectionRequestCreateWithoutAppointmentInput;
}

export interface VisitCollectionRequestCreateOrConnectWithoutBillInput {
  where: VisitCollectionRequestWhereUniqueInput;
  create: VisitCollectionRequestCreateWithoutBillInput;
}

export interface VisitCollectionRequestCreateOrConnectWithoutCommunicationsInput {
  where: VisitCollectionRequestWhereUniqueInput;
  create: VisitCollectionRequestCreateWithoutCommunicationsInput;
}

export interface VisitCollectionRequestCreateOrConnectWithoutCoverageBenefitInput {
  where: VisitCollectionRequestWhereUniqueInput;
  create: VisitCollectionRequestCreateWithoutCoverageBenefitInput;
}

export interface VisitCollectionRequestCreateOrConnectWithoutCreatedByInput {
  where: VisitCollectionRequestWhereUniqueInput;
  create: VisitCollectionRequestCreateWithoutCreatedByInput;
}

export interface VisitCollectionRequestCreateOrConnectWithoutDepositMappingInput {
  where: VisitCollectionRequestWhereUniqueInput;
  create: VisitCollectionRequestCreateWithoutDepositMappingInput;
}

export interface VisitCollectionRequestCreateOrConnectWithoutEstimateInput {
  where: VisitCollectionRequestWhereUniqueInput;
  create: VisitCollectionRequestCreateWithoutEstimateInput;
}

export interface VisitCollectionRequestCreateWithoutAccountInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  amount: number;
  note?: string | null;
  createdBy?: UserCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  estimate?: EstimateCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  depositMapping?: DepositMappingCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  appointment: AppointmentCreateNestedOneWithoutVisitCollectionRequestsInput;
  bill?: BillCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  communications?: CommunicationCreateNestedManyWithoutVisitCollectionRequestInput | null;
}

export interface VisitCollectionRequestCreateWithoutAppointmentInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  amount: number;
  note?: string | null;
  createdBy?: UserCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  estimate?: EstimateCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  account: AccountCreateNestedOneWithoutVisitCollectionRequestsInput;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  depositMapping?: DepositMappingCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  bill?: BillCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  communications?: CommunicationCreateNestedManyWithoutVisitCollectionRequestInput | null;
}

export interface VisitCollectionRequestCreateWithoutBillInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  amount: number;
  note?: string | null;
  createdBy?: UserCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  estimate?: EstimateCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  account: AccountCreateNestedOneWithoutVisitCollectionRequestsInput;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  depositMapping?: DepositMappingCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  appointment: AppointmentCreateNestedOneWithoutVisitCollectionRequestsInput;
  communications?: CommunicationCreateNestedManyWithoutVisitCollectionRequestInput | null;
}

export interface VisitCollectionRequestCreateWithoutCommunicationsInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  amount: number;
  note?: string | null;
  createdBy?: UserCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  estimate?: EstimateCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  account: AccountCreateNestedOneWithoutVisitCollectionRequestsInput;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  depositMapping?: DepositMappingCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  appointment: AppointmentCreateNestedOneWithoutVisitCollectionRequestsInput;
  bill?: BillCreateNestedOneWithoutVisitCollectionRequestsInput | null;
}

export interface VisitCollectionRequestCreateWithoutCoverageBenefitInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  amount: number;
  note?: string | null;
  createdBy?: UserCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  estimate?: EstimateCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  account: AccountCreateNestedOneWithoutVisitCollectionRequestsInput;
  depositMapping?: DepositMappingCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  appointment: AppointmentCreateNestedOneWithoutVisitCollectionRequestsInput;
  bill?: BillCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  communications?: CommunicationCreateNestedManyWithoutVisitCollectionRequestInput | null;
}

export interface VisitCollectionRequestCreateWithoutCreatedByInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  amount: number;
  note?: string | null;
  estimate?: EstimateCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  account: AccountCreateNestedOneWithoutVisitCollectionRequestsInput;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  depositMapping?: DepositMappingCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  appointment: AppointmentCreateNestedOneWithoutVisitCollectionRequestsInput;
  bill?: BillCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  communications?: CommunicationCreateNestedManyWithoutVisitCollectionRequestInput | null;
}

export interface VisitCollectionRequestCreateWithoutDepositMappingInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  amount: number;
  note?: string | null;
  createdBy?: UserCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  estimate?: EstimateCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  account: AccountCreateNestedOneWithoutVisitCollectionRequestsInput;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  appointment: AppointmentCreateNestedOneWithoutVisitCollectionRequestsInput;
  bill?: BillCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  communications?: CommunicationCreateNestedManyWithoutVisitCollectionRequestInput | null;
}

export interface VisitCollectionRequestCreateWithoutEstimateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  estimateType: CollectionRequestType;
  amount: number;
  note?: string | null;
  createdBy?: UserCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  account: AccountCreateNestedOneWithoutVisitCollectionRequestsInput;
  coverageBenefit?: CoverageBenefitCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  depositMapping?: DepositMappingCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  appointment: AppointmentCreateNestedOneWithoutVisitCollectionRequestsInput;
  bill?: BillCreateNestedOneWithoutVisitCollectionRequestsInput | null;
  communications?: CommunicationCreateNestedManyWithoutVisitCollectionRequestInput | null;
}

export interface VisitCollectionRequestListRelationFilter {
  every?: VisitCollectionRequestWhereInput | null;
  some?: VisitCollectionRequestWhereInput | null;
  none?: VisitCollectionRequestWhereInput | null;
}

export interface VisitCollectionRequestOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface VisitCollectionRequestRelationFilter {
  is?: VisitCollectionRequestWhereInput | null;
  isNot?: VisitCollectionRequestWhereInput | null;
}

export interface VisitCollectionRequestScalarWhereInput {
  AND?: VisitCollectionRequestScalarWhereInput[] | null;
  OR?: VisitCollectionRequestScalarWhereInput[] | null;
  NOT?: VisitCollectionRequestScalarWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  estimateType?: EnumCollectionRequestTypeFilter | null;
  createdById?: UuidNullableFilter | null;
  estimateId?: UuidNullableFilter | null;
  accountId?: UuidFilter | null;
  coverageBenefitId?: UuidNullableFilter | null;
  depositMappingId?: UuidNullableFilter | null;
  amount?: IntFilter | null;
  appointmentId?: UuidFilter | null;
  billId?: UuidNullableFilter | null;
  note?: StringNullableFilter | null;
}

export interface VisitCollectionRequestUpdateManyMutationInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  estimateType?: EnumCollectionRequestTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
}

export interface VisitCollectionRequestUpdateManyWithWhereWithoutAccountInput {
  where: VisitCollectionRequestScalarWhereInput;
  data: VisitCollectionRequestUpdateManyMutationInput;
}

export interface VisitCollectionRequestUpdateManyWithWhereWithoutAppointmentInput {
  where: VisitCollectionRequestScalarWhereInput;
  data: VisitCollectionRequestUpdateManyMutationInput;
}

export interface VisitCollectionRequestUpdateManyWithWhereWithoutBillInput {
  where: VisitCollectionRequestScalarWhereInput;
  data: VisitCollectionRequestUpdateManyMutationInput;
}

export interface VisitCollectionRequestUpdateManyWithWhereWithoutCoverageBenefitInput {
  where: VisitCollectionRequestScalarWhereInput;
  data: VisitCollectionRequestUpdateManyMutationInput;
}

export interface VisitCollectionRequestUpdateManyWithWhereWithoutCreatedByInput {
  where: VisitCollectionRequestScalarWhereInput;
  data: VisitCollectionRequestUpdateManyMutationInput;
}

export interface VisitCollectionRequestUpdateManyWithWhereWithoutDepositMappingInput {
  where: VisitCollectionRequestScalarWhereInput;
  data: VisitCollectionRequestUpdateManyMutationInput;
}

export interface VisitCollectionRequestUpdateManyWithWhereWithoutEstimateInput {
  where: VisitCollectionRequestScalarWhereInput;
  data: VisitCollectionRequestUpdateManyMutationInput;
}

export interface VisitCollectionRequestUpdateManyWithoutAccountNestedInput {
  create?: VisitCollectionRequestCreateWithoutAccountInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutAccountInput[] | null;
  upsert?: VisitCollectionRequestUpsertWithWhereUniqueWithoutAccountInput[] | null;
  createMany?: VisitCollectionRequestCreateManyAccountInputEnvelope | null;
  set?: VisitCollectionRequestWhereUniqueInput[] | null;
  disconnect?: VisitCollectionRequestWhereUniqueInput[] | null;
  delete?: VisitCollectionRequestWhereUniqueInput[] | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
  update?: VisitCollectionRequestUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: VisitCollectionRequestUpdateManyWithWhereWithoutAccountInput[] | null;
  deleteMany?: VisitCollectionRequestScalarWhereInput[] | null;
}

export interface VisitCollectionRequestUpdateManyWithoutAppointmentNestedInput {
  create?: VisitCollectionRequestCreateWithoutAppointmentInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutAppointmentInput[] | null;
  upsert?: VisitCollectionRequestUpsertWithWhereUniqueWithoutAppointmentInput[] | null;
  createMany?: VisitCollectionRequestCreateManyAppointmentInputEnvelope | null;
  set?: VisitCollectionRequestWhereUniqueInput[] | null;
  disconnect?: VisitCollectionRequestWhereUniqueInput[] | null;
  delete?: VisitCollectionRequestWhereUniqueInput[] | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
  update?: VisitCollectionRequestUpdateWithWhereUniqueWithoutAppointmentInput[] | null;
  updateMany?: VisitCollectionRequestUpdateManyWithWhereWithoutAppointmentInput[] | null;
  deleteMany?: VisitCollectionRequestScalarWhereInput[] | null;
}

export interface VisitCollectionRequestUpdateManyWithoutBillNestedInput {
  create?: VisitCollectionRequestCreateWithoutBillInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutBillInput[] | null;
  upsert?: VisitCollectionRequestUpsertWithWhereUniqueWithoutBillInput[] | null;
  createMany?: VisitCollectionRequestCreateManyBillInputEnvelope | null;
  set?: VisitCollectionRequestWhereUniqueInput[] | null;
  disconnect?: VisitCollectionRequestWhereUniqueInput[] | null;
  delete?: VisitCollectionRequestWhereUniqueInput[] | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
  update?: VisitCollectionRequestUpdateWithWhereUniqueWithoutBillInput[] | null;
  updateMany?: VisitCollectionRequestUpdateManyWithWhereWithoutBillInput[] | null;
  deleteMany?: VisitCollectionRequestScalarWhereInput[] | null;
}

export interface VisitCollectionRequestUpdateManyWithoutCoverageBenefitNestedInput {
  create?: VisitCollectionRequestCreateWithoutCoverageBenefitInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutCoverageBenefitInput[] | null;
  upsert?: VisitCollectionRequestUpsertWithWhereUniqueWithoutCoverageBenefitInput[] | null;
  createMany?: VisitCollectionRequestCreateManyCoverageBenefitInputEnvelope | null;
  set?: VisitCollectionRequestWhereUniqueInput[] | null;
  disconnect?: VisitCollectionRequestWhereUniqueInput[] | null;
  delete?: VisitCollectionRequestWhereUniqueInput[] | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
  update?: VisitCollectionRequestUpdateWithWhereUniqueWithoutCoverageBenefitInput[] | null;
  updateMany?: VisitCollectionRequestUpdateManyWithWhereWithoutCoverageBenefitInput[] | null;
  deleteMany?: VisitCollectionRequestScalarWhereInput[] | null;
}

export interface VisitCollectionRequestUpdateManyWithoutCreatedByNestedInput {
  create?: VisitCollectionRequestCreateWithoutCreatedByInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutCreatedByInput[] | null;
  upsert?: VisitCollectionRequestUpsertWithWhereUniqueWithoutCreatedByInput[] | null;
  createMany?: VisitCollectionRequestCreateManyCreatedByInputEnvelope | null;
  set?: VisitCollectionRequestWhereUniqueInput[] | null;
  disconnect?: VisitCollectionRequestWhereUniqueInput[] | null;
  delete?: VisitCollectionRequestWhereUniqueInput[] | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
  update?: VisitCollectionRequestUpdateWithWhereUniqueWithoutCreatedByInput[] | null;
  updateMany?: VisitCollectionRequestUpdateManyWithWhereWithoutCreatedByInput[] | null;
  deleteMany?: VisitCollectionRequestScalarWhereInput[] | null;
}

export interface VisitCollectionRequestUpdateManyWithoutDepositMappingNestedInput {
  create?: VisitCollectionRequestCreateWithoutDepositMappingInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutDepositMappingInput[] | null;
  upsert?: VisitCollectionRequestUpsertWithWhereUniqueWithoutDepositMappingInput[] | null;
  createMany?: VisitCollectionRequestCreateManyDepositMappingInputEnvelope | null;
  set?: VisitCollectionRequestWhereUniqueInput[] | null;
  disconnect?: VisitCollectionRequestWhereUniqueInput[] | null;
  delete?: VisitCollectionRequestWhereUniqueInput[] | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
  update?: VisitCollectionRequestUpdateWithWhereUniqueWithoutDepositMappingInput[] | null;
  updateMany?: VisitCollectionRequestUpdateManyWithWhereWithoutDepositMappingInput[] | null;
  deleteMany?: VisitCollectionRequestScalarWhereInput[] | null;
}

export interface VisitCollectionRequestUpdateManyWithoutEstimateNestedInput {
  create?: VisitCollectionRequestCreateWithoutEstimateInput[] | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutEstimateInput[] | null;
  upsert?: VisitCollectionRequestUpsertWithWhereUniqueWithoutEstimateInput[] | null;
  createMany?: VisitCollectionRequestCreateManyEstimateInputEnvelope | null;
  set?: VisitCollectionRequestWhereUniqueInput[] | null;
  disconnect?: VisitCollectionRequestWhereUniqueInput[] | null;
  delete?: VisitCollectionRequestWhereUniqueInput[] | null;
  connect?: VisitCollectionRequestWhereUniqueInput[] | null;
  update?: VisitCollectionRequestUpdateWithWhereUniqueWithoutEstimateInput[] | null;
  updateMany?: VisitCollectionRequestUpdateManyWithWhereWithoutEstimateInput[] | null;
  deleteMany?: VisitCollectionRequestScalarWhereInput[] | null;
}

export interface VisitCollectionRequestUpdateOneWithoutCommunicationsNestedInput {
  create?: VisitCollectionRequestCreateWithoutCommunicationsInput | null;
  connectOrCreate?: VisitCollectionRequestCreateOrConnectWithoutCommunicationsInput | null;
  upsert?: VisitCollectionRequestUpsertWithoutCommunicationsInput | null;
  disconnect?: boolean | null;
  delete?: boolean | null;
  connect?: VisitCollectionRequestWhereUniqueInput | null;
  update?: VisitCollectionRequestUpdateWithoutCommunicationsInput | null;
}

export interface VisitCollectionRequestUpdateWithWhereUniqueWithoutAccountInput {
  where: VisitCollectionRequestWhereUniqueInput;
  data: VisitCollectionRequestUpdateWithoutAccountInput;
}

export interface VisitCollectionRequestUpdateWithWhereUniqueWithoutAppointmentInput {
  where: VisitCollectionRequestWhereUniqueInput;
  data: VisitCollectionRequestUpdateWithoutAppointmentInput;
}

export interface VisitCollectionRequestUpdateWithWhereUniqueWithoutBillInput {
  where: VisitCollectionRequestWhereUniqueInput;
  data: VisitCollectionRequestUpdateWithoutBillInput;
}

export interface VisitCollectionRequestUpdateWithWhereUniqueWithoutCoverageBenefitInput {
  where: VisitCollectionRequestWhereUniqueInput;
  data: VisitCollectionRequestUpdateWithoutCoverageBenefitInput;
}

export interface VisitCollectionRequestUpdateWithWhereUniqueWithoutCreatedByInput {
  where: VisitCollectionRequestWhereUniqueInput;
  data: VisitCollectionRequestUpdateWithoutCreatedByInput;
}

export interface VisitCollectionRequestUpdateWithWhereUniqueWithoutDepositMappingInput {
  where: VisitCollectionRequestWhereUniqueInput;
  data: VisitCollectionRequestUpdateWithoutDepositMappingInput;
}

export interface VisitCollectionRequestUpdateWithWhereUniqueWithoutEstimateInput {
  where: VisitCollectionRequestWhereUniqueInput;
  data: VisitCollectionRequestUpdateWithoutEstimateInput;
}

export interface VisitCollectionRequestUpdateWithoutAccountInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  estimateType?: EnumCollectionRequestTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  estimate?: EstimateUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  depositMapping?: DepositMappingUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  bill?: BillUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutVisitCollectionRequestNestedInput | null;
}

export interface VisitCollectionRequestUpdateWithoutAppointmentInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  estimateType?: EnumCollectionRequestTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  estimate?: EstimateUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  depositMapping?: DepositMappingUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  bill?: BillUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutVisitCollectionRequestNestedInput | null;
}

export interface VisitCollectionRequestUpdateWithoutBillInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  estimateType?: EnumCollectionRequestTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  estimate?: EstimateUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  depositMapping?: DepositMappingUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutVisitCollectionRequestNestedInput | null;
}

export interface VisitCollectionRequestUpdateWithoutCommunicationsInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  estimateType?: EnumCollectionRequestTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  estimate?: EstimateUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  depositMapping?: DepositMappingUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  bill?: BillUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
}

export interface VisitCollectionRequestUpdateWithoutCoverageBenefitInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  estimateType?: EnumCollectionRequestTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  estimate?: EstimateUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  depositMapping?: DepositMappingUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  bill?: BillUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutVisitCollectionRequestNestedInput | null;
}

export interface VisitCollectionRequestUpdateWithoutCreatedByInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  estimateType?: EnumCollectionRequestTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  estimate?: EstimateUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  depositMapping?: DepositMappingUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  bill?: BillUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutVisitCollectionRequestNestedInput | null;
}

export interface VisitCollectionRequestUpdateWithoutDepositMappingInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  estimateType?: EnumCollectionRequestTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  estimate?: EstimateUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  bill?: BillUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutVisitCollectionRequestNestedInput | null;
}

export interface VisitCollectionRequestUpdateWithoutEstimateInput {
  id?: StringFieldUpdateOperationsInput | null;
  createdAt?: DateTimeFieldUpdateOperationsInput | null;
  updatedAt?: DateTimeFieldUpdateOperationsInput | null;
  estimateType?: EnumCollectionRequestTypeFieldUpdateOperationsInput | null;
  amount?: IntFieldUpdateOperationsInput | null;
  note?: NullableStringFieldUpdateOperationsInput | null;
  createdBy?: UserUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  account?: AccountUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  coverageBenefit?: CoverageBenefitUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  depositMapping?: DepositMappingUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  appointment?: AppointmentUpdateOneRequiredWithoutVisitCollectionRequestsNestedInput | null;
  bill?: BillUpdateOneWithoutVisitCollectionRequestsNestedInput | null;
  communications?: CommunicationUpdateManyWithoutVisitCollectionRequestNestedInput | null;
}

export interface VisitCollectionRequestUpsertWithWhereUniqueWithoutAccountInput {
  where: VisitCollectionRequestWhereUniqueInput;
  update: VisitCollectionRequestUpdateWithoutAccountInput;
  create: VisitCollectionRequestCreateWithoutAccountInput;
}

export interface VisitCollectionRequestUpsertWithWhereUniqueWithoutAppointmentInput {
  where: VisitCollectionRequestWhereUniqueInput;
  update: VisitCollectionRequestUpdateWithoutAppointmentInput;
  create: VisitCollectionRequestCreateWithoutAppointmentInput;
}

export interface VisitCollectionRequestUpsertWithWhereUniqueWithoutBillInput {
  where: VisitCollectionRequestWhereUniqueInput;
  update: VisitCollectionRequestUpdateWithoutBillInput;
  create: VisitCollectionRequestCreateWithoutBillInput;
}

export interface VisitCollectionRequestUpsertWithWhereUniqueWithoutCoverageBenefitInput {
  where: VisitCollectionRequestWhereUniqueInput;
  update: VisitCollectionRequestUpdateWithoutCoverageBenefitInput;
  create: VisitCollectionRequestCreateWithoutCoverageBenefitInput;
}

export interface VisitCollectionRequestUpsertWithWhereUniqueWithoutCreatedByInput {
  where: VisitCollectionRequestWhereUniqueInput;
  update: VisitCollectionRequestUpdateWithoutCreatedByInput;
  create: VisitCollectionRequestCreateWithoutCreatedByInput;
}

export interface VisitCollectionRequestUpsertWithWhereUniqueWithoutDepositMappingInput {
  where: VisitCollectionRequestWhereUniqueInput;
  update: VisitCollectionRequestUpdateWithoutDepositMappingInput;
  create: VisitCollectionRequestCreateWithoutDepositMappingInput;
}

export interface VisitCollectionRequestUpsertWithWhereUniqueWithoutEstimateInput {
  where: VisitCollectionRequestWhereUniqueInput;
  update: VisitCollectionRequestUpdateWithoutEstimateInput;
  create: VisitCollectionRequestCreateWithoutEstimateInput;
}

export interface VisitCollectionRequestUpsertWithoutCommunicationsInput {
  update: VisitCollectionRequestUpdateWithoutCommunicationsInput;
  create: VisitCollectionRequestCreateWithoutCommunicationsInput;
}

export interface VisitCollectionRequestWhereInput {
  AND?: VisitCollectionRequestWhereInput[] | null;
  OR?: VisitCollectionRequestWhereInput[] | null;
  NOT?: VisitCollectionRequestWhereInput[] | null;
  id?: UuidFilter | null;
  createdAt?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  estimateType?: EnumCollectionRequestTypeFilter | null;
  createdById?: UuidNullableFilter | null;
  estimateId?: UuidNullableFilter | null;
  accountId?: UuidFilter | null;
  coverageBenefitId?: UuidNullableFilter | null;
  depositMappingId?: UuidNullableFilter | null;
  amount?: IntFilter | null;
  appointmentId?: UuidFilter | null;
  billId?: UuidNullableFilter | null;
  note?: StringNullableFilter | null;
  createdBy?: UserRelationFilter | null;
  estimate?: EstimateRelationFilter | null;
  account?: AccountRelationFilter | null;
  coverageBenefit?: CoverageBenefitRelationFilter | null;
  depositMapping?: DepositMappingRelationFilter | null;
  appointment?: AppointmentRelationFilter | null;
  bill?: BillRelationFilter | null;
  communications?: CommunicationListRelationFilter | null;
}

export interface VisitCollectionRequestWhereUniqueInput {
  id?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
