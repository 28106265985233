import React, { Fragment, useState } from "react";
import { InformationCircleIcon, SearchIcon } from "@heroicons/react/outline";
import { gql, useMutation, useQuery } from "@apollo/client";

import { useUser } from "../../user-context";
import { Table, Td } from "../../components/Table";
import { GetPayers_payers as Payer } from "../../generated/GetPayers";
import { Badge, Modal, SubmitButton, Tooltip } from "../../components";
import {
  SetPayerTradingPartner,
  SetPayerTradingPartnerVariables,
} from "../../generated/SetPayerTradingPartner";
import {
  ClearPayerTradingPartner,
  ClearPayerTradingPartnerVariables,
  ClearPayerTradingPartner_updateOnePayer_tradingPartner as TradingPartner,
} from "../../generated/ClearPayerTradingPartner";
import {
  SetPayerInNetwork,
  SetPayerInNetworkVariables,
} from "../../generated/SetPayerInNetwork";
import {
  SetPayerOutNetwork,
  SetPayerOutNetworkVariables,
} from "../../generated/SetPayerOutNetwork";
import { OvalSpinner } from "../../components/loading";
import { Combobox, Dialog, Switch, Transition } from "@headlessui/react";
import { classNames } from "../../utils";
import { toast } from "react-toastify";
import { Layout } from "../layout";
import fuzzysort from "fuzzysort";
import { LoadAllTradingPartners } from "../../generated/LoadAllTradingPartners";
import {
  TogglePayerExcludedFromBenefitsWorkflow,
  TogglePayerExcludedFromBenefitsWorkflowVariables,
} from "../../generated/TogglePayerExcludedFromBenefitsWorkflow";
import { useFeatureFlags } from "../../hooks";
import { Link } from "react-router-dom";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { v4 as uuidV4 } from "uuid";
import { Form } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { UpsertPayer, UpsertPayerVariables } from "../../generated/UpsertPayer";

export const GET_PAYERS = gql`
  query GetPayers($organizationId: String!, $locationId: String!) {
    payers(
      where: {
        organizationId: { equals: $organizationId }
        locationId: { equals: $locationId }
      }
      orderBy: { name: asc }
    ) {
      id
      name
      excludedFromBenefitsWorkflow
      tradingPartner {
        id
        name
        changeTradingPartnerId
        eligibilityEnabled
      }
      organizationInNetworkPayers {
        id
      }
    }
  }
`;

export const LOAD_ALL_TRADING_PARTNERS = gql`
  query LoadAllTradingPartners {
    tradingPartners(orderBy: [{ name: asc }]) {
      id
      name
      changeTradingPartnerId
      eligibilityEnabled
    }
  }
`;

const EmptyPayers: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div className="text-center">
    <svg
      className="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
    <h3 className="mt-2 text-sm font-medium text-gray-900">No payers</h3>
    <p className="mt-1 text-sm text-gray-500">
      Get started by creating a new payer.
    </p>
    {/* <div className="mt-6">
      <Link
        to="/payers/new"
        className="flex px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        New Payer
      </Link>
    </div> */}
  </div>
);

const SET_PAYER_TRADING_PARTNER = gql`
  mutation SetPayerTradingPartner(
    $payerId: String!
    $tradingPartnerId: String!
  ) {
    updateOnePayer(
      where: { id: $payerId }
      data: { tradingPartner: { connect: { id: $tradingPartnerId } } }
    ) {
      id
      name
      tradingPartner {
        id
        name
        changeTradingPartnerId
        eligibilityEnabled
      }
      organizationInNetworkPayers {
        id
      }
    }
  }
`;

const CLEAR_PAYER_TRADING_PARTNER = gql`
  mutation ClearPayerTradingPartner($payerId: String!) {
    updateOnePayer(
      where: { id: $payerId }
      data: { tradingPartner: { disconnect: true } }
    ) {
      id
      name
      tradingPartner {
        id
        name
        changeTradingPartnerId
        eligibilityEnabled
      }
      organizationInNetworkPayers {
        id
      }
    }
  }
`;

const sortTradingPartners = ({
  searchQuery,
  tradingPartners,
}: {
  searchQuery: string;
  tradingPartners: TradingPartner[];
}) => {
  const sorted = fuzzysort.go(searchQuery, tradingPartners, {
    keys: ["name", "changeTradingPartnerId"],
    threshold: -2500,
    limit: 50,
  });
  return sorted;
};

export const TradingPartnerCommandPalete: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    defaultQuery: string | null;
    payer: Pick<Payer, "id">;
    onSelect?: (tradingPartner: TradingPartner) => void;
    allTradingPartners: TradingPartner[];
  }>
> = ({ open, setOpen, defaultQuery, payer, onSelect, allTradingPartners }) => {
  const [query, setQuery] = useState(defaultQuery);
  const [selectedTradingPartner, setSelectedTradingPartner] =
    useState<TradingPartner | null>(null);
  const [setPayerTradingPartner, setPayerTradingPartnerResult] = useMutation<
    SetPayerTradingPartner,
    SetPayerTradingPartnerVariables
  >(SET_PAYER_TRADING_PARTNER);

  const onSelectTradingPartner =
    onSelect ??
    ((tradingPartner: TradingPartner) => {
      setPayerTradingPartner({
        variables: {
          payerId: payer.id,
          tradingPartnerId: tradingPartner.id,
        },
        onCompleted: () => {
          setOpen(false);
          toast.success("Trading partner set successfully");
        },
        onError: (error) => {
          toast.error("Trading partner set failed");
        },
      });
    });

  const tradingPartners = sortTradingPartners({
    searchQuery: query ?? "",
    tradingPartners: allTradingPartners,
  });

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl p-2 transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                value={selectedTradingPartner}
                onChange={(tradingPartner: TradingPartner) => {
                  setSelectedTradingPartner(tradingPartner);
                  onSelectTradingPartner(tradingPartner);
                }}
              >
                <div className="relative">
                  <SearchIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search for a trading partner..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {tradingPartners.length > 0 && (
                  <Combobox.Options
                    static
                    className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                  >
                    {tradingPartners.map((result) => {
                      const tradingPartner = result.obj;
                      return (
                        <Combobox.Option
                          key={tradingPartner.id}
                          value={tradingPartner}
                          className={({ active, selected }) =>
                            classNames(
                              "cursor-default select-none px-4 py-2 rounded-md flex flex-wrap items-center gap-2",
                              active ? "bg-indigo-600 text-white" : "",
                              selected ? "bg-indigo-600 text-white" : ""
                            )
                          }
                        >
                          {({ active, selected }) => (
                            <>
                              <div className="contents">
                                {result[0]
                                  ? fuzzysort.highlight(result[0], (m, i) => (
                                      <div
                                        className={classNames(
                                          "font-semibold text-indigo-600",
                                          active ? "text-yellow-300" : "",
                                          selected ? "text-yellow-300" : ""
                                        )}
                                        key={i}
                                      >
                                        {m}
                                      </div>
                                    ))
                                  : tradingPartner.name}{" "}
                              </div>
                              <div className="contents">
                                <div className="flex">
                                  (
                                  {result[1]
                                    ? fuzzysort.highlight(result[1], (m, i) => (
                                        <div
                                          className={classNames(
                                            "font-semibold text-indigo-600",
                                            active ? "text-yellow-300" : "",
                                            selected ? "text-yellow-300" : ""
                                          )}
                                          key={i}
                                        >
                                          {m}
                                        </div>
                                      ))
                                    : tradingPartner.changeTradingPartnerId}
                                  )
                                </div>
                              </div>
                              {tradingPartner.eligibilityEnabled ? (
                                <Badge
                                  variant="success"
                                  text="Eligibility Supported"
                                />
                              ) : (
                                <Badge
                                  variant="warning"
                                  text="Eligibility Unsupported"
                                />
                              )}
                            </>
                          )}
                        </Combobox.Option>
                      );
                    })}
                  </Combobox.Options>
                )}

                {query !== "" && tradingPartners.length === 0 && (
                  <p className="p-4 text-sm text-gray-500">
                    No trading partners found.
                  </p>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const TradingPartnerCommandPaleteButton: React.FC<
  React.PropsWithChildren<{
    tradingPartner: string | null;
    payer: Payer;
    allTradingPartners: TradingPartner[];
  }>
> = ({ tradingPartner, payer, allTradingPartners }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        className="inline-flex justify-center items-center rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
        onClick={() => {
          setOpen(true);
        }}
      >
        Edit
      </button>
      {open && (
        <TradingPartnerCommandPalete
          open={open}
          setOpen={setOpen}
          defaultQuery={tradingPartner}
          payer={payer}
          allTradingPartners={allTradingPartners}
        />
      )}
    </>
  );
};

const ClearTradingPartnerMappingDialog: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    payer: Payer;
    tradingPartner: TradingPartner;
  }>
> = ({ open, setOpen, payer, tradingPartner }) => {
  const [clearPayerTradingPartner, clearPayerTradingPartnerResult] =
    useMutation<ClearPayerTradingPartner, ClearPayerTradingPartnerVariables>(
      CLEAR_PAYER_TRADING_PARTNER
    );
  if (!payer || !tradingPartner) return null;
  return (
    <Modal open={open} setOpen={setOpen}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <div className="flex justify-center gap-2 items-center text-2xl pb-4">
            Clear mapping from {payer.name} to {tradingPartner.name}?
          </div>
          <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
            <SubmitButton
              type="button"
              onClick={() => {
                clearPayerTradingPartner({
                  variables: {
                    payerId: payer.id,
                  },
                  onCompleted: () => {
                    setOpen(false);
                    toast.success("Payer trading partner cleared");
                  },
                  onError: (error) => {
                    setOpen(false);
                    toast.error("Failed to clear payer trading partner");
                  },
                });
              }}
              loading={clearPayerTradingPartnerResult.loading}
            >
              Clear
            </SubmitButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const PayerMappingButton: React.FC<
  React.PropsWithChildren<{
    payer: Payer;
    allTradingPartners: TradingPartner[];
  }>
> = ({ payer, allTradingPartners }) => {
  const [open, setOpen] = useState(false);
  return (
    <Td>
      <div className="flex gap-1 items-center">
        <div className="w-64 truncate text-ellipsis">
          {payer.tradingPartner ? (
            <div className="text-sm text-gray-900">
              {payer.tradingPartner.name} (
              {payer.tradingPartner.changeTradingPartnerId})
            </div>
          ) : (
            <div className="text-sm text-gray-700 italic">
              No trading partner mapped
            </div>
          )}
        </div>
        <div className="text-sm text-gray-900">
          <TradingPartnerCommandPaleteButton
            tradingPartner={payer.tradingPartner?.name ?? null}
            payer={payer}
            allTradingPartners={allTradingPartners}
          />
        </div>
        <div>
          <button
            className="inline-flex justify-center items-center rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-25"
            onClick={() => setOpen(true)}
            disabled={!payer.tradingPartner}
          >
            Clear
          </button>
        </div>
      </div>
      {open && (
        <ClearTradingPartnerMappingDialog
          open={open}
          setOpen={setOpen}
          payer={payer}
          tradingPartner={payer.tradingPartner!}
        />
      )}
    </Td>
  );
};

const SET_PAYER_IN_NETWORK = gql`
  mutation SetPayerInNetwork($payerId: String!, $organizationId: String!) {
    updateOnePayer(
      where: { id: $payerId }
      data: {
        organizationInNetworkPayers: {
          create: { organization: { connect: { id: $organizationId } } }
        }
      }
    ) {
      id
      name
      tradingPartner {
        id
        name
        changeTradingPartnerId
        eligibilityEnabled
      }
      organizationInNetworkPayers {
        id
      }
    }
  }
`;

const SET_PAYER_OUT_NETWORK = gql`
  mutation SetPayerOutNetwork($organizationInNetworkPayerId: String!) {
    deleteOneOrganizationInNetworkPayer(
      where: { id: $organizationInNetworkPayerId }
    ) {
      id
    }
  }
`;

const InNetworkToggleCell: React.FC<
  React.PropsWithChildren<{ payer: Payer }>
> = ({ payer }) => {
  const user = useUser()!;
  const organizationInNetworkPayerId = payer.organizationInNetworkPayers[0]?.id;
  const [enabled, setEnabled] = useState(
    payer.organizationInNetworkPayers.length > 0
  );
  const [setPayerInNetwork, setPayerInNetworkResult] = useMutation<
    SetPayerInNetwork,
    SetPayerInNetworkVariables
  >(SET_PAYER_IN_NETWORK);
  const [setPayerOutNetwork, setPayerOutNetworkResult] = useMutation<
    SetPayerOutNetwork,
    SetPayerOutNetworkVariables
  >(SET_PAYER_OUT_NETWORK);
  const onChange = (val: boolean) => {
    setEnabled(val);
    if (val) {
      setPayerInNetwork({
        variables: {
          payerId: payer.id,
          organizationId: user.organization.id,
        },
        onCompleted: () => {
          toast.success("Payer updated to in network");
        },
        onError: (error) => {
          toast.error("Failed to update payer to in network");
        },
      });
    }
    if (!val) {
      setPayerOutNetwork({
        variables: {
          organizationInNetworkPayerId,
        },
        onCompleted: () => {
          toast.success("Payer updated to out of network");
        },
        onError: (error) => {
          toast.error("Failed to update payer to out of network");
        },
      });
    }
  };
  return (
    <Td>
      <div className="flex items-center gap-1 text-sm text-gray-900">
        <Switch
          checked={enabled}
          onChange={onChange}
          className={classNames(
            enabled ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          )}
          disabled={
            setPayerInNetworkResult.loading || setPayerOutNetworkResult.loading
          }
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
        {(setPayerInNetworkResult.loading ||
          setPayerInNetworkResult.loading) && (
          <OvalSpinner className="w-4 h-4" />
        )}
      </div>
    </Td>
  );
};

const TOGGLE_PAYER_EXCLUDED_FROM_BENEFITS_WORKFLOW = gql`
  mutation TogglePayerExcludedFromBenefitsWorkflow(
    $id: String!
    $excluded: Boolean!
  ) {
    updateOnePayer(
      where: { id: $id }
      data: { excludedFromBenefitsWorkflow: { set: $excluded } }
    ) {
      id
      excludedFromBenefitsWorkflow
    }
  }
`;

const ExcludeFromBenefitsWorkflowCell: React.FC<
  React.PropsWithChildren<{ payer: Payer }>
> = ({ payer }) => {
  const [enabled, setEnabled] = useState(payer.excludedFromBenefitsWorkflow);
  const [
    togglePayerExcludedFromBenefitsWorkflow,
    togglePayerExcludedFromBenefitsWorkflowResult,
  ] = useMutation<
    TogglePayerExcludedFromBenefitsWorkflow,
    TogglePayerExcludedFromBenefitsWorkflowVariables
  >(TOGGLE_PAYER_EXCLUDED_FROM_BENEFITS_WORKFLOW);

  const onChange = (val: boolean) => {
    setEnabled(val);
    togglePayerExcludedFromBenefitsWorkflow({
      variables: {
        id: payer.id,
        excluded: val,
      },
      onCompleted: () => {
        if (val) {
          toast.success("Payer excluded from benefits workflow");
        } else {
          toast.success("Payer marked as included from benefits workflow");
        }
      },
      onError: () => {
        toast.error(
          `Failed to update payer as ${
            val ? "excluded" : "included"
          } from benefits workflow`
        );
      },
    });
  };
  return (
    <Td>
      <div className="flex items-center gap-1 text-sm text-gray-900">
        <Switch
          checked={enabled}
          onChange={onChange}
          className={classNames(
            enabled ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          )}
          disabled={togglePayerExcludedFromBenefitsWorkflowResult.loading}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
        {togglePayerExcludedFromBenefitsWorkflowResult.loading && (
          <OvalSpinner className="w-4 h-4" />
        )}
      </div>
    </Td>
  );
};

export const UPSERT_PAYER = gql`
  mutation UpsertPayer(
    $id: String!
    $create: PayerCreateInput!
    $update: PayerUpdateInput!
  ) {
    upsertOnePayer(where: { id: $id }, create: $create, update: $update) {
      id
      name
    }
  }
`;

const PayerSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const PayerForm: React.FC<{
  payer: Payer | null;
  onSuccess: () => void;
}> = ({ payer, onSuccess }) => {
  const user = useUser()!;

  const [upsertPayer, upsertPayerResult] = useMutation<
    UpsertPayer,
    UpsertPayerVariables
  >(UPSERT_PAYER);

  const form = useForm<z.infer<typeof PayerSchema>>({
    resolver: zodResolver(PayerSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      id: payer?.id ?? uuidV4(),
      name: payer?.name,
    },
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          upsertPayer({
            variables: {
              id: data.id,
              create: {
                id: data.id,
                name: data.name,
                location: { connect: { id: user.activeLocation.id } },
                organization: { connect: { id: user.organization.id } },
              },
              update: {
                name: { set: data.name },
              },
            },
            onCompleted: () => {
              toast.success("Payer saved");
              onSuccess();
            },
            onError: () => {
              toast.error("Failed to save payer");
            },
            refetchQueries: [GET_PAYERS],
          });
        })}
      >
        <div className="flex flex-col gap-8 p-2 w-full">
          <div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-4">
                <div className="w-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                  </label>
                  <Input {...form.register("name")} required />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div>
            <SubmitButton loading={upsertPayerResult.loading}>
              Save
            </SubmitButton>
          </div>
        </div>
      </form>
    </Form>
  );
};

export const PayerFormDialog: React.FC<{
  payer: Payer | null;
  setOpen: (open: boolean) => void;
}> = ({ payer, setOpen }) => {
  return (
    <Modal open={true} setOpen={setOpen}>
      <PayerForm payer={payer} onSuccess={() => setOpen(false)} />
    </Modal>
  );
};

const EditPayerCell: React.FC<{
  payer: Payer;
}> = ({ payer }) => {
  const [open, setOpen] = useState(false);
  return (
    <Td>
      <Button
        variant="ghost"
        size="sm"
        className="text-indigo-500 hover:bg-indigo-100"
        onClick={() => {
          setOpen(true);
        }}
      >
        Edit
      </Button>
      {open && <PayerFormDialog payer={payer} setOpen={setOpen} />}
    </Td>
  );
};

export const PayerList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser();
  const flags = useFeatureFlags();
  const { loading, error, data } = useQuery(GET_PAYERS, {
    variables: {
      organizationId: user!.organization.id,
      locationId: user!.activeLocation.id,
    },
  });
  const { data: tradingPartnerData } = useQuery<LoadAllTradingPartners>(
    LOAD_ALL_TRADING_PARTNERS
  );
  const allTradingPartners = tradingPartnerData?.tradingPartners ?? [];
  if (loading) return <>Loading</>;
  if (!data) return null;
  if (data.payers.length === 0) return <EmptyPayers />;
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <Table
            columnDefs={[
              {
                header: "Name",
                cellFn: (payer: Payer) => (
                  <Td>
                    <div className="text-sm text-gray-900">{payer.name}</div>
                  </Td>
                ),
              },
              {
                header: "In Network",
                cellFn: (payer: Payer) => <InNetworkToggleCell payer={payer} />,
              },
              {
                header: "Trading Partner",
                cellFn: (payer: Payer) => (
                  <PayerMappingButton
                    payer={payer}
                    allTradingPartners={allTradingPartners}
                  />
                ),
              },
              ...(flags.verificationWorkflowEnabled
                ? [
                    {
                      header: "Exclude from Verification Workflow",
                      headerComponent: (
                        <Tooltip
                          trigger={
                            <div className="flex gap-2">
                              Exclude from Verification Workflow{" "}
                              <InformationCircleIcon className="h-4 w-4" />
                            </div>
                          }
                          content={
                            <div className="max-w-sm">
                              Mark the payer as not needing benefits
                              verification. This will exclude patients with
                              policies from this payer from the verification
                              worklists.
                            </div>
                          }
                        />
                      ),
                      cellFn: (payer: Payer) => (
                        <ExcludeFromBenefitsWorkflowCell payer={payer} />
                      ),
                    },
                  ]
                : []),
              {
                header: "",
                cellFn: (payer: Payer) => <EditPayerCell payer={payer} />,
              },
            ]}
            rows={data.payers}
          />
        </div>
      </div>
    </div>
  );
};

export const Payers: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [open, setOpen] = useState(false);
  return (
    <Layout
      header={
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">Payers</h1>
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            New Payer
          </Button>
          {open && <PayerFormDialog payer={null} setOpen={setOpen} />}
        </div>
      }
      content={
        <div className="py-4">
          <PayerList />
        </div>
      }
    />
  );
};
