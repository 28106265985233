import { Cross2Icon, PlusCircledIcon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { addDays, format } from "date-fns";
import React, { useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import {
  DownloadDelinquentPatientsDialog,
  DownloadSentPatientsDialog,
} from "..";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Calendar } from "../../../components/ui/calendar";
import { Input } from "../../../components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { Separator } from "../../../components/ui/separator";
import { DataTableFacetedFilter } from "../../../components/ui/table-helpers/data-table-faceted-filter";
import { DataTableViewOptions } from "../../../components/ui/table-helpers/data-table-view-options";
import { BillState } from "../../../generated/globalTypes";
import { useUser } from "../../../user-context";
import { isDefined } from "../../../utils";
import { HorizontalPadding } from "../../layout";
import { BillsRow } from "./columns";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

interface DataTableToolbarProps<TData extends { id: string }> {
  table: Table<TData>;
}

const STATUS_OPTIONS = [
  { label: "In Review", value: BillState.InReview },
  { label: "Ready", value: BillState.Ready },
];

const SENT_OPTIONS = [
  { label: "Sent", value: "Sent" },
  { label: "Not Sent", value: "Not Sent" },
];

const SCHEDULED_OPTIONS = [
  { label: "Scheduled", value: "Scheduled" },
  { label: "Not Scheduled", value: "Not Scheduled" },
];

const REMINDER_OPTIONS = [
  { label: "Enrolled", value: "Enrolled" },
  { label: "Not Enrolled", value: "Not Enrolled" },
  { label: "Exhausted", value: "Exhausted" },
];

const ON_HOLD_OPTIONS = [
  { label: "On Hold", value: "true" },
  { label: "Not On Hold", value: "false" },
];

// Add new type for date filter
export type DateFilterType = "range" | "before" | "after";

const DateRangeFilter = ({ column, title }: { column: any; title: string }) => {
  const value = column?.getFilterValue() as
    | { from?: Date; to?: Date; type?: DateFilterType }
    | undefined;
  const [date, setDate] = React.useState<DateRange | undefined>(
    value ? { from: value.from, to: value.to } : undefined
  );
  const [filterType, setFilterType] = React.useState<DateFilterType>(
    value?.type || "range"
  );

  const clearFilter = () => {
    setDate(undefined);
    setFilterType("range");
    column?.setFilterValue(undefined);
  };

  const handleDateSelect = (selectedDate: DateRange | Date | undefined) => {
    if (!selectedDate) {
      column?.setFilterValue(undefined);
      return;
    }

    if (filterType === "range") {
      const dateRange = selectedDate as DateRange;
      const range = {
        from: dateRange.from,
        to: dateRange.to ? addDays(dateRange.to, 1) : undefined,
        type: filterType,
      };
      column?.setFilterValue(range);
      setDate(dateRange);
    } else if (filterType === "before") {
      const before = selectedDate as Date;
      const beforeRange = {
        from: undefined,
        to: before ? addDays(before, 1) : undefined,
        type: filterType,
      };
      column?.setFilterValue(beforeRange);
      setDate(beforeRange);
    } else if (filterType === "after") {
      const after = selectedDate as Date;
      const afterRange = {
        from: after,
        to: undefined,
        type: filterType,
      };
      column?.setFilterValue(afterRange);
      setDate(afterRange);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          <PlusCircledIcon className="mr-2 h-4 w-4" />
          {title}
          {value?.from || value?.to ? (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal"
              >
                {filterType === "before" && "Before "}
                {filterType === "after" && "After "}
                {value.from && format(value.from, "MM/dd/yyyy")}
                {filterType === "range" && value.from && value.to && " - "}
                {value.to && format(value.to, "MM/dd/yyyy")}
              </Badge>
            </>
          ) : null}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <div className="p-3 border-b flex justify-between items-center">
          <Select
            value={filterType}
            onValueChange={(value: DateFilterType) => {
              setFilterType(value);
              // Reset the date selection when changing filter type
              setDate(undefined);
              column?.setFilterValue(undefined);
            }}
          >
            <SelectTrigger className="w-[140px]">
              <SelectValue placeholder="Select filter type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="range">Date Range</SelectItem>
              <SelectItem value="before">Before Date</SelectItem>
              <SelectItem value="after">After Date</SelectItem>
            </SelectContent>
          </Select>
          {(value?.from || value?.to) && (
            <Button
              variant="ghost"
              size="sm"
              onClick={clearFilter}
              className="h-8 px-2 lg:px-3"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
        {/* @ts-expect-error - Doesn't like dynamic mode */}
        <Calendar
          initialFocus
          mode={
            filterType === "range" ? ("range" as const) : ("single" as const)
          }
          defaultMonth={value?.from}
          selected={date}
          onSelect={handleDateSelect}
          numberOfMonths={2}
        />
      </PopoverContent>
    </Popover>
  );
};

export function DataTableToolbar({ table }: DataTableToolbarProps<BillsRow>) {
  const [
    exportDelinquentPatientsDialogOpen,
    setExportDelinquentPatientsDialogOpen,
  ] = useState(false);
  const [
    exportSentBillsPatientsDialogOpen,
    setExportSentBillsPatientsDialogOpen,
  ] = useState(false);

  const isFiltered = table.getState().columnFilters.length > 0;

  const rows = table.getPreFilteredRowModel().rows;
  const accountTypeOptions = useMemo(() => {
    const accountTypeOptions = Array.from(
      new Set(rows.flatMap((row) => row.original.accountType).filter(isDefined))
    ).map((accountType) => ({
      value: accountType,
      label: accountType,
    }));
    return accountTypeOptions;
  }, [rows]);
  const providerOptions = useMemo(() => {
    const providerOptions = Array.from(
      new Set(
        rows.flatMap((row) => row.original.providerName).filter(isDefined)
      )
    ).map((provider) => ({
      value: provider,
      label: provider,
    }));
    return providerOptions;
  }, [rows]);
  const chargeCodeOptions = Array.from(
    new Set(
      table
        .getPreFilteredRowModel()
        .rows.flatMap((row) => row.original.chargeCodes)
    )
  ).map((chargeCode) => ({
    value: chargeCode,
    label: chargeCode,
  }));

  const statusFilterValue = table
    .getColumn("status")
    ?.getFilterValue() as string[];
  const reminderFilterValue = table
    .getColumn("reminder")
    ?.getFilterValue() as string[];
  const sentFilterValue = table.getColumn("sent")?.getFilterValue() as string[];

  return (
    <>
      <HorizontalPadding>
        <div className="flex items-start justify-between gap-2 w-full">
          <div className="flex flex-1 items-center flex-wrap gap-2">
            <Input
              placeholder="Search bills"
              value={table.getState().globalFilter ?? ""}
              onChange={(event) => table.setGlobalFilter(event.target.value)}
              className="h-8 w-[150px] lg:w-[250px]"
            />

            {table.getColumn("status") && (
              <DataTableFacetedFilter
                column={table.getColumn("status")}
                title="Status"
                options={STATUS_OPTIONS}
              />
            )}
            <DateRangeFilter
              column={table.getColumn("dateOfService")}
              title="Date of Service"
            />
            {table.getColumn("accountType") && (
              <DataTableFacetedFilter
                column={table.getColumn("accountType")}
                title="Account Type"
                options={accountTypeOptions}
              />
            )}
            {table.getColumn("provider") && (
              <DataTableFacetedFilter
                column={table.getColumn("provider")}
                title="Provider"
                options={providerOptions}
              />
            )}
            <DataTableFacetedFilter
              column={table.getColumn("onHold")}
              title="On Hold"
              options={ON_HOLD_OPTIONS}
            />
            <DataTableFacetedFilter
              column={table.getColumn("scheduled")}
              title="Scheduled"
              options={SCHEDULED_OPTIONS}
            />
            <DataTableFacetedFilter
              column={table.getColumn("sent")}
              title="Sent"
              options={SENT_OPTIONS}
            />
            <DataTableFacetedFilter
              column={table.getColumn("reminder")}
              title="Reminder"
              options={REMINDER_OPTIONS}
            />
            <DataTableFacetedFilter
              column={table.getColumn("chargeCodes")}
              title="Charge Codes"
              options={chargeCodeOptions}
              sortByCount={true}
              arrayValue={true}
            />
            {isFiltered && (
              <Button
                variant="ghost"
                onClick={() => table.resetColumnFilters()}
                className="h-8 px-2 lg:px-3"
              >
                Reset
                <Cross2Icon className="ml-2 h-4 w-4" />
              </Button>
            )}
          </div>
          <div className="flex gap-2 items-center">
            {statusFilterValue?.includes(BillState.Ready) &&
              reminderFilterValue?.includes("Exhausted") &&
              sentFilterValue?.includes("Sent") && (
                <Button
                  size="sm"
                  className="h-8"
                  onClick={() => setExportDelinquentPatientsDialogOpen(true)}
                >
                  Export Delinquent Patients
                </Button>
              )}
            {statusFilterValue?.includes(BillState.Ready) &&
              !reminderFilterValue?.includes("Exhausted") &&
              sentFilterValue?.includes("Sent") && (
                <Button
                  size="sm"
                  className="h-8"
                  onClick={() => setExportSentBillsPatientsDialogOpen(true)}
                >
                  Export Patients Sent Bills
                </Button>
              )}
            <DataTableViewOptions table={table} />
          </div>
        </div>
      </HorizontalPadding>
      {exportDelinquentPatientsDialogOpen && (
        <DownloadDelinquentPatientsDialog
          open={exportDelinquentPatientsDialogOpen}
          setOpen={setExportDelinquentPatientsDialogOpen}
        />
      )}
      {exportSentBillsPatientsDialogOpen && (
        <DownloadSentPatientsDialog
          open={exportSentBillsPatientsDialogOpen}
          setOpen={setExportSentBillsPatientsDialogOpen}
        />
      )}
    </>
  );
}
