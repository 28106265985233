import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  from,
  HttpLink,
  gql,
  Observable,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import DebounceLink from "apollo-link-debounce";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "react-query";

import { AuthProvider } from "./auth-context";
import { AnalyticsProvider } from "./analytics-context";
import { constants } from "./constants";

const demoMiddleware = new ApolloLink((operation, forward) => {
  const demo = window.localStorage.getItem("demo");
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "x-pledge-demo": demo ?? false,
    },
  }));
  return forward(operation);
});

/**
 * Custom error handler to report errors to Sentry
 */
const errorLink = onError(
  ({ operation, graphQLErrors, networkError, forward }) => {
    Sentry.withScope((scope) => {
      scope.setTransactionName(operation.operationName);
      scope.setContext("GraphQLOperation", {
        operationName: operation.operationName,
        variables: operation.variables,
        extensions: operation.extensions,
      });

      // Handle unauthorized errors
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          if (
            err.extensions.code === "INTERNAL_SERVER_ERROR" &&
            err.message.includes("Access denied")
          ) {
            // Notify the app about the unauthorized error
            window.dispatchEvent(new CustomEvent("UNAUTHORIZED_ERROR"));
            console.log("UNAUTHORIZED_ERROR", err.message);

            return new Observable((observer) => {
              observer.complete();
            });
          }
        }
      }

      graphQLErrors?.forEach((error) => {
        Sentry.captureMessage(error.message, {
          level: "error",
          fingerprint: ["{{ default }}", "{{ transaction }}"],
          contexts: {
            apolloGraphQLError: {
              error,
              message: error.message,
              extensions: error.extensions,
            },
          },
        });
      });

      if (networkError) {
        Sentry.captureMessage(networkError.message, {
          level: "error",
          contexts: {
            apolloNetworkError: {
              error: networkError,
              extensions: (networkError as any).extensions,
            },
          },
        });
      }
    });
  }
);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        localAppointments: {
          // Don't cache separate results based on
          // any of this field's arguments.
          // keyArgs: false,
          // Concatenate the new incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        localInReviewBills: {
          // Don't cache separate results based on
          // any of this field's arguments.
          // keyArgs: false,
          // Concatenate the new incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        localReadyBills: {
          // Don't cache separate results based on
          // any of this field's arguments.
          // keyArgs: false,
          // Concatenate the new incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        localBills: {
          // Don't cache separate results based on
          // any of this field's arguments.
          // keyArgs: false,
          // Concatenate the new incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return incoming;
          },
        },
      },
    },
    // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
    Bill: {
      fields: {
        toCollect: {
          // Non-normalized BillCollection object within Bill
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
  },
});

const typeDefs = gql`
  extend type Query {
    localAppointments: [AppointmentWithPatient!]!
    localBills: [Bill!]!
    localInReviewBills: [Bill!]!
    localReadyBills: [Bill!]!
  }
`;

const client = new ApolloClient({
  credentials: "include",
  cache,
  link: from([
    errorLink,
    demoMiddleware,
    new DebounceLink(0),
    new HttpLink({
      uri: `${constants.VITE_GRAPHQL_URL}/graphql`,
      credentials: "include",
    }),
  ]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
  typeDefs,
});

const queryClient = new QueryClient();

const AppProviders: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <AnalyticsProvider>
          <AuthProvider>{children}</AuthProvider>
        </AnalyticsProvider>
      </ApolloProvider>
    </QueryClientProvider>
  );
};
export default AppProviders;
