import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  Table as ReactTable,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import * as React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";

import { OvalSpinner } from "../../../components/loading";
import { DataTablePagination } from "../../../components/ui/table-helpers/data-table-pagination";
import { cn } from "../../../utils";
import { HorizontalPadding } from "../../layout";
import { BillsRow } from "./columns";
import { DataTableToolbar } from "./data-table-toolbar";

interface DataTableProps<TValue> {
  columns: ColumnDef<BillsRow, TValue>[];
  defaultColumnFilters?: ColumnFiltersState;
  data: BillsRow[];
}

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export type DataTableMeta = {
  updateData: (rowIndex: number, columnId: string, value: any) => void;
  notesExpanded: boolean;
  setNotesExpanded: (expanded: boolean) => void;
  columnFilters: ColumnFiltersState;
};

export function DataTable<TValue>({
  columns,
  data,
  defaultColumnFilters,
  children,
  loading,
  loadingMore,
  onRowClick,
}: DataTableProps<TValue> & {
  children?: (table: ReactTable<BillsRow>) => React.ReactElement;
  loading?: boolean;
  loadingMore?: boolean;
  onRowClick?: (row: BillsRow) => void;
}) {
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      sent: false,
      scheduled: false,
      reminder: false,
      onHold: false,
      chargeCodes: false,
    });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    defaultColumnFilters ?? []
  );
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "patient",
      desc: false,
    },
    {
      id: "dateOfService",
      desc: false,
    },
  ]);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const reminderFilter = columnFilters.find(
    (filter) => filter.id === "reminder"
  );
  const reminderFilterActive =
    Array.isArray(reminderFilter?.value) &&
    reminderFilter.value.some((v) => v === "Enrolled" || v === "Exhausted");

  const onHoldFilter = columnFilters.find((filter) => filter.id === "onHold");
  const onHoldFilterActive =
    Array.isArray(onHoldFilter?.value) &&
    onHoldFilter.value.some((v) => v === "true");
  const statusFilter = columnFilters.find((filter) => filter.id === "status");
  const readyStatusFilterActive =
    Array.isArray(statusFilter?.value) &&
    statusFilter.value.some((v) => v === "Ready");

  React.useEffect(() => {
    setColumnVisibility((columnVisibility) => ({
      ...columnVisibility,
      nextReminder: reminderFilterActive,
      onHold: onHoldFilterActive,
      communicationStatus: readyStatusFilterActive,
      dueDate: readyStatusFilterActive,
    }));
  }, [reminderFilterActive, onHoldFilterActive, readyStatusFilterActive]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      globalFilter,
      pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: (filters) => {
      setColumnFilters(filters);
      // Reset to first page on filter change
      setPagination({ ...pagination, pageIndex: 0 });
    },
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onPaginationChange: setPagination,
    autoResetPageIndex: false,
  });

  return (
    <>
      {children && children(table)}
      <div className="space-y-4">
        <HorizontalPadding>
          <div className="w-full">
            <DataTableToolbar table={table} />
          </div>
        </HorizontalPadding>
        <div className="border bg-white max-h-[75vh] overflow-x-auto">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="sticky top-0 z-10">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    onClick={
                      onRowClick ? () => onRowClick(row.original) : undefined
                    }
                  >
                    {row.getVisibleCells().map((cell, i) => (
                      <TableCell
                        key={cell.id}
                        className={cn(
                          "whitespace-nowrap"
                          // CSS to make the last column sticky
                          // "last:sticky last:right-0  last:bg-white last:drop-shadow"
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    <div className="w-full flex justify-center">
                      <OvalSpinner />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <HorizontalPadding>
          <div className="w-full">
            <DataTablePagination
              table={table}
              loading={loading || loadingMore}
            />
          </div>
        </HorizontalPadding>
      </div>
    </>
  );
}
