import { Comparison } from "./generated/globalTypes";

export type Condition<T> = {
  attribute: string | number | string[] | number[];
  attributeType: T;
  comparison: Comparison;
  providerServiceConfigurationId: string | null;
};

const BENEFIT_ATTRIBUTE_TYPES = [
  "remainingDeductible",
  "remainingOutOfPocket",
  "remainingVisits",
];

export type RuleBenefit = {
  providerServiceConfigurationId: string;
  remainingDeductible: number | null;
  remainingOutOfPocket: number | null;
  remainingVisits: number | null;
  deductibleApplies: boolean | null;
};

export type AttributeTypes =
  | keyof Visit
  | "remainingDeductible"
  | "remainingOutOfPocket"
  | "remainingVisits";
export const getAttribute = <
  T extends {
    benefits: RuleBenefit[];
    visitChargemasterGroups: { id: string; cashPay: boolean }[];
  }
>(
  condition: Condition<keyof T>,
  attributes: T
) => {
  let attribute = attributes[condition.attributeType] as
    | string
    | string[]
    | number
    | null;
  // If it's a benefit attribute, grab the attribute from the benefits array
  if (BENEFIT_ATTRIBUTE_TYPES.includes(condition.attributeType as string)) {
    const benefit = attributes.benefits.find(
      (b) =>
        b.providerServiceConfigurationId ===
        condition.providerServiceConfigurationId
    );
    // If deductible doesn't apply, set it to null
    if (
      condition.attributeType === "remainingDeductible" &&
      benefit?.deductibleApplies === false
    ) {
      attribute = null;
    } else {
      attribute = benefit?.[condition.attributeType as keyof typeof benefit] as
        | number
        | null;
    }
  }

  if (condition.attributeType === "visitChargemasterGroups") {
    attribute = (
      (attributes["visitChargemasterGroups"] ?? []) as {
        id: string;
        cashPay: boolean;
      }[]
    ).map((c) => c.id);
  }

  return attribute;
};

const parseNumericAttribute = (
  attribute: string | number | string[] | number[] | null
) => {
  if (typeof attribute === "number") return attribute;
  if (typeof attribute === "string") return Number.parseFloat(attribute);
  return null;
};

export const evaluateCondition = <
  T extends {
    benefits: RuleBenefit[];
    visitChargemasterGroups: { id: string; cashPay: boolean }[];
  }
>(
  condition: Condition<keyof T>,
  attributes: T
): boolean => {
  const attribute = getAttribute(condition, attributes);

  switch (condition.comparison) {
    case Comparison.Equals:
      // If the attribute is an array, check if any of the values match
      if (Array.isArray(attribute)) {
        return attribute.some((attr) => attr == condition.attribute);
      }
      return attribute == condition.attribute;
    case Comparison.NotEquals:
      // If the attribute is an array, check if every of the values don't match
      if (Array.isArray(attribute)) {
        return attribute.every((attr) => attr != condition.attribute);
      }
      return attribute != condition.attribute;
    case Comparison.GreaterThan:
      let conditionAttrGT = parseNumericAttribute(condition.attribute);
      let attrGT = parseNumericAttribute(attribute);
      if (Number.isNaN(conditionAttrGT) || typeof conditionAttrGT !== "number")
        return false;
      if (Number.isNaN(attrGT) || typeof attrGT !== "number") return false;
      return attrGT > conditionAttrGT;
    case Comparison.GreaterThanOrEqual:
      let conditionAttrGTE = parseNumericAttribute(condition.attribute);
      let attrGTE = parseNumericAttribute(attribute);
      if (
        Number.isNaN(conditionAttrGTE) ||
        typeof conditionAttrGTE !== "number"
      )
        return false;
      if (Number.isNaN(attrGTE) || typeof attrGTE !== "number") return false;
      return attrGTE >= conditionAttrGTE;
    case Comparison.LessThan:
      let conditionAttrLT = parseNumericAttribute(condition.attribute);
      let attrLT = parseNumericAttribute(attribute);
      if (Number.isNaN(conditionAttrLT) || typeof conditionAttrLT !== "number")
        return false;
      if (Number.isNaN(attrLT) || typeof attrLT !== "number") return false;
      return attrLT < conditionAttrLT;
    case Comparison.LessThanOrEqual:
      let conditionAttrLTE = parseNumericAttribute(condition.attribute);
      let attrLTE = parseNumericAttribute(attribute);
      if (
        Number.isNaN(conditionAttrLTE) ||
        typeof conditionAttrLTE !== "number"
      )
        return false;
      if (Number.isNaN(attrLTE) || typeof attrLTE !== "number") return false;
      return attrLTE <= conditionAttrLTE;
    case Comparison.In:
      if (!Array.isArray(condition.attribute)) return false;
      // If the attribute is an array, check if any of the values match
      if (Array.isArray(attribute)) {
        return attribute.some((attr) =>
          (condition.attribute as number[] | string[]).some((ca) => ca === attr)
        );
      }
      return condition.attribute.some((attr) => attr === attribute);
    case Comparison.NotIn:
      if (!Array.isArray(condition.attribute)) return false;
      if (Array.isArray(attribute)) {
        return attribute.every((attr) =>
          (condition.attribute as string[]).every((ca) => ca !== attr)
        );
      }
      return !condition.attribute.some((attr) => attr === attribute);
    case Comparison.Contains:
      // If the attribute is an array, check if any of the values match
      if (Array.isArray(attribute)) {
        return attribute.some((attr) =>
          attr.includes(condition.attribute?.toString())
        );
      }
      return (
        attribute?.toString()?.includes(condition.attribute?.toString()) ||
        false
      );
    // Only valid values are true or false
    case Comparison.IsMet:
      // No arrays allowed
      const isMet = attribute === 0 || attribute === null;
      // If condition is checking if met
      if (condition.attribute === "true") {
        return isMet;
      }
      // If condition is checking if not met
      if (condition.attribute === "false") {
        return !isMet;
      }
      return false;
  }
  return false;
};

// The attributes for a visit to evaluate
export type Visit = {
  providerId: string | null;
  providerTaxonomyCodeId: string | null;
  appointmentType: string | null;
  accountTypeId: string | null;
  appointmentLabelId: string | null;
  patientLabelId: string | null;
  payerId: string;
  groupName: string | null;
  inNetwork: string;
  chargemasterGroupIds: string[];
  codeGroups: string[];
  appointmentDuration: number | null;
  visitChargemasterGroups: { id: string; cashPay: boolean }[];
  benefits: RuleBenefit[];
};

type BenefitMapping = {
  benefitMappingConditions: Condition<keyof Visit>[];
};

const CHARGE_ATTRIBUTES = ["chargemasterGroupId", "chargeType"];
const isChargeAttribute = (attributeType: string) => {
  return CHARGE_ATTRIBUTES.includes(attributeType);
};

export const evaluateBenefitAssignmentRule = (
  visit: Visit,
  benefitMapping: Pick<BenefitMapping, "benefitMappingConditions">
) => {
  const conditions = benefitMapping.benefitMappingConditions;
  const visitConditions = conditions.filter(
    (c) => !isChargeAttribute(c.attributeType)
  );

  if (visit.chargemasterGroupIds.length > 0) {
    // If every charge has a match
    const chargesMatch = visit.chargemasterGroupIds.every(
      (chargemasterGroupId) => {
        const match = conditions.every((condition) => {
          return evaluateCondition(condition, {
            ...visit,
            chargemasterGroupIds: [chargemasterGroupId],
            // TODO: Charge grouping
            chargeType: null,
          });
        });
        return match;
      }
    );
    // If all charges match all conditions return true
    if (chargesMatch) {
      return true;
    }
  }

  // If no charge match, check visit match against visit conditions
  return visitConditions.every((condition) =>
    evaluateCondition(condition, visit)
  );
};
