import { Cross2Icon, DotsVerticalIcon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { gql, useMutation, useQuery } from "@apollo/client";

import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { DataTableViewOptions } from "../../components/ui/table-helpers/data-table-view-options";
import { DataTableFacetedFilter } from "../../components/ui/table-helpers/data-table-faceted-filter";

import { GetPayers, GetPayersVariables } from "../../generated/GetPayers";
import { useUser } from "../../user-context";
import { GET_PAYERS } from "../payers";
import { SkipForward } from "lucide-react";
import { VerificationWorkflowReason } from "../../generated/globalTypes";
import { cn } from "../../utils";
import {
  BulkWorklistItemActionMenu,
  GET_VERIFICATION_WORKFLOW_POLICIES,
} from "./list";
import { toast } from "react-toastify";
import {
  RunIntegrationSync,
  RunIntegrationSyncVariables,
} from "../../generated/RunIntegrationSync";
import { RefreshIcon } from "@heroicons/react/outline";
import { useDashboardResizable } from "../../dashboard-resizable";
import { VerificationSidePanel } from "./side-panel";
import { VerificationWorklistRow } from "./columns";
import { GET_ACCOUNT_TYPES } from "../billing";
import {
  GetAccountTypes,
  GetAccountTypesVariables,
} from "../../generated/GetAccountTypes";
import { useAnalytics } from "../../analytics-context";
import { useQueryClient } from "react-query";

interface DataTableToolbarProps<TData extends { id: string }> {
  table: Table<TData>;
}

const REASON_OPTIONS: {
  value: string;
  label: string;
}[] = [
  {
    value: VerificationWorkflowReason.NewPolicy,
    label: "New Policy",
  },
  {
    value: VerificationWorkflowReason.Reverification,
    label: "Reverification",
  },
];

const ELIGIBILITY_STATUS_OPTIONS = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
  {
    value: "NeedsReverification",
    label: "Needs Reverification",
  },
  {
    value: "Error",
    label: "Error",
  },
  {
    value: "Unverified",
    label: "Unverified",
  },
];

const RUN_INTEGRATION_SYNC = gql`
  mutation RunIntegrationSync($integrationId: String!) {
    runIntegrationSync(integrationId: $integrationId) {
      synced
      errors {
        message
      }
    }
  }
`;

export function DataTableToolbar({
  table,
}: DataTableToolbarProps<VerificationWorklistRow>) {
  const user = useUser()!;
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const { setPanelChild } = useDashboardResizable();
  const integrations = user.activeLocation.integrations;
  const integrationsSupportingSync = integrations.filter(
    (integration) => integration.supportsOnDemandSync
  );
  // const { openInsurancePolicyWorklistSplitPane } = useSplitPane();
  const payersResult = useQuery<GetPayers, GetPayersVariables>(GET_PAYERS, {
    variables: {
      organizationId: user.organization.id,
      locationId: user.activeLocation.id,
    },
  });
  const accountTypesResult = useQuery<
    GetAccountTypes,
    GetAccountTypesVariables
  >(GET_ACCOUNT_TYPES, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });
  const [runIntegrationSync, runIntegrationSyncResult] = useMutation<
    RunIntegrationSync,
    RunIntegrationSyncVariables
  >(RUN_INTEGRATION_SYNC);

  const statusOptions = user.activeLocation.verificationWorkflowStatuses.map(
    (s) => ({ value: s.name, label: s.name })
  );

  const payerOptions = Array.from(
    new Set(payersResult.data?.payers.map((p) => p.name) ?? [])
  ).map((payer) => ({ value: payer, label: payer }));

  const accountTypeOptions = Array.from(
    new Set(accountTypesResult?.data?.accountTypes?.map((p) => p.name) ?? [])
  ).map((payer) => ({ value: payer, label: payer }));

  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-start justify-between gap-2">
      <div className="flex flex-1 items-center flex-wrap gap-2">
        <Input
          placeholder="Filter by Patient or Member ID"
          value={table.getState().globalFilter ?? ""}
          onChange={(event) => table.setGlobalFilter(event.target.value)}
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statusOptions}
          />
        )}
        {table.getColumn("lastVerificationWorkflowReason") && (
          <DataTableFacetedFilter
            column={table.getColumn("lastVerificationWorkflowReason")}
            title="Reason"
            options={REASON_OPTIONS}
          />
        )}
        {table.getColumn("patientName") && (
          <DataTableFacetedFilter
            column={table.getColumn("patientName")}
            title="Account"
            options={accountTypeOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("payerName") && (
          <DataTableFacetedFilter
            column={table.getColumn("payerName")}
            title="Payer"
            options={payerOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("eligibilityStatus") && (
          <DataTableFacetedFilter
            column={table.getColumn("eligibilityStatus")}
            title="Eligibility Status"
            options={ELIGIBILITY_STATUS_OPTIONS}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <Button
        className="h-8"
        size="sm"
        disabled={table.getFilteredRowModel().rows.length === 0}
        onClick={() => {
          setPanelChild(
            <VerificationSidePanel
              ids={table
                .getFilteredRowModel()
                .rows.map((row) => row.original.id)}
            />
          );
          analytics?.track("Verification Worklist Batch Opened", {
            organizationId: user.organization.id,
            organizationName: user.organization.name,
            locationId: user.activeLocation.id,
            locationName: user.activeLocation.name,
            count: table.getFilteredRowModel().rows.length,
          });
          table.resetRowSelection();
        }}
        type="button"
      >
        Execute Tasks
        <SkipForward className="ml-2 h-4 w-4" />
      </Button>
      {integrationsSupportingSync.map((integration) => (
        <Button
          onClick={() =>
            runIntegrationSync({
              variables: { integrationId: integration.id },
              onCompleted: (data) => {
                analytics?.track("Integration Sync Run", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                  integrationId: integration.id,
                  integrationType: integration.type,
                });
                if (data.runIntegrationSync.errors?.at(0)) {
                  toast.error(data.runIntegrationSync.errors[0].message);
                } else {
                  toast.success("Synced from " + integration.name);
                  queryClient.invalidateQueries("verificationWorklist");
                }
              },
              onError: () => {
                toast.error("Error syncing from " + integration.name);
              },
            })
          }
          className="h-8"
          size="sm"
          disabled={runIntegrationSyncResult.loading}
        >
          Sync from {integration.name}
          <RefreshIcon
            className={cn(
              "h-4 w-4 ml-2",
              runIntegrationSyncResult.loading && "animate-spin"
            )}
          />
        </Button>
      ))}
      <BulkWorklistItemActionMenu
        worklistRows={table.getSortedRowModel().rows.map((row) => row.original)}
        trigger={
          <Button variant="outline" size="sm" className="h-8" type="button">
            <DotsVerticalIcon className="h-4 w-4" />
          </Button>
        }
        onClose={() => {
          table.resetRowSelection();
        }}
      />
      <DataTableViewOptions table={table} />
    </div>
  );
}
